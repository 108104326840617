<div class="document-list-item">
  <div class="document-list-item-content">
    <div class="document-list-item-content-title">

      <ev-file-button [url]="'/api/v1/finder/document/' + item.id + '/'" [link]="true">
        <i class="fa fa-file-pdf-o"></i>
        <strong>
          {{ date }}
        </strong> - {{ item.name }}.{{ item.extension }}
      </ev-file-button>

    </div>

    <div class="document-list-item-content-text" *ngIf="item.content" [innerHTML]="item.content">
    </div>

    <div class="document-list-item-content-tags">
      <div class="label label-category" *ngFor="let category of item.categories">
        <i class="fa fa-folder-open-o"></i>
        {{ category }}
      </div>

      <div class="label label-filenumber" *ngFor="let fileNumber of item.file_number">
        <i class="fa fa-paperclip"></i>
        {{ fileNumber }}
      </div>


      <div class="label label-additional" *ngFor="let additional of additionals">
        <i class="fa fa-tag"></i>
        {{ additional.key }}: {{ additional.value }}
      </div>

    </div>
  </div>
  <div class="document-list-item-action">
    <a [href]="item.web_url" title="Dokument im envisia finder bearbeiten" target="_blank">
      <i class="fa fa-pencil"></i>
    </a>
  </div>
</div>
