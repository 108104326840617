import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {WorkflowStatusModalComponent} from './workflow-status-modal.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowStatusChangeModalComponent} from './workflow-status-change-modal.component';

@NgModule({
    imports: [
        SharedModule,
        NgbModalModule,
    ],
    declarations: [WorkflowStatusModalComponent, WorkflowStatusChangeModalComponent]
})
export class WorkflowStatusModalModule {
}
