/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {ArticleNavigationModule} from '../navigiation/article-navigation.module';
import {SharedModule} from '../../../core/shared.module';
import {ArticleExtraModule} from '../../article-extra/article-extra.module';
import {ArticleShippingInstructionComponent} from './article-shipping-instruction.component';

@NgModule({
    imports: [
        SharedModule,
        ArticleExtraModule,
        ArticleNavigationModule,
    ],
    declarations: [
        ArticleShippingInstructionComponent
    ]
})
export class ArticleShippingInstructionModule {
}
