import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {MaterialUserListComponent} from './material-user-list.component';
import {UserListService} from './user-list.service';
import {MaterialGroupListAllService} from '../groups/material-group-list-all.service';
import {MaterialUserService} from './material-user.service';
import {MaterialProductGroupsService} from '../product-groups/material-product-groups.service';

function minimalUserListResolveFn(transition: Transition, service: UserListService) {
  return service.list(transition.params()).toPromise();
}

export function materialProductGroupListResolveFn(mpgs: MaterialProductGroupsService) {
  return mpgs.list({take: 200}).toPromise();
}

function groupListResolveFn(service: MaterialGroupListAllService) {
  return service.list().toPromise();
}

function groupVisibilityResolveFn(service: MaterialUserService) {
  return service.visibilityGet().toPromise();
}

export const MATERIAL_USER_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.material.users.list',
  url: '/material/user/list?page&sort&order&name_user&name_last&name_first',
  views: {'main@a': {component: MaterialUserListComponent}},
  params: {
    page: {dynamic: true},
    sort: {dynamic: true},
    order: {dynamic: true},
    name_user: {dynamic: true},
    name_last: {dynamic: true},
    name_first: {dynamic: true},
  },
  resolve: [
    {
      token: 'listData',
      resolveFn: minimalUserListResolveFn,
      deps: [Transition, UserListService]
    },
    {
      token: 'productGroups',
      resolveFn: materialProductGroupListResolveFn,
      deps: [MaterialProductGroupsService],
    },
    {
      token: 'groups',
      resolveFn: groupListResolveFn,
      deps: [MaterialGroupListAllService]
    },
    {
      token: 'groupVisibility',
      resolveFn: groupVisibilityResolveFn,
      deps: [MaterialUserService]
    },
  ],
  data: {requiresAuth: true}
};
