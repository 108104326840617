<div class="modal-header">
  <h3 class="modal-title">
    Änderungsverlauf Liefervorschriften {{visibleName}}
  </h3>
</div>

<div class="modal-body">
  <shipping-instruction-history-list [list]="list" [schema]="schema" [article]="article">
  </shipping-instruction-history-list>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default pull-right" (click)="close()">Schließen</button>
</div>
