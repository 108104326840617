<div class="modal-header">
  <h3 class="modal-title">Lagerbestand</h3>
</div>

<div class="modal-body inventory">

  <div class="row">

    <div class="col-md-3">
      <p class="inventory-headline">
        SDS-Nr.:
        <span *ngIf="article.sds_nr" class="inventory-fat">{{ article.sds_nr }}</span>
        <span *ngIf="!article.sds_nr" class="inventory-fat">{{ article.oa_nr }}</span>
      </p>
    </div>

    <div class="col-md-3">
      <p class="inventory-headline">
        Aktueller Bestand:
        <span [ngClass]="{'color-green': success()}" class="inventory-fat">{{ sum|money }}</span>
      </p>
    </div>

    <div class="col-md-6">
      <p class="inventory-headline">
        Kunde:
        <span class="inventory-fat">{{ article.customer }}</span>
      </p>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12">
      <table class="table whitetable">
        <thead>
        <tr>
          <th>Datecode</th>
          <th>Max. Lagerzeit</th>
          <th>Menge</th>
          <th>Lager</th>
          <th>Lagerplatz</th>
          <th>Vorgangs-Nr.</th>
          <th>Hinweis</th>
          <th class="icon" colspan="3" *ngIf="('inventory.transactions'|HasPerm)">&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr [ngClass]="{'warning': !!obj.maxDateReached}" *ngFor="let obj of groupObjects">
          <td class="click" (click)="setFormData(obj)">{{ obj.dateCode }}</td>
          <td class="click" (click)="setFormData(obj)">{{ obj.dateCodeMax }}</td>
          <td class="click" (click)="setFormData(obj)">{{ obj.totalSumQuantity }}</td>
          <td class="click" (click)="setFormData(obj)">{{ obj.storageBinName }}</td>
          <td class="click" (click)="setFormData(obj)">{{ obj.storageBinNumber }}</td>
          <td class="click" (click)="setFormData(obj)">{{ obj.workflowId }}</td>
          <td class="click" (click)="setFormData(obj)">{{ obj.note }}</td>
          <td class="click"
              [title]="((!!obj.note && obj.note.length > 0) ? 'Hinweis ändern' : 'Hinweis hinzufügen')"
              (click)="changeModalState(obj, (!!obj.note ? 3 : 2))"
              *ngIf="('inventory.transactions'|HasPerm)">
            <i class="fa fa-pencil"></i>
          </td>
          <td class="click"
              title="Lagerbestand Lagerplatz umbuchen"
              (click)="changeModalState(obj, 1)"
              *ngIf="('inventory.transactions'|HasPerm)">
            <i class="fa fa-truck"></i>
          </td>
          <td class="click"
              title="Lagerbestand Vorgang umbuchen"
              (click)="changeModalState(obj, 4)"
              *ngIf="('inventory.transactions'|HasPerm)">
            <i class="fa fa-code-fork"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12" *ngIf="reservedObjects.objects.length > 0">
      <h2>
        Auf Betriebsaufträgen reservierter Lagerbestand
      </h2>
      <table class="table whitetable">
        <thead>
        <tr>
          <th>BA-Nr.</th>
          <th>Datecode</th>
          <th>Menge</th>
          <th>Lager</th>
          <th>Lagerplatz</th>
          <th *ngIf="('inventory.transactions'|HasPerm)"></th>
        </tr>
        </thead>
        <tbody>

        <tr *ngFor="let obj of reservedObjects.objects">
          <td>{{ obj.ba_id }}-1</td>
          <td>{{ obj.date_code }}</td>
          <td>{{ -obj.quantity|money }}</td>
          <td>{{ obj.storage_bin_name }}</td>
          <td>{{ obj.storage_bin_number }}</td>
          <td class="click icon"
              title="Auf BA reservierten Lagerbestand zurückbuchen"
              (click)="businessOrderInventoryRelationRelease(obj)"
              *ngIf="('inventory.transactions'|HasPerm)">
            <i class="fa fa-level-down red_font"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12" *ngIf="('inventory.transactions'|HasPerm)">
      <article-inventory-form [oaNr]="article?.oa_nr"
                              [storage]="storage"
                              [workflowId]="workflowId"
                              [workflowIds]="workflowIds"
                              [selectedInventory]="selectedInventory"
                              (selectedUnset)="setFormData(null)"
                              (update)="save()">
      </article-inventory-form>
    </div>

    <div class="col-md-12">
      &nbsp;
    </div>

    <div class="col-md-12">
      <h4>Bestandsverlauf</h4>

      <date-range [start]="null" [end]="null" (update)="updateDateRange($event)"></date-range>

      <table class="whitetable table">
        <thead>
        <tr>
          <th>Typ</th>
          <th>Datum</th>
          <th>Benutzer</th>
          <th>Datecode</th>
          <th>Lager</th>
          <th>Lagerplatz</th>
          <th>Vorgangs-Nr.</th>
          <th>Menge</th>
          <th>Notiz</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let obj of objects">
          <td>
            <i class="fa" [ngClass]="obj.event.icon"></i> {{ obj.event.name }}
          </td>
          <td>{{ obj.date|date:"dd.MM.yyyy HH:mm" }}</td>
          <td>{{ obj.username }}</td>
          <td>{{ obj.dateCode }}</td>
          <td>{{ obj.storageBinName }}</td>
          <td>{{ obj.storageBinNumber }}</td>
          <td>{{ obj.workflowId }}</td>
          <td [ngClass]="obj.color">{{ obj.op }}&nbsp;&nbsp;{{ quantityFormat(obj.quantity) }}</td>
          <td>{{ obj.note }}</td>
        </tr>
        </tbody>
      </table>

      <ev-navigation [page]="inventoryData.page"
                     [pages]="inventoryData.pages"
                     [query]="query()"
                     (goto)="goto($event)">
      </ev-navigation>

    </div>

  </div>
</div>

<div class="modal-footer">
</div>
