<h3>Fertigungsvorlagen</h3>

<div class="row">
  <div class="col-md-12">

    <button type="button" class="btn btn-success" (click)="templateCreate()">
      Neue Fertigungsvorlage
    </button>

  </div>
</div>

<div class="row">

  <div class="col-md-3">

    <table class="table whitetable">
      <thead>
      <tr>
        <th>Name (Bezeichnung)</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let obj of templateList; let index = index" [class.selectedTemplate]="obj === selected">
        <td>
          {{ obj.name }}
          <span *ngIf="obj.description">({{ obj.description }})</span>
        </td>
        <td>
          <button type="button" class="btn btn-sm btn-link" (click)="templateEdit(index, obj)">
            <i class="fa fa-pencil"></i>
          </button>
        </td>
        <td>
          <button type="button" class="btn btn-sm btn-link" (click)="detail(obj)">
            <i class="fa fa-chevron-right fa-1x fa-fw"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

  </div>

  <div class="col-md-1">&nbsp;</div>

  <div class="col-md-3">
    <h4 *ngIf="selected">Fertigungsschritte in Vorlage: {{ selected.name }}</h4>

    <table class="table whitetable" *ngIf="selected">
      <thead>
      <tr>
        <th>Nr.</th>
        <th>Bezeichnung</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let obj of stepList; let index = index">
        <td>{{ obj.step.nr }}</td>
        <td>{{ obj.step.text }}</td>

        <td>
          <button type="button" class="btn btn-link btn-sm" *ngIf="!last(index)" (click)="stepUp(index, obj)">
            <i class="fa fa-chevron-down fa-fw"></i>
          </button>
        </td>
        <td>
          <button type="button" class="btn btn-link btn-sm" *ngIf="index !== 0" (click)="stepDown(index, obj)">
            <i class="fa fa-chevron-up fa-fw"></i>
          </button>
        </td>
        <td>
          <button type="button" class="btn btn-sm btn-link" (click)="stepEdit(index, obj)">
            <i class="fa fa-pencil"></i>
          </button>
        </td>
        <td>
          <button type="button" class="btn btn-link btn-sm" (click)="stepDelete(index, obj)">
            <i class="fa fa-trash-o fa-fw"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <div class="col-md-1">&nbsp;</div>

  <div class="col-md-3">
    <h4>Restlichen Fertigungsschritte</h4>

    <table class="table whitetable">
      <thead>
      <tr>
        <th>&nbsp;</th>
        <th>Nr.</th>
        <th>Bezeichnung</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let obj of steps; let index = index">
        <td>
          <button type="button" class="btn btn-sm btn-link" *ngIf="selected" (click)="stepAdd(obj)">
            <i class="fa fa-chevron-left fa-1x fa-fw"></i>
          </button>
        </td>
        <td>{{ obj.nr }}</td>
        <td>{{ obj.text }}</td>
      </tr>
      </tbody>
    </table>

  </div>
  <div class="col-md-1">&nbsp;</div>

</div>
