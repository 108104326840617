<div class="fileupload-progress">

  <div class="fileupload-bar"></div>

  <div class="fileupload-bar-icon fileupload-bar-end">
    <i class="fa fa-archive"></i>
  </div>

  <div class="fileupload-progress-status">

    <div class="fileupload-status-item" [ngClass]="{'fileupload-status-active': status === 1, 'fileupload-status-check': status > 0}">
      <div class="fileupload-bar-item"></div>
      <div class="timedot fileupload-status-dot"></div>
      <span class="fileupload-status-title">Ablageort</span>
    </div>

    <div class="fileupload-status-item" [ngClass]="{'fileupload-status-active': status === 2, 'fileupload-status-check': status > 1}">
      <div class="fileupload-bar-item"></div>
      <div class="timedot fileupload-status-dot"></div>
      <span class="fileupload-status-title">Zusatzinformationen</span>
    </div>

    <div class="fileupload-status-item" [ngClass]="{'fileupload-status-active': status === 3, 'fileupload-status-check': status > 2}">
      <div class="fileupload-bar-item"></div>
      <div class="timedot fileupload-status-dot"></div>
      <span class="fileupload-status-title">Datei auswählen</span>
    </div>

    <div class="fileupload-status-item" [ngClass]="{'fileupload-status-active': status === 4, 'fileupload-status-check': status > 3}">
      <div class="fileupload-bar-item"></div>
      <div class="timedot fileupload-status-dot"></div>
      <span class="fileupload-status-title">Verarbeitung</span>
    </div>

  </div>
</div>
