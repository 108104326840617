<div class="article-form-item" [formGroup]="form">
  <label class="article-form-item-label" [htmlFor]="modelCheckbox">{{ label }}:</label>
  <div class="article-form-item-data">

    <div class="article-form-data-content row">
      <div class="col-sm-2">
        <input type="checkbox"
               [id]="modelCheckbox"
               [ngClass]="{red_alert: errors[modelCheckbox]}"
               [formControlName]="modelCheckbox"
               (change)="change()">
      </div>
      <div class="col-sm-10" *ngIf="form.value[modelCheckbox] === true">
        <input type="text" class="form-control"
               [id]="modelInput"
               [ngClass]="{red_alert: errors[modelInput]}"
               [formControlName]="modelInput">
      </div>
    </div>

  </div>
</div>
