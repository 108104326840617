import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'Contains'})
export class Contains implements PipeTransform {

  constructor() {
  }

  transform(value: string[], value2: string): boolean {
    const index = value.findIndex(v => v === value2);
    return index >= 0;
  }

}
