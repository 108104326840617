import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'delivery-create-low-inventory-error-modal',
  templateUrl: './delivery-create-low-inventory-error-modal.component.html',
})
export class DeliveryCreateLowInventoryErrorModalComponent {

  constructor(private activeModal: NgbActiveModal) {
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
