import {Injectable} from '@angular/core';
import {SessionStorage} from '../storage/session-storage';
import {LocalStorage} from '../storage/local-storage';
import {HttpClient} from '@angular/common/http';
import {of, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CacheableHttpService {

  constructor(private http: HttpClient,
              private localStorage: LocalStorage,
              private sessionStorage: SessionStorage) {
  }

  public get<T>(cacheKey: string, uri: string, versionString?: string): Observable<T> {
    versionString = versionString ? versionString : 'version';
    const version = this.sessionStorage.get(versionString);
    const data = this.localStorage.getObject<{ data: T, version: string }>(cacheKey);
    if (data && version === data.version) {
      return of(data.data);
    } else {
      return this.http.get<T>(uri).pipe(
        tap(value => this.localStorage.setObject(cacheKey, {data: value, version: version}))
      );
    }
  }

}
