<div class="row">
  <div class="col-lg-6">
    <table class="whitetable table">
      <thead>
      <tr>
        <th>Datum</th>
        <th>Benutzer</th>
        <th class="icon"></th>
        <th class="icon" *ngIf="isAdmin"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let h of list.objects">
        <td [ngClass]="{storzgreen: h.active}">{{h.date_updated|date:'dd.MM.yyyy HH:mm'}}</td>
        <td [ngClass]="{storzgreen: h.active}">{{h.username}}</td>
        <td class="icon" [ngClass]="{storzgreen: h.active}">
          <a class="click" (click)="openPdf(h)"><i class="fa fa-file-pdf-o"></i></a>
        </td>
        <td class="icon" [ngClass]="{storzgreen: h.active}" *ngIf="isAdmin">
          <a class="click" (click)="openHtml(h)"><i class="fa fa-globe"></i></a>
        </td>
      </tr>
      </tbody>
    </table>

    <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
  </div>
</div>
