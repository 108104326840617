import { NgModule } from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {CustomerRatingComponent} from './customer-rating.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    CustomerRatingComponent,
  ],
  exports: [
    CustomerRatingComponent,
  ]
})
export class CustomerRatingModule { }
