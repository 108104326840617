import { Input, OnDestroy, OnInit, Directive } from '@angular/core';
import {ArticleSpecification} from '../../models/article-specification.model';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import Helper from '../../../../helper/helper';
import {ArticleCalculatorService} from '../services/article-calculator.service';
import {GlobalService} from '../../../../core/global.service';
import {debounceTime, tap} from 'rxjs/operators';
import {debug} from '../../../../helper/debug.func';

@Directive()
export abstract class ArticleSharedComponent implements OnInit, OnDestroy {
  @Input() specification: { [key: string]: ArticleSpecification };
  public saveLock = false;
  public options: string[] = [];
  public errors: { [key: string]: any; } = {};
  public form: UntypedFormGroup;
  public formSubscription?: Subscription;
  public init = false;
  public refCount = 0;
  public data = {ML4: 1, ML6: 2, ML8: 3, ML10: 4, ML12: 5, ML14: 6, ML16: 7};
  public automatedChange = false;

  protected abstract articleCalculatorService: ArticleCalculatorService;
  protected abstract fb: UntypedFormBuilder;
  protected abstract globalService: GlobalService;

  protected additionalFields: string[] = [];

  private multiCache: any = {};
  private multiValues = new Set<string>();

  protected additionalDefaultValue(name: string): { value: string | number | undefined, disabled: boolean } {
    return {value: undefined, disabled: true};
  }

  ngOnInit(): void {
    this.globalService.removeEvents();
    this.refCount = 0;
    this.changeSaveLock();
    this.options = this.specification['blocking_notice'].values;
    this.initializeForm();
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
      this.formSubscription = null;
    }
  }

  calculated(name: string): void {
    function isInt(value) {
      return typeof value === 'number' && isFinite(value) && Math.floor(value) === value && !Number.isNaN(value);
    }

    // initalization
    const formData = this.form.value;
    const bs1 = formData.board_size1;
    const bs2 = formData.board_size2;
    const us1 = formData.utilize_size1;
    const us2 = formData.utilize_size2;
    // Needs a explicit conversion
    const utilize1 = parseInt(formData.utilize1, 10);
    const u2 = formData.utilize2;
    const sum = formData.utilize_sum;
    const manual = name === 'utilize2' || name === 'utilize1';
    // return data
    const data = this.articleCalculatorService.utilize(bs1, bs2, us1, us2, utilize1, u2, manual);

    const patchableValue = {};
    if ((utilize1 === 0 || Number.isNaN(utilize1)) && (u2 === '' || u2 === 0)) {
      if (utilize1 > 0) {
        patchableValue['utilize2'] = data.utilize2;
      }
      patchableValue['utilize_sum'] = data.utilize_sum;
    } else if (data.utilize_sum !== parseInt(sum, 10)) {
      const utilize2 = data.utilize2;
      const utilize_sum = data.utilize_sum;

      if (isInt(utilize1) && isInt(utilize2) && isInt(utilize_sum)) {
          if (utilize1 > 0) {
            patchableValue['utilize2'] = utilize2;
          }
          patchableValue['utilize_sum'] = utilize_sum;
      }
    }

    patchableValue['surface'] = this.articleCalculatorService.surface(us1, us2, bs1, bs2, utilize1);
    debug('Article Calculated:', patchableValue);

    // FIXME: does this needs to be emitted or not?
    this.form.patchValue(patchableValue, {emitEvent: false});
  }

  aspectCalculated(): void {
    const aspectRatio = this.articleCalculatorService.aspect(this.form.value.endintensity, this.form.value.drilling);
    this.form.patchValue({aspect_ratio: aspectRatio});
  }

  drillingDensityPerLp() {
    const bs1 = this.articleCalculatorService.sanitizeFloat(this.form.value.board_size1);
    const bs2 = this.articleCalculatorService.sanitizeFloat(this.form.value.board_size2);
    const size = bs1 * bs2;
    const data = this.form.value.drilldensity / size;
    return isFinite(data) ? (data.toFixed(4).replace('.', ',')) : '';
  }

  protected abstract defaultValue(value: ArticleSpecification): { key: string, value: string | number | undefined }[];

  protected articleValue(name: string): any | null | undefined {
    return undefined;
  }

  private initializeForm(): void {
    const group = {};
    Helper.keys(this.specification, (value: ArticleSpecification) => {
      this.defaultValue(value).forEach(obj => {
        group[obj.key] = [obj.value];
      });
      if (!!value.multi) {
        this.multiValues.add(value.multi);
      }
    });
    /** adds additional fields like sds_nr */
    this.additionalFields.forEach(name => {
      group[name] = new UntypedFormControl(this.additionalDefaultValue(name));
    });
    this.form = this.fb.group(group);
    // initalize cache
    this.multiValues.forEach(name => {
      this.multiCache[name] = this.form.value[name];
    });
    this.formSubscription = this.form.valueChanges.pipe(
      tap(() => {
        if (!this.init && !this.automatedChange) {
          this.init = true;
          this.globalService.addEvents();
        }

        this.automatedChange = false;
      }),
      debounceTime(750)
    ).subscribe(() => {
      this.updateAllFields();
    });
  }

  private updateAllFields() {
    this.multiValues.forEach(name => {
      const value = ArticleCalculatorService.executionExtractor(name, this.form.value[name]);
      if (Helper.isInt(value)) {
        this.updateFields(name, Helper.toInt(value));
      }
      this.multiCache[name] = value;
    });
    this.refCount++;
    this.changeSaveLock();
  }

  protected updateFields(name: string, value: number): void {
    // gets all fields from defaultMultiValue
    const oldValue = ArticleCalculatorService.executionExtractor(name, this.multiCache[name]);
    if (!Helper.isInt(oldValue) || value === oldValue) {
      return;
    }
    const defaultValues = this.defaultMultiValue(name);
    defaultValues.forEach(defaultValue => {
      if (value > oldValue) {
        for (let i = (oldValue + 1); i < (value + 1); i++) {
          const realName = defaultValue.key + '_' + i;
          const newValue = this.articleValue(realName) || defaultValue.value;
          this.form.addControl(realName, new UntypedFormControl(newValue), {emitEvent: false});
        }
      } else {
        for (let i = oldValue; i > value; i--) {
          const realName = defaultValue.key + '_' + i;
          this.form.removeControl(realName, {emitEvent: false});
        }
      }
    });
  }

  private defaultMultiValue(multi: string): { key: string, value: string | number | undefined }[] {
    const values = [];
    Helper.keys(this.specification, (value) => {
      if (value.multi === multi) {
        values.push({key: value.name, value: value.default});
      }
    });
    return values;
  }

  protected changeSaveLock(): void {
    this.saveLock = this.refCount === 0;
  }

  get specNamesPlatingCore(): string[] {
    return this.specNamesInGroup('Galvanik Kern');
  }

  get specNamesPlatingVia(): string[] {
    return this.specNamesInGroup('Galvanik Via');
  }

  private specNamesInGroup(group: string): string[] {
    const specNames = [];
    Helper.keys(this.specification, (spec: ArticleSpecification) => {
      if (spec.group === group) {
        specNames.push(spec.name);
      }
    });
    return specNames;
  }

  get viaHandlingSectionErrors(): string | null {
    switch (this.form.value.via_handling) {
      case 'Plugged Via (IPC-4761 III-a)':
        return (!this.form.value.via_ls && !this.form.value.via_ds) ?
          'Via Handling: Keine Lochfüller Seite(n) ausgewählt' :
          null;
      case 'Filled and Covered Via (IPC-4761 VI)':
        return (!this.form.value.via_filling_resin && !this.form.value.via_filling_lacquer) ?
          'Via Handling: Keine Optionen ausgewählt' :
          null;
      default:
        return null;
    }
  }

  get validationErrors(): string[] {
    return [this.viaHandlingSectionErrors].filter(e => !!e);
  }

  setAutomatedChange(automatedChange: boolean): void {
    this.automatedChange = automatedChange;
  }
}
