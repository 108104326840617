import Axios, {AxiosInstance, AxiosResponse} from 'axios';

export class InvalidStatusCode extends Error {
  constructor(public error: string) {
    super();
  }
}

export class StatusError extends Error {
  constructor(public payload: AxiosResponse, public error: string) {
    super();
  }
}

class AxiosHttpClientClass {
  private readonly AxiosInstance: AxiosInstance;
  private readonly baseHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
  };

  private BuildInstance(): AxiosInstance {
    /* eslint-disable max-len */
    /**
     * axios also translates our XSRF-TOKEN to a X-XSRF-TOKEN request
     * See: https://docs.microsoft.com/aspnet/core/security/anti-request-forgery?view=aspnetcore-5.0#javascript-ajax-and-spas
     */
    /* eslint-enable max-len */
    const axios = Axios.create();
    axios.interceptors.response.use(
      value => {
        return value;
      },
      error => {
        return Promise.reject(error);
      },
    );
    return axios;
  }

  constructor() {
    this.AxiosInstance = this.BuildInstance();
  }

  async getPlain(uri: string, params: any | null = null): Promise<string | null> {
    const response = await this.AxiosInstance.get<string>(uri, {params: params, headers: this.baseHeaders});
    if (response.status <= 200 && response.status >= 299) {
      throw new StatusError(response, 'StatusCodeError');
    }

    return response.data;
  }

  async get<T>(uri: string, params: any | null = null, withPlain: boolean = false): Promise<T> {
    const response = await this.AxiosInstance.get<T>(uri, {
      params: params,
      headers: this.baseHeaders,
    });
    if (response.status <= 200 && response.status >= 299) {
      throw new StatusError(response, 'StatusCodeError');
    }

    if (response.status === 204) {
      throw new StatusError(response, 'Invalid204Response');
    }

    return response.data;
  }

  async delete(uri: string, headers: any | null = null): Promise<void> {
    const response = await this.AxiosInstance.delete(
      uri,
      {
        headers: {...this.baseHeaders, ...headers},
      },
    );
    if (response.status <= 200 && response.status >= 299) {
      throw new StatusError(response, 'StatusCodeError');
    }
  }

  async postFile<T>(uri: string, body: FormData): Promise<T> {
    const response = await this.AxiosInstance.post<T>(
      uri,
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
    if (response.status <= 200 && response.status >= 299) {
      throw new StatusError(response, 'StatusCodeError');
    }

    return response.data;
  }

  async putNullable<T>(uri: string, body: any, headers: any | null = null): Promise<T | null> {
    const response = await this.AxiosInstance.put<T>(
      uri,
      body,
      {headers: {...this.baseHeaders, ...headers}},
    );
    if (response.status <= 200 && response.status >= 299) {
      throw new StatusError(response, 'StatusCodeError');
    }

    if (response.status === 204) {
      return null;
    }

    return response.data;
  }

  async postNullable<T>(uri: string, body: any, headers: any | null = null): Promise<T | null> {
    const response = await this.AxiosInstance.post<T>(
      uri,
      body,
      {
        headers: {...this.baseHeaders, ...headers},
      },
    );
    if (response.status <= 200 && response.status >= 299) {
      throw new StatusError(response, 'StatusCodeError');
    }

    if (response.status === 204) {
      return null;
    }

    return response.data;
  }

  async post<T>(uri: string, body: any, headers: any | null = null): Promise<T> {
    const data = await this.postNullable<T>(uri, body, headers);
    if (data === null) {
      throw new InvalidStatusCode('invalid 204 status code');
    }

    return data;
  }

  async put<T>(uri: string, body: any, headers: any | null = null): Promise<T> {
    const data = await this.putNullable<T>(uri, body, headers);
    if (data === null) {
      throw new InvalidStatusCode('invalid 204 status code');
    }

    return data;
  }
}

export class AxiosHttpClientBuilder {
  public static Create(): AxiosHttpClientClass {
    return new AxiosHttpClientClass();
  }
}

export const AxiosHttpClient: AxiosHttpClientClass = AxiosHttpClientBuilder.Create();
