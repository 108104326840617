import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {
  OrderHistoryData,
  OrderItemForm,
  OrderListData, WorkflowNotesForm,
  WorkflowOrder,
  WorkflowOrderForm,
  WorkflowOrderView
} from '../order-request/order-request.models';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ListService} from '../../../common/search/search.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {OrderRequest, OrderRequestLists} from '../request/workflow-request.model';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class WorkflowOrderRequestService implements ListService<OrderListData> {
  private uriV4 = environment.apiv4uri + 'order/';

  constructor(private http: HttpClient) {
  }

  create(id: number, form: WorkflowOrderForm): Observable<OrderRequestLists> {
    return this.http.post<{ requests: OrderRequest[], orders: WorkflowOrder[] }>(
      '/api/v1/order/order/' + id + '/', form
    );
  }

  edit(id: number, index: number, form: OrderItemForm): Observable<{ requests: OrderRequest[], orders: WorkflowOrder[] }> {
    return this.http.put<{ requests: OrderRequest[], orders: WorkflowOrder[] }>(
      '/api/v1/order/order/' + id + '/edit/' + index + '/',
      form
    );
  }

  byId(id: number, index: number): Observable<OrderListData> {
    return this.http.get<EnvisiaObject<OrderListData>>('/api/v1/order/order/' + id + '/idx/' + index + '/').pipe(
      map(val => val.object)
    );
  }

  savePosition(id: number, index: number, form: any): Observable<any> {
    return this.http.put<any>(this.uriV4 + id + '/position/' + index + '/', form);
  }

  deletePosition(id: number): Observable<number> {
    return this.http.delete<number>(this.uriV4 + 'position/' + id);
  }

  resetStatus(id: number, status: number): Observable<any> {
    return this.http.put<any>(this.uriV4 + 'position/' + id + '/reset', {status: status});
  }

  history(id: number, index: number): Observable<OrderHistoryData> {
    return this.http.get<EnvisiaObject<OrderHistoryData>>('/api/v1/order/history/' + id + '/' + index + '/').pipe(
      map(val => val.object)
    );
  }

  list(query?: any): Observable<Lister<OrderListData>> {
    return this.http.get<Lister<OrderListData>>(
      '/api/v1/order/order/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  count(oa: string): Observable<{ count: number }> {
    return this.http.get<{ count: number }>('/api/v1/order/order/count/' + oa + '/');
  }

  cancelPosition(id: number, index: number): Observable<{ state: number }> {
    return this.http.delete<{ state: number }>('/api/v1/order/' + id + '/cancel/' + index + '/');
  }

  removePosition(id: number, index: number): Observable<{ state: number }> {
    return this.http.delete<{ state: number }>('/api/v1/order/' + id + '/delete/' + index + '/');
  }

  workflowList(workflowId: number): Observable<WorkflowOrderView[]> {
    return this.http.get<{ objects: WorkflowOrderView[] }>(
      '/api/v1/order/workflow/' + workflowId + '/'
    ).pipe(map(val => val.objects));
  }

  positions(workflowId: number): Observable<{ quantity: number, lose: number }[]> {
    return this.http.get<EnvisiaObjects<{ quantity: number, lose: number }>>(
      '/api/v1/order/positions/' + workflowId + '/'
    ).pipe(map(val => val.objects));
  }

  editNotes(id: number, form: WorkflowNotesForm): Observable<any> {
    return this.http.put<any>('/api/v1/order/request/' + id + '/notes/', form);
  }
}
