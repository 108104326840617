import {Directive} from '@angular/core';
import {
  ArticleMultilayerPlanModel,
} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.models';
import {ArticleSpecification} from '../../envisia/article/models/article-specification.model';

@Directive()
export abstract class MultilayerPlanHolderAbstractComponentDirective {
  model: ArticleMultilayerPlanModel;
  specification: { [key: string]: ArticleSpecification };

  saveBtnLocked = false;
  saveBtnPressed = 0;
  changes = 0; // only needed to ensure that angular rerenders react components correctly

  saveBtnPressedSet(): void {
    this.saveBtnPressed++;
  }

  saveBtnLockedFn(saveBtnLocked: boolean): void {
    this.saveBtnLocked = saveBtnLocked;
    this.changes++;
  }

  modelChanged(model: ArticleMultilayerPlanModel): void {
    this.model = model;
  }
}
