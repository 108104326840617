import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FinderSearchService} from '../../finder-search.service';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {Category} from '../../finder.models';
import {noop} from '../../../../helper/noop';
import {FinderCategoriesData} from '../finder-upload.models';

@Component({
  selector: 'finder-upload-category',
  templateUrl: './finder-upload-category.component.html',
})
export class FinderUploadCategoryComponent implements OnInit {
  @Input() initialData: FinderCategoriesData;
  @Input() categories: Category[];
  @Output() forwardEmitter = new EventEmitter<{ selected: Set<Category>, typ: 'archive' | 'process' }>();
  // @Input() searchType: 'customer' | 'article' | 'workflow';
  typ: 'archive' | 'process';
  selected: Set<Category>;
  blocked = false;

  constructor(private finderSearchService: FinderSearchService) {
  }

  ngOnInit(): void {
    this.selected = this.initialData.selected;
    this.typ = this.initialData.typ;
  }

  getProcesses() {
    this.finderSearchService.process().subscribe(response => {
      this.selected = new Set<Category>();
      this.typ = 'process';
      this.categories = response;
    }, ignoreRejection);
  }

  getCategories() {
    this.finderSearchService.category().subscribe(response => {
      this.selected = new Set<Category>();
      this.typ = 'archive';
      this.categories = response;
    }, ignoreRejection);
  }

  add(data) {
    this.selected.add(data);
  }

  remove(data) {
    this.selected.delete(data);
  }

  isSelected(data) {
    return this.selected.has(data);
  }

  generateClass(typ: 'archive' | 'process'): string {
    if (this.typ === typ) {
      return 'btn-primary';
    } else {
      return 'btn-default';
    }
  }

  next(category: Category | null): void {
    if (this.blocked) {
      return;
    }

    this.blocked = true;
    let selected = this.selected;
    if (category !== null) {
      selected = new Set<Category>([category]);
    }

    this.forwardEmitter.emit({selected: selected, typ: this.typ});
  }

  trackByFn(index: number, item: Category) {
    noop(this, index);
    return item.id;
  }

}
