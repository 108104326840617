import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {MaterialEditNavigationComponent} from './material-edit-navigation.component';
import {UIRouterModule} from '@uirouter/angular';

@NgModule({
  imports: [
    UIRouterModule,
    SharedModule,
  ],
  declarations: [
    MaterialEditNavigationComponent,
  ],
  exports: [
    MaterialEditNavigationComponent,
  ],
})
export class MaterialNavigationModule {
}
