<div class="modal-header">
  <h3 class="modal-title">Achtung Liefertermin prüfen!</h3>
</div>

<div class="modal-body">
  Bitte prüfen Sie die Liefertermine der ausgewählten Abrufe.
  Die Produktionszeit der Auswahl reicht für die Standard Produktionszeit nicht aus.

  <div>&nbsp;</div>

  <table class="table">
    <thead>
    <tr>
      <th colspan="3">Fehlerhafte Abrufe</th>
    </tr>
    <tr>
      <th>Abruf</th>
      <th>AT</th>
      <th>Eildienst</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let error of errors">
      <td>{{ error.fetch }}</td>
      <td>{{ error.at }}</td>
      <td><i *ngIf="error.express" class="fa fa-check"></i></td>
    </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <button class="btn btn-default pull-left" type="button" (click)="close()">
    Abbrechen
  </button>


  <loading-button clazz="btn btn-success pull-right" (loadingSave)="force($event)">
    Auft.-Best. trotzdem erstellen
  </loading-button>
</div>
