import {AfterViewInit, Directive, DoCheck, ElementRef, EventEmitter, Input, Output} from '@angular/core';

@Directive({selector: '[autofocus]'})
export class EvFocusDirective implements AfterViewInit, DoCheck {
  @Input('autofocus') doFocus = true;
  @Output('autofocus-reset') resetFocus = new EventEmitter<boolean>();
  private lastVisible = false;
  private initialised = false;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.initialised = true;
    this.ngDoCheck();
  }

  ngDoCheck(): void {
    if (!this.initialised) {
      return;
    }
    if (!this.doFocus) {
      return;
    }
    const visible = !!this.el.nativeElement.offsetParent;
    if (visible && !this.lastVisible) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 1);
    }
    this.lastVisible = visible;
  }

}
