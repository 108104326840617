<nav>
  <ul class="pager"> <!-- bootstrap 4 add pagination and remove pager -->
    <li class="page-item">
      <a [href]="pageUrl(page - 1)" *ngIf="page > 1" (click)="gotoPage($event, 'prev')" class="page-link">
        Zurück
      </a>
      <span *ngIf="!(page > 1)" class="page-link disabled">Zurück</span>
    </li>
    <li class="page-item">
      <span class="page-link disabled">
        Seite {{ page }} von {{ totalPages }}
      </span>
    </li>
    <li class="page-item">
      <a [href]="pageUrl(page + 1)" *ngIf="pages > page" (click)="gotoPage($event, 'next')" class="page-link">
        Weiter
      </a>
      <span *ngIf="!(pages > page)" class="page-link disabled">Weiter</span>
    </li>
  </ul>
</nav>
