<div class="modal-header">
  <h3>Produktionsvorlage editieren - {{ data.name }}</h3>
</div>

<form class="form-horizontal" [formGroup]="editForm">
  <div class="modal-body">

    <div class="form-group">
      <label for="template-description" class="col-sm-2 control-label">Beschreibung</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="template-description" formControlName="description"
               [ngClass]="{red_alert: errors['obj.description']}">
      </div>
    </div>

    <div class="form-group">
      <label for="template-expression" class="col-sm-2 control-label">Bedingung *</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="template-expression" formControlName="expression"
               [ngClass]="{red_alert: errors['obj.expression']}">

        <div>&nbsp;</div>

        <span *ngIf="errors['obj.expression']" class="red_alert">{{ errors['obj.expression'][0].msg }}</span>
      </div>

    </div>

    <div class="form-group">
      <label for="template-ordering" class="col-sm-2 control-label">Priorität / Reihenfolge</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="template-ordering" formControlName="ordering"
               [ngClass]="{red_alert: errors['obj.ordering']}">
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-success pull-right" (click)="edit()"
            [disabled]="editForm.pristine || !editForm.valid">
      Speichern
    </button>
  </div>
</form>
