import {AxiosHttpClient} from '../../../auth/axios-http-client';
import {ArticleMultilayerPlan, ArticleMultilayerPlanModel} from './article-multilayer-plan.models';
import {environment} from '../../../../../environments/environment';

export class ArticleMultilayerPlanAxiosService {
  private static uri = (oaNr: string) => {
    return (`${environment.apiv4uri}article/${oaNr}/multilayerplan`);
  };

  public static get(oaNr: string) {
    return AxiosHttpClient.get<ArticleMultilayerPlan>(this.uri(oaNr));
  }

  public static create(oaNr: string, form: ArticleMultilayerPlanModel) {
    return AxiosHttpClient.post<ArticleMultilayerPlan>(this.uri(oaNr), form);
  }

  public static update(oaNr: string, form: ArticleMultilayerPlanModel) {
    return AxiosHttpClient.put<ArticleMultilayerPlan>(this.uri(oaNr), form);
  }

  public static bewitHtml(id: number) {
    return AxiosHttpClient.post<{ object: string }>(`${environment.apiv4uri}multilayerplan/html/${id}`, {});
  }

  public static bewitPdf(id: number) {
    return AxiosHttpClient.post<{ object: string }>(`${environment.apiv4uri}multilayerplan/pdf/${id}`, {});
  }
}
