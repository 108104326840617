<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'confirmed'">
    <div class="order-modal-box-history" *ngIf="history?.confirmed">
      <div *ngFor="let confirmed of (history['confirmed'])" class="order-modal-box-history-item">
        <div class="order-modal-box-history-item-head">
          {{ confirmed.date_created|date: "dd.MM.yyyy HH:mm"}} - {{ confirmed.username}}
        </div>
        <div class="order-modal-box-history-item-content">
          <order-box-history-confirmed type="confirmed" [history]="confirmed.data"></order-box-history-confirmed>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'conveyed'">
    <div *ngIf="history?.conveyed" class="order-modal-box-history">
      <div *ngFor="let conveyed of history['conveyed']" class="order-modal-box-history-item">
        <div class="order-modal-box-history-item-head">
          {{ conveyed.date_created|date: "dd.MM.yyyy HH:mm" }} - {{ conveyed.username}}
        </div>
        <div class="order-modal-box-history-item-content">
          <div class="order-modal-box-history-item-content">
            <order-box-history-conveyed type="conveyed" [history]="conveyed.data"></order-box-history-conveyed>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'received'">
    <div *ngIf="history?.received" class="order-modal-box-history">
      <div *ngFor="let received of history['received']" class="order-modal-box-history-item">
        <div class="order-modal-box-history-item-head">
          {{ received.date_created|date: "dd.MM.yyyy HH:mm" }} - {{ received.username}}
        </div>
        <div class="order-modal-box-history-item-content">
          <order-box-history-received type="received" [history]="received.data"></order-box-history-received>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'reviewed'">
    <div *ngIf="history?.reviewed" class="order-modal-box-history">
      <div *ngFor="let reviewed of history['reviewed']" class="order-modal-box-history-item">
        <div class="order-modal-box-history-item-head">
          {{ reviewed.date_created|date: "dd.MM.yyyy HH:mm"}} - {{ reviewed.username}}
        </div>
        <div class="order-modal-box-history-item-content">
          <order-box-history-reviewed type="reviewed" [history]="reviewed.data"></order-box-history-reviewed>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'expensed'">
    <div *ngIf="history?.expensed" class="order-modal-box-history">
      <div *ngFor="let expensed of history['expensed']" class="order-modal-box-history-item">
        <div class="order-modal-box-history-item-head">
          {{ expensed.date_created|date: "dd.MM.yyyy HH:mm"}} - {{ expensed.username}}
        </div>
        <div class="order-modal-box-history-item-content">
          <order-box-history-expensed type="expensed" [history]="expensed.data"></order-box-history-expensed>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
