import {Component, Input, OnInit} from '@angular/core';
import {OrderRequest, OrderRequestView, RequestListData} from '../../request/workflow-request.model';
import {ErpProducer} from '../../../producer/producer.models';
import {OrderListData, WorkflowOrder} from '../order-request.models';
import {WorkflowOrderRequestService} from '../../workflow-services/workflow-order-request.service';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {Lister} from '../../../../common/wrapper.models';
import {PaginationQuery} from '../../../../common/pagination/pagination.model';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {WorkflowRequestService} from '../../workflow-services/workflow-request.service';
import {Observable} from 'rxjs';
import {CustomerCreditWorthiness} from '../../../customer/credit-worthiness/customer-credit-worthiness.models';

@Component({
  selector: 'workflow-order-request-main',
  templateUrl: './workflow-order-request-main.component.html',
})
export class WorkflowOrderRequestMainComponent implements OnInit {
  @Input() data: OrderRequestView;
  @Input() producerList: ErpProducer[];
  @Input() workflowPositions: { quantity: number, lose: number }[];
  @Input() customerCredit: CustomerCreditWorthiness;
  customerProducerList: ErpProducer[];

  queryArticle: PaginationQuery;
  queryWorkflow: PaginationQuery;

  requests: OrderRequest[];
  requestData: LoadingWrapper<Lister<RequestListData>>;

  orders: WorkflowOrder[];
  orderData: LoadingWrapper<Lister<OrderListData>>;

  orderToRequestData: { requestNr: number, producer: string } | undefined | null = null;

  constructor(private workflowOrderRequestService: WorkflowOrderRequestService,
              private requestService: WorkflowRequestService) {
  }

  ngOnInit(): void {
    if (this.data.customer.producers.length > 0) {
      this.customerProducerList = this.producerList.filter(p => this.data.customer.producers.includes(p.name));
    } else {
      this.customerProducerList = this.producerList;
    }

    this.requests = this.data.requests;
    this.orders = this.data.orders;

    this.queryArticle = {oa_nr: this.data.article.oa_nr};
    this.queryWorkflow = {oa_nr: this.data.article.oa_nr, workflow_id: this.data.workflow.id};
    this.updateOrderList();
    this.updateRequestList();
  }

  requestToOrder(data: { requestNr: number, producer: string } | null): void {
    this.orderToRequestData = data;
  }

  updateRequestNote(note: string): void {
    this.data.workflow.request_hint = note;
    this.updateNotes();
  }

  updateOrderNote(note: string): void {
    this.data.workflow.order_hint = note;
    this.updateNotes();
  }

  createRequest(requests: OrderRequest[]): void {
    requests.reverse().forEach((obj) => this.requests.unshift(obj));
  }

  private updateNotes(): void {
    const form = {order: this.data.workflow.order_hint, request: this.data.workflow.request_hint};
    this.workflowOrderRequestService.editNotes(this.data.workflow.id, form).subscribe(ignoreRejection, ignoreRejection);
  }

  updateRequests(): void {
    this.updateRequestList();
    this.requestService.list(this.queryWorkflow).subscribe(wo => {
      this.requests = wo.objects.map(v => v.order_request);
    }, ignoreRejection);
  }

  updateRequestList(): void {
    this.requestData = new LoadingWrapper<Lister<RequestListData>>(
      new Observable<Lister<RequestListData>>(subscriber => {
        subscriber.next({objects: [], page: 0, count: 0, pages: 0, size: 0});
        subscriber.complete();
      })
    );
    this.requestData = new LoadingWrapper<Lister<RequestListData>>(
      this.requestService.list(this.queryArticle)
    );
  }

  updateOrders(): void {
    this.updateOrderList();
    this.workflowOrderRequestService.list(this.queryWorkflow).subscribe(wo => {
      this.orders = wo.objects.map(v => v.order);
    }, ignoreRejection);
  }

  updateOrderList(): void {
    this.orderData = new LoadingWrapper<Lister<OrderListData>>(
      new Observable<Lister<OrderListData>>(subscriber => {
        subscriber.next({objects: [], page: 0, count: 0, pages: 0, size: 0});
        subscriber.complete();
      })
    );
    this.orderData = new LoadingWrapper<Lister<OrderListData>>(
      this.workflowOrderRequestService.list(this.queryArticle)
    );
  }
}
