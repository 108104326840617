import * as React from 'react';
import {ReactNode} from 'react';
import {ErpRouterContext} from './erp-react-router-context';
import {RawParams, StateOrName} from '@uirouter/angular';
import {TransitionOptions} from '@uirouter/core';

interface Props {
  children: ReactNode;
  routeAngular?: (to: StateOrName, params?: RawParams, options?: TransitionOptions) => void;
}

export function ErpReactRouterWrapper(props: Props) {
  return <ErpRouterContext.Provider value={{routeAngular: props.routeAngular}}>
    {props.children}
  </ErpRouterContext.Provider>;
}
