import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DateRange} from './date-range.models';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styles: ['.range-extra-label { padding: 3px; margin-right: 5px; }']
})
export class DateRangeComponent implements OnInit, OnDestroy {
  @Input() start?: string | Date;
  @Input() end?: string | Date;
  @Input() leftIcon: string | null;
  @Input() cssClass?: string;
  @Input() label?: string;
  @Output() update = new EventEmitter<DateRange>();
  dateRangeForm: UntypedFormGroup;
  icon: string;
  subscription?: Subscription;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.icon = this.leftIcon ? this.leftIcon : 'fa-filter';
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  trash(): void {
    this.start = null;
    this.end = null;
    this.ngOnDestroy();
    this.initForm();
    this.change();
  }

  change(): void {
    this.update.next({start: this.start, end: this.end});
  }

  private initForm(): void {
    this.dateRangeForm = this.fb.group({
      'start': [this.start],
      'end': [this.end]
    });
    this.subscription = this.dateRangeForm.valueChanges.subscribe(values => {
      this.start = values.start;
      this.end = values.end;
      this.change();
    }, () => {
    });
  }

}
