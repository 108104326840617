import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Article, ArticleToolingCost, UpdateForm} from '../models/article.models';
import {EnvisiaObject} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';

export declare type sdsType = 'de' | 'cn' | 'none';

@Injectable({providedIn: 'root'})
export class ArticleV2Service {

  private uri = '/article/v2/';

  constructor(private http: HttpClient) {
  }

  update(oaNr: string, form: UpdateForm): Observable<Article> {
    return this.http.put<EnvisiaObject<Article>>(this.uri + 'article/' + oaNr + '/', form).pipe(
      map(obj => obj.object)
    );
  }

  updateCustomer(oa: string, customer: string): Observable<Article> {
    return this.http.put<EnvisiaObject<Article>>(
      this.uri + 'customer/' + oa + '/',
      {customer: customer}
    ).pipe(map(value => value.object));
  }

  generateSds(oaNr: string, typ: sdsType): Observable<Article> {
    return this.http.put<EnvisiaObject<Article>>(this.uri + 'article/sds/' + oaNr + '/' + typ + '/', {}).pipe(
      map(obj => obj.object)
    );
  }

  createSister(oaNr: string): Observable<Article> {
    return this.http.put<EnvisiaObject<Article>>(this.uri + 'article/sister/' + oaNr + '/', {}).pipe(
      map(obj => obj.object)
    );
  }

  toolingCost(article: string): Observable<ArticleToolingCost | null> {
    return this.http.get<EnvisiaObject<ArticleToolingCost | null>>(
      '/article/v2/tooling/cost/' + article + '/'
    ).pipe(
      map(value => value.object)
    );
  }

  updateState(oa: string, data: 'green' | 'orange' | 'red'): Observable<Article> {
    return this.http.post<EnvisiaObject<Article>>(this.uri + 'article/state/' + oa + '/', {state: data}).pipe(
      map(obj => obj.object)
    );
  }

}

