import {Component, Input, OnInit} from '@angular/core';
import {
  MaterialArticle,
  MaterialTransaction,
  MaterialVariation,
  transactionTypes
} from '../../material-management.model';
import {PaginationBaseComponent} from '../../../../common/pagination/pagination.component';
import {MaterialInventoryTransactionListService} from '../material-inventory-transaction-list.service';
import {Lister} from '../../../../common/wrapper.models';

@Component({
  selector: 'material-inventory-transaction-list',
  templateUrl: './material-inventory-transaction-list.component.html',
})
export class MaterialInventoryTransactionListComponent
  extends PaginationBaseComponent<MaterialInventoryTransactionListService, MaterialTransaction>
  implements OnInit {

  @Input() currentArticle: MaterialArticle;
  @Input() currentVariation: MaterialVariation;
  @Input() currentTransactionList: Lister<MaterialTransaction>;

  transactionTypes: string[] = transactionTypes;

  constructor(protected service: MaterialInventoryTransactionListService) {
    super();
  }

  ngOnInit(): void {
    this.query({variation_id: this.currentVariation.id});
    this.list = this.currentTransactionList;
  }

  quantityColor(n: number): any {
    return n < 0 ? 'inventory-red' : 'inventory-green';
  }

  quantityOperator(n: number): string {
    return (n >= 0 ? '+' : '-');
  }

  quantityString(n: number): string {
    return Math.abs(n).toLocaleString();
  }

}
