import {DependencyList, useEffect} from 'react';

type AsyncEffectCallback = (didCancel: boolean) =>
  | void
  | (() => void | undefined)
  | Promise<void>
  | (() => Promise<void | undefined>);

export const useFetchEffect = (
  effect: AsyncEffectCallback,
  deps?: DependencyList,
) => {
  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      await effect(didCancel);
    }

    fetchData();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, deps);
};
