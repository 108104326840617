<div class="row"><div class="col-sm-12">&nbsp;</div></div>
<div class="row" *ngIf="error">
  <div class="col-sm-12 red_alert" style="clear: both" [ngSwitch]="error.error_type">

    <ng-container *ngSwitchCase="'GroupNotFoundError'">
      <h3>Die ausgewählte Materialgruppe konnte nicht gefunden werden</h3>
    </ng-container>

    <ng-container *ngSwitchCase="'ArticleError'">
      <ng-container [ngSwitch]="getArticleError().error_type">

        <ng-container *ngSwitchCase="'ArticleFieldValidationError'">
          <h3>Ein Feld im Artikel ist fehlerhaft</h3>
        </ng-container>

        <ng-container *ngSwitchCase="'ArticleNotFoundError'">
          <h3>Original Artikel konnte nicht gefunden werden</h3>
        </ng-container>

        <ng-container *ngSwitchCase="'ArticleExistsError'">
          <h3>Es existiert bereits ein Artikel mit diesem Artikelnamen</h3>
          <a uiSref="a.material.article.update" [uiParams]="{article: getExistingArticle().id}">
            {{getExistingArticle().article_id}} {{getExistingArticle().name}}
          </a>
        </ng-container>

      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'VariationsError'">
      <h3>{{ getVariationErrors().length > 1 ? 'Mehrere Variationen sind' : 'Eine Variation ist' }} fehlerhaft:</h3>

      <ng-container *ngFor="let variationError of getVariationErrors()">
        <div [ngSwitch]="variationError.error_type">
          <ng-container *ngSwitchCase="'VariationNotFoundError'">
            Die bereits vorhandene Variation in Zeile {{variationError.index+1}} konnte nicht gefunden werden.
          </ng-container>

          <ng-container *ngSwitchCase="'VariationExistsError'">
            Die Variation in Zeile {{variationError.index+1}} existiert bereits.
          </ng-container>

          <ng-container *ngSwitchCase="'VariationUnitError'">
            Die Variation in Zeile {{variationError.index+1}} Hat eine fehlerhafte Einheit.
          </ng-container>

          <ng-container *ngSwitchCase="'VariationDuplicatesError'">
            Die Variation in Zeile {{variationError.index + 1}}
            {{variationError.data ? 'existiert bereits in Zeile ' + (variationError.data + 1) : 'existiert mehrfach'}}.
          </ng-container>

          <ng-container *ngSwitchCase="'VariationPriceParseError'">
            Das Feld "Preis" in Zeile {{variationError.index+1}} ist nicht korrekt Formatiert.
            Nur Zahlen, Punkte und Kommas sind erlaubt.
          </ng-container>

          <ng-container *ngSwitchCase="'VariationFieldsError'">
            Die Variationsfelder in Zeile {{variationError.index+1}} sind nicht korrekt ausgefüllt.
          </ng-container>

        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <h3>Unbekannter Fehler beim &Uuml;bertragen des Formulars</h3>
    </ng-container>

  </div>
</div>
