<div class="row">

  <div class="col-md-12">
    <h1>Automatischer Rechnungslauf</h1>
  </div>

  <div class="col-md-6">
    <h2>Rechnungslauf starten</h2>

    <form class="form-horizontal" [formGroup]="form">

      <div class="form-group">
        <date-range cssClass="no-margin-fg" [start]="null" [end]="null" (update)="updateDateRange($event)"></date-range>
      </div>

      <div class="form-group">
        <label for="date" class="col-sm-2 control-label">RG-Datum</label>
        <div class="col-sm-10">
          <ev-datepicker id="date" formControlName="date" class="form-control datepicker-width-120"></ev-datepicker>
        </div>
      </div>

      <div class="form-group">
        <label for="sending_instant" class="col-sm-2 control-label">RGs automatisch nach Erstellung übermitteln</label>
        <div class="col-sm-10">
          <input type="checkbox" id="sending_instant" style="width:40px;" formControlName="sending_instant" class="form-control" />
        </div>
      </div>

      <h3>Ausgewählte Lieferscheine: {{ count }}</h3>

      <button type="button" class="btn btn-success" (click)="create()" [disabled]="disabled || form.invalid">
        Erstellen
      </button>

    </form>
  </div>

  <div class="col-md-6">

    <ul ngbNav #nav="ngbNav" class="nav-tabs">

      <li ngbNavItem class="nav-item">
        <a ngbNavLink>Fehlerhafte Aufträge</a>
        <ng-template ngbNavContent>
          <button (click)="refresh('error')" title="Liste aktualisieren" class="btn btn-link">
            Aktualisieren <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>

          <table class="table whitetable">
            <thead>
            <tr class="filterrow">
              <th>Lieferschein-ID</th>
              <th>Benutzer</th>
              <th>Fehler</th>
              <th>erneut versuchen</th>
              <th>Ausblenden</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let obj of errors; let index = index">
              <td>
                <ev-file-button [objId]="obj.delivery_id" type="delivery">
                  {{ obj.delivery_id }}
                </ev-file-button>
              </td>
              <td>{{ obj.username }}</td>
              <td>
                <ng-container [ngSwitch]="obj.error" *ngIf="obj.status!=='working'">
                  <!-- errors from sending -->
                  <ng-container *ngSwitchCase="'ENVISIA2'">Fehler beim versenden per Post</ng-container>
                  <ng-container *ngSwitchCase="'ENVISIA101'">Fehler beim versenden per E-Mail</ng-container>
                  <!-- errors from automation -->
                  <ng-container *ngSwitchCase="'delivery not found'">Lieferschein existiert nicht</ng-container>
                  <ng-container *ngSwitchCase="'delivery storno'">Lieferschein wurde storniert</ng-container>
                  <ng-container *ngSwitchCase="'delivery locked'">Lieferschein ist gesperrt</ng-container>
                  <ng-container *ngSwitchCase="'invoice not found'">Rechnung existiert nicht</ng-container>
                  <ng-container *ngSwitchCase="'invoice storno'">Rechnung wurde storniert</ng-container>
                  <ng-container *ngSwitchCase="'invoice creating error'">Fehler beim erstellen der Rechnung</ng-container>
                  <ng-container *ngSwitchCase="'user not set'">Benutzer fehlt oder hat keine Berechtigung</ng-container>
                  <ng-container *ngSwitchCase="'sending maximum reruns'">Konnte nicht versendet werden</ng-container>
                  <!-- errors from scala invoice creation -->
                  <ng-container *ngSwitchCase="'position'">Fehler in Position</ng-container>
                  <ng-container *ngSwitchCase="'vat'">Fehlerhafte USt.-ID</ng-container>
                  <ng-container *ngSwitchDefault>Unbekannter Fehler</ng-container>
                </ng-container>

                <ng-container *ngIf="obj.status==='working'">
                  Auftrag stehengeblieben
                </ng-container>
              </td>
              <td>
                <button type="button" class="btn btn-link btn-sm" (click)="restart(index, obj)">
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </td>
              <td>
                <button type="button" class="btn btn-link btn-sm" (click)="hide(index, obj)">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </li>

      <li ngbNavItem class="nav-item">
        <a ngbNavLink>Aufträge in Bearbeitung</a>
        <ng-template ngbNavContent>

          <button (click)="refresh('working')" title="Liste aktualisieren" class="btn btn-link">
            Aktualisieren <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>

          <table class="table whitetable">
            <thead>
            <tr class="filterrow">
              <th>Lieferschein-ID</th>
              <th>Benutzer</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let obj of working; let index = index">
              <td>
                <ev-file-button [objId]="obj.delivery_id" type="delivery">
                  {{ obj.delivery_id }}
                </ev-file-button>
              </td>
              <td>{{ obj.username }}</td>
              <td [ngSwitch]="obj.status">
                <ng-container *ngSwitchCase="'created'">Erstellt</ng-container>
                <ng-container *ngSwitchCase="'working'">In Bearbeitung</ng-container>
                <ng-container *ngSwitchCase="'created_invoice'">Rechnung erstellt</ng-container>
                <ng-container *ngSwitchCase="'sending_queued'">Warteschlange Versand</ng-container>
                <ng-container *ngSwitchCase="'sending'">Wird versendet</ng-container>
                <ng-container *ngSwitchCase="'sending_done'">Übermittelt</ng-container>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </li>

    </ul>

    <div [ngbNavOutlet]="nav"></div>
  </div>


</div>
