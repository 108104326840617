import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BigCreateDeliveryWorkflow} from '../../models/workflow-create.models';
import {BigWorkflow} from '../../models/workflow.models';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {DeliveryListData} from '../../models/delivery.models';
import {PdfHistoryModalService} from '../../../history/pdf-history-modal.service';
import {DeliveryService} from '../../workflow-services/delivery.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {DeliveryStornoModalService} from '../services/delivery-storno-modal.service';
import {WorkflowService} from '../../workflow-services/workflow.service';
import {BigFetchWithPosition} from '../../models/fetch.models';
import {DeliveryLockingModalService} from '../services/delivery-locking-modal.service';

@Component({
  selector: 'delivery-create-overview',
  templateUrl: './delivery-create-overview.component.html',
})
export class DeliveryCreateOverviewComponent {
  @Input() data: BigCreateDeliveryWorkflow;
  @Output() updateValues = new EventEmitter<{ workflow: BigWorkflow }>();

  constructor(private pdfHistoryModalService: PdfHistoryModalService,
              private deliveryService: DeliveryService,
              private alertService: AlertService,
              private workflowService: WorkflowService,
              private deliveryStornoModalService: DeliveryStornoModalService,
              private deliveryLockingModalService: DeliveryLockingModalService) {
  }

  historyDelivery(delivery: DeliveryListData) {
    this.pdfHistoryModalService.open('delivery', delivery.id, 'Auftragshistorie').subscribe(
      ignoreRejection,
      ignoreRejection
    );
  }

  lock(obj: DeliveryListData) {
    this.deliveryLockingModalService.open(obj.id, true).subscribe(response => {
      obj.delivery_invoice_lock = response.invoice_lock;
    }, ignoreRejection);
  }

  unlock(obj: DeliveryListData) {
    this.deliveryLockingModalService.open(obj.id, false).subscribe(response => {
      obj.delivery_invoice_lock = response.invoice_lock;
    }, ignoreRejection);
  }

  storno(obj: DeliveryListData) {
    this.deliveryStornoModalService.open(obj.id, this.data.workflow.object.id).subscribe(response => {
      obj.storno = response.storno;
      this.workflowService.detail(this.data.workflow.object.id).subscribe(bigWorkflow => {
        this.updateValues.emit({workflow: bigWorkflow});
        this.alertService.add('success', 'Lieferschein erfolgreich storniert!');
      }, () => {
        this.alertService.add('danger', 'Beim Stornieren des Lieferscheins ist ein Fehler aufgetreten!');
      });
    }, ignoreRejection);
  }

  containsInvoicedFetch(delivery: DeliveryListData) {
    const fetchList = this.data.workflow.frame.fetch;
    fetchList.forEach(val => {
      const fetchObject = val as BigFetchWithPosition;
      if (fetchObject.invoice_obj && fetchObject.status === 5 && fetchObject.invoice_obj.id === delivery.delivery_invoice_id) {
        return true;
      }
    });

    return false;
  }

  hasInvoice(deliveryId: number): boolean {
    const res = this.data.workflow.frame.fetch.find(x =>
      (x as BigFetchWithPosition).delivery_obj &&
      (x as BigFetchWithPosition).delivery_obj.id === deliveryId);
    return res && res.status && res.status === 5;
  }
}
