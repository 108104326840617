import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TransitionService, Transition} from '@uirouter/core';

@Component({
  selector: 'app-bootstrap',
  templateUrl: './app.bootstrap.component.html'
})
export class AppBootstrapComponent implements OnInit, OnDestroy {

  constructor(private renderer: Renderer2,
              private transitionService: TransitionService) {
  }

  ngOnInit(): void {
    this.transitionService.onSuccess({to: '**'}, (transition: Transition) => {
      const name = transition.targetState().name();
      if (name.startsWith('a')) {
        this.renderer.addClass(document.body, 'envisiabody');
      } else {
        this.ngOnDestroy();
      }
    }, {priority: 100});
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'envisiabody');
  }

}
