/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-e-test-labeling',
  templateUrl: './article-extra-e-test-labeling.component.html',
})
export class ArticleExtraETestLabelingComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'e_test_labeling';
  protected schemaName = 'e-test-labeling';

  protected fields = [
    'el_stamp',
    'el_stamp_other',
    'line_label',
    'line_label_other',
    'other',
    'testing_protocol_e_test',
    'testing_protocol_e_test_other',
    'first_pass_yield',
    'first_pass_yield_other',
    'email',
    'email_other',
    'paper',
    'paper_other'
  ];
}
