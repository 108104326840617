import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ListService} from '../../../../common/search/search.service';
import {InvoiceCorrection, InvoiceCorrectionForm, InvoiceCorrectionListData} from '../invoice.models';
import {Observable} from 'rxjs';
import {EnvisiaObject, Lister} from '../../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class InvoiceCorrectionService implements ListService<InvoiceCorrectionListData> {

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<InvoiceCorrectionListData>> {
    return this.http.get<Lister<InvoiceCorrectionListData>>(
      '/api/v1/invoice/correction/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  create(form: InvoiceCorrectionForm): Observable<InvoiceCorrection> {
    return this.http.post<EnvisiaObject<InvoiceCorrection>>(
      '/api/v1/invoice/correction/',
      form
    ).pipe(map(val => val.object));
  }

  fromInvoice(id: number, body: {hint: string, date: string}): Observable<InvoiceCorrection> {
    return this.http.post<EnvisiaObject<InvoiceCorrection>>(
      '/api/v1/invoice/correction/' + id + '/',
      body
    ).pipe(map(val => val.object));
  }

}
