/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'main-dropdown',
  templateUrl: './main-dropdown.component.html',
  styleUrls: ['./menu-fa-stack.scss'],
})
export class MainDropdownComponent {
  year: number = new Date().getFullYear();

  constructor(private authService: AuthService) {
  }

  logout(): void {
    this.authService.logout();
  }

}
