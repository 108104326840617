import {Component, Input, OnInit} from '@angular/core';
import {EnvisiaFileService} from './envisia-file.service';
import {Observable} from 'rxjs';
import Helper from '../../helper/helper';

@Component({
  selector: 'ev-file-button',
  templateUrl: './envisia-file-button.component.html'
})
export class EvFileButtonComponent implements OnInit {
  @Input() disabled?: boolean;
  @Input() link?: boolean;
  @Input() type?: string;
  @Input() objId?: number;
  @Input() url?: string;
  @Input() title?: string;
  @Input() hawk = false;
  @Input() hawkUrl?: () => Observable<string>;
  noLink = false;

  constructor(private envisiaFileService: EnvisiaFileService) {
  }

  ngOnInit(): void {
    if (this.link !== undefined) {
      this.noLink = !this.link;
    }
  }

  download() {
    if (!this.hawk) {
      if (this.objId === undefined && this.type === undefined) {
        this.envisiaFileService.open(this.url);
      } else {
        this.envisiaFileService.open(this.objId.toString(), this.type);
      }
    } else if (!Helper.undefined(this.hawkUrl)) {
      this.hawkUrl().subscribe(value => {
        window.open(value);
      });
    }
  }

}
