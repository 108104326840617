<div class="modal-header">
  <div class="modal-title"></div>
</div>

<form [formGroup]="finalBaForm" (ngSubmit)="submit()">
  <div class="modal-body">
    <table class="table whitetable" formArrayName="orders">
      <thead>
      <tr>
        <th>BA-Nr.</th>
        <th>Nutzen</th>
        <th>Start-Termin</th>
        <th>End-Termin</th>
        <th>Vergoldung bis</th>
        <th>Erstauftrag</th>
        <th>Interner Eildienst</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let order of orders.controls; let i = index" [formGroupName]="i.toString()">
        <td>
          {{ i + 1 }}
        </td>
        <td>
          <integer-input class="form-control" id="utilizeQuantity-{{ i }}" formControlName="utilize"></integer-input>
        </td>
        <td>
          <ev-datepicker id="startDate-{{ i }}" formControlName="start_date" class="form-control"></ev-datepicker>
        </td>
        <td>
          <ev-datepicker id="endDate-{{ i }}" formControlName="end_date" class="form-control"></ev-datepicker>
        </td>
        <td>
          <ev-datepicker id="goldDate-{{ i }}" formControlName="gold_date" class="form-control"></ev-datepicker>
        </td>
        <td>
          <input type="checkbox" class="form-control" id="firstContract" formControlName="first_contract">
        </td>
        <td>
          <input type="checkbox" class="form-control" id="express" formControlName="express">
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success btn-sm" [disabled]="!finalBaForm.valid || locked">
      Betriebsaufträge bestellen
      <ng-container *ngIf="locked">
        &nbsp;<i class="fa fa-circle-o-notch fa-spin"></i>
      </ng-container>
    </button>
  </div>
</form>
