import {Injectable} from '@angular/core';
import {ListService} from '../../../../common/search/search.service';
import {HttpClient} from '@angular/common/http';
import {Invoice, InvoiceCreateOverviewElement, InvoiceDelivery, InvoiceForm, InvoiceListData} from '../invoice.models';
import {Observable} from 'rxjs';
import {EnvisiaObject, Lister} from '../../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class InvoiceService implements ListService<InvoiceListData> {

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<InvoiceListData>> {
    return this.http.get<Lister<InvoiceListData>>(
      '/workflow/v1/invoice/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  create(deliveryId: number, body: InvoiceForm): Observable<Invoice> {
    return this.http.post<EnvisiaObject<Invoice>>('/workflow/v1/workflow/' + deliveryId + '/invoice/', body).pipe(
      map(val => val.object)
    );
  }

  deliveryList(workflowId: number): Observable<InvoiceDelivery[]> {
    return this.http.get<Lister<InvoiceDelivery>>(
      `${environment.apiv4uri}workflow/${workflowId}/invoice/create`
    ).pipe(map(val => val.objects));
  }

  overviewList(workflowId: number): Observable<InvoiceCreateOverviewElement[]> {
    return this.http.get<InvoiceCreateOverviewElement[]>(
      `${environment.apiv4uri}workflow/${workflowId}/invoice/create/overview`
    );
  }
}
