import * as React from 'react';
import {ArticleMultilayerPlanCreateButton} from './article-multilayer-plan-create-button';
import {ArticleMultilayerPlanModel} from '../article-multilayer-plan.models';
import {ArticleMultilayerPlanHelper} from '../article-multilayer-plan-helper';
import {Article} from '../../../models/article.models';
import {ArticleMultilayerSelect} from '../form-elements/article-multilayer-select.component';
import {ArticleSpecification} from '../../../models/article-specification.model';
import {ReactNode} from 'react';
import {ArticleMultilayerPlanCopyButton} from './article-multilayer-plan-copy-button';

interface Props {
  model: ArticleMultilayerPlanModel;
  article: Article;
  copyModelBtnPressed: () => void;
  specification: { [key: string]: ArticleSpecification };
  changeModel: (model: ArticleMultilayerPlanModel) => void;
  children?: ReactNode;
}

export function ArticleMultilayerPlanHead(props: Props) {

  const createDefault: () => void = () => {
    if (((props.model.parts?.length ?? 0) === 0) || confirm(
      'Soll der momentane Aufbauplan wirklich verworfen und dann gemäß der Artikelkonfiguration neu erstellt werden?'
    )) {
      props.changeModel(ArticleMultilayerPlanHelper.createRecursiveOuter(props.article.data));
    }
  };

  const copy: () => void = () => {
    props.copyModelBtnPressed();
  };

  const materialChange: (m: string | null | undefined) => void = (m) => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    model.selected_material = !m ? 'Standard FR4' : m;
    props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
  };

  return <>
    <div className={'row'}>
      <div className={'col-lg-6'}>
        <ArticleMultilayerSelect specification={props.specification}
                                 model={'manufacturer'}
                                 valueType={'keys'}
                                 value={props.model.selected_material}
                                 disabled={props.model.parts === null || props.model.parts === undefined}
                                 onChange={m => materialChange(m)}/>
      </div>
      <div className={'col-lg-6 align-right'}>
        {props.children}
        <ArticleMultilayerPlanCreateButton onClick={() => createDefault()}/>
        <ArticleMultilayerPlanCopyButton onClick={() => copy()}/>
      </div>
    </div>
  </>;
}
