import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {MaterialArticle, MaterialVariation} from '../../material-management.model';
import {MaterialInventoryEditModalService} from '../material-inventory-edit-modal.service';
import {ignoreRejection} from '../../../../helper/ignore_rejection';

@Component({
  selector: 'material-inventory-navigation',
  templateUrl: './material-inventory-navigation.component.html'
})
export class MaterialInventoryNavigationComponent implements OnInit {
  @Input() variation?: MaterialVariation;
  @Input() article?: MaterialArticle;
  @Output() updateEmitter: EventEmitter<void> = new EventEmitter<void>();

  inInventory: boolean;

  constructor(private service: MaterialInventoryEditModalService,
              private stateService: StateService) {
  }

  ngOnInit(): void {
    this.inInventory = this.stateService.includes('a.material.inventory');
  }

  openModal(): void {
    if (this.variation) {
      this.service.bookIntakeModalOpen(this.variation.id, this.article.konsi).then(() => {
        this.updateEmitter.emit();
      }, ignoreRejection);
    }
  }
}
