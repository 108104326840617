<form [formGroup]="form">
  <div class="modal-header">
    <h3 class="modal-title">Achtung!</h3>
  </div>

  <div class="modal-body">
    <p>Möchten Sie den Vorgang wirklich {{ lowercaseName }} ?</p>
    <p class="bold">Diese Aktion kann nicht rückgängig gemacht werden.</p>
  </div>

  <br/>

  <div class="form-group">
    <div class="col-sm-12">Grund:</div>
    <div class="col-sm-12">
      <textarea class="form-control" id="reason" formControlName="reason">
      </textarea>
    </div>
  </div>

  <div>&nbsp;</div>

  <div class="modal-footer">
    <button type="button" class="pull-left btn btn-default" (click)="closeModal()">
      Abbrechen
    </button>
    <button type="button" class="pull-right btn btn-success" (click)="save()" [disabled]="isDisabled()">
      {{ name }}
    </button>
  </div>
</form>
