import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  MaterialInventoryWithPlanning,
  MaterialPlanningDate,
  MaterialPlanningDateWithColorCodes,
} from './material-planning.model';
import {MaterialValueType} from '../material-management.model';
import {Customer} from '../../customer/model/customer';

@Component({
  selector: 'tr[material-planning-row]',
  templateUrl: './material-planning-row.component.html',
  styleUrls: ['./material-planning.styles.scss'],
})
export class MaterialPlanningRowComponent implements OnInit, OnChanges {

  @Input('material-planning-row') materialChoice: MaterialInventoryWithPlanning;
  @Input('material-planning-row-week-pages') weekPages: number[];
  @Input('material-planning-row-selected-week') selectedWeek: MaterialPlanningDate | null;
  @Input('material-planning-row-selected-variation-id') selectedVariationId: number | null;
  @Input('material-planning-row-start-date') startDate: string | null;
  @Input('material-planning-row-fields') fields: MaterialValueType[] = [];
  @Input('material-planning-row-customer') customer: Customer;

  @Output('material-planning-row-select-week') emitSelectWeek =
    new EventEmitter<MaterialPlanningDate | null>();

  selectedVariation: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.selectedVariation = this.selectedVariationId === this.materialChoice.inventory.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedVariationId && !changes.selectedVariationId.isFirstChange()) {
      this.selectedVariation = this.selectedVariationId === this.materialChoice.inventory.id;
    }
  }

  setSelectedWeek(week: MaterialPlanningDate) {
    if (this.selectedWeek?.first_day_of_week === week.first_day_of_week && this.selectedVariation) {
      this.emitSelectWeek.emit(null);
    } else {
      this.emitSelectWeek.emit(week);
    }
  }

  get weeksWithColors(): MaterialPlanningDateWithColorCodes[] {
    return this.weekPages.map(w => {
      const c = this.materialChoice.workload_list[w] as MaterialPlanningDateWithColorCodes;

      if (c.first_day_of_week === this.selectedWeek?.first_day_of_week && this.selectedVariation) {
        c.color1 = 'light-blue';
        c.color2 = 'dark-blue';
      } else if (c.required_to_date <= c.secure_confirmed_to_date + this.materialChoice.inventory.amount) {
        c.color1 = 'light-green';
        c.color2 = 'dark-green';
      } else {
        c.color1 = 'light-red';
        c.color2 = 'dark-red';
      }

      return c;
    });
  }

  get materialInventoryValue(): number {
    return (
      this.materialChoice.inventory.amount +
      this.materialChoice.inventory.amount_confirmed
    );
  }

  get materialToSelectedDateValue(): number | null | undefined {
    if (this.materialChoice.has_selected_date) {
      return (
        this.materialChoice.inventory.amount +
        this.materialChoice.total_confirmed_to_selected_date
      );
    } else {
      return null;
    }
  }

  get markOrange(): boolean {
    return this.materialChoice.inventory.amount_planned > this.materialInventoryValue;
  }

  get markRed(): boolean {
    return this.materialChoice.inventory.weeks_missed.length > 0;
  }

  get markOrangeToSelectedDate(): boolean {
    return this.materialChoice.has_selected_date && (
      this.materialChoice.total_required_to_selected_date > this.materialToSelectedDateValue
    );
  }

  get markRedCustomer(): boolean {
    return this.materialChoice.has_selected_customer && (
      this.materialChoice.total_required_customer > (this.materialToSelectedDateValue ?? this.materialInventoryValue)
    );
  }

  get linkParams() {
    return {
      variation_id: this.materialChoice.inventory.id,
      customer: this.customer?.name,
      start_date: this.startDate,
      start_date_mode: !this.startDate ? null : '<=',
      status: 0,
    };
  }

}
