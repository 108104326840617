import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {PriceHistory, PriceIncrease} from '../price.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import Helper from '../../../helper/helper';
import {PriceService} from '../services/price.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Article} from '../../article/models/article.models';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PriceDetailHistoryComponent} from './price-detail-history.component';
import {ProducerModalService} from '../../producer/producer-modal.service';
import {isObject, isObjects} from '../../../common/wrapper.models';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {from, of, Subscription, throwError} from 'rxjs';
import {deepCopy} from '../../../helper/deep-copy';
import {noop} from '../../../helper/noop';
import {PriceDetailNotesModalComponent} from './price-detail-notes-modal.component';
import {RightService} from '../../../common/right-service/right.service';
import {Customer} from '../../customer/model/customer';

@Component({
  selector: 'price-detail-list',
  templateUrl: './price-detail-list.component.html',
  styles: ['.notes-truncated {text-align: left; display: block; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;max-width: 6ch; background: #dc3545; padding: 1px 3px; color: #fff; border-radius: 3px;}'],
})
export class PriceDetailListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() article: Article;
  @Input() customer?: Customer;
  @Input() lastIncrease?: PriceIncrease;
  @Input() listData: PriceHistory[];
  @Input() typ: 'de' | 'cn';
  @Output() reload = new EventEmitter<void>();
  formListData: PriceHistory[];
  errors: { [key: string]: any; } = {};
  header: string;
  forms: { [key: string]: UntypedFormGroup; } = {};
  subscriptions: { [key: string]: Subscription; } = {};

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private service: PriceService,
              private rightService: RightService,
              private alertService: AlertService,
              private producerModalService: ProducerModalService) {
  }

  ngOnInit(): void {
    if (this.typ === 'de') {
      this.header = 'Preisliste DE';
    } else if (this.typ === 'cn') {
      this.header = 'Preisliste CN';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // reinitalizes the form on any change to listData
    if (!!changes.listData) {
      this.resetForm(changes.listData.currentValue);
    }
  }

  canRecalculate(obj: PriceHistory): boolean {
    return (!obj.valid && obj.calc === 'manuell' && this.typ === 'cn') || (!obj.valid && this.typ === 'de');
  }

  setProducer(obj?: PriceHistory): void {
    let producer: string | null = null;
    if (obj) {
      producer = obj.producer;
    }
    const saveMethod = (ref: NgbActiveModal, selected?: string) => {
      const saveData: { quantity?: number, lose?: number, producer?: string } = {producer: selected};
      if (obj) {
        saveData.quantity = obj.quanity;
        saveData.lose = obj.lose;
      }
      return this.service.saveProducer(this.article.oa_nr, saveData).pipe(
        mergeMap(data => {
          if (isObject(data) && obj) {
            obj.producer = data.object.producer;
          } else if (isObjects(data)) {
            this.resetForm(data.objects);
          }
          this.alertService.add('success', 'Produzent erfolgreich geändert!');
          ref.close(); // closes the modal
          return of(null);
        }),
        catchError(response => {
          // keeps the modal open
          this.alertService.add('danger', 'Beim Produzent ändern ist ein Fehler aufgetreten!');
          return throwError(response);
        })
      );
    };

    this.producerModalService.open(saveMethod, producer).subscribe(() => {
    }, () => {
    });
  }

  doApprove(obj: PriceHistory): void {
    this.service.approve(obj.id).subscribe(() => {
      obj.approval = true;
      this.alertService.add('success', 'Preis erfolgreich freigegeben!');
    }, () => {
      this.alertService.add('danger', 'Preis konnte nicht freigegeben werden!');
    });
  }

  recalculate(): void {
    this.service.recalculate(this.article.oa_nr, this.typ).subscribe(prices => {
      this.alertService.add('success', 'Preise erfolgreich aktualisiert!');
      this.resetForm(prices);
    }, response => {
      if (response.error && 'obj.customer' in response.error) {
        this.alertService.add(
          'danger',
          'Neukalkulieren nicht möglich, da Artikel kein Kunde hinterlegt hat!'
        );
      } else {
        this.alertService.add('danger', 'Neukalkulieren nicht möglich!');
      }
    });
  }

  recalculateSingle(obj: PriceHistory, index: number): void {
    this.service.recalculateSingle(obj.id).subscribe(response => {
      const objects = deepCopy(this.listData);
      objects[index] = response;
      this.resetForm(objects);
      this.alertService.add('success', 'Preise erfolgreich aktualisiert!');
    }, response => {
      if (response.error && 'obj.customer' in response.error) {
        this.alertService.add(
          'danger',
          'Neukalkulieren nicht möglich, da Artikel kein Kunde hinterlegt hat!'
        );
      } else {
        this.alertService.add('danger', 'Neukalkulieren nicht möglich!');
      }
    });
  }

  setManual(obj: PriceHistory, index: number): void {
    this.service.manual(obj.id).subscribe(history => {
      this.listData[index] = history;
    }, response => {
      if (response.error && 'obj.customer' in response.error) {
        this.alertService.add(
          'danger',
          'Preis auf manuell setzen nicht möglich, da Artikel kein Kunde hinterlegt hat!'
        );
      } else {
        this.alertService.add('danger', 'Preis auf manuell setzen  nicht möglich!');
      }
    });
  }

  openHistory(obj: PriceHistory): void {
    let release = null;
    if (obj.release) {
      release = obj.release;
    }
    this.service.history(obj.article, obj.quanity, obj.lose, release, obj.typ).subscribe(response => {
      const modalRef = this.modalService.open(PriceDetailHistoryComponent, {windowClass: 'modal2-sxlg'});
      modalRef.componentInstance.objects = response;
      modalRef.componentInstance.customer = this.customer;
      modalRef.componentInstance.current = obj;
      modalRef.componentInstance.typ = obj.typ;
      modalRef.result.then(() => {}, () => {});
      from(modalRef.componentInstance.reload as EventEmitter<any>).subscribe(() => this.reload.emit());
    }, () => {
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten');
    });
  }

  editNotes(obj: PriceHistory): void {
    if (this.rightService.has('price.change')) {
      const modalRef = this.modalService.open(PriceDetailNotesModalComponent, {windowClass: 'modal2-slg'});
      modalRef.componentInstance.ph = obj;
      modalRef.result.then(ph => {
        const index = this.formListData.findIndex(value => value.id === obj.id);
        this.updateInnerForm(ph);
        this.formListData[index] = ph;
      }, () => {
      });
    }
  }

  deletePrice(obj: PriceHistory): void {
    if (confirm('Soll der Preis wirklich gelöscht werden?')) {
      this.service.delete(obj.id).subscribe(() => {
        this.alertService.add('success', 'Preis erfolgreich gelöscht!');
        this.reload.emit();
      }, () => {
        this.alertService.add('danger', 'Preis konnte nicht gelöscht werden!');
      });
    }
  }

  hasErrors(obj: PriceHistory, name: string): boolean {
    const sub = this.errors[obj.id];
    if (sub) {
      return !!sub[name];
    }
    return false;
  }

  trackByFn(index: number, item: PriceHistory): number {
    noop(this);
    return item.id;
  }

  ngOnDestroy(): void {
    Helper.keys(this.subscriptions, (value) => {
      value.unsubscribe();
    });
    this.subscriptions = {};
  }

  private resetForm(newData: PriceHistory[]): void {
    // decomissions the form and the list data
    this.formListData = null;
    this.ngOnDestroy();
    this.initForms(newData);
    this.formListData = newData;
    this.listData = newData;
  }

  private initForms(newData: PriceHistory[]): void {
    // creates the form
    const forms = {};
    newData.forEach(obj => {
      if (this.typ === 'cn') {
        forms[obj.id.toString()] = this.createFormGroup(obj);
      } else {
        forms[obj.id.toString()] = this.fb.group({
        });
      }
    });
    this.forms = forms;

    Helper.keys(this.forms, (group: UntypedFormGroup, id: string) => {
      const history = newData.find(value => value.id.toString() === id);
      this.subscriptions[id] = group.valueChanges.pipe(
        debounceTime(750),
        map(value => {
          return {value: deepCopy(value), id: id, history: history};
        })
      ).subscribe((obj) => {
        // Form Changes per Line
        this.edit(obj);
      }, () => {
      });
    });
  }

  private createFormGroup(obj: PriceHistory): UntypedFormGroup {
    return this.fb.group({
      'exchange': [this.stringOrNull(obj.exchange)],
      'cost': [this.stringOrNull(obj.cost)],
      'price_reduction': [this.stringOrNull(obj.price_reduction)]
    });
  }

  private edit(obj: { value: any, id: string, history: PriceHistory }): void {
    this.errors[obj.history.id] = {};
    this.service.update(obj.history.id, obj.value).subscribe(ph => {
      const index = this.formListData.findIndex(value => value.id.toString() === obj.id);
      this.updateInnerForm(ph);
      this.formListData[index] = ph;
      this.alertService.add('success', 'Preis erfolgreich editiert.');
    }, response => {
      if (response.error) {
        this.errors[obj.history.id].cost = response.error.cost;
        this.errors[obj.history.id].price_reduction = response.error.price_reduction;
        this.errors[obj.history.id].exchange = response.error.exchange;
      }
      this.alertService.add('danger', 'Beim Speichern ist ein Fehler aufgetreten!');
    });
  }

  private updateInnerForm(ph: PriceHistory): void {
    if (ph.typ === 'cn') {
      this.forms[ph.id.toString()].setValue({
        'exchange': this.stringOrNull(ph.exchange),
        'cost': this.stringOrNull(ph.cost),
        'price_reduction': this.stringOrNull(ph.price_reduction)
      }, {emitEvent: false});
    } else {
      const index = this.listData.findIndex(value => value.id === ph.id);
      this.listData[index] = ph;
    }
  }

  private stringOrNull(value: any): string {
    noop(this);
    let ret = '';
    if (value !== null && value !== undefined) {
      ret = value.toString();
    }
    return ret;
  }

}
