import {Component, Input} from '@angular/core';
import {Article} from '../models/article.models';

@Component({
  selector: 'article-warning',
  templateUrl: './article-warning.component.html'
})
export class ArticleWarningComponent {
  @Input() article?: Article;
}
