<td [colSpan]="numFields"></td>
<td class="align-right" *ngIf="('material.order.price.check'|HasPerm)">
  <ng-container *ngIf="(!!price || price === 0)">
    {{price|money:2}}&nbsp;&euro;
  </ng-container>
</td>
<td>{{transaction.transaction.charge}}</td>
<td>{{transaction.transaction.location}}</td>
<td class="align-right">{{transaction.transaction.amount}}</td>
<td class="align-right icon">
  <a [href]="pdfUri"
     target="_blank"
     class="click"
     title="Etiketten PDF öffnen">
    <i class="fa fa-file-pdf-o row-icon" aria-hidden="true"></i>
  </a>
</td>
<td class="icon">
  <a class="click"
     title="Zusammen eingebuchtes Material anzeigen"
     (click)="openPrint(transaction.id)">
    <i class="fa fa-info-circle row-icon" aria-hidden="true"></i>
  </a>
</td>
<td class="icon">
  <a class="click"
     title="Einbuchung und alle dazugehörigen Umbuchungen löschen"
     (click)="remove()">
    <i class="fa fa-trash-o red_font row-icon" aria-hidden="true"></i>
  </a>
</td>
<td colspan="2"></td>
