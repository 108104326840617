import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Article, ArticleCore} from '../models/article.models';
import {StateService} from '@uirouter/angular';
import {ArticleCopyService} from '../component-service/article-copy.service';
import {ArticleSisterService} from '../component-service/article-sister.service';
import {ArticleDeleteService} from '../component-service/article-delete.service';
import {ArticleSdsService} from '../component-service/article-sds.service';
import Helper from '../../../helper/helper';
import {ArticleRecoverService} from '../component-service/article-recover.service';
import {
  ShippingInstructionModalService
} from '../../article-extra/shipping-instruction-history/shipping-instruction-modal.service';
import {StringHelper} from '../../../common/string-helper';

@Component({
  selector: 'article-button-navigation',
  templateUrl: './article-button-navigation.component.html'
})
export class ArticleButtonNavigationComponent {
  @Input() article: Article | ArticleCore;
  @Input() options: string[] = [];
  @Input() locked = false;
  @Input() withLock = true;
  @Input() validationErrors: string[] = [];
  @Output() saveEmitter = new EventEmitter<void>();
  @Output() lockChangeEmitter = new EventEmitter<number | null>();
  @Output() generateSdsEmitter = new EventEmitter<Article>();
  @Output() generateSisterEmitter = new EventEmitter<Article>();
  @Output() deleteEmitter = new EventEmitter<void>();
  @Output() recoverEmitter = new EventEmitter<Article>();

  public create = false;
  public workflow_id: string | null | undefined;
  public state: StateService;

  constructor(private stateService: StateService,
              private articleDeleteService: ArticleDeleteService,
              private articleRecoverService: ArticleRecoverService,
              private articleCopyService: ArticleCopyService,
              private articleSdsService: ArticleSdsService,
              private historyService: ShippingInstructionModalService,
              private articleSisterService: ArticleSisterService) {
    this.state = stateService;
    this.create = this.stateService.params.create;
    this.workflow_id = this.stateService.params.workflow_id;
  }

  showSisterButton() {
    let ret = false;
    if (this.article.sds_nr !== null) {
      ret = Helper.undefined(this.article.sister);
    }
    return ret;
  }

  get isLocked(): boolean {
    // checks if the button is locked, by either checking shared lock or article_locked/deleted
    return (!!this.article.date_deleted || !!this.article.locked || this.locked || this.hasValidationErrors);
  }

  get hasValidationErrors(): boolean {
    return (this.validationErrors ?? []).length > 0;
  }

  get saveButtonErrorMessage(): string {
    return this.hasValidationErrors ?
      'Artikel kann nicht gespeichert werden.\n' +
      StringHelper.concat((this.validationErrors ?? []).filter(e => !!e), '\n') :
      'Artikel speichern';
  }

  back(): string {
    if (this.create) {
      return '#!/workflow/create/contract/' + this.workflow_id;
    } else {
      return '#!/workflow/' + this.workflow_id;
    }
  }

  save(): void {
    this.saveEmitter.next(null);
  }

  copy(): void {
    this.articleCopyService.copyArticle(this.article.oa_nr, this.article.data.kd_art_nr).subscribe(() => {
      // ignore the event
    }, () => {
    });
  }

  sdsGenerate(): void {
    this.articleSdsService.generateSds(this.article.oa_nr).subscribe(value => {
      this.generateSdsEmitter.next(value);
    });
  }

  lockChange(value: boolean): void {
    if (value) {
      this.lockChangeEmitter.next(-1);
    } else {
      this.lockChangeEmitter.next(null);
    }
  }

  sisterCreate() {
    this.articleSisterService.createSisterArticle(this.article.oa_nr).subscribe(sister => {
      this.generateSisterEmitter.next(sister);
    });
  }

  deleteArticle() {
    this.articleDeleteService.deleteArticle(this.article.oa_nr).subscribe(() => {
      this.deleteEmitter.next(null);
    });
  }

  recoverArticle(): void {
    this.articleRecoverService.recoverArticle(this.article.oa_nr).subscribe(article => {
      this.recoverEmitter.next(article);
    });
  }

  openHistory() {
    this.historyService.open('article', this.article.oa_nr, `Artikel ${this.article.oa_nr}`, this.article);
  }
}
