import * as React from 'react';
import {InventoryGroup, StorageBin} from '../inventory.models';
import {ChangeEvent, FormEvent, useState} from 'react';
import Axios, {AxiosError} from 'axios';
import {ArticleInventoryTransferAxiosService} from './article-inventory-transfer-axios.service';
import {ArticleInventoryTransferWorkflowFormModel} from './article-inventory-transfer-workflow-form.model';
import {ArticleInventoryWorkflowAutosuggest} from '../form/article-inventory-workflow-autosuggest';
import {NumberHelper} from '../../../../helper/number-helper';
import {ArticleInventoryTransferErrorLabel} from './article-inventory-transfer-error-label';

interface Props {
  oaNr: string;
  inventory: InventoryGroup;
  storage: StorageBin[];
  workflowId: number;
  workflowIds: number[];
  back: () => void;
  update: () => void;
}

export function ArticleInventoryTransferWorkflowForm(props: Props) {
  const [saveBtnLocked, saveBtnLockedSet] = useState<boolean>(false);
  const [errors, errorsSet] = useState<{ [key: string]: string[] }>({});
  const [form, formSet] = useState<ArticleInventoryTransferWorkflowFormModel>(
    {
      date_code: props.inventory.dateCode,
      storage_bin_name: props.inventory.storageBinName,
      storage_bin_number: props.inventory.storageBinNumber ?? null,
      workflow_id_origin: props.inventory.workflowId ?? null,
      quantity: null,
      workflow_id_target: null,
      storage_note: null,
      note: null,
    }
  );

  const saveData: (event: FormEvent) => void = async (event) => {
    event.preventDefault();

    if (saveBtnLocked) {
      return;
    }

    saveBtnLockedSet(true);
    try {
      errorsSet({});
      await ArticleInventoryTransferAxiosService.transferWorkflow(props.oaNr, form)
        .then(() => {
          props.update();
        }, (error: Error | AxiosError) => {
          if (Axios.isAxiosError(error)) {
            errorsSet(error.response?.data?.errors ?? {});
          } else {
            console.error(error);
          }
        });
    } finally {
      saveBtnLockedSet(false);
    }
  };

  const getInputClassName: (element: string) => string = (element) => {
    return 'form-control' + (!!errors[element] ? ' red_alert' : '');
  };

  const changeQuantity: (e: ChangeEvent<any>) => void = (e) => {
    const model = {...form, quantity: NumberHelper.saveParseInteger(e.target.value)};
    formSet(model);
  };

  const changeNote: (e: ChangeEvent<any>) => void = (e) => {
    const model = {...form, note: e.target.value?.toString()};
    formSet(model);
  };

  const changeStorageNote: (e: ChangeEvent<any>) => void = (e) => {
    const model = {...form, storage_note: e.target.value?.toString()};
    formSet(model);
  };

  const changeWorkflowId: (workflowId: number | null) => void = (workflowId) => {
    formSet({...form, workflow_id_target: workflowId});
  };

  return <>
    <form onSubmit={saveData}>
      <div className='modal-header'>
        <h3 className='modal-title'>Lagerbestand auf anderen Vorgang umbuchen</h3>
      </div>

      <div className='modal-body inventory'>
        <div className='row mb-10'>
          <div className='col-md-3'>Datecode:</div>
          <div className='col-md-9'><span>{props.inventory.dateCode}</span></div>
        </div>
        <div className='row mb-10'>
          <div className='col-md-3'>Aktuelles Lager:</div>
          <div className='col-md-9'><span>{props.inventory.storageBinName}</span></div>
        </div>
        <div className='row mb-10'>
          <div className='col-md-3'>Aktueller Lagerplatz:</div>
          <div className='col-md-9'><span>{props.inventory.storageBinNumber}</span></div>
        </div>
        <div className='row mb-10'>
          <div className='col-md-3'>Aktuelle Vorgangs-Nr.:</div>
          <div className='col-md-9'>{props.inventory.workflowId?.toString(10) ?? '-'}</div>
        </div>

        <div className='row mb-10'>
          <div className='col-sm-3'><label htmlFor='quantity'>Menge</label></div>
          <div className='col-sm-9'>
            <input id='quantity'
                   type='number'
                   className={getInputClassName('quantity')}
                   value={form.quantity?.toString(10) ?? ''}
                   onChange={changeQuantity}/>
          </div>
        </div>

        <div className='row mb-10'>
          <div className='col-sm-3'><label htmlFor='workflow_id_target'>Vorgangs Nr.</label></div>
          <div className='col-sm-9'>
            <ArticleInventoryWorkflowAutosuggest id='workflow_id_target'
                                                 className={getInputClassName('workflow_id_target')}
                                                 workflowId={form.workflow_id_target}
                                                 workflowIds={props.workflowIds}
                                                 onChange={changeWorkflowId}/>
          </div>
        </div>

        <div className='row mb-10'>
          <div className='col-sm-3'><label htmlFor='storage_note'>Hinweis</label></div>
          <div className='col-sm-9'>
            <textarea className='form-control' id='storage_note' rows={3} onChange={changeStorageNote}/>
          </div>
        </div>

        <div className='row mb-10'>
          <div className='col-sm-3'><label htmlFor='note'>Notiz</label></div>
          <div className='col-sm-9'>
            <input id='note'
                   type='text'
                   className={getInputClassName('note')}
                   value={form.note ?? ''}
                   onChange={changeNote}/>
          </div>
        </div>

        <ArticleInventoryTransferErrorLabel errorType={errors} />

      </div>

      <div className='modal-footer'>
        <button type='button' className='btn btn-default pull-left' onClick={() => props.back()}>
          Abbrechen
        </button>
        <button type='submit' className='btn btn-success pull-right'>
          Bestand umbuchen
        </button>
      </div>
    </form>
  </>;
}
