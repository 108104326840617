import {Component, Input, OnInit} from '@angular/core';
import {MailHeaderData} from './mail.models';
import {User} from '../user/user.models';
import {MailService} from './mail.service';
import {AlertService} from '../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {WorkflowUser} from '../workflow/models/workflow.models';

@Component({
  selector: 'mail-modal',
  templateUrl: './mail-modal.component.html'
})
export class MailModalComponent implements OnInit {
  @Input() id: number | string;
  @Input() type: string;
  @Input() header: MailHeaderData;
  @Input() locked: boolean;
  @Input() language: string;
  @Input() template: string;
  @Input() user: User;
  @Input() workflowUser: WorkflowUser | null;
  @Input() frameTyp?: string;
  /** variables */
  disabled = false;
  errors: { [key: string]: any; } = {};
  users: (User | WorkflowUser)[] = [];
  form: UntypedFormGroup;
  mainUser: User | WorkflowUser;
  files: File[];
  private maxFiles = 5;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbActiveModal,
              private mailService: MailService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.mainUser = this.user;
    if (this.workflowUser) {
      if (this.workflowUser.username !== this.user.username) {
        this.users.push(this.workflowUser);
        this.mainUser = this.workflowUser;
      }
    }
    this.users.push(this.user);

    this.form = this.fb.group({
      rcpt: new UntypedFormControl({value: this.header.rcpt, disabled: this.locked}),
      bcc: [this.constructBcc()],
      body: [this.template]
    });
  }

  submit(): void {
    this.disabled = true;
    this.errors = {};

    const send = this.form.value;
    send.bcc = send.bcc.toString().replace(',', ';').split(';').map(Function.prototype.call, String.prototype.trim);
    const formData = new FormData();
    if (this.files && this.files.length > 0) {
      for (const f of this.files) {
        formData.append('files', f, f.name);
      }
    }
    formData.append('rcpt', send.rcpt);
    formData.append('bcc', send.bcc);
    formData.append('body', send.body);
    this.mailService.send(this.type, this.id, formData).subscribe(() => {
      this.disabled = false;
      this.alertService.add('success', 'E-Mail erfolgreich gesendet!');
      this.modalService.close();
    }, response => {
      this.disabled = false;
      if (response.error) {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten!');
    });
  }


  switchUser(user: WorkflowUser | User): void {
    if (!!user['firstName'] && !!user['lastName']) {
      // hot patch user object that is not snake cased
      user['first_name'] = user['firstName'];
      user['last_name'] = user['lastName'];
    }
    this.mainUser = user;
    const templateData = {
      user: user,
      header: this.header.header
    };
    this.mailService.template(this.type, templateData).subscribe(value => {
      this.form.patchValue({body: value});
    }, () => {
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten!');
    });
  }

  private constructBcc(): string {
    const mailArray = [];
    if (!this.header.forceBcc) {
      // issue #1812
      if (this.type === 'offer') {
        mailArray.push('angebote@storz-pcb.de');
      } else {
        mailArray.push(this.user.email);
      }
    }

    if (this.header.bcc.length > 0) {
      Array.prototype.push.apply(mailArray, this.header.bcc);
    }

    // issue #1344
    if (this.frameTyp === 'cn') {
      mailArray.push('purchase@storz-pcb.de');
    }

    return mailArray.join('; ');
  }

  onChange(event: any): void {
    const files = Array.from(event.target.files) as File[];
    if (files.length <= this.maxFiles) {
      this.files = files;
    } else {
      this.alertService.add('danger', 'Max. Dateianzahl überschritten (' + this.maxFiles + ')');
    }
  }

  currentFiles(): string[] {
    if (this.files && this.files.length > 0) {
      return this.files.map(f => f.name);
    } else {
      return [];
    }
  }

  uploaded(): boolean {
    return Array.isArray(this.files) && this.files.length > 0;
  }

}
