import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaterialOrderArticleListElement, MaterialOrderArticleListGroup} from '../../material-order.model';
import {MaterialGroupListElement, MaterialValueType} from '../../../material-management.model';

@Component({
  selector: 'tr[material-order-create-list-row]',
  templateUrl: './material-order-create-list-row.component.html',
})
export class MaterialOrderCreateListRowComponent implements OnInit {
  @Input('material-order-create-list-row') article: MaterialOrderArticleListElement;
  @Input('material-order-create-list-row-group') group: MaterialGroupListElement;
  @Input('material-order-create-list-row-ids') articleIds: number[];
  @Input('material-order-create-list-row-fields') fields: MaterialValueType[];
  @Output('material-order-create-list-row-add') articleEmitter = new EventEmitter<MaterialOrderArticleListGroup>();

  inList: Boolean;

  constructor() { }

  ngOnInit(): void {
    this.inList = (this.articleIds.findIndex(id => id === this.article.id) >= 0);
    this.articleIds = [];
  }

  addArticle(): void {
    this.inList = true;
    this.articleEmitter.emit({
      group: this.group,
      article_list: [this.article]
    });
  }

}
