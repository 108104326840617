<table class="table whitetable whitetable-borderless marginless-bottom">
  <tbody>
  <tr>
    <td class="bold">Produktionsvorschlag:</td>
    <td class="align-right">
      <i class="fa fa-spin fa-circle-o-notch" *ngIf="!proposalSuccess"></i>
    </td>
  </tr>
  <tr>
    <td>Bestellte Menge:</td>
    <td>{{ proposal.ordered_quantity|money }}</td>
  </tr>
  <tr [ngClass]="{'selected-table-box': proposal.inventory_sum > 0}">
    <td>Reservierter Lagerbestand:</td>
    <td>
      - {{ proposal.inventory_sum|money }}
      <ng-container *ngIf="proposal.kanban">(Kanban)</ng-container>
    </td>
  </tr>
  <tr class="bottom-row">
    <td>
      Überproduktion
    </td>
    <td>
      {{ proposal.over_production_string }} {{ proposal.over_production|money }}
    </td>
  </tr>
  <tr>
    <td><strong>Anzahl Nutzen:</strong></td>
    <td>
      {{ proposal.total_delivery_quantity|money }} /
      {{ proposal.amount_per_utilize|money }} =
      <strong>{{ proposal.total_utilized|money }}</strong>
    </td>
  </tr>
  <tr>
    <td><strong>Produktionsvorschlag:</strong></td>
    <td><strong>{{ proposal.total_utilized|money }}</strong></td>
  </tr>
  <tr>
    <td>Menge Platinen:</td>
    <td>{{ proposal.total_quantity|money }}</td>
  </tr>
  <tr *ngIf="proposal.no_ba_needed">
    <td colspan="2" class="red_alert">
      <strong>Achtung:</strong> Aktueller Lagerbestand ist höher als die benötigte Menge.
      Bitte verwenden Sie den Lagerbestand!
      Wenn trotzdem ein BA erstellt wird, wird auf diesen BA kein Lagerbestand reserviert.
    </td>
  </tr>
  </tbody>
</table>
