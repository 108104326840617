import {NgModule} from '@angular/core';
import {ArticleWarningComponent} from './article-warning.component';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ArticleWarningComponent
    ],
    exports: [
        ArticleWarningComponent
    ]
})
export class ArticleWarningModule {
}
