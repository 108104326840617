<div class="modal-header">
  <h3 class="modal-title">Vorgang duplizieren</h3>
</div>

<div class="modal-body">
  <div class="row">
    <workflow-create-article-search (nextEmitter)="next($event)" [withoutOffers]="true" [limit]="10">
    </workflow-create-article-search>
  </div>
</div>

<div class="modal-footer">
</div>
