/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {CalculationService} from './calculation.service';
import {CalculationCopyForm} from './calculation.model';
import {AlertService} from '../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StateService} from '@uirouter/angular';

@Component({
  selector: 'calculation-copy-modal',
  templateUrl: './calculation-copy-modal.component.html'
})
export class CalculationCopyModalComponent implements OnInit, OnDestroy {
  @Input() current: string;
  private subscription?: Subscription;
  calcForm: UntypedFormGroup;

  constructor(private modalRef: NgbActiveModal,
              private alertService: AlertService,
              private fb: UntypedFormBuilder,
              private calculationService: CalculationService,
              private stateService: StateService) {}

  ngOnInit(): void {
    this.calcForm = this.fb.group({
      newCalculation: [null, Validators.required]
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  cancel(): void {
    this.modalRef.dismiss();
  }

  copy() {
    const form = {new_value: this.calcForm.value.newCalculation, old_value: this.current} as CalculationCopyForm;
    this.calculationService.copy(form).subscribe(() => {
      this.alertService.add('success', 'Kalkulation wurde erfolgreich kopiert.');
      this.stateService.reload(this.stateService.$current);
      this.cancel();
    }, () => {
      this.alertService.add('danger', 'Fehler beim Kopieren der Kalulation, bitte versuchen Sie es später erneut!');
    });
  }

}
