<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Verpackung
  </article-extra-headline>

  <div class="clearfix"></div>

  <h6>Verpackungseinheit</h6>
  <div class="article-form-item-extra-values">
    <article-extra-text formControlName="quantity_ve" type="int" class="article-form-item-extra-long">
      Liefernutzen / VE
    </article-extra-text>

    <article-extra-text formControlName="quantity_ve_other" class="article-form-item-extra" *ngIf="!article">
    </article-extra-text>

    <article-extra-utilize *ngIf="article" [quantity]="form.value['quantity_ve']" [article]="article"
                           class="article-form-item-extra">
      LP / VE
    </article-extra-utilize>

  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="vacuum" class="article-form-item-values">
      Vakuum
    </article-extra-checkbox>

    <article-extra-text formControlName="vacuum_other" class="article-form-item-extra"></article-extra-text>

  </div>

  <div class="article-form-item-extra-values">
    <article-extra-text-area formControlName="other" class="article-form-item-extra-long" cols="5">
      Sonstiges
    </article-extra-text-area>

  </div>

  <h6>Umkarton</h6>

  <div class="article-form-item-extra-values">
    <article-extra-text formControlName="ve_carton" type="int" class="article-form-item-extra-long">
      VE / Karton
    </article-extra-text>

    <article-extra-text formControlName="ve_carton_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-text formControlName="maximum_weight" unit="kg" type="int" class="article-form-item-extra-long">
      max. Gewicht
    </article-extra-text>

    <article-extra-text formControlName="maximum_weight_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-text-area formControlName="carton_other" class="article-form-item-extra-long" cols="5">
      Sonstiges
    </article-extra-text-area>
  </div>

</div>
