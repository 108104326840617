import {Component, Input, OnInit} from '@angular/core';

import {StateService} from '@uirouter/angular';
import {Article} from '../models/article.models';

@Component({
  selector: 'article-navigation',
  templateUrl: './article-navigation.component.html',
})
export class ArticleNavigationComponent implements OnInit {
  @Input() article?: Article;
  @Input() data?: {article: Article};
  public state: StateService;
  public create: boolean;
  public backButton = false;
  public workflow_id: string | null | undefined;
  public createButton = false; // FIXME: override

  constructor(private stateService: StateService) {
    this.state = stateService;
    this.workflow_id = this.stateService.params.workflow_id;
    this.backButton = this.state.is('a.price.check') && !!this.workflow_id;
    this.create = this.state.params.create;
  }

  ngOnInit(): void {
    if (this.article) {
      this.data = {article: this.article};
    }
  }

  back() {
    if (this.create) {
      return '#!/workflow/create/contract/' + this.workflow_id;
    } else {
      return '#!/workflow/' + this.workflow_id;
    }
  }

}
