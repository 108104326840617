import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {ArticleMultilayerPlan} from './article-multilayer-plan.models';

@Injectable({
  providedIn: 'root'
})
export class ArticleMultilayerPlanService {
  private uri = (oaNr: string) => {
    return (`${environment.apiv4uri}article/${oaNr}/multilayerplan`);
  }

  constructor(private http: HttpClient) {
  }

  public get(oaNr: string): Observable<ArticleMultilayerPlan> {
    return this.http.get<ArticleMultilayerPlan>(this.uri(oaNr));
  }

  public getById(sharedId: string): Observable<ArticleMultilayerPlan> {
    return this.http.get<ArticleMultilayerPlan>(`${environment.apiv4uri}multilayerplan/${sharedId}`);
  }
}
