import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PaginationBaseComponent} from '../../../../../common/pagination/pagination.component';
import {WorkflowRequestService} from '../../../workflow-services/workflow-request.service';
import {RequestListData} from '../../../request/workflow-request.model';
import {Workflow} from '../../../models/workflow.models';
import {Article} from '../../../../article/models/article.models';
import {PaginationQuery} from '../../../../../common/pagination/pagination.model';
import {Lister} from '../../../../../common/wrapper.models';

@Component({
  selector: 'order-request-main-request-wrapper',
  templateUrl: './order-request-main-request-wrapper.component.html',
})
export class OrderRequestMainRequestWrapperComponent
  extends PaginationBaseComponent<WorkflowRequestService, RequestListData>
  implements OnInit, OnChanges {
  @Input() workflow: Workflow;
  @Input() article: Article;
  @Input() outerQuery: PaginationQuery;
  @Input() outerList: Lister<RequestListData>;
  @Output() requestToOrderEmitter = new EventEmitter<{ requestNr: number, producer: string } | null>();

  constructor(protected service: WorkflowRequestService) {
    super();
  }

  ngOnInit(): void {
    const q = this.query({oa_nr: this.article.oa_nr});
    this.serviceCall(q);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.outerQuery && !changes.outerQuery.isFirstChange() && this.outerQuery) {
      this.lastQuery = this.outerQuery;
    }

    if (changes.outerList && !changes.outerList.isFirstChange() && this.outerList) {
      this.list = this.outerList;
      this.lastQuery = this.outerQuery;
    }
  }

  get transformedList() {
    return this.list?.objects?.map(l => l.order_request) ?? [];
  }
}
