import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MaterialValueType} from '../../material-management.model';
import {MaterialOrder, MaterialOrderIntakeListElement, MaterialOrderPosition} from '../material-order.model';
import {MaterialOrderPositionService} from '../material-order-position.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';
import {noop} from '../../../../helper/noop';
import {DateHelper} from '../../../../helper/date-helper';

@Component({
  selector: 'tr[material-order-update-position]',
  templateUrl: './material-order-update-position.component.html',
  styleUrls: ['../material-order.component.scss'],
})
export class MaterialOrderUpdatePositionComponent implements OnInit, OnChanges {
  @Input('material-order-update-position') position: MaterialOrderIntakeListElement;
  @Input('material-order-update-position-order') order: MaterialOrder;
  @Input('material-order-update-position-fields') fields: MaterialValueType[];
  @Input('material-order-update-position-group-permit') groupPermitted: boolean;
  @Output('material-order-update-position-update') updateEvent = new EventEmitter<void>();
  @Output('material-order-update-position-copy') copyEvent = new EventEmitter<void>();
  form: UntypedFormGroup;
  edit: boolean;
  lockCopy = false;

  constructor(private service: MaterialOrderPositionService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'amount_ordered': [this.position.amount_ordered, Validators.required],
      'date_delivery': [this.position.date_delivery],
      'price': [this.position.price],
      'note': [this.position.note],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.position && !changes.position.isFirstChange()) {
      this.edit = false;
      this.form.patchValue({
        'amount_ordered': this.position.amount_ordered,
        'date_delivery': this.position.date_delivery,
        'price': this.position.price,
        'note': this.position.note,
      });
    }
  }

  delete(): void {
    if (confirm('Möchten Sie diese Position wirklich löschen?')) {
      this.service.delete(this.position.position_id).subscribe(() => {
        this.updateEvent.emit();
        this.position.deleted = true;
      }, (errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 409) {
          this.alertService.add('danger', 'Es existieren bereits Einbuchungen für diese Position.');
        }
      });
    }
  }

  deleteUndo(): void {
    if (confirm('Möchten Sie diese Position wirklich wiederherstellen?')) {
      this.service.deleteUndo(this.position.position_id).subscribe(() => {
        this.updateEvent.emit();
        this.position.deleted = false;
      });
    }
  }

  update(): void {
    this.service.update(this.position.position_id, this.form.value).subscribe(position => {
      this.position.amount_ordered = position.amount;
      this.position.date_delivery = position.date_delivery;
      this.position.price = position.price;
      this.position.status = position.status;
      this.position.note = position.note;
      this.edit = false;
      this.updateEvent.emit();
    }, () => {
      this.alertService.add('danger', 'Es muss ein gültiger Betrag eingetragen werden.');
    });
  }

  setEdit(edit: boolean): void {
    this.edit = edit;
    if (!this.edit) {
      this.form.reset({
        amount_ordered: this.position.amount_ordered,
        date_delivery: this.position.date_delivery,
        price: this.position.price,
        note: this.position.note,
      });
    }
  }

  copy(): void {
    if (!this.lockCopy) {
      this.lockCopy = true;
      this.service.copy(this.position.position_id).subscribe(() => {
        this.lockCopy = false;
        this.alertService.add('success', 'Position erfolgreich Kopiert');
        this.copyEvent.emit();
      }, () => {
        this.alertService.add('danger', 'Fehler beim kopieren der Position');
        this.lockCopy = false;
      });
    }
  }

  dateParser(date: string | Date | null | undefined): Date | null {
    noop(this);
    if (date instanceof Date) {
      return date;
    }
    if (!!date) {
      return DateHelper.parseISO8601(date);
    }
    return null;
  }

  get toggleConfirmTooltip(): string {
    return this.position.status ? 'Position auf Status "Bestellt" zurücksetzen' : 'Position als "Bestätigt" markieren';
  }

  get toggleConfirmIconClass(): {[key: string]: boolean} {
    return this.position.status === 0 ? {'fa-check': true, 'green': true} : {'fa-undo': true};
  }

  toggleConfirmState(): void {
    if (this.position.status === 0) {
      this.updateState(1);
    } else {
      this.updateState(0);
    }
  }

  updateState(state: number): void {
    this.service.updateState(this.position.position_id, state).subscribe(position => {
      this.updateEvent.emit();
      this.alertService.add('success', 'Status wurde geändert');
      this.position.status = position.status;
    }, (errorResponse: HttpErrorResponse) => {
      if (errorResponse.status === 404) {
        this.alertService.add('danger', 'Position wurde nicht gefunden');
      } else {
        this.alertService.add('danger', 'Status konnte nicht geändert werden');
      }
    });
  }
}
