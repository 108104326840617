<div class="page-header">
  <h3>Mahnungslauf</h3>
</div>

<div class="row">
  <div class="col-md-12">

    <button type="button" class="btn btn-success" (click)="create()">
      Mahnungslauf starten
    </button>

  </div>
</div>

<div class="row">
  <div class="col-md-12">
    &nbsp;
  </div>
</div>

<div class="row">
  <div class="col-md-10">
    <table class="table whitetable chrissies">
      <thead>
      <tr>
        <th>
          <label class="sr-only" for="select-all">alle auswählen</label>
          <tri-state-checkbox id="select-all" [items]="invoices"></tri-state-checkbox>
        </th>
        <th>Rechnungsnummer</th>
        <th>Rechnungsdatum</th>
        <th>Vorgangs-Nr.</th>
        <th>Kunde</th>
        <th>Mahnstufe</th>
        <th>letzte Mahnung</th>
        <th class="align-right">Bezahlt</th>
        <th class="align-right">Offen</th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let invoice of invoices">
        <td class="width-35">
          <label class="sr-only" aria-hidden="true" for="select-single">Auswählen</label>
          <input type="checkbox"
                 name="clickable"
                 class="form-control"
                 id="select-single"
                 [(ngModel)]="invoice.selected">
        </td>
        <td>
          <ev-file-button [objId]="invoice.id" type="invoice">
            {{ invoice.id }}
            <img [src]="'/ui/assets/images/flags/' + invoice.typ + '.png'">
          </ev-file-button>
        </td>
        <td>{{ invoice.invoice_date|date:'dd.MM.yyyy'}}</td>
        <td>
          <a uiSref="a.workflow.detail" [uiParams]="{id: invoice.workflow_id}">
            {{ invoice.workflow_id }}
          </a>
        </td>
        <td>
          <a uiSref="a.crm.customer.detail" [uiParams]="{id: invoice.customer_id}">
            {{ invoice.customer_name }}
          </a>
        </td>
        <td><strong>{{ invoice.dunning_level }}</strong></td>
        <td>{{ invoice.last_dunning_date|date:'dd.MM.yyyy' }}</td>
        <td class="align-right">{{ invoice.expensed|money:2 }} €</td>
        <td class="align-right"><strong>{{ invoice.open|money: 2 }} €</strong></td>
        <td>
          <div class="label label-success"
               placement="top"
               [ngbTooltip]="invoice.date_sent|date:'dd.MM.yyyy'"
               *ngIf="invoice.date_sent">
            Übermittelt
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
