import * as React from 'react';
import {ArticleMultilayerPartTypeHelper} from '../article-multilayer-part-type-helper';
import {ArticleMultilayerParts} from '../article-multilayer-plan.models';
import {ArticleMultilayerSelect} from '../form-elements/article-multilayer-select.component';
import {ArticleSpecification} from '../../../models/article-specification.model';
import {ArticleMultilayerUnit} from '../form-elements/article-multilayer-unit';
import {NumberHelper} from '../../../../../helper/number-helper';
import {FormatNumber} from '../formatters/format-number';
import {ReactNodeArray} from 'react';

interface Props {
  model: ArticleMultilayerParts;
  specification: { [key: string]: ArticleSpecification };
  partChange: (part: ArticleMultilayerParts) => void;
  children: ReactNodeArray;
}

export function ArticleMultilayerPlanPartCuKasch(props: Props) {
  const type = ArticleMultilayerPartTypeHelper.types.cu_kasch;

  const thicknessChange: (value: string) => void = (value: string) => {
    const copyPlan = {...props.model};
    copyPlan.cu_kasch = {...props.model.cu_kasch, thickness_selected: NumberHelper.saveParseInteger(value)};
    props.partChange(copyPlan);
  };

  return <>
    <tr style={{'height': '100%'}}>
      {props.children[0]}
      <td className={'height-fix'}>
        <div className={'rounded-full-left justify-end color-class ' + type.color_class}>
          {props.model.cu_kasch.index_copper}
        </div>
      </td>
      <td className={'height-fix'}>
        <div className={'rounded-full-center justify-end color-class ' + type.color_class}>
          {props.children[1]}
        </div>
      </td>
      {props.children[5]}
      <td className={'height-fix'}>
        <div className={'rounded-full-center color-class ' + type.color_class}>{type.label}</div>
      </td>
      <td className={'height-fix'}>
        <div className={'rounded-full-right color-class ' + type.color_class}>
          <ArticleMultilayerSelect model={'cu_inside'}
                                   specification={props.specification}
                                   value={props.model.cu_kasch.thickness_selected?.toString(10)}
                                   valueType={'keys'}
                                   onChange={value => thicknessChange(value)}>
            <ArticleMultilayerUnit specification={props.specification} model={'cu_inside'}/>
          </ArticleMultilayerSelect>
        </div>
      </td>
      <td>{props.children[4]}</td>
      <td>
        <div className={'flex align-center justify-end fit-all'}>
          <FormatNumber value={props.model.thickness / 1000} decimals={3}/>&nbsp;mm
        </div>
      </td>
      {props.children[3]}
      <td className={'align-right border-none-top border-dashed-bottom align-bottom'}>
        <FormatNumber value={props.model.thickness_from_top / 1000} decimals={3}/>&nbsp;mm
      </td>
      <td className={'align-right border-dashed-top border-none-bottom align-top'}>
        <FormatNumber value={props.model.thickness_from_bottom / 1000} decimals={3}/>&nbsp;mm
      </td>
      <td>{props.model.index < props.model.cu_kasch.index_core ? null : props.children[2]}</td>
      <td/>
    </tr>
  </>;
}
