import {Component, ErrorHandler, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialOrderPositionWithWeek} from '../material-order.model';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {MaterialOrderPositionSmallListService} from './material-order-position-small-list.service';
import {MaterialPlanningDate} from '../../planning/material-planning.model';
import {PaginationBaseComponent} from '../../../../common/pagination/pagination.component';

@Component({
  selector: 'material-order-position-small-list',
  templateUrl: './material-order-position-small-list.component.html',
})
export class MaterialOrderPositionSmallListComponent
  extends PaginationBaseComponent<MaterialOrderPositionSmallListService, MaterialOrderPositionWithWeek>
  implements OnInit, OnChanges {
  @Input() currentVariationId: number;
  @Input() selectedWeek: MaterialPlanningDate;
  @Input() list: Lister<MaterialOrderPositionWithWeek>;

  data: { [key: string]: string } = {};
  type = 'material_order_position_small';

  constructor(protected service: MaterialOrderPositionSmallListService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  ngOnInit() {
    this.query({'variation_id': this.currentVariationId.toString(10)});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentVariationId && !changes.currentVariationId.isFirstChange()) {
      this.query({'variation_id': this.currentVariationId.toString(10)});
    }
  }

}
