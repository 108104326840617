import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {SharedModule} from '../../../core/shared.module';
import {OrderReceivedModalModule} from './modal/order-received-modal.module';
import {ProducerModalModule} from '../../producer/producer-modal.module';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {WorkflowCnOrderListComponent} from './list/workflow-cn-order-list.component';
import {WorkflowNavigationModule} from '../navigation/workflow-navigation.module';
import {ORDER_REQUEST_STATES} from './order-request.states';
import {OrderPdfEditModalModule} from './order-pdf-edit-modal/order-pdf-edit-modal.module';
import {OrderBoxModule} from './box/order-box.module';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    UIRouterModule.forChild({states: ORDER_REQUEST_STATES}),
    SharedModule,
    OrderReceivedModalModule,
    ProducerModalModule,
    WorkflowPipesModule,
    WorkflowNavigationModule,
    OrderPdfEditModalModule,
    OrderBoxModule,
    NgbTooltip,
  ],
  declarations: [
    WorkflowCnOrderListComponent,
  ]
})
export class OrderRequestModule {
}
