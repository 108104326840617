/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {WorkflowStatusModalComponent} from './workflow-status-modal.component';
import { from } from 'rxjs';
import {WorkflowStatusChangeModalComponent} from './workflow-status-change-modal.component';

@Injectable({providedIn: 'root'})
export class WorkflowStatusModalService {

  constructor(private modalService: NgbModal) {
  }

  open(id: number, typ: 'delete' | 'cancel' | 'status'): Observable<number> {
    const component = typ === 'status' ? WorkflowStatusChangeModalComponent : WorkflowStatusModalComponent;
    const modalRef =  this.modalService.open(component, {windowClass: 'modal2-smm'});
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.typ = typ;
    return from(modalRef.result);
  }

}
