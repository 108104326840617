import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {DunningAutomationComponent} from './dunning-automation.component';
import {UIRouterModule} from '@uirouter/angular';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {DunningListComponent} from './dunning-list.component';
import {DUNNING_STATES} from './dunning.states';
import {MailModule} from '../mail/mail.module';
import {DunningLockModalComponent} from './lock-modal/dunning-lock-modal.component';

@NgModule({
  imports: [
    UIRouterModule.forChild({states: DUNNING_STATES}),
    NgbTooltipModule,
    SharedModule,
    InfiniteScrollModule,
    MailModule,
  ],
  declarations: [
    DunningAutomationComponent,
    DunningListComponent,
    DunningLockModalComponent,
  ],
  exports: [
    DunningLockModalComponent,
  ]
})
export class DunningModule {
}
