<div class="filter-row">
  <div class="filter-left">

    <button class="btn btn-default btn-sm" type="button"
            (click)="badge('true', 'not_delivered', true)"
            [ngClass]="{active: q('true', 'not_delivered')}">
      Nicht übermittelt
    </button>

  </div>
</div>
<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <tr>
      <th>
        <a (click)="sort($event, 'id', 'ASC')" [href]="sortUrl('id', 'ASC')">#</a>
      </th>
      <th>
        <a (click)="sort($event, 'date', 'ASC')" [href]="sortUrl('date', 'ASC')">Mahnung-Datum</a>
      </th>
      <th>
        <a (click)="sort($event, 'date_sent', 'ASC')" [href]="sortUrl('date_sent', 'ASC')">Gesendet</a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'DESC')" [href]="sortUrl('customer_name', 'DESC')">Kunde-Name</a>
      </th>
      <th>Rechnungen</th>
      <th>
        <a (click)="sort($event, 'username', 'ASC')" [href]="sortUrl('username', 'ASC')">Bearbeiter</a>
      </th>
      <th>&nbsp;</th>
    </tr>
    <tr class="filterrow">
      <th>
        <input type="text" class="form-control input-sm" name="dunning_id" [(ngModel)]="data.dunning_id" [autofocus]>
      </th>
      <th>
        <ev-datepicker id="id_date" name="date" class="form-control input-sm" [(ngModel)]="data.date"></ev-datepicker>
      </th>
      <th>
        <ev-datepicker id="id_date_sent" name="date_sent" class="form-control input-sm" [(ngModel)]="data.date_sent">
        </ev-datepicker>
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="customer_name" [(ngModel)]="data.customer_name">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="invoice" [(ngModel)]="data.invoice">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="username" [(ngModel)]="data.username">
      </th>
      <th></th>
    </tr>
    <tr *ngFor="let obj of list.objects; let index = index">
      <td>
        <ev-file-button [objId]="obj.id" type="dunning" [link]="true">
          <i class="fa fa-download"></i> {{ obj.id }}
        </ev-file-button>
      </td>
      <td>{{ obj.date }}</td>
      <td>{{ obj.date_sent }}</td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id: obj.customer_id}">
          {{ obj.customer_name }}
        </a>
      </td>
      <td>
        <ng-container *ngFor="let invoice of obj.invoices; let subIndex = index">
            {{ invoice }}<span *ngIf="subIndex !== obj.invoices.length">,</span>
        </ng-container>
      </td>
      <td>{{ obj.username }}</td>
      <td>
        <div class="label label-success" *ngIf="obj.date_sent">
          Übermittelt
        </div>

        <button type="button" class="btn btn-sm btn-success" (click)="transmit(obj, index)" *ngIf="!obj.date_sent">
          Übermitteln
        </button>

        &nbsp;

        <button type="button" class="btn btn-sm btn-link" (click)="mail(obj, index)" *ngIf="!obj.date_sent">
          <i class="fa fa-envelope fa-2x"></i>
        </button>
      </td>
    </tr>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
