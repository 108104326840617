import {Injectable} from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {KanbanApprovalData, KanbanSaveForm} from './kanban.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class KanbanGeneralService implements ListService<KanbanApprovalData> {

  constructor(private http: HttpClient) {
  }

  list(query?: { [paramName: string]: any; }): Observable<Lister<KanbanApprovalData>> {
    return this.http.get<Lister<KanbanApprovalData>>(
      '/api/v1/workflow/kanban/approval/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  save(id: number, form: KanbanSaveForm): Observable<void> {
    return this.http.post<void>(
      '/api/v1/workflow/kanban/approval/' + id + '/', form
    );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(
      '/api/v1/workflow/kanban/approval/' + id + '/'
    );
  }

  approvalList(id: number, query?: any): Observable<Lister<KanbanApprovalData>> {
    return this.http.get<Lister<KanbanApprovalData> | null>(
      '/api/v1/workflow/kanban/approval/list/' + id + '/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

}
