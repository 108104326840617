import {Component, Input, OnInit} from '@angular/core';
import {BusinessOrderView} from '../models/business-order.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderService} from '../services/business-order.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'business-order-storno-modal',
  templateUrl: './business-order-storno-modal.component.html'
})
export class BusinessOrderStornoModalComponent implements OnInit {
  @Input() ba: BusinessOrderView;
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder,
              private activeModal: NgbActiveModal,
              private service: BusinessOrderService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      reason: [null]
    });
  }

  submit(): void {
    console.log('Submit');
    this.errors = {};
    this.service.storno(this.ba.object.id, this.ba.object.index, this.form.value).subscribe(value => {
      this.alertService.add('success', 'Betriebsauftrag erfolgreich storniert!');
      this.activeModal.close({confirm: true, business_order: value});
    }, (response: HttpErrorResponse) => {
      if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Betriebsauftrag konnte nicht storniert werden!');
    });
  }

  close(): void {
    this.activeModal.dismiss();
  }

}
