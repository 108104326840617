import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LokiConfig, LokiConfigForm} from './config.models';
import {map} from 'rxjs/operators';
import {
  CamFolderServiceInfo,
  CamFolderValidationExcelPath,
  CamFolderValidationPath
} from './network/cam-folder-service/cam-folder-service-info.model';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ConfigService {

  constructor(private http: HttpClient) {
  }

  list(): Observable<{ [key: string]: LokiConfig }> {
    return this.http.get<{ objects: { [key: string]: LokiConfig } }>('/setting/v1/list/').pipe(
      map(val => val.objects)
    );
  }

  broadcast(message: string): Observable<{}> {
    return this.http.post<{}>('/setting/v1/broadcast/', {message: message});
  }

  save(form: LokiConfigForm) {
    return this.http.put<{}>('/setting/v1/save/', form);
  }

  reindex(): Observable<{objects: LokiConfig[], errors: boolean}> {
    return this.http.post<{objects: any, errors: boolean}>('/setting/v1/refresh/', {});
  }

  camFolderServiceInfo(): Observable<CamFolderServiceInfo> {
    return this.http.get<CamFolderServiceInfo>(`${environment.apiv4uri}settings/camfolder/info`);
  }

  camFolderServiceValidate(): Observable<CamFolderValidationPath[]> {
    return this.http.get<CamFolderValidationPath[]>(`${environment.apiv4uri}settings/camfolder/validate`);
  }

  camFolderServiceValidateSubfolder(index: number): Observable<CamFolderValidationPath[]> {
    return this.http.get<CamFolderValidationPath[]>(`${environment.apiv4uri}settings/camfolder/validate/${index}`);
  }

  camFolderServiceValidateExcel(): Observable<CamFolderValidationExcelPath> {
    return this.http.get<CamFolderValidationExcelPath>(`${environment.apiv4uri}settings/camfolder/validate/excel`);
  }

}
