<form [formGroup]="form">
  <div class="whitebox">
    <div class="row">
      <div class="col-sm-2">
        <a class="click" style="color:black;" (click)="close()">
          <i class="fa fa-chevron-right fa-2x"></i>
        </a>
      </div>
      <div class="col-sm-10 align-right"><strong>KW&nbsp;{{week.week}}/{{week.first_day_of_week|date:'yy'}}</strong>
      </div>
    </div>
    <div class="align-right">
      {{week.first_day_of_week|date:'dd.MM.yyyy'}} - {{week.last_day_of_week|date:'dd.MM.yyyy'}}
    </div>
    <table class="table">
      <tr>
        <th></th>
        <th></th>
        <th class="align-right">Möglich</th>
        <th class="align-right">Sicher</th>
      </tr>

      <tr>
        <td><strong>Lagerbestand:</strong></td>
        <td></td>
        <td class="align-right" [ngClass]="{'green': inventory.inventory.amount > 0}">
          {{inventory.inventory.amount|money}}
        </td>
        <td class="align-right" [ngClass]="{'green': inventory.inventory.amount > 0}">
          {{inventory.inventory.amount|money}}
        </td>
      </tr>

      <tr>
        <td><strong>Bestellt:</strong></td>
        <td></td>
        <td class="align-right" [ngClass]="{'green': week.ordered_to_date > 0}">
          {{week.ordered_to_date|money}}
        </td>
        <td></td>
      </tr>

      <tr>
        <td><strong>Bestätigt:</strong></td>
        <td></td>
        <td class="align-right" [ngClass]="{'green': week.confirmed_to_date > 0}">
          {{week.confirmed_to_date|money}}
        </td>
        <td class="align-right" [ngClass]="{'green': week.secure_confirmed_to_date > 0}">
          {{week.secure_confirmed_to_date|money}}
        </td>
      </tr>

      <tr>
        <td><strong>Planung:</strong></td>
        <td></td>
        <td class="align-right" [ngClass]="{'red_font': week.required_to_date > 0}">
          -{{week.required_to_date|money}}
        </td>
        <td class="align-right" [ngClass]="{'red_font': week.required_to_date > 0}">
          -{{week.required_to_date|money}}
        </td>
      </tr>

      <tr>
        <td><strong>Temp. Planung:</strong></td>
        <td class="align-right">
          <input id="temp_amount"
                 class="form-control"
                 style="width: 80px; display: inherit;"
                 min="0"
                 type="number"
                 formControlName="temp_amount">
        </td>
        <td class="align-right" [ngClass]="{'red_font': tempAmount < 0, 'green': tempAmount > 0}">
          {{tempAmount|money}}
        </td>
        <td class="align-right" [ngClass]="{'red_font': tempAmount < 0, 'green': tempAmount > 0}">
          {{tempAmount|money}}
        </td>
      </tr>

      <tr>
        <td><strong>Summe:</strong></td>
        <td></td>
        <td class="align-right" [ngClass]="{'red_font': endAmountPossible < 0, 'green': endAmountPossible >= 0}">
          <strong>{{endAmountPossible|money}}</strong>
        </td>
        <td class="align-right" [ngClass]="{'red_font': endAmountSecure < 0, 'green': endAmountSecure >= 0}">
          <strong>{{endAmountSecure|money}}</strong>
        </td>
      </tr>
    </table>

    <ng-container *ngIf="orderData.data$ | async as list; else loadingOrdersOrError.template">
      <material-order-position-small-list [list]="list"
                                          [selectedWeek]="week"
                                          [currentVariationId]="inventory.inventory.id">
      </material-order-position-small-list>
    </ng-container>

    <loading-or-error #loadingOrdersOrError
                      [loadingWrapper]="orderData"
                      [errorMessage]="'Konnte Material Bestellungen nicht laden.'">
    </loading-or-error>

    <ng-container *ngIf="relationData.data$ | async as list; else loadingRelationsOrError.template">
      <material-planning-small-list [list]="list"
                                    [selectedWeek]="week"
                                    [currentVariationId]="inventory.inventory.id">
      </material-planning-small-list>
    </ng-container>

    <loading-or-error #loadingRelationsOrError
                      [loadingWrapper]="orderData"
                      [errorMessage]="'Konnte BAs nicht laden.'">
    </loading-or-error>
  </div>
</form>
