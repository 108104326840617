<div class="buttonbar-view">
  <material-inventory-navigation [article]="currentArticle"
                                 [variation]="currentVariation"
                                 (updateEmitter)="getLists()">
  </material-inventory-navigation>
</div>

<div class="row">
  <div class="col-sm-12">
    <h3>
      {{currentVariation.variation_id}} -
      {{currentArticle.name}} -
      Art. Nr. Lieferant: {{currentVariation.vendor_article_id}}
    </h3>
    <div>
      Gruppe: {{currentGroup.name}}
    </div>

    <div>
      <material-inventory-field-switch [fieldValues]="currentAggregation.article_fields">
      </material-inventory-field-switch>
    </div>
    <div>
      <material-inventory-field-switch [fieldValues]="currentAggregation.variation_fields">
      </material-inventory-field-switch>
    </div>
  </div>
  <div class="col-sm-12">
    &nbsp;
  </div>
  <div class="col-sm-12">
    &nbsp;
  </div>
</div>


<div class="row" *ngIf="currentInventory">
  <div class="col-sm-12">
    <h3>
      Lagerbestand
      <button class="btn btn-link"
              [ngbTooltip]="'Nullbestände ' + (showAll ? 'ausblenden' : 'einblenden')"
              (click)="toggleShowAll()">
        <i class="fa" [ngClass]="{'fa-eye': showAll, 'fa-eye-slash': !showAll}">
        </i>
      </button>
    </h3>

    <table class="table whitetable">
      <thead>
      <tr>
        <th>Lagerplatz</th>
        <th colspan="2">Wareneingang</th>
        <th class="align-right" style="width: 170px;">
          <div ngbTooltip="Anzahl der Ware, die am selben Tag und unter gleicher Chargennummer eingebucht wurde">
            Menge Wareneingang
          </div>
        </th>
        <th class="icon" *ngIf="('material.order.show.transaction.links'|HasPerm)"></th>
        <th>Chargennummer</th>
        <th class="align-right" *ngIf="('material.order.price.check'|HasPerm)">Einzelpreis</th>
        <th class="align-right" colspan="2" *ngIf="currentArticle.konsi">Konsi Bestand</th>
        <th class="align-right" colspan="2" *ngIf="currentArticle.konsi">Eigen Bestand</th>
        <th class="align-right" [colSpan]="currentArticle.konsi ? 1 : 2">Gesamt Bestand</th>
        <th>Einheit</th>
        <th class="align-right" *ngIf="('material.order.price.check'|HasPerm)">Materialwert</th>
        <th *ngIf="currentGroup.has_expiration">Haltbar bis</th>
        <th>
          <material-label-print-button [variationId]="currentVariation.id" classInner="fa-2x">
          </material-label-print-button>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let storage of currentInventory; trackBy:trackByFn; let index = index"
          [material-inventory-storage-row]="storage"
          [material-inventory-storage-row-has-expiration]="currentGroup.has_expiration"
          [material-inventory-storage-row-variation]="currentVariation"
          (material-inventory-storage-row-update)="getLists()">
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="4"></td>
        <td class="icon" *ngIf="('material.order.show.transaction.links'|HasPerm)"></td>
        <td></td>
        <td class="align-right" *ngIf="('material.order.price.check'|HasPerm)"></td>
        <td class="align-right" *ngIf="currentArticle.konsi">{{totalAmount.konsi | money}}</td>
        <td *ngIf="currentArticle.konsi"></td>
        <td class="align-right" *ngIf="currentArticle.konsi">{{totalAmount.storage | money}}</td>
        <td *ngIf="currentArticle.konsi"></td>
        <td class="align-right">{{totalAmount.amount | money}}</td>
        <td *ngIf="!currentArticle.konsi"></td>
        <td>{{currentVariation?.unit}}</td>
        <td class="align-right" *ngIf="('material.order.price.check'|HasPerm)">
          {{totalAmount.priceTotal|money:2}}&nbsp;&euro;
        </td>
        <td *ngIf="currentGroup.has_expiration"></td>
        <td class="icon"></td>
      </tr>
      </tfoot>
    </table>
  </div>
</div>

<material-article-order-list [variation]="currentVariation" *ngIf="'material.order.view'|HasPerm">
</material-article-order-list>

<div class="row"><div class="col-sm-12">&nbsp;</div></div>
<div class="row"><div class="col-sm-12">&nbsp;</div></div>

<material-inventory-transaction-list [currentArticle]="currentArticle"
                                     [currentVariation]="currentVariation"
                                     [currentTransactionList]="transactions"
                                     *ngIf="transactionLister.data$ | async as transactions; else loadingOrError.template">
</material-inventory-transaction-list>

<loading-or-error #loadingOrError
                  [loadingWrapper]="transactionLister"
                  [errorMessage]="'Konnte Bestandsverlauf nicht laden'">
</loading-or-error>
