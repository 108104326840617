import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ExtraCost, ExtraCostForm, ExtraCostList} from '../models/offer-extra.models';
import {map} from 'rxjs/operators';
import {EnvisiaObject} from '../../../common/wrapper.models';

@Injectable({providedIn: 'root'})
export class OfferExtraService {

  constructor(private http: HttpClient) {
  }

  list(): Observable<ExtraCostList> {
    return this.http.get<{ objects: ExtraCostList }>(
      '/api/v2/workflow/extracost/'
    ).pipe(map(val => val.objects));
  }

  adminList(): Observable<ExtraCostList> {
    return this.http.get<{ objects: ExtraCostList }>(
      '/api/v2/workflow/extracost/admin/'
    ).pipe(map(val => val.objects));
  }

  create(typ: string, form: ExtraCostForm): Observable<ExtraCost> {
    return this.http.post<EnvisiaObject<ExtraCost>>(
      '/api/v2/workflow/extracost/admin/' + typ + '/',
      form
    ).pipe(map(val => val.object));
  }

  update(id: number, form: ExtraCostForm): Observable<ExtraCost> {
    return this.http.put<EnvisiaObject<ExtraCost>>(
      '/api/v2/workflow/extracost/admin/' + id + '/',
      form
    ).pipe(map(val => val.object));
  }

  last(typ: string): Observable<number> {
    return this.http.get<EnvisiaObject<number>>(
      '/api/v2/workflow/extracost/admin/last/' + typ + '/'
    ).pipe(map(val => val.object));
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>('/api/v2/workflow/extracost/admin/' + id + '/');
  }

}
