<form [formGroup]="searchForm" class="form-horizontal">
  <div class="form-group">
    <label class="col-sm-2 control-label" for="query">
      Artikel Gruppen Suche:
    </label>
    <div class="col-sm-10">
      <input class="form-control" type="text" id="query" formControlName="searchQuery">
    </div>
  </div>
</form>

<div class="row">
  <div class="col-sm-12">
    &nbsp;
  </div>
</div>
<div class="whitelist" *ngIf="showGroups()">
  <ng-container *ngIf="currentGroup">
    <div class="whitelist-item selected" (click)="toggleSelectGroup(currentGroup)">
      <i class="fa fa-check"></i> {{currentGroup.group_id}} {{currentGroup.name}}
    </div>
  </ng-container>
  <ng-container *ngIf="!currentGroup">
    <div class="whitelist-item" [ngClass]="isGroupSelected(group)? 'selected' : ''" (click)="toggleSelectGroup(group)"
         *ngFor="let group of groups">
      <i class="fa fa-check" [ngClass]="isGroupSelected(group)?'':'hide-icon'"></i> {{group.group_id}} {{group.name}}
    </div>
  </ng-container>
</div>
<div class="whitelist" *ngIf="showArticles()">
  <ng-container *ngIf="currentArticle">
    <div class="whitelist-item selected" (click)="toggleSelectArticle(currentArticle)">
      <i class="fa fa-check"></i> {{currentArticle.article_id}} {{currentArticle.name}}
    </div>
  </ng-container>
  <ng-container *ngIf="!currentArticle">
    <div class="whitelist-item" [ngClass]="isArticleSelected(article)? 'selected' : ''"
         (click)="toggleSelectArticle(article)" *ngFor="let article of articles">
      <i class="fa fa-check" [ngClass]="isArticleSelected(article)?'':'hide-icon'"></i> {{article.article_id}}
      {{article.name}}
    </div>
  </ng-container>
</div>
<div class="whitelist" *ngIf="showVariations()">
  <ng-container *ngIf="currentVariation">
    <div class="whitelist-item selected" (click)="toggleSelectVariation(currentVariation)">
      <i class="fa fa-check"></i> {{currentVariation.variation_id}} {{currentVariation.vendor_article_id}}
    </div>
  </ng-container>
  <ng-container *ngIf="!currentVariation">
    <div class="whitelist-item" [ngClass]="isVariationSelected(variation)? 'selected' : ''"
         (click)="toggleSelectVariation(variation)" *ngFor="let variation of variations">
      <i class="fa fa-check" [ngClass]="isVariationSelected(variation)?'':'hide-icon'"></i> {{variation.variation_id}}
      {{variation.vendor_article_id}}
    </div>
  </ng-container>
</div>
<ng-container *ngIf="!!searchResults && !currentVariation">
  <ng-container *ngIf="searchResults.length === 0">
    Keine Artikel mit diesen Eigenschaften gefunden
  </ng-container>
  <ng-container *ngIf="searchResults.length > 0">
    <table class="whitetable">
      <thead>
      <tr>
        <th>Nr.</th>
        <th>Gruppe</th>
        <th *ngIf="level > 1">Artikel</th>
        <th *ngIf="level > 2">Art. Nr. Lieferant</th>
        <th *ngIf="level > 2">Passende Felder</th>
      </tr>
      </thead>
      <tbody>
      <tr (click)="toggleSelectResult(result)" *ngFor="let result of searchResults">
        <td>{{result.string_id}}</td>
        <td>{{result.group_name}}</td>
        <td *ngIf="level > 1">{{result.article_name}}</td>
        <td *ngIf="level > 2">{{result.vendor_article_id}}</td>
        <td *ngIf="level > 2">{{result.matching_fields ? stringHelper.concat(result.matching_fields) : ''}}</td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>
