import * as React from 'react';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {InventoryGroup, StorageBin} from '../inventory.models';
import {ArticleInventoryTransferWorkflowForm} from './article-inventory-transfer-workflow-form';

const containerElementName = 'reactArticleInventoryForm';

@Component({
  selector: 'article-inventory-transfer-workflow-form',
  template: `<div #${containerElementName}></div>`,
})
export class ArticleInventoryTransferWorkflowFormComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() oaNr: string;
  @Input() inventory: InventoryGroup;
  @Input() storage: StorageBin[];
  @Input() workflowId: number;
  @Input() workflowIds: number[];
  @Output() setActiveInventory: EventEmitter<InventoryGroup> = new EventEmitter<InventoryGroup>();

  constructor() {
    super();
  }

  protected override renderComponent() {
    const _ = React.version;
    return <ArticleInventoryTransferWorkflowForm
      oaNr={this.oaNr}
      inventory={this.inventory}
      storage={this.storage}
      workflowId={this.workflowId}
      workflowIds={this.workflowIds}
      back={() => this.setActiveInventory.emit(null)}
      update={() => this.setActiveInventory.emit(null)}/>;
  }
}
