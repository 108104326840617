<div class="whitelist-item-head" [ngClass]="fetchStatusClass(fetch.status)">
  <div class="col-sm-9">
    <h4 *ngIf="fetch.status < 4 || fetch.status > 4" style="display: inline-block;padding:4px;">
      ABRUF {{ fetch.ordering }}&nbsp;
      <span class="label label-danger" *ngIf="fetch.is_complaint">Reklamation</span>&nbsp;
      (#{{fetch.id}}) - {{ fetchStatusName(fetch.status) }}
    </h4>

    <h4 *ngIf="fetch.status === 4" style="display: inline-block;padding:4px;">
      ABRUF {{ fetch.ordering }}&nbsp;
      <span class="label label-danger" *ngIf="fetch.is_complaint">Reklamation</span>&nbsp;
      (#{{fetch.id}}) - Geliefert
      &nbsp;
      <span *ngIf="fetch.delivery_obj.invoice_lock" class="label label-danger ng-scope">
          Gesperrt für Rechnungen
        </span>
    </h4>
    <a style="display: inline-block;padding: 5px;"
       type="button"
       (click)="open()"
       *ngIf="('fetch.change.status'|HasPerm)">
      <strong>Status&auml;nderung</strong>
    </a>
  </div>
  <div class="col-sm-3">
    <div class="pull-right" style="margin-top: 3px;">
      <span class="label label-danger" *ngIf="fetch.earliest_delivery">Frühestmöglicher Liefertermin</span>
      &nbsp;|&nbsp;
      <strong>Liefertermin: {{ fetch.delivery }}</strong>
    </div>
  </div>
</div>
<div class="document-row">
  <div class="document-row-cell">
    Auft.-Best.:
    <span *ngIf="fetch.contract_obj">
      <ev-file-button [objId]="fetch.contract_obj.id" type="contract">
        <i class="fa fa-file-pdf-o"></i> <strong>{{ fetch.contract_obj.id }}</strong> -
        {{ fetch.contract_obj.date }}
        <span class="label label-purple" *ngIf="fetch.contract_obj.date_sent">Versendet</span>
      </ev-file-button>
      <button type="button" class="btn btn-link btn-sm"
              title="Historie anzeigen"
              (click)="history('contract', fetch.contract_obj.id, 'Auftragshistorie')">
        <i class="fa fa-clock-o"></i>
      </button>
      <button type="button"
              class="btn btn-link btn-sm"
              title="per Mail versenden"
              (click)="mail()">
        <i class="fa fa-envelope"></i>
      </button>
      </span>
  </div>

  <div class="document-row-cell">
    <div style="display: block;" *ngIf="fetch.status === 4 || fetch.status === 5">
      Lieferschein:
      <ev-file-button [objId]="fetch.delivery_obj.id" type="delivery">
        <i class="fa fa-file-pdf-o"></i> <strong>{{ fetch.delivery_obj.id }}</strong> -
        {{ fetch.delivery_obj.date }}
      </ev-file-button>
      <button type="button" class="btn btn-link btn-sm"
              title="Historie anzeigen"
              (click)="history('delivery', fetch.delivery_obj.id, 'Lieferscheinhistorie')">
        <i class="fa fa-clock-o"></i>
      </button>
      &nbsp;
    </div>
  </div>

  <div class="document-row-cell">
    <div style="display: block;" *ngIf="fetch.status === 5">
      Rechnung:

      <div *ngIf="fetch.invoice_obj">
        <ev-file-button [objId]="fetch.invoice_obj.id" type="invoice">
          <i class="fa fa-file-pdf-o"></i>
          <strong>{{ fetch.invoice_obj.id }}</strong> - {{ fetch.invoice_obj.date }}
        </ev-file-button>

        <button type="button" class="label label-default label-button"
                *ngIf="fetch.invoice_obj.status.id === invoiceState(0).id"
                (click)="invoiceSend()">
          Rechnung übermitteln
        </button>

        <div *ngIf="fetch.invoice_obj.status.id === invoiceState(1).id" class="label label-warning">
          {{ invoiceState(1).name }}
        </div>
        <div *ngIf="fetch.invoice_obj.status.id === invoiceState(2).id" class="label label-warning">
          {{ invoiceState(2).name }}
        </div>
      </div>
      <div *ngIf="!fetch.invoice_obj">Alte Rechnung (vom System entfernt)</div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <table class="table inBoxtable">
      <thead>
      <tr>
        <th>Bezeichnung</th>
        <th class="right">Menge</th>
        <th class="right">Preis</th>
        <th class="right" *ngIf="hasPriceIncrease">Preis + Zuschlag</th>
        <th class="right">Gesamt</th>
      </tr>
      </thead>
      <tbody>
      <tr [fetch-box-position]="pos"
          [fetch-box-position-has-price-increase]="hasPriceIncrease"
          *ngFor="let pos of fetch.positions">
      </tr>

      <tr class="no-border padding-top" *ngIf="!isEuWithDeVat && fetch.article_weight?.data$|async as weight">
        <td>Gewicht LP:&nbsp;{{ weight.board }}</td>
        <td [colSpan]="!hasPriceIncrease ? 3 : 4"></td>
      </tr>

      <tr class="no-border padding-top" *ngIf="!isEuWithDeVat && fetch.article_weight?.data$|async as weight">
        <td><strong>Gewicht Gesamt:&nbsp;{{ weight.total }}</strong></td>
        <td [colSpan]="!hasPriceIncrease ? 3 : 4"></td>
      </tr>

      <tr class="no-border padding-top" *ngIf="!isEuWithDeVat">
        <td>Auslands-Lieferung: {{ country }}</td>
        <td [colSpan]="!hasPriceIncrease ? 1 : 2"></td>
        <td class="right"><strong>Summe:</strong></td>
        <td class="right"><strong>{{ fetch.price_total|money:2 }}&nbsp;€</strong></td>
      </tr>

      <tr class="no-border padding-top" *ngIf="isEuWithDeVat">
        <td>
          <ng-container *ngIf="fetch.article_weight?.data$|async as weight">
            Gewicht LP:&nbsp;{{ weight.board }}
          </ng-container>
        </td>
        <td [colSpan]="!hasPriceIncrease ? 1 : 2"></td>
        <td class="right">Summe:</td>
        <td class="right">{{ fetch.price_total|money:2 }}&nbsp;€</td>
      </tr>

      <tr class="no-border padding-top" *ngIf="isEuWithDeVat">
        <td>
          <ng-container *ngIf="fetch.article_weight?.data$|async as weight">
            <strong>Gewicht Gesamt:&nbsp;{{weight.total}}</strong>
          </ng-container>
        </td>
        <td [colSpan]="!hasPriceIncrease ? 1 : 2"></td>
        <td class="right">USt.&nbsp;{{ fetch.vat|money:2 }}%:</td>
        <td class="right">{{ fetch.price_ust|money:2 }}&nbsp;€</td>
      </tr>

      <tr class="no-border padding-top" *ngIf="isEuWithDeVat">
        <td [colSpan]="!hasPriceIncrease ? 2 : 3"></td>
        <td class="right"><strong>Gesamt:</strong></td>
        <td class="right"><strong>{{ fetch.price_total_ust|money:2 }}&nbsp;€</strong></td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
