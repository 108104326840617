import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {IndustrialSectorForm} from '../../industrial-sector/producer.models';

@Injectable({providedIn: 'root'})
export class CustomerIndustrialSectorService {

  constructor(private http: HttpClient) {
  }

  updateProducers(customerId: number, form: IndustrialSectorForm): Observable<void> {
    return this.http.put<void>( environment.apiv4uri + 'customer/' + customerId + '/industrial-sector', form);
  }
}
