/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {StreamDataService} from './stream/stream-data.service';
import {GlobalService} from '../../core/global.service';
import {Subscription} from 'rxjs';
import {SessionStorage} from '../../common/storage/session-storage';
import {InitData} from '../../common/init-data/init-data';
import {LocalStorage} from '../../common/storage/local-storage';
import {InvoiceJobErrorStore} from '../workflow/invoice/job-error/invoice-job-error.store';
import {AuthService} from '../auth/auth.service';
import {RightService} from '../../common/right-service/right.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./menu-fa-stack.scss'],
  styles: ['.job-error { color: #fff !important; background: #d9534f !important;  }']
})
export class BaseComponent implements OnInit, OnDestroy {
  public state: StateService;
  status: { isopen: boolean };
  share: { price: boolean, print_failed: boolean } = {price: false, print_failed: false};
  loader: GlobalService;
  year: number = new Date().getFullYear();
  private subscription?: Subscription;
  private versionSubscription?: Subscription;
  needsReload = false;
  environment: string;
  jobErrorsData: { value: number };
  start = false;
  hasAnyRights = false;

  constructor(private stateService: StateService,
              private streamDataService: StreamDataService,
              private globalService: GlobalService,
              private sessionService: SessionStorage,
              private localStorage: LocalStorage,
              private invoiceJobErrorStore: InvoiceJobErrorStore,
              private authService: AuthService,
              private rightService: RightService) {
    this.state = stateService;
    this.loader = globalService;
  }

  ngOnInit(): void {
    this.checkForAnyRights();
    this.jobErrorsData = this.invoiceJobErrorStore.current;

    this.start = this.stateService.current.name === 'start';

    this.environment = this.localStorage.getObject<InitData>('data').environment;

    this.subscription = this.streamDataService.subscribeStateful(model => {
      this.share.price = model.price;
      this.share.print_failed = model.print_failed;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
  }

  resetPrice(event): void {
    this.streamDataService.nextPrice(false);
  }

  resetPrintState(event): void {
    this.streamDataService.nextPrintState(false);
  }

  checkForAnyRights(): void {
    this.hasAnyRights = this.rightService.hasAny();
  }

  logout(confirm?: boolean): void {
    if (!confirm || window.confirm('Möchten Sie sich wirklich abmelden?')) {
      this.authService.logout();
    }
  }

  resetPage(): void {
    this.sessionService.removeItem('authenticated');
    window.location.reload();
  }

}
