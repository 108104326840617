import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {MaterialGroupPermissionForm, MaterialUserGroupRelation} from './material-user-list.models';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaterialUserService {
  uri = `${environment.apiv4uri}material/group/permission`;

  constructor(private http: HttpClient) {
  }

  create(form: MaterialGroupPermissionForm): Observable<MaterialUserGroupRelation> {
    return this.http.post<MaterialUserGroupRelation>(this.uri, form);
  }

  delete(form: MaterialGroupPermissionForm): Observable<MaterialUserGroupRelation> {
    return this.http.delete<MaterialUserGroupRelation>(this.uri, {params: EnvisiaLocation.httpParams(form)});
  }

  list(userId: number): Observable<MaterialUserGroupRelation[]> {
    return this.http.get<MaterialUserGroupRelation[]>(`${this.uri}/list/${userId}`);
  }

  visibilityGet(): Observable<boolean> {
    return this.http.get<{ visible: boolean }>(`${environment.apiv4uri}material/group/visibility`)
      .pipe(map(vis => vis.visible));
  }

  visibilitySet(visibility: boolean): Observable<{ visible: boolean }> {
    return this.http.put<{ visible: boolean }>(
      `${environment.apiv4uri}material/group/visibility`,
      {visible: visibility},
    );
  }
}
