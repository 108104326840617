import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Customer} from '../model/customer';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerNoteModalComponent} from './customer-note-modal.component';
import {CustomerSelectModalComponent} from './customer-select-modal.component';
import {CustomerService} from '../services/customer.service';
import {Observable, of, from} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {noop} from '../../../helper/noop';

export const EV_CUSTOMER_BOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomerBoxComponent),
  multi: true,
};

export const EV_CUSTOMER_BOX_VALUE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CustomerBoxComponent),
  multi: true
};

@Component({
  selector: 'customer-box',
  templateUrl: './customer-box.component.html',
  providers: [
    EV_CUSTOMER_BOX_VALUE_ACCESSOR,
    EV_CUSTOMER_BOX_VALUE_VALIDATOR,
  ]
})
export class CustomerBoxComponent implements ControlValueAccessor, Validator {
  // FIXME: outer value's might be unsupported (please remove comment after testing on something like article)
  @Input() customer?: Customer | { name: string } | { name: string, id: number } | null;
  @Input() rights = true;
  @Input() clear = true;
  @Input() withAddress = false;
  /** Form Related */
  @Input() disabled = false;
  @Input() realValue = false;
  @Input() customerIdValue = false;
  @Input() fullDisabled = true;
  @Input() writeBackValue = true;
  @Input() disabledHeader = false;
  @Input() buttonLabel: string | null = 'Bearbeiten';
  @Input() showSpecialProductionLabel = false;
  /** End: Form Related */

  @Output() updateEmitter = new EventEmitter<Customer | null | undefined>();
  validationError = false;
  formDisabled = false;
  private formInit = false;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  @Input() onSave: (customer: Customer) => Observable<any> = (_: Customer) => of({}); // FIXME: might be unneeded

  constructor(private customerService: CustomerService,
              private modalService: NgbModal) {
  }

  writeValue(obj: any): void {
    if (obj === null || obj === undefined) {
      this.customer = null;
    } else if (obj === 'undefined') {
      this.customer = null;
    } else if (typeof obj === 'string') {
      this.customer = {name: obj};
    } else if (obj.hasOwnProperty('id')) {
      this.customer = obj;
      // write back the id value
      if (this.customerIdValue || this.writeBackValue) {
        this.doChange(this.customer as Customer);
      }
    } else {
      // backup
      this.customer = null;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
    if (!this.formInit) {
      this.formInit = true;
      // write back form values
      // on form register
      if (this.customer && this.writeBackValue) {
        if (this.realValue) {
          this._onChange(this.customer);
        } else {
          this._onChange(this.customer.name);
        }
      } else if (this.writeBackValue) {
        this._onChange(null);
      }
    }
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.fullDisabled) {
      this.disabled = isDisabled;
    } else {
      this.formDisabled = isDisabled;
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    this.validationError = c.errors !== null;
    return null;
  }

  switchCustomer(): void {
    const currentName = this.customer ? this.customer.name : undefined;
    this.customerService.list({name: currentName}).pipe(
      mergeMap(data => {
        const modalRef = this.modalService.open(CustomerSelectModalComponent);
        modalRef.componentInstance.customers = data.objects;
        modalRef.componentInstance.onSave = this.onSave;
        modalRef.componentInstance.currentName = currentName;
        return from(modalRef.result);
      })
    ).subscribe((customer?: Customer) => {
      if (customer) {
        this.customer = customer;
        this.doChange(customer);
      } else {
        this.clearCustomer();
      }
    }, () => {
    });
  }

  clearCustomer(): void {
    this.customer = undefined;
    this._onChange(null);
    this.updateEmitter.next(null);
  }

  showNote(): void {
    if (!!this.customer && this.customer.hasOwnProperty('id')) {
      const modalRef = this.modalService.open(CustomerNoteModalComponent, {windowClass: 'modal2-lg'});
      modalRef.componentInstance.customer = this.customer as Customer;
      modalRef.result.then(() => {
      }, ignoreRejection);
    }
  }

  get customerObj(): Customer | null {
    // if our obj has an id, we know that we have a customer instead of just a name
    if (!!this.customer && this.customer.hasOwnProperty('id')) {
      return this.customer as Customer;
    } else {
      return null;
    }
  }

  escape(name: string): string {
    noop(this);
    return encodeURIComponent(name);
  }

  canBeCleared(): boolean {
    return !!this.customer && this.clear;
  }

  private doChange(customer: Customer): void {
    if (this.realValue) {
      this._onChange(customer);
    } else if (this.customerIdValue) {
      this._onChange(customer.id);
    } else {
      this._onChange(customer.name);
    }
    this.updateEmitter.next(customer);
  }

  get isSpecialCustomer(): boolean {
    return this.customer ? !!this.customer['allow_special_production'] : false;
  }
}
