import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../../models/article-specification.model';

@Component({
  selector: 'article-electroplating-form',
  templateUrl: './article-electroplating-form.component.html'
})
export class ArticleElectroplatingFormComponent implements OnInit {
  @Input() errors: { [key: string]: any; } = {};
  @Input() form: UntypedFormGroup;
  @Input() refCount = 0;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() title = 'Galvanik';

  idTag: string;

  constructor() { }

  ngOnInit(): void {
    switch (this.title) {
      case 'Galvanik Kern':
        this.idTag = '_core';
        break;
      case 'Galvanik Via':
        this.idTag = '_via';
        break;
      default:
        this.idTag = '';
        break;
    }
  }

}
