import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {MaterialGroup, MaterialGroupFormValue} from './material-group.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialGroupService {
  uri = `${environment.apiv4uri}material/groups/`;

  constructor(private http: HttpClient) {
  }

  create(form: MaterialGroupFormValue): Observable<MaterialGroup> {
    return this.http.post<MaterialGroup>(this.uri, form);
  }

  update(form: MaterialGroupFormValue, groupId: number): Observable<MaterialGroup> {
    return this.http.put<MaterialGroup>(`${this.uri}${groupId}`, form);
  }
}
