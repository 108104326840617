/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Holiday, HolidayForm} from './holiday.model';
import {EnvisiaObject, EnvisiaObjects} from '../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HolidayService {

  private uri = '/api/v1/holiday/';

  constructor(private http: HttpClient) {
  }

  list(): Observable<Holiday[]> {
    return this.http.get<EnvisiaObjects<Holiday>>(this.uri + 'manage/').pipe(map(obj => obj.objects));
  }

  del(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + 'delete/' + id + '/');
  }

  create(form: HolidayForm): Observable<Holiday> {
    return this.http.post<EnvisiaObject<Holiday>>(this.uri, form).pipe(map(value => value.object));
  }

  listAsObject(): Observable<{ [year: string]: { [month: string]: number[] } }> {
    return this.http.get<{ [year: string]: { [month: string]: number[] } }>('/api/v1/holiday/');
  }
}
