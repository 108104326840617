import {Component, ErrorHandler, Input, OnInit} from '@angular/core';
import {Lister} from '../../../common/wrapper.models';
import {UserListService} from './user-list.service';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {MinimalUser} from '../../user/minimal-user.models';
import {MaterialUserService} from './material-user.service';
import {MaterialGroup} from '../groups/material-group.model';
import {MaterialGroupAndRelationWithClasses, MaterialUserGroupRelation} from './material-user-list.models';
import {noop} from '../../../helper/noop';
import {MaterialProductGroupListElement} from '../product-groups/material-product-groups.models';
import {MaterialUserProductGroupService} from './material-user-product-group.service';
import {forkJoin} from 'rxjs';
import {
  MaterialProductGroupAndRelationWithClasses,
  MaterialProductGroupUserRelation
} from './material-user-product-group-permission.models';
import {MaterialProductGroupsService} from '../product-groups/material-product-groups.service';

@Component({
  selector: 'material-user-list',
  templateUrl: './material-user-list.component.html',
})
export class MaterialUserListComponent
  extends SearchBaseComponent<UserListService, MinimalUser>
  implements OnInit {
  // userlist
  @Input() listData: Lister<MinimalUser>;
  @Input() productGroups: Lister<MaterialProductGroupListElement>;
  @Input() groups: Lister<MaterialGroup>;

  // current user data
  currentUser?: MinimalUser = null;
  currentUserPermissions?: MaterialProductGroupAndRelationWithClasses[] = null;
  currentUserPermissionUserGroups?: MaterialGroupAndRelationWithClasses[] = null;
  currentUserLock = false;

  // Group visibility
  @Input() groupVisibility: boolean;
  groupVisibilityClasses: {[key: string]: boolean};

  protected type = 'material-user-list';

  constructor(private materialUserService: MaterialUserService,
              private materialUserProductGroupService: MaterialUserProductGroupService,
              private materialProductGroupService: MaterialProductGroupsService,
              protected service: UserListService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.groupVisibilitySet(this.groupVisibility);
  }

  toggleVisibility() {
    this.materialUserService.visibilitySet(!this.groupVisibility).subscribe(obj => {
      this.groupVisibilitySet(obj.visible);
    });
  }

  groupVisibilitySet(visible: boolean) {
    this.groupVisibility = visible;
    this.groupVisibilityClasses = {
      'fa-toggle-on': !!this.groupVisibility,
      'green': !!this.groupVisibility,
      'fa-toggle-off': !this.groupVisibility,
    };
  }

  setCurrentUser(user: MinimalUser): void {
    forkJoin([
      this.materialUserProductGroupService.list(user.id),
      this.materialUserService.list(user.id),
      this.materialProductGroupService.list(),
    ]).subscribe(relations => {
      this.productGroups = relations[2];
      this.currentUserPermissions = this.productGroups.objects.map(pg => {
        return this.generateProductGroup(pg, relations[0], relations[1]);
      });
      const usedGroupIds = [];
      this.currentUserPermissions.forEach(p => {
        p.groups.forEach(mg => {
          usedGroupIds.push(mg.group.id);
        });
      });
      this.currentUserPermissionUserGroups = this.groups.objects
        .filter(mg => !usedGroupIds.find(id => id === mg.id))
        .map(mg => {
          return this.generateGroup(mg, relations[1]);
        });
      this.currentUser = user;
    });
  }

  generateProductGroup(
    productGroup: MaterialProductGroupListElement,
    productGroupRelations: MaterialProductGroupUserRelation[],
    groupRelations: MaterialUserGroupRelation[],
  ): MaterialProductGroupAndRelationWithClasses {
    const productGroupPermission = productGroupRelations.find(mug => mug.product_group_id === productGroup.product_group.id);
    return {
      productGroup: productGroup.product_group,
      groups: productGroup.groups.map(mg => this.generateGroup(mg, groupRelations)),
      relation: productGroupPermission,
      classes: {
        'fa-toggle-on': !!productGroupPermission,
        'green': !!productGroupPermission,
        'fa-toggle-off': !productGroupPermission,
      },
    };
  }

  generateGroup(
    group: MaterialGroup,
    groupRelations: MaterialUserGroupRelation[],
  ): MaterialGroupAndRelationWithClasses {
    const groupPermission = groupRelations.find(mug => mug.group_id === group.id);
    return {
      group: group,
      relation: groupPermission,
      classes: {
        'fa-toggle-on': !!groupPermission,
        'green': !!groupPermission,
        'fa-toggle-off': !groupPermission,
      },
    };
  }

  toggleProductGroup(reference: MaterialProductGroupAndRelationWithClasses): void {
    this.currentUserLock = true;
    const form = {user_id: this.currentUser.id, product_group_id: reference.productGroup.id};
    (!reference.relation ?
        this.materialUserProductGroupService.create(form) :
        this.materialUserProductGroupService.delete(form)
    ).subscribe(() => {
      this.setCurrentUser(this.currentUser);
      this.currentUserLock = false;
    }, () => {
      this.currentUserLock = false;
    });
  }

  toggleGroup(reference: MaterialGroupAndRelationWithClasses): void {
    this.currentUserLock = true;
    const form = {user_id: this.currentUser.id, group_id: reference.group.id};
    (!reference.relation ?
        this.materialUserService.create(form) :
        this.materialUserService.delete(form)
    ).subscribe(() => {
      this.setCurrentUser(this.currentUser);
      this.currentUserLock = false;
    }, () => {
      this.currentUserLock = false;
    });
  }

  get currentUserName(): string {
    if (!!this.currentUser?.name_first && !!this.currentUser?.name_last) {
      return `(${this.currentUser.name_last}, ${this.currentUser.name_first})`;
    } else if (!!this.currentUser?.name_last) {
      return `(${this.currentUser.name_last})`;
    } else if (!!this.currentUser?.name_first) {
      return `(${this.currentUser.name_first})`;
    } else {
      return '';
    }
  }

  trackByProductGroupFn(index: number, item: MaterialProductGroupAndRelationWithClasses): number {
    noop(this);
    return item.productGroup.id;
  }

  trackByGroupFn(index: number, item: MaterialGroupAndRelationWithClasses): number {
    noop(this);
    return item.group.id;
  }
}
