/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import { EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Directive } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  ShippingInstruction,
  ShippingInstructionHistory,
  ShippingInstructionSchema,
  ShippingInstructionSchemaDictionary,
  ShippingInstructionType
} from '../../article/models/article-extra.models';
import {Subscription} from 'rxjs';
import {ShippingInstructionDelete} from '../article-extra.models';

@Directive()
export abstract class ArticleExtraBaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() schema: ShippingInstructionSchemaDictionary;
  @Input() data: ShippingInstruction | ShippingInstructionHistory;
  @Input() mainType: ShippingInstructionType;
  @Input() readOnly = false;
  @Input() historyType?: 'old' | 'new';
  @Output() deleteCalled = new EventEmitter<ShippingInstructionDelete>();
  @Output() updateEmitter = new EventEmitter<{ data: any, name: string }>();
  public form: UntypedFormGroup;
  public defaultValues: { [key: string]: ShippingInstructionSchema };
  public type: ShippingInstructionType;
  private formSubscription?: Subscription;

  public abstract formName: string;
  protected abstract schemaName: string;
  protected abstract fields: string[];

  protected abstract fb: UntypedFormBuilder;

  protected formData(): { [key: string]: any | null | undefined } {
    const obj = {};
    const data = this.data[this.formName] ?? {};
    const history = this.data[this.formName + '_old'];
    const fullData = this.data['full_data'];
    this.fields.forEach(field => {
      switch (this.historyType) {
        case 'new':
          // Old history objects (fullData is falsy) only store changed values and need to be merged.
          // See #2213 for details.
          if (!!data && (fullData || !!data[field] || data[field] === false || data[field] === '')) {
            obj[field] = data[field];
          } else if (!!history && !!history[field]) {
            obj[field] = history[field];
          } else {
            obj[field] = null;
          }
          break;
        case 'old':
          obj[field] = !history ? null : history[field] ?? null;
          break;
        default:
          obj[field] = !data ? null : data[field] ?? null;
          break;
      }
    });
    return obj;
  }

  ngOnInit(): void {
    console.log('Schema:', this.schemaName, this.schema, this.schema[this.schemaName]);
    this.defaultValues = this.schema[this.schemaName];
    this.type = this.data[this.formName + '_type'];

    const formGroupData = {};
    for (const [key, value] of Object.entries(this.formData())) {
      formGroupData[key] = [{value: value, disabled: this.readOnly}];
    }

    this.form = this.fb.group(formGroupData);

    this.formSubscription = this.form.valueChanges.subscribe(values => {
      this.updateEmitter.emit({data: values, name: this.formName});
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.data.isFirstChange()) {
      this.type = this.data[this.formName + '_type'];
      this.form.patchValue(this.formData(), {emitEvent: false});
    }
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
