<td>{{storage.location}}</td>

<td style="width: 75px">{{storage.date_received}}</td>

<td style="width: 70px" class="align-right">
  <span class="label" [ngClass]="{'label-info': konsiNow, 'label-default': !konsiNow}">
    {{storage.days_stored|money:0}}&nbsp;Tage
  </span>
</td>

<td class="align-right">{{storage.amount_intake|money}}</td>

<td class="icon" *ngIf="('material.order.show.transaction.links'|HasPerm)">
  <ng-container *ngIf="(!!storage.order_transaction_id)">
    <a uiSref="a.material.order.intake"
       ngbTooltip="Zur Einbuchung in Bestellung springen"
       [uiParams]="{order_id: storage.order_id, marked_transaction_id: storage.order_transaction_id}">
      <i class="fa fa-map-marker"></i>
    </a>
  </ng-container>
</td>

<td>{{storage.charge}}</td>

<td class="align-right" [ngClass]="{'bold': isVendorPrice}" *ngIf="('material.order.price.check'|HasPerm)">
  <div *ngIf="(priceSingle !== null)" [ngbTooltip]="isVendorPrice ? 'Preis aus Preisliste' : null">
    {{priceSingle|money:2}}&nbsp;&euro;
  </div>
</td>

<!--konsi amount columns - only shown when konsi is set for article, editable only when konsi is still active-->
<td class="align-right" *ngIf="konsiNow">
  {{storage.amount_konsi|money}}
</td>
<td class="align-right click icon" (click)="changeAmountModal(storage, true)" *ngIf="konsiNow">
  <i class="fa fa-pencil" style="margin-left: 5px;"></i>
</td>

<td class="align-right" *ngIf="storage.konsi && !konsiNow">
  -
</td>
<td class="align-right" *ngIf="storage.konsi && !konsiNow">
</td>


<!--storage amount columns - only shown when konsi is set for article-->
<td class="align-right" *ngIf="storage.konsi">
  {{storage.amount_storage|money}}
</td>
<td class="align-right click icon" (click)="changeAmountModal(storage, false)" *ngIf="storage.konsi">
  <i class="fa fa-pencil" style="margin-left: 5px;"></i>
</td>

<!--total amount columns - always shown, editable only when konsi is inactive-->
<td class="align-right">
  {{storage.amount|money}}
</td>
<td class="align-right click icon" (click)="changeAmountModal(storage)" *ngIf="!storage.konsi">
  <i class="fa fa-pencil" style="margin-left: 5px;"></i>
</td>

<td>{{variation.unit}}</td>

<td class="align-right" *ngIf="('material.order.price.check'|HasPerm)">
  <ng-container *ngIf="(priceTotal !== null)">
    {{priceTotal|money:2}}&nbsp;&euro;
  </ng-container>
</td>

<td *ngIf="hasExpiration">{{storage.date_expiration}}</td>
<td class="icon">
  <ev-file-button [url]="'/api/material/pdf/' + storage.id" [link]="true">
    <i class="fa fa-file-pdf-o" aria-hidden="true" title="Etiketten PDF öffnen"></i>
  </ev-file-button>
</td>

