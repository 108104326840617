<div class="task">
  <h2>
    <a [uiParams]="{id: data.workflow.object.id}" ngbTooltip="Zurück zum Vorgang" uiSref="a.workflow.detail">
      <i class="fa fa-chevron-left"></i>
      Vorgang: {{ data.workflow.object.id }}
    </a>
  </h2>

  <h1>Auftragsbestätigung erstellen</h1>

  <div class="row">
    <div class="col-md-8">
      <contract-create-additional (saveEmitter)="updateAdditionalForm($event)"
                                  *ngIf="data.workflow.frame"
                                  [additional]="data.additional"
                                  [addresses]="data.address"
                                  [contractList]="data.contract_list.objects"
                                  [lastOffer]="data.last_offer"
                                  [workflow]="data.workflow">
      </contract-create-additional>

      <div *ngIf="!data.workflow.frame?.approval" class="row">
        <div class="col-sm-12 btn-group simple-margin">
          <div *ngIf="data.workflow.frame && !data.workflow.frame?.approval" class="red_alert height-20">
            Bitte warten Sie bis der Auftragsrahmen bestätigt wurde!
          </div>
          <button (click)="addFrame()" *ngIf="!data.workflow.frame" class="btn btn-default btn-sm">
            Auftragsrahmen erstellen
          </button>
        </div>
      </div>

      <contract-create-form (triggerPrice)="triggerPrice()"
                            (updateList)="updateList()"
                            *ngIf="data.workflow.frame?.approval"
                            [inputForm]="additionalFormData"
                            [workflow]="data.workflow"
                            [article]="data.article"
                            [priceIncreaseFlex]="data.price_increase_flex">
      </contract-create-form>

      <div class="row">
        <div class="col-md-12">
          <article-warning [article]="data.article"></article-warning>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          Nur Positionen die noch nicht bestätigt wurden und ein Liefertermin eingetragen haben, werden auf
          der Auft.-Best. bestätigt.
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          <a [uiParams]="{id: data.workflow.object.id}" class="btn btn-default" uiSref="a.workflow.detail">
            Zurück zur Übersicht
          </a>
        </div>

        <div class="col-md-10">
          <customer-credit-worthiness-alert [customerCredit]="data.customer_credit_worthiness">
            <ng-template>
              <loading-button (loadingSave)="create($event)"
                              [disabled]="createLocked"
                              clazz="btn btn-success pull-right">
                Auf.-Best. erstellen
              </loading-button>
            </ng-template>
          </customer-credit-worthiness-alert>
        </div>
      </div>

    </div>

    <div class="col-md-4">
      <contract-create-list [article]="data.article"
                            [contractList]="data.contract_list?.objects"
                            [lastOffer]="data.last_offer"
                            [workflow]="data.workflow"
                            [priceIncreaseFlex]="data.price_increase_flex"
                            (updatePriceIncreaseFlex)="updatePriceIncreaseFlex($event)">
      </contract-create-list>
    </div>
  </div>

</div>
