import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {noop} from '../../../../helper/noop';
import {
  MaterialOrderIntakeListElement,
  MaterialOrderPosition,
  MaterialOrderPositionTransactionIndices,
  MaterialOrderTransaction
} from '../material-order.model';
import {MaterialValueType} from '../../material-management.model';
import {Subscription} from 'rxjs';
import {MaterialOrderPositionService} from '../material-order-position.service';

@Component({
  selector: 'tbody[material-order-intake-position]',
  templateUrl: './material-order-intake-position.component.html'
})
export class MaterialOrderIntakePositionComponent implements OnInit, OnChanges, OnDestroy {
  @Input('material-order-intake-position') position: MaterialOrderIntakeListElement;
  @Input('material-order-intake-position-form') form: UntypedFormGroup;
  @Input('material-order-intake-position-errors') errors: { [key: string]: any; };
  @Input('material-order-intake-position-fields') fields: MaterialValueType[];
  @Input('material-order-intake-position-marked-variation-id') markedVariationId?: number;
  @Input('material-order-intake-position-marked-position-id') markedPositionId?: number;
  @Input('material-order-intake-position-marked-transaction-id') markedTransactionId?: number;
  @Input('material-order-intake-position-group-permit') groupPermission: boolean;
  @Output('material-order-intake-position-add-transaction') addTransaction =
    new EventEmitter<MaterialOrderPositionTransactionIndices>();
  @Output('material-order-intake-position-remove-transaction') removeTransaction =
    new EventEmitter<MaterialOrderPositionTransactionIndices>();
  @Output('material-order-intake-position-update-order') updateOrder = new EventEmitter<void>();

  remaining: number;
  subscription: Subscription;

  constructor(private mopService: MaterialOrderPositionService) {
  }

  ngOnInit(): void {
    this.initSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.position && !changes.position.isFirstChange()) {
      this.updateRemaining();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initSubscription(): void {
    this.subscription = this.form.valueChanges.subscribe(() => {
      this.updateRemaining();
    });
    this.updateRemaining();
  }

  updateRemaining(): void {
    let sum = 0;
    this.form.value.transactions.forEach(t => {
      sum += t.amount;
    });
    this.remaining = Math.max(this.position.amount_ordered - this.position.amount_received - sum, 0);
  }

  get positionFormGroups() {
    const formArray = this.form.get('transactions') as UntypedFormArray;
    return formArray.controls.map(group => group as UntypedFormGroup);
  }

  trackByFnTransaction(index: number, item: MaterialOrderTransaction): number {
    noop(this);
    return item.transaction_id;
  }

  trackByFnControl(index: number, item: AbstractControl): number {
    noop(this);
    return index;
  }

  addTransactionPosition(index: number, position: MaterialOrderPositionTransactionIndices) {
    position.t_index = index;
    this.addTransaction.emit(position);
  }

  removeTransactionPosition(id: number): void {
    this.position.transactions = this.position.transactions.filter(t => t.id !== id);
    this.mopService.get(this.position.position_id).subscribe(mop => {
      this.position.amount_received = mop.amount_received;
      this.updateRemaining();
    });
  }

  updatePosition(position: MaterialOrderPosition): void {
    this.updateOrder.emit();
    this.position.status = position.status;
    this.position.deleted = !!position.date_deleted;
  }
}
