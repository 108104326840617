<div>

  <div class="row">

    <workflow-create-article-search (offerEmitter)="showWorkflow($event)" (nextEmitter)="next($event)" *ngIf="state === 0">
    </workflow-create-article-search>

    <create-task [data]="offerData" *ngIf="state === 0 && !error && !!offerData" class="col-md-5">
    </create-task>

    <div *ngIf="error && state === 0" class="col-md-5">
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <h3>Angebot hat schon einen Auftrag!</h3>
    </div>

  </div>

  <!-- ="$ctrl.finish(typ, customer, contact)"-->
  <workflow-create-customer *ngIf="state === 1" [data]="customerData" (finishEmitter)="finish($event)">
  </workflow-create-customer>

</div>
