import * as React from 'react';
import {ArticleMultilayerPartTypeHelper} from '../article-multilayer-part-type-helper';
import {
  ArticleMultilayerParts,
  ArticleMultilayerPlanPrepregElementModel,
} from '../article-multilayer-plan.models';
import {ArticleSpecification} from '../../../models/article-specification.model';
import {ArticleMultilayerPlanMaterialSelect} from '../form-elements/article-multilayer-plan-material-select';
import {ReactNodeArray} from 'react';

interface Props {
  model: ArticleMultilayerParts;
  specification: { [key: string]: ArticleSpecification };
  partChange: (part: ArticleMultilayerParts) => void;
  children: ReactNodeArray;
}

export function ArticleMultilayerPlanPartPrepreg(props: Props) {
  const type = ArticleMultilayerPartTypeHelper.types.prepreg;

  const materialChange: (material: ArticleMultilayerPlanPrepregElementModel) => void = (material) => {
    const model = {
      ...props.model,
      thickness: material.thickness ?? 0,
      prepreg: {
        tissue: material.tissue,
        group: 0,
        thickness_selected: material.thickness,
        thickness_copper_subtract: 0,
      },
    };

    props.partChange(model);
  };

  return <>
    <tr style={{'height': '100%'}}>
      {props.children[0]}
      <td className={'height-fix'}/>
      <td className={'height-fix'}>
        <div className={'rounded-full-left justify-end color-class ' + type.color_class}>&nbsp;</div>
      </td>
      {props.children[5]}
      <td className={'height-fix'}>
        <div className={'rounded-full-center justify-start color-class ' + type.color_class}>
          {type.label}
        </div>
      </td>
      <td className={'height-fix'}>
        <div className={'rounded-full-right color-class ' + type.color_class}>
          <ArticleMultilayerPlanMaterialSelect index={props.model.index}
                                               value={props.model.prepreg.tissue}
                                               changeModel={m => materialChange(m)}/>
        </div>
      </td>
      <td/>
      {props.children[3]}
      {props.children[4]}
      <td className={'border-none-top border-none-bottom'}/>
      <td className={'border-none-top border-none-bottom'}/>
      <td>{props.children[1]}</td>
      <td>{props.children[2]}</td>
    </tr>
  </>;
}
