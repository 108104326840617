import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'limitTo'})
export class TruncatePipe implements PipeTransform {

  transform(value: string | null | undefined, args: any): string {
    // let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
    // let trail = args.length > 1 ? args[1] : '...';
    const limit = args ? parseInt(args, 10) : 10;
    const trail = '...';

    if (value) {
      return value.length > limit ? value.substring(0, limit) + trail : value;
    } else {
      return value;
    }
  }

}
