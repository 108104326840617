import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ContractListData, ContractListFetchData} from '../../models/contract.models';

@Component({
  selector: 'tbody[contract-list-element]',
  templateUrl: './contract-list-element.component.html',
})
export class ContractListElementComponent implements OnInit, OnChanges {
  @Input('contract-list-element') contract: ContractListData;
  @Input('contract-list-element-single-row') singleRowOuter: boolean;
  singleRow = false;
  multiFetch = false;

  ngOnInit(): void {
    this.multiFetch = this.contract.fetches.length > 1;
    this.singleRow = this.multiFetch && this.singleRowOuter;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.singleRowOuter && !changes.singleRowOuter.isFirstChange()) {
      this.singleRow = this.multiFetch && this.singleRowOuter;
    }
  }

  setSingleRow(singleRow: boolean): void {
    this.singleRow = singleRow;
  }

  get fetches(): ContractListFetchData[] {
    return this.singleRow ? [] : this.contract.fetches.filter((f, i) => i > 0);
  }
}
