import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CommentService} from '../comment/comment.service';
import {Comment} from '../comment/comment.models';
import {AlertService} from '../../common/alert-service/alert.service';
import {PaginationBaseComponent} from '../../common/pagination/pagination.component';
import {Commentv2Service} from './commentv2.service';
import {Lister} from '../../common/wrapper.models';

@Component({
  selector: 'comment-2',
  templateUrl: './commentv2.component.html',
  styles: [
    '.label_print_table { text-align: left; border-spacing: 2px; border-collapse: separate; }',
    'td { padding-right: 3px; }',
    'th { padding-right: 5px; }',
    '.label_print_table2 { text-align: center; }',
    'div.whitelist-item-detail-second { white-space: pre-wrap; }',
    '.ml-2 { margin-left: 2rem; }',
    '.whitelist-item-no-cursor { cursor: default !important; }',
    '.whitelist-item-danger-bg { color: #a94442 !important; background-color: #f2dede !important; border-color: #ebccd1 !important; }'
  ]
})
export class Commentv2Component extends PaginationBaseComponent<Commentv2Service, Comment> implements OnInit {
  @Input() list: Lister<Comment>;
  @Input() item: string;
  @Input() typ: string;
  @Input() placement: 'bottom' | 'top' = 'top';
  @Input() canEdit?: boolean;
  box = false;
  error = false;
  isArticleOrWorkflow = false;

  createForm: UntypedFormGroup;

  constructor(protected service: Commentv2Service,
              private commentService: CommentService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.canEdit === undefined) {
      this.canEdit = true;
    }

    this.query({
      'item': this.item,
      'typ': this.typ,
      'sort': 'date',
      'order': (this.placement === 'top' ? 'DESC' : 'ASC'),
    });
  }

  create() {
    this.error = false;
    this.commentService.create(this.typ, this.item, this.createForm.value.text).subscribe(value => {
      this.box = false;
      this.reload();
      this.alertService.add('success', 'Hinzufügen des Kommentars erfolgreich abgeschlossen!');
    }, () => {
      this.error = true;
      this.alertService.add('danger', 'Hinzufügen des Kommentars fehlgeschlagen!');
    });
  }

  openBox(): void {
    this.buildForm();
    this.error = false;
    this.box = true;
  }

  remove(index: number, comment: Comment): void {
    if (confirm('Möchten Sie das Kommentar von ' + comment.username + '\r\n\r\n' + comment.text +
      '\r\n\r\n wirklich löschen?')) {
      this.commentService.remove(this.typ, comment.id).subscribe(() => {
        this.reload();
        this.alertService.add('success', 'Löschen des Kommentars erfolgreich!');
      }, () => {
        this.alertService.add('danger', 'Fehler beim Löschen des Kommentars!');
      });
    }
  }

  mark(index: number, comment: Comment): void {
    this.commentService.mark(comment.typ, comment.id).subscribe(() => {
      comment.marked = !comment.marked;
      if (comment.marked) {
        this.alertService.add('success', 'Kommentar erfolgreich markiert!');
      } else {
        this.alertService.add('success', 'Kommentar Markierung entfernt!');
      }
    }, () => {
      this.alertService.add('danger', 'Kommentar Markierungsänderung fehlgeschlagen!');
    });
  }

  commentClasses(comment: Comment): string {
    let clazz = '';

    if (comment.typ !== this.typ && comment.typ !== 'label_print' && comment.typ !== 'article_log') {
      clazz = 'alert-warning';
    } else if (comment.additional && comment.additional.state) {
      if (comment.additional.state === 1) {
        clazz = 'whitelist-item-success';
      } else if (comment.additional.state === 2) {
        clazz = 'whitelist-item-danger';
      } else if (comment.additional.state === 3) {
        clazz = 'whitelist-item-primary';
      }
    }

    if (this.canBeMarked(comment) && comment.marked) {
      clazz = 'whitelist-item-danger-bg';
    }

    return clazz;
  }

  canBeMarked(comment: Comment): boolean {
    return comment.typ === 'order' || comment.typ === 'workflow' || comment.typ === 'article';
  }

  private buildForm() {
    this.createForm = this.fb.group({
      'text': [null]
    });
  }

}
