import {Component, ErrorHandler, Input} from '@angular/core';
import {MaterialGroupListElement} from '../material-management.model';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialGroupCreateComponent} from './material-group-create.component';
import {Lister} from '../../../common/wrapper.models';
import {MaterialGroup} from './material-group.model';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {MaterialGroupListAllService} from './material-group-list-all.service';

@Component({
  selector: 'material-group-list',
  templateUrl: './material-group-list.component.html'
})

export class MaterialGroupListComponent extends SearchBaseComponent<MaterialGroupListAllService, MaterialGroup> {
  @Input() listData: Lister<MaterialGroup>;
  locked = false;

  protected type = 'material-group-list';
  constructor(
    private modalService: NgbModal,
    protected locationService: EnvisiaLocation,
    protected storageService: LocalStorage,
    protected errorHandler: ErrorHandler,
    protected service: MaterialGroupListAllService,
  ) {
    super();
  }

  openCreateModal(groupListElement?: MaterialGroupListElement): void {
    const modalRef = this.modalService.open(MaterialGroupCreateComponent, {windowClass: 'modal2-sm'});
    if (groupListElement) {
      modalRef.componentInstance.editing = true;
      modalRef.componentInstance.editingValues = groupListElement;
    }
    modalRef.result.then(() => {
      this.reload();
    }, ignoreRejection);
  }
}
