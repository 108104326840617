import {Component, Input, OnInit} from '@angular/core';
import {RightService} from '../../../common/right-service/right.service';

@Component({
  selector: 'article-config-redirect',
  templateUrl: './article-config-redirect.component.html',
})
export class ArticleConfigRedirectComponent implements OnInit {
  @Input() type: string;
  landingPage?: string;

  constructor(private rightService: RightService) {
  }

  ngOnInit(): void {
    if (this.rightService.has('article.change')) {
      this.landingPage = 'article_config.extra';
    } else if (this.rightService.has('article.configuration')) {
      this.landingPage = 'article_config.main';
    } else if (this.rightService.has('article.mask')) {
      this.landingPage = 'article_config.release';
    } else if (this.rightService.has('article.production_step_setting')) {
      this.landingPage = 'article_config.production_step';
    } else if (this.rightService.has('production.template_setting')) {
      this.landingPage = 'article_config.production_template';
    } else if (this.rightService.has('article.delete')) {
      this.landingPage = 'article_config.delete';
    } else if (this.rightService.has('article.customer_locker')) {
      this.landingPage = 'article_config.customer_locker';
    } else if (this.rightService.has('article.configuration')) {
      this.landingPage = 'article_config.weight_calculation';
    } else {
      this.landingPage = null;
    }
  }

}
