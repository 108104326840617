import {NgModule} from '@angular/core';
import {CustomerListComponent} from './list/customer-list.component';
import {SharedModule} from '../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {CustomerCreateComponent} from './create/customer-create.component';
import {CommentModule} from '../comment/comment.module';
import {CustomerCommentComponent} from './comment/customer-comment.component';
import {AkquiseSaveModule} from '../akquise/akquise-save.module';
import {CustomerContactCreateComponent} from './contact/create/customer-contact-create.component';
import {CustomerContactListComponent} from './contact/list/customer-contact-list.component';
import {NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CustomerContactEditComponent} from './contact/edit/customer-contact-edit.component';
import {CustomerArticleExtraComponent} from './article-extra/customer-article-extra.component';
import {ArticleExtraModule} from '../article-extra/article-extra.module';
import {CustomerDetailComponent} from './detail/customer-detail.component';
import {CustomerMapComponent} from './detail/customer-map.component';
import {CustomerDetailAddressComponent} from './detail/customer-detail-address.component';
import {CustomerDetailFormComponent} from './detail/customer-detail-form.component';
import {CustomerDetailFieldServiceComponent} from './detail/customer-detail-field-service.component';
import {CustomerIncreaseComponent} from './increase/customer-increase.component';
import {CustomerIncreaseModalComponent} from './increase/customer-increase-modal.component';
import {CustomerDetailAddressDeleteModalComponent} from './detail/customer-detail-address-delete-modal.component';
import {CustomerDetailAddressUpsertModalComponent} from './detail/customer-detail-address-upsert-modal.component';
import {CustomerOposComponent} from './opos/customer-opos.component';
import {CustomerOposCorrectionTableComponent} from './opos/customer-opos-correction-table.component';
import {CustomerOposInvoiceTableComponent} from './opos/customer-opos-invoice-table.component';
import {CustomerOposFocusDirective} from './opos/customer-opos-focus.directive';
import {CustomerOposInputComponent} from './opos/customer-opos-input.component';
import {CustomerNavigationModule} from './navigation/customer-navigation.module';
import {CustomerDocumentModule} from './document/customer-document.module';
import {CUSTOMER_STATES} from './customer.states';
import {OposHistoryModalModule} from '../opos/history/opos-history-modal.module';
import {WorkflowPipesModule} from '../workflow/pipes/workflow-pipes.module';
import {CustomerCalculationComponent} from './detail/customer-calculation.component';
import {CustomerDetailProducerComponent} from './detail/customer-detail-producer.component';
import {FormsModule} from '@angular/forms';
import {PriceSelectorModule} from '../../common/price-selector/price-selector.module';
import {CustomerDetailIndustrialSectorComponent} from './detail/customer-detail-industrial-sector.component';
import {
  CustomerListIndustrialSectorSelectComponent,
} from './list/industrial-sector-select/customer-list-industrial-sector-select.component';
import {LoadingOrErrorModule} from '../../common/loading/loading-or-error.module';
import {CustomerContactFormComponent} from './contact/form/customer-contact-form.component';
import {CustomerHeaderModule} from './header/customer-header.module';
import {CustomerRatingModule} from './rating/customer-rating.module';
import {CustomerOposTargetDateEditComponent} from './opos/customer-opos-target-date-edit.component';
import {CustomerCreditWorthinessAlertComponent} from './credit-worthiness/customer-credit-worthiness-alert/customer-credit-worthiness-alert.component';
import {NgxBootstrapMultiselectModule} from '../../common/multiselect/ngx-bootstrap-multiselect.module';

@NgModule({
  imports: [
    SharedModule,
    UIRouterModule.forChild({states: CUSTOMER_STATES}),
    CommentModule,
    WorkflowPipesModule,
    AkquiseSaveModule,
    NgbModalModule,
    ArticleExtraModule,
    CustomerNavigationModule,
    CustomerDocumentModule,
    OposHistoryModalModule,
    FormsModule,
    NgxBootstrapMultiselectModule,
    PriceSelectorModule,
    LoadingOrErrorModule,
    CustomerHeaderModule,
    CustomerRatingModule,
    NgbTooltipModule,
  ],
  declarations: [
    CustomerListComponent,
    CustomerDetailComponent,
    CustomerCreateComponent,
    CustomerCommentComponent,
    CustomerContactListComponent,
    CustomerArticleExtraComponent,
    CustomerOposComponent,
    CustomerOposInvoiceTableComponent,
    CustomerOposCorrectionTableComponent,
    CustomerOposFocusDirective,
    CustomerOposInputComponent,
    CustomerMapComponent,
    CustomerIncreaseComponent,
    CustomerDetailFieldServiceComponent,
    CustomerCalculationComponent,
    CustomerDetailFormComponent,
    CustomerDetailAddressComponent,
    CustomerDetailProducerComponent,
    // Modals
    CustomerDetailAddressUpsertModalComponent,
    CustomerDetailAddressDeleteModalComponent,
    CustomerIncreaseModalComponent,
    CustomerContactEditComponent,
    CustomerContactCreateComponent,
    CustomerDetailIndustrialSectorComponent,
    CustomerListIndustrialSectorSelectComponent,
    CustomerContactFormComponent,
    CustomerOposTargetDateEditComponent,
    CustomerCreditWorthinessAlertComponent,
  ],
  exports: [
    CustomerListIndustrialSectorSelectComponent,
    CustomerCreditWorthinessAlertComponent
  ]
})
export class CustomerModule {
}
