import {Component, Input} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {GlobalService} from '../../../../core/global.service';
import {ArticleCalculatorService} from '../services/article-calculator.service';
import {ArticleUpdateSharedComponent} from '../helper/article-update-shared.component';
import {ArticleUpdateService} from '../../component-service/article-update.service';
import {StateService} from '@uirouter/angular';

@Component({
  selector: 'article-detail',
  templateUrl: './article-detail.component.html'
})
export class ArticleDetailComponent extends ArticleUpdateSharedComponent {
  constructor(protected fb: UntypedFormBuilder,
              protected articleCalculatorService: ArticleCalculatorService,
              protected globalService: GlobalService,
              protected stateService: StateService,
              protected articleUpdateService: ArticleUpdateService) {
    super();
  }

}
