import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkflowOrderRequestMainComponent} from './workflow-order-request-main.component';
import {OrderRequestMainOrderListComponent} from './order-request-main-order-list/order-request-main-order-list.component';
import {OrderRequestMainRequestListComponent} from './order-request-main-request-list/order-request-main-request-list.component';
import {OrderRequestMainRequestFormComponent} from './order-request-main-request-form/order-request-main-request-form.component';
import {OrderRequestMainOrderFormComponent} from './order-request-main-order-form/order-request-main-order-form.component';
import {SharedModule} from '../../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {ArticleWarningModule} from '../../../article/warning/article-warning.module';
import {OrderRequestMainRequestPositionFormComponent} from './order-request-main-request-form/order-request-main-request-position-form.component';
import {OrderRequestMainOrderPositionFormComponent} from './order-request-main-order-form/order-request-main-order-position-form.component';
import {DeliveryConditionBoxModule} from '../../delivery-condition-box/delivery-condition-box.module';
import {OrderRequestArticleOverviewComponent} from './order-request-article-overview/order-request-article-overview.component';
import {LoadingOrErrorModule} from '../../../../common/loading/loading-or-error.module';
import {OrderRequestMainRequestWrapperComponent} from './order-request-main-request-list/order-request-main-request-wrapper.component';
import {OrderRequestMainOrderWrapperComponent} from './order-request-main-order-list/order-request-main-order-wrapper.component';
import {OrderRequestArticleBoxComponent} from './order-request-article-box/order-request-article-box.component';
import {CustomerModule} from '../../../customer/customer.module';

@NgModule({
  declarations: [
    WorkflowOrderRequestMainComponent,
    OrderRequestMainOrderListComponent,
    OrderRequestMainRequestListComponent,
    OrderRequestMainRequestFormComponent,
    OrderRequestMainOrderFormComponent,
    OrderRequestMainRequestPositionFormComponent,
    OrderRequestMainOrderPositionFormComponent,
    OrderRequestArticleOverviewComponent,
    OrderRequestMainRequestWrapperComponent,
    OrderRequestMainOrderWrapperComponent,
    OrderRequestArticleBoxComponent,
  ],
  exports: [
    OrderRequestArticleOverviewComponent
  ],
  imports: [
    UIRouterModule.forChild(),
    CommonModule,
    SharedModule,
    ArticleWarningModule,
    DeliveryConditionBoxModule,
    LoadingOrErrorModule,
    CustomerModule,
  ]
})
export class WorkflowOrderRequestMainModule {
}
