<div class="buttonbar-view">
  <customer-navigation [customer]="customer"
                       (customerLocker)="toggleLock($event)"
                       (customerDeleted)="setDeleted($event)">
  </customer-navigation>
</div>

<customer-header-template [customer]="customer" [showRating]="true" (ratingChanged)="setRating($event)">
</customer-header-template>

<div class="row content" id="content">
  <div class="col-md-10">

    <form [formGroup]="form" (ngSubmit)="changeName()">
      <div class="input-group" style="width: 100%; margin-bottom:10px;">
        <span class="input-group-addon" style="width: 110px;">
            <strong># {{ customer.id }} -</strong>
        </span>
        <!-- FIXME: add a edit button -->
        <input type="text" class="form-control" maxlength="100"
               formControlName="name"
               [ngClass]="{red_alert: nameError}">

        <div class="input-group-btn" *ngIf="nameChangeEnabled">
          <button type="submit" class="btn btn-success">Speichern</button>
        </div>
        <div class="input-group-btn" *ngIf="!nameChangeEnabled">
          <button type="button" class="btn btn-default" (click)="enableNameChange()">
            <i class="fa fa-pencil"></i>
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="duplicated">
      <h3 class="red_alert">Kundenname ist bereits vorhanden.</h3>
    </div>

    <div class="contentbox">
      <div style="display: table; width: 100%; font-size: 18px;">
        <div style="display: table-cell;">
          <h3>Rechnungsadresse</h3>
        </div>
      </div>
      <div style="display: table; width: 100%;">
        <div style="display: table-cell; vertical-align: top;">
          <div style="padding-bottom: 5px;">
            {{ customer.invoice.street }}, {{ customer.invoice.zip }}
            {{ customer.invoice.city }} ,
            <img [src]="flagUrl()">
            {{ countries[customer.invoice.country].name_de }}
          </div>
          <div>
            <customer-map [address]="customer.invoice"></customer-map>
          </div>
        </div>
      </div>
    </div>

    <customer-detail-form [customer]="customer"
                          [increase]="increase"
                          [producers]="producers"
                          [fieldServices]="fieldServices"
                          [fieldServiceUser]="fieldServiceUser"
                          [terms]="terms"
                          [processes]="processes"
                          [industrialSectors]="industrialSectors">
    </customer-detail-form>
  </div>

  <div class="col-md-2">
    <customer-detail-address [customer]="customer" [countries]="countries"
                             (updateInvoice)="updateInvoiceAddress($event)">
    </customer-detail-address>
  </div>

</div>
