<ng-container *ngIf="!currentGroup">
  <h2>Gruppe auswählen</h2>
  <material-select-article [level]="1" (group)="setGroup($event)"></material-select-article>
</ng-container>

<ng-container *ngIf="currentGroup">
  <ng-container *ngIf="fieldsObservable.data$|async as field; else loadingOrError.template">

    <material-article-create-form-holder [articleFieldDefs]="field" [currentGroup]="currentGroup">
    </material-article-create-form-holder>

  </ng-container>

  <loading-or-error
      #loadingOrError
      [loadingWrapper]="fieldsObservable"
      [errorMessage]="'Konnte Gruppe nicht laden'"
      [spinnerSize]="2"
      spinnerName="circle-o-notch">
  </loading-or-error>
</ng-container>
