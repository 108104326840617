import {Transition, Ng2StateDeclaration} from '@uirouter/angular';
import {CustomerService} from './services/customer.service';
import {Customer} from './model/customer';
import {map} from 'rxjs/operators';
import {ErpTransition} from '../../core/erp-transition.service';

export function nameRedirectFn(transition: Transition /* THIS NEEDS TO STAY AS IS */) {
  const customerService: CustomerService = transition.injector().get<CustomerService>(CustomerService);
  const erpTransition: ErpTransition = transition.injector().get<ErpTransition>(ErpTransition);
  return customerService.byName(decodeURIComponent(erpTransition.params().name)).pipe(
    map((value: Customer) => {
      return {state: 'a.crm.customer.detail', params: {id: value.id}};
    })
  ).toPromise();
}

export const CUSTOMER_NAME_REDIRECT_STATE: Ng2StateDeclaration = {
  name: 'a.crm.redirect',
  url: '/crm/name/{name}',
  redirectTo: nameRedirectFn
};
