<ng-container *ngIf="form" [formGroup]="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>Rechnung</th>
      <th>Vorgang</th>
      <th>RG-Datum</th>
      <th>Zahlungsziel</th>
      <th>Fälligkeit</th>
      <th>RG-Betrag</th>
      <th>Offen €</th>
      <th>Gebucht €</th>
      <th>Gebucht Skonto %</th>
      <th>Gebucht Skonto €</th>
      <th>Mahnst.</th>
      <th>Bezahlt</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    </thead>
    <tbody formArrayName="objects">
    <tr *ngFor="let form of table.controls;trackBy: trackByFn; let index = index"
        [formGroupName]="index.toString()"
        [ngClass]="{'alert-warning': objects[index]?.invoice_storno_id}">

      <ng-container *ngIf="objects[index] as obj"> <!-- easier name -->
        <td>
          <ev-file-button [objId]="obj.invoice_id" type="invoice">
            <span [ngClass]="{deleted: obj.invoice_storno_id}">{{ obj.invoice_id }}</span>
            <span class="label" [ngClass]="'label-' + obj.state.label">{{ obj.state.name }}</span>
          </ev-file-button>
          <ev-file-button [objId]="obj.invoice_storno_id" type="invoice_correction" *ngIf="obj.invoice_storno_id">
            <span class="label label-warning">Storno</span>
          </ev-file-button>
        </td>
        <td>
          <a uiSref="a.workflow.detail" [uiParams]="{id: obj.workflow_id}">
            {{ obj.workflow_id }}
            <img *ngIf="obj.typ === 'de'" src="/ui/assets/images/flags/de.png"
                 title="Es wurden deutsche Preise angeboten" class="table-flag">
            <img *ngIf="obj.typ === 'cn'" src="/ui/assets/images/flags/cn.png"
                 title="Es wurden China-Preise angeboten" class="table-flag">
          </a>
        </td>
        <td>{{ obj.date }}</td>
        <td>{{ obj.terms }} Tage</td>
        <td style="width: 125px;">
          <customer-opos-target-date-edit [data]="obj" (targetDateChanged)="datesChanged(index, $event)">
          </customer-opos-target-date-edit>
        </td>
        <td>{{ obj.revenue }}</td>
        <td>{{ obj.open }}</td>
        <td>
          <customer-opos-input formControlName="expensed"
                               [error]="errors['obj.objects[' + index + '].expensed']"
                               [focus]="obj.focus_expensed"
                               (keyUpEmitter)="focus($event, index, 'expensed', obj)"
                               (lazyEmitter)="expensed(index, $event, obj)">
          </customer-opos-input>
        </td>
        <td>
          <customer-opos-input formControlName="skonto"
                               [error]="errors['obj.objects[' + index + '].skonto']"
                               [focus]="obj.focus_skonto"
                               (keyUpEmitter)="focus($event, index, 'skonto', obj)"
                               (lazyEmitter)="skonto(index, $event, obj)">
          </customer-opos-input>
        </td>
        <td>{{ form.controls.skonto_total.value }} €</td>
        <td>&nbsp;</td>
        <td>
          <input type="checkbox" formControlName="save"
                 (click)="save(index, $event.target.checked, obj)"
                 [oposFocus]="obj.focus_save"
                 (keyup)="focus($event, index, 'save', obj)">
        </td>
        <td>

          <button type="button" (click)="skontoCustomer(index, obj)" class="btn btn-default btn-sm">
            Skonto
          </button>
        </td>
        <td>
          <button type="button" (click)="putAll(index, obj)" class="btn btn-default btn-sm">
            Gesamt
          </button>
        </td>

        <td>
          <button type="button" class="btn btn-sm btn-link" (click)="history(obj)">
            <i class="fa fa-history"></i>
          </button>
        </td>
      </ng-container>

    </tr>

    <tr class="no-border">
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td class="bold">{{ totalRevenue }} €</td>
      <td class="bold">{{ totalOpen }} €</td>
      <td class="bold">{{ totalExpensed }} €</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
    </tbody>

  </table>
</ng-container>
