<div class="article-fixed-head">

  <article-top-navigation [article]="article"
                          [workflowId]="workflow_id"
                          [multilayerPlan]="multilayerPlan">
  </article-top-navigation>

</div>

<finder-search [initial]="search" searchType="article" [id]="article.oa_nr" [main]="article.sds_nr">
</finder-search>
