import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MailService {

  constructor(private http: HttpClient) {
  }

  send(type: string, id: number | string, form: FormData): Observable<void> {
    return this.http.post<void>('/mail/v1/' + type + '/' + id + '/', form);
  }

  template(name: string, data: any): Observable<string> {
    return this.http.put(
      '/mail/v2/template/' + name + '/',
      data,
      {observe: 'body', responseType: 'text'}
    ).pipe(map((value: string) => value.replace(/<br>/g, '\n')));
  }

}
