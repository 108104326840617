import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WorkflowPositionView} from '../../../models/position.models';
import {noop} from '../../../../../helper/noop';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {PositionChange} from '../position-box.models';

@Component({
  selector: 'position-box-list',
  templateUrl: './position-box-list.component.html',
})
export class PositionBoxListComponent {
  @Input() listChange: number;
  @Input() data: BigWorkflowOffer;
  @Output() updatePosition = new EventEmitter<{ position: PositionChange, index: number }>();

  constructor() {
  }

  trackByFn(index: number, item: WorkflowPositionView): number {
    noop(this, index);
    return item.id;
  }

  doUpdatePosition(position: PositionChange, index: number): void {
    this.updatePosition.emit({position: position, index: index});
  }
}
