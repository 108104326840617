import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MaterialOrderPositionStatusLabelComponent
} from './material-order/material-order-position-status-label.component';

@NgModule({
  declarations: [
    MaterialOrderPositionStatusLabelComponent,
  ],
  exports: [
    MaterialOrderPositionStatusLabelComponent
  ],
  imports: [
    CommonModule
  ]
})
export class StatusLabelModule { }
