import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowPositionView} from '../models/position.models';

@Component({
  selector: 'create-task-no-history-modal',
  templateUrl: './create-task-no-history-modal.component.html',
})
export class CreateTaskNoHistoryModalComponent implements OnInit {
  @Input() position: WorkflowPositionView;
  @Input() typ: 'de' | 'cn';
  price: string | undefined | null;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (this.typ === 'de') {
      this.price = this.position.de.price;
    } else if (this.typ === 'cn') {
      this.price = this.position.cn.price;
    }
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

}
