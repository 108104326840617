import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ShippingInstructionType} from '../../article/models/article-extra.models';

@Component({
  selector: 'article-extra-headline',
  templateUrl: './article-extra-headline.component.html'
})
export class ArticleExtraHeadlineComponent {
  @Input() type: ShippingInstructionType;
  @Input() mainType: ShippingInstructionType;
  @Input() readOnly = false;
  @Output() deleteCalled = new EventEmitter<boolean>();

  constructor() {
  }
}
