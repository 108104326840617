<div class="row">
  <div class="col-md-4">
    <h2>
      <a uiSref="a.workflow.detail" [uiParams]="{id: data.workflow.object.id }" ngbTooltip="Zurück zum Vorgang">
        <i class="fa fa-chevron-left"></i>
        Vorgang: {{ data.workflow.object.id }}
      </a>
    </h2>

    <h1>
      Angebot erstellen
    </h1>

    <form [formGroup]="form" (ngSubmit)="create()">
      <div class="form-group row">
        <label class="control-label col-md-4">
          Angebots-Datum:
        </label>

        <div class="col-md-8 chrissies">
          <ev-datepicker id="date" formControlName="date" class="form-control width-100"
                         [evClass]="{field_error: errors['offer_date']}">
          </ev-datepicker>
        </div>
      </div>


      <div class="form-group row">
        <label class="control-label col-md-4" for="offer-create-de-price">
          <img src="/ui/assets/images/flags/de.png"> DE Preise:
        </label>

        <div class="col-md-8">
          <input type="checkbox" id="offer-create-de-price" formControlName="de">
          <div class="label label-danger inline-div" *ngIf="!data.customer.additional.offer_de">
            KEINE DE ANGEBOTE ERWÜNSCHT
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="control-label col-md-4" for="offer-create-de-delivery-time">
          <img src="/ui/assets/images/flags/de.png"> DE Lieferzeit:
        </label>

        <div class="col-md-8">
          <textarea id="offer-create-de-delivery-time"
                    style="width: 90%;"
                    rows="3"
                    formControlName="de_delivery_time"
                    class="form-control">
          </textarea>
        </div>
      </div>

      <div class="form-group row">
        <label class="control-label col-md-4" for="offer-create-chn-price">
          <img src="/ui/assets/images/flags/cn.png"> CHN Preise:
        </label>

        <div class="col-md-8">
          <input type="checkbox" id="offer-create-chn-price" formControlName="cn">
          <div class="label label-danger inline-div" *ngIf="!data.customer.additional.offer_cn">
            KEINE CN ANGEBOTE ERWÜNSCHT
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="control-label col-md-4" for="offer-create-chn-delivery-time">
          <img src="/ui/assets/images/flags/cn.png"> CHN Lieferzeit:
        </label>

        <div class="col-md-8">
          <textarea id="offer-create-chn-delivery-time"
                    style="width: 90%;"
                    rows="3"
                    formControlName="cn_delivery_time"
                    class="form-control">
          </textarea>
        </div>
      </div>

      <div class="form-group row">
        <label class="control-label col-md-4" for="offer-create-new_nr">
          Neue Angebots-Nr.
        </label>

        <div class="col-md-8">
          <input type="checkbox" id="offer-create-new_nr" formControlName="number">
        </div>
      </div>

      <div class="form-group row">
        <label class="control-label col-md-4" for="offer-create-kanban">
          Kanban
        </label>

        <div class="col-md-8">
          <input type="checkbox" id="offer-create-kanban" formControlName="kanban">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-12 control-label">Hinweis-Text:</label>

        <div class="col-sm-12">
          <textarea class="form-control" cols="10" rows="5" formControlName="text"></textarea>
        </div>
      </div>
      <div class="row" *ngIf="data.article.sds_nr?.indexOf('-c') > 0 || data.article.sds_nr?.indexOf('-C') > 0">
        <div class="col-sm-12">
          <div class="alert alert-danger">
            <i class="fa fa-warning"></i> <strong>Achtung!</strong>
            China Artikel: {{ data.article.sds_nr }}
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <article-warning [article]="data.article"></article-warning>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <a class="btn btn-default" uiSref="a.workflow.detail" [uiParams]="{id: data.workflow.object.id }">
            Zurück
          </a>
          <button type="submit" class="btn btn-success pull-right" [disabled]="creating">
            Angebot erstellen
          </button>
        </div>
      </div>
    </form>

  </div>

  <div class="col-md-8">
    <h2>Vorhandene Angebote</h2>
    <table class="table whitetable" style="max-width: 500px;">
      <thead>
      <tr>
        <th>Angebot</th>
        <th>Datum</th>
        <th>Ersteller</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let offer of data.offer_list; trackBy: trackByFn; let index = index">
        <td>{{ offer.id }} <workflow-state type="offer" [key]="offer.status" clazz="item-status"></workflow-state></td>
        <td>{{ offer.date }}</td>
        <td>{{ offer.username }}</td>
        <td>
          <img src="/ui/assets/images/flags/de.png" *ngIf="offer.de"
               ngbTooltip="Es wurden deutsche Preise angeboten">
        </td>
        <td>
          <img src="/ui/assets/images/flags/cn.png" *ngIf="offer.cn"
               ngbTooltip="Es wurden China-Preise angeboten">
        </td>
        <td>
          <ev-file-button [objId]="offer.id" type="offer">
            <div class="inline-div fa fa-file" ngbTooltip="Angebot öffnen"></div>
          </ev-file-button>
        </td>
        <td>
          <a (click)="mail(offer.id)" class="click"
             ngbTooltip="Angebot per Mail versenden">
            <i class="fa fa-envelope"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
