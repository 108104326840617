import {Component, Input, EventEmitter, Output} from '@angular/core';
import {Workflow} from '../models/workflow.models';
import {WorkflowStatusModalService} from '../status-modal/workflow-status-modal.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';

@Component({
  selector: 'workflow-status-label',
  templateUrl: './workflow-status-label.component.html',
})
export class WorkflowStatusLabelComponent {
  @Input() workflow: Workflow;
  @Output() statusEvent = new EventEmitter<number>();

  constructor(private statusModalService: WorkflowStatusModalService) {
  }

  open(): void {
    this.statusModalService.open(this.workflow.id, 'status').subscribe(state => {
      this.statusEvent.emit(state);
    }, ignoreRejection);
  }
}
