<div class="row">

  <div class="col-md-12">
    <h3>Artikel</h3>
  </div>

  <div class="content-box pricecheckarticle">
    <div class="col-md-3">

      <article-box [article]="priceData.price.article"
                   [workflowId]="workflowId"
                   [withTitle]="false"
                   [inventory]="inventory"
                   (reloadInventory)="reloadInventory()"
                   targetWindow="_blank">
        <price-detail-sister-article [sister]="priceData.price.article?.sister" class="sds-projection">
        </price-detail-sister-article>

        <price-detail-order [article]="priceData.price.article.oa_nr"
                            *ngIf="('task.offer.view'|HasPerm) || ('task.view'|HasPerm)">
        </price-detail-order>

        <table class="whitetable table">
          <tbody>
          <tr>
            <td class="borderless-top fixed-width-150">Zu letzt verwend.:</td>
            <td class="borderless-top">
              <a uiSref="a.workflow.o.list" target="_blank" [uiParams]="{oa_nr: priceData.price.article.oa_nr}">
                Angebote
              </a>
              /
              <a uiSref="a.workflow.c.list" target="_blank" [uiParams]="{oa_nr: priceData.price.article.oa_nr}">
                Aufträge
              </a> <br />

              <a uiSref="a.workflow.request.list" target="_blank" [uiParams]="{oa_nr: priceData.price.article.oa_nr}">
                CN Anfragen
              </a> /
              <a uiSref="a.workflow.order.list"
                 target="_blank"
                 [uiParams]="{oa_nr: priceData.price.article.oa_nr, page: 1, order: 'DESC', sort: 'workflow_order_id'}">
                CN Bestellungen
              </a>
            </td>
          </tr>
          <tr *ngIf="customer">
            <td class="fixed-width-150">Letzter Kunde:</td>
            <td>
              <a uiSref="a.crm.customer.detail" [uiParams]="{id: customer.id}" target="_blank">
                {{ customer.name }}
              </a>
              <button class="btn label label-danger click" (click)="openSalesNote(customer)"
                      *ngIf="customer.sales_note">
                Vertriebsnotiz
              </button>
            </td>
          </tr>
          <tr>
            <td class="fixed-width-150">Artikel Kunde:</td>
            <td *ngIf="priceData.article_customer">
              <a uiSref="a.crm.customer.detail" [uiParams]="{id: priceData.article_customer.id}" target="_blank">
                {{ priceData.article_customer.name }}
              </a>
              <button class="btn label label-danger click"
                      (click)="openSalesNote(priceData.article_customer)"
                      *ngIf="priceData.article_customer.sales_note">
                Vertriebsnotiz
              </button>
            </td>
            <td *ngIf="!priceData.article_customer" class="right-align">
              <span class="label label-default">KEIN KUNDE AUSGEWÄHLT</span>
            </td>
          </tr>
          </tbody>
        </table>
      </article-box>
    </div>

    <div class="col-md-2">
      <div class="row">
      <price-detail-article-calc [article]="priceData.price.article"
                                 [processes]="processes"
                                 [defaultCalculation]="defaultCalculation"
                                 (updateArticle)="updateArticle($event)"
                                 (updateCalculation)="updateCalculation($event)">
      </price-detail-article-calc>
      </div>
      <div class="row">
        <delivery-condition-overdelivery-box [oa_nr]="priceData.price.article.oa_nr">
        </delivery-condition-overdelivery-box>
      </div>
    </div>

    <div class="col-md-4">
      <price-box [article]="priceData.price.article"
                 [processes]="processes"
                 [calculation]="defaultCalculation"
                 (updatePrices)="updatesPrices($event)">
      </price-box>
    </div>

    <div class="col-md-3">
      <comment [comments]="comments" [item]="priceData.price.article.oa_nr" typ="article"></comment>
    </div>
  </div>

</div>

<div class="row">
  <div class="col-md-5">
    <price-detail-list [article]="priceData.price.article"
                       [customer]="priceData.article_customer"
                       [lastIncrease]="priceData.last_increase_de"
                       [listData]="priceData.price.de"
                       (reload)="reloadPrices()"
                       [typ]="'de'">
    </price-detail-list>
  </div>

  <div class="col-md-7">
    <price-detail-list [article]="priceData.price.article"
                       [customer]="priceData.article_customer"
                       [listData]="priceData.price.cn"
                       (reload)="reloadPrices()"
                       [typ]="'cn'">
    </price-detail-list>
  </div>
</div>

<div class="row" *ngIf="toolingCost">
  <div class="col-md-5"></div>
  <div class="col-md-7">
    <h3>Nebenkosten CN</h3>
    Einkauf: <b>{{ toolingCost.cost }}</b> - Verkauf: <b>{{ toolingCost.price }}</b>
  </div>

</div>
