<div class="modal-header">
</div>

<div class="modal-body">
  <div>
    <div *ngIf="salesNote" class="sales-note-box-danger">
      <h3>Angebotsrelevante Informationen</h3>
      <pre id="sales_note" [innerHtml]="salesNote"></pre>
    </div>
  </div>
  <div *ngIf="note" class="sales-note-box-warning">
    <h3>Allgemeine Notiz</h3>
    <pre id="note" [innerHtml]="note"></pre>
  </div>
</div>

<div class="modal-footer">
</div>
