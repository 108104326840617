export class MailHeaderData {
  public rcpt: string;
  public bcc: string[];
  public header: string;
  public forceBcc: boolean;

  constructor(rcpt: string, header?: string, bcc?: string[] | string, forceBcc?: boolean) {
    this.rcpt = rcpt;
    this.header = header ? header : 'Sehr geehrte Damen und Herren,';
    if (!!bcc) {
      if (typeof bcc === 'string') {
        this.bcc = [bcc];
      } else {
        this.bcc = bcc;
      }
    } else {
      this.bcc = [];
    }

    // if forceBcc is used inside the constructor we will use that instead
    if (forceBcc !== null && forceBcc !== undefined) {
      this.forceBcc = forceBcc;
    } else {
      this.forceBcc = bcc !== null && bcc !== undefined;
    }
  }

}
