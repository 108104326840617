import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../common/alert-service/alert.service';
import {CustomerService} from '../services/customer.service';
import {CustomerId} from '../model/customer';
import {DateHelper} from '../../../helper/date-helper';
import {StateService} from '@uirouter/angular';
import {EnvisiaEventService} from '../../../core/event.service';
import {AkquiseSaveComponent} from '../../akquise/akquise-save.component';
import {Contact} from '../model/contact';
import {ContactCreateModalService} from '../contact/create/customer-contact-create.service';

@Component({
  selector: 'customer-navigation',
  templateUrl: './customer-navigation.component.html',
})
export class CustomerNavigationComponent implements OnInit {
  @Input() customer?: CustomerId;
  // used to share customer and contacts for customer-contact-list
  @Input() data?: {customer: CustomerId, contacts?: Contact[]};
  @Output() customerLocker = new EventEmitter<null | string>();
  @Output() customerDeleted = new EventEmitter<null | string>();
  state: StateService;

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private customerService: CustomerService,
              private contactCreateModalService: ContactCreateModalService,
              private stateService: StateService,
              private eventService: EnvisiaEventService) {
    this.state = stateService;
  }

  ngOnInit(): void {
    if (this.data) {
      this.customer = this.data.customer;
    }
  }

  deleteCustomer(): void {
    if (confirm('Möchsten Sie diesen Kunden löschen?')) {
      this.customerService.delete(this.customer.id).subscribe(() => {
        this.customer.date_deleted = DateHelper.format(new Date());
        this.customerDeleted.next(this.customer.date_deleted);
        this.alertService.add('success', 'Kunde erfolgreich gelöscht!');
      }, () => {
        this.alertService.add('danger', 'Kunde konnte nicht gelöscht werden!');
      });
    }
  }

  lockButton(): string {
    let message = 'Kunde sperren';
    const locked = this.isLocked();
    if (locked === true) {
      message = 'Kunde entsperren';
    }
    return message;
  }

  lockCustomer(): void {
    let message = 'Möchten Sie diesen Kunden';
    const locked = this.isLocked();
    if (locked) {
      message += ' entsperren?';
    } else {
      message += ' sperren?';
    }
    if (confirm(message)) {
      this.customerService.toggleLock(this.customer.id).subscribe(() => {
        let data = null;
        if (!locked) {
          data = DateHelper.format(new Date());
        }
        this.customer.locked = data;
        this.customerLocker.next(data);
        if (locked) {
          message = 'entsperrt!';
        } else {
          message = 'gesperrt!';
        }
        this.alertService.add('success', 'Kunde erfolgreich ' + message);
      });
    }
  }

  newContact(): void {
    this.contactCreateModalService.open(this.customer).subscribe(response => {
      // FIXME: angularjs 1.x remove me, this is for compat of customer-router
      if (this.data && this.data.contacts) {
        this.data.contacts.unshift(response);
      }
      this.eventService.next({command: 'contact_new', data: response});
    }, () => {
    });
  }

  activity(): void {
    const modalRef = this.modalService.open(AkquiseSaveComponent, {windowClass: 'modal2-akquise'});
    modalRef.componentInstance.customer = this.customer;
    modalRef.result.then(() => {
      this.eventService.next({command: 'akquise_list_refresh'});
    }, () => {
    });
  }

  private isLocked(): boolean {
    if (this.customer) {
      return this.customer.locked !== null && this.customer.locked !== undefined;
    } else {
      return false;
    }
  }

}
