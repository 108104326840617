import {Component, Input, OnInit} from '@angular/core';
import {Article, SmallArticle} from '../models/article.models';
import {StateService} from '@uirouter/angular';
import {ArticleMultilayerPlan} from '../main/multilayer-plan/article-multilayer-plan.models';

@Component({
  selector: 'article-document',
  templateUrl: './article-document.component.html'
})
export class ArticleDocumentComponent implements OnInit {
  @Input() search: any;
  @Input() article: Article | SmallArticle;
  @Input() multilayerPlan?: ArticleMultilayerPlan;
  workflow_id: string | null | undefined;

  constructor(private stateService: StateService) {
  }

  ngOnInit(): void {
    this.workflow_id = this.stateService.params.workflow_id;
  }

}
