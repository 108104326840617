import {Component, Input} from '@angular/core';
import {WorkflowPositionView} from '../../../models/position.models';

@Component({
  selector: 'position-readonly-box',
  templateUrl: './position-readonly-box.component.html',
})
export class PositionReadonlyBoxComponent {
  @Input() positions: WorkflowPositionView[];

  constructor() {
  }
}
