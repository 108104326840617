<form #changeForm="ngForm" name="form">
  <div class="row">
    <div class="col-sm-5"><h2>Bestellungen</h2></div>
    <div class="col-sm-7">
      <a class="click"
         uiSref="a.material.inventory.update"
         target="_blank"
         [uiParams]="{variation: currentVariationId}">
        Bestellungen öffnen
      </a>
    </div>
  </div>
  <table class="table whitetable whitetable-borderless">
    <tr>
      <th>Status</th>
      <th>Bestelldatum</th>
      <th>Liefertermin</th>
      <th class="align-right">Menge</th>
    </tr>
    <ng-container *ngFor="let position of list.objects">
      <tr>
        <td>
          <material-order-position-status [id]="position.id"
                                          [edit]="false"
                                          [status]="position.status"
                                          [deleted]="!!position.date_deleted">
          </material-order-position-status>
        </td>
        <td>{{position.order?.date_created|date:'dd.MM.yyyy'}}</td>
        <td>{{position.date_delivery|date:'dd.MM.yyyy'}}</td>
        <td class="align-right">
          <a class="click" [ngbTooltip]="position.note" *ngIf="!!position.note"><i class="fa fa-info-circle"></i></a>
          {{position.amount|money}}
        </td>
      </tr>
      <tr>
        <td colspan="2"></td>
        <td style="font-size: 8px" *ngIf="position.date_delivery !== position.date_delivery_first_contact">
          {{position.date_delivery_first_contact|date:'dd.MM.yyyy'}}
        </td>
        <td *ngIf="position.date_delivery === position.date_delivery_first_contact"></td>
        <td class="align-right" style="font-size: 8px" *ngIf="position.amount !== position.amount_first_contact">
          {{position.amount_first_contact|money}}
        </td>
        <td *ngIf="position.amount !== position.amount_first_contact"></td>
      </tr>
    </ng-container>
  </table>
  <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
</form>
