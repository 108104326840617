import {Component, Input} from '@angular/core';
import {Comment} from '../../comment/comment.models';
import {MaterialOrderCommentAdditional} from './comment-material-order.model';

@Component({
  selector: 'comment-material-order',
  templateUrl: './comment-material-order.component.html',
})
export class CommentMaterialOrderComponent {
  @Input() comment: Comment;
  @Input() add: MaterialOrderCommentAdditional;
}
