/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../core/shared.module';
import {FetchBoxComponent} from './fetch-box.component';
import {FetchBoxInnerComponent} from './fetch-box-inner.component';
import {LoadingOrErrorModule} from '../../../../common/loading/loading-or-error.module';
import { FetchBoxPositionComponent } from './fetch-box-position.component';

@NgModule({
    imports: [
        SharedModule,
        LoadingOrErrorModule
    ],
    declarations: [
        FetchBoxComponent,
        FetchBoxInnerComponent,
        FetchBoxPositionComponent
    ],
    exports: [
        FetchBoxComponent
    ]
})
export class FetchBoxModule {
}
