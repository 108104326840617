import {Injectable} from '@angular/core';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {Observable} from 'rxjs';
import {
  Inventory,
  InventoryGroup,
  InventoryGroupWithBA,
  InventoryListData, InventoryReserved,
  InventoryStorage,
  StorageBin,
  StorageBinInsertForm,
  StorageBinUpdateForm,
  StorageBinWithSize,
} from './inventory.models';
import {HttpClient} from '@angular/common/http';
import {ListService} from '../../../common/search/search.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ArticleInventorySum} from '../../workflow/models/inventory.models';

@Injectable({providedIn: 'root'})
export class InventoryService implements ListService<InventoryListData> {

  private uri = '/api/v1/inventory/';

  constructor(private http: HttpClient) {
  }

  inventorySum(article: string, workflowId?: number): Observable<ArticleInventorySum> {
    return this.http.get<ArticleInventorySum>(
      `${environment.apiv4uri}inventory/${article}/sum`,
      {params: EnvisiaLocation.httpParams({'workflow_id': workflowId})}
    );
  }

  storage(): Observable<StorageBin[]> {
    return this.http.get<EnvisiaObjects<StorageBin>>(this.uri + 'storage/').pipe(map(obj => obj.objects));
  }

  inventoryList(article: string, query?: any): Observable<Lister<Inventory>> {
    return this.http.get<Lister<Inventory> | null>(
      this.uri + article + '/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  articleGroupWithNotes(article: string): Observable<{ objects: InventoryGroup[], sum: number }> {
    return this.http.get<{ objects: InventoryStorage[], sum: number }>(
      `${environment.apiv4uri}inventory/storage/${article}`
    ).pipe(map((o) => {
      // Reformat to old variable names
      return {
        sum: o.sum,
        objects: o.objects.map(i => {
          return {
            dateCode: i.date_code,
            totalSumQuantity: i.quantity,
            storageBinName: i.storage_bin_name,
            storageBinNumber: i.storage_bin_number,
            workflowId: i.workflow_id,
            note: i.note,
            dateCodeMax: i.date_code_max,
            maxDateReached: i.max_date_reached,
          };
        }),
      };
    }));
  }

  listReservedForBa(oaNr: string): Observable<Lister<InventoryReserved>> {
    return this.http.get<Lister<InventoryReserved>>(`${environment.apiv4uri}inventory/reservations/${oaNr}`);
  }

  businessOrderInventoryRelationRelease(inventory: InventoryReserved): Observable<void> {
    return this.http.delete<void>(`${environment.apiv4uri}inventory/reservation/${inventory.id}/business/order/${inventory.ba_id}/1`);
  }

  list(query?: any): Observable<Lister<InventoryListData>> {
    return this.http.get<Lister<InventoryListData>>(
      this.uri,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  // --- admin routes

  adminList(): Observable<StorageBinWithSize[]> {
    return this.http.get<EnvisiaObjects<StorageBinWithSize>>(this.uri + 'admin/storage/').pipe(
      map(value => value.objects)
    );
  }

  adminInsert(data: StorageBinInsertForm): Observable<StorageBin> {
    return this.http.post<EnvisiaObject<StorageBin>>(this.uri + 'admin/storage/', data).pipe(
      map(value => value.object)
    );
  }

  adminUpdate(name: string, data: StorageBinUpdateForm): Observable<StorageBin> {
    return this.http.put<EnvisiaObject<StorageBin>>(this.uri + 'admin/storage/' + name + '/', data).pipe(
      map(value => value.object)
    );
  }

  adminDelete(name: string): Observable<void> {
    return this.http.delete<void>(this.uri + 'admin/storage/' + name + '/');
  }

  transferStock(article: string, form: any): Observable<any> {
    return this.http.post<any>(`${environment.apiv4uri}inventory/storage/${article}/transfer/stock`, form);
  }

  insertNote(article: string, form: any): Observable<any> {
    return this.http.post<any>(`${environment.apiv4uri}inventory/storage/${article}/note`, form);
  }

  editNote(article: string, form: any): Observable<any> {
    return this.http.put<any>(`${environment.apiv4uri}inventory/storage/${article}/note`, form);
  }

}
