/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {Transition, StateService} from '@uirouter/angular';
import {WorkflowFactoryService} from './workflow-factory.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {BigCreateWorkflow, BigWorkflowInvoice, BigWorkflowOffer} from '../models/workflow-create.models';
import {OfferService} from './offer.service';
import {EMPTY, Observable, forkJoin, of} from 'rxjs';
import {AddressService} from '../../customer/services/address.service';
import {ContractService} from './contract.service';
import {DeliveryService} from './delivery.service';
import {InvoiceService} from '../invoice/services/invoice.service';
import {PriceIncreaseFlexService} from '../../price-increase-flex/price-increase-flex.service';
import {CustomerCreditWorthinessService} from '../../customer/credit-worthiness/customer-credit-worthiness.service';

@Injectable({providedIn: 'root'})
export class WorkflowCreateDocumentService {

  constructor(private stateService: StateService,
              private workflowFactoryService: WorkflowFactoryService,
              private offerService: OfferService,
              private addressService: AddressService,
              private contractService: ContractService,
              private deliveryService: DeliveryService,
              private invoiceService: InvoiceService,
              private priceIncreaseFlexService: PriceIncreaseFlexService,
              private customerCreditWorthinessService: CustomerCreditWorthinessService) {
  }

  call(transition: Transition): Observable<any> {
    const workflowId = transition.params().id;
    const name = transition.params().name;

    return this.workflowFactoryService.create(workflowId, false).pipe(
      mergeMap<BigCreateWorkflow, any>(val => {
        const workflow = val.workflow.object;
        const isOffer = WorkflowFactoryService.workflowStatusCheck(workflow);

        let obs: Observable<BigCreateWorkflow | BigWorkflowOffer | BigWorkflowInvoice> = EMPTY;
        if (name === 'offer' && isOffer) {
          obs = this.offerCreateObs(val);
        } else if (name === 'contract' && isOffer) {
          obs = this.offerContractCreateObs(val);
        } else if (name === 'contract' && !isOffer) {
          obs = this.contractCreateObs(val);
        } else if (name === 'delivery' && !isOffer) {
          obs = this.deliveryCreateObs(val);
        } else if (name === 'invoice' && !isOffer) {
          obs = this.invoiceCreateObs(val);
        } else {

          this.stateService.go('o.error404');
        }

        return obs;
      })
    );

  }

  private offerCreateObs(val: BigCreateWorkflow) {
    return this.offerService.workflowList(val.workflow.object.id).pipe(
      catchError(() => of([])),
      map(offersList => {
        val['offer_list'] = offersList;
        return val;
      })
    );
  }

  private offerContractCreateObs(val: BigCreateWorkflow) {
    return this.offerService.workflowList(val.workflow.object.id).pipe(
      catchError(() => of([])),
      map(offersList => {
        val['positions'] = val.workflow.positions;
        val['last_offer'] = offersList.length > 0 ? offersList[offersList.length - 1] : null;
        return val;
      })
    );
  }

  private contractCreateObs(val: BigCreateWorkflow) {
    const offersObs = this.offerService.workflowList(val.workflow.object.id).pipe(catchError(() => of([])));
    const addressListObs = this.addressService.list({customer_id: val.customer.id});
    const additionalObs = this.workflowFactoryService.createAdditionalObs(val);
    const contractListObs = this.contractService.createList(val.workflow.object.id);
    const pifObs = this.priceIncreaseFlexService.get(val.workflow.object.id);
    const customerCredit = this.customerCreditWorthinessService.get(val.workflow.object.customer_id);

    return forkJoin([
      offersObs,
      addressListObs,
      additionalObs,
      contractListObs,
      pifObs,
      customerCredit,
    ]).pipe(
      map(responses => {
        val['last_offer'] = responses[0].length > 0 ? responses[0][responses[0].length - 1] : null;
        val['address'] = responses[1];
        val['additional'] = responses[2];
        val['contract_list'] = responses[3];
        val['price_increase_flex'] = responses[4].object;
        val['customer_credit_worthiness'] = responses[5];
        return val;
      })
    );
  }

  private deliveryCreateObs(val: BigCreateWorkflow) {
    const addressListObs = this.addressService.list({customer_id: val.customer.id});
    const additionalObs = this.workflowFactoryService.createAdditionalObs(val);
    const deliveryListObs = this.deliveryService.list({workflow_id: val.workflow.object.id});
    const customerCredit = this.customerCreditWorthinessService.get(val.workflow.object.customer_id);

    return forkJoin([
      addressListObs,
      additionalObs,
      deliveryListObs,
      customerCredit,
    ]).pipe(
      map(responses => {
        val['addresses'] = responses[0];
        val['additional'] = responses[1];
        val['delivery'] = responses[2];
        val['customer_credit_worthiness'] = responses[3];
        return val;
      })
    );
  }

  private invoiceCreateObs(val: BigCreateWorkflow) {
    const addressListObs = this.addressService.list({customer_id: val.customer.id});
    const additionalObs = this.workflowFactoryService.createAdditionalObs(val);
    const deliveryListObs = this.invoiceService.deliveryList(val.workflow.object.id);
    const overviewListObs = this.invoiceService.overviewList(val.workflow.object.id);

    return forkJoin([
      addressListObs,
      additionalObs,
      deliveryListObs,
      overviewListObs,
    ]).pipe(
      map(responses => {
        val['address'] = responses[0];
        val['additional'] = responses[1];
        val['delivery_list'] = responses[2];
        val['overview_list'] = responses[3];

        return val;
      })
    );
  }


}
