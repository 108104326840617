import {Component, Input} from '@angular/core';
import {CustomerOposSharedTableComponent} from './customer-opos-shared-table.component';
import {OposHistoryHelperService} from '../../opos/history/opos-history-helper.service';
import {OposFinalData} from '../../opos/opos.models';
import {noop} from '../../../helper/noop';
import {AbstractControl} from '@angular/forms';
import {OposService} from '../../opos/services/opos.service';

@Component({
  selector: 'customer-opos-invoice-table',
  templateUrl: './customer-opos-invoice-table.component.html'
})
export class CustomerOposInvoiceTableComponent extends CustomerOposSharedTableComponent {
  @Input() objects: OposFinalData[];
  @Input() customerId: number;

  constructor(private oposHistoryHelperService: OposHistoryHelperService,
              private oposService: OposService) {
    super('objects');
  }

  history(obj: OposFinalData): void {
    this.oposHistoryHelperService.open(obj.invoice_id);
  }

  trackByFn(index: number, item: AbstractControl): number {
    noop(this, item);
    return index;
  }

  datesChanged(index: number, returnData: OposFinalData): void {
    this.objects[index] = returnData;
  }
}
