<customer-header-template [customer]="customer"></customer-header-template>

<div class="row">
  <div class="col-lg-12">

    <div *ngFor="let obj of comments" class="whitebox whitebox-margin">
      <div class="row">

        <div class="col-md-6">
          <h5>
            <i class="fa fa-user"></i> Benutzer: {{ obj.comment.username }} -
            <a uiSref="a.workflow.detail" [uiParams]="{id:obj.workflow.id}" target="_blank">
              Vorgang: {{ obj.workflow.id}}
            </a>
            &nbsp;
            &nbsp;
            <workflow-state [key]="obj.workflow.status"></workflow-state>
          </h5>
        </div>

        <div class="col-md-6" style="text-align: right;">
          Datum: {{ obj.comment.date}}
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">
          <pre>{{ obj.comment.text }}</pre>
        </div>
      </div>

    </div>

  </div>
</div>
