import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ArticleMatrix} from './article-matrix.models';
import {deepCopy} from '../../../../helper/deep-copy';
import {noop} from '../../../../helper/noop';

export const ARTICLE_MATRIX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleMatrixComponent),
  multi: true,
};

@Component({
  selector: 'article-matrix',
  templateUrl: './article-matrix.component.html',
  providers: [
    ARTICLE_MATRIX_VALUE_ACCESSOR,
  ]
})
export class ArticleMatrixComponent implements ControlValueAccessor {
  @Input() defaults: ArticleMatrix;
  @Input() error = false;
  init = false;
  data: ArticleMatrix;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  writeValue(obj: any): void {
    if (obj === undefined || obj === null || obj === '') {
      this.data = deepCopy(this.defaults);
    } else {
      this.data = obj;
    }
    if (this.data.Sonstiges === undefined) {
      this.data.Sonstiges = {'this': false, 'other': [false, false, false, false, false]};
    }
    this.init = true;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  changeValue($event: Event, name: string, index: string | number): void {
    const dataValue = ($event.target as HTMLInputElement).checked;
    if (typeof index === 'string') {
      this.data[name]['this'] = dataValue;
    } else {
      this.data[name]['other'][index] = dataValue;
    }
    this._onChange(this.data);
  }

  trackByFn(index: number, item: boolean): number {
    noop(this);
    return index;
  }

}
