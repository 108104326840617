<div>

  <div class="content-box">
    <h3>Datei auswählen</h3>

    <file-drop (changeEmitter)="doUpdateFile($event)"></file-drop>
  </div>

</div>

<div style="clear: both;">&nbsp;</div>

<button type="button" class="btn btn-default btn-lg pull-left next-button" (click)="reverseEmitter.emit({})">
  Zurück
</button>

<button type="button" class="btn btn-success btn-lg pull-right next-button"
        (click)="start()"
        [disabled]="file === null || pending">
  Verarbeitung starten
</button>
