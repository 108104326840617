<div class="row margin-up-down-10">
  <div class="col-md-12">
    <div class="btn-group">
      <a class="btn btn-default btn-sm"
         uiSref="a.article.workflow.overview" [uiParams]="{id:article, workflow_id: workflowId}">
        Artikelansicht
      </a>
      <a class="btn btn-default btn-sm"
         uiSref="a.article.workflow.detail" [uiParams]="{id:article, workflow_id: workflowId}">
        CAM
      </a>
      <a class="btn btn-info btn-sm alert-info" target="_blank" *ngIf="('price.detail'|HasPerm)"
         uiSref="a.price.check" [uiParams]="{id:article, workflow_id: workflowId}">
        Preisprüfung
      </a>
    </div>
  </div>
</div>
