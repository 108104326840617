import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article} from '../models/article.models';
import {ArticleInventorySum} from '../../workflow/models/inventory.models';

@Component({
  selector: 'article-box',
  templateUrl: './article-box.component.html'
})
export class ArticleBoxComponent implements OnInit {
  @Input() withTitle = true;
  @Input() targetWindow = '_self';
  @Input() right = true;
  @Input() workflowId?: number;
  @Input() article: Article;
  @Input() inventory: ArticleInventorySum;
  @Output() reloadInventory: EventEmitter<number> = new EventEmitter<number>();
  workflowUrl?: string;

  constructor() {
  }

  ngOnInit(): void {
    this.workflowUrl = '';
    if (this.workflowId) {
      this.workflowUrl = '?workflow_id=' + this.workflowId?.toString(10);
    }
  }
}
