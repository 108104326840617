import { Injectable, Injector } from '@angular/core';
import { EnvisiaInterceptor } from '../../common/http/http-interceptor';
import { createHttpClient } from './http-client.factory';

@Injectable({ providedIn: 'root' })
export class BasicHttpClient {
  public http = createHttpClient(this.injector, [EnvisiaInterceptor]);

  constructor(private injector: Injector) {
  }
}
