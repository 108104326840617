import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContractListData} from '../../models/contract.models';

@Component({
  selector: 'tr[contract-list-element-main]',
  templateUrl: './contract-list-element-main.component.html',
})
export class ContractListElementMainComponent {
  @Input('contract-list-element-main') contract: ContractListData;
  @Input('contract-list-element-main-toggle-show') toggleShow: boolean;
  @Output('contract-list-element-main-toggle-single-row') toggleSingleRow = new EventEmitter<any>();

  toggle(): void {
    this.toggleSingleRow.emit();
  }
}
