<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Impedanz
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="measurement_protocol"
                            [defaultValues]="defaultValues['measurement-protocol']"
                            class="article-form-item-values">
      Messprotokoll
    </article-extra-dropdown>

    <article-extra-text formControlName="measurement_protocol_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="measurement_coupon"
                            [defaultValues]="defaultValues['measurement-coupon']"
                            class="article-form-item-values">
      Messcoupon
    </article-extra-dropdown>

    <article-extra-text formControlName="measurement_coupon_other" class="article-form-item-extra"></article-extra-text>
  </div>

</div>
