<div class="modal-header">
  <div class="modal-title">
    <h3>Artikel Feld {{specification.name}} umbenennen</h3>
  </div>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="form-group">
      <label class="col-md-2 control-label" for="name_de">Bezeichnung DE</label>

      <div class="col-md-10">
      <input id="name_de"
             class="form-control"
             formControlName="name_de"
             [ngClass]="{red_alert: errors.name_de}">
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-2 control-label" for="name_en">Bezeichnung EN</label>

      <div class="col-md-10">
      <input id="name_en"
             class="form-control"
             formControlName="name_en"
             [ngClass]="{red_alert: errors.name_en}">
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-success pull-right" type="submit">
      Bezeichnungen Speichern
    </button>
  </div>
</form>
