/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {Component} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';

@Component({
  selector: 'article-extra-impedance',
  templateUrl: './article-extra-impedance.component.html',
})
export class ArticleExtraImpedanceComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'impedance';
  protected schemaName = 'impedance';
  protected fields = [
    'measurement_protocol',
    'measurement_protocol_other',
    'measurement_coupon',
    'measurement_coupon_other',
  ];
}
