import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  MaterialOrderIntakeListElement,
  MaterialOrderPosition,
} from '../../material-order.model';
import {FormGroup} from '@angular/forms';
import {MaterialOrderIntakeBookedPositionForm} from './material-order-intake-booked-form.model';
import {MaterialValueType} from '../../../material-management.model';
import {MaterialOrderPositionService} from '../../material-order-position.service';
import {MaterialOrderIntakeBookedRowAbstractComponent} from './material-order-intake-booked-row-abstract.component';

@Component({
  selector: 'tr[material-order-intake-booked-row-empty]',
  templateUrl: './material-order-intake-booked-row-empty.component.html',
  styleUrls: ['../../material-order.component.scss'],
})
export class MaterialOrderIntakeBookedRowEmptyComponent extends MaterialOrderIntakeBookedRowAbstractComponent {
  @Input('material-order-intake-booked-row-empty') position?: MaterialOrderIntakeListElement;
  @Input('material-order-intake-booked-row-empty-finder-supplier-uri') finderSupplierUri?: string;
  @Input('material-order-intake-booked-row-empty-form-position') formPosition: FormGroup<MaterialOrderIntakeBookedPositionForm>;
  @Input('material-order-intake-booked-row-empty-fields') fields: MaterialValueType[];
  @Output('material-order-intake-booked-row-empty-update-position') updatePositionEmitter =
    new EventEmitter<MaterialOrderPosition>();

  constructor(protected positionService: MaterialOrderPositionService) {
    super(positionService);
  }
}
