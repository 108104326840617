<form [formGroup]="form" (ngSubmit)="submit()">
  <h4>Volltext Suche</h4>
  <div class="document-list">
    <div class="document-list-search">
      <div class="document-list-search-action">
        <button class="btn btn-default" type="submit">
          Suchen
        </button>
      </div>
      <div class="document-list-search-input">
        <input class="form-control" formControlName="q">
      </div>

    </div>

    <div *ngFor="let item of data.hits">
      <finder-item-content [item]="item"></finder-item-content>
    </div>
  </div>

  <div class="document-list-pager">
    <button type="button" [disabled]="page === 0" class="document-list-pager-angle" (click)="previous()">
      <i class="fa fa-angle-left"></i>
    </button>

    <div class="document-list-pager-info">
      Seite {{ current() }}
    </div>

    <button type="button" [disabled]="last()" class="document-list-pager-angle" (click)="next()">
      <i class="fa fa-angle-right"></i>
    </button>
  </div>
</form>
