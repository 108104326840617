<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Über / Unterlieferung
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="row">
    <div class="col-md-6">
      <article-extra-table formControlName="over_delivery">
        Überlieferung
        <span class="left">Stück bis</span>
        <span class="middle">%</span>
        <span class="right">Stück</span>
      </article-extra-table>
    </div>
    <div class="col-md-6">
      <article-extra-table formControlName="under_delivery">
        Unterlieferung
        <span class="left">Stück bis</span>
        <span class="middle">%</span>
        <span class="right">Stück</span>
      </article-extra-table>
    </div>
  </div>
</div>
