import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {EnvisiaObjects} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {
  MaterialProductGroupPermissionForm,
  MaterialProductGroupUserRelation,
} from './material-user-product-group-permission.models';

@Injectable({
  providedIn: 'root'
})
export class MaterialUserProductGroupService {
  uri = `${environment.apiv4uri}material/product/group/permission`;

  constructor(private http: HttpClient) {
  }

  create(form: MaterialProductGroupPermissionForm): Observable<MaterialProductGroupUserRelation> {
    return this.http.post<MaterialProductGroupUserRelation>(this.uri, form);
  }

  delete(form: MaterialProductGroupPermissionForm): Observable<MaterialProductGroupUserRelation> {
    return this.http.delete<MaterialProductGroupUserRelation>(this.uri, {params: EnvisiaLocation.httpParams(form)});
  }

  list(userId: number): Observable<MaterialProductGroupUserRelation[]> {
    return this.http.get<EnvisiaObjects<MaterialProductGroupUserRelation>>(`${this.uri}/list/${userId}`)
      .pipe(map(o => o.objects));
  }
}
