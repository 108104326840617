<div class="finder">

  <div class="finder-content">

    <div class="finder-header">
      <h2>Neues Dokument hinzufügen</h2>
    </div>

    <div class="finder-body">
      <finder-upload-progress [status]="status"></finder-upload-progress>

      <div class="clearfix"></div>

      <finder-upload-category *ngIf="status === 1"
                              [categories]="category"
                              [initialData]="categoriesData"
                              (forwardEmitter)="onNextCategories($event)">
      </finder-upload-category>

      <finder-upload-additional *ngIf="status === 2"
                                [additionals]="additionals"
                                (forwardEmitter)="onNextAdditional($event)"
                                (reverseEmitter)="onReverse('category')">
      </finder-upload-additional>

      <finder-upload-file *ngIf="status === 3"
                          [data]="{categories: categoriesData, additionals: additionalsData}"
                          (forwardEmitter)="onNextFinish($event.guid)"
                          (reverseEmitter)="onReverse('additional')">
      </finder-upload-file>

      <finder-upload-success *ngIf="success"
                             [searchType]="searchType"
                             [guid]="guidData"
                             [id]="id"
                             (resetEmitter)="onReverse('start')"
                             (updateStatusEmitter)="onUpdateStatus()">
      </finder-upload-success>

      <div style="clear: both;">&nbsp;</div>
    </div>

  </div>

</div>
