<form class="form-inline small-input input-align-left small-form">
  <div class="row">

    <!-- Section 1 -->
    <div class="col-md-4 margin-bottom">
      <div class="row">

        <div class="col-md-12 margin-bottom">
          <article-extra-initial-sample-testing [schema]="schemaDictionary"
                                                [data]="data"
                                                [mainType]="mainType"
                                                (deleteCalled)="deleteSectionEmitter.emit($event)"
                                                (updateEmitter)="update($event)">
          </article-extra-initial-sample-testing>
        </div>

        <div class="col-md-12 margin-bottom">
          <article-extra-dimension-inspection [schema]="schemaDictionary"
                                              [data]="data"
                                              [mainType]="mainType"
                                              (deleteCalled)="deleteSectionEmitter.emit($event)"
                                              (updateEmitter)="update($event)">
          </article-extra-dimension-inspection>
        </div>

        <div class="col-md-12 margin-bottom">
          <article-extra-microsection [schema]="schemaDictionary"
                                      [data]="data"
                                      [mainType]="mainType"
                                      (deleteCalled)="deleteSectionEmitter.emit($event)"
                                      (updateEmitter)="update($event)">
          </article-extra-microsection>
        </div>
      </div>
    </div>
    <!-- End: Section 1 -->
    <!-- Section 2 -->
    <div class="col-md-4 margin-bottom">
      <div class="row">

        <div class="col-md-12 margin-bottom">
          <article-extra-c-o-c-testing-protocol [schema]="schemaDictionary"
                                                [data]="data"
                                                [mainType]="mainType"
                                                (deleteCalled)="deleteSectionEmitter.emit($event)"
                                                (updateEmitter)="update($event)">
          </article-extra-c-o-c-testing-protocol>
        </div>

        <div class="col-md-12 margin-bottom">
          <article-extra-product-specific-inspection-plan [schema]="schemaDictionary"
                                                          [data]="data"
                                                          [mainType]="mainType"
                                                          (deleteCalled)="deleteSectionEmitter.emit($event)"
                                                          (updateEmitter)="update($event)">
          </article-extra-product-specific-inspection-plan>
        </div>

        <div class="col-md-12 margin-bottom">
          <article-extra-e-test-labeling [schema]="schemaDictionary"
                                         [data]="data"
                                         [mainType]="mainType"
                                         (deleteCalled)="deleteSectionEmitter.emit($event)"
                                         (updateEmitter)="update($event)">
          </article-extra-e-test-labeling>
        </div>


        <div class="col-md-12 margin-bottom">
          <article-extra-solder-pattern [schema]="schemaDictionary"
                                        [data]="data"
                                        [mainType]="mainType"
                                        (deleteCalled)="deleteSectionEmitter.emit($event)"
                                        (updateEmitter)="update($event)">
          </article-extra-solder-pattern>
        </div>


        <div class="col-md-12 margin-bottom">
          <article-extra-impedance [schema]="schemaDictionary"
                                   [data]="data"
                                   [mainType]="mainType"
                                   (deleteCalled)="deleteSectionEmitter.emit($event)"
                                   (updateEmitter)="update($event)">
          </article-extra-impedance>
        </div>

        <div class="col-md-12 margin-bottom">
          <article-extra-storage [schema]="schemaDictionary"
                                 [data]="data"
                                 [mainType]="mainType"
                                 (deleteCalled)="deleteSectionEmitter.emit($event)"
                                 (updateEmitter)="update($event)">
          </article-extra-storage>
        </div>

        <div class="col-md-12 margin-bottom">
          <article-extra-delivery-date [schema]="schemaDictionary"
                                       [data]="data"
                                       [mainType]="mainType"
                                       (deleteCalled)="deleteSectionEmitter.emit($event)"
                                       (updateEmitter)="update($event)">
          </article-extra-delivery-date>
        </div>
        <div class="col-md-12 margin-bottom">
          <article-extra-termination-date [schema]="schemaDictionary"
                                          [data]="data"
                                          [mainType]="mainType"
                                          (deleteCalled)="deleteSectionEmitter.emit($event)"
                                          (updateEmitter)="update($event)">
          </article-extra-termination-date>
        </div>
      </div>
    </div>
    <!-- End: Section 2 -->

    <!-- Section 3 -->
    <div class="col-md-4 margin-bottom">
      <div class="row">
        <div class="col-md-12 margin-bottom">
          <article-extra-packaging [schema]="schemaDictionary"
                                   [data]="data"
                                   [article]="article"
                                   [mainType]="mainType"
                                   (deleteCalled)="deleteSectionEmitter.emit($event)"
                                   (updateEmitter)="update($event)">
          </article-extra-packaging>
        </div>

        <div class="col-md-12 margin-bottom">
          <article-extra-bad-part [schema]="schemaDictionary"
                                  [data]="data"
                                  [mainType]="mainType"
                                  (deleteCalled)="deleteSectionEmitter.emit($event)"
                                  (updateEmitter)="update($event)">
          </article-extra-bad-part>
        </div>
        <div class="col-md-12 margin-bottom">
          <article-extra-over-under-delivery [schema]="schemaDictionary"
                                             [data]="data"
                                             [mainType]="mainType"
                                             (deleteCalled)="deleteSectionEmitter.emit($event)"
                                             (updateEmitter)="update($event)">
          </article-extra-over-under-delivery>
        </div>
      </div>
    </div>
    <!-- End: Section 3 -->

  </div>
</form>
