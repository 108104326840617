import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {HttpParameterCodec, HttpParams} from '@angular/common/http';
import {deepCopy} from '../../helper/deep-copy';
import {isPlainObject} from '../../helper/serialize.func';
import {UrlService} from '@uirouter/angular';
import {Location} from '@angular/common';
import {SubscriptionLike} from 'rxjs';
import {ignoreRejection} from '../../helper/ignore_rejection';

function standardEncoding(v: string): string {
  return encodeURIComponent(v)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/gi, '$')
    .replace(/%2C/gi, ',')
    .replace(/%3B/gi, ';')
    .replace(/%2B/gi, '+')
    .replace(/%3D/gi, '=')
    .replace(/%3F/gi, '?')
    .replace(/%2F/gi, '/');
}

export class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return standardEncoding(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string) {
    return decodeURIComponent(value);
  }
}


@Injectable({providedIn: 'root'})
export class EnvisiaLocation {

  static clean(obj: any): any {
    for (const propName in obj) {
      if (obj.hasOwnProperty(propName)) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
          delete obj[propName];
        }
      }
    }
  }

  static decodeHttpParams(query?: any): any {
    if (query) {
      const finalMap = {};
      for (const propName in query) {
        if (query.hasOwnProperty(propName) && query[propName]) {
          finalMap[propName] = decodeURIComponent(query[propName]);
        }
      }
      return finalMap;
    } else {
      return query;
    }
  }

  static httpParamsWrapper(query: any): HttpParams {
    return new HttpParams({fromObject: query, encoder: new HttpUrlEncodingCodec()});
  }

  static httpParams(query?: string | any): HttpParams {
    let params;
    if (typeof query === 'string') {
      params = new HttpParams({fromString: query, encoder: new HttpUrlEncodingCodec()});
    } else if (query) {
      const copiedParams = deepCopy(query);
      delete copiedParams['#'];
      // clean any empty value from the plain object
      if (isPlainObject(copiedParams)) {
        EnvisiaLocation.clean(copiedParams);
      }
      params = new HttpParams({fromObject: copiedParams, encoder: new HttpUrlEncodingCodec()});
    }
    return params;
  }

  constructor(private urlService: UrlService,
              private stateService: StateService,
              private location: Location) {
  }

  subscribe(): SubscriptionLike {
    const path = this.urlService.path();
    return this.location.subscribe(value => {

      const currentParams = this.stateService.params;
      let problematic = false;
      if (currentParams.range_start_start ||
        currentParams.range_start_end ||
        currentParams.range_end_end ||
        currentParams.range_end_start) {
        problematic = true;
      }

      if (value.type === 'popstate') {
        const finalPopPath = this.urlService.path();
        const current = this.stateService.current.name;
        if (path === finalPopPath) {
            if (!problematic) {
              this.stateService.go(this.stateService.current, currentParams,
                {reload: true, location: false, inherit: false}).then(ignoreRejection, ignoreRejection);
            } else {
              if (current !== 'a.dashboard.main' && !!currentParams.dashboard) {
                this.stateService.go('a.dashboard');
              }
            }
        }
      }
    });
  }

  search(search?: any): any {
    let ret;
    if (search) {
      this.stateService.go(this.stateService.current, search, {reload: false, inherit: false});
      ret = this;
    } else {
      ret = this.urlService.search();
    }
    return EnvisiaLocation.decodeHttpParams(ret);
  }

  path(): string {
    return this.urlService.path();
  }

}
