import {Component, Input} from '@angular/core';
import {Article} from '../../../../article/models/article.models';

@Component({
  selector: 'order-request-article-box',
  templateUrl: './order-request-article-box.component.html',
})
export class OrderRequestArticleBoxComponent {
  @Input() article: Article;
  @Input() workflowId?: string;
  @Input() whitebox = true;
  @Input() targetWindow = '_self';

  constructor() {
  }

}
