/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {WorkflowListRequestComponent} from './list/workflow-list-request.component';
import {UIRouterModule} from '@uirouter/angular';
import {WORKFLOW_REQUEST_STATES} from './workflow-request.states';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {WorkflowOrderRequestMainModule} from '../order-request/main/workflow-order-request-main.module';

@NgModule({
  imports: [
    SharedModule,
    WorkflowPipesModule,
    UIRouterModule.forChild({states: WORKFLOW_REQUEST_STATES}),
    WorkflowPipesModule,
    WorkflowOrderRequestMainModule,
  ],
  declarations: [
    WorkflowListRequestComponent,
  ],
})
export class WorkflowRequestModule {
}
