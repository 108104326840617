<div class="comment-box">
  <h3 *ngIf="canEdit">Kommentare
    <span class="clickable text-primary" title="Kommentar hinzufügen" (click)="openBox()">
    <i class="fa fa-plus click"></i>
    </span>
  </h3>

  <form *ngIf="box && canEdit" class="new-task-comment" [formGroup]="createForm" (ngSubmit)="create()">
    <label>
      <textarea rows="3" class="form-control input-sm" formControlName="text"
                placeholder="Kommentar hinzufügen" autofocus="autofocus">
      </textarea>
      <button class="btn btn-sm btn-success" type="submit" style="margin-top: 5px;">
        Hinzufügen
      </button>
    </label>
  </form>

  <div class="whitelist commentlist">
    <div class="whitelist-item whitelist-item-no-cursor" *ngFor="let comment of comments; let index = index" [ngClass]="commentClasses(comment)">
      <div class="whitelist-detail">
        <div class="whitelist-item-detail-first" *ngIf="comment.additional">
          <strong>{{ comment.additional.headline }}</strong>
        </div>
        <div class="whitelist-item-detail-first">
          <div class="pull-left"><strong>{{ comment.date }} - {{ comment.username }}</strong></div>

          <div class="pull-right ml-2" *ngIf="canBeMarked(comment)">
            <button type="button"
                    class="ev-button"
                    title="Kommentar markieren"
                    (click)="mark(index, comment)">
              <i class="fa fa-flag" aria-hidden="true"></i>
            </button>
          </div>
          <div class="pull-right" *ngIf="isArticleOrWorkflow">
            <button type="button"
                    class="ev-button"
                    title="Kommentar löschen"
                    (click)="remove(index, comment)">
              <i class="fa fa-trash-o"></i>
            </button>
          </div>
        </div>
        </div>
        <div class="clear-both"></div>
        <!-- DO NOT WRAP: comment.text -->
        <div *ngIf="comment.typ !== 'label_print' && !comment.additional?.json && !comment.additional?.html" class="whitelist-item-detail-second">{{ comment.text }}</div>
        <div *ngIf="comment.typ === 'order' && comment.additional?.json" class="whitelist-item-detail-second">
          <p>Alte Werte:</p>
          <p>Menge: {{comment.additional?.order_edit_comment.quantity_old}}</p>
          <p>Preis: {{comment.additional?.order_edit_comment.price_old}}</p>
          <p>Lief.Ter.: {{comment.additional?.order_edit_comment.delivery_date_old}}</p>
          <p>Auf Abruf: <span *ngIf="comment.additional?.order_edit_comment.call_off_old">Ja</span>
            <span *ngIf="!comment.additional?.order_edit_comment.call_off_old">Nein</span>
          </p>
          <p>&nbsp;</p>
          <p>Neue Werte:</p>
          <p>Menge: {{comment.additional?.order_edit_comment.quantity_new}}</p>
          <p>Preis: {{comment.additional?.order_edit_comment.price_new}}</p>
          <p>Lief.Ter.: {{comment.additional?.order_edit_comment.delivery_date_new}}</p>
          <p>Auf Abruf: <span *ngIf="comment.additional?.order_edit_comment.call_off_new">Ja</span>
            <span *ngIf="!comment.additional?.order_edit_comment.call_off_new">Nein</span>
          </p>
        </div>
        <div
          *ngIf="comment.typ !== 'label_print' && !!comment.additional?.html"
          class="whitelist-item-detail"
          [innerHTML]="comment.text">
        </div>

      <comment-business-order [comment]="comment" *ngIf="comment.typ === 'business_order'">
      </comment-business-order>

        <div *ngIf="comment.typ === 'label_print'" class="whitelist-item-detail-second">
          <table class="label_print_table">
            <tr>
              <td>Prod-Datum: {{comment.additional.prod_date}}</td>
              <td>Stck. Gesamt: {{comment.additional.quantity_total}}</td>
            </tr>
            <tr>
              <td>Anzahl Nutzen: {{comment.additional.quantity_utilize}}</td>
              <td>E-Test: {{comment.additional.e_test}}</td>
            </tr>
          </table>
          <table class="label_print_table2">
            <thead>
              <tr>
                <th>Nr.</th>
                <th>Stck/VE</th>
                <th>N.sch.LP</th>
                <th>Sch.LP</th>
                <th>Lager</th>
              </tr>
            </thead>
            <tbody *ngFor="let obj of comment.additional.objects; let index = index">
              <tr>
                <td>{{index + 1}}</td>
                <td>{{obj.quantity}}</td>
                <td>{{obj.utilize}}</td>
                <td>{{obj.schlechte}}</td>
                <td>{{obj.inventory}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="comment.additional?.wastrel > 0">Ausschuss: {{comment.additional.wastrel}}</div>
      </div>
    </div>
  </div>
