import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FinderSearchService} from '../../finder-search.service';
import {FinderSearchItemData} from '../../finder.models';

@Component({
  selector: 'finder-upload-success',
  templateUrl: './finder-upload-success.component.html',
  styleUrls: ['./finder-upload-success.component.scss']
})
export class FinderUploadSuccessComponent implements OnInit, OnDestroy {
  @Input() guid: string;
  @Input() searchType: 'customer' | 'article' | 'workflow';
  @Input() id: string | number | null | undefined;
  @Output() updateStatusEmitter = new EventEmitter<{}>();
  @Output() resetEmitter = new EventEmitter<{}>();
  httpStatus: 'processing' | 'error' | 'success' = 'processing';
  success: FinderSearchItemData[] | null = null;
  status: 4 | 5 = 4;
  href: string;
  stopping = false;
  timeouts: any[] = [];

  constructor(private finderSearchService: FinderSearchService) {
  }

  ngOnInit() {
    this.href = this.buildUrl();
    this.poll(this.guid);
  }

  ngOnDestroy(): void {
    this.stopping = true;
    this.timeouts.forEach(id => clearTimeout(id));
  }

  private poll(guid: string): void {
    this.finderSearchService.poll(guid).subscribe(response => {
      if (response.status === 'success') {
        this.status = 5;
        this.updateStatusEmitter.emit({});
        this.success = response.data;
        this.httpStatus = response.status;
      } else if (response.status === 'processing' && !this.stopping) {
        const timeoutId = setTimeout(() => {
          this.poll(guid);
        }, 5000);
        this.timeouts.push(timeoutId);
      }
    }, response => {
      this.status = 5;
      this.updateStatusEmitter.emit({});
      this.httpStatus = response.error.status;
    });
  }

  private buildUrl(): string {
    let url;
    switch (this.searchType) {
      case 'customer':
        url = '#!/crm/' + this.id + '/document/list';
        break;
      case 'article':
        url = '#!/article/' + this.id + '/document/list';
        break;
      case 'workflow':
        url = '#!/workflow/document/' + this.id;
        break;
    }
    return url;
  }

}
