import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {ArticleService} from '../../services/article.service';

@Component({
  selector: 'article-unlock-modal',
  templateUrl: './article-unlock.modal.component.html',
})
export class ArticleUnlockModalComponent implements OnInit {
  @Input() oa: string;
  errors: { [key: string]: any; } = {};

  unlockForm: UntypedFormGroup;

  constructor(private articleService: ArticleService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder,
              private modalRef: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.buildForm();
    // locking is allowed if valid is marked
  }

  submit(): void {
    this.errors = {};
    this.articleService.unlock(this.oa, this.unlockForm.value).subscribe(() => {
     this.alertService.add('success', 'Entsperren des Artikels erfolgreich!');
      this.modalRef.close(false);
   }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      console.log(response);
     this.alertService.add('danger', 'Beim Entsperren des Artikels ist ein Fehler aufgetreten!');
   });

  }

  close(): void {
    this.modalRef.dismiss();
  }

  private buildForm(): void {
    this.unlockForm = this.fb.group({
      'reference': [null],
    });
  }

}
