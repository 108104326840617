import {Component, Input, OnInit} from '@angular/core';
import {MultilayerPlanHolderAbstractComponentDirective} from './multilayer-plan-holder-abstract-component.directive';
import {ArticleSpecification} from '../../envisia/article/models/article-specification.model';
import {Lister} from '../../common/wrapper.models';
import {ArticleMultilayerPlan} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.models';
import {ArticleMultilayerPlanHelper} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan-helper';

@Component({
  selector: 'multilayer-plan-create-holder',
  templateUrl: './multilayer-plan-create-holder.component.html',
})
export class MultilayerPlanCreateHolderComponent extends MultilayerPlanHolderAbstractComponentDirective implements OnInit {
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() list: Lister<ArticleMultilayerPlan>;

  constructor() {
    super();
  }

  ngOnInit() {
    this.model = ArticleMultilayerPlanHelper.emptyModel();
  }
}
