import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'article-ab-checkbox',
  templateUrl: './article-ab-checkbox.component.html'
})
export class ArticleAbCheckboxComponent {
  @Input() label: string;
  @Input() model1: string;
  @Input() model2: string;
  @Input() form: UntypedFormGroup;
  @Input() error1?: string;
  @Input() error2?: string;
  @Output() changeEmitter = new EventEmitter<string>();

  change(name: string): void {
    this.changeEmitter.next(name);
  }

}
