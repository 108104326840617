/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {ListService} from '../../../common/search/search.service';
import {RequestListData} from '../../../envisia/workflow/request/workflow-request.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {HttpClient} from '@angular/common/http';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {OrderRequest, OrderRequestForm, OrderRequestView} from '../request/workflow-request.model';

@Injectable({providedIn: 'root'})
export class WorkflowRequestService implements ListService<RequestListData> {

  private uri = '/api/v1/order/request/';

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<RequestListData>> {
    return this.http.get<Lister<RequestListData>>(this.uri, {params: EnvisiaLocation.httpParams(query)});
  }

  detail(id: number): Observable<OrderRequestView> {
    return this.http.get<OrderRequestView>(this.uri + id + '/');
  }

  create(id: number, form: OrderRequestForm): Observable<{objects: OrderRequest[], errors: string[]}> {
    return this.http.post<{objects: OrderRequest[], errors: string[]}>(this.uri + id + '/', form);
  }

}
