import { Injectable } from '@angular/core';
import {ListService} from '../../../../common/search/search.service';
import {MaterialOrderPositionWithWeek} from '../material-order.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lister} from '../../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialOrderPositionSmallListService implements ListService<MaterialOrderPositionWithWeek> {

  constructor(protected http: HttpClient) {
  }

  list(query?: any): Observable<Lister<MaterialOrderPositionWithWeek>> {
    return this.http.get<Lister<MaterialOrderPositionWithWeek>>(
      environment.apiv4uri + 'material/order/list/variation',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}
