import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../common/alert-service/alert.service';
import {CustomerIncreaseService} from '../services/customer-increase.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'customer-increase-modal',
  templateUrl: './customer-increase-modal.component.html'
})
export class CustomerIncreaseModalComponent implements OnInit {
  @Input() typ: string;
  @Input() customerId: number;
  uppercaseTyp: string;
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbActiveModal,
              private alertService: AlertService,
              private customerIncreaseService: CustomerIncreaseService) {
  }

  ngOnInit(): void {
    this.uppercaseTyp = this.typ.toUpperCase();
    this.form = this.fb.group({
      percent: ['']
    });
  }

  dismiss(): void {
    this.modalService.dismiss();
  }

  save(): void {
    this.errors = {};
    const form = {percent: this.form.value.percent, typ: this.typ};
    this.customerIncreaseService.create(this.customerId, form).subscribe(value => {
      this.alertService.add('success', 'Preiserhöhung erfolgreich hinzugefügt');
      this.modalService.close(value);
    }, response => {
      this.alertService.add('danger', 'Beim Hinzufügen einer Preiserhöhung ist ein Fehler aufgetreten');
      if (response.error) {
        this.errors = response.error;
      }
    });
  }

}
