import {Component, Input} from '@angular/core';
import {OrderHistory} from '../../order-request.models';

@Component({
  selector: 'order-box-history',
  templateUrl: './order-box-history.component.html'
})
export class OrderBoxHistoryComponent {
  @Input() type: string;
  @Input() history: { [key: string]: OrderHistory[] };

  constructor() {
  }
}
