import {Customer} from '../customer/model/customer';
import {DateHelper} from '../../helper/date-helper';
import {Money} from '../../common/money/Money';

export interface IOposCorrection {
  id: number;
  document_id: string;
  date: string;
  customer_id: number;
  invoice_id?: number;
  hint?: string;
  label: string;
  description?: string;
  ust_id?: string;
  account: number;
  sum: string;
  revenue: string;
  username: string;
  expensed: string;
  expensed_date?: string;
  skonto: number;
  open: string;
  customer_skonto: number;
}

export interface IOposFinalData {
  invoice_id: number;
  invoice_storno_id?: number;
  workflow_id: number;
  typ: string;
  state: OposState;
  date: string;
  terms: number;
  due_date: string;
  revenue: string;
  expensed: string;
  open: string;
  skonto: number;
  customer_skonto: number;
  expensed_date?: string;
  save: boolean;
  state_original: OposState;
  target_date: string | null;
}

export interface IOposListData {
  objects: IOposFinalData[];
  total_revenue: string;
  total_expensed: string;
  total_open: string;
  customer: Customer;
  corrections: IOposCorrection[];
  correction_total_revenue: string;
  correction_total_expensed: string;
  correction_total_open: string;
}

export interface OposState {
  id: number;
  name: string;
  label: string;
}

class OposStates {
  public static readonly Valuta: OposState = {id: -200, name: 'Valuta', label: 'primary'};
  public static readonly Due: OposState = {id: -100, name: 'Fällig', label: 'info'};
}

export class OposFinalData implements IOposFinalData {
  public focus_skonto = 0;
  public focus_expensed = 0;
  public focus_save = 0;
  public skonto_total: string;
  public numberRevenue: number;

  static fromInterface(finalData: IOposFinalData): OposFinalData {
    const now = DateHelper.now();
    const dateParsed = DateHelper.safeParse(finalData.date);
    const dueDateParsed = DateHelper.copy(dateParsed);
    dueDateParsed.setDate(dueDateParsed.getDate() + finalData.terms);
    const targetDateParsed = DateHelper.safeParse(finalData.target_date);
    const isValuta = !!targetDateParsed;
    const isValutaNotDue = isValuta && (targetDateParsed > now);
    const isNotDue = (!!dueDateParsed && (dueDateParsed > now));
    const state: OposState = isValuta ?
      (isValutaNotDue ? {...OposStates.Valuta} : {...OposStates.Due}) :
      (isNotDue ? {...finalData.state_original} : {...OposStates.Due});

    return new OposFinalData(
      finalData.invoice_id,
      finalData.invoice_storno_id,
      finalData.workflow_id,
      finalData.typ,
      state,
      DateHelper.format(dateParsed),
      finalData.terms,
      DateHelper.format(dueDateParsed),
      finalData.revenue,
      finalData.expensed,
      finalData.open,
      finalData.skonto,
      finalData.customer_skonto,
      finalData.expensed_date,
      finalData.save,
      finalData.state_original,
      !targetDateParsed ? null : DateHelper.format(targetDateParsed),
    );
  }

  constructor(public invoice_id: number,
              public invoice_storno_id: number | null | undefined,
              public workflow_id: number,
              public typ: string,
              public state: OposState,
              public date: string,
              public terms: number,
              public due_date: string,
              public revenue: string,
              public expensed: string,
              public open: string,
              public skonto: number,
              public customer_skonto: number,
              public expensed_date: string | null | undefined,
              public save: boolean,
              public state_original: OposState,
              public target_date: string | null) {

    this.numberRevenue = Money.parse(revenue);
    const numberExpensed = Money.parse(expensed);
    this.skonto_total = Money.stringify(this.numberRevenue - numberExpensed, 2);
  }

}

export class OposCorrection {
  public focus_skonto = 0;
  public focus_expensed = 0;
  public focus_save = 0;
  public skonto_total: string;
  public numberRevenue: number;

  static fromInterface(oposCorrection: IOposCorrection): OposCorrection {
    return new OposCorrection(
      oposCorrection.id,
      oposCorrection.document_id,
      oposCorrection.date,
      oposCorrection.customer_id,
      oposCorrection.invoice_id,
      oposCorrection.hint,
      oposCorrection.label,
      oposCorrection.description,
      oposCorrection.ust_id,
      oposCorrection.account,
      oposCorrection.sum,
      oposCorrection.revenue,
      oposCorrection.username,
      oposCorrection.expensed,
      oposCorrection.expensed_date,
      oposCorrection.skonto,
      oposCorrection.open,
      oposCorrection.customer_skonto,
    );
  }

  constructor(public id: number,
              public document_id: string,
              public date: string,
              public customer_id: number,
              public invoice_id: number | null | undefined,
              public hint: string | null | undefined,
              public label: string,
              public description: string | null | undefined,
              public ust_id: string | null | undefined,
              public account: number,
              public sum: string,
              public revenue: string,
              public username: string,
              public expensed: string,
              public expensed_date: string | null | undefined,
              public skonto: number,
              public open: string,
              public customer_skonto: number) {

    this.numberRevenue = Money.parse(revenue);
    const numberExpensed = Money.parse(expensed);
    this.skonto_total = Money.stringify(this.numberRevenue - numberExpensed, 2);
  }

}

export class OposListData {

  static fromInterface(listData: IOposListData) {
    return new OposListData(
      listData.objects.map(value => OposFinalData.fromInterface(value)),
      listData.total_revenue,
      listData.total_expensed,
      listData.total_open,
      listData.customer,
      listData.corrections.map(value => OposCorrection.fromInterface(value)),
      listData.correction_total_revenue,
      listData.correction_total_expensed,
      listData.correction_total_open
    );
  }

  constructor(public objects: OposFinalData[],
              public total_revenue: string,
              public total_expensed: string,
              public total_open: string,
              public customer: Customer,
              public corrections: OposCorrection[],
              public correction_total_revenue: string,
              public correction_total_expensed: string,
              public correction_total_open: string) {
  }

}

export interface OposSaveData {
  invoice_id: number;
  expensed: string;
  skonto: number;
  skontoTotal: string;
  save: boolean;
}

export interface CorrectionSaveData {
  correctionId: number;
  expensed: string;
  skonto: number;
  save: boolean;
}

export interface OposSaveHandler {
  objects: OposSaveData[];
  corrections: CorrectionSaveData[];
  date: string;
}

export interface OposHistory {
  id: number;
  invoice_id: number;
  expensed: string;
  skonto_total?: string;
  skonto?: string;
  username: string;
  date: string;
  note?: string;
  typ: string;
}

export interface OposHistoryCorrection {
  id: number;
  correction_id: number;
  expensed: string;
  skonto_total?: string;
  skonto?: string;
  username: string;
  date: string;
  note?: string;
  typ: string;
}

export interface OposInvoiceState {
  id: number;
  name: string;
  label: string;
}

export interface OposGlobalListData {
  id: number;
  workflow_id: number;
  typ: string;
  status: number;
  date_created: string;
  invoice_date: string;
  invoice_date_sent: string;
  invoice_account: number;
  invoice_revenue: string;
  invoice_netto_price: string;
  invoice_state: OposInvoiceState;
  invoice_due_date: string;
  invoice_dunning_level: number;
  invoice_dunning_lock: boolean;
  invoice_customer_id: number;
  sds_nr: string;
  oa_nr: string;
  kd_art_nr: string;
  customer_id: number;
  customer_name: string;
  customer_zip: string;
  customer_city: string;
  customer_country: string;
  customer_street: string;
  customer_field_service: string;
  username: string;
  email: string;
  comments: number;
  expensed: string;
  open: string;
  skonto: number;
  expensed_date: string;
  sent?: OposInvoiceState;
  invoice_storno_id?: number;
  target_date?: string;
}
