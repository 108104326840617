import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  MaterialGroupListElement,
  MaterialGroupListElementSelectable
} from '../material-management.model';

@Component({
  selector: 'material-field-select-group',
  templateUrl: './material-field-select-group.component.html'
})
export class MaterialFieldSelectGroupComponent implements OnInit {
  @Input('groups') selectableGroups: MaterialGroupListElementSelectable[] = [];
  @Output('event') updateEmitter = new EventEmitter<MaterialGroupListElement[]>();

  constructor() {}

  ngOnInit() {
    this.selectableGroups.map(group => group.is_disabled = group.is_selected);
  }

  toggleSelect(groupEvent: MaterialGroupListElementSelectable): void {
    if (!groupEvent.is_disabled) {
      groupEvent.is_selected = !groupEvent.is_selected;
    }
    this.updateEmitter.emit(this.selectableGroups.filter((group) => group.is_selected));
  }
}
