import {EventEmitter, Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderListData} from '../order-request.models';
import {Observable} from 'rxjs';
import {InventoryService} from '../../../article/inventory/inventory.service';
import {CustomerShortService} from '../../../customer/services/customer-short.service';
import {FrameShortService} from '../../workflow-services/frame-short.service';
import {CommentService} from '../../../comment/comment.service';
import {of} from 'rxjs';
import {Workflow} from '../../models/workflow.models';
import {ShortFrame} from '../../models/frame.models';
import {ShortFetch} from '../../models/fetch.models';
import {WorkflowOrderRequestService} from '../../workflow-services/workflow-order-request.service';
import {forkJoin} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {throwError, from} from 'rxjs';
import {OrderReceivedModalComponent} from './order-received-modal.component';

@Injectable({providedIn: 'root'})
export class OrderReceivedModalService {

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private inventoryService: InventoryService,
              private customerShortService: CustomerShortService,
              private frameShortService: FrameShortService,
              private commentService: CommentService,
              private workflowOrderRequestService: WorkflowOrderRequestService) {
  }

  fromIdAndIndex(id: number, index: number): Observable<void> {
    return this.workflowOrderRequestService.byId(id, index).pipe(
      catchError(() => {
        this.alertService.add('danger', 'Beim Aufrufen des Bestellungsstatus ist ein Fehler aufgetreten!');
        return throwError('some requests failed');
      }),
      mergeMap(val => {
        return this.open(val);
      })
    );
  }

  open(data: OrderListData): Observable<any> {
    const item = data.order.id + '-' + (data.order.positions.index + 1);
    const inventoryData = this.inventoryService.storage();
    const frameData = this.frameObs(data.workflow);
    const commentData = this.commentService.list('order', item);
    const customerData = this.customerShortService.short(data.workflow.invoice_id);
    const historyData = this.workflowOrderRequestService.history(data.order.id, data.order.positions.index);
    const inventoryGroupData = this.inventoryService.articleGroupWithNotes(data.article.oa_nr);
    const newData = this.workflowOrderRequestService.byId(data.order.id, data.order.positions.index);

    return forkJoin([
      inventoryData,
      frameData,
      commentData,
      customerData,
      historyData,
      inventoryGroupData,
      newData
    ]).pipe(
      catchError(() => {
        this.alertService.add('danger', 'Beim Aufrufen des Bestellungsstatus ist ein Fehler aufgetreten!');
        return throwError('some requests failed');
      }),
      mergeMap(val => {
        const modalRef = this.modalService.open(OrderReceivedModalComponent, {windowClass: 'modal2-cn-order'});
        modalRef.componentInstance.data = val[6];
        modalRef.componentInstance.storage = val[0];
        modalRef.componentInstance.frame = val[1];
        modalRef.componentInstance.comments = val[2];
        modalRef.componentInstance.customer = val[3];
        modalRef.componentInstance.history = val[4];
        modalRef.componentInstance.inventoryGroupData = val[5];
        return from(modalRef.componentInstance.reloadList as EventEmitter<any>);
      })
    );
  }

  private frameObs(workflow: Workflow): Observable<{ frame: ShortFrame, fetches: ShortFetch[] } | null> {
    if (workflow.status < 3 || workflow.status > 99) {
      return of(null);
    } else {
      return this.frameShortService.detail(workflow.id);
    }
  }

}
