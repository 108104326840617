import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InventoryModalService} from '../inventory/inventory.modal.service';
import {ArticleInventorySum} from '../../workflow/models/inventory.models';

@Component({
  selector: 'article-inventory-quantity-box',
  templateUrl: './article-inventory-quantity-box.component.html',
})
export class ArticleInventoryQuantityBoxComponent {
  @Input() oaNr: string;
  @Input() workflowId?: number;
  @Input() showInventoryButton = true;
  @Input() inventory: ArticleInventorySum;
  @Output() reloadInventory: EventEmitter<number> = new EventEmitter<number>();

  constructor(private modalService: InventoryModalService) {
  }

  openInventory(): void {
    this.modalService.openInventory(this.oaNr, this.workflowId).subscribe(amount => {
      this.reloadInventory.emit(amount);
    }, () => {
    });
  }
}
