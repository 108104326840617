<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-header">
    <h3 class="modal-title">Notiz hinzfügen</h3>
  </div>

  <inventory-storage-note-form [form]="form" [errors]="errors" [article]="article" [inventory]="inventory">
  </inventory-storage-note-form>

  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-left" (click)="back()">Abbrechen</button>
    <button type="submit" class="btn btn-success pull-right" [disabled]="!form.valid">Notiz hinzufügen</button>
  </div>
</form>
