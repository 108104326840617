<div class="article-form-item-label">
  <label for="input-dropdown">
    <ng-content></ng-content>
  </label>
</div>

<div class="article-form-item-data">
  <select class="form-control" id="input-dropdown" [ngModel]="current" (ngModelChange)="onChange($event)" [disabled]="disabled">
    <option *ngFor="let name of defaultValues.typ.values;trackBy:trackByFn;let index = index" [ngValue]="name">
      {{ name }}
    </option>
  </select>

</div>

