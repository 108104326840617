<ng-container [ngSwitch]="type" *ngIf="history">
  <ng-container *ngIf="history.date">Wareneingang: {{ history.date }},</ng-container>
  <ng-container *ngIf="history.data">
    <div *ngFor="let obj of history.data">
      <ng-container *ngIf="obj.quantity">Menge: {{ obj.quantity|money }}, </ng-container>
      <ng-container *ngIf="obj.storage_bin_name">Lager: {{ obj.storage_bin_name }}, </ng-container>
      <ng-container *ngIf="obj.storage_bin_number">Lagerplatz: {{ obj.storage_bin_number }}, </ng-container>
      <ng-container *ngIf="obj.date_code">Datecode: {{ obj.date_code }}, </ng-container>
    </div>
  </ng-container>
</ng-container>
