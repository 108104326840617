import {Component, Input} from '@angular/core';
import {ArticleHistoryUserData} from './article-history.models';
import {noop} from '../../../../helper/noop';

@Component({
  selector: 'article-history-simple',
  templateUrl: './article-history-simple.component.html'
})
export class ArticleHistorySimpleComponent {
  @Input() history: ArticleHistoryUserData[];

  trackByFn(index: number, item: ArticleHistoryUserData): number {
    noop(this, index);
    return item.id;
  }

}
