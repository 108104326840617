import {Injectable} from '@angular/core';
import {MaterialOrderUpdateService} from './material-order-update.service';
import {MaterialOrderUpdateComponent} from './material-order-update.component';
import {EMPTY, forkJoin, from, Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';
import {catchError, mergeMap} from 'rxjs/operators';
import {MaterialOrderService} from '../material-order.service';
import {MaterialGroupListPermittedService} from '../../groups/material-group-list-permitted.service';

@Injectable({providedIn: 'root'})
export class MaterialOrderUpdateModalService {

  constructor(private service: MaterialOrderService,
              private updateService: MaterialOrderUpdateService,
              private groupService: MaterialGroupListPermittedService,
              private modalService: NgbModal,
              private alertService: AlertService) {
  }

  open(id: number, position_id?: number): Observable<any> {
    return forkJoin({
      order: this.service.get(id),
      listData: this.updateService.list({'order_id': id}),
      groups: this.groupService.list(),
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.alertService.add('danger', `Konnte Bestellung ${id} nicht finden.`);
        }
        return EMPTY;
      }), mergeMap<any, Observable<any>>(listData => {
        const modalRef = this.modalService.open(MaterialOrderUpdateComponent, {windowClass: 'modal2-full'});
        modalRef.componentInstance.materialOrder = listData.order;
        modalRef.componentInstance.list = listData.listData;
        modalRef.componentInstance.groups = listData.groups;
        modalRef.componentInstance.markedPositionId = position_id;
        return from(modalRef.result);
      }));
  }
}
