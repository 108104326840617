import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {AkquiseSaveComponent} from './akquise-save.component';
import {UIRouterModule} from '@uirouter/angular';
import {AkquiseService} from './akquise.service';

@NgModule({
    imports: [
        UIRouterModule.forChild(),
        SharedModule,
    ],
    declarations: [AkquiseSaveComponent],
    exports: [AkquiseSaveComponent]
})
export class AkquiseSaveModule {
}
