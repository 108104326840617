import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialVariationEditModalComponent} from './material-variation-edit-modal.component';
import {MaterialManagementService} from '../../material-management.service';
import {Observable} from 'rxjs';
import {EMPTY, from} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaterialVariationEditModalService {
  constructor(private modalService: NgbModal,
              private mms: MaterialManagementService) {
  }

  open(variationId: number): Observable<any> {
    return this.mms.getSingleVariation(variationId).pipe(
      catchError(() => {
        return EMPTY;
      }),
      mergeMap(variation => {
        const modalRef = this.modalService.open(MaterialVariationEditModalComponent, {windowClass: 'modal2-mm'});
        modalRef.componentInstance.variation = variation;
        return from(modalRef.result);
      })
    );
  }
}
