<div class="row">
  <div class="col-md-7">

    <!-- // Nested Layout start -->

    <div class="row">

      <div class="col-md-12">

        <h2>
          <i class="fa fa-tasks"></i> Vorgang: {{ workflow.id }} - {{ workflow.username }}
          &nbsp;<workflow-status-label [workflow]="workflow" (statusEvent)="updateWorkflow()">
          </workflow-status-label>
        </h2>

        <div class="alert alert-danger" role="alert" *ngIf="workflow.status === 100">
          <span class="bold">Achtung!</span> Vorgang wurde storniert!
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="workflow.status === 101">
          <span class="bold">Achtung!</span> Vorgang wurde gelöscht!
        </div>

        <div *ngIf="data.workflow.frame" [ngClass]="{'alert alert-danger': !data.workflow.frame.approval}">
          Status:

          <i *ngIf="data.workflow.frame">
            (Abruf {{ data.workflow.frame.current }} von {{ data.workflow.frame.fetches }})
          </i>

          <img *ngIf="data.workflow.frame?.typ ==='cn'" src="/ui/assets/images/flags/cn.png" alt="cn">
          <img *ngIf="data.workflow.frame?.typ ==='de'" src="/ui/assets/images/flags/de.png" alt="de">

          <div *ngIf="!data.workflow.frame.approval" style="display:inline; margin-left: 20px;">
            <i class="fa fa-warning"></i> <strong>Achtung!</strong> Vorgang gesperrt.
            <a class="alert-link"
               *ngIf="('price.detail'|HasPerm)"
               uiSref="a.price.check"
               [uiParams]="{id:data.article.oa_nr, workflow_id: workflow.id}">
              Vorgang freigeben.
            </a>
          </div>
        </div>

      </div>

      <div class="col-md-12">&nbsp;</div>
    </div>

    <div class="row">
      <div class="col-md-12 tasknavigation">

        <div class="btn-group">
          <a class="btn btn-default btn-sm"
             [uiSref]="isDisabled('contract') ? '':'a.workflow.create'"
             [uiParams]="{name: 'contract', id: workflow.id}"
             *ngIf="('task.change'|HasPerm)"
             [class.disabled]="isDisabled('contract')">
            Auft.-Best. erstellen
          </a>

          <a class="btn btn-default btn-sm"
             uiSref="a.workflow.request.detail" [uiParams]="{id: workflow.id}"
             *ngIf="data.workflow.frame?.typ ==='cn' || !!data.article.data.special_production">
            Anfrage / Bestellung
          </a>

          <a class="btn btn-default btn-sm" uiSref="a.business_order.create" [uiParams]="{id: workflow.id}">
            Betriebsauftrag erstellen
          </a>

          <a class="btn btn-default btn-sm"
             [uiSref]="isDisabled('delivery') ? '':'a.workflow.create'"
             [uiParams]="{name: 'delivery', id: workflow.id}"
             *ngIf="('task.change'|HasPerm)"
             [class.disabled]="isDisabled('delivery')">
            Lieferschein erstellen
          </a>

          <a class="btn btn-default btn-sm"
             [uiSref]="isDisabled('invoice') ? '':'a.workflow.create'"
             [uiParams]="{name: 'invoice', id: workflow.id}"
             *ngIf="('task.change'|HasPerm)"
             [class.disabled]="isDisabled('invoice')">
            Rechnung erstellen
          </a>

          <a class="btn btn-default btn-sm" uiSref="a.workflow.document.list" [uiParams]="{id: workflow.id}">
            Dokumente
          </a>
        </div>

      </div>
    </div>

    <div class="row" *ngIf="customerCreditState !== 0">
      <div class="col-sm-12 customer-credit-alert" [ngSwitch]="customerCreditState">
        <div class="alert alert-warning" role="warning" *ngSwitchCase="1">
          <b>Achtung: Kreditlimit wird überschritten!</b>
          Limit: {{data.customer.credit_limit|money:2}}&nbsp;&euro;&nbsp;netto |
          Offene Rechnungen: {{this.customerCredit|money:2}}&nbsp;&euro;&nbsp;netto
        </div>
        <div class="alert alert-danger" role="alert" *ngSwitchCase="2">
          <b>Achtung: Kreditlimit erreicht!</b>
          Limit: {{data.customer.credit_limit|money:2}}&nbsp;&euro;&nbsp;netto |
          Offene Rechnungen: {{this.customerCredit|money:2}}&nbsp;&euro;&nbsp;netto
        </div>
      </div>
    </div>

    <div class="row">
      <form [formGroup]="form">
        <div class="col-md-4">
          <customer-box formControlName="customer"
                        [writeBackValue]="false"
                        [realValue]="true"
                        [clear]="false"
                        [withAddress]="true">
          </customer-box>
        </div>

        <div class="col-md-4">
          <contact-box formControlName="contact" [customerId]="data.customer.id">
          </contact-box>
        </div>

        <div class="col-md-4">
          <address-box formControlName="address"
                       name="Lieferanschrift"
                       [uniqueAddress]="true"
                       [addresses]="data.customer.addresses"
                       [realValue]="true"
                       [writeBack]="false"
                       [customerChangeNoWriteBack]="false"
                       [customerId]="data.customer.id"
                       [deliveryId]="workflow.delivery_id">
          </address-box>
        </div>
        <div class="col-md-12">&nbsp;</div>
      </form>
    </div>

    <div class="row">
      <div class="col-md-12 header">
        <h3>Vorgangsverlauf</h3>
      </div>
      <div class="col-md-12 contract-history-list whitelist" *ngIf="data.workflow.frame">
        <fetch-box [workflow]="workflow"
                   [fetches]="filteredFetches"
                   [customer]="data.customer"
                   [addresses]="data.address"
                   [frameTyp]="data.workflow.frame.typ"
                   [priceIncreaseFlex]="data.price_increase_flex"
                   (statusChange)="updateComments($event)">
        </fetch-box>
      </div>
    </div>

    <!-- // Nested Layout end -->

  </div>


  <div class="col-md-3">
    <div class="contract-history-list">
      <workflow-price-increase-flex-box [workflowId]="data.workflow.object.id"
                                        [priceIncreaseFlex]="data.price_increase_flex"
                                        (update)="updatePriceIncreaseFlex($event)">
      </workflow-price-increase-flex-box>

      <frame-box [frame]="data.workflow.frame"
                 [lastOffer]="data.last_offer"
                 [cn]="data.workflow.frame?.typ === 'cn'"
                 (frameUpdatedEvent)="updateWorkflow()"
                 *ngIf="data.workflow.frame">
      </frame-box>

      <canban-box *ngIf="data.workflow.frame && data.workflow.frame?.lose > 1"
                  [data]="data.additional"
                  [workflowId]="workflow.id"
                  [delivered]="data.workflow.delivered"
                  [inventory]="inventory.amount_regular"
                  [article]="data.article.oa_nr"
                  [producer]="data.workflow.frame.producer"
                  [isCn]="data.workflow.frame?.typ ==='cn'"
                  [lose]="data.workflow.frame.lose">
      </canban-box>

      <contract-order-box *ngIf="data.workflow.frame?.typ ==='cn'"
                          [orders]="data.orders"
                          [workflowId]="workflow.id"
                          [article]="data.article.oa_nr"
                          (reloadInventory)="reloadInventory()">
      </contract-order-box>

      <contract-order-count *ngIf="data.workflow.frame?.typ ==='de'" [workflowId]="workflow.id">
      </contract-order-count>

      <order-request-article-overview *ngIf="data.workflow.frame?.typ ==='cn'"
                                      [oaNr]="data.article.oa_nr"
                                      [whitebox]="false">
      </order-request-article-overview>

      <article-box [article]="data.article"
                   [workflowId]="workflow.id"
                   [inventory]="inventory"
                   (reloadInventory)="reloadInventory()">
      </article-box>

      <article-button-box [article]="data.article.oa_nr" [workflowId]="workflow.id.toString()"></article-button-box>
    </div>
  </div>

  <div class="col-md-2 header">
    <comment [comments]="data.comments" typ="workflow" [item]="workflow.id.toString()" placement="bottom">
    </comment>
  </div>

</div>
