import {EventEmitter, Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {InventoryService} from './inventory.service';
import {ArticleService} from '../services/article.service';
import {Observable, forkJoin, from, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {InventoryModalWizardComponent} from './modal/inventory-modal-wizard.component';
import {WorkflowIdListByArticleService} from '../../workflow/list-by-article/workflow-id-list-by-article.service';
import {NumberHelper} from '../../../helper/number-helper';
import {AlertService} from '../../../common/alert-service/alert.service';

@Injectable({providedIn: 'root'})
export class InventoryModalService {

  constructor(private modalService: NgbModal,
              private inventoryService: InventoryService,
              private articleService: ArticleService,
              private workflowIdListByArticleService: WorkflowIdListByArticleService,
              private alertService: AlertService) {
  }

  openInventory(oaNr: string, workflowId?: number | string): Observable<number> {
    const inventoryObservable = forkJoin([
      this.articleService.detail(oaNr),
      this.inventoryService.inventoryList(oaNr),
      this.inventoryService.articleGroupWithNotes(oaNr),
      this.inventoryService.storage(),
      this.inventoryService.listReservedForBa(oaNr),
      this.workflowIdListByArticleService.list(oaNr),
    ]);

    return inventoryObservable.pipe(
      catchError(() => {
        this.alertService.add('danger', 'Beim Aufrufen des Lagers ist ein Fehler aufgetreten!');
        return throwError('some requests failed');
      }),
      mergeMap(value => {
        const modalRef = this.modalService.open(InventoryModalWizardComponent, {windowClass: 'modal2-lg'});
        modalRef.componentInstance.article = value[0];
        modalRef.componentInstance.inventoryData = value[1];
        modalRef.componentInstance.inventoryGroupData = value[2];
        modalRef.componentInstance.storage = value[3];
        modalRef.componentInstance.reservedObjects = value[4];
        modalRef.componentInstance.workflowId = NumberHelper.saveParseInteger(workflowId);
        modalRef.componentInstance.workflowIds = value[5];
        return from(modalRef.componentInstance.setInventoryQuantity as EventEmitter<number>);
      }));
  }

  open(oaNr: string, workflowId?: number | string): Observable<{ modal: NgbModalRef, emitter: EventEmitter<number> }> {
    const inventoryObservable = forkJoin([
      this.articleService.detail(oaNr),
      this.inventoryService.inventoryList(oaNr),
      this.inventoryService.articleGroupWithNotes(oaNr),
      this.inventoryService.storage(),
      this.inventoryService.listReservedForBa(oaNr),
      this.workflowIdListByArticleService.list(oaNr),
    ]);

    return inventoryObservable.pipe(
      map(value => {
        const modalRef = this.modalService.open(InventoryModalWizardComponent, {windowClass: 'modal2-lg'});
        modalRef.componentInstance.article = value[0];
        modalRef.componentInstance.inventoryData = value[1];
        modalRef.componentInstance.inventoryGroupData = value[2];
        modalRef.componentInstance.storage = value[3];
        modalRef.componentInstance.reservedObjects = value[4];
        modalRef.componentInstance.workflowId = NumberHelper.saveParseInteger(workflowId);
        modalRef.componentInstance.workflowIds = value[5];
        const emitter: EventEmitter<number> = modalRef.componentInstance.setInventoryQuantity;
        return {modal: modalRef, emitter: emitter};
      })
    );
  }

}
