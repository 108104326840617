<div class="modal-header">
  <h3 class="modal-title">
    Produktionsfreigabe
  </h3>
</div>
<form class="form-horizontal">
  <div class="modal-body">
    <div class="col-md-12">
    <h4>Freigabe hinzufügen</h4>

    <form class="form" [formGroup]="form" (ngSubmit)="save()">
      <div class="row">

        <div class="col-md-4">
          <div><label for="quantity">Menge:</label></div>
          <input id="quantity" type="number" class="form-control" formControlName="quantity">
        </div>
        <div class="col-md-4">
          <div><label for="note">Notiz:</label></div>
          <input id="note" type="text" class="form-control" formControlName="note">
        </div>

        <div class="col-md-2">
          <br/>
          <button type="submit" class="btn btn-success pull-left pull-down">
            Hinzufügen
          </button>
        </div>

      </div>
    </form>
    </div>

    <div>&nbsp;</div>

    <div class="col-md-12">
      <h4>Verlauf</h4>

      <table class="whitetable table">
        <thead>
        <tr>
          <th>Menge</th>
          <th>Datum</th>
          <th>Benutzer</th>
          <th>Notiz</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let obj of list">
          <td>{{obj.quantity}}</td>
          <td>{{obj.date}}</td>
          <td>{{obj.username}}</td>
          <td>{{obj.note}}</td>
          <td><a class="fa fa-trash-o click" (click)="delete(obj.id)"></a></td>
        </tr>
        </tbody>
      </table>

      <ev-navigation [page]="approvalData.page"
                     [pages]="approvalData.pages"
                     [query]="query()"
                     (goto)="goto($event)">
      </ev-navigation>

    </div>

  </div>
  <div class="modal-footer">
  </div>
</form>
