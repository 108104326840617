import {IMultiSelectSettings, IMultiSelectTexts} from './multiselect/types';

export class MultiSelectConfig {
  // Settings configuration
  public static settings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 9,
    displayAllSelectedText: true
  };

  // Text configuration
  public static texts: IMultiSelectTexts = {
    checkAll: 'Alle auswählen',
    uncheckAll: 'Alle entfernen',
    checked: 'Element ausgewählt',
    checkedPlural: 'Elemente ausgewählt',
    searchPlaceholder: 'Suche',
    searchEmptyResult: 'Kein Treffer...',
    searchNoRenderText: 'Tippen in der Suchbox um Treffer zu sehen...',
    defaultTitle: 'Auswählen',
    allSelected: 'Alles ausgewählt',
  };
}
