import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {CustomerBoxComponent} from './customer-box.component';
import {UIRouterModule} from '@uirouter/angular';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CustomerNoteModalComponent} from './customer-note-modal.component';
import {CustomerSelectModalComponent} from './customer-select-modal.component';

@NgModule({
    imports: [SharedModule, NgbModalModule, NgbTooltipModule, UIRouterModule.forChild()],
    declarations: [CustomerBoxComponent, CustomerNoteModalComponent, CustomerSelectModalComponent],
    exports: [CustomerBoxComponent, CustomerNoteModalComponent]
})
export class CustomerBoxModule {
}
