import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../core/shared.module';
import {CustomerBoxModule} from '../../../customer/box/customer-box.module';
import {AddressBoxModule} from '../../../customer/address-box/address-box.module';
import {InvoiceCorrectionCreateComponent} from './invoice-correction-create.component';
import {InvoiceCorrectionModalService} from './invoice-correction-modal.service';
import {InvoiceCorrectionNavigationComponent} from './invoice-correction-navigation.component';
import {InvoiceCorrectionListComponent} from './invoice-correction-list.component';
import {UIRouterModule} from '@uirouter/angular';
import {INVOICE_CORRECTION_STATES} from './invoice-correction.states';

@NgModule({
    imports: [
        SharedModule,
        CustomerBoxModule,
        AddressBoxModule,
        UIRouterModule.forChild({ states: INVOICE_CORRECTION_STATES })
    ],
    declarations: [
        InvoiceCorrectionNavigationComponent,
        InvoiceCorrectionListComponent,
        // Modals
        InvoiceCorrectionCreateComponent,
    ]
})
export class InvoiceCorrectionModule {
}
