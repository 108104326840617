<div class="btn-group" role="group" aria-label="material">
  <a uiSref="a.material.article.list"
     title="Artikel"
     class="btn btn-default"
     [ngClass]="{'active' : materialArticleList}">
    <span class="fa fa-cubes"></span>
    Artikel
  </a>
  <a uiSref="a.material.group.list"
     title="Gruppen"
     class="btn btn-default"
     [ngClass]="{'active': materialGroupList}">
    <span class="fa fa-object-ungroup"></span>
    Artikel Gruppen
  </a>
  <a uiSref="a.material.product.groups.list"
     title="Produkt Gruppen"
     class="btn btn-default"
     [ngClass]="{'active': materialProductGroupList}">
    <span class="fa fa-object-group"></span>
    Produkt Gruppen
  </a>
  <a uiSref="a.material.users.list"
     title="Benutzer in Gruppen"
     class="btn btn-default"
     [ngClass]="{'active': materialUsersList}">
    <span class="fa fa-users"></span>
    Berechtigungen
  </a>
  <a uiSref="a.material.field.list"
     title="Felder"
     class="btn btn-default"
     [ngClass]="{'active': materialFieldList}">
    <span class="fa fa-list-alt"></span>
    Artikel Felder
  </a>
  <a uiSref="a.material.units.list"
     title="Einheiten"
     class="btn btn-default"
     [ngClass]="{'active': materialUnitsList}">
    <span class="fa fa-circle-o"></span>
    Einheiten
  </a>
</div>
