import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FieldService} from '../model/field-service';
import {UserInfo} from '../../user/user.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Customer} from '../model/customer';
import {FieldServiceService} from '../services/field-service.service';
import {UserService} from '../../user/user.service';
import Helper from '../../../helper/helper';
import {forkJoin, Subscription, of} from 'rxjs';
import {ignoreRejection} from '../../../helper/ignore_rejection';

@Component({
  selector: 'customer-detail-field-service',
  templateUrl: './customer-detail-field-service.component.html'
})
export class CustomerDetailFieldServiceComponent implements OnInit, OnDestroy {
  @Input() customer: Customer;
  @Input() fieldServices: FieldService[];
  @Input() fieldServiceUser?: UserInfo;
  form: UntypedFormGroup;
  formSubscription?: Subscription;
  httpSubscription?: Subscription;
  error = false;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private userService: UserService,
              private fieldServiceService: FieldServiceService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      fieldService: [this.customer.field_service]
    });
    this.formSubscription = this.form.valueChanges.subscribe(values => {
      if (this.httpSubscription) {
        this.httpSubscription.unsubscribe();
      }
      this.error = false;
      if (values.fieldService) {
        this.updateFieldService(values.fieldService);
      } else {
        this.removeFieldService();
      }
    }, ignoreRejection);
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  private updateFieldService(value: string): void {
    const username = Helper.getByKey(this.fieldServices, value, 'name').username;
    const usernameObs = username ? this.userService.info(username) : of(null);

    this.httpSubscription = forkJoin([
      this.fieldServiceService.set(this.customer.id, value),
      usernameObs
    ]).subscribe(responses => {
      this.httpSubscription = null;
      this.alertService.add('success', 'Vertriebsgebiet erfolgreich geändert!');
      this.fieldServiceUser = responses[1];
    }, () => {
      this.httpSubscription = null;
      this.alertService.add('danger', 'Vertriebsgebiet konnte nicht geändert werden!');
      this.error = true;
    });
  }

  private removeFieldService(): void {
    this.httpSubscription = this.fieldServiceService.drop(this.customer.id).subscribe(() => {
      this.httpSubscription = null;
      this.alertService.add('success', 'Vertriebsgebiet erfolgreich geändert!');
      this.fieldServiceUser = null;
    }, () => {
      this.httpSubscription = null;
      this.alertService.add('danger', 'Vertriebsgebiet konnte nicht geändert werden!');
      this.error = true;
    });
  }

}
