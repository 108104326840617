import {Component, OnDestroy, OnInit} from '@angular/core';
import {Workflow} from '../../models/workflow.models';
import {CommentService} from '../../../comment/comment.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {forkJoin, Subscription} from 'rxjs';
import {Address} from '../../../customer/model/address';
import {WorkflowService} from '../../workflow-services/workflow.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {Customer} from '../../../customer/model/customer';
import {debug} from '../../../../helper/debug.func';
import {AbstractDetailFormComponent} from '../../base/abstract-detail-form.component';
import {FrameService} from '../../workflow-services/frame.service';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';
import {InventoryService} from '../../../article/inventory/inventory.service';
import {ArticleInventorySum} from '../../models/inventory.models';
import {CustomerCreditWorthiness} from '../../../customer/credit-worthiness/customer-credit-worthiness.models';
import {NumberHelper} from '../../../../helper/number-helper';
import {BigFetchWithCleanPosition} from '../../fetch/box/fetch-box.model';
import {FetchBoxHelperService} from '../../fetch/box/fetch-box-helper.service';
import {CustomerCreditWorthinessService} from '../../../customer/credit-worthiness/customer-credit-worthiness.service';

@Component({
  selector: 'contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss']
})
export class ContractDetailComponent extends AbstractDetailFormComponent implements OnInit, OnDestroy {
  workflow: Workflow;
  inventory: ArticleInventorySum;
  addresses: { [key: string]: Address } = {};

  customerCreditWorthiness: CustomerCreditWorthiness | null = null;
  customerCreditState = 0;
  customerCredit = 0;

  filteredFetches: BigFetchWithCleanPosition[] = [];

  form: UntypedFormGroup;
  formCustomerSubscription?: Subscription;
  formAddressSubscription?: Subscription;

  constructor(private commentService: CommentService,
              protected workflowService: WorkflowService,
              protected alertService: AlertService,
              private frameService: FrameService,
              private inventoryService: InventoryService,
              private fetchBoxHelperService: FetchBoxHelperService,
              private customerCreditWorthinessService: CustomerCreditWorthinessService,
              private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.workflow = this.data.workflow.object;
    this.customerCreditWorthiness = this.data.customer_credit_worthiness ?? null;
    this.inventory = this.data.inventory;

    this.updateFetches();
    this.initalizeAddresses();
    const deliveryAddress = this.workflow.delivery_id ? this.addresses[this.workflow.delivery_id] : null;

    debug(deliveryAddress, this.data.customer.invoice, this.data.customer.addresses);
    this.form = this.fb.group({
      'customer': [this.data.customer],
      'contact': [this.data.contact],
      'address': [deliveryAddress, Validators.required],
    });

    this.formCustomerSubscription = this.form.get('customer').valueChanges.subscribe(values => {
      forkJoin([
        this.customerCreditWorthinessService.get(values.id),
        this.workflowService.update(this.workflow.id, {customer_id: values.id}),
      ]).subscribe((responses) => {
        this.data.customer = values as Customer;

        this.customerCreditWorthiness = responses[0];
        this.calculateCustomerCreditWorthinessState();

        const currentDeliveryId = responses[1].delivery_id ? responses[1].delivery_id : responses[1].invoice_id;
        this.form.patchValue({'contact': null}, {emitEvent: false});
        this.data.contact = null;

        // we do not write back the delivery_id
        // we do not patch it like that: this.form.patchValue({'address': currentDeliveryId}, {emitEvent: false});
        // instead we set it on the workflow object and that will change the address box
        // because else it will have timing problems
        this.updateAddressId(currentDeliveryId, responses[1].invoice_id);
        this.alertService.add('success', 'Kunde erfolgreich geändert!');
      }, () => {
        this.form.patchValue({'customer': this.data.customer}, {emitEvent: false});
        this.alertService.add('danger', 'Kunde konnte nicht geändert werden!');
      });
    });

    this.envisiaOnInitContactForm(this.form);
    this.formAddressSubscription = this.form.get('address').valueChanges.subscribe(values => {
      debug('address ValueChanges:', values);
      this.workflowService.update(this.workflow.id, {delivery_id: values.id}).subscribe(() => {
        this.updateAddressId(values.id, this.workflow.invoice_id);
        this.alertService.add('success', 'Addresse erfolgreich geändert!');
      }, () => {
        this.updateAddressId(this.workflow.delivery_id, this.workflow.invoice_id);
        this.alertService.add('danger', 'Addresse konnte nicht geändert werden!');
      });
    });
  }

  ngOnDestroy(): void {
    if (this.formCustomerSubscription) {
      this.formCustomerSubscription.unsubscribe();
    }
    this.envisiaOnDesotryContactForm();
    if (this.formAddressSubscription) {
      this.formAddressSubscription.unsubscribe();
    }
  }

  isDisabled(typ: 'contract' | 'delivery' | 'invoice'): boolean {
    if (typ === 'delivery' && this.data.workflow.frame && this.data.workflow.frame.fetch) {
      const e0 = this.data.workflow.frame.fetch === undefined;
      const e1 = (this.data.workflow.frame.fetch as any[]).filter((o) => o.status >= 1).length === 0;
      return e0 || e1 || this.workflow.status === 101;
    } else if (typ === 'contract') {
      return this.workflow.status === 101 || !this.form.valid;
    } else if (typ === 'invoice' && this.data.workflow.frame && this.data.workflow.frame.fetch) {
      const e0 = this.data.workflow.frame.fetch === undefined;
      const e1 = (this.data.workflow.frame.fetch as any[]).filter((o) => o.status >= 4).length === 0;
      return e0 || e1 || this.workflow.status === 101;
    } else {
      return true;
    }
  }

  reloadInventory(): void {
    this.inventoryService.inventorySum(this.data.article?.oa_nr, this.workflow?.id).subscribe(inventory => {
      this.inventory = inventory;
    }, () => {});
  }

  updateComments(workflowId: number): void {
    this.commentService.listBottom('workflow', workflowId.toString()).subscribe(value => {
      this.data.comments = value;
    }, () => {
    });
  }

  initalizeAddresses(): void {
    this.addresses[this.data.customer.invoice.id] = this.data.customer.invoice;
    this.data.customer.addresses.forEach((data) => this.addresses[data.id] = data);
  }

  updateAddressId(deliveryId: string | null | undefined, invoiceId: string | null | undefined): void {
    this.workflow.delivery_id = deliveryId;
    this.data.workflow.object.delivery_id = deliveryId;
    this.workflow.invoice_id = invoiceId;
    this.data.workflow.object.invoice_id = invoiceId;
  }

  updateWorkflow(): void {
    this.workflowService.detail(this.workflow.id, false).subscribe(wf => {
      this.workflow = wf.object;
      this.data.workflow.object = wf.object;

      this.frameService.get(this.workflow.id).subscribe(frame => {
        this.data.workflow.delivered = frame.delivered;
      });
    });
    this.updateComments(this.workflow.id);
  }

  updatePriceIncreaseFlex(priceIncreaseFlex: PriceIncreaseFlex): void {
    this.data.price_increase_flex = priceIncreaseFlex;
    this.updateComments(this.workflow.id);
    this.updateFetches();
  }

  updateFetches(): void {
    this.filteredFetches = this.fetchBoxHelperService.getCleanFetches(
      this.data.workflow.frame?.fetch ?? [],
      this.data.article.oa_nr,
      this.data.workflow.frame?.typ,
      this.data.price_increase_flex
    );

    this.calculateCustomerCreditWorthinessState();
  }

  calculateCustomerCreditWorthinessState(): void {
    const customerCreditLimit = NumberHelper.saveParseFloat(this.data.customer?.credit_limit);
    const priceTotalContract = this.filteredFetches.reduce((sum, f) => {
      return sum + (f.status < 5 ? (f.price_total ?? 0) : 0);
    }, 0);

    this.customerCredit = this.customerCreditWorthiness.credit_used - this.customerCreditWorthiness.credit_owed;
    if (!this.customerCreditWorthiness || customerCreditLimit === null || this.workflow.status >= 5) {
      this.customerCreditState = 0;
    } else if (customerCreditLimit < this.customerCredit) {
      this.customerCreditState = 2;
    } else if (customerCreditLimit < this.customerCredit + (priceTotalContract ?? 0)) {
      this.customerCreditState = 1;
    } else {
      this.customerCreditState = 0;
    }
  }
}
