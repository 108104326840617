import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EnvisiaLocation} from '../location/envisia-location';
import {deepCopy} from '../../helper/deep-copy';

@Component({
  selector: 'ev-navigation',
  templateUrl: './ev-navigation.component.html',
})
export class EvNavigationComponent {
  @Input() page: number;
  @Input() pages: number;
  @Input() query: any;
  @Output() goto: EventEmitter<number> = new EventEmitter<number>();

  private path = this.envisiaLocation.path();

  constructor(private envisiaLocation: EnvisiaLocation) {
  }

  pageUrl(page: number) {
    const empty: any = deepCopy(this.query);
    empty.page = page;

    return `#!${this.path}?${EnvisiaLocation.httpParams(empty).toString()}`;
  }

  gotoPage(event: Event, where: string) {
    event.preventDefault();
    let page = null;
    page = where === 'next' ? this.page + 1 : page;
    page = where === 'prev' ? this.page - 1 : page;

    this.goto.emit(page);
  }

  get totalPages(): number {
    return this.pages > 0 ? this.pages : 1;
  }

}
