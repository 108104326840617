export const sanitize = (data) => {
  // Sanitizer for KV etc blocks
  if (data === undefined) {
    return null;
  } else if (data === null) {
    return null;
  } else if (data === '-') {
    return null;
  } else if (data === '') {
    return null;
  } else {
    return '' + data;
  }
};
