<div class="row">
  <div class="col-md-12">
    <h4>Versand Fehler</h4>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <ng-container *ngIf="data.data$ | async as errors; else loadingOrError.template">
      <table class="table whitetable">
        <thead>
        <tr>
          <th>Rechnungs Nr.</th>
          <th>Art</th>
          <th>Fehler</th>
          <th>Datum</th>
          <th *ngIf="'post.cleanup'|HasPerm">&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let job of errors; let index = index">
          <td>{{ job.invoice_id }}</td>
          <td>{{ job.type }}</td>
          <td><ng-container *ngFor="let error of job.errors">{{ error.description }}, </ng-container></td>
          <td>{{ job.date_created|date:'dd.MM.yyyy HH:mm' }}</td>
          <td *ngIf="'post.cleanup'|HasPerm">
            <button type="button" class="btn btn-sm btn-default" (click)="clean(errors, index, job)">
              Auftrag löschen
            </button>
          </td>
          <td>
            <button type="button" class="btn btn-sm btn-success" (click)="restart(errors, index, job)">
              Rechnung erneut versenden
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </ng-container>

    <loading-or-error
      #loadingOrError
      [loadingWrapper]="data"
      [errorMessage]="'Konnte Versand Fehler nicht laden'">
    </loading-or-error>
  </div>
</div>
