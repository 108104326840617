import {Injectable} from '@angular/core';
import {LocalStorage} from '../storage/local-storage';

@Injectable({providedIn: 'root'})
export class EnvisiaFileService {
  open(base: string | number, type?: string, callback?: any): any {
    let url: string;
    if (typeof base === 'string') {
      url = base;
    } else {
      url = base.toString();
    }

    if (type) {
      url = `/workflow/v1/${type}/${base}/`;
    }

    let called = window.open(url);
    if (callback) {
      if (typeof callback === 'boolean') {
        // ignored
      } else {
        called = callback(called);
      }
    }
    return called;
  }

}
