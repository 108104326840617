<div class="btn-group" role="group">
  <button type="button" class="btn" [ngClass]="generateClass('process')" (click)="getProcesses()">
    <i class="fa fa-tasks"></i> Prozess
  </button>
  <button type="button" class="btn" [ngClass]="generateClass('archive')" (click)="getCategories()">
    <i class="fa fa-archive"></i> Archiv
  </button>
</div>

<div *ngIf="typ === 'archive'">

  <div class="content-box">

    <div class="content-box-table-row" *ngFor="let category of categories;trackBy: trackByFn">
      <div class="content-box-cell content-box-category">
        <span class="base" *ngIf="!category.base_name">{{ category.name }}</span>
        <span class="sub" *ngIf="category.base_name">
          <span class="basename">{{ category.base_name }} / </span>{{ category.name }}
        </span>
      </div>
      <div class="content-box-cell content-box-cell-action">
        <a (click)="add(category)" *ngIf="!isSelected(category)" class="click">
          <i class="fa fa-toggle-off"></i>
        </a>
        <a (click)="remove(category)" *ngIf="isSelected(category)" class="click">
          <i class="fa fa-toggle-on"></i>
        </a>

      </div>
    </div>

  </div>

  <div style="clear: both;">&nbsp;</div>

  <button type="button" class="btn btn-success btn-lg pull-right next-button"
          (click)="next(null)"
          *ngIf="selected.size > 0"
          [disabled]="blocked">
    Weiter
  </button>

</div>

<div *ngIf="typ === 'process'">

  <div class="content-box">

    <div class="content-box-table-row" *ngFor="let category of categories;trackBy: trackByFn">
      <div class="content-box-cell content-box-category">
        <span class="sub subprocess" *ngIf="!category.base_name">{{ category.name }}</span>
        <span class="sub subprocess" *ngIf="category.base_name">
          <span class="basename">{{ category.base_name }} / </span>{{ category.name }}
        </span>
      </div>
      <div class="content-box-cell content-box-cell-action">
        <a class="click" (click)="next(category)">
          <i class="fa fa-angle-right"></i>
        </a>
      </div>
    </div>

  </div>

</div>
