import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PriceHistory} from '../price.models';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PriceDetailHistoryResetService {

  constructor(private http: HttpClient) {
  }

  public reset(
    oaNr: string,
    priceId: number,
    historyHistoryId: number,
    customerId?: number,
    force?: boolean,
  ): Observable<PriceHistory> {
    return this.http.put<PriceHistory>(
      `${environment.apiv4uri}price/history/reset`,
      {
        oa_nr: oaNr,
        history_history_id: historyHistoryId,
        price_id: priceId,
        customer_id: customerId,
        force: force === true,
      }
    );
  }
}
