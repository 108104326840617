import {Transition, Ng2StateDeclaration} from '@uirouter/angular';
import {CustomerCommentComponent} from './customer-comment.component';
import {CustomerNavigationComponent} from '../navigation/customer-navigation.component';
import {CUSTOMER_DETAIL_RESOLVE} from '../customer.resolve';
import {CommentService} from '../../comment/comment.service';

export function customerCommentResolveFn(commentService: CommentService, trans: Transition) {
  return commentService.customerList(trans.params().id).toPromise();
}

export const CUSTOMER_COMMENT_RESOLVE = {
  token: 'comments',
  resolveFn: customerCommentResolveFn,
  deps: [CommentService, Transition],
};

export const CUSTOMER_COMMENT_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer.comment',
  url: '/crm/{id:[0-9]+}/comments',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: CustomerCommentComponent}
  },
  resolve: [
    CUSTOMER_DETAIL_RESOLVE,
    CUSTOMER_COMMENT_RESOLVE
  ],
  data: {requiresAuth: true}
};
