import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'delivery-create-message-modal',
  templateUrl: './delivery-create-message-modal.component.html'
})
export class DeliveryCreateMessageModalComponent {
  @Input() message: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
