import {Ng2StateDeclaration} from '@uirouter/angular';
import {KanbanDeService} from './kanban.de.service';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {KanbanListDeComponent} from './kanban-list-de.component';
import {ErpTransition} from '../../../core/erp-transition.service';
import {KanbanListCnComponent} from './kanban-list-cn.component';
import {KanbanCnService} from './kanban.cn.service';

export const KANBAN_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.kanban',
  abstract: true,
  data: {requiresAuth: true}
};

export function kanbanListResolveFnDe(transition: ErpTransition, service: KanbanDeService) {
  return service.list(transition.params()).toPromise();
}

export function kanbanListResolveFnCn(transition: ErpTransition, service: KanbanCnService) {
  return service.list(transition.params()).toPromise();
}


export const KANBAN_LIST_STATE_DE: Ng2StateDeclaration = {
  name: 'a.workflow.kanban.de',
  url: '/workflow/kanban/de/list?page&order&sort&cfa&date_start&date_end&workflow_id&customer_name&sds_nr&kd_art_nr&kd_art_extend&fetch_position_quantity&frame_quantity&open_quantity&stock&minimum_stock&production_approval&currently_in_production&production_approval_not_reached',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: KanbanListDeComponent}
  },
  resolve: [{token: 'listData', resolveFn: kanbanListResolveFnDe, deps: [ErpTransition, KanbanDeService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    cfa: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    workflow_id: {dynamic: true},
    customer_name: {dynamic: true},
    sds_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    kd_art_extend: {dynamic: true},
    fetch_position_quantity: {dynamic: true},
    frame_quantity: {dynamic: true},
    open_quantity: {dynamic: true},
    stock: {dynamic: true},
    minimum_stock: {dynamic: true},
    production_approval: {dynamic: true},
    currently_in_production: {dynamic: true},
    production_approval_not_reached: {dynamic: true},
  },
  data: {requiresAuth: true}
};


export const KANBAN_LIST_STATE_CN: Ng2StateDeclaration = {
  name: 'a.workflow.kanban.cn',
  url: '/workflow/kanban/cn/list?page&order&sort&cfa&date_start&date_end&workflow_id&customer_name&sds_nr&kd_art_nr&kd_art_extend&fetch_position_quantity&frame_quantity&open_quantity&stock&minimum_stock&production_approval&currently_in_production&production_approval_not_reached',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: KanbanListCnComponent}
  },
  resolve: [{token: 'listData', resolveFn: kanbanListResolveFnCn, deps: [ErpTransition, KanbanCnService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    cfa: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    workflow_id: {dynamic: true},
    customer_name: {dynamic: true},
    sds_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    kd_art_extend: {dynamic: true},
    fetch_position_quantity: {dynamic: true},
    frame_quantity: {dynamic: true},
    open_quantity: {dynamic: true},
    stock: {dynamic: true},
    minimum_stock: {dynamic: true},
    production_approval: {dynamic: true},
    currently_in_production: {dynamic: true},
    production_approval_not_reached: {dynamic: true},
  },
  data: {requiresAuth: true}
};

export const KANBAN_STATES: Ng2StateDeclaration[] = [
  KANBAN_MAIN_STATE,
  KANBAN_LIST_STATE_DE,
  KANBAN_LIST_STATE_CN
];
