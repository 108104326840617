<div class="modal-header">
  <div class="modal-title">
    <h3>Abmeldung nicht möglich</h3>
  </div>
</div>

<div class="modal-body">
  <div>
    <span class="alert alert-danger">
      Der Fertigungsschritt kann nicht abgemeldet werden, da die Mindest-Bearbeitungszeit nicht erreicht ist.
    </span>
  </div>
  <div>&nbsp;</div>
  <div>
    <span class="alert alert-danger">
      <strong>Verbleibende Zeit: {{remaining}} Sekunden</strong>
    </span>
  </div>
  <div>&nbsp;</div>
  <div>&nbsp;</div>
</div>

<div class="modal-footer">
  <button class="btn btn-default" (click)="dismiss()">Abbrechen</button>
</div>
