import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialStorage} from '../material-management.model';
import {Injectable} from '@angular/core';
import {MaterialInventoryIntakeModalComponent} from './material-inventory-intake-modal/material-inventory-intake-modal.component';
import {MaterialInventoryEditModalComponent} from './material-inventory-edit-modal/material-inventory-edit-modal.component';

@Injectable({providedIn: 'root'})
export class MaterialInventoryEditModalService {
  constructor(private modalService: NgbModal) {
  }

  changeAmountModalOpen(storage: MaterialStorage, isKonsi?: boolean): Promise<boolean> {
    const modalRef = this.modalService.open(MaterialInventoryEditModalComponent, {windowClass: 'modal2-mg'});
    modalRef.componentInstance.storage = storage;
    modalRef.componentInstance.isKonsi = isKonsi;
    return modalRef.result.catch(() => false);
  }

  bookIntakeModalOpen(variationId: number, konsi?: boolean): Promise<boolean> {
    const modalRef = this.modalService.open(MaterialInventoryIntakeModalComponent, {windowClass: 'modal2-mg'});
    modalRef.componentInstance.variationId = variationId;
    modalRef.componentInstance.konsi = konsi;
    return modalRef.result.catch(() => false);
  }
}
