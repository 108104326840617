import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../../common/search/search.component';
import {InvoiceCorrectionService} from '../services/invoice-correction.service';
import {InvoiceCorrectionListData} from '../invoice.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {Lister} from '../../../../common/wrapper.models';
import {OposHistoryHelperService} from '../../../opos/history/opos-history-helper.service';

@Component({
  selector: 'invoice-correction-list',
  templateUrl: './invoice-correction-list.component.html'
})
export class InvoiceCorrectionListComponent extends SearchBaseComponent<InvoiceCorrectionService, InvoiceCorrectionListData> {
  @Input() listData: Lister<InvoiceCorrectionListData>;
  protected type = 'invoice_correction';

  constructor(protected service: InvoiceCorrectionService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private oposHistoryHelperService: OposHistoryHelperService) {
    super();
  }

  history(obj: InvoiceCorrectionListData): void {
    this.oposHistoryHelperService.openCorrection(obj.id);
  }
}
