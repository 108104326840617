import {Injectable, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, from} from 'rxjs';
import {DeliveryStornoModalComponent} from '../modals/delivery-storno-modal.component';

@Injectable({providedIn: 'root'})
export class DeliveryStornoModalService {

  constructor(private modalService: NgbModal) {
  }

  open(id: number, wid: number): Observable<any> {
    const modalRef = this.modalService.open(DeliveryStornoModalComponent, {windowClass: 'modal2-m'});
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.wid = wid;
    return from(modalRef.result);
  }

}
