import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OposHistoryModalComponent} from './opos-history-modal.component';
import {OposHistoryService} from './opos-history.service';
import {OposHistoryCorrectionModalComponent} from './opos-history-correction-modal.component';

@Injectable({providedIn: 'root'})
export class OposHistoryHelperService {

  constructor(private modalService: NgbModal,
              private service: OposHistoryService) {
  }

  open(invoiceId: number): void {
    this.service.history(invoiceId).subscribe(value => {
      const modalRef = this.modalService.open(OposHistoryModalComponent, {windowClass: 'modal2-lg'});
      modalRef.componentInstance.id = invoiceId;
      modalRef.componentInstance.history = value;
      modalRef.result.then(() => {
      }, () => {
      });
    });
  }

  openCorrection(correctionId: number): void {
    this.service.historyCorrection(correctionId).subscribe(value => {
      const modalRef = this.modalService.open(OposHistoryCorrectionModalComponent, {windowClass: 'modal2-lg'});
      modalRef.componentInstance.id = correctionId;
      modalRef.componentInstance.history = value;
      modalRef.result.then(() => {
      }, () => {
      });
    });
  }

}
