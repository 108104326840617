import {Component} from '@angular/core';
import {StateService} from '@uirouter/angular';

@Component({
  selector: 'material-edit-navigation',
  templateUrl: './material-edit-navigation.component.html',
})
export class MaterialEditNavigationComponent {

  constructor(private stateService: StateService) {
  }

  get materialArticleList(): boolean {
    return this.stateService.includes('a.material.article.list');
  }

  get materialProductGroupList(): boolean {
    return this.stateService.includes('a.material.product.groups.list');
  }

  get materialGroupList(): boolean {
    return this.stateService.includes('a.material.group.list');
  }

  get materialUsersList(): boolean {
    return this.stateService.includes('a.material.users.list');
  }

  get materialFieldList(): boolean {
    return this.stateService.includes('a.material.field.list');
  }

  get materialUnitsList(): boolean {
    return this.stateService.includes('a.material.units');
  }
}
