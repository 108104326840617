<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row">
    <div class="col-lg-12">
      <h2>LP-Gewicht Berechnungs-Variablen</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      Bei Änderungen der Variablen werden alle in den den Vorgängen angezeigten Gewichte mit den neuen Werten berechnet.
    </div>
  </div>
  <div class="row">&nbsp;</div>
  <div class="row">
    <div class="col-lg-12">
      <h4>Variablen</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 align-right">
      <div class="row form-group">
        <article-input label="Cu LBA" model="cu_lba" unit="&nbsp;&nbsp;&nbsp;" [form]="form"
                       [error]="errors?.cu_lba">
        </article-input>
      </div>
      <div class="row form-group">
        <article-input label="Cu Belegung Standard Außen" model="cu_bsa" unit="%" [form]="form"
                       [error]="errors?.cu_bsa">
        </article-input>
      </div>
      <div class="row form-group">
        <article-input label="Cu Belegung Standard Innen" model="cu_bsi" unit="%" [form]="form"
                       [error]="errors?.cu_bsi">
        </article-input>
      </div>
    </div>

    <div class="col-lg-4 align-right">
      <div class="row form-group">
        <article-input label="Dichte Glas/Epoxy" model="rge" unit="g/cm³" [form]="form"
                       [error]="errors?.rge">
        </article-input>
      </div>
      <div class="row form-group">
        <article-input label="Dichte Kupfer" model="rc" unit="g/cm³" [form]="form"
                       [error]="errors?.rc">
        </article-input>
      </div>
      <div class="row form-group">
        <article-input label="Flächengewicht Lack" model="fl" unit="g/cm²" [form]="form"
                       [error]="errors?.fl">
        </article-input>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button type="submit" class="btn btn-success" [disabled]="!this.form.valid">Speichern</button>
    </div>
  </div>
</form>
