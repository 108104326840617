import {Ng2StateDeclaration} from '@uirouter/angular';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {ContractService} from '../workflow-services/contract.service';
import {ContractListComponent} from './contract-list.component';
import {ContractCreateComponent} from './create/contract-create.component';
import {WORKFLOW_CREATE_RESOLVER} from '../resolver/workflow-data.resolver';
import {ErpTransition} from '../../../core/erp-transition.service';
import {ContractDetailComponent} from './detail/contract-detail.component';
import {WorkflowNavigationComponent} from '../navigation/workflow-navigation.component';
import {
  ContractCreateNavigationComponent
} from './create/contract-create-navigation/contract-create-navigation.component';

export const CONTRACT_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.contract',
  abstract: true,
  data: {requiresAuth: true}
};

export function contractListResolveFn(transition: ErpTransition, service: ContractService) {
  return service.list(transition.params()).toPromise();
}

export const CONTRACT_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.contract.list',
  url: '/workflow/contract/list?page&order&sort&contract_id&contract_date&offer_nr&workflow_id&sds_nr&kd_art_nr&customer_name&status&typ&contract_fetch_delivery_date&contract_fetch_order_nr',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: ContractListComponent}
  },
  resolve: [{token: 'listData', resolveFn: contractListResolveFn, deps: [ErpTransition, ContractService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    contract_id: {dynamic: true},
    contract_date: {dynamic: true},
    offer_nr: {dynamic: true},
    workflow_id: {dynamic: true},
    sds_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    customer_name: {dynamic: true},
    status: {dynamic: true},
    typ: {dynamic: true},
    contract_fetch_delivery_date: {dynamic: true},
    contract_fetch_order_nr: {dynamic: true},
  },
  data: {requiresAuth: true}
};

export const CONTRACT_MAIN_DETAIL_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.detail.detail_contract',
  params: {data: {dynamic: true}, id: {dynamic: true}},
  resolve: [WORKFLOW_CREATE_RESOLVER],
  views: {
    'navigation@a': {component: WorkflowNavigationComponent},
    'main@a': {component: ContractDetailComponent}
  }
};

export const CONTRACT_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.create.create_contract_create',
  params: {data: {dynamic: true}, name: {}, id: {}},
  resolve: [WORKFLOW_CREATE_RESOLVER],
  views: {
    'navigation@a': {component: ContractCreateNavigationComponent},
    'main@a': {component: ContractCreateComponent}
  }
};

export const CONTRACT_STATES: Ng2StateDeclaration[] = [
  CONTRACT_MAIN_STATE,
  CONTRACT_LIST_STATE,
  CONTRACT_CREATE_STATE,
  CONTRACT_MAIN_DETAIL_STATE
];
