import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleCopyModalComponent} from '../main/copy-modal/article-copy-modal.component';
import {Observable, from} from 'rxjs';
import {StateService} from '@uirouter/angular';
import {AlertService} from '../../../common/alert-service/alert.service';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ArticleCopyService {

  constructor(private ngbModal: NgbModal,
              private alertService: AlertService,
              private stateService: StateService) {
  }

  copyArticle(article: string, kdArtNr?: string): Observable<any> {
    const modalRef = this.ngbModal.open(ArticleCopyModalComponent);
    modalRef.componentInstance.oaNr = article;
    modalRef.componentInstance.kdArtNr = kdArtNr;
    return from<any>(modalRef.result).pipe(
      tap(value => {
        console.log('New Article2: ', value);
        this.alertService.add('success', `Article ${value.oa_nr} erfolgreich kopiert!`);
        // Re-Initalizes the article data, shouldn't be needed for everything, since the values of the old article should be used
        this.stateService.go(
          this.stateService.current,
          {id: value.oa_nr},
          {reload: true, location: true, notify: true}
        );
      })
    );
  }

}
