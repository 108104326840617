<h3>Adapter-Liste</h3>

<div class="filter-row">
  <div class="filter-left">
    <ev-file-button [url]="downloadUrl('/api/adapter/export/')" [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>
    &nbsp;
    <ev-file-button url="/api/adapter/export/finished" [link]="false">
      <i class="fa fa-download"></i> Liste aller abgeschlossenen BAs
    </ev-file-button>
  </div>
  <div class="filter-right date-range-right">
    <date-range [label]="'End-Termin'" [start]="null" [end]="null" (update)="updateDateRange($event)"></date-range>
  </div>
</div>


<form #changeForm="ngForm" name="form" *ngIf="list">
  <table class="table whitetable">
    <thead>
    <tr>
      <th style="width: 110px;"><a (click)="sort($event, 'id', 'ASC')" [href]="sortUrl('id', 'ASC')">BA-Nr</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'customer', 'ASC')" [href]="sortUrl('customer', 'ASC')">Kunde</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'workflow_id', 'ASC')" [href]="sortUrl('workflow_id', 'ASC')">Vorgang</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">SDS-Nr.</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'work_days', 'ASC')" [href]="sortUrl('work_days', 'ASC')">AT</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'good_piece', 'ASC')" [href]="sortUrl('good_piece', 'ASC')">Gutstück</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'end_date', 'ASC')" [href]="sortUrl('end_date', 'ASC')">End-Termin</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'delivery_date', 'ASC')" [href]="sortUrl('delivery_date', 'ASC')">Liefertermin</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'article_measuring_device', 'ASC')" [href]="sortUrl('article_measuring_device', 'ASC')">Prüfmittel</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'current_step', 'ASC')" [href]="sortUrl('current_step', 'ASC')">Fertigungsschritt</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'inventory', 'ASC')" [href]="sortUrl('inventory', 'ASC')">Akt. Lagerbestand</a></th>
      <th style="width: 110px;"><a (click)="sort($event, 'e_test', 'ASC')" [href]="sortUrl('e_test', 'ASC')">E-Test</a></th>
    </tr>


    <tr class="filterrow">
      <th><input type="text" class="form-control input-sm" name="sid" [(ngModel)]="data.sid" [autofocus] autocomplete="off search-adapter-id"></th>
      <th><input type="text" class="form-control input-sm" name="customer" [(ngModel)]="data.customer"></th>
      <th><input type="text" class="form-control input-sm" name="workflow_id" [(ngModel)]="data.workflow_id"></th>
      <th><input type="text" class="form-control input-sm" name="sds_nr" [(ngModel)]="data.sds_nr"></th>
      <th><input type="text" class="form-control input-sm" name="work_days" [(ngModel)]="data.work_days"></th>
      <th><input type="text" class="form-control input-sm" name="good_piece" [(ngModel)]="data.good_piece"></th>
      <th>
        <ev-datepicker class="form-control input-sm" name="end_date" [(ngModel)]="data.end_date"></ev-datepicker>
      </th>
      <th>
        <ev-datepicker class="form-control input-sm" name="delivery_date" [(ngModel)]="data.delivery_date"></ev-datepicker>
      </th>
      <th><input type="text" class="form-control input-sm" name="article_measuring_device" [(ngModel)]="data.article_measuring_device"></th>
      <th><input type="text" class="form-control input-sm" name="current_step" [(ngModel)]="data.current_step"></th>
      <th><input type="text" class="form-control input-sm" name="inventory" [(ngModel)]="data.inventory"></th>
      <th><input type="text" class="form-control input-sm" name="e_test" [(ngModel)]="data.e_test"></th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let adapter of list.objects; let topIndex = index">
      <td>
        <a uiSref="a.business_order.view" [uiParams]="{id:adapter.id, index:adapter.index}">
          {{ adapter.id }}-{{ adapter.index }}
        </a>
        <span class="label label-purple" *ngIf="adapter.kanban">KANBAN</span>
        <span class="label label-primary" *ngIf="adapter.express">EILDIENST</span>
      </td>
      <td id="customer_col">
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:adapter.customer_id}">{{ adapter.customer }}</a>
      </td>
      <td>
        <a uiSref="a.workflow.detail" [uiParams]="{id: adapter.workflow_id}">{{ adapter.workflow_id }}</a>
      </td>
      <td>
        <a uiSref="a.article.workflow.overview" [uiParams]="{id: adapter.oa_nr}">{{ adapter.sds_nr }}</a>
      </td>
      <td>
        <span *ngIf="adapter.express" class="label label-purple marginless">{{ adapter.work_days }} AT</span>
      </td>
      <td>
        {{ adapter.good_piece }}
      </td>
      <td>
        {{ adapter.end_date }}
      </td>
      <td>
        {{ adapter.delivery_date }}
      </td>
      <td>
        {{ adapter.article_measuring_device }}
      </td>
      <td>
        <div>{{ adapter.current_step }}</div>
        <div>&nbsp;</div>
        <div *ngIf="adapter.last_step_end_date" class="label label-warning finish-date">Abgemeldet: {{ adapter.last_step_end_date }}</div>
      </td>
      <td>
        {{ adapter.inventory }}
      </td>
      <td>
        {{ adapter.e_test }}
      </td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
