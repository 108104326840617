<customer-header-template [customer]="customer"></customer-header-template>

<div class="row-flexbox">
  <button type="button" class="btn btn-success pull-left" (click)="saveData()" [disabled]="!valid">
    Speichern
  </button>

  <button type="button" class="btn btn-default pull-right" (click)="openHistory()">
    Verlauf
  </button>
</div>

<article-extra-grouped [data]="values"
                       [schema]="schema"
                       [mainType]="'customer'"
                       (deleteSectionEmitter)="onDeleteSection($event)"
                       (saveEmitter)="updateForm($event)">
</article-extra-grouped>
