import {Component, Input} from '@angular/core';
import {ArticleHistoryUserData} from './article-history.models';
import {ArticlePreviewModalComponent} from './article-preview.modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleHistoryService} from '../../services/article-history.service';
import {noop} from '../../../../helper/noop';

@Component({
  selector: 'article-history-detail',
  templateUrl: './article-history-detail.component.html'
})
export class ArticleHistoryDetailComponent {
  @Input() history: ArticleHistoryUserData[];

  constructor(private articleHistoryService: ArticleHistoryService,
              private ngbModal: NgbModal) {
  }

  trackByFn(index: number, item: string): number {
    noop(this, item);
    return index;
  }

  preview(article: number): void {
    this.articleHistoryService.preview(article).subscribe(value => {
      const modalRef = this.ngbModal.open(ArticlePreviewModalComponent);
      modalRef.componentInstance.data = value;
    }, () => {
    });
  }

}
