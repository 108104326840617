import {Component, Input} from '@angular/core';
import {ContractListFetchData} from '../../models/contract.models';

@Component({
  selector: 'tr[contract-list-element-fetch]',
  templateUrl: './contract-list-element-fetch.component.html',
})
export class ContractListElementFetchComponent {
  @Input('contract-list-element-fetch') fetch: ContractListFetchData;
}
