import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {KanbanCnService} from './kanban.cn.service';
import {KanbanListDataView} from './kanban.model';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Lister} from '../../../common/wrapper.models';
import {deepCopy} from '../../../helper/deep-copy';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'kanban-list-cn',
  templateUrl: './kanban-list-cn.component.html'
})
export class KanbanListCnComponent extends SearchBaseComponent<KanbanCnService, KanbanListDataView> {
  @Input() listData: Lister<KanbanListDataView>;
  protected type = 'kanban';

  constructor(protected service: KanbanCnService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  excelDownload(): void {
    const empty = deepCopy(this.query());
    delete empty.page;
    const params = EnvisiaLocation.httpParams(empty).toString();
    window.open(`${environment.apiv4uri}workflow/kanban/cn/excel${!params ? '' : `?${params}`}`, '_blank');
  }

}
