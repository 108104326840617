<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-header">
    <h3 class="modal-title">Lagerbestand auf anderen Lagerplatz umbuchen</h3>
  </div>

  <div class="modal-body inventory">

    <div class="row mb-10">
      <div class="col-md-3">Datecode:</div>
      <div class="col-md-9"><span>{{inventory.dateCode}}</span></div>
    </div>
    <div class="row mb-10">
      <div class="col-md-3">Aktuelles Lager:</div>
      <div class="col-md-9"><span>{{inventory.storageBinName}}</span></div>
    </div>
    <div class="row mb-10">
      <div class="col-md-3">Aktueller Lagerplatz:</div>
      <div class="col-md-9"><span>{{inventory.storageBinNumber}}</span></div>
    </div>
    <div class="row mb-10">
      <div class="col-md-3">Aktuelle Vorgangs-Nr.:</div>
      <div class="col-md-9">
        <span *ngIf="!!inventory.workflowId">{{inventory.workflowId}}</span>
        <span *ngIf="!inventory.workflowId">-</span>
      </div>
    </div>

    <div class="row mb-10">
      <div class="col-md-3"><label for="storage_bin_name_target">Ziel Lager:</label></div>
      <div class="col-md-9">
        <select class="form-control" id="storage_bin_name_target" formControlName="storage_bin_name_target"
                [ngClass]="{red_alert: errors['storage_bin_name_target']}">
          <option [ngValue]="null" selected>&nbsp;</option>
          <option [ngValue]="obj.name" *ngFor="let obj of storage">{{ obj.name }}</option>
        </select>
      </div>
    </div>

    <div class="row mb-10">
      <div class="col-md-3"><label for="storage_bin_number_target">Ziel Lagerplatz</label></div>
      <div class="col-md-9">
        <input type="number"
               min="0"
               formControlName="storage_bin_number_target"
               class="form-control"
               id="storage_bin_number_target"
               [ngClass]="{red_alert: errors['storage_bin_number_target']}">
      </div>
    </div>

    <div class="row mb-10">
      <div class="col-sm-3"><label for="quantity">Menge</label></div>
      <div class="col-sm-9">
        <input type="number" formControlName="quantity" class="form-control" id="quantity"
               [ngClass]="{red_alert: errors['quantity']}">
      </div>
    </div>

    <div class="row mb-10">
      <div class="col-sm-3">
        <label for="storage_note">Hinweis</label>
      </div>
      <div class="col-sm-9">
        <textarea formControlName="storage_note" class="form-control" id="storage_note" rows="3"
                  [ngClass]="{red_alert: errors['storage_note']}"></textarea>
      </div>
    </div>

    <div class="row mb-10">
      <div class="col-sm-3">
        <label for="note">Notiz</label>
      </div>
      <div class="col-sm-9">
        <input type="text" formControlName="note" class="form-control" id="note"
               [ngClass]="{red_alert: errors['note']}">
      </div>
    </div>

    <div class="row mb-10" *ngIf="errors['note']">
      <div class="col-sm-12">
        <span class="label label-danger red_alert" style="font-size: 12px;">
          Bitte geben Sie einen aussagekräftigen Grund für die Umbuchung an.
        </span>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-left" (click)="back()">Abbrechen</button>
    <button type="submit" class="btn btn-success pull-right">Bestand umbuchen</button>
  </div>
</form>
