import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FetchWithPositions, MergedFrame} from '../../../models/frame.models';
import {FetchPosition} from '../../../models/fetch.models';
import {noop} from '../../../../../helper/noop';
import {Article} from '../../../../article/models/article.models';
import {PriceIncreaseFlex} from '../../../models/price-increase-flex.model';

@Component({
  selector: 'tbody[contract-create-form-item]',
  templateUrl: './contract-create-form-item.component.html',
})
export class ContractCreateFormItemComponent {
  @Input('contract-create-form-item') fetch: FetchWithPositions;
  @Input('contract-create-form-item-index') index: number;
  @Input('contract-create-form-item-nr') nr: boolean;
  @Input('contract-create-form-item-frame') frame: MergedFrame;
  @Input('contract-create-form-item-article') article: Article;
  @Input('contract-create-form-item-last-fetch-id') createdLastFetchId: number | null;
  @Input('contract-create-form-item-price-increase-flex') priceIncreaseFlex?: PriceIncreaseFlex;
  @Output('contract-create-form-item-trigger') trigger = new EventEmitter<boolean>();
  @Output('contract-create-form-item-delete') deleteEvent = new EventEmitter<{ fetchIndex: number, positionIndex: number, fetch: FetchWithPositions, position: FetchPosition; }>();

  constructor() {
  }

  trackByPosition(index: number, position: FetchPosition): number {
    noop(this, index);
    return position.id;
  }

  updateMulti(positions: FetchPosition[]): void {
    this.fetch.positions = positions;
  }

  updateSingle(position: FetchPosition, index: number): void {
    this.fetch.positions[index] = position;
  }
}
