import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ShippingInstructionHistory,
  ShippingInstructionSchema,
  ShippingInstructionType,
} from '../../article/models/article-extra.models';
import {ArticleCore} from '../../article/models/article.models';

@Component({
  selector: 'shipping-instruction-history-modal',
  templateUrl: './shipping-instruction-history-modal.component.html'
})
export class ShippingInstructionHistoryModalComponent {
  @Input() schema: ShippingInstructionSchema[];
  @Input() list: ShippingInstructionHistory[];
  @Input() type: ShippingInstructionType;
  @Input() item: string;
  @Input() visibleName: string;
  @Input() article?: ArticleCore;

  constructor(private modalRef: NgbActiveModal) {
  }

  close() {
    this.modalRef.dismiss();
  }
}
