import {Injectable} from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {MaterialTransaction} from '../material-management.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class MaterialInventoryTransactionListService implements ListService<MaterialTransaction> {

  constructor(private http: HttpClient) {
  }

  list(query: any): Observable<Lister<MaterialTransaction>> {
    return this.http.get<Lister<MaterialTransaction>>(
      environment.apiv4uri + 'material/transactions/history',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}
