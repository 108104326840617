import {Component, Input} from '@angular/core';
import {FinderSearchItemList} from '../finder.models';

@Component({
  selector: 'finder-search',
  templateUrl: './finder-search.component.html',
})
export class FinderSearchComponent {
  @Input() initial: FinderSearchItemList;
  @Input() searchType: 'customer' | 'article' | 'workflow';
  @Input() id: string | null | undefined;
  @Input() main: string | null | undefined;

  constructor() {
  }
}
