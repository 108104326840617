import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  BusinessOrder,
  BusinessOrderSwitchStepResult
} from '../../models/business-order.models';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderViewMergeService {

  constructor(private http: HttpClient) {
  }

  merge(form: any): Observable<BusinessOrder> {
    return this.http.post<BusinessOrder>(`${environment.apiv4uri}business/order/merge`, form);
  }

  selectStep(id: number, index: number, ordering: number): Observable<BusinessOrderSwitchStepResult> {
    return this.http.put<BusinessOrderSwitchStepResult>(
      `${environment.apiv4uri}business/order/${id}/${index}/merge/step`,
      {'ordering': ordering}
    );
  }

  removeStep(id: number, index: number): Observable<BusinessOrderSwitchStepResult> {
    return this.http.delete<BusinessOrderSwitchStepResult>(
      `${environment.apiv4uri}business/order/${id}/${index}/merge/step`
    );
  }
}
