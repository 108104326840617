import {Component, Input, OnInit} from '@angular/core';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';
import {CalculationProcess} from '../../../../price/price.models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PositionService} from '../../../workflow-services/position.service';
import {ProcessService} from '../../../../price/services/process.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {debug} from '../../../../../helper/debug.func';
import {HttpErrorResponse} from '@angular/common/http';
import {OfferHistoryModalService} from '../../history/offer-history-modal.service';
import {PositionCreateResponse, WorkflowPositionView, WorkflowPrice} from '../../../models/position.models';
import {Observable} from 'rxjs';

@Component({
  selector: 'position-add-modal',
  templateUrl: './position-add-modal.component.html',
})
export class PositionAddModalComponent implements OnInit {
  @Input() data: BigWorkflowOffer;
  processesData: LoadingWrapper<{ de: CalculationProcess[], cn: CalculationProcess[] }>;
  form: UntypedFormGroup;
  send = false;

  constructor(private positionService: PositionService,
              private processService: ProcessService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder,
              private activeModal: NgbActiveModal,
              private offerHistoryModalService: OfferHistoryModalService) {
  }

  ngOnInit() {
    this.processesData = new LoadingWrapper(this.processService.list());
    this.form = this.fb.group({
      'calc_de': [this.data.article.calc_de, Validators.required],
      'calc_cn': [this.data.article.calc_chn, Validators.required],
      'quanity': [null, Validators.required],
      'lose': [1, Validators.required],
    });
  }

  submit(): void {
    this.send = true;
    const workflowId = this.data.workflow.object.id;
    const formValue = this.form.value;
    const article = this.data.article.oa_nr;
    debug('Creating new Article Position', formValue);
    this.positionService.createArticle(workflowId, formValue).subscribe(response => {
      const obj = response.object;
      if (response.created) {
        this.send = false;
        this.activeModal.close(obj);
        return;
      }

      this.openModal(workflowId, article, obj, formValue).subscribe((value: PositionCreateResponse) => {
        this.activeModal.close(value.object);
      }, () => {
        this.activeModal.dismiss(null);
      });

      this.send = false;
    }, (response: HttpErrorResponse) => {
      this.send = false;
      if ('obj.customer' in response.error) {
        this.alertService.add('danger', 'Position hinzufügen nicht möglich, da Artikel kein Kunde hinterlegt hat!');
      }
    });
  }

  private openModal(workflowId: number,
                    article: string,
                    obj: WorkflowPrice | WorkflowPositionView,
                    formValue: any): Observable<PositionCreateResponse> {
    return this.offerHistoryModalService.open(workflowId, article, obj as WorkflowPrice, formValue);
  }

}
