<!--
  ~ Copyright (C) 2017 envisia GmbH
  ~ All Rights Reserved.
  -->
<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    BA Endtermin Steuerung
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="row">
    <div class="col-md-12">
      <article-extra-table-ba-termination formControlName="ba_termination">
        <span class="left">Nutzen bis</span>
        <span class="right">Tage Davor</span>
      </article-extra-table-ba-termination>
    </div>
  </div>

</div>
