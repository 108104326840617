import * as React from 'react';
import {Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {ReactBaseComponent} from '../../../react/base/react-base.component';
import {MaterialSupplierSelect} from '../../../react/material/supplier-select/material-supplier-select';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NumberHelper} from '../../../helper/number-helper';

const containerElementName = 'materialSupplierSelect';
export const MATERIAL_SUPPLIER_SELECTOR_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => MaterialSupplierSelectSearchComponent),
  multi: true,
};

@Component({
  selector: 'material-supplier-select-search',
  template: `<div #${containerElementName}></div>`,
  providers: [MATERIAL_SUPPLIER_SELECTOR_VALUE_ACCESSOR],
})
export class MaterialSupplierSelectSearchComponent extends ReactBaseComponent implements ControlValueAccessor {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() groupId?: number;
  supplierId: number | null;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  constructor() {
    super();
  }

  writeValue(obj: any): void {
    const supplierIdNew = NumberHelper.saveParseInteger(obj);
    if (this.supplierId === supplierIdNew) {
      return;
    }

    this.supplierId = supplierIdNew;
    this._onChange(this.supplierId);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  protected renderComponent(): JSX.Element {
    const _ = React.version;
    return <MaterialSupplierSelect groupId={this.groupId}
                                   supplierId={this.supplierId}
                                   supplierIdChanged={supplierId => this.writeValue(supplierId)}/>;
  }
}
