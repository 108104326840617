import {Component, ErrorHandler, Input, OnDestroy, OnInit} from '@angular/core';
import {WorkflowOrder} from '../order-request.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {WorkflowOrderRequestService} from '../../workflow-services/workflow-order-request.service';
import Helper from '../../../../helper/helper';
import {Subscription} from 'rxjs';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'app-order-pdf-edit-modal',
  templateUrl: './order-pdf-edit-modal.component.html',
})
export class OrderPdfEditModalComponent implements OnInit, OnDestroy {
  @Input() data: WorkflowOrder;
  form: UntypedFormGroup;
  deliveryDateSubscription: Subscription;
  callOfSubscription: Subscription;

  constructor(private activeModal: NgbActiveModal,
              private fb: UntypedFormBuilder,
              private errorHandler: ErrorHandler,
              private alertService: AlertService,
              private workflowOrderRequestService: WorkflowOrderRequestService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'quanity': [Helper.toInt(this.data.positions.quanity.replace('.', ''))],
      'price': [this.data.positions.price],
      'delivery_date': [this.data.positions.delivery_date],
      'call_of': [this.data.positions.call_of],
    });

    this.deliveryDateSubscription = this.form.get('delivery_date').valueChanges.subscribe(() => {
      this.form.patchValue({'call_of': null}, {emitEvent: false});
    });
    this.callOfSubscription = this.form.get('call_of').valueChanges.subscribe(() => {
      this.form.patchValue({'delivery_date': null}, {emitEvent: false});
    });
  }

  ngOnDestroy(): void {
    this.deliveryDateSubscription.unsubscribe();
    this.callOfSubscription.unsubscribe();
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  save(): void {
    console.log('FormValues:', this.form.value);
    this.workflowOrderRequestService.edit(this.data.id, this.data.positions.index, this.form.value).subscribe(response => {
      this.activeModal.close(response);
    }, (error) => {
      this.errorHandler.handleError('Error: ' + JSON.stringify(error));
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten!');
    });
  }

}
