import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ArticleWeightConfig, ArticleWeightModel} from '../models/article-weight.model';

@Injectable({providedIn: 'root'})
export class ArticleWeightService {

  constructor(private http: HttpClient) {
  }

  getWeight(oaNr: string, amount: number): Observable<ArticleWeightModel> {
    return this.http.get<ArticleWeightModel>(environment.apiv4uri + `weight/article/${oaNr}/${amount}`);
  }

  setConfig(weightConfig: ArticleWeightConfig): Observable<ArticleWeightConfig> {
    return this.http.put<ArticleWeightConfig>(environment.apiv4uri + 'weight/config', weightConfig);
  }

  getConfig(): Observable<ArticleWeightConfig> {
    return this.http.get<ArticleWeightConfig>(environment.apiv4uri + 'weight/config');
  }
}
