import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../core/shared.module';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderStartModalComponent} from './business-order-start-modal.component';

@NgModule({
    imports: [
        NgbModalModule,
        SharedModule,
    ],
    declarations: [
        BusinessOrderStartModalComponent
    ]
})
export class BusinessOrderStartModule {
}
