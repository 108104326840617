<label for="input-text">
  <ng-content></ng-content>
</label>


<div class="input-group" *ngIf="unit">
  <input type="text" id="input-text" class="form-control"
         [ngClass]="{red_alert: parseError}"
         [value]="current"
         [disabled]="disabled"
         (change)="onChange($event.target.value)"
         (keyup)="onChange($event.target.value)">

  <div class="input-group-addon">
    {{ unit }}
  </div>
</div>

<input type="text" id="input-text" class="form-control" *ngIf="!unit"
       [ngClass]="{red_alert: parseError}"
       [value]="current"
       [disabled]="disabled"
       (change)="onChange($event.target.value)"
       (keyup)="onChange($event.target.value)">
