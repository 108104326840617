<div>
  &nbsp;
  <a uiSref="a.multilayerplan.create" title="Multilayer Plan erstellen" class="btn btn-default">
    <span class="fa fa-plus"></span>
  </a>

  <button class="btn btn-default" (click)="saveEmitter.emit()" type="button" title="Artikel speichern" [disabled]="locked">
    Speichern
  </button>
</div>
