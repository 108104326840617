import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BusinessOrder, BusinessOrderReproduction} from '../../models/business-order.models';
import {BusinessOrderViewMergeService} from './business-order-view-merge.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../../../../common/alert-service/alert.service';

@Component({
  selector: 'business-order-view-merge-modal',
  templateUrl: './business-order-view-merge-modal.component.html',
})
export class BusinessOrderViewMergeModalComponent implements OnInit {
  @Input() businessOrder: BusinessOrder;
  @Input() reproduction: BusinessOrderReproduction;
  form: UntypedFormGroup;
  errorType?: string = null;

  constructor(private service: BusinessOrderViewMergeService,
              private alertService: AlertService,
              private modalService: NgbActiveModal,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      source_id: [this.reproduction.id, {options: {emitEvent: false}}],
      source_index: [this.reproduction.index, {options: {emitEvent: false}}],
      note: [null],
    });
  }

  submit(): void {
    this.errorType = null;
    this.service.merge(this.form.value).subscribe(() => {
      this.alertService.add('success', 'Nachfertigung wurde erfolgreich mit Ziel BA zusammengefügt');
      this.modalService.close();
    }, (error: HttpErrorResponse) => {
      this.errorType = error.error as string;
    });
  }

  dismiss(): void {
    this.modalService.dismiss();
  }
}
