import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {DeliveryConditionBoxComponent} from './delivery-condition-box.component';
import {DeliveryConditionOuTableComponent} from './delivery-condition-ou-table.component';
import {DeliveryConditionOverdeliveryBoxComponent} from './delivery-condition-overdelivery-box.component';
import {LoadingOrErrorModule} from '../../../common/loading/loading-or-error.module';

@NgModule({
    imports: [
        SharedModule,
        LoadingOrErrorModule,
    ],
    declarations: [
        DeliveryConditionBoxComponent,
        DeliveryConditionOuTableComponent,
        DeliveryConditionOverdeliveryBoxComponent
    ],
    exports: [
        DeliveryConditionBoxComponent,
        DeliveryConditionOverdeliveryBoxComponent
    ]
})
export class DeliveryConditionBoxModule {
}
