<div class="buttonbar-view">

  <a uiSref="a.article.create" class="btn btn-default" *ngIf="('article.change'|HasPerm)">
    <i class="fa fa-plus"></i>
  </a>

  <button class="clickable btn btn-default"
          type="button"
          [disabled]="validationErrors.length > 0"
          [ngClass]="{'red_font': hasValidationErrors}"
          [ngbTooltip]="saveButtonErrorMessage"
          (click)="create()">
    Erstellen
  </button>

</div>

<article-overview-template [specification]="specification"
                           [form]="form"
                           [refCount]="refCount"
                           [errors]="errors"
                           (customerChange)="customerChange($event)"
                           (calculated)="calculated($event)"
                           (aspectCalculated)="aspectCalculated()">
</article-overview-template>
