<ng-container [ngSwitch]="type" *ngIf="history">
  <ng-container *ngIf="history.date">
    Datum: {{ history.date }},
  </ng-container>
  <ng-container *ngIf="history.delivery_date_estimated">
    Vor.Lief: {{ history.delivery_date_estimated }},
  </ng-container>
  <ng-container *ngIf="history.quantity">
    Menge: {{ history.quantity|money }},
  </ng-container>
  <ng-container *ngIf="history.ship_name">
    Schiffsname: {{history.ship_name}},
  </ng-container>
  <ng-container *ngIf="history.booking_id">
    Buchungsnr: {{history.booking_id}},
  </ng-container>
  <ng-container *ngIf="history.supplier_rg_nr">
    RG Nr Lieferant: {{history.supplier_rg_nr}}
  </ng-container>
</ng-container>
