import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article} from '../../article/models/article.models';
import {CalculationProcess} from '../price.models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PriceService} from '../services/price.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {noop} from '../../../helper/noop';

@Component({
  selector: 'price-detail-article-calc',
  templateUrl: './price-detail-article-calc.component.html'
})
export class PriceDetailArticleCalcComponent implements OnInit {
  @Input() article: Article;
  @Input() defaultCalculation: { de: string, cn: string };
  @Input() processes: { de: CalculationProcess[], cn: CalculationProcess[] } = {de: [], cn: []};
  @Output() updateArticle = new EventEmitter<Article>();
  @Output() updateCalculation = new EventEmitter<{ de: string, cn: string }>();
  form: UntypedFormGroup;
  hashNotEqual: boolean;

  constructor(private fb: UntypedFormBuilder,
              private service: PriceService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.hashNotEqual = this.article.hash !== this.article.current_hash;
    this.initForm();
  }

  calc(): void {
    this.service.articleCalc(this.article.oa_nr, this.form.value).subscribe(article => {
      this.saveArticle(article);
    }, () => {
      this.alertService.add('danger', 'Kalkulation konnte nicht freigegeben werden!');
    });
  }

  overwriteCalc(): void {
    this.service.articleCalcOverwrite(this.article.oa_nr, this.form.value).subscribe(article => {
      this.saveArticle(article);
    }, () => {
      this.alertService.add('danger', 'Kalkulation konnte nicht gesetzt werden!');
    });
  }

  trackByFn(index: number, item: CalculationProcess) {
    noop(this, item);
    return index;
  }

  private saveArticle(article: Article): void {
    this.alertService.add('success', 'Kalkulation erfolgreich gesetzt!');
    this.article = article;
    this.updateArticle.next(article);
    this.updateCalculation.next(this.form.value);
    this.form.setValue({
      de: article.calc_de,
      cn: article.calc_chn
    });
    this.hashNotEqual = this.article.hash !== this.article.current_hash;
  }

  private initForm(): void {
    this.form = this.fb.group({
      'de': [this.article.calc_de, Validators.required],
      'cn': [this.article.calc_chn, Validators.required],
    });
  }

}
