import {Ng2StateDeclaration} from '@uirouter/angular';
import {CustomerCreateComponent} from './customer-create.component';
import {CustomerNavigationComponent} from '../navigation/customer-navigation.component';
import {FieldServiceService} from '../services/field-service.service';
import {CountryService} from '../services/country.service';

export function fieldServicesResolveFn(fieldService: FieldServiceService) {
  return fieldService.list().toPromise();
}

export function countryServiceResolveFn(countryService: CountryService) {
  return countryService.list().toPromise();
}

export const CUSTOMER_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.crm.create',
  url: '/crm/create',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: CustomerCreateComponent}
  },
  resolve: [
    {
      token: 'fieldServices',
      resolveFn: fieldServicesResolveFn,
      deps: [FieldServiceService],
    },
    {
      token: 'countries',
      resolveFn: countryServiceResolveFn,
      deps: [CountryService],
    }
  ],
  data: {requiresAuth: true}
};
