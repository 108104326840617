<ng-container [formGroup]="form">
  <td>
    <label style="display: inline-block">
      {{form.value.nr}}
    </label>
  </td>
  <td>
    <label style="width: 100%;">
      <input type="text" class="position" style="width: 99%;"
             (keyup.arrowDown)="clone()"
             formControlName="description"
             [inputSelect]>
    </label>
    <br>
    <label style="width: 100%;">
      <textarea id="desextension" class="position" maxlength="158"
                style="width: 99%; font-size: 11px; line-height: 13px;"
                (keyup.arrowDown)="clone()"
                formControlName="desextension"
                [inputSelect]></textarea>
    </label>
  </td>
  <td>
    <label>
      <input type="text" class="position right" style="width: 70px;"
             (keyup.arrowDown)="clone()"
             [autofocus]="position.focus"
             (autofocus-reset)="resetFocus()"
             formControlName="quanity"
             [inputSelect]>
    </label>
  </td>
  <td class="storzgreen" [ngClass]="{red_alert: invalidDe, 'greyed-out': !de}">
    <label>
      <input type="text" class="position right" style="width: 40px;"
             formControlName="lose_de"
             (keyup.arrowDown)="clone()"
             [inputSelect]>
    </label>
  </td>
  <td class="storzgreen" [ngClass]="{red_alert: invalidDe, 'greyed-out': !de}">
    <label>
      <input type="text" class="position right" style="width: 70px;"
             formControlName="calc_quanity"
             (keyup.arrowDown)="clone()"
             [inputSelect]>
    </label>
  </td>
  <td class="storzgreen" [ngClass]="{red_alert: invalidDe, 'greyed-out': !de}">
    <label>
      <input min="0" class="position right" style="width: 70px;"
             formControlName="release_de"
             (keyup.arrowDown)="clone()"
             [inputSelect]>
    </label>
  </td>
  <ng-container formGroupName="de">
    <td class="storzgreen" [ngClass]="{red_alert: invalidDe, 'greyed-out': !de}">
      <label>
        <input type="text" class="position right bold" style="width: 70px;"
               formControlName="price"
               (keyup.arrowDown)="clone()"
               [inputSelect]>
        <ng-container *ngIf="position.de.price">€</ng-container>
      </label>
      <div class="position_info_block">

        <span *ngIf="!invalidDe && position.typ === 'article' && !position.saved_de"
                (click)="insertHistory('de')"
                class="position_action position_refresh click" style="margin-right: 15px;"
                title="Preis in Preisliste speichern">
          <i class="fa fa-floppy-o"></i>
        </span>

        <span *ngIf="position.saved_de" class="position_action position_refresh"
             style="color: green; display: inline-block; cursor: help;margin-right: 15px;"
             title="Preis wurde erfolgreich in die Preisliste gespeichert">
          <i class="fa fa-check"></i>
        </span>

        <span *ngIf="invalidDe && position.typ === 'article' && !position.saved_de"
                (click)="manualize('de')"
                class="position_action position_refresh click" style="margin-right: 15px"
                title="Preis manuell setzen / aktualisieren">
          <i class="fa fa-check"></i>
        </span>

        <span class="position_action position_refresh click" title="Deutschen Preis neu berechnen"
                (click)="refresh('de')" *ngIf="position.typ === 'article'">
          <i class="fa fa-refresh"></i>
        </span>
      </div>
    </td>
  </ng-container>

  <td class="storzgreen align-right">
    <ng-container *ngIf="position.typ === 'article'">
      <input type="number"
             min="0"
             class="position right bold"
             style="width: 50px;"
             formControlName="delivery_time_days_de">&nbsp;AT
    </ng-container>
  </td>

  <ng-container formGroupName="de">
    <td class="storzgreen_dark right"
        [ngClass]="{red_alert: invalidDe, 'greyed-out': !de}">
      <label>
        <input type="text" class="position right" style="width: 85px;"
               formControlName="price_total"
               (keyup.arrowDown)="clone()"
               [inputSelect]>
        <ng-container *ngIf="position.de.price_total">€</ng-container>
      </label>
      <label class="position_info_block">
        {{ position.calc_de }}
      </label>

    </td>
  </ng-container>

  <td class="storzgreen" [ngClass]="{red_alert: invalidDe, 'greyed-out': !de}">
    <div *ngIf="position.notes_de" title="{{position.notes_de}}" class="notes-truncated inline-div click"
         (click)="editNotes('de')">
      {{ position.notes_de }}
    </div>
    <ng-container *ngIf="!position.notes_de">
      <i class="fa fa-1x fa-pencil click" style="color: #999;" (click)="editNotes('de')"></i>
    </ng-container>
  </td>

  <td class="storzblue" [ngClass]="{red_alert: invalidCn, 'greyed-out': !cn}">
    <label>
      <input type="text" class="position right"
             formControlName="lose_cn"
             (keyup.arrowDown)="clone()"
             [inputSelect]>
    </label>
  </td>

  <td class="storzblue" [ngClass]="{red_alert: invalidCn, 'greyed-out': !cn}">
    <label>
      <input min="0" class="position right" style="width: 70px;"
             formControlName="release_cn"
             (keyup.arrowDown)="clone()"
             [inputSelect]>
    </label>
  </td>

  <ng-container formGroupName="cn">
    <td class="storzblue" [ngClass]="{red_alert: invalidCn, 'greyed-out': !cn}">
      <label>
        <input type="text" class="position right" style="width: 70px;"
               formControlName="price_buy"
               (keyup.arrowDown)="clone()"
               [inputSelect]>
        <ng-container *ngIf="position.cn.price_buy">$</ng-container>
      </label>

      <label class="position_info_block" *ngIf="position.typ === 'article'">
        {{ position.producer_cn }}
        <i class="fa fa-truck fa-2x click" [ngClass]="{'btn-link': position.producer_cn}"
           (click)="producer()" style="margin-left: 5px;"
           title="Produzent festlegen"></i>
      </label>

    </td>

    <td class="storzblue" [ngClass]="{red_alert: invalidCn, 'greyed-out': !cn}">
      <label>
        <input type="text" class="position right bold" style="width: 70px;"
               formControlName="price"
               (keyup.arrowDown)="clone()"
               [inputSelect]>
        <ng-container *ngIf="position.cn.price">€</ng-container>
      </label>

      <div class="position_info_block">
        <span *ngIf="!invalidCn && position.typ === 'article' && !position.saved_cn"
                (click)="insertHistory('cn')"
                class="position_action position_refresh click" style="margin-right: 15px;"
                title="Preis in Preisliste speichern">
          <i class="fa fa-floppy-o"></i>
        </span>

        <span *ngIf="position.saved_cn" class="position_action position_refresh"
             style="color: green; display: inline-block; cursor: help;margin-right: 15px;"
             title="Preis wurde erfolgreich in die Preisliste gespeichert">
          <i class="fa fa-check"></i>
        </span>

        <!-- Tooling Save -->
        <span *ngIf="position.typ === 'additional' && !position.saved_cn"
                (click)="insertAdditionalHistory('cn')"
                class="position_action position_refresh click" style="margin-right: 15px;"
                title="Preis in Preisliste speichern">
          <i class="fa fa-floppy-o"></i>
        </span>
        <!-- End Tooling Save -->

        <span *ngIf="invalidCn && position.typ === 'article' && !position.saved_cn"
                (click)="manualize('cn')"
                class="position_action position_refresh click" style="margin-right: 15px"
                title="Preis manuell setzen / aktualisieren">
          <i class="fa fa-check"></i>
        </span>

        <span class="position_action position_refresh click" title="China Preis neu berechnen"
                (click)="refresh('cn')" *ngIf="position.typ === 'article'">
          <i class="fa fa-refresh"></i>
        </span>
      </div>
      <label class="position_info_block" *ngIf="position.typ === 'article'">
        {{position.production_cn}}
      </label>
    </td>
  </ng-container>

  <td class="storzblue align-right">
    <ng-container *ngIf="position.typ === 'article'">
      <input type="number"
             min="0"
             class="position right bold"
             style="width: 50px;"
             formControlName="delivery_time_days_cn">&nbsp;AT
    </ng-container>
  </td>

  <td class="storzblue" [ngClass]="{'greyed-out': !cn}">
    <label>
      <input type="text" class="position right bold" style="width: 70px;"
             formControlName="price_reduction_cn"
             (keyup.arrowDown)="clone()"
             [inputSelect]>
    </label>
  </td>

  <ng-container formGroupName="cn">
    <td class="storzblue_dark right"
        [ngClass]="{red_alert: invalidCn, 'greyed-out': !cn}">
      <div *ngIf="position.typ === 'article'">
        <div class="pull-left">EK:</div>
        <div class="pull-right">
          {{ position.cn.price_buy_total|euro }}
        </div>
      </div>
      <div class="clearfix" *ngIf="position.typ === 'article'"></div>
      <div>
        <div class="pull-left" *ngIf="position.typ === 'article'"><label for="china-max-price">VK:</label></div>
        <div class="pull-right">
          <input type="text" class="position right" style="width: 85px; font-weight: bold;"
                 id="china-max-price"
                 [ngClass]="{'red-font': position.cn.red}"
                 formControlName="price_total"
                 (keyup.arrowDown)="clone()"
                 [inputSelect]>
          <div class="inline-div" *ngIf="position.cn.price_total" [ngClass]="{'red-font': position.cn.red}">€</div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div>
        <div class="pull-left">&nbsp;</div>
        <div class="pull-right" style="display: block; text-align: right; font-size: 10px;">
          {{ position.calc_cn }}
        </div>
      </div>
    </td>
  </ng-container>

  <td class="storzblue" [ngClass]="{red_alert: invalidCn, 'greyed-out': !cn}">
    <div *ngIf="position.notes_cn" title="{{position.notes_cn}}" class="notes-truncated inline-div click"
         (click)="editNotes('cn')">
      {{ position.notes_cn }}
    </div>
    <ng-container *ngIf="!position.notes_cn">
      <i class="fa fa-1x fa-pencil click" style="color: #999;" (click)="editNotes('cn')"></i>
    </ng-container>
  </td>

  <td style="padding: 0; text-align: center; vertical-align: middle;font-size: 19px;">
    <div class="position_action position_copy click" title="Position duplizieren"
         (click)="clone()" *ngIf="position.typ === 'article'">
      <i class="fa fa-copy"></i>
    </div>
    <br/>

    <div class="position_action position_delete click" title="Position löschen" (click)="removePosition()">
      <i class="fa fa-trash-o"></i>
    </div>
  </td>
</ng-container>
