import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ContractAdditionalForm, ContractAdditionalFormWithNr, WorkflowGroup} from '../../models/workflow-create.models';
import {Contract} from '../../models/contract.models';
import {debounceTime, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {WorkflowAdditional} from '../../models/workflow.models';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {FrameService} from '../../workflow-services/frame.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Address} from '../../../customer/model/address';
import {WorkflowService} from '../../workflow-services/workflow.service';
import {WorkflowAddressUpdate} from '../../helper/workflow-address-update';
import {DateHelper} from '../../../../helper/date-helper';
import {Offer} from '../../models/offer.models';
import {StringHelper} from '../../../../common/string-helper';
import {ContractCreateExistingModalComponent} from './contract-create-existing-modal/contract-create-existing-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'contract-create-additional',
  templateUrl: './contract-create-additional.component.html'
})
export class ContractCreateAdditionalComponent extends WorkflowAddressUpdate implements OnInit, OnDestroy {
  @Input() additional: WorkflowAdditional;
  @Input() addresses: Address[];
  @Input() workflow: WorkflowGroup;
  @Input() contractList: Contract[];
  @Input() lastOffer?: Offer;
  @Output() saveEmitter = new EventEmitter<ContractAdditionalFormWithNr>();
  form: UntypedFormGroup;
  formSubscription?: Subscription;
  errors: { [key: string]: any; } = {};
  current: ContractAdditionalFormWithNr;

  constructor(private fb: UntypedFormBuilder,
              protected alertService: AlertService,
              private frameService: FrameService,
              protected workflowService: WorkflowService,
              private modalService: NgbModal) {
    super();
  }

  ngOnInit(): void {
    console.log('LastOffer:', this.lastOffer);
    let advice = this.additional.advice;
    if (this.lastOffer && !StringHelper.isNull(this.lastOffer.text) && StringHelper.isNull(advice)) {
      advice = this.lastOffer.text;
    }

    // generates a patch set that will be emitted upstream and patched into the form
    const patchValue: ContractAdditionalForm = {
      contract_date: this.additional.contract_date ? this.additional.contract_date : DateHelper.format(new Date()),
      order_nr: this.additional.order_nr,
      order_date: this.additional.order_date,
      advice: advice
    };
    this.form = this.fb.group({
      contract_date: [patchValue.contract_date],
      order_nr: [patchValue.order_nr],
      order_date: [patchValue.order_date],
      advice: [patchValue.advice]
    });

    // set the current form object and emit it
    this.current = Object.assign({}, patchValue, {nr: true});
    this.saveEmitter.emit(this.current);

    // form debouncer that will always update the parent contract and after a debounce update all form values
    this.formSubscription = this.form.valueChanges.pipe(
      tap(val => this.mergeAndUpdateForm(val)),
      debounceTime(850)
    ).subscribe(val => {
      this.updateAdditional(val);
    }, ignoreRejection);
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  updateAdditional(formValues: any): void {
    this.errors = {};
    this.frameService.additionalSave(this.workflow.object.id, formValues).subscribe(response => {
      if (response.existing.length > 0) {
        this.openExistingWorkflowIdModal(response.existing);
      } else {
        this.alertService.add('success', 'Werte erfolgreich gespeichert!');
      }
    }, (response: HttpErrorResponse) => {
      const isJson = response.headers.get('Content-Type') === 'application/json';

      if (!!response.error && isJson) {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Beim speichern der Werte ist ein Fehler aufgetreten!');
    });
  }

  toggle(): void {
    this.current.nr = !this.current.nr;
    this.saveEmitter.emit(this.current);
  }

  // -- workflow address update

  protected currentDeliveryAddressId(): string {
    return this.workflow.object.delivery_id;
  }

  protected updateDeliveryId(data: Address): void {
    this.workflow.object.delivery_id = data.id;
  }

  protected workflowId(): number {
    return this.workflow.object.id;
  }

  protected resetDeliveryAddress(): void {
    const oldId = this.workflow.object.delivery_id;
    this.workflow.object.delivery_id = undefined;
    this.workflow.object.delivery_id = oldId;
  }

  // -- simple helper

  private mergeAndUpdateForm(formValues: any): void {
    this.current.contract_date = formValues.contract_date;
    this.current.order_nr = formValues.order_nr;
    this.current.order_date = formValues.order_date;
    this.current.advice = formValues.advice;
    this.saveEmitter.emit(this.current);
  }

  openExistingWorkflowIdModal(workflowIds: number[]): void {
    const modalRef = this.modalService.open(ContractCreateExistingModalComponent);
    modalRef.componentInstance.workflowIds = workflowIds;
  }

}
