<div class="modal-header">
  <div class="modal-header">
    <h3 class="modal-title">
      Materialgruppen für Produkt Gruppe {{productGroup.id}} {{productGroup.name}} Bearbeiten
    </h3>
  </div>
</div>

<div class="modal-body">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>Gruppe</th>
      <th>in Produkt Gruppe</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let group of groupsWithRelations; trackBy: trackByFn; let index = index">
      <td>{{ group.mg.group_id }} {{ group.mg.name }}</td>
      <td>
        <button type="button" class="ev-button" [disabled]="lock" (click)="toggleGroup(group)">
          <i class="fa fa-2x" [ngClass]="group.classes"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default pull-right" (click)="close()">
    Schließen
  </button>
</div>
