import * as React from 'react';
import {Money} from '../../../../../common/money/Money';
import Helper from '../../../../../helper/helper';

interface Props {
  value: number | string | null | undefined;
  decimals?: number;
}
export function FormatNumber(props: Props) {
  const valueFormatted: () => string = () => {
    if (Helper.undefined(props.value)) {
      return '';
    }

    let n: number;
    if (typeof props.value === 'string') {
      n = Money.parse(props.value);
    } else {
      n = props.value;
    }

    return Money.stringify(n, props.decimals ?? 0);
  };

  return <>{valueFormatted()}</>;
}
