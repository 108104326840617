import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject, throwError, from, of} from 'rxjs';
import {Article, ArticleStream, ArticleStreamError, ArticleStreamValue} from '../models/article.models';
import {ArticleMainSaveModalComponent} from '../main/save-modal/article-main-save-modal.component';
import {ArticleSimpleUpdateService} from './article-simple-update.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BusinessOrderService} from '../../workflow/business-order/services/business-order.service';
import {mergeMap, catchError, map, takeUntil} from 'rxjs/operators';
import {AlertService} from '../../../common/alert-service/alert.service';

@Injectable({providedIn: 'root'})
export class ArticleUpdateService {

  constructor(private stateService: StateService,
              private ngbModal: NgbModal,
              private alertService: AlertService,
              private updateService: ArticleSimpleUpdateService,
              private businessOrderService: BusinessOrderService) {
  }

  update(article: Article, sdsNr: string, data: any, options: { [key: string]: any; }): Observable<ArticleStream> {
    if (article.sds_nr) {
      return this.saveModalCall(article, sdsNr, data, options);
    } else {
      return this.updateService.simpleCall(article, {data: data, sds_nr: sdsNr}).pipe(
        map(value => new ArticleStreamValue(value)),
        catchError((error: HttpErrorResponse) => of(new ArticleStreamError(error)))
      );
    }
  }

  private saveModalCall(article: Article, sdsNr: string, data: any, options: { [key: string]: any; }): Observable<ArticleStream> {
    return this.businessOrderService.hasActiveByArticle(article.oa_nr).pipe(
      catchError(err => {
        this.alertService.add(
          'danger',
          'Artikel speichern nicht möglich, bitte wenden Sie sich an Ihren Administrator!'
        );
        return throwError(err);
      }),
      mergeMap(val => {
        const subject = new Subject<ArticleStream>();
        const modalRef = this.ngbModal.open(ArticleMainSaveModalComponent, {windowClass: 'modal2-mm'});
        modalRef.componentInstance.data = data;
        modalRef.componentInstance.sdsNr = sdsNr;
        modalRef.componentInstance.article = article;
        modalRef.componentInstance.notices = options.blocking_notice;
        modalRef.componentInstance.subject = subject;
        modalRef.componentInstance.has_active_bas = val;
        // we create a non error observable from the modal so that when the modal is closed,
        // any corresponding stream, will also be closed
        const modalObservable = from(modalRef.result).pipe(catchError(() => of(null)));

        return subject.asObservable().pipe(takeUntil(modalObservable));
      })
    );
  }

}
