<table class="table article-matrix" *ngIf="init">
  <thead>
    <tr>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>geätzt</th>
      <th>KZD</th>
      <th>LSM</th>
      <th>A</th>
      <th>B</th>
      <th>&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="gray">
        SDS
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['SDS']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'SDS', 'this')">
      </td>
      <td *ngFor="let d of data['SDS']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'SDS', index)">
      </td>
      <td>&nbsp;</td>
    </tr>

    <tr>
      <td class="gray">
        UL-USA
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['UL-USA']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'UL-USA', 'this')">
      </td>
      <td *ngFor="let d of data['UL-USA']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'UL-USA', index)">
      </td>
      <td>&nbsp;</td>
    </tr>

    <tr>
      <td class="gray">
        UL-CSA
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['UL-CSA']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'UL-CSA', 'this')">
      </td>
      <td *ngFor="let d of data['UL-CSA']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'UL-CSA', index)">
      </td>
      <td>&nbsp;</td>
    </tr>

    <tr>
      <td class="gray">
        DSR (UL 769A)
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['DSR (UL 769A)']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'DSR (UL 769A)', 'this')">
      </td>
      <td *ngFor="let d of data['DSR (UL 769A)']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'DSR (UL 769A)', index)">
      </td>
      <td>&nbsp;</td>
    </tr>

    <tr>
      <td class="gray">
        E130014
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['E130014']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'E130014', 'this')">
      </td>
      <td *ngFor="let d of data['E130014']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'E130014', index)">
      </td>
      <td>&nbsp;</td>
    </tr>

    <tr>
      <td class="gray">
        94V-0
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['94V-0']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, '94V-0', 'this')">
      </td>
      <td *ngFor="let d of data['94V-0']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, '94V-0', index)">
      </td>
      <td>&nbsp;</td>
    </tr>

    <tr>
      <td class="gray">
        UR-Zeichen
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['UR-Zeichen']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'UR-Zeichen', 'this')">
      </td>
      <td *ngFor="let d of data['UR-Zeichen']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'UR-Zeichen', index)">
      </td>
      <td>&nbsp;</td>
    </tr>

    <tr>
      <td class="gray">
        Date-Code
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['Date-Code']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'Date-Code', 'this')">
      </td>
      <td *ngFor="let d of data['Date-Code']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'Date-Code', index)">
      </td>
      <td>
        <div class="col-sm-6">
          <ng-content select=".date_code_input"></ng-content>
        </div>
      </td>
    </tr>
    <tr>
      <td class="gray">
        Sonstiges
      </td>
      <td class="gray">
        <input type="checkbox" [checked]="data['Sonstiges']['this']" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'Sonstiges', 'this')">
      </td>
      <td *ngFor="let d of data['Sonstiges']['other']; trackBy: trackByFn; let index = index">
        <input type="checkbox" [checked]="d" [ngClass]="{red_alert: error}"
               (change)="changeValue($event, 'Sonstiges', index)">
      </td>
      <td>
        <div class="col-sm-6">
          <ng-content select=".sonstiges_input"></ng-content>
        </div>
      </td>
    </tr>
  </tbody>

</table>
