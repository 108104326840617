import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {FinderItemContentModule} from './item-content/finder-item-content.module';
import {FinderSearchComponent} from './search/finder-search.component';
import {FinderSearchModule} from './search/finder-search.module';
import {FinderUploadComponent} from './upload/finder-upload.component';
import {FinderUploadModule} from './upload/finder-upload.module';

@NgModule({
  imports: [
    SharedModule,
    FinderSearchModule,
    FinderItemContentModule,
    FinderUploadModule,
  ],
  declarations: [
  ],
  exports: [
    FinderSearchComponent,
    FinderUploadComponent,
  ],
})
export class FinderModule {
}
