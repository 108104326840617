<div class="modal-header">
  <h3 class="modal-title">Kontakt auswählen</h3>
</div>
<div class="modal-body">
  <div class="whitelist contactlist">
    <ng-container *ngIf="data.data$ | async as contactList; else loadingOrError.template">
      <div class="whitelist-item click" (click)="select(null, contactList)">
        <h4>Kein Ansprechpartner</h4>
      </div>

      <div class="whitelist-item click"
           *ngFor="let contact of contactList"
           (click)="select(contact, contactList)"
           [ngClass]="{selected: currentContact?.id === contact.id}">

        <h4>{{ contact.first_name }} {{ contact.last_name }}</h4>

        <p *ngIf="contact.departments.length > 0">{{ contact.departments|JoinStringPipe }}</p>
        <p *ngIf="contact.position">{{ contact.position }}</p>

        <div class="bottom">
          <p *ngIf="contact.phone"><i class="fa fa-phone"></i>{{ contact.phone }}</p>

          <p *ngIf="contact.mobile"><i class="fa fa-mobile"></i>{{ contact.mobile }}</p>

          <p *ngIf="contact.mail"><i class="fa fa-envelope"></i>{{ contact.mail }}</p>
        </div>
      </div>
    </ng-container>

    <loading-or-error
        #loadingOrError
        [loadingWrapper]="data"
        [errorMessage]="'Konnte \'Kontakte\' nicht laden'"
        [spinnerSize]="2"
        spinnerName="circle-o-notch">
    </loading-or-error>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-default pull-left" (click)="cancel()">Abbrechen</button>
</div>
