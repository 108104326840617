import {Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'article-optional-group',
  templateUrl: './article-optional-group.component.html'
})
export class ArticleOptionalGroupComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() specNames: string[] = []; // names of the form elements that should be cleaned
  @Input() condition: boolean; // condition to show the form elements
  @ContentChild(TemplateRef, {static: true}) tmpl: TemplateRef<any>;
  hiddenValues = {};

  ngOnInit(): void {
    this.setHiddenValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && !changes.form.isFirstChange()) {
      this.setHiddenValues();
    }

    if (changes.condition && !changes.condition.isFirstChange()) {
      if (this.condition && changes.condition.previousValue !== this.condition) {
        this.form.patchValue(this.hiddenValues);
      } else {
        this.writeNullValues();
      }
    }
  }

  private setHiddenValues(): void {
    this.specNames.forEach(name => {
      this.hiddenValues[name] = this.form.value[name];
    });
  }

  private writeNullValues(): void {
    const specObjects = {};
    this.specNames.forEach(name => {
      this.hiddenValues[name] = this.form.value[name];
      specObjects[name] = null;
    });
    this.form.patchValue(specObjects);
  }
}
