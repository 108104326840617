<div class="modal-header">
</div>


<div class="modal-body">
  <div>
    <h4><strong>Kalkulation kopieren</strong></h4>
    <div style="margin-bottom: 10px;"></div>
    <div class="form-group form-group-sm">
      <table>
        <tbody>
        <tr>
          <td>Ursprungs Kalkulation:</td>
          <td>&nbsp;</td>
          <td><strong>{{current}}</strong></td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Name neue Kalkulation:</td>
          <td>&nbsp;</td>
          <td [formGroup]="calcForm">
            <input type="text" class="form-control input-sm" id="new_calculation" formControlName="newCalculation">
            <small id="calculation_max_size" class="form-text text-muted"><strong>max. 11 Zeichen</strong></small>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default btn-sm" (click)="cancel()">Abbrechen</button>
  <button type="button" class="btn btn-success btn-sm" (click)="copy()">Kalkulation kopieren</button>
</div>
