import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Lister} from '../../../../../common/wrapper.models';
import {
  MaterialOrder,
  MaterialOrderIntakeGroupElement,
  MaterialOrderIntakeListElement,
  MaterialOrderTransactionListElement,
} from '../../material-order.model';
import {noop} from '../../../../../helper/noop';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  MaterialOrderIntakeBookedPositionForm,
  MaterialOrderIntakeBookedGroupForm,
  MaterialOrderIntakeBookedForm,
  MaterialOrderIntakeBookedFormValue,
  MaterialOrderIntakeBookedTransactionForm,
} from './material-order-intake-booked-form.model';
import {MaterialOrderIntakeBookedPriceService} from './material-order-intake-booked-price.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {MaterialGroup} from '../../../groups/material-group.model';

type EitherArray = FormArray<FormGroup<MaterialOrderIntakeBookedGroupForm>> | FormArray<FormGroup<MaterialOrderIntakeBookedPositionForm>> | FormArray<FormGroup<MaterialOrderIntakeBookedTransactionForm>>;

@Component({
  selector: 'material-order-intake-booked-form',
  templateUrl: './material-order-intake-booked-form.component.html',
})
export class MaterialOrderIntakeBookedFormComponent implements OnInit, OnChanges {
  @Input() order: MaterialOrder;
  @Input() listBookedData: Lister<MaterialOrderIntakeGroupElement>;
  @Input() markedVariationId?: number;
  @Input() markedTransactionId?: number;
  @Input() finderSupplierUri?: string;
  @Input() groups: Lister<MaterialGroup>;
  @Output() updateOrder: EventEmitter<void> = new EventEmitter<void>();

  form: FormGroup<MaterialOrderIntakeBookedForm>;
  errors: {[key: string]: any} = {};
  submitLocked = false;

  constructor(private service: MaterialOrderIntakeBookedPriceService,
              private fb: FormBuilder,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      groups: this.fb.array(this.listBookedData.objects.map(g => this.createGroupForm(g)))
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listBookedData && !changes.listBookedData.isFirstChange()) {
      this.updateForm();
    }
  }

  createTransactionForm(
    transaction: MaterialOrderTransactionListElement,
    position: MaterialOrderIntakeListElement
  ): FormGroup<MaterialOrderIntakeBookedTransactionForm> {
    return this.fb.group<MaterialOrderIntakeBookedTransactionForm>({
      order_transaction_id: new FormControl<number>(transaction.id),
      price: new FormControl<string>(transaction.price ?? position.price ?? ''),
    });
  }

  createPositionForm(position: MaterialOrderIntakeListElement): FormGroup<MaterialOrderIntakeBookedPositionForm> {
    return this.fb.group<MaterialOrderIntakeBookedPositionForm>({
      order_position_id: new FormControl<number>(position.position_id),
      mark_finished: new FormControl<boolean>(position.status === 3),
      transactions: this.fb.array<FormGroup<MaterialOrderIntakeBookedTransactionForm>>(
        position.transactions
          .filter(t => t.new_transaction === true)
          .map(t => this.createTransactionForm(t, position))
      )
    });
  }

  createGroupForm(group: MaterialOrderIntakeGroupElement): FormGroup<MaterialOrderIntakeBookedGroupForm> {
    return this.fb.group<MaterialOrderIntakeBookedGroupForm>({
      article_group_id: new FormControl<number>(group.group.id),
      positions: this.fb.array<FormGroup<MaterialOrderIntakeBookedPositionForm>>(
        group.article_list.map(p => this.createPositionForm(p))
      )
    });
  }

  updateTransactionsForm(
    transactionArray: FormArray<FormGroup<MaterialOrderIntakeBookedTransactionForm>>,
    position: MaterialOrderIntakeListElement
  ): void {
    const transactionsLeft: number[] = [];
    for (let i = 0; i < position.transactions.length; i++) {
      const transaction = position.transactions[i];
      if (transaction.new_transaction !== true) {
        continue;
      }

      transactionsLeft.push(transaction.id);

      const transactionIndex = transactionArray.value.findIndex(t => t.order_transaction_id === transaction.id);
      if (transactionIndex < 0) {
        const insertTransactionAt = transactionArray.value.findIndex(t => t.order_transaction_id < transaction.id);
        transactionArray.insert(insertTransactionAt, this.createTransactionForm(transaction, position));
      } else {
        const transactionForm = transactionArray.at(transactionIndex) as FormGroup<MaterialOrderIntakeBookedTransactionForm>;
        if ((!transactionForm.dirty) && (transactionForm.value.price !== transaction.price) && (!!transaction.price)) {
          transactionForm.patchValue({price: transaction.price});
        }
      }
    }

    this.removeElementFromArray(transactionArray, transactionsLeft, 'order_transaction_id');
  }

  updatePositionsForm(
    positionArray: FormArray<FormGroup<MaterialOrderIntakeBookedPositionForm>>,
    positionElementArray: MaterialOrderIntakeListElement[]
  ): void {
    const positionIdsLeft: number[] = [];
    for (let i = 0; i < positionElementArray.length; i++) {
      const position = positionElementArray[i];
      positionIdsLeft.push(position.position_id);

      const positionIndex = positionArray.value.findIndex(p => p.order_position_id === position.position_id);
      if (positionIndex < 0) {
        const insertPositionAt = positionArray.value.findIndex(p => p.order_position_id > position.position_id);
        positionArray.insert(insertPositionAt, this.createPositionForm(position));
      } else {
        const positionForm = positionArray.at(positionIndex) as FormGroup<MaterialOrderIntakeBookedPositionForm>;
        if ((!positionForm.dirty) && positionForm.value.mark_finished !== (position.status === 3)) {
          positionForm.patchValue({mark_finished: position.status === 3});
        }

        this.updateTransactionsForm(
          positionForm.get('transactions') as FormArray<FormGroup<MaterialOrderIntakeBookedTransactionForm>>,
          position
        );
      }
    }

    this.removeElementFromArray(positionArray, positionIdsLeft, 'order_position_id');
  }

  updateGroupsForm(
    groupArray: FormArray<FormGroup<MaterialOrderIntakeBookedGroupForm>>,
    groupElementArray: MaterialOrderIntakeGroupElement[]
  ): void {
    const groupIdsLeft: number[] = [];
    for (let i = 0; i < groupElementArray.length; i++) {
      const group = groupElementArray[i];
      groupIdsLeft.push(group.group.id);

      const groupIndex = groupArray.value.findIndex(g => g.article_group_id === group.group.id);
      if (groupIndex < 0) {
        const insertAt = groupArray.value.findIndex(g => g.article_group_id > group.group.id);
        groupArray.insert(insertAt, this.createGroupForm(group));
      } else {
        this.updatePositionsForm(
          groupArray.at(groupIndex).get('positions') as FormArray<FormGroup<MaterialOrderIntakeBookedPositionForm>>,
          group.article_list
        );
      }
    }

    this.removeElementFromArray(groupArray, groupIdsLeft, 'article_group_id');
  }

  updateForm() {
    this.updateGroupsForm(
      this.form.get('groups') as FormArray<FormGroup<MaterialOrderIntakeBookedGroupForm>>,
      this.listBookedData.objects
    );
  }

  updateView(): void {
    this.updateOrder.emit();
    this.updateForm();
  }

  removeElementFromArray(array: EitherArray, idsLeft: number[], idProperty: string): void {
    for (let i = array.length - 1; i >= 0; i--) {
      if (!idsLeft.find(gId => gId === array.value[i][idProperty])) {
        array.removeAt(i);
      }
    }
  }

  get formArrayMapped(): (MaterialOrderIntakeGroupElement & {form: FormGroup<MaterialOrderIntakeBookedGroupForm>})[] {
    const formArray = this.form.get('groups') as FormArray<FormGroup<MaterialOrderIntakeBookedGroupForm>>;
    return this.listBookedData.objects.map(group => {
      const index = formArray.value.findIndex(g => g.article_group_id === group.group.id);
      return {...group, form: formArray.at(index)};
    });
  }

  trackByBookedFn(index: number, item: MaterialOrderIntakeGroupElement): number {
    noop(this);
    return item.group.id;
  }

  submit(): void {
    this.errors = {};
    this.submitLocked = true;
    this.service.setPrice(this.order.id, this.form.value as MaterialOrderIntakeBookedFormValue).subscribe(changes => {
      this.submitLocked = false;
      this.form.reset({...this.form.value});
      this.updateView();
      if (changes.position_states_changed === 0 && changes.transactions_changed === 0) {
        this.alertService.add('success', 'Keine Änderungen vorhanden');
      } else if (changes.transactions_changed > 0) {
        this.alertService.add('success', 'Preise wurden erfolgreich angepasst!');
      } else {
        this.alertService.add('success', 'Der Positionsstatus wurde erfolgreich gesetzt!');
      }
    }, (httpError: HttpErrorResponse) => {
      this.submitLocked = false;
      this.alertService.add('danger', 'Konnte Preise nicht speichern, bitte Eingaben prüfen.');
      if (httpError.status === 400) {
        this.errors = httpError.error.errors;
      }
    });
  }
}
