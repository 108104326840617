import {Component, Input} from '@angular/core';
import {CustomerOposSharedTableComponent} from './customer-opos-shared-table.component';
import {OposCorrection, OposFinalData} from '../../opos/opos.models';
import {noop} from '../../../helper/noop';
import {AbstractControl} from '@angular/forms';
import {OposHistoryHelperService} from '../../opos/history/opos-history-helper.service';

@Component({
  selector: 'customer-opos-correction-table',
  templateUrl: './customer-opos-correction-table.component.html',
})
export class CustomerOposCorrectionTableComponent extends CustomerOposSharedTableComponent {
  @Input() objects: OposCorrection[];

  constructor(private oposHistoryHelperService: OposHistoryHelperService) {
    super('corrections');
  }

  trackByFn(index: number, item: AbstractControl): number {
    noop(this, item);
    return index;
  }

  history(obj: OposCorrection): void {
    this.oposHistoryHelperService.openCorrection(obj.id);
  }
}
