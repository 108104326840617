<div *ngIf="loadingWrapper.data$|async as industrialSectors; else loadingOrError.template">
  <form [formGroup]="form">
    <ngx-bootstrap-multiselect class="input-sm"
                             formControlName="tags"
                             [options]="options"
                             [texts]="texts"
                             [settings]="settings">
    </ngx-bootstrap-multiselect>
  </form>
</div>

<loading-or-error #loadingOrError [loadingWrapper]="loadingWrapper" [errorMessage]="'Konnte Branchen nicht laden.'">
</loading-or-error>
