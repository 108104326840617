import {Component, Input, OnInit} from '@angular/core';
import {ProductionFetchView} from '../../models/fetch.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderService} from '../services/business-order.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {noop} from '../../../../helper/noop';
import {FetchService} from '../../workflow-services/fetch.service';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Component({
  selector: 'business-order-fetch-modal',
  templateUrl: './business-order-fetch-modal.component.html',
})
export class BusinessOrderFetchModalComponent implements OnInit {
  @Input() fetches: ProductionFetchView[];
  @Input() frameId: number;
  @Input() gold: boolean;
  @Input() workflowId: number;
  selected = false;

  constructor(private ngbActiveModal: NgbActiveModal,
              private alertService: AlertService,
              private fetchService: FetchService,
              private businessOrderService: BusinessOrderService) {
  }

  ngOnInit(): void {
    this.initSelected();
  }

  toggle(fetch: ProductionFetchView): void {
    fetch.selected = !fetch.selected;
    // set a warning flag, if we need one
    this.calculateTotals();
  }

  submit(): void {
    const filtered: ProductionFetchView[] = [];
    this.fetches.forEach(value => {
      if (value.selected) {
        filtered.push(value);
      }
    });
    this.ngbActiveModal.close(filtered);
  }

  dismiss(): void {
    this.ngbActiveModal.dismiss();
  }

  revert(fetch: ProductionFetchView): void {
    if (confirm('Möchten Sie den Abruf (' + fetch.fetch.ordering + ') wirklich zurücksetzen?' +
      '\r\nAchtung: Dies führt dazu, dass der Abruf nicht mehr mit dem ursprünglichen BA verbunden ist.')) {
      this.businessOrderService.revertFetch(fetch.fetch.id, this.workflowId, fetch.fetch.ordering).pipe(
        catchError(() => {
          this.alertService.add('danger', 'Abruf konnte nicht zurückgesetzt werden!');
          return EMPTY;
        }),
        mergeMap(() => this.fetchService.productionFetches(this.workflowId, this.frameId, this.gold))
      ).subscribe(data => {
        this.fetches = data;
        this.initSelected();
        this.alertService.add('success', 'Abruf zurückgesetzt!');
      }, () => {
        this.alertService.add('warning', 'Bitte aktualisieren Sie Ihre Seite!');
      });
    }
  }

  trackByFn(index: number, fetch: ProductionFetchView): number {
    noop(this, index);
    return fetch.fetch.id;
  }

  private initSelected() {
    let init = false;
    this.fetches.forEach(value => {
      if (value.fetch.status.id === 1 && !init) {
        value.selected = true;
        init = true;
      }
    });
    this.calculateTotals();
  }

  private calculateTotals(): void {
    const filtered = this.fetches.filter(val => val.selected);
    this.selected = filtered.length > 0;
  }

}
