<div [formGroup]="form">
  <div class="form-group" style="max-width:800px;">
    <label class="col-sm-2 control-label" for="name">Bezeichnung</label>
    <div class="col-sm-10">
      <input class="form-control" id="name" type="text" formControlName="name">
    </div>
  </div>


  <div class="form-group" style="max-width:800px;">
    <label class="col-sm-2 control-label" for="name">Bestell Bezeichnung</label>
    <div class="col-sm-10">
      <input class="form-control" id="name_order" type="text" formControlName="name_order">
    </div>
  </div>

  <div class="form-group" style="max-width:800px;">
    <label class="col-sm-2 control-label" for="supplier">Lieferant</label>
    <div class="col-sm-10">
      <material-supplier-selector id="supplier" formControlName="supplier_id"></material-supplier-selector>
    </div>
  </div>

  <div class="form-group" style="max-width:800px;">
    <label class="col-sm-2 control-label" for="konsi">Konsi</label>
    <div class="col-sm-10">
      <input type="checkbox" id="konsi" formControlName="konsi">
    </div>
  </div>

  <ng-container [formGroup]="articleFields">
    <div class="form-group"
         style="max-width:800px;"
         [formGroupName]="field.html_name"
         *ngFor="let field of articleFieldDefs">
      <label class="col-sm-2 control-label" [attr.for]="field.html_name">
        {{field.name}}
      </label>
      <div class="col-sm-10">
        <ng-container [ngSwitch]="field.getValueType()">
          <ng-container *ngSwitchCase="'choices'">
            <select class="form-control"
                    [id]="field.html_name"
                    formControlName="value">
              <option *ngFor="let choice of field.choices" [value]="choice">
                {{choice}}
              </option>
            </select>
          </ng-container>

          <ng-container *ngSwitchCase="'checkbox'">
            <input type="checkbox" [id]="field.html_name" formControlName="value">
          </ng-container>

          <ng-container *ngSwitchCase="'integer'">
            <input class="form-control" [id]="field.html_name" type="number" step="1" formControlName="value">
          </ng-container>

          <ng-container *ngSwitchCase="'float'">
            <input class="form-control" [id]="field.html_name" type="number" formControlName="value">
          </ng-container>

          <ng-container *ngSwitchDefault>
            <input class="form-control" [id]="field.html_name" type="text" formControlName="value">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
