import {Ng2StateDeclaration} from '@uirouter/angular';
import {DeliveryService} from '../workflow-services/delivery.service';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {DeliveryListComponent} from './list/delivery-list.component';
import {WORKFLOW_CREATE_RESOLVER} from '../resolver/workflow-data.resolver';
import {ErpTransition} from '../../../core/erp-transition.service';
import {DeliveryCreateComponent} from './create/delivery-create.component';

export const DELIVERY_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.d',
  abstract: true,
  data: {requiresAuth: true}
};

export function deliveryListResolveFn(transition: ErpTransition, service: DeliveryService) {
  return service.list(transition.params()).toPromise();
}

export const DELIVERY_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.d.list',
  url: '/workflow/delivery/list?page&order&sort&status&state&typ&date_start&date_end&sid&workflow_id&sds_nr&quanity&oa_nr&kd_art_nr&customer_name&customer_zip&customer_city&customer_country&customer_field_service&username',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: DeliveryListComponent}
  },
  resolve: [{token: 'listData', resolveFn: deliveryListResolveFn, deps: [ErpTransition, DeliveryService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    status: {dynamic: true},
    state: {dynamic: true},
    typ: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    sid: {dynamic: true},
    workflow_id: {dynamic: true},
    sds_nr: {dynamic: true},
    quanity: {dynamic: true},
    oa_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    customer_name: {dynamic: true},
    customer_zip: {dynamic: true},
    customer_city: {dynamic: true},
    customer_country: {dynamic: true},
    customer_field_service: {dynamic: true},
    username: {dynamic: true},
  },
  data: {requiresAuth: true}
};

export const DELIVERY_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.create.create_delivery_create',
  params: {data: {dynamic: true}, name: {}, id: {}},
  resolve: [WORKFLOW_CREATE_RESOLVER],
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: DeliveryCreateComponent}
  }
};

export const DELIVERY_STATES: Ng2StateDeclaration[] = [
  DELIVERY_MAIN_STATE,
  DELIVERY_LIST_STATE,
  DELIVERY_CREATE_STATE,
];
