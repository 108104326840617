import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {InvoiceCorrectionService} from '../services/invoice-correction.service';
import {InvoiceCorrectionNavigationComponent} from './invoice-correction-navigation.component';
import {InvoiceCorrectionListComponent} from './invoice-correction-list.component';
import {ErpTransition} from '../../../../core/erp-transition.service';

export const INVOICE_CORRECTION_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.correction',
  abstract: true,
  data: {requiresAuth: true}
};

export const INVOICE_CORRECTION_SUB_STATE: Ng2StateDeclaration = {
  name: 'a.correction.ic',
  abstract: true,
  data: {requiresAuth: true}
};

export function invoiceCorrectionListResolveFn(transition: ErpTransition, service: InvoiceCorrectionService) {
  return service.list(transition.params()).toPromise();
}

export const INVOICE_CORRECTION_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.correction.ic.list',
  url: '/workflow/invoice/correction/list?page&order&sort&date_start&date_end&correction_id&date&customer_name&invoice_id&label&revenue&expensed&expensed_date&username&status',
  views: {
    'navigation@a': {component: InvoiceCorrectionNavigationComponent},
    'main@a': {component: InvoiceCorrectionListComponent}
  },
  resolve: [
    {token: 'listData', resolveFn: invoiceCorrectionListResolveFn, deps: [ErpTransition, InvoiceCorrectionService]}
  ],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    correction_id: {dynamic: true},
    date: {dynamic: true},
    customer_name: {dynamic: true},
    invoice_id: {dynamic: true},
    label: {dynamic: true},
    revenue: {dynamic: true},
    expensed: {dynamic: true},
    expensed_date: {dynamic: true},
    username: {dynamic: true},
    status: {dynamic: true},
    note: {dynamic: true},
  },
  data: {requiresAuth: true}
};

export const INVOICE_CORRECTION_STATES: Ng2StateDeclaration[] = [
  INVOICE_CORRECTION_MAIN_STATE,
  INVOICE_CORRECTION_SUB_STATE,
  INVOICE_CORRECTION_LIST_STATE
];
