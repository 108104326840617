import {SearchBaseComponent} from '../../../../common/search/search.component';
import {DeliveryService} from '../../workflow-services/delivery.service';
import {DeliveryListData} from '../../models/delivery.models';
import {Component, ErrorHandler, Input} from '@angular/core';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {Lister} from '../../../../common/wrapper.models';
import {Observable} from 'rxjs';
import {DeliveryCocMaterialModalService} from '../coc-material-modal/delivery-coc-material-modal.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'delivery-list',
  templateUrl: './delivery-list.component.html'
})
export class DeliveryListComponent extends SearchBaseComponent<DeliveryService, DeliveryListData> {
  @Input() listData: Lister<DeliveryListData>;
  protected type = 'delivery';
  protected handles400Errors = true;

  constructor(protected service: DeliveryService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private documentModalService: DeliveryCocMaterialModalService) {
    super();
  }

  documentDownload(delivery: DeliveryListData, type: string): () => Observable<string> {
    return () => {
      return this.documentModalService
        .documentDownload(delivery.id, type, !delivery.material_saved, delivery.material)
        .pipe(map(material => {
        delivery.material = material;
        delivery.material_saved = true;
        return material;
      }));
    };
  }

  get lockedFilter(): boolean {
    const query = this.query();
    return query.is_locked === true || query.is_locked === 'true';
  }
}
