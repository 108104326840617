import {Component, Input, OnInit} from '@angular/core';
import {ArticleSpecification} from '../../../../envisia/article/models/article-specification.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ArticleSpecificationService} from '../../../../envisia/article/services/article-specification.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'article-config-field-names-edit-modal',
  templateUrl: './article-config-field-names-edit-modal.component.html'
})
export class ArticleConfigFieldNamesEditModalComponent implements OnInit {
  @Input() specification: ArticleSpecification;
  form: UntypedFormGroup;

  errors: {[key: string]: any} = {};

  constructor(private service: ArticleSpecificationService,
              private alertService: AlertService,
              private activeModal: NgbActiveModal,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name_de: [this.specification.name_de],
      name_en: [this.specification.name_en],
    });
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.errors = {};
    this.service.setNames(this.specification.name, this.form.value).subscribe(updatedSpec => {
      this.activeModal.close(updatedSpec);
    }, (errorResponse: HttpErrorResponse) => {
      this.alertService.add('danger', 'Konnte Bezeichnungen nicht speichern.');
      this.errors = errorResponse.error ?? {};
    });
  }

}
