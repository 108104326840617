<ng-container *ngFor="let tForm of positionFormGroups; trackBy: trackByFnControl; let iT = index">
  <tr [ngClass]="{'info': (markedVariationId === position.id || markedPositionId === position.position_id) }"
      [material-order-intake-row]="position"
      [material-order-intake-row-form]="tForm"
      [material-order-intake-row-errors]="errors"
      [material-order-intake-row-remaining]="remaining"
      [material-order-intake-row-fields]="fields"
      [material-order-intake-row-is-first]="iT === 0"
      [material-order-intake-row-group-permit]="groupPermission"
      (material-order-intake-row-add-transaction)="addTransactionPosition(iT, $event)"
      (material-order-intake-row-remove-transaction)="removeTransaction.emit($event)"
      (material-order-intake-row-update-position)="updatePosition($event)">
  </tr>
</ng-container>
<ng-container>
  <tr [ngClass]="{'info': (markedVariationId === position.id || markedPositionId === position.position_id || markedTransactionId === transaction.id)}"
      [material-order-intake-transaction-row]="transaction"
      [material-order-intake-transaction-row-num-fields]="fields.length + 8"
      (material-order-intake-transaction-row-remove-transaction)="removeTransactionPosition($event)"
      *ngFor="let transaction of position.transactions; trackBy:trackByFnTransaction; let iT = index">
  </tr>
</ng-container>
