import * as React from 'react';
interface Props {
  modalState: number;
  quantity?: number;
}

export function MaterialLabelAmountWarning(props: Props) {
  switch (props.modalState) {
    case 2:
      return <>
        <div>Sollen wirklich <strong>{props.quantity.toString(10)}</strong> Labels gedruckt werden?</div>
      </>;
    case 3:
      return <>
        <div>Es können maximal <strong>50</strong> Labels per Anfrage gedruckt werden.</div>
        <div>Sollen wirklich <strong>50</strong> Labels gedruckt werden?</div>
      </>;
    default:
      return null;
  }
}
