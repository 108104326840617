/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvisiaObject} from '../../common/wrapper.models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LabelForm, LabelPreviewForm, LabelSettings} from './label.models';

@Injectable({providedIn: 'root'})
export class LabelService {

  private uri = '/api/v1/label/';

  constructor(private http: HttpClient) {
  }

  listSettings(): Observable<LabelSettings> {
    return this.http.get<EnvisiaObject<LabelSettings>>(this.uri + 'settings/').pipe(map(val => val.object));
  }

  updateSettings(data: LabelSettings): Observable<LabelSettings> {
    return this.http.put<EnvisiaObject<LabelSettings>>(this.uri + 'settings/', data).pipe(map(response => response.object));
  }

  preview(data: LabelPreviewForm): Observable<{ status: 'ok', object: 'string' }> {
    return this.http.put<{ status: 'ok', object: 'string' }>(this.uri + 'preview/', data);
  }

  getCustomer(name: string): Observable<string> {
    return this.http.get<{ object: string }>(this.uri + 'settings/customer/' + encodeURIComponent(name) + '/')
      .pipe(map(val => val.object));
  }

  saveCustomer(data: any): Observable<any> {
    return this.http.put<any>(this.uri + 'settings/customer/', data).pipe(map(val => val.object));
  }

  print(data: LabelForm): Observable<{ status: 'ok' }> {
    return this.http.post<{ object: { status: 'ok' } }>(this.uri, data).pipe(map(val => val.object));
  }

}
