<form [formGroup]="form">
  <table class="whitetable table">
    <thead>
    <tr>
      <th>Versand Etikett</th>
      <th>Gewicht</th>
    </tr>
    </thead>
    <tbody formArrayName="weights">

    <tr *ngFor="let weight of weights.controls; let i = index">
      <td>{{ i + 1 }}</td>
      <td>
        <input type="text"
               class="input-bg"
               [name]="i + '-weight'"
               [formControlName]="i + ''"
               [ngClass]="{red_alert: errors[i + '']}" [autofocus]="i === 0">
      </td>
    </tr>

    </tbody>
  </table>


  <button class="btn btn-default pull-left" type="button" (click)="dismissButton()">
    Abbrechen
  </button>

  <button class="btn btn-success pull-right"
          type="button"
          (click)="nextButton()"
          [disabled]="!form.valid">
    Weiter
  </button>

</form>
