import {Component, Input, OnInit} from '@angular/core';
import {ArticleStep} from './production-step-article.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'production-step-article-title',
  templateUrl: './production-step-article-title.component.html'
})
export class ProductionStepArticleTitleComponent implements OnInit {
  @Input() step: ArticleStep;
  name = '';
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (this.step.article) {
      if (this.step.article.production_description !== this.step.article.production_title) {
        this.name += '(' + this.step.article.production_description + ') ';
      }
      this.name += this.step.article.production_title;
    } else if (this.step.validation) {
      if (this.step.validation.group_label !== '') {
        this.name += '[' + this.step.validation.group_label + '] ';
      }
      this.name = this.step.validation.label;
    }

    this.form = this.fb.group({
      title: [this.step.title ? this.step.title : null]
    });
  }

  abort(): void {
    this.activeModal.dismiss();
  }

  save(): void {
    this.activeModal.close(this.form.value.title);
  }

}
