<div class="modal-header">
  <h3 class="modal-title">Materialauswahl</h3>
  <div class="row">
    <div class="col-sm-6">
      <table class="table">
        <tr>
          <td>Benötigt:</td>
          <td><strong>Interne Materialvorgabe:</strong></td>
          <td>{{selectionData.article.data.material_internal || '-'}}</td>
          <td><strong>Start Datum:</strong></td>
          <td>{{selectionData.business_order.start_date|date:'dd.MM.yyyy'}}</td>
        </tr>
        <tr>
          <td></td>
          <td><strong>Hersteller / Bezeichnung:</strong></td>
          <td>{{selectionData.article.data.manufacturer || '-'}}</td>
          <td><strong>Anzahl Benötigtes Material:</strong></td>
          <td>{{selectionData.amount|money}}</td>
        </tr>

        <ng-container *ngIf="currentGroupId <= 2">
          <tr>
            <td></td>
            <td><strong>TG:</strong></td>
            <td>{{ suggestedTgValue }}</td>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td></td>
            <td><strong>Format:</strong></td>
            <td>{{ selectionData.article.data.utilize_format }}</td>
            <td colspan="2"></td>
          </tr>

          <ng-container *ngIf="currentGroupId === 1">
            <tr>
              <td></td>
              <td><strong>Kerndicke:</strong></td>
              <td>{{ selectionData.article.data.core_thickness_1 }}</td>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td></td>
              <td><strong>Cu Innen:</strong></td>
              <td>{{ selectionData.article.data.cu_inside_1 }}</td>
              <td colspan="2"></td>
            </tr>
          </ng-container>

          <ng-container *ngIf="currentGroupId === 2">
            <tr>
              <td></td>
              <td><strong>Materialdicke:</strong></td>
              <td>{{ selectionData.article.data.material_thickness }}</td>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td></td>
              <td><strong>Basis Cu Aussen:</strong></td>
              <td>{{ selectionData.article.data.cu_outside }}</td>
              <td colspan="2"></td>
            </tr>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="currentGroupId === 3">
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
        </ng-container>

        <tr *ngIf="errorMl">
          <td colspan="4">
            <business-order-create-multilayer-warning [errorMl]="errorMl" [invalidML]="invalidML">
            </business-order-create-multilayer-warning>
          </td>
        </tr>
      </table>
    </div>

    <div class="col-sm-3"></div>

    <div class="col-sm-3">
      <table class="table" *ngIf="!!selectedWeek">
        <tr>
          <td>Summe bis Auswahl:</td>
          <td><strong>Bestellt:</strong></td>
          <td class="align-right">{{selectedWeek.ordered_to_date|money}}&nbsp;Stück</td>
        </tr>
        <tr>
          <td></td>
          <td><strong>Bestätigt:</strong></td>
          <td class="align-right">{{selectedWeek.confirmed_to_date|money}}&nbsp;Stück</td>
        </tr>
        <tr>
          <td></td>
          <td><strong>Planung:</strong></td>
          <td class="align-right">{{selectedWeek.required_to_date|money}}&nbsp;Stück</td>
        </tr>
      </table>
    </div>
  </div>

  <div class="row" *ngIf="hasCustomerSpecificationMaterial">
    <div class="col-sm-12" style="margin-bottom: 20px; padding: 5px;">
      <div class="label label-danger red_alert" style="margin: 0 10px; padding: 10px;">
        <b>Achtung:</b> Material Kundenvorgabe ({{selectionData.article.data.customer_specification_material_type}})
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <ng-container *ngFor="let group of groups.objects">
        <button type="button"
                class="btn btn-default"
                [ngClass]="{'active': currentGroupId === group.id}"
                [autofocus]="currentGroupId === group.id"
                (click)="setGroup(group.id)"
                *ngIf="group.id !== 3 || (isMl && !!multilayerPlan)">
          {{ group.group_id }} {{ group.name }}
        </button>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-body">
  <business-order-material-select-group [selectionData]="selectionData"
                                        [params]="params"
                                        [groups]="groups"
                                        [fieldValues]="usedFields"
                                        [lockSelection]="lockSelection"
                                        (emitSelect)="selectMaterial($event)"
                                        (emitSelectWeek)="setSelectedWeek($event)">
  </business-order-material-select-group>
</div>

<div class="modal-footer">
</div>
