import {Injectable} from '@angular/core';
import {ListService} from '../../../../common/search/search.service';
import {AdapterListElem} from '../models/adapter.models';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lister} from '../../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class AdapterService implements ListService<AdapterListElem> {

  private url = '/api/adapter/';

  constructor(private http: HttpClient) {}

  list(query?: any): Observable<Lister<AdapterListElem>> {
    return this.http.get<Lister<AdapterListElem>>(this.url, {params: EnvisiaLocation.httpParams(query)});
  }

}
