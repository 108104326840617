<div class="modal-header">
  <h3 class="modal-title">
    Artikel speichern
  </h3>
</div>

<div class="modal-body red_alert">

  <article-duplicate [duplicates]="duplicates"></article-duplicate>

</div>

<div class="modal-footer red_alert">
  <button type="button" class="btn btn-default" (click)="close()">
    Schließen
  </button>
</div>
