import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WorkflowOrderListDataElement} from '../order-request.models';
import {ListService} from '../../../../common/search/search.service';
import {Observable} from 'rxjs';
import {Lister} from '../../../../common/wrapper.models';
import {environment} from '../../../../../environments/environment';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class WorkflowCnOrderListService implements ListService<WorkflowOrderListDataElement> {
  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<WorkflowOrderListDataElement>> {
    return this.http.get<Lister<WorkflowOrderListDataElement>>(
      `${environment.apiv4uri}order/list`,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}
