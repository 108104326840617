import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MaterialPlanningListAbstractService} from './material-planning-list.abstract-service';
import {MaterialSelectLister} from './material-planning.model';
import {environment} from '../../../../environments/environment';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaterialPlanningListService extends MaterialPlanningListAbstractService {
  constructor(private http: HttpClient) {
    super();
  }

  list(groupId: number, query?: any): Observable<MaterialSelectLister> {
    return this.http.get<MaterialSelectLister>(
      `${environment.apiv4uri}material/planning/list/${groupId}`,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}
