<form [formGroup]="form" (ngSubmit)="submit()" *ngIf="listBookedData.objects.length > 0">
  <div class="row">
    <div class="col-sm-12" formArrayName="groups">
      <h3>Abgeschlossene Positionen</h3>
      <material-order-intake-booked-group [group]="group"
                                          [indexG]="iG"
                                          [groups]="groups"
                                          [markedVariationId]="markedVariationId"
                                          [markedTransactionId]="markedTransactionId"
                                          [finderSupplierUri]="finderSupplierUri"
                                          [form]="group.form"
                                          [errors]="errors"
                                          (updateOrder)="updateView()"
                                          *ngFor="let group of formArrayMapped;
                                                  trackBy: trackByBookedFn;
                                                  let iG = index">
      </material-order-intake-booked-group>
    </div>
  </div>

  <div class="row" *ngIf="('material.order.price.check'|HasPerm)">
    <div class="col-sm-12 align-right">
      <button type="submit" class="btn btn-success" [disabled]="form.valid && submitLocked">
        Preise festlegen
      </button>
    </div>
  </div>
</form>
