<ng-container [ngSwitch]="type" *ngIf="history">
  <ng-container *ngIf="!!history.date">
    Datum:&nbsp;{{history.date}},
  </ng-container>
  <ng-container *ngIf="history.confirmed === true || history.confirmed === false">
    Bestätigt:&nbsp;<i class="fa"
                       [title]="history.confirmed === true ? 'ist Bestätigt' : 'ist NICHT Bestätigt'"
                       [ngClass]="{'fa-check': history.confirmed === true, 'fa-times': history.confirmed === false}"
                       *ngIf="history.confirmed === true || history.confirmed === false"></i>,
  </ng-container>
  <ng-container *ngIf="history.quantity">Menge: {{ history.quantity|money }},</ng-container>
  <ng-container *ngIf="history.delivery_method">
    Transport Art:&nbsp;<cn-order-delivery-method [deliveryMethod]="history.delivery_method"></cn-order-delivery-method>
  </ng-container>
</ng-container>
