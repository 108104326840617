<div class="modal-header">
  <h3 class="modal-title">Fehler</h3>
</div>

<div class="modal-body">
  <i class="fa fa-exclamation-triangle fa-2x red-font" aria-hidden="true"></i>
  <ng-container *ngIf="!!error">{{ error }}</ng-container>
  <ng-container *ngIf="!error">
    Es ist ein unbekannter Fehler aufgetreten, bitte wenden Sie sich an Ihren Administrator
  </ng-container>
</div>

<div class="modal-footer">
  <button class="btn btn-danger" (click)="close()">Ok</button>
</div>
