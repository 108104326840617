import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../../common/alert-service/alert.service';
import {StateService} from '@uirouter/angular';
import {GlobalService} from '../../../core/global.service';
import {Customer} from '../model/customer';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {OposListData} from '../../opos/opos.models';
import {OposService} from '../../opos/services/opos.service';
import {Money} from '../../../common/money/Money';
import Helper from '../../../helper/helper';
import {debounceTime} from 'rxjs/operators';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Component({
  selector: 'customer-opos',
  templateUrl: './customer-opos.component.html',
  styles: ['.invoices {width: 100%;}']
})
export class CustomerOposComponent implements OnInit, OnDestroy {
  @Input() opos: OposListData;
  init = false;
  errors: { [key: string]: any; } = {};
  customer: Customer;
  termDe: string;
  termCn: string;
  globalOposDate: Date | string | null;
  /** form */
  form: UntypedFormGroup;
  listForm: UntypedFormGroup;
  formSubscription: Subscription;
  httpSubscription: Subscription;
  /** totals */
  totalOpen?: string;
  totalRevenue?: string;
  totalExpensed?: string;
  totalExpensedInit?: number;
  totalExpensedNow?: string;

  constructor(private fb: UntypedFormBuilder,
              private globalService: GlobalService,
              private alertService: AlertService,
              private stateService: StateService,
              private oposService: OposService) {
  }

  ngOnInit(): void {
    this.customer = this.opos.customer;
    this.globalOposDate = this.globalService.getOposDate();
    this.termDe = this.customer.term_de || '14 Tage 2% - 30 Tage netto';
    this.termCn = this.customer.term_cn || '30 Tage netto';
    this.specialInit();

    this.form = this.fb.group({
      global_opos_date: [this.globalOposDate],
      invoices: [this.stateService.params.invoices]
    });
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(250)).subscribe(values => {
      // save opos global date
      if (values.global_opos_date !== this.globalOposDate) {
        this.globalService.setOposDate(values.global_opos_date);
        this.filter();
        this.globalOposDate = values.global_opos_date;
      }
    }, ignoreRejection);
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  save(): void {
    this.errors = {};
    const value1 = (this.listForm.controls.objects as UntypedFormArray).controls
      .filter((form: UntypedFormGroup) => !form.pristine)
      .map((form: UntypedFormGroup) => form.value);
    const value2 = (this.listForm.controls.corrections as UntypedFormArray).controls
      .filter((form: UntypedFormGroup) => !form.pristine)
      .map((form: UntypedFormGroup) => form.value);

    const data = {
      objects: value1,
      corrections: value2,
      date: this.form.value.global_opos_date
    };

    this.oposService.save(this.customer.id, this.form.value.invoices, data).subscribe(values => {
      this.opos = values;
      this.specialInit();
    }, response => {
      if (!response.error.error) {
        this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut!');
        this.errors = response.error;
      } else if (response.error.error === 'values') {
        this.alertService.add('warning', 'Rechnungen wurden schon abgerechnet, bitte versuchen Sie es erneut!');
        this.opos = response.error.data;
        // re-init all the new data
        this.specialInit();
      } else {
        this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut!');
      }
    });
    // this.specialInit();
  }

  filter(): void {
    this.killHttpSubscription();
    const filter = {
      date: this.form.value.global_opos_date,
      invoices: this.form.value.invoices
    };

    this.stateService.go(
      this.stateService.current,
      {id: this.customer.id, invoices: this.form.value.invoices},
      {reload: false}
      );

    this.httpSubscription = this.oposService.customer(this.customer.id, filter).subscribe(value => {
      this.httpSubscription = null;
      this.opos = value;
      this.specialInit();
    }, response => {
      this.httpSubscription = null;
      console.log('Error Response:', response);
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten!');
    });
  }

  isValid(): boolean {
    if (this.init && !!this.globalOposDate) {
      const v1 = (this.listForm.controls.objects as UntypedFormArray).controls
        .filter((value: UntypedFormGroup) => !value.pristine).length > 0;
      const v2 = (this.listForm.controls.corrections as UntypedFormArray).controls
        .filter((value: UntypedFormGroup) => !value.pristine).length > 0;

      let valid = v1 || v2;
      Helper.keys(this.errors, (obj) => {
        if (obj) {
          valid = false;
        }
      });
      return valid;
    } else {
      return false;
    }
  }

  updateTotals(name: string, open: string, expensed: string) {
    if (name === 'corrections') {
      this.opos.correction_total_open = open;
      this.opos.correction_total_expensed = expensed;
    } else {
      this.opos.total_open = open;
      this.opos.total_expensed = expensed;
    }
    this.calculateTotals();
  }

  focus(data: { index: number, keyCode: number, type: string, tableName: string }): void {
    const objectsLength = this.opos.objects.length;
    if (data.keyCode === 40 && data.tableName === 'objects' && data.index + 1 === objectsLength) {
      if (this.opos.corrections[0]) {
        this.opos.corrections[0]['focus_' + data.type] = true;
      }
    } else if (data.keyCode === 38 && data.tableName === 'corrections' && data.index === 0 && objectsLength - 1 >= 0) {
      if (this.opos.objects[objectsLength - 1]) {
        this.opos.objects[objectsLength - 1]['focus_' + data.type] = true;
      }
    }
  }

  private specialInit(): void {
    this.init = (this.opos.objects.length + this.opos.corrections.length) > 0;
    this.totalExpensedInit = Money.parse(this.opos.total_expensed);
    this.calculateTotals();
    this.initForm();
  }

  private initForm(): void {
    const invoicesFGs = this.opos.objects.map(value => {
      return this.fb.group({
        invoice_id: [value.invoice_id],
        expensed: [value.expensed],
        skonto: [value.skonto],
        skonto_total: [value.skonto_total],
        save: [false],
        changed: [false]
      });
    });

    const correctionsFGs = this.opos.corrections.map(value => {
      return this.fb.group({
        correction_id: [value.id],
        expensed: [value.expensed],
        skonto: [value.skonto],
        skonto_total: [value.skonto_total],
        save: [false],
        changed: [false]
      });
    });

    this.listForm = this.fb.group({
      objects: this.fb.array(invoicesFGs),
      corrections: this.fb.array(correctionsFGs)
    });
  }

  private calculateTotals() {
    // Calculate the Total Open
    const parsedTotalOpen = Money.parse(this.opos.total_open);
    const parsedCorrectionTotalOpen = Money.parse(this.opos.correction_total_open);
    this.totalOpen = Money.stringify(parsedTotalOpen + parsedCorrectionTotalOpen);
    // Calculate the Total Revenue
    const parsedTotalRevenue = Money.parse(this.opos.total_revenue);
    const parsedCorrectionTotalRevenue = Money.parse(this.opos.correction_total_revenue);
    this.totalRevenue = Money.stringify(parsedTotalRevenue + parsedCorrectionTotalRevenue);
    // Calculate the Total Expensed
    const parsedTotalExpensed = Money.parse(this.opos.total_expensed);
    const parsedCorrectionTotalExpensed = Money.parse(this.opos.correction_total_expensed);
    this.totalExpensed = Money.stringify(parsedTotalExpensed + parsedCorrectionTotalExpensed);
    this.totalExpensedNow = Money.stringify(parsedTotalExpensed + parsedCorrectionTotalExpensed - this.totalExpensedInit);
  }

  private killHttpSubscription(): void {
    if (this.httpSubscription) {
      this.httpSubscription.unsubscribe();
      this.httpSubscription = null;
    }
  }

}
