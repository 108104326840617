import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, EMPTY, from} from 'rxjs';
import {OfferService} from '../../workflow-services/offer.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {OfferInfoModalComponent} from './offer-info-modal.component';

@Injectable({providedIn: 'root'})
export class OfferInfoModalService {

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private offerService: OfferService) {
  }

  open(id: number): Observable<void> {
    return this.offerService.history(id).pipe(
      catchError(() => {
        this.alertService.add('danger', 'Angebots Information kann nicht geladen werden!');
        return EMPTY;
      }),
      mergeMap(val => {
        const modalRef = this.modalService.open(OfferInfoModalComponent, {windowClass: 'modal2-article'});
        modalRef.componentInstance.obj = val;
        return from(modalRef.result);
      })
    );
  }

}
