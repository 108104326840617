import * as React from 'react';
import {useCombobox, UseComboboxStateChange} from 'downshift';
import {MaterialSupplier} from '../../../lazy/material-supplier/material-supplier.model';
import {clsx} from 'clsx';
import {MaterialSupplierSelectHook} from './material-supplier-select.model';
import {useEffect} from 'react';

interface Props {
  state: MaterialSupplierSelectHook;
}

export function MaterialSupplierSelectCombobox({state: {state, setSupplier, getSuggestions}}: Props) {
  const onStateChange: (changes: UseComboboxStateChange<MaterialSupplier>) => void = (changes) => {
    switch (changes.type) {
      case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
      case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
        setSupplier(state.suppliers[changes.highlightedIndex]);
        return;
      case useCombobox.stateChangeTypes.InputClick:
        if (state.suppliers.length <= 0) {
          getSuggestions(changes.inputValue ?? '');
        }

        return;
      case useCombobox.stateChangeTypes.InputChange:
        getSuggestions(changes.inputValue ?? '');
        return;
      case useCombobox.stateChangeTypes.ItemClick:
        setSupplier(changes.selectedItem);
        return;
      default:
        return;
    }
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
  } = useCombobox<MaterialSupplier>({
    items: state.suppliers,
    initialInputValue: state.query ?? '',
    initialSelectedItem: state.selected ?? null,
    onStateChange: onStateChange,
    itemToString: supplier => supplier?.name?.trim() ?? '',
  });

  useEffect(() => {
    if (state.selected?.id !== selectedItem?.id) {
      selectItem(state.selected);
    }
  }, [state.selected]);

  return <>
    <div className="inline-block min-w-80" {...getMenuProps()}>
      <input className="form-control input-sm" {...getInputProps()} />
      {isOpen &&
        <div {...getMenuProps()} className="absolute bg-white p-2 z-10 shadow-md min-w-80 max-h-80 overflow-y-auto">
          {state.suggestionsLoading &&
            <div className="flex items-center justify-center p-2">
              <i className="fa fa-circle-o-notch fa-spin"/>
            </div>
          }
          {state.suppliers.map((materialSupplier, index) => (
            <div key={materialSupplier.id}
                 {...getItemProps({key: materialSupplier.id, index, item: materialSupplier})}
                 className={`p-2 ${clsx({
                   'text-blue-500': highlightedIndex === index,
                   'font-bold': selectedItem?.id === materialSupplier.id,
                 })}`}>
              {materialSupplier.name}
            </div>
          ))
          }
        </div>
      }
    </div>
  </>;
}
