<div class="modal-header">
  <div class="modal-title">
    <h2>Zu produzierende Abrufe bearbeiten</h2>
  </div>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-sm-12">
      <table class="table whitetable">
        <thead>
        <tr>
          <th>Abruf</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>Menge</th>
          <th>Spätester End-Termin</th>
          <th>Kunden Liefertermin</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let fetch of fetches; trackBy: trackByFn; let index = index">
          <td>{{ fetch.fetch.ordering }}</td>
          <td><span [ngClass]="'label label-' + fetch.fetch.status.label">{{ fetch.fetch.status.name }}</span></td>
          <td><span class="label label-danger" *ngIf="fetch.express">EILDIENST</span></td>
          <td>{{ fetch.position.quanity|money }}</td>
          <td>{{ fetch.last_work_day }}</td>
          <td>{{ fetch.fetch.delivery }}</td>
          <td>
            <button type="button"
                    class="btn btn-sm"
                    *ngIf="fetch.fetch.status.id === 2"
                    (click)="revert(fetch)">
              <i class="fa fa-history" aria-hidden="true"></i>
            </button>
            <button type="button"
                    class="btn btn-sm btn-success"
                    *ngIf="fetch.fetch.status.id === 1 && !fetch.selected"
                    (click)="toggle(fetch)">
              Hinzufügen
            </button>
            <button type="button"
                    class="btn btn-sm btn-danger"
                    *ngIf="fetch.selected"
                    (click)="toggle(fetch)">
              Entfernen
            </button>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-danger pull-left" (click)="dismiss()">
    Abbrechen
  </button>
  <button class="btn btn-sm btn-success pull-right" (click)="submit()" [disabled]="!selected">
    Übernehmen
  </button>
</div>
