import {EvConfirm} from '../../../../../react/modal/EvConfirm';
import * as React from 'react';

interface Props {
  response: (confirmed: boolean) => void;
}

export function ArticleMultilayerSaveBeforePdfModal(props: Props) {
  return <>
    <EvConfirm response={confirmed => (props.response(confirmed))}>
      <div className={'row'}>
        <div className={'col-sm-1'}>
          <i className={'fa fa-warning fa-2x'} style={{color: 'red'}}></i>
        </div>
        <div className={'col-sm-11'}>
            <strong>
              Soll jetzt gespeichert und dann die PDF erstellt werden?
            </strong>
        </div>
      </div>
    </EvConfirm>
  </>;
}
