import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({selector: '[oposFocus]'})
export class CustomerOposFocusDirective implements OnChanges {
  @Input('oposFocus') hasFocus: number | boolean;

  constructor(private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasFocus && !changes.hasFocus.isFirstChange()) {
      this.elementRef.nativeElement.focus();
    }
  }

}
