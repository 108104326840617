<ng-container [formGroup]="variationForm">
  <td>{{ variation ? variation.variation_id : currentArticle.article_id + ".???" }}</td>

  <td>
    <input [id]="'vendor_article_id' + index" type="text" formControlName="vendor_article_id" class="form-control">
  </td>

  <td>
    <input [id]="'vendor_price' + index" type="text" formControlName="vendor_price" class="form-control"
           [ngClass]="(error && error.error_type === 'VariationPriceParseError') ? 'red_alert' : ''">
  </td>

  <td>
    <select [id]="'unit' + index" formControlName="unit" class="form-control"
            [ngClass]="(error && error.error_type === 'VariationUnitError') ? 'red_alert' : ''">
      <option *ngFor="let unit of units" [value]="unit.unit">{{unit.unit}}</option>
    </select>
  </td>

  <td>
    <input [id]="'favorite' + index" type="checkbox" formControlName="favorite" class="form-control">
  </td>

  <td>
    <input [id]="'min_stock' + index" type="number" formControlName="min_stock" step="1" min="0" class="form-control">
  </td>

  <td>
    <input [id]="'min_order' + index"
           type="number"
           formControlName="min_order"
           step="1"
           min="0"
           class="form-control">
  </td>

  <td>
    <input [id]="'lead_time' + index" type="number" formControlName="lead_time" step="1" min="0" class="form-control">
  </td>

  <td>
    <input [id]="'ean' + index" type="text" formControlName="ean" class="form-control">
  </td>

  <ng-container formGroupName="variation_fields">
  <td [formGroupName]="field.html_name" *ngFor="let field of variationFieldDefs">
    <ng-container [ngSwitch]="field.getValueType()">
      <ng-container *ngSwitchCase="'choices'">
        <select class="form-control"
                [id]="field.html_name + '_' + index"
                formControlName="value">
          <option *ngFor="let choice of field.choices" [value]="choice">
            {{choice}}
          </option>
        </select>
      </ng-container>

      <ng-container *ngSwitchCase="'checkbox'">
        <input type="checkbox" [id]="field.html_name + '_' + index" formControlName="value">
      </ng-container>

      <ng-container *ngSwitchCase="'integer'">
        <input class="form-control"
               [id]="field.html_name + '_' + index"
               type="number"
               step="1"
               formControlName="value">
      </ng-container>

      <ng-container *ngSwitchCase="'float'">
        <input class="form-control"
               [id]="field.html_name + '_' + index" type="number"
               step="0.1"
               formControlName="value">
      </ng-container>

      <ng-container *ngSwitchDefault>
        <input class="form-control" [id]="field.html_name + '_' + index" type="text" formControlName="value">
      </ng-container>
    </ng-container>
  </td>
  </ng-container>
</ng-container>

<td class="icon">
  <ng-container *ngIf="variation">
    <a uiSref="a.material.inventory.update" [uiParams]="{variation: variation.id}">
      <i class="fa fa-cubes"></i>
    </a>
  </ng-container>
</td>
<td class="icon">
  <ng-container *ngIf="variation">
    <i class="fa fa-trash-o red_alert" (click)="deleteVariation()"></i>
  </ng-container>
  <ng-container *ngIf="!variation">
    <i class="fa fa-trash-o" (click)="deleteRow()"></i>
  </ng-container>
</td>
