import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EnvisiaCommon} from '../common';
import {DateRangeModule} from './date-range/date-range.module';
import {StatusLabelModule} from '../envisia/shared/status-label/status-label.module';

@NgModule({
  exports: [
    EnvisiaCommon,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateRangeModule,
    StatusLabelModule,
  ],
})
export class SharedModule {
}
