<ng-container *ngIf="goodpieces.data$|async as goodpieceData; else loadingOrError.template">
  <div class="row">
    <div class="col-lg-12">
      <table class="table whitetable business-order-view-table business-order-create">
        <tbody>
        <tr>
          <td><strong>Anzahl Fe-Nutzen BA:</strong></td>
          <td><strong>{{ goodpieceData.utilize_quantity_bo }}</strong></td>
        </tr>
        <tr *ngIf="goodpieceData.good_utilize !== null && goodpieceData.good_utilize !== undefined">
          <td><strong>Aktuelle Anzahl Fe-Nutzen BA:</strong></td>
          <td><strong>{{ goodpieceData.good_utilize }}</strong></td>
        </tr>
        <tr>
          <td><strong>Menge BA:</strong></td>
          <td><strong>{{ additional.quantity|money }}</strong></td>
        </tr>
        <tr class="frame-table-box">
          <td><strong>Gutstück Menge:</strong></td>
          <td>
            <strong *ngIf="('good_piece.change'|HasPerm)">
              <a class="click" (click)="openGoodPieceModal()">{{ goodpieceData.goodpieces_bo|money }}</a>
            </strong>
            <strong *ngIf="!('good_piece.change'|HasPerm)">
              {{ goodpieceData.goodpieces_bo|money }}
            </strong>
          </td>
        </tr>
        </tbody>

        <ng-container *ngIf="goodpieceData.has_reproductions">
        <tbody class="frame-table-box" *ngFor="let gp of goodpieceData.reproduction_sets">
          <tr [business-order-view-goodpiece-box-element]="gp.original"
              [business-order-view-goodpiece-box-element-current]="ba"
              [business-order-view-goodpiece-box-element-reproduction]="null"
              [ngClass]="{'blue-child': gp.requested}"
              *ngIf="gp.original.status < 100 && !gp.original.date_deleted && !gp.original.date_storno">
          </tr>
          <tr [business-order-view-goodpiece-box-element]="bo"
              [business-order-view-goodpiece-box-element-current]="ba"
              [business-order-view-goodpiece-box-element-reproduction]="bo.reproduction"
              [ngClass]="{'blue-child': gp.requested}"
              *ngFor="let bo of gp.reproductions">
          </tr>
        </tbody>
        </ng-container>

        <tfoot>
        <tr class="red-child" *ngIf="goodpieceData.has_reproductions">
          <td><strong>Gutstück Menge incl. Nachfertigung:</strong></td>
          <td>
            <strong>
              {{ goodpieceData.goodpieces_reproduction|money }}
            </strong>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</ng-container>
<loading-or-error #loadingOrError
                  [loadingWrapper]="goodpieces"
                  [errorMessage]="'Konnte Nutzeninformation nicht laden'">
</loading-or-error>
