import {Component, Input, OnInit} from '@angular/core';
import {MaterialPlanningListElement} from '../../../material/planning/material-planning.model';
import {
  ArticleMultilayerPlan
} from './article-multilayer-plan.models';
import {Article} from '../../models/article.models';
import {ArticleSpecification} from '../../models/article-specification.model';
import {
  MultilayerPlanHolderAbstractComponentDirective
} from '../../../../lazy/multilayer-plan/multilayer-plan-holder-abstract-component.directive';
import {ArticleMultilayerPlanHelper} from './article-multilayer-plan-helper';
import {ArticleMultilayerPlanCopyService} from './copy-select/article-multilayer-plan-copy.service';
import {ignoreRejection} from '../../../../helper/ignore_rejection';

@Component({
  selector: 'article-multilayer-plan-holder',
  templateUrl: './article-multilayer-plan-holder.component.html',
})
export class ArticleMultilayerPlanHolderComponent
  extends MultilayerPlanHolderAbstractComponentDirective
  implements OnInit {
  @Input() multilayerPlan?: ArticleMultilayerPlan;
  @Input() article: Article;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() materialList: MaterialPlanningListElement[] = [];

  constructor(private copyModalService: ArticleMultilayerPlanCopyService) {
    super();
  }

  ngOnInit() {
    this.model = this.multilayerPlan?.data ?? ArticleMultilayerPlanHelper.emptyModel();
  }

  modelSaved(plan: ArticleMultilayerPlan): void {
    this.multilayerPlan = plan;
    this.model = this.multilayerPlan.data;
  }

  generatedSds(value: Article): void {
    value.calc_cn = value.calc_chn;
    this.article = value;
  }

  generatedSister(value: Article): void {
    value.calc_cn = value.calc_chn;
    this.article = value;
  }

  openCopyModal(): void {
    this.copyModalService.open().subscribe(multiLayerPlan => {
      this.modelChanged(multiLayerPlan.data);
    }, ignoreRejection);
  }
}
