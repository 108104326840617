<div class="business-order-create">

  <div class="row">

    <div class="col-lg-12">
      <a [uiParams]="{id: workflow_id}" uiSref="a.workflow.detail">
        <h3><i class="fa fa-chevron-left"></i> Vorgang {{ workflow_id }}</h3>
      </a>
    </div>

  </div>

  <div class="row">
    <!-- Nested -->
    <div class="col-lg-7">

      <!-- Header -->
      <div class="row">
        <div class="col-lg-6">
          <h1>Betriebsauftrag erstellen für</h1>
        </div>
        <div *ngIf="this.fullData.frame" class="col-lg-6">
          <a (click)="openProductionFetches()" class="bold click pull-right">
            Zu produzierende Abrufe bearbeiten
          </a>
        </div>
      </div>

      <business-order-frame-table [fetchesMin]="fetchesMin" [fetches]="fetches">
      </business-order-frame-table>

      <div class="row">
        <div class="col-lg-12">&nbsp;</div> <!-- Whitespace Row -->
      </div>

      <div class="row">
        <div class="col-lg-12">&nbsp;</div>
      </div>

      <div class="row">
        <div class="col-lg-12">&nbsp;</div>
      </div>

      <business-order-article-box (reloadInventory)="reloadInventory()"
                                  [article]="article"
                                  [extra]="fullData.extra"
                                  [hasExpress]="hasExpress()"
                                  [inventory]="inventory"
                                  [quantity]="quantity"
                                  [workflowId]="workflow_id">
      </business-order-article-box>

      <div class="row">
        <div class="col-lg-12">&nbsp;</div>
      </div>

      <div class="row">
        <div class="col-lg-12">&nbsp;</div>
      </div>

      <customer-credit-worthiness-alert [customerCredit]="fullData.customerCredit">
        <ng-template>
          <ng-container *ngIf="!reproductionSelected">
            <business-order-form (reloadInventory)="reloadInventory()"
                                 (update)="updateBusinessOrders()"
                                 (updateQuantity)="updateQuantity($event)"
                                 [article]="article"
                                 [fetchesMin]="fetchesMin"
                                 [fetches]="fetches"
                                 [frame]="frame ? frame : null"
                                 [hasBusinessOrders]="this.fullData.business_order_count > 0"
                                 [inventory]="inventory.amount_regular"
                                 [plistEmpty]="plistEmpty"
                                 [startNumber]="startNumber"
                                 [validHash]="fullData.validArticle"
                                 [shippingInstructions]="fullData.extra"
                                 [multilayerPlan]="fullData.multilayerPlan"
                                 [workflowId]="workflow_id">
            </business-order-form>
          </ng-container>

          <ng-container *ngIf="!!reproductionSelected">
            <business-order-create-reproduction [selected]="reproductionSelected.business_order"
                                                [date]="reproductionSelected.date"
                                                [article]="article"
                                                [fetchesMin]="fetchesMin"
                                                [fetches]="fetches"
                                                [frame]="frame ? frame : null"
                                                [hasBusinessOrders]="this.fullData.business_order_count > 0"
                                                [inventory]="inventory.amount_regular"
                                                [plistEmpty]="plistEmpty"
                                                [validHash]="fullData.validArticle"
                                                [shippingInstructions]="fullData.extra"
                                                [workflowId]="workflow_id"
                                                [multilayerPlan]="fullData.multilayerPlan"
                                                (reloadInventory)="reloadInventory()"
                                                (update)="updateBusinessOrders()"
                                                (updateQuantity)="updateQuantity($event)"
                                                (closeForm)="reproductionSelect(null)">
            </business-order-create-reproduction>
          </ng-container>
        </ng-template>
      </customer-credit-worthiness-alert>

      <div class="row">
        <div class="col-lg-12">&nbsp;</div>
      </div>

      <div class="row">
        <div class="col-lg-12">&nbsp;</div>
      </div>

      <business-order-article-list (reloadInventory)="reloadInventory()"
                                   (updateSum)="updateSum($event)"
                                   (reproductionSelect)="reproductionSelect($event)"
                                   [article]="article"
                                   [businessOrders]="this.fullData.business_order"
                                   [fetches]="fetchesSmall"
                                   [changes]="businessOrderChanges"
                                   [empb]="empb"
                                   [kanban]="kanban"
                                   [workflowId]="workflow_id">
      </business-order-article-list>
    </div>

    <div class="col-lg-3">
      <frame-box [frame]="extendedFrame()"
                 [lastOffer]="lastOffer"
                 (frameUpdatedEvent)="updateComments()">
      </frame-box>

      <canban-box *ngIf="frame && frame.lose > 1"
                  [article]="article.oa_nr"
                  [data]="fullData.additional"
                  [delivered]="delivered"
                  [inventory]="inventory.amount_regular"
                  [isCn]="isCn"
                  [lose]="frame.lose"
                  [producer]="frame.producer"
                  [workflowId]="fullData.frame.frame.workflow_id">
      </canban-box>

      <delivery-condition-box [extra]="fullData.extra"></delivery-condition-box>
    </div>

    <div class="col-md-2">
      <comment [comments]="comments" [item]="workflow_id" placement="bottom" typ="workflow"></comment>
    </div>

  </div>

</div>
