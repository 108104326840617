<div class="modal-header">
  <h3 class="modal-title">Artikel Änderungsstatus anpassen</h3>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-lg-12">
      <button class="btn btn-default article-state-red full-width" (click)="changeState('red')"
              [disabled]="article.change_state === 'red'">
        Rot
      </button>
    </div>

    <div class="col-lg-12">
      &nbsp;
    </div>

    <div class="col-lg-12">
      <button class="btn btn-default article-state-orange full-width" (click)="changeState('orange')"
              [disabled]="article.change_state === 'orange'">
        Orange
      </button>
    </div>

    <div class="col-lg-12">
      &nbsp;
    </div>

    <div class="col-lg-12">
      <button class="btn btn-default article-state-green full-width" (click)="changeState('green')"
              [disabled]="article.change_state === 'green'">
        Grün
      </button>
    </div>
  </div>

</div>

<div class="modal-footer">

  <button type="button" class="btn btn-default pull-left" (click)="close()">
    Abbrechen
  </button>

</div>
