import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialOrderIntakeService} from '../material-order-intake.service';
import {MaterialOrderIntakePrintModalComponent} from './material-order-intake-print-modal.component';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class MaterialOrderIntakePrintModalService {

  constructor(private modalService: NgbModal,
              private ts: MaterialOrderIntakeService) {
  }

  open(orderId: number, index: number, transactionId?: number): void {
    this.ts.listTransactions(orderId, index).subscribe(transactions => {
      const modalRef = this.modalService.open(MaterialOrderIntakePrintModalComponent, {windowClass: 'modal2-mg'});
      modalRef.componentInstance.transactions = transactions;
      modalRef.componentInstance.markedTransactionId = transactionId;
    }, (error: HttpErrorResponse) => {
      console.log('Material order intake transaction modal error', error);
    });
  }
}
