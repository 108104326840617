<div class="modal-header">
  <h3 class="modal-title">Achtung!</h3>
</div>

<div class="modal-body">
  <p>Möchten Sie wirklich den Status ändern?</p>
  <div class="status-change">
    <table>
      <tbody>
        <tr>
          <td><button type="button" class="btn btn-primary" (click)="save('approved')">Bestätigt</button></td>
          <td><button type="button" class="btn btn-purple" (click)="save('production')">In Produktion</button></td>
          <td><button type="button" class="btn btn-rose" (click)="save('produced')">Produziert</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="pull-left btn btn-default" (click)="closeModal()">
    Abbrechen
  </button>
</div>
