<div class="row" [formGroup]="form">
  <div class="col-sm-8">
    <select class="form-control input-sm" id="group" formControlName="group">
      <option [ngValue]="null" selected disabled hidden>&nbsp;</option>
      <option [ngValue]="group" *ngFor="let group of groups">
        {{group.group_id}} {{group.name}}
      </option>
    </select>
  </div>
  <div class="col-sm-4">
    <button type="button"
            class="btn btn-default"
            [disabled]="disabled || form.value.group === null"
            (click)="addGroupManually()">
      Gruppe hinzufügen
    </button>
  </div>
</div>
