<form class="form-horizontal small-form" [formGroup]="topForm">

  <div class="article-head" [ngClass]="{
    'article-head-alert-warning': article?.date_deleted,
    'article-head-alert-danger': article?.locked
  }">

    <div class="article-head-col">
      <div *ngIf="create" class="article-form-item article-fake-sds-field">
        <!-- Fake SDS Field for create-->
        <label class="article-form-item-label bold">&nbsp;</label>
        <div class="article-form-item-data data-group width-165">
          <div class="article-form-data-content">
            &nbsp;
          </div>
        </div>
      </div>
      <div *ngIf="!create" class="article-form-item">
        <label class="article-form-item-label bold">
          <i class="fa fa-trash-o" *ngIf="article?.date_deleted"></i>
          <i class="fa fa-lock" *ngIf="article?.locked"></i>
          SDS-Nr.:
        </label>
        <div class="article-form-item-data data-group width-165">
          <div class="article-form-data-content">

            <article-top-sds-input formControlName="sds_nr"
                                   [error]="errors"
                                   (changeEmitter)="sdsCheck('sds_nr')"
                                   [sdsDisabled]="disabledSds">
            </article-top-sds-input>

          </div>
          <div class="article-form-data-content" *ngIf="article?.sister">
            <div class="article-form-data-unit-block article-form-data-unit-block-border click"
                 (click)="redirectSdsNr(article.sister)">
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </div>
          </div>
          <div class="article-form-data-content">
            <ng-container *ngIf="!create">
              <ng-container *ngIf="article as validArticle">
                <div class="article-form-data-unit-block click"
                     *ngIf="!create"
                     (click)="openStateChangeModal(validArticle)"
                     [ngClass]="'article-state-' + validArticle.change_state + (stateChangeValid(validArticle) ? ' click' : '')">
                  {{ validArticle.oa_nr }}
                </div>
              </ng-container>
            </ng-container>

          </div>

          <div class="loading" *ngIf="loading.sds_nr">
            <div class="loading-content">
              <div class="loading-content-icon">
                <i class="fa fa-circle-o-notch fa-spin"></i>
              </div>
              <div class="loading-content-text">
                SDS-Nr. wird geprüft
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="article-form-item article-form-item-customer" *ngIf="!create">
        <label for="article-customer-name" class="article-form-item-label">Kunde:</label>

        <div class="article-form-item-data width-180">
          <div class="article-form-data-content">

            <span id="article-customer-name">{{ article?.customer }}</span>
          </div>
        </div>
      </div>
    </div>


    <div class="article-head-col">
      <div class="article-form-item">
        <label for="article_kd_art_nr" class="article-form-item-label">Kunden-Art-Nr:</label>

        <div class="article-form-item-data">
          <div class="article-form-data-content">
            <input type="text" class="form-control"
                   id="article_kd_art_nr"
                   formControlName="kd_art_nr"
                   (change)="sdsCheck('kd_art_nr')"
                   [class.red_alert]="errors">
          </div>
          <div class="loading" *ngIf="loading.kd_art_nr">
            <div class="loading-content">
              <div class="loading-content-icon">
                <i class="fa fa-circle-o-notch fa-spin"></i>
              </div>
              <div class="loading-content-text">
                Kunden-Art-Nr. wird geprüft
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="article-form-item">
        <label for="article-kd-art-index" class="article-form-item-label">Kunden-Index:</label>

        <div class="article-form-item-data">
          <div class="article-form-data-content">
            <input type="text" class="form-control"
                   id="article-kd-art-index"
                   (change)="sdsCheck('kd_art_index')"
                   formControlName="kd_art_index"
                   [class.red_alert]="errors">
          </div>

          <div class="margin-left-10">
            <i class="fa fa-2x fa-spin fa-spinner" *ngIf="loading.kd_art_index"></i>
          </div>
        </div>
      </div>


    </div>

    <div class="article-head-col">
      <div class="article-form-item">
        <label for="article-kd-art-extend1" class="article-form-item-label">Kundenbez 1:</label>

        <div class="article-form-item-data">
          <div class="article-form-data-content">
            <input type="text" class="form-control" id="article-kd-art-extend1" formControlName="kd_art_extend1">
          </div>
        </div>
      </div>

      <div class="article-form-item">
        <label for="article-kd-art-extend2" class="article-form-item-label">Kundenbez 2:</label>

        <div class="article-form-item-data">
          <div class="article-form-data-content">
            <input type="text" class="form-control" id="article-kd-art-extend2" formControlName="kd_art_extend2">
          </div>
        </div>
      </div>
    </div>

    <div class="article-head-col">

      <div class="article-head-note">
        <label for="article-note"></label>
        <textarea class="form-control" formControlName="article_note" rows="5" id="article-note"></textarea>
      </div>
    </div>

  </div>

</form>
