import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {CalculationListComponent} from './calculation-list.component';
import {CalculationTableComponent} from './calculation-table.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CALCULATION_STATES} from './calculation.states';
import {CalculationCopyModalComponent} from './calculation-copy-modal.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {CalculationListFormComponent} from './calculation-list-form.component';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: CALCULATION_STATES }),
        SharedModule,
        NgbTooltipModule,
        NgbModalModule,
    ],
    declarations: [
        CalculationListComponent,
        CalculationTableComponent,
        CalculationCopyModalComponent,
        CalculationListFormComponent
    ],
    exports: [CalculationCopyModalComponent]
})
export class CalculationModule {
}
