import {Component, Input} from '@angular/core';
import {ArticleHistoryUserData} from './article-history.models';

@Component({
  selector: 'article-preview-modal',
  templateUrl: './article-preview.modal.component.html',
})
export class ArticlePreviewModalComponent {

  @Input() data: ArticleHistoryUserData;

  trackByFn(index: number, item: string) {
    return index;
  }

}
