import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, from} from 'rxjs';
import {OfferHistoryModalComponent} from './offer-history-modal.component';

@Injectable({providedIn: 'root'})
export class OfferHistoryModalService {

  constructor(private modalService: NgbModal) {
  }

  open(wid: number, article: string, positionData: any, send: any, header = null, refresh = null): Observable<any> {
    const modalRef = this.modalService.open(OfferHistoryModalComponent, {windowClass: 'modal2-lg'});
    modalRef.componentInstance.workflow_id = wid;
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.positionData = positionData;
    modalRef.componentInstance.send = send;
    modalRef.componentInstance.refresh = refresh;
    modalRef.componentInstance.header = header;
    return from(modalRef.result);
  }

}
