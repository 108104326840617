<div class="row" *ngIf="weightLoader.data$|async as weight">
  <ng-container *ngIf="weight.board !== 0">
    <div class="col-md-12">
      <h4>Leiterplatten Gewicht</h4>
      <div class="article-weight-inline article-weight-margin">
        Einzel LP: <strong>{{weight.board}}</strong>
      </div>
      <div class="article-weight-inline article-weight-margin">
        Liefer-Nutzen: <strong>{{weight.utilize}}</strong>
      </div>
      <div class="article-weight-inline">
        Gesamt: <strong>{{weight.total}}</strong>
      </div>
    </div>
  </ng-container>
</div>
