/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {BusinessOrderGoodPieceService} from '../services/business-order-good-piece.service';
import {StateService} from '@uirouter/angular';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {BusinessOrder} from '../models/business-order.models';


@Component({
  selector: 'business-order-good-piece',
  templateUrl: './business-order-good-piece.component.html',
})
export class BusinessOrderGoodPieceComponent implements OnInit {
  @Input() ba: BusinessOrder;
  form: UntypedFormGroup;

  constructor(private modalRef: NgbActiveModal,
              private goodPieceService: BusinessOrderGoodPieceService,
              private fb: UntypedFormBuilder,
              private stateService: StateService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      good_piece: [this.ba.good_piece, Validators.compose([
        Validators.required,
        Validators.min(0),
      ])],
      comment: [null, Validators.required],
    });
  }

  submit(): void {
    const data = this.form.value;
    const goodPiece = data['good_piece'];
    const comment = data['comment'];
    this.goodPieceService.update(this.ba.id, this.ba.index, goodPiece, comment).subscribe(response => {
      this.alertService.add('success', 'Gutstückzahl wurde erfolgreich geändert!');
      this.modalRef.close(response);
      this.stateService.reload(this.stateService.$current).then(ignoreRejection, ignoreRejection);
    }, response => {
      if (response.error.object === 'undefined') {
        this.alertService.add('danger', 'Gutstückmenge konnte nicht angepasst werden.');
      } else {
        this.alertService.add('danger', response.error.object);
      }
    });
  }

}
