<div class="modal-header">
  <h3>Produktionsschritt editieren</h3>
</div>

<form class="form-horizontal" [formGroup]="editForm">
  <div class="modal-body">

    <div class="form-group">
      <label for="production-step-nr" class="col-sm-2 control-label">Nr.</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="production-step-nr" formControlName="nr"
               [ngClass]="{red_alert: errors['obj.nr']}" [autofocus]>
      </div>
    </div>

    <div class="form-group">
      <label for="production-step-text" class="col-sm-2 control-label">Bezeichnung</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="production-step-text" formControlName="text"
               [ngClass]="{red_alert: errors['obj.text']}">
      </div>
    </div>

    <div class="form-group">
      <label for="production-step-duration" class="col-sm-2 control-label">Dauer</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="production-step-duration" formControlName="duration"
               [ngClass]="{red_alert: errors['obj.duration']}">
      </div>
    </div>

    <div class="form-group">
      <label for="production-step-significance" class="col-sm-2 control-label">Wertigkeit</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="production-step-significance" formControlName="significance"
               [ngClass]="{red_alert: errors['obj.significance']}">
      </div>
    </div>


    <div class="form-group">
      <label for="production-step-max-duration" class="col-sm-2 control-label">Max-Dauer</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="production-step-max-duration" formControlName="max_duration"
               [ngClass]="{red_alert: errors['obj.max_duration']}">
      </div>
    </div>

    <div class="form-group">
      <label for="production-step-min-duration" class="col-sm-2 control-label">Min-Dauer</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="production-step-min-duration" formControlName="min_duration"
               [ngClass]="{red_alert: errors['obj.min_duration']}">
      </div>
    </div>

    <div class="form-group">
      <label for="production-step-booking-type" class="col-sm-2 control-label">Materialbuchung</label>
      <div class="col-sm-10">
        <select type="checkbox" class="form-control" id="production-step-booking-type" formControlName="booking_type"
               [ngClass]="{red_alert: errors['obj.booking_type']}">
          <option [ngValue]="0">Keine Materialbuchung</option>
          <option [ngValue]="1">Materialbuchung möglich</option>
          <option [ngValue]="2">Materialbuchung Pflicht</option>
          <option [ngValue]="3">Basismaterial Buchung</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label for="production-step-drill-op-code-type" class="col-sm-2 control-label">Bohr Barcode</label>
      <div class="col-sm-10">
        <select type="checkbox"
                class="form-control"
                id="production-step-drill-op-code-type"
                formControlName="drill_op_code_type"
                [ngClass]="{red_alert: errors['obj.drill_op_code_type']}">
          <option [ngValue]="null" [defaultSelected]="true">Kein Barcode</option>
          <option [ngValue]="1">Bohrung ohne DMC Parameter</option>
          <option [ngValue]="2">Bohrung mit DMC Parameter</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label for="production-step-drill-op-code" class="col-sm-2 control-label">Bohr Index</label>
      <div class="col-sm-10">
        <input type="text"
               id="production-step-drill-op-code"
               class="form-control"
               maxlength="2"
               formControlName="drill_op_code"
               [ngClass]="{red_alert: errors['obj.drill_op_code']}" />
      </div>
    </div>

    <div class="form-group">
      <label for="production-step-use-good-pieces" class="col-sm-2 control-label">Ausbuchung</label>
      <div class="col-sm-10">
        <input type="checkbox"
               id="production-step-use-good-pieces"
               formControlName="use_good_pieces"
               [ngClass]="{red_alert: errors['obj.use_good_pieces']}">&nbsp;<label
        for="production-step-use-good-pieces">
        Gutstück Abfrage, anstatt Ausschuss
      </label>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-success pull-right" (click)="edit()" [disabled]="editForm.pristine || !editForm.valid">
      Speichern
    </button>
  </div>
</form>
