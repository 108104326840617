<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Lagerung
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="row">
    <div class="col-md-8">
      <article-extra-text formControlName="storage_time_max"
                          unit="Wochen"
                          type="int"
                          class="article-form-item-extra-long">
        Max. Lagerzeit
      </article-extra-text>
    </div>
    <div class="col-md-4">
      <article-extra-text formControlName="storage_time_other" class="article-form-item-reduced">
        &nbsp;
      </article-extra-text>
    </div>
  </div>
</div>
