<ng-container [formGroup]="form">
  <td>
    <material-order-position-status [id]="position.position_id"
                                    [status]="position.status"
                                    [edit]="false"
                                    [deleted]="position.deleted">
    </material-order-position-status>
  </td>
  <td class="align-right">{{position.index}}</td>
  <td class="align-right">
    <span *ngIf="position.delivery_index === 0">1</span>
    <strong *ngIf="position.delivery_index > 0">{{position.delivery_index + 1}}</strong>
  </td>
  <td *ngIf="groupPermitted">
    <a uiSref="a.material.inventory.update" target="_blank" [uiParams]="{variation: position.id}">
      {{position.variation_id}}
    </a>
  </td>
  <td *ngIf="!groupPermitted">
    {{position.variation_id}}
  </td>
  <td>{{position.vendor_article_id}}</td>
  <td>{{position.article_name}}</td>
  <td>{{position.article_name_order}}</td>
  <td [ngClass]="{'align-right': field.isValueType('Number')}" *ngFor="let field of fields">
    <ng-container [ngSwitch]="field.data_type" *ngIf="!(position.fields[field.html_name]|isNull)">
      <ng-container *ngSwitchCase="'Option'">
        <i class="fa" [ngClass]="{
          'fa-check':(position.fields[field.html_name] === true),
          'fa-times': (position.fields[field.html_name] === false)}"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'Ganzzahl'">
        {{ position.fields[field.html_name] | money:0 }}
      </ng-container>
      <ng-container *ngSwitchCase="'Kommazahl'">
        {{ position.fields[field.html_name] | money }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ position.fields[field.html_name] }}
      </ng-container>
    </ng-container>
  </td>
  <td class="align-right" *ngIf="!edit">
    {{position.amount_ordered|money}}
    <span style="font-size: 8px"
          *ngIf="position.amount_ordered_first_contact && (position.amount_ordered !== position.amount_ordered_first_contact)">
      <br/>{{position.amount_ordered_first_contact|money}}
    </span>
  </td>
  <td *ngIf="edit">
    <input type="number"
           class="form-control align-right"
           formControlName="amount_ordered"
           [id]="'amount_ordered' + position.index">
  </td>
  <td>{{position.unit}}</td>
  <td *ngIf="!edit">
    <ng-container *ngIf="position.date_delivery">{{position.date_delivery | date:'dd.MM.yyyy'}}</ng-container>
    <ng-container *ngIf="!position.date_delivery">-</ng-container>
    <span style="font-size: 8px" *ngIf="position.date_delivery_first_contact &&
                         (position.date_delivery !== position.date_delivery_first_contact)">
      <br />{{position.date_delivery_first_contact | date:'dd.MM.yyyy'}}
    </span>
  </td>
  <td *ngIf="edit">
    <ev-datepicker class="form-control"
                   [id]="'date_delivery' + position.index"
                   [parser]="dateParser"
                   [coreFormat]="true"
                   [writeBack]="false"
                   formControlName="date_delivery"
                   [allDates]="true">
    </ev-datepicker>
  </td>
  <td class="align-right" *ngIf="!edit">
    <ng-container *ngIf="position.price">{{position.price|money:2}}&nbsp;€</ng-container>
    <ng-container *ngIf="!position.price">-</ng-container>
  </td>
  <td class="align-right" *ngIf="edit">
    <input class="form-control align-right" type="text" formControlName="price" [id]="'price_' + position.index">
  </td>
  <td *ngIf="!edit">
    {{position.note}}
  </td>
  <td *ngIf="edit">
    <input class="form-control" [id]="'note_' + position.index" formControlName="note">
  </td>
  <td class="icon" *ngIf="('material.order.create'|HasPerm) && !order.date_deleted">
    <a class="click"
       title="Position bearbeiten"
       (click)="setEdit(true)"
       *ngIf="!edit && !position.deleted && groupPermitted">
      <i class="fa fa-pencil row-icon"></i>
    </a>
    <a class="click"
       title="Änderung speichern"
       (click)="update()"
       *ngIf="edit && !position.deleted">
      <i class="fa fa-floppy-o row-icon"></i>
    </a>
  </td>
  <td class="icon" *ngIf="('material.order.create'|HasPerm) && !order.date_deleted">
    <a class="click"
       title="Abbrechen"
       (click)="setEdit(false)"
       *ngIf="edit && !position.deleted">
      <i class="fa fa-times red_font row-icon"></i>
    </a>
  </td>
  <td class="icon" *ngIf="!order.date_deleted">
    <a title="Position kopieren"
       [ngClass]="{click: !lockCopy}"
       (click)="copy()"
       *ngIf="groupPermitted">
      <i class="fa fa-plus row-icon" [ngClass]="{green: !lockCopy, gray_font: lockCopy}"></i>
    </a>
  </td>
  <td class="icon" *ngIf="!order.date_deleted">
    <a class="click"
       [title]="toggleConfirmTooltip"
       (click)="toggleConfirmState()"
       *ngIf="position.status < 2">
      <i class="fa row-icon" [ngClass]="toggleConfirmIconClass"></i>
    </a>
  </td>
  <td class="icon" *ngIf="('material.order.create'|HasPerm)">
    <a class="click"
       title="Position löschen"
       (click)="delete()"
       *ngIf="!position.deleted && (position.transactions.length === 0) && groupPermitted">
      <i class="fa fa-trash-o red_font row-icon"></i>
    </a>
    <a class="click"
       title="Position wiederherstellen"
       (click)="deleteUndo()"
       *ngIf="position.deleted && !order.date_deleted && groupPermitted">
      <i class="fa fa-undo row-icon"></i>
    </a>
  </td>
</ng-container>
