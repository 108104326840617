/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {EnvisiaLocation} from '../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class ErpTransition {

  constructor(private stateService: StateService) {
  }

  params(): { [paramName: string]: any; } {
    // we need to get the current transition
    // so that the correct params are there
    const transition = this.stateService.transition;
    return EnvisiaLocation.decodeHttpParams(transition.params());
  }

}
