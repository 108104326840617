import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as React from 'react';
import {MultilayerPlanUpdate} from './multilayer-plan-update';
import {
  ArticleMultilayerPlan,
  ArticleMultilayerPlanModel,
} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.models';
import {ArticleSpecification} from '../../envisia/article/models/article-specification.model';
import {ReactBaseComponent} from '../../react/base/react-base.component';

const containerElementName = 'reactMultilayerPlanContainer';

@Component({
  selector: 'multilayer-plan-update',
  template: `<div #${containerElementName}></div>`,
})
export class MultilayerPlanUpdateComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() multilayerPlan: ArticleMultilayerPlan;
  @Input() changes: number;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() saveBtnPressed = 0;
  @Output() saveBtnLocked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modelChanged: EventEmitter<ArticleMultilayerPlanModel> = new EventEmitter<ArticleMultilayerPlanModel>();
  @Output() modelSaved: EventEmitter<ArticleMultilayerPlan> = new EventEmitter<ArticleMultilayerPlan>();

  constructor() {
    super();
  }

  protected override renderComponent() {
    const _ = React.version;
    return <MultilayerPlanUpdate model={this.multilayerPlan}
                                 modelChanged={m => this.modelChanged.emit(m)}
                                 specification={this.specification}
                                 saveBtnPressed={this.saveBtnPressed}
                                 saveBtnLocked={sbc => this.saveBtnLocked.emit(sbc)}
                                 modelSaved={m => this.modelSaved.emit(m)}
    />;
  }

}
