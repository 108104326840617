import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Article} from '../../models/article.models';
import {InventoryGroup} from '../inventory.models';

@Component({
  selector: 'inventory-storage-note-form',
  templateUrl: './inventory-storage-note-form.component.html',
  styles: ['.mb-10 { margin-bottom:10px; }']
})
export class InventoryStorageNoteFormComponent {
  @Input() article: Article;
  @Input() inventory: InventoryGroup;
  @Input() form: UntypedFormGroup;
  @Input() errors: { [key: string]: any } = {};

  constructor() {
  }
}
