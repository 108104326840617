<div class="page-header">
  <h3>Feiertage</h3>
</div>

<div class="row">
  <div class="col-md-12">
    <button type="button" class="btn btn-success" (click)="templateCreate()">
      Feiertag eintragen
    </button>
  </div>
  <div class="col-md-12" style="margin-top: 30px;">
    <input type="file" #fileInput placeholder="Upload file..." accept=".xlsx" />
  </div>
  <div class="col-md-12" style="margin-top: 10px;">
    <button type="button" class="btn btn-success" (click)="upload()">
      XLSX Upload
    </button>
    <div>
      Bitte laden Sie die Datei im <b>xlsx</b>-Format hoch.
      <b>A-Spalte:</b> Nur Datum (Datumsformat wie z.B. "04/02/2018").
      <b>B-Spalte:</b> Kommentare (optional, Text z.B. "Ostermontag").
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    &nbsp;
  </div>
</div>

<div class="row">
  <div class="col-md-8">
    <table class="table whitetable chrissies">
      <thead>
      <tr>
        <th>Datum</th>
        <th>Kommentar</th>
        <th>Erstellt</th>
        <th>User</th>
        <th>L&ouml;schen</th>
      </tr>
      </thead>

      <tbody infiniteScroll [infiniteScrollDisabled]="locked">
      <tr *ngFor="let obj of objects">
        <td>
          {{ obj.date }}
        </td>
        <td>
            {{ obj.comment }}
        </td>
        <td>
          {{ obj.date_created }}
        </td>
        <td>
          {{ obj.username }}
        </td>
        <td class="icon">
          <button class="btn btn-link btn-sm" style="color: red;" (click)="del(obj.id)">
            <i class="fa fa-trash-o"></i>
          </button>
        </td>
        <td>
        </td>
      </tr>
      </tbody>

    </table>

    <div>
      <div *ngIf="busy"><i class="fa fa-spin fa-spinner"></i> neue Daten laden</div>
    </div>

  </div>

</div>
