import * as React from 'react';
import {EvConfirm} from '../../../../../react/modal/EvConfirm';

interface Props {
  response: (confirmed: boolean) => void;
}

export function ArticleMultilayerSaveBeforeRedirectModal(props: Props) {
  return <>
    <EvConfirm response={confirmed => (props.response(confirmed))}>
      <div className={'row'}>
        <div className={'col-sm-1'}>
          <i className={'fa fa-warning fa-2x'} style={{color: 'red'}}></i>
        </div>
        <div className={'col-sm-11'}>
          <p>
            <strong>
              Soll jetzt gespeichert werden?
            </strong>
          </p>
          <p>
            Sie werden danach zur Übersicht weitergeleitet.
          </p>
        </div>
      </div>
    </EvConfirm>
  </>;
}
