import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {OfferAnalysisComponent} from './offer-analysis.component';
import {OfferAnalysisQueryParams} from './offer-analysis.model';
import {OfferAnalysisParamHelper} from './offer-analysis-param-helper';

function queryParamsResolveFn(transition: Transition): OfferAnalysisQueryParams {
  return OfferAnalysisParamHelper.parseParameters(transition.params());
}

function channelIdResolveFn(transition: Transition): string {
  return transition.params()?.channel_id;
}

export const OFFER_ANALYSIS_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.o.analysis',
  url: '/workflow/offer/analysis?channel_id&sid&workflow_id&status&workflow_status&typ&resubmission_date&sds_nr&oa_nr&kd_art_nr&kd_art_index&customer_id&customer_name&customer_zip&customer_city&customer_country&customer_field_service&username&execution&field_service&rejection_reason_id&has_hdi_type&has_via_handling&has_blind_hole_tech&silk_screen&has_gold&window_id&date_start&date_end',
  params: {
    channel_id: {dynamic: true},
    sid: {dynamic: true},
    workflow_id: {dynamic: true},
    status: {dynamic: true},
    workflow_status: {dynamic: true},
    typ: {dynamic: true},
    resubmission_date: {dynamic: true},
    sds_nr: {dynamic: true},
    oa_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    kd_art_index: {dynamic: true},
    customer_id: {dynamic: true},
    customer_name: {dynamic: true},
    customer_zip: {dynamic: true},
    customer_city: {dynamic: true},
    customer_country: {dynamic: true},
    customer_field_service: {dynamic: true},
    username: {dynamic: true},
    execution: {dynamic: true},
    field_service: {dynamic: true},
    rejection_reason_id: {dynamic: true},
    has_hdi_type: {dynamic: true},
    has_via_handling: {dynamic: true},
    has_blind_hole_tech: {dynamic: true},
    silk_screen: {dynamic: true},
    has_gold: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
  },
  views: {
    'main@a': {component: OfferAnalysisComponent},
  },
  resolve: [
    {token: 'query', resolveFn: queryParamsResolveFn, deps: [Transition]},
    {token: 'channelId', resolveFn: channelIdResolveFn, deps: [Transition]},
  ],
  dynamic: true,
  data: {requiresAuth: true},
};
