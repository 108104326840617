import * as React from 'react';
import {ReactNode} from 'react';

interface Props {
  children?: ReactNode | ReactNode[];
}

export function EvEmptyContainer(props: Props) {
  return <>
    {props.children}
  </>;
}
