import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommonType, InitData} from '../../../common/init-data/init-data';
import {LocalStorage} from '../../../common/storage/local-storage';

@Component({
  selector: 'workflow-state',
  template: `<span class="label" [ngClass]="'label-' + data.label + appendClass">{{ labelName }}</span>`,
  styles: ['span.label {margin: 0 5px; padding: 5px 6px !important;}',
           '.label-spacer {margin-left: 100px;}',
  ]
})
export class WorkflowStateComponent implements OnChanges {
  @Input() type = 'workflow_status';
  @Input() key: string | number;
  @Input() clazz?: string;
  @Input() additional?: string;
  public data: CommonType;
  public appendClass = '';
  public labelName: string;

  constructor(private localStorage: LocalStorage) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.clazz) {
      this.appendClass = ' ' + this.clazz;
    }

    let value: string;
    if (typeof this.key === 'number') {
      value = this.key.toString();
    } else {
      value = this.key;
    }


    const state = this.statusHelper(this.type, value);
    this.data = state;
    this.labelName = this.additional ? state.name + ' ' + this.additional : state.name;
  }

  private statusHelper(type: string, input: string | number): CommonType {
    return this.localStorage.getObject<InitData>('data')[type][input];
  }

}
