import {
  ShippingInstructionBase,
  ShippingInstructionBaTerminationTableRow,
  ShippingInstructionTableRow,
} from '../../article/models/article-extra.models';
import {deepCopy} from '../../../helper/deep-copy';
import {Component, Input, OnInit} from '@angular/core';
import {ShippingInstructionService} from '../../article/services/shipping-instruction.service';

@Component({
  selector: 'delivery-condition-box',
  templateUrl: './delivery-condition-box.component.html'
})
export class DeliveryConditionBoxComponent implements OnInit {
  @Input() extra: ShippingInstructionBase | undefined;
  @Input() article: string | undefined;

  loading = true;
  overDelivery: ShippingInstructionTableRow[] = [];
  underDelivery: ShippingInstructionTableRow[] = [];
  baTerminationDates: ShippingInstructionBaTerminationTableRow[];

  constructor(private shippingInstructionService: ShippingInstructionService) {
  }

  ngOnInit(): void {
    if (this.extra) {
      this.init();
      this.loading = false;
    } else if (this.article) {
      this.shippingInstructionService.get('article', this.article).subscribe(value => {
        this.extra = value.data;
        this.init();
        this.loading = false;
      }, () => {
        this.extra = undefined;
        this.loading = false;
      });
    } else {
      this.extra = undefined;
      this.loading = false;
    }
  }

  fillTable(table: ShippingInstructionTableRow[]): ShippingInstructionTableRow[] {
    return deepCopy(table);
  }

  private init(): void {
    if (this.extra?.over_under_delivery?.under_delivery) {
      this.underDelivery = this.fillTable(this.extra.over_under_delivery.under_delivery);
    }
    if (this.extra?.over_under_delivery?.over_delivery) {
      this.overDelivery = this.fillTable(this.extra.over_under_delivery.over_delivery);
    }
    if (this.extra?.ba_termination?.ba_termination) {
      this.baTerminationDates = deepCopy(this.extra.ba_termination.ba_termination);
    }
  }
}
