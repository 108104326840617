import {Directive, OnDestroy} from '@angular/core';
import {ListService} from '../search/search.service';
import {Lister} from '../wrapper.models';
import {Subscription} from 'rxjs';
import {PaginationQuery} from './pagination.model';

@Directive()
export abstract class PaginationBaseComponent<Y extends ListService<T>, T> implements OnDestroy {
  protected abstract service: Y;
  public list: Lister<T>;
  protected lastRequest?: Subscription = null;
  protected lastQuery?: PaginationQuery = { page: 1 };

  ngOnDestroy(): void {
    this.lastRequest?.unsubscribe();
  }

  query(query?: any): PaginationQuery {
    if (query) {
      this.lastQuery = query;
    }
    return this.lastQuery;
  }

  reload(): void {
    this.serviceCall(this.query());
  }

  serviceCall(query: PaginationQuery): void {
    this.lastRequest?.unsubscribe();
    this.lastRequest = this.service.list(query).subscribe(list => this.list = list);
  }

  goto(page: number): void {
    const query = this.query();
    query.page = page;

    this.serviceCall(query);
  }
}
