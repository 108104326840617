/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-c-o-c-testing-protocol',
  templateUrl: './article-extra-c-o-c-testing-protocol.component.html',
})
export class ArticleExtraCOCTestingProtocolComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'coc_testing_protocol';
  protected schemaName = 'coc-testing-protocol';
  protected fields = [
    'coc_testing_protocol',
    'coc_testing_protocol_other',
    'china_outgoing_report',
    'china_outgoing_report_other',
    'completion',
    'completion_other',
    'other',
    'email',
    'email_other',
    'paper',
    'paper_other',
    'gold_protocol',
    'gold_protocol_other',
  ];
}
