import {DateCode} from './date-code';
import {NumberHelper} from './number-helper';

/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
export const monthNames: string[] =
  [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];

export class DateHelper {
  static now(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static today(): Date {
    const date = new Date(Date.now());
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static thisMonth(): Date {
    const date = new Date(Date.now());
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  static copy(date: Date): Date {
    return  new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  private static pad(num: number) {
    const s = '0' + num;
    return s.substr(s.length - 2);
  }

  static format(date?: null | string | Date, iso?: string): string {
    let ret;
    if (date !== null && date !== undefined) {
      if (date instanceof Date) {
        const day = DateHelper.pad(date.getDate());
        const monthIndex = DateHelper.pad(date.getMonth() + 1);
        const year = date.getFullYear();
        if (iso) {
          ret = year + '-' + monthIndex + '-' + day;
        } else {
          ret = day + '.' + monthIndex + '.' + year;
        }
      } else {
        // if it is already formatted we can just ignore everything above
        ret = date;
      }
    } else {
      ret = null;
    }

    return ret;
  }

  static formatTime(date: Date): string {
    return `${DateHelper.pad(date.getHours())}:${DateHelper.pad(date.getMinutes())}`;
  }

  static safeParse(string: string | null | undefined): Date | null {
    if (string !== null && string !== '' && string !== undefined) {
      const splitted = string.split('.');
      if (splitted.length === 3) {
        return new Date(
          parseInt(splitted[2], 10),
          parseInt(splitted[1], 10) - 1,
          parseInt(splitted[0], 10)
        );
      }
    }
    return null;
  }

  static safeParseDate(string: string | null | undefined, english: boolean = false): Date | null {
    if (string !== null && string !== '' && string !== undefined) {
      const dateParts = string
        .split(english ? '-' : '.')
        .map(NumberHelper.saveParseInteger)
        .filter(dp => dp !== null);
      if (dateParts.length === 3 && english) {
        return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      } else if (dateParts.length === 3) {
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[1]);
      }
    }

    return null;
  }

  static parse(string: string | Date | null): Date | string {
    if (string !== null || string === '') {
      if (!(string instanceof Date)) {
        const splitted = string.split('.');
        if (splitted.length === 3) {
          return new Date(
            parseInt(splitted[2], 10),
            parseInt(splitted[1], 10) - 1,
            parseInt(splitted[0], 10)
          );
        } else {
          return '';
        }
      } else {
        return string;
      }
    }
  }

  static parseISO8601(string: string): Date {
    // https://stackoverflow.com/a/14242553/2250209 and removed time offset
    const regexp = '([0-9]{4})(-([0-9]{2})(-([0-9]{2})' +
      '(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(\.([0-9]+))?)?' +
      '(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?';
    const d = string.match(new RegExp(regexp));

    if (d.length > 0) {

      const date = new Date(parseInt(d[1], 10), 0, 1);

      if (d[3]) {
        date.setMonth(parseInt(d[3], 10) - 1);
      }
      if (d[5]) {
        date.setDate(parseInt(d[5], 10));
      }
      if (d[7]) {
        date.setHours(parseInt(d[7], 10));
      }
      if (d[8]) {
        date.setMinutes(parseInt(d[8], 10));
      }
      if (d[10]) {
        date.setSeconds(parseInt(d[10], 10));
      }
      if (d[12]) {
        date.setMilliseconds(Number('0.' + d[12]) * 1000);
      }
      return date;
    } else {
      return null;
    }
  }

  static parseAll(string: string | Date | null | undefined) {
    if (string instanceof Date) {
      return string;
    }
    return this.safeParse(string);
  }

  /**
   * returns the difference between two dates in days
   * @param a
   * @param b
   */
  static diffDays(a: Date, b: Date): number {
    return Math.floor(Math.abs(a.getTime() - b.getTime()) / 1000 / 60 / 60 / 24);
  }

  /*
   * Week related functions
   */

  /**
   * Returns the date of the first day in a monday based week in relation to the given date
   * @param date any date in the week
   * @return date the first day of the week as a date
   */
  static firstDayOfWeek(date: Date | null | undefined): Date {
    return date ? new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay() + 6) % 7) : date;
  }

  /**
   * Returns the number of weeks in a year
   * @param year
   */
  static weeksInYear(year: number): number {
    return 52 + ((DateHelper.lastWeekDayOfYear(year) === 4 || DateHelper.lastWeekDayOfYear(year - 1) === 3) ? 1 : 0);
  }

  /**
   * Returns the day of the week of the last day of a year
   * @param year
   */
  static lastWeekDayOfYear(year: number): number {
    return Math.floor(year + Math.floor(year / 4) - Math.floor(year / 100) + Math.floor(year / 400)) % 7;
  }

  /**
   * See https://en.wikipedia.org/wiki/ISO_week_date#Calculating_an_ordinal_or_month_date_from_a_week_date
   * and MR !2730 for a better algorithm description.
   * 1.    Multiply the week number @week by 7.
     2.    Then add the weekday number @day.
     3.    From this sum subtract the correction for the year:
              Get the weekday of the 4th of January and add 3.
     4.    The result is the ordinal date, which can be converted into a calendar date.
              If the ordinal date thus obtained is zero or negative, the date belongs to the previous calendar year;
              if it is greater than the number of days in the year, it belongs to the following year.
   * @param year  year of the week
   * @param week  calendar week
   * @param day   day of the week
   */
  static weekDateToDate(year, week, day) {
    const fourthDayOfYear = new Date(year, 0, 4);
    // we add 2 to the weekday of the fourth day, as js is zero based and starts with sunday
    const days = day + week * 7 - (fourthDayOfYear.getDay() + 2);
    return new Date(year, 0, days);
  }

  /**
   * Calculates the week and year from a given date
   * See https://en.wikipedia.org/wiki/ISO_week_date#Calculating_the_week_number_from_a_month_and_day_of_the_month_or_ordinal_date
   * @param date
   */
  static dateToWeekDate(date: Date): DateCode {
    const diff = DateHelper.diffDays(new Date(date.getFullYear(), 0, 1), date);
    const woy = Math.floor((10 + diff - date.getDay()) / 7);
    if (woy < 1) {
      return new DateCode(date.getFullYear(), DateHelper.weeksInYear(date.getFullYear()));
    } else if (woy > DateHelper.weeksInYear(date.getFullYear())) {
      return new DateCode(date.getFullYear() + 1, 1);
    } else {
      return new DateCode(date.getFullYear(), woy);
    }
  }

  /*
   * holiday related functions
   */
  static isHoliday(date: Date | null | undefined, holidays: { [year: string]: { [month: string]: number[] } }): boolean {
    if (!date) {
      return false;
    }
    const year = date.getFullYear().toString(10);
    const month = (date.getMonth() + 1).toString(10);
    return (holidays[year]?.[month]?.findIndex(d => d === date.getDate())) >= 0;
  }

  static isWorkday(date: Date | null | undefined): boolean {
    if (!date) {
      return false;
    }
    return date.getDay() !== 0 && date.getDay() !== 6;
  }

  static isBusinessDay(
    date: Date | null | undefined,
    holidays: { [year: string]: { [month: string]: number[] } }
  ): boolean {
    return DateHelper.isWorkday(date) && !DateHelper.isHoliday(date, holidays);
  }

  private static workDaysIncrement(
    date: Date,
    days: number,
    increment: number,
    holidays: { [year: string]: { [month: string]: number[] } }
  ): Date {
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    while (days > 0) {
      newDate.setDate(newDate.getDate() + increment);

      if (DateHelper.isBusinessDay(newDate, holidays)) {
        days -= 1;
      }
    }

    return newDate;
  }

  static workDaysMinus(date: Date, days: number, holidays: { [year: string]: { [month: string]: number[] } }): Date {
    return DateHelper.workDaysIncrement(date, days, -1, holidays);
  }

  static workDaysPlus(date: Date, days: number, holidays: { [year: string]: { [month: string]: number[] } }): Date {
    return DateHelper.workDaysIncrement(date, days, 1, holidays);
  }

  static diffWorkDays(dateA: Date, dateB: Date, holidays: { [year: string]: { [month: string]: number[] } }): number {
    const firstA = dateA < dateB;
    const first = firstA ? dateA : dateB;
    const second = !firstA ? dateA : dateB;
    const day = new Date(first.getFullYear(), first.getMonth(), first.getDate());
    let dayCount = 0;
    while (day < second) {
      day.setDate(day.getDate() + 1);
      if (DateHelper.isBusinessDay(day, holidays)) {
        dayCount++;
      }
    }

    return dayCount;
  }
}

export {YearMonth} from './year-helper';
