<div class="col-sm-4">
  <div class="row">
    <table class="table whitetable">
      <thead>
      <tr>
        <th>Nr.</th>
        <th>Einheit</th>
        <th class="icon"><a class="click" (click)="openModal()"><i class="fa fa-plus"></i></a></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let unit of units;">
        <td>{{unit.id}}</td>
        <td>{{unit.unit}}</td>
        <td class="icon">
          <a class="click" (click)="openModal(unit)">
            <i class="fa fa-pencil"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
