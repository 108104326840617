<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Schlechtteile
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="null_error_utilize" class="article-form-item-values">
      Null-Fehler-Nutzen
    </article-extra-checkbox>

    <article-extra-text formControlName="null_error_utilize_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <div class="row">
    <div class="col-md-6">
      <article-extra-table formControlName="utilize">
        Schlechtteile / Nutzen
        <span class="left">Stück bis</span>
        <span class="middle">%</span>
        <span class="right">Stück</span>
      </article-extra-table>
    </div>
    <div class="col-md-6">
      <article-extra-table formControlName="batch">
        Schlechtteile / Los
        <span class="left">Stück bis</span>
        <span class="middle">%</span>
        <span class="right">Stück</span>
      </article-extra-table>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <article-extra-table formControlName="utilize_batch">
        Schlechtnutzen / Los
        <span class="left">Stück bis</span>
        <span class="middle">%</span>
        <span class="right">Stück</span>
      </article-extra-table>
    </div>
  </div>

  <h6>Schlechtmarkierung</h6>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="bad_mark" class="article-form-item-values">
      Badmark
    </article-extra-checkbox>

    <article-extra-text formControlName="bad_mark_other" class="article-form-item-extra"></article-extra-text>

  </div>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="stift"
                            [defaultValues]="defaultValues['stift']"
                            class="article-form-item-values">
      Stift
    </article-extra-dropdown>

    <article-extra-text formControlName="stift_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-text formControlName="other" class="article-form-item-extra-long">
      Sonstiges
    </article-extra-text>

  </div>

</div>
