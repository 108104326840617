import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../models/article-specification.model';
import {ArticleInputMarkerDirective} from './article-input-marker.directive';

@Component({
  selector: 'article-input',
  templateUrl: './article-input.component.html'
})
export class ArticleInputComponent extends ArticleInputMarkerDirective implements OnInit, OnChanges {
  @Input() specification: { [key: string]: ArticleSpecification } | undefined;
  @Input() label?: string;
  @Input() unit?: string;
  @Input() model: string;
  @Input() form: UntypedFormGroup;
  @Input() error?: string;
  @Input() cssClass?: string;
  @Input() refCount?: number;
  @Output() changeEmitter = new EventEmitter<string>();
  view = true;
  refCounted = false;
  @Input() isDisabled?: boolean;
  private viewFn: (key: string, data: any) => boolean | undefined;

  ngOnInit(): void {
    const spec = this.specification ? this.specification[this.model] : null;
    if (!!spec && !!spec.view_fn) {
      this.viewFn = (key, data) => {
        return new Function('data', 'article', spec.view_fn)(key, data);
      };
      this.refCounted = true;
    }

    if (!!this.viewFn) {
      this.view = this.viewFn('', this.form.value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refCount && !changes.refCount.isFirstChange() && this.refCounted) {
      // this runs debounced after every keystroke, maybe we can find a more optimized version
      // currently I guess this is database dependent so a change might need a change in the fn database version
      if (!!this.viewFn) {
        this.view = this.viewFn('', this.form.value);
      }
    }
  }

  change(): void {
    this.changeEmitter.next(this.model);
  }

}
