import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {
  MaterialOrder,
  MaterialOrderIntakeGroupElement,
  MaterialOrderPositionTransactionIndices,
} from '../material-order.model';
import {noop} from '../../../../helper/noop';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialGroup} from '../../groups/material-group.model';

@Component({
  selector: 'material-order-intake-form',
  templateUrl: './material-order-intake-form.component.html'
})
export class MaterialOrderIntakeFormComponent {
  @Input() order: MaterialOrder;
  @Input() list: MaterialOrderIntakeGroupElement[];
  @Input() groups: Lister<MaterialGroup>;
  @Input() form: UntypedFormGroup;
  @Input() errors: { [key: string]: any; };
  @Input() markedVariationId?: number;
  @Input() markedPositionId?: number;
  @Input() markedTransactionId?: number;
  @Output() addTransaction = new EventEmitter<MaterialOrderPositionTransactionIndices>();
  @Output() removeTransaction = new EventEmitter<MaterialOrderPositionTransactionIndices>();
  @Output() updateOrder = new EventEmitter<void>();

  constructor() {
  }

  trackByFn(index: number, item: UntypedFormGroup): number {
    noop(this);
    return index;
  }

  get groupFormGroups() {
    const formArray = this.form.get('groups') as UntypedFormArray;
    return formArray.controls.map(group => group as UntypedFormGroup);
  }

  addTransactionPosition(index: number, position: MaterialOrderPositionTransactionIndices) {
    position.g_index = index;
    this.addTransaction.emit(position);
  }
}
