import {NgModule} from '@angular/core';
import {CustomerHeaderTemplateComponent} from './customer-header-template.component';
import {CustomerNavbarComponent} from './customer-navbar.component';
import {UIRouterModule} from '@uirouter/angular';
import {SharedModule} from '../../../core/shared.module';
import {CustomerRatingModule} from '../rating/customer-rating.module';

@NgModule({
  imports: [
    UIRouterModule.forChild(),
    SharedModule,
    CustomerRatingModule,
  ],
  declarations: [
    CustomerNavbarComponent,
    CustomerHeaderTemplateComponent,
  ],
  exports: [
    CustomerHeaderTemplateComponent,
  ],
})
export class CustomerHeaderModule {
}
