<ng-container [formGroup]="form">
  <td>
    {{ fetch.ordering }} (#{{fetch.id}})
    <span *ngIf="fetch.status === 1" class="label label-primary ng-scope">BESTÄTIGT</span>
    <span *ngIf="fetch.status === 3" class="label label-rose ng-scope">PRODUZIERT</span>
    <span *ngIf="fetch.status === 4" class="label label-warning ng-scope">GELIEFERT</span>
    <span *ngIf="fetch.status === 5" class="label label-success ng-scope">ABGERECHNET</span>
    <span *ngIf="!fetch.contract_obj && fetch.status <= 4" class="label label-danger ng-scope">AB FEHLT</span>
  </td>
  <td>
    <ng-container *ngIf="fetch.status === 5">{{ fetch.positions[0].quanity }}</ng-container>
    <ng-container *ngIf="fetch.status !== 5">
      <integer-input formControlName="quantity" [inputSelect]="true" [changeEvent]="false"></integer-input>
    </ng-container>
  </td>
  <td>{{ fetch.delivery }}</td>
  <td>
    <ng-container *ngIf="(fetch.status < 4 || (fetch.status === 4 && !newNr)) && !fetch.blocked;">
      <button class="btn btn-danger btn-xs" (click)="toggle(fetch)" *ngIf="fetch.use" style="width: 75px;">
        Entfernen
      </button>
      <button class="btn btn-success btn-xs" (click)="toggle(fetch)" *ngIf="!fetch.use" style="width: 75px;">
        Auswählen
      </button>
    </ng-container>
  </td>
</ng-container>
