import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ListService} from '../../../common/search/search.service';
import {MaterialInventory} from '../material-management.model';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialInventoryListService implements ListService<MaterialInventory> {

  constructor(private http: HttpClient) {
  }

  list(groupId: number, query?: any): Observable<Lister<MaterialInventory>> {
    return this.http.get<Lister<MaterialInventory>>(
      `/api/material/inventory/${groupId}`,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  updateVariation(variationId: number, form: any): Observable<void> {
    return this.http.put<void>(`${environment.apiv4uri}material/variation/${variationId}`, form);
  }
}
