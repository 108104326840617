<div class="row">
  <div class="col-lg-3">
    <table class="table whitetable">
      <thead>
      <tr>
        <th>Änderung</th>
        <th>Benutzer</th>
        <th>Datum</th>
      </tr>
      </thead>
      <tbody>
      <tr class="click"
          [ngClass]="{gray: history.id === current?.id }"
          (click)="selectHistory(history)"
          *ngFor="let history of list">
        <td>
          <label class="label label-success" *ngFor="let operation of history.creates">
            {{operation}}
          </label>
          <label class="label"
                 [ngClass]="{'label-danger': history.deleted, 'label-info': !history.deleted}"
                 *ngFor="let operation of history.edits">
            {{operation}}
          </label>
        </td>
        <td>{{history.username}}</td>
        <td>{{history.date_created|ZonedDateTime}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-lg-9">
    <shipping-instruction-history-detail [history]="current" [schema]="schema" [article]="article" *ngIf="current">
    </shipping-instruction-history-detail>
  </div>
</div>
