import {Component, Input, OnInit} from '@angular/core';
import {Comment} from '../../comment/comment.models';
import {CalculationProcess, PriceIncrease, PriceMain} from '../price.models';
import {Customer} from '../../customer/model/customer';
import {Article, ArticleToolingCost} from '../../article/models/article.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PriceSalesNoteModalComponent} from './price-sales-note-modal.component';
import {PriceService} from '../services/price.service';
import {ArticleInventorySum} from '../../workflow/models/inventory.models';
import {InventoryService} from '../../article/inventory/inventory.service';

@Component({
  selector: 'price-detail',
  templateUrl: './price-detail.component.html'
})
export class PriceDetailComponent implements OnInit {
  @Input() defaultCalculation: { de: string, cn: string };
  @Input() toolingCost?: ArticleToolingCost;
  @Input() priceData: {
    price: PriceMain,
    article_customer?: Customer,
    workflow_customer?: Customer,
    last_increase_de?: PriceIncrease
  };
  @Input() processes: { de: CalculationProcess[], cn: CalculationProcess[] };
  @Input() inventory: ArticleInventorySum;
  @Input() comments: Comment[];
  @Input() workflowId?: number;
  customer?: Customer;
  customerName?: string;

  constructor(private service: PriceService,
              private inventoryService: InventoryService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (this.priceData.workflow_customer) {
      this.customer = this.priceData.workflow_customer;
      this.customerName = encodeURIComponent(encodeURIComponent(this.priceData.workflow_customer.name));
    }
  }

  openSalesNote(customer: Customer): void {
    const modalRef = this.modalService.open(PriceSalesNoteModalComponent);
    modalRef.componentInstance.customer = customer;
    modalRef.result.then(() => {
    }, () => {
    });
  }

  updatesPrices(price: PriceMain): void {
    if (price.de) {
      this.priceData.price.de = price.de;
    }
    if (price.cn) {
      this.priceData.price.cn = price.cn;
    }
  }

  updateArticle(article: Article): void {
    this.priceData.price.article = article;
  }

  updateCalculation(calculation: { de: string, cn: string }): void {
    this.defaultCalculation = calculation;
  }

  reloadPrices(): void {
    this.service.detail(this.priceData.price.article.oa_nr).subscribe(price => {
      this.priceData.price = price;
    });
  }

  reloadInventory(): void {
    this.inventoryService.inventorySum(this.priceData.price.article.oa_nr, this.workflowId).subscribe(inventory => {
      this.inventory = inventory;
    }, () => {});
  }

}
