/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, OnInit} from '@angular/core';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Customer} from '../model/customer';
import {ArticleExtraSaveForm, ShippingInstructionDelete} from '../../article-extra/article-extra.models';
import {
  ShippingInstruction,
  ShippingInstructionBase,
  ShippingInstructionHolder,
  ShippingInstructionSchema
} from '../../article/models/article-extra.models';
import {ShippingInstructionService} from '../../article/services/shipping-instruction.service';
import {ShippingInstructionModalService} from '../../article-extra/shipping-instruction-history/shipping-instruction-modal.service';

@Component({
  selector: 'customer-article-extra',
  templateUrl: './customer-article-extra.component.html',
})
export class CustomerArticleExtraComponent implements OnInit {
  @Input() data: ShippingInstructionHolder<undefined>;
  @Input() schema: ShippingInstructionSchema[];
  @Input() customer: Customer;
  values: ShippingInstruction;
  formValues?: ShippingInstructionBase;
  valid = false;

  constructor(private alertService: AlertService,
              private historyService: ShippingInstructionModalService,
              private shippingInstructionService: ShippingInstructionService) {
  }

  ngOnInit(): void {
    this.values = this.data.data;
  }

  updateForm(value: ArticleExtraSaveForm): void {
    console.log('update');
    this.formValues = value.data;
    this.valid = value.valid;
  }

  saveData() {
    this.shippingInstructionService.save(
      'customer',
      this.customer.id.toString(10),
      {data: this.formValues}).subscribe(data => {
      this.data.data = data.data;
      this.values = data.data;
      this.alertService.add('success', 'Liefervorschriften erfolgreich gespeichert');
    }, response => {
      this.alertService.add('danger', 'Liefervorschriften konnten nicht gespeichert werden!');
      console.log('Liefervorschriften Fehler:', response);
    });
  }

  onDeleteSection(data: ShippingInstructionDelete): void {
    if (confirm('Möchten Sie die Liefervorschrift Sektion wirklich löschen? ACHTUNG! ' +
      'Alle Kunden Liefervorschriften in der ausgewählten Sektion werden gelöscht!')) {
      this.shippingInstructionService.delete(
        'customer',
        this.customer.id.toString(10),
        data.section).subscribe(inner => {
        this.valid = false;
        this.values = inner.data;
        this.data.data = inner.data;
      }, () => {
        this.alertService.add('danger', 'Konnte Sektion nicht löschen!');
      });
    }
  }

  openHistory() {
    this.historyService.open('customer', this.customer.id.toString(10), `Kunde ${this.customer.id}`);
  }

}
