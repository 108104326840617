import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PriceIncreaseFlex} from '../workflow/models/price-increase-flex.model';
import {Observable} from 'rxjs';
import {EnvisiaObject} from '../../common/wrapper.models';

@Injectable({
  providedIn: 'root'
})
export class PriceIncreaseFlexService {
  uri = `${environment.apiv4uri}price/increase/flex/`;

  constructor(private http: HttpClient) {
  }

  get(workflowId: number): Observable<EnvisiaObject<PriceIncreaseFlex | null>> {
    return this.http.get<EnvisiaObject<PriceIncreaseFlex | null>>(`${this.uri}${workflowId}`);
  }

  create(workflowId: number, flex: PriceIncreaseFlex): Observable<PriceIncreaseFlex> {
    return this.http.post<PriceIncreaseFlex>(`${this.uri}${workflowId}`, flex);
  }

  update(workflowId: number, flex: PriceIncreaseFlex): Observable<PriceIncreaseFlex> {
    return this.http.put<PriceIncreaseFlex>(`${this.uri}${workflowId}`, flex);
  }

  delete(workflowId: number): Observable<PriceIncreaseFlex> {
    return this.http.delete<PriceIncreaseFlex>(`${this.uri}${workflowId}`);
  }
}
