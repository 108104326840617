import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {ArticleV2Service} from '../../services/article2.service';
import {Article} from '../../models/article.models';

@Component({
  selector: 'article-top-change-state-modal',
  templateUrl: './article-top-change-state-modal.component.html',
  styles: ['.full-width { width: 100%; }']
})
export class ArticleTopChangeStateModalComponent {
  @Input() article: Article;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private articleV2Service: ArticleV2Service) {
  }

  changeState(state: 'green' | 'orange' | 'red'): void {
    console.log('New State:', state);
    this.articleV2Service.updateState(this.article.oa_nr, state).subscribe(value => {
      this.alertService.add('success', 'Artikel Änderungsstatus erfolgreich geändert!');
      this.activeModal.close(value.change_state);
    }, () => {
      this.alertService.add('danger', 'Artikel Änderungsstatus konnte nicht geändert werden!');
    });
  }

  close(): void {
    this.activeModal.dismiss();
  }

}
