/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {ArticleCore} from '../../article/models/article.models';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-packaging',
  templateUrl: './article-extra-packaging.component.html',
})
export class ArticleExtraPackagingComponent extends ArticleExtraBaseComponent {
  @Input() article?: ArticleCore;

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'packaging';
  protected schemaName = 'packaging';
  protected fields = [
    'quantity_ve',
    'quantity_ve_other',
    'vacuum',
    'vacuum_other',
    'other',
    've_carton',
    've_carton_other',
    'maximum_weight',
    'maximum_weight_other',
    'carton_other',
  ];
}
