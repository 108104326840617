import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderService} from '../services/business-order.service';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'business-order-step-min-duration-modal',
  templateUrl: './business-order-step-min-duration-modal.component.html',
})
export class BusinessOrderStepMinDurationModalComponent {

  constructor(private businessOrderService: BusinessOrderService,
              private alertService: AlertService,
              private modalService: NgbActiveModal) {
  }

  @Input() remaining: string;

  dismiss(): void {
    this.modalService.dismiss();
  }
}
