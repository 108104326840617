import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../models/article-specification.model';
import {ArticleMatrix} from '../form/article-matrix.models';

@Component({
  selector: 'article-detail-matrix',
  templateUrl: './article-detail-matrix.component.html'
})
export class ArticleDetailMatrixComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() errors: { [key: string]: any; } = {};
  matrix: ArticleMatrix;

  ngOnInit(): void {
    const dataMatrix = this.specification['description_matrix'];
    this.matrix = dataMatrix.values2['defaults'];
  }
}
