import {Component, Input} from '@angular/core';

@Component({
  selector: 'workflow-article-state',
  template: `
    <span class="label label-default" [ngClass]="'article-state-' + articleState">
      <i class="fa fa-circle"></i>
    </span>
  `
})
export class WorkflowArticleStateComponent {
  @Input() articleState: string;
}
