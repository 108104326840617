import * as React from 'react';
import {ReactNode} from 'react';

interface Props {
  children?: ReactNode | ReactNode[];
  response: (confirmed: boolean) => void;
}

export function EvConfirm(props: Props) {
  return <>
    <div className='modal-body'>
      {props.children}
    </div>
    <div className='modal-footer'>
      <button type='button' className='btn btn-danger' onClick={() => props.response(false)}>Abbrechen</button>
      <button type='button' className='btn btn-success' onClick={() => props.response(true)}>Ok</button>
    </div>
  </>;
}
