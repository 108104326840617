import {Component, Input, OnInit} from '@angular/core';
import {
  MaterialAggregation,
  MaterialArticle,
  MaterialInventory,
  MaterialStorage,
  MaterialTransaction,
  MaterialVariation
} from '../material-management.model';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {Lister} from '../../../common/wrapper.models';
import {StateService} from '@uirouter/core';
import {MaterialManagementService} from '../material-management.service';
import {MaterialInventoryTransactionListService} from './material-inventory-transaction-list.service';
import {NumberHelper} from '../../../helper/number-helper';
import {noop} from '../../../helper/noop';
import {MaterialGroup} from '../groups/material-group.model';

@Component({
  selector: 'material-inventory-detail',
  templateUrl: './material-inventory-detail.component.html'
})
export class MaterialInventoryDetailComponent implements OnInit {

  @Input() materialInventoryAggregations: MaterialInventory;
  @Input() currentGroup: MaterialGroup;
  @Input() currentArticle: MaterialArticle;
  @Input() currentVariation: MaterialVariation;
  @Input() currentInventory: MaterialStorage[];
  @Input() transactionLister: LoadingWrapper<Lister<MaterialTransaction>>;
  @Input() currentAggregation: MaterialAggregation = null;
  @Input() searchParams: { [key: string]: string };

  totalAmount: { konsi: number, storage: number, amount: number, priceTotal: number } =
    {konsi: 0, storage: 0, amount: 0, priceTotal: 0};

  showAll = false;

  constructor(private stateService: StateService,
              private mmService: MaterialManagementService,
              private mtListService: MaterialInventoryTransactionListService) {
  }

  ngOnInit() {
    if (this.currentAggregation) {
      this.currentGroup = this.currentAggregation.group;
      this.currentArticle = this.currentAggregation.article;
      this.currentVariation = this.currentAggregation.variation;
      this.getTransactionList(this.currentVariation.id);
    }
    this.calculateInventoryTotalSums();
  }
  getTransactionList(variationId: number): void {
    this.transactionLister = new LoadingWrapper<Lister<MaterialTransaction>>(
      this.mtListService.list({variation_id: variationId})
    );
  }

  getInventoryList(variationId: number): void {
    this.mmService.listInventoryForVariation(variationId, this.showAll).subscribe((inventory) => {
      this.currentInventory = inventory;
      this.calculateInventoryTotalSums();
    });
  }

  getLists(): void {
    this.getTransactionList(this.currentVariation.id);
    this.getInventoryList(this.currentVariation.id);
  }

  calculateInventoryTotalSums(): void {
    const s = {konsi: 0, storage: 0, amount: 0, priceTotal: 0};

    if (this.currentInventory?.length > 0) {
      this.currentInventory.forEach(storage => {
        s.konsi += storage.amount_konsi;
        s.storage += storage.amount_storage;
        s.amount += storage.amount;
        const price = NumberHelper.saveParseFloat(storage.price);
        s.priceTotal += !price ? (this.currentVariation?.vendor_price ?? 0) * storage.amount : price * storage.amount;
      });
    }

    this.totalAmount = s;
  }

  trackByFn(index: number, item: MaterialStorage): number {
    noop(this);
    return item.id;
  }

  toggleShowAll(): void {
    this.showAll = !this.showAll;
    this.getInventoryList(this.currentVariation.id);
  }
}
