/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, OnInit} from '@angular/core';
import {AlertService} from '../../../common/alert-service/alert.service';
import {ArticleExtraSaveForm} from '../../../envisia/article-extra/article-extra.models';
import {ShippingInstructionService} from '../../../envisia/article/services/shipping-instruction.service';
import {
  ShippingInstruction,
  ShippingInstructionBase,
  ShippingInstructionHolder,
  ShippingInstructionSchema
} from '../../../envisia/article/models/article-extra.models';
import {ShippingInstructionModalService} from '../../../envisia/article-extra/shipping-instruction-history/shipping-instruction-modal.service';

@Component({
  selector: 'article-config-extra',
  templateUrl: './article-config-extra.component.html'
})
export class ArticleConfigExtraComponent implements OnInit {
  @Input() data: ShippingInstructionHolder<undefined>;
  @Input() schema: ShippingInstructionSchema[];
  formValues: ShippingInstructionBase = null;
  values: ShippingInstruction;
  valid = false;

  constructor(private service: ShippingInstructionService,
              private historyService: ShippingInstructionModalService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.values = this.data.data;
  }

  updateForm(value: ArticleExtraSaveForm): void {
    this.formValues = value.data;
    this.valid = value.valid;
  }

  saveData() {
    this.service.save('global', 'global', {data: this.formValues}).subscribe(data => {
      this.values = data.data;
      this.alertService.add('success', 'Liefervorschriften erfolgreich gespeichert');
    }, (response) => {
      this.alertService.add('danger', 'Liefervorschriften konnten nicht gespeichert werden!');
      console.log('Liefervorschriften Fehler:', response);
    });
  }

  openHistory() {
    this.historyService.open('global', 'global', 'Global');
  }
}

export function getExtraFn(service: ShippingInstructionService) {
  return service.get('global', 'global').toPromise();
}

export function getExtraDefaultsFn(service: ShippingInstructionService) {
  return service.schema().toPromise();
}

export const articleConfigExtraResolve = [
  {
    token: 'data',
    resolveFn: getExtraFn,
    deps: [ShippingInstructionService]
  },
  {
    token: 'schema',
    resolveFn: getExtraDefaultsFn,
    deps: [ShippingInstructionService]
  }
];
