import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as React from 'react';
import {ArticleMultilayerPlan, ArticleMultilayerPlanModel} from './article-multilayer-plan.models';
import {ArticleSpecification} from '../../models/article-specification.model';
import {Article} from '../../models/article.models';
import {ArticleMultilayerPlanCreate} from './article-multilayer-plan-create';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';

const containerElementName = 'reactArticleMultilayerPlanContainer';

@Component({
  selector: 'article-multilayer-plan-create',
  template: `<div #${containerElementName}></div>`,
})
export class ArticleMultilayerPlanCreateComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() externalModelChange: ArticleMultilayerPlanModel;
  @Input() article: Article;
  @Input() changes: number;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() saveBtnPressed: number;
  @Output() saveBtnLocked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modelChanged: EventEmitter<ArticleMultilayerPlanModel> = new EventEmitter<ArticleMultilayerPlanModel>();
  @Output() modelSaved: EventEmitter<ArticleMultilayerPlan> = new EventEmitter<ArticleMultilayerPlan>();
  @Output() copyModelBtnPressed: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    super();
  }

  protected override renderComponent() {
    const _ = React.version;
    return <ArticleMultilayerPlanCreate externalModelChange={this.externalModelChange}
                                        article={this.article}
                                        changeModel={m => this.modelChanged.emit(m)}
                                        saveModel={m => this.modelSaved.emit(m)}
                                        specification={this.specification}
                                        saveBtnPressed={this.saveBtnPressed}
                                        saveBtnLocked={sbc => this.saveBtnLocked.emit(sbc)}
                                        copyModelBtnPressed={() => this.copyModelBtnPressed.emit()}
    />;
  }

}
