import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PriceHistory, PriceHistoryHistory} from '../price.models';
import {PriceDetailHistoryResetService} from './price-detail-history-reset.service';
import {PriceService} from '../services/price.service';
import {Customer} from '../../customer/model/customer';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'price-detail-history',
  templateUrl: './price-detail-history.component.html'
})
export class PriceDetailHistoryComponent implements OnInit {
  @Input() objects: PriceHistoryHistory[];
  @Input() current: PriceHistory;
  @Input() customer?: Customer;
  @Input() typ: 'de' | 'cn';
  @Output() reload: EventEmitter<void> = new EventEmitter<void>();
  typFormat = '';
  isCn: boolean;

  constructor(private priceService: PriceService,
              private resetService: PriceDetailHistoryResetService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.isCn = this.typ === 'cn';
    if (this.typ === 'de') {
      this.typFormat = 'DE';
    } else if (this.typ === 'cn') {
      this.typFormat = 'CHN';
    }
  }

  reset(priceHistory: PriceHistoryHistory, force?: boolean): void {
    if (!force && !confirm('Soll der Preis wirklich zurückgesetzt werden?')) {
      return;
    }
    this.resetService.reset(
      this.current.article,
      this.current.id,
      priceHistory.history_history_id,
      this.customer?.id,
      force,
    ).subscribe(priceHistoryNew => {
      this.alertService.add('success', 'Preis wurde erfolgreich zurückgesetzt');
      this.current = priceHistoryNew;
      this.reloadList();
    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        this.alertService.add('danger', 'Konnte Preis nicht zurücksetzen, Fehlercode: ' + error.error);
      } else if (force !== true && error.status === 409) {
        let confirmationString = '';
        switch (error.error) {
          case 'conflict_article':
            confirmationString += 'Die momentanen Artikeldaten weichen von den im Preis hinterlegten Artikeldaten ab.';
            break;
          case 'conflict_price_increase':
            confirmationString += 'Die Preise wurden zwischenzeitlich erhöht.';
            break;
        }
        if (confirm(confirmationString + '\nDie Kalkulation wird darum auf "manuell" gesetzt.')) {
          this.reset(priceHistory, true);
        }
      }
    });
  }

  reloadList(): void {
    this.priceService.history(
      this.current.article,
      this.current.quanity,
      this.current.lose,
      this.current.release,
      this.current.typ,
    ).subscribe(response => {
      this.objects = response;
      this.reload.next();
    });
  }

}
