import {Component, Input} from '@angular/core';
import {DocumentHistory} from './history.models';

@Component({
  selector: 'pdf-history-modal',
  templateUrl: './pdf-history-modal.component.html'
})
export class PdfHistoryModalComponent {
  @Input() objects: DocumentHistory[];
  @Input() id: number | string;
  @Input() title: string;
  @Input() name: string;
}
