import {ShippingInstructionBase} from '../../article/models/article-extra.models';
import {Component, Input, OnInit} from '@angular/core';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {map} from 'rxjs/operators';
import {ShippingInstructionService} from '../../article/services/shipping-instruction.service';

@Component({
  selector: 'delivery-condition-overdelivery-box',
  templateUrl: './delivery-condition-overdelivery-box.component.html'
})
export class DeliveryConditionOverdeliveryBoxComponent implements OnInit {
  @Input() oa_nr: string;
  shippingInstructions: LoadingWrapper<ShippingInstructionBase>;

  constructor(private shippingInstructionService: ShippingInstructionService) {
  }

  ngOnInit(): void {
    this.shippingInstructions = new LoadingWrapper<ShippingInstructionBase>(
      this.shippingInstructionService.get('article', this.oa_nr).pipe(map(a => a.data))
    );
  }

}
