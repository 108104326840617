<div class="page-header">
  <h3>Artikel entsperren</h3>
</div>

<ng-container *ngIf="success">
  <div class="row lock-md1">
    <div class="col-md-12 alert alert-success">
      <h2>Artikel wurden erfolgreich entsperrt!</h2>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="sending">
  <div class="row">
    <div class="col-md-12">
      <h2>Artikel werden entsperrt, bitte warten...</h2>
    </div>
    <div class="col-md-12">
      <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!sending">
  <div class="row lock-md1">
    <div class="col-md-12">
      <p style="font-size: 14px;">
        Mit dieser Funktion kann eine Liste mit Artikeln entsperrt werden.<br>
        <br>
        1. <strong>CSV</strong> Datei erstellen. In dieser Datei darf es nur <strong>eine</strong> Spalte mit der SDS
        Nummer geben.
        <strong>Es darf keine Kopfzeile in der Spalte vorhanden sein.</strong>
        <br>
        2. Datei über den Button "Durchsuchen" auswählen.<br>
        3. Mit Button "Ausführen" werden alle Artikel, die in der Liste aufgelistet sind, entsperrt werden sollen.<br>
        <br>
        <strong>Dieser Vorgang kann nicht rückgängig gemacht werden.</strong>
      </p>
    </div>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="form" class="form-horizontal">

    <div class="form-group" style="margin-top: 30px;">

      <div class="col-md-12">
        <ev-file-upload id="file-upload" formControlName="file"></ev-file-upload>
      </div>
    </div>

    <h2 class="lock-md1">Entsperrgrund</h2>

    <div class="form-group">
      <label class="col-md-2 control-label" for="reason">Grund:</label>

      <div class="col-md-10">
        <select class="form-control" formControlName="reason" id="reason">
          <option *ngFor="let notice of notices" [value]="notice">{{ notice }}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-2 control-label" for="reference">Referenz</label>

      <div class="col-md-10">
      <textarea [ngClass]="{red_alert: errors['reference']}" class="form-control" formControlName="reference"
                id="reference"
                rows="10">
      </textarea>
      </div>
    </div>

    <button [disabled]="!form.valid || sending" class="btn btn-success" type="submit">
      Ausführen
    </button>

  </form>
</ng-container>
