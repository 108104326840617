import {Component} from '@angular/core';
import {ArticleSpecification} from '../../models/article-specification.model';
import {UntypedFormBuilder} from '@angular/forms';
import {sanitize} from '../helper/sanitizer';
import {Customer} from '../../../customer/model/customer';
import {ArticleCalculatorService} from '../services/article-calculator.service';
import {ArticleSharedComponent} from '../helper/article-shared.component';
import {GlobalService} from '../../../../core/global.service';
import {ArticleService} from '../../services/article.service';
import {StateService} from '@uirouter/angular';
import {Article} from '../../models/article.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleSdsModalComponent} from '../sds-modal/article-sds-modal.component';
import {debug} from '../../../../helper/debug.func';
import {RightService} from '../../../../common/right-service/right.service';
import {StringHelper} from '../../../../common/string-helper';

@Component({
  selector: 'article-create',
  templateUrl: './article-create.component.html'
})
export class ArticleCreateComponent extends ArticleSharedComponent {
  customerName?: string;

  constructor(protected fb: UntypedFormBuilder,
              protected globalService: GlobalService,
              protected articleCalculatorService: ArticleCalculatorService,
              private stateService: StateService,
              private articleService: ArticleService,
              private rightService: RightService,
              private modalService: NgbModal) {
    super();
  }

  create(): void {
    debug('Article Create called:', this.customerName, this.form.value);
    const errorFunction = (response) => {
      debug('Article Errors:', response);
      // response === 0 means the modal is closed by a user
      if (response === 0) {
        return;
      }
      if (response.error) {
        if (response.error.objects) {
          this.errors = response.error.objects;
        } else {
          this.errors = response.error;
        }
      }
    };
    const successFunction = (obj: Article) => {
      this.globalService.removeEvents();
      this.stateService.go('a.article.workflow.overview', {id: obj.oa_nr});
    };
    if (this.rightService.has('article.sds_generate')) {
      const modalRef = this.modalService.open(ArticleSdsModalComponent);
      modalRef.componentInstance.create = true;
      modalRef.componentInstance.data = this.form.value;
      modalRef.componentInstance.customer = this.customerName;
      modalRef.result.then(successFunction, errorFunction);
    } else if (confirm('Möchsten Sie den Artikel wirklich erstellen?')) {
      const sendData = {data: this.form.value, customer: this.customerName};
      this.articleService.create('none', sendData).subscribe(successFunction, errorFunction);
    }

  }

  customerChange(event: Customer | null | undefined): void {
    if (!!event) {
      this.customerName = event.name;
    }
  }

  protected defaultValue(value: ArticleSpecification): { key: string, value: string | number | undefined }[] {
    if (value.dateType !== 'NOVALUE') {
      let currentValue;
      if (!value.multi) {
        currentValue = {key: value.name, value: value.default ? sanitize(value.default) : undefined};
      } else {
        currentValue = {key: value.name + '_1', value: value.default ? sanitize(value.default) : undefined};
      }
      return [currentValue];
    } else {
      return [];
    }
  }

  get hasValidationErrors(): boolean {
    return (this.validationErrors ?? []).length > 0;
  }

  get saveButtonErrorMessage(): string {
    return this.hasValidationErrors ?
      'Artikel kann nicht gespeichert werden.\n' +
      StringHelper.concat((this.validationErrors ?? []).filter(e => !!e), '\n') :
      'Artikel speichern';
  }
}
