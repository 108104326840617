import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'delivery-create-main-error-modal',
  templateUrl: './delivery-create-main-error-modal.component.html'
})
export class DeliveryCreateMainErrorModalComponent {
  @Input() error?: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  close(): void {
    this.activeModal.dismiss();
  }

}
