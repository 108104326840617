import {NgModule} from '@angular/core';
import {WorkflowStatusLabelComponent} from './workflow-status-label.component';
import {SharedModule} from '../../../core/shared.module';

@NgModule({
  declarations: [
    WorkflowStatusLabelComponent,
  ],
  exports: [
    WorkflowStatusLabelComponent
  ],
  imports: [
    SharedModule,
  ]
})
export class WorkflowStatusLabelModule {}
