/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Component, ErrorHandler, Input, OnInit} from '@angular/core';
import {SearchBaseComponent} from '../../../../common/search/search.component';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {Lister} from '../../../../common/wrapper.models';
import {noop} from '../../../../helper/noop';
import {AdapterListElem} from '../models/adapter.models';
import {AdapterService} from '../services/adapter.service';

@Component({
  selector: 'adapter-list',
  templateUrl: './adapter-list.component.html',
  styles: ['td { white-space: nowrap;overflow: hidden;text-overflow: ellipsis; }',
           '.finish-date {margin-left: 0 !important; margin-top: 4px !important; padding: 5px 7px !important;}',
           '.date-range-right {width: 350px !important;}']
})
export class AdapterListComponent extends SearchBaseComponent<AdapterService, AdapterListElem> implements OnInit {
  errors: { [key: string]: any; } = {};
  @Input() protected listData: Lister<AdapterListElem>;
  protected type = 'adapter';

  constructor(protected service: AdapterService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  trackByFn(index: number, item: string): number {
    noop(this);
    return index;
  }

}
