import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../../models/article-specification.model';

@Component({
  selector: 'article-surface-type-form',
  templateUrl: './article-surface-type-form.component.html',
})
export class ArticleSurfaceTypeFormComponent {
  @Input() errors: { [key: string]: any; } = {};
  @Input() form: UntypedFormGroup;
  @Input() specification: { [key: string]: ArticleSpecification };
  constructor() { }

}
