/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Address} from '../../../customer/model/address';
import {Workflow} from '../../models/workflow.models';
import {Customer} from '../../../customer/model/customer';
import {Country} from '../../../customer/model/country';
import {CountryService} from '../../../customer/services/country.service';
import {map} from 'rxjs/operators';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';
import {noop} from '../../../../helper/noop';
import {BigFetchWithCleanPosition} from './fetch-box.model';

@Component({
  selector: 'fetch-box',
  templateUrl: './fetch-box.component.html'
})
export class FetchBoxComponent implements OnInit {
  @Input() workflow: Workflow;
  @Input() customer: Customer;
  @Input() addresses: Address[];
  @Input() fetches: BigFetchWithCleanPosition[];
  @Input() frameTyp: string;
  @Input() priceIncreaseFlex?: PriceIncreaseFlex;
  @Output() statusChange = new EventEmitter<number>();

  isoCountryLoader: LoadingWrapper<Country | null | undefined>;

  constructor(private countryService: CountryService) {
  }

  ngOnInit(): void {
    this.isoCountryLoader = new LoadingWrapper(this.countryService.map().pipe(map(countries => {
      return countries[this.customer.invoice.country];
    })));
  }

  trackById(index: number, obj: BigFetchWithCleanPosition): number {
    noop(this);
    return obj.id;
  }
}
