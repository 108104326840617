import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../core/shared.module';
import {OrderBoxComponent} from './order-box.component';
import {OrderBoxHistoryComponent} from './history/order-box-history.component';
import {OrderBoxHistoryConfirmedComponent} from './history/order-box-history-confirmed.component';
import {OrderBoxHistoryConveyedComponent} from './history/order-box-history-conveyed.component';
import {OrderBoxHistoryExpensedComponent} from './history/order-box-history-expensed.component';
import {OrderBoxHistoryReceivedComponent} from './history/order-box-history-received.component';
import {OrderBoxHistoryReviewedComponent} from './history/order-box-history-reviewed.component';
import {CnOrderDeliveryMethodComponent} from './cn-order-delivery-method/cn-order-delivery-method.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    OrderBoxComponent,
    OrderBoxHistoryComponent,
    OrderBoxHistoryConveyedComponent,
    OrderBoxHistoryConfirmedComponent,
    OrderBoxHistoryReceivedComponent,
    OrderBoxHistoryReviewedComponent,
    OrderBoxHistoryExpensedComponent,
    CnOrderDeliveryMethodComponent,
  ],
  exports: [
    OrderBoxComponent,
    CnOrderDeliveryMethodComponent
  ]
})
export class OrderBoxModule {
}
