<div class="button-input" *ngIf="data">
  <ng-container *ngIf="data.data$|async as supplier; else loadingOrError.template">
    <input type="text"
           class="form-control"
           [ngClass]="{red_alert: supplier.date_deleted}"
           [value]="supplier.id+' - '+supplier.name"
           disabled>
    <button type="button" class="btn btn-default" (click)="modal(supplier)" [disabled]="disabled">
      <i class="fa fa-search-plus"></i>
    </button>
  </ng-container>
  <loading-or-error
      #loadingOrError
      [loadingWrapper]="data"
      [errorMessage]="'Konnte Lieferant nicht laden'">
  </loading-or-error>
</div>
<div class="button-input" *ngIf="!data">
  <input type="text" class="form-control" placeholder="Bitte Lieferanten wählen" disabled>
  <button type="button" class="btn btn-default" (click)="modal(null)">
    <i class="fa fa-search-plus"></i>
  </button>
</div>
