<div class="inline-div">
  <ng-container *ngIf="data">
    <ng-container *ngIf="data.data$|async as obj; else loadingOrError.template">

      <a uiSref="a.price.check" [uiParams]="{id: obj.oa_nr}"
              target="_blank"
              class="label label-warning">
        Zum Schwesterartikel
      </a>

    </ng-container>

    <loading-or-error
        #loadingOrError
        [loadingWrapper]="data"
        [errorMessage]="'Konnte \'Schwester Artikel\' nicht laden'"
        [spinnerSize]="2"
        spinnerName="circle-o-notch">
    </loading-or-error>


  </ng-container>
</div>
