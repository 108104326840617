import {UntypedFormGroup} from '@angular/forms';
import {DateHelper} from '../../../../helper/date-helper';
import {Injectable} from '@angular/core';
import {
  BusinessOrder,
  BusinessOrderForm,
  BusinessOrderListElem,
  BusinessOrderSimple,
  BusinessOrderSuccess,
  BusinessOrderView,
  BusinessOrderWithReproduction,
} from '../models/business-order.models';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../../common/wrapper.models';
import {ListService} from '../../../../common/search/search.service';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {map} from 'rxjs/operators';
import {FetchPosition} from '../../models/fetch.models';
import {environment} from '../../../../../environments/environment';

export function dateBefore(g: UntypedFormGroup) {
  const start = DateHelper.parseAll(g.get('start_date').value);
  const end = DateHelper.parseAll(g.get('end_date').value);
  const startTime = start ? start.getTime() : 0;
  const endTime = end ? end.getTime() : 0;

  return startTime < endTime ? null : {'end_date must be greater than start_date': true};
}

@Injectable({providedIn: 'root'})
export class BusinessOrderService implements ListService<BusinessOrderListElem> {

  private url = '/api/v1/workflow/business/order/';

  constructor(private http: HttpClient) {
  }

  detail(id: number, index: number): Observable<BusinessOrderView> {
    return this.http.get<BusinessOrderView>(this.url + id + '/' + index + '/');
  }

  list(query?: any): Observable<Lister<BusinessOrderListElem>> {
    return this.http.get<Lister<BusinessOrderListElem>>(this.url, {params: EnvisiaLocation.httpParams(query)});
  }

  listByArticle(article: string, status?: string): Observable<BusinessOrderWithReproduction[]> {
    return this.http.get<EnvisiaObjects<BusinessOrderWithReproduction>>(
      this.url + 'article/' + article + '/',
      {params: EnvisiaLocation.httpParams({status: status})}
    ).pipe(map(value => value.objects));
  }

  sumByArticle(article: string): Observable<number> {
    return this.http.get<EnvisiaObject<number>>(
      this.url + 'article/sum/' + article + '/'
    ).pipe(map(value => value.object));
  }

  countOpenByArticle(article: string): Observable<number> {
    return this.http.get<number>(`${this.url}article/open/${article}/`);
  }

  create(form: BusinessOrderForm): Observable<BusinessOrderSuccess> {
    return this.http.post<{object: BusinessOrderSuccess}>(this.url, form).pipe(map(value => value.object));
  }

  start(order: BusinessOrderSimple): Observable<BusinessOrder> {
    return this.http.post<EnvisiaObject<BusinessOrder>>(
      `${environment.apiv4uri}business/order/${order.id}/${order.index}/start`,
      {}
    ).pipe(map(value => value.object));
  }

  storno(id: number, index: number, value: { reason: string }): Observable<BusinessOrder> {
    return this.http.put<EnvisiaObject<BusinessOrder>>(
      '/api/v1/workflow/business/order/storno/' + id + '/' + index + '/',
      value
    ).pipe(map(response => response.object));
  }

  hasActiveByArticle(article: string): Observable<boolean> {
    return this.http.get<EnvisiaObject<boolean>>(
      this.url + 'article/active/' + article + '/'
    ).pipe(map(value => value.object));
  }

  countByArticle(article: string): Observable<number> {
    return this.http.get<EnvisiaObject<number>>(this.url + 'article/production/' + article)
      .pipe(map(v => v.object));
  }

  countAllByArticle(article: string): Observable<number> {
    return this.http.get<EnvisiaObject<number>>(this.url + 'article/all/' + article)
      .pipe(map(v => v.object));
  }

  revertFetch(fetchId: number, workflowId: number, ordering: number): Observable<void> {
    return this.http.post<void>(this.url + 'revert/fetch/' + fetchId + '/' + workflowId + '/' + ordering + '/', {});
  }

  setWatchlistState(id: number, index: number, watchlist: boolean): Observable<{ watchlist: boolean }> {
    return this.http.post<{ watchlist: boolean }>(this.url + `watch/${id}/${index}`, { watchlist: watchlist });
  }

  getFetches(id: number, index: number): Observable<FetchPosition[]> {
    return this.http.get<FetchPosition[]>(`${environment.apiv4uri}business/order/${id}/${index}`);
  }

}
