import {NgModule} from '@angular/core';
import {ArticleProductionListComponent} from './article-production-list.component';
import {SharedModule} from '../../../core/shared.module';
import {ArticleNavigationModule} from '../navigiation/article-navigation.module';
import {ArticleProductionEditComponent} from './article-production-edit.component';
import {UIRouterModule} from '@uirouter/angular';
import {ARTICLE_PRODUCTION_STATES} from './article-production.states';
import { ArticleProductionConfirmationModalComponent } from './confirmation-modal/article-production-confirmation-modal.component';

@NgModule({
    imports: [
        SharedModule,
        ArticleNavigationModule,
        UIRouterModule.forChild({ states: ARTICLE_PRODUCTION_STATES }),
    ],
    declarations: [
        ArticleProductionListComponent,
        ArticleProductionEditComponent,
        ArticleProductionConfirmationModalComponent,
    ]
})
export class ArticleProductionModule {
}
