import {Injectable} from '@angular/core';
import {ListService} from '../../../../common/search/search.service';
import {OfferListData} from '../../models/offer.models';
import {Observable} from 'rxjs';
import {Lister} from '../../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class OfferListService implements ListService<OfferListData> {
  constructor(private http: HttpClient) {
  }

  list(query?: { [paramName: string]: any; }): Observable<Lister<OfferListData>> {
    return this.http.get<Lister<OfferListData>>(
      `${environment.apiv4uri}offer/list`,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}