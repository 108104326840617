<div class="row row-no-margin top-inner-buffer row-flexbox">

  <div class="col-lg-7 whitebox col-height-hack">
    <h4>{{title}}</h4>

    <div class="row">
      <div class="col-lg-6">
        <article-xy-input label="Fläche CAM"
                          unit="dm²"
                          x="A"
                          y="B"
                          [form]="form"
                          [model1]="'surface_cam_a' + this.idTag"
                          [model2]="'surface_cam_b' + this.idTag"
                          [error1]="errors['surface_cam_a' + this.idTag]"
                          [error2]="errors['surface_cam_b' + this.idTag]">
        </article-xy-input>
      </div>
      <div class="col-lg-6">&nbsp;</div>
    </div>

    <div class="row">
      <div class="col-lg-6" *ngIf="title !== 'Galvanik'">
        <article-input label="Bemerkung"
                       [form]="form"
                       [model]="'electroplating_note' + this.idTag"
                       [error]="errors['electroplating_note' + this.idTag]">
        </article-input>

        <article-input label="Differenzätzen" model="electroplating_diff"
                       unit="µm"
                       [form]="form"
                       [error]="errors['electroplating_diff']">
        </article-input>
      </div>
    </div>

  </div>

  <div class="col-lg-5 bluebox col-padding">

    <div class="row">
      <div class="col-lg-6">
        <article-xy-input label="Fläche Produktion"
                          unit="dm²"
                          x="A"
                          y="B"
                          [form]="form"
                          [model1]="'surface_production_a' + this.idTag"
                          [model2]="'surface_production_b' + this.idTag"
                          [error1]="errors['surface_production_a' + this.idTag]"
                          [error2]="errors['surface_production_b' + this.idTag]">
        </article-xy-input>

        <article-select label="Programm-Nr."
                        [form]="form"
                        [model]="'programm_nr' + this.idTag"
                        [error]="errors['programm_nr' + this.idTag]"
                        [specification]="specification"></article-select>

        <article-xy-input label="Sonderprogramm"
                          x="I"
                          y="t"
                          unit1="A"
                          unit2="s"
                          [form]="form"
                          [specification]="specification"
                          [model1]="'special_program_i' + this.idTag"
                          [model2]="'special_program_t' + this.idTag"
                          [error1]="errors['special_program_i' + this.idTag]"
                          [error2]="errors['special_program_t' + this.idTag]">
        </article-xy-input>

        <article-checkbox label="Werte prüfen"
                          [form]="form"
                          [model]="'galvanize_value_check' + this.idTag"
                          [error]="errors['galvanize_value_check' + this.idTag]">
        </article-checkbox>
      </div>
      <div class="col-lg-6" *ngIf="title === 'Galvanik'">
        <article-select label="Cu-Elektrolyt"
                        [form]="form"
                        [model]="'cu_bad' + this.idTag"
                        [error]="errors['cu_bad' + this.idTag]"
                        [specification]="specification"></article-select>

        <article-select label="LBA-Verfahren"
                        [form]="form"
                        [model]="'lba_procedure' + this.idTag"
                        [error]="errors['lba_procedure' + this.idTag]"
                        [specification]="specification">
        </article-select>

        <article-select label="Programm"
                        [form]="form"
                        [refCount]="refCount"
                        [model]="'galvanize_programm' + this.idTag"
                        [error]="errors['galvanize_programm' + this.idTag]"
                        [specification]="specification">
        </article-select>

        <article-xy-input label="Sonderprog. SP"
                          x="I"
                          y="t"
                          unit1="A"
                          unit2="s"
                          [form]="form"
                          [refCount]="refCount"
                          [specification]="specification"
                          [model1]="'special_program_sp_i' + this.idTag"
                          [model2]="'special_program_sp_t' + this.idTag"
                          [error1]="errors['special_program_sp_i' + this.idTag]"
                          [error2]="errors['special_program_sp_t' + this.idTag]">
        </article-xy-input>

      </div>
    </div>

  </div>

</div>
