<h3>Artikel Felder Bezeichnung</h3>

<div class="row">

  <div class="col-lg-8">
    <table class="table whitetable">
      <thead>
      <tr>
        <th>Name</th>
        <th>Datenbank-ID</th>
        <th>Typ</th>
        <th>Bezeichnung DE</th>
        <th>Bezeichnung EN</th>
        <th class="icon"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of specs">
        <td>{{item.title}}</td>
        <td>{{item.name}}</td>
        <td>{{item.dateType}}</td>
        <td>{{item.name_de}}</td>
        <td>{{item.name_en}}</td>
        <td class="icon"><a class="click" (click)="open(item)"><i class="fa fa-pencil"></i></a></td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-lg-4">
  </div>

</div>
