import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Offer, WorkflowDateText, WorkflowOffer} from '../models/offer.models';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {map} from 'rxjs/operators';
import {OfferCreateForm} from '../offer/create/offer-create.models';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class OfferService {

  constructor(private http: HttpClient) {
  }

  create(workflowId: number, form: OfferCreateForm): Observable<Offer> {
    return this.http.post<EnvisiaObject<Offer>>('/workflow/v1/offer/' + workflowId + '/', form).pipe(
      map(val => val.object)
    );
  }

  updateDate(id: number, date: string): Observable<void> {
    return this.http.put<void>('/workflow/v1/offer/date/' + id + '/', {date: date});
  }

  status(id: number, status: number | null): Observable<number> {
    const stringStatus = status ? {'status': status.toString()} : {};
    return this.http.put<EnvisiaObject<number>>(
      '/workflow/v1/offer/status/' + id + '/',
      {},
      {params: EnvisiaLocation.httpParamsWrapper(stringStatus)}
    ).pipe(map(val => val.object));
  }

  history(id: number): Observable<any> {
    return this.http.get<EnvisiaObject<any>>('/workflow/v1/offer/' + id + '/history/').pipe(
      map(val => val.object)
    );
  }

  workflowList(workflowId: number): Observable<Offer[]> {
    return this.http.get<EnvisiaObjects<Offer>>('/workflow/v1/workflow/' + workflowId + '/offer/').pipe(
      map(val => val.objects)
    );
  }

  workflow(workflowId: number): Observable<WorkflowOffer> {
    return this.http.get<WorkflowOffer>('/workflow/v1/offer/' + workflowId + '/workflow/');
  }

  dateText(workflowId: string): Observable<WorkflowDateText> {
    return this.http.get<WorkflowDateText>(`${environment.apiv4uri}workflow/offer/${workflowId}/delivery-date-text`);
  }

}
