import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObjects} from '../../common/wrapper.models';
import {Additional, Category, FinderSearchItemData, FinderSearchItemList} from './finder.models';
import {map} from 'rxjs/operators';
import {EnvisiaLocation} from '../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class FinderSearchService {

  constructor(private http: HttpClient) {
  }

  category(): Observable<Category[]> {
    return this.http.post<EnvisiaObjects<Category>>(
      '/api/v1/finder/category/',
      {}
      ).pipe(map(value => value.objects));
  }

  process(): Observable<Category[]> {
    return this.http.post<EnvisiaObjects<Category>>(
      '/api/v1/finder/process/',
      {}
    ).pipe(map(value => value.objects));
  }

  search(typ: string, id: string | number, params: any = {page: 0}): Observable<FinderSearchItemList> {
    return this.http.get<FinderSearchItemList>(
      '/api/v1/finder/search/' + typ + '/' + id + '/',
      {params: EnvisiaLocation.httpParams(params)}
    );
  }

  poll(uuid: string): Observable<{status: string, data: FinderSearchItemData[]}> {
    return this.http.get<{status: string, data: FinderSearchItemData[]}>('/api/v1/finder/poll/' + uuid + '/');
  }

  additional(body: any): Observable<Additional[]> {
    return this.http.post<EnvisiaObjects<Additional>>('/api/v1/finder/additional/', body).pipe(
      map(val => val.objects)
    );
  }

}
