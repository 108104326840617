<table class="table whitetable">
  <thead>
  <tr>
    <th>Bestellung</th>
    <th>Vorgang</th>
    <th>Datum</th>
    <th>Artikel</th>
    <th>Produzent</th>
    <th>Lief.Ter.(ETD)</th>
    <th>Anfrage</th>
    <th>Menge</th>
    <th>Preis</th>
    <th>Ersteller</th>
    <th>&nbsp;</th>
    <th>&nbsp;</th>
    <th>&nbsp;</th>
    <th>&nbsp;</th>
    <th>&nbsp;</th>
    <th>&nbsp;</th>
  </tr>
  </thead>

  <tbody>
  <tr *ngFor="let obj of orders">
    <td>{{ obj.id }}</td>
    <td>
      <a class="click" uiSref="a.workflow.detail" target="_blank" [uiParams]="{id: obj.workflow_id}">
        <span [ngClass]="{ 'label': workflow.id === obj.workflow_id, 'label-info': workflow.id === obj.workflow_id }">
          {{ obj.workflow_id }}
        </span>
      </a>
    </td>
    <td>{{ obj.date }}</td>
    <td>
      <a uiSref="a.article.workflow.overview" [uiParams]="{id: obj.article}" target="_blank" *ngIf="article.sds_nr">
        <div class="label"
             [ngClass]="{'label-danger': obj.article_hash !== article.current_hash, 'label-success': obj.article_hash === article.current_hash }">
          {{ article.sds_nr }}
        </div>
      </a>
    </td>
    <td>{{ obj.producer }}</td>
    <td *ngIf="!obj.positions.call_of">{{ obj.positions.delivery_date}}</td>
    <td *ngIf="obj.positions.call_of">Auf Abruf</td>
    <td>{{ obj.request_nr }}</td>
    <td>{{ obj.positions.quanity }}</td>
    <td>{{ obj.positions.price }}</td>
    <td>{{ obj.username }}</td>
    <td class="icon">
      <ev-file-button [objId]="obj.id" type="order">
        <i class="fa fa-file-pdf-o"></i>
      </ev-file-button>
    </td>
    <td class="icon">
      <button type="button" class="btn btn-link btn-sm" (click)="mail(obj.id, obj.workflow_id, obj.producer, 'order')">
        <i class="fa fa-envelope"></i>
      </button>
    </td>

    <td>
      <!-- History Order -->
      <button type="button" class="btn btn-link btn-sm" (click)="historyOrder(obj)">
        <i class="fa fa-clock-o"></i>
      </button>
    </td>

    <td>
      <!-- Edit Order -->
      <button type="button"
              class="btn btn-link btn-sm"
              (click)="editOrder(obj)"
              *ngIf="obj.workflow_id === workflow.id">
        <i class="fa fa-pencil"></i>
      </button>
    </td>

    <td class="icon">
      <button type="button"
              class="btn btn-link btn-sm"
              (click)="deleteOrder(obj)"
              *ngIf="obj.workflow_id === workflow.id">
        <i class="fa fa-trash-o"></i>
      </button>
    </td>

  </tr>
  </tbody>
</table>
