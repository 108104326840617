<div class="modal-header">
  <h3>Produktionsschritt editieren - Artikel Felder</h3>
</div>

<div class="modal-body">
  <div class="row">

    <div class="col-lg-3 article-production-max-height">

      <!-- Draggable -->
      <div class="row">
        <div *ngFor="let step of articleSpecification; let i = index" class="col-md-6" dnd-draggable
             [dropZones]="zones" [dragData]="{data: step, typ: 'article'}">
          <div class="panel panel-default dnd-drag-item">
            <ng-container *ngIf="step.production_description !== step.production_title">
              ({{ step.production_description }}):&nbsp;
            </ng-container>
            {{ step.production_title }}
          </div>
        </div>
      </div>

    </div>

    <div class="col-lg-6">

      <div class="panel panel-default">
        <div class="panel-body">

          <div *ngFor="let stepLine of lines; let lineIndex = index">
            <div class="row">

              <div class="col-md-3" *ngFor="let step of stepLine.inner; let stepIndex = index">
                <div class="panel panel-default panel-min-height dnd-drag-item"
                     *ngIf="canDrop(lineIndex, stepIndex)"
                     dnd-droppable
                     [dropZones]="[step.zone]"
                     (dropSuccessEmitter)="add($event, lineIndex, stepIndex)">

                  <div class="row">
                    <div class="col-md-9">
                      <ng-container *ngIf="step.step?.article">
                        <ng-container *ngIf="step.step.article.production_description !== step.step.article.production_title">
                          ({{ step.step.article.production_description }})
                        </ng-container>
                        {{ step.step.article.production_title }}
                      </ng-container>
                      <ng-container *ngIf="step.step?.validation">
                        <ng-container *ngIf="step.step?.validation.group_label !== null && step.step?.validation.group_label !== ''">
                          [{{ step.step?.validation.group_label }}]&nbsp;
                        </ng-container>
                        {{ step.step?.validation.label }}
                      </ng-container>
                      <ng-container *ngIf="!step.step">
                        <strong>Neues Element hier einfügen</strong>
                      </ng-container>
                    </div>

                    <div class="col-md-3">
                      <ng-container *ngIf="canEdit(lineIndex, stepIndex)">
                        <button class="btn btn-link" (click)="edit(step.step)">
                          <i class="fa fa-edit"></i>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="canRemove(lineIndex, stepIndex)">
                        <button class="btn btn-link" (click)="remove(lineIndex, stepIndex)">
                          <i class="fa fa-remove red_alert"></i>
                        </button>
                      </ng-container>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-9" *ngIf="stepLine.empty">

                <div class="panel panel-default panel-min-height dnd-drag-item">
                  <div class="row">
                    <div class="col-md-11">
                      <strong>Leerzeile</strong>
                    </div>

                    <div class="col-md-1">
                      <!-- FIXME: correct ngIf -->
                      <ng-container *ngIf="canRemove(lineIndex, null)">
                        <button class="btn btn-link" (click)="remove(lineIndex, null)">
                          <i class="fa fa-remove red_alert"></i>
                        </button>
                      </ng-container>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-1">
                <button class="btn btn-link" (click)="down(lineIndex)"
                        *ngIf="(lines.length - 2) !== lineIndex && (lines.length - 1) !== lineIndex">
                  <i class="fa fa-chevron-down"></i>
                </button>
              </div>

              <div class="col-md-1">
                <button class="btn btn-link" (click)="up(lineIndex)"
                        *ngIf="lineIndex !== 0 && (lines.length - 1) !== lineIndex">
                  <i class="fa fa-chevron-up"></i>
                </button>
              </div>

              <div class="col-md-1">
                <ng-container *ngIf="!stepLine.empty">
                  <button class="btn-btn-link" *ngIf="(lines.length - 1) !== lineIndex" (click)="newLine(lineIndex)">
                    <i class="fa fa-plus"></i>
                  </button>
                </ng-container>
              </div>

            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="col-lg-3 article-production-max-height">
      <div class="row">
        <div *ngFor="let validation of articleExtraValidation; let i = index" class="col-md-6" dnd-draggable
             [dropZones]="zones" [dragData]="{data: validation, typ: 'delivery'}">
          <div class="panel panel-default dnd-drag-item">
            <ng-container *ngIf="validation.group_label !== ''">[{{ validation.group_label }}]&nbsp;</ng-container>
            {{ validation.label }}
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-success pull-right" (click)="save()">
    Speichern
  </button>
</div>
