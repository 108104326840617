/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input} from '@angular/core';
import {Workflow} from '../models/workflow.models';

@Component({
  selector: 'workflow-document-list',
  templateUrl: './workflow-document-list.component.html'
})
export class WorkflowDocumentListComponent {
  @Input() workflow: Workflow;
  @Input() search: any;
}
