<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Liefertermin
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="row">
    <div class="col-md-8">

      <div class="row">
        <div class="col-md-6">
          <article-extra-text formControlName="before" class="article-form-item-extra">
            Tage früher
          </article-extra-text>
        </div>

        <div class="col-md-6">
          <article-extra-text formControlName="after" class="article-form-item-extra">
            Tage später
          </article-extra-text>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <article-extra-text formControlName="other" class="article-form-item-reduced">
            &nbsp;
          </article-extra-text>
        </div>
      </div>
    </div>
  </div>
</div>
