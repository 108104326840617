import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MaterialManagementService} from '../material-management.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'material-units-create',
  templateUrl: './material-units-create.component.html'
})
export class MaterialUnitsCreateComponent {
  @Input() form: UntypedFormGroup;

  errorCode: number;

  constructor(
    private mms: MaterialManagementService,
    private modalService: NgbActiveModal,
  ) {
  }

  dismiss(): void {
    this.modalService.dismiss();
  }

  onSubmit(): void {
    this.mms.updateUnits(this.form.value).subscribe(
      (units) => {
        this.modalService.close(units);
      },
      (response) => {
        this.errorCode = response.status;
      }
    );
  }
}
