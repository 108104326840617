import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ArticleCustomerLockerService} from './article-customer-locker.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {SignalRService} from '../../../core/signalr.service';

@Component({
  selector: 'article-customer-locker',
  templateUrl: './article-customer-locker.component.html',
  styleUrls: ['./article-customer-locker.component.scss']
})
export class ArticleCustomerLockerComponent implements OnInit, OnDestroy {
  @Input() notices: string[];
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};
  sending = false;
  customerId: number | null = null;
  success = false;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private signalRService: SignalRService,
              private customerLockerService: ArticleCustomerLockerService) {
  }

  ngOnInit(): void {
    this.signalRService.startConnection(() => {
      this.signalRService.on('ArticleCustomerLocker', customerId => {
        if (customerId === this.customerId) {
          this.success = true;
          this.customerId = null;
          this.sending = false;
          setTimeout(() => {
            this.success = false;
          }, 5000);
        }
      });
    });

    this.form = this.fb.group({
      'customer': [null, Validators.required],
      'reason': [this.notices?.length > 0 ? this.notices[0] : 'Ext. Änderung', Validators.required],
      'reference': ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.signalRService.off('ArticleCustomerLocker');
    this.signalRService.stopConnection();
  }

  submit(): void {
    this.success = false;
    this.sending = true;
    const form = {
      customer_id: this.form.value.customer.id,
      reason: this.form.value.reason,
      reference: this.form.value.reference
    };
    this.customerId = this.form.value.customer.id;
    this.customerLockerService.lock(form).subscribe(() => {
    }, () => {
      this.sending = false;
      this.alertService.add('danger', 'Kunden Artikel konnten nicht gesperrt werden!');
    });
  }

}
