import {Injectable} from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {KanbanListDataView} from './kanban.model';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {HttpClient} from '@angular/common/http';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class KanbanCnService implements ListService<KanbanListDataView> {

  constructor(private http: HttpClient) {
  }

  list(query?: { [paramName: string]: any; }): Observable<Lister<KanbanListDataView>> {
    return this.http.get<Lister<KanbanListDataView>>(
      environment.apiv4uri + 'workflow/kanban/cn',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

}
