import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  MaterialOrderIntakeListElement,
  MaterialOrderPosition,
  MaterialOrderPositionTransactionIndices,
} from '../material-order.model';
import {MaterialValueType} from '../../material-management.model';
import {UntypedFormGroup} from '@angular/forms';
import {MaterialOrderPositionService} from '../material-order-position.service';

@Component({
  selector: 'tr[material-order-intake-row]',
  templateUrl: './material-order-intake-row.component.html',
  styleUrls: ['../material-order.component.scss'],
})
export class MaterialOrderIntakeRowComponent {
  @Input('material-order-intake-row') position: MaterialOrderIntakeListElement;
  @Input('material-order-intake-row-form') form: UntypedFormGroup;
  @Input('material-order-intake-row-errors') errors: { [key: string]: any; };
  @Input('material-order-intake-row-remaining') remaining: number;
  @Input('material-order-intake-row-fields') fields: MaterialValueType[];
  @Input('material-order-intake-row-is-first') isFirst: boolean;
  @Input('material-order-intake-row-group-permit') groupPermission: boolean;
  @Output('material-order-intake-row-add-transaction') addTransaction =
    new EventEmitter<MaterialOrderPositionTransactionIndices>();
  @Output('material-order-intake-row-remove-transaction') removeTransaction =
    new EventEmitter<MaterialOrderPositionTransactionIndices>();
  @Output('material-order-intake-row-update-position') updatePositionEmitter =
    new EventEmitter<MaterialOrderPosition>();

  constructor(private service: MaterialOrderPositionService) {
  }

  addTransactionForm(): void {
    this.addTransaction.emit({ position_id: this.position.position_id });
  }

  removeTransactionForm(): void {
    this.removeTransaction.emit(this.form.value);
  }

  setStatus(status: number): void {
    this.service.updateState(this.position.position_id, status).subscribe(position => {
      this.position.status = position.status;
      this.updatePositionEmitter.emit(position);
    });
  }

  get errorLocation(): boolean {
    return !!this.errors['groups[' + this.form.value.g_index +
    '].positions[' + this.form.value.p_index +
    '].transactions[' + this.form.value.t_index +
    '].location'];
  }

  get errorAmount(): boolean {
    return !!this.errors['groups[' + this.form.value.g_index +
    '].positions[' + this.form.value.p_index +
    '].transactions[' + this.form.value.t_index +
    '].amount'];
  }
}
