<div class="row">
  <div class="col-lg-12">

    <div class="content-header">
      <h2 [ngClass]="{red_alert: customer.date_deleted}">
        <span class="fa fa-user"></span>
        Kunde: {{ customer.name }}
        <span class="label label-warning" *ngIf="customer.locked">Gesperrt</span>
        &nbsp;

        <customer-rating [rating]="customer.rating"
                         [edit]="('crm.rating'|HasPerm)"
                         (listener)="setRating($event)"
                         *ngIf="showRating">
        </customer-rating>
      </h2>
    </div>

    <customer-navbar [customer]="customer"></customer-navbar>

    <div class="content-spacer"></div>
  </div>
</div>
