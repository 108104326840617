import {Component, Input} from '@angular/core';
import {Customer} from '../model/customer';

@Component({
  selector: 'customer-document',
  templateUrl: './customer-document.component.html'
})
export class CustomerDocumentComponent {
  @Input() data: {customer: Customer};
  @Input() search: any;
}
