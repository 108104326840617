<ng-container *ngFor="let position of fetch.positions; trackBy: trackByPosition; let positionIndex = index">
  <tr (contract-create-form-item-position-delete)="deleteEvent.emit($event)"
      (contract-create-form-item-position-trigger)="trigger.emit(true)"
      (contract-create-form-item-position-update-multi)="updateMulti($event)"
      (contract-create-form-item-position-update-single)="updateSingle($event, positionIndex)"
      [contract-create-form-item-position-last-fetch-id]="createdLastFetchId"
      [contract-create-form-item-position-fetchIndex]="index"
      [contract-create-form-item-position-fetch]="fetch"
      [contract-create-form-item-position-index]="positionIndex"
      [contract-create-form-item-position-nr]="nr"
      [contract-create-form-item-position-frame]="frame"
      [contract-create-form-item-position-article]="article"
      [contract-create-form-item-position-price-increase-flex]="priceIncreaseFlex"
      [contract-create-form-item-position]="position">
  </tr>
</ng-container>
