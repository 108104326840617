<div class="modal-header">
  <h3>Lieferant auswählen</h3>
</div>
<form class="form-horizontal" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group">
        <div class="col-sm-2 control-label">
          <label for="search">Suchen</label>
        </div>
        <div class="col-sm-8">
          <input id="search" class="form-control" type="text" formControlName="query">
        </div>
        <div class="col-sm-2">
          <button class="btn btn-default" type="button" (click)="updateList()"><i class="fa fa-search"></i></button>
        </div>
      </div>
      <div class="whitelist">
        <div class="whitelist-item selected" *ngIf="selected">
          {{selected.id}} - {{selected.name}}
        </div>
        <div class="whitelist-item" (click)="toggleSelect(supplier)" *ngFor="let supplier of supplierList">
          {{supplier.id}} - {{supplier.name}}
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Abbrechen
    </button>

    <button type="submit" class="btn btn-success pull-right" [disabled]="!selected">
      <ng-container>Auswählen</ng-container>
    </button>
  </div>
</form>
