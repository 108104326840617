import {Component, Input, OnInit} from '@angular/core';
import {ArticleMultilayerPlan} from '../multilayer-plan/article-multilayer-plan.models';
import {Lister} from '../../../../common/wrapper.models';
import {PaginationBaseComponent} from '../../../../common/pagination/pagination.component';
import {ArticleMultilayerPlanHistoryService} from './article-multilayer-plan-history.service';
import {ArticleMultilayerPlanAxiosService} from '../multilayer-plan/article-multilayer-plan-axios.service';
import {RightService} from '../../../../common/right-service/right.service';

@Component({
  selector: 'article-multilayer-plan-history',
  templateUrl: './article-multilayer-plan-history.component.html',
})
export class ArticleMultilayerPlanHistoryComponent
  extends PaginationBaseComponent<ArticleMultilayerPlanHistoryService, ArticleMultilayerPlan>
  implements OnInit {
  @Input() oaNr: string;
  @Input() list: Lister<ArticleMultilayerPlan>;

  isAdmin: boolean;
  constructor(protected service: ArticleMultilayerPlanHistoryService,
              private rightsService: RightService) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.rightsService.isSuperuser();
    this.lastQuery['oaNr'] = this.oaNr;
  }

  openPdf(amp: ArticleMultilayerPlan): void {
    ArticleMultilayerPlanAxiosService.bewitPdf(amp.id).then(s => {
      window.open(s.object, '_blank');
    });
  }

  openHtml(amp: ArticleMultilayerPlan): void {
    ArticleMultilayerPlanAxiosService.bewitHtml(amp.id).then(s => {
      window.open(s.object, '_blank');
    });
  }
}
