<div class="task">
  <h2>
    <a uiSref="a.workflow.detail" [uiParams]="{id: data.workflow.object.id}" ngbTooltip="Zurück zum Vorgang">
      <i class="fa fa-chevron-left"></i>
      Vorgang: {{ data.workflow.object.id }}
    </a>
  </h2>

  <div class="row">
    <div class="col-md-4">
      <h1>Lieferschein erstellen</h1>
      <customer-credit-worthiness-alert [customerCredit]="data.customer_credit_worthiness">
        <ng-template>
          <delivery-create-form [data]="data" [frame]="frame">
          </delivery-create-form>
        </ng-template>
      </customer-credit-worthiness-alert>
    </div>

    <div class="col-md-4">
      <!-- we have no lastOffer here?! and also we never want to show exchange rate -->
      <frame-box [frame]="data.workflow.frame"
                 [lastOffer]="null"
                 [cn]="false"
                 *ngIf="data.workflow.frame">
      </frame-box>

      <delivery-condition-box [article]="data.article.oa_nr"></delivery-condition-box>
    </div>

    <div class="col-md-4">
      <delivery-create-overview [data]="data" (updateValues)="updateWorkflow($event)">
      </delivery-create-overview>
    </div>

  </div>
</div>
