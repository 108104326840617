<div class="row">
  <div class="col-sm-6">
    <h3>Nutzerliste</h3>
  </div>
  <div class="col-sm-6">
    <h3>
      Material Gruppen sichtbar für Benutzer ohne Gruppen
      <button type="button" class="ev-button" [disabled]="currentUserLock" (click)="toggleVisibility()">
        <i class="fa fa-2x" style="font-size: revert;" [ngClass]="groupVisibilityClasses"></i>
      </button>
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <form name="form" #changeForm="ngForm">
      <table class="table whitetable">
        <thead>
        <tr>
          <th>Benutzername</th>
          <th>Nachname</th>
          <th>Vorname</th>
        </tr>
        <tr class="filterrow">
          <th><input class="form-control input-sm" name="name_user" [(ngModel)]="data['name_user']"/></th>
          <th><input class="form-control input-sm" name="name_last" [(ngModel)]="data['name_last']"/></th>
          <th><input class="form-control input-sm" name="name_first" [(ngModel)]="data['name_first']"/></th>
        </tr>
        </thead>

        <tbody>
        <tr class="click"
            [ngClass]="{'selected': user.id === currentUser?.id}"
            (click)="setCurrentUser(user)"
            *ngFor="let user of list.objects">
          <td>
            {{user.name_user}}
          </td>
          <td>
            {{user.name_last}}
          </td>
          <td>
            {{user.name_first}}
          </td>
        </tr>
        </tbody>
      </table>

      <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
    </form>
  </div>

  <div class="col-lg-6" *ngIf="!!currentUser">
    <h3>
      Sichtbare Gruppen für Benutzer #{{currentUser.id}} {{currentUser.name_user}} {{currentUserName}}
    </h3>
    <table class="table whitetable">
      <thead>
      <tr>
        <th>Produktgruppe</th>
        <th>Gruppe</th>
        <th>Zugriff</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let productGroup of currentUserPermissions; trackBy: trackByProductGroupFn; let index = index">
        <tr>
          <td>{{productGroup.productGroup.name}}</td>
          <td></td>
          <td>
            <button type="button" class="ev-button" [disabled]="currentUserLock" (click)="toggleProductGroup(productGroup)">
              <i class="fa fa-2x" [ngClass]="productGroup.classes"></i>
            </button>
          </td>
        </tr>
        <tr *ngFor="let group of productGroup.groups; trackBy: trackByGroupFn">
          <td></td>
          <td>{{group.group.group_id}} {{group.group.name}}</td>
          <td>
            <button type="button" class="ev-button" [disabled]="currentUserLock" (click)="toggleGroup(group)">
              <i class="fa fa-2x" [ngClass]="group.classes"></i>
            </button>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td>Sonstige</td>
        <td></td>
        <td></td>
      </tr>
      <tr *ngFor="let group of currentUserPermissionUserGroups; trackBy: trackByGroupFn">
        <td></td>
        <td>{{group.group.group_id}} {{group.group.name}}</td>
        <td>
          <button type="button" class="ev-button" [disabled]="currentUserLock" (click)="toggleGroup(group)">
            <i class="fa fa-2x" [ngClass]="group.classes"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
