import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleDuplicate} from '../../models/article.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ArticleService} from '../../services/article.service';

@Component({
  selector: 'article-copy-modal',
  templateUrl: './article-copy-modal.component.html',
})
export class ArticleCopyModalComponent implements OnInit {
  @Input() oaNr: string;
  @Input() kdArtNr?: string;

  duplicates?: ArticleDuplicate[];
  errors: { [key: string]: any; } = {};

  copyForm: UntypedFormGroup;

  constructor(private articleService: ArticleService,
              private fb: UntypedFormBuilder,
              private ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.copyForm = this.fb.group({
      'sds_nr': [null],
      'kd_art_nr': [this.kdArtNr],
      'kd_art_index': [null],
      'site_nr': [null]
    });
  }

  save() {
    this.errors = {};
    this.duplicates = null;
    this.articleService.copy(this.oaNr, this.copyForm.value).subscribe(value => {
      this.ngbActiveModal.close(value);
    }, response => {
      if (response.error.duplicates) {
        this.duplicates = response.error.duplicates;
        this.errors = {'obj.sds_nr': true, 'obj.kd_art_nr': true, 'obj.kd_art_index': true};
      } else {
        this.errors = response.error;
      }
    });
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

}
