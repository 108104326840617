<span class="label label-gold"
      ngbTooltip="Chem. Ni/Au"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="surface === 'Chem NI / AU'">
  <ng-container *ngSwitchCase="'big'">Gold</ng-container>
  <ng-container *ngSwitchCase="'small'">G</ng-container>
</span>
<span class="label label-primary"
      ngbTooltip="Erstauftrag"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="empb">
  <ng-container *ngSwitchCase="'big'">EMPB</ng-container>
  <ng-container *ngSwitchCase="'small'">E</ng-container>
</span>
<span class="label label-purple"
      ngbTooltip="Kanban"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="kanban">
  <ng-container *ngSwitchCase="'big'">KANBAN</ng-container>
  <ng-container *ngSwitchCase="'small'">K</ng-container>
</span>
<span class="label label-rose"
      ngbTooltip="Viafill"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="hasPlugging">
  <ng-container *ngSwitchCase="'big'">VIAFILL</ng-container>
  <ng-container *ngSwitchCase="'small'">VF</ng-container>
</span>
<span class="label label-orange"
      ngbTooltip="Copperfilling"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="hasCopperFilling">
  <ng-container *ngSwitchCase="'big'">COPPERFILLING</ng-container>
  <ng-container *ngSwitchCase="'small'">C</ng-container>
</span>
<span class="label label-info"
      ngbTooltip="Blind Via / Sackloch"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="hasBlindHole">
  <ng-container *ngSwitchCase="'big'">Sackloch</ng-container>
  <ng-container *ngSwitchCase="'small'">S</ng-container>
</span>
<span class="label label-danger"
      ngbTooltip="Nachfertigung"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="hasReproduction">
  <ng-container *ngSwitchCase="'big'">NACHFERTIGUNG</ng-container>
  <ng-container *ngSwitchCase="'small'">NF</ng-container>
</span>
<span class="label label-success"
      ngbTooltip="Plugged Via Typ IIIa"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="isT3">
  <ng-container *ngSwitchCase="'big'">Plugged Via Typ IIIa</ng-container>
  <ng-container *ngSwitchCase="'small'">T3</ng-container>
</span>
<span class="label label-gold"
      ngbTooltip="Filled and Covered Typ VI Lack"
      [ngClass]="{'label-big': size === 'big'}"
      [ngSwitch]="size"
      *ngIf="isT6">
  <ng-container *ngSwitchCase="'big'">Filled and Covered Typ VI Lack</ng-container>
  <ng-container *ngSwitchCase="'small'">T6</ng-container>
</span>
