<div class="row">
  <div class="col-lg-12">

    <table class="table whitetable business-order-create business-order-view-table">
      <tbody>
      <tr class="red-child align-center">
        <td colspan="2">
          <strong>
            <a class="good-piece-update-button" (click)="openDateChangeModal()" *ngIf="('ba.date.change'|HasPerm); else normalDateChange">
              {{ ba.work_days }} AT
            </a>
          </strong>
        </td>
      </tr>
      <tr>
        <td>Start-Termin:</td>
        <td>{{ ba.start_date }}</td>
      </tr>
      <tr>
        <td><strong>Vergoldung bis:</strong></td>
        <td><strong>{{ ba.gold_date }}</strong></td>
      </tr>
      <tr class="frame-table-box">
        <td><strong>End-Termin:</strong></td>
        <td><strong>{{ ba.end_date }} / {{ ba.end_week }}</strong></td>
      </tr>
      </tbody>
    </table>

    <table class="table whitetable business-order-create business-order-view-table" *ngIf="fetches?.length > 0">
      <thead>
      <tr>
        <th>Abruf</th><th>Menge</th><th>Liefertermin</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let fetchPosition of fetches">
        <td>
          <a class="click" uiSref="a.workflow.detail" [uiParams]="{id: fetchPosition.frame.workflow_id}">
            {{fetchPosition.fetch.ordering}} (#{{fetchPosition.fetch_id}})
          </a>
        </td>
        <td>{{fetchPosition.quantity|money:0}}</td>
        <td>{{fetchPosition.fetch.delivery|date:'dd.MM.yyyy'}}</td>
      </tr>
      </tbody>
    </table>

  </div>
</div>

<business-order-view-goodpiece-box [ba]="ba"
                                   [additional]="additional"
                                   [goodpieces]="goodpieces"
                                   (goodpiecesChange)="goodpiecesChange.emit($event)">
</business-order-view-goodpiece-box>

<ng-template #normalDateChange>
  {{ ba.work_days }} AT
</ng-template>

<div class="row">
  <div class="col-lg-12">

    <table class="table whitetable business-order-create business-order-view-table">
      <tbody>
      <tr>
        <td>Liefer-Obergrenze:</td>
        <td>{{ reproduction?.original?.over_delivery ?? ba.over_delivery }}</td>
      </tr>
      <tr>
        <td>Bestellte Menge:</td>
        <td>{{ reproduction?.original?.quantity ?? ba.quantity }}</td>
      </tr>
      <tr>
        <td>Liefer-Untergrenze:</td>
        <td>{{ reproduction?.original?.under_delivery ?? ba.under_delivery }}</td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
