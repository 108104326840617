import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PriceService} from '../services/price.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PriceHistory} from '../price.models';

@Component({
  selector: 'price-detail-notes-modal',
  templateUrl: './price-detail-notes-modal.component.html'
})
export class PriceDetailNotesModalComponent implements OnInit {
  @Input() ph: PriceHistory;
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private modalRef: NgbActiveModal,
              private service: PriceService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'pdt': [this.ph && this.ph.notes ? this.ph.notes : null, Validators.required],
    });
  }

  save(): void {
    this.service.updateNotes(this.ph.id, this.form.value.pdt).subscribe(ph => {
      this.alertService.add('success', 'Preis-Notiz erfolgreich editiert.');
      this.modalRef.close(ph);
    }, () => {
      this.alertService.add('danger', 'Beim Speichern ist ein Fehler aufgetreten!');
    });
  }

}
