<ng-container *ngIf="!isCustomerCreditWorthy">
  <div class="row">
    <div class="col-lg-12">
      <div class="alert alert-danger" role="alert">
        <b>Achtung: Kreditlimit erreicht!</b>
        Limit: {{customerCredit.credit_limit|money:2}}&nbsp;&euro;&nbsp;netto |
        Offene Rechnungen: {{this.customerCreditUsed|money:2}}&nbsp;&euro;&nbsp;netto
      </div>
    </div>
  </div>
</ng-container>
<ng-container [ngTemplateOutlet]="tmpl" *ngIf="!!isCustomerCreditWorthy">
</ng-container>
