import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject, Lister} from '../../../common/wrapper.models';
import {Customer, CustomerCalculations, CustomerList, CustomerProducers} from '../model/customer';
import {ListService} from '../../../common/search/search.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {CustomerCreateForm} from '../model/customer.form';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class CustomerService implements ListService<CustomerList> {

  private url = '/crm/v1/customer/';

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<CustomerList>> {
    // hack as long as this issue is not resolved: https://github.com/angular/angular/issues/18261
    if (query.hasOwnProperty('name') && query.name !== undefined && query.name.indexOf('+') > -1) {
      return this.http.get<Lister<CustomerList>>(this.url + '?name=' + encodeURIComponent(query.name));
    } else {
      return this.http.get<Lister<CustomerList>>(
        this.url,
        {params: EnvisiaLocation.httpParams(query)}
      );
    }
  }

  create(form: CustomerCreateForm): Observable<Customer> {
    return this.http.post<EnvisiaObject<Customer>>(this.url, form).pipe(map(obj => obj.object));
  }

  detail(id: number): Observable<Customer> {
    return this.http.get<EnvisiaObject<Customer>>(this.url + id + '/').pipe(map(obj => obj.object));
  }

  byName(name: string): Observable<Customer> {
    return this.http.post<Customer>(`${environment.apiv4uri}customer/name`, {'name': name});
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(this.url + id + '/');
  }

  toggleLock(id: number): Observable<void> {
    return this.http.put<void>(this.url + 'lock/' + id + '/', {});
  }

  setRating(id: number, rating: number): Observable<void> {
    return this.http.put<void>(this.url + id + '/rating/', {rating: rating});
  }

  update(customerId: number, form: any): Observable<Customer> {
    return this.http.put<EnvisiaObject<Customer>>(
      this.url + customerId + '/',
      form
    ).pipe(map(value => value.object));
  }

  updateCalc(customerId: number, form: CustomerCalculations): Observable<void> {
    return this.http.put<void>(
      this.url + 'calc/' + customerId + '/', form
    ).pipe(map(() => {}));
  }

  updateProducers(customerId: number, form: CustomerProducers): Observable<void> {
    return this.http.put<void>(
      this.url + 'producers/' + customerId + '/', form
    ).pipe(map(() => {}));
  }

  updateName(customerId: number, name: string): Observable<Customer> {
    return this.http.put<EnvisiaObject<Customer>>(
      this.url + customerId + '/name/',
      {name: name}
    ).pipe(map(value => value.object));
  }

  deliveryAddress(customerId: number, deliveryId: string): Observable<Customer> {
    const body = {address_id: deliveryId};
    return this.http.put<EnvisiaObject<Customer>>(
      this.url + 'delivery/address/' + customerId + '/',
      body
    ).pipe(map(value => value.object));
  }

}
