import {UntypedFormBuilder} from '@angular/forms';
import {ArticleUpdateService} from '../../component-service/article-update.service';
import {ArticleUpdateSharedComponent} from '../helper/article-update-shared.component';
import {GlobalService} from '../../../../core/global.service';
import {ArticleCalculatorService} from '../services/article-calculator.service';
import {Component} from '@angular/core';
import {Customer} from '../../../customer/model/customer';
import {ArticleV2Service} from '../../services/article2.service';
import {StateService} from '@uirouter/angular';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'article-overview',
  templateUrl: './article-overview.component.html'
})
export class ArticleOverviewComponent extends ArticleUpdateSharedComponent {
  constructor(protected fb: UntypedFormBuilder,
              protected articleCalculatorService: ArticleCalculatorService,
              protected globalService: GlobalService,
              protected articleUpdateService: ArticleUpdateService,
              protected stateService: StateService,
              private alertService: AlertService,
              private articleV2Service: ArticleV2Service) {
    super();
  }


  customerChange(event: Customer | null | undefined): void {
    if (!!event) {
      this.articleV2Service.updateCustomer(this.article.oa_nr, event.name).subscribe(article => {
        this.article.date_deleted = article.date_deleted;
        this.article.locked = article.locked;
        this.article.customer = article.customer;
        this.alertService.add('success', 'Kunde erfolgreicht geändert!');
      }, () => {
        this.alertService.add('danger', 'Beim Ändern des Kundens ist ein Fehler aufgetreten!');
      });
    }
  }

}
