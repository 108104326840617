import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {ErpProducer} from './producer.models';
import {ignoreRejection} from '../../helper/ignore_rejection';

@Component({
  selector: 'producer-modal',
  templateUrl: './producer-modal.component.html'
})
export class ProducerModalComponent implements OnInit {
  @Input() producers: ErpProducer[];
  @Input() current?: string;
  @Input() saveMethod: (ref: NgbActiveModal, selected?: string) => Observable<any>;
  selected?: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.selected = this.current;
  }

  select(obj?: ErpProducer): void {
    if (!!obj) {
      this.selected = obj.name;
    } else {
      this.selected = null;
    }
  }

  close(): void {
    this.activeModal.dismiss();
  }

  save(): void {
    // just calls the injected selectable
    this.saveMethod(this.activeModal, this.selected).subscribe(ignoreRejection, ignoreRejection);
  }

}
