import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PriceIncrease} from '../../price/price.models';
import {EnvisiaObject} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CustomerIncreaseService {

  private uri = '/api/v1/crm/increase/';

  constructor(private http: HttpClient) {
  }

  last(name: string, typ: 'de' | 'cn'): Observable<PriceIncrease | null> {
    const escapedName = encodeURIComponent(name);
    return this.http.get<EnvisiaObject<PriceIncrease | null>>(
      this.uri + typ + '/' + escapedName + '/'
    ).pipe(map(value => value.object));
  }

  list(id: number): Observable<{de?: PriceIncrease[], cn?: PriceIncrease[]}> {
    return this.http.get<EnvisiaObject<{de?: PriceIncrease[], cn?: PriceIncrease[]}>>(
      this.uri + id + '/'
    ).pipe(map(values => values.object));
  }

  create(customerId: number, form: {percent: string, typ: string}): Observable<PriceIncrease> {
    return this.http.post<EnvisiaObject<PriceIncrease>>(
      this.uri + customerId + '/',
      form
    ).pipe(map(value => value.object));
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + id + '/');
  }

}
