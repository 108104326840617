import {Ng2StateDeclaration} from '@uirouter/angular';
import {CustomerService} from '../services/customer.service';
import {CustomerListComponent} from './customer-list.component';
import {CustomerNavigationComponent} from '../navigation/customer-navigation.component';
import {ErpTransition} from '../../../core/erp-transition.service';

export function customerListParams(trans: ErpTransition): {[key: string]: any} {
  return trans.params();
}

export function customerListResolveFn(trans: ErpTransition, service: CustomerService) {
  return service.list(trans.params()).toPromise();
}

export const CUSTOMER_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.crm.list',
  url: '/crm/list?sort&order&rating&page&sid&name&street&zip&city&country&phone&mail&field_service&industrial_sectors&locked',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: CustomerListComponent}
  },
  params: {
    page: {dynamic: true},
    sort: {dynamic: true},
    order: {dynamic: true},
    rating: {dynamic: true, value: '3,4,5'},
    sid: {dynamic: true},
    name: {dynamic: true},
    street: {dynamic: true},
    zip: {dynamic: true},
    city: {dynamic: true},
    country: {dynamic: true},
    phone: {dynamic: true},
    mail: {dynamic: true},
    field_service: {dynamic: true},
    industrial_sectors: {dynamic: true},
    locked: {dynamic: true, value: 'false'},
  },
  resolve: [
    {
      token: 'listData',
      resolveFn: customerListResolveFn,
      deps: [ErpTransition, CustomerService],
    },
    {
      token: 'data',
      resolveFn: customerListParams,
      deps: [ErpTransition],
    }
  ],
  data: {requiresAuth: true}
};
