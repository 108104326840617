import {Injectable} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DateHelper} from '../../../../helper/date-helper';
import {
  MaterialOrderIntakeGroupElement,
  MaterialOrderIntakeListElement,
  MaterialOrderPositionTransactionIndices,
} from '../material-order.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialOrderIntakeFormCreateService {

  protected constructor(protected fb: UntypedFormBuilder) {
  }

  initForm(orderId: number, list: MaterialOrderIntakeGroupElement[]): UntypedFormGroup {
    const date = DateHelper.format(DateHelper.today(), 'yyyy-MM-dd');
    const groups = this.fb.array(list.map((mig, gIndex) => this.groupForm(mig, gIndex)));
    return this.fb.group({
      date_received: [date, Validators.required],
      order_id: [orderId, Validators.required],
      groups: groups,
    });
  }

  private groupForm(mig: MaterialOrderIntakeGroupElement, gIndex: number): UntypedFormGroup {
    const positions = this.fb.array(mig.article_list.map((mop, pIndex) =>
      this.positionForm(mop, gIndex, pIndex)));
    return this.fb.group({
      group_id: [mig.group.id, Validators.required],
      positions: positions
    });
  }

  private positionForm(mop: MaterialOrderIntakeListElement, gIndex: number, pIndex: number): UntypedFormGroup {
    const positionForm = {position_id: mop.position_id, g_index: gIndex, p_index: pIndex, t_index: 0};
    const positions = this.fb.array([this.transactionForm(positionForm)]);
    return this.fb.group({
      position_id: [mop.position_id, Validators.required],
      transactions: positions
    });
  }

  transactionForm(position: MaterialOrderPositionTransactionIndices): UntypedFormGroup {
    return this.fb.group({
      position_id: [position.position_id, Validators.required],
      location: [null, Validators.required],
      charge: [null],
      amount: [null, Validators.required],
      g_index: [position.g_index, Validators.required],
      p_index: [position.p_index, Validators.required],
      t_index: [position.t_index, Validators.required],
    });
  }

  resetForm(form: UntypedFormGroup, list: MaterialOrderIntakeGroupElement[]): void {
    const groups = this.fb.array([]);
    list.forEach((group, gIndex) => {
      groups.push(this.groupForm(group, gIndex));
    });
    form.setControl('groups', groups);
  }
}
