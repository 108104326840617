import {Component} from '@angular/core';
import {StateService} from '@uirouter/angular';

@Component({
  selector: 'article-config-navigation',
  templateUrl: './article-config-navigation.component.html'
})
export class ArticleConfigNavigationComponent {
  state: StateService;

  constructor(private stateService: StateService) {
    this.state = stateService;
  }
}
