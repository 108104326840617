import {Injectable} from '@angular/core';
import {ArticleV2Service} from '../services/article2.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Article} from '../models/article.models';
import {EMPTY, Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleSisterModalComponent} from '../main/sister-modal/article-sister-modal.component';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ArticleSisterService {

  constructor(private articleV2Service: ArticleV2Service,
              private ngbModal: NgbModal,
              private alertService: AlertService) {
  }

  createSisterArticle(article: string): Observable<Article> {
    if (confirm('Möchten Sie den Schwesterartikel wirklich erstellen?')) {
      return this.articleV2Service.createSister(article).pipe(
        tap(value => {
          this.alertService.add(
            'success',
            'Schwesterartikel erfolgreich erstellt!'
          );

          const modalRef = this.ngbModal.open(ArticleSisterModalComponent);
          modalRef.componentInstance.article = value;
        }, () => {
          this.alertService.add(
            'danger',
            'Beim Erstellen eines Schwesterartikels ist ein Fehler aufgetreten, ' +
            'bitte benachrichtigen Sie Ihren Administrator'
          );
        })
      );
    } else {
      return EMPTY;
    }
  }

}
