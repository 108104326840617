import {Injectable} from '@angular/core';
import {NumberHelper} from '../../../../helper/number-helper';
import {BigFetchWithCleanPosition, FetchPositionClean} from './fetch-box.model';
import {ArticleWeightService} from '../../../article/services/article-weight.service';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {ArticleWeightModel} from '../../../article/models/article-weight.model';
import {BigFetchWithPosition} from '../../models/fetch.models';

@Injectable({providedIn: 'root'})
export class FetchBoxHelperService {
  constructor(private weightService: ArticleWeightService) {
  }

  getCleanFetches(
    fetches: BigFetchWithPosition[],
    article: string,
    frameTyp: string,
    priceIncreaseFlex?: PriceIncreaseFlex
  ): BigFetchWithCleanPosition[] {
    const cleanFetches: BigFetchWithCleanPosition[] = [];
    const decimals = frameTyp === 'de' ? 2 : 3;
    fetches.forEach(fetch => {
      let priceTotalFetch = 0;
      const cleanedPositions = fetch.positions.map(p => {
        // If the position is at status 'invoice' or 'storno' we have to use the price increase from the position
        const priceIncrease = (fetch.status < 5 ? priceIncreaseFlex?.increase : p.price_increase) ?? null;
        const priceParsed = NumberHelper.saveParseFloat(p.price as string);
        const priceWithIncrease = NumberHelper.roundDecimal(priceParsed * (1 + priceIncrease / 100), decimals);
        const priceSingle = (p.typ === 'article') ? priceWithIncrease : priceParsed;
        const quantityParsed = NumberHelper.saveParseInteger(p.quanity) ?? 0;
        const priceTotal = (quantityParsed > 0) ? priceSingle * quantityParsed : 0;
        priceTotalFetch += priceTotal;
        return {
          id: p.id,
          ordering: p.ordering,
          fetch_id: p.fetch_id,
          typ: p.typ,
          label: p.label,
          desextension: p.desextension,
          quantity: quantityParsed,
          price: priceParsed,
          price_with_increase: priceWithIncrease,
          price_increase: priceIncrease,
          price_total: priceTotal,
          increase: priceIncreaseFlex?.increase,
        } as FetchPositionClean;
      });

      const priceUst = fetch.vat / 100 * priceTotalFetch;
      const fetchCleaned = {
        id: fetch.id,
        ordering: fetch.ordering,
        frame: fetch.frame,
        status: fetch.status,
        delivery: fetch.delivery,
        positions: cleanedPositions,
        contract_obj: fetch.contract_obj,
        delivery_obj: fetch.delivery_obj,
        invoice_obj: fetch.invoice_obj,
        order_nr: fetch.order_nr,
        is_complaint: fetch.is_complaint,
        earliest_delivery: fetch.earliest_delivery,
        blocked: fetch.blocked,
        vat: fetch.vat,
        price_total: priceTotalFetch,
        price_ust: priceUst,
        price_total_ust: priceTotalFetch + priceUst,
        article_weight: null,
      } as BigFetchWithCleanPosition;
      fetchCleaned.article_weight = this.fetchBoardWeight(fetchCleaned, article);
      cleanFetches.push(fetchCleaned);
    });
    return cleanFetches;
  }

  fetchBoardWeight(fetch: BigFetchWithCleanPosition, article: string): LoadingWrapper<ArticleWeightModel> | null {
    const filteredPositions = fetch.positions.filter(p => p.typ === 'article');
    if (filteredPositions.length > 0) {
      return new LoadingWrapper<ArticleWeightModel>(
        this.weightService.getWeight(article, filteredPositions[0].quantity)
      );
    }

    return null;
  }
}
