import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleEither} from '../../main/workflow-main.models';
import {WorkflowSimpleArticleForm} from '../../models/workflow.models';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {WorkflowService} from '../../workflow-services/workflow.service';
import {BigCreateWorkflow, BigWorkflowOffer} from '../../models/workflow-create.models';

@Component({
  selector: 'workflow-duplicate-modal',
  templateUrl: './workflow-duplicate-modal.component.html',
  styleUrls: ['./workflow-duplicate-modal.component.scss']
})
export class WorkflowDuplicateModalComponent {
  @Input() data: BigCreateWorkflow | BigWorkflowOffer;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private workflowService: WorkflowService) {
  }

  next(nextData: ArticleEither): void {
    const article = nextData.article;
    const data = article ? {article: article} : {};

    this.workflowService.copy(this.data.workflow.object.id, data as WorkflowSimpleArticleForm).subscribe(response => {
      this.alertService.add('success', 'Vorgang erfolgreich kopiert!');
      this.activeModal.close(response);
    }, () => {

      this.alertService.add('danger', 'Fehler beim Kopieren des Vorganges');
    });
  }

}
