/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject} from '../../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {BusinessOrderDateChangeForm} from '../models/business-order-date-change.models';

@Injectable({providedIn: 'root'})
export class BusinessOrderDateChangeService {

  private url = '/api/v1/workflow/business/order/update/date/';

  constructor(private http: HttpClient) {}

  update(baId: number, index: number, form: BusinessOrderDateChangeForm): Observable<boolean> {
    return this.http.put<EnvisiaObject<boolean>>(
      this.url + baId + '/' + index + '/', form).pipe(map(value => value.object));
  }

}
