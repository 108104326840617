/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PriceBoxForm, PriceCheckData} from '../box/price-box.models';
import {
  PriceHistory,
  PriceHistoryForm,
  PriceHistoryHistory,
  PriceListData,
  PriceMain
} from '../price.models';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {ListService} from '../../../common/search/search.service';
import {Article} from '../../article/models/article.models';
import {map} from 'rxjs/operators';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class PriceService implements ListService<PriceListData> {

  private priceUri = '/price/v1/price/';
  private calculateUri = '/price/v1/calculate/';

  constructor(private http: HttpClient) {
  }

  list(query?: { [paramName: string]: any; }): Observable<Lister<PriceListData>> {
    return this.http.get<Lister<PriceListData>>('/price/v1/list/');
  }

  calculate(article: string, form: PriceBoxForm): Observable<PriceCheckData> {
    return this.http.post<PriceCheckData>(this.calculateUri + article + '/', form);
  }

  save(article: string, form: PriceHistoryForm): Observable<PriceMain> {
    return this.http.put<PriceMain>(this.priceUri + article + '/', form);
  }

  detail(article: string): Observable<PriceMain> {
    return this.http.get<PriceMain>(this.priceUri + article + '/');
  }

  update(id: number, value: any): Observable<PriceHistory> {
    return this.http.put<EnvisiaObject<PriceHistory>>(
      '/price/v1/update/' + id + '/', value
    ).pipe(map(response => response.object));
  }

  updateNotes(id: number, notes: string): Observable<PriceHistory> {
    return this.http.put<EnvisiaObject<PriceHistory>>(
      '/price/v1/update/notes/' + id + '/',
      {value: notes}
    ).pipe(map(response => response.object));
  }

  approve(id: number): Observable<void> {
    return this.http.post<void>('/price/v1/approve/' + id + '/', {});
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiv4uri}price/history/${id}`);
  }

  articleCalc(article: string, form: { de: string, cn: string }): Observable<Article> {
    return this.http.post<EnvisiaObject<Article>>(
      '/price/v1/article/calculation/' + article + '/',
      form
    ).pipe(map(value => value.object));
  }

  articleCalcOverwrite(article: string, form: { de: string, cn: string }): Observable<Article> {
    return this.http.put<EnvisiaObject<Article>>(
      '/price/v1/article/calculation/' + article + '/',
      form
    ).pipe(map(value => value.object));
  }

  recalculate(article: string, typ: 'de' | 'cn'): Observable<PriceHistory[]> {
    return this.http.post<EnvisiaObjects<PriceHistory>>(
      '/price/v1/recalculate/multiple/' + article + '/?typ=' + typ,
      {}
    ).pipe(map(value => value.objects));
  }

  recalculateSingle(id: number): Observable<PriceHistory> {
    return this.http.post<EnvisiaObject<PriceHistory>>(
      '/price/v1/recalculate/single/' + id + '/',
      {}
    ).pipe(map(value => value.object));
  }

  manual(id: number): Observable<PriceHistory> {
    return this.http.post<EnvisiaObject<PriceHistory>>(
      '/price/v1/manual/' + id + '/',
      {}
    ).pipe(map(value => value.object));
  }

  history(article: string, quantity: number, lose: number, release: number | null, typ: string): Observable<PriceHistoryHistory[]> {
    let params = {};
    if (release) {
      params = {
        quanity: quantity.toString(),
        lose: lose.toString(),
        release: release.toString(),
        typ: typ
      };
    } else {
      params = {
        quanity: quantity.toString(),
        lose: lose.toString(),
        typ: typ
      };
    }
    return this.http.get<EnvisiaObjects<PriceHistoryHistory>>(
      '/price/v1/history/' + article + '/',
      {params: EnvisiaLocation.httpParamsWrapper(params)},
    ).pipe(map(value => value.objects));
  }

  saveProducer(
    article: string,
    data: {quantity?: number, lose?: number, producer?: string},
  ): Observable<EnvisiaObject<PriceHistory> | EnvisiaObjects<PriceHistory>> {
    return this.http.put<EnvisiaObject<PriceHistory> | EnvisiaObjects<PriceHistory>>(
      '/price/v1/producer/' + article + '/',
      data
    );
  }

}
