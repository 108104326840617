import {Injectable} from '@angular/core';
import {ArticleSpecificationService} from '../services/article-specification.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Article} from '../models/article.models';
import {catchError, mergeMap} from 'rxjs/operators';
import {throwError, from, Observable} from 'rxjs';
import {ArticleRecoverModalComponent} from '../main/recover/article-recover.modal.component';

@Injectable({providedIn: 'root'})
export class ArticleRecoverService {

  constructor(private articleSpecificationService: ArticleSpecificationService,
              private alertService: AlertService,
              private modalService: NgbModal) {
  }

  recoverArticle(article: string): Observable<Article> {
    return this.articleSpecificationService.specification().pipe(
      catchError(error => {
        console.log('Modal Error', error);
        this.alertService.add('danger', 'Ein unbekannter Fehler ist aufgetreten!');
        return throwError('modal error');
      }),
      mergeMap(data => {
        const modalRef = this.modalService.open(ArticleRecoverModalComponent, {windowClass: 'modal2-mm'});
        modalRef.componentInstance.oa = article;
        modalRef.componentInstance.notices = data['blocking_notice'].values;

        return from(modalRef.result);
      })
    );
  }

}
