import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../model/contact';
import {ContactService} from '../services/contact.service';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {debug} from '../../../helper/debug.func';

@Component({
  selector: 'contact-box-modal',
  templateUrl: './contact-box-modal.component.html',
  styleUrls: ['./contact-box-modal.component.scss']
})
export class ContactBoxModalComponent implements OnInit {
  @Input() currentContact: Contact | null;
  @Input() customerId: number;
  data: LoadingWrapper<Contact[]>;

  constructor(private contactService: ContactService,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.data = new LoadingWrapper(this.contactService.list(this.customerId));
  }

  select(selected: Contact | null, contactList: Contact[]): void {
    debug('Selected Contact:', selected, contactList);
    this.activeModal.close(selected);
  }

  cancel(): void {
    this.activeModal.dismiss(null);
  }

}
