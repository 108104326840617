import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {KanbanGeneralService} from './kanban.general.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../common/alert-service/alert.service';
import {KanbanApprovalData} from './kanban.model';
import {Lister} from '../../../common/wrapper.models';

@Component({
  selector: 'kanban-approval-modal',
  templateUrl: './kanban-approval-modal.component.html',
  styles: ['.pull-down { margin-top: 5px; }']
})
export class KanbanApprovalModalComponent implements OnInit {

  @Input() workflowId;
  @Output() clickevent = new EventEmitter<number>();
  approvalData: Lister<KanbanApprovalData> | any;
  form: UntypedFormGroup;
  list: KanbanApprovalData[];
  queryData: any = {start: null, end: null};

  constructor(private activeModal: NgbActiveModal,
              private service: KanbanGeneralService,
              private fb: UntypedFormBuilder,
              private alertService: AlertService) {}

  ngOnInit(): void {
    this.approvalData = {page: 1};
    this.buildForm();
    this.fetchData();
  }

  private fetchData() {
    this.service.approvalList(this.workflowId).subscribe(data => {
      this.approvalData = data;
      this.list = data.objects;
      const sum = this.list.reduce((a, b) => a + b.quantity, 0);
      this.clickevent.emit(sum);
    }, e => {
      console.error(e);
    });
  }

  save() {
    const data = this.form.getRawValue();
    this.service.save(this.workflowId, data).subscribe(() => {
      this.alertService.add('success', 'Produktionsfreigabe hinzugefügt');
      this.fetchData();
      const sum = this.list.reduce((a, b) => a + b.quantity, 0);
      this.activeModal.close(sum + this.form.getRawValue().quantity);
    }, error => {
      console.error(error);
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten');
    });
  }

  delete(id: number) {
    this.service.delete(id).subscribe(() => {
      this.alertService.add('success', 'Produktionsfreigabe gelöscht');
      this.fetchData();
    }, () => {
      this.alertService.add('danger', 'Produktionsfreigabe konnte nicht gelöscht werden');
    });
  }

  private buildForm() {
    this.form = this.fb.group({
      'quantity': [null],
      'note': [null]
    });
  }

  goto(page: number): void {
    this.queryData.page = page;
    this.call();
  }

  query(any?: any): any {
    return this.queryData;
  }

  private call(): void {
    this.service.approvalList(this.workflowId, this.query()).subscribe(value => {
      this.approvalData = value;
      this.list = value.objects;
    }, response => {
      console.log('Response Error', response);
    });
  }

}
