import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BusinessOrderWithReproduction} from '../../models/business-order.models';
import {BusinessOrderService} from '../../services/business-order.service';
import {Article, ArticleCore} from '../../../../article/models/article.models';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';
import {CommonType, InitData, Map as EvMap} from '../../../../../common/init-data/init-data';
import {LocalStorage} from '../../../../../common/storage/local-storage';
import {BusinessOrderArticleListElement} from './business-order-article-list.models';
import {FetchWithPositions} from '../../../models/frame.models';

@Component({
  selector: 'business-order-article-list',
  templateUrl: './business-order-article-list.component.html',
  styleUrls: ['./business-order-article-list.component.scss'],
})
export class BusinessOrderArticleListComponent implements OnInit, OnChanges {
  @Input() businessOrders: BusinessOrderWithReproduction[];
  @Input() fetches: FetchWithPositions[];
  @Input() changes: number;
  @Input() article: Article | ArticleCore;
  @Input() kanban: boolean;
  @Input() empb: boolean;
  @Input() workflowId: number;
  @Output() updateSum = new EventEmitter<number>();
  @Output() reloadInventory = new EventEmitter<any>();
  @Output() reproductionSelect = new EventEmitter<BusinessOrderWithReproduction>();
  businessOrderStatus: EvMap<CommonType>;

  list: BusinessOrderArticleListElement[];
  status = 'active';
  businessOrderOpenCount: LoadingWrapper<number>;

  constructor(private localStorage: LocalStorage,
              private businessOrderService: BusinessOrderService) {
  }

  ngOnInit(): void {
    this.businessOrderStatus = this.localStorage.getObject<InitData>('data').business_order_status;
    this.setBusinessOrderListWithFetches(this.businessOrders);
    this.businessOrderOpenCount =
      new LoadingWrapper<number>(this.businessOrderService.countOpenByArticle(this.article.oa_nr));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changes && !changes.changes.isFirstChange()) {
      this.businessOrderOpenCount =
        new LoadingWrapper<number>(this.businessOrderService.countOpenByArticle(this.article.oa_nr));
      this.reloadList();
    }
  }

  setState(status: string): void {
    this.status = status;
    this.reloadList();
  }

  reloadList(): void {
    this.reloadInventory.emit();
    this.businessOrderService.listByArticle(this.article.oa_nr, this.status).subscribe(list => {
      this.setBusinessOrderListWithFetches(list);
      this.businessOrderService.sumByArticle(this.article.oa_nr).subscribe(res => {
        this.updateSum.next(res);
      }, () => {});
    });
  }

  setBusinessOrderListWithFetches(list: BusinessOrderWithReproduction[]): void {
    this.list = list.map(bo => ({
      business_order: bo,
      fetches: bo.fetches.map(feId => this.fetches.find(fe => fe.id === feId)).filter(f => !!f),
    }) as BusinessOrderArticleListElement);
  }
}
