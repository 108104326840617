<ng-container [formGroup]="form">
  <div class="form-group">
    <label for="input-tg" class="col-sm-2 control-label">TG</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" id="input-tg" formControlName="tg"
             [ngClass]="{red_alert: errors[errorPrefix + 'tg']}">
    </div>
  </div>

  <div class="form-group">
    <label for="input-cti" class="col-sm-2 control-label">CTI</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" id="input-cti" formControlName="cti"
             [ngClass]="{red_alert: errors[errorPrefix + 'cti']}">
    </div>
  </div>

  <div class="form-group">
    <label for="input-plc" class="col-sm-2 control-label">PLC</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" id="input-plc" formControlName="plc"
             [ngClass]="{red_alert: errors[errorPrefix + 'plc']}">
    </div>
  </div>

  <div class="form-group">
    <label for="input-ipc_4101" class="col-sm-2 control-label">IPC 4101</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" id="input-ipc_4101" formControlName="ipc_4101"
             [ngClass]="{red_alert: errors[errorPrefix + 'ipc_4101']}">
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-offset-2 col-sm-10">
      <div class="checkbox">
        <label>
          <input type="checkbox" formControlName="halogen_free"
                 [ngClass]="{red_alert: errors[errorPrefix + 'halogen_free']}"> halogenfrei
        </label>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-offset-2 col-sm-10">
      <div class="checkbox">
        <label>
          <input type="checkbox" formControlName="de_compliant"
                 [ngClass]="{red_alert: errors[errorPrefix + 'de_compliant']}"> DE konform
        </label>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-offset-2 col-sm-10">
      <div class="checkbox">
        <label>
          <input type="checkbox" formControlName="china_compliant"
                 [ngClass]="{red_alert: errors[errorPrefix + 'china_compliant']}"> China konform
        </label>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="input-china_text" class="col-sm-2 control-label">China Text</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" id="input-china_text" formControlName="china_text"
             [ngClass]="{red_alert: errors[errorPrefix + 'china_text']}">
    </div>
  </div>

  <div class="form-group">
    <label for="priority" class="col-sm-2 control-label">Priorität</label>
    <div class="col-sm-10">
      <input type="number" class="form-control" id="priority" formControlName="priority"
             [ngClass]="{red_alert: errors[errorPrefix + 'priority']}">
    </div>
  </div>
</ng-container>
