import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';
import {CustomerCreditWorthiness} from '../customer-credit-worthiness.models';

@Component({
  selector: 'customer-credit-worthiness-alert',
  templateUrl: './customer-credit-worthiness-alert.component.html',
})
export class CustomerCreditWorthinessAlertComponent implements OnInit {
  @Input() customerCredit: CustomerCreditWorthiness;
  @ContentChild(TemplateRef, {static: true}) tmpl: TemplateRef<any>;
  customerCreditUsed: number;
  isCustomerCreditWorthy: boolean;
  constructor() { }

  ngOnInit(): void {
    this.setCustomerCredit();
  }

  setCustomerCredit(): void {
    this.customerCreditUsed = this.customerCredit.credit_used - this.customerCredit.credit_owed;
    this.isCustomerCreditWorthy = (
      ((this.customerCredit.credit_limit ?? null) === null) ||
      (this.customerCredit.credit_limit >= this.customerCreditUsed)
    );
  }
}
