<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-header">
    <h3 class="modal-title">
      Flexiblen Zuschlag bearbeiten
    </h3>
  </div>

  <div class="modal-body">
    <div class="row form-group">
      <label class="col-sm-4 control-label" for="increase">Zuschlag</label>
      <div class="col-sm-7 align-right">
        <input id="increase"
               class="form-control align-right"
               type="text"
               formControlName="increase"
               [ngClass]="{'red_alert': errors['increase']}">
      </div>
      <label class="col-sm-1 control-label" for="increase">%</label>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-danger pull-left" type="button" (click)="dismiss()">
      Abbrechen
    </button>

    <button class="btn btn-success pull-right" type="submit">
      Speichern
    </button>
  </div>
</form>
