/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {HolidayManageComponent} from './holiday-manage.component';
import {HolidayService} from './holiday.service';
import {Ng2StateDeclaration} from '@uirouter/angular';

export function holidayListResolveFn(holidayService: HolidayService) {
  return holidayService.list().toPromise();
}

export const HOLIDAY_STATES: Ng2StateDeclaration[] = [
  {name: 'a.holiday', abstract: true, data: {requiresAuth: true}},

  {
    name: 'a.holiday.manage',
    url:  '/holiday/manage',
    views: {'main@a': {component: HolidayManageComponent}},
    resolve: [
      {
        token: 'objects',
        resolveFn: holidayListResolveFn,
        deps: [HolidayService],
      }
    ],
    data: {requiresAuth: true}
  }

];
