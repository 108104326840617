import { NgModule } from '@angular/core';
import {FinderSearchComponent} from './finder-search.component';
import {SharedModule} from '../../../core/shared.module';
import { FinderSearchRightComponent } from './finder-search-right.component';
import { FinderSearchLeftComponent } from './finder-search-left.component';
import {FinderItemContentModule} from '../item-content/finder-item-content.module';


@NgModule({
  declarations: [
    FinderSearchComponent,
    FinderSearchRightComponent,
    FinderSearchLeftComponent,
  ],
  exports: [
    FinderSearchComponent,
  ],
  imports: [
    SharedModule,
    FinderItemContentModule
  ]
})
export class FinderSearchModule {
}
