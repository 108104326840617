import {ShippingInstructionTableRow} from '../../article/models/article-extra.models';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'delivery-condition-ou-table',
  templateUrl: './delivery-condition-ou-table.component.html'
})
export class DeliveryConditionOuTableComponent {
  @Input() tableName: string;
  @Input() deliveryConditionList: ShippingInstructionTableRow[] = [];

  constructor() {
  }
}
