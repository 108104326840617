import {Component, Input} from '@angular/core';

@Component({
  selector: 'article-button-box',
  templateUrl: './article-button-box.component.html'
})
export class ArticleButtonBoxComponent {
  @Input() article: string;
  @Input() workflowId: string | number;
}
