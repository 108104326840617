<ng-container [ngSwitch]="field.data_type" *ngIf="!fields[field.html_name]">
  <ng-container *ngSwitchCase="'Option'">
    <i class="fa" [ngClass]="boolValueClass"></i>
  </ng-container>
  <ng-container *ngSwitchCase="'Ganzzahl'">
    {{ active?.value | money:0 }}
  </ng-container>
  <ng-container *ngSwitchCase="'Kommazahl'">
    {{ active?.value | money:4 }}
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ active?.value }}
  </ng-container>
</ng-container>
