<button type="button" class="btn btn-default article-state-red"
        (click)="toggle('red')"
        [ngClass]="{active: includes('red')}">
  Artikelstatus
</button>

<button type="button" class="btn btn-default article-state-orange"
        (click)="toggle('orange')"
        [ngClass]="{active: includes('orange')}">
  Artikelstatus
</button>

<button type="button" class="btn btn-default article-state-green"
        (click)="toggle('green')"
        [ngClass]="{active: includes('green')}">
  Artikelstatus
</button>
