import * as React from 'react';
import {EvReactModal} from '../../../../../react/modal/EvReactModal';
import {ArticleMultilayerDrillData} from '../article-multilayer-plan.models';
import {ChangeEvent, FormEvent, ReactNode, useEffect, useState} from 'react';
import {EvConfirm} from '../../../../../react/modal/EvConfirm';
import {EvFormGroup} from '../../../../../react/form/EvFormGroup';

interface Props {
  model: ArticleMultilayerDrillData;
  title: string;
  children: ReactNode;
  resolveDeleted?: () => void;
  resolveEdit: (s: ArticleMultilayerDrillData) => void;
}

const drillDataTypes: string[] = ['Microvia', 'Via gefüllt', 'DK'];

export function ArticleMultilayerPlanDrillModal(props: Props) {
  const [model, modelSet] = useState<ArticleMultilayerDrillData>(props.model ?? {type: null, name: null});
  const [showModal, showModalSet] = useState<boolean>(false);
  const [showDeleteConfirm, showDeleteConfirmSet] = useState<boolean>(false);
  const formReady = model.type === 'Microvia' || model.type === 'Via gefüllt' || model.type === 'DK';

  useEffect(() => {
    modelSet(props.model ?? {type: null, name: null});
  }, [props.model]);

  const cleanModal: () => void = () => {
    modelSet(props.model ?? {type: null, name: null});
    showModalSet(false);
    showDeleteConfirmSet(false);
  };

  const toggleModal: (toggle: boolean) => void = (toggle) => {
    showModalSet(toggle);
  };

  const cancel: () => void = () => {
    cleanModal();
  };

  const askDelete: () => void = () => {
    showDeleteConfirmSet(true);
  };

  const resolveDelete: (confirmed: boolean) => void = (confirmed) => {
    if (confirmed && props.resolveDeleted) {
      props.resolveDeleted();
      cleanModal();
    } else {
      showDeleteConfirmSet(false);
    }
  };

  const saveData: (event: FormEvent) => void = (event) => {
    event.preventDefault();
    props.resolveEdit(model);
    cleanModal();
  };

  const setTypeValue: (event: ChangeEvent<any>) => void = (event) => {
    let typeValue = event.target.value;
    if ((typeValue !== 'Microvia' && typeValue !== 'Via gefüllt' && typeValue !== 'DK')) {
      typeValue = null;
    }

    modelSet({type: typeValue, name: model.name});
  };

  const setNameValue: (event: ChangeEvent<any>) => void = (event) => {
    modelSet({type: model.type, name: event.target.value === '' ? null : event.target.value});
  };

  return <>
    <div className={'click'} title={props.title} onClick={() => toggleModal(true)}>
      {props.children}
    </div>

    <EvReactModal showModal={showModal}
                  contentLabel='Bohrungstyp Wählen'
                  modalClosed={() => cancel()}>
      {
        showDeleteConfirm ?
          <EvConfirm response={confirmed => resolveDelete(confirmed)}>
            Soll diese Bohrung wirklich entfernt werden?
          </EvConfirm>
          :
          <form onSubmit={saveData}>
            <div className='modal-body'>

              <EvFormGroup nameValue={'type'}
                           nameLabel={'Typ'}
                           columnParts={8}
                           columnPartsLabel={2}>
                <select id='type'
                        className='form-control'
                        value={(model.type ?? '')}
                        onChange={setTypeValue}>
                  <option key={''} value={''}>-</option>
                  {drillDataTypes.map(dt => <option key={dt} value={dt}>{dt}</option>)}
                </select>
              </EvFormGroup>

              <EvFormGroup nameValue={'name'}
                           nameLabel={'Name'}
                           columnParts={8}
                           columnPartsLabel={2}>
                <input id='name' type='text'
                       className='form-control'
                       value={model.name ?? ''}
                       onChange={setNameValue}
                       autoFocus/>
              </EvFormGroup>

            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-danger float-left' onClick={() => cancel()}>
                Abbrechen
              </button>

              {!props.model ? null :
                <button type='button' className='btn btn-danger float-left' onClick={() => askDelete()}>
                  Löschen
                </button>
              }

              <button type='submit' className='btn btn-success float-right' disabled={!formReady}>
                Speichern
              </button>
            </div>
          </form>
      }
    </EvReactModal>
  </>;
}
