import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MaterialGroupListElement, MaterialValueType} from '../../../material/material-management.model';
import {MaterialManagementService} from '../../../material/material-management.service';
import {Lister} from '../../../../common/wrapper.models';
import {
  MaterialPlanningDate,
  MaterialPlanningListElement,
  MaterialPlanningListQuery,
  MaterialSelect
} from '../../../material/planning/material-planning.model';

@Component({
  selector: 'business-order-material-select-group',
  templateUrl: './business-order-material-select-group.component.html',
})
export class BusinessOrderMaterialSelectGroupComponent implements OnInit, OnChanges {
  @Input() groups: Lister<MaterialGroupListElement>;
  @Input() params: MaterialPlanningListQuery;
  @Input() selectionData: MaterialSelect;
  @Input() fieldValues: { id: number, value: string }[];
  @Input() currentSupplierId?: number;
  @Input() lockSelection: boolean;
  @Output() emitSelect = new EventEmitter<MaterialPlanningListElement>();
  @Output() emitSelectWeek = new EventEmitter<{ week: MaterialPlanningDate, variationId: number } | null>();
  fields: MaterialValueType[];
  fieldsLoaded = false;

  constructor(protected mms: MaterialManagementService) {
  }

  ngOnInit(): void {
    this.loadFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.params && !changes.params.isFirstChange()) {
      this.loadFields();
    }
  }

  loadFields(): void {
    this.fieldsLoaded = false;
    this.mms.listFormFieldClasses(this.params.group_id).subscribe(fields => {
      fields.forEach(f => {
        f.value = this.fieldValues.find(fv => fv.id == f.id)?.value;
      });
      this.fields = fields;
      this.fieldsLoaded = true;
    });
  }
}
