<form class="form form-inline" [formGroup]="businessOrderForm" (ngSubmit)="preSubmit()">
  <div class="row">

    <div class="col-lg-4">
      <div class="form-group">
        <label for="startDate">Start-Termin:</label>
        <ev-datepicker id="startDate" formControlName="start_date" class="form-control" [future]="true"></ev-datepicker>
      </div>

    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="endDate">End-Termin:</label>
        <ev-datepicker id="endDate" formControlName="end_date" class="form-control" [future]="true"></ev-datepicker>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="goldDate">Vergoldung bis:</label>
        <ev-datepicker id="goldDate" formControlName="gold_date" class="form-control" [future]="true"></ev-datepicker>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-lg-12"></div> <!-- Whitespace Row -->
  </div>

  <div class="row">

    <div class="col-lg-4">
      <div class="form-group">
        <label for="utilizeQuantity">Anzahl Nutzen:</label>
        <integer-input class="form-control" id="utilizeQuantity" formControlName="utilize_quantity"></integer-input>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="baAmount">Anzahl BAs:</label>
        <integer-input class="form-control" id="baAmount" formControlName="amount"></integer-input>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="orderedQuantity">bestellte Menge:</label>
        <integer-input class="form-control" id="orderedQuantity" formControlName="ordered_quantity"></integer-input>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-lg-4">

      <div class="form-group">
        <label for="firstContract">Erstauftrag:</label>
        <input type="checkbox" class="form-control" id="firstContract" formControlName="first_contract">
      </div>

    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="express">Interner Eildienst:</label>
        <input type="checkbox" class="form-control" id="express" formControlName="express">
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="custom_delivery_quantities">BA spezifische Unter-&nbsp;/&nbsp;&Uuml;berlieferung:</label>
        <input type="checkbox"
               class="form-control"
               id="custom_delivery_quantities"
               formControlName="custom_delivery_quantities">
      </div>
    </div>
  </div>

  <div class="row" *ngIf="this.businessOrderForm.value.custom_delivery_quantities">
    <div class="col-lg-4">
      <div class="form-group">
        <label for="under_delivery"><i class="fa fa-minus red-font"></i>&nbsp;Unterlieferung:</label>
        <input class="form-control" type="number" id="under_delivery" formControlName="under_delivery">
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="over_delivery"><i class="fa fa-plus green"></i>&nbsp;Überlieferung:</label>
        <input class="form-control" type="number" id="over_delivery" formControlName="over_delivery">
      </div>
    </div>
  </div>

  <div class="row" *ngIf="this.businessOrderForm.value.custom_delivery_quantities">
    <div class="col-lg-12">
      <div class="form-group" style="width: 100%;">
        <label for="custom_delivery_note">Notiz:</label>
        <textarea class="form-control"
                  id="custom_delivery_note"
                  rows="5"
                  style="width: 100%;"
                  formControlName="custom_delivery_note">
      </textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">&nbsp;</div> <!-- Whitespace Row -->
  </div>

  <div class="row">
    <div class="col-lg-12">

      <div class="label label-danger red_alert mid-label" *ngIf="!validHash">
        <strong>Achtung!</strong> Artikel wurde geändert. Fertigungsliste muss freigegeben werden.
      </div>

      <div class="label label-danger red_alert mid-label" *ngIf="plistEmpty">
        <strong>Achtung!</strong> Artikel wurde geändert. Fertigungsliste ist leer.
      </div>

      <business-order-create-multilayer-warning [errorMl]="errorMl" [invalidML]="invalidML">
      </business-order-create-multilayer-warning>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">&nbsp;</div> <!-- Whitespace Row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <article-warning [article]="article"></article-warning>
    </div>
  </div>

  <div *ngIf="true" style="margin-bottom: 20px;">
    <strong>
      Gesamtmenge Platinen: {{ (businessOrderForm.value.utilize_quantity * amountPerUtilize)|money }}
    </strong>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <button type="submit" class="btn btn-success"
              [disabled]="!businessOrderForm.valid || !validHash || !!article.date_deleted || !!article.locked || locked">
        Betriebsauftrag erstellen
        <ng-container *ngIf="locked">
          &nbsp;<i class="fa fa-circle-o-notch fa-spin"></i>
        </ng-container>
      </button>
    </div>
  </div>

</form>
