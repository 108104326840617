/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Alert, AlertService} from '../../common/alert-service/alert.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'main-alert',
  templateUrl: './main-alert.component.html',
})
export class MainAlertComponent implements OnInit, OnDestroy {
  alerts: Alert[] = [];
  private subscription?: Subscription;

  constructor(private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.subscription = this.alertService.getSubject().subscribe(alert => {
      this.alerts.push(alert);
      this.clearAlertAfterTimeout(alert.id, alert.timeout);
    }, () => { // error never happens
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeAlert(index: number): void {
    this.alerts.splice(index, 1);
  }

  private clearAlertAfterTimeout(id: string, timeout: number): void {
    setTimeout(() => {
      const index = this.alerts.findIndex(value => value.id === id);
      if (index !== -1) {
        this.alerts.splice(index, 1);
      }
    }, timeout);
  }

}
