import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContractAdditionalFormWithNr, WorkflowGroup} from '../../models/workflow-create.models';
import {
  BigFetchWithPosition,
  BigFetchWithPositionWithUse,
  FetchPosition,
} from '../../models/fetch.models';
import {FetchWithPositions, MergedFrame} from '../../models/frame.models';
import {FetchService} from '../../workflow-services/fetch.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {RightService} from '../../../../common/right-service/right.service';
import Helper from '../../../../helper/helper';
import {noop} from '../../../../helper/noop';
import {debug} from '../../../../helper/debug.func';
import {OfferExtraService} from '../../workflow-services/offer-extra.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContractCreateFetchAddModalComponent} from '../modals/contract-create-fetch-add-modal.component';
import {from, of, EMPTY, Subject} from 'rxjs';
import {ContractCreateFetchExtraAddModalComponent} from '../modals/contract-create-fetch-extra-add-modal.component';
import {catchError, mergeMap, takeUntil} from 'rxjs/operators';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {Article} from '../../../article/models/article.models';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';

@Component({
  selector: 'contract-create-form',
  templateUrl: './contract-create-form.component.html',
  styleUrls: [
    './contract-create.component.scss',
    './contract-create-form.component.scss'
  ],
})
export class ContractCreateFormComponent implements OnInit {
  @Input() workflow: WorkflowGroup;
  @Input() article: Article;
  @Input() inputForm: ContractAdditionalFormWithNr | undefined;
  @Input() priceIncreaseFlex?: PriceIncreaseFlex;
  @Output() updateList = new EventEmitter<void>();
  @Output() triggerPrice = new EventEmitter<boolean>();
  form: ContractAdditionalFormWithNr | { nr: true };
  createdLastFetchId: number | null = null;
  private frame: MergedFrame;

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private rightService: RightService,
              private fetchService: FetchService,
              private offerExtraService: OfferExtraService) {
  }

  ngOnInit(): void {
    if (this.inputForm) {
      this.form = this.inputForm;
    } else {
      this.form = {nr: true};
    }
    this.frame = this.workflow.frame;

    // initialize selected fetches
    this.workflow.frame.fetch.filter(val => {
      return Helper.correctlyDefined(val.delivery) && val.status === 0;
    }).forEach(fetch => {
      fetch.use = true;
    });
    setTimeout(() => {
      this.triggerPrice.emit(true);
    });
  }

  // -- track by fn
  trackByFetch(index: number, fetch: FetchWithPositions): number {
    noop(this, index);
    return fetch.id;
  }

  serviceAddFetch(): void {
    const modalRef = this.modalService.open(ContractCreateFetchAddModalComponent, {windowClass: 'modal2-smm'});
    modalRef.componentInstance.frame = this.frame;
    from(modalRef.result).subscribe((value: BigFetchWithPositionWithUse) => {
      if (this.workflow.frame.fetch) {
        this.workflow.frame.fetch.push(value);
      } else {
        this.workflow.frame.fetch = [value];
      }
      this.createdLastFetchId = value.id;
      if (value.delivery) {
        value.use = true;
        this.triggerPrice.emit(true);
      }
    }, error => {
      debug('Error:', error);
    });
  }

  serviceAddFetchExtra(): void {
    this.offerExtraService.list().pipe(
      catchError(() => {
        this.alertService.add('danger', 'Extra Modal konnte nicht geöffnet werden');
        return EMPTY;
      }),
      mergeMap(extraCosts => {
        const subject = new Subject<{ fetch: BigFetchWithPosition, index: number }>();
        const modalRef = this.modalService.open(
          ContractCreateFetchExtraAddModalComponent,
          {windowClass: 'modal2-1200'}
        );
        modalRef.componentInstance.frame = this.frame;
        modalRef.componentInstance.article = this.article;
        modalRef.componentInstance.subject = subject;
        modalRef.componentInstance.extraCosts = extraCosts;
        const modalObservable = from(modalRef.result).pipe(catchError(() => of(null)));

        return subject.asObservable().pipe(takeUntil(modalObservable));
      })
    ).subscribe(() => {
      this.updateList.emit();
    }, ignoreRejection);
  }

  serviceDropFetch(data: { fetchIndex: number, positionIndex: number, fetch: FetchWithPositions, position: FetchPosition; }): void {
    this.fetchService.remove(data.position.id).subscribe(() => {
      this.updateList.emit();
    }, () => {
      this.alertService.add('danger', 'Abrufposition konnte nicht gelöscht werden!');
    });
  }
}
