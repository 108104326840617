<ng-container *ngIf="!button">
  <div class="row">
    <div class="col-md-12">
      <h3>{{ name }}</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 min">

      <ng-container *ngIf="address">
        {{ address.street }}<br/>
        {{ address.zip }} {{ address.city }}<br/>
        {{ address.country }}
      </ng-container>

      <div class="alert alert-danger" *ngIf="!address && addresses?.length > 0">
        Bitte wählen
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">

      <button class="btn btn-default btn-sm" (click)="open()" *ngIf="rights" [disabled]="isEmpty()">
        Bearbeiten
      </button>

    </div>
  </div>
</ng-container>

<!-- renders the whole component as a button box-->
<ng-container *ngIf="button">
  <div class="btn btn-default button-box-text" (click)="open()" [ngClass]="{click: !isEmpty()}">
    <ng-container *ngIf="address">
      {{ address.street }}<br/>
      {{ address.zip }} {{ address.city }}<br/>
      {{ address.country }}
    </ng-container>
  </div>
</ng-container>
