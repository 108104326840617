<div class="modal-header red_alert">
  <h3 class="modal-title">ACHTUNG!</h3>
</div>

<div class="modal-body red_alert">
  <div *ngIf="message === 'ProductionOrOrder'">
    Mindest Lagerbestand unterschritten. Bitte Produktionsauftrag oder Bestellung erstellen.
  </div>
  <div *ngIf="message === 'MinimumInventoryAndCustomerApproval'">
    Mindest Lagerbestand unterschritten und Produktionsfreigabe erreicht. Bitte Kundenfreigabe einholen.
  </div>
  <div *ngIf="message ==='CustomerApproval'">
    Produktionsfreigabe erreicht. Bitte Kundenfreigabe einholen.
  </div>
  <div *ngIf="message ==='MinimumInventory'">
    Mindest Lagerbestand unterschritten.
  </div>
</div>

<div class="modal-footer red_alert">
  <button class="btn btn-default pull-left" (click)="close()">Schließen</button>
</div>
