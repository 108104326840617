import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
  MaterialArticle,
  MaterialUnit,
  MaterialValueType,
  MaterialVariation,
  MaterialVariationError,
} from '../material-management.model';
import {MaterialManagementService} from '../material-management.service';

@Component({
  selector: 'tr[material-variation-row]',
  templateUrl: './material-variation-row.component.html',
  styles: ['.inline-tr { display: inline; }']
})
export class MaterialVariationRowComponent implements OnInit, OnChanges {
  @Input('material-variation-row') variation: MaterialVariation | null;
  @Input('material-variation-row-form') variationForm: UntypedFormGroup;
  @Input('material-variation-row-index') index: number = 0;
  @Input('material-variation-row-field-def') variationFieldDefs: MaterialValueType[];
  @Input('material-variation-row-current-article') currentArticle: MaterialArticle;
  @Input('material-variation-row-error') error: MaterialVariationError;
  @Input('material-variation-row-units') units: MaterialUnit[];
  @Output('material-variation-row-delete-row') deleteRowEmitter = new EventEmitter<number>();

  constructor(private mmService: MaterialManagementService) {
  }

  ngOnInit() {
    this.patchIndex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.index && !changes.index.isFirstChange()) {
      this.patchIndex();
    }
    if (changes.variationForm && !changes.variationForm.isFirstChange()) {
      this.patchIndex();
    }
  }

  deleteRow(): void {
    this.deleteRowEmitter.emit(this.index);
  }

  patchIndex(): void {
    this.variationForm.patchValue({'index': this.index});
  }

  deleteVariation(): void {
    if (confirm('Achtung!\nArtikel Variation wirklich löschen?\nInhalte werden entgültig gelöscht.')) {
      this.mmService.deleteVariation(this.variation.id).subscribe(result => {
        if (result.date_deleted) {
          this.deleteRow();
        }
      }, _ => {
      });
    }
  }
}
