import {Component, Input, OnInit} from '@angular/core';
import {CalculationService} from './calculation.service';
import {
  CalculationField,
  CalculationLayout,
  CalculationProcess,
  CalculationTable,
  CalculationTableRow
} from './calculation.model';
import {StateService} from '@uirouter/angular';
import {AlertService} from '../../common/alert-service/alert.service';

@Component({
  selector: 'calculation-table',
  templateUrl: './calculation-table.component.html',
})
export class CalculationTableComponent implements OnInit {
  @Input() data: CalculationTable;
  layout: CalculationLayout;
  active: number;
  process: CalculationProcess;
  table: CalculationTableRow[][];
  headers: CalculationField[];
  limiter: number;

  errors: { [key: string]: any; } = {};

  constructor(private calculationService: CalculationService, private stateService: StateService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    const data = this.data;
    this.process = data.process;
    this.layout = data.layout;
    this.table = data.rows;
    this.headers = data.header;
    this.limiter = data.header.length;
    this.active = null;

  }

  setActive(index: number): void {
    this.active = index;
  }

  create() {
    this.errors = {};
    this.calculationService.createRow(this.process.id, this.layout.id, this.table.length + 1).subscribe(response => {
      this.table.push(response);
      this.alertService.add('success', 'Kalkulation erfolgreich erstellt!');
    }, response => {
      this.errors = response.error;
      this.alertService.add('danger', 'Fehler beim Absenden des Requests!');
    });
  }

  deleteRow(row: number) {
    this.errors = {};
    const observable = this.calculationService.deleteRow(this.process.id, this.layout.id, row + 1);
    observable.subscribe(() => {
      this.table = this.table.filter((r, i) => i !== row);
    }, response => {
      this.errors = response.error;
      this.alertService.add('danger', 'Fehler beim Löschen!');
    });
  }

  edit(column: CalculationTableRow): void {
    this.errors = {};
    column.error = false;

    if (column.id === null) {
      const data = {'field_id': column.field.id, 'value': column.value};
      this.calculationService.createCell(this.process.id, this.layout.id, column.row, data).subscribe(response => {
        column.id = response.id;
        column.value = response.value;
        this.alertService.add('success', 'Kalkulationstabellenwert gespeichert');
      }, () => {
        column.error = true;
      });
    } else {
      const data = {'field_id': column.field.id, 'value': column.value};
      this.calculationService.updateCell(column.id, data).subscribe(response => {
        column.value = response.value;
        this.alertService.add('success', 'Kalkulationstabellenwert gespeichert');
      }, () => {
        column.error = true;
      });
    }
  }

}
