<div class="row">
  <div class="col-lg-12" id="article-navigation">

    <div class="navigation">
      <a uiSref="a.workflow.detail" [uiParams]="{id: workflowId}"
         ngbTooltip="Zurück zum Vorgang ohne Änderungen zu sichern"
         placement="bottom" *ngIf="(workflowId && 'task.view'|HasPerm)">
        <i class="fa fa-chevron-left"></i> Vorgang: {{ workflowId }}
      </a>

      <a uiSref="a.article.workflow.detail" [uiParams]="{id: article.oa_nr, workflow_id: workflowId}"
         [ngClass]="{active: state.includes('a.article.workflow.detail')}">
        Übersicht
      </a>

      <a uiSref="a.article.workflow.overview" [uiParams]="{id: article.oa_nr, workflow_id: workflowId}"
         [ngClass]="{active: state.includes('a.article.workflow.overview')}">
        Angebotsansicht
      </a>

      <a uiSref="a.article.workflow.history" [uiParams]="{id: article.oa_nr, workflow_id: workflowId}"
         [ngClass]="{active: state.includes('a.article.workflow.history')}">
        Änderungsverlauf
      </a>

      <a uiSref="a.article.workflow.extra" [uiParams]="{id: article.oa_nr, workflow_id: workflowId}"
         [ngClass]="{active: state.includes('a.article.workflow.extra')}">
        Liefervorschriften
      </a>

      <a uiSref="a.article.workflow.multilayer"
         [uiParams]="{id: article.oa_nr, workflow_id: workflowId}"
         [ngClass]="{active: state.includes('a.article.workflow.multilayer')}"
         *ngIf="isMl && ('article.multilayerplan.change'|HasPerm)">
        Multilayeraufbauplan
        <article-multilayer-difference-warning [article]="article"
                                               [multilayerPlan]="multilayerPlan">
        </article-multilayer-difference-warning>
      </a>

      <a uiSref="a.article.production.list" [uiParams]="{id: article.oa_nr, workflow_id: workflowId}"
         [ngClass]="{active: state.includes('a.article.production.list')}">
        Fertigungsliste <i class="fa fa-exclamation-triangle" *ngIf="invalidHash"></i>
      </a>

      <a uiSref="a.article.document.list" [uiParams]="{id: article.oa_nr, workflow_id: workflowId}"
         [ngClass]="{active: state.includes('a.article.document')}">
        Dokumente
      </a>

      <a uiSref="a.workflow.request.list" [uiParams]="{oa_nr: article.oa_nr}" target="_blank">
        Anfragen
      </a>

      <a uiSref="a.workflow.order.list"
         [uiParams]="{oa_nr: article.oa_nr, order: 'ASC', sort: 'workflow_order_delivery_date'}"
         [ngClass]="{active: state.includes('a.workflow.order.list')}"
         target="_blank">
        Bestellungen
      </a>

      <a [href]="link + article.sds_nr" target="_blank" *ngIf="!!article.sds_nr && intranetLink.data$ | async as link">
        Intranet
      </a>

    </div>

  </div>
</div>
