import {Transition, Ng2StateDeclaration} from '@uirouter/angular';
import {CustomerService} from '../services/customer.service';
import {ContactService} from '../services/contact.service';
import {CustomerNavigationComponent} from '../navigation/customer-navigation.component';
import {CustomerContactListComponent} from './list/customer-contact-list.component';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';

export function contactListResolveFn(transition: Transition,
                                     customerService: CustomerService,
                                     contactService: ContactService) {
  const customerObs = customerService.detail(transition.params().id);
  const contactObs = contactService.list(transition.params().id);
  return forkJoin([customerObs, contactObs]).pipe(
    map(response => {
      return {customer: response[0], contacts: response[1]};
    })
  ).toPromise();
}

export const CONTACT_LIST_RESOLVE = {
  token: 'data',
  resolveFn: contactListResolveFn,
  deps: [Transition, CustomerService, ContactService]
};

export const CUSTOMER_CONTACTS_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer.contacts',
  url: '/crm/{id:[0-9]+}/contacts',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: CustomerContactListComponent}
  },
  resolve: [CONTACT_LIST_RESOLVE],
  data: {requiresAuth: true}
};
