import {Injectable} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {LocalStorage} from '../common/storage/local-storage';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection = null;

  public startConnection = (afterConnected: () => void | null = null) => {
    if (this.hubConnection !== null) {
      return;
    }

    let uri = '';
    const baseUri = environment.apiv4uri;
    if (baseUri.indexOf('://') !== -1) {
      uri = baseUri;
    } else {
      uri = location.protocol + '//' + location.host + environment.apiv4uri;
    }
    uri += 'stream';

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(uri, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    this.hubConnection
      .start()
      .then(() => {
        if (afterConnected) {
          afterConnected();
        }
        this.hubConnection.onclose(() => console.log('Connection closed'));
      })
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  public stopConnection = () => {
    this.hubConnection
      .stop()
      .then(() => {
        console.log('Connection stopped');
        this.hubConnection = null;
      })
      .catch(err => console.log('Error while stopping connection: ' + err));
  }

  public on(methodName: string, newMethod: (...args: any[]) => void): void {
    this.hubConnection.on(methodName, newMethod);
  }

  public off(methodName: string): void {
    this.hubConnection.off(methodName);
  }
}
