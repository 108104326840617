import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {ErpTransition} from '../../core/erp-transition.service';
import {ArticleMultilayerPlanService} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.service';
import {getArticleSpecificationResolveFn} from '../../envisia/article/article.states';
import {ArticleSpecificationService} from '../../envisia/article/services/article-specification.service';
import {MultilayerPlanCreateHolderComponent} from './multilayer-plan-create-holder.component';
import {MultilayerPlanUpdateHolderComponent} from './multilayer-plan-update-holder.component';
import {MultilayerPlanAxiosService} from './multilayer-plan-axios.service';

function getMultilayerPlanResolveFn(transition: ErpTransition, service: ArticleMultilayerPlanService) {
  return service.getById(transition.params().id).toPromise();
}

function getMultilayerPlanListResolveFn() {
  return MultilayerPlanAxiosService.listPromise();
}

const MULTILAYER_PLAN_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.multilayerplan.create',
  url: '/create',
  data: {requiresAuth: true},
  views: {'main@a': {component: MultilayerPlanCreateHolderComponent}},
  resolve: [
    {token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]},
    {token: 'list', resolveFn: getMultilayerPlanListResolveFn, deps: []},
  ]
};

const MULTILAYER_PLAN_UPDATE_STATE: Ng2StateDeclaration = {
  name: 'a.multilayerplan.update',
  url: '/update/{id}',
  data: {requiresAuth: true},
  views: {'main@a': {component: MultilayerPlanUpdateHolderComponent}},
  params: {
    id: {dynamic: true},
  },
  resolve: [
    {token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]},
    {token: 'list', resolveFn: getMultilayerPlanListResolveFn, deps: []},
    {token: 'multilayerPlan', resolveFn: getMultilayerPlanResolveFn, deps: [Transition, ArticleMultilayerPlanService]},
  ]
};

export const MULTILAYER_PLAN_STATES: Ng2StateDeclaration[] = [
  {
    name: 'a.multilayerplan',
    url: '/multilayerplan',
    abstract: true,
    data: {requiresAuth: true},
  },
  MULTILAYER_PLAN_CREATE_STATE,
  MULTILAYER_PLAN_UPDATE_STATE,
];
