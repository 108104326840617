import {Component, Input} from '@angular/core';
import {
  ContractAdditionalFormWithNr,
  BigCreateWorkflow,
} from '../../models/workflow-create.models';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';
import {ContractService} from '../../workflow-services/contract.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {EnvisiaFileService} from '../../../../common/envisia-file-button/envisia-file.service';
import {StateService} from '@uirouter/angular';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {ContractCreateFrameAddModalComponent} from '../modals/contract-create-frame-add-modal.component';
import {
  ContractCreateDeliveryDateErrorModalComponent
} from '../modals/contract-create-delivery-date-error-modal.component';
import {ContractCreateService} from '../services/contract-create.service';
import {DeliveryDateError} from '../contract.models';
import {ContractForm} from '../../models/contract.models';
import {ContractCreateOrderNrErrorModalService} from '../modals/contract-create-order-nr-error-modal.service';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';
import {WorkflowService} from '../../workflow-services/workflow.service';
import {FetchWithPositions} from '../../models/frame.models';

@Component({
  selector: 'contract-create',
  templateUrl: './contract-create.component.html',
  styleUrls: ['./contract-create.component.scss']
})
export class ContractCreateComponent {
  @Input() data: BigCreateWorkflow;
  additionalFormData: ContractAdditionalFormWithNr | undefined;
  hasPrice = false;

  constructor(private modalService: NgbModal,
              private stateService: StateService,
              private alertService: AlertService,
              private contractService: ContractService,
              private envisiaFileService: EnvisiaFileService,
              private workflowService: WorkflowService,
              private contractCreateService: ContractCreateService,
              private contractCreateOrderNrErrorModalService: ContractCreateOrderNrErrorModalService) {
  }

  updateAdditionalForm(form: ContractAdditionalFormWithNr): void {
    this.additionalFormData = form;
  }

  addFrame(): void {
    const modalRef = this.modalService.open(ContractCreateFrameAddModalComponent, {windowClass: 'modal2-smm'});
    modalRef.componentInstance.workflow = this.data.workflow.object;
    from(modalRef.result).subscribe(value => {
      this.data.additional = value.additional;
      this.data.workflow.frame = value.value;
    }, ignoreRejection);
  }

  get createLocked(): boolean {
    return (
      ((this.data.workflow.frame?.fetch ?? []).filter(f => f.use).length < 1) ||
      !this.data.workflow.frame?.approval ||
      !!this.data.article.date_deleted ||
      (!!this.data.article.locked && this.data.workflow.frame.typ === 'de') ||
      !this.hasPrice
    );
  }

  create(clb?: LoadingButtonEvent): void {
    const filteredFetches = Array.from(this.data.workflow.frame.fetch.filter(f => f.use));
    const data: ContractForm = {
      fetch: filteredFetches.map(f => f.id),
      order_nr: this.additionalFormData.order_nr,
      advice: this.additionalFormData.advice,
      workflow: this.data.workflow.object.id,
      contract_date: this.additionalFormData.contract_date,
      order_date: this.additionalFormData.order_date,
      nr: this.additionalFormData.nr
    };

    const callbackHook = () => {
      if (clb) {
        clb.callback(false);
      }
    };

    let orderNrValid = true;
    filteredFetches.forEach(fetch => {
      const orderMissing = !fetch.order_nr;
      orderNrValid = orderNrValid && !orderMissing || (!fetch.delivery === orderMissing);
    });

    const deliveryErrorHook = (errors: DeliveryDateError[]) => {
      const modalRef = this.modalService.open(ContractCreateDeliveryDateErrorModalComponent);
      modalRef.componentInstance.errors = errors;
      modalRef.componentInstance.form = data;
      modalRef.componentInstance.workflowId = this.data.workflow.object.id;
      modalRef.componentInstance.customerId = this.data.customer.id;
      from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
    };

    this.contractCreateOrderNrErrorModalService.openIfInvalid(orderNrValid).subscribe(() => {
      this.contractCreateService.create(
        this.data.workflow.object.id,
        this.data.customer.id,
        false,
        data,
        deliveryErrorHook,
        callbackHook,
        callbackHook
      );
    }, () => {
      callbackHook();
    });
  }

  triggerPrice() {
    this.hasPrice = this.checkHasPrice();
  }

  checkHasPrice(): boolean {

    const data = [];
    const contractDate = this.additionalFormData ? this.additionalFormData.contract_date : undefined;
    if (this.data.workflow.frame && this.data.workflow.frame.fetch && contractDate) {
      this.data.workflow.frame.fetch.filter(f => f.use).forEach((ele) => {

        const total_price = ele.positions[0].total_price !== undefined && ele.positions[0].total_price !== '' &&
          ele.positions[0].total_price !== null;
        const quanity = ele.positions[0] !== undefined && ele.positions[0].quanity !== null &&
          ele.positions[0].quanity !== '';

        data.push(total_price && quanity);
      });
    }

    return data.length === 0 ? false : data.every((v) => v === true);
  }

  updatePriceIncreaseFlex(priceIncreaseFlex: PriceIncreaseFlex): void {
    this.data.price_increase_flex = priceIncreaseFlex;
  }

  updateList(): void {
    this.workflowService.detail(this.data.workflow.object.id).subscribe(w => {
      const positionsNew: FetchWithPositions[] = [];
      w.frame.fetch.forEach(fetch => {
        positionsNew.push({...fetch, use: this.data.workflow.frame.fetch.find(f => f.id === fetch.id)?.use === true});
      });

      this.data.workflow.frame.fetch = positionsNew;
    }, ignoreRejection);
  }
}
