<div class="xsmall-form" [formGroup]="positionForm">
  <div class="row">
    <h3>Positionen</h3>
  </div>

  <div class="row">
    <div class="form-group">
      <div class="col-sm-4">Menge</div>

      <div class="col-sm-4">Lose</div>

      <div class="col-sm-4">&nbsp;</div>
    </div>
  </div>

  <ng-container formArrayName="positions">
    <div class="row" *ngFor="let position of positions.controls; let i=index">

      <div class="form-group" [formGroup]="asFormGroup(position)">

        <div class="col-sm-4">
          <!-- ng-class="{red_alert: $ctrl.fieldError('quantity')}" -->
          <input type="text"
                 class="form-control"
                 id="input-quanity"
                 formControlName="quantity"
                 [ngClass]="{'red_alert': errors['obj.positions[' + i + '].quantity']}">
        </div>

        <div class="col-sm-4">
          <!-- ng-class="{red_alert: $ctrl.fieldError('lose')}" -->
          <input type="number"
                 class="form-control"
                 id="input-lose"
                 formControlName="lose"
                 [ngClass]="{'red_alert': errors['obj.positions[' + i + '].lose']}">
        </div>

        <div class="col-sm-2">

          <button class="btn btn-link red_font" *ngIf="positions.length > 1" (click)="remove(i)">
            <i class="fa fa-minus-circle fa-2x"></i>
          </button>
        </div>
        <div class="col-sm-2">
          <button class="btn btn-link green" *ngIf="(positions.length - 1) === i" (click)="add()">
            <i class="fa fa-plus-circle fa-2x"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
