import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductionTemplateCreateComponent} from './production-template-create.component';
import {ProductionTemplateEditComponent} from './production-template-edit.component';
import {ProductionTemplateStepEditComponent} from './production-template-step-edit.component';
import {
  ProductionStepEntity,
  ProductionTemplate,
  StepEntity
} from '../../../envisia/article/production/production.models';
import {ProductionTemplateService} from '../../../envisia/article/production-services/production-template.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {deepCopy} from '../../../helper/deep-copy';
import {noop} from '../../../helper/noop';

@Component({
  selector: 'production-template',
  templateUrl: './production-template.component.html',
})
export class ProductionTemplateComponent {
  @Input() templateList: ProductionTemplate[];
  @Input() stepList: StepEntity[];
  @Input() steps: ProductionStepEntity[];

  selected: ProductionTemplate | undefined;

  constructor(private productionTemplateService: ProductionTemplateService,
              private modalService: NgbModal,
              private alertService: AlertService) {
  }

  detail(obj: ProductionTemplate) {
    this.productionTemplateService.stepList(obj.name).subscribe((value) => {
      this.stepList = value;
      this.selected = obj;
    });
  }

  templateCreate() {
    const modalRef = this.modalService.open(ProductionTemplateCreateComponent, {windowClass: 'modal2-mm'});
    modalRef.result.then(value => {
      this.templateList.push(value);
    }, () => { // ignore modal backdrop
    });
  }

  templateEdit(index: number, obj: ProductionTemplate) {
    const modalRef = this.modalService.open(ProductionTemplateEditComponent, {windowClass: 'modal2-mm'});
    modalRef.componentInstance.data = obj;
    modalRef.result.then(value => {
      this.templateList[index] = value;
    }, ignoreRejection);
  }

  templateDelete(index: number, obj: ProductionTemplate) {
  }

  stepAdd(obj: ProductionStepEntity) {
    this.productionTemplateService.stepAdd(this.selected.name, obj.id).subscribe((value) => {
      this.stepList.push({step: obj, template: value});
    }, () => {
      this.alertService.add('danger', 'Schritt konnte nicht hinzugefügt werden!');
    });
  }

  stepDelete(index: number, obj: StepEntity) {
    if (confirm('Möchten Sie den Schritt (' + obj.step.nr + ') ' + obj.step.text + ' von der Vorlage entfernen?')) {
      this.productionTemplateService.stepDelete(obj.template.id).subscribe(() => {
        this.stepList.splice(index, 1);
      });
    }
  }

  stepDown(index: number, obj: StepEntity) {
    this.productionTemplateService.stepDown(obj.template.id).subscribe(() => {
      const copy = this.stepList[index];
      this.stepList[index] = this.stepList[index - 1];
      this.stepList[index - 1] = copy;
    });
  }

  stepUp(index: number, obj: StepEntity) {
    this.productionTemplateService.stepUp(obj.template.id).subscribe(() => {
      const copy = this.stepList[index];
      this.stepList[index] = this.stepList[index + 1];
      this.stepList[index + 1] = copy;
    });
  }

  stepEdit(index: number, obj: StepEntity) {
    const modalRef = this.modalService.open(ProductionTemplateStepEditComponent);
    modalRef.componentInstance.data = deepCopy(obj);
    modalRef.result.then((value: StepEntity) => {
      this.stepList[index].template.expression = value.template.expression;
    }, ignoreRejection);
  }

  last(index): boolean {
    noop(this);
    return this.stepList.length === index + 1;
  }

}
