import * as React from 'react';
import {ReactNode} from 'react';
import {FormatNumber} from '../formatters/format-number';
import {ArticleMultilayerDrillData, ArticleMultilayerPlanModel} from '../article-multilayer-plan.models';
import {ArticleMultilayerPlanDrillModal} from '../drill-modal/article-multilayer-plan-drill-modal';
import {EvEmptyContainer} from '../../../../../react/empty/EvEmptyContainer';

interface Props {
  cycles: number;
  children: ReactNode[];
  model: ArticleMultilayerPlanModel;
  refreshLayerLabels: () => void;
  drillColumnEditLocked: boolean;
  drillColumnEditLockedToggled: () => void;
  drillColumnAdd: (index: number, data: ArticleMultilayerDrillData) => void;
  drillColumnEdit: (index: number, data: ArticleMultilayerDrillData) => void;
  drillColumnRemove: (index: number) => void;
}

export function ArticleMultilayerPlanTable(props: Props) {
  const thickness = props.model.thickness_cycle;
  const thicknessHeaders = thickness.slice(0, thickness.length - 1).map((ct, i) => {
    return <th key={ct.cycle} style={{width: '65px'}} className={'align-right'}>
      {
        i === 0 ?
        <div title={`Verpresste Dicke aus Presszyklus ${ct.cycle}`} className={'fontsize10'}>
          Dicke nach
        </div> : null
      }
      <div title={`Verpresste Dicke aus Presszyklus ${ct.cycle}`}>VP&nbsp;{ct.cycle}</div>
    </th>;
  });

  const thicknessTotalCycle = thickness.slice(0, thickness.length - 1).map(ct => {
    return <td className={'align-right'} key={ct.cycle}>
      <div title={`Gesamte verpresste Enddicke aus Presszyklus ${ct.cycle}`}>
        <FormatNumber value={ct.thickness > 0 ? ct.thickness / 1000 : 0} decimals={3}/>&nbsp;mm
      </div>
    </td>;
  });

  return <>
    <table className={'table whitetable'} style={{'height': '1px'}}>
      <thead>
      <tr>
        <th style={{width: '50px'}} colSpan={props.cycles + 1}>Presszyklus</th>
        <th style={{width: '20px'}} className={'align-right'}>Lage</th>
        <th style={{width: '80px'}}>
          <div className={'click'} style={{width: '20px'}} onClick={() => props.refreshLayerLabels()}>
            <i className={'fa fa-refresh'}></i>
          </div>
        </th>
        <th key={-1} className={'drill-cell'}>
          <div className={'click'}
               title={'Bohrfile editieren: ' + (props.drillColumnEditLocked ? 'Inaktiv' : 'Aktiv')}
               onClick={() => props.drillColumnEditLockedToggled()}>
            <i className={'fa fa-' + (props.drillColumnEditLocked ? 'lock' : 'unlock-alt')}></i>
          </div>

          <ArticleMultilayerPlanDrillModal model={null}
                                           title={'neue Bohrfile einfügen'}
                                           resolveEdit={m => props.drillColumnAdd(0, m)}>
            <i className={'fa fa-plus-circle green'}/>
          </ArticleMultilayerPlanDrillModal>
        </th>
        {(props.model.drill_data ?? []).map((dd, i) => {
          return <EvEmptyContainer key={i}>
            <th className={'drill-cell rotated'}>
              <div className={'drill-cell-outer'}>
                <div className={'drill-cell-inner'} title={`${dd.name} (${dd.type})`}>
                  {dd.name}
                </div>
              </div>
              <ArticleMultilayerPlanDrillModal model={dd}
                                               title={`${dd.name} (${dd.type})`}
                                               resolveDeleted={() => props.drillColumnRemove(i)}
                                               resolveEdit={m => props.drillColumnEdit(i, m)}>
                <i className={'fa fa-pencil'}/>
              </ArticleMultilayerPlanDrillModal>
            </th>

            <th className={'drill-cell'}>
              <ArticleMultilayerPlanDrillModal model={null}
                                               title={'neue Bohrfile einfügen'}
                                               resolveEdit={m => props.drillColumnAdd(i + 1, m)}>
                <i className={'fa fa-plus-circle green'}/>
              </ArticleMultilayerPlanDrillModal>
            </th>
          </EvEmptyContainer>;
        })}
        <th style={{width: '200px'}}>Komponente</th>
        <th style={{width: '300px'}}>Typ</th>
        <th style={{width: '85px'}}>Cu-Fläche</th>
        <th style={{width: '50px'}} className={'align-right'}>Enddicke</th>
        {thicknessHeaders}
        <th>Abstand <i className={'fa fa-arrow-down'}/></th>
        <th>Abstand <i className={'fa fa-arrow-up'}/></th>
        <th>{props.children[0]}</th>
        <th/>
      </tr>
      </thead>
      <tbody style={{'height': '100%'}}>
      {props.children[1]}
      </tbody>
      <tfoot>
      <tr>
        <td colSpan={props.cycles + 4}/>
        <td colSpan={((props.model.drill_data?.length ?? 0) * 2) + 2}/>
        <td>Gesamt:</td>
        <td className={'align-right'}>
          <FormatNumber value={props.model.thickness_total > 0 ? props.model.thickness_total / 1000 : 0}
                        decimals={3}/>&nbsp;mm
        </td>
        {thicknessTotalCycle}
        <td/>
        <td colSpan={2} className={'borderless-top'}/>
        <td colSpan={2}/>
      </tr>
      </tfoot>
    </table>
  </>;
}

