<div class="row">

  <div class="col-lg-6">
    <table class="table whitetable marginless-bottom">
      <tbody>
        <tr [ngClass]="{'selected-table-box': success}">
          <td class="bold">Aktueller Lagerbestand:</td>
          <td class="bold align-right">
            <article-inventory-quantity-box [oaNr]="article?.oa_nr"
                                            [workflowId]="workflowId"
                                            [inventory]="inventory"
                                            (reloadInventory)="reloadInventory.emit($event)">
            </article-inventory-quantity-box>
          </td>
        </tr>
        <tr>
          <td class="bold">Aktuell in Produktion:</td>
          <td class="bold align-right">{{ sum }}</td>
        </tr>
      </tbody>
    </table>

    <div>&nbsp;</div>

    <table class="table whitetable whitetable-borderless marginless-bottom">
      <tbody>
        <tr>
          <td class="bold">Artikel</td>
          <td class="align-right"><ng-container *ngIf="article.sds_nr">SDS {{ article.sds_nr }}</ng-container></td>
        </tr>
        <tr>
          <td>Fertigungsnutzen-St.:</td>
          <td class="align-right">
            {{ article.data.utilize1|money }} x {{ article.data.utilize2|money }} =
            <span class="bold">{{ article.data.utilize_sum|money }}</span>
          </td>
        </tr>
        <tr>
          <td>LP Typ:</td>
          <td class="align-right">{{ article.data.execution }}</td>
        </tr>
        <tr>
          <td>Oberfläche:</td>
          <td class="align-right">{{ article.data.surface_area }}</td>
        </tr>
        <tr>
          <td>Null-Fehler-Nutzen:</td>
          <td class="align-right">
            <div *ngIf="!!extra.bad_parts.null_error_utilize"><i class="fa fa-check"></i></div>
            <div *ngIf="!extra.bad_parts.null_error_utilize"><i class="fa fa-times"></i></div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="label label-gold" *ngIf="article.data.surface_area === 'Chem NI / AU'">Gold</span>
            <span class="label label-info" *ngIf="!!article.data.blind_hole_tech_ls || !!article.data.blind_hole_tech_ds">
              Sackloch
            </span>
            <span class="label label-rose" *ngIf="(article.data.via_plugging || article.data.plugging_core)">VIAFILL</span>
            <span class="label label-orange" *ngIf="(article.data.cu_filling || article.data.cu_filling_core)">COPPERFILLING</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-lg-6">
    <business-order-proposal-table [workflowId]="workflowId"
                                   [quantity]="quantity"
                                   [amountPerUtilize]="amountPerUtilize"
                                   [hasExpress]="hasExpress">
    </business-order-proposal-table>
  </div>
</div>
