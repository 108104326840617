import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {CustomerService} from '../services/customer.service';
import {CustomerList} from '../model/customer';
import {LocalStorage} from '../../../common/storage/local-storage';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {Lister} from '../../../common/wrapper.models';

@Component({
  selector: 'customer-list',
  templateUrl: './customer-list.component.html'
})
export class CustomerListComponent extends SearchBaseComponent<CustomerService, CustomerList> {

  @Input() protected listData: Lister<CustomerList>;
  @Input() public data?: { [key: string]: string; };
  protected type = 'customer';

  constructor(protected service: CustomerService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  setShowLockedOnly(state: boolean) {
    this.updateStringValue('locked', state === true ? 'true' : 'false');
  }

  get showLockedOnly(): boolean {
    const locked = this.query().locked;
    return locked === true || locked === 'true';
  }

}
