import {NgModule} from '@angular/core';
import {InvoiceModule} from './invoice/invoice.module';
import {WorkflowRequestModule} from './request/workflow-request.module';
import {BusinessOrderModule} from './business-order/business-order.module';
import {FetchModule} from './fetch/fetch.module';
import {WorkflowNavigationModule} from './navigation/workflow-navigation.module';
import {WorkflowMainModule} from './main/workflow-main.module';
import {ContractModule} from './contract/contract.module';
import {KanbanModule} from './kanban/kanban.module';
import {OfferModule} from './offer/offer.module';
import {DeliveryModule} from './delivery/delivery.module';
import {OrderRequestModule} from './order-request/order-request.module';
import {WorkflowDocumentModule} from './document/workflow-document.module';
import {AdapterModule} from './adapter/adapter.module';
import {FetchBoxModule} from './fetch/box/fetch-box.module';
import {InventoryModule} from '../article/inventory/inventory.module';
import {CreateTaskModule} from './create-task/create-task.module';
import {FrameBoxUpdateComponent} from './boxes/update/frame-box-update.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {EnvisiaCommon} from '../../common';
import {UIRouterModule} from '@uirouter/angular';

@NgModule({
  imports: [
    WorkflowMainModule,
    InvoiceModule,
    WorkflowRequestModule,
    BusinessOrderModule,
    FetchModule,
    OfferModule,
    ContractModule,
    DeliveryModule,
    KanbanModule,
    OrderRequestModule,
    WorkflowNavigationModule,
    WorkflowDocumentModule,
    AdapterModule,
    FetchBoxModule,
    InventoryModule,
    CreateTaskModule,
    ReactiveFormsModule,
    CommonModule,
    EnvisiaCommon,
    UIRouterModule,
  ],
  declarations: [FrameBoxUpdateComponent]
})
export class WorkflowModule {
}
