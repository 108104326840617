import {Component, EventEmitter, Input, Output} from '@angular/core';
import {debug} from '../../../helper/debug.func';

@Component({
  selector: 'fetch-cam-article-state',
  templateUrl: './fetch-cam-article-state.component.html'
})
export class FetchCamArticleStateComponent {
  @Output() listener = new EventEmitter<void>();
  @Output() stateChange = new EventEmitter<string>();
  stateValue: Set<string> = new Set([]);

  @Input()
  get state(): string | undefined | null {
    return Array.from(this.stateValue).join(',');
  }

  set state(val: string | undefined | null) {
    if (val) {
      if (typeof val === 'string') {
        this.stateValue = new Set(val.split(','));
        this.stateChange.emit(this.state);
      } else {
        debug('FetchCamArticleStateComponent: set undefined value');
      }
    }
  }

  includes(name: 'red' | 'orange' | 'green'): boolean {
    return this.stateValue.has(name);
  }

  toggle(name: 'red' | 'orange' | 'green'): void {
    if (this.includes(name)) {
      this.stateValue.delete(name);
    } else {
      this.stateValue.add(name);
    }

    this.stateChange.emit(this.state);
    this.listener.emit();
  }

}
