<div class="modal-header red_alert">
  <h3 class="modal-title">Fehler <i class="fa fa-exclamation-triangle"></i></h3>
</div>

<div class="modal-body red_alert">

  Preis konnte nicht berechnet werden, da keine Kalkulationswerte hinterlegt sind.
  Bitte wenden Sie sich an die Geschäftsleitung.

</div>

<div class="modal-footer red_alert">
  <button type="button" class="pull-left btn btn-default" (click)="cancel()">
    Schließen
  </button>
</div>
