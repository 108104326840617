<div class="modal-header">
  <h3 id="modal-title">Dokument per E-Mail versenden</h3>
</div>

<form name="form" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="row" *ngIf="users?.length > 1">
      <div class="col-md-12">

        <div class="btn-group" role="group">
          <button type="button" *ngFor="let obj of users" (click)="switchUser(obj)"
                  class="btn"
                  [ngClass]="{'btn-success': mainUser === obj, 'btn-default': mainUser !== obj}">
            {{ obj.username }}
          </button>
        </div>

      </div>
      <div class="col-md-12">&nbsp;</div>
    </div>

    <p>
      An:<br>
      <input class="form-control" id="id_send_to" type="text"
             formControlName="rcpt"
             [ngClass]="{red_alert: errors['rcpt']}">
    </p>

    <p>
      Kopie: <br>
      <input class="form-control" id="id_bcc" type="text"
             formControlName="bcc"
             [ngClass]="{red_alert: errors['bcc']}">
    </p>

    <p>
        <textarea class="form-control" cols="40" id="id_body" rows="10" style="height: 450px; width: 1000px"
                  formControlName="body"
                  [ngClass]="{red_alert: errors['body']}">
        </textarea>
    </p>
  </div>


  <div class="modal-footer row" style="clear: both; margin-right: 10px;">
    <div class="row">
      <div class="col-lg-2 pull-right">
        <button type="submit" class="btn button btn-default" id="button_mail_send" [disabled]="disabled">
          Dokument versenden <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
        </button>
      </div>
      <div class="col-lg-3 pull-right">
        <label for="files" class="btn btn-default">Dokumente anhängen</label>
        <input id="files" type="file" class="btn btn-default"  style="visibility:hidden;"
               (change)="onChange($event)"
               placeholder="Upload file"
               accept="application/pdf, application/x-zip-compressed"
               multiple/>
      </div>
    </div>
    <div class="row pull-right" style="margin-right: 20px;">
      <div class="col-lg-12">
        <table class="table" *ngIf="uploaded()">
          <thead>
            <tr>
              <th><strong>Angehängte Dokumente</strong></th>
            </tr>
          </thead>
          <tbody *ngFor="let obj of currentFiles()">
            <tr>
              <td>
                {{obj}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
