<div class="modal-header">
  <h3 class="modal-title">Buchungshistorie - RG-Korrektur-Nr.: {{ id }}</h3>
</div>

<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="row form-group">
      <div class="col-sm-1">
        <label for="note">Kommentar:</label>
      </div>
      <div class="col-sm-6">
        <input id="note"
               class="form-control"
               name="note"
               formControlName="note"
               [ngClass]="{red_alert: !!errors['note']}">
      </div>
      <div class="col-sm-1">
        <button type="submit" class="btn btn-success">Speichern</button>
      </div>
    </div>
  </form>

  <table class="table whitetable">
    <thead>
    <tr class="minimal">
      <th class="typ">Typ</th>
      <th class="booked align-right bold">Gebucht €</th>
      <th class="skonto align-right">Skonto %</th>
      <th class="skonto-total align-right bold">Skonto €</th>
      <th class="note">Kommentar</th>
      <th class="user">Benutzer</th>
      <th class="date">Datum</th>
    </tr>
    </thead>
    <tbody>
    <tr class="minimal" *ngFor="let obj of history">
      <td class="typ" [ngSwitch]="obj.typ">
        <ng-container *ngSwitchCase="'booking.locked'">Rechnung gesperrt</ng-container>
        <ng-container *ngSwitchCase="'booking.unlocked'">Rechnung entsperrt</ng-container>
        <ng-container *ngSwitchCase="'target.date.changed'">Fälligkeit geändert</ng-container>
        <ng-container *ngSwitchCase="'note'">Notiz</ng-container>
        <ng-container *ngSwitchCase="'storno'">Rechnung storniert</ng-container>
        <ng-container *ngSwitchCase="'correction.created'">RG-Korrektur erstellt</ng-container>
        <ng-container *ngSwitchDefault>Buchung</ng-container>
      </td>
      <td class="booked" *ngIf="obj.expensed === null"></td>
      <td class="booked align-right bold" *ngIf="obj.expensed !== null">{{ obj.expensed|money:2 }}&nbsp;€</td>
      <td class="skonto" *ngIf="obj.skonto === null"></td>
      <td class="skonto align-right" *ngIf="obj.skonto !== null">{{ obj.skonto|money:0 }}&nbsp;%</td>
      <td class="skonto-total" *ngIf="obj.skonto_total === null"></td>
      <td class="skonto-total align-right bold" *ngIf="obj.skonto_total !== null">{{ obj.skonto_total|money:2}}&nbsp;€</td>
      <td class="note">{{ obj.note }}</td>
      <td class="user">{{ obj.username }}</td>
      <td class="date">{{ obj.date|ZonedDateTime}}</td>
    </tr>
    </tbody>
  </table>

</div>

<div class="modal-footer">
</div>
