import {Component, Input, OnInit} from '@angular/core';
import {
  BusinessOrder,
  BusinessOrderReproduction,
} from '../../models/business-order.models';

@Component({
  selector: 'tr[business-order-view-goodpiece-box-element]',
  templateUrl: './business-order-view-goodpiece-box-element.component.html',
})
export class BusinessOrderViewGoodpieceBoxElementComponent implements OnInit {
  @Input('business-order-view-goodpiece-box-element') bo: BusinessOrder;
  @Input('business-order-view-goodpiece-box-element-current') current: BusinessOrder;
  @Input('business-order-view-goodpiece-box-element-reproduction') reproduction: BusinessOrderReproduction;

  isCurrent: boolean;
  isParentCurrent: boolean;
  constructor() {
  }

  ngOnInit(): void {
    this.isCurrent = (
      this.current.id === this.bo.id &&
      this.current.index === this.bo.index
    );
    this.isParentCurrent = (
      this.current.id === this.reproduction?.parent_id &&
      this.current.index === this.reproduction?.parent_index
    );
  }
}
