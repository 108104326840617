/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../../common/search/search.component';
import {RequestListData} from '../workflow-request.model';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {Lister} from '../../../../common/wrapper.models';
import {ProducerService} from '../../../producer/producer.service';
import {map} from 'rxjs/operators';
import {MailModalService} from '../../../../envisia/mail/mail-modal.service';
import {MailHeaderData} from '../../../mail/mail.models';
import {WorkflowRequestService} from '../../workflow-services/workflow-request.service';

@Component({
  selector: 'workflow-list-request',
  templateUrl: './workflow-list-request.component.html',
})
export class WorkflowListRequestComponent extends SearchBaseComponent<WorkflowRequestService, RequestListData> {
  @Input() protected listData: Lister<RequestListData>;
  protected type = 'order_request';

  constructor(protected service: WorkflowRequestService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              protected mailModalService: MailModalService,
              private producerService: ProducerService) {
    super();
  }

  mail(obj: RequestListData): void {
    const mailObs = this.producerService.detail(obj.order_request.producer).pipe(
      map(response => new MailHeaderData(response.mail, 'Dear Sir or Madam,'))
    );

    this.mailModalService.open(
      obj.order_request.id,
      'order_request',
      mailObs,
      false,
      'en',
      obj.workflow.id,
      false
    ).subscribe(() => {
      obj.order_request.mail_sent = true;
    });
  }

}
