import {Component, ErrorHandler, Input, OnDestroy} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {InventoryService} from './inventory.service';
import {InventoryListData} from './inventory.models';
import {InventoryModalService} from '../../../envisia/article/inventory/inventory.modal.service';
import {LocalStorage} from '../../../common/storage/local-storage';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {Lister} from '../../../common/wrapper.models';
import {Subscription} from 'rxjs';
import {ArticleSpecification} from '../models/article-specification.model';

@Component({
  selector: 'inventory-list',
  templateUrl: './inventory-list.component.html',
})
export class InventoryListComponent
  extends SearchBaseComponent<InventoryService, InventoryListData>
  implements OnDestroy {

  @Input() protected listData: Lister<InventoryListData>;
  @Input() protected specification: { [key: string]: ArticleSpecification } | undefined;
  protected type = 'inventory_list';
  private inventorySubscription?: Subscription;
  public specificationData: { key: string; value: string; }[];

  constructor(protected service: InventoryService,
              protected errorHandler: ErrorHandler,
               protected locationService: EnvisiaLocation,
               protected storageService: LocalStorage,
               private inventoryModalService: InventoryModalService) {
    super();
  }

  openInventory(obj: InventoryListData): void {
    // we can safely ignore any modal callbacks since we do not need to pass an emitter
    this.inventoryModalService.open(obj.oa_nr).subscribe(value => {
      this.unsubscribeInventory(); // first unsubscribe the old value if we have any new subscription going  on
      this.inventorySubscription = value.emitter.subscribe(() => {
        // if any new sum object arrives we can just research in the background
        this.search(true);
      }, () => {
      });
    }, error => {
      console.log('Modal', error);
    });
  }

  unsubscribeInventory() {
    if (this.inventorySubscription) {
      this.inventorySubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubscribeInventory();
  }
}
