import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {WorkflowPositionView} from '../../models/position.models';
import {OfferPositionNotesService} from './offer-position-notes.service';

@Component({
  selector: 'offer-position-modal',
  templateUrl: './offer-position-modal.component.html'
})
export class OfferPositionModalComponent implements OnInit {
  @Input() typ: string;
  @Input() position: WorkflowPositionView;
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private modalRef: NgbActiveModal,
              private service: OfferPositionNotesService) {
  }

  ngOnInit(): void {

    let notes = '';
    if (this.typ === 'de' && this.position.notes_de) {
      notes = this.position.notes_de;
    } else if (this.typ === 'cn' && this.position.notes_cn) {
      notes = this.position.notes_cn;
    } else {
      notes = null;
    }

    this.form = this.fb.group({
      'notes': [notes, Validators.required],
    });

  }

  save(): void {
    this.service.updateNotes(this.position.id, this.typ, this.form.value.notes).subscribe(view => {
      this.alertService.add('success', 'Preis-Notiz erfolgreich editiert.');
      this.modalRef.close(view);
    }, () => {
      this.alertService.add('danger', 'Beim Speichern ist ein Fehler aufgetreten!');
    });
  }

}
