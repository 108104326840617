<div class="modal-header">
  <div class="modal-title"> Betriebsauftrags-Daten ändern </div>
</div>

<form [formGroup]="form" (ngSubmit)="submit()" class="form form-horizontal">
  <div class="modal-body">
    <div class="form-group">
      <label for="id_start_date" class="col-sm-3 control-label">Start-Termin</label>
      <div class="col-sm-9">
        <ev-datepicker class="form-control input-sm date-picker" id="id_start_date" name="start_date" formControlName="start_date"></ev-datepicker>
      </div>
      <div>&nbsp;</div>
      <label for="id_end_date" class="col-sm-3 control-label date-picker">End-Termin</label>
      <div class="col-sm-9">
        <ev-datepicker class="form-control input-sm" id="id_end_date" name="end_date" formControlName="end_date"></ev-datepicker>
      </div>
      <div>&nbsp;</div>
      <label for="id_gold_date" class="col-sm-3 control-label date-picker">Vergoldungs-Termin</label>
      <div class="col-sm-9">
        <ev-datepicker class="form-control input-sm" id="id_gold_date" name="gold_date" formControlName="gold_date"></ev-datepicker>
      </div>
      <label for="express" class="col-sm-3 control-label">Eildienst</label>
      <div class="col-sm-9">
        <input type="checkbox" formControlName="express" class="input-sm" id="express">
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success btn-sm pull-right">
      Übernehmen
    </button>
  </div>
</form>
