import {Component, Input} from '@angular/core';

@Component({
  selector: 'business-order-label',
  templateUrl: './business-order-label.component.html',
  styleUrls: ['./business-order-label.component.scss'],
})
export class BusinessOrderLabelComponent {
  @Input() size: 'small' | 'big' = 'small';
  @Input() surface?: string;
  @Input() empb?: boolean;
  @Input() kanban?: boolean;
  @Input() hasPlugging?: boolean;
  @Input() hasCopperFilling?: boolean;
  @Input() hasReproduction?: boolean;
  @Input() hasBlindHole?: boolean;
  @Input() isT3?: boolean;
  @Input() isT6?: boolean;

  constructor() {
  }
}
