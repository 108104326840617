import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Additional, Category} from '../../finder.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {FinderAdditionalData} from '../finder-upload.models';

@Component({
  selector: 'finder-upload-additional',
  templateUrl: './finder-upload-additional.component.html',
})
export class FinderUploadAdditionalComponent implements OnInit {
  @Input() additionals: Additional[];
  @Output() reverseEmitter = new EventEmitter<{}>();
  @Output() forwardEmitter = new EventEmitter<FinderAdditionalData>();
  additionalForm: UntypedFormGroup;
  extraWordsForm: UntypedFormGroup;
  fileNumberForm: UntypedFormGroup;
  fileNumbers: Set<string> = new Set<string>();

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    const additionalFg = {};
    this.additionals.forEach(additional => {
      additionalFg[additional.id.toString()] = [null];
    });
    this.additionalForm = this.fb.group(additionalFg);
    this.extraWordsForm = this.fb.group({extraWords: [null]});
    this.fileNumberForm = this.fb.group({fileNumber: [null]});
  }

  addFileNumber(): void {
    this.fileNumbers.add(this.fileNumberForm.value.fileNumber);
    this.fileNumberForm.reset();
  }

  removeFileNumber(n: string): void {
    this.fileNumbers.delete(n);
  }

  next(): void {
    this.forwardEmitter.emit({
      additionals: this.additionalForm.value,
      extra: this.extraWordsForm.value.extraWords,
      fileNumbers: this.fileNumbers,
    });
  }

}
