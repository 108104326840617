import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {DateRangeComponent} from './date-range.component';
import {DatepickerModule} from '@envisia/ev-datepicker';

@NgModule({
  imports: [DatepickerModule, ReactiveFormsModule, CommonModule],
  declarations: [
    DateRangeComponent
  ],
  exports: [
    DateRangeComponent
  ]
})
export class DateRangeModule {
}
