import {NgModule} from '@angular/core';
import {ArticleLockComponent} from './article-lock.component';
import {ArticleLockModalComponent} from './article-lock.modal.component';
import {SharedModule} from '../../../../core/shared.module';
import {ArticleUnlockModalComponent} from './article-unlock.modal.component';

// FIXME: angularjs 1.x ArticleLockCompoent does not need to be in entryComponents
const components = [
  ArticleLockComponent,
  ArticleLockModalComponent,
  ArticleUnlockModalComponent
];

@NgModule({
    imports: [SharedModule],
    declarations: components,
    exports: [ArticleLockComponent]
})
export class ArticleLockModule {
}
