import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {FetchService} from '../workflow-services/fetch.service';
import {FetchListData} from '../models/fetch.models';
import {LocalStorage} from '../../../common/storage/local-storage';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {Lister} from '../../../common/wrapper.models';

@Component({
  selector: 'fetch-list',
  templateUrl: './fetch-list.component.html'
})
export class FetchListComponent extends SearchBaseComponent<FetchService, FetchListData> {
  @Input() listData: Lister<FetchListData>;
  protected type = 'fetch_list';

  constructor(protected service: FetchService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }


}
