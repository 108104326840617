import {Component, Input} from '@angular/core';
import {OrderDataExpensed} from '../../order-request.models';

@Component({
  selector: 'order-box-history-expensed',
  templateUrl: './order-box-history-expensed.component.html',
})
export class OrderBoxHistoryExpensedComponent {

  @Input() type: string;
  @Input() history: OrderDataExpensed;

  constructor() {
  }
}
