<table class="table whitetable">
  <thead>
  <tr>
    <th>Anfrage</th>
    <th>Vorgang</th>
    <th>Datum</th>
    <th>Artikel</th>
    <th>Produzent</th>
    <th>Lief.Ter.(ETD)</th>
    <th>Ersteller</th>
    <th>&nbsp;</th>
    <th>&nbsp;</th>
    <th>&nbsp;</th>
  </tr>
  </thead>

  <tbody>
  <tr *ngFor="let obj of requests">
    <td>
      {{ obj.id }}
      <ev-status name="request_status" [state]="obj.state"></ev-status>
    </td>
    <td>
      <a class="click" uiSref="a.workflow.detail" target="_blank" [uiParams]="{id: obj.workflow_id}">
        <span [ngClass]="{ 'label': workflow.id === obj.workflow_id, 'label-info': workflow.id === obj.workflow_id }">
          {{ obj.workflow_id }}
        </span>
      </a>
    </td>
    <td>{{ obj.request_date }}</td>
    <td>
      <a uiSref="a.article.workflow.overview" [uiParams]="{id: obj.article}" target="_blank">
        <div class="label"
             [ngClass]="{'label-danger': obj.article_hash !== article.current_hash, 'label-success': obj.article_hash === article.current_hash }">
          {{ obj.article }}
        </div>
      </a>
    </td>
    <td>{{ obj.producer }}</td>
    <td>{{ obj.delivery_date}}</td>
    <td>{{ obj.username}}</td>
    <td class="icon">
      <ev-file-button [objId]="obj.id" type="order_request">
        <i class="fa fa-file-pdf-o"></i>
      </ev-file-button>
    </td>
    <td class="icon">
      <button type="button" class="btn btn-link btn-sm"
              (click)="mail(obj.id, obj.workflow_id, obj.producer, 'order_request')">
        <i class="fa fa-envelope"></i>
      </button>
    </td>
    <td>
      <button type="button" class="btn btn-default btn-sm"
              (click)="requestToOrderEmitter.emit({requestNr: obj.id, producer: obj.producer})"
              *ngIf="obj.workflow_id === workflow.id">
        Bestellung
      </button>
    </td>

  </tr>
  </tbody>
</table>
