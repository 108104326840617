import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Workflow} from '../../models/workflow.models';
import {Customer} from '../../../customer/model/customer';
import {Address} from '../../../customer/model/address';
import {CommonType, InitData, Map} from '../../../../common/init-data/init-data';
import {Country} from '../../../customer/model/country';
import {GlobalService} from '../../../../core/global.service';
import {FetchService} from '../../workflow-services/fetch.service';
import {MailModalService} from '../../../mail/mail-modal.service';
import {InvoiceSendModalService} from '../../invoice/send/invoice-send-modal.service';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {StateService} from '@uirouter/core';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {FetchStatusModalService} from '../modal/fetch-status-modal.service';
import {PdfHistoryModalService} from '../../../history/pdf-history-modal.service';
import {CountryService} from '../../../customer/services/country.service';
import {DateHelper} from '../../../../helper/date-helper';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {BigFetchWithCleanPosition} from './fetch-box.model';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';

@Component({
  selector: 'fetch-box-inner',
  templateUrl: './fetch-box-inner.component.html',
})
export class FetchBoxInnerComponent implements OnInit, OnChanges {
  @Input() workflow: Workflow;
  @Input() customer: Customer;
  @Input() addresses: Address[];
  @Input() fetch: BigFetchWithCleanPosition;
  @Input() index: number;
  @Input() frameTyp: string;
  @Input() isoCountry: Country;
  @Input() priceIncreaseFlex?: PriceIncreaseFlex;
  @Output() statusChange = new EventEmitter<number>();

  init = false;
  country: string;
  is_german: boolean;
  fetchStatus: Map<CommonType>;
  localCache: any = {};
  hasPriceIncrease: boolean;

  constructor(private globalService: GlobalService,
              private fetchService: FetchService,
              private mailModalService: MailModalService,
              private invoiceSendModalService: InvoiceSendModalService,
              private localStorage: LocalStorage,
              private stateService: StateService,
              private alertService: AlertService,
              private statusModalService: FetchStatusModalService,
              private pdfHistoryModalService: PdfHistoryModalService,
              private countryService: CountryService) {
  }

  ngOnInit(): void {
    this.updateHasPriceIncrease();
    this.fetchStatus = this.localStorage.getObject<InitData>('data').fetch_status;

    const innerAddresses: { [id: string]: Address } = {};
    this.addresses.forEach(data => innerAddresses[data.id] = data);
    const addressId = this.addressId();
    this.country = !addressId ? null : innerAddresses[addressId].country;
    this.is_german = this.country === 'DEU';
    this.init = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.priceIncreaseFlex && !changes.priceIncreaseFlex.isFirstChange()) {
      this.updateHasPriceIncrease();
    }
    if (changes.fetch && !changes.fetch.isFirstChange()) {
      this.updateHasPriceIncrease();
    }
  }

  // either de or eu with de vat
  get isEuWithDeVat(): boolean {
    if (this.fetch.contract_obj && this.fetch.contract_obj.ust) {
      return this.is_german || this.isoCountry.vat && this.fetch.contract_obj.ust.startsWith('DE');
    } else {
      return this.is_german;
    }
  }

  mail(): void {
    this.mailModalService.open(
      this.fetch.contract_obj.id,
      'contract',
      null,
      null,
      'de',
      this.workflow.id,
      true,
      this.frameTyp
    ).subscribe(() => {
      // if the contract_obj does not exist we actually do not set a date_sent
      if (this.fetch.contract_obj) {
        this.fetch.contract_obj.date_sent = DateHelper.format(new Date());
      }
    }, ignoreRejection);
  }

  invoiceSend(): void {
    const flipper = (state) => {
      this.fetch.invoice_obj.status = state;
      this.fetch.invoice_obj.date_sent = DateHelper.format(new Date());
    };
    this.invoiceSendModalService.open(this.fetch.invoice_obj.id, flipper)
      .subscribe(ignoreRejection, ignoreRejection);
  }

  history(name: string, objId: number, title: string): void {
    this.pdfHistoryModalService.open(name, objId, title).subscribe(ignoreRejection, ignoreRejection);
  }

  fetchStatusClass(id: number): string {
    const labelString = this.fetchStatus[id].label;
    return 'alert-' + labelString;
  }

  fetchStatusName(id: number): string {
    return this.fetchStatus[id].name;
  }

  invoiceState(n: number): CommonType {
    if (!this.localCache.hasOwnProperty('invoice_status')) {
      this.localCache.invoice_status = this.localStorage.getObject<InitData>('data').invoice_status;
    }
    // 1 means sending
    // 2 means paid
    return this.localCache.invoice_status[n];
  }

  private addressId(): string {
    if (this.workflow.delivery_id) {
      return this.workflow.delivery_id;
    } else if (!this.workflow.delivery_id && this.workflow.invoice_id) {
      return this.workflow.invoice_id;
    } else {
      return this.customer.invoice.id;
    }
  }

  open() {
    if (this.fetch.status >= 1 && this.fetch.status <= 3) {
      this.statusModalService.open(this.workflow.id, this.fetch.id, this.fetch.ordering).subscribe(newStatus => {
        this.fetch.status = newStatus;
        this.statusChange.emit(this.workflow.id);
      }, ignoreRejection);
    } else {
      this.alertService.add('danger', 'Abruf kann nicht geändert werden.');
    }
  }

  private updateHasPriceIncrease(): void {
    this.hasPriceIncrease = (
      this.fetch.status < 5 ?
        !!this.priceIncreaseFlex :
        !!this.fetch.positions.find(p => p.typ === 'article' && p.price_increase > 0)
    );
  }
}
