import {Transition, ResolveTypes} from '@uirouter/angular';

export function workflowCreateResolveDataFn(transition: Transition) {
  return transition.params().data;
}

export const WORKFLOW_CREATE_RESOLVER: ResolveTypes = {
  token: 'data',
  resolveFn: workflowCreateResolveDataFn,
  deps: [Transition]
};
