<div class="modal-header">
  <h3 class="modal-title">{{ header }}</h3>
</div>

<form name="address" [formGroup]="form" (ngSubmit)="upsert()">

  <div class="modal-body">
    <label for="id_name">Standort:</label>
    <input type="text" id="id_name" maxlength="100" class="form-control"
           formControlName="name"
           [ngClass]="{red_alert: errors['obj.name']}">

    <div *ngIf="!main">
      <label for="id_customer_name">Firmenname:</label>
      <input type="text" id="id_customer_name" maxlength="100" class="form-control" formControlName="company_name">

      <label for="id_customer_department">Abteilung</label>
      <input type="text" id="id_customer_department" maxlength="100" class="form-control"
             formControlName="company_department">
    </div>

    <label for="id_street">Straße:</label>
    <input type="text" id="id_street" maxlength="100" class="form-control"
           formControlName="street"
           [ngClass]="{red_alert: errors['obj.street']}">

    <label for="id_zip">Postleitzahl:</label>
    <input type="text" id="id_zip" maxlength="10" class="form-control"
           formControlName="zip"
           [ngClass]="{red_alert: errors['obj.zip']}">

    <label for="id_city">Stadt:</label>
    <input type="text" id="id_city" maxlength="100" class="form-control"
           formControlName="city"
           [ngClass]="{red_alert: errors['obj.city']}">

    <label for="id_country">Land:</label>
    <select class="form-control" id="id_country" style="width: 308px;"
            formControlName="country"
            [ngClass]="{red_alert: errors['obj.country']}">
      <option *ngFor="let item of countryList" [value]="item.iso3">{{ item.name_de }}</option>
    </select>

    <label for="id_ust">Umsatzsteuer-Identifikationsnummer:</label>
    <input type="text" id="id_ust" maxlength="100" class="form-control"
           formControlName="ust"
           [ngClass]="{red_alert: errors['obj.ust']}">

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="blocked">{{ button }}</button>
  </div>

</form>
