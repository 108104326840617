import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleConfigMainUpdateComponent} from './article-config-main-update.component';
import {
  ArticleBaseMaterialFormWithKey,
  ArticleSpecification
} from '../../../envisia/article/models/article-specification.model';
import {deepCopy} from '../../../helper/deep-copy';
import {ArticleConfigMainCreateComponent} from './article-config-main-create/article-config-main-create.component';
import {sortedManufacturerSpec} from '../../../envisia/article/main/helper/specification-helper';
import {ArticleSpecificationService} from '../../../envisia/article/services/article-specification.service';

@Component({
  selector: 'article-config-main',
  templateUrl: './article-config-main.component.html',
})
export class ArticleConfigMainComponent implements OnInit {

  @Input() data?: ArticleSpecification;
  listData: ArticleBaseMaterialFormWithKey[];
  lockSubmission = false;

  constructor(private articleSpecService: ArticleSpecificationService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.prepare(this.data);
  }

  private prepare(value: ArticleSpecification) {
    this.listData = sortedManufacturerSpec(value);
  }

  openCreate(): void {
    const modalRef = this.modalService.open(ArticleConfigMainCreateComponent, {windowClass: 'modal2-normal'});
    modalRef.result.then((value) => {
      console.log('Update Result:', value);
      this.prepare(value);
    }, () => {
      // we ignore any errors
    });
  }

  openUpdate(index: number, data: ArticleBaseMaterialFormWithKey) {
    const modalRef = this.modalService.open(ArticleConfigMainUpdateComponent, {windowClass: 'modal2-normal'});
    modalRef.componentInstance.data = deepCopy(data);
    modalRef.result.then((value) => {
      console.log('Insert Result:', value);
      this.prepare(value);
    }, () => {
      // we ignore any errors
    });
  }

  delete(index: number, data: ArticleBaseMaterialFormWithKey): void {
    if (this.lockSubmission || !confirm('Soll das Material wirklich gelöscht werden?')) {
      return;
    }

    this.lockSubmission = true;
    this.articleSpecService.saveBaseMaterial({
       name: data.key,
       data: {...data.data, deleted: true},
    }).subscribe(value => {
      this.prepare(value);
      this.lockSubmission = false;
    }, () => {
      this.lockSubmission = false;
    });
  }
}
