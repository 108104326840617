import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {InvoiceAdditional} from '../../workflow/invoice/invoice.models';

@Injectable({
  providedIn: 'root'
})
export class DunningLockService {
  constructor(private http: HttpClient) { }
  toggleLock(invoiceId: number, form: any): Observable<boolean> {
    return this.http.put<InvoiceAdditional>(
      `${environment.apiv4uri}dunning/${invoiceId}/lock`,
      form
    ).pipe(map(ia => ia.dunning_lock));
  }
}
