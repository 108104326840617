import {Injectable} from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {environment} from '../../../../environments/environment';
import {MinimalUser} from '../../user/minimal-user.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({
  providedIn: 'root'
})
export class UserListService implements ListService<MinimalUser> {
  constructor(private http: HttpClient) {
  }

  list(params?: any): Observable<Lister<MinimalUser>> {
    return this.http.get<Lister<MinimalUser>>(
      `${environment.apiv4uri}material/group/permission/users`,
      {params: EnvisiaLocation.httpParams(params)},
    );
  }
}
