/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {LocalStorage} from '../storage/local-storage';
import {InitData} from '../init-data/init-data';

@Injectable({providedIn: 'root'})
export class RightService {

  constructor(private localStorage: LocalStorage) {
  }

  has(perm: string) {
    const data = this.localStorage.getObject<InitData>('data');
    const group = data.group;
    // checks the rights according to the user group
    // actually we call into Scala.JS so it would create a TypeError
    // if we pass the undefined group when we log out a user
    return group.rights.map(entry => entry.name).includes(perm, 0);
  }

  hasAny(): boolean {
    const data = this.localStorage.getObject<InitData>('data');
    return data.group.rights.length > 0;
  }

  isSuperuser(): boolean {
    const data = this.localStorage.getObject<InitData>('data');
    return data.user.isSuperUser && data.user.isActive;
  }
}
