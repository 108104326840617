/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {WorkflowDocumentListComponent} from './workflow-document-list.component';
import {WorkflowService} from '../workflow-services/workflow.service';
import {FinderSearchService} from '../../finder/finder-search.service';
import {map} from 'rxjs/operators';
import {WorkflowDocumentNewComponent} from './workflow-document-new.component';

export const WORKFLOW_DOCUMENT_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.document',
  abstract: true,
  data: {requiresAuth: true}
};

export function workflowDocumentResolveFn(service: WorkflowService, transition: Transition) {
  return service.detail(transition.params().id).pipe(map(val => val.object)).toPromise();
}

export function searchWorkflowDocumentListResolveFn(service: FinderSearchService, transition: Transition) {
  return service.search('workflow', transition.params().id).toPromise();
}

export const WORKFLOW_DOCUMENT_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.document.list',
  url: '/workflow/document/{id}',
  views: {'main@a': {component: WorkflowDocumentListComponent}},
  resolve: [
    {token: 'workflow', resolveFn: workflowDocumentResolveFn, deps: [WorkflowService, Transition]},
    {token: 'search', resolveFn: searchWorkflowDocumentListResolveFn, deps: [FinderSearchService, Transition]}
  ],
  data: {requiresAuth: true}
};

export function workflowDocumentCategoryResolveFn(service: FinderSearchService) {
  return service.category().toPromise();
}

export const WORKFLOW_DOCUMENT_NEW_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.document.new',
  url: '/workflow/document/{id}/new',
  views: {'main@a': {component: WorkflowDocumentNewComponent}},
  resolve: [
    {token: 'workflow', resolveFn: workflowDocumentResolveFn, deps: [WorkflowService, Transition]},
    {token: 'category', resolveFn: workflowDocumentCategoryResolveFn, deps: [FinderSearchService]},
  ],
  data: {requiresAuth: true}
};

export const WORKFLOW_DOCUMENT_STATES: Ng2StateDeclaration[] = [
  WORKFLOW_DOCUMENT_STATE,
  WORKFLOW_DOCUMENT_LIST_STATE,
  WORKFLOW_DOCUMENT_NEW_STATE
];
