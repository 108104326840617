<ng-container *ngIf="wrapper.data$ | async as list">
  <ng-container *ngIf="list.count > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>Offene Bestellungen</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <table class="table whitetable">
          <thead>
          <tr>
            <th>Bestellung</th>
            <th>Status</th>
            <th>Bestelldatum</th>
            <th>Lieferdatum</th>
            <th>Int. Notiz</th>
            <th class="align-right">Bestellt</th>
            <th class="align-right">Eingebucht</th>
            <th class="align-right">Offen</th>
            <th class="icon" *ngIf="('material.order.transactions'|HasPerm)"></th>
            <th class="icon"></th>
          </tr>
          </thead>
          <tbody>
          <tr [ngClass]="{'danger': mop.delivery_status === 0, 'warning': mop.delivery_status === 1 }"
              *ngFor="let mop of list.objects">
            <td>{{mop.order_id}}</td>
            <td>
              <material-order-position-status [id]="mop.id"
                                              [status]="mop.status"
                                              [deleted]="!!mop.date_deleted"
                                              [edit]="false">
              </material-order-position-status>
            </td>
            <td>{{mop.order.date_created | date:'dd.MM.yyyy HH:mm'}}</td>
            <td>{{mop.date_delivery | date:'dd.MM.yyyy'}}</td>
            <td>{{mop.note}}</td>
            <td class="align-right">{{mop.amount | money}}&nbsp;{{variation.unit}}</td>
            <td class="align-right">{{mop.amount_received | money}}&nbsp;{{variation.unit}}</td>
            <td class="align-right">{{mop.amount_left_total | money}}&nbsp;{{variation.unit}}</td>
            <td class="icon" *ngIf="('material.order.transactions'|HasPerm)">
              <a class="btn btn-link" uiSref="a.material.order.intake" target="_blank"
                 [uiParams]="{order_id: mop.order_id, marked_variation_id: variation.id}">
                <i class="fa fa-truck"></i>
              </a>
            </td>
            <td class="icon">
              <a class="btn btn-link" uiSref="a.material.order.list" target="_blank" [uiParams]="{id: mop.order_id}">
                <i class="fa fa-question-circle"></i>
              </a>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="5"></td>
            <td class="align-right">{{totalSum | money}}&nbsp;{{variation.unit}}</td>
            <td class="align-right">{{totalSumReceived | money}}&nbsp;{{variation.unit}}</td>
            <td class="align-right">{{totalSumRemaining | money}}&nbsp;{{variation.unit}}</td>
            <td [colSpan]="('material.order.transactions'|HasPerm) ? 2 : 1"></td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-container>
</ng-container>
