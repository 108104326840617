/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {WorkflowFactoryService} from './workflow-factory.service';
import {Observable, EMPTY, of, forkJoin} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {BigCreateWorkflow, BigWorkflowOffer} from '../models/workflow-create.models';
import {OfferService} from './offer.service';
import {AddressService} from '../../customer/services/address.service';
import {WorkflowOrderRequestService} from './workflow-order-request.service';
import {PriceIncreaseFlexService} from '../../price-increase-flex/price-increase-flex.service';
import {CustomerCreditWorthinessService} from '../../customer/credit-worthiness/customer-credit-worthiness.service';

@Injectable({providedIn: 'root'})
export class WorkflowDetailService {

  constructor(private workflowFactoryService: WorkflowFactoryService,
              private offerService: OfferService,
              private addressService: AddressService,
              private workflowOrderRequestService: WorkflowOrderRequestService,
              private priceIncreaseFlexService: PriceIncreaseFlexService,
              private customerCreditWorthinessService: CustomerCreditWorthinessService) {
  }

  call(workflowId: number): Observable<any> {
    return this.workflowFactoryService.create(workflowId, true).pipe(
      mergeMap<BigCreateWorkflow, any>(val => {
        const workflow = val.workflow.object;

        let obs: Observable<BigCreateWorkflow | BigWorkflowOffer> = EMPTY;
        if (WorkflowFactoryService.workflowStatusCheck(workflow)) {
          obs = this.createWorkflowOfferObs(val);
        } else {
          obs = this.createWorkflowContractObs(val);
        }

        return obs;
      })
    );
  }

  private createWorkflowOfferObs(val: BigCreateWorkflow) {
    return this.offerService.workflowList(val.workflow.object.id).pipe(
      catchError(() => of([])),
      map(response => {
        val['offer_list'] = response;
        val['positions'] = val.workflow.positions;
        return val;
      })
    );
  }

  private createWorkflowContractObs(val: BigCreateWorkflow) {
    const addressesObs = this.addressService.list({customer_id: val.customer.id});
    const additionalObs = this.workflowFactoryService.createAdditionalObs(val);
    const ordersObs = this.workflowOrderRequestService.workflowList(val.workflow.object.id);
    const pifObs = this.priceIncreaseFlexService.get(val.workflow.object.id);
    const ccw = this.customerCreditWorthinessService.get(val.customer.id);
    return forkJoin([addressesObs, additionalObs, ordersObs, pifObs, ccw]).pipe(
      map(responses => {
        val['address'] = responses[0];
        val['additional'] = responses[1];
        val['orders'] = responses[2];
        val['price_increase_flex'] = responses[3].object;
        val['customer_credit_worthiness'] = responses[4];
        return val;
      })
    );
  }

}
