import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../../core/shared.module';
import {DeliveryStornoModalComponent} from './delivery-storno-modal.component';
import {DeliveryCreateMainErrorModalComponent} from './delivery-create-main-error-modal.component';
import {DeliveryCreateLowInventoryErrorModalComponent} from './delivery-create-low-inventory-error-modal.component';
import {DeliveryCreateWeightsInvalidErrorModalComponent} from './delivery-create-weights-invalid-error-modal.component';
import {DeliveryCreateMessageModalComponent} from './delivery-create-message-modal.component';
import {DeliveryLockingModalComponent} from './delivery-locking-modal.component';

@NgModule({
    imports: [
        NgbModalModule,
        SharedModule,
    ],
    declarations: [
        DeliveryStornoModalComponent,
        DeliveryLockingModalComponent,
        DeliveryCreateMainErrorModalComponent,
        DeliveryCreateLowInventoryErrorModalComponent,
        DeliveryCreateWeightsInvalidErrorModalComponent,
        DeliveryCreateMessageModalComponent
    ],
    exports: [
        DeliveryStornoModalComponent,
        DeliveryLockingModalComponent
    ]
})
export class DeliveryModalModule {
}
