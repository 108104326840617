import {Component} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ErrorSharedComponent} from './error-shared.component';

@Component({
  selector: 'error401',
  templateUrl: './error401.component.html'
})
export class Error401Component extends ErrorSharedComponent {

  constructor(protected authService: AuthService) {
    super();
  }
}
