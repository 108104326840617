import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'article-config-main-form',
  templateUrl: './article-config-main-form.component.html',
})
export class ArticleConfigMainFormComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() prefix?: string;
  @Input() errors?: { [key: string]: string; } = {};
  errorPrefix = '';

  constructor() { }

  ngOnInit() {
    if (this.prefix) {
      this.errorPrefix = this.prefix + '.';
    }
  }

}
