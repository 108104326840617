import {Ng2StateDeclaration} from '@uirouter/angular';
import {ArticleConfigMainComponent} from './main/article-config-main.component';
import {ProductionTemplateComponent} from './production-template/production-template.component';
import {ProductionStepComponent} from './production-step/production-step.component';
import {ArticleConfigExtraComponent, articleConfigExtraResolve} from './extra/article-config-extra.component';
import {ArticleConfigNavigationComponent} from './navigation/article-config-navigation.component';
import {ArticleSpecificationService} from '../../envisia/article/services/article-specification.service';
import {ProductionTemplateService} from '../../envisia/article/production-services/production-template.service';
import {
  getProductionStepResolve,
  productionStepResolve
} from '../../envisia/article/production-services/production-step.resolve';
import {ProductionStepService} from '../../envisia/article/production-services/production-step.service';
import {ArticleConfigDeleteComponent} from './delete/article-config-delete.component';
import {ArticleReleaseComponent} from './release/article-release.component';
import {ArticleCustomerLockerComponent} from './article-customer-locker/article-customer-locker.component';
import {getArticleSpecificationResolveFn} from '../../envisia/article/article.states';
import {ArticleConfigWeightCalculationComponent} from './weight-calculation/article-config-weight-calculation.component';
import {ArticleWeightService} from '../../envisia/article/services/article-weight.service';
import {ArticleConfigLockComponent} from './lock/article-config-lock.component';
import {ArticleConfigFieldNamesComponent} from './field-names/article-config-field-names.component';
import {ArticleConfigUnlockComponent} from './unlock/article-config-unlock.component';

export const ARTICLE_CONFIG_STATE: Ng2StateDeclaration = {
  parent: 'a',
  name: 'article_config',
  url: '/article/config',
  redirectTo: 'article_config.extra',
  data: {requiresAuth: true}
};

export function getManufacturer(service: ArticleSpecificationService) {
  return service.get('manufacturer').toPromise();
}

export function getSpecs(service: ArticleSpecificationService) {
  return service.descriptionUnfiltered().toPromise();
}

export function getSpecsUncached(service: ArticleSpecificationService) {
  return service.descriptionUncached().toPromise();
}

export function getSpecsUncachedWithoutDeleted(service: ArticleSpecificationService) {
  return service.descriptionUncachedHideDeleted().toPromise();
}

export const ARTICLE_CONFIG_MAIN_STATE: Ng2StateDeclaration = {
  name: 'article_config.main',
  url: '/main',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleConfigMainComponent}
  },
  resolve: [
    {
      token: 'data',
      resolveFn: getManufacturer,
      deps: [ArticleSpecificationService],
    }
  ],
  data: {requiresAuth: true}
};

/** Liefervorschriften */
export const ARTICLE_CONFIG_EXTRA_STATE: Ng2StateDeclaration = {
  name: 'article_config.extra',
  url: '/extra',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleConfigExtraComponent}
  },
  resolve: articleConfigExtraResolve,
  data: {requiresAuth: true}
};

export function getTemplateListResolveFn(service: ProductionTemplateService) {
  return service.list().toPromise();
}

export const templateResolve = [
  {
    token: 'templateList',
    resolveFn: getTemplateListResolveFn,
    deps: [ProductionTemplateService],
  },
  {
    token: 'steps',
    resolveFn: getProductionStepResolve,
    deps: [ProductionStepService],
  }
];

export const ARTICLE_CONFIG_PRODUCTION_TEMPLATE_STATE: Ng2StateDeclaration = {
  name: 'article_config.production_template',
  url: '/production/template',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ProductionTemplateComponent}
  },
  resolve: templateResolve,
  data: {requiresAuth: true}
};

export const ARTICLE_CONFIG_PRODUCTION_STEP_STATE: Ng2StateDeclaration = {
  name: 'article_config.production_step',
  url: '/production/step',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ProductionStepComponent}
  },
  resolve: [productionStepResolve],
  data: {requiresAuth: true}
};

export const ARTICLE_CONFIG_DELETE_STATE: Ng2StateDeclaration = {
  name: 'article_config.delete',
  url: '/delete',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleConfigDeleteComponent}
  },
  data: {requiresAuth: true}
};


export const ARTICLE_CONFIG_RELEASE_STATE: Ng2StateDeclaration = {
  name: 'article_config.release',
  url: '/release',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleReleaseComponent}
  },
  resolve: [
    {
      token: 'specs',
      resolveFn: getSpecsUncached,
      deps: [ArticleSpecificationService],
    }
  ],
  data: {requiresAuth: true}
};

export function getArticleNoticesResolveFn(service: ArticleSpecificationService) {
  return getArticleSpecificationResolveFn(service).then(specification => specification['blocking_notice'].values);
}

export const ARTICLE_CUSTOMER_LOCKER_STATE: Ng2StateDeclaration = {
  name: 'article_config.customer_locker',
  url: '/customer-locker',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleCustomerLockerComponent}
  },
  resolve: [
    {token: 'notices', resolveFn: getArticleNoticesResolveFn, deps: [ArticleSpecificationService]}
  ],
  data: {requiresAuth: true}
};

export function getArticleWeightSettings(service: ArticleWeightService) {
  return service.getConfig().toPromise();
}

export const ARTICLE_CONFIG_WEIGHT_CALCULATION_STATE: Ng2StateDeclaration = {
  name: 'article_config.weight_calculation',
  url: '/weight',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleConfigWeightCalculationComponent}
  },
  resolve: [{token: 'config', resolveFn: getArticleWeightSettings, deps: [ArticleWeightService]}],
  data: {requiresAuth: true}
};

export const ARTICLE_CONFIG_LOCK_STATE: Ng2StateDeclaration = {
  name: 'article_config.lock',
  url: '/lock',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleConfigLockComponent}
  },
  resolve: [
    {token: 'notices', resolveFn: getArticleNoticesResolveFn, deps: [ArticleSpecificationService]}
  ],
  data: {requiresAuth: true}
};

export const ARTICLE_CONFIG_UNLOCK_STATE: Ng2StateDeclaration = {
  name: 'article_config.unlock',
  url: '/unlock',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleConfigUnlockComponent}
  },
  resolve: [
    {token: 'notices', resolveFn: getArticleNoticesResolveFn, deps: [ArticleSpecificationService]}
  ],
  data: {requiresAuth: true}
};

export const ARTICLE_CONFIG_FIELD_NAMES_STATE: Ng2StateDeclaration = {
  name: 'article_config.field_names',
  url: '/field_names',
  views: {
    'navigation@a': {component: ArticleConfigNavigationComponent},
    'main@a': {component: ArticleConfigFieldNamesComponent}
  },
  resolve: [
    {
      token: 'specs',
      resolveFn: getSpecsUncachedWithoutDeleted,
      deps: [ArticleSpecificationService],
    }
  ],
  data: {requiresAuth: true}
};

export const ARTICLE_CONFIG_STATES: Ng2StateDeclaration[] = [
  ARTICLE_CONFIG_STATE,
  ARTICLE_CONFIG_MAIN_STATE,
  ARTICLE_CONFIG_EXTRA_STATE,
  ARTICLE_CONFIG_PRODUCTION_TEMPLATE_STATE,
  ARTICLE_CONFIG_PRODUCTION_STEP_STATE,
  ARTICLE_CONFIG_DELETE_STATE,
  ARTICLE_CONFIG_RELEASE_STATE,
  ARTICLE_CUSTOMER_LOCKER_STATE,
  ARTICLE_CONFIG_WEIGHT_CALCULATION_STATE,
  ARTICLE_CONFIG_LOCK_STATE,
  ARTICLE_CONFIG_UNLOCK_STATE,
  ARTICLE_CONFIG_FIELD_NAMES_STATE,
];
