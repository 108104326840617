import {Ng2StateDeclaration} from '@uirouter/angular';
import {WORKFLOW_CREATE_RESOLVER} from '../../resolver/workflow-data.resolver';
import {WorkflowNavigationComponent} from '../../navigation/workflow-navigation.component';
import {OfferDetailBaseComponent} from './base/offer-detail-base.component';

export const OFFER_MAIN_DETAIL_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.detail.detail_offer',
  params: {data: {dynamic: true}, id: {dynamic: true}},
  resolve: [WORKFLOW_CREATE_RESOLVER],
  views: {
    'navigation@a': {component: WorkflowNavigationComponent},
    'main@a': {component: OfferDetailBaseComponent}
  }
};

export const OFFER_DETAIL_STATES: Ng2StateDeclaration[] = [
  OFFER_MAIN_DETAIL_STATE
];
