import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowService} from '../workflow-services/workflow.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'workflow-status-modal',
  templateUrl: './workflow-status-modal.component.html'
})
export class WorkflowStatusModalComponent implements OnInit {
  @Input() id: number;
  @Input() typ: string;
  name: string;
  success: string;
  lowercaseName: string;
  form: UntypedFormGroup;

  constructor(private modalRef: NgbActiveModal,
              private workflowService: WorkflowService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    if (this.typ === 'cancel') {
      this.name = 'Stornieren';
      this.success = 'storniert';
    } else if (this.typ === 'delete') {
      this.name = 'Löschen';
      this.success = 'gelöscht';
    }
    this.lowercaseName = this.name.toLowerCase();
    this.form = this.fb.group({
      reason: [''],
    });
  }

  closeModal(): void {
    this.modalRef.dismiss(null);
  }

  isDisabled(): boolean {
    return !this.form.getRawValue().reason;
  }

  save(): void {
    let data;
    let id;
    if (this.typ === 'cancel') {
      data = 'cancelled';
      id = 100;
    } else if (this.typ === 'delete') {
      data = 'deleted';
      id = 101;
    }
    if (data) {
      this.workflowService.updateStatus(this.id, data, this.form.getRawValue().reason).subscribe(() => {
        this.alertService.add('success', 'Auftrag erfolgreich ' + this.success + '!');
        this.modalRef.close(id);
      }, () => {
        this.alertService.add('danger', 'Beim ' + this.name + ' ist ein Fehler aufgetreten!');
      });
    }
  }

}
