<h3>Freigabe-Masken</h3>

<div class="row">

  <div class="col-lg-8">
    <table class="table whitetable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Datenbank-ID</th>
          <th>Typ</th>
          <th>Produktions-Freigabe</th>
          <th>Preis-Freigabe</th>
          <th>Gelöscht</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of specs" [ngClass]="{'alert-danger': item.deleted}">
          <td>{{item.title}}</td>
          <td>{{item.name}}</td>
          <td>{{item.dateType}}</td>
          <td>
            <button type="button" class="ev-button" (click)="toggleProd(item.name)"
                    *ngIf="!item.production_release && !item.deleted">
              <i class="fa fa-toggle-off fa-2x"></i>
            </button>
            <button type="button" class="ev-button" (click)="toggleProd(item.name)"
                    *ngIf="!!item.production_release && !item.deleted">
              <i class="fa fa-toggle-on fa-2x" style="color: green;"></i>
            </button>
          </td>
          <td>
            <button type="button" class="ev-button" (click)="togglePrice(item.name)"
                    *ngIf="!item.price_release && !item.deleted">
              <i class="fa fa-toggle-off fa-2x"></i>
            </button>
            <button type="button" class="ev-button" (click)="togglePrice(item.name)"
                    *ngIf="!!item.price_release && !item.deleted">
              <i class="fa fa-toggle-on fa-2x" style="color: green;"></i>
            </button>
          </td>
          <td>{{item.deleted}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-lg-3">
    <h3>Freigabe-Historie</h3>
    <div class="row comment-row">
      <comment [comments]="comments" [canEdit]="false" [item]="null" typ="'article_spec'"></comment>
    </div>
  </div>
  <div class="col-lg-1">
  </div>

</div>
