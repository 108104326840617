import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {IOposListData, OposGlobalListData, OposListData, OposSaveHandler} from '../opos.models';
import {Lister} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {ListService} from '../../../common/search/search.service';

@Injectable({providedIn: 'root'})
export class OposService implements ListService<OposGlobalListData> {

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<OposGlobalListData>> {
    return this.http.get<Lister<OposGlobalListData>>(
      '/api/v1/workflow/opos/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  customer(id: number, filter: { invoices?: string | null, date?: string | null }): Observable<OposListData> {
    return this.http.get<{ data: IOposListData }>(
      '/api/v1/workflow/opos/' + id + '/',
      {params: EnvisiaLocation.httpParams(filter)}
    ).pipe(map(value => OposListData.fromInterface(value.data)));
  }

  save(id: number, invoices: string | undefined | null, data: OposSaveHandler): Observable<OposListData> {
    return this.http.put<{ data: IOposListData }>(
      '/api/v1/workflow/opos/' + id + '/',
      data,
      {params: EnvisiaLocation.httpParams({invoices: invoices})}
    ).pipe(map(value => OposListData.fromInterface(value.data)));
  }
}
