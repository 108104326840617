import {Component, ErrorHandler, Input, OnDestroy, OnInit} from '@angular/core';
import {Article, ArticleListData} from '../../article/models/article.models';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../common/alert-service/alert.service';
import {debounceTime, map, tap} from 'rxjs/operators';
import {ArticleService} from '../../article/services/article.service';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {CustomerService} from '../../customer/services/customer.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'label-article-select-modal',
  templateUrl: './label-article-select-modal.component.html'
})
export class LabelArticleSelectModalComponent implements OnInit, OnDestroy {
  @Input() currentArticle: ArticleListData | null;
  @Input() disableDismissFn: (value: boolean) => void;
  data: LoadingWrapper<ArticleListData[]>;
  selected: string | null;
  form: UntypedFormGroup;
  public disabledButton = false;
  private subscription?: Subscription;

  constructor(private fb: UntypedFormBuilder,
              private modalRef: NgbActiveModal,
              private articleService: ArticleService,
              private customerService: CustomerService,
              private alertService: AlertService,
              private errorHandler: ErrorHandler) {
  }

  ngOnInit(): void {
    const sdsNr = this.currentArticle ? this.currentArticle.sds_nr : null;
    this.selected = this.currentArticle ? this.currentArticle.oa_nr : null;
    this.form = this.fb.group({
      name: [{value: this.selected, disabled: true}],
    });

    this.data = this.query(sdsNr);

    this.subscription = this.form.valueChanges.pipe(debounceTime(550)).subscribe(data => {
      // we reset the selected state, so that no invalid article
      // will be set
      this.selected = null;
      this.form.get('name').disable({emitEvent: false});
      this.data = this.query(data.name);
    }, () => {
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  select(article?: ArticleListData): void {
    if (article) {
      this.currentArticle = article;
      this.selected = article.oa_nr;
    }
  }

  ok(): void {
    if (this.currentArticle) {
      this.disabledButton = true;
      this.disableDismissFn(true);

      if (this.currentArticle.customer) {
        this.customerService.byName(this.currentArticle.customer).subscribe(customer => {
          this.modalRef.close({article: this.currentArticle, customer: customer});
        }, (response: HttpErrorResponse) => {
          this.errorHandler.handleError({error: response, message: 'article label box could not find customer'});
          this.alertService.add('danger', 'Artikel konnte nicht gesetzt werden!');
          this.disabledButton = false;
          this.disableDismissFn(false);
        });
      } else {
        this.modalRef.close({article: this.currentArticle, customer: null});
      }
    } else {
      this.modalRef.dismiss();
    }
  }

  cancel(): void {
    this.modalRef.dismiss();
  }

  private query(value: string): LoadingWrapper<ArticleListData[]> {
    return new LoadingWrapper<ArticleListData[]>(this.articleService.search({
      sds_nr: value,
      limit: 20
    }).pipe(
      map(dataValue => dataValue),
      tap(() => this.form.get('name').enable({emitEvent: false})),
    ));
  }

}
