<div class="buttonbar-view">
  <material-planning-force-check-button (reloaded)="reload()"
                                        *ngIf="('material.planning.manual.start'|HasPerm)">
  </material-planning-force-check-button>
</div>

<div class="filter-row">
  <div class="filter-left">
    <div class="row">
      <div class="col-sm-12">
        <button class="btn btn-info btn-sm" type="button" (click)="badge('0')" [ngClass]="{active: q('0')}">
          Warteschlange <span class="badge">{{ list.badges?.one ?? 0 }}</span>
        </button>

        <button class="btn btn-danger btn-sm" type="button" (click)="badge('1000')" [ngClass]="{active: q('1000')}">
          Handlungsbedarf <span class="badge">{{ list.badges?.cfa ?? 0 }}</span>
        </button>

        <button class="btn btn-warning btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
          In Produktion <span class="badge">{{ list.badges?.two ?? 0 }}</span>
        </button>

        <button class="btn btn-primary btn-sm" type="button" (click)="badge('2')" [ngClass]="{active: q('2')}">
          Lagerbuchung <span class="badge">{{ list.badges?.three ?? 0 }}</span>
        </button>

        <button class="btn btn-rose btn-sm" type="button" (click)="badge('3')" [ngClass]="{active: q('3')}">
          Etikettendruck <span class="badge">{{ list.badges?.four ?? 0 }}</span>
        </button>

        <button class="btn btn-success btn-sm" type="button" (click)="badge('4')" [ngClass]="{active: q('4')}">
          Abgeschlossen <span class="badge">{{ list.badges?.five ?? 0 }}</span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <button class="btn btn-default btn-sm" type="button" (click)="badge('all')" [ngClass]="{active: q('all')}">
          Alle
        </button>

        <button class="btn btn-default btn-sm" type="button" (click)="badge('100')" [ngClass]="{active: q('100')}">
          Storniert
        </button>

        <button class="btn btn-default btn-sm" type="button" (click)="badge('102')" [ngClass]="{active: q('102')}">
          Kompletter Ausschuss <span class="badge">{{ list.badges?.isWasted ?? 0 }}</span>
        </button>

        <button class="btn btn-default btn-sm" type="button"
                [ngClass]="{active: q2.is_reproduction?.toString() === 'true'}"
                (click)="badgeToggle('is_reproduction', true)">
          Nachfertigung
        </button>

        <button class="btn btn-default btn-sm" type="button" (click)="badge('150')" [ngClass]="{active: q('150')}">
          Nachfertigung zusammengefügt
        </button>

        <ev-file-button style="margin-right:30px;"
                        [url]="downloadUrl('/api/v1/workflow/business/order/export/')"
                        [link]="false">
          <i class="fa fa-download"></i>
        </ev-file-button>

        <button class="btn btn-default btn-sm" type="button" (click)="selectStandard()">
          Material Zuordnung <span class="badge">{{ list.badges?.isStandard ?? 0 }}</span>
        </button>

        <button class="btn btn-warning btn-sm" type="button" (click)="selectNoMapping()">
          Zuordnungsfehler <span class="badge">{{ list.badges?.noMapping ?? 0 }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="filter-right row">
    <div class="col-sm-6">
      <label>Start-Termin</label>
      <date-range [start]="data['range_start_start']" [end]="data['range_start_end']" (update)="updateDateRangeStart($event)"></date-range>
    </div>

    <div class="col-sm-6">
      <label>End-Termin</label>
      <date-range [start]="data['range_end_start']" [end]="data['range_end_end']" (update)="updateDateRangeEnd($event)"></date-range>
    </div>
  </div>
</div>

<form #changeForm="ngForm" name="form" *ngIf="list">

  <span class="label label-warning"
        style="padding: 12px 6px 6px 6px;"
        *ngIf="!!filteredMaterial && !!data.variation_id">
    <strong><i class="fa fa-exclamation-triangle fa-2x" style="color: #FFF !important;"></i> Achtung:</strong>
    Es werden nur Ergebnisse für BAs angezeigt, die das Material
    <a uiSref="a.material.inventory.update"
       target="_blank"
       [uiParams]="{variation: filteredMaterial.variation.id}">
      {{filteredMaterial.variation.variation_id}} {{filteredMaterial.article.name}}
    </a>
    verwenden.
    <strong>
      <a (click)="unsetVariation()" class="click" style="color: #FFF;">
        Filter Zurücksetzen <i class="fa fa-times"></i>
      </a>
    </strong>
  </span>

  <table class="table whitetable">
    <thead>
    <tr>
      <th style="width: 110px;"><a (click)="sort($event, 'id', 'ASC')" [href]="sortUrl('id', 'ASC')">BA-Nr</a></th>
      <th style="width: 80px;">AT</th>
      <th><a (click)="sort($event, 'status', 'ASC')" [href]="sortUrl('status', 'ASC')">Status</a></th>
      <th>
        <a (click)="sort($event, 'current_step', 'ASC')" [href]="sortUrl('current_step', 'ASC')">
          Fertigungsschritt
        </a>
      </th>
      <th style="width: 77px;"><a (click)="sort($event, 'workflow_id', 'ASC')" [href]="sortUrl('workflow_id', 'ASC')">Vorgang</a>
      </th>
      <th style="width: 105px;">
        <a (click)="sort($event, 'start_date', 'ASC')" [href]="sortUrl('start_date', 'ASC')">Start-Termin</a>
      </th>
      <th style="width:105px;"><a (click)="sort($event, 'end_date', 'ASC')" [href]="sortUrl('end_date', 'ASC')">End-Termin</a>
      </th>
      <th class="align-right">
        <a (click)="sort($event, 'quantity', 'ASC')" [href]="sortUrl('quantity', 'ASC')">Best.&nbsp;Menge</a>
      </th>
      <th class="align-right" colspan="2">
        Menge&nbsp;BA
      </th>
      <th class="align-right" style="width: 85px;">
        <a (click)="sort($event, 'good_piece', 'ASC')" [href]="sortUrl('good_piece', 'ASC')">Gutstück</a>
      </th>
      <th style="width: 70px;">
        <a (click)="sort($event, 'lp_type', 'ASC')" [href]="sortUrl('lp_type', 'ASC')">LP-Typ</a>
      </th>
      <th style="width: 70px;">
        <a (click)="sort($event, 'hdi_type', 'ASC')" [href]="sortUrl('hdi_type', 'ASC')">HDI-Typ</a>
      </th>
      <th>
        <a (click)="sort($event, 'target_material', 'ASC')" [href]="sortUrl('target_material', 'ASC')">
          Material Vorgabe
        </a>
      </th>
      <th style="width: 80px;"><a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">SDS-Nr.</a>
      </th>
      <th><a (click)="sort($event, 'kd_art_nr', 'ASC')" [href]="sortUrl('kd_art_nr', 'ASC')">Kd-Art-Nr.</a></th>
      <th><a (click)="sort($event, 'customer', 'ASC')" [href]="sortUrl('customer', 'ASC')">Kunde</a></th>
      <th><a (click)="sort($event, 'date_created', 'ASC')" [href]="sortUrl('date_created', 'ASC')">Erstellung</a></th>
      <th>&nbsp;</th>
      <th></th>
    </tr>

    <tr class="filterrow">
      <th><input type="text" class="form-control input-sm" name="sid" [(ngModel)]="data.sid" [autofocus] autocomplete="off search-business-order-id"></th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th><input type="text" class="form-control input-sm" name="current_step" [(ngModel)]="data.current_step"></th>
      <th><input type="text" class="form-control input-sm" name="quantity" [(ngModel)]="data.workflow_id"></th>
      <th>
        <ev-datepicker class="form-control input-sm" name="start_date" [(ngModel)]="data.start_date"></ev-datepicker>
      </th>
      <th>
        <ev-datepicker class="form-control input-sm" name="end_date" [(ngModel)]="data.end_date"></ev-datepicker>
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="quantity" [(ngModel)]="data.quantity">
      </th>
      <th colspan="2"></th>
      <th>
        <input type="text" class="form-control input-sm" name="good_piece" [(ngModel)]="data.good_piece">
      </th>
      <th>
        <article-list-spec-select specName="execution"
                                  name="lp_type"
                                  [specification]="specification"
                                  [(ngModel)]="data.lp_type">
        </article-list-spec-select>
      </th>
      <th>
        <article-list-spec-select specName="hdi_type"
                                  name="hdi_type"
                                  [specification]="specification"
                                  [(ngModel)]="data.hdi_type">
        </article-list-spec-select>
      </th>
      <th>
        <select class="form-control input-sm" id="ba-target-material"
                name="target_material"
                [(ngModel)]="data.target_material">
          <option [ngValue]="null">-</option>
          <option value="standard*">Alle Standard FR4</option>
          <option *ngFor="let name of getMatVals();let index = index" [value]="name">{{ name }}</option>
          <option value="no_mapping*">Zuordnungsfehler</option>
        </select>
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="sds_nr" [(ngModel)]="data.sds_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="kd_art_nr" [(ngModel)]="data.kd_art_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="customer" [(ngModel)]="data.customer">
      </th>
      <th>
        <ev-datepicker class="form-control input-sm" name="date_created" [(ngModel)]="data.date_created">
        </ev-datepicker>
      </th>
      <th>&nbsp;</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr [business-order-list-element]="ba"
        (business-order-list-element-reload)="reload()"
        *ngFor="let ba of list.objects; trackBy: trackByFn; let topIndex = index;">
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
