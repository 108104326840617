<form class="form-horizontal">
  <h3>Konfiguration</h3>

  <div class="row">
    <div class="col-md-12">

      <button type="button" class="btn btn-success" (click)="newProductionStep()" *ngIf="('article.production_step_setting'|HasPerm)">
        Neuer Fertigungsschritt
      </button>

    </div>

    <div class="col-md-12">&nbsp;</div>

    <div class="col-md-4">

      <table class="table whitetable">

        <thead>
        <tr>
          <th>Nr</th>
          <th>Bezeichnung</th>
          <th>Dauer</th>
          <th>Min-Dauer</th>
          <th>Max-Dauer</th>
          <th>Wertigkeit</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let obj of objects; trackBy: trackById; let index = index" style="text-align: center; vertical-align: middle;">
          <td>{{ obj.nr }}</td>
          <td>{{ obj.text }}</td>
          <td>{{ obj.duration }} m</td>
          <td>{{obj.min_duration}} m</td>
          <td>{{ obj.max_duration }} m</td>
          <td>{{obj.significance}}</td>
          <td>
            <button type="button" class="ev-button" (click)="stepEdit(index, obj)">
              <i class="fa fa-2x fa-pencil"></i>
            </button>
          </td>
          <td>
            <button type="button" class="btn btn-default btn-sm" (click)="editArticle(index, obj)">
              Artikelfelder
            </button>
          </td>
          <td>
            <button type="button" class="ev-button" (click)="toggle(index, obj)"
                    *ngIf="!obj.active && ('settings.settings'|HasPerm)">
              <i class="fa fa-toggle-off fa-2x"></i>
            </button>
            <button type="button" class="ev-button" (click)="toggle(index, obj)"
                    *ngIf="obj.active  && ('settings.settings'|HasPerm)">
              <i class="fa fa-toggle-on fa-2x" style="color: green;"></i>
            </button>
          </td>
        </tr>
        </tbody>

      </table>

    </div>

  </div>

</form>
