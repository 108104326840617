/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-microsection',
  templateUrl: './article-extra-microsection.component.html',
})
export class ArticleExtraMicrosectionComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'microsection_analysis';
  protected schemaName = 'microsection-analysis';
  protected fields = [
    'every_batch',
    'every_batch_other',
    'sds_cut_image_analysis',
    'sds_cut_image_analysis_other',
    'email',
    'email_other',
    'paper',
    'paper_other',
    'with_pattern_measurement',
    'with_pattern_measurement_other',
    'with_grounded_chunk',
    'with_grounded_chunk_other',
  ];
}
