<form name="additional" [formGroup]="form">
  <div class="row">
    <div class="col-md-4 form-horizontal">
      <div class="form-group">
        <label for="contract-date" class="col-sm-3 control-label">Auf.-Best.-Datum:</label>

        <div class="col-sm-5">
          <ev-datepicker id="contract-date" formControlName="contract_date" class="form-control" [allDates]="true"
                      [placeholder]="'___ . ___ . ______'" [writeBack]="false"
                      [evClass]="{red_alert: errors['obj.contract_date']}">
          </ev-datepicker>
        </div>
        <div class="col-sm-4"></div>
      </div>

      <div class="form-group" *ngIf="contractList.length !== 0">
        <label for="new-nr" class="col-sm-3 control-label">Neue Nr.</label>

        <div class="col-sm-5">
          <input type="checkbox" id="new-nr" class="form-control"
                 [checked]="current.nr"
                 [ngClass]="{red_alert: errors['obj.nr']}"
                 (click)="toggle()">
        </div>
        <div class="col-md-12" *ngIf="!current.nr">
          <div class="alert alert-danger" style="margin-top: 15px;">
            <strong>Achtung:</strong> Wenn keine neue Nummer verwendet wird,
            wird die letzte Auftragsbestätigung storniert und überschrieben.
            Diese Funktion sollte nur zum Korrigieren vorhandener ABs dienen,
            nicht zum Bestätigen neuer Positionen.
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-4 form-horizontal">
      <div class="form-group">
        <label for="order-nr" class="col-sm-3 control-label">Bestell-Nr.:</label>

        <div class="col-sm-5">
          <input id="order-nr" type="text" class="form-control" formControlName="order_nr"
                 [ngClass]="{red_alert: errors['obj.order_nr']}">
        </div>
        <div class="col-sm-4"></div>
      </div>
      <div class="form-group">
        <label class="col-sm-3 control-label" for="order-date">Bestell-Datum:</label>

        <div class="col-sm-5">
          <ev-datepicker id="order-date" formControlName="order_date" class="form-control" [writeBack]="false"
                      [allDates]="true" [placeholder]="'___ . ___ . ______'"
                      [evClass]="{red_alert: errors['obj.order_date']}"></ev-datepicker>
        </div>
        <div class="col-sm-4"></div>
      </div>
    </div>
    <div class="col-md-4 form-horizontal">
      <div class="form-group">
        <label class="col-sm-3 control-label" for="advice">Hinweis:</label>

        <div class="col-sm-9">
          <textarea class="form-control" id="advice" formControlName="advice"
                    [ngClass]="{red_alert: errors['obj.advice']}">
          </textarea>
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-sm-3">Lieferadresse: *</label>
        <address-box class="col-sm-9"
                     [addresses]="addresses"
                     [invoiceId]="workflow.object.delivery_id"
                     [button]="true"
                     (updateEmitter)="updateDeliveryAddress($event)">
        </address-box>
      </div>

    </div>

  </div>
</form>
