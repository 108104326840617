import {Component, ErrorHandler, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {MaterialPlanningSmallListService} from './material-planning-small-list.service';
import {MaterialPlanningDate, MaterialPlanningRelationWithWeek} from './material-planning.model';
import {PaginationBaseComponent} from '../../../common/pagination/pagination.component';
import {DateHelper} from '../../../helper/date-helper';

@Component({
  selector: 'material-planning-small-list',
  templateUrl: './material-planning-small-list.component.html',
})
export class MaterialPlanningSmallListComponent
  extends PaginationBaseComponent<MaterialPlanningSmallListService, MaterialPlanningRelationWithWeek>
  implements OnInit, OnChanges {
  @Input() currentVariationId: number;
  @Input() selectedWeek: MaterialPlanningDate;
  @Input() list: Lister<MaterialPlanningRelationWithWeek>;

  data: { [key: string]: string } = {};
  type = 'material_planning_small';

  constructor(protected service: MaterialPlanningSmallListService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  ngOnInit() {
    this.query({
      'variation_id': this.currentVariationId.toString(10),
      'first_day_of_week': this.selectedWeek.first_day_of_week,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.selectedWeek && !changes.selectedWeek.isFirstChange()) ||
        (changes.currentVariationId && !changes.currentVariationId.isFirstChange())) {
      this.query({
        'variation_id': this.currentVariationId.toString(10),
        'first_day_of_week': this.selectedWeek.first_day_of_week,
      });
    }
  }

  get linkParams() {
    return {
      variation_id: this.currentVariationId,
      range_start_start: DateHelper.format(DateHelper.parseISO8601(this.selectedWeek.first_day_of_week)),
      range_start_end: DateHelper.format(DateHelper.parseISO8601(this.selectedWeek.last_day_of_week)),
      status: 0,
    };
  }

}
