<td>{{transaction.position.variation.variation_id}}</td>
<td>{{transaction.position.variation.article.name}}</td>
<td class="align-right">{{transaction.amount|money}}</td>
<td>{{transaction.transaction.charge}}</td>
<td>{{transaction.transaction.location}}</td>
<td>
  <a [href]="pdfUri" target="_blank" title="Etiketten PDF öffnen">
    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
  </a>
</td>
<td>
  <material-label-print-button [variationId]="transaction.position.variation_id">
  </material-label-print-button>
</td>
