import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {EnvisiaLocation} from '../../../../../common/location/envisia-location';
import {ArticleMultilayerPlan} from '../article-multilayer-plan.models';
import {Lister} from '../../../../../common/wrapper.models';

@Injectable({
  providedIn: 'root'
})
export class ArticleMultilayerPlanCopyListService {
  constructor(private http: HttpClient) {
  }

  public list(query: any): Observable<Lister<ArticleMultilayerPlan>> {
    return this.http.get<Lister<ArticleMultilayerPlan>>(
      `${environment.apiv4uri}multilayerplan/all`,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}
