import {Component, Input, OnInit} from '@angular/core';
import {
  MaterialGroupAndRelationWithClasses,
  MaterialProductGroup,
  MaterialProductGroupRelation
} from '../material-product-groups.models';
import {MaterialGroup} from '../../groups/material-group.model';
import {MaterialProductGroupsRelationService} from '../material-product-groups-relation.service';
import {noop} from '../../../../helper/noop';
import {EnvisiaObjects, Lister} from '../../../../common/wrapper.models';
import {Observable} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'material-product-group-selection',
  templateUrl: './material-product-groups-selection-modal.component.html',
})
export class MaterialProductGroupsSelectionModalComponent implements OnInit {
  @Input() productGroup: MaterialProductGroup;
  @Input() relations: EnvisiaObjects<MaterialProductGroupRelation>;
  @Input() groups: EnvisiaObjects<MaterialGroup>;
  groupsWithRelations: MaterialGroupAndRelationWithClasses[] = [];

  lock = false;

  constructor(private relationService: MaterialProductGroupsRelationService,
              private modalRef: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.rebuildList();
  }

  toggleGroup(reference: MaterialGroupAndRelationWithClasses): void {
    this.lock = true;
    (!reference.mpgr ? this.create(reference.mg.id) : this.delete(reference.mg.id)).subscribe(() => {
      this.reloadRelations();
    }, () => {
      this.lock = false;
    });
  }

  create(groupId: number): Observable<any> {
    return this.relationService.create(this.productGroup.id, groupId);
  }

  delete(groupId: number): Observable<any> {
    return this.relationService.delete(this.productGroup.id, groupId);
  }

  reloadRelations(): void {
    this.relationService.list(this.productGroup.id).subscribe(relations => {
      this.relations = relations;
      this.rebuildList();
    }, () => {
      this.lock = false;
    });
  }

  rebuildList(): void {
    this.groupsWithRelations = this.groups.objects.map(mg => {
      const relation = this.relations.objects.find(r => r.group_id === mg.id);
      return {
        mg: mg,
        mpgr: relation,
        classes: {'fa-toggle-on': !!relation, 'green': !!relation, 'fa-toggle-off': !relation},
      };
    });

    this.lock = false;
  }

  close(): void {
    this.modalRef.close();
  }

  trackByFn(index: number, item: MaterialGroupAndRelationWithClasses): number {
    noop(this);
    return item.mg.id;
  }
}
