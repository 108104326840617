<ng-container *ngIf="priceIncreaseLoader.data$|async as wrapper; else loadingOrError.template">
  <div *ngIf="(!wrapper.object && ('workflow.price.increase.flex'|HasPerm))">
    <workflow-price-increase-flex-empty [workflowId]="workflowId" (update)="reload()">
    </workflow-price-increase-flex-empty>
  </div>
  <div *ngIf="!!wrapper.object">
    <workflow-price-increase-flex-view [flexPriceIncrease]="wrapper.object" (update)="reload()">
    </workflow-price-increase-flex-view>
  </div>
</ng-container>

<loading-or-error #loadingOrError
                  [loadingWrapper]="priceIncreaseLoader"
                  [errorMessage]="'Konnte Flexible Preiserhöhung nicht laden'">
</loading-or-error>
