import {NgModule} from '@angular/core';
import {ArticleConfigMainComponent} from './main/article-config-main.component';
import {ArticleConfigMainUpdateComponent} from './main/article-config-main-update.component';
import {ProductionTemplateComponent} from './production-template/production-template.component';
import {ProductionStepComponent} from './production-step/production-step.component';
import {ProductionStepCreateComponent} from './production-step/production-step-create.component';
import {ProductionStepEditComponent} from './production-step/production-step-edit.component';
import {ProductionStepArticleComponent} from './production-step/production-step-article.component';
import {ProductionTemplateCreateComponent} from './production-template/production-template-create.component';
import {ProductionTemplateEditComponent} from './production-template/production-template-edit.component';
import {ProductionTemplateStepEditComponent} from './production-template/production-template-step-edit.component';
import {ArticleConfigExtraComponent} from './extra/article-config-extra.component';
import {UIRouterModule} from '@uirouter/angular';
import {ArticleConfigNavigationComponent} from './navigation/article-config-navigation.component';
import {ARTICLE_CONFIG_STATES} from './article-config.states';
import {SharedModule} from '../../core/shared.module';
import {ArticleExtraModule} from '../../envisia/article-extra/article-extra.module';
import {DndModule} from '../dnd';
import {ArticleConfigDeleteComponent} from './delete/article-config-delete.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {ProductionStepArticleTitleComponent} from './production-step/production-step-article-title.component';
import {ArticleReleaseComponent} from './release/article-release.component';
import {CommentModule} from '../../envisia/comment/comment.module';
import { ArticleConfigMainCreateComponent } from './main/article-config-main-create/article-config-main-create.component';
import { ArticleConfigMainFormComponent } from './main/article-config-main-form/article-config-main-form.component';
import { ArticleCustomerLockerComponent } from './article-customer-locker/article-customer-locker.component';
import {CustomerBoxModule} from '../../envisia/customer/box/customer-box.module';
import { ArticleConfigWeightCalculationComponent } from './weight-calculation/article-config-weight-calculation.component';
import {ArticleMainModule} from '../../envisia/article/main/article-main.module';
import { ArticleConfigRedirectComponent } from './redirect/article-config-redirect.component';
import {ArticleConfigLockComponent} from './lock/article-config-lock.component';
import { ArticleConfigFieldNamesComponent } from './field-names/article-config-field-names.component';
import { ArticleConfigFieldNamesEditModalComponent } from './field-names/edit-modal/article-config-field-names-edit-modal.component';
import {ArticleConfigUnlockComponent} from './unlock/article-config-unlock.component';

@NgModule({
    imports: [
        UIRouterModule,
        UIRouterModule.forChild({ states: ARTICLE_CONFIG_STATES }),
        NgbModalModule,
        SharedModule,
        ArticleExtraModule,
        // Drag & Drop
        DndModule.forRoot(),
        CommentModule,
        CustomerBoxModule,
        ArticleMainModule,
    ],
    declarations: [
        ArticleConfigNavigationComponent,
        ArticleConfigMainComponent,
        ArticleConfigMainUpdateComponent,
        ProductionTemplateComponent,
        ProductionTemplateCreateComponent,
        ProductionTemplateEditComponent,
        ProductionStepComponent,
        ProductionStepCreateComponent,
        ProductionStepEditComponent,
        ProductionStepArticleComponent,
        ProductionTemplateStepEditComponent,
        ArticleConfigExtraComponent,
        ArticleConfigDeleteComponent,
        ProductionStepArticleTitleComponent,
        ArticleReleaseComponent,
        ArticleConfigMainCreateComponent,
        ArticleConfigMainFormComponent,
        ArticleCustomerLockerComponent,
        ArticleConfigWeightCalculationComponent,
        ArticleConfigRedirectComponent,
        ArticleConfigLockComponent,
        ArticleConfigUnlockComponent,
        ArticleConfigFieldNamesComponent,
        ArticleConfigFieldNamesEditModalComponent,
    ],
    exports: [
        ArticleConfigRedirectComponent
    ]
})
export class ArticleConfigModule {
}
