import {Ng2StateDeclaration} from '@uirouter/angular';
import {OfferService} from '../workflow-services/offer.service';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {OfferListComponent} from './list/offer-list.component';
import {WORKFLOW_CREATE_RESOLVER} from '../resolver/workflow-data.resolver';
import {OfferContractCreateComponent} from './create/offer-contract-create.component';
import {ErpTransition} from '../../../core/erp-transition.service';
import {OfferCreateComponent} from './create/offer-create.component';
import {
  ConfigOfferRejectionReasonAxiosService
} from '../../../lazy/config/offer-rejection/config-offer-rejection-reason-axios.service';
import {OFFER_ANALYSIS_STATE} from './analysis/offer-analysis-states';
import {OfferListService} from './list/offer-list.service';

export const OFFER_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.o',
  abstract: true,
  data: {requiresAuth: true}
};

export function offerListResolveFn(transition: ErpTransition, service: OfferListService) {
  return service.list(transition.params()).toPromise();
}

export function offerCreateDateTextResolveFn(transition: ErpTransition, service: OfferService) {
  const workflowId = transition.params().id;
  return service.dateText(workflowId).toPromise();
}

export async function configOfferRejectionListResolveFn() {
  const response = await ConfigOfferRejectionReasonAxiosService.list();
  return response.data;
}

export const OFFER_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.o.list',
  url: '/workflow/offer/list?page&order&sort&date_start&date_end&typ&status&workflow_status&sid&workflow_id&resubmission_date&sds_nr&oa_nr&kd_art_nr&customer_name&customer_zip&customer_city&customer_country&customer_field_service&username',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: OfferListComponent}
  },
  resolve: [
    {token: 'listData', resolveFn: offerListResolveFn, deps: [ErpTransition, OfferListService]},
    {token: 'rejectionReasons', resolveFn: configOfferRejectionListResolveFn, deps: []},
  ],
  dynamic: true,
  data: {requiresAuth: true}
};


export const OFFER_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.create.create_offer_create',
  params: {data: {dynamic: true}, name: {}, id: {}},
  resolve: [
    WORKFLOW_CREATE_RESOLVER,
    {token: 'dateText', resolveFn: offerCreateDateTextResolveFn, deps: [ErpTransition, OfferService]}
  ],
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: OfferCreateComponent}
  }
};

export const OFFER_CONTRACT_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.create.create_offer_create_contract',
  params: {data: {dynamic: true}, name: {}, id: {}},
  resolve: [WORKFLOW_CREATE_RESOLVER],
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: OfferContractCreateComponent}
  }
};

export const OFFER_STATES: Ng2StateDeclaration[] = [
  OFFER_MAIN_STATE,
  OFFER_LIST_STATE,
  OFFER_CREATE_STATE,
  OFFER_CONTRACT_CREATE_STATE,
  OFFER_ANALYSIS_STATE,
];
