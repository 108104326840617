import * as React from 'react';

interface Props {
  onClick: () => void;
}

export function ArticleMultilayerPlanCopyButton(props: Props) {
  return <>
    <button className={'btn btn-default float-right'} onClick={() => props.onClick()}>
      Plan Übernehmen
    </button>
  </>;
}

