<ng-container *ngIf="isFirst">
  <td>
    <material-order-position-status [id]="position.position_id"
                                    [status]="position.status"
                                    (updatePosition)="updatePositionEmitter.emit($event)">
    </material-order-position-status>
  </td>
  <td class="align-right">{{position.index + 1}}</td>
  <td class="align-right">
    <span *ngIf="position.delivery_index === 0">1</span>
    <strong *ngIf="position.delivery_index > 0">{{position.delivery_index + 1}}</strong>
  </td>
  <td *ngIf="groupPermission">
    <a uiSref="a.material.inventory.update" target="_blank" [uiParams]="{variation: position.id}">
      {{position.variation_id}}
    </a>
  </td>
  <td *ngIf="!groupPermission">
    {{position.variation_id}}
  </td>
  <td>{{position.vendor_article_id}}</td>
  <td>{{position.article_name}}</td>
  <td [ngClass]="{'align-right': field.isValueType('Number')}" *ngFor="let field of fields">
    <ng-container [ngSwitch]="field.data_type" *ngIf="!(position.fields[field.html_name]|isNull)">
      <ng-container *ngSwitchCase="'Option'">
        <i class="fa" [ngClass]="{
          'fa-check': (position.fields[field.html_name] === true),
          'fa-times': (position.fields[field.html_name] === false)}"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'Ganzzahl'">
        {{ position.fields[field.html_name] | money:0 }}
      </ng-container>
      <ng-container *ngSwitchCase="'Kommazahl'">
        {{ position.fields[field.html_name] | money }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ position.fields[field.html_name] }}
      </ng-container>
    </ng-container>
  </td>
  <td>{{position.date_delivery|date:'dd.MM.yyyy'}}</td>
  <td class="align-right">{{ position.amount_ordered|money }}</td>
</ng-container>

<ng-container *ngIf="!isFirst">
  <td [colSpan]="8 + fields.length"></td>
</ng-container>

<ng-container *ngIf="('material.order.price.check'|HasPerm)">
  <td class="align-right" [formGroup]="formTransaction" *ngIf="transaction.new_transaction === true">
    <input type="text"
           class="form-control inline-block align-right"
           style="width: 65px;"
           formControlName="price"
           [ngClass]="{'red_alert': !!errorPrice}"/>
  </td>
  <td class="align-right" *ngIf="transaction.new_transaction !== true">
    <ng-container *ngIf="!!position.price">
      {{position.price|money:2}}&nbsp;&euro;
    </ng-container>
  </td>

  <td class="align-right" *ngIf="!isFirst"></td>
  <td *ngIf="isFirst">
    <a class="inline-block click" [title]="setStatusTooltip" (click)="confirmPrice()">
      <i class="fa row-icon" [ngClass]="positionPriceConfirmed" aria-hidden="true"></i>
    </a>
  </td>
</ng-container>

<td>{{transaction.transaction.charge}}</td>
<td>{{transaction.transaction.location}}</td>
<td class="align-right">{{ transaction.transaction.amount|money }}</td>

<td class="icon">
  <a [href]="pdfUri"
     target="_blank"
     title="Etiketten PDF öffnen">
    <i class="fa fa-file-pdf-o row-icon" aria-hidden="true"></i>
  </a>
</td>

<td class="icon">
  <a class="click"
     title="Zusammen eingebuchtes Material anzeigen"
     (click)="openPrint(transaction.id)">
    <i class="fa fa-info-circle row-icon" aria-hidden="true"></i>
  </a>
</td>

<td>
  <a class="click"
     title="Positionsstatus auf &quot;Bestätigt&quot; zurücksetzen"
     (click)="setStatus(1)"
     *ngIf="('material.order.status'|HasPerm)">
    <i class="fa fa-undo row-icon" aria-hidden="true"></i>
  </a>
</td>

<td class="icon">
  <a class="click"
     title="Einbuchung und alle dazugehörigen Umbuchungen löschen"
     (click)="remove()">
    <i class="fa fa-trash-o red_font row-icon" aria-hidden="true"></i>
  </a>
</td>

<td class="icon" *ngIf="('material.order.price.check'|HasPerm)">
  <a [href]="finderSupplierUri"
     target="_blank"
     class="click"
     title="Im envisia finder öffnen">
    <img src="/ui/assets/images/logo_e_icon.svg" width="20px">
  </a>
</td>

<td class="icon">
  <material-label-print-button [variationId]="position.id" classInner="fa-2x"></material-label-print-button>
</td>
