import {NgModule} from '@angular/core';
import {Commentv2Component} from './commentv2.component';
import {SharedModule} from '../../core/shared.module';
import { CommentMaterialOrderComponent } from './comment-material-order/comment-material-order.component';

@NgModule({
    imports: [
      SharedModule,
    ],
    exports: [Commentv2Component],
    declarations: [
      Commentv2Component,
      CommentMaterialOrderComponent,
    ],
})
export class Commentv2Module {
}
