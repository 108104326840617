import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductionFetchMin, ProductionFetchView} from '../../models/fetch.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderCreateModalComponent} from './business-order-create-modal.component';
import {BusinessOrderService, dateBefore} from '../services/business-order.service';
import {Article, ArticleCore} from '../../../article/models/article.models';
import {ExtendedFrame} from '../../models/frame.models';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {BusinessOrder} from '../models/business-order.models';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {debug} from '../../../../helper/debug.func';
import {BusinessOrderGoldDateErrorService} from './business-order-gold-date-error.service';
import {debounceTime} from 'rxjs/operators';
import {Money} from '../../../../common/money/Money';
import {BusinessOrderProposalService} from '../services/business-order-proposal.service';
import {ShippingInstructionBase} from '../../../article/models/article-extra.models';
import {ShippingInstructionHelper} from '../../../article/models/article-extra-helper';
import {NumberHelper} from '../../../../helper/number-helper';
import {BusinessOrderCreateDirective} from './business-order-create.directive';

@Component({
  selector: 'business-order-form',
  templateUrl: './business-order-form.component.html',
})
export class BusinessOrderFormComponent
  extends BusinessOrderCreateDirective
  implements OnInit, OnChanges, OnDestroy {
  @Input() validHash: boolean;
  @Input() fetches: ProductionFetchView[];
  @Input() fetchesMin: ProductionFetchMin;
  @Input() article: Article | ArticleCore;
  @Input() inventory: number;
  @Input() frame?: ExtendedFrame;
  @Input() workflowId: number;
  @Input() startNumber: number;
  @Input() hasBusinessOrders: boolean;
  @Input() shippingInstructions: ShippingInstructionBase | undefined;
  @Output() updateQuantity: EventEmitter<number> = new EventEmitter<number>();
  @Output() update: EventEmitter<BusinessOrder[]> = new EventEmitter<BusinessOrder[]>();
  @Output() reloadInventory: EventEmitter<any> = new EventEmitter<any>();
  businessOrderForm: UntypedFormGroup;
  errors = false;
  locked = false;
  overDelivery: number | null = null;
  underDelivery: number | null = null;
  private quantitySubscription: Subscription;
  private customDeliveryQuantitySubscription: Subscription;

  amountPerUtilize: number;
  isMl = false;

  @Input() plistEmpty: boolean;

  constructor(private fb: UntypedFormBuilder,
              private ngbModal: NgbModal,
              private service: BusinessOrderService,
              private proposalService: BusinessOrderProposalService,
              private alertService: AlertService,
              private goldDateService: BusinessOrderGoldDateErrorService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    const util = this.article.data.utilize_sum ? Money.parse(this.article.data.utilize_sum.toString()) : 0;
    this.amountPerUtilize = Number.isInteger(Number(util)) ? Number(util) : 0;

    // Check article for multilayer
    const execution = this.article.data.execution;
    this.isMl = execution && execution.indexOf('ML') !== -1;

    this.initSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initFetches(changes.fetchesMin);
    this.initMultilayerPlanCheck();
  }

  ngOnDestroy(): void {
    this.destroySubscription();
  }

  initForm() {
    this.overDelivery = null;
    this.underDelivery = null;
    this.businessOrderForm = this.fb.group({
      'start_date': [null, Validators.required],
      'end_date': [null, Validators.required],
      'gold_date': [null],
      'utilize_quantity': [null, Validators.compose([Validators.required, Validators.min(1)])],
      'amount': [1, Validators.compose([Validators.required, Validators.min(1)])],
      'first_contract': [this.startNumber < Number(this.article.sds_nr) && !this.hasBusinessOrders, Validators.required],
      'express': [false, Validators.required],
      'ordered_quantity': [null, Validators.compose([Validators.min(1), Validators.required])],
      'custom_delivery_quantities': [false, Validators.required],
      'custom_delivery_note': [null],
      'over_delivery': [null],
      'under_delivery': [null],
    }, {validator: dateBefore});
  }

  destroySubscription() {
    this.quantitySubscription.unsubscribe();
    this.customDeliveryQuantitySubscription.unsubscribe();
  }

  initSubscription() {
    const orderedQuantityControl = (this.businessOrderForm.get('ordered_quantity') as UntypedFormControl);
    this.quantitySubscription = orderedQuantityControl.valueChanges.pipe(debounceTime(550)).subscribe(value => {
      this.setCustomDeliveryQuantities(value);
      this.updateQuantity.emit(value > 0 ? value : null);
    });

    this.customDeliveryQuantitySubscription =
      this.businessOrderForm.get('custom_delivery_quantities').valueChanges.subscribe(value => {
        this.toggleCustomDeliveryQuantities(value);
      });
  }

  initFetches(fetches: { currentValue: ProductionFetchMin } | undefined | null): void {
    if (fetches && fetches.currentValue) {
      const totals = fetches.currentValue;
      if (totals.at > 0 && totals.start_date !== null && totals.end_date !== null) {
        this.businessOrderForm.patchValue({
          'start_date': totals.start_date,
          'end_date': totals.end_date
        });
      }

      if (totals.at >= 0 && totals.gold_date !== null && this.article.data['surface_area'] === 'Chem NI / AU') {
        this.businessOrderForm.patchValue({
          'gold_date': totals.gold_date
        });

        // open a modal upfront that the gold_date
        // could not be handled, since we are out of time
        if (totals.warning) {
          this.goldDateService.open();
        }
      }

      if (totals.quantity > 0) {
        this.businessOrderForm.patchValue({'ordered_quantity': totals.quantity});
      }
    }
  }

  toggleCustomDeliveryQuantities(setCustomDeliveryQuantity: boolean): void {
    this.businessOrderForm.patchValue(
      {
        custom_delivery_quantities: setCustomDeliveryQuantity,
        custom_delivery_note: null,
        over_delivery: setCustomDeliveryQuantity ? this.overDelivery : null,
        under_delivery: setCustomDeliveryQuantity ? this.underDelivery : null,
      },
      {emitEvent: false}
    );
  }

  setCustomDeliveryQuantities(quantity: number): void {
    const orderedQuantity = NumberHelper.isDefined(quantity) ? this.businessOrderForm.value.ordered_quantity : quantity;
    const overUnderDelivery = this.shippingInstructions?.over_under_delivery;

    const underDelivery = ShippingInstructionHelper.getHighestOverUnderDeliveryMatch(
      overUnderDelivery?.under_delivery,
      orderedQuantity
    );
    const underDeliveryQuantity = (
      NumberHelper.isDefined(underDelivery?.percent) ?
        (underDelivery.percent * orderedQuantity / 100) :
        underDelivery?.quantity
    ) ?? 0;

    const overDelivery = ShippingInstructionHelper.getHighestOverUnderDeliveryMatch(
      overUnderDelivery?.over_delivery,
      orderedQuantity
    );
    const overDeliveryQuantity = (
      NumberHelper.isDefined(overDelivery?.percent) ?
        (overDelivery.percent * orderedQuantity / 100) :
        overDelivery?.quantity
    ) ?? 0;

    this.overDelivery = orderedQuantity + Math.ceil(overDeliveryQuantity);
    this.underDelivery = orderedQuantity - Math.ceil(underDeliveryQuantity);
    if (this.businessOrderForm.value.custom_delivery_quantities) {
      this.businessOrderForm.patchValue(
        {custom_delivery_quantities: true, over_delivery: this.overDelivery, under_delivery: this.underDelivery},
        {emitEvent: false}
      );
    }
  }

  resetForm() {
    this.destroySubscription();
    this.initForm();
    this.updateQuantity.emit(null);
    this.initSubscription();
    this.initFetches({currentValue: {quantity: 0, warning: false}});
  }

  preSubmit() {
    let submit = true;
    if (this.inventory > 0) {
      if (!confirm('Achtung: Es ist ein Lagerbestand vorhanden. Wurde dieser einkalkuliert?')) {
        submit = false;
      }
    }

    if (submit && this.fetches.length === 0) {
      if (!confirm('Betriebsauftrag ohne Abrufe erstellen?')) {
        submit = false;
      }
    }

    if (submit) {
      this.submit();
    }
  }

  submit() {
    // if we create multiple business orders at once
    // we actually need to split all the nutzen onto them
    if (this.businessOrderForm.value.amount > 1) {
      const modalRef = this.ngbModal.open(BusinessOrderCreateModalComponent);
      modalRef.componentInstance.data = this.businessOrderForm.value;
      modalRef.componentInstance.fetches = this.formFetches();
      modalRef.componentInstance.article = this.article;
      modalRef.componentInstance.frame = this.frame ? this.frame : null;
      modalRef.componentInstance.workflowId = this.workflowId;
      modalRef.result.then(orders => {
        this.reloadInventory.emit();
        this.update.next(orders);
        this.resetForm();
      }, () => {
      });
    } else {
      if (this.locked) {
        return;
      }

      this.locked = true;
      // FIXME: create BusinessOrder Directly
      const data = {
        amount: this.businessOrderForm.value.amount,
        utilize_quantity: this.businessOrderForm.value.utilize_quantity,
        orders: [{
          start_date: this.businessOrderForm.value.start_date,
          end_date: this.businessOrderForm.value.end_date,
          utilize: this.businessOrderForm.value.utilize_quantity,
          gold_date: this.businessOrderForm.value.gold_date,
          first_contract: this.businessOrderForm.value.first_contract,
          express: this.businessOrderForm.value.express
        }],
        ordered_quantity: this.businessOrderForm.value.ordered_quantity,
        fetches: this.formFetches(),
        article: this.article.oa_nr,
        workflow_id: Number(this.workflowId),
        without_frame: !this.frame,
        custom_delivery_quantities: this.businessOrderForm.value.custom_delivery_quantities,
        custom_delivery_note: this.businessOrderForm.value.custom_delivery_note,
        over_delivery: this.businessOrderForm.value.over_delivery,
        under_delivery: this.businessOrderForm.value.under_delivery,
      };
      this.service.create(data).subscribe(success => {
        this.locked = false;
        this.update.next(success.bas);
        this.resetForm();
        this.reloadInventory.emit();
        this.alertService.add('success', 'Betriebsauftrag erfolgreich erstellt');
      }, (response: HttpErrorResponse) => {
        this.locked = false;
        debug('Response:', response.error);
        if (response.error !== null && response.error !== undefined) {
          if ('invalid.gold_date' in response.error) {
            this.goldDateService.open();
          }
        }
        this.alertService.add('danger', 'Beim Erstellen des Betriebsauftrages ist ein Fehler aufgetreten!');
      });
    }
  }

  private formFetches(): number[] {
    const fetchList: number[] = [];
    this.fetches.forEach(value => {
      fetchList.push(value.fetch.id);
    });
    return fetchList;
  }

}
