<div class="modal-header">
  <h3 class="modal-title">Notiz bearbeiten</h3>
</div>

<form [formGroup]="form" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="form-group">
      <textarea id="price_detail_note" class="form-control" formControlName="notes" rows="3"></textarea>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success pull-right" id="save-button">
      Speichern
    </button>
  </div>
</form>
