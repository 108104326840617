import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MaterialOrderPosition} from '../material-order.model';
import {MaterialOrderUpdateModalService} from '../update/material-order-update-modal.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'tr[material-order-position-list-row]',
  templateUrl: './material-order-position-list-row.component.html',
})
export class MaterialOrderPositionListRowComponent {
  @Input('material-order-position-list-row') position: MaterialOrderPosition;
  @Output('material-order-position-list-row-update') updateEvent = new EventEmitter<void>();

  constructor(private moums: MaterialOrderUpdateModalService) {
  }

  get finderUri(): string {
    return environment.finderUri +
      'Search?categories=' +
      environment.finder_category_material_order_pdf +
      '&q=' +
      this.position.order_id;
  }

  open(): void {
    this.moums.open(this.position.order_id, this.position.id).subscribe(() => {
      this.updateEvent.emit();
    }, () => {
      this.updateEvent.emit();
    });
  }

  openPdf(id: number): void {
    window.open(`${environment.apiv4uri}material/order/pdf/${id}`, '_blank');
  }
}
