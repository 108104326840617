import {CalculationListComponent} from './calculation-list.component';
import {CalculationService} from './calculation.service';
import {CalculationTableComponent} from './calculation-table.component';
import {Transition, Ng2StateDeclaration} from '@uirouter/angular';
import {NumberHelper} from '../../helper/number-helper';

export function calculationListResolveFn(service: CalculationService) {
  return service.list().toPromise();
}

export function calculationViewResolveFn(service: CalculationService, transition: Transition) {
  const processId = NumberHelper.saveParseInteger(transition.params()?.id);
  const layoutId = NumberHelper.saveParseInteger(transition.params()?.table);
  return service.table(processId, layoutId).toPromise();
}

export const CALCULATION_STATES: Ng2StateDeclaration[] = [
  {name: 'a.calculation', abstract: true, data: {requiresAuth: true}},
  {name: 'a.calculation.table', abstract: true, data: {requiresAuth: true}},

  {
    name: 'a.calculation.list',
    url: '/calculation/list?id',
    views: {'main@a': {component: CalculationListComponent}},
    resolve: [
      {
        token: 'processes',
        resolveFn: calculationListResolveFn,
        deps: [CalculationService],
      }
    ],
    params: {id: {type: 'query', dynamic: true}},
    data: {requiresAuth: true}
  },

  {
    name: 'a.calculation.table.view',
    url: '/calculation/{id}/table/{table}',
    views: {'main@a': {component: CalculationTableComponent}},
    resolve: [
      {
        token: 'data',
        resolveFn: calculationViewResolveFn,
        deps: [CalculationService, Transition]
      }
    ],
    data: {requiresAuth: true}
  }

];
