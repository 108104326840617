<div class="modal-header">
  <h3 id="modal-title">Lagerbestand manuell anpassen</h3>
</div>

<form name="form" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="form-group row">
      <div class="col-md-4">Einzelpreis:</div><div class="col-sm-8" *ngIf="storage.price">{{storage.price|money:2}}&nbsp;&euro;</div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">Chargennummer:</div><div class="col-sm-8">{{storage.charge}}</div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">Wareneingang:</div><div class="col-sm-8">{{storage.date_received}}</div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">Lagerplatz:</div><div class="col-sm-8">{{storage.location}}</div>
    </div>

    <div class="form-group row" *ngIf="konsi !== null && konsi !== undefined">
      <div class="col-md-4">Bestandstyp:</div><div class="col-sm-8">{{konsi ? 'Konsi' : 'Eigen'}}</div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">
        <label for="amount" class="control-label">Menge hinzufügen / entfernen:</label>
      </div>
      <div class="col-md-8">
        <input id="amount" type="number" class="form-control" formControlName="amount"
               [ngClass]="{red_alert: errors['obj.amount']}">
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">
        <label for="note" class="control-label">Notiz</label>
      </div>
      <div class="col-md-8">
        <input id="note" type="text" class="form-control" formControlName="note"
               [ngClass]="{red_alert: errors['obj.note']}">
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">&nbsp;</div>
    </div>

    <material-inventory-transaction-error [errors]="errors"></material-inventory-transaction-error>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Abbrechen
    </button>

    <button type="submit" class="btn btn-success pull-right" [disabled]="lockSubmission || !form.valid">
      Bestand anpassen
    </button>
  </div>
</form>
