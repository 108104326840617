import {Component, ErrorHandler, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowPositionView} from '../models/position.models';
import {Workflow} from '../models/workflow.models';
import {WorkflowService} from '../workflow-services/workflow.service';
import {PriceHistory} from '../../price/price.models';
import {CreateTaskCallerService} from './create-task-caller.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';

@Component({
  selector: 'create-task-history-modal',
  templateUrl: './create-task-history-modal.component.html',
})
export class CreateTaskHistoryModalComponent implements OnInit {
  @Input() position: WorkflowPositionView;
  @Input() typ: 'de' | 'cn';
  @Input() history: PriceHistory;
  @Input() workflow: Workflow;
  price: string | undefined | null;

  constructor(private activeModal: NgbActiveModal,
              private errorHandler: ErrorHandler,
              private createTaskCallerService: CreateTaskCallerService) {
  }

  ngOnInit(): void {
    if (this.typ === 'de') {
      this.price = this.position.de.price;
    } else if (this.typ === 'cn') {
      this.price = this.position.cn.price;
    }
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.createTaskCallerService.next(this.workflow, this.position, this.typ, true).subscribe(() => {
      this.activeModal.close();
    }, error => {
      this.errorHandler.handleError('CreateTaskHistoryModal#Error: ' + JSON.stringify(error));
    });
  }

}
