/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-product-specific-inspection-plan',
  templateUrl: './article-extra-product-specific-inspection-plan.component.html',
})
export class ArticleExtraProductSpecificInspectionPlanComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'product_specific_inspection_plan';
  protected schemaName = 'product-specific-inspection-plan';
  protected fields = [
    'product_specific_inspection_plan',
    'product_specific_inspection_plan_other',
  ];
}
