import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {Lister} from '../../../../common/wrapper.models';
import {ListService} from '../../../../common/search/search.service';
import {HttpClient} from '@angular/common/http';
import {ArticleMultilayerPlan} from '../multilayer-plan/article-multilayer-plan.models';

@Injectable({
  providedIn: 'root'
})
export class ArticleMultilayerPlanHistoryService implements ListService<ArticleMultilayerPlan> {
  constructor(protected http: HttpClient) {
  }

  public list(query: any): Observable<Lister<ArticleMultilayerPlan>> {
    return this.http.post<Lister<ArticleMultilayerPlan>>(
      `${environment.apiv4uri}article/${query.oaNr}/multilayerplan/history`,
      query
    );
  }
}
