import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {ContractService} from '../workflow-services/contract.service';
import {ContractListData} from '../models/contract.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Lister} from '../../../common/wrapper.models';
import {noop} from '../../../helper/noop';

@Component({
  selector: 'contract-list',
  templateUrl: './contract-list.component.html'
})
export class ContractListComponent extends SearchBaseComponent<ContractService, ContractListData> {
  @Input() listData: Lister<ContractListData>;
  protected type = 'contract';
  singleRow = true;

  constructor(protected service: ContractService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  trackByFn(index: number, item: ContractListData): number {
    noop(this);
    return item.id;
  }

  get currentIconClass(): {} {
    return {
      'fa': true,
      'fa-plus': this.singleRow,
      'green': this.singleRow,
      'fa-minus': !this.singleRow,
      'red-font': !this.singleRow,
    };
  }

  toggleSingleRow(singleRow: boolean): void {
    this.singleRow = singleRow;
  }
}
