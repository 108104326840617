import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Customer, CustomerProducers} from '../model/customer';
import {FieldServiceService} from '../services/field-service.service';
import {UserService} from '../../user/user.service';
import {Subscription} from 'rxjs';
import {CustomerService} from '../services/customer.service';
import {ErpProducer} from '../../producer/producer.models';
import {MultiSelectConfig} from '../../../common/multi-select-config';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from '../../../common/multiselect/types';

@Component({
  selector: 'customer-detail-producer',
  templateUrl: './customer-detail-producer.component.html'
})
export class CustomerDetailProducerComponent implements OnInit {
  @Input() customer: Customer;
  @Input() producers: ErpProducer[];
  myForm: UntypedFormGroup;
  formSubscription?: Subscription;
  error = false;
  errors: { [key: string]: any; } = {};
  model: number[];
  options: IMultiSelectOption[] = [];

  settings: IMultiSelectSettings = MultiSelectConfig.settings;
  texts: IMultiSelectTexts = MultiSelectConfig.texts;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private userService: UserService,
              private fieldServiceService: FieldServiceService,
              private customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      optionsModel: [this.customer.producers],
    });
    this.myForm.controls['optionsModel'].valueChanges
      .subscribe((selectedOptions) => {
        this.updateCustomer(selectedOptions);
      });
    this.producers.map(p => this.options.push({id: p.name, name: p.name}));
  }

  private updateCustomer(values: string[]): void {
    const form = {values: values} as CustomerProducers;
    this.customerService.updateProducers(this.customer.id, form).subscribe(() => {
      this.alertService.add('success', 'Kundendaten erfolgreich gespeichert!');
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      this.errorReport();
    });
  }

  private errorReport(): void {
    this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Eingabe!');
  }

}
