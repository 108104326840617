<ng-container [formGroup]="form">
  <div formArrayName="data">
    <div class="row" *ngFor="let data of datas.controls; let i=index">
      <div class="form-group" [formGroupName]="i.toString()">
        <div class="col-sm-1">
          <h4>{{ i + 1 }}</h4>
        </div>

        <div class="col-sm-2">
          <!--  ng-class="{red_alert: $ctrl.fieldError('quanity')}" -->
          <input type="text" class="form-control" id="input-quanity" formControlName="quanity">
        </div>

        <div class="col-sm-2">
          <div class="input-group">
            <!--  ng-class="{red_alert: $ctrl.fieldError('price')}" -->
            <input type="text" class="form-control" id="input-price"
                   formControlName="price"
                   [ngClass]="{red_alert: errors['data['+i+'].price']}">
            <span class="input-group-addon">$</span>
          </div>
        </div>
        <div class="col-sm-2">
          <!-- ng-class="{red_alert: $ctrl.fieldError('delivery_date')||$ctrl.fieldError()}" -->
          <ev-datepicker class="form-control" formControlName="delivery_date" [allDates]="true"></ev-datepicker>
        </div>

        <div class="col-sm-2">
          <input type="checkbox" formControlName="call_of">
        </div>

        <div class="col-sm-1">
          <button class="btn btn-link green" *ngIf="(datas.length - 1) === i" (click)="addPosition()">
            <i class="fa fa-plus-circle fa-2x"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
