<ng-container *ngFor="let fieldValue of fieldValues">
  <div style="margin: 3px 30px 0 0;display: inline-block;">
    {{ fieldValue.name }}:
    <strong [ngSwitch]="fieldValue.data_type">
      <ng-container *ngSwitchCase="'Option'">
        {{ fieldValue.value === true ? 'Ja' : 'Nein' }}
      </ng-container>
      <ng-container *ngSwitchCase="'Ganzzahl'">
        {{ fieldValue.value | money }}
      </ng-container>
      <ng-container *ngSwitchCase="'Kommazahl'">
        {{ fieldValue.value | money }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ fieldValue.value }}
      </ng-container>
    </strong>
  </div>
</ng-container>
