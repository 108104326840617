<div class="modal-header">
  <h3>Produktionsschritt editieren - {{ name }}</h3>
</div>

<form [formGroup]="form" (ngSubmit)="save()">
  <div class="modal-body">

    <div class="form-group">
      <label for="inputTitle" class="col-sm-2 control-label">Titel</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="inputTitle" formControlName="title">
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-danger pull-left" (click)="abort()" type="button">
      Abbrechen
    </button>

    <button class="btn btn-success pull-right" type="submit">
      Speichern
    </button>
  </div>
</form>
