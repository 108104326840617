import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ShortFrame} from '../models/frame.models';
import {ShortFetch} from '../models/fetch.models';

@Injectable({providedIn: 'root'})
export class FrameShortService {

  constructor(private http: HttpClient) {
  }

  detail(id: number): Observable<{frame: ShortFrame, fetches: ShortFetch[]}> {
    return this.http.get<{frame: ShortFrame, fetches: ShortFetch[]}>('/api/v2/workflow/frame/short/' + id + '/');
  }

}
