import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductionField, ProductionStepEntity} from '../../../envisia/article/production/production.models';
import {
  ArticleSpecification,
  ArticleSpecificationName
} from '../../../envisia/article/models/article-specification.model';
import {ProductionStepService} from '../../../envisia/article/production-services/production-step.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import Helper from '../../../helper/helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductionStepArticleTitleComponent} from './production-step-article-title.component';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {ShippingInstructionSchema} from '../../../envisia/article/models/article-extra.models';

/** can either held an article spec or an article delivery, but not none */
export interface ArticleStep {
  article?: ArticleSpecification;
  validation?: ShippingInstructionSchema;
  title?: string;
}

@Component({
  selector: 'production-step-article',
  templateUrl: './production-step-article.component.html',
})
export class ProductionStepArticleComponent implements OnInit {
  @Input() object: ProductionStepEntity;
  @Input() articleSpecification: ArticleSpecification[] = [];
  @Input() articleExtraValidation: ShippingInstructionSchema[] = [];
  lines: { inner: { step?: ArticleStep, zone: string }[], empty: boolean }[] = [];
  zones = ['zone1', 'zone2', 'zone3'];

  constructor(private productionStepService: ProductionStepService,
              private modalService: NgbModal,
              private modalRef: NgbActiveModal,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.initField();
    this.sortSpecification();
    this.sortValidation();
    this.createEmptyLine();
  }

  add($event: any, lineIndex: number, stepIndex: number): void {
    // adding a element is already checked by canDrop
    if ($event) {
      const current = this.lines[lineIndex].inner[stepIndex].step;
      if ($event.dragData.typ === 'article') {
        this.setData(lineIndex, stepIndex, $event.dragData.data, null, current);
      } else if ($event.dragData.typ === 'delivery') {
        this.setData(lineIndex, stepIndex, null, $event.dragData.data, current);
      }
      if (!current && stepIndex === 0) {
        this.createEmptyLine();
      }
    }
  }

  canDrop(lineIndex: number, stepIndex: number): boolean {
    // steps can be dropped when either the stepIndex is zero
    // or if the previous step does exist
    const line = this.lines[lineIndex];
    let addeable = !line.empty;
    if (stepIndex !== 0 && !line.empty) {
      const previousStep = line.inner[stepIndex - 1];
      addeable = !!previousStep.step;
    }
    return addeable;
  }

  remove(lineIndex: number, stepIndex?: number): void {
    // this method can only be called if it satisfies canRemove
    const line = this.lines[lineIndex];
    const lineLength = line.inner.length;
    if (stepIndex !== undefined && stepIndex !== null) {
      // if (lineLength - 1 === stepIndex) {
      //   this.appendValue(line.inner[stepIndex].step);
      //   line.inner[stepIndex].step = null;
      // } else {
      this.appendValue(line.inner[stepIndex].step);
      let isNull = true;
      for (let i = stepIndex; i < lineLength; i++) {
        if (i + 1 < lineLength) {
          line.inner[i].step = line.inner[i + 1].step;
          line.inner[i + 1].step = null;
        } else {
          line.inner[i].step = null;
        }
        if (!!line.inner[i].step) {
          isNull = false;
        }
      }

      if (isNull && stepIndex === 0) {
        this.lines.splice(lineIndex, 1);
      }
      // }
    } else if (line.empty) {
      // empty lines can be deleted directly
      this.lines.splice(lineIndex, 1);
    }
  }

  edit(step?: ArticleStep): void {
    if (!!step) {
      const modalRef = this.modalService.open(ProductionStepArticleTitleComponent);
      modalRef.componentInstance.step = step;
      from(modalRef.result).subscribe(value => {
        step.title = value;
      }, ignoreRejection);
    }
  }

  canEdit(lineIndex: number, stepIndex?: number): boolean {
    // /every step can be edited if it is not empty
    let editable = true;
    if (stepIndex !== null && stepIndex !== undefined) {
      if (!this.lines[lineIndex].empty) {
        editable = !!this.lines[lineIndex].inner[stepIndex].step;
      }
    }
    return editable;
  }

  canRemove(lineIndex: number, stepIndex?: number): boolean {
    // every step can be removed if it is not empty or
    // we do not hit the last line
    let removeable = true;
    if (stepIndex !== null && stepIndex !== undefined) {
      removeable = this.lines[lineIndex].empty;
      if (!this.lines[lineIndex].empty) {
        removeable = !!this.lines[lineIndex].inner[stepIndex].step;
      }
    }
    return removeable;
  }

  save(): void {
    this.productionStepService.editArticle(this.object.id, this.clearData()).subscribe(value => {
      this.alertService.add('success', 'Artikelschritte erfolgreich aktualisiert!');
      this.modalRef.close(value);
    }, () => {
      this.alertService.add(
        'danger',
        'Es ist ein Fehler aufgetreten, bitte benachrichtigen Sie Ihren Administrator!'
      );
    });
  }

  up(i: number): void {
    const current = this.lines[i];
    this.lines[i] = this.lines[i - 1];
    this.lines[i - 1] = current;
  }

  down(i: number): void {
    const current = this.lines[i];
    this.lines[i] = this.lines[i + 1];
    this.lines[i + 1] = current;
  }

  newLine(lineIndex: number): void {
    this.lines.splice(lineIndex + 1, 0, {inner: [], empty: true});
  }

  private removeDropSpecification(data: ArticleSpecificationName): ArticleSpecification | null {
    const index = this.articleSpecification.findIndex(value => value.name === data.name);
    if (index !== -1) {
      const current = this.articleSpecification[index];
      this.articleSpecification.splice(index, 1);
      return current;
    }
    return null;
  }

  private removeValidationSpecification(data: { name: string, group: string }): ShippingInstructionSchema | null {
    const index = this.articleExtraValidation.findIndex(value => value.name === data.name &&
      value.group === data.group);
    if (index !== -1) {
      const current = this.articleExtraValidation[index];
      this.articleExtraValidation.splice(index, 1);
      return current;
    }
    return null;
  }

  private appendValue(data: ArticleStep): void {
    if (data.article) {
      this.articleSpecification.push(data.article);
      this.sortSpecification();
    } else if (data.validation) {
      this.articleExtraValidation.push(data.validation);
      this.sortValidation();
    }
  }

  private sortSpecification(): void {
    this.articleSpecification.sort((a, b) => {
      const nameA = (a.production_description + a.production_title).toUpperCase();
      const nameB = (b.production_description + b.production_title).toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  private sortValidation(): void {
    const generateSortableName = (validation: ShippingInstructionSchema) => {
      let name = '';
      if (validation.group_label !== null && validation.group_label !== '') {
        name += validation.group_label;
      }
      name += validation.label;
      return name.toUpperCase();
    };
    this.articleExtraValidation.sort((a, b) => {
      const nameA = generateSortableName(a);
      const nameB = generateSortableName(b);
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  private initField(): void {
    console.log(this.object.article_field);
    Helper.keys(this.object.article_field, (outerValue: { right?: ProductionField[], left?: string }) => {
      if (outerValue.left) {
        this.lines.push({inner: [], empty: true});
      } else if (outerValue.right) {
        const value = outerValue.right;
        if (value.length === 0) {
          return;
        }

        const zones = [];
        for (let i = 0; i < this.zones.length; i++) {
          const v = value[i];

          // FIXME: add handling for article delivery
          let step = null;
          if (!!v) {
            if (v.typ === 'article') {
              step = {
                article: this.removeDropSpecification({name: v.name}),
                title: v.title
              };
            } else if (v.typ === 'article_delivery') {
              const splitted = v.name.split(':');
              step = {
                validation: this.removeValidationSpecification({name: splitted[1], group: splitted[0]}),
                title: v.title
              };
            }
          }

          zones.push({step: step, zone: this.zones[i]});
        }

        if (zones.length > 0) {
          this.lines.push({inner: zones, empty: false});
        }
      }
    });

    console.log('Full Lines:', this.lines);
  }

  private clearData(): { [key: string]: { data?: { name: string, typ: string }[]; empty: boolean } } {
    const cleared: { [key: string]: { data?: { name: string, typ: string, title?: string }[]; empty: boolean } } = {};
    this.lines.forEach((fields, index) => {
      if (fields.empty) {
        cleared[(index + 1).toString()] = {empty: true};
      } else {
        const sendData = fields.inner.filter(data => !!data.step).map(data => {
          if (data.step.article) {
            return {name: data.step.article.name, typ: 'article', title: data.step.title};
          } else if (data.step.validation) {
            return {
              name: data.step.validation.group + ':' + data.step.validation.name,
              typ: 'article_delivery',
              title: data.step.title
            };
          } else {
            return null;
          }
        });
        // the last line will have a length of zero,
        // so we need to filter it
        if (sendData.length > 0) {
          // index should be started at 1 instead of zero
          cleared[(index + 1).toString()] = {data: sendData, empty: false};
        }
      }
    });

    console.log('cleared:', cleared);
    return cleared;
  }

  private createEmptyLine(): void {
    const line = [];
    for (let i = 0; i < this.zones.length; i++) {
      line.push({step: null, zone: this.zones[i]});
    }
    this.lines.push({inner: line, empty: false});
  }

  private setData(lineIndex: number,
                  stepIndex: number,
                  article: ArticleSpecification | null,
                  validation: ShippingInstructionSchema | null,
                  current?: ArticleStep): void {
    this.lines[lineIndex].inner[stepIndex].step = {article: article, validation: validation};

    if (!!article) {
      this.removeDropSpecification(article);
    } else if (!!validation) {
      // FIXME: remove on drop
      this.removeValidationSpecification({name: validation.name, group: validation.group});
    }

    if (!!current) {
      if (!!current.article) {
        this.articleSpecification.push(current.article);
        this.sortSpecification();
      } else if (!!current.validation) {
        this.articleExtraValidation.push(current.validation);
        this.sortValidation();
      }
    }
  }

}
