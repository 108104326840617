import {Component} from '@angular/core';

@Component({
  selector: 'base-dashboard',
  templateUrl: './base-dashboard.component.html',
  styles: [
    '.base-dashboard-spacer {margin: 50px 15px 20px 15px; }'
  ]
})
export class BaseDashboardComponent {
}
