<div>
  <h3 *ngIf="withTitle">Artikel</h3>
  <table class="table whitetable marginless-bottom">
    <tbody>
    <tr>
      <td class="fixed-width-150">
        <strong>SDS-Nr.:</strong>
      </td>
      <td>
        <a title="Artikel öffnen"
           [href]="'#!/article/overview/' + article.oa_nr + workflowUrl" [target]="targetWindow"
           *ngIf="article.sds_nr">
          <strong>{{ article.sds_nr }}</strong>
          <i class="fa fa-warning" style="color:red;"
             *ngIf="article.sds_nr.indexOf('-c') > 0 || article.sds_nr.indexOf('-C') > 0"
             [ngbTooltip]="'China-Artikel'"></i>
          <span *ngIf="article.locked" class="label label-warning">Gesperrt</span>
        </a>
        <div class="label label-danger" *ngIf="!!article.data.special_production">Spezial</div>
        <ng-content select=".sds-projection"></ng-content>
      </td>
    </tr>
    <tr>
      <td class="fixed-width-150">0A-Nr.:</td>
      <td>
        <a title="Artikel öffnen" [href]="'#!/article/overview/' + article.oa_nr + workflowUrl" [target]="targetWindow">
          {{ article.oa_nr }}
        </a>
      </td>
    </tr>
    <tr>
      <td class="fixed-width-150">Kunden-Nr.:</td>
      <td>
        <a title="Artikel öffnen" [href]="'#!/article/overview/' + article.oa_nr + workflowUrl" [target]="targetWindow">
          {{ article.data.kd_art_nr }}
        </a>
      </td>
    </tr>
    <tr>
      <td class="fixed-width-150">Kunden-Index:</td>
      <td>
        <a title="Artikel öffnen" [href]="'#!/article/overview/' + article.oa_nr + workflowUrl" [target]="targetWindow">
          {{ article.data.kd_art_index }}
        </a>
      </td>
    </tr>
    <tr>
      <td class="fixed-width-150">Kundenbezeichnung 1:</td>
      <td>
        <a title="Artikel öffnen" [href]="'#!/article/overview/' + article.oa_nr + workflowUrl" [target]="targetWindow">
          {{ article.data.kd_art_extend1 }}
        </a>
      </td>
    </tr>
    <tr *ngIf="inventory">
      <td class="fixed-width-150">Lagerbestand:</td>
      <td>
        <article-inventory-quantity-box [oaNr]="article.oa_nr"
                                        [workflowId]="workflowId"
                                        [inventory]="inventory"
                                        [showInventoryButton]="right"
                                        (reloadInventory)="reloadInventory.emit($event)">
        </article-inventory-quantity-box>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<ng-content></ng-content>
<div class="clearfix"></div>
