<div class="whitelist-item-detail">
  <ng-container [ngSwitch]="add?.sub_type">
    <div *ngSwitchCase="1">
      <table class="table">
        <tr>
          <th>Lagerplatz</th>
          <th>Charge</th>
          <th>Wareneingangsdatum</th>
          <th class="align-right">Preis</th>
          <th class="align-right">Menge</th>
        </tr>
        <tr *ngFor="let transaction of add.transactions;">
          <td>{{transaction.location}}</td>
          <td>{{transaction.charge}}</td>
          <td>{{transaction.date_received|date:'dd.MM.yyyy'}}</td>
          <td class="align-right">{{transaction.price|money:2}}&nbsp;&euro;</td>
          <td class="align-right">{{transaction.amount|money}}</td>
        </tr>
      </table>
    </div>

    <div *ngSwitchCase="3">
      <material-order-position-status-label [status]="add.position_state_old">
      </material-order-position-status-label>
      <i class="fa fa-arrow-right"></i>
      <material-order-position-status-label [status]="add.position_state_new">
      </material-order-position-status-label>
    </div>

    <div *ngSwitchCase="4">
      <table class="table" *ngIf="('material.order.price.check'|HasPerm)">
        <tr>
          <th class="align-right">Einbuchung</th>
          <th class="align-right">Alter Preis</th>
          <th class="align-right">Neuer Preis</th>
        </tr>
        <tr *ngFor="let transaction of add.transaction_price_changes;">
          <td class="align-right"><b>{{transaction.transaction_index}}.</b></td>
          <td class="align-right">{{transaction.price_old|money:2}}&nbsp;&euro;</td>
          <td class="align-right">{{transaction.price_new|money:2}}&nbsp;&euro;</td>
        </tr>
      </table>
    </div>
  </ng-container>
</div>
