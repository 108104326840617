import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  BigWorkflow, NextForm,
  TaskListData,
  Workflow, WorkflowDiffForm, WorkflowSimpleArticleForm,
  WorkflowUpdateForm,
  WorkflowUser,
  WorkflowWizardForm
} from '../models/workflow.models';
import {EnvisiaObject, Lister} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {ListService} from '../../../common/search/search.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {ExtendedFrame} from '../models/frame.models';

@Injectable({providedIn: 'root'})
export class WorkflowService implements ListService<TaskListData> {

  constructor(private http: HttpClient) {
  }

  list(query?: { [paramName: string]: any; }): Observable<Lister<TaskListData>> {
    return this.http.get<Lister<TaskListData>>(
      '/workflow/v1/workflow/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  detail(workflowId: number, reorder: boolean = false): Observable<BigWorkflow> {
    let params = new HttpParams();
    if (reorder) {
      params = EnvisiaLocation.httpParamsWrapper({reorder: 'true'});
    }
    return this.http.get<BigWorkflow>('/workflow/v1/workflow/' + workflowId + '/', {params: params});
  }

  create(form: WorkflowWizardForm): Observable<Workflow> {
    return this.http.post<Workflow>('/workflow/v1/workflow/', form);
  }

  update(workflowId: number, form: WorkflowUpdateForm): Observable<Workflow> {
    return this.http.put<EnvisiaObject<Workflow>>('/workflow/v1/workflow/' + workflowId + '/', form).pipe(
      map(val => val.object)
    );
  }

  copy(workflowId: number, form: WorkflowSimpleArticleForm): Observable<number> {
    return this.http.put<EnvisiaObject<number>>('/workflow/v1/copy/' + workflowId + '/', form).pipe(
      map(val => val.object)
    );
  }

  userInfo(workflowId: number): Observable<WorkflowUser> {
    return this.http.get<EnvisiaObject<WorkflowUser>>('/workflow/v1/user/' + workflowId + '/').pipe(
      map(value => value.object)
    );
  }

  updateStatus(workflowId: number, data: string, reason: string): Observable<void> {
    return this.http.put<void>('/workflow/v1/' + data + '/' + workflowId + '/', {reason: reason});
  }

  diff(workflowId: number, form: WorkflowDiffForm): Observable<any> {
    return this.http.post<{ objects: any }>(
      '/workflow/v1/diff/' + workflowId + '/',
      form
    ).pipe(map(val => val.objects));
  }

  next(workflowId: number, form: NextForm, history?: boolean): Observable<ExtendedFrame> {
    return this.http.post<EnvisiaObject<ExtendedFrame>>(
      '/workflow/v1/next/' + workflowId + '/',
      form,
      {params: EnvisiaLocation.httpParams({history: history})}
    ).pipe(map(val => val.object));
  }

}
