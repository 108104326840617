import {Injectable} from '@angular/core';
import {CacheableHttpService} from '../../../common/cacheable-http/cacheable-http.service';
import {Observable} from 'rxjs';
import {EnvisiaObjects} from '../../../common/wrapper.models';
import {Country} from '../model/country';
import {map} from 'rxjs/operators';

const COUNTRY_CACHE = 'countries';

@Injectable({providedIn: 'root'})
export class CountryService {

  constructor(private cacheableHttpService: CacheableHttpService) {
  }

  public list(): Observable<Country[]> {
    return this.cacheableHttpService.get<EnvisiaObjects<Country>>(
      COUNTRY_CACHE,
      '/crm/v1/country/'
    ).pipe(map(obj => obj.objects));
  }

  public map(): Observable<{ [key: string]: Country }> {
    return this.list().pipe(map(values => {
      const data = {};
      values.forEach(value => {
        data[value.iso3] = value;
      });
      return data;
    }));
  }

}
