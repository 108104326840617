import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {WorkflowPositionView} from '../../../models/position.models';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {ProducerModalService} from '../../../../producer/producer-modal.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {PositionService} from '../../../workflow-services/position.service';
import {tap} from 'rxjs/operators';
import {OfferDetailPositionChangeModalService} from '../position-change-modal/offer-detail-position-change-modal.service';
import {OfferDetailNewPositionAddModalService} from '../position-add-modal/offer-detail-new-position-add-modal.service';
import {ignoreRejection} from '../../../../../helper/ignore_rejection';
import {OfferDetailPositionExtraModalService} from '../position-extra-modal/offer-detail-position-extra-modal.service';
import {Subject, Subscription} from 'rxjs';
import {
  PositionChange,
  PositionCloneUpdateChange,
  PositionRemoveChange,
  PositionSingleUpdateChange
} from '../position-box.models';

@Component({
  selector: 'position-holder',
  templateUrl: './position-holder.component.html'
})
export class PositionHolderComponent implements OnInit, OnDestroy {
  @Input() data: BigWorkflowOffer;
  @Output() updatePosition = new EventEmitter<{ position: WorkflowPositionView, index: number } | null>();
  subject: Subject<WorkflowPositionView>;
  subscription: Subscription;
  listChange = 0;

  constructor(private producerModalService: ProducerModalService,
              private alertService: AlertService,
              private positionService: PositionService,
              private offerDetailNewPositionChangeModalService: OfferDetailPositionChangeModalService,
              private offerDetailNewPositionAddModalService: OfferDetailNewPositionAddModalService,
              private offerDetailPositionExtraModalService: OfferDetailPositionExtraModalService) {
  }

  ngOnInit() {
    this.subject = new Subject<WorkflowPositionView>();
    this.subscription = this.subject.subscribe(response => {
      this.addPosition(response);
      this.updatePosition.emit(null);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subject) {
      this.subject.unsubscribe();
    }
  }

  addPosition(data: WorkflowPositionView): void {
    // Adds the position on top of the stack
    const cloned = Object.assign([], this.data.workflow.positions);
    cloned.push(data);
  }

  addArticlePosition(): void {
    this.offerDetailNewPositionAddModalService.open(this.data).subscribe(response => {
      this.addPosition(response);
      this.updatePosition.emit(null);
    }, ignoreRejection);
  }

  addExtraCostPosition(): void {
    this.offerDetailPositionExtraModalService.open(this.data, this.subject).subscribe(response => {
      this.updatePosition.emit(null);
    }, ignoreRejection);
  }

  changeAllCalculations(): void {
    this.offerDetailNewPositionChangeModalService.open(this.data).subscribe(response => {
      this.data.workflow.positions = response;
      this.listChange += 1;
      this.updatePosition.emit(null);
    }, ignoreRejection);
  }

  changeAllProducers(): void {
    const method = (instance: NgbActiveModal, data?: string) => {
      return this.positionService.producerAll(this.data.workflow.object.id, data).pipe(
        tap(success => {
          if (success.length > 0) {
            this.alertService.add('success', 'Produzenten erfolgreich geändert!');
            this.data.workflow.positions.forEach((obj) => {
              if (obj.typ === 'article') {
                obj.producer_cn = data;
              }
            });
          }
          instance.close();
        }, () => {
          this.alertService.add('danger', 'Beim Setzen von Produzenten ist ein Fehler aufgetreten');
        })
      );
    };

    this.producerModalService.open(method).subscribe(() => {
    }, ignoreRejection);
  }

  positionChanged(data: { position: WorkflowPositionView, index: number }) {
    this.updatePosition.emit(data);
  }

  doPositionUpdate(data: { position: PositionChange, index: number }): void {
    // we always overwrite arrays to trigger change detection
    if (data.position instanceof PositionCloneUpdateChange) {
      this.addPosition(data.position.position);
      this.positionChanged({position: data.position.position, index: data.index});
    } else if (data.position instanceof PositionRemoveChange) {
      this.positionChanged({position: data.position.position, index: data.index});
    } else if (data.position instanceof PositionSingleUpdateChange) {
      this.positionChanged({position: data.position.position, index: data.index});
    }
  }

}
