import {Component, Input} from '@angular/core';
import {StateService} from '@uirouter/core';
import {MaterialGroupListElement} from '../material-management.model';
import {MaterialGroup} from '../groups/material-group.model';
import {Lister} from '../../../common/wrapper.models';

@Component({
  selector: 'material-inventory-create',
  templateUrl: './material-inventory-list-group-select.component.html'
})
export class MaterialInventoryListGroupSelectComponent {
  @Input() groups: Lister<MaterialGroup>;

  constructor(private stateService: StateService) {
  }

  setCurrentGroup(group: MaterialGroupListElement) {
    this.stateService.go('a.material.inventory.list', {group: group.id});
  }
}
