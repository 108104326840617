import {Component, Input, OnInit} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {CustomerService} from '../services/customer.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomerCreateForm} from '../model/customer.form';
import {FieldService} from '../model/field-service';
import {Country} from '../model/country';
import {CustomerDuplicate} from '../model/customer';

@Component({
  selector: 'customer-create',
  templateUrl: './customer-create.component.html'
})
export class CustomerCreateComponent implements OnInit {
  @Input() fieldServices: FieldService[];
  @Input() countries: Country[];
  createForm: UntypedFormGroup;
  errors: { [key: string]: any; } = {};
  duplicated: CustomerDuplicate[] = [];
  loading = false;

  constructor(private stateService: StateService,
              private customerService: CustomerService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  create() {
    this.errors = {};
    this.duplicated = [];
    this.loading = true;
    const body: CustomerCreateForm = {
      name: this.createForm.value.name,
      phone: this.createForm.value.phone,
      fax: this.createForm.value.fax,
      mail: this.createForm.value.mail,
      website: this.createForm.value.website,
      field_service: this.createForm.value.field_service,
      invoice: {
        name: 'Rechnungsadresse',
        street: this.createForm.value.street,
        country: this.createForm.value.country,
        zip: this.createForm.value.zip,
        city: this.createForm.value.city,
      }
    };
    this.customerService.create(body).subscribe(value => {
      this.loading = false;
      this.stateService.go('a.crm.customer.detail', {id: value.id});
    }, response => {
      this.errors = response.error;
      this.loading = false;
      if (response.error.error === 'duplicated') {
        this.errors.name = true;
        this.duplicated = response.error.objects;
      } else {
        console.log(response.error);
      }
    });
  }

  private buildForm() {
    const defaultFieldService = this.fieldServices.length > 0 ? this.fieldServices[0].name : null;
    this.createForm = this.fb.group({
      'name': [null, Validators.required],
      'street': [null, Validators.required],
      'city': [null, Validators.required],
      'zip': [null, Validators.required],
      'country': ['DEU', Validators.required],
      'field_service': [defaultFieldService, Validators.required],
      'mail': [null],
      'phone': [null],
      'fax': [null],
      'website': [null],
    });
  }

}
