/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {noop} from '../../../helper/noop';
import {ShippingInstructionSchema} from '../../article/models/article-extra.models';

export const ARTICLE_EXTRA_DROPDOWN_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleExtraDropdownComponent),
  multi: true,
};

export const ARTICLE_EXTRA_DROPDOWN_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ArticleExtraDropdownComponent),
  multi: true
};

@Component({
  selector: 'article-extra-dropdown',
  templateUrl: './article-extra-dropdown.component.html',
  providers: [
    ARTICLE_EXTRA_DROPDOWN_ACCESSOR,
    ARTICLE_EXTRA_DROPDOWN_VALIDATOR,
  ]
})
export class ArticleExtraDropdownComponent implements ControlValueAccessor, Validator {
  @Input() defaultValues: ShippingInstructionSchema;
  @Input() disabled = false;
  current: any;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  writeValue(obj: any): void {
    this.current = obj === null ? '-' : obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(c: AbstractControl): ValidationErrors | any {
    return null;
  }

  onChange(value: any): void {
    let cleaned;
    if (value === '-') {
      cleaned = '-';
    } else {
      cleaned = value;
    }
    this._onChange(cleaned);
  }

  trackByFn(index: number, item: string): number {
    noop(this);
    return index;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    noop(this);
    return this.disabled;
  }
}
