import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {WorkflowService} from '../../../workflow-services/workflow.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Customer} from '../../../../customer/model/customer';
import {AbstractDetailFormComponent} from '../../../base/abstract-detail-form.component';
import {debounceTime} from 'rxjs/operators';
import {debug} from '../../../../../helper/debug.func';
import {HttpErrorResponse} from '@angular/common/http';
import {WorkflowPositionView} from '../../../models/position.models';

@Component({
  selector: 'offer-detail-form',
  templateUrl: './offer-detail-form.component.html',
})
export class OfferDetailFormComponent extends AbstractDetailFormComponent implements OnInit, OnDestroy {
  @Output() updatePosition = new EventEmitter<{ position: WorkflowPositionView, index: number } | null>();
  errors: { [key: string]: any; } = {};
  formCustomer: UntypedFormGroup;
  form: UntypedFormGroup;
  kinds: { name: string, id: string }[];
  kind: { name: string, id: string } | null | undefined;
  private formCustomerSubscription?: Subscription;
  private formBaseSubscription?: Subscription;

  constructor(protected workflowService: WorkflowService,
              protected alertService: AlertService,
              private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    const kindsObject = {
      EMAIL: {name: 'E-Mail', id: 'EMAIL'},
      PHONE: {name: 'Telefon', id: 'PHONE'},
      FAX: {name: 'Fax', id: 'FAX'},
    };
    this.kinds = [kindsObject.EMAIL, kindsObject.PHONE, kindsObject.FAX];
    this.kind = this.data.workflow.object.work_kind ? kindsObject[this.data.workflow.object.work_kind] : null;

    this.form = this.fb.group({
      'work_kind': [this.data.workflow.object.work_kind],
      'work_date': [this.data.workflow.object.work_date],
      'work_nr': [this.data.workflow.object.work_nr],
      'exchange_rate': [this.data.workflow.object.exchange_rate],
    });
    this.formBaseSubscription = this.form.valueChanges.pipe(debounceTime(550)).subscribe(values => {
      debug('Workflow ValueChanges: ', values);
      this.workflowService.update(this.data.workflow.object.id, values).subscribe(response => {
        this.alertService.add('success', 'Anfrage Werte erfolgreich gespeichert!');
        this.data.workflow.object.work_kind = values.work_kind;
        this.data.workflow.object.work_date = values.work_date;
        this.data.workflow.object.work_nr = values.work_nr;

        // FIXME: update positions?!
        if (this.data.workflow.object.exchange_rate !== values.exchange_rate) {
          this.updatePosition.emit(null);
        }
        this.data.workflow.object.exchange_rate = values.exchange_rate;
        this.form.patchValue({exchange_rate: response.exchange_rate}, {emitEvent: false});
        this.form.patchValue({work_date: response.work_date}, {emitEvent: false});
        this.form.patchValue({work_kind: response.work_kind}, {emitEvent: false});
        this.form.patchValue({work_nr: response.work_nr}, {emitEvent: false});
      }, (response: HttpErrorResponse) => {
        if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
          this.errors = response.error;
        }
        debug('Response Error:', response);
        this.alertService.add('danger', 'Konnte Anfrage Werte nicht speichern!');
      });
    });

    /** customer forms */
    this.formCustomer = this.fb.group({
      'customer': [this.data.customer],
      'contact': [this.data.contact],
    });

    this.formCustomerSubscription = this.formCustomer.get('customer').valueChanges.subscribe(values => {
      this.workflowService.update(this.data.workflow.object.id, {customer_id: values.id}).subscribe((response) => {
        this.data.customer = values as Customer;
        this.formCustomer.patchValue({'contact': null}, {emitEvent: false});
        this.data.contact = null;

        this.alertService.add('success', 'Kunde erfolgreich geändert!');
      }, () => {
        this.formCustomer.patchValue({'customer': this.data.customer}, {emitEvent: false});
        this.alertService.add('danger', 'Kunde konnte nicht geändert werden!');
      });
    });

    this.envisiaOnInitContactForm(this.formCustomer);
  }


  ngOnDestroy(): void {
    if (this.formCustomerSubscription) {
      this.formCustomerSubscription.unsubscribe();
    }
    this.envisiaOnDesotryContactForm();
    if (this.formBaseSubscription) {
      this.formBaseSubscription.unsubscribe();
    }
  }

}
