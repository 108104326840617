import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {WorkflowCreateArticleSearchComponent} from './workflow-create-article-search.component';

@NgModule({
    imports: [
        SharedModule,
        WorkflowPipesModule,
    ],
    declarations: [
        WorkflowCreateArticleSearchComponent,
    ],
    exports: [
        WorkflowCreateArticleSearchComponent
    ]
})
export class WorkflowCreateArticleSearchModule {
}
