<form class="form-inline small-input input-align-left small-form">
  <div class="row">
    <div class="col-md-5 margin-bottom">
      <div class="row">
        <h3>Stand vor Änderung</h3>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row">
        <h3>Stand nach Änderung</h3>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Erstmusterprüfung') ||
                          (history.edits|Contains:'Erstmusterprüfung')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Erstmusterprüfung')">
        <article-extra-initial-sample-testing historyType="old"
                                              [schema]="schemaDictionary"
                                              [data]="history"
                                              [readOnly]="true">
        </article-extra-initial-sample-testing>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Erstmusterprüfung',
             'label-info': history.edits|Contains:'Erstmusterprüfung'
           }"
           *ngIf="!history.deleted">
        <article-extra-initial-sample-testing historyType="new"
                                              [schema]="schemaDictionary"
                                              [data]="history"
                                              [readOnly]="true">
        </article-extra-initial-sample-testing>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Massprüfung') ||
                          (history.edits|Contains:'Massprüfung')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Massprüfung')">
        <article-extra-dimension-inspection historyType="old"
                                            [schema]="schemaDictionary"
                                            [data]="history"
                                            [readOnly]="true">
        </article-extra-dimension-inspection>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Massprüfung',
             'label-info': history.edits|Contains:'Massprüfung'
           }"
           *ngIf="!history.deleted">
        <article-extra-dimension-inspection historyType="new"
                                            [schema]="schemaDictionary"
                                            [data]="history"
                                            [readOnly]="true">
        </article-extra-dimension-inspection>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Schliffbilduntersuchung') ||
                          (history.edits|Contains:'Schliffbilduntersuchung')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Schliffbilduntersuchung')">
        <article-extra-microsection historyType="old"
                                    [schema]="schemaDictionary"
                                    [data]="history"
                                    [readOnly]="true">
        </article-extra-microsection>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Schliffbilduntersuchung',
             'label-info': history.edits|Contains:'Schliffbilduntersuchung'
           }"
           *ngIf="!history.deleted">
        <article-extra-microsection historyType="new"
                                    [schema]="schemaDictionary"
                                    [data]="history"
                                    [readOnly]="true">
        </article-extra-microsection>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'COC/Prüfprotokoll') ||
                          (history.edits|Contains:'COC/Prüfprotokoll')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'COC/Prüfprotokoll')">
        <article-extra-c-o-c-testing-protocol historyType="old"
                                              [schema]="schemaDictionary"
                                              [data]="history"
                                              [readOnly]="true">
        </article-extra-c-o-c-testing-protocol>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'COC/Prüfprotokoll',
             'label-info': history.edits|Contains:'COC/Prüfprotokoll'
           }"
           *ngIf="!history.deleted">
        <article-extra-c-o-c-testing-protocol historyType="new"
                                              [schema]="schemaDictionary"
                                              [data]="history"
                                              [readOnly]="true">
        </article-extra-c-o-c-testing-protocol>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Produktspezifischer Prüfplan') ||
                          (history.edits|Contains:'Produktspezifischer Prüfplan')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Produktspezifischer Prüfplan')">
        <article-extra-product-specific-inspection-plan historyType="old"
                                                        [schema]="schemaDictionary"
                                                        [data]="history"
                                                        [readOnly]="true">
        </article-extra-product-specific-inspection-plan>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Produktspezifischer Prüfplan',
             'label-info': history.edits|Contains:'Produktspezifischer Prüfplan'
           }"
           *ngIf="!history.deleted">
        <article-extra-product-specific-inspection-plan historyType="new"
                                                        [schema]="schemaDictionary"
                                                        [data]="history"
                                                        [readOnly]="true">
        </article-extra-product-specific-inspection-plan>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Kennzeichnung E-Test') ||
                          (history.edits|Contains:'Kennzeichnung E-Test')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Kennzeichnung E-Test')">
        <article-extra-e-test-labeling historyType="old"
                                       [schema]="schemaDictionary"
                                       [data]="history"
                                       [readOnly]="true">
        </article-extra-e-test-labeling>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Kennzeichnung E-Test',
             'label-info': history.edits|Contains:'Kennzeichnung E-Test'
           }"
           *ngIf="!history.deleted">
        <article-extra-e-test-labeling historyType="new"
                                       [schema]="schemaDictionary"
                                       [data]="history"
                                       [readOnly]="true">
        </article-extra-e-test-labeling>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Lötmuster') ||
                          (history.edits|Contains:'Lötmuster')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Lötmuster')">
        <article-extra-solder-pattern historyType="old"
                                      [schema]="schemaDictionary"
                                      [data]="history"
                                      [readOnly]="true">
        </article-extra-solder-pattern>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Lötmuster',
             'label-info': history.edits|Contains:'Lötmuster'
           }"
           *ngIf="!history.deleted">
        <article-extra-solder-pattern historyType="new"
                                      [schema]="schemaDictionary"
                                      [data]="history"
                                      [readOnly]="true">
        </article-extra-solder-pattern>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Impedanz') ||
                          (history.edits|Contains:'Impedanz')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Impedanz')">
        <article-extra-impedance historyType="old"
                                 [schema]="schemaDictionary"
                                 [data]="history"
                                 [readOnly]="true"
                                 [historyType]="'old'">
        </article-extra-impedance>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Impedanz',
             'label-info': history.edits|Contains:'Impedanz'
           }"
           *ngIf="!history.deleted">
        <article-extra-impedance historyType="new"
                                 [schema]="schemaDictionary"
                                 [data]="history"
                                 [readOnly]="true">
        </article-extra-impedance>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Liefertermin') ||
                          (history.edits|Contains:'Liefertermin')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Liefertermin')">
        <article-extra-delivery-date historyType="old"
                                     [schema]="schemaDictionary"
                                     [data]="history"
                                     [readOnly]="true">
        </article-extra-delivery-date>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Liefertermin',
             'label-info': history.edits|Contains:'Liefertermin'
           }"
           *ngIf="!history.deleted">
        <article-extra-delivery-date historyType="new"
                                     [schema]="schemaDictionary"
                                     [data]="history"
                                     [readOnly]="true">
        </article-extra-delivery-date>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'BA Endtermin Steuerung') ||
                          (history.edits|Contains:'BA Endtermin Steuerung')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'BA Endtermin Steuerung')">
        <article-extra-termination-date historyType="old"
                                        [schema]="schemaDictionary"
                                        [data]="history"
                                        [readOnly]="true">
        </article-extra-termination-date>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'BA Endtermin Steuerung',
             'label-info': history.edits|Contains:'BA Endtermin Steuerung'
           }"
           *ngIf="!history.deleted">
        <article-extra-termination-date historyType="new"
                                        [schema]="schemaDictionary"
                                        [data]="history"
                                        [readOnly]="true">
        </article-extra-termination-date>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Verpackung') ||
                          (history.edits|Contains:'Verpackung')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Verpackung')">
        <article-extra-packaging historyType="old"
                                 [schema]="schemaDictionary"
                                 [data]="history"
                                 [article]="article"
                                 [readOnly]="true">
        </article-extra-packaging>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Verpackung',
             'label-info': history.edits|Contains:'Verpackung'
           }"
           *ngIf="!history.deleted">
        <article-extra-packaging historyType="new"
                                 [schema]="schemaDictionary"
                                 [data]="history"
                                 [article]="article"
                                 [readOnly]="true">
        </article-extra-packaging>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Schlechtteile') ||
                          (history.edits|Contains:'Schlechtteile')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{ 'label-info': !history.deleted, 'label-danger': history.deleted }"
           *ngIf="!(history.creates|Contains:'Schlechtteile')">
        <article-extra-bad-part historyType="old"
                                [schema]="schemaDictionary"
                                [data]="history"
                                [readOnly]="true">
        </article-extra-bad-part>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Schlechtteile',
             'label-info': history.edits|Contains:'Schlechtteile'
           }"
           *ngIf="!history.deleted">
        <article-extra-bad-part historyType="new"
                                [schema]="schemaDictionary"
                                [data]="history"
                                [readOnly]="true">
        </article-extra-bad-part>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="(history.creates|Contains:'Über / Unterlieferung') ||
                          (history.edits|Contains:'Über / Unterlieferung')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{'label-info': !history.deleted, 'label-danger': history.deleted}"
           *ngIf="!(history.creates|Contains:'Über / Unterlieferung')">
        <article-extra-over-under-delivery historyType="old"
                                           [schema]="schemaDictionary"
                                           [data]="history"
                                           [readOnly]="true">
        </article-extra-over-under-delivery>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Über / Unterlieferung',
             'label-info': history.edits|Contains:'Über / Unterlieferung'
           }"
           *ngIf="!history.deleted">
        <article-extra-over-under-delivery historyType="new"
                                           [schema]="schemaDictionary"
                                           [data]="history"
                                           [readOnly]="true">
        </article-extra-over-under-delivery>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="(history.creates|Contains:'Lagerung') ||
                          (history.edits|Contains:'Lagerung')">
    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{'label-info': !history.deleted, 'label-danger': history.deleted}"
           *ngIf="!(history.creates|Contains:'Lagerung')">
        <article-extra-storage historyType="old"
                               [schema]="schemaDictionary"
                               [data]="history"
                               [readOnly]="true">
        </article-extra-storage>
      </div>
    </div>

    <div class="col-md-1 margin-bottom">
    </div>

    <div class="col-md-5 margin-bottom">
      <div class="row"
           style="padding: 0 10px;"
           [ngClass]="{
             'label-success': history.creates|Contains:'Lagerung',
             'label-info': history.edits|Contains:'Lagerung'
           }"
           *ngIf="!history.deleted">
        <article-extra-storage historyType="new"
                               [schema]="schemaDictionary"
                               [data]="history"
                               [readOnly]="true">
        </article-extra-storage>
      </div>
    </div>
  </div>
</form>
