<div class="modal-header">
  <h3 class="modal-title">
    Preis ist nicht freigegeben
  </h3>
</div>

<div class="modal-body">

  <p>
    <i class="fa fa-warning"></i> Der Preis von {{ price }}
    für die Stückzahl {{ position.quanity }} ist nicht freigegeben
  </p>

  <div>&nbsp;</div>

  <div class="whitebox">
    <h4 *ngIf="typ === 'de'"><img src="/ui/assets/images/flags/de.png">&nbsp;Deutschland</h4>
    <h4 *ngIf="typ === 'cn'"><img src="/ui/assets/images/flags/cn.png">&nbsp;China</h4>

    <div class="row">
      <div class="col-sm-3">Kalkulation:</div>
      <div class="col-sm-3">{{ history.calc }}</div>

      <div class="col-sm-3">Datum:</div>
      <div class="col-sm-3">{{ history.date }}</div>
    </div>

    <div class="row">
      <div class="col-sm-3">Lose:</div>
      <div class="col-sm-3">{{ history.lose }}</div>

      <div class="col-sm-3" *ngIf="typ === 'de'">Kalk-Menge:</div>
      <div class="col-sm-3" *ngIf="typ === 'cn'">Wechselkurs:</div>

      <div class="col-sm-3" *ngIf="typ === 'de'">
        <ng-container *ngIf="history.calc_quanity">{{ history.calc_quanity }}</ng-container>
        <ng-container *ngIf="!history.calc_quanity">{{ history.quanity }}</ng-container>
      </div>
      <div class="col-sm-3" *ngIf="typ === 'cn'">{{ history.exchange }}</div>
    </div>

    <div class="row">
      <div class="col-sm-3">Einzel:</div>
      <div class="col-sm-3 bold">{{ history.single }}</div>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="dismiss()">
    Abbrechen
  </button>

  <button type="button" class="btn btn-success pull-right" (click)="submit()">
    Preis übernehmen und Auftrag erstellen
  </button>
</div>
