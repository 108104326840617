/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {CustomerService} from '../../customer/services/customer.service';
import {ContactService} from '../../customer/services/contact.service';
import {WorkflowService} from './workflow.service';
import {InventoryService} from '../../article/inventory/inventory.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {BigWorkflow, Workflow, WorkflowAdditional, WorkflowAdditionalSimple} from '../models/workflow.models';
import {Observable, forkJoin, of} from 'rxjs';
import {Contact} from '../../customer/model/contact';
import {BigCreateWorkflow} from '../models/workflow-create.models';
import {LocalDate} from '../models/local-date';
import {DateHelper} from '../../../helper/date-helper';
import {FrameService} from './frame.service';

@Injectable({providedIn: 'root'})
export class WorkflowFactoryService {

  constructor(private workflowService: WorkflowService,
              private customerService: CustomerService,
              private contactService: ContactService,
              private inventoryService: InventoryService,
              private frameService: FrameService) {
  }

  static workflowStatusCheck(workflow: Workflow): boolean {
    // if true, we have an offer
    return workflow.status === 0 || workflow.status === 1;
  }

  create(id: number, reorder: boolean = false): Observable<any> {
    return this.workflowService.detail(id, reorder).pipe(
      mergeMap<BigWorkflow, any>(val => {
        const inventoryObs = this.inventoryService.inventorySum(val.object.article, val.object.id);
        const customerObs = this.customerService.detail(val.object.customer_id);
        const contactObs = this.createContactObs(val);

        return forkJoin([
          inventoryObs,
          customerObs,
          contactObs
        ]).pipe(map(response => {
          val.object['frame'] = val.frame;
          const workflow = {
            object: val.object,
            positions: val.positions,
            frame: val.frame,
            delivered: val.delivered || {actual: 0, rest: 0}
          };

          return {
            workflow: workflow,
            inventory: response[0],
            customer: response[1],
            contact: response[2],
            article: val.article,
            comments: val.comments
          };
        }));
      })
    );

  }

  createAdditionalObs(val: BigCreateWorkflow): Observable<WorkflowAdditional> {
    return this.frameService.additional(val.workflow.object.id).pipe(
      map<WorkflowAdditionalSimple, WorkflowAdditional>((data: WorkflowAdditionalSimple) => {
        data['workflow'] = val.workflow.object.id;
        return data as WorkflowAdditional;
      }),
      catchError<any, any>(() => {

        const additional = {
          workflow: val.workflow.object.id,
          contract_date: DateHelper.format(new Date()) as LocalDate,
          order_date: null,
          order_nr: null,
          hint: null,
          delivery_hint: null,
          minimum_stock: null,
          new_production_within: null
        };
        return of(additional);
      })
    );
  }

  private createContactObs(val: BigWorkflow): Observable<Contact | null> {
    if (val.object.contact_id) {
      return this.contactService.detail(val.object.contact_id);
    } else {
      return of(null);
    }
  }


}
