<form class="form-horizontal" [formGroup]="form">
  <div class="col-md-12">

    <h3>Anfrage erstellen</h3>

    <div class="form-group" formGroupName="producers">
      <label class="col-sm-4 control-label">Produzent</label>

      <div class="col-sm-8"
           *ngFor="let obj of producerList; let index = index"
           [ngClass]="{'col-sm-offset-4': index !== 0}">
        <div class="checkbox">
          <label>
            <input type="checkbox" id="producer" [formControlName]="obj.name">
            {{ obj.name }}
          </label>
        </div>
      </div>

    </div>

    <div class="form-group">
      <label for="input-delivery-date" class="col-sm-4 control-label">Liefertermin (ETD)</label>
      <div class="col-sm-6">
        <ev-datepicker id="input-delivery-date"
                       class="form-control"
                       formControlName="delivery_date"
                       [ngClass]="{'red_alert': errors['obj.delivery_date']}"
                       [allDates]="true">
        </ev-datepicker>
      </div>
      <div class="col-sm-2">&nbsp;</div>
    </div>

    <div class="col-sm-12">
      <order-request-main-request-position-form [positionForm]="positionForm" [errors]="errors">
      </order-request-main-request-position-form>
    </div>

    <div class="form-group">
      <div class="col-sm-12">
        <label for="input-request_hint" class="control-label">Hinweis-Text</label>

        <textarea class="form-control"
                  id="input-request_hint"
                  rows="5"
                  formControlName="note"
                  [ngClass]="{'red_alert': errors['obj.note']}">
        </textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="alert alert-danger" role="alert" *ngIf="!data.conformance">
          <strong>Achtung!</strong> Basismaterial nicht China konform.
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <article-warning [article]="data.article"></article-warning>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <button type="button" class="btn btn-success pull-right"
                (click)="createRequest()"
                [disabled]="!form.valid || creating"
                *ngIf="!data.article.date_deleted">
          Anfrage erstellen
        </button>
      </div>
    </div>

  </div>
</form>
