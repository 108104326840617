/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Component, OnInit} from '@angular/core';
import {LocalStorage} from '../../common/storage/local-storage';
import {InitData} from '../../common/init-data/init-data';

@Component({
             selector: 'main-version',
             templateUrl: './main-version.component.html',
           })
export class MainVersionComponent implements OnInit {

  environment: string;
  version: string;

  constructor(private localStorage: LocalStorage) {}

  ngOnInit(): void {
    this.environment = this.localStorage.getObject<InitData>('data').environment;
    this.version = this.localStorage.getObject<InitData>('data').version;
  }

}
