import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InvoiceAdditional} from '../../workflow/invoice/invoice.models';

@Injectable({
  providedIn: 'root'
})
export class CustomerOposTargetDateEditService {
  constructor(private http: HttpClient) {
  }

  setDate(id: number, form: any): Observable<InvoiceAdditional> {
    return this.http.put<InvoiceAdditional>(`${environment.apiv4uri}invoice/${id}/target/date`, form);
  }
}
