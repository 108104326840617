<div [formGroup]="form">
  <div formArrayName="groups">
    <ng-container *ngFor="let gForm of groupFormGroups; trackBy: trackByFn; let iG = index">
      <material-order-intake-group [group]="list[iG]"
                                   [form]="gForm"
                                   [errors]="errors"
                                   [gIndex]="iG"
                                   [formGroupName]="iG"
                                   [groups]="groups"
                                   [markedVariationId]="markedVariationId"
                                   [markedPositionId]="markedPositionId"
                                   [markedTransactionId]="markedTransactionId"
                                   (addTransaction)="addTransactionPosition(iG, $event)"
                                   (removeTransaction)="removeTransaction.emit($event)"
                                   (updateOrder)="updateOrder.emit()">
      </material-order-intake-group>
    </ng-container>
  </div>
</div>
