import {Pipe, PipeTransform} from '@angular/core';
import {RightService} from './right.service';
import {LocalStorage} from '../storage/local-storage';

@Pipe({name: 'HasPerm'})
export class HasPerm implements PipeTransform {

  constructor(private rightService: RightService) {
  }

  transform(value: string): boolean {
    return this.rightService.has(value);
  }

}

@Pipe({name: 'HasGroup'})
export class HasGroup implements PipeTransform {

  constructor(private localStorage: LocalStorage) {
  }

  transform(value: string): boolean {
    return this.localStorage.getObject<any>('user').group === value;
  }

}
