import {HttpErrorResponse} from '@angular/common/http';
import {Comment} from '../../comment/comment.models';

export interface ArticleToolingCost {
  article: string;
  cost?: string;
  price: string;
}

export interface ArticleListData {
  id: number;
  oa_nr: string;
  date_deleted?: string;
  lock?: number;
  sds_nr?: string;
  kd_art_nr?: string;
  kd_art_index?: string;
  kd_art_extend?: string;
  kd_art_extend2?: string;
  surface?: string;
  execution?: string;
  surface_area?: string;
  cu_thickness?: string;
  endintensity?: string;
  drilling?: string;
  customer?: string;
  offer_list?: number[];
  change_state: string;
  status: {name: string, label: string};
}

export interface ArticleListElement {
  oa_nr: string;
  sds_nr?: string;
  change_state?: string;
  status: number;
  kd_art_nr?: string;
  kd_art_index?: string;
  kd_art_extend1?: string;
  kd_art_extend2?: string;
  surface?: number;
  execution?: string;
  surface_area?: string;
  cu_thickness?: number;
  end_intensity?: number;
  drilling?: number;
  customer?: string;
  offer_list?: number[];
  state: string;
  data: {[key: string]: any};
  date_deleted?: string;
  lock?: number;
  weight?: number;
}

export interface ArticleCore {
  oa_nr: string;
  data: any;
  username: string;
  customer?: string;
  calc_de: string;
  calc_cn: string;
  calc_chn?: string;
  hash?: string;
  date_deleted?: string;
  locked?: number;
  sds_nr?: string;
  sister?: string;
  change_state: string;
}

export interface Article extends ArticleCore {
  oa_nr: string;
  data: any;
  username: string;
  customer?: string;
  calc_de: string;
  calc_chn: string;
  comments?: Comment[];
  hash: string;
  date_deleted?: string;
  locked?: number;
  sds_nr?: string;
  sister?: string;
  current_hash?: string;
  full_hash_v1?: string;
  full_hash_v2?: string;
  change_state: string;
}

export interface ArticleDuplicate {
  oa_nr: string;
  sds_nr: string;
  kd_art_nr: string;
  kd_art_index: string;
}

export interface ArticleTopForm {
  sds_nr?: string;
  site_nr?: string;
  kd_art_nr?: string;
  kd_art_index?: string;
  kd_art_extend1?: string;
  kd_art_extend2?: string;
  article_note?: string;
}

export interface ArticleCopyForm {
  sds_nr?: string;
  kd_art_nr?: string;
  kd_art_index?: string;
  site_nr?: string;
}

export interface SmallArticleData {
  site_nr?: string;
  kd_art_nr?: string;
  kd_art_index?: string;
  kd_art_extend1?: string;
  kd_art_extend2?: string;
  article_note?: string;
  utilize1?: number;
}

export interface SmallArticle {
  oa_nr: string;
  sds_nr?: string;
  data: SmallArticleData;
  customer?: string;
  date_deleted?: string;
  locked?: number;
  sister?: string;
}

export interface UpdateForm {
  data: any;
  sds_nr?: string;
}

export interface ArticleStream {
}

export class ArticleStreamValue implements ArticleStream {
  constructor(public article: Article) {
  }
}

export class ArticleStreamError implements ArticleStream {
  constructor(public response: HttpErrorResponse) {
  }
}
