/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
export default class Helper {

  static isNumber(value: any): boolean {
    if (Helper.correctlyDefined(value)) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    } else {
      return false;
    }
  }

  static isInt(value: any): boolean {
    if (isNaN(value)) {
      return false;
    }
    const x = parseFloat(value);
    return (x | 0) === x;
  }

  static toInt(value) {
    if (value instanceof String || typeof value === 'string') {
      value = value.replace('.', '').replace(',', '');
    }
    return parseInt(value, 10);
  }

  static cleanInt(value) {
    if (this.isInt(value) && !this.undefined(value)) {
      return this.toInt(value);
    } else {
      return null;
    }
  }

  static isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  }

  static isLastRow(obj, row) {
    if (Helper.isArray(obj)) {
      return obj.length - 1 === row;
    } else {
      return false;
    }
  }

  static exists(obj, key) {
    return obj.hasOwnProperty(key);
  }

  static keys(obj, func) {
    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue;
      }
      func(obj[key], key);
    }
  }

  static foldLeft(obj, func, start) {
    Helper.keys(obj, (data, key) => {
      start += func(data, key);
    });
    return start;
  }

  static key(obj, name) {
    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue;
      }
      return obj[key];
    }
  }

  static assign(obj1, obj2) {
    return Object.assign(obj1, obj2);
  }

  static toSimpleArray(obj) {
    const array = [];
    Helper.keys(obj, (data) => {
      array.push(data);
    });
    return array;
  }

  static dynamicObject(key: string, value: any) {
    const obj = {};
    obj[key] = value;
    return obj;
  }

  static toSimpleObject(arr) {
    const obj = {};
    arr.forEach((ele, index) => {
      obj[index] = ele;
    });
    return obj;
  }

  static toArray(obj, keyName = 'id', valueName = 'value') {
    const dataArray = [];
    Helper.keys(obj, (data, key) => {
      if (!Helper.undefined(data)) {
        const newObj = {};
        newObj[keyName] = Helper.toInt(key);
        newObj[valueName] = data;
        dataArray.push(newObj);
      }
    });
    return dataArray;
  }

  static isDefined(obj) {
    return !(Object.keys(obj).length === 0 && obj.constructor === Object);
  }

  static guid() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  static nonEmpty(value) {
    return value !== null && value !== '' && value !== undefined;
  }

  static defined(obj) {
    return obj !== '' ||
      obj !== null ||
      obj !== undefined;
  }

  static undefined(obj) {
    return obj === '' ||
      obj === null ||
      obj === undefined;
  }

  static correctlyDefined(obj) {
    return obj !== '' && obj !== null && obj !== undefined;
  }

  static checkUndef(obj, name) {
    return obj[name] !== '' &&
      obj[name] !== null &&
      obj[name] !== undefined;
  }

  static either(typ, lfunc, rfunc) {
    if (typ === 'left') {
      return lfunc();
    } else if (typ === 'right') {
      return rfunc();
    }
  }

  static fullOfNull(array, isObject = false) {
    let empty = false;
    array.forEach((obj) => {
      if (isObject) {
        if (!Helper.isDefined(obj)) {
          empty = true;
        }
      } else {
        if (obj === undefined || obj === null) {
          empty = true;
        }
      }
    });
    return empty;
  }

  static getByKey(array, name, key) {
    let data = null;
    array.forEach((obj) => {
      if (obj[key] === name) {
        data = obj;
      }
    });
    return data;
  }

  static objectEmpty(objectValue: any): boolean {
    Object.keys(objectValue ?? {}).forEach(k => {
      if (objectValue[k] === null || objectValue[k] === undefined || objectValue[k] === '') {
        return false;
      }
    });
    return true;
  }

  // static numberFormat(n) {
  //   return SJSHelper.numberFormat(n);
  // }
  //
  // static abs(n) {
  //   return SJSHelper.abs(n);
  // }
  //
  // static sortBy(obj, func) {
  //   return SJSHelper.sortBy(hello, (o) => o.name);
  // }
  //
  // static sortPosition(positions) {
  //   return SJSHelper.sortPosition(positions);
  // }
  //
  // static map(obj, property) {
  //   return SJSHelper.map(obj, property);
  // }
  //
  // static filter(obj, func) {
  //   return SJSHelper.filter(obj, func);
  // }

}
