import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CalculationProcess} from './calculation.model';
import {CalculationService} from './calculation.service';
import {AlertService} from '../../common/alert-service/alert.service';

@Component({
  selector: 'calculation-list-form',
  templateUrl: './calculation-list-form.component.html',
})
export class CalculationListFormComponent implements OnInit, OnChanges {
  @Input() current: CalculationProcess;
  @Output() reload: EventEmitter<void> = new EventEmitter<void>();
  form: UntypedFormGroup;
  saveLocked = false;

  constructor(private processService: CalculationService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      external_separated: [this.current?.external_separated ?? false],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.current && !changes.current?.isFirstChange()) {
      this.form.reset({external_separated: this.current.external_separated});
    }
  }

  submit() {
    if (this.saveLocked) {
      return;
    }

    this.saveLocked = true;
    this.processService.updateProcess(this.current.id, this.form.value).subscribe(() => {
      this.saveLocked = false;
      this.alertService.add('success', 'Erfolgreich gespeichert!');
      this.reload.emit();
    }, () => {
      this.alertService.add('danger', 'Fehler beim speichern!');
      this.saveLocked = false;
    });
  }
}
