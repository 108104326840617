import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder} from '@angular/forms';
import {Validators} from '@angular/forms';
import {AlertService} from '../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialGroupListElement} from '../material-management.model';
import {MaterialGroupService} from './material-group.service';
import {MaterialGroupForm} from './material-group.model';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'material-group-create',
  templateUrl: './material-group-create.component.html'
})
export class MaterialGroupCreateComponent implements OnInit {
  materialCreateGroupForm: FormGroup<MaterialGroupForm>;
  editing = false;
  editingValues: MaterialGroupListElement = null;
  error: {[key: string]: any};

  constructor(
    private service: MaterialGroupService,
    private modalService: NgbActiveModal,
    private alertService: AlertService,
    private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.materialCreateGroupForm = this.fb.group({
      name: new FormControl<string>(
        (this.editing && !!this.editingValues?.name ? this.editingValues.name : null),
        [Validators.required]
      ),
      has_expiration: new FormControl<boolean>(
        (this.editingValues?.has_expiration ?? false),
        [Validators.required]
      ),
      show_in_planning: new FormControl<boolean>(
        (this.editingValues?.show_in_planning ?? false),
        [Validators.required]
      ),
      show_in_app: new FormControl<boolean>(
        (this.editingValues?.show_in_app ?? false),
        [Validators.required]
      ),
    });
  }


  dismiss(): void {
    this.modalService.dismiss();
  }

  save() {
    this.error = {};
    if (this.editing) {
      this.service.update({
        name: this.materialCreateGroupForm.value.name,
        has_expiration: this.materialCreateGroupForm.value.has_expiration,
        show_in_planning: this.materialCreateGroupForm.value.show_in_planning,
        show_in_app: this.materialCreateGroupForm.value.show_in_app,
      }, this.editingValues.id)
        .subscribe((value) => {
          this.alertService.add(
            'success',
            'Gruppe ' + value.group_id + ' erfolgreich bearbeitet'
          );
          this.modalService.close(value);
        }, (response: HttpErrorResponse) => {
          console.log(response);
          if (response.error && response.error.errors) {
            this.error = response.error.errors;
          }
        });
    } else {
      this.service.create(this.materialCreateGroupForm.value as any).subscribe((value) => {
        this.alertService.add(
          'success',
          'Gruppe erfolgreich hinzugefügt'
        );
        this.modalService.close(value);
      }, (response: HttpErrorResponse) => {
        if (response.error && response.error.errors) {
          this.error = response.error.errors;
        }
      });
    }
  }
}
