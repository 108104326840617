import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {AkquiseListComponent} from './akquise-list.component';
import {UIRouterModule} from '@uirouter/angular';
import {AkquiseSaveModule} from './akquise-save.module';
import {AKQUISE_STATES} from './akquise.states';
import {CustomerNavigationModule} from '../customer/navigation/customer-navigation.module';
import {CustomerHeaderModule} from '../customer/header/customer-header.module';

@NgModule({
  imports: [
    UIRouterModule.forChild({states: AKQUISE_STATES}),
    SharedModule,
    CustomerNavigationModule,
    AkquiseSaveModule,
    CustomerHeaderModule,
  ],
  declarations: [AkquiseListComponent],
})
export class AkquiseModule {
}
