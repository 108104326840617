import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'delivery-coc-material-modal',
  templateUrl: './delivery-coc-material-modal.component.html',
})
export class DeliveryCocMaterialModalComponent implements OnInit {
  @Input() materials: string[] = [];
  @Input() selected: string;
  form: UntypedFormGroup;

  constructor(private modalRef: NgbActiveModal,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      material: [this.selected === 'Standard FR4' ? null : this.selected, Validators.required],
    });
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

  submit(): void {
    this.modalRef.close(this.form.value.material);
  }

}
