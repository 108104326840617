import {Transition} from '@uirouter/angular';
import {CustomerService} from './services/customer.service';

export function customerDetailResolveFn(customerService: CustomerService, trans: Transition) {
  return customerService.detail(trans.params().id).toPromise();
}

export const CUSTOMER_DETAIL_RESOLVE = {
  token: 'customer',
  resolveFn: customerDetailResolveFn,
  deps: [CustomerService, Transition],
};
