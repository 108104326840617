import {StringHelper} from '../common/string-helper';

export class NumberHelper {
  static isDefined(value: number | null | undefined): boolean {
    return value !== null && value !== undefined;
  }

  /**
   * Rounds a number to two decimals
   * @param value number to be rounded
   * @param decimal numbers after the decimal point to round to
   */
  static roundDecimal(value: number, decimal: number): number {
    const vExp = value.toExponential().split('e');
    const vSig = value < 0 ? -1 : 1;
    const e = decimal + Number.parseInt(vExp[1], 10);
    const eString = e < 0 ? `${vExp[0]}e-${-e}` : `${vExp[0]}e+${e}`;
    return vSig * Number(Math.round(vSig * Number(eString)) + `e-${decimal}`);
  }

  static saveParseFloat(
    value: number | string | null | undefined,
    floatingPoint: string = ',',
    thousandPoint: string = '.',
    min: number = -1000000000000000,
    max: number = 1000000000000000
  ): number | null {
    if (typeof value === 'number') {
      return value as number;
    }

    const thousandPointsRemoved = StringHelper.concat(value?.split(thousandPoint) ?? [], '');
    const numVal = Number.parseFloat(thousandPointsRemoved?.replace(floatingPoint, '.') ?? '');
    if (Number.isNaN(numVal) || !NumberHelper.isDefined(numVal)) {
      return null;
    } else if (numVal < min) {
      return min;
    } else if (numVal > max) {
      return max;
    } else {
      return numVal;
    }
  }

  static saveParseInteger(value: number | string | null | undefined): number | null {
    if (typeof value === 'number') {
      return value as number;
    }

    const numVal = Number.parseInt(value ?? '', 10);
    return Number.isInteger(numVal) ? numVal : null;
  }

  static sum(values: number[]): number {
    return values.reduce((a, b) => a + b, 0);
  }

  static min(values: (number | null | undefined)[]): number | null {
    return values.reduce((a, b) => a !== null && a !== undefined && a < b ? a : b ?? null, null);
  }

  static max(values: (number | null | undefined)[]): number | null {
    return values.reduce((a, b) => a !== null && a !== undefined && a > b ? a : b ?? null, null);
  }

  static toCommaString(values: number | null | undefined): string {
    return values?.toString(10)?.replace('.', ',');
  }
}
