<div class="modal-header">
  <h3 *ngIf="editing">Material Artikel Gruppe bearbeiten</h3>
  <h3 *ngIf="!editing">Material Artikel Gruppe hinzuf&uuml;gen</h3>
</div>
<form class="form-horizontal" [formGroup]="materialCreateGroupForm" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="form-group row">
      <div class="col-sm-2 control-label">
        <label for="name">Bezeichnung</label>
      </div>
      <div class="col-sm-8">
        <input id="name"
               type="text"
               class="form-control"
               formControlName="name"
               autofocus
               [ngClass]="{'red_alert': !!error?.name}">
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-9 control-label">
        <label for="has_expiration">Hat Mindesthaltbarkeit</label>
      </div>
      <div class="col-sm-1">
        <input id="has_expiration"
               type="checkbox"
               class="form-control"
               formControlName="has_expiration"
               autofocus
               [ngClass]="{'red_alert': !!error?.has_expiration}">
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-9 control-label">
        <label for="show_in_planning">In Materialplanung sichtbar</label>
      </div>
      <div class="col-sm-1">
        <input id="show_in_planning"
               type="checkbox"
               class="form-control"
               formControlName="show_in_planning"
               autofocus
               [ngClass]="{'red_alert': !!error?.show_in_planning}">
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-9 control-label">
        <label for="show_in_app">In ERP Terminal App sichtbar</label>
      </div>
      <div class="col-sm-1">
        <input id="show_in_app"
               type="checkbox"
               class="form-control"
               formControlName="show_in_app"
               autofocus
               [ngClass]="{'red_alert': !!error?.show_in_app}">
      </div>
    </div>

    <div class="row" *ngIf="error?.name === 'ExistsError'">
      <div class="col-sm-12 red_alert" [ngSwitch]="error">
        <h3>Eine Gruppe mit diesem Namen existiert bereits</h3>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Abbrechen
    </button>

    <button type="submit" class="btn btn-success pull-right" [disabled]="!materialCreateGroupForm.valid">
      <ng-container *ngIf="editing">Speichern</ng-container>
      <ng-container *ngIf="!editing">Hinzuf&uuml;gen</ng-container>
    </button>
  </div>
</form>
