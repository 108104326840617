import {Ng2StateDeclaration} from '@uirouter/angular';
import {DunningAutomationComponent} from './dunning-automation.component';
import {DunningService} from './dunning.service';
import {DunningListComponent} from './dunning-list.component';
import {ErpTransition} from '../../core/erp-transition.service';

export function dunningAutomation2ResolveFn(service: DunningService) {
  return service.automationList().toPromise();
}

export function dunningListResolveFn(trans: ErpTransition, service: DunningService) {
  return service.list(trans.params()).toPromise();
}

export const DUNNING_STATES: Ng2StateDeclaration[] = [

  {name: 'a.dunning', url: '/dunning', abstract: true, data: {requiresAuth: true}},

  {
    name: 'a.dunning.automation',
    url: '/automation',
    views: {'main@a': {component: DunningAutomationComponent}},
    resolve: [{token: 'invoices', resolveFn: dunningAutomation2ResolveFn, deps: [DunningService]}],
    data: {requiresAuth: true}
  },

  {
    name: 'a.dunning.list',
    url: '/list?page&sort&order&not_delivered&dunning_id&date&date_sent&customer_name&invoice&username',
    views: {'main@a': {component: DunningListComponent}},
    resolve: [{token: 'listData', resolveFn: dunningListResolveFn, deps: [ErpTransition, DunningService]}],
    params: {
      page: {dynamic: true},
      sort: {dynamic: true},
      order: {dynamic: true},
      not_delivered: {dynamic: true},
      dunning_id: {dynamic: true},
      date: {dynamic: true},
      date_sent: {dynamic: true},
      customer_name: {dynamic: true},
      invoice: {dynamic: true},
      username: {dynamic: true},
    },
    data: {requiresAuth: true}
  },

];
