import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Comment} from '../../../../envisia/comment/comment.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderHistoryData, OrderListData} from '../order-request.models';
import {ShortFrame} from '../../models/frame.models';
import {ShortFetch} from '../../models/fetch.models';
import {ShortCustomer} from '../../../customer/model/customer';
import {StorageBin} from '../../../../envisia/article/inventory/inventory.models';
import {RightService} from '../../../../common/right-service/right.service';
import Helper from '../../../../helper/helper';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {WorkflowOrderRequestService} from '../../workflow-services/workflow-order-request.service';
import {HttpErrorResponse} from '@angular/common/http';
import {deepCopy} from '../../../../helper/deep-copy';
import {InventoryGroup} from '../../../article/inventory/inventory.models';
import {InventoryService} from '../../../article/inventory/inventory.service';
import {CommentService} from '../../../comment/comment.service';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
const SECTIONS = ['confirmed', 'conveyed', 'received', 'ipe', 'reviewed', 'expensed'];

@Component({
  selector: 'order-received-modal',
  templateUrl: './order-received-modal.component.html'
})
export class OrderReceivedModalComponent implements OnInit {
  @Input() data: OrderListData;
  @Input() storage: StorageBin[];
  @Input() frame: { frame: ShortFrame, fetches: ShortFetch[] } | null;
  @Input() customer: ShortCustomer;
  @Input() history: OrderHistoryData;
  @Input() comments: Comment[];
  @Input() inventoryGroupData: { objects: InventoryGroup[], sum: number };
  @Output() reloadList = new EventEmitter<any>();
  loading = false;
  right: boolean;
  hasFrame: boolean;
  item: string;
  form: any;
  groupObjects: InventoryGroup[];
  errors: { [key: string]: any; } = {};
  resetStatusLocked = false;

  constructor(private activeModal: NgbActiveModal,
              private rightService: RightService,
              private alertService: AlertService,
              private inventoryService: InventoryService,
              private commentService: CommentService,
              private workflowOrderRequestService: WorkflowOrderRequestService) {
  }

  ngOnInit(): void {
    this.right = this.rightService.has('order.testing.change');
    this.item = this.data.order.id + '-' + (this.data.order.positions.index + 1);
    this.hasFrame = this.frame !== null;
    this.groupObjects = this.inventoryGroupData.objects;
    this.initForm();
  }

  save(): void {
    const id = this.data.order.id;
    const index = this.data.order.positions.index;
    this.loading = true;
    this.errors = {};

    const body = deepCopy(this.form);
    this.cleanSections(body);
    this.workflowOrderRequestService.savePosition(id, index, body).subscribe(response => {
      this.workflowOrderRequestService.history(id, index).subscribe(history => {
        this.history = history;
        this.success(response);
      }, () => {
        this.success(response);
      });
      this.inventoryService.articleGroupWithNotes(this.data.article.oa_nr).subscribe(groupData => {
          this.groupObjects = groupData.objects;
        }, () => {
          this.success(response);
        }
      );
    }, (response: HttpErrorResponse) => {
      let message = 'Position konnte nicht geändert werden!';
      switch (response.status) {
        case 400:
          if (!!response.error) {
            this.errors = response.error.errors ?? response.error;

            const newMessage = this.genererateErrorMessage(response.error);
            if (newMessage !== '') {
              message = newMessage;
            }
          }
          break;

        case 404:
          message += ' Bitte prüfen Sie, ob die Zeile oder die Bestellung entfernt wurden.';
          break;

        case 409:
          message += ' Eine Zeile oder das Inventar hat sich in der Zwischenzeit geändert.';
          break;
      }

      this.alertService.add('danger', message);

      this.loading = false;
    });
  }

  orderSplit(): void {
    this.form.data.push(this.defaultObject(false));
  }

  orderDrop(index: number, id?: number): void {
    if (confirm('Soll die Zeile wirklich entfernt werden? Alle Daten aus der Zeile werden unwiderruflich gelöscht.')) {
      if (id) {
        this.workflowOrderRequestService.deletePosition(id).subscribe(() => {
          this.alertService.add('success', 'Geteilte Zeile wurde entfernt.');
          const commentId = `${this.data.order.id}-${this.data.order.positions.index + 1}`;
          this.commentService.list('order', commentId).subscribe(h => {
            this.comments = h;
          });
          this.form.data.splice(index, 1);
        }, () => {
          this.alertService.add('danger', 'Konnte Zeile nicht löschen.');
        });
      } else {
        this.form.data.splice(index, 1);
      }
    }
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  private success(response: any): void {
    const data = response.order_position_items.map(v => {
      v.data.id = v.id;
      v.data.index = v.index;
      return v.data;
    }).sort(v => v.index);
    this.initSections({data: data});
    this.form.testing = response.order_position.testing;
    this.form.data = data;
    this.alertService.add('success', 'Position erfolgreich gespeichert');
    this.loading = false;
    this.reloadList.emit();
  }

  private initForm(): void {
    this.form = deepCopy(this.data.order_item_position);
    if (!Helper.isDefined(this.form)) {
      if (this.data.order.positions.delivery_date) {
        // If the form isn't here we actually need to create it
        // and also create a default object so that we can render something
        this.form = {};
        this.form.data = [this.defaultObject(true)];
      }
    }

    this.initSections(this.form);
  }

  private defaultObject(newForm: boolean): any {
    const data = {confirmed: {date: this.data.order.positions.delivery_date}};
    if (!newForm) {
      this.initInnerSections(data);
    }
    return data;
  }

  // -- form cleaner

  /** initalize empty sections */
  private initSections(form: any): void {
    form.data.forEach(value => {
      this.initInnerSections(value);
    });
  }

  private initInnerSections(value: any): void {
    // creates empty sections
    SECTIONS.forEach(name => {
      if (!value[name]) {
        value[name] = {};
        if (name === 'received') {
          value[name].data = [{storage_bin_name: null}];
        } else if (name === 'expensed') {
          value[name].quantities = [null];
        }
      } else if (name === 'received' && !value[name].data) { // old code might not have this **required** section
        value[name].data = [{storage_bin_name: null}];
      } else if (name === 'expensed' && !value[name].quantities) { // old code might not have this **required** section
        value[name].quantities = [null];
      }
    });
  }

  /** cleans out empty sections */
  private cleanSections(form: any): void {
    form.data.forEach(value => {
      SECTIONS.forEach(name => {
        if (name === 'conveyed' && !!value[name] && Helper.isDefined(value[name])) {
          // Remove any changes to the ship name when the delivery method is not by ship
          if (value['confirmed']?.delivery_method !== 'Schiff') {
            value[name].ship_name = null;
          }
          // Remove section object all together when it is empty
          if (!Helper.objectEmpty(value[name])) {
            delete value[name];
          }
        } else if (name === 'received' || name === 'expensed') {
          if (!this.innerClean(value[name], name)) {
            delete value[name];
          }
        } else {
          if (!Helper.isDefined(value[name])) {
            delete value[name];
          }
        }
      });
    });
  }

  /** checks received and expensed if they have any value */
  private innerClean(value: any, name: string): boolean {
    /** checks if any object value is defined */
    function objectValuesEmpty(objectValue: any): boolean {
      let defined = Helper.isDefined(objectValue);
      if (defined) {
        defined = false;
        Helper.keys(objectValue, outerValue => {
          defined = defined || !!outerValue;
        });
      }
      return defined;
    }

    let isDefined = !!value.date;
    if (name === 'expensed') {
      // set empty quantity fields to zero
      value.quantities = value.quantities.map(innerValue => innerValue ?? 0);
      isDefined = isDefined || value.quantities.filter(innerValue => innerValue > 0).length > 0;
      Helper.keys(value, (innerValue, key) => {
        if (key !== 'quantities') {
          isDefined = isDefined || innerValue > 0;
        }
      });
    } else if (name === 'received') {
      // remove empty array values
      value.data = value.data.filter(objectValue => objectValuesEmpty(objectValue));
      isDefined = isDefined || value.data.length > 0;
      Helper.keys(value, (innerValue, key) => {
        if (key !== 'data') {
          isDefined = isDefined || !!innerValue;
        }
      });
    }

    // if the multi section contains only undefined/empty arrays/fields then we can
    // actually prune the section completely
    return isDefined;
  }

  private genererateErrorMessage(error: any): string {
    let message = '';
    Helper.keys(error, (data, key) => {
      if (key.endsWith('storage_bin_number')) {
        const obj = data[0];
        const arr = key.match(/data\[(\d+)\]\..*\.data\[(\d+)\]\..*/);
        if (arr.length > 2 && obj.msg[0] === 'error.storage') {
          const id = Helper.toInt(arr[1]) + 1;
          const code = Helper.toInt(arr[2]) + 1;
          const arg = obj.args[0] ? obj.args[0] : null;
          message += 'Fehler: Bestellung ' + id + ', Lieferung ' + code;
          if (arg !== null) {
            message += ' muss einen Lagerplatz innerhalb von ' + arg.min + ' - ' + arg.max + ' haben! ';
          } else {
            message += ' muss einen leeren Lagerplatz haben! ';
          }
        }
      }
    });

    return message;
  }

  resetStatus(id: number, status: number): void {
    if (confirm('Status wirklich zurücksetzen und alle in der Box eingegeben Daten löschen?')) {
      this.resetStatusLocked = true;
      this.workflowOrderRequestService.resetStatus(id, status).subscribe(() => {
        this.workflowOrderRequestService.byId(this.data.order.id, this.data.order_item_position.index).subscribe(data => {
          this.data = data;
          this.initForm();
          this.resetStatusLocked = false;
          const item = `${this.data.order.id}-${this.data.order_item_position.index + 1}`;
          this.commentService.list('order', item).subscribe(comments => {
            this.comments = comments;
          }, ignoreRejection);
        });
        this.reloadList.emit();
      });
    }
  }

}
