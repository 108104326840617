/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-solder-pattern',
  templateUrl: './article-extra-solder-pattern.component.html',
})
export class ArticleExtraSolderPatternComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'solder_pattern';
  protected schemaName = 'solder-pattern';
  protected fields = [
    'solder_pattern',
    'solder_pattern_other',
  ];
}
