import {Transition, Ng2StateDeclaration} from '@uirouter/angular';
import {ArticleProductionListComponent} from './article-production-list.component';
import {ProductionArticleService} from './production-article.service';
import {ProductionStepService} from '../production-services/production-step.service';
import {ArticleNavigationComponent} from '../navigiation/article-navigation.component';
import {ArticleMultilayerPlanService} from '../main/multilayer-plan/article-multilayer-plan.service';
import {getArticleMultilayerPlanResolveFn} from '../article.states';

export const ARTICLE_PRODUCTION_STATE: Ng2StateDeclaration = {
  name: 'a.article.production',
  abstract: true,
  data: {requiresAuth: true}
};

export function articleProductionListResolveFn(service: ProductionStepService) {
  return service.list().toPromise();
}

export function articleProductionDataResolveFn(service: ProductionArticleService, trans: Transition) {
  return service.list(trans.params().id).toPromise();
}

export function articleProductionHashResolveFn(service: ProductionArticleService, trans: Transition) {
  return service.getHash(trans.params().id).toPromise();
}

export const ARTICLE_PRODUCTION_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.article.production.list',
  url: '/production/{id}?workflow_id',
  views: {
    'navigation@a': {component: ArticleNavigationComponent},
    'main@a': {component: ArticleProductionListComponent},
  },
  resolve: [
    {token: 'productionStepList', resolveFn: articleProductionListResolveFn, deps: [ProductionStepService]},
    {token: 'data', resolveFn: articleProductionDataResolveFn, deps: [ProductionArticleService, Transition]},
    {token: 'hash', resolveFn: articleProductionHashResolveFn, deps: [ProductionArticleService, Transition]},
    {
      token: 'multilayerPlan',
      resolveFn: getArticleMultilayerPlanResolveFn,
      deps: [Transition, ArticleMultilayerPlanService],
    },
  ]
};

export const ARTICLE_PRODUCTION_STATES: Ng2StateDeclaration[] = [
  ARTICLE_PRODUCTION_STATE,
  ARTICLE_PRODUCTION_LIST_STATE,
];
