<div class="row">

  <div class="col-md-12">
    <div *ngIf="additionals.length > 0">

      <div class="content-box" [formGroup]="additionalForm">
        <h3>Zusatzinformationen</h3>
        <div class="form-horizontal documentadditional form-horizontal-flat" *ngFor="let additional of additionals">

          <div class="form-group">
            <label for="additional-value-{{ additional.id }}" class="col-sm-4 control-label">
              {{ additional.title }}
            </label>
            <div class="col-sm-8">
              <input type="text"
                     id="additional-value-{{ additional.id }}"
                     class="form-control"
                     [formControlName]="additional.id.toString()">
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="col-md-12">

    <div class="content-box" [formGroup]="extraWordsForm">
      <div class="form-horizontal">
        <h3 class="margin-bottom-light">Zusätzliche Schlagworte</h3>
        <div class="content-box-row">
          <div class="content-box-cell content-box-input-cell ">
            <input type="text" id="content_txtadditionalwords" class="form-control form-control-flat"
                   formControlName="extraWords">
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col-md-6">

    <div class="content-box" [formGroup]="fileNumberForm">
      <div class="form-horizontal">
        <h3 class="margin-bottom-light">Aktenzeichen</h3>
        <div class="content-box-row">
          <div class="content-box-cell content-box-input-cell">
            <input type="text" class="form-control form-control-flat" formControlName="fileNumber">
          </div>
          <div class="content-box-cell content-box-submit-cell">
            <button type="button" id="content_btninsertfilenumber" class="btn btn-success-flat"
                    (click)="addFileNumber()">
              Hinzufügen
            </button>
          </div>
        </div>

        <div class="content-box-tag-row">

          <div class="label label-filenumber" *ngFor="let label of fileNumbers">
            <i class="fa fa-paperclip"></i>
            <span class="tag-text">{{ label }}</span>
            <div class="tag-delete">
              <a class="click" title="Aktenzeichen entfernen" (click)="removeFileNumber(label)">
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>

<div style="clear: both;">&nbsp;</div>

<button type="button" class="btn btn-default btn-lg pull-left next-button" (click)="reverseEmitter.emit({})">
  Zurück
</button>

<button type="button" class="btn btn-success btn-lg pull-right next-button" (click)="next()">
  Weiter
</button>
