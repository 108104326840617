<div class="modal-header">
  <h3 class="modal-title">Basismaterial (Hersteller/Bezeichnung) festlegen</h3>
</div>

<div class="modal-body">
  Zum Erstellen des Dokuments muss ein Basismaterial (Herstellung/Bezeichnung) für diese Lieferung festgelegt werden:
  <form [formGroup]="form">
    <div class="form-group">
      <label for="material">Basismaterial:</label>
      <select id="material" class="form-control" formControlName="material">
        <option [ngValue]="null">-</option>
        <option [ngValue]="material" *ngFor="let material of materials">{{material}}</option>
      </select>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-default pull-left" (click)="dismiss()">
    Abbrechen
  </button>
  <button class="btn btn-success pull-right" [disabled]="!form.valid" (click)="submit()">
    Festlegen
  </button>
</div>
