import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  MaterialOrderArticleListElement,
  MaterialOrderArticleListGroup,
  MaterialOrderArticleListIndexedGroupElement,
  MaterialOrderCreateForm
} from '../material-order.model';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {noop} from '../../../../helper/noop';
import {MaterialManagementService} from '../../material-management.service';
import {StateService} from '@uirouter/core';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MaterialOrderCreateListComponent} from './list/material-order-create-list.component';
import {MaterialOrderCreateService} from './material-order-create.service';
import {MaterialSupplierGroup} from '../supplier-group/material-supplier-group.model';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialGroup} from '../../groups/material-group.model';

@Component({
  selector: 'material-order-create',
  templateUrl: './material-order-create.component.html'
})
export class MaterialOrderCreateComponent implements OnInit {
  @Input() supplierGroups: MaterialSupplierGroup[];
  @Input() groups: Lister<MaterialGroup>;
  @Input() orderArticleList: MaterialOrderArticleListGroup[] = [];
  @Input() startSupplierId?: number = null;
  @Input() markedVariationId?: number = null;

  form: UntypedFormGroup;
  currentSupplier?: MaterialSupplierGroup = null;
  currentGroups: MaterialGroup[] = [];
  errors: { [key: string]: any } = {};

  private static calculateAmount(article: MaterialOrderArticleListElement): number {
    const total = article.amount + article.amount_ordered;
    const minRequired = article.min_stock - total;
    return minRequired > article.min_order ? minRequired : article.min_order;
  }

  constructor(private mocs: MaterialOrderCreateService,
              private stateService: StateService,
              private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private mms: MaterialManagementService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.initForm();
    if (this.startSupplierId) {
      this.currentSupplier = this.supplierGroups.find(supplier => supplier.supplier.id === this.startSupplierId);
      this.form.patchValue({ supplier_id: this.startSupplierId });
      this.selectSupplier();
    }
  }

  private initForm(): void {
    const orders = this.fb.array([]);

    this.orderArticleList.forEach(mig => {
      mig.article_list.forEach(article => {
        orders.push(this.initArticleForm(article, false, 0));
      });
    });

    this.form = this.fb.group({
      supplier_id: [this.currentSupplier?.supplier?.id, Validators.required],
      orders: orders,
      note: [null],
      force_amount: [false],
    });
  }

  private initArticleForm(article: MaterialOrderArticleListElement, selected: boolean, deliveryIndex: number): UntypedFormGroup {
    return this.fb.group({
      selected: [!!selected || (article.id === this.markedVariationId)],
      index: [article.id * 10000 + deliveryIndex, {emitEvent: false, onlySelf: true}],
      variation_id: [article.id, {emitEvent: false, onlySelf: true}],
      amount: [MaterialOrderCreateComponent.calculateAmount(article), Validators.required],
      date_delivery: [article.date_delivery],
      price: [article.vendor_price],
      delivery_index: [deliveryIndex],
      note: [],
    });
  }

  selectSupplier(): void {
    this.currentSupplier = this.supplierGroups.find(supplier => supplier?.supplier?.id === this.form.value.supplier_id);
    if (this.currentSupplier) {
      this.currentGroups = this.groups.objects.filter(g => this.currentSupplier.group_ids.findIndex(gid => gid === g.id) > -1);
      const data = {
        supplier_id: this.currentSupplier.supplier.id,
        marked_variation_id: this.markedVariationId,
        show_order_proposal: true,
      };
      this.mocs.list(data).subscribe(list => {
        this.orderArticleList = list.objects;
        this.initForm();
      }, ignoreRejection);
    } else {
      this.currentGroups = [];
    }
  }

  submit(): void {
    this.form.patchValue({supplier_id: this.currentSupplier?.supplier.id}, {emitEvent: false});

    if (this.form.valid) {
      const form = (this.form.value as MaterialOrderCreateForm);
      form.orders = form.orders.filter(a => a.selected);
      this.errors = {};
      if (form.orders.length > 0) {
        this.mocs.create(form).subscribe(() => {
          this.stateService.go('a.material.order.list', {'status': 50});
        }, (response: HttpErrorResponse) => {
          switch (response.status) {
            case 400:
              const errors = response?.error?.errors || {};
              // Map key indices for each error
              Object.keys(errors).forEach(k => {
                if (k.startsWith('orders[')) {
                  this.form.value.orders.forEach((o, i) => {
                    if (k.startsWith('orders[' + i + ']')) {
                      this.errors['orders[' + o.index + ']' + k.split(']')[1]] = errors[k];
                    }
                  });
                } else {
                  this.errors[k] = errors[k];
                }
              });
              this.alertService.add('danger', 'Bitte prüfen Sie das Formular');
              break;
            default:
              this.alertService.add('danger', 'Unbekannter Fehler');
              break;
          }
        });
      } else {
        this.alertService.add('danger', 'Es muss mindestens ein Artikel mit Menge größer 0 ausgewählt werden');
      }
    }
  }

  addGroupManually(group: MaterialGroup): void {
    this.mergeList({group: {...group}, article_list: []});
  }

  private mergeList(mig: MaterialOrderArticleListGroup, selected?: boolean): void {
    const orders = this.form.get('orders') as UntypedFormArray;

    mig.article_list.forEach(article => {
      const count = this.form.value.orders.filter(a => a.variation_id === article.id).length;
      orders.push(this.initArticleForm(article, selected, count));
    });

    let existingMig = this.orderArticleList.find(a => a.group.id === mig.group.id);
    if (!existingMig) {
      existingMig = mig;
      this.orderArticleList.push(mig);
      this.orderArticleList.sort((a, b) =>
        a.group.group_id.localeCompare(b.group.group_id));
    } else {
      existingMig.article_list = existingMig.article_list.concat(mig.article_list);
    }

    existingMig.article_list.sort((a, b) => a.variation_id.localeCompare(b.variation_id));
  }

  removeOrder(mig: MaterialOrderArticleListIndexedGroupElement): void {
    console.log(mig.article.id, mig.delivery_index);
    const orders = this.form.get('orders') as UntypedFormArray;
    const orderFormIndex = this.form.value.orders.findIndex(o =>
      o.variation_id === mig.article.id &&
      o.delivery_index === mig.delivery_index
    );
    orders.removeAt(orderFormIndex);

    // If the article is no longer used we can drop it
    if (this.form.value.orders.findIndex(o => o.variation_id === mig.article.id) === -1) {
      this.orderArticleList.forEach(existingMig => {
        if (existingMig.group.id === mig.group.id) {
          existingMig.article_list = existingMig.article_list.filter(article =>
            article.id !== mig.article.id
          );
        }
      });
    }
  }

  copyOrder(mig: MaterialOrderArticleListIndexedGroupElement): void {
    const orders = this.form.get('orders') as UntypedFormArray;
    const count = this.form.value.orders.filter(o => o.variation_id === mig.article.id).length;
    orders.push(this.initArticleForm(mig.article, true, count));
  }

  openAddArticleModal(groupId?: number): void {
    const modalRef = this.modalService.open(MaterialOrderCreateListComponent, {windowClass: 'modal2-full'});
    modalRef.componentInstance.currentSupplier = this.currentSupplier.supplier;
    modalRef.componentInstance.groups = this.currentGroups ?? this.groups.objects;
    modalRef.componentInstance.currentGroupId = groupId;

    const articleIds = [];
    this.orderArticleList.forEach(mig => {
      mig.article_list.forEach(article => {
        articleIds.push(article.id);
      });
    });
    modalRef.componentInstance.currentArticleIds = articleIds;

    modalRef.componentInstance.articleEmitter.subscribe(mig => this.mergeList(mig, true));
  }

  clearPrices(): void {
    if (confirm('Möchten Sie wirklich alle Preise leeren?')) {
      const orders = this.form.get('orders') as UntypedFormArray;
      for (let i = 0; i < orders.length; i++) {
        orders.at(i).patchValue({'price': null});
      }
    }
  }

  get unlistedGroups(): MaterialGroup[] {
    const listedGroups = this.orderArticleList.map(mig => mig.group.id);
    return this.currentGroups?.filter(g => !listedGroups.find(gid => gid === g.id)) ?? [];
  }

  setForceAmount(): void {
    this.form.patchValue({'force_amount': true});
  }

  get hasMinAmountErrors(): boolean {
    for (let i = 0; i < this.form.value.orders.length; i++) {
      if (this.form.hasError('min', ['orders', i, 'amount'])) {
        return true;
      }
    }
    return false;
  }

  trackByFn(index: number, item: MaterialOrderArticleListGroup): number {
    noop(this);
    return item.group.id;
  }
}
