import {useRef, useState} from 'react';
import {MaterialSupplierSelectHook, MaterialSupplierSelectState} from './material-supplier-select.model';
import {MaterialSupplierSelectAxiosService} from './material-supplier-select-axios.service';
import {useDebouncedCallback} from '@react-hookz/web';
import {MaterialSupplier} from '../../../lazy/material-supplier/material-supplier.model';
import {useFetchEffect} from '../../helper/react-async-fetch-effect';

interface Props {
  groupId: number;
  supplierId: number | null;
  supplierIdChanged: (supplierId: number | null) => void;
}

export function useMaterialSupplierSelect(props: Props): MaterialSupplierSelectHook {
  const abortController = useRef<AbortController | null>(null);
  const [state, setState] = useState<MaterialSupplierSelectState>({
    query: '',
    suppliers: [],
    selected: null,
    suggestionsOpen: false,
    suggestionsLoading: false,
    preselectedLoading: false,
  });

  // load preselected items
  const loadPreselected: () => Promise<void> = async () => {
    setState({
      ...state,
      preselectedLoading: true,
    });
    try {
      const preselected = await MaterialSupplierSelectAxiosService.getDefault(props.supplierId, props.groupId);
      setState({
        ...state,
        query: preselected.name.trim(),
        suppliers: [preselected],
        selected: preselected,
        preselectedLoading: false,
      });
    } catch (e) {
      console.error(e);
      setState({
        ...state,
        preselectedLoading: false,
      });
    }
  };

  // reload items when the supplier id or group id changed
  useFetchEffect(async () => {
    if (props.supplierId === null || props.supplierId === state.selected?.id) {
      return;
    }

    await loadPreselected();
  }, [props.supplierId, props.groupId]);

  const loadSuggestions: (query: string) => void = (query: string) => {
    abortController.current?.abort();
    abortController.current = new AbortController();
    try {
      MaterialSupplierSelectAxiosService.search(
        props.groupId,
        query,
        abortController.current,
      ).then(result => {
        setState({
          ...state,
          query: query,
          suppliers: result.data.objects ?? [],
          suggestionsLoading: false,
        });
      });
    } catch (e) {
      setState({...state, suggestionsLoading: false});
      if (e.message !== 'canceled') {
        console.error(e);
      }
    } finally {
      abortController.current = null;
    }
  };

  const debouncedChangeSupplierId = useDebouncedCallback(props.supplierIdChanged, [], 500);
  const debouncedLoadSuggestions = useDebouncedCallback(loadSuggestions, [state, abortController], 750);

  const setSupplier: (selected: MaterialSupplier | null) => void = (selected) => {
    debouncedChangeSupplierId(selected?.id ?? null);
    setState({
      ...state,
      query: selected?.name?.trim() ?? '',
      selected: selected ?? null,
      suggestionsLoading: false,
    });
  };

  const getSuggestions: (query: string) => void = (query) => {
    debouncedLoadSuggestions(query);
    const selected = query === '' ? null : state.selected;
    if (!selected && !!state.selected) {
      debouncedChangeSupplierId(null);
    }

    setState({
      ...state,
      query: query,
      selected: selected,
      suggestionsLoading: true,
    });
  };

  return {
    state: state,
    setSupplier: setSupplier,
    getSuggestions: getSuggestions,
  };
}
