/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {from} from 'rxjs';
import {FetchStatusChangeModalComponent} from './fetch-status-change-modal.component';

@Injectable({providedIn: 'root'})
export class FetchStatusModalService {

  constructor(private modalService: NgbModal) {
  }

  open(wid: number, id: number, ordering: number): Observable<number> {
    const modalRef =  this.modalService.open(FetchStatusChangeModalComponent, {windowClass: 'modal2-smm'});
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.wid = wid;
    modalRef.componentInstance.ordering = ordering;
    return from(modalRef.result);
  }

}
