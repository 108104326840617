<div class="modal-header">
  <h3>Material Einheit {{this.form.value.id ? 'bearbeiten' : 'erstellen'}}</h3>
</div>
<form class="form-horizontal" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="form-group">
      <div class="col-sm-2 control-label">
        <label for="unit">Bezeichnung</label>
      </div>
      <div class="col-sm-8">
        <input id="unit" type="text" class="form-control" formControlName="unit" autofocus>
      </div>
    </div>

    <div class="row" *ngIf="errorCode">
      <div class="col-sm-12 red_alert" style="clear: both" [ngSwitch]="errorCode">
        <ng-container *ngSwitchCase="404">
          <h3>Die Einheit wurde nicht gefunden</h3>
        </ng-container>
        <ng-container *ngSwitchCase="409">
          <h3>Diese Einheit existiert bereits</h3>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <h3>Unbekannter Fehler beim &Uuml;bertragen des Formulars</h3>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Abbrechen
    </button>

    <button type="submit" class="btn btn-success pull-right" [disabled]="!this.form.valid">
      <ng-container *ngIf="this.form.value.id">Speichern</ng-container>
      <ng-container *ngIf="!this.form.value.id">Hinzuf&uuml;gen</ng-container>
    </button>
  </div>
</form>
