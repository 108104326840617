import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import {useEffect, useState} from 'react';
import {ChangeEvent} from 'react-autosuggest';
import {NumberHelper} from '../../../../helper/number-helper';
import {AutosuggestTheme} from '../../../../react/autosuggest/autosuggest-theme';

interface Props {
  id: string;
  className: string;
  workflowId: number | null;
  workflowIds: number[];
  placeholder?: string;
  onChange: (workflowId: number | null) => void;
}

export function ArticleInventoryWorkflowAutosuggest(props: Props) {
  const [stringValue, setStringValue] = useState<string>(props.workflowId?.toString(10) ?? '');
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    setStringValue(props.workflowId?.toString(10) ?? '');
  }, [props.workflowId]);

  const getSuggestions: (input: string) => string[] = (input) => {
    const inputValue = input.trim();
    return props.workflowIds
      .map(workflowId => workflowId.toString(10))
      .filter(workflowId => workflowId.startsWith(inputValue))
      .filter((workflowId, i) => i < 15);
  };

  const findAndSetValue: (input: string) => void = (input) => {
    setStringValue(input);
    props.onChange(NumberHelper.saveParseInteger(input));
  };

  const suggestionsFetchRequested: (obj: { value: string }) => void = (obj) => {
    findAndSetValue(obj.value);
    setSuggestions(getSuggestions(obj.value));
  };

  const suggestionsClearRequested: () => void = () => {
    setSuggestions([]);
  };

  const onChange: (event: React.FormEvent<HTMLElement>, params: ChangeEvent) => void = (_, ce) => {
    findAndSetValue(ce.newValue);
  };

  const inputProps = {id: props.id, placeholder: props.placeholder ?? '', value: stringValue, onChange: onChange};

  return <>
    <Autosuggest id='workflow_id'
                 theme={{...AutosuggestTheme, input: props.className}}
                 suggestions={suggestions}
                 onSuggestionsFetchRequested={suggestionsFetchRequested}
                 onSuggestionsClearRequested={suggestionsClearRequested}
                 getSuggestionValue={suggestion => suggestion}
                 renderSuggestion={suggestion => suggestion}
                 inputProps={inputProps}/>
  </>;
}
