<div class="filter-row">
  <div class="filter-left">
    <search-type typ="de" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>
    <search-type typ="cn" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>

    <button class="btn btn-info btn-filter" type="button" (click)="badge('2')" [ngClass]="{active: q('2')}">
      Auftrag <span class="badge">{{ list.badges.order }}</span>
    </button>
    <button class="btn btn-primary btn-filter" type="button" (click)="badge('3')" [ngClass]="{active: q('3')}">
      Bestätigt <span class="badge">{{ list.badges.approved }}</span>
    </button>
    <button class="btn btn-warning btn-filter" type="button" (click)="badge('4')" [ngClass]="{active: q('4')}">
      Geliefert <span class="badge">{{ list.badges.delivered }}</span>
    </button>
    <button class="btn btn-success btn-filter" type="button" (click)="badge('5')" [ngClass]="{active: q('5')}">
      Abgerechnet <span class="badge">{{ list.badges.invoiced }}</span>
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('100')" [ngClass]="{active: q('100')}">
      Storniert
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('101')" [ngClass]="{active: q('101')}">
      Gelöscht
    </button>
    <button class="btn btn-danger btn-sm" type="button" (click)="badge('noprice')" [ngClass]="{active: q('noprice')}">
      Prüfung ausblenden
    </button>

    <button class="btn btn-purple btn-sm" type="button" (click)="badge('kanban')" [ngClass]="{active: q('kanban')}">
      Kanban <span class="badge"></span>
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('all')" [ngClass]="{active: q('all')}">
      Alle
    </button>

    &nbsp;

    <button class="btn btn-default btn-sm"
            type="button"
            [ngClass]="{active: q2.has_release?.toString() === 'true'}"
            (click)="badgeToggle('has_release', true)">
      Mit Freigabe
    </button>

    &nbsp;

    <ev-file-button [url]="downloadUrl('/workflow/v1/workflow/export/')" [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>
  </div>

  <div class="filter-right">
    <date-range [start]="null" [end]="null" (update)="updateDateRange($event)" class="float-right"></date-range>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'id', 'ASC')" [href]="sortUrl('id', 'ASC')">
          Vorgang
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'status', 'DESC')" [href]="sortUrl('status', 'DESC')">
          Status
        </a>
      </th>
      <th></th>
      <th>
        Abruf
      </th>
      <th class="align-right">
        <a (click)="sort($event, 'quanity', 'DESC')" [href]="sortUrl('quanity', 'DESC')">
          Stückzahl
        </a>
      </th>
      <th class="align-right">
        <a (click)="sort($event, 'frame_release', 'DESC')"
           [href]="sortUrl('frame_release', 'DESC')">
          Freigabe
        </a>
      </th>
      <th class="align-right">Einzel</th>
      <th>
        <a (click)="sort($event, 'date', 'ASC')" [href]="sortUrl('date', 'ASC')">
          Datum
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">
          SDS-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'oa_nr', 'ASC')" [href]="sortUrl('oa_nr', 'ASC')">
          0A-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_nr', 'ASC')" [href]="sortUrl('kd_art_nr', 'ASC')">
          Kd-Art-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'additional_order_nr', 'ASC')" [href]="sortUrl('additional_order_nr', 'ASC')">
          Kd-Bestell-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'ASC')"
           [href]="sortUrl('customer_name', 'ASC')">
          Kunde
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_field_service', 'ASC')" [href]="sortUrl('customer_field_service', 'ASC')">
          Vertriebsgebiet
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'username', 'ASC')" [href]="sortUrl('username', 'ASC')">
          Ersteller
        </a>
      </th>
      <th></th>
    </tr>

    <tr class="filterrow">
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.sid" name="sid" [autofocus] autocomplete="off search-workflow-id">
      </th>
      <th colspan="3">&nbsp;</th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.quanity" name="quanity">
      </th>
      <th colspan="3">&nbsp;</th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.sds_nr" name="sds_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.oa_nr" name="oa_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.kd_art_nr" name="kd_art_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.additional_order_nr" name="additional_order_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_name" name="customer_name">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_field_service"
               name="customer_field_service">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.username" name="username">
      </th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let object of list.objects; let index = index"
        [ngClass]="{'alert-danger': !object.approval, 'selectedgray': object.state === 4}">
      <td>
        <a uiSref="a.workflow.detail" [uiParams]="{id: object.id}">
          <img *ngIf="object.typ === 'cn'" src="/ui/assets/images/flags/cn.png">
          <img *ngIf="object.typ === 'de'" src="/ui/assets/images/flags/de.png">
          {{ object.id }}
          <span class="label label-danger" *ngIf="!object.approval">Prüfung</span>
          <span *ngIf="object.comments > 0" class="td-comments" title="Kommentar(e) vorhanden">
            <i class="fa fa-comments"></i>
          </span>
          <span class="label label-danger" *ngIf="object.kanban">Kanban</span>
        </a>
      </td>
      <td>
        <workflow-state type="workflow_status" [key]="object.status"></workflow-state>
      </td>
      <td>
        <ng-container *ngIf="object.express_service">
          <span class="fa fa-stack subscript right" ngbTooltip="Eildienst" style="display: inline-block">
            <i class="fa fa-stack-1x fa-exclamation-triangle"></i>
            <i class="fa fa-stack-2x fa-truck" style="font-size: 12px;"></i>
          </span>
        </ng-container>
        <ng-container *ngIf="object.fix_date">
          <span class="fa fa-stack subscript right" ngbTooltip="Fix" style="display: inline-block">
            <i class="fa fa-stack-1x fa-exclamation-triangle"></i>
            <i class="fa fa-stack-2x fa-calendar" style="font-size: 12px;"></i>
          </span>
        </ng-container>
        <ng-container *ngIf="(object.frame_release ?? null) !== null">
          <span class="fa fa-stack subscript right" ngbTooltip="Freigabe" style="display: inline-block">
            <i class="fa fa-stack-1x fa-exclamation-triangle"></i>
            <i class="fa fa-stack-2x fa-stop-circle" style="font-size: 12px;"></i>
          </span>
        </ng-container>
      </td>
      <td>{{ object.current }}&nbsp;von&nbsp;{{ object.fetches }}</td>
      <td class="align-right">{{ object.quanity|money }}</td>
      <td class="align-right">{{ object.frame_release|money }}</td>
      <td class="align-right">{{ object.frame_single }}&nbsp;&euro;</td>
      <td>{{ object.date }}</td>
      <td>
        <strong>{{ object.sds_nr }}</strong>
        <workflow-article-state [articleState]="object.article_state_change"></workflow-article-state>
      </td>
      <td>{{ object.oa_nr }}</td>
      <td>
        {{ object.kd_art_nr }}
        <span *ngIf="object.kd_art_index" class="label label-default">
          {{ object.kd_art_index }}
        </span>
      </td>
      <td>{{ object.additional_order_nr }}</td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}" target="_blank">
          {{ object.customer_name }}
        </a>
      </td>
      <td>{{object.customer_field_service}}</td>
      <td>{{ object.username }}</td>
      <td>
        <a uiSref="a.price.check" [uiParams]="{id:object.oa_nr}" *ngIf="('price.detail'|HasPerm)">
          Prüfung
        </a>
      </td>

    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
