import {Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {IndustrialSector} from '../../../industrial-sector/producer.models';
import {IndustrialSectorListService} from '../../../../lazy/config/industrial-sector/industrial-sector-list.service';
import {debounceTime, map} from 'rxjs/operators';
import {MultiSelectConfig} from '../../../../common/multi-select-config';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from '../../../../common/multiselect/types';

@Component({
  selector: 'customer-list-industrial-sector-select',
  templateUrl: './customer-list-industrial-sector-select.component.html',
})
export class CustomerListIndustrialSectorSelectComponent implements OnInit, OnChanges {
  @Input() selected: string;
  @Output() changeSector = new EventEmitter<string | null>();
  loadingWrapper: LoadingWrapper<IndustrialSector[]>;

  options: IMultiSelectOption[] = [];
  settings: IMultiSelectSettings = MultiSelectConfig.settings;
  texts: IMultiSelectTexts = MultiSelectConfig.texts;

  form: UntypedFormGroup;

  constructor(private listService: IndustrialSectorListService,
              private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.settings.dynamicTitleMaxItems = 1;
    this.loadingWrapper = new LoadingWrapper<IndustrialSector[]>(
      this.listService.list({take: 200, sort: 'name'}).pipe(map(v => v.objects))
    );

    this.form = this.fb.group({tags: [this.selected?.split(',') ?? []]});

    this.form.get('tags').valueChanges
      .pipe(debounceTime(550))
      .subscribe(selectedOptions => {
        const output = selectedOptions.join(',');
        this.changeSector.emit(output !== '' ? output : null);
      });

    this.loadingWrapper.data$.subscribe(industrialSectors => {
      industrialSectors.map(p => this.options.push({id: p.name, name: p.name}));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected && !changes.selected.isFirstChange()) {
      this.form.patchValue({ tags: this.selected?.split(',') ?? [] });
    }
  }

}
