<div *ngIf="group.article_list.length > 0" [formGroup]="form">
  <div class="row">
    <div class="col-md-12">&nbsp;</div>
  </div>

  <h4>Bestell Positionen für Gruppe: <strong>{{group.group.group_id}}. {{group.group.name}}</strong></h4>

  <table class="table"
         formArrayName="positions"
         *ngIf="materialFieldData.data$ | async as fields; else loadingOrError.template">
    <thead>
    <tr>
      <th>Status</th>
      <th class="align-right">Pos.</th>
      <th class="align-right">Lief.</th>
      <th>Interne Nr.</th>
      <th>Art. Nr. Lief.</th>
      <th>Bezeichnung</th>
      <th *ngFor="let field of fields">{{ field.name }}</th>
      <th>Liefer-Termin (ETD)</th>
      <th class="align-right">Bestellt</th>
      <th style="width: 100px;" class="align-right" *ngIf="('material.order.price.check'|HasPerm)">Preis</th>
      <th style="width:  10px;" *ngIf="('material.order.price.check'|HasPerm)"></th>
      <th style="width: 200px;">Charge</th>
      <th style="width: 100px;">Lagerplatz</th>
      <th style="width: 110px;" class="align-right">Gelief. Menge</th>
      <th></th>
      <th class="icon"></th>
      <th class="icon"></th>
      <th class="icon"></th>
      <th class="icon" *ngIf="('material.order.price.check'|HasPerm)"></th>
      <th class="icon"></th>
    </tr>
    </thead>
    <tbody [material-order-intake-booked-position]="positionForm"
           [material-order-intake-booked-position-index-g]="indexG"
           [material-order-intake-booked-position-index-p]="iP"
           [material-order-intake-booked-position-finder-supplier-uri]="finderSupplierUri"
           [material-order-intake-booked-position-fields]="fields"
           [material-order-intake-booked-position-group-permits]="groupPermission"
           [material-order-intake-booked-position-marked-variation-id]="markedVariationId"
           [material-order-intake-booked-position-marked-transaction-id]="markedTransactionId"
           [material-order-intake-booked-position-form]="positionForm.form"
           [material-order-intake-booked-position-errors]="errors"
           (material-order-intake-booked-position-update-order)="updateOrder.emit()"
           *ngFor="let positionForm of formArrayMapped; trackBy:trackBy; let iP = index">
    </tbody>
  </table>
  <loading-or-error
    #loadingOrError
    [loadingWrapper]="materialFieldData"
    [errorMessage]="'Konnte Material Felder nicht laden'"
    [spinnerSize]="2"
    spinnerName="circle-o-notch">
  </loading-or-error>
</div>
