import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {
  MaterialOrderIntakeGroupElement,
  MaterialOrderPositionTransactionIndices,
  MaterialOrderTransaction,
} from '../material-order.model';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {MaterialValueType} from '../../material-management.model';
import {MaterialManagementService} from '../../material-management.service';
import {noop} from '../../../../helper/noop';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialGroup} from '../../groups/material-group.model';

@Component({
  selector: 'material-order-intake-group',
  templateUrl: './material-order-intake-group.component.html'
})
export class MaterialOrderIntakeGroupComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() errors: { [key: string]: any; };
  @Input() group: MaterialOrderIntakeGroupElement;
  @Input() groups: Lister<MaterialGroup>;
  @Input() gIndex: number;
  @Input() markedVariationId?: number;
  @Input() markedPositionId?: number;
  @Input() markedTransactionId?: number;
  @Output() addTransaction = new EventEmitter<MaterialOrderPositionTransactionIndices>();
  @Output() removeTransaction = new EventEmitter<MaterialOrderPositionTransactionIndices>();
  @Output() updateOrder = new EventEmitter<void>();

  materialFieldData: LoadingWrapper<MaterialValueType[]>;

  groupPermission: boolean;

  constructor(private mms: MaterialManagementService) {
  }

  ngOnInit(): void {
    this.materialFieldData = new LoadingWrapper<MaterialValueType[]>(this.mms.listFormFieldClasses(this.group.group.id));
    this.groupPermission = !!this.groups.objects.find(mg => mg.id === this.group.group.id);
  }

  get positionFormGroups() {
    const formArray = this.form.get('positions') as UntypedFormArray;
    return formArray.controls.map(group => group as UntypedFormGroup);
  }

  trackByFnControl(index: number, item: AbstractControl): number {
    noop(this);
    return index;
  }

  addTransactionPosition(index: number, position: MaterialOrderPositionTransactionIndices) {
    position.p_index = index;
    this.addTransaction.emit(position);
  }
}
