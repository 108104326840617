import * as React from 'react';
import {ReactNode} from 'react';

interface Props {
  children?: ReactNode;
  rows?: number;
  cols?: number;
}

export function ArticleMultilayerPlanCycleColumn(props: Props) {
  return <td className={'height-fix'}
             style={{'width': '34px'}}
             rowSpan={props.rows ?? 1}
             colSpan={props.cols ?? 1}>
    {props.children}
  </td>;
}
