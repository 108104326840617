/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {debug} from '../../helper/debug.func';
import Helper from '../../helper/helper';

@Injectable({providedIn: 'root'})
export class LocalStorage {
  set(key: string, value: string): void {
    window.localStorage[key] = value;
  }

  put(key: string, value: string): void {
    this.set(key, value);
  }

  get(key: string, defaultValue?: string): string {
    return window.localStorage[key] || defaultValue;
  }

  remove(key: string): void {
    window.localStorage.removeItem(key);
  }

  setObject(key: string, value: any): void {
    const data = JSON.stringify(value);
    debug('Data Length:', data.length);
    window.localStorage[key] = data;
  }

  putObject(key: string, value: any): void {
    this.setObject(key, value);
  }

  getObject<T>(key: string): T {
    const parsed = JSON.parse(window.localStorage[key] || '{}');
    if (Helper.isDefined(parsed)) {
      return parsed;
    }
    return undefined;
  }

  keys(): string[] {
    const keys: string[] = [];
    for (const key in window.localStorage) {
      keys.push(key);
    }
    return keys;
  }
}
