<customer-header-template [customer]="customer"></customer-header-template>

<form class="form-inline" [formGroup]="form" (ngSubmit)="filter()">
  <div class="row">
    <div class="col-md-2">
      Zahlungsbedingungen <img src="/ui/assets/images/flags/de.png" title="DE">: {{ termDe }}
    </div>
    <div class="col-md-2">
      Zahlungsbedingungen <img src="/ui/assets/images/flags/cn.png" title="CN">: {{ termCn }}
    </div>
    <div class="col-md-2">
      <!-- Global Opos Date -->

      <div class="form-group">
        <label>Globales Buchungsdatum:</label>
        <ev-datepicker formControlName="global_opos_date"
                    class="form-control input-sm customer-opos-date"
                    [allDates]="true">
        </ev-datepicker>
      </div>

    </div>
    <div class="col-md-2">
      Summe Betrag: <span class="bold">{{ totalRevenue }} €</span>
    </div>
    <div class="col-md-2">
      Summe Offen: <span class="bold">{{ totalOpen }} €</span>
    </div>
    <div class="col-md-2">
      Summe Gebucht (Sitzung): <span class="bold">{{ totalExpensedNow }} €</span>
    </div>
  </div>

  <div class="row" style="margin: 15px -15px;">
    <div class="col-md-1">
      <button type="submit" class="btn btn-default btn-sm">
        <label for="invoices">RG-Nr. Filter anwenden</label>
      </button>
    </div>
    <div class="col-md-11">
      <input type="text" id="invoices" class="form-control invoices" formControlName="invoices">
    </div>
  </div>
</form>


<div class="row">
  <div class="col-lg-12">

    <customer-opos-invoice-table [objects]="opos.objects"
                                 [form]="listForm"
                                 [totalRevenue]="opos.total_revenue"
                                 [totalOpen]="opos.total_open"
                                 [totalExpensed]="opos.total_expensed"
                                 [errors]="errors"
                                 (focuser)="focus($event)"
                                 (updateTotals)="updateTotals($event.name, $event.open, $event.expensed)">
    </customer-opos-invoice-table>

    <customer-opos-correction-table [objects]="opos.corrections"
                                    [form]="listForm"
                                    [totalRevenue]="opos.correction_total_revenue"
                                    [totalOpen]="opos.correction_total_open"
                                    [totalExpensed]="opos.correction_total_expensed"
                                    [errors]="errors"
                                    (focuser)="focus($event)"
                                    (updateTotals)="updateTotals($event.name, $event.open, $event.expensed)">
    </customer-opos-correction-table>
  </div>

  <div class="col-lg-12">
    <button class="btn btn-success pull-left" (click)="save()" [disabled]="!isValid()">
      Buchungen speichern
    </button>
    <span *ngIf="!globalOposDate">
      Rechnungen können nicht verbucht werden, da das globale Buchungsdatum nicht gesetzt wurde.
    </span>
  </div>
</div>
