<div class="article-fixed-head">
  <article-top-navigation [article]="article"
                          [workflowId]="workflow_id"
                          [multilayerPlan]="multilayerPlan">
  </article-top-navigation>
</div>

<finder-upload [category]="category"
               [initial]="{article_sds_nr: article.sds_nr}"
               searchType="article"
               [id]="article.oa_nr">
</finder-upload>
