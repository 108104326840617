import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ArticleSpecification} from '../../models/article-specification.model';
import {debounceTime} from 'rxjs/operators';
import {ArticleInputMarkerDirective} from '../form/article-input-marker.directive';

@Component({
  selector: 'article-hdi-form',
  templateUrl: './article-hdi-form.component.html'
})
export class ArticleHdiFormComponent
  extends ArticleInputMarkerDirective
  implements OnInit, OnDestroy, OnChanges {
  @Input() errors: { [key: string]: any; } = {};
  @Input() form: UntypedFormGroup;
  @Input() refCount = 0;
  @Input() specification: { [key: string]: ArticleSpecification };

  sbuLayerSubscription?: Subscription = null;
  cappingCoreSubscription?: Subscription = null;
  cappingSubscription?: Subscription = null;
  hdiVisible = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.hdiVisible = this.form.value.sbu_layers > 0;
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.unSubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && !changes.form.isFirstChange()) {
      this.unSubscribe();
      this.subscribe();
    }
  }

  unSubscribe(): void {
    this.sbuLayerSubscription?.unsubscribe();
    this.cappingCoreSubscription?.unsubscribe();
    this.cappingSubscription?.unsubscribe();
  }

  subscribe(): void {
    this.sbuLayerSubscription = this.form.get('sbu_layers').valueChanges
      .pipe(debounceTime(275))
      .subscribe(sbu_layers => {
      this.hdiVisible = sbu_layers > 0;
      this.form.patchValue({
        cu_filling: sbu_layers >= 1 || this.form.value.cu_filling,
        cu_filling_core: sbu_layers > 1 || this.form.value.cu_filling_core,
      });
    });

    this.cappingCoreSubscription = this.form.get('capping').valueChanges.subscribe(capping_core => {
      if (capping_core === true) {
        this.form.patchValue({'via_plugging': true});
      }
    });

    this.cappingSubscription = this.form.get('capping_core').valueChanges.subscribe(capping => {
      if (capping === true) {
        this.form.patchValue({'plugging_core': true});
      }
    });
  }
}
