import {Injectable} from '@angular/core';
import {WorkflowService} from '../workflow-services/workflow.service';
import {Workflow} from '../models/workflow.models';
import {WorkflowPositionView} from '../models/position.models';
import {StateService} from '@uirouter/angular';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ExtendedFrame} from '../models/frame.models';

@Injectable({
  providedIn: 'root'
})
export class CreateTaskCallerService {

  constructor(private workflowService: WorkflowService,
              private stateService: StateService) {
  }

  next(workflow: Workflow,
       position: WorkflowPositionView,
       typ: 'de' | 'cn',
       history: boolean | null = null): Observable<ExtendedFrame> {
    return this.workflowService.next(workflow.id, {position_id: position.id, typ: typ}, history).pipe(
      tap(val => {
        this.stateService.go('a.workflow.detail', {id: workflow.id});
      }),
    );
  }
}
