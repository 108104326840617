<div>
  <h3>Auftragsübersicht</h3>

  <div class="contract-history-box">
    <table class="table whitetable" *ngIf="orderMapData.data$|async as orderMap; else loadingOrError.template">
      <tbody>

      <tr *ngFor="let orderType of orderTypeList">
        <td>{{ orderType.name }}</td>
        <td class="right"></td>
        <td class="right"></td>
        <td class="right">
          <ng-container *ngFor="let order of orderMap[orderType.type]; let i = index">
            <button class="btn btn-sm"
                    style="margin-left: 5px;"
                    [title]="'Übersicht für Bestellung ' + order.id + ' anzeigen'"
                    [ngClass]="orderType.clazz"
                    (click)="open(order.id, order.positions.index)">

              <cn-order-delivery-method [size17]="true" [deliveryMethod]="order.delivery_method">
              </cn-order-delivery-method>

              {{ order.id }}-{{ order.positions.index + 1 }}
              <ng-container *ngIf="order.quantity">({{ order.quantity|money:0 }})</ng-container>
            </button>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>

    <loading-or-error
        #loadingOrError
        [loadingWrapper]="orderMapData"
        [errorMessage]="'Bestellungen konnten nicht geladen werden'">
    </loading-or-error>

  </div>
</div>
