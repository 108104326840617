import * as React from 'react';
import {Money} from '../../common/money/Money';

interface Props {
  value: number;
  decimals?: number;
  unit?: string;
}
export function EvFormattedNumber({value, decimals = 0, unit}: Props): JSX.Element {
  return <>{(Money.stringify(value, decimals) + (!unit?'':`\u00A0${unit}`))}</>;
}
