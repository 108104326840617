<div class="filter-row">
  <div class="filter-left">

    <button class="btn btn-default btn-sm" type="button" (click)="badge('all')" [ngClass]="{active: q('all')}">
      Alle
    </button>

    <button class="btn btn-success btn-sm" type="button" (click)="badge('0')" [ngClass]="{active: q('0')}">
      30-36 Wochen <span class="badge">{{ list.badges.one }}</span>
    </button>

    <button class="btn btn-primary btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
      37-45 Wochen <span class="badge">{{ list.badges.two }}</span>
    </button>

    <button class="btn btn-warning btn-sm" type="button" (click)="badge('2')" [ngClass]="{active: q('2')}">
      46-52 Wochen <span class="badge">{{ list.badges.three }}</span>
    </button>

    <button class="btn btn-danger btn-sm" type="button" (click)="badge('3')" [ngClass]="{active: q('3')}">
      &Auml;lter <span class="badge">{{ list.badges.cfa }}</span>
    </button>

    <ev-file-button [url]="downloadUrl('/api/v1/inventory/export/')" [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>

  </div>
  <div class="filter-right">
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">
          SDS-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'oa_nr', 'ASC')" [href]="sortUrl('oa_nr', 'ASC')">
          0A-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_nr', 'ASC')" [href]="sortUrl('kd_art_nr', 'ASC')">
          Kunden-Art-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'surface', 'ASC')" [href]="sortUrl('surface', 'ASC')">
          Oberfl&auml;che
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer', 'ASC')" [href]="sortUrl('customer', 'ASC')">
          Kunde
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'date_code', 'ASC')" [href]="sortUrl('date_code', 'ASC')">
          Datecode
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'storage_bin_name', 'ASC')" [href]="sortUrl('storage_bin_name', 'ASC')">
          Lager
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'storage_bin_number', 'ASC')" [href]="sortUrl('storage_bin_number', 'ASC')">
          Lagerplatz
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'quantity', 'ASC')" [href]="sortUrl('quantity', 'ASC')">
          Menge
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'last_date', 'ASC')" [href]="sortUrl('last_date', 'ASC')">
          Letzte Änderung
        </a>
      </th>
      <th>&nbsp;</th>
    </tr>
    <tr class="filterrow">
      <th>
        <input [autofocus]="true" type="text" class="form-control input-sm" name="sds_nr" [(ngModel)]="data.sds_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="oa_nr" [(ngModel)]="data.oa_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="kd_art_nr" [(ngModel)]="data.kd_art_nr">
      </th>
      <th>
        <select id="ba-surface" class="form-control input-sm"
                name="surface"
                [(ngModel)]="data.surface">
          <option [ngValue]="null">-</option>
          <option *ngFor="let obj of specificationData;" [ngValue]="obj.value">{{ obj.key }}</option>
        </select>
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="customer" [(ngModel)]="data.customer">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="date_code" [(ngModel)]="data.date_code">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="storage_bin_name" [(ngModel)]="data.storage_bin_name">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="storage_bin_number" [(ngModel)]="data.storage_bin_number">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="quantity" [(ngModel)]="data.quantity">
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let object of list.objects">
      <td>{{ object.sds_nr }}
      <td>{{ object.oa_nr }}</td>
      <td>{{ object.kd_art_nr }}</td>
      <td>{{object.surface}}</td>
      <td>{{ object.customer }}</td>
      <td>{{ object.date_code }}</td>
      <td>{{ object.storage_bin_name }}</td>
      <td>{{ object.storage_bin_number }}</td>
      <td>{{ object.quantity }}</td>
      <td>{{ object.last_date|date: "dd.MM.yyyy" }}</td>
      <td>
        <button type="button" class="ev-button" (click)="openInventory(object)">
          <i class="fa fa-cubes"></i>
        </button>
      </td>

    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
