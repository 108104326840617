import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DismissButtonComponent} from '../../../../core/dismiss-button/dismiss-button.component';
import {debug} from '../../../../helper/debug.func';

@Component({
  selector: 'delivery-create-modal-form-weight',
  templateUrl: './delivery-create-modal-form-weight.component.html',
})
export class DeliveryCreateModalFormWeightComponent extends DismissButtonComponent implements OnInit {
  @Input() labelCount: number;
  @Output() next = new EventEmitter<string[]>();
  form: UntypedFormGroup;
  disabled = false;
  errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  get weights() {
    return this.form.get('weights') as UntypedFormArray;
  }

  ngOnInit() {
    this.form = this.fb.group({
      'weights': this.fb.array(this.createFormArray())
    });
  }

  nextButton(): void {
    this.next.emit(this.form.value.weights);
  }

  private createFormArray(): UntypedFormControl[] {
    const formControlArray: UntypedFormControl[] = [];
    for (let i = 0; i < this.labelCount; i++) {
      formControlArray.push(this.fb.control('', Validators.required));
    }
    debug('DeliveryCreateModalFormWeightComponent, Label Count:', this.labelCount, formControlArray);
    return formControlArray;
  }

}
