/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject} from '../../../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BusinessOrderGoodPieceService {

  private url = '/api/v1/workflow/business/order/update/goodpiece/';

  constructor(private http: HttpClient) {}

  update(baId: number, index: number, goodPiece: number, comment: string): Observable<string> {
    return this.http.put<EnvisiaObject<string>>(
      this.url + baId + '/' + index + '/',
      {good_piece: goodPiece, comment: comment}
    ).pipe(map(value => value.object));
  }

}
