<div class="alert alert-danger" *ngIf="invalidML">
  <strong>Achtung!</strong>
  <ng-container [ngSwitch]="errorMl">
    <ng-container *ngSwitchCase="'ml.missing'">
      Der Multilayeraufbauplan ist vom Artikel vorgegeben, wurde aber nicht erstellt.
    </ng-container>
    <ng-container *ngSwitchCase="'ml.differs'">
      Die Werte im Multilayeraufbauplan weichen vom Artikel ab.
    </ng-container>
    <ng-container *ngSwitchDefault>
      Bitte Multilayerplan überprüfen.
    </ng-container>
  </ng-container>
</div>
