import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleBaseMaterialFormWithKey} from '../../../envisia/article/models/article-specification.model';
import {ArticleSpecificationService} from '../../../envisia/article/services/article-specification.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'article-config-main-update',
  templateUrl: './article-config-main-update.component.html'
})
export class ArticleConfigMainUpdateComponent implements OnInit {
  data: ArticleBaseMaterialFormWithKey;
  errors?: { [key: string]: string; } = {};

  constructor(private articleSpecificationService: ArticleSpecificationService,
              private alertService: AlertService,
              private modalRef: NgbActiveModal) {
  }

  ngOnInit(): void {
    // we set it as a default value if it is not there: https://git.envisia.io/erp/loki/issues/1576
    if (this.data.data.de_compliant === null || this.data.data.de_compliant === undefined) {
      this.data.data.de_compliant = true;
    }
  }

  save() {
    this.articleSpecificationService.saveBaseMaterial({
      name: this.data.key,
      data: this.data.data,
    }).subscribe(data => {
      this.alertService.add('success', this.data.key + ' erfolgreich geändert!');
      this.modalRef.close(data);
    }, (response) => {
      console.log(response);
      if (response.error) {
        this.errors = response.error;
        console.log(this.errors);
      } else {
        this.alertService.add('danger', 'Fehler bei der Änderung!');
      }
    });
  }

}
