/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {InventoryService} from './inventory.service';
import {InventoryListComponent} from './inventory-list.component';
import {getArticleSpecificationResolveFn} from '../article.states';
import {ArticleSpecificationService} from '../services/article-specification.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {ErpTransition} from '../../../core/erp-transition.service';

export const INVENTORY_STATE: Ng2StateDeclaration = {
  name: 'a.inventory',
  abstract: true,
  data: {requiresAuth: true}
};

export function inventoryListResolveFn(transition: ErpTransition, service: InventoryService) {
  return service.list(transition.params()).toPromise();
}

export const INVENTORY_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.inventory.list',
  url: '/workflow/inventory/list?page&sort&order&sds_nr&oa_nr&kd_art_nr&{customer:any}&date_code&storage_bin_name&storage_bin_number&quantity&status&{surface:any}',
  views: {'main@a': {component: InventoryListComponent}},
  resolve: [
    {token: 'listData', resolveFn: inventoryListResolveFn, deps: [ErpTransition, InventoryService]},
    {token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]}
  ],
  params: {
    page: {dynamic: true},
    sort: {dynamic: true},
    order: {dynamic: true},
    sds_nr: {dynamic: true},
    oa_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    customer: {dynamic: true, raw: true},
    date_code: {dynamic: true},
    storage_bin_name: {dynamic: true},
    storage_bin_number: {dynamic: true},
    quantity: {dynamic: true},
    status: {dynamic: true},
    surface: {dynamic: true, raw: true}
  }
};

export const INVENTORY_STATES: Ng2StateDeclaration[] = [
  INVENTORY_STATE,
  INVENTORY_LIST_STATE
];
