<div [ngClass]="{whitebox: whitebox}" *ngIf="articleOverview.data$ | async as overview; else loadingOrError.template">
  <h3>Artikel Bestands-Übersicht</h3>
  <table class="table whitetable">
    <tbody>
    <tr class="whi">
      <td>Bestätigte Aufträge:</td>
      <td class="align-right">{{overview.required | money}}</td>
    </tr>
    <tr>
      <td>Aktueller Lagerbestand:</td>
      <td class="align-right">{{overview.inventory | money}}</td>
    </tr>
    <tr>
      <td>In CN bestellte Menge:</td>
      <td class="align-right">{{overview.ordered | money}}</td>
    </tr>
    <tr>
      <td><strong>Summe:</strong></td>
      <td class="align-right"><strong>{{overview.total | money}}</strong></td>
    </tr>
    </tbody>
  </table>
</div>

<loading-or-error #loadingOrError
                  [loadingWrapper]="articleOverview"
                  [errorMessage]="'Konnte Artikel Bestands-Übersicht nicht laden'"
                  [spinnerSize]="2"
                  spinnerName="circle-o-notch">
</loading-or-error>
