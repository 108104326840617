import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {ArticleService} from '../services/article.service';
import {ArticleListElement} from '../models/article.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Lister} from '../../../common/wrapper.models';
import {InventoryModalService} from '../inventory/inventory.modal.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'article-list',
  templateUrl: './article-list.component.html',
})
export class ArticleListComponent extends SearchBaseComponent<ArticleService, ArticleListElement> {
  @Input() protected listData: Lister<ArticleListElement>;
  protected type = 'article';

  constructor(protected service: ArticleService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private inventoryModalService: InventoryModalService) {
    super();
  }

  query(any?: any): any {
    if (any) {
      this.cachedQuery = any;
      this.locationService.search(any);
    } else {
      if (!this.cachedQuery) {
        this.cachedQuery = this.locationService.search();
        this.cachedQuery['order'] = ((this.cachedQuery['order'] as string)?.startsWith('DESC') === true) ? 'DESC' : 'ASC';
      }
    }

    return this.cachedQuery;
  }

  openInventory(obj: ArticleListElement): void {
    // we can safely ignore any modal callbacks since we do not need to pass an emitter
    this.inventoryModalService.openInventory(obj.oa_nr).subscribe(() => {
    }, () => {
    });
  }

  getParams(long: boolean): string {
    return EnvisiaLocation.httpParams({...(this.query() as {}), allFields: long}).toString();
  }

  get downloadShort(): string {
    return `${environment.apiv4uri}article/list/export?${this.getParams(false)}`;
  }

  get downloadLong(): string {
    return `${environment.apiv4uri}article/list/export?${this.getParams(true)}`;
  }

}
