import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {createNumberValidator} from '../order-request-main-request-form/request-form.builds';
import {OrderItemForm} from '../../order-request.models';
import Helper from '../../../../../helper/helper';

function xor(b1: boolean, b2: boolean): boolean {
  const n1 = b1 ? 1 : 0;
  const n2 = b2 ? 1 : 0;

  return (n1 ^ n2) === 1;
}

export function orderArrayFilter(value: OrderItemForm): boolean {
  return !(Helper.undefined(value.quanity) &&
    Helper.undefined(value.price) &&
    Helper.undefined(value.delivery_date) &&
    !value.call_of);
}

export function orderInnerForm(fb: UntypedFormBuilder): UntypedFormGroup {
  const filledValidator = (control: AbstractControl) => {
    const values = control.value as OrderItemForm;
    const filled = orderArrayFilter(values);
    if (!filled) {
      return null;
    }

    const allValuesProvided = xor(values.call_of, !Helper.undefined(values.delivery_date)) &&
      !Helper.undefined(values.price) &&
      !Helper.undefined(values.quanity);
    return allValuesProvided ? null : {'form': 'invalid'};
  };

  return fb.group({
    'delivery_date': [null],
    'price': [null],
    'call_of': [false, Validators.required],
    'quanity': [null, createNumberValidator(false)],
  }, {validators: filledValidator});
}
