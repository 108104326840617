import {MaterialDateMapping} from './material-planning.model';

export class MaterialPlanningWeekListPaginator {
  static readonly pageElements = 10;
  private _weeks: MaterialDateMapping[];

  private firstVisibleIndex: number | null;

  private _lastDate: string | null | undefined;

  constructor(weeks: MaterialDateMapping[], firstVisibleIndex?: number) {
    this.reset(weeks);
    this.firstVisibleIndex = firstVisibleIndex ?? 0;
  }

  get weeks(): MaterialDateMapping[] {
    return this._weeks;
  }

  get lastDate(): string | null | undefined {
    return this._lastDate;
  }

  get visibleWeekIndices(): number[] {
    const list = [];
    if (this.firstVisibleIndex !== null) {
      for (let i = this.firstVisibleIndex; i < this.firstVisibleIndex + MaterialPlanningWeekListPaginator.pageElements; i++) {
        list.push(i);
      }
    }
    return list;
  }

  // returns true when page is one before the end
  get reloadPages(): boolean {
    return this.firstVisibleIndex > (this._weeks.length - MaterialPlanningWeekListPaginator.pageElements - 2);
  }

  weekScrollPage(pages: number): void {
    if (this.firstVisibleIndex + pages < 0) {
      this.firstVisibleIndex = 0;
    } else if (this.firstVisibleIndex + pages + MaterialPlanningWeekListPaginator.pageElements >= this._weeks.length) {
      this.firstVisibleIndex = this._weeks.length - MaterialPlanningWeekListPaginator.pageElements;
    } else {
      this.firstVisibleIndex += pages;
    }
  }

  addWeeks(weeks: MaterialDateMapping[]): void {
    weeks.forEach(week => {
      this._weeks.push(week);
    });

    this._lastDate = this._weeks[this._weeks.length - 1]?.first_day_of_week;
  }

  reset(weeks: MaterialDateMapping[]): void {
    this._weeks = weeks;
    const hasWeeks = (this._weeks && this._weeks.length > 0);
    this.firstVisibleIndex = hasWeeks ? 0 : null;
    this._lastDate = hasWeeks ? this._weeks[this._weeks.length - 1].first_day_of_week : null;
  }
}
