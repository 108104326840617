import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StateService} from '@uirouter/angular';
import {HttpErrorResponse} from '@angular/common/http';
import {Article, SmallArticle} from '../models/article.models';
import {ShippingInstruction, ShippingInstructionBase} from '../models/article-extra.models';
import {ShippingInstructionService} from '../services/shipping-instruction.service';

@Component({
  selector: 'article-shipping-confirm',
  templateUrl: './article-shipping-confirm.component.html',
})
export class ArticleShippingConfirmComponent implements OnInit {

  @Input() article: Article | SmallArticle;
  @Input() formValues?: ShippingInstructionBase;
  @Input() values: ShippingInstruction;
  errors?: { [key: string]: string; } = {};
  commentForm: UntypedFormGroup;

  constructor(private modalRef: NgbActiveModal,
              private modalService: NgbModal,
              private fb: UntypedFormBuilder,
              private service: ShippingInstructionService,
              private alertService: AlertService,
              private stateService: StateService) {
  }

  ngOnInit(): void {
    this.commentForm = this.fb.group({
      commentText: [null]
    });
  }

  save(): void {
    const finalForm = {
      comment: this.commentForm.value.commentText,
      data: this.formValues
    };
    this.service.save('article', this.article.oa_nr, finalForm).subscribe(data => {
      this.alertService.add('success', 'Erfolgreich gespeichert!');
      this.stateService.go(this.stateService.current, {id: this.article.oa_nr}, {reload: true});
      this.modalRef.close(data.data);
    }, (response: HttpErrorResponse) => {
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte benachrichtigen Sie Ihren Administrator');
      console.log('Error:', response);
    });
  }

  validate(): boolean {
    return !(this.commentForm.value.commentText && this.commentForm.value.commentText.length > 0);
  }

  dismiss() {
    this.modalRef.dismiss();
  }

}
