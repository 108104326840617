import * as React from 'react';
import {ChangeEvent, FormEvent, useState} from 'react';
import {EvReactModal} from '../../../react/modal/EvReactModal';
import {MaterialLabelPrintAxiosService} from './material-label-print-axios.service';
import {NumberHelper} from '../../../helper/number-helper';
import Axios, {AxiosError} from 'axios';
import {MaterialLabelAmountWarning} from './material-label-amount-warning';

interface Props {
  variationId: number;
  classInner?: string;
}

export function MaterialLabelPrintButton(props: Props) {
  const [modalState, modalStateSet] = useState<number>(0);
  const [printQuantity, printQuantitySet] = useState<number>(1);
  const [printBtnLocked, printBtnLockedSet] = useState<boolean>(false);
  const [errors, errorsSet] = useState<{ [key: string]: string }>({});

  const setModalState: (state: number) => void = (state) => {
    printQuantitySet(1);
    modalStateSet(state);
  };

  const changePrintQuantity: (e: ChangeEvent<any>) => void = (e) => {
    printQuantitySet(NumberHelper.saveParseInteger(e.target.value) ?? 1);
  };

  const printSubmit: (event: FormEvent) => void = async (event) => {
    event.preventDefault();

    if (printQuantity > 50 && modalState === 1) {
      modalStateSet(3);
      printQuantitySet(50);
      return;
    }

    if (printQuantity >= 10 && modalState === 1) {
      modalStateSet(2);
      return;
    }

    if (printBtnLocked) {
      return;
    }

    errorsSet({});
    printBtnLockedSet(true);
    try {
      await MaterialLabelPrintAxiosService.print(props.variationId, printQuantity).then(() => {
        printBtnLockedSet(false);
        modalStateSet(0);
      }, (error: Error | AxiosError) => {
        if (Axios.isAxiosError(error)) {
          errorsSet(error.response?.data?.errors ?? {});
        } else {
          console.error(error);
        }
      });
    } finally {
      printBtnLockedSet(false);
    }
  };

  const baseClass = 'fa ' + (!props.classInner ? '' : (props.classInner + ' '));
  const printButtonLabelClass = baseClass + (
    printBtnLocked ?
      'fa-spinner fa-circle-o-notch fa-spin' :
      'fa-print blue'
  );

  return <>
    <button type={'button'}
            className={'btn btn-sm btn-link'}
            title={'Material Etiketten drucken'}
            style={{'padding': '0'}}
            disabled={printBtnLocked}
            onClick={() => setModalState(1)}>
      <i className={printButtonLabelClass}/>
    </button>

    <EvReactModal showModal={modalState > 0}
                  contentLabel='Anzahl Etiketten wählen'
                  modalClosed={() => setModalState(0)}>
      <form onSubmit={printSubmit}>
        <div className='modal-body'>
          { modalState > 1 ?
            <MaterialLabelAmountWarning modalState={modalState} quantity={printQuantity} /> :
            <input type={'number'}
                   id={'quantity'}
                   name={'quantity'}
                   className={'form-control' + (!!errors['quantity'] ? ' red_alert' : '')}
                   value={printQuantity.toString(10)}
                   onChange={changePrintQuantity}/>
          }
        </div>

        <div className={'modal-footer'}>
          <button type={'button'}
                  className={'btn btn-danger float-left'}
                  onClick={() => setModalState(modalState === 1 ? 0 : 1)}>
            Abbrechen
          </button>
          <button type={'submit'}
                  className={'btn btn-success float-right'}
                  disabled={printBtnLocked}>
            Ok
          </button>
        </div>
      </form>
    </EvReactModal>
  </>;
}
