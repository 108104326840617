import {Component, Input} from '@angular/core';
import {ArticleV2Service, sdsType} from '../../services/article2.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleService} from '../../services/article.service';

@Component({
  selector: 'article-sds-modal',
  templateUrl: './article-sds-modal.component.html',
})
export class ArticleSdsModalComponent {
  @Input() create: boolean;
  // update
  @Input() oaNr?: string;
  // create
  @Input() data?: any;
  @Input() customer: string;

  disabled = false;

  constructor(private articleService: ArticleService,
              private articleV2Service: ArticleV2Service,
              private alertService: AlertService,
              private modalRef: NgbActiveModal) {
  }

  route(typ: sdsType): void {
    if (!this.disabled) {
      this.disabled = true;
      if (this.create) {
        this._create(typ);
      } else {
        this._update(typ);
      }
    }
  }

  _create(typ: sdsType): void {
    const sendData = {data: this.data, customer: this.customer};
    this.articleService.create(typ, sendData).subscribe(success => {
      this.disabled = false;
      this.modalRef.close(success);
    }, response => {
      this.disabled = false;
      this.modalRef.dismiss(response);
    });
  }

  _update(typ: sdsType): void {
    this.articleV2Service.generateSds(this.oaNr, typ).subscribe(value => {
      this.alertService.add('success', 'SDS-Nr. erfolgreich generiert');
      this.disabled = false;
      this.modalRef.close(value);
    }, () => {
      this.alertService.add(
        'danger',
        'Es ist ein Fehler aufgetreten, bitte wenden Sie sich an Ihren Administrator!'
      );
      this.disabled = false;
    });
  }

}
