<div class="modal-header">
  <h3>Bestell-Nr. bereits vorhanden</h3>
</div>
<div class="modal-body">
  <p>Die Bestell-Nr. ist bereits in einem anderen Auftrag vorhanden:</p>
  <p *ngFor="let workflowId of workflowIds">
    <a [href]="'#!/workflow/' + workflowId" target="_blank">Vorgang: {{workflowId}}</a>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="dismiss()">Ok</button>
</div>
