import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, from} from 'rxjs';
import {OfferPositionModalComponent} from './offer-position-modal.component';
import {WorkflowPositionView} from '../../models/position.models';


@Injectable({providedIn: 'root'})
export class OfferPositionModalService {

  constructor(private modalService: NgbModal) {
  }

  open(position: any, typ: string): Observable<WorkflowPositionView> {
    const modalRef = this.modalService.open(OfferPositionModalComponent);
    modalRef.componentInstance.typ = typ;
    modalRef.componentInstance.position = position;
    return from(modalRef.result);
  }

}
