import { EventEmitter, Output, Directive } from '@angular/core';

@Directive()
export abstract class DismissButtonComponent {
  @Output() dismiss = new EventEmitter<any>();

  dismissButton(): void {
    this.dismiss.emit(null);
  }
}
