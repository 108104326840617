import {OfferAnalysisQueryParams} from './offer-analysis.model';
import {NumberHelper} from '../../../../helper/number-helper';

export class OfferAnalysisParamHelper {
  static ParamStringKeys = [
    'sid',
    'workflow_id',
    'typ',
    'resubmission_date',
    'sds_nr',
    'oa_nr',
    'kd_art_nr',
    'kd_art_index',
    'customer_name',
    'customer_zip',
    'customer_city',
    'customer_country',
    'customer_field_service',
    'username',
    'execution',
    'field_service',
    'date_start',
    'date_end',
  ];
  static ParamBoolKeys = [
    'has_hdi_type',
    'has_via_handling',
    'has_blind_hole_tech',
    'silk_screen',
    'has_gold',
  ];
  static ParamNumberKeys = [
    'status',
    'workflow_status',
    'rejection_reason_id',
  ];
  static ParamKeys = [
    ...OfferAnalysisParamHelper.ParamStringKeys,
    ...OfferAnalysisParamHelper.ParamBoolKeys,
    ...OfferAnalysisParamHelper.ParamNumberKeys,
  ]

  static clean(query: {}): OfferAnalysisQueryParams {
    return OfferAnalysisParamHelper.ParamKeys
      .filter(k => !!query[k] || query[k] === 0)
      .reduce((accum, k) => {
        accum[k] = query[k];
        return accum;
      }, {} as OfferAnalysisQueryParams);
  }

  static stringifyParams(query: OfferAnalysisQueryParams): { [key: string]: string } {
    return OfferAnalysisParamHelper.ParamKeys
      .filter(k => !!query[k] || query[k] === 0)
      .reduce((accum, k) => {
        accum[k] = query[k]?.toString();
        return accum;
      }, {} as { [key: string]: string });
  }

  static match(a: OfferAnalysisQueryParams, b: OfferAnalysisQueryParams): boolean {
    return OfferAnalysisParamHelper.ParamKeys
      .reduce((accum, k) => accum && a[k]?.toString() === b[k]?.toString(), true);
  }

  static backfill(query: OfferAnalysisQueryParams): OfferAnalysisQueryParams {
    return OfferAnalysisParamHelper.ParamKeys.reduce((accum, k) => {
      accum[k] = query[k] ?? null;
      return accum;
    }, {} as OfferAnalysisQueryParams);
  }

  static parseParameters(query: {}): OfferAnalysisQueryParams {
    return {
      ...OfferAnalysisParamHelper.parseParams(query, OfferAnalysisParamHelper.ParamBoolKeys, p => p?.toString() === 'true'),
      ...OfferAnalysisParamHelper.parseParams(query, OfferAnalysisParamHelper.ParamNumberKeys, NumberHelper.saveParseInteger),
      ...OfferAnalysisParamHelper.parseParams(query, OfferAnalysisParamHelper.ParamStringKeys, p => p?.toString()),
    }
  }

  private static parseParams(query: {}, keys: string[], func: (key: string) => boolean | number | string | null): OfferAnalysisQueryParams {
    return keys
      .filter(k => query[k] !== null && query[k] !== undefined)
      .reduce((accum, k) => {
        const parsedParam = func(query[k]);
        if (parsedParam !== null && parsedParam !== undefined) {
          accum[k] = parsedParam;
        }

        return accum;
      }, {} as OfferAnalysisQueryParams);
  }
}
