import {NgModule} from '@angular/core';
import {ArticleTopNavigationComponent} from './article-top-navigation.component';
import {ArticleTopComponent} from '../main/top/article-top.component';
import {SharedModule} from '../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {ArticleLockModule} from '../main/lock/article-lock.module';
import {ArticleNavigationComponent} from './article-navigation.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ArticleButtonNavigationComponent} from './article-button-navigation.component';
import {ArticleTopSdsInputComponent} from '../main/top/article-top-sds-input.component';
import {ArticleTopChangeStateModalComponent} from '../main/top/article-top-change-state-modal.component';
import {
  ArticleMultilayerDifferenceWarningComponent
} from '../main/multilayer-plan/difference-warning/article-multilayer-difference-warning.component';

@NgModule({
    imports: [
        UIRouterModule.forChild(),
        NgbTooltipModule,
        SharedModule,
        ArticleLockModule,
    ],
    declarations: [
        ArticleButtonNavigationComponent,
        ArticleTopComponent,
        ArticleTopSdsInputComponent,
        ArticleTopNavigationComponent,
        ArticleNavigationComponent,
        ArticleMultilayerDifferenceWarningComponent,
        // Modal
        ArticleTopChangeStateModalComponent
    ],
    exports: [
        ArticleButtonNavigationComponent,
        ArticleTopComponent,
        ArticleTopNavigationComponent,
        ArticleNavigationComponent,
    ]
})
export class ArticleNavigationModule {
}
