import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {Error404Component} from './envisia/error/error404.component';
import {Error500Component} from './envisia/error/error500.component';
import {Error401Component} from './envisia/error/error401.component';
import {Error403Component} from './envisia/error/error403.component';
import {BaseComponent} from './envisia/main/base.component';
import {LocalStorage} from './common/storage/local-storage';
import {User} from './envisia/user/user.models';
import {debug} from './helper/debug.func';
import {BaseDashboardComponent} from './envisia/main/base-dashboard.component';
import {InitData} from './common/init-data/init-data';

export function error403resolveFn(transition: Transition) {
  return transition.params().additional;
}

const ERROR403_RESOLVE = {
  token: 'additional',
  resolveFn: error403resolveFn,
  deps: [Transition]
};

export function homeTransition(transition: Transition) {
  const localStorage = transition.injector().get<LocalStorage>(LocalStorage);
  const user = localStorage.getObject<InitData>('data').user;
  debug('Home Transition: ', user);

  return {state: 'start'};
}

export const MAIN_STATES: Ng2StateDeclaration[] = [
  {name: 'home', redirectTo: homeTransition, data: {requiresAuth: true}},

  {name: 'a', abstract: true, component: BaseComponent, data: {requiresAuth: true}},
  {name: 'start', url: '/', component: BaseComponent, data: {requiresAuth: true}},
  {name: 'd', abstract: true, component: BaseDashboardComponent, data: {requiresAuth: true}},

  {name: 'o', abstract: true, data: {requiresAuth: false}},
  {name: 'o.error401', views: {'!$default': {component: Error401Component}}, data: {requiresAuth: false}},
  {
    name: 'o.error403',
    views: {'!$default': {component: Error403Component}},
    // adds the reason why we were unauthorized
    resolve: [ERROR403_RESOLVE],
    params: {additional: {type: 'string'}},
    data: {requiresAuth: false}
  },
  {name: 'o.error404', views: {'!$default': {component: Error404Component}}, data: {requiresAuth: false}},
  {name: 'o.error500', views: {'!$default': {component: Error500Component}}, data: {requiresAuth: false}},

  {
    name: 'analysis.**',
    url: '/analysis',
    loadChildren: () => import('./lazy/analysis/analysis.module').then(m => m.AnalysisModule),
    data: {requiresAuth: false}
  },
  {
    name: 'analysis_business_order.**',
    url: '/analysis/business/order',
    loadChildren: () => import('./lazy/analysis-business-order/analysis-business-order.module').then(m => m.AnalysisBusinessOrderModule),
    data: {requiresAuth: false}
  },
  {
    name: 'analysis_material.**',
    url: '/analysis/material',
    loadChildren: () => import('./lazy/analysis-material/analysis-material.module').then(m => m.AnalysisMaterialModule),
    data: {requiresAuth: false}
  },
  {
    name: 'article_config.**',
    url: '/article/config',
    loadChildren: () => import('./lazy/article-config/article-config.module').then(m => m.ArticleConfigModule),
    data: {requiresAuth: false}
  },
  {
    name: 'a.dashboard.**',
    url: '/dashboard',
    loadChildren: () => import('./lazy/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {requiresAuth: false}
  },
  {
    name: 'd.cn_order_list.**',
    url: '/cn-order-list',
    loadChildren: () => import('./lazy/cn-order-list/cn-order-list.module').then(m => m.CnOrderListModule),
    data: {requiresAuth: false}
  },
  {
    name: 'a.material_supplier.**',
    url: '/material/supplier',
    loadChildren: () => import('./lazy/material-supplier/material-supplier.module').then(m => m.MaterialSupplierModule),
    data: {requiresAuth: true}
  },
  {
    name: 'a.config.**',
    url: '/configuration',
    loadChildren: () => import('./lazy/config/config.module').then(m => m.ConfigModule),
    data: {requiresAuth: false}
  },
  {
    name: 'a.user.**',
    url: '/user',
    loadChildren: () => import('./lazy/user/user.module').then(m => m.UserModule),
    data: {requiresAuth: false}
  },
  {
    name: 'a.print.**',
    url: '/print',
    loadChildren: () => import('./lazy/print/print.module').then(m => m.PrintModule),
    data: {requiresAuth: false}
  },
  {
    name: 'a.cluster.**',
    url: '/cluster',
    loadChildren: () => import('./lazy/cluster/cluster.module').then(m => m.ClusterModule),
    data: {requiresAuth: false}
  },
  {
    name: 'a.workload.**',
    url: '/workload',
    loadChildren: () => import('./lazy/analysis-workload-timetable/analysis-workload-timetable.module')
      .then(m => m.AnalysisWorkloadTimetableModule),
    data: {requiresAuth: true}
  },
  {
    name: 'a.multilayerplan.**',
    url: '/export',
    loadChildren: () => import('./lazy/multilayer-plan/multilayer-plan.module').then(m => m.MultilayerPlanModule),
    data: {requiresAuth: true}
  },
  {
    name: 'a.export.**',
    url: '/export',
    loadChildren: () => import('./lazy/export/export.module').then(m => m.ExportModule),
    data: {requiresAuth: true}
  },
];
