import {ArticleMultilayerPlan, ArticleMultilayerPlanModel} from './article-multilayer-plan.models';
import {Article} from '../../models/article.models';
import {ArticleSpecification} from '../../models/article-specification.model';
import {useEffect, useState} from 'react';
import {useFetchEffect} from '../../../../react/helper/react-async-fetch-effect';
import {ArticleMultilayerPlanAxiosService} from './article-multilayer-plan-axios.service';
import {ArticleMultilayerPlanForm} from './article-multilayer-plan-form';
import * as React from 'react';
import {ArticleMultilayerPlanHead} from './components/article-multilayer-plan-head';
import {ArticleMultilayerPlanHelper} from './article-multilayer-plan-helper';
import {EvReactModal} from '../../../../react/modal/EvReactModal';
import {
  ArticleMultilayerDifferenceWarningModal,
} from './modal/article-multilayer-difference-warning-modal';
import {ArticleMultilayerPlanHolderCompareHelper} from './article-multilayer-plan-holder-compare-helper';

interface Props {
  externalModelChange: ArticleMultilayerPlanModel;
  article: Article;
  specification: { [key: string]: ArticleSpecification };
  changeModel: (model: ArticleMultilayerPlanModel) => void;
  saveModel: (model: ArticleMultilayerPlan) => void;
  saveBtnPressed: number;
  saveBtnLocked: (saveBtnPressed: boolean) => void;
  copyModelBtnPressed: () => void;
}

export function ArticleMultilayerPlanCreate(props: Props) {
  const [modelState, modelStateSet] = useState<ArticleMultilayerPlanModel>(ArticleMultilayerPlanHelper.emptyModel());
  const [saveBtnPressed, saveBtnPressedSet] = useState<number>(props.saveBtnPressed);
  const [saveBtnLocked, saveBtnLockedSet] = useState<boolean>(false);
  const [showSaveConfirmation, showSaveConfirmationSet] = useState<boolean>(false);

  const changeModel: (m: ArticleMultilayerPlanModel) => void = (m) => {
    props.changeModel(m);
    modelStateSet(m);
  };

  const save: (force: boolean) => void = async (force) => {
    if (!force) {
      const diff = ArticleMultilayerPlanHolderCompareHelper.diff(props.article, modelState);
      if (diff.differs) {
        showSaveConfirmationSet(true);
        return;
      }
    }

    if (saveBtnLocked) {
      return;
    }
    saveBtnLockedSet(true);
    props.saveBtnLocked(true);
    try {
      const plan = await ArticleMultilayerPlanAxiosService.create(props.article.oa_nr, modelState);
      props.saveModel(plan);
      modelStateSet(plan.data);
    } finally {
      saveBtnLockedSet(false);
      props.saveBtnLocked(false);
    }
    saveBtnPressedSet(props.saveBtnPressed);
  };

  const saveConfirm: (confirmed: boolean) => void = async (confirmed) => {
    showSaveConfirmationSet(false);
    if (confirmed) {
      await save(true);
    }
  };

  /* Hooks */

  useFetchEffect(async (didCancel) => {
    if (props.saveBtnPressed <= saveBtnPressed) {
      return;
    }

    await save(false);
  }, [props.saveBtnPressed]);

  useEffect(() => {
    if (props.externalModelChange) {
      modelStateSet(props.externalModelChange);
    }
  }, [props.externalModelChange]);

  return <ArticleMultilayerPlanForm model={modelState}
                                    specification={props.specification}
                                    changeModel={m => changeModel(m)}>

    <EvReactModal showModal={showSaveConfirmation}
                  contentLabel='Die angegebenen Daten weichen von den Artikeldaten ab'
                  modalClosed={() => saveConfirm(false)}>
      <ArticleMultilayerDifferenceWarningModal response={confirmed => saveConfirm(confirmed)}/>
    </EvReactModal>

    <ArticleMultilayerPlanHead model={modelState}
                               article={props.article}
                               specification={props.specification}
                               copyModelBtnPressed={props.copyModelBtnPressed}
                               changeModel={m => changeModel(m)}/>
  </ArticleMultilayerPlanForm>;
}
