/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HolidayService} from './holiday.service';
import {AlertService} from '../../common/alert-service/alert.service';

@Component({
  selector: 'holiday-create',
  templateUrl: './holiday-create.component.html',
})
export class HolidayCreateComponent {

  errors?: { [key: string]: string; } = {};
  createForm: UntypedFormGroup;

  constructor(private holidayService: HolidayService,
              private modalRef: NgbActiveModal,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
    this.buildForm();
  }

  create() {
    this.errors = {};
    this.holidayService.create(this.createForm.value).subscribe((value) => {
      this.modalRef.close(value);
    }, () => {
      this.alertService.add(
        'danger',
        'Datum existiert bereits oder ist Wochenendtag.'
      );
    });
  }

  private buildForm() {
    this.createForm = this.fb.group({
      date: [null, Validators.required],
      comment: [null],
    });
  }

}
