import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Dunning, DunningInvoiceData, DunningSetting} from './dunning.models';
import {EnvisiaLocation} from '../../common/location/envisia-location';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../common/wrapper.models';
import {ListService} from '../../common/search/search.service';
import {map} from 'rxjs/operators';
import {SuperSettingForm} from '../../lazy/config/config.models';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class DunningService implements ListService<Dunning> {

  private uri = '/api/v1/dunning/';

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<Dunning>> {
    return this.http.get<Lister<Dunning>>(
      this.uri,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  automationList(): Observable<DunningInvoiceData[]> {
    return this.http.get<DunningInvoiceData[]>(`${environment.apiv4uri}dunning/exceeded/invoices`);
  }

  createJobs(ids: number[]): Observable<any> {
    return this.http.post<any>(`${environment.apiv4uri}dunning/job/create`, ids);
  }

  update(id: number): Observable<Dunning> {
    return this.http.put<EnvisiaObject<Dunning>>(this.uri + id + '/', {}).pipe(
      map(value => value.object)
    );
  }

  settings(): Observable<DunningSetting[]> {
    return this.http.get<EnvisiaObjects<DunningSetting>>(this.uri + 'setting/').pipe(
      map(val => val.objects)
    );
  }

  settingsSave(body: SuperSettingForm): Observable<DunningSetting[]> {
    return this.http.post<EnvisiaObjects<DunningSetting>>(this.uri + 'setting/', body).pipe(
      map(val => val.objects)
    );
  }

}
