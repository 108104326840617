import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import Helper from '../../../helper/helper';

@Component({
  selector: 'customer-rating',
  templateUrl: './customer-rating.component.html'
})
export class CustomerRatingComponent implements OnInit, OnChanges {
  @Input() rating: string | number | number[];
  @Input() edit: boolean;
  @Input() list?: boolean;
  @Output() ratingChange = new EventEmitter<string>();
  @Output() listener = new EventEmitter<number>();
  /** default values */
  ratingValue: number | Set<number> = new Set([]);
  ratingObj = {1: false, 2: false, 3: false, 4: false, 5: false};

  ngOnInit(): void {
    if (!this.list) {
      this.list = false;
    }

    this.parseAndSetRating(this.rating);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rating && !changes.rating.isFirstChange()) {
      this.parseAndSetRating(this.rating);
    }
  }

  changeRating(val: number) {
    if (this.list) {
      if (typeof this.ratingValue === 'number') {
        this.ratingValue = new Set([val]);
      } else {
        if (this.ratingValue.has(val)) {
          this.ratingValue.delete(val);
        } else {
          this.ratingValue.add(val);
        }
      }
      this.setSimpleNumberRating(val, true);
    } else {
      this.ratingValue = val;
      this.clearNumberRating();
      this.setComplexNumberRating(val);
    }

    this.ratingChange.emit(this.ratingValueFormatted());
    this.listener.emit(val);
  }

  private ratingValueFormatted(): string {
    if (typeof this.ratingValue === 'number') {
      return this.ratingValue.toString();
    } else {
      return Array.from(this.ratingValue).sort((a, b) => a - b).join(',');
    }
  }

  private parseAndSetRating(val: string | number | number[]): void {
    this.clearNumberRating();
    if (!this.list) {
      let value: number;
      if (typeof val === 'number') {
        value = val;
      } else if (typeof val === 'string') {
        value = Math.max(...val.split(',').filter(Helper.isInt).map(Helper.toInt));
      } else {
        value = Math.max(...(val as number[]));
      }
      this.setComplexNumberRating(value);
      this.ratingValue = value;
    } else {
      let values: Set<number>;
      if (typeof val === 'number') {
        values = new Set([val]);
      } else if (typeof val === 'string') {
        // creates a distinct array for all values
        values = new Set(val.split(',').filter(Helper.isInt).map(Helper.toInt).filter(n => n >= 1 && n <= 5));
      } else {
        // creates a unique array for all the values
        values = new Set(val as number[]);
      }

      values.forEach(n => {
        this.setSimpleNumberRating(n, false);
      });
      this.ratingValue = values;
    }
  }

  private clearNumberRating(): void {
    this.ratingObj[1] = false;
    this.ratingObj[2] = false;
    this.ratingObj[3] = false;
    this.ratingObj[4] = false;
    this.ratingObj[5] = false;
  }

  private setComplexNumberRating(val: number): void {
    if (val >= 1 && val <= 5) {
      Array.from(Array(val).keys(), (value) => value + 1).forEach(value => {
        this.ratingObj[value] = true;
      });
    }
  }

  private setSimpleNumberRating(val: number, toggle: boolean): void {
    this.ratingObj[val] = toggle ? !this.ratingObj[val] : true;
  }

}
