import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaterialOrderIntakeGroupElement, MaterialOrderIntakeListElement} from '../../material-order.model';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';
import {MaterialValueType} from '../../../material-management.model';
import {MaterialManagementService} from '../../../material-management.service';
import {noop} from '../../../../../helper/noop';
import {FormArray, FormGroup} from '@angular/forms';
import {
  MaterialOrderIntakeBookedPositionForm,
  MaterialOrderIntakeBookedGroupForm
} from './material-order-intake-booked-form.model';
import {Lister} from '../../../../../common/wrapper.models';
import {MaterialGroup} from '../../../groups/material-group.model';

@Component({
  selector: 'material-order-intake-booked-group',
  templateUrl: './material-order-intake-booked-group.component.html'
})
export class MaterialOrderIntakeBookedGroupComponent implements OnInit {
  @Input() group: MaterialOrderIntakeGroupElement;
  @Input() groups: Lister<MaterialGroup>;
  @Input() indexG: number;
  @Input() markedVariationId?: number;
  @Input() markedTransactionId?: number;
  @Input() finderSupplierUri?: string;
  @Input() form: FormGroup<MaterialOrderIntakeBookedGroupForm>;
  @Input() errors: {[key: string]: any} = {};
  @Output() updateOrder = new EventEmitter<void>();

  materialFieldData: LoadingWrapper<MaterialValueType[]>;

  groupPermission: boolean;

  constructor(private mms: MaterialManagementService) {
  }

  ngOnInit(): void {
    this.materialFieldData = new LoadingWrapper<MaterialValueType[]>(this.mms.listFormFieldClasses(this.group.group.id));
    this.groupPermission = !!this.groups.objects.find(mg => mg.id === this.group.group.id);
  }

  trackBy(index: number, item: MaterialOrderIntakeListElement): number {
    noop(this);
    return item.position_id;
  }

  get formArrayMapped(): (MaterialOrderIntakeListElement & {form: FormGroup<MaterialOrderIntakeBookedPositionForm>})[] {
    const formArray = this.form.get('positions') as FormArray<FormGroup<MaterialOrderIntakeBookedPositionForm>>;
    return this.group.article_list.map(position => {
      const index = formArray.value.findIndex(p => p.order_position_id === position.position_id);
      return {...position, form: formArray.at(index)};
    });
  }
}
