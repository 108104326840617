<div class="modal-header">
  <h3 class="modal-title">{{ title }} - {{ id }}</h3>
</div>

<div class="modal-body">

  <table class="table whitetable">
    <thead>
    <tr>
      <th>History ID</th>
      <th>Username</th>
      <th>Datum</th>
      <th>Tracking-ID</th>
      <th>&nbsp;</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let obj of objects">
      <td>{{ obj.id }}</td>
      <td>{{ obj.username }}</td>
      <td>{{ obj.date|date:"dd.MM.yyyy" }}</td>
      <td>
        <tr *ngFor="let shipment of obj.shipments">
          <td>{{shipment}}</td>
        </tr>
      </td>
      <td>
        <ev-file-button [url]="'/api/v1/document/history/pdf/' + name + '/' + obj.pdf + '/'">
          <i class="fa fa-file-pdf-o"></i>
        </ev-file-button>
      </td>
    </tr>
    </tbody>
  </table>

</div>

<div class="modal-footer">
</div>
