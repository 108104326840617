import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CustomerService} from '../services/customer.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Customer} from '../model/customer';
import {CalculationProcess, PriceIncrease} from '../../price/price.models';
import {FieldService} from '../model/field-service';
import {UserInfo} from '../../user/user.models';
import {Subscription} from 'rxjs';
import {Term} from '../model/term.model';
import {debounceTime} from 'rxjs/operators';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {ErpProducer} from '../../producer/producer.models';
import {IndustrialSector} from '../../industrial-sector/producer.models';

@Component({
  selector: 'customer-detail-form',
  templateUrl: './customer-detail-form.component.html'
})
export class CustomerDetailFormComponent implements OnInit, OnDestroy {
  @Input() terms: Term[];
  @Input() deliveryTerms: Term[] = [{id: 1, description: 'Ab Werk Kenzingen (FCA)'}];
  @Input() customer: Customer;
  @Input() producers: ErpProducer[];
  @Input() increase: { de?: PriceIncrease[], cn?: PriceIncrease[] };
  @Input() fieldServices: FieldService[];
  @Input() fieldServiceUser?: UserInfo;
  @Input() processes: { de: CalculationProcess[], cn: CalculationProcess[] };
  @Input() industrialSectors: IndustrialSector[];
  form: UntypedFormGroup;
  formSubscription?: Subscription;
  httpSubscription?: Subscription;
  errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private customerService: CustomerService) {
  }

  ngOnInit(): void {
    const additionalForm = this.fb.group({
      percent_add_chn: [this.customer.additional.percent_add_chn],
      percent_add_de: [this.customer.additional.percent_add_de],
      offer_de: [this.customer.additional.offer_de],
      offer_cn: [this.customer.additional.offer_cn],
      offer_mail: [this.customer.additional.offer_mail],
      contract_mail: [this.customer.additional.contract_mail],
      delivery_mail: [this.customer.additional.delivery_mail],
      invoice_mail: [this.customer.additional.invoice_mail],
      remainder_mail: [this.customer.additional.remainder_mail],
      term_lose: [this.customer.additional.term_lose],
      term_kanban: [this.customer.additional.term_kanban],
      circuit_board_needs: [this.customer.additional.circuit_board_needs],
      possible_revenue: [this.customer.additional.possible_revenue],
      main_contact: [this.customer.additional.main_contact],
      competitor: [this.customer.additional.competitor],
      invoice_free_text: [this.customer.additional.invoice_free_text],
      insurance_sum: [this.customer.additional.insurance_sum]
    });
    this.form = this.fb.group({
      // Common
      phone: [this.customer.phone],
      fax: [this.customer.fax],
      mail: [this.customer.mail],
      website: [this.customer.website],
      additional: additionalForm,
      note: [this.customer.note],
      sales_note: [this.customer.sales_note],
      cam_note: [this.customer.cam_note],
      assured: [this.customer.assured],
      tax_identification_number: [this.customer.tax_identification_number],
      dunning_lock: [this.customer.dunning_lock],
      preference: [this.customer.preference],
      cn_ul: [this.customer.cn_ul],
      show_ul_types: [this.customer.show_ul_types],
      show_producer: [this.customer.show_producer],
      show_producer_offer: [this.customer.show_producer_offer],
      surplus_disposal: [this.customer.surplus_disposal],
      surplus_disposal_limit: [{value: this.customer.surplus_disposal_limit, disabled: !this.customer.surplus_disposal}],
      show_contract_overview: [this.customer.show_contract_overview],
      allow_special_production: [this.customer.allow_special_production],
      // Terms
      term_de: this.customer.term_de,
      term_cn: this.customer.term_cn,
      delivery_terms: [this.customer.delivery_terms],
      credit_limit: [{value: this.customer.credit_limit, disabled: false}],
    });
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(550)).subscribe(values => {
      this.errors = {};
      // if the form is invalid we can just skip any update at all
      if (this.form.valid) {
        if (!this.form.value.surplus_disposal) {
          const surplusDisposalLimitControl = this.form.get('surplus_disposal_limit') as UntypedFormControl;
          surplusDisposalLimitControl.disable({emitEvent: false});
          surplusDisposalLimitControl.patchValue(null, {emitEvent: false});
          values.surplus_disposal_limit = null;
        } else {
          this.form.get('surplus_disposal_limit').enable({emitEvent: false});
        }
        if (this.httpSubscription) {
          this.httpSubscription.unsubscribe();
          this.httpSubscription = null;
        }
        this.updateCustomer(values);
      } else {
        this.errorReport();
      }
    }, ignoreRejection);
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  private updateCustomer(values: any): void {
    this.httpSubscription = this.customerService.update(this.customer.id, values).subscribe(() => {
      this.httpSubscription = null;
      this.alertService.add('success', 'Kundendaten erfolgreich gespeichert!');
    }, response => {
      this.httpSubscription = null;
      if (response.error) {
        this.errors = response.error;
      }
      this.errorReport();
    });
  }

  private errorReport(): void {
    this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Eingabe!');
  }

}
