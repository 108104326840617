import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {MaterialOrderArticleListElement, MaterialOrderArticleListIndexedElement} from '../material-order.model';
import {MaterialValueType} from '../../material-management.model';
import {noop} from '../../../../helper/noop';
import {DateHelper} from '../../../../helper/date-helper';

@Component({
  selector: 'tr[material-order-create-row]',
  templateUrl: './material-order-create-row.component.html',
  styleUrls: ['../material-order.component.scss'],
})
export class MaterialOrderCreateRowComponent implements OnInit, OnChanges {
  @Input('material-order-create-row') article: MaterialOrderArticleListElement;
  @Input('material-order-create-row-form') parentForm: UntypedFormGroup;
  @Input('material-order-create-row-index') index: number;
  @Input('material-order-create-row-delivery-index') deliveryIndex: number;
  @Input('material-order-create-row-fields') fields: MaterialValueType[];
  @Input('material-order-create-row-errors') errors: { [key: string]: any } = {};
  @Output('material-order-create-row-remove') removeEmitter = new EventEmitter<MaterialOrderArticleListIndexedElement>();
  @Output('material-order-create-row-copy') copyEmitter = new EventEmitter<MaterialOrderArticleListElement>();

  form: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    const ordersForm = this.parentForm.get('orders') as UntypedFormArray;
    const formIndex = this.parentForm.value.orders.findIndex(o =>
      o.variation_id === this.article.id && o.delivery_index === this.deliveryIndex
    );
    this.form = ordersForm.at(formIndex) as UntypedFormGroup;
    this.form.patchValue({'index': this.index});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.index && !changes.index.isFirstChange()) {
      this.form.patchValue({'index': this.index}, {emitEvent: false});
    }

    if (changes.deliveryIndex && !changes.deliveryIndex.isFirstChange()) {
      this.form.patchValue({'delivery_index': this.deliveryIndex}, {emitEvent: false});
    }
  }

  remove(): void {
    this.removeEmitter.emit({delivery_index: this.deliveryIndex, article: this.article});
  }

  copy(): void {
    this.copyEmitter.emit(this.article);
  }

  dateParser(date: string | Date | null | undefined): Date | null {
    noop(this);
    if (date instanceof Date) {
      return date;
    }
    if (!!date) {
      return DateHelper.parseISO8601(date);
    }
    return null;
  }
}
