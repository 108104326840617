<form id="customerForm" role="form" class="wizardcustomerForm form-horizontal" [formGroup]="form">
  <div class="row">
    <div class="col-md-3">
      <div class="pull-left" *ngIf="currentCustomer" style="margin-bottom: 10px;">
        <button class="btn btn-success"
                (click)="finishEmitter.emit({typ: 1, customerId: currentCustomer.id, contactId: currentContact?.id})"
                type="button"
                *ngIf="'task.change'|HasPerm">
          Neuer Auftrag
        </button>
        &nbsp;
        <button class="btn btn-success"
                (click)="finishEmitter.emit({typ: 0, customerId: currentCustomer.id, contactId: currentContact?.id})"
                type="button"
                *ngIf="'task.offer.change'|HasPerm">
          Neues Angebot
        </button>
      </div>
    </div>
    <div class="col-md-2">
      <button class="btn btn-success" *ngIf="currentCustomer" (click)="newContact()">
        Neuer Ansprechpartner erstellen
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-3">
      <div class="row">
        <div class="col-sm-2"><h3><label for="id_oa_nr">Kunde</label></h3></div>
        <div class="col-sm-9">
          <input class="form-control" id="id_oa_nr" type="text" formControlName="search" [autofocus]>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="whitelist whitelist-bold-head">
            <div class="whitelist-item"
                 *ngFor="let customer of customerList?.objects"
                 [ngClass]="{selected: currentCustomer?.id === customer.id}"
                 (click)="selectCustomer(customer)">
              <h4>
                {{ customer.name }}
                <span class="label label-warning" *ngIf="customer.locked">Gesperrt</span>
              </h4>

              <p>{{ customer.street }}</p>

              <p>{{ customer.country }} - {{ customer.zip }} {{ customer.city }}</p>
            </div>
          </div>

          <nav>
            <button type="button" class="btn btn-default pull-left"
                    (click)="setPage(customerList?.page - 1)"
                    [disabled]="customerList?.page <= 1">
              Zurück
            </button>

            <button type="button" class="btn btn-default pull-right"
                    (click)="setPage(customerList?.page + 1)"
                    [disabled]="customerList?.page === customerList?.pages">
              Weiter
            </button>
          </nav>

        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="row">
        <div class="col-sm-12">
          <h3>Ansprechpartner</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="whitelist whitelist-one-row">
            <div class="whitelist-item nocontact-row"
                 *ngIf="contactList"
                 (click)="selectContact(null)"
                 [ngClass]="{selected: !currentContact}">
              <h4>Kein Ansprechpartner</h4>
            </div>
            <div class="whitelist-item contact-row"
                 *ngFor="let contact of contactList"
                 [ngClass]="{selected: contact.id === currentContact?.id}"
                 (click)="selectContact(contact)">
              <p>{{ contact.title }}</p>

              <h4>{{ contact.last_name }}, {{ contact.first_name }} </h4>

              <p>{{ contact.position }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="row sales-note-box-danger" *ngIf="currentCustomer?.sales_note">
        <div class="col-sm-12">
          <h3>Angebotsrelevante Informationen</h3>
        </div>

        <div class="col-sm-12">
          <pre id="sales_note">{{ currentCustomer.sales_note }}</pre>
        </div>
      </div>
      <div class="row sales-note-box-warning" *ngIf="currentCustomer?.note">
        <div class="col-sm-12">
          <h3>Allgemeine Notiz</h3>
        </div>

        <div class="col-sm-12">
          <pre id="note">{{ currentCustomer.note }}</pre>
        </div>
      </div>
    </div>
  </div>

</form>
