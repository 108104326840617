import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusinessOrderWithReproduction} from '../../models/business-order.models';
import {CommonType, Map as EvMap} from '../../../../../common/init-data/init-data';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {BusinessOrderStartService} from '../../start/business-order-start.service';
import {BusinessOrderStornoService} from '../../storno/business-order-storno.service';
import {Article, ArticleCore} from '../../../../article/models/article.models';
import {FetchWithPositions} from '../../../models/frame.models';

@Component({
  selector: 'business-order-article-list-element',
  templateUrl: './business-order-article-list-element.component.html',
  styleUrls: ['./business-order-article-list.component.scss'],
})
export class BusinessOrderArticleListElementComponent implements OnInit {
  @Input() businessOrder: BusinessOrderWithReproduction;
  @Input() workflowId: number;
  @Input() businessOrderStatus: EvMap<CommonType>;
  @Input() article: Article | ArticleCore;
  @Input() empb: boolean;
  @Input() kanban: boolean;
  @Input() fetches: FetchWithPositions[];
  @Output() reproductionSelect = new EventEmitter<BusinessOrderWithReproduction>();
  @Output() reloadList = new EventEmitter<void>();

  isSameWorkflow: boolean;
  canSelectReproduction: boolean;
  businessOrderUri: string;

  constructor(private alertService: AlertService,
              private businessOrderStartService: BusinessOrderStartService,
              private stornoService: BusinessOrderStornoService) {
  }

  ngOnInit(): void {
    this.isSameWorkflow = this.businessOrder.workflow_id === Number(this.workflowId);
    this.canSelectReproduction = (
      (this.businessOrder.status !== 0) &&
      (this.businessOrder.status !== 100) &&
      (this.businessOrder.status !== 101) &&
      (!this.fetches.find(f => f.status >= 4)) &&
      this.isSameWorkflow
    );
    this.businessOrderUri = `/api/v1/workflow/business/order/pdf/${this.businessOrder.id}/${this.businessOrder.index}/`;
  }

  get hashesAreValid(): boolean {
    return !this.businessOrder.divergent;
  }

  get labelText(): string {
    return this.businessOrderStatus[this.businessOrder.status].name;
  }

  get labelStatus(): string {
    let labelCss = 'alert-' + this.businessOrderStatus[this.businessOrder.status].label;
    if (this.businessOrder.status === 4 && this.hashesAreValid) {
      labelCss = 'alert-grey';
    }
    return labelCss;
  }

  selectReproduction(): void {
    this.reproductionSelect.emit(this.businessOrder);
  }

  start(): void {
    this.businessOrderStartService.start(this.businessOrder).subscribe(() => {
      this.reloadList.emit();
    }, error => {
      console.log('Start BA Error:', error);
    });
  }

  storno(): void {
    this.stornoService.call(this.businessOrder).subscribe(() => {
      this.reloadList.emit();
    }, error => {
      console.log('Storno BA Error:', error);
    });
  }

}
