import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CalculationProcess} from '../price.models';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ProcessService {

  constructor(private http: HttpClient) {
  }

  list(): Observable<{de: CalculationProcess[], cn: CalculationProcess[]}> {
    return this.http.get<{de: CalculationProcess[], cn: CalculationProcess[]}>('/workflow/v1/process/');
  }

}
