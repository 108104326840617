import {Component, Input} from '@angular/core';
import {MaterialFieldListElement} from '../material-management.model';
import {StateService} from '@uirouter/angular';
import {MaterialManagementService} from '../material-management.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'material-field-list',
  templateUrl: './material-field-list.component.html'
})
export class MaterialFieldListComponent {
  @Input() fields: MaterialFieldListElement[];
  sortingBy: string;
  desc = false;
  busy = false;
  length: number;
  after: number | null = 0;
  locked = false;

  constructor(private stateService: StateService,
              private mmService: MaterialManagementService,
              private alertService: AlertService) {
  }

  sort(sortingBy: string) {
    if (this.sortingBy === sortingBy) {
      this.desc = !this.desc;
    } else {
      this.sortingBy = sortingBy;
      this.desc = false;
    }
    this.stateService.go(
      'a.material.field.list',
      {sort: this.sortingBy, order: this.desc ? 'DESC' : 'ASC'}
    );
    this.fields.sort((fieldA, fieldB) =>
      (this.desc ? -1 : 1) *
      (!isNaN(Number(fieldA[this.sortingBy])) ?
          fieldA[this.sortingBy] - fieldB[this.sortingBy] :
          fieldA[this.sortingBy].localeCompare(fieldB[this.sortingBy])
      )
    );
  }

  delete(field: MaterialFieldListElement) {
    if (confirm('Achtung!\nFeld wirklich löschen?')) {
      this.mmService.deleteField(field.id).subscribe(deletedField => {
        this.alertService.add(
          'success',
          'Feld ' + deletedField.name + ' wurde erfolgreich gelöscht'
        );
        this.mmService.listField(this.sortingBy, this.desc).subscribe(fieldList => {
          this.fields = fieldList;
        });
      }, response => {
        this.alertService.add(
          'error',
          (response.status === 404 ? 'Feld wurde nicht gefunden' : 'Feld konnte nicht gelöscht werden')
        );
      });
    }
  }
}
