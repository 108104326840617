import {WorkflowPositionView} from '../../models/position.models';

export interface PositionChange {
}

export class PositionSingleUpdateChange {
  position: WorkflowPositionView;

  constructor(position: WorkflowPositionView) {
    this.position = position;
  }
}

export class PositionCloneUpdateChange {
  position: WorkflowPositionView;

  constructor(position: WorkflowPositionView) {
    this.position = position;
  }
}

export class PositionRemoveChange {
  position: WorkflowPositionView;

  constructor(position: WorkflowPositionView) {
    this.position = position;
  }
}
