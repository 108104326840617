import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {StepEntity} from '../../../envisia/article/production/production.models';
import {ProductionTemplateService} from '../../../envisia/article/production-services/production-template.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'production-template-step-edit',
  templateUrl: './production-template-step-edit.component.html',
})
export class ProductionTemplateStepEditComponent implements OnInit {
  errors?: { [key: string]: any; } = {};
  editForm: UntypedFormGroup;

  @Input() data: StepEntity;

  constructor(private productionTemplateService: ProductionTemplateService,
              private fb: UntypedFormBuilder,
              private modalRef: NgbActiveModal,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      expression: [this.data.template.expression]
    });
  }

  edit() {
    this.errors = {};
    this.productionTemplateService.stepEdit(
      this.data.template.id,
      this.editForm.value.expression
    ).subscribe(value => {
      this.alertService.add('success', 'Vorlagenschritt erfolgreich editiert!');
      this.modalRef.close(value);
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      this.alertService.add(
        'danger',
        'Es ist ein Fehler aufgerterten, bitte überprüfen Sie die eingegeben Werte und versuchen Sie es erneut!'
      );
    });
  }

}
