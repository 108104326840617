<div class="col-lg-12">
  &nbsp;
</div>

<div *ngFor="let object of history" class="col-lg-12">

  <div class="row row-no-margin row-flexbox">
    <div class="whitebox"
         [ngClass]="{red_alert: (object.locked || object.event === 'delete'), 'col-lg-6': !object.article_changed || !(object.change_text === 'Artikel erstellt'), 'col-lg-4': object.article_changed || object.change_text === 'Artikel erstellt'}">
      <h4>
        <i class="fa fa-clock-o"></i> {{ object.date }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i class="fa fa-user"></i> {{ object.username }}
      </h4>

      <div class="row">
        <div class="col-sm-9">
          <div [innerHTML]="object.change_text"></div>
        </div>
        <div class="col-sm-3" *ngIf="object.article_changed || object.change_text === 'Artikel erstellt'">
          <button (click)="preview(object.id)" class="btn btn-default">Artikelstand</button>
        </div>
      </div>

    </div>

    <div class="col-lg-2 yellowbox" *ngIf="object.article_changed">
      <ul *ngFor="let data of object.change_list;trackBy:trackByFn; let index = index"
          style="list-style-type: none; margin: 0; padding: 0;">
        <li>- {{ data }}</li>
      </ul>
    </div>

    <div class="col-lg-2 whitebox" *ngIf="!object.article_changed" style="padding: 7px;">
      &nbsp;
    </div>

    <div class="col-lg-6">&nbsp;</div>
  </div>

</div>

<div class="row">
  <div class="col-lg-12">
    &nbsp;
  </div>
</div>
