import {Injectable} from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {ContactDepartment} from '../../../envisia/customer/contact-department/customer-contact-department.models';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {environment} from '../../../../environments/environment';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {HttpClient} from '@angular/common/http';
import {IndustrialSector} from '../../../envisia/industrial-sector/producer.models';

@Injectable({providedIn: 'root'})
export class CustomerContactDepartmentListService implements ListService<ContactDepartment> {

  uri = environment.apiv4uri + 'customer/contact/department';

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<ContactDepartment>> {
    return this.http.get<Lister<ContactDepartment>>(this.uri, {params: EnvisiaLocation.httpParams(query)});
  }

  create(form: any): Observable<IndustrialSector> {
    return this.http.post<IndustrialSector>(this.uri, form);
  }

}
