import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import {NumberHelper} from '../../../../../helper/number-helper';

interface Props {
  value: number | null;
  model: string;
  children?: ReactNode;
  onValueChange: (value: number | null) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
  width?: number;
}

export function ArticleMultilayerNumberInput(props: Props) {
  const [inputValue, inputValueSet] = useState<string | null>(props.value === null ? null : props.value.toString(10));

  /* Hooks */
  useEffect(() => {
    inputValueSet(props.value === null ? null : props.value.toString(10));
  }, [props.value]);

  const isValid: boolean =
    (!NumberHelper.isDefined(props.value) && !inputValue) ||
    (props.value?.toString(10) === inputValue);

  const changeValue: (valueString: string | null) => void = (valueString) => {
    const valueParsed = NumberHelper.saveParseFloat(valueString, ',', '.', 0);
    inputValueSet(valueString);
    if (!NumberHelper.isDefined(valueParsed)) {
      props.onValueChange(null);
    } else if (valueParsed < (props.min ?? 0)) {
      props.onValueChange(props.min ?? 0);
    } else if (valueParsed > (props.max ?? 1000000000000000)) {
      props.onValueChange(props.max ?? 1000000000000000);
    } else {
      props.onValueChange(valueParsed);
    }
  };

  return <>
    <div className={'article-form-item'} style={{padding: '0', margin: '0'}}>
      <div className={'no-padding article-form-item-data' + (!!props.children ? ' data-group' : '')}
           style={{'width': !!props.width ? `${(props.width)}px` : '285px'}}>
        <div className={'article-form-data-content'}>
          <input id={'article-model-' + props.model}
                 type={'number'}
                 min={props.min ?? 0}
                 max={props.max ?? 1000000000000000}
                 step={0.001}
                 className={'form-control number-input-no-arrows align-right' + (isValid ? '' : ' red_alert')}
                 value={inputValue ?? ''}
                 disabled={props.disabled}
                 style={{
                   'width': !!props.width ? `${(props.width - 25)}px` : '245px',
                   'background': 'white',
                   'padding': '0px 20px 0px 0px',
                 }}
                 onChange={c => changeValue(c.target.value)}/>
        </div>
        {props.children}
      </div>
    </div>
  </>;
}
