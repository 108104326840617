import {AuthService} from '../auth/auth.service';

export abstract class ErrorSharedComponent {
  protected abstract authService: AuthService;

  logout(): void {
    this.authService.logout();
  }

  home(): void {
    window.location.replace('/');
  }

  back(): void {
    window.history.back();
  }

}
