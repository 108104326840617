import {Component, Input} from '@angular/core';

@Component({
  selector: 'business-order-film-inventory',
  templateUrl: './business-order-film-inventory.component.html',
  styleUrls: ['./business-order-film-inventory.scss'],
})
export class BusinessOrderFilmInventoryComponent {
  @Input() filmInventory: number;
  constructor() {
  }

}
