import {Component, Input, OnInit} from '@angular/core';
import {LocalStorage} from '../storage/local-storage';
import {CommonType, InitData} from '../init-data/init-data';

@Component({
  selector: 'ev-status',
  templateUrl: './ev-status.component.html',
  styleUrls: ['./ev-status.component.scss']
})
export class EvStatusComponent implements OnInit {
  @Input() name: string;
  @Input() state: number;
  status: CommonType;

  constructor(private localStorage: LocalStorage) {
  }

  ngOnInit() {
    this.status = this.localStorage.getObject<InitData>('data')[this.name][this.state];
  }

}
