import {AxiosHttpClient} from '../../../auth/axios-http-client';
import {ArticleInventoryFormModel} from './article-inventory-form.model';
import {Inventory} from '../inventory.models';
import {environment} from '../../../../../environments/environment';

export class ArticleInventoryAxiosService {
  static add(oaNr: string, form: ArticleInventoryFormModel): Promise<Inventory> {
    return AxiosHttpClient.post<Inventory>(`${environment.apiv4uri}inventory/${oaNr}/`, form);
  }
}
