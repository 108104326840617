import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderPdfEditModalComponent} from './order-pdf-edit-modal.component';
import {WorkflowOrder} from '../order-request.models';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {OrderRequest} from '../../request/workflow-request.model';

@Injectable({
  providedIn: 'root'
})
export class OrderPdfEditModalService {

  constructor(private modalService: NgbModal) {
  }

  open(order: WorkflowOrder, updated: (response: { requests: OrderRequest[], orders: WorkflowOrder[] }) => void): void {
    const modalRef = this.modalService.open(OrderPdfEditModalComponent, {windowClass: 'modal2-mm'});
    modalRef.componentInstance.data = order;
    from(modalRef.result).subscribe(updated, ignoreRejection);
  }

}
