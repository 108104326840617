<div [ngClass]="{red_alert: hashNotEqual}" class="whitebox pricecheck-box">
  <!-- disabled temporarily for issue #1270, hashNotEqual -->
  <h3>Artikel Kalkulation</h3>

  <form [formGroup]="form">
    <div *ngIf="false" class="pricecheck-box-row">
      <!-- disabled temporarily for issue #1270, originally hashNotEqual -->
      <div class="pricecheck-box-col-100">
        <i class="fa fa-exclamation-triangle"></i> Artikeländerung. Kalkulation muss freigegeben werden.
      </div>
    </div>


    <div class="pricecheck-box-row">
      <div class="pricecheck-box-col-50">
        <label for="id_germany">Kalkulation DE:</label>
      </div>
      <div class="pricecheck-box-col-50">
        <label for="id_china">Kalkulation CHN:</label>
      </div>
    </div>

    <div class="pricecheck-box-row">
      <div class="pricecheck-box-col-50">
        <price-selector *ngIf="'price.change'|HasPerm" name="de" [form]="form" [processes]="processes.de"
                        id="id_germany">
        </price-selector>
        <span *ngIf="!('price.change'|HasPerm)">{{ article.calc_de }}</span>
      </div>

      <div class="pricecheck-box-col-50">
        <price-selector *ngIf="'price.change'|HasPerm" name="cn" [form]="form" [processes]="processes.cn"
                        id="id_china">
        </price-selector>
        <span *ngIf="!('price.change'|HasPerm)">{{ article.calc_chn }}</span>
      </div>
    </div>

    <div class="pricecheck-box-row">
      <div class="pricecheck-box-col-50">
        <button (click)="overwriteCalc()" *ngIf="'price.change'|HasPerm" class="btn btn-default btn-sm"
                style="width: 100%;margin-top: 5px;"
                type="button">
          Speichern
        </button>
      </div>

      <div *ngIf="false" class="pricecheck-box-col-50"> <!-- disabled temporarily for issue #1270 -->
        <button (click)="calc()" *ngIf="'price.change'|HasPerm" class="btn btn-warning btn-sm"
                style="width: 100%;margin-top: 5px;"
                type="button">
          Freigeben
        </button>
      </div>
    </div>

    <div *ngIf="false" class="pricecheck-box-row" style="margin-top:10px;">
      <!-- disabled temporarily for issue #1270 -->
      <div class="pricecheck-box-col-100">
        <strong>Achtung:</strong> Wenn Kalkulationen freigegeben werden, können Preise mit
        dieser Kalkulation
        in Aufträgen bestätigt werden, auch wenn diese nicht in der Preisliste enthalten sind.
      </div>
    </div>

  </form>

</div>
