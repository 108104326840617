<div class="modal-header">
  <h3 class="modal-title">
    <ng-container *ngIf="current === 'weight'">Gewichtsangabe für Versand außerhalb von Deutschland</ng-container>
    <ng-container *ngIf="current === 'inventory'">Lieferschein für Menge {{ frozenQuantity }} Stück</ng-container>
  </h3>
</div>


<div class="modal-body">
  <!--Weight / Optional Form -->
  <delivery-create-modal-form-weight *ngIf="current === 'weight'"
                                     [labelCount]="labelCount"
                                     (next)="next($event)"
                                     (dismiss)="dismiss()">
  </delivery-create-modal-form-weight>

  <!-- Inventory / Final Form -->
  <delivery-create-modal-form-inventory *ngIf="current === 'inventory'"
                                        [labelCount]="labelCount"
                                        [data]="data"
                                        [workflow]="workflow"
                                        [frozenQuantity]="frozenQuantity"
                                        [fetches]="fetches"
                                        [storages]="storage"
                                        [weightValues]="weightValues"
                                        (dismiss)="dismiss()"
                                        (closeEmitter)="close($event)">
  </delivery-create-modal-form-inventory>

</div>

<div class="modal-footer">
</div>
