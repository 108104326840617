import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Customer, CustomerCalculations} from '../model/customer';
import {FieldServiceService} from '../services/field-service.service';
import {UserService} from '../../user/user.service';
import {Subscription} from 'rxjs';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {CalculationProcess} from '../../price/price.models';
import {CustomerService} from '../services/customer.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'customer-calculation',
  templateUrl: './customer-calculation.component.html'
})
export class CustomerCalculationComponent implements OnInit, OnDestroy {
  @Input() customer: Customer;
  @Input() processes: { de: CalculationProcess[], cn: CalculationProcess[] };
  form: UntypedFormGroup;
  formSubscription?: Subscription;
  httpSubscription?: Subscription;
  error = false;
  errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private userService: UserService,
              private fieldServiceService: FieldServiceService,
              private customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      calc_de: [this.customer.calculation_de],
      calc_cn: [this.customer.calculation_cn]
    });
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(550)).subscribe(values => {
      this.errors = {};
      // if the form is invalid we can just skip any update at all
      if (this.form.valid) {
        if (this.httpSubscription) {
          this.httpSubscription.unsubscribe();
          this.httpSubscription = null;
        }

        const calcs = {
          calculation_de: values.calc_de,
          calculation_cn: values.calc_cn
        } as CustomerCalculations;

        this.updateCustomer(calcs);
      } else {
        this.errorReport();
      }
    }, ignoreRejection);
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  private updateCustomer(values: CustomerCalculations): void {
    this.httpSubscription = this.customerService.updateCalc(this.customer.id, values).subscribe(() => {
      this.httpSubscription = null;
      this.alertService.add('success', 'Kundendaten erfolgreich gespeichert!');
    }, response => {
      this.httpSubscription = null;
      if (response.error) {
        this.errors = response.error;
      }
      this.errorReport();
    });
  }

  private errorReport(): void {
    this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Eingabe!');
  }

}
