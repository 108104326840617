import {Component, Input, OnInit} from '@angular/core';
import {AddressService} from '../services/address.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Customer} from '../model/customer';
import {Country} from '../model/country';
import {Address} from '../model/address';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import Helper from '../../../helper/helper';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'customer-detail-address-upsert-modal',
  templateUrl: './customer-detail-address-upsert-modal.component.html'
})
export class CustomerDetailAddressUpsertModalComponent implements OnInit {
  /** input data */
  @Input() main: boolean;
  @Input() customer: Customer;
  @Input() countries: { [key: string]: Country; };
  @Input() address?: Address;
  /** variables */
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};
  button: string;
  header: string;
  countryList: Country[] = [];
  blocked = false;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbActiveModal,
              private alertService: AlertService,
              private addressService: AddressService) {
  }

  ngOnInit(): void {
    Helper.keys(this.countries, value => {
      this.countryList.push(value);
    });
    if (this.address) {
      this.button = 'Adresse speichern';
      this.header = 'Adresse ändern';
    } else {
      this.button = 'Adresse erstellen';
      this.header = this.button;
    }
    this.form = this.fb.group(this.createFormObject());
  }

  upsert(): void {
    this.errors = {};
    if (this.validate()) {
      if (this.address) {
        // edit
        this.addressService.update(this.address.id, this.form.value).subscribe(value => {
          this.alertService.add('success', 'Adresse erfolgreich geändert!');
          this.modalService.close(value);
        }, (error) => this.errorHandler(error));
      } else {
        // create
        this.addressService.create(this.customer.id, this.form.value).subscribe(value => {
          this.alertService.add('success', 'Adresse erfolgreich erstellt!');
          this.modalService.close(value);
        }, (error) => this.errorHandler(error));
      }
    } else {
      this.alertService.add('danger', 'Es Fehlen Adressdaten!');
    }
  }

  validate() {
    const f = this.form.getRawValue();
    return f.street && f.name && f.zip && f.country && f.city;
  }

  private errorHandler(response: HttpErrorResponse): void {
    if (response.error) {
      this.errors = response.error;
    }
    this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Eingabe!');
  }

  private createFormObject(): { [key: string]: any } {
    // all possible form fields
    const fields = ['name', 'company_name', 'company_department', 'street', 'zip', 'city', 'country', 'ust'];
    const data = {};
    fields.forEach(name => {
      data[name] = this.defaultOrEmpty(name);
    });
    return data;
  }

  private defaultOrEmpty(name: string): string {
    if (this.address) {
      return this.address[name] || '';
    } else if (name === 'country') {
      return 'DEU';
    } else {
      return '';
    }
  }

}
