<div class="modal-header">
  <h3 class="modal-title">
    Bestellung Nr.:
    <span style="font-weight: bold;">{{ data.order.id }}-{{ data.order.positions.index + 1 }}</span> - Bestellte Menge:
    <strong>{{ data.order.positions.quanity }}</strong>
    - Ersteller:&nbsp;<strong>{{ data.order.username }}</strong>
  </h3>
</div>
<form class="form-horizontal">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-10">
        <div class="row">

          <div class="col-md-5">
            <div class="row">
              <div class="col-md-12">

                <div class="whitebox">
                  <h4>Artikel <span class="label label-danger" *ngIf="!!data.article.locked">Gesperrt</span></h4>
                  <div class="row">
                    <div class="col-sm-4">SDS-Nr.:</div>
                    <div class="col-sm-8">{{ data.article.sds_nr }}</div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="input-testing">
                        Prüfung:
                      </label>
                    </div>
                    <div class="col-sm-8">
                      <select id="input-testing" class="form-control" name="testing" [(ngModel)]="form.testing"
                              [ngClass]="{red_alert: errors['testing']}" *ngIf="right">
                        <option [ngValue]="null">-</option>
                        <option value="F-Muster" *ngIf="form.testing === 'F-Muster'">F-Muster</option>
                        <option value="E-Muster">E-Muster</option>
                        <option value="Serie">Serie</option>
                        <option value="Serie mit EMPB">Serie mit EMPB</option>
                      </select>
                      <span *ngIf="!right">{{ data.order_item_position.testing }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">Ausführung:</div>
                    <div class="col-sm-8">{{ data.article.data.execution }}</div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">&nbsp;</div>

            <div class="row">
              <div class="col-md-12" *ngIf="hasFrame">

                <div class="whitebox">
                  <h4>Abrufe</h4>
                  <table class="table">
                    <tbody *ngFor="let fetch of frame.fetches">
                    <tr *ngIf="fetch.status.id < 5">
                      <td>Menge: {{ fetch.quanity }}</td>
                      <td>
                        Liefertermin: {{ fetch.delivery|date: 'dd.MM.yyyy' }}
                        &nbsp;
                        <span class="label label-danger" *ngIf="fetch.earliest_delivery">Frühestmöglich</span>
                      </td>
                      <td>Status: <span
                        class="label label-{{ fetch.status.label }}">{{ fetch.status.name }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12" *ngIf="hasFrame">

                <div class="whitebox">
                  <h4>Auftragsrahmen</h4>
                  <div class="row">
                    <div class="col-sm-6">Menge:</div>
                    <div class="col-sm-6">{{ frame.frame.quanity }}</div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">Lose:</div>
                    <div class="col-sm-6">{{ frame.frame.lose }}</div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">Produzent:</div>
                    <div class="col-sm-6">{{ data.order.producer }}</div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">&nbsp;</div>

            <div class="row">
              <div class="col-md-12">

                <div class="whitebox">
                  <h4>Kunde</h4>
                  <h6>{{ customer.name }}</h6>
                  <address>
                    {{ customer.street }}<br>
                    {{ customer.zip }} {{ customer.city }}
                  </address>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="col-sm-12">
              <table class="table whitetable">
                <thead>
                <tr>
                  <th>Datecode</th>
                  <th>Menge</th>
                  <th>Lager</th>
                  <th>Lagerplatz</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let obj of groupObjects">
                  <td>{{ obj.dateCode }}</td>
                  <td>{{ obj.totalSumQuantity }}</td>
                  <td>{{ obj.storageBinName }}</td>
                  <td>{{ obj.storageBinNumber }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="clearfix">&nbsp;</div>
        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-success btn-sm" (click)="orderSplit()">
              Bestellung teilen
            </button>
          </div>
        </div>
        <div class="clearfix">&nbsp;</div>
        <div class="order-modal-row" *ngFor="let subForm of form.data; let index = index">
          <div class="row" *ngIf="index > 0">
            <div class="col-sm-12">
              <button class="btn btn-sm btn-link" (click)="orderDrop(index, subForm.id)">
                Geteilte Zeile entfernen
              </button>
            </div>
          </div>
          <new-order-box [data]="subForm"
                         [index]="index"
                         [errors]="errors"
                         [orderId]="data.order.id"
                         [orderIndex]="data.order.positions.index"
                         [history]="(subForm.id ? history[subForm.id.toString()] : [])"
                         [storage]="storage"
                         [articleLocked]="!!data.article.locked"
                         [resetStatusAvailable]="!resetStatusLocked"
                         (resetStatus)="resetStatus($event, subForm.status)">
          </new-order-box>
        </div>
      </div>
      <div class="col-md-2">
        <div class="order-modal-comment">
          <comment [comments]="comments" [typ]="'order'" [item]="item"></comment>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Schließen
    </button>
    <button type="submit" class="btn btn-success pull-right" (click)="save()" [disabled]="loading">
      Speichern
    </button>
  </div>
</form>
