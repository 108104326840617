import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  MaterialOrderIntakeGroupElement,
  MaterialOrderTransactionCreateForm,
  MaterialOrderTransactionListElement
} from '../material-order.model';
import {environment} from '../../../../../environments/environment';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {Lister} from '../../../../common/wrapper.models';
import {ListService} from '../../../../common/search/search.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialOrderIntakeService implements ListService<MaterialOrderIntakeGroupElement> {
  private uri = environment.apiv4uri + 'material/order/intake';

  constructor(protected http: HttpClient) {
  }

  list(form: any): Observable<Lister<MaterialOrderIntakeGroupElement>> {
    return this.http.get<Lister<MaterialOrderIntakeGroupElement>>(
      this.uri + '/list', {params: EnvisiaLocation.httpParams(form)}
    );
  }

  listTransactions(id: number, index: number): Observable<MaterialOrderTransactionListElement[]> {
    return this.http.get<MaterialOrderTransactionListElement[]>(this.uri + `/list/${id}/${index}`);
  }

  orderTransaction(form: MaterialOrderTransactionCreateForm): Observable<MaterialOrderTransactionListElement[]> {
    return this.http.post<MaterialOrderTransactionListElement[]>(this.uri, form);
  }

  deleteTransaction(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + `/${id}`);
  }
}
