/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../core/shared.module';
import {OrderReceivedModalModule} from '../../order-request/modal/order-received-modal.module';
import {ContractOrderBoxComponent} from './contract-order-box.component';
import {ContractOrderCountComponent} from './contract-order-count.component';
import {LoadingOrErrorModule} from '../../../../common/loading/loading-or-error.module';
import {OrderBoxModule} from '../../order-request/box/order-box.module';

@NgModule({
    imports: [
        SharedModule,
        OrderReceivedModalModule,
        LoadingOrErrorModule,
        OrderBoxModule,
    ],
    declarations: [
        ContractOrderBoxComponent,
        ContractOrderCountComponent
    ],
    exports: [
        ContractOrderBoxComponent,
        ContractOrderCountComponent
    ]
})
export class ContractOrderBoxModule {
}
