import {Component, Input, OnInit} from '@angular/core';
import {Article} from '../../models/article.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductionArticleService} from '../production-article.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';

@Component({
  selector: 'article-production-confirmation-modal',
  templateUrl: './article-production-confirmation-modal.component.html'
})
export class ArticleProductionConfirmationModalComponent implements OnInit {
  @Input() article: Article;
  @Input() hasBa: boolean;

  form: UntypedFormGroup;

  constructor(private articleProductionService: ProductionArticleService,
              private alertService: AlertService,
              private modalRef: NgbActiveModal,
              private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      ignore_existing_ba: [this.hasBa === true ? false : null, Validators.required],
    });
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

  submit(clb?: LoadingButtonEvent): void {
    this.articleProductionService.addAll(this.article.oa_nr, this.form.value).subscribe(value => {
      this.alertService.add('success', 'Fertigungsschritte erfolgreich hinzugefügt!');
      this.modalRef.close(value);
    }, (errorResponse: HttpErrorResponse) => {
      if (clb) {
        clb.callback(false);
      }
      switch (errorResponse.status) {
        case 409:
          this.alertService.add(
            'danger',
            'Es sind Betriebsaufträge mit dieser Fertigungsliste in Produktion. Bitte erneut versuchen und bestätigen.'
          );
          break;
        default:
          this.alertService.add('danger', 'Keine Fertigungsvorlage, die alle Parameter erfüllt, gefunden!');
          break;
      }
    });
  }

}
