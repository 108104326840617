import {Component, Input, OnInit} from '@angular/core';
import {PriceIncrease} from '../../price/price.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../common/alert-service/alert.service';
import {CustomerIncreaseService} from '../services/customer-increase.service';
import {CustomerIncreaseModalComponent} from './customer-increase-modal.component';
import {ignoreRejection} from '../../../helper/ignore_rejection';

@Component({
  selector: 'customer-increase',
  templateUrl: './customer-increase.component.html'
})
export class CustomerIncreaseComponent implements OnInit {
  @Input() typ: string;
  @Input() increases: PriceIncrease[] = [];
  @Input() customerId: number;
  uppercaseTyp: string;

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private customerIncreaseService: CustomerIncreaseService) {
  }

  ngOnInit(): void {
    this.uppercaseTyp = this.typ.toUpperCase();
  }

  openCreateModal(): void {
    const modalRef = this.modalService.open(CustomerIncreaseModalComponent);
    modalRef.componentInstance.typ = this.typ;
    modalRef.componentInstance.customerId = this.customerId;
    modalRef.result.then(value => {
      this.increases.unshift(value);
    }, ignoreRejection);
  }

  deletePriceIncrease(index: number, obj: PriceIncrease): void {
    if (confirm('Möchten Sie die Preiserhöhung wirklich löschen?')) {
      this.customerIncreaseService.delete(obj.id).subscribe(() => {
        this.increases.splice(index, 1);
        this.alertService.add('success', 'Preiserhöhung erfolgreich gelöscht!');
      }, () => {
        this.alertService.add('danger', 'Preiserhöhung konnte nicht gelöscht werden!');
      });
    }
  }

}
