/*
* Copyright (C) 2018 envisia GmbH
* All Rights Reserved.
*/

import Helper from '../../../../helper/helper';
import {
  ArticleBaseMaterialForm,
  ArticleBaseMaterialFormWithKey,
  ArticleSpecification
} from '../../models/article-specification.model';

// specs that use a priority for sorting ({"<name>" -> {..., "priority": <number, lowest first>}})
const specNames: string[] = ['manufacturer', 'material_internal', 'via_handling'];

export function fillData(
  spec: ArticleSpecification,
  currentKey?: string,
  filter?: (key: string) => boolean
): { key: string; value: string; }[] {
  if (!!specNames.find(sn => sn === spec.name)) {
    return sortedManufacturerSpec(spec, currentKey, filter).map(v2 => {
      return { key: v2.key, value: v2.key };
    });
  }

  const data = [];
  Helper.keys(spec.values2, (value: any, key: string) => {
    if (typeof filter !== 'function' || filter(key)) {
      data.push({key: key, value: key});
    }
  });

  if (spec.name === 'surface_area') {
    data.sort((a, b) => {
      const keyA = a.key.toLowerCase(),
        keyB = b.key.toLowerCase();
      if (keyA > keyB) { // sort string ascending
        return -1;
      } else if (keyA < keyB) {
        return 1;
      }
      return 0; // default return value (no sorting)
    });
  }
  return data;

}

export function sortedManufacturerSpec(
  spec: ArticleSpecification,
  currentKey?: string,
  filter?: (key: string) => boolean
): ArticleBaseMaterialFormWithKey[] {
  const data: ArticleBaseMaterialFormWithKey[] = [];
  Helper.keys(spec.values2, (value: ArticleBaseMaterialForm, key: string) => {
    if (
      ((value?.deleted !== true) || (key === currentKey)) &&
      ((typeof filter !== 'function') || (filter(key)))
    ) {
      data.push({key: key, data: value});
    }
  });
  data.sort((a, b) => {
    if (a.data.priority === b.data.priority) {
      return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
    } else if (Helper.undefined(a.data.priority) || Helper.undefined(b.data.priority)) {
      return Helper.undefined(a.data.priority) ? 1 : -1;
    } else {
      return a.data.priority - b.data.priority;
    }
  });

  return data;
}
