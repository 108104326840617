import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {WorkflowNavigationCreateComponent} from './workflow-navigation-create.component';
import {WorkflowNavigationComponent} from './workflow-navigation.component';
import {WorkflowDuplicateModalComponent} from './workflow-duplicate-modal/workflow-duplicate-modal.component';
import {WorkflowCreateArticleSearchModule} from '../main/workflow-create-article-search.module';

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild(),
        WorkflowCreateArticleSearchModule,
    ],
    declarations: [
        WorkflowNavigationComponent,
        WorkflowNavigationCreateComponent,
        WorkflowDuplicateModalComponent,
    ],
    exports: [
        WorkflowNavigationComponent,
        WorkflowNavigationCreateComponent,
    ]
})
export class WorkflowNavigationModule {
}
