import {AxiosHttpClient} from '../../../auth/axios-http-client';
import {Inventory} from '../inventory.models';
import {environment} from '../../../../../environments/environment';
import {ArticleInventoryTransferWorkflowFormModel} from './article-inventory-transfer-workflow-form.model';

export class ArticleInventoryTransferAxiosService {
  static transferWorkflow(oaNr: string, form: ArticleInventoryTransferWorkflowFormModel): Promise<Inventory> {
    return AxiosHttpClient.post<Inventory>(`${environment.apiv4uri}inventory/storage/${oaNr}/transfer/workflow`, form);
  }
}
