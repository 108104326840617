<div class="modal-header">
  <h3 class="modal-title">Artikel-Position hinzufügen</h3>
</div>

<form name="position" (ngSubmit)="save()" [formGroup]="form">
  <div class="modal-body">
    <div>
      Rahmen Übersicht:
      <div>{{ country }}</div>
      <div>Menge: {{ frame.quanity }}</div>
      <div>Lose: {{ frame.lose }}</div>
      <div>Einzel: {{ frame.single }}</div>
    </div>

    <label for="quanity">Menge</label>
    <input id="quanity"
           formControlName="quanity"
           class="form-control"
           type="text"
           tabindex="1"
           autocomplete="off"
           [autofocus]>

    <label for="delivery">Liefertermin</label>
    <ev-datepicker id="delivery"
                class="form-control"
                placeholder="__ . __ . _____"
                formControlName="delivery"
                [future]="true">
    </ev-datepicker>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="dismiss()" class="btn btn-default pull-left" tabindex="4">Abbrechen</button>

    <button type="submit" class="btn btn-primary" tabindex="3">Hinzufügen</button>
  </div>
</form>
