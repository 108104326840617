<div class="modal-header">
  <h3 class="modal-title">Preishistorie</h3>
</div>

<div class="modal-body">

  <table class="table whitetable">
    <thead>
    <tr>
      <th>&nbsp;</th>
      <th>Menge</th>
      <th>K.-Me</th>
      <th>Lose</th>
      <th>Freigabe</th>
      <th *ngIf="isCn"><i class="fa fa-truck"></i></th>
      <th style="font-weight: bold;">Einzel {{ typFormat }}</th>
      <th *ngIf="isCn">Einkaufspreis {{ typFormat }}</th>
      <th *ngIf="isCn">Preis %</th>
      <th>Gesamt</th>
      <th *ngIf="isCn">Wechselkurs</th>
      <th>Kalk</th>
      <th>Benutzer</th>
      <th>Datum</th>
      <th>Notiz</th>
      <th></th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let obj of objects; let i = index">
      <td>
        <i class="fa fa-exclamation-triangle" style="color: #a94442 !important;" *ngIf="!obj.valid"></i>
      </td>
      <td>{{ obj.quanity }}</td>
      <td>
        <span *ngIf="obj.calc_quanity">{{ obj.calc_quanity }}</span>
        <span *ngIf="!obj.calc_quanity">{{ obj.quanity }}</span>
      </td>
      <td>{{ obj.lose }}</td>
      <td>{{ obj.release }}</td>
      <td *ngIf="isCn">{{ obj.producer }}</td>
      <td style="font-weight: bold;">{{ obj.single }}</td>
      <td *ngIf="isCn">{{ obj.cost }}</td>
      <td *ngIf="isCn">{{ obj.price_reduction }}</td>
      <td>{{ obj.multi }}</td>
      <td *ngIf="isCn">{{ obj.exchange }}</td>
      <td>{{ obj.calc }}</td>
      <td>{{ obj.username }}</td>
      <td>{{ obj.date|date:"dd.MM.yyyy HH:mm:ss" }}</td>
      <td>{{ obj.notes }}</td>
      <td>
        <button type="button"
                class="btn btn-sm btn-default"
                (click)="reset(obj)"
                *ngIf="(i !== 0)">
          Zurücksetzen
        </button>
      </td>
    </tr>
    </tbody>
  </table>

</div>

<div class="modal-footer">
</div>
