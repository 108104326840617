import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BusinessOrder, BusinessOrderAdditional, BusinessOrderReproduction} from '../models/business-order.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderDateChangeComponent} from '../date-change/business-order-date-change.component';
import {FetchPositionWithFetchAndFrame} from '../../models/fetch.models';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {BusinessOrderGoodpieces} from './goodpiece-box/business-order-view-goodpiece-box.models';

@Component({
  selector: 'business-order-view-box',
  templateUrl: './business-order-view-box.component.html',
})
export class BusinessOrderViewBoxComponent {
  @Input() ba: BusinessOrder;
  @Input() additional: BusinessOrderAdditional;
  @Input() fetches: FetchPositionWithFetchAndFrame[];
  @Input() reproduction?: BusinessOrderReproduction;
  @Input() goodpieces: LoadingWrapper<BusinessOrderGoodpieces>;
  @Output() goodpiecesChange: EventEmitter<BusinessOrderGoodpieces> = new EventEmitter<BusinessOrderGoodpieces>();

  constructor(private modalService: NgbModal) {
  }

  openDateChangeModal(): void {
    const modalRef = this.modalService.open(BusinessOrderDateChangeComponent);
    modalRef.componentInstance.ba = this.ba;
  }

}
