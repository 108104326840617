import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable, of} from 'rxjs';
import {InvoiceEpostSendModalComponent} from './invoice-epost-send-modal.component';
import {InvoiceState} from '../invoice.models';
import {InvoiceSendService} from '../services/invoice-send.service';
import {catchError, mergeMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class InvoiceSendModalService {

  constructor(private modalService: NgbModal,
              private invoiceSendService: InvoiceSendService) {
  }

  open(invoiceId: number, flipper: (state: InvoiceState) => void): Observable<void> {
    return this.invoiceSendService.postValue().pipe(
      catchError(() => {
        return of({enabled: false, active: false});
      }),
      mergeMap(value => {
        const modalRef = this.modalService.open(InvoiceEpostSendModalComponent);
        modalRef.componentInstance.id = invoiceId;
        modalRef.componentInstance.active = value.active && value.enabled;
        modalRef.componentInstance.flipper = flipper;
        return from(modalRef.result);
      })
    );
  }

}
