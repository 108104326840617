<div class="modal-header">
  <h3 class="modal-title">
    Nachfertigung {{reproduction.id}}-{{reproduction.index}}
    wird mit BA {{reproduction.original_id}}-{{reproduction.original_index}}
    zusammengefügt
  </h3>
  <div>
    Diese Aktion kann nicht rückgängig gemacht werden.
  </div>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">
    <div class="form-group">
      <label class="col-md-2 control-label" for="note">Bemerkung:</label>

      <div class="col-md-10">
      <textarea id="note" class="form-control" formControlName="note" rows="5"></textarea>
      </div>
    </div>

    <div class="red_alert" *ngIf="!!errorType">
      Es ist ein Fehler aufgetreten:
      <ng-container [ngSwitch]="errorType">
        <div *ngSwitchCase="'ba.source.wrong.step'">
          Der Zusammenfügungspunkt wurde noch nicht erreicht.
        </div>
        <div *ngSwitchCase="'ba.reproduction.no.mergepoint'">
          Die Nachfertigung hat keinen Zusammenfügungspunkt.
        </div>
        <div *ngSwitchCase="'ba.source.closed'">
          Diese Nachfertigung wurde bereits zusammengefügt, storniert oder gelöscht.
        </div>
        <div *ngSwitchCase="'ba.source.not.found'">
          Konnte Nachfertigung nicht finden.
        </div>
        <div *ngSwitchCase="'ba.target.not.found'">
          Konnte den Hauptbetriebsauftrag nicht finden.
        </div>
        <div *ngSwitchCase="'ba.target.closed'">
          Der Hauptbetriebsauftrag wurde storniert oder gelöscht.
        </div>
        <div *ngSwitchCase="'ba.reproduction.has.dependencies'">
          Es gibt noch Nachfertigungen für diesen Betriebsauftrag.
          Bitte fügen Sie diese zuerst zu diesem hinzu.
        </div>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-default pull-left" (click)="dismiss()" type="button">
      Abbrechen
    </button>
    <button class="btn btn-success pull-right" type="submit" [disabled]="!form.valid">
      Nachfertigung mit Haupt BA zusammenfügen
    </button>
  </div>
</form>
