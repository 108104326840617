import * as React from 'react';
import {MaterialSupplierSelectCombobox} from './material-supplier-select-combobox';
import {useMaterialSupplierSelect} from './material-supplier-select-hook';
import {EvLoadingSpinnerElement} from '../../components/ev-loading-spinner-element';

interface Props {
  groupId: number;
  supplierId: number | null;
  supplierIdChanged: (supplierId: number | null) => void;
}

export function MaterialSupplierSelect(props: Props): JSX.Element {
  const state = useMaterialSupplierSelect(props);
  if (state.state.preselectedLoading) {
    return <EvLoadingSpinnerElement/>;
  } else {
    return <MaterialSupplierSelectCombobox state={state}/>;
  }
}
