<div class="page-header">
  <h3>Variationen</h3>
</div>
<table class="table">
  <thead>
  <tr>
    <th>Interne Nr.</th>
    <th>Art-Nr. Lieferant</th>
    <th>Preis</th>
    <th>Einheit</th>
    <th>Bevorzugt</th>
    <th>Mindestbestand</th>
    <th>Mindestbestellung</th>
    <th>Beschaffungszeit</th>
    <th>EAN</th>
    <th *ngFor="let field of variationFieldDefs">{{field.name}}</th>
    <th class="icon">
    </th>
    <th class="icon">
      <a (click)="addVariationRow()" class="click"><i class="fa fa-plus green"></i></a>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let obj of variationArray; trackBy: trackByFn; let i = index"
      [material-variation-row]="obj.variation"
      [material-variation-row-form]="obj.form"
      [material-variation-row-index]="i"
      [material-variation-row-current-article]="currentArticle"
      [material-variation-row-field-def]="variationFieldDefs"
      [material-variation-row-error]="obj.error"
      [material-variation-row-units]="units"
      (material-variation-row-delete-row)="deleteRow($event)"
      [ngClass]="{'red_alert' : obj.isRowErrorType}">
  </tr>
</table>
