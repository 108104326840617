import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
  MaterialArticle,
  MaterialArticleError,
  MaterialVariationError,
} from '../material-management.model';

@Component({
  selector: 'material-article-error-message',
  templateUrl: './material-article-error-message.component.html'
})
export class MaterialArticleErrorMessageComponent {
  @Input() form: UntypedFormGroup;
  @Input() error: MaterialArticleError;

  constructor() {
  }

  getVariationErrors(): MaterialVariationError[] | null {
    if (this.error && this.error.error_type === 'VariationsError' && this.error.data) {
      return this.error.data as MaterialVariationError[];
    } else {
      return null;
    }
  }

  getArticleError(): MaterialArticleError | null {
    if (this.error && this.error.error_type === 'ArticleError' && this.error.data) {
      return this.error.data as MaterialArticleError;
    } else {
      return null;
    }
  }

  getExistingArticle(): MaterialArticle | null {
    return this.getArticleError().data as MaterialArticle;
  }
}
