import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {WorkflowStateComponent} from './workflow-state.component';
import {WorkflowArticleStateComponent} from './workflow-article-state.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    WorkflowStateComponent,
    WorkflowArticleStateComponent
  ],
  exports: [
    WorkflowStateComponent,
    WorkflowArticleStateComponent
  ]
})
export class WorkflowPipesModule {
}
