import {SearchBaseComponent} from '../../../common/search/search.component';
import {PriceService} from '../services/price.service';
import {PriceListData} from '../price.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Lister} from '../../../common/wrapper.models';
import {Component, ErrorHandler, Input} from '@angular/core';
import {WorkflowStatusModalService} from '../../workflow/status-modal/workflow-status-modal.service';

@Component({
  selector: 'price-list',
  templateUrl: './price-list.component.html'
})
export class PriceListComponent extends SearchBaseComponent<PriceService, PriceListData> {
  @Input() protected listData: Lister<PriceListData>;
  protected type = 'price';

  constructor(protected service: PriceService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private workflowStatusModalService: WorkflowStatusModalService) {
    super();
  }

  open(index: number, id: number): void {
    this.workflowStatusModalService.open(id, 'delete').subscribe(() => {
      this.list.objects.splice(index, 1);
    }, () => {
    });
  }


}
