import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, UntypedFormGroup} from '@angular/forms';
import {CalculationProcess} from '../../envisia/price/price.models';

@Component({
  selector: 'price-selector',
  templateUrl: './price-selector.component.html',
})
export class PriceSelectorComponent implements OnChanges, ControlValueAccessor {
  @Input() processes: CalculationProcess[];
  @Input() form: UntypedFormGroup;
  @Input() name: string;
  @Input() error = false;
  @Input() nullable = false;
  @Input() errorClass = 'red_alert';
  clazzes = '';

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error) {
      if (changes.error.currentValue) {
        this.clazzes = this.errorClass;
      } else {
        this.clazzes = '';
      }
    }
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
