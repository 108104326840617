<div class="whitebox pricecheckform pricecheck-box">
  <form [formGroup]="form" role="form">
    <div class="row">

      <div class="col-md-12">
        <h3>Preisprüfung</h3>
      </div>

      <div class="col-md-12">
        <div class="pricecheck-box-col-20">
          <div class="form-group">
            <input [inputSelect] class="form-control" formControlName="quantity" id="pricecheck-quanity" min="0"
                   placeholder="Menge" type="number">
          </div>
        </div>
        <div class="pricecheck-box-col-20">
          <div class="form-group">
            <input [inputSelect] class="form-control" formControlName="release" id="pricecheck-release" min="0"
                   placeholder="Freigabe" type="number">
          </div>
        </div>
        <div class="pricecheck-box-col-20">
          <div class="form-group">
            <button type="button"
                    class="btn btn-primary"
                    style="width: 100%;"
                    [disabled]="lock"
                    (click)="calculate(true)">
              <i class="fa fa-refresh"></i>
            </button>
          </div>
        </div>
        <div class="pricecheck-box-col-20">
          <div class="form-group">
            <button (click)="save('de')" *ngIf="responseData.german && ('price.change'|HasPerm) && !blockSave" class="btn btn-success" style="width: 100%;"
                    type="button">
              DE
            </button>
          </div>
        </div>
        <div class="pricecheck-box-col-20">
          <div class="form-group">
            <button (click)="save('cn')" *ngIf="responseData.china && ('price.change'|HasPerm) && !blockSave" class="btn btn-success" style="width: 100%;"
                    type="button">
              CN
            </button>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="whitebox-inner storzgreen">
          <h3>Deutschland</h3>
          <div class="pricecheck-box-row">
            <div class="pricecheck-box-col" style="width: 75px;">
              <label for="pricecheck-calc-quanity">Kalk-Me:</label><br>
              <input [inputSelect] class="form-control" formControlName="calc_quantity_de"
                     id="pricecheck-calc-quanity" type="number">
              <span *ngIf="!responseData.calc_quantity_found" class="red_alert">
                Kalk-Me nicht gefunden!
              </span>
            </div>
            <div class="pricecheck-box-col" style="width: 50px;">
              <label for="pricecheck-lose-de">Lose:</label><br>
              <input [inputSelect] class="form-control" formControlName="lose_de"
                     id="pricecheck-lose-de" type="number">
            </div>
            <div class="pricecheck-box-col" style="width: 110px;">
              <label>Kalk:</label><br>

              <select class="form-control" formControlName="calc_de" id="id_germany" name="germany">
                <option [ngValue]="null" label="deaktivieren"></option>
                <option label="manuell" value="manuell">Manuell</option>
                <ng-container *ngFor="let obj of processes.de">
                  <option *ngIf="!obj.hidden || form.value['calc_de'] === obj.name" [value]="obj.name">
                    {{ obj.name }}
                  </option>
                </ng-container>
              </select>
            </div>
            <div class="pricecheck-box-col" style="width: 80px;">
              <label for="pricecheck-de-price"><strong>Einzel:</strong></label><br>

              <span class="input-group">
                <input [inputSelect] class="form-control" formControlName="price_de"
                       id="pricecheck-de-price" type="text">
                <span class="input-group-addon" id="basic-addon">€</span>
              </span>
            </div>
          </div>
          <div class="pricecheck-box-row">
            <div class="pricecheck-box-col" style="width: 75px; display: none;">
              <label>Kalk-Me:</label><br>
              <span *ngIf="responseData.german">{{ responseData.german.calc_quanity }}</span>
            </div>
            <div class="pricecheck-box-col" style="width: 90px;">
              <label>Gesamt:</label><br>
              <span *ngIf="responseData.german">{{ responseData.german.total_price }} €</span>
            </div>
            <div class="pricecheck-box-col" style="width: 75px;">
              <label>Nebenk.:</label><br>
              <span *ngIf="responseData.german">{{ responseData.german.tooling }} €</span>
            </div>
            <div class="pricecheck-box-col" style="width: 75px;">
              <label>Adapterk.:</label><br>
              <span *ngIf="responseData.german">{{ responseData.german.adapter }} €</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="whitebox-inner storzblue">
          <h3>China</h3>

          <div class="pricecheck-box-row">
            <div class="pricecheck-box-col" style="width:60px;">
              <div class="form-group">
                <label>Kurs:</label>
                <input [inputSelect] class="form-control" formControlName="exchange_rate"
                       id="pricecheck-exchange-rate" type="text">
              </div>
            </div>
            <div class="pricecheck-box-col" style="width: 50px;">
              <div class="form-group">
                <label for="pricecheck-lose-cn">Lose:</label><br>
                <input [inputSelect] class="form-control" formControlName="lose_cn"
                       id="pricecheck-lose-cn" type="number">
              </div>
            </div>

            <div class="pricecheck-box-col" style="width: 100px;">
              <div class="form-group">
                <label>Kalk:</label><br>
                <select class="form-control" formControlName="calc_cn" id="id_china" name="china">
                  <option [ngValue]="null" label="deaktivieren"></option>
                  <option label="manuell" value="manuell">Manuell</option>
                  <ng-container *ngFor="let obj of processes.cn">
                    <option *ngIf="!obj.hidden || form.value['calc_cn'] === obj.name" [value]="obj.name">
                      {{ obj.name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="pricecheck-box-col" style="width: 85px;">
              <div class="form-group ">
                <label for="pricecheck-cn-price"><strong>Einzel:</strong></label><br>
                <span class="input-group">
                  <input [inputSelect] class="form-control" formControlName="price_cn"
                         id="pricecheck-cn-price" type="text">
                    <span class="input-group-addon" id="basic-addon2">€</span>
                  </span>
              </div>
            </div>
          </div>
          <div class="pricecheck-box-row">

            <div class="pricecheck-box-col" style="width: 90px;">
              <div class="form-group ">
                <label>Gesamt:</label><br>
                <span *ngIf="responseData.china">{{ responseData.china.total_price }} €</span>
              </div>
            </div>
            <div class="pricecheck-box-col" style="width: 75px;">
              <div class="form-group">
                <label>Nebenk.:</label><br>
                <span *ngIf="responseData.china">{{ responseData.china.tooling }} €</span>
              </div>
            </div>
            <div class="pricecheck-box-col" style="width: 75px;">
              <div class="form-group">
                <label>Kalk-Me:</label><br>
                <span *ngIf="responseData.china">{{ responseData.china.calc_quanity }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <article-weight [oaNr]="article.oa_nr" [amount]="form.value.quantity"></article-weight>
  </form>
</div>
