import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../../core/shared.module';
import {WorkflowNavigationModule} from '../../navigation/workflow-navigation.module';
import {CommentModule} from '../../../comment/comment.module';
import {ArticleBoxModule} from '../../../article/box/article-box.module';
import {CustomerBoxModule} from '../../../customer/box/customer-box.module';
import {ContactBoxModule} from '../../../customer/contact-box/contact-box.module';
import {LoadingOrErrorModule} from '../../../../common/loading/loading-or-error.module';
import {UIRouterModule} from '@uirouter/angular';
import {OfferDetailBaseComponent} from './base/offer-detail-base.component';
import {PositionHolderComponent} from './position-holder/position-holder.component';
import {PositionReadonlyBoxComponent} from './position-readonly-box/position-readonly-box.component';
import {PositionChangeModalComponent} from './position-change-modal/position-change-modal.component';
import {PositionAddModalComponent} from './position-add-modal/position-add-modal.component';
import {PositionExtraModalComponent} from './position-extra-modal/position-extra-modal.component';
import {PositionExtraErrorModalComponent} from './position-extra-error-modal/position-extra-error-modal.component';
import {OfferDetailFormComponent} from './form/offer-detail-form.component';
import {OFFER_DETAIL_STATES} from './offer-detail.states';
import { PositionBoxListComponent } from './position-box-list/position-box-list.component';
import { PositionBoxListItemComponent } from './position-box-list-item/position-box-list-item.component';
import {WorkflowPipesModule} from '../../pipes/workflow-pipes.module';
import {PriceSelectorModule} from '../../../../common/price-selector/price-selector.module';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: OFFER_DETAIL_STATES }),
        NgbModalModule,
        SharedModule,
        WorkflowNavigationModule,
        CommentModule,
        ArticleBoxModule,
        CustomerBoxModule,
        ContactBoxModule,
        LoadingOrErrorModule,
        WorkflowPipesModule,
        PriceSelectorModule,
    ],
    declarations: [
        OfferDetailBaseComponent,
        OfferDetailFormComponent,
        PositionHolderComponent,
        PositionReadonlyBoxComponent,
        // Modals
        PositionChangeModalComponent,
        PositionAddModalComponent,
        PositionExtraModalComponent,
        PositionExtraErrorModalComponent,
        PositionBoxListComponent,
        PositionBoxListItemComponent,
    ]
})
export class OfferDetailModule {
}
