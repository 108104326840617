<div class="readonly-box"
     [ngClass]="{bold: data.state.id === -100}"
     *ngIf="!('invoice.target.date.edit'|HasPerm)">
  <div class="date-box">
    {{data.target_date ?? data.due_date}}
  </div>

  <div class="button-box" [ngbTooltip]="'Fälligkeit wurde manuell gesetzt'" *ngIf="!!data.target_date">
    <i class="fa fa-info-circle"></i>
  </div>
</div>

<div class="editable-box"
     [ngClass]="{bold: data.state.id === -100}"
     *ngIf="!edit && ('invoice.target.date.edit'|HasPerm)">
  <div class="date-box">
    {{data.target_date ?? data.due_date}}
  </div>

  <div class="button-box">
    <button type="button"
            class="btn btn-sm btn-default"
            (click)="toggleEdit()"
            [ngbTooltip]="'Fälligkeit bearbeiten'">
      <i class="fa fa-pencil"></i>
    </button>
  </div>
</div>

<div class="editable-box" *ngIf="edit">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="date-box">
      <ev-datepicker allDates="true"
                     mode="day"
                     class="form-control form-control-sm"
                     formControlName="target_date">
      </ev-datepicker>
    </div>

    <div class="button-box">
      <button type="submit" class="btn btn-success btn-sm">
        <i class="fa fa-save"></i>
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="toggleEdit()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </form>
</div>
