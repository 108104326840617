import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {MaterialGroup} from './material-group.model';
import {ListService} from '../../../common/search/search.service';
import {Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({
  providedIn: 'root'
})
export class MaterialGroupListAllService implements ListService<MaterialGroup> {
  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<MaterialGroup>> {
    return this.http.get<Lister<MaterialGroup>>(
      `${environment.apiv4uri}material/groups/list/all`,
      {params: EnvisiaLocation.httpParams(query)},
    );
  }
}
