<div class="page-header">

  <h3>Artikel löschen</h3>

</div>

<div class="row">
  <div class="col-md-12">
    <p style="font-size: 14px;">
      Mit dieser Funktion kann eine Liste mit Artikeln auf gelöscht gesetzt werden.<br>
      <br>
      1. <strong>CSV</strong> Datei erstellen. In dieser Datei darf es nur <strong>eine</strong> Spalte mit der SDS Nummer geben.
      <strong>Es darf keine Kopfzeile in der Spalte vorhanden sein.</strong>
      <br>
      2. Datei über den Button "Durchsuchen" auswählen.<br>
      3. Mit Button "Ausführen" werden alle Artikel, die in der Liste aufgelistet sind, auf gelöscht gesetzt.<br>
      <br>
      <strong>Dieser Vorgang kann nicht rückgängig gemacht werden.</strong>
    </p>
  </div>

  <div class="col-md-12" style="margin-top: 30px;">
    <input type="file"  #fileInput>
  </div>

  <div class="col-md-12" style="margin-top: 15px;">
    <button (click)="upload()" class="btn btn-success">Ausführen</button>
  </div>

  <div class="col-md-12" style="margin-top: 30px; font-size: 19px;">
    <div *ngIf="inProgress">
      Vorgang wird ausgeführt. Bitte warten.. <i class="fa fa-circle-o-notch fa-spin"></i>
    </div>

    <div *ngIf="isFinished" style="color:#449D44;">
      Vorgang abgeschlossen.
    </div>
  </div>
</div>
