import {Component, Input, OnInit} from '@angular/core';
import {PositionService} from '../../../workflow-services/position.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProcessService} from '../../../../price/services/process.service';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';
import {CalculationProcess} from '../../../../price/price.models';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'position-change-modal',
  templateUrl: './position-change-modal.component.html',
})
export class PositionChangeModalComponent implements OnInit {
  @Input() data: BigWorkflowOffer;
  processesData: LoadingWrapper<{ de: CalculationProcess[], cn: CalculationProcess[] }>;
  form: UntypedFormGroup;
  send = false;

  constructor(private positionService: PositionService,
              private processService: ProcessService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.processesData = new LoadingWrapper(this.processService.list());
    this.form = this.fb.group({
      'de': [this.data.article.calc_de, Validators.required],
      'cn': [this.data.article.calc_chn, Validators.required],
    });
  }

  submit(): void {
    this.send = true;
    const returnCode = window.confirm('Sind Sie sich sicher, dass Sie diese Änderungen übernehmen wollen? ' +
      'Alle Preise / Kalkulationen werden ohne Rückfrage überschrieben!'
    );

    if (returnCode) {
      this.positionService.changeAll(this.data.workflow.object.id, this.form.value).subscribe(response => {
        this.activeModal.close(response);
        this.send = false;
      }, (response: HttpErrorResponse) => {
        this.send = false;
        if ('obj.customer' in response.error) {
          this.alertService.add(
            'danger',
            'Positionen Kalkulation ändern nicht möglich, da Artikel kein Kunde hinterlegt hat!'
          );
        }
      });
    }
  }

}
