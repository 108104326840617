<form class="searchForm" #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
      <tr>
        <th>
          <a (click)="sort($event, 'article_sds_nr', 'ASC')" [href]="sortUrl('article_sds_nr', 'ASC')">
            SDS-Nr.
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'workflow_id', 'ASC')" [href]="sortUrl('workflow_id', 'ASC')">
            Vorgang
          </a>
        </th>

        <th>
          <a (click)="sort($event, 'article_oa_nr', 'ASC')" [href]="sortUrl('article_oa_nr', 'ASC')">
            0A-Nr.
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'article_kd_art_nr', 'ASC')" [href]="sortUrl('article_kd_art_nr', 'ASC')">
            Kd-Art-Nr.
          </a>
        </th>
        <th>Kunde</th>
        <th>Bearbeiter</th>
        <th>&nbsp;</th>
      </tr>
      <tr class="filterrow">
        <th>
          <input [(ngModel)]="data.sds_nr" name="sds_nr" type="text" class="form-control input-sm">
        </th>
        <th>
          <input [(ngModel)]="data.workflow_id" name="workflow_id" type="text" class="form-control input-sm" [autofocus] autocomplete="off search-workflow-id">
        </th>

        <th>
          <input [(ngModel)]="data.oa_nr" name="oa_nr" type="text" class="form-control input-sm">
        </th>
        <th>
          <input [(ngModel)]="data.kd_art_nr" name="kd_art_nr" type="text" class="form-control input-sm">
        </th>


        <th>
          <input [(ngModel)]="data.customer_name" name="customer_name" type="text" class="form-control input-sm">
        </th>
        <th>
          <input [(ngModel)]="data.username" name="username" type="text" class="form-control input-sm">
        </th>

        <th>&nbsp;</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let object of list.objects; let index = index">
        <td>
          <a uiSref="a.price.check" [uiParams]="{id: object.oa_nr}">
            <strong>{{ object.sds_nr }}</strong>
          </a>
        </td>
        <td>
          <a uiSref="a.workflow.detail" [uiParams]="{id: object.workflow_id}">
            {{ object.workflow_id }}
          </a>
        </td>

        <td>{{ object.oa_nr }}</td>
        <td>
          <a uiSref="a.price.check" [uiParams]="{id: object.oa_nr}">
            {{ object.kd_art_nr }}
            <span *ngIf="object.kd_art_nr && object.kd_art_index" class="label label-default">
             {{ object.kd_art_index }}
            </span>
          </a>
        </td>

        <td>
          <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}" target="_blank">
            {{ object.customer_name }}
          </a>
        </td>
        <td><a [href]="'mailto:' + object.email">{{ object.username }}</a></td>
        <td>
          <button type="button" class="btn btn-danger btn-sm" (click)="open(index, object.workflow_id)">
            Vorgang löschen
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
