import {Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {ArticleListData} from '../../article/models/article.models';
import {Customer} from '../../customer/model/customer';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from} from 'rxjs';
import {LabelArticleSelectModalComponent} from './label-article-select-modal.component';
import Helper from '../../../helper/helper';

export const EV_LABEL_ARTICLE_BOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LabelArticleFormInputComponent),
  multi: true,
};

export const EV_LABEL_ARTICLE_BOX_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => LabelArticleFormInputComponent),
  multi: true,
};

@Component({
  selector: 'label-article-box',
  templateUrl: './label-article-form-input.component.html',
  providers: [EV_LABEL_ARTICLE_BOX_VALUE_ACCESSOR, EV_LABEL_ARTICLE_BOX_VALIDATOR]
})
export class LabelArticleFormInputComponent implements ControlValueAccessor, Validator {
  @Output() updateEmitter = new EventEmitter<{article: ArticleListData, customer?: Customer} | null>();
  article: ArticleListData | null;
  disabledDismiss = false;
  formValidationError = false;
  formDisabled = false;

  private formInit = false;
  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  constructor(private modalService: NgbModal) {
  }

  switchArticle(): void {
    const dismissFn = () => {
      return !this.disabledDismiss;
    };
    const modalRef = this.modalService.open(LabelArticleSelectModalComponent, {beforeDismiss: dismissFn});
    modalRef.componentInstance.currentArticle = this.article;
    modalRef.componentInstance.disableDismissFn = (value: boolean) => this.disabledDismiss = value;
    from(modalRef.result).subscribe(result => {
      console.log('Result:', result);
      this.changeArticle(result);
    }, () => {
    });
  }

  /**
   * Start Form Stuff
   */

  registerOnChange(fn: any): void {
    this._onChange = fn;
    if (!this.formInit) {
      this.formInit = true;
      // write back form values
      // on form register
      if (this.article) {
        this._onChange(this.article.oa_nr);
      } else {
        this._onChange(null);
      }
    }
  }

  writeValue(obj: any): void {
    if (!Helper.undefined(obj) && obj.hasOwnProperty('id') && obj.hasOwnProperty('oa_nr')) {
      this.article = obj;
    }

    this.article = null;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.formValidationError = control.errors !== null;
    return null;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.formDisabled = isDisabled;
  }

  private changeArticle(data: {article: ArticleListData, customer?: Customer} | null): void {
    this.article = data.article;
    this._onChange(data.article.oa_nr);
    this.updateEmitter.next(data);
  }
}
