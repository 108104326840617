import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {INVOICE_STATES} from './invoice.states';
import {InvoiceAutomationComponent} from './automation/invoice-automation.component';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {WorkflowNavigationModule} from '../navigation/workflow-navigation.module';
import {InvoiceListComponent} from './list/invoice-list.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceCorrectionModule} from './correction/invoice-correction.module';
import {InvoiceCreateComponent} from './create/invoice-create.component';
import {InvoiceCreateCorrectionModalComponent} from './create/invoice-create-correction-modal.component';
import {InvoiceEpostSendModalComponent} from './send/invoice-epost-send-modal.component';
import {InvoiceJobErrorListComponent} from './job-error/invoice-job-error-list.component';
import {LoadingOrErrorModule} from '../../../common/loading/loading-or-error.module';
import {InvoiceSendModalComponent} from './send/invoice-send-modal.component';

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({ states: INVOICE_STATES }),
        NgbNavModule,
        NgbTooltipModule,
        WorkflowPipesModule,
        WorkflowNavigationModule,
        InvoiceCorrectionModule,
        LoadingOrErrorModule,
    ],
    declarations: [
        InvoiceAutomationComponent,
        InvoiceListComponent,
        InvoiceCreateComponent,
        InvoiceJobErrorListComponent,
        // Modals
        InvoiceSendModalComponent,
        InvoiceEpostSendModalComponent,
        InvoiceCreateCorrectionModalComponent
    ]
})
export class InvoiceModule {
}
