import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Offer} from '../../models/offer.models';
import {Workflow} from '../../models/workflow.models';
import {DeliveredFrame, MergedFrame} from '../../models/frame.models';
import {Article} from '../../../article/models/article.models';
import {Contract} from '../../models/contract.models';
import {MailModalService} from '../../../mail/mail-modal.service';
import {DateHelper} from '../../../../helper/date-helper';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {PdfHistoryModalService} from '../../../history/pdf-history-modal.service';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';

@Component({
  selector: 'contract-create-list',
  templateUrl: './contract-create-list.component.html'
})
export class ContractCreateListComponent {
  @Input() workflow: { delivered: DeliveredFrame; frame?: MergedFrame; object: Workflow; };
  @Input() article: Article;
  @Input() lastOffer?: Offer;
  @Input() contractList: Contract[];
  @Input() priceIncreaseFlex?: PriceIncreaseFlex;
  @Output() updatePriceIncreaseFlex: EventEmitter<PriceIncreaseFlex> = new EventEmitter<PriceIncreaseFlex>();

  constructor(private mailModalService: MailModalService,
              private pdfHistoryModalService: PdfHistoryModalService) {
  }

  historyContract(contract: Contract): void {
    this.pdfHistoryModalService.open('contract', contract.id, 'Auftragshistorie').subscribe(
      ignoreRejection,
      ignoreRejection
    );
  }

  mail(contract: Contract): void {
    this.mailModalService.open(
      contract.id,
      'contract',
      null,
      null,
      'de',
      this.workflow.object.id,
      true,
      contract.typ
    ).subscribe(() => {
      contract.date_sent = DateHelper.format(new Date());
    }, ignoreRejection);
  }

}
