import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Address} from '../model/address';

@Component({
  selector: 'customer-map',
  template: '<iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="data"></iframe>'
})
export class CustomerMapComponent implements OnChanges {
  @Input() address: Address;
  data: string;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address) {
      this.data = this.createHtml(changes.address.currentValue) as string;
    }
  }

  private createHtml(address: Address): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.google.com/maps/embed/v1/place?key=AIzaSyDLJ5uRfjIc25qs8r22x5QUgiNzZOVUgBA&q=' +
      address.street + ', ' + address.zip + ' ' + address.city);
  }

}
