import * as React from 'react';
import {ReactNode} from 'react';

interface Props {
  nameValue: string;
  nameLabel: string;
  columnPartsLabel: number;
  columnParts: number;
  children: ReactNode | ReactNode[];
}
export function EvFormGroup(props: Props) {
  return <>
    <div className='form-group'>
      <div className='row'>
        <div className={`col-sm-${props.columnPartsLabel}`}>
          <label className='control-label' htmlFor={props.nameValue}>{props.nameLabel}</label>
        </div>
        <div className={`col-sm-${props.columnParts}`}>
          {props.children}
        </div>
      </div>
    </div>
  </>;
}
