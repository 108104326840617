import {Component, Input} from '@angular/core';
import {CustomerComment} from '../model/customer-comment';
import {Customer} from '../model/customer';

@Component({
  selector: 'customer-comment',
  templateUrl: './customer-comment.component.html',
})
export class CustomerCommentComponent {
  @Input() comments: CustomerComment[];
  @Input() customer: Customer;

  constructor() {
  }

}
