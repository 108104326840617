<div class="red_alert">
  <b>Achtung!</b> Artikel kann nicht gespeichert werden, da es bereites einen Artikel mit der gleichen Bezeichnung gibt:

  <div>&nbsp;</div>
  <div>&nbsp;</div>

  <div *ngFor="let obj of duplicates" class="top-buffer">
    <div class="row">
      <div class="col-md-6">
        <a [href]="'#!/article/overview/' + obj.oa_nr" target="_blank">SDS-Nr.: {{ obj.sds_nr }}</a>
      </div>
      <div class="col-md-6">
        <a [href]="'#!/article/overview/' + obj.oa_nr" target="_blank">0A-Nr.: {{ obj.oa_nr }}</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">Kunden-Art-Nr.: {{ obj.kd_art_nr }}</div>
      <div class="col-md-6">Kunden-Index: {{ obj.kd_art_index }}</div>
    </div>
  </div>
</div>
