<div class="modal-header">
  <div class="modal-title">
    <h3>Multilayer Aufbauplan kopieren</h3>
  </div>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="submitRequest()">
  <div class="modal-body">
    <div class="row">
      <label class="control-label col-sm-2">Suchen mit:</label>
      <div class="col-sm-3">
        <button type="button"
                class="btn btn-default"
                style="width: 120px;"
                [ngClass]="{'active': withArticle}"
                (click)="setWithArticle(true)">
          Artikel
        </button>
      </div>
      <div class="col-sm-3">
        <button type="button"
                class="btn btn-default"
                style="width: 120px;"
                [ngClass]="{'active': !withArticle}"
                (click)="setWithArticle(false)">
          Name
        </button>
      </div>
    </div>

    <div class="row" *ngIf="withArticle">
      <label class="control-label col-sm-2" for="oa_nr">OaNr:</label>
      <div class="col-md-10">
        <input type="text" id="oa_nr" class="form-control" formControlName="oa_nr">
      </div>
    </div>

    <div class="row" *ngIf="!withArticle">
      <label class="control-label col-sm-2" for="name">Name:</label>
      <div class="col-sm-10">
        <input type="text" id="name" class="form-control" formControlName="name">
      </div>
    </div>

    <table class="table whitetable">
      <thead>
      <tr>
        <th>{{withArticle ? 'OaNr' : 'Name'}}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="click" (click)="select(plan)" *ngFor="let plan of list?.objects">
        <td [ngClass]="{'storzgreen': plan.id === selection?.id}">{{withArticle ? plan.oa_nr : plan.name}}</td>
      </tr>
      </tbody>
    </table>
    <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query" (goto)="goto($event)"></ev-navigation>
  </div>

  <div class="modal-footer">
    <div class="red_alert" style="text-align: center;">
      Der kopierte Multilayer Aufbauplan wird den momentanen Aufbauplan ersetzen.
      <br />
      Nicht gespeicherte &Auml;nderungen im momentanen Aufbauplan gehen verloren.
      <br />
      Es muss jedoch gespeichert werden um die &Auml;nderungen wirksam zu machen.
    </div>
    <button type="button" class="btn btn-default pull-left" (click)="dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-success pull-right" [disabled]="!this.selection" (click)="apply()">
      Übernehmen
    </button>
  </div>
</form>

