import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable} from 'rxjs';
import {WorkflowPositionView} from '../../../models/position.models';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {PositionAddModalComponent} from './position-add-modal.component';

@Injectable({providedIn: 'root'})
export class OfferDetailNewPositionAddModalService {

  constructor(private modalService: NgbModal) {
  }

  open(data: BigWorkflowOffer): Observable<WorkflowPositionView> {
    const modalRef = this.modalService.open(PositionAddModalComponent, {windowClass: 'modal2-normal'});
    modalRef.componentInstance.data = data;
    return from(modalRef.result);
  }
}
