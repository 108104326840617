import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {ProductionStepEntity, ProductionStepForm} from '../production/production.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProductionStepService {

  private uri = '/api/v1/settings/production/step/'; // :param1/:id/'

  constructor(private http: HttpClient) {
  }

  list(): Observable<ProductionStepEntity[]> {
    return this.http.get<EnvisiaObjects<ProductionStepEntity>>(this.uri).pipe(map(value => value.objects));
  }

  create(form: ProductionStepForm): Observable<ProductionStepEntity> {
    return this.http.post<EnvisiaObject<ProductionStepEntity>>(this.uri, form).pipe(map(value => value.object));
  }

  edit(id: number, form: ProductionStepForm): Observable<ProductionStepEntity> {
    return this.http.put<EnvisiaObject<ProductionStepEntity>>(this.uri + id + '/', form).pipe(
      map(value => value.object)
    );
  }

  toggle(id: number): Observable<ProductionStepEntity> {
    return this.http.post<EnvisiaObject<ProductionStepEntity>>(this.uri + id + '/', {}).pipe(
      map(value => value.object)
    );
  }

  editArticle(id: number, data: { [key: string]: { data?: { name: string, typ: string }[]; empty: boolean } }): Observable<ProductionStepEntity> {
    return this.http.put<EnvisiaObject<ProductionStepEntity>>(
      this.uri + 'article/' + id + '/', data
    ).pipe(map(value => value.object));
  }

}
