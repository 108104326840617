import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleLockModalComponent} from './article-lock.modal.component';
import {BusinessOrderService} from '../../../workflow/business-order/services/business-order.service';
import {ArticleService} from '../../services/article.service';
import {RightService} from '../../../../common/right-service/right.service';
import {ArticleUnlockModalComponent} from './article-unlock.modal.component';
import {forkJoin} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'article-lock',
  template: `
      <button class="btn btn-default" type="button" *ngIf="!locked && canLock()" (click)="lock()">Sperren</button>
      <button type="button" class="btn btn-default" *ngIf="locked && canUnlock()" (click)="unlock()">Entsperren
      </button>`
})
export class ArticleLockComponent {
  @Input() oa: string;
  @Input() locked: boolean;
  @Input() notices: string[];
  @Output() locker = new EventEmitter<boolean>();

  constructor(private articleService: ArticleService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private businessOrderService: BusinessOrderService,
              private rightService: RightService) {
  }

  canLock(): boolean {
    return this.rightService.has('article.lock');
  }

  canUnlock(): boolean {
    return this.rightService.has('article.unlock');
  }

  lock(): void {
    forkJoin({
      hasActiveBas: this.businessOrderService.hasActiveByArticle(this.oa),
      hasActiveOrders: this.articleService.hasActiveOrders(this.oa),
    }).subscribe(value => {
      const modalRef = this.modalService.open(ArticleLockModalComponent, {windowClass: 'modal2-mm'});
      modalRef.componentInstance.notices = this.notices;
      modalRef.componentInstance.oa = this.oa;
      modalRef.componentInstance.sister = false;
      modalRef.componentInstance.has_active_bas = value.hasActiveBas;
      modalRef.componentInstance.has_active_orders = value.hasActiveOrders;
      modalRef.result.then(() => {
        this.locked = true;
        this.locker.next(true);
      }, ignoreRejection);
    }, error => {
      console.log('Modal Error', error);
      this.alertService.add('danger', 'Ein unbekannter Fehler ist aufgetreten!');
    });
  }

  unlock(): void {
    const modalRef = this.modalService.open(ArticleUnlockModalComponent, {windowClass: 'modal2-mm'});
    modalRef.componentInstance.oa = this.oa;
    modalRef.result.then(() => {
      this.locked = false;
      this.locker.next(false);
    }, () => {
    });
  }

}
