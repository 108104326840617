import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../core/shared.module';
import {DeliveryModalModule} from '../modals/delivery-modal.module';
import {DeliveryCreateModalModule} from '../create-modal/delivery-create-modal.module';
import {UIRouterModule} from '@uirouter/angular';
import {DeliveryCreateComponent} from './delivery-create.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { DeliveryCreateOverviewComponent } from './delivery-create-overview.component';
import {WorkflowBoxesModule} from '../../boxes/workflow-boxes.module';
import {DeliveryConditionBoxModule} from '../../delivery-condition-box/delivery-condition-box.module';
import { DeliveryCreateFormComponent } from './delivery-create-form.component';
import {AddressBoxModule} from '../../../customer/address-box/address-box.module';
import { DeliveryCreateFormFetchItemComponent } from './delivery-create-form-fetch-item.component';
import {CustomerModule} from '../../../customer/customer.module';

@NgModule({
  imports: [
    UIRouterModule.forChild(),
    NgbTooltipModule,
    SharedModule,
    DeliveryModalModule,
    DeliveryCreateModalModule,
    WorkflowBoxesModule,
    DeliveryConditionBoxModule,
    AddressBoxModule,
    CustomerModule,
  ],
  declarations: [
    DeliveryCreateComponent,
    DeliveryCreateOverviewComponent,
    DeliveryCreateFormComponent,
    DeliveryCreateFormFetchItemComponent,
  ]
})
export class DeliveryCreateModule {
}
