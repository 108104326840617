import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {PositionUpdateForm, WorkflowPositionView} from '../../../models/position.models';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {from, Subscription} from 'rxjs';
import {debug} from '../../../../../helper/debug.func';
import {PositionService} from '../../../workflow-services/position.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {OfferHistoryModalService} from '../../history/offer-history-modal.service';
import {WorkflowService} from '../../../workflow-services/workflow.service';
import {ignoreRejection} from '../../../../../helper/ignore_rejection';
import {
  PositionChange,
  PositionCloneUpdateChange,
  PositionRemoveChange,
  PositionSingleUpdateChange,
} from '../position-box.models';
import {OfferPositionModalService} from '../../position/offer-position-modal-service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProducerModalService} from '../../../../producer/producer-modal.service';


@Component({
  selector: '[position-box-list-item]',
  templateUrl: './position-box-list-item.component.html',
  styles: ['.inline-div { display: inline; }']
})
export class PositionBoxListItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input('position-box-list-item') position: WorkflowPositionView;
  @Input('position-box-list-item-data') data: BigWorkflowOffer;
  @Input('position-box-list-item-list-change') listChange: number;
  @Output('position-box-list-item-update-position') updatePosition = new EventEmitter<PositionChange>();
  form: UntypedFormGroup;
  formSubscription: Subscription;
  errors: { [key: string]: any; } = {};
  invalidDe: boolean;
  invalidCn: boolean;
  de: boolean;
  cn: boolean;

  constructor(private fb: UntypedFormBuilder,
              private positionService: PositionService,
              private alertService: AlertService,
              private workflowService: WorkflowService,
              private producerModalService: ProducerModalService,
              private offerPositionModalService: OfferPositionModalService,
              private offerHistoryModalService: OfferHistoryModalService) {
  }

  ngOnInit() {
    this.invalidDe = this.invalidPrice('de');
    this.invalidCn = this.invalidPrice('cn');
    const customer_additional = this.data.customer.additional;
    this.de = customer_additional.offer_de;
    this.cn = customer_additional.offer_cn;
    this.form = this.fb.group({
      'id': [this.position.id],
      'nr': [this.position.nr],
      'sub_nr': [this.position.sub_nr],
      'description': [this.position.description],
      'desextension': [this.position.desextension],
      'quanity': [this.position.quanity],
      'lose_de': [this.position.lose_de],
      'release_de': [this.position.release_de],
      'calc_quanity': [this.position.calc_quanity],
      'de': this.fb.group({
        'price_buy': [this.position.de.price_buy],
        'price': [this.position.de.price],
        'price_total': [this.position.de.price_total],
      }),
      'lose_cn': [this.position.lose_cn],
      'release_cn': [this.position.release_cn],
      'cn': this.fb.group({
        'price_buy': [this.position.cn.price_buy],
        'price': [this.position.cn.price],
        'price_total': [this.position.cn.price_total],
      }),
      'delivery_time_days_cn': [this.position.delivery_time_days_cn, Validators.min(0)],
      'delivery_time_days_de': [this.position.delivery_time_days_de, Validators.min(0)],
      'price_reduction_cn': [this.position.price_reduction_cn],
      'notes_de': [this.position.notes_de],
      'notes_cn': [this.position.notes_cn],
    });
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(1500)).subscribe(v => this._update(v));
  }


  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.listChange && !changes.listChange.isFirstChange())
      || (changes.position && !changes.position.isFirstChange())) {
      this.form.patchValue(this.position, {emitEvent: false});
      this.invalidDe = this.invalidPrice('de');
      this.invalidCn = this.invalidPrice('cn');
    }
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }


  clone(): void {
    // FIXME: update before CLONE?!
    this.positionService.duplicate(this.position.id).subscribe(result => {
      // try to focus the position
      result.focus = true;
      this.updatePosition.emit(new PositionCloneUpdateChange(result));
    }, (response) => {
      if (response.error === null) {
        this.alertService.add('danger', 'Position duplizieren nicht möglich, bitte erstellen Sie eine neue Position!');
      } else if ('obj.customer' in response.error) {
        this.alertService.add('danger', 'Position duplizieren nicht möglich, da Artikel kein Kunde hinterlegt hat!');
      }
    });
  }

  removePosition(): void {
    this.positionService.remove(this.position.id).subscribe(() => {
      this.updatePosition.emit(new PositionRemoveChange(this.position));
    }, ignoreRejection);
  }

  resetFocus(): void {
    this.position.focus = false;
  }

  refresh(typ: 'de' | 'cn'): void {
    this._update(this.form.value, true, typ);
  }

  editNotes(typ: 'de' | 'cn') {
    this.offerPositionModalService.open(this.position, typ).subscribe(updatedPosition => {
      this.overwrite(updatedPosition);
    }, ignoreRejection);
  }

  manualize(typ: 'de' | 'cn') {
    this.positionService.manual(this.position.id, typ).subscribe(success => {
      this.overwrite(success);
      this.saveButton(typ);
    }, (response) => {
      if ('obj.customer' in response.error) {
        this.alertService.add(
          'danger',
          'Position auf manuell setzen nicht möglich, da Artikel kein Kunde hinterlegt hat!'
        );
      }
    });
  }

  insertHistory(typ: 'de' | 'cn'): void {
    this.positionService.history(this.position.id, typ).subscribe(() => {
      this.saveButton(typ);
    }, (response) => {
      if (response.error !== null && 'obj.customer' in response.error) {
        this.alertService.add(
          'danger',
          'Position zu Preishistorie hinzufügen nicht möglich, da Artikel kein Kunde hinterlegt hat!'
        );
      }
    });
  }

  insertAdditionalHistory(typ: 'de' | 'cn'): void {
    this.positionService.additionalHistory(this.position.id, typ).subscribe(() => {
      this.saveButton(typ);
    }, ignoreRejection);
  }


  producer(): void {
    const method = (instance: NgbActiveModal, data) => {
      const promise = this.positionService.producer(this.position.id, data).toPromise().then(success => {
        this.overwrite(success);
        instance.close();
      }, errors => {
        return errors;
      });
      return from(promise);
    };
    this.producerModalService.open(method, this.position.producer_cn).subscribe(ignoreRejection, ignoreRejection);
  }

  private overwrite(obj: WorkflowPositionView) {
    this.position = obj;
    this.form.patchValue(obj, {emitEvent: false});
    this.doUpdatePosition();
  }

  private historyModal(obj, send, header, refresh) {
    this.offerHistoryModalService.open(
      this.data.workflow.object.id,
      this.data.article.oa_nr,
      obj,
      send,
      header,
      refresh
    ).subscribe(value => {
      this.overwrite(value.object);
    }, (error) => {
      ignoreRejection(error);
    });
  }

  private doUpdatePosition(): void {
    this.invalidDe = this.invalidPrice('de');
    this.invalidCn = this.invalidPrice('cn');
    this.updatePosition.emit(new PositionSingleUpdateChange(this.position));
  }

  private _update(data: PositionUpdateForm, refresh: boolean = false, typ: string | null | undefined = null): void {
    debug('UPDATE', data);
    const send = data;
    const header = refresh ? {refresh: true, refresh_typ: typ} : {};
    this.positionService.update(this.position.id, send, header).subscribe(response => {
      const obj = response.object;
      if (response.updated) {
        this.overwrite(obj as WorkflowPositionView);
      } else {
        this.historyModal(obj, send, {id: data.id, refresh: true}, typ);
      }
    }, (error) => {
      if (error.error !== null && 'obj.customer' in error.error) {
        this.alertService.add('danger', 'Position updaten nicht möglich, da Artikel kein Kunde hinterlegt hat!');
      }
      if (error.error !== null && ('delivery_time_days_cn' in error.error || 'delivery_time_days_de' in error.error)) {
        this.alertService.add('danger', 'Lieferzeit darf nicht negativ sein');
      }
      this.errors = error.error;
    });
  }

  private invalidPrice(typ: 'de' | 'cn'): boolean {
    let valid = true;

    if (this.position.typ === 'article') {
      if (typ === 'de' && !this.position.valid_de) {
        valid = false;
      }
      if (typ === 'cn' && !this.position.valid_cn) {
        valid = false;
      }
    }

    return !valid;
  }

  private saveButton(typ: 'de' | 'cn'): void {
    if (typ === 'de') {
      this.savedDe();
    }
    if (typ === 'cn') {
      this.savedCn();
    }
  }

  private savedDe(): void {
    this.position.saved_de = true;
    setTimeout(() => {
      this.position.saved_de = false;
    }, 5000);
  }

  private savedCn(): void {
    this.position.saved_cn = true;
    setTimeout(() => {
      this.position.saved_cn = false;
    }, 5000);
  }

}
