<td [ngClass]="{'light-green': materialHolder.selected && !conflict, 'dark-red': conflict}">
  <a uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: materialHolder.variation.id}"
     *ngIf="('material.transactions'|HasPerm)">
    {{materialHolder.variation.article.name}}
  </a>
  <ng-container *ngIf="!('material.transactions'|HasPerm)">
    {{materialHolder.variation.article.name}}
  </ng-container>
</td>
<ng-container *ngFor="let week of list">
  <td class="align-right" [ngClass]="week.classes[i]" [title]="title" *ngFor="let title of week.title; let i=index">
    &nbsp;
  </td>
</ng-container>
