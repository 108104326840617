import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContractDateCheckHolder} from './material-planning-date-check.model';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class MaterialPlanningDateCheckService {
  constructor(private http: HttpClient) {
  }

  check(oaNr: string, amount: number, date: string): Observable<ContractDateCheckHolder> {
    return this.http.get<ContractDateCheckHolder>(
      `${environment.apiv4uri}contract/date/check/${oaNr}?amount=${amount}&date=${date}`
    );
  }
}
