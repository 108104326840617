import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'article-checkbox',
  templateUrl: './article-checkbox.component.html',
  styleUrls: ['./article-checkbox.component.scss']
})
export class ArticleCheckboxComponent {
  @Input() label: string;
  @Input() bold = false;
  @Input() model: string;
  @Input() model2?: string;
  @Input() form: UntypedFormGroup;
  @Input() error?: string;
  @Input() error2?: string;
  @Output() changeEmitter = new EventEmitter<string>();

  change(): void {
    this.changeEmitter.next(this.model);
  }

  change2(): void {
    this.changeEmitter.next(this.model2);
  }

}
