import {Component, Input} from '@angular/core';
import {ArticleDuplicate} from '../../models/article.models';

@Component({
  selector: 'article-duplicate',
  templateUrl: './article-duplicate.html',
})
export class ArticleDuplicateComponent {
  @Input() duplicates: ArticleDuplicate[];
}
