import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FinderItemContentComponent} from './finder-item-content.component';
import {SharedModule} from '../../../core/shared.module';



@NgModule({
    declarations: [
        FinderItemContentComponent,
    ],
    exports: [
        FinderItemContentComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
    ]
})
export class FinderItemContentModule {
}
