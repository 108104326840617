import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FinderSearchBothAbstractComponent} from './finder-search-both.abstract-component';
import {FinderSearchService} from '../finder-search.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'finder-search-right',
  templateUrl: './finder-search-right.component.html',
})
export class FinderSearchRightComponent extends FinderSearchBothAbstractComponent implements OnInit {
  @Input() main: string | null | undefined;
  form: UntypedFormGroup;

  constructor(protected finderSearchService: FinderSearchService,
              protected alertService: AlertService,
              private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'q': [this.main]
    });
  }

  submit(): void {
    this.q = this.form.value.q;
    this.search(0);
  }

}
