<div class="modal-header">
  <h3 class="modal-title">
    Rechnung versenden
  </h3>
</div>

<div class="modal-body">
  Möchten Sie die Rechnung mit der Nummer {{ id }} versenden ?

  <div>&nbsp;</div>

  <div class="text-hide">E-Post Modal</div>

  <div class="alert alert-danger" *ngIf="!active">
    Rechnung kann nicht versendet werden, da keine Post Zugangsdaten hinterlegt sind.
  </div>

  <div class="row" *ngIf="lock && !done">
    <div class="col-sm-3">Wird gesendet</div>
    <div class="col-sm-9">
      <div class="progress">
        <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
             aria-valuenow="100" aria-valuemin="0" style="width: 100%">
          <span class="sr-only">Wird gesendet</span>
        </div>
      </div>
    </div>
  </div>

  <h4 class="alert alert-success" *ngIf="done">
    <ng-container [ngSwitch]="successVal">
      <ng-container *ngSwitchCase="'mail'">Rechnung wurde erfolgreich per E-Mail verschickt</ng-container>
      <ng-container *ngSwitchCase="'post'">
        Rechnung wird per Post verschickt
      </ng-container>
      <ng-container *ngSwitchDefault>Rechnung wurde erfolgreich verschickt</ng-container>
    </ng-container>
  </h4>

  <div class="alert alert-danger" *ngIf="errorText !== null">
    <h4>Beim Versenden ist ein Fehler aufgetreten!</h4>

    {{ errorText }}
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-danger pull-left" type="button" [disabled]="lock" (click)="dismiss()" *ngIf="!done">
    Abbrechen
  </button>

  <button class="btn btn-success pull-right" type="button" [disabled]="lock" (click)="send()" *ngIf="!done && active">
    Senden
  </button>

  <button class="btn btn-success pull-right" type="button" (click)="dismiss()" *ngIf="done">
    Schließen
  </button>
</div>
