import {Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {noop} from '../../helper/noop';

@Directive({selector: 'input[inputSelect],textarea[inputSelect]'})
export class InputSelectDirective implements OnChanges {
  // we need to disable ts-lint since we want to rename the input properties
  @Input('inputSelect') refocus?: any;
  @Input('inputSelectEnabled') enabled = true;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refocus && this.enabled) {
      if (!changes.refocus.isFirstChange()) {
        const element = this.el.nativeElement as HTMLInputElement | HTMLTextAreaElement;
        if (document.activeElement.isEqualNode(element)) {
          this.refocusElement(element);
        }
      }
    }
  }

  @HostListener('focus', ['$event']) onFocus(event: Event) {
    if (this.enabled) {
      // guard
      const element = this.el.nativeElement as HTMLInputElement | HTMLTextAreaElement;
      if ((event.target as HTMLInputElement | HTMLTextAreaElement).isEqualNode(element)) {
        this.refocusElement(event.target as HTMLInputElement | HTMLTextAreaElement);
      }
    }
  }

  private refocusElement(element: HTMLInputElement | HTMLTextAreaElement): void {
    noop(this);
    element.select();
    if (!window.getSelection().toString()) {
      if (element.value) {
        // required for mobile safari
        element.setSelectionRange(0, element.value.length);
      }
    }
  }

}
