<div class="modal-header">
  <h3 id="modal-title">Zusammen eingebuchtes Material</h3>
</div>
<div class="modal-body">
  <table class="table whitetable whitetable-borderless">
    <thead>
    <tr>
      <th>Interne. Nr.</th>
      <th>Bezeichnung</th>
      <th class="align-right">Menge</th>
      <th>Charge</th>
      <th>Lagerplatz</th>
      <th class="icon"></th>
      <th class="icon"></th>
    </tr>
    </thead>
    <tbody>
    <tr [material-order-intake-print-row]="transaction"
        [ngClass]="{'info': transaction.id === markedTransactionId}"
        *ngFor="let transaction of transactions; let index = index">
    </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="dismiss()">
    Schließen
  </button>

  <button type="submit" class="btn btn-success pull-right" (click)="print()" [autofocus]="true">
    Gesammelte PDF für alle Etiketten erstellen
  </button>
</div>
