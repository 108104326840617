import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductionStepService} from '../../../envisia/article/production-services/production-step.service';

@Component({
  selector: 'production-step-create',
  templateUrl: './production-step-create.component.html',
})
export class ProductionStepCreateComponent {

  errors?: { [key: string]: string; } = {};
  createForm: UntypedFormGroup;

  constructor(private productionStepService: ProductionStepService,
              private modalRef: NgbActiveModal,
              private fb: UntypedFormBuilder) {
    this.buildForm();
  }

  create() {
    this.errors = {};
    this.productionStepService.create(this.createForm.value).subscribe((value) => {
      this.modalRef.close(value);
    }, (response) => {
      this.errors = response.error;
    });
  }

  private buildForm() {
    this.createForm = this.fb.group({
      nr: [null, Validators.required],
      text: [null, Validators.required],
      duration: [null, Validators.required],
      max_duration: [null, Validators.required],
      booking_type: [0, Validators.required],
      drill_op_code_type: [null],
      drill_op_code: [null],
      use_good_pieces: [false, Validators.required],
    });
  }

}
