<div class="page-header">
  <h3>Material Artikel Gruppen</h3>
</div>
<div>
  <button class="btn btn-success" (click)="openCreateModal()">
    Neue&nbsp;Gruppe
  </button>
</div>
<div class="row">
  <div class="col-md-8">
    <form #changeForm="ngForm" name="form">
      <table class="table whitetable chrissies">
        <thead>
        <tr>
          <th><a (click)="sort($event, 'id', 'ASC')">Nr.</a></th>
          <th><a (click)="sort($event, 'name', 'ASC')">Bezeichnung</a></th>
          <th class="icon">&nbsp;</th>
        </tr>
        </thead>

        <tbody infiniteScroll [infiniteScrollDisabled]="locked">
        <tr *ngFor="let group of list.objects">
          <td>
            <a uiSref="a.material.article.list" [uiParams]="{group: group.id}">{{group.group_id}}</a>
          </td>
          <td>
            {{ group.name }}
          </td>
          <td class="icon">
            <a (click)="openCreateModal(group)"><i class="fa fa-pencil"></i></a>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

</div>
