import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  PositionArticleForm,
  PositionCreateResponse, PositionFailResponse,
  PositionUpdateForm,
  WorkflowPositionView,
  WorkflowPrice
} from '../models/position.models';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class PositionService {
  private uri = '/api/v2/position/';

  constructor(private http: HttpClient) {
  }

  list(workflowId: number): Observable<WorkflowPositionView[]> {
    return this.http.get<EnvisiaObjects<WorkflowPositionView>>(this.uri + 'list/' + workflowId + '/').pipe(
      map(val => val.objects)
    );
  }

  createArticle(workflowId: number,
                form: PositionArticleForm): Observable<PositionCreateResponse | PositionFailResponse> {
    return this.http.post<PositionCreateResponse | PositionFailResponse>(
      this.uri + 'article/' + workflowId + '/',
      form
    );
  }

  createOther(workflowId: number,
              typ: string,
              extra?: number): Observable<WorkflowPositionView> {
    return this.http.post<{ created: true, object: WorkflowPositionView }>(
      this.uri + 'other/' + typ + '/' + workflowId + '/',
      {},
      {params: EnvisiaLocation.httpParams({extra: extra})}
    ).pipe(map(val => val.object));
  }

  update(positionId: number,
         form: PositionUpdateForm,
         history: { refresh?: boolean; refresh_typ?: string; history_cn?: boolean; history_de?: boolean }
  ): Observable<{ updated: true, object: WorkflowPositionView } | { updated: false, object: WorkflowPrice }> {
    return this.http.put<{ updated: true, object: WorkflowPositionView } | { updated: false, object: WorkflowPrice }>(
      this.uri + 'all/' + positionId + '/',
      form,
      {params: EnvisiaLocation.httpParams(history)}
    );
  }

  changeAll(workflowId: number, form: { de: string, cn: string }): Observable<WorkflowPositionView[]> {
    return this.http.put<EnvisiaObjects<WorkflowPositionView>>(
      this.uri + 'workflow/' + workflowId + '/',
      form
    ).pipe(map(val => val.objects));
  }

  remove(positionId: number): Observable<void> {
    return this.http.delete<void>(this.uri + 'all/' + positionId + '/');
  }

  duplicate(positionId: number): Observable<WorkflowPositionView> {
    return this.http.post<EnvisiaObject<WorkflowPositionView>>(
      this.uri + 'duplicate/' + positionId + '/',
      {}
    ).pipe(map(val => val.object));
  }

  producer(positionId: number, producer?: string): Observable<WorkflowPositionView> {
    return this.http.put<EnvisiaObject<WorkflowPositionView>>(
      this.uri + 'producer/' + positionId + '/',
      {producer: producer}
    ).pipe(map(val => val.object));
  }

  producerAll(workflowId: number, producer: string): Observable<WorkflowPositionView[]> {
    return this.http.put<EnvisiaObjects<WorkflowPositionView>>(
      this.uri + 'producer/all/' + workflowId + '/',
      {producer: producer}
    ).pipe(map(val => val.objects));
  }

  history(positionId: number, typ: string): Observable<void> {
    return this.http.put<void>(
      this.uri + 'history/' + positionId + '/' + typ + '/',
      {}
    );
  }

  additionalHistory(positionId: number, typ: string): Observable<void> {
    return this.http.put<void>(
      this.uri + 'history/additional/' + positionId + '/' + typ + '/',
      {}
    );
  }

  manual(positionId: number, typ: string) {
    return this.http.put<EnvisiaObject<WorkflowPositionView>>(
      this.uri + 'manual/' + positionId + '/' + typ + '/',
      {}
    ).pipe(map(val => val.object));
  }

}
