/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {StateService} from '@uirouter/angular';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {BusinessOrder} from '../models/business-order.models';
import {BusinessOrderDateChangeService} from '../services/business-order-date-change.service';


@Component({
  selector: 'business-order-date-change',
  templateUrl: './business-order-date-change.component.html',
})
export class BusinessOrderDateChangeComponent implements OnInit {
  @Input() ba: BusinessOrder;
  form: UntypedFormGroup;

  constructor(private modalRef: NgbActiveModal,
              private dateChangeService: BusinessOrderDateChangeService,
              private fb: UntypedFormBuilder,
              private stateService: StateService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      start_date: [this.ba.start_date, Validators.required],
      end_date: [this.ba.end_date, Validators.required],
      gold_date: [this.ba.gold_date],
      express: [this.ba.express]
    });
  }

  submit(): void {
    const data = this.form.getRawValue();
    this.dateChangeService.update(this.ba.id, this.ba.index, data).subscribe(response => {
      this.alertService.add('success', 'Daten wurden erfolgreich geändert!');
      this.modalRef.close(response);
      this.stateService.reload(this.stateService.$current).then(ignoreRejection, ignoreRejection);
    }, response => {
      this.alertService.add('danger', 'Datum konnte nicht angepasst werden.');
    });
  }

}
