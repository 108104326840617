import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {CustomerNavigationComponent} from '../navigation/customer-navigation.component';
import {CustomerDocumentComponent} from './customer-document.component';
import {CustomerService} from '../services/customer.service';
import {FinderSearchService} from '../../finder/finder-search.service';
import {CustomerDocumentNewComponent} from './customer-document-new.component';
import {map} from 'rxjs/operators';

export function customerDocumentResolveFn(service: CustomerService, transition: Transition) {
  return service.detail(transition.params().id).pipe(map(value => {
    return {customer: value};
  })).toPromise();
}

export function customerDocumentSearchResolveFn(service: FinderSearchService, transition: Transition) {
  return service.search('customer', transition.params().id).toPromise();
}

export const CUSTOMER_DOCUMENT_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer.document_list',
  url: '/crm/{id:[0-9]+}/document/list',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: CustomerDocumentComponent},
  },
  resolve: [
    {token: 'data', resolveFn: customerDocumentResolveFn, deps: [CustomerService, Transition]},
    {token: 'search', resolveFn: customerDocumentSearchResolveFn, deps: [FinderSearchService, Transition]}
  ]
};

export function customerDocumentCategoryResolveFn(service: FinderSearchService) {
  return service.category().toPromise();
}

export const CUSTOMER_DOCUMENT_NEW_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer.document_create',
  url: '/crm/{id:[0-9]+}/document/create',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: CustomerDocumentNewComponent},
  },
  resolve: [
    {token: 'data', resolveFn: customerDocumentResolveFn, deps: [CustomerService, Transition]},
    {token: 'category', resolveFn: customerDocumentCategoryResolveFn, deps: [FinderSearchService]}
  ]
};

export const CUSTOMER_DOCUMENT_STATES: Ng2StateDeclaration[] = [
  CUSTOMER_DOCUMENT_LIST_STATE,
  CUSTOMER_DOCUMENT_NEW_STATE
];
