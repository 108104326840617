import {Injectable} from '@angular/core';
import Helper from '../../helper/helper';

@Injectable({providedIn: 'root'})
export class SessionStorage {

  set(key: string, value: string): void {
    window.sessionStorage[key] = value;
  }

  get(key: string, defaultValue?: string): string {
    return window.sessionStorage[key] || defaultValue;
  }

  setObject<T>(key: string, value: any): void {
    const data = JSON.stringify(value);
    window.sessionStorage[key] = data;
  }

  getObject<T>(key: string, defaultValue: T): T {
    const parsed = JSON.parse(window.sessionStorage[key] || '{}');
    if (Helper.isDefined(parsed)) {
      return parsed;
    } else {
      return defaultValue;
    }
  }

  removeItem(key: string): void {
    window.sessionStorage.removeItem(key);
  }

}
