import * as React from 'react';
import ReactModal from 'react-modal';
import {ReactNode, useEffect, useState} from 'react';

interface Props {
  contentLabel: string;
  children?: ReactNode | ReactNode[];
  showModal: boolean;
  modalClosed: () => void;
}

ReactModal.setAppElement('#content-root');

export function EvReactModal(props: Props) {
  const [showModal, showModalSet] = useState<boolean>(props.showModal);

  const handleCloseModal: () => void = () => {
    props.modalClosed();
  };

  useEffect(() => {
    if (props.showModal !== showModal) {
      showModalSet(props.showModal);
    }
  }, [props.showModal]);

  return (showModal && <div id={'react-modal-root'}>
    <ReactModal isOpen={showModal}
                style={{
                  overlay: {zIndex: 9999, display: 'flex', alignItems: 'center', justifyContent: 'center'},
                  content: {position: null, inset: null},
                }}
                onRequestClose={handleCloseModal}
                contentLabel={props.contentLabel}>
      <div className='modal-header'>
        <h3 className='modal-title'>{props.contentLabel}</h3>
      </div>
      {props.children}
    </ReactModal>
  </div>);
}
