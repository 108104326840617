import {Component, ErrorHandler, Input, OnInit} from '@angular/core';
import {CreateTaskModel} from './create-task.models';
import {WorkflowPositionView} from '../models/position.models';
import {CreateTaskCallerService} from './create-task-caller.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {CreateTaskModalService} from './create-task-modal.service';

@Component({
  selector: 'create-task',
  templateUrl: './create-task.component.html',
})
export class CreateTaskComponent implements OnInit {
  @Input() data: CreateTaskModel;
  filteredPositions: WorkflowPositionView[];
  clicked: { position: WorkflowPositionView, typ: 'de' | 'cn' } | null = null;

  constructor(private createTaskCallerService: CreateTaskCallerService,
              private errorHandler: ErrorHandler,
              private createTaskModalService: CreateTaskModalService) {
  }

  ngOnInit(): void {
    this.filteredPositions = this.data.positions.filter(val => val.typ === 'article');
  }

  select(position: WorkflowPositionView, typ: 'de' | 'cn'): void {
    this.clicked = {position: position, typ: typ};
  }

  check(position: WorkflowPositionView, typ: 'de' | 'cn'): boolean {
    return this.clicked ? (position.id === this.clicked.position.id && this.clicked.typ === typ) : false;
  }


  valid(typ: 'de' | 'cn'): boolean {
    if (this.data) {
      const customer_additional = this.data.customer.additional;
      const de = customer_additional.offer_de;
      const cn = customer_additional.offer_cn;
      const last_de = this.data.lastOffer.de;
      const last_cn = this.data.lastOffer.cn;
      return typ === 'de' ? de && last_de : cn && last_cn;
    } else {
      return false;
    }
  }

  create(): void {
    this.createTaskCallerService
      .next(this.data.workflow, this.clicked.position, this.clicked.typ, null)
      .subscribe(ignoreRejection, (error) => {
        if (error.error.error === 'invalid') {
          if (error.error.state === 0) {
            this.createTaskModalService
              .openWithHistory(this.data.workflow, this.clicked.position, this.clicked.typ, error.error.history);
          } else if (error.error.state === 1) {
            this.createTaskModalService
              .openNoHistory(this.clicked.position, this.clicked.typ);
          }
        } else {
          this.errorHandler.handleError('CreateTaskComponent#Error: ' + JSON.stringify(error));
        }
      });
  }

}
