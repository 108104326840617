import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../../core/shared.module';
import {BusinessOrderStornoModalComponent} from './business-order-storno-modal.component';

@NgModule({
    imports: [
        NgbModalModule,
        SharedModule,
    ],
    declarations: [
        BusinessOrderStornoModalComponent,
    ]
})
export class BusinessOrderStornoModule {
}
