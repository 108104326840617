/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-dimension-inspection',
  templateUrl: './article-extra-dimension-inspection.component.html',
})
export class ArticleExtraDimensionInspectionComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'dimensional_inspection';
  protected schemaName = 'dimensional-inspection';
  protected fields = [
    'kmg',
    'kmg_other',
    'sds_dimensional_inspection',
    'sds_dimensional_inspection_other',
    'email',
    'email_other',
    'paper',
    'paper_other',
    'with_pattern_measurement',
    'with_pattern_measurement_other',
  ];
}
