import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialProductGroupsService} from '../material-product-groups.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  MaterialProductGroupForm,
  MaterialProductGroupFormValue
} from '../material-product-groups.models';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'material-product-groups-create-modal',
  templateUrl: './material-product-groups-create-modal.component.html',
})
export class MaterialProductGroupsCreateModalComponent implements OnInit {
  form: FormGroup<MaterialProductGroupForm>;
  errors: {[key: string]: any} = {};
  constructor(private service: MaterialProductGroupsService,
              private modalRef: NgbActiveModal,
              private alertService: AlertService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: new FormControl<string>('', [Validators.required]),
    } as MaterialProductGroupForm);
  }

  save(): void {
    this.errors = {};
    this.service.create(this.form.value as MaterialProductGroupFormValue).subscribe(() => {
      this.modalRef.close();
    }, (error: HttpErrorResponse) => {
      this.errors = error.error.errors ?? {};
      if (error.status === 409) {
        this.alertService.add('danger', 'Es existiert bereits eine Produkt Gruppe mit diesem Namen');
      }
    });
  }

  close(): void {
    this.modalRef.dismiss();
  }
}
