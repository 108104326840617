import {Component, Input, OnInit} from '@angular/core';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialOrderPositionWithOrder} from '../material-order.model';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {MaterialArticleOrderListService} from './material-article-order-list.service';
import {MaterialVariation} from '../../material-management.model';

@Component({
  selector: 'material-article-order-list',
  templateUrl: './material-article-order-list.component.html'
})
export class MaterialArticleOrderListComponent implements OnInit {
  @Input() variation: MaterialVariation;
  wrapper: LoadingWrapper<Lister<MaterialOrderPositionWithOrder>>;
  totalSum = 0;
  totalSumReceived = 0;
  totalSumRemaining = 0;

  constructor(private orderListService: MaterialArticleOrderListService) { }

  ngOnInit(): void {
    this.wrapper = new LoadingWrapper<Lister<MaterialOrderPositionWithOrder>>(this.orderListService.list(this.variation.id));
    this.wrapper.data$.subscribe(list => {
      list.objects.forEach(mop => {
        this.totalSum += mop.amount;
        this.totalSumReceived += mop.amount_received;
        this.totalSumRemaining += mop.amount_left_total;
      });
    });
  }

}
