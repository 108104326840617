<div class="modal-header">
  <h3 class="modal-title">{{ data.key }} ändern</h3>
</div>

<form class="form-horizontal">

  <div class="modal-body">
    <div class="form-group">
      <label for="input-tg" class="col-sm-2 control-label">TG</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="input-tg" name="tg" [(ngModel)]="data.data.tg"
               [ngClass]="{red_alert: errors['tg']}" [autofocus]>
      </div>
    </div>

    <div class="form-group">
      <label for="input-cti" class="col-sm-2 control-label">CTI</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="input-cti" name="cti" [(ngModel)]="data.data.cti"
               [ngClass]="{red_alert: errors['cti']}">
      </div>
    </div>

    <div class="form-group">
      <label for="input-plc" class="col-sm-2 control-label">PLC</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="input-plc" name="plc" [(ngModel)]="data.data.plc"
               [ngClass]="{red_alert: errors['plc']}">
      </div>
    </div>

    <div class="form-group">
      <label for="input-ipc_4101" class="col-sm-2 control-label">IPC 4101</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="input-ipc_4101" name="ipc_4101" [(ngModel)]="data.data.ipc_4101"
               [ngClass]="{red_alert: errors['ipc_4101']}">
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <div class="checkbox">
          <label>
            <input type="checkbox" name="halogen_free" [(ngModel)]="data.data.halogen_free"
                   [ngClass]="{red_alert: errors['halogen_free']}"> halogenfrei
          </label>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <div class="checkbox">
          <label>
            <input type="checkbox" name="de_compliant" [(ngModel)]="data.data.de_compliant"
                   [ngClass]="{red_alert: errors['de_compliant']}"> DE konform
          </label>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <div class="checkbox">
          <label>
            <input type="checkbox" name="china_compliant" [(ngModel)]="data.data.china_compliant"
                   [ngClass]="{red_alert: errors['china_compliant']}"> China konform
          </label>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="input-china_text" class="col-sm-2 control-label">China Text</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="input-china_text" name="china_text"
               [(ngModel)]="data.data.china_text"
               [ngClass]="{red_alert: errors['china_text']}">
      </div>
    </div>

    <div class="form-group">
      <label for="priority" class="col-sm-2 control-label">Priorität</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="priority" name="priority"
               [(ngModel)]="data.data.priority"
               [ngClass]="{red_alert: errors['priority']}">
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-success pull-right" (click)="save()">
      Speichern
    </button>

  </div>

</form>

