/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {StateService} from '@uirouter/angular';
import {Component, Input, ViewChild} from '@angular/core';
import {Holiday} from './holiday.model';
import {AlertService} from '../../common/alert-service/alert.service';
import {HolidayService} from './holiday.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HolidayCreateComponent} from './holiday-create.component';
import {DateHelper} from '../../helper/date-helper';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'holiday-manage',
  templateUrl: './holiday-manage.component.html',
})
export class HolidayManageComponent {
  @Input() objects: Holiday[];
  @ViewChild('fileInput', {static: true}) fileInput;
  busy = false;
  length: number;
  after: number | null = 0;
  locked = false;

  constructor(private alertService: AlertService,
              private holidayService: HolidayService,
              private modalService: NgbModal,
              private http: HttpClient,
              private stateService: StateService) {
  }

  del(id: number) {
    if (confirm('Wollen Sie das Datum l&ouml;schen?')) {
      this.holidayService.del(id).subscribe(
        () => this.objects = this.objects.filter(function (obj) {
          return obj.id !== id;
        })
      );
    }
  }

  templateCreate() {
    const modalRef = this.modalService.open(HolidayCreateComponent, {windowClass: 'modal2-mm'});
    modalRef.result.then(value => {
      this.objects.push(value);
      console.log(this.objects);
      this.objects.sort(function (a, b) {
        return DateHelper.safeParse(b.date).getTime() - DateHelper.safeParse(a.date).getTime();
      });
    }, () => { // ignore modal backdrop
    });
  }

  upload() {
    const fileBrowser = this.fileInput.nativeElement;
    if (fileBrowser.files && fileBrowser.files[0]) {

      const formData = new FormData();
      formData.append('files', fileBrowser.files[0]);
      this.http.post<void>('/api/v4/holiday/upload/', formData).subscribe(() => {
        this.stateService.reload(this.stateService.$current);
      }, (error) => {
        console.log(error);
        this.alertService.add('danger', 'CSV konnte nicht hochgeladen werden.');
      });
    }
  }
}
