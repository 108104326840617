<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Produktspezifischer Prüfplan
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="product_specific_inspection_plan" class="article-form-item-values">
    </article-extra-checkbox>

    <article-extra-text formControlName="product_specific_inspection_plan_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

</div>
