import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {FetchListComponent} from './fetch-list.component';
import {FetchService} from '../workflow-services/fetch.service';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {FetchCamListComponent} from './fetch-cam-list.component';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {ErpTransition} from '../../../core/erp-transition.service';

export const FETCH_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.fetch',
  abstract: true,
  data: {requiresAuth: true}
};

export function fetchListResolveFn(transition: ErpTransition, service: FetchService) {
  return service.list(transition.params()).toPromise();
}

export const FETCH_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.fetch.list',
  url: '/workflow/fetch/list?page&order&sort&status&date_start&date_end&has_delivery_date&kanban&typ&sid&quantity&delivery_date&oa_nr&sds_nr&kd_art_nr&customer_name&customer_city&customer_field_service',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: FetchListComponent}
  },
  resolve: [{token: 'listData', resolveFn: fetchListResolveFn, deps: [ErpTransition, FetchService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    status: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    has_delivery_date: {dynamic: true},
    kanban: {dynamic: true},
    typ: {dynamic: true},
    sid: {dynamic: true},
    quantity: {dynamic: true},
    delivery_date: {dynamic: true},
    oa_nr: {dynamic: true},
    sds_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    customer_name: {dynamic: true},
    customer_city: {dynamic: true},
    customer_field_service: {dynamic: true}
  },
  data: {requiresAuth: true}
};

export const FETCH_CAM_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.fetch.list_cam',
  url: '/workflow/fetch/list/cam?page&order&sort&article_state&date_start&date_end&has_delivery_date&kanban&typ&sid&quantity&delivery_date&sds_nr&customer_name&article_execution&article_manufacturer',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: FetchCamListComponent}
  },
  resolve: [{token: 'listData', resolveFn: fetchListResolveFn, deps: [ErpTransition, FetchService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    article_state: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    article_execution: {dynamic: true},
    article_manufacturer: {dynamic: true},
    has_delivery_date: {dynamic: true},
    kanban: {dynamic: true},
    typ: {dynamic: true},
    sid: {dynamic: true},
    quantity: {dynamic: true},
    delivery_date: {dynamic: true},
    sds_nr: {dynamic: true},
    customer_name: {dynamic: true},
  },
  data: {requiresAuth: true}
};

export const FETCH_STATES: Ng2StateDeclaration[] = [
  FETCH_MAIN_STATE,
  FETCH_LIST_STATE,
  FETCH_CAM_LIST_STATE,
];
