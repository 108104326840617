import {Component} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {InvoiceCorrectionModalService} from './invoice-correction-modal.service';
import {ignoreRejection} from '../../../../helper/ignore_rejection';

@Component({
  selector: 'invoice-correction-navigation',
  templateUrl: './invoice-correction-navigation.component.html'
})
export class InvoiceCorrectionNavigationComponent {

  constructor(private invoiceCorrectionModalService: InvoiceCorrectionModalService,
              private stateService: StateService) {
  }

  create(): void {
    this.invoiceCorrectionModalService.open().subscribe(() => {
      this.stateService.go(this.stateService.current, {}, {reload: true, inherit: false});
    }, ignoreRejection);
  }

}
