import {Component, Input, OnInit} from '@angular/core';
import {Workflow} from '../../models/workflow.models';
import {DeliveryCreateModalData} from './delivery-create-modal.models';
import {BigFetchWithPosition} from '../../models/fetch.models';
import {DeliveryMessage} from '../../models/delivery.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Money} from '../../../../common/money/Money';
import {InventoryStorage} from '../../../article/inventory/inventory.models';

@Component({
  selector: 'delivery-create-modal',
  templateUrl: './delivery-create-modal.component.html',
})
export class DeliveryCreateModalComponent implements OnInit {
  @Input() workflow: Workflow;
  @Input() data: DeliveryCreateModalData;
  @Input() labelCount: number;
  @Input() germanDelivery: boolean;
  @Input() fetches: BigFetchWithPosition[];
  @Input() storage: InventoryStorage[];

  current: 'weight' | 'inventory';
  frozenQuantity: number;
  weightValues: string[];

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    // we need to enter weights if we want to ship outside of germany
    // See: https://git.envisia.io/erp/loki/issues/1016 for more details
    this.current = this.labelCount > 0 && !this.germanDelivery ? 'weight' : 'inventory';
    this.frozenQuantity = this.initQuantity();
  }

  next(data: string[]): void {
    this.current = 'inventory';
    this.weightValues = data;
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  close(data: DeliveryMessage): void {
    this.activeModal.close(data);
  }

  private initQuantity(): number {
    let frozenQuantity = 0;
    this.fetches.forEach((obj) => {
      const quantity = obj.positions[0].quanity;
      if (typeof quantity === 'number') {
        frozenQuantity += (quantity as number);
      } else {
        frozenQuantity += Money.parse(quantity as string) || 0;
      }
    });
    return frozenQuantity;
  }

}
