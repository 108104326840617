import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MaterialOrderTransactionListElement} from '../material-order.model';
import {HttpErrorResponse} from '@angular/common/http';
import {MaterialOrderIntakeService} from './material-order-intake.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {MaterialOrderIntakePrintModalService} from './intake-print-modal/material-order-intake-print-modal.service';
import {MaterialOrderIntakePdfRow} from './material-order-intake-pdf-row';
import {NumberHelper} from '../../../../helper/number-helper';

@Component({
  selector: 'tr[material-order-intake-transaction-row]',
  templateUrl: './material-order-intake-transaction-row.component.html',
  styleUrls: ['../material-order.component.scss'],
})
export class MaterialOrderIntakeTransactionRowComponent extends MaterialOrderIntakePdfRow {
  @Input('material-order-intake-transaction-row') transaction: MaterialOrderTransactionListElement;
  @Input('material-order-intake-transaction-row-num-fields') numFields: number;
  @Output('material-order-intake-transaction-row-remove-transaction') removeTransaction = new EventEmitter<number>();

  constructor(private service: MaterialOrderIntakeService,
              private alertService: AlertService,
              private moipms: MaterialOrderIntakePrintModalService) {
    super();
  }

  remove(): void {
    if (confirm('Möchten Sie diese Einbuchung wirklich löschen?')) {
      this.service.deleteTransaction(this.transaction.id).subscribe(() => {
        this.removeTransaction.emit(this.transaction.id);
      }, (response: HttpErrorResponse) => {
        switch (response.status) {
          case 404:
            this.alertService.add('danger', 'Konnte Transaktion nicht finden');
            break;
          case 409:
            this.alertService.add('danger', 'Es gibt bereits Buchungen auf diesem Material');
        }
      });
    }
  }

  openPrint(transactionId: number): void {
    this.moipms.open(this.transaction.order_id, this.transaction.index, transactionId);
  }

  get price(): number {
    return NumberHelper.saveParseFloat(this.transaction.price);
  }
}
