import {Component, Input, OnInit} from '@angular/core';
import {BigWorkflow} from '../../models/workflow.models';
import {BigCreateDeliveryWorkflow} from '../../models/workflow-create.models';
import {MergedFrame} from '../../models/frame.models';

@Component({
  selector: 'delivery-create',
  templateUrl: './delivery-create.component.html'
})
export class DeliveryCreateComponent implements OnInit {
  @Input() data: BigCreateDeliveryWorkflow;
  frame: MergedFrame;

  constructor() {
  }

  ngOnInit() {
    // we need a frame object since updateWorkflow
    // will only update the frame which won't trigger
    // ngOnChanges
    this.frame = this.data.workflow.frame;
  }

  updateWorkflow(data: {workflow: BigWorkflow}): void {
    const frame = data.workflow.frame as unknown as MergedFrame;
    this.frame = frame;
    this.data.workflow.frame = frame;
  }

}
