import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'material-inventory-konsi-switch',
  templateUrl: './material-inventory-konsi-switch.component.html'
})
export class MaterialInventoryKonsiSwitchComponent {
  @Input() transactionForm: UntypedFormGroup;
  @Input() isKonsiArticle = false;
  @Input() ignoreAmount = false;

  setKonsiState(isKonsi: Boolean): void {
    this.transactionForm.patchValue({konsi: isKonsi}, {emitEvent: false});
  }
}
