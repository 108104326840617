<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Erstmusterprüfung
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="empb"
                            class="article-form-item-values"
                            [defaultValues]="defaultValues['empb']">
      EMPB
    </article-extra-dropdown>

    <article-extra-text formControlName="empb_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <h6>Besonderheiten China</h6>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="empb_china"
                            class="article-form-item-values"
                            [defaultValues]="defaultValues['empb_china']">
      China EMPB
    </article-extra-dropdown>

    <article-extra-text formControlName="empb_china_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="sds_cover_sheet" class="article-form-item-values">
      SDS Deckblatt
    </article-extra-checkbox>

    <article-extra-text formControlName="sds_cover_sheet_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="sds_cut_image_analysis" class="article-form-item-values">
      SDS Schliffbildanalyse
    </article-extra-checkbox>

    <article-extra-text formControlName="sds_cut_image_analysis_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="sds_dimensional_inspection" class="article-form-item-values">
      SDS Maßprüfung
    </article-extra-checkbox>

    <article-extra-text formControlName="sds_dimensional_inspection_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <h6>Versand EMPB</h6>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="email" class="article-form-item-values">
      E-Mail
    </article-extra-checkbox>

    <article-extra-text formControlName="email_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="paper" class="article-form-item-values">
      Papier
    </article-extra-checkbox>

    <article-extra-text formControlName="paper_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="portal" class="article-form-item-values">
      Portal
    </article-extra-checkbox>

    <article-extra-text formControlName="portal_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="with_pattern_measurement" class="article-form-item-values">
      Mit Messmuster
    </article-extra-checkbox>

    <article-extra-text formControlName="with_pattern_measurement_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="with_grounded_chunk" class="article-form-item-values">
      Mit Schliffklotz
    </article-extra-checkbox>

    <article-extra-text formControlName="with_grounded_chunk_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

</div>
