<customer-header-template [customer]="customer" *ngIf="customer"></customer-header-template>

<form #changeForm="ngForm" name="form">
  <div class="filter-row">
    <div class="filter-left">

      <span style="float: left;margin: 6px 10px; font-size: 20px;" class="fa fa-filter"></span>

      <select class="form-control input-sm" name="typ" style="width: 100px;float: left;" [(ngModel)]="data.typ">
        <option value=""></option>
        <option value="1" style="background-color: #D2EEFF;">Planung</option>
        <option value="2" style="background-color: #fff;">Bericht</option>
      </select>

      <span style="float: left;margin: 6px 10px 6px 40px; font-size: 20px;" class="fa fa-user"></span>

      <select class="form-control input-sm" name="username" id="user_select" style="width: 150px;float: left;"
              [(ngModel)]="data.username" [autofocus]>
        <option value=""></option>
        <option *ngFor="let username of users" [value]="username">{{ username }}</option>
      </select>

      <date-range class="left-space" [start]="start" [end]="end" (update)="updateDate($event)"
                  leftIcon="fa-picker-size fa-clock-o"></date-range>
    </div>

    <div class="filter-right">
      <ev-file-button [url]="downloadUrl('/akquise/v1/akquise/pdf/')" [link]="false"
                      title="Liste als PDF exportieren">
        <span class="fa fa-file"></span>
      </ev-file-button>
    </div>
  </div>

</form>

<div class="row">
  <div class="col-md-12">
    <div class="akquiselist" style="margin-top: 10px;">

      <div class="akquiselist-item" *ngFor="let akquise of list.objects; let index = index"
           [ngClass]="{plan: akquise.date_compare}">
        <div class="akquise-detail">
          <div class="akquise-status">
            <div *ngIf="akquise.date_compare">
              <span class="label label-primary">Planung</span>
            </div>
            <div *ngIf="!akquise.date_compare">
              <span class="label label-success">Bericht</span>
            </div>
          </div>
          <div class="akquise-date"><i class="fa fa-calendar"></i> {{ akquise.date|date: "dd.MM.yyyy" }}</div>
          <div class="akquise-user"><span class="fa fa-user"></span>{{ akquise.username }}</div>
          <div class="akquise-time" *ngIf="akquise.start && akquise.end">
            <i class="fa fa-clock-o"></i> {{ akquise.start }} - {{ akquise.end }}
            <span class="label label-default">{{ akquise.range }}</span>
          </div>
        </div>
        <div class="akquise-customer">
          <div class="akquise-customer-name">
            <a uiSref="a.crm.customer.akquise_list"
               [uiParams]="{id: akquise.customer.id, start: data.start, end: data.end, username: data.username, typ: data.typ}">
              {{ akquise.customer.name }}
            </a>
          </div>
          <div class="akquise-customer-address">
            {{ akquise.customer.address.street }} <br>
            {{ akquise.customer.address.zip }} {{ akquise.customer.address.city }} <br>
            <span *ngIf="akquise.customer.phone"><i class="fa fa-phone"></i> {{ akquise.customer.phone }}</span>
          </div>
        </div>
        <div class="akquise-text">
          <pre [innerHtml]="akquise.text"></pre>
        </div>
        <div class="akquise-action">

          <button class="btn btn-link" (click)="deleteActivity(akquise.id)"
                  style="float: right; padding:0 10px 10px 10px; font-size: 20px;" *ngIf="('activity.delete'|HasPerm)">
            <span class="fa fa-trash"></span>
          </button>

          <button class="btn btn-link" (click)="editModal(index, akquise)"
                  style="float: right; padding:0 10px 10px 10px; font-size: 20px;" *ngIf="(('activity.self'|HasPerm) && isLoggedInUser(akquise.username)) || ('activity.edit.all'|HasPerm)">
            <span class="fa fa-pencil"></span>
          </button>

        </div>
      </div>
    </div>
  </div>
</div>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
