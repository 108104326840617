import {Component, Input} from '@angular/core';
import {BusinessOrderSimple} from '../models/business-order.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderService} from '../services/business-order.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'business-order-start-modal',
  templateUrl: './business-order-start-modal.component.html',
})
export class BusinessOrderStartModalComponent {
  @Input() order: BusinessOrderSimple;
  errors: string[] = [];

  constructor(private businessOrderService: BusinessOrderService,
              private alertService: AlertService,
              private modal: NgbActiveModal) {
  }

  submit(clb?: LoadingButtonEvent): void {
    this.errors = [];
    this.businessOrderService.start(this.order).subscribe(response => {
      this.alertService.add('success', 'BA erfolgreich gestartet!');
      this.modal.close(response);
    }, (error: HttpErrorResponse) => {
      this.errors = error.error?.errors ?? [];
      if (clb) {
        clb.callback(false);
      }
    });
  }

  trackByFn(index: number, item: string): number {
    return index;
  }
}
