import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EnvisiaObjects} from '../../../common/wrapper.models';
import {MaterialProductGroupListElement} from './material-product-groups.models';

@Injectable({
  providedIn: 'root'
})
export class MaterialProductGroupListService {
  constructor(private http: HttpClient) {
  }

  list(): Observable<EnvisiaObjects<MaterialProductGroupListElement>> {
    return this.http.get<EnvisiaObjects<MaterialProductGroupListElement>>(
      `${environment.apiv4uri}material/product/group/list`,
    );
  }
}
