/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, OnInit} from '@angular/core';
import {Article, ArticleCore} from '../models/article.models';
import {ArticleExtraSaveForm, ShippingInstructionDelete} from '../../article-extra/article-extra.models';
import {Transition, StateService} from '@uirouter/angular';
import {AlertService} from '../../../common/alert-service/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleShippingConfirmComponent} from './article-shipping-confirm.component';
import {
  ShippingInstruction,
  ShippingInstructionBase, ShippingInstructionHolder,
  ShippingInstructionSchema
} from '../models/article-extra.models';
import {ShippingInstructionService} from '../services/shipping-instruction.service';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {ArticleMultilayerPlan} from '../main/multilayer-plan/article-multilayer-plan.models';
import {ArticleMultilayerPlanService} from '../main/multilayer-plan/article-multilayer-plan.service';
import {getArticleMultilayerPlanResolveFn} from '../article.states';

@Component({
  selector: 'article-shipping-instruction',
  templateUrl: './article-shipping-instruction.component.html',
})
export class ArticleShippingInstructionComponent implements OnInit {
  @Input() schema: ShippingInstructionSchema[];
  @Input() data: ShippingInstructionHolder<ArticleCore>;
  @Input() workflowId?: number;
  @Input() multilayerPlan?: ArticleMultilayerPlan;
  values: ShippingInstruction;
  locked = true;
  article: ArticleCore;
  formValues?: ShippingInstructionBase;

  constructor(private alertService: AlertService,
              private stateService: StateService,
              private shippingInstructionService: ShippingInstructionService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.values = this.data.data;
    this.article = this.data.object;
  }

  updateForm(value: ArticleExtraSaveForm): void {
    this.locked = !value.valid;
    this.formValues = value.data;
  }


  confirmModal() {
    const modalRef = this.modalService.open(ArticleShippingConfirmComponent);
    modalRef.componentInstance.values = this.values;
    modalRef.componentInstance.formValues = this.formValues;
    modalRef.componentInstance.article = this.article;
    from(modalRef.result).subscribe(data => {
      this.data.data = data;
      this.values = data;
    }, ignoreRejection);
  }

  generatedSds(value: Article): void {
    value.calc_cn = value.calc_chn;
    this.article = value;
  }

  generatedSister(value: Article): void {
    value.calc_cn = value.calc_chn;
    this.article = value;
  }

  onDeleteSection(data: ShippingInstructionDelete): void {
    if (confirm('Möchten Sie die Liefervorschrift Sektion wirklich löschen? ACHTUNG! ' +
      'Alle Artikel Liefervorschriften in der ausgewählten Sektion werden gelöscht!')) {
      this.shippingInstructionService.delete('article', this.article.oa_nr, data.section).subscribe(inner => {
        this.locked = true;
        this.values = inner.data;
        this.data.data = inner.data;
      }, () => {
        this.alertService.add('danger', 'Konnte Sektion nicht löschen!');
      });
    }
  }

}

export function workflowIdResolveFn(transition: Transition) {
  return transition.params().workflow_id;
}

export function articleExtraResolveFn(service: ShippingInstructionService, transition: Transition) {
  return service.get<ArticleCore>('article', transition.params().id).toPromise();
}

export function articleExtraSchemaResolveFn(service: ShippingInstructionService) {
  return service.schema().toPromise();
}

export const articleShippingInstructionResolve = [
  {token: 'workflowId', resolveFn: workflowIdResolveFn, deps: [Transition]},
  {token: 'data', resolveFn: articleExtraResolveFn, deps: [ShippingInstructionService, Transition]},
  {token: 'schema', resolveFn: articleExtraSchemaResolveFn, deps: [ShippingInstructionService]},
  {
    token: 'multilayerPlan',
    resolveFn: getArticleMultilayerPlanResolveFn,
    deps: [Transition, ArticleMultilayerPlanService],
  },
];
