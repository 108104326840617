import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from '../../../customer/model/customer';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../models/article-specification.model';
import {Article} from '../../models/article.models';
import {ArticleMultilayerPlan} from '../multilayer-plan/article-multilayer-plan.models';

@Component({
  selector: 'article-overview-template',
  templateUrl: './article-overview-template.component.html'
})
export class ArticleOverviewTemplateComponent implements OnInit {
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() errors: { [key: string]: any; };
  @Input() form: UntypedFormGroup;
  @Input() refCount: number;
  @Input() article?: Article;
  @Input() workflowId?: number;
  @Input() multilayerPlan?: ArticleMultilayerPlan;
  @Output('customerChange') customerChangeEmitter = new EventEmitter<Customer | null | undefined>();
  @Output('calculated') calculatedEmitter = new EventEmitter<string>();
  @Output('aspectCalculated') aspectCalculatedEmitter = new EventEmitter<any>();
  @Output('updateState') updateStateEmitter = new EventEmitter<string>();
  @Output('setAutomatedChange') setAutomatedChangeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  articleCustomer: { name: string } | null = null;

  ngOnInit(): void {
    if (!!this.article && !!this.article.customer) {
      this.articleCustomer = {name: this.article.customer};
    }
  }

  customerChange(event: Customer | null | undefined): void {
    this.customerChangeEmitter.next(event);
  }

  calculated(name: string): void {
    this.calculatedEmitter.next(name);
  }

  aspectCalculated(): void {
    this.aspectCalculatedEmitter.next({});
  }

  updateState(state: string): void {
    this.updateStateEmitter.next(state);
  }
}
