<table class="table whitetable">
  <thead>
  <tr>
    <th colspan="3">{{tableName}}</th>
  </tr>
  <tr>
    <th>Stück bis</th>
    <th>%</th>
    <th>Stück</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let obj of deliveryConditionList">
    <td>{{ obj.quantity_till|money }}</td>
    <td>{{ obj.percent|money }}</td>
    <td>{{ obj.quantity|money }}</td>
  </tr>
  </tbody>
</table>
