<div [formGroup]="formCustomer">
  <div class="col-md-3">
    <customer-box formControlName="customer"
                  [writeBackValue]="false"
                  [realValue]="true"
                  [clear]="false"
                  [withAddress]="true"
                  [showSpecialProductionLabel]="true">
    </customer-box>
  </div>

  <div class="col-md-3">

    <contact-box formControlName="contact" [customerId]="data.customer.id">
    </contact-box>

  </div>
</div>

<div class="col-md-3" [formGroup]="form">

  <div class="content-box">
    <h3>Anfrage</h3>

    <div class="row">
      <div class="col-md-4">
        <label for="data-work_kind">Anfrage per:</label>
      </div>
      <div class="col-md-8">
        <select class="form-control input-sm" id="data-work_kind"

                [ngClass]="{field_error: errors.work_kind}"
                formControlName="work_kind"
                *ngIf="('task.offer.change'|HasPerm)">
          <option *ngFor="let kind of kinds" [ngValue]="kind.id">{{kind.name}}</option>
        </select>

        <div *ngIf="!('task.offer.change'|HasPerm)" style="display: inline;">
          <ng-container *ngIf="kind">
            {{ kind.name }}
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label>Anfrage vom:</label>
      </div>
      <div class="col-md-8">
        <ev-datepicker id="start" class="form-control"
                       [allDates]="true" [realValue]="false" [writeBack]="false"
                       placement="bottom"
                       formControlName="work_date"
                       [evClass]="{red_alert: errors['work_date']}"
                       *ngIf="('task.offer.change'|HasPerm)">
        </ev-datepicker>
        <div *ngIf="!('task.offer.change'|HasPerm)" style="display: inline;">
          {{ data.workflow.object.work_date }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label for="data-work_nr">Anfrage-Nr.:</label>
      </div>
      <div class="col-md-8">
        <input type="text" id="data-work_nr" class="form-control input-sm"
               [ngClass]="{field_error: errors.work_nr}"
               *ngIf="('task.offer.change'|HasPerm)"
               formControlName="work_nr">
        <div *ngIf="!('task.offer.change'|HasPerm)" style="display: inline;">
          {{ data.workflow.object.work_nr }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label for="data-exchange_rate">1 USD in Euro:</label>
      </div>
      <div class="col-md-8">
        <input type="text" id="data-exchange_rate" class="form-control input-sm"
               [ngClass]="{field_error: errors.exchange_rate}"
               *ngIf="('task.offer.change'|HasPerm)"
               formControlName="exchange_rate">
        <div *ngIf="!('task.offer.change'|HasPerm)" style="display: inline;">
          {{ data.workflow.object.exchange_rate }}
        </div>
      </div>
    </div>
  </div>

</div>
