<div class="content-navigation">
  <a uiSref="a.crm.customer.detail" [uiParams]="{id: customer.id}" uiSrefActiveEq="active">
    Übersicht
  </a>
  <a uiSref="a.crm.customer.contacts" [uiParams]="{id: customer.id}" uiSrefActiveEq="active">
    Ansprechpartner
  </a>
  <a uiSref="a.crm.customer.akquise_list" [uiParams]="{id: customer.id}" uiSrefActiveEq="active"
     (click)="reload($event, 'a.crm.customer.akquise_list')">
    Aktivitäten
  </a>
  <a uiSref="a.crm.customer.comment" [uiParams]="{id: customer.id}" uiSrefActiveEq="active">
    Kommentare
  </a>
  <a uiSref="a.crm.customer.article_extra" [uiParams]="{id: customer.id}" uiSrefActiveEq="active"
     *ngIf="('article.change'|HasPerm)">
    Liefervorschriften
  </a>
  <!-- FIXME: change state to a.crm.document.list and a.crm.document.create and use uiSrefActive-->
  <a uiSref="a.crm.customer.document_list" [uiParams]="{id: customer.id}" uiSrefActiveEq="active"
     [ngClass]="{active: state.includes('a.crm.customer.document_create')}">
    Dokumente
  </a>
  <a uiSref="a.crm.customer.opos" [uiParams]="{id: customer.id}" uiSrefActiveEq="active"
     *ngIf="('opos'|HasPerm)">
    OPOS
  </a>
</div>
