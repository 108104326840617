import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable} from 'rxjs';
import {ArticleConfigFieldNamesEditModalComponent} from './article-config-field-names-edit-modal.component';
import {ArticleSpecification} from '../../../../envisia/article/models/article-specification.model';

@Injectable({providedIn: 'root'})
export class ArticleConfigFieldNamesEditModalService {
  constructor(private modalService: NgbModal) {
  }

  open(spec: ArticleSpecification): Observable<ArticleSpecification> {
    return (new Observable<ArticleSpecification>(o => {
        const modalRef = this.modalService.open(ArticleConfigFieldNamesEditModalComponent);
        modalRef.componentInstance.specification = spec;
        from(modalRef.result).subscribe({
          next: (updatedSpec) => o.next(updatedSpec),
          error: () => o.error(),
          complete: () => o.complete(),
        });
    }));
  }
}
