<div class="row">
  <div class="col-md-10">
    <!-- Nested Bootstrap Container, See: http://getbootstrap.com/css/#grid-nesting-->
    <div class="row">
      <div class="col-md-12">
        <h2>
          <i class="fa fa-tasks"></i> Vorgang: {{ data.workflow.object.id }} - {{ data.workflow.object.username }}
        </h2>

        <p [ngClass]="{'alert alert-danger': !positionsValid && !('sales.restricted'|HasPerm)}">
          Status: Angebot
          <ng-container *ngIf="lastOffer">
            <ev-file-button [objId]="lastOffer.id" type="offer" class="text-danger"
                            [title]="lastOffer.id + ' vom ' + lastOffer.date">
              {{ lastOffer.id }} vom {{ lastOffer.date }}

              <div *ngIf="!positionsValid && !('sales.restricted'|HasPerm)" class="text-danger inline-div">
                <i class="fa fa-warning"></i>
                <strong>Achtung!</strong> Artikel, Preis oder Wechselkurs wurde geändert
              </div>

              <img *ngIf="lastOffer.de" src="/ui/assets/images/flags/de.png"
                   title="Es wurden deutsche Preise angeboten">

              <img *ngIf="lastOffer.cn" src="/ui/assets/images/flags/cn.png"
                   title="Es wurden China-Preise angeboten">

              <ng-container *ngIf="lastOffer">
                <div class="inline-div">
                  <workflow-state type="offer" [key]="lastOffer.status" clazz="item-status"></workflow-state>
                </div>
              </ng-container>

            </ev-file-button>
          </ng-container>
        </p>

        <div class="alert alert-danger" role="alert" *ngIf="data.workflow.object.status === 100">
          <span class="bold">Achtung!</span> Vorgang wurde storniert!
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="data.workflow.object.status === 101">
          <span class="bold">Achtung!</span> Vorgang wurde gelöscht!
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="!data.article.customer">
          <span class="bold">Achtung!</span> Artikel hat kein Kunde hinterlegt!
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="!!lastOffer?.rejection_reason_id">
          <div class="row">
            <div class="col-sm-3">
              Grund für Ablehnung: {{ lastOffer.rejection_reason }}
            </div>
            <div class="col-sm-9">
              <ng-container *ngFor="let rejectionNoteLine of lastOfferRejectionNoteLines">
                <p>{{ rejectionNoteLine }}</p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="btn-group">
          <a class="btn btn-default btn-sm"
             [uiSref]="(data.workflow.positions.length === 0 || !positionsValid) ? '' : 'a.workflow.create'"
             [uiParams]="{name: 'offer', id: data.workflow.object.id}"
             *ngIf="('task.change'|HasPerm)"
             [class.disabled]="(data.workflow.positions.length === 0 || !positionsValid)">
            Angebot erstellen
          </a>
          <a class="btn btn-default btn-sm"
             [uiSref]="(data.offer_list.length <= 0 || data.workflow.positions.length === 0) ? '' : 'a.workflow.create'"
             [uiParams]="{name: 'contract', id: data.workflow.object.id}"
             *ngIf="('task.offer.change'|HasPerm) || ('task.change'|HasPerm)"
             [class.disabled]="(data.offer_list.length <= 0 || data.workflow.positions.length === 0)">
            Auftrag erstellen
          </a>

          <a uiSref="a.workflow.request.detail"
             [uiParams]="{id: data.workflow.object.id}"
             *ngIf="('task.offer.change'|HasPerm) || ('task.change'|HasPerm)"
             class="btn btn-default btn-sm">
            Anfrage / Bestellung
          </a>

          <a class="btn btn-default btn-sm"
             uiSref="a.workflow.o.list"
             [uiParams]="{workflow_id: data.workflow.object.id}"
             *ngIf="data.offer_list.length > 0 || ('sales.restricted'|HasPerm)">
            Angebotsliste
          </a>

          <a class="btn btn-default btn-sm"
             uiSref="a.workflow.document.list"
             [uiParams]="{id: data.workflow.object.id}">
            Dokumente
          </a>
        </div>

        <div>&nbsp;</div>
      </div>
    </div>

    <!-- TODO(schmitch): Replace some of these with angularjs directives -->
    <form name="workflow_form">
      <div class="row">
        <div class="col-md-3">
          <div class="content-box">

            <article-box [article]="data.article"
                         [workflowId]="data.workflow.object.id"
                         [inventory]="inventory"
                         [right]="('task.offer.change'|HasPerm)"
                         (reloadInventory)="reloadInventory()">
            </article-box>

          </div>
        </div>

        <offer-detail-form [data]="data" (updatePosition)="positionChanged($event)"></offer-detail-form>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="btn-group" *ngIf="!('sales.restricted'|HasPerm)">
            <article-button-box [article]="data.article.oa_nr" [workflowId]="data.workflow.object.id">
            </article-button-box>
          </div>
        </div>
      </div>
    </form>

    <!-- End Nested Bootstrap Container -->
  </div>
  <div class="col-md-2">
    <div style="max-height: 400px; overflow-y: auto;">
      <comment [comments]="data.comments"
               typ="workflow"
               [item]="data.workflow.object.id.toString()"
               placement="bottom">
      </comment>
    </div>
  </div>
</div>

<position-holder [data]="data" (updatePosition)="positionChanged($event)"></position-holder>
