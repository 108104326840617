import {Component, Input} from '@angular/core';
import {MaterialArticleListElement, MaterialGroupListElement} from '../material-management.model';
import {StateService} from '@uirouter/core';
import {MaterialManagementService} from '../material-management.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'material-article-list',
  templateUrl: './material-article-list.component.html'
})
export class MaterialArticleListComponent {
  @Input() articles: MaterialArticleListElement[];
  @Input() currentGroup: MaterialGroupListElement;
  busy = false;
  length: number;
  after: number | null = 0;
  locked = false;

  constructor(private stateService: StateService,
              private mmService: MaterialManagementService,
              private alertService: AlertService) {
  }

  isGroupFiltered(): boolean {
    return !this.stateService.params.group;
  }

  getGroup(): string {
    return this.stateService.params.group;
  }

  delete(article: MaterialArticleListElement): void {
    if (confirm(
      'Achtung!\nArtikel wirklich löschen?\nArtikel und alle dazugehörigen Variationen werden endgültig gelöscht.'
    )) {
      this.mmService.deleteArticle(article.id).subscribe(deletedArticle => {
        this.alertService.add(
          'success',
          'Artikel ' + deletedArticle.article_id + ' ' + deletedArticle.name + ' wurde erfolgreich gelöscht'
        );
        const groupId = Number(this.getGroup());
        this.mmService.listArticle(Number.isInteger(groupId) ? groupId : null).subscribe(list => {
          this.articles = list;
        });
      }, response => {
        this.alertService.add(
          'error',
          (response.status === 404 ? 'Artikel wurde nicht gefunden' : 'Artikel konnte nicht gelöscht werden')
        );
      });
    }
  }
}
