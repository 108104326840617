import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {noop} from '../../../../../helper/noop';
import {
  MaterialOrderIntakeListElement,
  MaterialOrderPosition,
  MaterialOrderTransaction,
  MaterialOrderTransactionListElement
} from '../../material-order.model';
import {MaterialValueType} from '../../../material-management.model';
import {MaterialOrderPositionService} from '../../material-order-position.service';
import {FormArray, FormGroup} from '@angular/forms';
import {
  MaterialOrderIntakeBookedPositionForm,
  MaterialOrderIntakeBookedTransactionForm
} from './material-order-intake-booked-form.model';

@Component({
  selector: 'tbody[material-order-intake-booked-position]',
  templateUrl: './material-order-intake-booked-position.component.html'
})
export class MaterialOrderIntakeBookedPositionComponent implements OnInit {
  @Input('material-order-intake-booked-position') position: MaterialOrderIntakeListElement;
  @Input('material-order-intake-booked-position-index-g') iG: number;
  @Input('material-order-intake-booked-position-index-p') iP: number;
  @Input('material-order-intake-booked-position-finder-supplier-uri') finderSupplierUri?: string;
  @Input('material-order-intake-booked-position-fields') fields: MaterialValueType[];
  @Input('material-order-intake-booked-position-group-permits') groupPermission: boolean;
  @Input('material-order-intake-booked-position-form') form: FormGroup<MaterialOrderIntakeBookedPositionForm>;
  @Input('material-order-intake-booked-position-marked-variation-id') markedVariationId?: number;
  @Input('material-order-intake-booked-position-marked-transaction-id') markedTransactionId?: number;
  @Input('material-order-intake-booked-position-errors') errors: {[key: string]: any} = {};
  @Output('material-order-intake-booked-position-update-order') updateOrder = new EventEmitter<void>();

  remaining: number;
  transactions: MaterialOrderTransactionListElement[];

  constructor(private mopService: MaterialOrderPositionService) {
  }

  ngOnInit(): void {
    const transactions = this.position.transactions;
    this.transactions = (transactions === null || transactions.length === 0) ? [null] : this.position.transactions;
  }

  trackByFn(index: number, item?: MaterialOrderTransaction): number {
    noop(this);
    return item?.id;
  }

  updatePosition(position: MaterialOrderPosition): void {
    this.updateOrder.emit();
    this.position.status = position.status;
    this.position.deleted = !!position.date_deleted;
  }

  get formArrayMapped(): (MaterialOrderTransactionListElement & {form: FormGroup<MaterialOrderIntakeBookedTransactionForm>})[] {
    const formArray = this.form.get('transactions') as FormArray<FormGroup<MaterialOrderIntakeBookedTransactionForm>>;
    return this.position.transactions.map(transaction => {
      const index = formArray.value.findIndex(p => p.order_transaction_id === transaction.id);
      return {...transaction, form: formArray.at(index)};
    });
  }
}
