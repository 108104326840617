<div class="modal-header">
  <div class="modal-title">
    <h3>Artikel speichern</h3>
  </div>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="form-group">
      <label class="col-md-2 control-label" for="reason">Grund:</label>

      <div class="col-md-10">
        <select id="reason" class="form-control" formControlName="reason"
                [ngClass]="{red_alert: errors['obj.form.reason']}">
          <option *ngFor="let notice of notices" [value]="notice">{{ notice }}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-2 control-label" for="reference">Referenz</label>

      <div class="col-md-10">
      <textarea id="reference" class="form-control" formControlName="reference" rows="10"
                [ngClass]="{red_alert: errors['obj.form.reference']}">
      </textarea>
      </div>
    </div>

    <div class="form-group alert alert-warning" *ngIf="article.sister">
      <label>
        <input type="checkbox" formControlName="sister">
        Schwester-Artikel mit gleichem Inhalt sperren.
      </label>
    </div>

    <div class="form-group red_alert" *ngIf="has_active_bas">
      <div class="row">
        <div class="col-sm-1">
          <img src="/ui/assets/images/envisia/chkbx-checked.png"
               *ngIf="valid"
               (click)="makeValid(false)"
               class="fade-chkbx" style="margin-right: 15px;">
          <img src="/ui/assets/images/envisia/chkbx-unchecked.png"
               *ngIf="!valid"
               (click)="makeValid(true)"
               class="fade-chkbx" style="margin-right: 15px;">
        </div>
        <div class="col-sm-11">
          <strong>Achtung:</strong>
          Es sind aktive Betriebsaufträge mit diesem Artikel vorhanden.
          Artikel wirklich ändern?
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">&nbsp;</div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <a uiSref="a.business_order.list" [uiParams]="{oa_nr: article.oa_nr, sds_nr: article.sds_nr}" target="_blank">
          Liste mit Betriebsaufträgen mit diesem Artikel öffnen
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-default pull-left" (click)="close()" type="button">
      Abbrechen
    </button>
    <button class="btn btn-success pull-right" type="submit"
            [disabled]="form.invalid || form.pristine || buttonBlock || !valid">
      Artikel speichern
    </button>
  </div>
</form>
