import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../core/shared.module';
import {MaterialProductGroupsNavigationComponent} from './material-product-groups-navigation.component';
import {MaterialProductGroupsListComponent} from './material-product-groups-list.component';
import {MaterialProductGroupsFormComponent} from './modals/material-product-groups-form.component';
import {MaterialProductGroupsCreateModalComponent} from './modals/material-product-groups-create-modal.component';
import {MaterialProductGroupsEditModalComponent} from './modals/material-product-groups-edit-modal.component';
import {MaterialProductGroupsSelectionModalComponent} from './modals/material-product-groups-selection-modal.component';
import {MaterialProductGroupsTabListComponent} from './material-product-groups-tab-list/material-product-groups-tab-list.component';
import {MaterialNavigationModule} from '../navigation/material-navigation.module';

@NgModule({
  declarations: [
    MaterialProductGroupsNavigationComponent,
    MaterialProductGroupsListComponent,
    MaterialProductGroupsFormComponent,
    MaterialProductGroupsCreateModalComponent,
    MaterialProductGroupsEditModalComponent,
    MaterialProductGroupsSelectionModalComponent,
    MaterialProductGroupsTabListComponent,
  ],
  exports: [
    MaterialProductGroupsTabListComponent
  ],
  imports: [
    SharedModule,
    NgbModalModule,
    MaterialNavigationModule,
  ]
})
export class MaterialProductGroupsModule {
}
