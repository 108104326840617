<div class="modal-header">
  <h3 id="modal-title">Lagerbestand umbuchen</h3>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="form-group row" *ngIf="('material.order.price.check'|HasPerm)">
      <div class="col-md-4">Einzelpreis:</div>
      <div class="col-md-8" *ngIf="!!storage.price">{{storage.price|money:2}}&nbsp;&euro;</div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">Charge:</div>
      <div class="col-md-8">{{storage.charge}}</div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">Wareneingang:</div>
      <div class="col-md-8">{{storage.date_received}}</div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">Aktueller Lagerplatz:</div>
      <div class="col-md-8">{{storage.location}}</div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">Bestandstyp:</div>
      <div class="col-md-8">{{storage.konsi && isKonsi ? 'Konsi' : 'Eigen' }}</div>
    </div>

    <div class="form-group row">
      <label class="control-label col-md-4" for="location">Ziel Lagerplatz:</label>

      <div class="col-md-8">
        <input id="location" type="text" class="form-control" formControlName="location"
               [ngClass]="{red_alert: errors['obj.location']}">
      </div>
    </div>

    <div class="form-group row">
      <label class="control-label col-md-4" for="amount">Menge:</label>

      <div class="col-md-8">
        <input id="amount" type="number" class="form-control" min="1" [max]="storage.amount" formControlName="amount"
               [ngClass]="{red_alert: errors['obj.amount']}">
      </div>
    </div>

    <div class="form-group row">
      <label for="note" class="control-label col-md-4">Notiz:</label>
      <div class="col-md-8">
        <input id="note" type="text" class="form-control" formControlName="note"
               [ngClass]="{red_alert: errors['obj.note']}">
      </div>
    </div>

    <div *ngIf="errors['obj.amount']">
      <ng-container [ngSwitch]="errors['obj.amount'][0]['msg']">
        <ng-container *ngSwitchCase="'error.negative.balance'">
          <span class="alert red_alert">Es können nicht mehr Materialien umgebucht werden, als vorhanden sind.</span>
        </ng-container>

        <ng-container *ngSwitchCase="'error.zero.balance'">
          <span class="alert red_alert">Es sollte mindestens eine Einheit umgebucht werden.</span>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Abbrechen
    </button>

    <button class="btn btn-success pull-right" type="submit">
      Bestand umbuchen
    </button>
  </div>
</form>
