import {NgModule} from '@angular/core';
import {EvFileButtonComponent} from './envisia-file-button/envisia-file-button.component';
import {HasGroup, HasPerm} from './right-service/right.pipes';
import {CommonModule} from '@angular/common';
import {EvFocusDirective} from './ev-focus/ev-focus';
import {EvNavigationComponent} from './ev-navigation/ev-navigation.component';
import {TruncatePipe} from './limit-to/limit-to.pipe';
import {TriStateCheckboxComponent} from './tri-state-checkbox/tri-state-checkbox.component';
import {FormsModule} from '@angular/forms';
import {IntegerInputComponent} from './envisia-input/integer-input.component';
import {MoneyPipe} from './money/money.pipe';
import {LoadingButtonComponent} from './loading-button/loading-button.component';
import {InputSelectDirective} from './input-select/input-select.directive';
import {SearchTypeComponent} from './search/search-type.component';
import {WeekPipe} from '../helper/week.pipe';
import {DatePickerInputComponent, DatepickerModule, EvClass, DirectiveModule} from '@envisia/ev-datepicker';
import {EuroPipe} from './money/euro.pipe';
import {EvStatusComponent} from './ev-status/ev-status.component';
import {EvAnchorUISrefDirective, EVSrefDirective} from './sref/sref';
import {IsNullPipe} from './is-null/is-null.pipe';
import {Contains} from './contains-pipe/contains.pipes';
import {EvFileUploadComponent} from './ev-file-upload/ev-file-upload.component';
import {JoinStringPipe} from './join-string-pipe/join-string-pipe.pipe';
import {Concat} from './concat-pipe/concat.pipe';
import {ZonedDateTimePipe} from './zoned-date/zoned-date-time.pipe';

@NgModule({
    imports: [CommonModule, FormsModule, DatepickerModule, DirectiveModule],
    declarations: [
        SearchTypeComponent,
        HasPerm,
        HasGroup,
        EvFileButtonComponent,
        EvFocusDirective,
        EvNavigationComponent,
        LoadingButtonComponent,
        TruncatePipe,
        MoneyPipe,
        EuroPipe,
        ZonedDateTimePipe,
        TriStateCheckboxComponent,
        IntegerInputComponent,
        InputSelectDirective,
        WeekPipe,
        EvStatusComponent,
        EVSrefDirective,
        EvAnchorUISrefDirective,
        IsNullPipe,
        Contains,
        Concat,
        JoinStringPipe,
        EvFileUploadComponent,
    ],
    exports: [
        SearchTypeComponent,
        HasPerm,
        HasGroup,
        EvClass,
        EvFileButtonComponent,
        EvFocusDirective,
        EvNavigationComponent,
        LoadingButtonComponent,
        TruncatePipe,
        MoneyPipe,
        EuroPipe,
        ZonedDateTimePipe,
        TriStateCheckboxComponent,
        IntegerInputComponent,
        DatePickerInputComponent,
        InputSelectDirective,
        WeekPipe,
        EvStatusComponent,
        EVSrefDirective,
        EvAnchorUISrefDirective,
        IsNullPipe,
        Contains,
        Concat,
        JoinStringPipe,
        EvFileUploadComponent,
    ]
})
export class EnvisiaCommon {
}
