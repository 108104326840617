/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {ErrorHandler, Injectable} from '@angular/core';
import {LocalStorage} from '../../common/storage/local-storage';
import {StreamService} from '../main/stream/stream.service';
import {SessionStorage} from '../../common/storage/session-storage';
import {Observable, throwError, of, timer} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {AppRunService} from '../../app.service';
import {InitData} from '../../common/init-data/init-data';
import {environment} from '../../../environments/environment';
import {BroadcastChannel, createLeaderElection} from 'broadcast-channel';
import {GlobalService} from '../../core/global.service';
import { createHttpClient } from './http-client.factory';
import { BasicHttpClient } from './basic-http-client';

@Injectable({providedIn: 'root'})
export class AuthService {
  public authenticated = false;

  constructor(private http: HttpClient,
              private appRunService: AppRunService,
              private localStorageService: LocalStorage,
              private streamService: StreamService,
              private localStorage: LocalStorage,
              private sessionStorage: SessionStorage,
              private globalService: GlobalService,
              private basicHttpClient: BasicHttpClient,
              private errorHandler: ErrorHandler) {
  }

  logout(streamLogout: boolean = true, inactivity = false): void {
    this.http.post<void>(environment.accountUri + 'Logout', {}).subscribe(
      () => this.removeAuth(streamLogout, inactivity));
  }

  public isAuthenticated(): Observable<boolean> {
    // we ask the sessionStorage if the user is already authenticated,
    // if the sessionStorage returns a useful value, than we know that
    // the user is authenticated
    // (if we need to call init() on every tab reload, we can use a static var in AuthService,
    //  because AuthService is a application Singleton)
    // if (this.sessionStorage.get('authenticated', 'false') !== 'true') {
    if (this.authenticated !== true) {
      return this.appRunService.init().pipe(
        map((data: InitData) => {
          this.localStorage.putObject('data', data);
          this.sessionStorage.set('version', data.raw_version);
          this.sessionStorage.set('authenticated', 'true');
          this.authenticated = true;

          this.streamService.newEventSource((inactivity = false) => {
            this.removeAuth(false, inactivity);
          });
          this.checkLogout();

          return true;
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.status === 401) {
            window.location.href = environment.accountUri + 'Login';
            return of(false);
          } else {
            return throwError(response);
          }
        })
      );
    } else {
      return of(true);
    }
  }

  public removeAuth(streamLogout: boolean, inactivity: boolean) {
    if (inactivity) {
      this.basicHttpClient.http.get(environment.accountUri + 'Check', {observe: 'response'}).subscribe(() => {
        this.errorHandler.handleError('inactivity triggered with a valid session, something is still fishy');
      }, () => {
        this.removeAuthInner(streamLogout, inactivity);
      });
    } else {
      this.removeAuthInner(streamLogout, inactivity);
    }
  }

  private removeAuthInner(streamLogout: boolean, inactivity: boolean) {
    if (streamLogout) {
      this.streamService.logout(inactivity);
    }

    // we only need to remove data, token and user keys since
    // others can still be cached after logout
    this.localStorageService.remove('data');
    this.localStorageService.remove('token');
    this.localStorageService.remove('user');
    // removes the session to revalidate the login
    this.authenticated = false;
    this.sessionStorage.set('authenticated', 'false');
    // redirects to logged out page
    this.globalService.removeEvents();
    (window as any).location = environment.accountUri + 'LoggedOut' + (inactivity ? '?inactivity=true' : '');
  }

  private checkLogout() {
    const channelLeaderElection = new BroadcastChannel<any>('auth-poll-leader-election');
    const elector = createLeaderElection(channelLeaderElection, {
      fallbackInterval: 2000, // optional configuration for how often will renegotiation for leader occur
      responseTime: 1000, // optional configuration for how long will instances have to respond
    });
    elector.awaitLeadership().then(() => {
      console.log('i am the tab leader for auth-poll');
      //  30000: 30 seconds
      timer(30000, 30000).subscribe(() => {
        this.http.get(environment.accountUri + 'Check', {observe: 'response'}).subscribe();
      });
    });
  }

}
