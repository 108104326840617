import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {InventoryModalComponent} from './inventory.modal.component';
import {ArticleInventoryFormComponent} from './form/article-inventory-form.component';
import {
  ArticleInventoryTransferWorkflowFormComponent
} from './transfer-workflow/article-inventory-transfer-workflow-form.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    InventoryModalComponent,
    ArticleInventoryFormComponent,
    ArticleInventoryTransferWorkflowFormComponent
  ],
  exports: [InventoryModalComponent, ArticleInventoryTransferWorkflowFormComponent]
})
export class InventoryModalModule {
}
