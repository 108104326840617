import {DateHelper} from '../../../../helper/date-helper';

/**
 * Checks if a date string is bigger than another date string
 *
 * @param {string} current the current date string
 * @param {string} date the new date string that should be bigger than the current
 * @returns {boolean} true if the new string will be bigger than current
 */
export function dateCheck(current: string, date: string): boolean {
  const currentDate = DateHelper.safeParse(current);
  const dateDate = DateHelper.safeParse(date);
  let checked = false;
  if (!!currentDate && !!dateDate) {
    // if the year is bigger than, we now that the new date is bigger
    if (dateDate.getFullYear() < currentDate.getFullYear()) {
      checked = true;
      // if the month is bigger than, while the year is equal, we know that the new date is bigger
    } else if (dateDate.getFullYear() === currentDate.getFullYear() && dateDate.getMonth() < currentDate.getMonth()) {
      checked = true;
      // if the day is bigger than the current date while the year and month are equal, the new date is bigger
    } else if (dateDate.getFullYear() === currentDate.getFullYear() &&
      dateDate.getMonth() === currentDate.getMonth() && dateDate.getDate() < currentDate.getDate()) {
      checked = true;
    }
  } else if (!currentDate && !!dateDate) {
    checked = true;
  }
  return checked;
}
