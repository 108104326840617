import * as React from 'react';
import {Lister} from '../../../../common/wrapper.models';
import {OfferRejectionReason} from '../../../../lazy/config/offer-rejection/config-offer-rejection-reason.model';
import {useState} from 'react';
import {EvReactModal} from '../../../../react/modal/EvReactModal';
import {OfferRejectionForm} from './offer-rejection-form';

interface Props {
  offerId: number;
  reasons: Lister<OfferRejectionReason>;
  reload: () => void;
}

export function OfferRejectionModal(props: Props): JSX.Element {
  const [showModal, showModalSet] = useState<boolean>(false);

  const closeModal: () => void = () => {
    showModalSet(false);
  };

  const closeModalAndReload: () => void = () => {
    closeModal();
    props.reload();
  };

  const openModal: () => void = () => {
    showModalSet(true);
  };

  return <>
    <button type={'button'}
            className={'btn btn-link btn-sm'}
            title={'Angebot auf Status \'Kein Auftrag\' setzen'}
            onClick={openModal}>
      <i className={'fa fa-thumbs-o-down'}/>
    </button>
    <EvReactModal contentLabel={'Angebot auf Status \'Kein Auftrag\' setzen'}
                  showModal={showModal}
                  modalClosed={closeModal}>
      <OfferRejectionForm offerId={props.offerId}
                          reasons={props.reasons}
                          success={closeModalAndReload}
                          cancel={closeModal}/>
    </EvReactModal>
  </>;
}
