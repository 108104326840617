/*
 * Copyright (C) 2019 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {InvoiceJobValue} from '../invoice.models';

@Injectable({providedIn: 'root'})
export class InvoiceSendService {

  constructor(private http: HttpClient) {
  }

  postValue(): Observable<{ enabled: boolean, active: boolean }> {
    return this.http.get<{ enabled: boolean, active: boolean }>(environment.apiv4uri + 'invoice/post/is-active');
  }

  send(invoiceId: number): Observable<InvoiceJobValue> {
    return this.http.post<InvoiceJobValue>(environment.apiv4uri + 'invoice/send/' + invoiceId, {});
  }

}
