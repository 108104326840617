<div class="row-field">
  <div class="row">
    <div class="col-md-12">
      <h3>Artikel</h3>
    </div>
  </div>

  <!-- Null Value -->
  <div class="row">
    <div class="col-md-12 min" *ngIf="article">
      <a uiSref="a.article.workflow.overview" [uiParams]="{id: article.oa_nr}"
         target="_blank"
         ngbTooltip="Artikelseite in neuem Fenster öffnen">

        <h4 *ngIf="article.sds_nr">SDS-Nr.: {{ article.sds_nr }}</h4>
        <h4 *ngIf="!article.sds_nr">0A-Nr.: {{ article.oa_nr }}</h4>
      </a>
      <span *ngIf="article.sds_nr">0A-Nr.: {{ article.sds_nr }}</span>
    </div>

    <div class="col-md-12 min" *ngIf="!article">
      <h4>kein Artikel ausgewählt</h4>
    </div>

  </div>

  <div class="row">
    <div class="col-md-12 text-left">

      <div class="btn-group">
        <button type="button" class="btn btn-default btn-sm" (click)="switchArticle()" [disabled]="formDisabled">
          Bearbeiten
        </button>
      </div>

    </div>
  </div>
</div>
