import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as React from 'react';
import {MaterialProductGroupsTabList} from './material-product-groups-tab-list';
import {MaterialGroup} from '../../groups/material-group.model';
import {MaterialProductGroupListElement} from '../material-product-groups.models';
import {ArrayHelper} from '../../../../common/array-helper';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';

const containerElementName = 'reactMaterialProductGroupsTabList';

@Component({
  selector: 'material-product-groups-tab-list',
  template: `<div #${containerElementName}></div>`,
})
export class MaterialProductGroupsTabListComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() productGroups: MaterialProductGroupListElement[];
  @Input() groups: MaterialGroup[] = [];
  @Input() activeProductGroupId?: number;
  @Input() activeGroupIds?: number[] = [];
  @Input() singleMode = false;
  @Output() setGroupId: EventEmitter<number> = new EventEmitter<number>();
  @Output() setProductGroupId: EventEmitter<number> = new EventEmitter<number>();
  @Output() setGroupIds: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor() {
    super();
  }

  emitSetProductGroupId(selectedProductGroupId: number): void {
    if (selectedProductGroupId === this.activeProductGroupId) {
      return;
    }

    this.setProductGroupId.emit(selectedProductGroupId);
  }

  emitSetGroupIds(selectedGroupIds: number[]): void {
    if (ArrayHelper.equals(selectedGroupIds, this.activeGroupIds)) {
      return;
    }

    this.setGroupIds.emit(selectedGroupIds);
    if (selectedGroupIds.length === 1) {
      this.setGroupId.emit(selectedGroupIds[0]);
    }
  }

  protected override renderComponent() {
    const _ = React.version;
    return <MaterialProductGroupsTabList productGroups={this.productGroups ?? []}
                                         groups={this.groups ?? []}
                                         activeProductGroupId={this.activeProductGroupId ?? null}
                                         activeGroupIds={this.activeGroupIds ?? []}
                                         singleMode={this.singleMode}
                                         setProductGroupId={id => this.emitSetProductGroupId(id)}
                                         setGroupIds={ids => this.emitSetGroupIds(ids)}
    />;
  }
}
