import {Transition, Ng2StateDeclaration} from '@uirouter/angular';
import {CustomerNavigationComponent} from '../navigation/customer-navigation.component';
import {CustomerOposComponent} from './customer-opos.component';
import {GlobalService} from '../../../core/global.service';
import {OposService} from '../../opos/services/opos.service';

export function oposResolveFn(transition: Transition, globalService: GlobalService, oposService: OposService) {
  const id = transition.params().id;
  const filter = {
    invoices: transition.params().invoices,
    date: globalService.getOposDateRaw()
  };

  return oposService.customer(id, filter).toPromise();
}

export const CUSTOMER_OPOS_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer.opos',
  url: '/crm/opos/{id:[0-9]+}?invoices',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: CustomerOposComponent}
  },
  resolve: [
    {
      token: 'opos',
      resolveFn: oposResolveFn,
      deps: [Transition, GlobalService, OposService],
    }
  ],
  params: {
    id: {dynamic: true},
    invoices: {type: 'query', dynamic: true}
  },
  data: {requiresAuth: true}
};
