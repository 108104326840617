<form [formGroup]="form">

  <div class="row">
    <div class="col-lg-12">

      <h2>
        <a uiSref="a.workflow.detail" [uiParams]="{id: workflow.id }" ngbTooltip="Zurück zum Vorgang">
          <i class="fa fa-chevron-left"></i> Vorgang: {{ workflow.id }}
        </a>
      </h2>

      <h1>Rechnung erstellen</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-7">

      <div class="form-group row">
        <label for="date" class="control-label col-md-1">Rechnungs-Datum: *</label>

        <div class="col-md-5">
          <ev-datepicker [writeBack]="false" [allDates]="true" formControlName="date" class="form-control" id="date">
          </ev-datepicker>
        </div>

        <div class="col-md-6">&nbsp;</div>
      </div>
      <div class="form-group row">
        <label for="hint" class="control-label col-md-1">Hinweis</label>

        <div class="col-md-5">
                    <textarea id="hint" formControlName="hint" class="form-control">
                    </textarea>
        </div>
        <div class="col-md-6">&nbsp;</div>
      </div>

      <table class="table whitetable">
        <thead>
        <tr>
          <th>Auf.-Best.</th>
          <th>Lieferschein</th>
          <th class="align-right">Menge</th>
          <th class="align-right">Betrag</th>
          <th>Kundenbestellung</th>
          <th>&nbsp;</th>
        </tr>
        </thead>

        <tbody>

        <tr *ngFor="let obj of data.delivery_list">
          <td>
            <ng-container *ngIf="obj.last_contract">
              <ev-file-button [objId]="obj.last_contract.id" type="contract">
                {{ obj.last_contract.id }} - {{ obj.last_contract.date|date:'dd.MM.yyyy' }}
              </ev-file-button>
            </ng-container>
          </td>
          <td>
            <ev-file-button [objId]="obj.id" type="delivery">
              {{ obj.id }} - {{ obj.delivery_date|date:'dd.MM.yyyy' }}
            </ev-file-button>
          </td>
          <td class="align-right">{{ obj.quantity |money:0}}</td>
          <td class="align-right">
            <span class="bold">{{ obj.price_total|money:2 }}&nbsp;€</span>
          </td>
          <td>
            <ng-container *ngIf="obj.last_contract">
              {{ obj.last_contract.offer_nr }}
              <ng-container *ngIf="obj.last_contract.offer_nr && obj.last_contract.offer_date"> -</ng-container>
              {{ obj.last_contract.offer_date|date:'dd.MM.yyyy' }}
            </ng-container>
          </td>
          <td>

            <loading-button clazz="btn btn-success pull-right"
                            (loadingSave)="create($event, obj)"
                            [disabled]="(!!data.article.date_deleted || (!!data.article.locked && data.workflow.frame.typ === 'de'))">
              Rechnung erstellen
            </loading-button>
          </td>
        </tr>

        </tbody>
      </table>

    </div>

    <div class="col-lg-5">
      <table class="table whitetable">
        <thead>
        <tr>
          <th>Rechnung</th>
          <th>Datum</th>
          <th>Ersteller</th>
          <th>Stornierung</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let invoice of data.overview_list; let index = index">
          <td>
            <ev-file-button [objId]="invoice.id" type="invoice">
              <span [ngClass]="{deleted: !!invoice.storno_id}">{{ invoice.id }}</span>
              <ng-container>
                <span class="label label-purple">
                  RG übermittelt
                </span>
              </ng-container>
            </ev-file-button>

            <span class="label label-warning" *ngIf="invoice.storno_id">Storno</span>
          </td>
          <td>
            <ev-file-button [objId]="invoice.id" type="invoice_correction">
              {{ invoice.invoice_date|date:'dd.MM.yyyy' }}
            </ev-file-button>
          </td>
          <td>
            <a [href]="'mailto:' + invoice.user_email">{{ invoice.username }}</a>
          </td>
          <td>
            <button type="button" class="btn btn-default btn-sm"
                    (click)="openStorno(invoice, index)"
                    *ngIf="!invoice.storno_id">
              Rechnung stornieren
            </button>
            <ev-file-button [objId]="invoice.storno_id" type="invoice_correction"
                            *ngIf="!!invoice.storno_id">
              {{ invoice.storno_id }}
            </ev-file-button>
            <div class="click" style="display: inline-block" [ngbTooltip]="invoice.correction_note" *ngIf="!!invoice.correction_note">
              <i class="fa fa-exclamation"></i>
            </div>
          </td>
          <td>
            <button type="button" class="label label-default label-button"
                    *ngIf="invoice.storno_id === 0"
                    (click)="invoiceModal(invoice.id, index)">
              Rechnung übermitteln
            </button>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>

</form>
