<div class="modal-header">
  <h3>Feiertag eintragen</h3>
</div>

<form class="form-horizontal" [formGroup]="createForm" (ngSubmit)="create()">
  <div class="modal-body">

    <div class="form-group">
      <label for="holiday-date" class="col-sm-2 control-label">Datum</label>
      <div class="col-sm-10">
        <label for="holiday-date">Datum:</label>
        <ev-datepicker id="holiday-date" formControlName="date" class="form-control"></ev-datepicker>
      </div>
    </div>

    <div class="form-group">
      <label for="holiday-comment" class="col-sm-2 control-label">Kommentar</label>
      <div class="col-sm-10">
        <textarea class="form-control" id="holiday-comment" formControlName="comment"
                  [ngClass]="{red_alert: errors['obj.comment']}"></textarea>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success pull-right" [disabled]="createForm.pristine || !createForm.valid">
      Hinzufügen
    </button>
  </div>
</form>
