import * as React from 'react';
import {ReactNode} from 'react';
import {ArticleSpecification} from '../../../models/article-specification.model';

interface Props {
  specification: { [key: string]: ArticleSpecification };
  value: any;
  valueType: 'values' | 'objects' | 'keys';
  model: string;
  children?: ReactNode;
  onChange: (s: string) => void;
  disabled?: boolean;
}

function showOptions(props: Props) {
  const specification = props.specification[props.model];
  switch (props.valueType) {
    case 'values':
      const data0 = specification.values as string[];
      return data0.map(key => <option key={key} value={key}>{key}</option>);
    case 'objects':
      const data1 = specification.values2 as any[];
      return data1.map(obj => <option key={obj.key} value={obj.key}>{obj.value}</option>);
    case 'keys':
      const data2 = Object.keys(specification.values2);
      return data2.map(key => <option key={key} value={key}>{key}</option>);
  }
}

export function ArticleMultilayerSelect(props: Props) {
  return <>
    <div className={'article-form-item'} style={{padding: '0', margin: '0'}}>
      <div className={'no-padding article-form-item-data' + (!!props.children ? ' data-group' : '')}
           style={{'width': '285px'}}>
        <div className={'article-form-data-content'}>
          <select id={'article-model-' + props.model}
                  className={'form-control align-right'}
                  value={props.value ?? ''}
                  disabled={props.disabled}
                  style={{'width': '245px'}}
                  onChange={c => props.onChange(c.target.value)}>
            <option key={''} value={''}>-</option>
            {showOptions(props)}
          </select>
        </div>
        {props.children}
      </div>
    </div>
  </>;
}
