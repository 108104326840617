import {Component, Input, OnInit} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CalculationCopyModalComponent} from './calculation-copy-modal.component';
import {ignoreRejection} from '../../helper/ignore_rejection';
import {CalculationService} from './calculation.service';
import {AlertService} from '../../common/alert-service/alert.service';
import {CalculationProcessListElement} from './calculation.model';
import {NumberHelper} from '../../helper/number-helper';

@Component({
  selector: 'calculation-list',
  templateUrl: './calculation-list.component.html',
})
export class CalculationListComponent implements OnInit {
  @Input() processes: CalculationProcessListElement[];
  current: CalculationProcessListElement | null;

  constructor(private stateService: StateService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private processService: CalculationService) {
  }

  ngOnInit(): void {
    this.setCurrentElement(this.stateService.params?.id);
  }

  openModal() {
    const modalRef = this.modalService.open(CalculationCopyModalComponent, {windowClass: 'modal2-smm'});
    modalRef.componentInstance.current = this.current.name;
    modalRef.result.then(() => {
    }, ignoreRejection);
  }

  select(event: Event, id: number): void {
    this.setCurrentElement(id);
  }

  setCurrentElement(id?: number) {
    const idParsed = NumberHelper.saveParseInteger(id);
    const current = this.processes.find(p => p.id === idParsed);
    this.current = idParsed === null || !current ? null : current;
  }

  reload(): void {
    this.processService.list().subscribe(values => {
      this.processes = values;
      this.setCurrentElement(this.current?.id);
    });
  }

  toggleHide() {
    const current = this.current;
    let text = 'Möchten Sie die Kalkulation "' + current.name + '" wirklich ';
    if (!current.hidden) {
      text += 'ausblenden?';
    } else {
      text += 'anzeigen?';
    }

    if (confirm(text)) {
      this.processService.toggleHide(current.id).subscribe(() => {
        this.reload();
      }, () => {
        this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut');
      });
    }
  }

}
