import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  WorkflowAdditional,
  WorkflowAdditionalForm,
  WorkflowAdditionalKanbanForm,
  WorkflowAdditionalSimple,
  WorkflowAdditionalWithDuplicates
} from '../models/workflow.models';
import {Observable} from 'rxjs';
import {EnvisiaObject} from '../../../common/wrapper.models';
import {BigFrame, ExtendedFrame, FrameCreateForm, MergedFrame} from '../models/frame.models';
import {map} from 'rxjs/operators';
import {BusinessOrderProductionValues} from '../business-order/models/business-order-production-values.models';
import {GermanOrChinaPrice} from '../models/position.models';

@Injectable({providedIn: 'root'})
export class FrameService {

  constructor(private http: HttpClient) {
  }

  create(workflowId: number, data: FrameCreateForm): Observable<MergedFrame> {
    return this.http.post<EnvisiaObject<MergedFrame>>('/workflow/v1/' + workflowId + '/frame/', data).pipe(
      map(val => val.object)
    );
  }

  get(workflowId: number): Observable<BigFrame> {
    return this.http.get<EnvisiaObject<BigFrame>>(
      '/workflow/v1/frame/' + workflowId + '/'
    ).pipe(map(value => value.object));
  }

  update(workflowId: number, form: any): Observable<ExtendedFrame> {
    return this.http.put<ExtendedFrame>('/workflow/v1/frame/' + workflowId + '/', form);
  }

  additional(workflowId: number): Observable<WorkflowAdditionalSimple> {
    return this.http.get<EnvisiaObject<WorkflowAdditionalSimple>>(
      '/workflow/v1/frame/additional/' + workflowId + '/'
    ).pipe(map(value => value.object));
  }

  additionalSave(workflowId: number, data: WorkflowAdditionalForm): Observable<WorkflowAdditionalWithDuplicates> {
    return this.http.post<WorkflowAdditionalWithDuplicates>(
      '/workflow/v1/frame/additional/' + workflowId + '/',
      data
    );
  }

  kanbanSave(workflowId: number, data: WorkflowAdditionalKanbanForm): Observable<WorkflowAdditional> {
    return this.http.put<EnvisiaObject<WorkflowAdditional>>(
      '/workflow/v1/frame/additional/kanban/' + workflowId + '/',
      data
    ).pipe(map(value => value.object));
  }

  productionValues(article: string): Observable<BusinessOrderProductionValues> {
    return this.http.get<BusinessOrderProductionValues>(
      '/api/v1/workflow/business/order/article/sums/' + article
    ).pipe(map(value => value));
  }

  check(workflowId: number, data: FrameCreateForm): Observable<GermanOrChinaPrice> {
    return this.http.post<EnvisiaObject<GermanOrChinaPrice>>(
      '/workflow/v1/' + workflowId + '/check/',
      data
    ).pipe(map(val => val.object));
  }

}
