<div class="modal-header">
  <h3 class="modal-title">Arbeitsschritt bearbeiten</h3>
</div>

<form *ngIf="!commentActive" [formGroup]="editForm">
  <div class="modal-body">

    <div class="row">
      <div class="col-md-4">
        Arbeisschritt:
      </div>
      <div class="col-md-4">
        {{ data.production.nr }} {{ data.production.text }}
      </div>
      <div class="col-md-4">&nbsp;</div>

      <div class="col-md-4">
        Dauer:
      </div>
      <div class="col-md-4">
        {{ data.production.duration }} h
      </div>
      <div class="col-md-4">&nbsp;</div>

      <div class="col-md-4">
        Max-Dauer:
      </div>
      <div class="col-md-4">
        {{ data.production.max_duration }} h
      </div>
      <div class="col-md-4">&nbsp;</div>
    </div>

    <div class="row"><div class="col-md-12">&nbsp;</div></div>

    <div class="row"><div class="col-md-12" *ngIf="data.article_values.length > 0"><h3>Artikelwerte:</h3></div></div>

    <div class="row" *ngFor="let line of data.article_values">
      <div class="col-md-12" [innerHTML]="line"></div>
    </div>

    <div class="row">
      <div class="col-md-12">&nbsp;</div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label for="info_text">Info-Text</label>

        <textarea rows="3" name="info_text" id="info_text" class="form-control" formControlName="infoText"
                  [ngClass]="{red_alert: errors['obj.infoText']}">
        </textarea>
      </div>

      <div class="col-md-6">
        <label for="lock_text">Sperr-Text</label>

        <textarea rows="3" name="lock_text" id="lock_text" class="form-control" formControlName="lockText"
                  [ngClass]="{red_alert: errors['obj.lockText']}">
        </textarea>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-left" (click)="dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-success pull-right" (click)="preSubmit(isLockAction())">
      Speichern
    </button>
  </div>

</form>


<form *ngIf="commentActive" [formGroup]="editForm">
  <div class="modal-body">

    <div class="row">
      <div class="col-md-12">
        <label for="comment_text">Kommentar-Text</label>
        <textarea
          (keyup)="validateLockText()"
          [autofocus]="true"
          name="comment_text"
          id="comment_text"
          class="form-control"
          formControlName="commentText"
          [ngClass]="{red_alert: errors['obj.infoText']}"></textarea>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-left" (click)="dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-success pull-right" [disabled]="canNotSubmit" (click)="submit()">
      Speichern
    </button>
  </div>

</form>

