<customer-header-template [customer]="customer"></customer-header-template>

<div id="contactcards">
  <div class="contactcard" *ngFor="let contact of contacts; let index = index" (click)="open(index, contact)">
    <div style="align-self: flex-start;">
      <div class="row">
        <div class="col-sm-12">
          <h4>{{ contact.title }} {{ contact.first_name }} {{ contact.last_name}}</h4>
        </div>
      </div>
      <div class="row" *ngIf="contact.departments.length > 0">
        <div class="col-sm-12">
          {{ contact.departments|JoinStringPipe }}
        </div>
      </div>
      <div class="row" *ngIf="contact.position">
        <div class="col-sm-12">
          {{ contact.position }}
        </div>
      </div>
    </div>

    <div style="align-self: flex-end;">
      <div class="row" *ngIf="contact.phone">
        <div class="col-sm-2">Telefon:</div>
        <div class="col-sm-10">{{ contact.phone }}</div>
      </div>

      <div class="row" *ngIf="contact.mobile">
        <div class="col-sm-2">Mobil:</div>
        <div class="col-sm-10">{{ contact.mobile }}</div>
      </div>

      <div class="row" *ngIf="contact.fax">
        <div class="col-sm-2">Fax:</div>
        <div class="col-sm-10">{{ contact.fax }}</div>
      </div>

      <div class="row" *ngIf="contact.mail">
        <div class="col-sm-2">E-Mail:</div>
        <div class="col-sm-10">{{ contact.mail }}</div>
      </div>
    </div>

    <div style="align-self: flex-end;" *ngIf="contact.note">
      <div class="row">
        <div class="col-sm-12"><strong>Hinweis:</strong></div>
      </div>
      <div class="row">
        <div class="col-sm-12"><strong>{{contact.note}}</strong></div>
      </div>
    </div>

  </div>

</div>
