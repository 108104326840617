import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, from} from 'rxjs';
import {DeliveryLockingModalComponent} from '../modals/delivery-locking-modal.component';

@Injectable({providedIn: 'root'})
export class DeliveryLockingModalService {

  constructor(private modalService: NgbModal) {
  }

  open(id: number, lock: boolean): Observable<any> {
    const modalRef = this.modalService.open(DeliveryLockingModalComponent, {windowClass: 'modal2-m'});
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.lock = lock;
    return from(modalRef.result);
  }

}
