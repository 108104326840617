import {Component, Input} from '@angular/core';

@Component({
  selector: 'finder-upload-progress',
  templateUrl: './finder-upload-progress.component.html',
  styleUrls: ['./finder-upload-progress.component.scss']
})
export class FinderUploadProgressComponent {
  @Input() status: 1 | 2 | 3 | 4 | 5;

  constructor() {
  }
}
