<div class="row">

  <div class="col-md-12">
    <a uiSref="a.workflow.detail" [uiParams]="{id: data.workflow.id}">
      <h3><i class="fa fa-chevron-left"></i> Vorgang {{ data.workflow.id }}</h3>
    </a>
  </div>

  <div class="col-md-3">
    <order-request-main-request-form [data]="data"
                                     [producerList]="customerProducerList"
                                     [workflowPositions]="workflowPositions"
                                     (updateNoteEmitter)="updateRequestNote($event)"
                                     (createRequestEmitter)="updateRequests()">
    </order-request-main-request-form>
  </div>

  <div class="col-md-1"></div>

  <div class="col-md-2">
    <div class="row">
      <div class="col-md-12">
        <delivery-condition-overdelivery-box [oa_nr]="data.article.oa_nr">
        </delivery-condition-overdelivery-box>
      </div>
    </div>
    <div class="row">&nbsp;</div>
    <div class="row">
      <div class="col-md-12">
        <order-request-article-overview [oaNr]="data.article.oa_nr">
        </order-request-article-overview>
      </div>
    </div>
    <div class="row">&nbsp;</div>
    <div class="row">
      <div class="col-md-12">
        <order-request-article-box targetWindow="_blank"
                                   [article]="data.article"
                                   [workflowId]="data.workflow?.id?.toString(10)">
        </order-request-article-box>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <order-request-main-order-form [data]="data"
                                   [producerList]="customerProducerList"
                                   [workflowPositions]="workflowPositions"
                                   [orderToRequestData]="orderToRequestData"
                                   [customerCredit]="customerCredit"
                                   (requestToOrderEmitter)="requestToOrder($event)"
                                   (updateNoteEmitter)="updateOrderNote($event)"
                                   (createOrderEmitter)="updateOrders()">
    </order-request-main-order-form>
  </div>
</div>

<div class="row">
  <div class="col-md-12 margin-bottom">&nbsp;</div>
</div>

<div class="row">
  <div class="col-md-6">
    <h3>Im Vorgang vorhandene Anfragen</h3>
    <order-request-main-request-list [article]="data.article"
                                     [requests]="requests"
                                     [workflow]="data.workflow"
                                     (requestToOrderEmitter)="requestToOrder($event)">
    </order-request-main-request-list>
  </div>

  <div class="col-md-6">
    <h3>Im Vorgang vorhandene Bestellungen</h3>
    <order-request-main-order-list [orders]="orders"
                                   [article]="data.article"
                                   [workflow]="data.workflow"
                                   (updateParent)="updateOrders()">
    </order-request-main-order-list>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <ng-container *ngIf="requestData.data$|async as requestLister; else loeRequests.template">
      <h3>Alle vorhandenen Anfragen</h3>
      <order-request-main-request-wrapper [article]="data.article"
                                          [workflow]="data.workflow"
                                          [outerQuery]="queryArticle"
                                          [outerList]="requestLister"
                                          (requestToOrderEmitter)="requestToOrder($event)">
      </order-request-main-request-wrapper>
    </ng-container>
  </div>

  <div class="col-md-6">
    <ng-container *ngIf="orderData.data$|async as orderLister; else loeOrders.template">
      <h3>Alle vorhandenen Bestellungen</h3>
      <order-request-main-order-wrapper [article]="data.article"
                                        [workflow]="data.workflow"
                                        [outerQuery]="queryArticle"
                                        [outerList]="orderLister"
                                        (updateParent)="updateOrders()">
      </order-request-main-order-wrapper>
    </ng-container>
  </div>
</div>

<loading-or-error #loeRequests [loadingWrapper]="orderData" [errorMessage]="'Konnte Anfrage Liste nicht laden.'">
</loading-or-error>

<loading-or-error #loeOrders [loadingWrapper]="orderData" [errorMessage]="'Konnte Bestellungs Liste nicht laden.'">
</loading-or-error>
