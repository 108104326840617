<div class="filter-row">
  <div class="filter-left">
    <search-type typ="de" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>
    <search-type typ="cn" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>

    <button class="btn btn-info btn-sm" type="button" (click)="badge('noinvoice')"
            [ngClass]="{active: q('noinvoice')}">
      Ohne Rechnung
    </button>
    <button class="btn btn-warning btn-filter" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
      Aktive <span class="badge">{{ list.badges.delivered }}</span>
    </button>

    <button class="btn btn-default btn-filter" type="button" (click)="badge('4')" [ngClass]="{active: q('4')}">
      Gesperrt <span class="badge">{{ list.badges.locked }}</span>
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('2')" [ngClass]="{active: q('2')}">
      Inaktive
    </button>

    <button class="btn btn-default btn-filter" type="button" (click)="badge('all')" [ngClass]="{active: q('all')}">
      Alle <span class="badge">{{ list.badges.all }}</span>
    </button>

    <ev-file-button [url]="downloadUrl('/workflow/v1/delivery/export/')" [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>
  </div>

  <div class="filter-right">
    <date-range [start]="null" [end]="null" (update)="updateDateRange($event)"></date-range>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'id', 'ASC')" [href]="sortUrl('id', 'ASC')">
          Lieferschein
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'workflow_id', 'ASC')" [href]="sortUrl('workflow_id', 'ASC')">
          Vorgang
        </a>
      </th>
      <th>&nbsp;</th>
      <th>
        <a (click)="sort($event, 'date', 'ASC')" [href]="sortUrl('date', 'ASC')">
          Datum
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">
          SDS-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'quanity', 'ASC')" [href]="sortUrl('quanity', 'ASC')">
          Stückzahl
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'oa_nr', 'ASC')" [href]="sortUrl('oa_nr', 'ASC')">
          0A-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_nr', 'ASC')" [href]="sortUrl('kd_art_nr', 'ASC')">
          Kd-Art-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'ASC')" [href]="sortUrl('customer_name', 'ASC')">
          Kunde
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_zip', 'ASC')" [href]="sortUrl('customer_zip', 'ASC')">
          PLZ
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_city', 'ASC')" [href]="sortUrl('customer_city', 'ASC')">
          Stadt
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_country', 'ASC')" [href]="sortUrl('customer_country', 'ASC')">
          Land
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_field_service', 'ASC')" [href]="sortUrl('customer_field_service', 'ASC')">
          Vertriebsgebiet
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'username', 'ASC')" [href]="sortUrl('username', 'ASC')">
          Ersteller
        </a>
      </th>
      <th colspan="5"></th>
    </tr>
    <tr class="filterrow">
      <th>
        <input type="text"
               class="form-control input-sm"
               name="sid"
               [ngClass]="{'red_alert': filterFormErrors.sid}"
               [(ngModel)]="data.sid"
               [autofocus]
               autocomplete="off search-delivery-id">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="workflow_id"
               [ngClass]="{'red_alert': filterFormErrors.workflow_id}"
               [(ngModel)]="data.workflow_id">
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="sds_nr"
               [ngClass]="{'red_alert': filterFormErrors.sds_nr}"
               [(ngModel)]="data.sds_nr">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="quanity"
               [ngClass]="{'red_alert': filterFormErrors.quanity}"
               [(ngModel)]="data.quanity">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="oa_nr"
               [ngClass]="{'red_alert': filterFormErrors.oa_nr}"
               [(ngModel)]="data.oa_nr">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="kd_art_nr"
               [ngClass]="{'red_alert': filterFormErrors.kd_art_nr}"
               [(ngModel)]="data.kd_art_nr">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_name"
               [ngClass]="{'red_alert': filterFormErrors.customer_name}"
               [(ngModel)]="data.customer_name">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_zip"
               [ngClass]="{'red_alert': filterFormErrors.customer_zip}"
               [(ngModel)]="data.customer_zip">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_city"
               [ngClass]="{'red_alert': filterFormErrors.customer_city}"
               [(ngModel)]="data.customer_city">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_country"
               [ngClass]="{'red_alert': filterFormErrors.customer_country}"
               [(ngModel)]="data.customer_country">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_field_service"
               [ngClass]="{'red_alert': filterFormErrors.customer_field_service}"
               [(ngModel)]="data.customer_field_service">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="username"
               [ngClass]="{'red_alert': filterFormErrors.username}"
               [(ngModel)]="data.username">
      </th>
      <th colspan="5">&nbsp;</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let object of list.objects; let index = index">
      <td>
        <ev-file-button [objId]="object.id" type="delivery">{{ object.id }}</ev-file-button>
        <img *ngIf="object.typ === 'cn'" src="/ui/assets/images/flags/cn.png">
        <img *ngIf="object.typ === 'de'" src="/ui/assets/images/flags/de.png">

        <span *ngIf="object.storno" class="label label-danger">Storniert</span>
        <span class="label label-default" *ngIf="object.delivery_invoice_lock && !object.storno">Gesperrt</span>
        <span class="label label-success"
              *ngIf="object.delivery_invoice_id && !object.storno && !object.delivery_invoice_lock">
          Abgerechnet
        </span>
        <span class="label label-warning"
              *ngIf="!object.delivery_invoice_id && !object.storno && !object.delivery_invoice_lock">
          Geliefert
        </span>
      </td>
      <td>
        <a uiSref="a.workflow.detail" [uiParams]="{id: object.workflow_id}">
          {{ object.workflow_id }}
          <span *ngIf="object.comments > 0" class="td-comments" title="Kommentar(e) vorhanden">
            <i class="fa fa-comments"></i>
          </span>
        </a>
      </td>
      <td>
        <ng-container *ngIf="object.status >= 100">
          <workflow-state type="workflow_status" [key]="object.status"></workflow-state>
        </ng-container>
      </td>
      <td>{{ object.date }}</td>
      <td>
        <strong>{{ object.sds_nr }}</strong>
      </td>
      <td>{{ object.quanity }}</td>
      <td>{{ object.oa_nr }}</td>
      <td>
        {{ object.kd_art_nr }}
        <span *ngIf="object.kd_art_index" class="label label-default">{{ object.kd_art_index }}</span>
      </td>
      <td>
        <span [ngbTooltip]="object.customer_name">
          {{ object.customer_name|limitTo: 30 }}
        </span>
      </td>
      <td>{{ object.customer_zip }}</td>
      <td>{{ object.customer_city }}</td>
      <td>{{ object.customer_country }}</td>
      <td>{{ object.customer_field_service }}</td>
      <td><a [href]="'mailto:' + object.email">{{ object.username }}</a></td>
      <td>&nbsp;</td>
      <td>
        <a class="btn btn-default btn-sm" uiSref="a.price.check" [uiParams]="{id: object.oa_nr}"
           *ngIf="'price.detail'|HasPerm">
          Prüfung
        </a>
      </td>
      <td>
        <ev-file-button [hawk]="true"
                        [hawkUrl]="documentDownload(object, 'coc')"
                        [link]="false"
                        [disabled]="!object.has_shipping_instructions">
          COC
        </ev-file-button>
      </td>
      <td>
        <ev-file-button [hawk]="true"
                        [hawkUrl]="documentDownload(object, 'konform')"
                        [link]="false"
                        [disabled]="!object.has_shipping_instructions">
          Konf
        </ev-file-button>
      </td>
      <td>
        <ev-file-button [hawk]="true"
                        [hawkUrl]="documentDownload(object, 'werk')"
                        [link]="false"
                        [disabled]="!object.has_shipping_instructions">
          Werk
        </ev-file-button>
      </td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
