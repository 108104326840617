import {Component, Input, OnInit} from '@angular/core';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PriceIncreaseFlexService} from '../../../price-increase-flex/price-increase-flex.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'workflow-price-increase-flex-update-modal',
  templateUrl: './workflow-price-increase-flex-update-modal.component.html',
})
export class WorkflowPriceIncreaseFlexUpdateModalComponent implements OnInit {
  @Input() flexPriceIncrease: PriceIncreaseFlex;

  form: UntypedFormGroup;
  errors: { [key: string]: any } = {};

  constructor(private fb: UntypedFormBuilder,
              private service: PriceIncreaseFlexService,
              private alertService: AlertService,
              private modalRef: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      increase: [this.flexPriceIncrease.increase.toLocaleString(), Validators.required],
    });
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

  submit(): void {
    this.errors = {};
    this.service.update(this.flexPriceIncrease.workflow_id, this.form.value).subscribe(frame => {
      this.alertService.add('success', 'Flexibler Zuschlag wurde erfolgreich gespeichert');
      this.modalRef.close(frame);
    }, (errorResponse: HttpErrorResponse) => {
      if (errorResponse.error) {
        this.errors = errorResponse.error?.errors;
      }
    });
  }

}
