import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {DeliveryService} from '../../workflow-services/delivery.service';

@Component({
  selector: 'delivery-storno-modal',
  templateUrl: './delivery-storno-modal.component.html'
})
export class DeliveryStornoModalComponent implements OnInit {
  @Input() id: number;
  @Input() wid: number;
  form: UntypedFormGroup;

  constructor(private modalRef: NgbActiveModal,
              private alertService: AlertService,
              private fb: UntypedFormBuilder,
              private deliveryService: DeliveryService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'reason': [null]
    });
  }

  closeModal(): void {
    this.modalRef.dismiss(null);
  }

  isDisabled(): boolean {
    return !this.form.getRawValue().reason;
  }

  save(): void {
    if (confirm('Möchten Sie den Lieferschein wirklich stornieren?')) {
       this.deliveryService.storno(this.id, this.wid, this.form.getRawValue().reason).subscribe(response => {
         this.modalRef.close(response);
       }, () => {
         this.alertService.add('danger', 'Beim Stornieren des Lieferscheins ist ein Fehler aufgetreten!');
       });
   }
  }

}
