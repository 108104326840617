import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Article, UpdateForm} from '../models/article.models';
import {AlertService} from '../../../common/alert-service/alert.service';
import {ArticleV2Service} from '../services/article2.service';
import {StateService} from '@uirouter/angular';
import {ArticleDuplicateModalComponent} from '../main/duplicate/article-duplicate-modal';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

/**
 * WARNING: this class should only be called from {ArticleUpdateService} and {ArticleMainSaveModalComponent}
 */
@Injectable({providedIn: 'root'})
export class ArticleSimpleUpdateService {

  constructor(private stateService: StateService,
              private articleV2Service: ArticleV2Service,
              private modalService: NgbModal,
              private alertService: AlertService) {
  }

  /** simple update call without sister lock modal */
  public simpleCall(article: Article, form: UpdateForm): Observable<Article> {
    return this.articleV2Service.update(article.oa_nr, form).pipe(
      tap(() => {
        this.alertService.add('success', 'Artikel erfolgreich gespeichert!');
        this.hasWorkflow();
      }, response => {
        this.errors(response);
      })
    );
  }


  /** general tap error */
  private errors(response: any): Observable<any> {
    // on error we either need to open the duplicates modal
    // or just return the errors so that they can be displayed inside the view
    if (response.error.objects) {
      this.alertService.add(
        'danger',
        '<b>Achtung</b>! Artikel konnte nicht gespeichert werden, ' +
        'da nicht alle notwendigen Felder ausgefüllt worden sind. ' +
        'Bitte prüfen Sie Ihre Eingabe.'
      );
    } else if (response.error.duplicates) {
      const modalRef = this.modalService.open(ArticleDuplicateModalComponent, {windowClass: 'modal2-smm'});
      modalRef.componentInstance.duplicates = response.error.duplicates;
      modalRef.result.then(() => {
      }, () => {
      });
    }
    return response;
  }

  /** redirects a article on save */
  private hasWorkflow(): void {
    const workflowId = this.stateService.params.workflow_id;

    if (workflowId) {
      this.stateService.go('a.workflow.detail', {id: workflowId});
    }
  }

}
