<div class="modal-header">

  <h3 class="modal-title">Aktivität {{ header }}</h3>
</div>

<form class="form-horizontal" [formGroup]="akquiseForm" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="row">
      <div class="col-md-4 form-group">
        <label class="col-sm-4 control-label" for="id_date">Datum:</label>

        <div class="col-sm-8">
          <ev-datepicker id="id_date" formControlName="date" [parser]="dateParser" class="form-control"
                      [evClass]="{red_alert: errors['obj.date']}">
          </ev-datepicker>
        </div>
      </div>

      <div class="col-md-4 form-group">
        <label class="col-sm-4 control-label" for="id_start">Anfang:</label>

        <div class="col-sm-2">
          <input class="form-control" id="id_start" name="start" style="width:83px;" type="text"
                 [ngClass]="{red_alert: errors['obj.start']}" formControlName="start">
        </div>
      </div>

      <div class="col-md-4 form-group">
        <label class="col-sm-4 control-label" for="id_end">Ende:</label>

        <div class="col-sm-2">
          <input class="form-control" id="id_end" name="start" style="width:83px;" type="text"
                 [ngClass]="{red_alert: errors['obj.end']}" formControlName="end">
        </div>
      </div>
      <div class="col-md-12">
        <textarea class="form-control" cols="40" id="id_text" name="text" rows="10"
                  style="width: 538px; height: 220px;" [ngClass]="{red_alert: errors['obj.text']}" formControlName="text">
        </textarea>
      </div>

    </div>

  </div>

  <div class="modal-footer">
    <div class="row">
      <div class="col-sm-9">&nbsp;</div>
      <div class="col-sm-1">

        <loading-button [clazz]="'btn btn-default'" name="Speichern" (loadingSave)="submit($event)">
        </loading-button>
      </div>
    </div>
  </div>

</form>
