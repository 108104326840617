import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {ArticleService} from '../../services/article.service';

@Component({
  selector: 'article-lock-modal',
  templateUrl: './article-lock.modal.component.html',
})
export class ArticleLockModalComponent implements OnInit {
  @Input() oa: string;
  @Input() sister: boolean;
  @Input() notices: string[];
  @Input() has_active_bas: boolean;
  @Input() has_active_orders: boolean;
  errors: { [key: string]: any; } = {};
  valid1 = false;
  valid2 = false;

  lockForm: UntypedFormGroup;

  constructor(private articleService: ArticleService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder,
              private modalRef: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.buildForm();
    // locking is allowed if valid is marked
    this.valid1 = !this.has_active_bas;
    this.valid2 = !this.has_active_orders;
  }

  submit(): void {
    this.errors = {};
    // FIXME: angularjs 1.x change to ArticleComponentService (circular dependency?!)
    this.articleService.lock(this.oa, this.lockForm.value).subscribe(() => {
      this.alertService.add('success', 'Artikel erfolgreich gesperrt!');
      this.modalRef.close(true);
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      console.log(response);
      this.alertService.add('danger', 'Fehler beim Sperren des Artikels!');
    });
  }

  close(): void {
    this.modalRef.dismiss();
  }

  makeValid1(value: boolean): void {
    this.valid1 = value;
  }

  makeValid2(value: boolean): void {
    this.valid2 = value;
  }

  private buildForm(): void {
    this.lockForm = this.fb.group({
      'reason': ['Ext. Änderung'],
      'reference': [''],
      'sister': [false],
    });
  }

}
