<ng-container [formGroup]="form">
  <td><input [id]="'selected_' + index" type="checkbox" formControlName="selected"></td>
  <td>{{article.variation_id}}</td>
  <td>{{article.vendor_article_id}}</td>
  <td>{{article.article_name}}</td>
  <td>{{article.article_name_order}}</td>
  <td>{{article.unit}}</td>
  <td [ngClass]="{'align-right': field.isValueType('Number')}" *ngFor="let field of fields">
    <ng-container [ngSwitch]="field.data_type" *ngIf="!(article.fields[field.html_name]|isNull)">
      <ng-container *ngSwitchCase="'Option'">
        <i class="fa" [ngClass]="{
          'fa-check':(article.fields[field.html_name] === true),
          'fa-times': (article.fields[field.html_name] === false)}"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'Ganzzahl'">
        {{ article.fields[field.html_name] | money:0 }}
      </ng-container>
      <ng-container *ngSwitchCase="'Kommazahl'">
        {{ article.fields[field.html_name] | money }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ article.fields[field.html_name] }}
      </ng-container>
    </ng-container>
  </td>
  <td class="align-right">{{article.amount|money}}</td>
  <td class="align-right">{{article.amount_ordered|money}}</td>
  <td class="align-right">{{article.min_stock|money}}</td>
  <td class="align-right">{{article.min_order|money}}</td>
  <td>
    <input class="form-control align-right"
           type="number"
           formControlName="amount"
           [id]="'amount_' + index"
           [min]="(parentForm.value.force_amount ? 0 : article.min_order)"
           [ngClass]="{red_alert: !!errors['orders[' + index + '].amount']}">
  </td>
  <td>
    <ev-datepicker class="form-control"
                   formControlName="date_delivery"
                   [id]="'date_delivery_' + index"
                   [coreFormat]="true"
                   [writeBack]="false"
                   [parser]="dateParser"
                   [allDates]="true"
                   [ngClass]="{red_alert: !!errors['orders[' + index + '].date_delivery']}">
    </ev-datepicker>
  </td>
  <td class="align-right">
    <input class="form-control align-right"
           type="text"
           formControlName="price"
           [id]="'price_' + index"
           [ngClass]="{red_alert: !!errors['orders[' + index + '].price']}">
  </td>
  <td>
    <input class="form-control"
           type="text"
           formControlName="note"
           [id]="'note_' + index"
           [ngClass]="{red_alert: !!errors['orders[' + index + '].note']}">
  </td>
  <td class="icon">
    <a class="click"
       title="Position kopieren"
       (click)="copy()">
      <i class="fa fa-plus green row-icon" aria-hidden="true"></i>
    </a>
  </td>
  <td class="icon">
    <a class="click"
       title="Position löschen"
       (click)="remove()">
      <i class="fa fa-trash-o red_font row-icon" aria-hidden="true"></i>
    </a>
  </td>
</ng-container>
