import {Component, Input} from '@angular/core';
import {Article} from '../../models/article.models';

@Component({
  selector: 'article-locked-info',
  templateUrl: './article-locked-info.component.html'
})
export class ArticleLockedInfoComponent {
  @Input() article?: Article;
}
