import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ShippingInstructionHistory, ShippingInstructionType} from '../models/article-extra.models';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ShippingInstructionHistoryService {

  constructor(private http: HttpClient) {
  }

  list(type: ShippingInstructionType, item: string): Observable<ShippingInstructionHistory[]> {
    return this.http.get<ShippingInstructionHistory[]>(
      environment.apiv4uri + 'shipping-instruction/history/' + type + '/' + item
    );
  }
}
