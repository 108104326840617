<div [ngClass]="{whitebox: whitebox}" *ngIf="article">
  <h3>Artikel</h3>
  <table class="table whitetable">
    <tbody>
    <tr>
      <td><strong>SDS-Nr.:</strong></td>
      <td class="align-right"><strong>{{article.sds_nr}}</strong></td>
    </tr>
    <tr>
      <td>0A-Nr.:</td>
      <td class="align-right">{{article.oa_nr}}</td>
    </tr>
    <tr>
      <td>Kunden-Art-Nr.:</td>
      <td class="align-right">{{article.data.kd_art_nr}}</td>
    </tr>
    </tbody>
  </table>
  <a uiSref="a.article.workflow.overview"
     [uiParams]="{id: article.oa_nr, workflow_id: workflowId}"
     [target]="targetWindow">
    Artikelansicht
  </a>
</div>
