import {Component, ErrorHandler} from '@angular/core';
import {CustomerList} from '../../customer/model/customer';
import {WorkflowService} from '../workflow-services/workflow.service';
import {StateService} from '@uirouter/angular';
import {WorkflowSimpleArticleForm, WorkflowWizardForm} from '../models/workflow.models';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {OfferService} from '../workflow-services/offer.service';
import {CustomerService} from '../../customer/services/customer.service';
import {ContactService} from '../../customer/services/contact.service';
import {ArticleEither} from './workflow-main.models';
import {map, mergeMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {CreateTaskModel} from '../create-task/create-task.models';
import {Contact} from '../../customer/model/contact';
import {Lister} from '../../../common/wrapper.models';

export interface WorkflowCreateCustomerData {
  customerList: Lister<CustomerList>;
  search: string | null | undefined;
  customer: CustomerList | null;
  contactList: Contact[];
}

@Component({
  selector: 'workflow-create',
  templateUrl: './workflow-create.component.html',
})
export class WorkflowCreateComponent {
  state = 0;
  error = false;
  articleEither: WorkflowSimpleArticleForm | null = null;
  customerData: WorkflowCreateCustomerData | null = null;
  offerData: CreateTaskModel;

  constructor(private workflowService: WorkflowService,
              private offerService: OfferService,
              private customerService: CustomerService,
              private contactService: ContactService,
              private stateService: StateService,
              private errorHandler: ErrorHandler) {
  }

  showWorkflow(offer: number): void {
    this.reset();
    this.offerService.workflow(offer).pipe(
      mergeMap(val => {
        const obs = {
          workflow: of(val.object),
          customer: this.customerService.detail(val.object.customer_id),
          contact: val.object.contact_id ? this.contactService.detail(val.object.contact_id) : of(null),
          article: of(val.article),
          comments: of(val.comments),
          positions: of(val.positions),
          lastOffer: this.offerService.workflowList(val.object.id).pipe(map(response => {
            return response.sort((a, b) => a.id - b.id)[response.length - 1];
          })),
        };

        return forkJoin(obs);
      }),
    ).subscribe(responses => {
      this.offerData = responses;
    }, () => {
      // raised, when an offer is already created
      this.error = true;
    });
  }

  next(data: ArticleEither): void {
    if (data.customer) {
      this.customerService.list({name: data.customer}).pipe(
        mergeMap(val => {
          const firstCustomer = val.objects.length > 0 ? val.objects[0] : null;
          const responses = {
            customerList: of(val),
            search: of(data.customer),
            customer: of(firstCustomer),
            contactList: firstCustomer ? this.contactService.list(firstCustomer.id) : of([]),
          };
          return forkJoin(responses);
        }),
      ).subscribe(val => {
        this.customerData = val;
        this.articleEither = data.article;
        this.state = 1;
      }, ignoreRejection);
    } else {
      this.articleEither = data.article;
      this.state = 1;
    }
  }

  finish(values: { typ: 0 | 1, customerId: number, contactId?: number }): void {
    const data: WorkflowWizardForm = {
      typ: values.typ,
      customer: values.customerId,
      contact: values.contactId,
      article: this.articleEither,
    };
    this.workflowService.create(data).subscribe(workflow => {
      this.stateService.go('a.workflow.detail', {id: workflow.id});
    }, ignoreRejection);
  }

  reset(): void {
    this.error = false;
  }

}
