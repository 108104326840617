/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
export interface EnvisiaObject<T> {
  object: T;
}

export interface EnvisiaObjects<T> {
  objects: T[];
}

export interface Lister<T> {
  objects: T[];
  count: number;
  page: number;
  size: number;
  pages: number;
  badges?: any;
}

export function isObject<T>(arg: EnvisiaObject<T> | EnvisiaObjects<T>): arg is EnvisiaObject<T> {
  return 'object' in (arg as any);
}

export function isObjects<T>(arg: EnvisiaObject<T> | EnvisiaObjects<T>): arg is EnvisiaObjects<T> {
  return 'objects' in (arg as any);
}
