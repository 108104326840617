import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Lister} from '../../common/wrapper.models';
import {ListService} from '../../common/search/search.service';
import {Comment} from '../comment/comment.models';
import {EnvisiaLocation} from '../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class Commentv2Service implements ListService<Comment> {

  private uri = environment.apiv4uri + 'comment/list';

  constructor(private http: HttpClient) {
  }

  list(form: { typ: string, item: string }): Observable<Lister<Comment>> {
    return this.http.get<Lister<Comment>>(this.uri, {params: EnvisiaLocation.httpParams(form)});
  }

}
