import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MaterialGroupListElement} from '../../material-management.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'material-order-create-group-select',
  templateUrl: './material-order-create-group-select.component.html'
})
export class MaterialOrderCreateGroupSelectComponent implements OnInit, OnChanges {

  @Input() groups: MaterialGroupListElement[];
  @Input() disabled: boolean;
  @Output() addGroup = new EventEmitter<MaterialGroupListElement>();

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({group: [{value: null, disabled: this.disabled}]});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && !changes.disabled.isFirstChange()) {
      if (this.disabled) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  addGroupManually(): void {
    const group = this.form.value.group;
    this.form.reset();
    this.groups = this.groups.filter(g => g.id !== group.id);
    this.addGroup.emit(group);
  }
}
