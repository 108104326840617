import {AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import Helper from '../../../../../helper/helper';

function sanitizeString(value: string, withStrings: boolean): string {
  if (!withStrings) {
    return value;
  }

  return value.replace('.', '').replace(',', '');
}

export function createNonRequiredNumberValidator(withStrings: boolean = true, empty: boolean = true): ValidatorFn {
  const regex = /^\d+$/;
  return (control: AbstractControl) => {
    if (empty && Helper.undefined(control.value)) {
      return null;
    }

    const isNumber = Helper.isInt(Helper.toInt(control.value));

    let digitOnly = false;
    if (typeof control.value === 'string') {
      digitOnly = regex.test(sanitizeString(control.value, withStrings));
    } else if (typeof control.value === 'number') {
      digitOnly = true;
    }

    return isNumber && digitOnly ? null : {'number': 'invalid'};
  };
}

export function createNumberValidator(withStrings: boolean = true): ValidatorFn[] {
  return [createNonRequiredNumberValidator(withStrings, false), Validators.required];
}

export function buildInnerGroup(fb: UntypedFormBuilder): UntypedFormGroup {
  return fb.group({
    'quantity': [null, createNumberValidator()],
    'lose': [null, createNumberValidator()],
  });
}
