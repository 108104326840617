<div class="modal-header">
  <h2>Achtung</h2>
</div>

<div class="modal-body red_alert">
  Produktionszeitraum zu gering. Bitte prüfen Sie die Felder Start-Termin, End-Termin, Vergoldung bis manuell.
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-danger pull-right" (click)="close()">
    Bestätigen
  </button>
</div>
