<div class="page-header">
  <h3>Material Artikel{{currentGroup ? ' in Gruppe ' + currentGroup.name : ''}}</h3>
</div>
<div>
  <button class="btn btn-success"
          uiSref="a.material.article.create"
          [uiParams]="currentGroup ? {group: getGroup()} : {}">
    Neuer&nbsp;Artikel
  </button>
</div>
<div class="row">
  <div class="col-md-8">
    <table class="table whitetable chrissies">
      <thead>
      <tr>
        <th>Artikel&nbsp;Nr.</th>
        <th *ngIf="isGroupFiltered()">Gruppe</th>
        <th>Bezeichnung</th>
        <th>Bestell Bezeichnung</th>
        <th>Lieferant</th>
        <th class="icon"></th>
        <th class="icon"></th>
      </tr>
      </thead>

      <tbody infiniteScroll [infiniteScrollDisabled]="locked">
      <tr *ngFor="let article of articles">
        <td>
          <a uiSref="a.material.article.update" [uiParams]="{article: article.id, group: article.group_id}">
            {{ article.article_id }}
          </a>
        </td>
        <td *ngIf="isGroupFiltered()">
          {{ article.group }}
        </td>
        <td>
          {{ article.name }}
        </td>
        <td>
          {{ article.name_order }}
        </td>
        <td>
          {{ article.supplier_name }}
        </td>
        <td class="icon">
          <a uiSref="a.material.article.update" [uiParams]="{article: article.id, group: article.group_id}">
            <i class="fa fa-pencil"></i>
          </a>
        </td>
        <td class="icon">
          <i class="fa fa-trash-o red_alert click" (click)="delete(article)"></i>
        </td>
      </tr>
      </tbody>
    </table>

    <div>
      <div *ngIf="busy"><i class="fa fa-spin fa-spinner"></i> neue Daten laden</div>
    </div>

  </div>

</div>
