<div class="form-inline" [formGroup]="dateRangeForm">
  <span *ngIf="label !== undefined && label !== null" class="range-extra-label">{{label}}</span>
  <i class="fa" [ngClass]="icon"></i>

  <div class="form-group" [ngClass]="cssClass">
    <label class="sr-only" for="date_start">Start</label>
    <ev-datepicker id="date_start" formControlName="start" class="form-control datepicker-width-80"
                [allDates]="true"
                [placeholder]="'Start'">
    </ev-datepicker>
  </div>
  -
  <i class="fa fa-calendar"></i>

  <div class="form-group" [ngClass]="cssClass">
    <label class="sr-only" for="date_end">Ende</label>
    <ev-datepicker id="date_end" formControlName="end" class="form-control datepicker-width-80"
                [allDates]="true"
                [placeholder]="'Ende'">
    </ev-datepicker>
  </div>

  <button type="button" class="btn btn-default btn-sm" (click)="trash()">
    <i class="fa fa-trash-o"></i>
  </button>
</div>
