<div class="modal-header">
  <h3 class="modal-title">Adresse ändern</h3>
</div>

<div class="modal-body">
  <div class="whitelist contactlist">
    <div class="whitelist-item click" *ngFor="let value of addresses" (click)="select(value)"
         [ngClass]="{selected: value.id === selected}">
      <h4>{{ value.name }}</h4>
      <p *ngIf="value.company_name">{{ value.company_name }} </p>
      <p *ngIf="value.company_department">{{ value.company_department }}</p>
      <p>{{ value.street }}</p>
      <p>{{ value.zip }} {{ value.city }}</p>
      <p>{{ value.country }}</p>
    </div>
  </div>
</div>

<div class="modal-footer">
</div>
