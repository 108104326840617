<ng-container *ngIf="shippingInstructions.data$|async as si else loadingOrError.template">
  <ng-container *ngIf="si?.over_under_delivery?.over_delivery !== null && si.over_under_delivery.over_delivery.length > 0">
    <div class="whitebox">
      <h3>Lieferbedingungen</h3>
      <delivery-condition-ou-table tableName="Überlieferung"
                                   [deliveryConditionList]="si.over_under_delivery.over_delivery">
      </delivery-condition-ou-table>
    </div>
  </ng-container>
</ng-container>
<loading-or-error
  #loadingOrError
  [loadingWrapper]="shippingInstructions"
  [errorMessage]="'Konnte Lieferbedingungen nicht laden'"
  [spinnerSize]="2"
  spinnerName="circle-o-notch">
</loading-or-error>
