import {Component, Directive, EventEmitter, Input, Output} from '@angular/core';
import {
  MaterialOrderIntakeListElement,
  MaterialOrderPosition,
} from '../../material-order.model';
import {FormGroup} from '@angular/forms';
import {MaterialOrderIntakeBookedPositionForm} from './material-order-intake-booked-form.model';
import {MaterialValueType} from '../../../material-management.model';
import {MaterialOrderIntakeService} from '../material-order-intake.service';
import {MaterialOrderPositionService} from '../../material-order-position.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {MaterialOrderIntakePrintModalService} from '../intake-print-modal/material-order-intake-print-modal.service';
import {MaterialOrderIntakePdfRow} from '../material-order-intake-pdf-row';

@Directive()
export abstract class MaterialOrderIntakeBookedRowAbstractComponent extends MaterialOrderIntakePdfRow {
  position?: MaterialOrderIntakeListElement;
  formPosition: FormGroup<MaterialOrderIntakeBookedPositionForm>;
  updatePositionEmitter = new EventEmitter<MaterialOrderPosition>();

  protected constructor(protected positionService: MaterialOrderPositionService) {
    super();
  }

  setStatus(status: number): void {
    this.positionService.updateState(this.position.position_id, status).subscribe(position => {
      this.position.status = position.status;
      this.updatePositionEmitter.emit(position);
    });
  }

  get setStatusTooltip(): string {
    return `Position auf Status \"${(this.formPosition.value.mark_finished ? 'Gebucht' : 'Geprüft')}\" setzen`;
  }

  get positionPriceConfirmed(): {[key: string]: boolean} {
    return this.formPosition.value.mark_finished ? {'fa-undo': true} : {'fa-check': true, 'green': true};
  }

  confirmPrice(): void {
    this.formPosition.patchValue({mark_finished: !this.formPosition.value.mark_finished});
  }
}
