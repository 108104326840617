import {Directive, Input} from '@angular/core';
import {
  ArticleMultilayerPlanHolderCompareHelper
} from '../../../article/main/multilayer-plan/article-multilayer-plan-holder-compare-helper';
import {Article, ArticleCore} from '../../../article/models/article.models';
import {ArticleMultilayerPlan} from '../../../article/main/multilayer-plan/article-multilayer-plan.models';

@Directive()
export abstract class BusinessOrderCreateDirective {
  @Input() multilayerPlan?: ArticleMultilayerPlan;
  @Input() article: Article | ArticleCore;

  invalidML = false;
  errorMl?: string = null;

  initMultilayerPlanCheck(): void {
    if (this.article.data.structure_sds_development_plan !== true) {
      this.invalidML = false;
      this.errorMl = null;
      return;
    }

    if (!this.multilayerPlan) {
      this.invalidML = true;
      this.errorMl = 'ml.missing';
      return;
    }

    const diff = ArticleMultilayerPlanHolderCompareHelper.diff(this.article as Article, this.multilayerPlan.data);
    this.invalidML = diff.differs;
    this.errorMl = diff.differs ? 'ml.differs' : null;
  }
}
