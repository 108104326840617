<div class="modal-header">
  <h3 class="modal-title">Ansprechpartner</h3>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="save()">

  <div class="modal-body" *ngIf="!change">
    <div class="row border-bottom">
      <div class="col-sm-9">
        <div class="row">
          <div class="col-sm-12">
            {{ contact.title }}
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <h4>{{ contact.first_name }}&nbsp;{{ contact.last_name }}</h4>
          </div>
        </div>

        <div class="row"><div class="col-sm-12">&nbsp;</div></div>

        <div class="row" *ngIf="contact.departments.length > 0">
          <div class="col-sm-2">Abteilung:</div>
          <div class="col-sm-10">{{ contact.departments|JoinStringPipe }}</div>
        </div>

        <div class="row" *ngIf="contact.position">
          <div class="col-sm-2">Position:</div>
          <div class="col-sm-10">{{ contact.position }}</div>
        </div>
      </div>
      <div class="col-sm-3">
        <img style="height: 100px;" src="/ui/assets/images/contact_opancy.png">
      </div>
    </div>

    <div class="row border-bottom">
      <div class="col-sm-9">
        <h4>Kontakt</h4>

        <div class="row" *ngIf="contact.phone">
          <div class="col-sm-2">Telefon:</div>
          <div class="col-sm-10">{{ contact.phone }}</div>
        </div>

        <div class="row" *ngIf="contact.fax">
          <div class="col-sm-2">Fax:</div>
          <div class="col-sm-10">{{ contact.fax }}</div>
        </div>

        <div class="row" *ngIf="contact.mobile">
          <div class="col-sm-2">Mobil:</div>
          <div class="col-sm-10">{{ contact.mobile }}</div>
        </div>

        <div class="row" *ngIf="contact.mail">
          <div class="col-sm-2">E-Mail:</div>
          <div class="col-sm-10">{{ contact.mail }}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
    </div>

    <div class="row" *ngIf="contact.note">
      <div class="col-sm-9">
        <h4>Hinweis</h4>
        <strong>{{contact.note}}</strong>
      </div>
    </div>
  </div>


  <div class="modal-body" *ngIf="change">
    <customer-contact-form [form]="form" [departments]="departments"></customer-contact-form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default float-left" *ngIf="!change" (click)="dismiss()">Abbrechen</button>
    <button type="button" class="btn btn-default float-left" *ngIf="change" (click)="toggle()">Abbrechen</button>

    <div class="btn-group float-right">
      <button class="btn btn-danger" type="button" (click)="deleteContact()" *ngIf="('crm.contact.delete'|HasPerm)">
        <span class="fa fa-trash-o"></span>
      </button>

      <button type="button" class="btn btn-default" *ngIf="!change" (click)="toggle()">
        Ansprechpartner bearbeiten
      </button>
      <button type="submit" class="btn btn-default" *ngIf="change">
        Ansprechpartner speichern
      </button>
    </div>
  </div>
</form>
