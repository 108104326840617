import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef} from '@angular/core';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {MaterialSupplier} from '../../../lazy/material-supplier/material-supplier.model';
import {MaterialSupplierService} from '../../../lazy/material-supplier/material-supplier.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from} from 'rxjs';
import {MaterialSupplierSelectComponent} from './material-supplier-select.component';

export const MATERIAL_VENDOR_SELECTOR_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MaterialSupplierSelectorComponent),
  multi: true,
};

@Component({
  selector: 'material-supplier-selector',
  templateUrl: './material-supplier-selector.component.html',
  providers: [
    MATERIAL_VENDOR_SELECTOR_VALUE_ACCESSOR
  ],
  styleUrls: ['./material-supplier-selector.component.scss']
})
export class MaterialSupplierSelectorComponent implements ControlValueAccessor {
  data: LoadingWrapper<MaterialSupplier> | null = null;
  disabled = false;
  supplierId: number | null;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  constructor(private mss: MaterialSupplierService,
              private modalService: NgbModal) {
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  writeValue(obj: any): void {
    if (obj === this.supplierId || obj === null) {
      return;
    }
    this.data = new LoadingWrapper<MaterialSupplier>(this.mss.detail(obj));
    this.supplierId = obj;
    this._onChange(obj);
  }

  modal(supplier?: MaterialSupplier): void {
    const modalRef = this.modalService.open(MaterialSupplierSelectComponent);
    modalRef.componentInstance.selected = supplier;
    from(modalRef.result).subscribe(selectedSupplier => {
      this.writeValue(selectedSupplier.id);
    }, _ => {
    });
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
