import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Contact} from '../model/contact';
import {ContactBoxModalComponent} from './contact-box-modal.component';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../helper/ignore_rejection';

export const EV_CONTACT_BOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ContactBoxComponent),
  multi: true,
};

@Component({
  selector: 'contact-box',
  templateUrl: './contact-box.component.html',
  styleUrls: ['./contact-box.component.scss'],
  providers: [EV_CONTACT_BOX_VALUE_ACCESSOR]
})
export class ContactBoxComponent implements ControlValueAccessor {
  @Input() contact?: Contact | null;
  @Input() customerId: number;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  constructor(private modalService: NgbModal) {
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (obj === null || obj === undefined) {
      this.contact = null;
    } else {
      this.contact = obj;
    }
  }

  switchContact(): void {
    const modalRef = this.modalService.open(ContactBoxModalComponent, {windowClass: 'modal2-slg'});
    modalRef.componentInstance.currentContact = this.contact;
    modalRef.componentInstance.customerId = this.customerId;
    from(modalRef.result).subscribe(value => {
      this.contact = value;
      this._onChange(value);
    }, ignoreRejection);
  }
}
