import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MaterialValueType} from '../material-management.model';

@Component({
  selector: 'material-field-value',
  templateUrl: './material-field-value.component.html',
})
export class MaterialFieldValueComponent implements OnInit, OnChanges {
  @Input() field: MaterialValueType;
  @Input() fields: {id: number, value: any}[];

  active?: {id: number, value: any};
  constructor() { }

  ngOnInit(): void {
    this.active = this.fields.find(v => v.id === this.field.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field && changes.fields) {
      this.active = this.fields.find(v => v.id === this.field.id);
    }
  }

  get boolValueClass(): any {
    return {
      'fa-check': this.active?.value === true,
      'fa-times': this.active?.value === false,
    };
  }
}
