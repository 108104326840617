import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {WorkflowService} from '../workflow-services/workflow.service';
import {TaskListData} from '../models/workflow.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Lister} from '../../../common/wrapper.models';
import {MailModalService} from '../../mail/mail-modal.service';

@Component({
  selector: 'workflow-list',
  templateUrl: './workflow-list.component.html'
})
export class WorkflowListComponent extends SearchBaseComponent<WorkflowService, TaskListData> {
  @Input() listData: Lister<TaskListData>;
  protected type = 'contract';

  constructor(protected service: WorkflowService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              protected mailModalService: MailModalService) {
    super();
  }

}
