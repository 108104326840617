import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Article, SmallArticle} from '../../../models/article.models';
import {ArticleMultilayerPlan} from '../article-multilayer-plan.models';
import {ArticleMultilayerPlanHolderCompareHelper} from '../article-multilayer-plan-holder-compare-helper';

@Component({
  selector: 'article-multilayer-difference-warning',
  templateUrl: './article-multilayer-difference-warning.component.html',
})
export class ArticleMultilayerDifferenceWarningComponent implements OnInit, OnChanges {
  @Input() article: Article | SmallArticle;
  @Input() multilayerPlan?: ArticleMultilayerPlan;

  visible: boolean;
  reason?: string = null;
  constructor() {
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).filter(c => !changes[c].isFirstChange()).length > 0) {
      this.update();
    }
  }

  update() {
    if (this.article.data.structure_sds_development_plan !== true) {
      this.visible = false;
      this.reason = '';
      return;
    }

    if (!this.multilayerPlan) {
      this.visible = true;
      this.reason = 'Multilayeraufbauplan ist nicht vorhanden';
      return;
    }

    const diff = ArticleMultilayerPlanHolderCompareHelper.diff(this.article as Article, this.multilayerPlan.data);
    this.visible = diff.differs;
    this.reason = diff.differs ? 'Daten weichen vom Artikel ab' : '';
  }
}
