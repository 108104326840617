import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription, Subject, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, mergeMap, delay} from 'rxjs/operators';

export const CUSTOMER_OPOS_INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomerOposInputComponent),
  multi: true,
};


@Component({
  selector: 'customer-opos-input',
  template: `<input type="text"
                    [ngClass]="{red_alert: !!error}"
                    [ngModel]="data"
                    (ngModelChange)="modelChanged($event)"
                    [oposFocus]="focus"
                    (keyup)="keyupEvent($event)"
                    inputSelect>`,
  providers: [
    CUSTOMER_OPOS_INPUT_VALUE_ACCESSOR
  ]
})
export class CustomerOposInputComponent implements ControlValueAccessor, OnDestroy {
  @Input() error: any | undefined | null;
  @Input() focus: boolean | number;
  @Output() keyUpEmitter = new EventEmitter<Event>();
  @Output() lazyEmitter = new EventEmitter<any>();
  data?: any;
  initd = false;
  subscription?: Subscription;
  private lazySubject = new Subject<string>();
  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  writeValue(obj: any): void {
    // clean all events when writing a value back
    this.ngOnDestroy();
    this.init();
    this.data = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  modelChanged(value: string): void {
    this._onChange(value);
    if (!this.initd) {
      this.init();
    }
    this.lazySubject.next(value);
  }

  keyupEvent(event: Event): void {
    this.keyUpEmitter.next(event);
  }

  private init(): void {
    this.initd = true;
    this.subscription = this.lazySubject.pipe(
      debounceTime(850),
      distinctUntilChanged(),
      mergeMap(value => of(value).pipe(delay(500)))
    ).subscribe(value => {
      this.lazyEmitter.next(value);
    });
  }

}
