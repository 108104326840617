<div class="row">
  <div class="col-md-12">
    <workflow-price-increase-flex-box [workflowId]="workflow.object.id"
                                      [priceIncreaseFlex]="priceIncreaseFlex"
                                      (update)="updatePriceIncreaseFlex.emit($event)">
    </workflow-price-increase-flex-box>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <h3>Auftragsrahmen</h3>

    <div class="whitebox ordersituation whitebox-margin"
         [ngClass]="{'alert alert-danger': !workflow?.frame?.approval}" *ngIf="workflow?.frame">

      <div *ngIf="lastOffer">Aus Angebot:
        <ev-file-button [objId]="lastOffer.id" type="offer">
          {{ lastOffer.id }}
        </ev-file-button>
      </div>
      <div>Menge: {{ workflow.frame?.quanity }}</div>
      <div>Kalk-Menge: {{ workflow.frame?.calc_quanity }}</div>
      <div>Lose: {{ workflow.frame?.lose }}</div>
      <div>Freigabe: {{workflow.frame?.release }}</div>
      <div>Einzel: {{ workflow.frame?.single }} €</div>
      <div>Kalk: {{ workflow.frame?.calc }}</div>
      <div *ngIf="!workflow.frame?.approval">
        <a uiSref="a.price.check" [uiParams]="{id: article.oa_nr, workflow_id: workflow.object.id, create: true}">
          <span class="label label-danger">Preisprüfung</span>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <table class="table whitetable">
      <thead>
      <tr>
        <th>Auftr. Best.</th>
        <th>Datum</th>
        <th>Ersteller</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let contract of contractList">
        <td>
          <ev-file-button [objId]="contract.id" type="contract">
            {{ contract.id }}
            <span class="label label-purple" *ngIf="contract.date_sent">Versendet</span>
          </ev-file-button>
        </td>
        <td>
          <ev-file-button [objId]="contract.id" type="contract">
            {{ contract.contract_date }}
          </ev-file-button>
        </td>
        <td>
          <ev-file-button [objId]="contract.id" type="contract">
            {{ contract.username }}
          </ev-file-button>
        </td>
        <td>
          <button type="button" class="btn btn-link btn-sm" (click)="historyContract(contract)">
            <i class="fa fa-clock-o"></i>
          </button>
        </td>
        <td>
          <button type="button" class="btn btn-link btn-sm" (click)="mail(contract)">
            <i class="fa fa-envelope"></i>
          </button>
        </td>
      </tr>
      </tbody>

    </table>
  </div>
</div>
