import {Component, Input} from '@angular/core';
import {Article} from '../../models/article.models';

@Component({
  selector: 'article-sister-modal',
  templateUrl: './article-sister-modal.component.html',
})
export class ArticleSisterModalComponent {

  @Input() article: Article;

}
