/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Transition, Ng2StateDeclaration} from '@uirouter/angular';
import {CustomerNavigationComponent} from '../navigation/customer-navigation.component';
import {CustomerArticleExtraComponent} from './customer-article-extra.component';
import {ShippingInstructionService} from '../../article/services/shipping-instruction.service';
import {customerDetailResolveFn} from '../customer.resolve';
import {CustomerService} from '../services/customer.service';

export function customerAdditionalResolveFn(service: ShippingInstructionService, transition: Transition) {
  return service.get('customer', transition.params().id).toPromise();
}

export function shippingInstructionSchemaFn(service: ShippingInstructionService) {
  return service.schema().toPromise();
}

export const CUSTOMER_ARTICLE_EXTRA_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer.article_extra',
  url: '/crm/{id:[0-9]+}/article',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: CustomerArticleExtraComponent}
  },
  resolve: [
    {
      token: 'customer',
      resolveFn: customerDetailResolveFn,
      deps: [CustomerService, Transition],
    },
    {
      token: 'data',
      resolveFn: customerAdditionalResolveFn,
      deps: [ShippingInstructionService, Transition]
    },
    {
      token: 'schema',
      resolveFn: shippingInstructionSchemaFn,
      deps: [ShippingInstructionService, Transition]
    }
  ],
  data: {requiresAuth: true}
};
