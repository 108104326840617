<div id="header">
  <div class="buttonbar">
    <div id="logo-header">
      <div id="logo-dashboard">
        <a uiSref="home" class="click">
          <img src="/ui/assets/images/envisiaERP.png">
        </a>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <main-dropdown></main-dropdown>
  <div class="base-dashboard-spacer">
    <ui-view></ui-view>
  </div>
</div>

<main-version></main-version>
