import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'search-type',
  template: `<button class="btn btn-default btn-flag" type="button" [ngClass]="{active: isType()}"
                     (click)="select()"><img [src]="image"></button>`
})
export class SearchTypeComponent implements OnInit {
  @Input() typ: string;
  @Input() current: string;
  @Output() update = new EventEmitter<string | null>();
  image: string;

  ngOnInit(): void {
    this.image = '/ui/assets/images/flags/' + this.typ + '.png';
  }

  select(): void {
    if (this.current === this.typ) {
      this.update.next(null);
    } else {
      this.update.next(this.typ);
    }
  }

  isType(): boolean {
    if (this.typ === 'de') {
      return this.current === 'de';
    } else if (this.typ === 'cn') {
      return this.current === 'cn';
    } else {
      return false;
    }
  }

}
