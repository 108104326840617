<div class="buttonbar-view">

  <article-button-navigation [article]="article"
                             [options]="options"
                             [locked]="saveLock"
                             [validationErrors]="validationErrors"
                             (saveEmitter)="save()"
                             (deleteEmitter)="deleted()"
                             (recoverEmitter)="recovered($event)"
                             (generateSdsEmitter)="generatedSds($event)"
                             (generateSisterEmitter)="generatedSister($event)"
                             (lockChangeEmitter)="lockChange($event)">
  </article-button-navigation>

</div>

<article-overview-template [article]="article"
                           [specification]="specification"
                           [form]="form"
                           [refCount]="refCount"
                           [errors]="errors"
                           [multilayerPlan]="multilayerPlan"
                           (setAutomatedChange)="setAutomatedChange($event)"
                           (customerChange)="customerChange($event)"
                           (calculated)="calculated($event)"
                           (aspectCalculated)="aspectCalculated()"
                           (updateState)="updateState($event)">
</article-overview-template>
