/*
 * Copyright (C) 2019 envisia GmbH
 * All Rights Reserved.
 */
import {Component, ViewChild, TemplateRef, Input, OnInit} from '@angular/core';
import {NgIfContext} from '@angular/common';
import {LoadingWrapper} from './loading-wrapper';

@Component({
  selector: 'loading-or-error',
  template: `
    <ng-template #template>
      <div *ngIf="loadingWrapper.errorLoading$ | async; else loading">
        {{ errorMessage }}
      </div>
      <ng-template #loading>
        <ng-container *ngIf="!row">
          <i class="fa fa-spin" [ngClass]="spinnerCss"></i> {{ loadingText }}
        </ng-container>
        <div class="row" *ngIf="row">
          <div class="col-md-12">
            <i class="fa fa-spin" [ngClass]="spinnerCss"></i> {{ loadingText }}
          </div>
        </div>
      </ng-template>
    </ng-template>
  `
})
export class LoadingOrErrorComponent implements OnInit {
  /**
   * The template that should get created when we are in a loading or error state.
   * Use it in the else condition of *ngIf.
   */
  @ViewChild('template', {static: true}) template: TemplateRef<NgIfContext> | null = null;
  /**
   * The loading wrapper that should be used to show the loading/error state
   */
  @Input() loadingWrapper: LoadingWrapper<any> | null = null;
  /**
   * A configurable error message for error cases.
   */
  @Input() errorMessage = 'Es ist ein Fehler aufgetreten!';
  @Input() loadingText = '';
  @Input() spinnerSize = 2;
  @Input() spinnerName = 'circle-o-notch';
  spinnerCss: string;
  @Input() row = false;

  ngOnInit(): void {
    this.spinnerCss = `fa-${this.spinnerSize}x fa-${this.spinnerName}`;
  }
}
