<ng-container [ngSwitch]="deliveryMethod">
  <i class="fa fa-plane ev-cn-order-blue-color"
     [title]="deliveryMethod"
     [ngClass]="clazz"
     *ngSwitchCase="'Flugzeug'">
  </i>
  <i class="fa fa-train"
     [title]="deliveryMethod"
     [ngClass]="clazz"
     *ngSwitchCase="'Zug'">
  </i>
  <i class="fa fa-ship"
     [title]="deliveryMethod"
     [ngClass]="clazz"
     *ngSwitchCase="'Schiff'">
  </i>
  <i class="fa fa-truck ev-cn-order-dhl-color"
     [title]="deliveryMethod"
     [ngClass]="clazz"
     *ngSwitchCase="'DHL Express'">
  </i>
</ng-container>
