import {Component, ErrorHandler, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchBaseComponent} from '../../common/search/search.component';
import {AkquiseService} from './akquise.service';
import {Akquise} from './akquise.models';
import {Lister} from '../../common/wrapper.models';
import {EnvisiaLocation} from '../../common/location/envisia-location';
import {Customer} from '../customer/model/customer';
import {DateHelper} from '../../helper/date-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AkquiseSaveComponent} from './akquise-save.component';
import {Subscription} from 'rxjs';
import {EnvisiaEventService} from '../../core/event.service';
import {DateRange} from '../../core/date-range/date-range.models';
import {StateService} from '@uirouter/angular';
import {InitData} from '../../common/init-data/init-data';
import {LocalStorage} from '../../common/storage/local-storage';

@Component({
  selector: 'akquise-list',
  templateUrl: './akquise-list.component.html',
})
export class AkquiseListComponent
  extends SearchBaseComponent<AkquiseService, Akquise>
  implements OnInit, OnDestroy {
  @Input() customer?: Customer;
  @Input() listData: Lister<Akquise>;
  @Input() users: string[];
  start?: string | Date = null;
  end?: string | Date = null;
  private loggedInUser?: string;
  protected type = 'akquise';
  protected debounceTime = 100; // override deboucne time for akquise

  private akquiseSubscription: Subscription;

  constructor(protected service: AkquiseService,
              protected errorHandler: ErrorHandler,
              private stateService: StateService,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private modalService: NgbModal,
              private eventService: EnvisiaEventService,
              private localStorage: LocalStorage) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.start = this.data.start;
    this.end = this.data.end;
    this.loggedInUser = this.localStorage.getObject<InitData>('data').user.username;
    this.akquiseSubscription = this.eventService.subscribe(event => {
      if (event.command === 'akquise_list_refresh') {
        this.search(true);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.akquiseSubscription) {
      this.akquiseSubscription.unsubscribe();
    }
  }

  updateDate(date: DateRange): void {
    this.data.start = DateHelper.format(date.start);
    this.data.end = DateHelper.format(date.end);
    this.search();
  }

  query(any?) {
    // patches the returned query by overriding the customer_id
    // if any customer is set (the customer.id might be provided
    // via the id parameter instead of ?customer_id
    const cQuery = super.query(any);
    if (this.customer) {
      cQuery.customer_id = this.customer.id;
    }
    return cQuery;
  }

  editModal(index: number, akquise: Akquise) {
    const modalRef = this.modalService.open(AkquiseSaveComponent, {windowClass: 'modal2-akquise'});
    modalRef.componentInstance.akquise = akquise;
    modalRef.result.then(response => {
      this.list.objects[index] = response;
    }, () => {
    });
  }

  deleteActivity(id: number) {
    if (window.confirm('Wirklich löschen?')) {
      this.service.delete(id).subscribe(() => {
        this.stateService.go(this.stateService.current, this.stateService.params, {reload: true, inherit: false});
      }, () => {
      });
    }
  }

  isLoggedInUser(user: string): boolean {
    return user === this.loggedInUser;
  }
}
