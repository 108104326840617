import {Observable} from 'rxjs';
import {Article, ArticleCopyForm, ArticleListData, ArticleListElement} from '../models/article.models';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {ListService} from '../../../common/search/search.service';
import {sdsType} from '../../../envisia/article/services/article2.service';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

/**
 * do not call this service directly for any
 * article related services, since they will be proxied through their own provider
 */
@Injectable({providedIn: 'root'})
export class ArticleService implements ListService<ArticleListElement> {

  private uri = '/article/v1/article/'; // /article/v1/:options/:id/

  constructor(private http: HttpClient) {
  }

  create(strategy: sdsType, data: any): Observable<Article> {
    return this.http.post<{ object: Article }>(
      '/article/v1/article/' + strategy + '/', data
    ).pipe(map(values => values.object));
  }

  search(query?: any): Observable<ArticleListData[]> {
    return this.http.get<EnvisiaObjects<ArticleListData>>(
      '/article/v1/search/',
      {params: EnvisiaLocation.httpParams(query)}
    ).pipe(map(value => value.objects));
  }

  list(query?: any): Observable<Lister<ArticleListElement>> {
    return this.http.get<Lister<ArticleListElement>>(
      `${environment.apiv4uri}article/list`,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  detail(oaNr: string, fullHash: boolean = false): Observable<Article> {
    let url = this.uri + oaNr + '/';
    if (fullHash) {
      url = url + '?full_hash=true';
    }
    return this.http.get<EnvisiaObject<Article>>(url).pipe(map(value => value.object));
  }

  lock(article: string, body: { reason: string, reference: string, sister: boolean }): Observable<void> {
    return this.http.put<void>('/article/v1/locker/' + article + '/', body);
  }

  unlock(article: string, body: { reason?: string }): Observable<void> {
    return this.http.put<void>('/article/v1/unlocker/' + article + '/', body);
  }

  delete(article: string, body: { reason: string, reference: string, sister: boolean }): Observable<void> {
    return this.http.post<void>(this.uri + 'delete/' + article + '/', body);
  }

  recover(article: string, body: { reason: string, reference: string, sister: boolean }): Observable<Article> {
    return this.http.post<EnvisiaObject<Article>>(this.uri + 'recover/' + article + '/', body).pipe(
      map(value => value.object)
    );
  }

  copy(article: string, form: ArticleCopyForm): Observable<Article> {
    return this.http.post<EnvisiaObject<Article>>('/article/v1/copy/' + article + '/', form).pipe(
      map(obj => obj.object)
    );
  }

  calculation(article: string): Observable<{ de: string, cn: string }> {
    return this.http.get<EnvisiaObject<{ de: string, cn: string }>>(
      '/article/v1/calculation/' + article + '/'
    ).pipe(map(value => value.object));
  }

  hasActiveOrders(article: string): Observable<boolean> {
    return this.http.get<{ object: boolean }>(environment.apiv4uri + 'article/active-orders/' + article).pipe(
      map(val => val.object)
    );
  }

}
