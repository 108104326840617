import {ShippingInstructionTableRow} from './article-extra.models';

export class ShippingInstructionHelper {
  public static getHighestOverUnderDeliveryMatch(
    sioud: ShippingInstructionTableRow[] | null | undefined,
    closestTo: number
  ): ShippingInstructionTableRow | null {
    if (sioud === null || sioud === undefined) {
      return null;
    }
    const sorted = sioud.sort(ShippingInstructionHelper.sortFn);
    const empty = sorted.filter(si => ShippingInstructionHelper.emptyFilter(si));
    const nonEmpty = sorted.filter(si => !ShippingInstructionHelper.emptyFilter(si));
    return (
      nonEmpty.find(si => si.quantity_till >= closestTo) ??
      empty.find(si => si.percent !== null && si.percent !== undefined) ??
      null
    );
  }

  private static emptyFilter(si: ShippingInstructionTableRow): boolean {
    return si.quantity_till === null || si.quantity_till === undefined;
  }

  private static sortFn(si1: ShippingInstructionTableRow, si2: ShippingInstructionTableRow): number {
    const check1 = (si1.quantity_till ?? Number.MAX_SAFE_INTEGER) - (si2.quantity_till ?? Number.MAX_SAFE_INTEGER);
    if (check1 !== 0) {
      return check1;
    }
    const check2 = (si1.percent ?? Number.MAX_SAFE_INTEGER) - (si2.percent ?? Number.MAX_SAFE_INTEGER);
    if (check2 !== 0) {
      return check2;
    }
    return (si1.quantity ?? Number.MAX_SAFE_INTEGER) - (si2.quantity ?? Number.MAX_SAFE_INTEGER);
  }
}
