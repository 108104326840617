/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects} from '../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {ErpProducer, ErpProducerForm} from './producer.models';

@Injectable({providedIn: 'root'})
export class ProducerService {

  private uri = '/api/v1/producer/';

  constructor(private http: HttpClient) {
  }

  list(): Observable<ErpProducer[]> {
    return this.http.get<EnvisiaObjects<ErpProducer>>(this.uri).pipe(map(value => value.objects));
  }

  detail(name: string): Observable<ErpProducer> {
    return this.http.get<EnvisiaObject<ErpProducer>>(this.uri + name + '/').pipe(map(value => value.object));
  }

  save(data: ErpProducerForm): Observable<ErpProducer> {
    return this.http.put<EnvisiaObject<ErpProducer>>(this.uri, data).pipe(map(value => value.object));
  }

  drop(name: string): Observable<void> {
    return this.http.delete<void>(this.uri + name + '/');
  }

}
