import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleMultilayerPlan} from '../article-multilayer-plan.models';
import {from, Observable} from 'rxjs';
import {ArticleMultilayerPlanCopySelectModalComponent} from './article-multilayer-plan-copy-select-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ArticleMultilayerPlanCopyService {

  constructor(private modalService: NgbModal) {
  }

  open(): Observable<ArticleMultilayerPlan> {
    const modalRef = this.modalService.open(ArticleMultilayerPlanCopySelectModalComponent, {windowClass: 'modal2-xs'});
    return from(modalRef.result);
  }
}
