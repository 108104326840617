<form [formGroup]="form">
  <div class="modal-header">
    <h3 class="modal-title">Zusatzkosten hinzufügen</h3>
    <label for="fetch">Abruf:</label>

    <input id="fetch" type="text" name="fetch" formControlName="fetch" tabindex="1" class="form-control" [autofocus]>
  </div>

  <div class="modal-body">
    <div style="float:left; width: 200px;">
      <div style="float:left; padding-bottom: 20px;">
        <h5>Nebenkosten</h5>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_toolingcost"
                (click)="add('additional')">
          Nebenkosten hinzufügen
        </button>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_viafilling_type6_sieve"
                (click)="add('viafilling_type6_sieve')">
          Sieberstellung Viafilling Typ VI
        </button>
      </div>
      <div style="float:left;clear:left;padding-bottom: 20px;">
        <h5>Adapterkosten</h5>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_adaptercost"
                (click)="add('conventionell')">
          Adapterkosten hinzufügen
        </button>
        <br>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_fingertest"
                (click)="add('fingertester')">
          Fingertester hinzufügen
        </button>
      </div>
      <div style="float:left;clear:left;">
        <h5>Individual</h5>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_individual"
                (click)="add('individual')">
          Individual-Feld hinzufügen
        </button>
      </div>
    </div>

    <div style="float:left; width: 260px;">
      <h5>Eildienst DS</h5>
      <span *ngFor="let extra of extraCosts['express_ds']">
        <button class="btn btn-sm extracosts" (click)="add('express_ds', extra.id)"
                [ngClass]="{'btn-default': !extra.highlight, 'btn-primary': extra.highlight, 'bold': extra.bold}"
                style="margin: 5px 5px 0 0; width: 70px;">
          <span ng-if="extra.text">{{ extra.text }}</span>
          <span ng-if="!extra.text">{{ extra.description_de }}</span>
        </button>
      </span>
    </div>

    <div style="float:left; width: 260px;">
      <h5>Eildienst ML</h5>
      <span *ngFor="let extra of extraCosts['express_ml']">
        <button class="btn btn-sm extracosts" (click)="add('express_ml', extra.id)"
                [ngClass]="{'btn-default': !extra.highlight, 'btn-primary': extra.highlight, 'bold': extra.bold}"
                style="margin: 5px 5px 0 0; width: 70px;">
          <span ng-if="extra.text">{{ extra.text }}</span>
          <span ng-if="!extra.text">{{ extra.description_de }}</span>
        </button>
      </span>
    </div>

    <div style="float:left; width: 275px;">
      <h5>Extra Kosten - Fix Wert</h5>
      <span *ngFor="let extra of extraCosts['extracost']">
        <button class="btn btn-sm extracosts" (click)="add('extracost', extra.id)"
                [ngClass]="{'btn-default': !extra.highlight, 'btn-primary': extra.highlight, 'bold': extra.bold}"
                style="margin: 5px 5px 0 0; width: 130px;">
          <span *ngIf="extra.text">{{ extra.text }}</span>
          <span *ngIf="!extra.text">{{ extra.description }}</span>
        </button>
      </span>
    </div>

    <div style="float:left; width: 160px;">
      <h5>Extrakosten - Prozentual</h5>
      <span *ngFor="let extra of extraCosts['extracost_percentual']">
        <button class="btn btn-sm extracosts" (click)="add('extracost_percentual', extra.id)"
                [ngClass]="{'btn-default': !extra.highlight, 'btn-primary': extra.highlight, 'bold': extra.bold}"
                style="margin: 5px 5px 0 0; width: 150px;">
          <span *ngIf="extra.text">{{ extra.text }}</span>
          <span *ngIf="!extra.text">{{ extra.description }}</span>
        </button>
      </span>
    </div>

  </div>


  <div class="modal-footer"></div>
</form>
