import * as React from 'react';

interface Props {
  onClick: () => void;
}

export function ArticleMultilayerPlanCreateButton(props: Props) {
  return <>
    <button className={'btn btn-default float-right'} onClick={() => props.onClick()}>
      Gem. Artikel konfigurieren
    </button>
  </>;
}

