import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FrameService} from '../../workflow-services/frame.service';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';
import {Workflow} from '../../models/workflow.models';
import {HttpErrorResponse} from '@angular/common/http';
import {ContractHistoryModalComponent} from './contract-history-modal.component';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';

@Component({
  selector: 'contract-create-frame-add-modal',
  templateUrl: './contract-create-frame-add-modal.component.html'
})
export class ContractCreateFrameAddModalComponent implements OnInit {
  @Input() workflow: Workflow;
  form: UntypedFormGroup;
  cq_needed = false;
  articleUndef = false;
  errors: any = {};

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private frameService: FrameService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      quanity: [null],
      lose: [1],
      release: [null],
      calc_quanity: [null]
    });
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  check(): boolean {
    return this.cq_needed && !this.form.value.calc_quanity;
  }

  // standard dispatch if no combination match was found
  dispatch(clb?: LoadingButtonEvent) {
    this.errors = {};
    this.frameService.create(this.workflow.id, this.form.value).subscribe(response => {
      this.frameService.additional(this.workflow.id).subscribe(additional => {
        if (clb) {
          clb.callback(false);
        }
        this.activeModal.close({value: response, additional: additional});
      }, () => {
        if (clb) {
          clb.callback(false);
        }
        this.activeModal.close({value: null, additional: null});
        this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte laden Sie die Seite erneut!');
      });
    }, (response: HttpErrorResponse) => {
      const errors = response.error;

      if (clb) {
        clb.callback(false);
      }

      if ('obj.customer' in errors) {
        this.alertService.add('danger', 'Auftragsrahmen erstellen, nicht möglich, da Artikel kein Kunde hinterlegt hat!');
      }

      if (!this.cq_needed && errors.calc_quanity) {
        this.cq_needed = true;
      }

      if (errors.status === 1) {
        this.articleUndef = true;
      } else {
        // TODO(schmitch): Delete calc_quanity if not needed
        if (!!errors.errors) {
          this.errors = errors.errors;
        }
      }
    });
  }

  add(clb?: LoadingButtonEvent): void {
    this.frameService.check(this.workflow.id, this.form.value).subscribe(response => {
      const modalRef = this.modalService.open(ContractHistoryModalComponent, {windowClass: 'modal2-m'});
      modalRef.componentInstance.data = response;
      from(modalRef.result).subscribe(value => {
        // cast undefined to null
        if (value.release !== undefined) {
          this.form.patchValue({'release': value.release});
        }
        this.dispatch(clb);
      }, ignoreRejection);
    }, () => {
      this.dispatch(clb);
    });
  }

}
