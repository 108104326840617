<div class="row">
  <div class="col-lg-12">

    <table class="table whitetable business-order-create business-order-view-table">
      <tbody>
      <tr>
        <td>Gesamt Anzahl Fe. Nutzen:</td>
        <td>{{ ba.utilize_quantity_total }}</td>
      </tr>
      <tr>
        <td>Gesamt Menge:</td>
        <td>{{ ba.utilize_quantity_total * article.data.utilize_sum}}</td>
      </tr>
      <tr>
        <td>Reservierter Lagerstand:</td>
        <td>{{ sums.reserved_inventory|money:0 }}</td>
      </tr>
      <tr>
        <td><strong>Gesamt Gutstück Menge:</strong></td>
        <td><strong>{{ sums.full_good_piece|money:0 }}</strong></td>
      </tr>
      </tbody>
    </table>

  </div>
</div>

<div class="row">
  <div class="col-lg-12">&nbsp;</div>
</div>


<div class="row">
  <div class="col-lg-12">
    <table class="table whitetable business-order-view-table business-order-create">
      <tbody>
      <tr class="middle-child">
        <td colspan="2">
          <a uiSref="a.article.workflow.detail" [uiParams]="{id:article.oa_nr}">
            <h4>
              <span *ngIf="article.sds_nr">{{ article.sds_nr }}</span>
              <span *ngIf="!article.sds_nr">{{ article.oa_nr }}</span>
            </h4>
          </a>
        </td>
      </tr>
      <tr class="middle-child">
        <td colspan="2">
          <strong>
            {{ article.data.execution }}
            <span *ngIf="article.data.surface_area">- {{ article.data.surface_area }}</span>
          </strong>
        </td>
      </tr>
      <tr>
        <td>Kunden-Art-Nr.:</td>
        <td>{{ article.data.kd_art_nr }}</td>
      </tr>
      <tr>
        <td>Kunden-Index:</td>
        <td>{{ article.data.kd_art_index }}</td>
      </tr>
      <tr>
        <td>Fe.-Nutzengröße</td>
        <td>{{ article.data.utilizeFormat }}</td>
      </tr>
      <tr>
        <td>LP / Fe.-Nutzen:</td>
        <td>{{ article.data.utilize1 }} x {{ article.data.utilize2 }} = {{ article.data.utilize_sum}}</td>
      </tr>
      <tr *ngIf="ba.multilayer_id">
        <td>Aufbauplan:</td>
        <td><a class="click" (click)="openMultilayerPdf()"><i class="fa fa-file-pdf-o"></i></a></td>
      </tr>
      <tr class="article-child">
        <td colspan="2">
          <a uiSref="a.article.workflow.detail" [uiParams]="{id:article.oa_nr}" target="_blank">
            Artikel öffnen
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
