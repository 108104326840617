import * as React from 'react';
import {ArticleSpecification} from '../../../models/article-specification.model';

interface Props {
  specification?: { [key: string]: ArticleSpecification };
  model?: string;
  unit?: string;
}

export function ArticleMultilayerUnit(props: Props) {
  return <div className={'article-form-data-content'}>
    <div className={'article-form-data-unit-block'}>
      {(!!props.specification && !!props.model) ? props.specification[props.model]?.unit : props.unit}
    </div>
  </div>;
}
