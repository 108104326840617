import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialOrderTransaction} from '../../material-order.model';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'material-order-intake-print-modal',
  templateUrl: './material-order-intake-print-modal.component.html',
})
export class MaterialOrderIntakePrintModalComponent {
  @Input() transactions: MaterialOrderTransaction[];
  @Input() markedTransactionId?: number;

  constructor(private activeModal: NgbActiveModal) {
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  print(): void {
    if (this.transactions.length > 0) {
      const t = this.transactions[0];
      window.open(`${environment.apiv4uri}material/pdf/intake/${t.order_id}/${t.index}`, '_blank');
    }
    this.activeModal.close();
  }
}
