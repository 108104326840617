/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {EnvisiaInterceptor} from './http-interceptor';

// Track: https://github.com/angular/angular/blob/master/packages/http/src/http_module.ts
@NgModule({
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: EnvisiaInterceptor,
    multi: true,
  }, provideHttpClient(withInterceptorsFromDi())],
  imports: [],
})
export class EnvisiaHttpModule {
}

