/*
 * Copyright (C) 2019 envisia GmbH
 * All Rights Reserved.
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {InvoiceJobErrorStore} from './invoice-job-error.store';
import {InvoiceJobErrorListService} from './invoice-job-error-list.service';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {InvoiceJobError} from './invoice-job-error.models';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {InvoiceSendModalService} from '../send/invoice-send-modal.service';

@Component({
  selector: 'job-error-list',
  templateUrl: './invoice-job-error-list.component.html'
})
export class InvoiceJobErrorListComponent implements OnInit, OnDestroy {
  data: LoadingWrapper<InvoiceJobError[]>;

  constructor(private store: InvoiceJobErrorStore,
              private service: InvoiceJobErrorListService,
              private alertService: AlertService,
              private sendModalService: InvoiceSendModalService) {
  }

  ngOnInit(): void {
    this.store.lock();
    this.store.clean();
    this.data = new LoadingWrapper(this.service.failedList());
  }

  ngOnDestroy(): void {
    this.store.unlock();
  }

  clean(errors: InvoiceJobError[], index: number, job: InvoiceJobError): void {
    this.service.failedJobClean(job.id).subscribe(() => {
      this.alertService.add('success', 'Versand Auftrag erfolgreich gelöscht!');
      errors.splice(index, 1);
    }, () => {
      this.alertService.add('danger', 'Versand Auftrag konnte nicht gelöscht werden!');
    });
  }

  restart(errors: InvoiceJobError[], index: number, job: InvoiceJobError): void {
    const flipper = () => {
      errors.splice(index, 1);
    };
    this.sendModalService.open(job.invoice_id, flipper).subscribe(() => {}, () => {});
  }

}
