<ng-container [formGroup]="form">
  <ng-container *ngFor="let transaction of formArrayMapped; trackBy: trackByFn; let iT = index">
    <tr [ngClass]="{'info': (markedVariationId === position.id) || (markedTransactionId === transaction.id)}"
        [material-order-intake-booked-row]="position"
        [material-order-intake-booked-row-index-g]="iG"
        [material-order-intake-booked-row-index-p]="iP"
        [material-order-intake-booked-row-index-t]="iT"
        [material-order-intake-booked-row-finder-supplier-uri]="finderSupplierUri"
        [material-order-intake-booked-row-transaction]="transaction"
        [material-order-intake-booked-row-fields]="fields"
        [material-order-intake-booked-row-group-permit]="groupPermission"
        [material-order-intake-booked-row-is-first]="iT === 0"
        [material-order-intake-booked-row-form-position]="form"
        [material-order-intake-booked-row-form-transaction]="transaction?.form"
        [material-order-intake-booked-row-errors]="errors"
        (material-order-intake-booked-row-remove-transaction)="updateOrder.emit()"
        (material-order-intake-booked-row-update-position)="updatePosition($event)">
    </tr>
  </ng-container>

  <tr [ngClass]="{'info': (markedVariationId === position.id)}"
      [material-order-intake-booked-row-empty]="position"
      [material-order-intake-booked-row-empty-finder-supplier-uri]="finderSupplierUri"
      [material-order-intake-booked-row-empty-fields]="fields"
      [material-order-intake-booked-row-empty-form-position]="form"
      (material-order-intake-booked-row-empty-update-position)="updatePosition($event)"
      *ngIf="formArrayMapped.length === 0">
  </tr>
</ng-container>
