<div class="row">
  <div [ngClass]="{'col-lg-6': !stacked, 'col-lg-12': stacked}">
    <article-select label="Via Handling"
                    model="via_handling"
                    [form]="form"
                    [specification]="specification"
                    [error]="errors['via_handling']">
    </article-select>
  </div>

  <div [ngClass]="{'col-lg-6': !stacked, 'col-lg-12': stacked}">
    <article-ab-checkbox label="Seite"
                         model1="via_ls"
                         model2="via_ds"
                         [form]="form"
                         [error1]="errors['via_ls']"
                         [error2]="errors['via_ds']"
                         *ngIf="viaHandlingType === 3">
    </article-ab-checkbox>

    <article-checkbox label="Lack" model="via_filling_lacquer"
                      [form]="form"
                      [error]="errors['via_filling_lacquer']"
                      *ngIf="(viaHandlingType === 6)">
    </article-checkbox>

    <article-checkbox label="Harz" model="via_filling_resin"
                      [form]="form"
                      [error]="errors['via_filling_resin']"
                      *ngIf="(viaHandlingType === 6)">
    </article-checkbox>
  </div>
</div>
