<ng-container *ngIf="isFirst">
  <td>
    <material-order-position-status [id]="position.position_id"
                                    [status]="position.status"
                                    (updatePosition)="updatePositionEmitter.emit($event)"
                                    *ngIf="isFirst">
    </material-order-position-status>
  </td>
  <td class="align-right">{{position.index}}</td>
  <td class="align-right">
    <span *ngIf="position.delivery_index === 0">1</span>
    <strong *ngIf="position.delivery_index > 0">{{position.delivery_index + 1}}</strong>
  </td>
  <td *ngIf="groupPermission">
    <a uiSref="a.material.inventory.update" target="_blank" [uiParams]="{variation: position.id}">
      {{position.variation_id}}
    </a>
  </td>
  <td *ngIf="!groupPermission">
    {{position.variation_id}}
  </td>
  <td>{{position.vendor_article_id}}</td>
  <td>{{position.article_name}}</td>
  <td [ngClass]="{'align-right': field.isValueType('Number')}" *ngFor="let field of fields">
    <ng-container [ngSwitch]="field.data_type" *ngIf="!(position.fields[field.html_name]|isNull)">
      <ng-container *ngSwitchCase="'Option'">
        <i class="fa" [ngClass]="{
          'fa-check':(position.fields[field.html_name] === true),
          'fa-times': (position.fields[field.html_name] === false)}"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'Ganzzahl'">
        {{ position.fields[field.html_name] | money:0 }}
      </ng-container>
      <ng-container *ngSwitchCase="'Kommazahl'">
        {{ position.fields[field.html_name] | money }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ position.fields[field.html_name] }}
      </ng-container>
    </ng-container>
  </td>
  <td>
    {{position.date_delivery|date:'dd.MM.yyyy'}}
    <span style="font-size: 8px" *ngIf="position.date_delivery_first_contact &&
                         (position.date_delivery !== position.date_delivery_first_contact)">
    <br />{{position.date_delivery_first_contact | date:'dd.MM.yyyy'}}
    </span>
  </td>
  <td class="align-right">
    {{position.amount_ordered|money}}
    <span style="font-size: 8px" *ngIf="position.amount_ordered_first_contact &&
                         (position.amount_ordered !== position.amount_ordered_first_contact)">
      <br />{{position.amount_ordered_first_contact|money}}
    </span>
  </td>
</ng-container>

<ng-container *ngIf="!isFirst">
  <td [colSpan]="8 + fields.length"></td>
</ng-container>

<ng-container [formGroup]="form">
  <td class="align-right" *ngIf="('material.order.price.check'|HasPerm)">
    <ng-container *ngIf="!!position.price">
      {{position.price|money:2}}&nbsp;&euro;
    </ng-container>
  </td>
  <td>
    <input type="text" class="form-control" formControlName="charge">
  </td>
  <td>
    <input type="text" class="form-control" formControlName="location" [ngClass]="{red_alert: errorLocation}">
  </td>
  <td>
    <input type="number"
           class="form-control align-right"
           min="0"
           formControlName="amount"
           [ngClass]="{red_alert: errorAmount}">
    <span class="red_font" *ngIf="errorAmount">Keine negativen Werte</span>
  </td>
</ng-container>

<ng-container *ngIf="isFirst">
  <td class="align-right">{{remaining|money}}</td>
  <td class="icon"></td>
  <td class="icon">
    <a class="click" title="Einbuchungsposition hinzufügen" (click)="addTransactionForm()">
      <i class="fa fa-plus-circle green row-icon" aria-hidden="true"></i>
    </a>
  </td>
  <td>
    <a class="click" title="Position als &quot;Gebucht&quot; markieren" (click)="setStatus(2)" *ngIf="('material.order.status'|HasPerm)">
      <i class="fa fa-check gray_font row-icon" aria-hidden="true"></i>
    </a>
  </td>
  <td>
    <material-label-print-button [variationId]="position.id" classInner="fa-2x"></material-label-print-button>
  </td>
</ng-container>

<ng-container *ngIf="!isFirst">
  <td></td>
  <td class="icon"></td>
  <td class="icon">
    <a class="click" (click)="removeTransactionForm()">
      <i class="fa fa-minus-circle red_font row-icon" title="Einbuchungsposition entfernen"></i>
    </a>
  </td>
  <td colspan="2"></td>
</ng-container>
