<div class="row">

  <div class="col-md-6">

    <div class="content-box upload-typ-item click" (click)="resetEmitter.emit({})">
      <div class="upload-item-icon">
        <i class="fa fa-download"></i>
      </div>
      <div class="upload-item-title">
        Neues Dokument hinzufügen
      </div>
    </div>
  </div>

  <div class="col-md-6">

    <a [href]="href">
      <div class="content-box upload-typ-item click">
        <div class="upload-item-icon">
          <i class="fa fa-search"></i>
        </div>
        <div class="upload-item-title">
          Zur Suche
        </div>
      </div>
    </a>
  </div>

  <div class="col-md-12" *ngIf="httpStatus === 'processing'">
    <div class="content-box upload-typ-item">
      <div class="upload-item-icon">
        <i class="fa fa-circle-o-notch fa-spin"></i>
      </div>
      <div class="upload-item-title">
        Verarbeitung
      </div>
    </div>
  </div>

  <div class="col-md-12" *ngIf="httpStatus === 'error'">
    <div class="content-box">
      <h3>Beim Verarbeiten der Dokumente ist ein Fehler aufgetreten</h3>

      <i class="fa fa-exclamation-triangle fa-4x" aria-hidden="true"></i>

    </div>
  </div>

  <div class="col-md-12" *ngIf="httpStatus === 'success'">
    <div class="content-box">
      <h3>Verarbeitete Dokumente</h3>

      <div class="content-box-table-row" *ngFor="let obj of success">
        <div class="content-box-cell content-box-category">
          <a id="upload1" [href]="obj.web_url" target="_blank">
            {{ obj.name }}.{{ obj.extension }}
          </a>
        </div>
        <div class="content-box-cell content-box-cell-action">
          <a id="upload2" [href]="obj.web_url" target="_blank">
            <i class="fa fa-angle-right"></i>
          </a>
        </div>
      </div>

    </div>
  </div>

</div>
