import {Component, Input, OnInit} from '@angular/core';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {WorkflowOrderRequestService} from '../../workflow/workflow-services/workflow-order-request.service';

@Component({
  selector: 'price-detail-order',
  templateUrl: './price-detail-order.component.html',
  styles: [
    '.ml-0 { margin-left: 0 !important; }',
    '.pl-0 { padding-left: 0 !important; }'
  ]
})
export class PriceDetailOrderComponent implements OnInit {
  @Input() article: string;
  data: LoadingWrapper<{count: number}>;

  constructor(private orderService: WorkflowOrderRequestService) {
  }

  ngOnInit(): void {
    this.data = new LoadingWrapper(this.orderService.count(this.article));
  }

}
