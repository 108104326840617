
<div class="content-box">
  <h3>Ansprechpartner <span class="label label-danger" *ngIf="contact?.active === false">Gelöscht</span></h3>

  <div class="customer-inside">
    <div *ngIf="contact">
      {{ contact.title }}
      <h4>{{ contact.first_name }} {{ contact.last_name }}</h4>

      <p *ngIf="contact.departments.length > 0">
        {{ contact.departments|JoinStringPipe }}
      </p>

      <p *ngIf="contact.position">
        {{ contact.position }}
      </p>

      <p *ngIf="contact.phone"><i class="fa fa-phone"></i>
        {{ contact.phone }}
      </p>

      <p *ngIf="contact.mobile"><i class="fa fa-mobile"></i>
        {{ contact.mobile }}
      </p>

      <p *ngIf="contact.mail">
        <a [href]="'mailto:' + contact.mail"
           ngbTooltip="E-Mail an Ansprechpartner senden">
          <i class="fa fa-envelope"></i> {{ contact.mail }}
        </a>
      </p>
    </div>
    <div *ngIf="!contact" class="customer-inside">
      Kein Ansprechpartner ausgewählt
    </div>
  </div>

  <div class="btn-group content-box-bottom">
    <button class="btn btn-default btn-sm" *ngIf="('task.offer.change'|HasPerm)" (click)="switchContact()">
      Bearbeiten
    </button>
  </div>
</div>
