<div class="filter-row">
  <div class="filter-left">
    <search-type typ="de" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>
    <search-type typ="cn" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>

    <button class="btn btn-default btn-filter" type="button" (click)="badge('0')" [ngClass]="{active: q('0')}" *ngIf="('offer.view.processing'|HasPerm)">
      Bearbeitung <span class="badge">{{ list.badges.processing|money }}</span>
    </button>

    <button class="btn btn-purple btn-filter" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
      Versendet <span class="badge">{{ list.badges.sent|money }}</span>
    </button>

    <button class="btn btn-danger btn-filter" type="button" (click)="badge('3')" [ngClass]="{active: q('3')}">
      Kein Auftrag <span class="badge">{{ list.badges.rejected|money }}</span>
    </button>

    <button class="btn btn-warning btn-filter" type="button" (click)="badge('4')" [ngClass]="{active: q('4')}">
      Auftrag kommt <span class="badge">{{ list.badges.accepted|money }}</span>
    </button>

    <button class="btn btn-success btn-filter" type="button" (click)="badge('5')" [ngClass]="{active: q('5')}">
      Bestellt <span class="badge">{{ list.badges.ordered|money }}</span>
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('100', 'workflow_status', true)"
            [ngClass]="{active: q('100', 'workflow_status')}">
      Storniert
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('101', 'workflow_status', true)"
            [ngClass]="{active: q('101', 'workflow_status')}">
      Gelöscht
    </button>

    <button class="btn btn-primary btn-filter" type="button" (click)="badge('all')" [ngClass]="{active: q('all')}">
      Alle <span class="badge">{{ list.badges.all|money }}</span>
    </button>

    <a [href]="exportUri"
       target="_blank"
       class="btn btn-default btn-sm"
       title="als CSV Datei Exportieren"
       *ngIf="!('sales.restricted'|HasPerm)">
      <i class="fa fa-download"></i>
    </a>

    &nbsp;
    <a uiSref="a.workflow.o.analysis"
       target="_blank"
       [uiParams]="analyzeParamsWithChannelId"
       class="btn btn-default btn-sm"
       ngbTooltip="Angebotsauswertung"
       *ngIf="('task.offer.analysis'|HasPerm)">
      <i class="fa fa-line-chart"></i>
    </a>
  </div>

  <div class="filter-right">
    <date-range [start]="null" [end]="null" (update)="updateDateRange($event)"></date-range>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th style="width: 110px;">
        <a (click)="sort($event, 'id', 'ASC')" [href]="sortUrl('id', 'ASC')">Angebot</a>
      </th>
      <th>Status</th>
      <th>
        <a (click)="sort($event, 'workflow_id', 'DESC')" [href]="sortUrl('workflow_id', 'DESC')">Vorgang</a>
      </th>
      <th style="width: 90px;">
        <a (click)="sort($event, 'date', 'DESC')" [href]="sortUrl('date', 'DESC')">Datum</a>
      </th>
      <th style="width: 90px;">
        <a (click)="sort($event, 'resubmission_date', 'DESC')" [href]="sortUrl('resubmission_date', 'DESC')">
          WV-Datum
        </a>
      </th>
      <th style="width: 70px;">
        <a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">SDS-Nr.</a>
      </th>
      <th style="width:75px;">
        <a (click)="sort($event, 'oa_nr', 'ASC')" [href]="sortUrl('oa_nr', 'ASC')">0A-Nr.</a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_nr', 'ASC')" [href]="sortUrl('kd_art_nr', 'ASC')">Kd-Art-Nr.</a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'ASC')" [href]="sortUrl('customer_name', 'ASC')">Kunde</a>
      </th>
      <th style="width: 60px;">
        <a (click)="sort($event, 'customer_zip', 'ASC')" [href]="sortUrl('customer_zip', 'ASC')">PLZ</a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_city', 'ASC')" [href]="sortUrl('customer_city', 'ASC')">Stadt</a>
      </th>
      <th style="width: 55px;">
        <a (click)="sort($event, 'customer_country', 'ASC')" [href]="sortUrl('customer_country', 'ASC')">Land</a>
      </th>
      <th style="width: 50px;">
        <a (click)="sort($event, 'customer_field_service', 'ASC')" [href]="sortUrl('customer_field_service', 'ASC')">
          VG
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'username', 'ASC')" [href]="sortUrl('username', 'ASC')">Ersteller</a>
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th></th>
    </tr>
    <tr class="filterrow">
      <th>
        <input type="text"
               class="form-control input-sm"
               name="sid"
               [ngClass]="{ 'red_alert': !!filterFormErrors.sid }"
               [(ngModel)]="data.sid"
               [autofocus]
               autocomplete="off search-offer-id">
      </th>
      <th></th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="workflow_id"
               [ngClass]="{ 'red_alert': !!filterFormErrors.workflow_id }"
               [(ngModel)]="data.workflow_id">
      </th>
      <th></th>
      <th>
        <ev-datepicker class="form-control input-sm"
                       name="resubmission_date"
                       [allDates]="true"
                       [evClass]="{ 'red_alert': !!filterFormErrors.resubmission_date }"
                       [(ngModel)]="data.resubmission_date">
        </ev-datepicker>
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.sds_nr" name="sds_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.oa_nr" name="oa_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.kd_art_nr" name="kd_art_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_name" name="customer_name">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_zip" name="customer_zip">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_city" name="customer_city">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_country" name="customer_country">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_field_service"
               name="customer_field_service">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.username" name="username">
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let object of list.objects; trackBy:trackByFn; let index = index">
      <td>
        <ev-file-button [objId]="object.id" type="offer" [link]="true">
          {{ object.id }}
        </ev-file-button>
        <img *ngIf="object.de" src="/ui/assets/images/flags/de.png"
             title="Es wurden deutsche Preise angeboten" class="table-flag">
        <img *ngIf="object.cn" src="/ui/assets/images/flags/cn.png"
             title="Es wurden China-Preise angeboten" class="table-flag">
      </td>
      <td>
        <workflow-state type="offer" [key]="object.offer_status" clazz="item-status"></workflow-state>
      </td>
      <td>
        <a uiSref="a.workflow.detail" [uiParams]="{id: object.workflow_id}">
          {{ object.workflow_id }}
          <workflow-state type="workflow_status" [key]="object.status" clazz="item-status"></workflow-state>
          <span *ngIf="object.comments > 0" class="td-comments" title="Kommentar(e) vorhanden">
              <i class="fa fa-comments"></i>
          </span>
        </a>
      </td>
      <td>{{ object.date }}</td>
      <td>
        <!-- Form Element is IMPORTANT, so that we don't use the outer table form -->
        <form>
          <ev-datepicker class="form-control input-sm form-input" [ngModel]="object.resubmission_date"
                      [allDates]="true" (callback)="saveDate($event, object)" name="resubmission_date"
                      [evClass]="{'form-alert': later(object.resubmission_date)}">
          </ev-datepicker>
        </form>
        <div style="display: inline;" *ngIf="('sales.restricted'|HasPerm)">
          {{ object.resubmission_date|date:'dd.MM.yyyy' }}
        </div>
      </td>
      <td>
        <strong>{{ object.sds_nr }}</strong>
        <div class="label label-danger" *ngIf="object.special_production">Spezial</div>
      </td>
      <td>
        <a uiSref="a.article.workflow.overview" [uiParams]="{id: object.oa_nr}">
          {{ object.oa_nr }}
        </a>
      </td>
      <td>
        <a uiSref="a.article.workflow.overview" [uiParams]="{id: object.oa_nr}">
          {{ object.kd_art_nr }}
          <span ng-if="object.kd_art_index" class="label label-default">{{ object.kd_art_index }}</span>
        </a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}">{{ object.customer_name }}</a>
      </td>
      <td>{{ object.customer_zip }}</td>
      <td>{{ object.customer_city }}</td>
      <td>{{ object.customer_country }}</td>
      <td>{{ object.customer_field_service }}</td>
      <td><a [href]="'mailto:' + object.email">{{ object.username }}</a></td>
      <td class="icon">
        <button (click)="mail(object.id, object.workflow_id)"
                class="btn btn-link btn-sm"
                type="button">
          <i class="fa fa-envelope"></i>
        </button>

      </td>
      <td class="icon">
        <button type="button" class="btn btn-link btn-sm" (click)="history(object.id)"
                title="Angebotener Artikel anzeigen" *ngIf="!('sales.restricted'|HasPerm)">
          <i class="fa fa-info"></i>
        </button>
      </td>
      <td class="icon">
        <button type="button" class="btn btn-link btn-sm" *ngIf="object.offer_status < 3"
                (click)="setStatus(object, 4)" title="Angebot auf Status 'Auftrag kommt' setzen">
          <i class="fa fa-thumbs-o-up"></i>
        </button>

        <button type="button" class="btn btn-link btn-sm" *ngIf="object.offer_status > 2"
                (click)="setStatus(object, null)" title="Angebot auf Status Offen setzen">
          <i class="fa fa-repeat"></i>
        </button>
      </td>
      <td class="icon">
        <offer-rejection-modal [offerId]="object.id"
                               [rejectionReasons]="rejectionReasons"
                               (reloadList)="reload()"
                               *ngIf="object.offer_status < 3">
        </offer-rejection-modal>
      </td>
      <td class="icon">
        <button type="button" class="btn btn-link btn-sm" *ngIf="object.offer_status < 3"
                (click)="setStatus(object, 5)" title="Angebot auf Status 'Bestellt' setzen">
          <i class="fa fa-home"></i>
        </button>
      </td>
      <td>
        <a uiSref="a.workflow.create" [uiParams]="{name: 'contract', id: object.workflow_id}"
           class="btn btn-default btn-sm"
           tabindex="-1"
           *ngIf="object.status === 1 && 'task.change'|HasPerm"
           title="Auftrag aus diesem Angebot erstellen">
          Auftrag
        </a>
      </td>
      <td>
        <a class="btn btn-default btn-sm"
           uiSref="a.price.check" [uiParams]="{id: object.oa_nr}" *ngIf="'price.detail'|HasPerm">
          Prüfung
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
