import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DeliveryCreateModalComponent} from './delivery-create-modal.component';
import {DeliveryCreateModalFormWeightComponent} from './delivery-create-modal-form-weight.component';
import {DeliveryCreateModalFormInventoryComponent} from './delivery-create-modal-form-inventory.component';
import {SharedModule} from '../../../../core/shared.module';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {DeliveryModalModule} from '../modals/delivery-modal.module';

@NgModule({
    imports: [
        CommonModule,
        NgbModalModule,
        SharedModule,
        DeliveryModalModule,
    ],
    declarations: [
        DeliveryCreateModalComponent,
        DeliveryCreateModalFormWeightComponent,
        DeliveryCreateModalFormInventoryComponent,
    ]
})
export class DeliveryCreateModalModule { }
