import {Component, Input, OnInit} from '@angular/core';
import {BusinessOrder, BusinessOrderSums, BusinessOrderView} from '../models/business-order.models';
import {Article} from '../../../article/models/article.models';
import {
  ArticleMultilayerPlanAxiosService
} from '../../../article/main/multilayer-plan/article-multilayer-plan-axios.service';

@Component({
  selector: 'business-order-view-article-box',
  templateUrl: './business-order-view-article-box.component.html',
})
export class BusinessOrderViewArticleBoxComponent implements OnInit {
  @Input() data: BusinessOrderView;
  ba: BusinessOrder;
  sums: BusinessOrderSums;
  article: Article;

  constructor() {
  }

  ngOnInit(): void {
    this.ba = this.data.object;
    this.sums = this.data.sums;
    this.article = this.data.article;
  }

  openMultilayerPdf(): void {
    ArticleMultilayerPlanAxiosService.bewitPdf(this.ba.multilayer_id).then(s => {
      window.open(s.object, '_blank');
    });
  }
}
