<div class="row">

  <div class="col-md-12">

    <h1>Etiketten Einstellungen</h1>

  </div>

</div>

<div class="row">

  <div class="col-md-4">

    <ul ngbNav #nav="ngbNav" (tabChange)="tabChange($event)" class="nav-tabs">
      <li [ngbNavItem]="'default'" class="nav-item">
        <a ngbNavLink>Standard</a>
        <ng-template ngbNavContent>

          <form class="form form-horizontal" [formGroup]="form" (ngSubmit)="submitData()">

            <div class="form-group">
              <label for="inputPrinter" class="col-sm-2 control-label">Drucker IP:</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="inputPrinter"
                       placeholder="___ . ___ . ___ . ___"
                       formControlName="ip">
              </div>
            </div>

            <div class="form-group">
              <label for="inputTemplate" class="col-sm-2 control-label">Template:</label>
              <div class="col-sm-10">
                        <textarea class="form-control" id="inputTemplate"
                                  rows="20"
                                  formControlName="template">
                        </textarea>
              </div>
            </div>

            <button type="submit" class="btn btn-success">
              Speichern
            </button>

          </form>

        </ng-template>
      </li>

      <li [ngbNavItem]="'customer'" class="nav-item">
        <a ngbNavLink>Kundespezifisch</a>
        <ng-template ngbNavContent>

          <label-settings-customer (updateTemplate)="updateCustomerTemplate($event)">
          </label-settings-customer>

        </ng-template>
      </li>

    </ul>

    <div [ngbNavOutlet]="nav"></div>
  </div>

  <div class="col-md-8">
    <label-create [printerData]="printerData" (labelPreview)="preview($event)">
    </label-create>
  </div>

</div>

<div class="row">
  <div class="col-md-12">

    <img [src]="previewImage" *ngIf="previewImage" style="width: 1000px;" alt="Vorschau">

  </div>
</div>
