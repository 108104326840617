import * as React from 'react';
import {
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import {MaterialLabelPrintButton} from './material-label-print-button';
import {ReactBaseComponent} from '../../../react/base/react-base.component';

const containerElementName = 'reactMaterialLabelPrintButton';

@Component({
  selector: 'material-label-print-button',
  template: `<div style="padding: 0;" #${containerElementName}></div>`,
})
export class MaterialLabelPrintButtonComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() variationId: number;
  @Input() classInner?: string;

  constructor() {
    super();
  }

  protected renderComponent(): JSX.Element {
    const _ = React.version;
    return <MaterialLabelPrintButton variationId={this.variationId} classInner={this.classInner}/>;
  }
}
