/*
 * Copyright (C) 2019 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {InvoiceJobError} from './invoice-job-error.models';

@Injectable({providedIn: 'root'})
export class InvoiceJobErrorListService {

  constructor(private http: HttpClient) {
  }

  failedJobClean(id: string): Observable<void> {
    return this.http.post<void>(environment.apiv4uri + 'invoice/send/failed-clean/' + id, {});
  }

  failedPoll(): Observable<number> {
    return this.http.get<{ object: number }>(environment.apiv4uri + 'invoice/send/failed-poll').pipe(
      map(val => val.object)
    );
  }

  failedList(): Observable<InvoiceJobError[]> {
    return this.http.get<{ objects: InvoiceJobError[] }>(environment.apiv4uri + 'invoice/send/failed-list').pipe(
      map(val => val.objects)
    );
  }
}
