import {environment} from '../../../environments/environment';
import {AxiosHttpClient} from '../../envisia/auth/axios-http-client';
import {
  ArticleMultilayerPlan,
  ArticleMultilayerPlanModel,
} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.models';
import {Lister} from '../../common/wrapper.models';
import {from, Observable} from 'rxjs';
import {ListService} from '../../common/search/search.service';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class MultilayerPlanAxiosService implements ListService<ArticleMultilayerPlan> {
  private static uri = (`${environment.apiv4uri}multilayerplan`);

  public static get(sharedId: string) {
    return AxiosHttpClient.get<ArticleMultilayerPlan>(this.uri + `/${sharedId}`);
  }

  public static create(form: ArticleMultilayerPlanModel) {
    return AxiosHttpClient.post<ArticleMultilayerPlan>(this.uri, form);
  }

  public static update(sharedId: string, form: ArticleMultilayerPlanModel) {
    return AxiosHttpClient.put<ArticleMultilayerPlan>(this.uri + `/${sharedId}`, form);
  }

  public static delete(sharedId: string): Observable<void> {
    return from(AxiosHttpClient.delete(this.uri + `/${sharedId}`));
  }

  public static updateName(form: {shared_id: string, name: string}): Observable<ArticleMultilayerPlan> {
    return from(AxiosHttpClient.put<ArticleMultilayerPlan>(this.uri + `/${form.shared_id}/name`, form));
  }

  public static listPromise(): Promise<Lister<ArticleMultilayerPlan>> {
    return AxiosHttpClient.get<Lister<ArticleMultilayerPlan>>(this.uri);
  }

  list(query?: { page?: number, id?: string }): Observable<Lister<ArticleMultilayerPlan>> {
    return from(AxiosHttpClient.get<Lister<ArticleMultilayerPlan>>(
      MultilayerPlanAxiosService.uri,
      {page: query?.page ?? 1, id: query?.id}
    ));
  }
}
