<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-header">
    <h3 class="modal-title">
      Auftragsrahmen bearbeiten
    </h3>
  </div>

  <div class="modal-body">
    <div class="row form-group">
      <div class="col-sm-4">
        <label for="quanity">Menge</label>
      </div>
      <div class="col-sm-8">
        <input id="quanity"
               class="form-control"
               type="number"
               min="0"
               formControlName="quanity"
               [ngClass]="{'red_alert': errors['obj.quanity']}">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-4">
        <label for="lose">Lose</label>
      </div>
      <div class="col-sm-8">
        <input id="lose"
               class="form-control"
               type="number"
               min="0"
               formControlName="lose"
               [ngClass]="{'red_alert': errors['obj.lose']}">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-4">
        <label for="single">Preis</label>
      </div>
      <div class="col-sm-8">
        <input id="single"
               class="form-control"
               type="number"
               formControlName="single"
               step="0.001"
               [ngClass]="{'red_alert': errors['obj.single']}">
      </div>
    </div>

    <div class="row form-group" *ngIf="frame.typ === 'cn'">
      <div class="col-sm-4">
        <label for="cost">Einkauf $</label>
      </div>
      <div class="col-sm-8">
        <input id="cost"
               class="form-control"
               type="number"
               formControlName="cost"
               step="0.001"
               [ngClass]="{'red_alert': errors['obj.cost']}">
      </div>
    </div>

    <div class="row form-group" *ngIf="frame.typ === 'cn'">
      <div class="col-sm-4">
        <label for="producer">Produzent</label>
      </div>
      <div class="col-sm-8">
        <select id="producer" class="form-control" formControlName="producer">
          <option [ngValue]="null">-</option>
          <option [ngValue]="producer.name" *ngFor="let producer of producers">{{producer.name}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-danger pull-left" type="button" (click)="dismiss()">
      Abbrechen
    </button>

    <button class="btn btn-success pull-right" type="submit">
      Speichern
    </button>
  </div>
</form>
