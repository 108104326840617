import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../model/customer';
import {Country} from '../model/country';
import {FieldService} from '../model/field-service';
import {CalculationProcess, PriceIncrease} from '../../price/price.models';
import {UserInfo} from '../../user/user.models';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CustomerService} from '../services/customer.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {Term} from '../model/term.model';
import {Address} from '../model/address';
import {ErpProducer} from '../../producer/producer.models';
import {IndustrialSector} from '../../industrial-sector/producer.models';

@Component({
  selector: 'customer-detail',
  templateUrl: './customer-detail.component.html'
})
export class CustomerDetailComponent implements OnInit {
  /** input data */
  @Input() terms: Term[];
  @Input() data: {
    customer: Customer,
    country: { [key: string]: Country },
    producers: ErpProducer[],
    fieldServices: FieldService[],
    increase: { de?: PriceIncrease[], cn?: PriceIncrease[] },
    fieldServiceUser?: UserInfo,
    industrialSectors: IndustrialSector[],
  };
  @Input() processes: { de: CalculationProcess[], cn: CalculationProcess[] };
  customer: Customer;
  countries: { [key: string]: Country };
  producers: ErpProducer[];
  fieldServices: FieldService[];
  increase: { de?: PriceIncrease[], cn?: PriceIncrease[] };
  fieldServiceUser?: UserInfo;
  industrialSectors: IndustrialSector[];
  /** custom variables */
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};
  nameError = false;
  duplicated = false;
  nameChangeEnabled = false;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.customer = this.data.customer;
    this.countries = this.data.country;
    this.producers = this.data.producers;
    this.fieldServices = this.data.fieldServices;
    this.increase = this.data.increase;
    this.fieldServiceUser = this.data.fieldServiceUser;
    this.industrialSectors = this.data.industrialSectors;

    this.form = this.fb.group({
      name: new UntypedFormControl({value: this.customer.name, disabled: true})
    });
  }

  changeName(): void {
    this.duplicated = false;
    this.nameError = false;
    this.customerService.updateName(this.customer.id, this.form.value.name).subscribe(value => {
      this.nameChangeEnabled = false;
      this.customer.name = value.name;
      (this.form.controls.name as UntypedFormControl).disable();
      this.alertService.add('success', 'Kundenname erfolgreich geändert!');
    }, response => {
      if (response.error) {
        if (response.error.error === 'duplicated') {
          this.duplicated = true;
        }
      }
      this.nameError = true;
      this.alertService.add('danger', 'Kundenname konnte nicht geändert werden!');
    });
  }

  enableNameChange(): void {
    this.nameChangeEnabled = true;
    (this.form.controls.name as UntypedFormControl).enable();
  }

  setRating(rating: number): void {
    this.customer.rating = rating;
  }

  updateInvoiceAddress(address: Address): void {
    this.customer.invoice = address;
  }

  toggleLock(lock: null | string): void {
    this.customer.locked = lock;
  }

  setDeleted(lock: null | string): void {
    this.customer.date_deleted = lock;
  }

  flagUrl(): string {
    const country = this.countries[this.customer.invoice.country].iso2.toLowerCase();
    return '/ui/assets/images/flags/' + country + '.png';
  }

}
