import {
  AfterViewInit,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Component,
} from '@angular/core';
import React from 'react';
import {createRoot} from 'react-dom/client';

@Component({
  template: '',
})
export abstract class ReactBaseComponent implements OnChanges, OnDestroy, AfterViewInit {
  protected containerRef: ElementRef;

  protected constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit(): void {
    this.render();
  }

  private root: any; // there are no typings 😭
  protected abstract renderComponent(): JSX.Element;

  protected render() {
    const self = this;
    if (self.containerRef === undefined) {
      return;
    }

    const _ = React.version;
    if (!self.root) {
      self.root = createRoot(self.containerRef.nativeElement);
    }

    const component = self.renderComponent();
    self.root.render(<>{component}</>);
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.unmount();
    }
  }
}
