<h1 class="page-header" *ngIf="!settingsMode">{{ headerLabel }}</h1>

<h2 *ngIf="settingsMode">{{ headerLabel }}</h2>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row">

    <div class="col-sm-6">

      <customer-box [ngClass]="{'red_alert': errors[errorName + '.customer']}"
                    formControlName="customer"
                    [fullDisabled]="false"
                    [writeBackValue]="true"
                    [clear]="false">
      </customer-box>

    </div>

    <div class="col-sm-6">

      <label-article-box formControlName="article"
                         (updateEmitter)="updateArticle($event)"
                         [ngClass]="{'red_alert': errors[errorName + '.article']}">
      </label-article-box>

    </div>

  </div>

  <div class="row">
    <div class="col-sm-12">&nbsp;</div>
  </div>


  <div class="form form-horizontal">
    <div class="row">

      <div class="col-sm-4">
        <div class="form-group">
          <label for="inputBaNr" class="col-sm-2 control-label">BA-Nr.</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="inputBaNr" formControlName="ba_nr"/>
          </div>
        </div>

        <div class="form-group">
          <label for="inputInventory" class="col-sm-2 control-label">Lager</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="inputInventory"
                   formControlName="inventory"
                   [ngClass]="{red_alert: errors[errorName + '.inventory']}"/>
          </div>
        </div>

        <div class="form-group">
          <label for="inputQuantityTotal" class="col-sm-2 control-label">Stck. Gesamt</label>
          <div class="col-sm-10">
            <integer-input formControlName="quantity_total" id="inputQuantityTotal" class="form-control"
                           [initError]="true"
                           [evClass]="{red_alert: errors[errorName + '.quantity_total']}">
            </integer-input>
          </div>
        </div>

      </div>

      <div class="col-sm-4">

        <div class="form-group">
          <label for="inputBestNr" class="col-sm-2 control-label">Best-Nr.</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="inputBestNr" formControlName="best_nr"/>
          </div>
        </div>

        <div class="form-group">
          <label for="inputETest" class="col-sm-2 control-label">E-Test</label>
          <div class="col-sm-10">
            <select class="form-control" id="inputETest"
                    formControlName="e_test"
                    [ngClass]="{red_alert: errors[errorName + '.e_test']}">
              <option value="-">-</option>
              <option value="Ja">Ja</option>
              <option value="Nein">Nein</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label for="inputQuantityVE" class="col-sm-2 control-label">Stck. VE</label>
          <div class="col-sm-10">
            <integer-input formControlName="quantity_ve" id="inputQuantityVE" class="form-control"
                           [initError]="true"
                           [evClass]="{red_alert: errors[errorName + '.quantity_ve']}">
            </integer-input>
          </div>
        </div>

      </div>

      <div class="col-sm-4">
        <div class="form-group">
          <label for="inputProdDatum" class="col-sm-2 control-label">Prod-Datum</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="inputProdDatum"
                   formControlName="prod_date"
                   [ngClass]="{red_alert: errors[errorName + '.prod_date']}"/>
          </div>
        </div>

        <div class="form-group">
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>

        <div class="form-group">
          <label for="inputQuantityUtilize" class="col-sm-2 control-label">Anzahl Nutzen</label>
          <div class="col-sm-10">
            <integer-input formControlName="quantity_utilize" id="inputQuantityUtilize" class="form-control"
                           [initError]="true"
                           [evClass]="{red_alert: errors[errorName + '.quantity_utilize']}">
            </integer-input>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row" *ngIf="errors[errorName + '.objects']">
    <div class="col-sm-12">
      Bitte erstellen Sie mindestens eine Zeile in der Tabelle
    </div>
  </div>

  <div class="row">
    <div class="col-sm-3">
      <button type="button" class="btn btn-default" [disabled]="!canCreateTable()" (click)="createTable()">
        Etiketten Liste erstellen
      </button>
    </div>

    <div class="col-sm-2">
      <button type="button" class="btn btn-default" (click)="addColumn()" [disabled]="submitting">
        Zeile hinzufügen
      </button>
    </div>

    <div class="col-sm-3">
      <button type="button" class="btn btn-default" (click)="deleteAllRows()" [disabled]="submitting">
        Etiketten Liste löschen
      </button>
    </div>

    <div class="col-sm-2">
      <button type="submit" class="btn btn-success" [disabled]="!form.valid || submitting">
        {{ buttonLabel }}
        <ng-container *ngIf="submitting">
          <i class="fa fa-circle-o-notch fa-spin"></i>
        </ng-container>
      </button>
    </div>
    <div class="col-sm-2">
      <button type="button" class="btn btn-warning" (click)="clearForm()" [disabled]="submitting || form.pristine">
        Form zurücksetzen
      </button>
    </div>
  </div>

  <div class="row" *ngIf="templateError">
    <div class="col-sm-12">&nbsp;</div>
  </div>

  <div class="row" *ngIf="templateError">
    <div class="col-sm-12">
      <div class="alert alert-danger">
        Das eingegeben Template ist fehlerhaft oder existiert nicht, bitte bessern Sie das Template aus und versuchen
        Sie es erneut!
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">&nbsp;</div>
  </div>

  <div class="form form-inline label-table">
    <div class="row">
      <div class="col-md-12">

        <table class="table">
          <thead>
          <tr>
            <th>Nr.</th>
            <th>Stck / VE</th>
            <th>Nutzen mit schlecht LP</th>
            <th>Schlecht LP</th>
            <th>Lagerplatz</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody formArrayName="objects">
          <tr *ngFor="let row of objects.controls; let index = index" [formGroupName]="'' + index">
            <td class="chrissies">
              <input type="text" class="form-control width-35" [value]="(index + 1).toString()" [readOnly]="true"/>
            </td>
            <td>
              <integer-input formControlName="quantity" class="form-control"
                             [initError]="true"
                             [evClass]="{red_alert: errors[errorName + '.objects[' + index + '].quantity']}">
              </integer-input>
            </td>
            <td>
              <integer-input formControlName="utilize" class="form-control"
                             [initError]="true"
                             [evClass]="{red_alert: errors[errorName + '.objects[' + index + '].utilize']}">
              </integer-input>
            </td>
            <td>
              <integer-input formControlName="schlechte" class="form-control"
                             [initError]="true"
                             [evClass]="{red_alert: errors[errorName + '.objects[' + index + '].schlechte']}">
              </integer-input>
            </td>
            <td>
              <input type="text" class="form-control" [attr.id]="'input-' + index + '-inventory'"
                     formControlName="inventory"
                     [ngClass]="{red_alert: errors[errorName + '.objects[' + index + '].inventory']}"/>
            </td>
            <td>
              <button type="button" class="btn btn-link" (click)="deleteColumn(index)" [disabled]="submitting">
                <i class="fa fa-trash-o"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>

</form>

