<div class="row">
  <div class="col-md-12">
    <h1>Kunden Artikel sperren</h1>
  </div>
</div>

<ng-container *ngIf="success">
  <div class="row lock-md1">
    <div class="col-md-12 alert alert-success">
      <h2>Kunden Artikel erfolgreich gesperrt!</h2>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="sending">

  <div class="row">
    <div class="col-md-12">
      <h2>Artikel wird gesperrt, bitte warten...</h2>
    </div>
    <div class="col-md-12">
      <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="!sending">
  <div class="row lock-md1">
    <div class="col-md-12">
      <p>Mit dieser Funktion können alle Artikel eines Kunden gesperrt werden. (Ausnahme: Bereits gelöschte Artikel)</p>
      <ul>
        <li>1. Kunde auswählen</li>
        <li>2. Sperrgrund angeben (Wird bei allen Artikeln hinterlegt)</li>
        <li>3. Mit Button "Ausführen" werden alle Artikel des Kundens mit dem Sperrgrund gesperrt.</li>
      </ul>

      <p><strong>Dieser Vorgang kann nicht rückgängig gemacht werden.</strong></p>

    </div>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="form" class="form-horizontal">

    <customer-box [clear]="false"
                  [disabledHeader]="true"
                  [realValue]="true"
                  [writeBackValue]="false"
                  formControlName="customer">
      <h2>Kunden Auswahl</h2>
    </customer-box>


    <h2 class="lock-md1">Sperrgrund</h2>

    <div class="form-group">
      <label class="col-md-2 control-label" for="reason">Grund:</label>

      <div class="col-md-10">
        <select class="form-control" formControlName="reason" id="reason">
          <option *ngFor="let notice of notices" [value]="notice">{{ notice }}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-2 control-label" for="reference">Referenz</label>

      <div class="col-md-10">
      <textarea [ngClass]="{red_alert: errors['reference']}" class="form-control" formControlName="reference" id="reference"
                rows="10">
      </textarea>
      </div>
    </div>

    <button [disabled]="!form.valid || sending" class="btn btn-success" type="submit">
      Ausführen
    </button>

  </form>
</ng-container>
