import {Component, ErrorHandler, Input} from '@angular/core';
import {
  MaterialGroupListElement,
  MaterialInventory,
  MaterialUnit,
  MaterialValueType
} from '../material-management.model';
import {StringHelper} from '../../../common/string-helper';
import {Lister} from '../../../common/wrapper.models';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {MaterialInventoryListService} from './material-inventory-list.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Money} from '../../../common/money/Money';
import {StateService} from '@uirouter/core';
import {MaterialSupplier} from '../../../lazy/material-supplier/material-supplier.model';
import {MaterialVariationEditModalService} from './material-variation-edit-modal/material-variation-edit-modal.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'material-inventory-list',
  templateUrl: './material-inventory-list.component.html'
})
export class MaterialInventoryListComponent extends SearchBaseComponent<MaterialInventoryListService, MaterialInventory> {
  @Input() protected listData: Lister<MaterialInventory>;
  @Input() currentGroup: MaterialGroupListElement;
  @Input() fields: MaterialValueType[];

  @Input() suppliers: MaterialSupplier[];

  @Input() units: MaterialUnit[];
  protected type = 'material-inventory-list';

  public filterFormErrors: {[key: string]: any} = {};
  protected handles400Errors = true;

  stringHelper = StringHelper;

  constructor(protected service: MaterialInventoryListService,
              protected errorHandler: ErrorHandler,
              protected stateService: StateService,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private materialVariationEditModalService: MaterialVariationEditModalService) {
    super();
  }

  serviceCall(query: string): void {
    // cancel a ongoing request
    if (this.lastRequest) {
      this.lastRequest.unsubscribe();
    }

    // remove the 'fields' query string for the server query
    delete query['fields'];

    this.lastRequest = this.service.list(this.currentGroup.id, query).subscribe((data) => {
      query['fields'] = this.reformatFieldQueries(query);
      this.apply(query, data);
    });
  }

  sanitizeLocation(query?: any): void {
    query = query ?? this.query();
    query.fields?.toString().split('_field_').forEach(kv => {
      const s = kv.split('=');
      query[`field_${s[0]}`] = decodeURIComponent(s[1]);
    });
    super.sanitizeLocation(query);
  }

  triStateChange(field: MaterialValueType): void {
    if (this.data[field.html_name] === undefined || this.data[field.html_name] === null) {
      this.data[field.html_name] = 'true';
    } else if (this.data[field.html_name] === 'true') {
      this.data[field.html_name] = 'false';
    } else if (this.data[field.html_name] === 'false') {
      this.data[field.html_name] = null;
    }
    this.search();
  }

  triStateValue(field: MaterialValueType) {
    return {
      'gray_font': this.data[field.html_name] === undefined || this.data[field.html_name] === null,
      'fa-check': !this.data[field.html_name] || this.data[field.html_name] === 'true',
      'fa-times': this.data[field.html_name] === 'false'
    };
  }

  getOptionFaClass(field?: boolean | number | string) {
    return {
      'fa-check': field === true,
      'fa-times': field === false,
    };
  }

  getFormattedNumber(field: boolean | number | string): string {
    if (typeof field === 'number') {
      return Money.stringify(field);
    } else {
      return '';
    }
  }

  isFieldEmpty(field?: any): boolean {
    return field === undefined || field === null;
  }

  inventoryBadge(badge_id: string): void {
    const query = (badge_id === 'all' ? {} : this.query());
    delete query.page;
    switch (badge_id) {
      case 'confirmed':
        query.status = (query.status === 0) ? null : 0;
        break;
      case 'ordered':
        query.status = (query.status === 1) ? null : 1;
        break;
      case 'low_stock':
        query.status = (query.status === 2) ? null : 2;
        break;
      case 'passed_deadline':
        query.passed_deadline = !query.passed_deadline;
        break;
    }

    this.serviceCall(query);
  }

  get iQ(): { status: number, passed_deadline: boolean } {
    return this.query();
  }

  /**
   * Flatten the field query strings to a single string with the syntax fields=_field_<i>=<value>_field_<i2>=<value>...
   */
  reformatFieldQueries(query: any): string {
    let fieldQuery = '';
    this.fields.forEach(field => {
      if (query[field.html_name]) {
        fieldQuery += `_${field.html_name}=${encodeURIComponent(query[field.html_name])}`;
      }
    });
    return fieldQuery;
  }

  linkDetails(variation_id: number): void {
    const query = this.query();
    query.group = this.currentGroup.id;
    query.fields = this.reformatFieldQueries(query);
    if (query.fields === '') {
      delete query['fields'];
    }

    // Drop all field_<i> key value pairs
    for (const key in Object.keys(query)) {
      if (key.startsWith('field_')) {
        delete query[key];
      }
    }

    this.stateService.go('a.material.inventory.update', {variation: variation_id, searchParams: query});
  }

  openEditModal(article: MaterialInventory): void {
    this.materialVariationEditModalService.open(article.id).subscribe(() => {
      this.reload();
    }, ignoreRejection);
  }

  download(withCharge: boolean): void {
    const params = EnvisiaLocation.httpParams({...this.query(), with_charge: withCharge}).toString();
    window.open(
      `${environment.apiv4uri}material/inventory/list/${this.currentGroup.id}/export?${params}`,
      '_blank');
  }
}
