import {
  MaterialArticle,
  MaterialArticleFormElement,
  MaterialValueType
} from '../material-management.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

export abstract class MaterialArticleFormBuilder {
  protected abstract fb: UntypedFormBuilder;

  protected buildArticleGroup(
    groupId: number,
    fieldDef: MaterialValueType[]
  ): UntypedFormGroup {
    const articleFields = this.buildFields(fieldDef);

    return this.fb.group({
      'id': [null, {options: {emitEvent: false}}],
      'group_id': [groupId, {options: {emitEvent: false}}],
      'name': [null, Validators.required],
      'name_order': [null],
      'supplier_id': [null, Validators.required],
      'article_fields': articleFields,
      'konsi': [false],
    });
  }

  protected buildArticleGroupWithOriginalArticle(
    originalArticle: MaterialArticle,
    fieldDef: MaterialValueType[]
  ): UntypedFormGroup {
    const articleFields = this.buildFields(fieldDef, originalArticle.article_fields, true);

    return this.fb.group({
      'id': [originalArticle.id, {options: {emitEvent: false}}],
      'group_id': [originalArticle.group_id, {options: {emitEvent: false}}],
      'name': [originalArticle.name, Validators.required],
      'name_order': [originalArticle.name_order],
      'supplier_id': [originalArticle.supplier_id, Validators.required],
      'article_fields': articleFields,
      'konsi': [originalArticle.konsi],
    });
  }

  protected buildFields(
    fieldDef: MaterialValueType[],
    originalFields?: MaterialArticleFormElement[],
    readOnly?: boolean
  ): UntypedFormGroup {
    const formGroup = {};
    fieldDef.forEach((field) => {
      formGroup[field.html_name] = field.buildFormGroup(this.fb, originalFields, readOnly);
    });
    return this.fb.group(formGroup);
  }
}
