import {ArticleService} from '../../article/services/article.service';
import {Transition, ResolveTypes} from '@uirouter/angular';
import {ArticleV2Service} from '../../article/services/article2.service';
import {CommentService} from '../../comment/comment.service';
import {InventoryService} from '../../article/inventory/inventory.service';
import {ProcessService} from '../services/process.service';
import {PriceService} from '../services/price.service';
import {CustomerService} from '../../customer/services/customer.service';
import {CustomerIncreaseService} from '../../customer/services/customer-increase.service';
import {forkJoin, of, firstValueFrom, Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {map} from 'rxjs/operators';
import {encode} from '../../../common/helper';
import {Customer} from '../../customer/model/customer';
import {PriceIncrease} from '../price.models';
import {NumberHelper} from '../../../helper/number-helper';

export function defaultCalculationResolveFn(trans: Transition, service: ArticleService) {
  return firstValueFrom(service.calculation(trans.params().id));
}

export function toolingCostResolveFn(trans: Transition, service: ArticleV2Service) {
  return firstValueFrom(service.toolingCost(trans.params().id));
}

export function priceDataResolveFn(trans: Transition,
                                   service: PriceService,
                                   customerService: CustomerService,
                                   increaseService: CustomerIncreaseService) {
  const article = trans.params().id;

  return service.detail(article).pipe(
    mergeMap(value => {
      let workflowCustomerObs: Observable<null> | Observable<Customer> = of(null);
      let articleCustomerObs: Observable<null> | Observable<Customer> = of(null);
      let lastIncreaseDeObs: Observable<null> | Observable<PriceIncrease> = of(null);
      const encodedCustomer = encode(value.article.customer);
      if (value.article.customer) {
        articleCustomerObs = customerService.byName(value.article.customer);
        lastIncreaseDeObs = increaseService.last(encodedCustomer, 'de');
      }

      if (!!value.last_workflow) {
        workflowCustomerObs = customerService.detail(value.last_workflow.customer_id);
      }

      return forkJoin([
        of(value),
        articleCustomerObs,
        lastIncreaseDeObs,
        workflowCustomerObs,
      ]).pipe(
        map(values => {
          return {
            price: values[0],
            article_customer: values[1],
            last_increase_de: values[2],
            workflow_customer: values[3]
          };
        })
      );
    })
  ).toPromise();
}

export function processesResolveFn(service: ProcessService) {
  return service.list().toPromise();
}

export function workflowIdResolveFn(trans: Transition): number {
  return NumberHelper.saveParseInteger(trans.params().workflow_id);
}

export function inventoryResolveFn(trans: Transition, service: InventoryService) {
  const workflowId = workflowIdResolveFn(trans);
  return service.inventorySum(trans.params().id, workflowId).toPromise();
}

export function commentsResolveFn(trans: Transition, service: CommentService) {
  return service.list('article', trans.params().id).toPromise();
}

export const detailResolve: ResolveTypes[] = [
  {token: 'defaultCalculation', resolveFn: defaultCalculationResolveFn, deps: [Transition, ArticleService]},
  {token: 'toolingCost', resolveFn: toolingCostResolveFn, deps: [Transition, ArticleV2Service]},
  {
    token: 'priceData',
    resolveFn: priceDataResolveFn,
    deps: [Transition, PriceService, CustomerService, CustomerIncreaseService]
  },
  {token: 'processes', resolveFn: processesResolveFn, deps: [ProcessService]},
  {token: 'inventory', resolveFn: inventoryResolveFn, deps: [Transition, InventoryService]},
  {token: 'comments', resolveFn: commentsResolveFn, deps: [Transition, CommentService]},
  {token: 'workflowId', resolveFn: workflowIdResolveFn, deps: [Transition]},
];
