import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ProductionTemplate,
  ProductionTemplateForm,
  ProductionTemplateStepEntity,
  StepEntity
} from '../production/production.models';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProductionTemplateService {

  private uri = '/api/v1/settings/production/template/';

  constructor(private http: HttpClient) {
  }

  list(): Observable<ProductionTemplate[]> {
    return this.http.get<EnvisiaObjects<ProductionTemplate>>(this.uri).pipe(map(value => value.objects));
  }

  create(data: ProductionTemplate): Observable<ProductionTemplate> {
    return this.http.post<EnvisiaObject<ProductionTemplate>>(this.uri, data).pipe(map(value => value.object));
  }

  edit(name: string, data: ProductionTemplateForm): Observable<ProductionTemplate> {
    return this.http.put<EnvisiaObject<ProductionTemplate>>(this.uri + name + '/', data).pipe(
      map(value => value.object)
    );
  }

  stepList(name: string): Observable<StepEntity[]> {
    return this.http.get<EnvisiaObjects<StepEntity>>(this.uri + name + '/').pipe(
      map(value => value.objects)
    );
  }

  stepAdd(name: string, step_id: number): Observable<ProductionTemplateStepEntity> {
    return this.http.post<EnvisiaObject<ProductionTemplateStepEntity>>(
      this.uri + 'step/' + name + '/',
      {step_id: step_id}
    ).pipe(map(value => value.object));
  }

  stepEdit(id: number, expression: string): Observable<StepEntity> {
    return this.http.put<EnvisiaObject<StepEntity>>(this.uri + 'step/' + id + '/', {expression: expression}).pipe(
      map(value => value.object)
    );
  }

  stepUp(id: number): Observable<void> {
    return this.http.put<void>(this.uri + 'step/up/' + id + '/', {});
  }

  stepDown(id: number): Observable<void> {
    return this.http.put<void>(this.uri + 'step/down/' + id + '/', {});
  }

  stepDelete(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + 'step/' + id + '/');
  }

}
