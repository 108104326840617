/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {InitData} from './common/init-data/init-data';

@Injectable({providedIn: 'root'})
export class AppRunService {

  constructor(private http: HttpClient) {
  }

  init(): Observable<InitData> {
    return this.http.get<InitData>('/initialize');
  }

}
