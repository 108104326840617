import {NgModule} from '@angular/core';
import {ContactBoxComponent} from './contact-box.component';
import {SharedModule} from '../../../core/shared.module';
import {NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {UIRouterModule} from '@uirouter/angular';
import {ContactBoxModalComponent} from './contact-box-modal.component';
import {LoadingOrErrorModule} from '../../../common/loading/loading-or-error.module';

@NgModule({
    imports: [
        SharedModule,
        NgbModalModule,
        UIRouterModule.forChild(),
        NgbTooltipModule,
        LoadingOrErrorModule,
    ],
    declarations: [
        ContactBoxComponent,
        ContactBoxModalComponent,
    ],
    exports: [
        ContactBoxComponent
    ]
})
export class ContactBoxModule {
}
