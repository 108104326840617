import {Component, Input} from '@angular/core';
import {ArticleSpecification} from '../../../envisia/article/models/article-specification.model';
import {Comment} from '../../../envisia/comment/comment.models';
import {ArticleSpecificationService} from '../../../envisia/article/services/article-specification.service';
import {CommentService} from '../../../envisia/comment/comment.service';
import {RightService} from '../../../common/right-service/right.service';
import {ArticleConfigFieldNamesEditModalService} from './edit-modal/article-config-field-names-edit-modal.service';

@Component({
  selector: 'article-config-field-names',
  templateUrl: './article-config-field-names.component.html'
})
export class ArticleConfigFieldNamesComponent {

  @Input() specs: ArticleSpecification[];
  comments: Comment[];

  constructor(private service: ArticleSpecificationService,
              private commentService: CommentService,
              private rightService: RightService,
              private modalService: ArticleConfigFieldNamesEditModalService) {
  }

  open(spec: ArticleSpecification): void {
    this.modalService.open(spec).subscribe(updatedSpec => {
      spec.name_de = updatedSpec.name_de;
      spec.name_en = updatedSpec.name_en;
    });
  }
}
