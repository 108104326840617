/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, OnInit} from '@angular/core';
import {Workflow} from '../models/workflow.models';
import {Category} from '../../finder/finder.models';

@Component({
  selector: 'workflow-document-new',
  templateUrl: './workflow-document-new.component.html'
})
export class WorkflowDocumentNewComponent implements OnInit {
  @Input() workflow: Workflow;
  @Input() category: Category[];
  initial: { workflow_id: number };

  ngOnInit(): void {
    this.initial = {workflow_id: this.workflow.id};
  }

}
