import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BusinessOrderUtilizeCalc} from '../models/business-order.models';
import {BusinessOrderProposalService} from '../services/business-order-proposal.service';

@Component({
  selector: 'business-order-proposal-table',
  templateUrl: './business-order-proposal-table.component.html',
  styles: []
})
export class BusinessOrderProposalTableComponent implements OnInit, OnChanges {
  @Input() workflowId: number;
  @Input() quantity?: number;
  @Input() hasExpress: boolean;
  @Input() amountPerUtilize: number;

  proposal: BusinessOrderUtilizeCalc;

  proposalSuccess = true;

  constructor(private proposalService: BusinessOrderProposalService) {
  }

  ngOnInit(): void {
    this.proposal = {
      ordered_quantity: 0,
      amount_per_utilize: this.amountPerUtilize,
      over_delivery: 0,
      over_delivery_string: '',
      over_production: 0,
      over_production_string: '',
      total_delivery_quantity: 0,
      total_quantity: 0,
      total_utilized: 0,
      inventory_sum: 0,
      no_ba_needed: false,
      kanban: false
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.quantity) || (changes.hasExpress && !changes.hasExpress.firstChange)) {
      this.updateProposal();
    }
  }

  updateProposal(): void {
    if (this.quantity > 0) {
      this.proposalSuccess = false;
      this.proposalService.calculate(this.workflowId, this.quantity, this.hasExpress).subscribe(proposal => {
        this.proposalSuccess = true;
        this.proposal = proposal;
      });
    } else {
      this.ngOnInit();
    }
  }
}
