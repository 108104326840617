<div class="envisiasite">

  <div class="errorsite">
    <div class="error-img">
      <i class="fa fa-ban text-danger"></i>
    </div>
    <div class="error-status">
      <h2>404</h2>
      <h1>Seite nicht gefunden!</h1>
    </div>
    <div class="error-message">
      Die angefordete Seite konnte nicht gefunden werden!
    </div>
    <div class="error-link">
      <button class="btn btn-default btn-lg" (click)="back()">
        <i class="fa fa-arrow-left"></i>Zurück
      </button>
      <button class="btn btn-success btn-lg" (click)="logout()">
        Abmelden
      </button>
      <button class="btn btn-success btn-lg" (click)="home()">
        Startseite
      </button>
    </div>
  </div>
  <div class="footer"><a href="http://www.envisia.de" target="_blank">www.envisia.de</a></div>

</div>
