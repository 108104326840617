import {useContext} from 'react';
import {ErpRouterContext} from '../components/react-router/erp-react-router-context';
import {RawParams, StateOrName} from '@uirouter/angular';
import {TransitionOptions} from '@uirouter/core';

export function useErpLocation(): [(to: StateOrName, params?: RawParams, options?: TransitionOptions) => void] {
  const routerContext = useContext(ErpRouterContext);

  function navigate(to: StateOrName, params?: RawParams, options?: TransitionOptions): void {
    if (routerContext === undefined || routerContext === null || routerContext.routeAngular === undefined) {
      return;
    }

    routerContext.routeAngular(to, params, options);
  }

  return [navigate];
}
