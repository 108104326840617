import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  MaterialOrder,
  MaterialOrderArticleListGroup,
  MaterialOrderCreateForm,
} from '../material-order.model';
import {environment} from '../../../../../environments/environment';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {Lister} from '../../../../common/wrapper.models';
import {ListService} from '../../../../common/search/search.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialOrderCreateService implements ListService<MaterialOrderArticleListGroup> {
  private uri = environment.apiv4uri + 'material/order/';

  constructor(protected http: HttpClient) {
  }

  list(form: any): Observable<Lister<MaterialOrderArticleListGroup>> {
    return this.http.get<Lister<MaterialOrderArticleListGroup>>(
      this.uri + 'create/list', {params: EnvisiaLocation.httpParams(form)}
    );
  }

  create(form: MaterialOrderCreateForm): Observable<MaterialOrder> {
    return this.http.post<MaterialOrder>(this.uri, form);
  }

}
