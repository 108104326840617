<div class="article-form-item" [formGroup]="form">
  <label class="article-form-item-label">{{ label }}:</label>

  <div class="article-form-item-data">

    <div class="article-form-data-content">
      <label [htmlFor]="'article-model-' + model1">A</label>
    </div>
    <div class="article-form-data-content" style="margin-right: 30px;">
      <input type="checkbox"
             [id]="'article-model-' + model1"
             [formControlName]="model1"
             [ngClass]="{red_alert: error1}"
             (change)="change(model1)">
    </div>

    <div class="article-form-data-content">
      <label [htmlFor]="'article-model-' + model2">B</label>
    </div>
    <div class="article-form-data-content">
      <input type="checkbox"
             [id]="'article-model-' + model2"
             [formControlName]="model2"
             [ngClass]="{red_alert: error2}"
             (change)="change(model2)">
    </div>
  </div>
</div>
