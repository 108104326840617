<div class="modal-header">
  <h3 class="modal-title">
    Übersicht - Bestellung {{materialOrder.id}}
  </h3>
</div>

<div class="modal-body row">
  <div class="col-lg-9">
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="'material.order.create'|HasPerm">
      <div class="row">
        <div class="col-sm-2">
          <label for="delivery_id">Lieferanten AB Nr.</label>
        </div>
        <div class="col-sm-4">
          <input type="text" class="form-control" id="delivery_id" formControlName="delivery_id">
        </div>
        <div class="col-sm-6">
          <button type="submit" class="btn btn-success">Speichern</button>
        </div>
      </div>
      <div class="row"><div class="col-sm-12">&nbsp;</div></div>
    </form>

    <div class="row" *ngIf="!('material.order.create'|HasPerm)">
      <div class="col-sm-2">Lieferanten AB Nr.</div>
      <div class="col-sm-2">{{materialOrder.delivery_id}}</div>
    </div>

    <div class="row">
      <div class="col-md-12">&nbsp;</div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h3>Bestell Positionen</h3>
      </div>
    </div>

    <form #changeForm="ngForm" name="form"></form>

    <material-order-update-group [group]="mig"
                                 [groups]="groups"
                                 [order]="materialOrder"
                                 [markedPositionId]="markedPositionId"
                                 (updateEvent)="updateComments()"
                                 (copyEvent)="copyEvent()"
                                 *ngFor="let mig of list.objects; trackBy: trackBy">
    </material-order-update-group>
    <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
  </div>

  <div class="col-md-3">
    <div *ngIf="commentLoader.data$ | async as comments; else loadingOrError.template">
      <comment-2 typ="material_order" placement="bottom" [list]="comments" [item]="materialOrder.id.toString()">
      </comment-2>
    </div>
    <loading-or-error #loadingOrError
                      [loadingWrapper]="commentLoader"
                      [errorMessage]="'Konnte Kommentare nicht laden'"
                      [spinnerSize]="2"
                      spinnerName="circle-o-notch">
    </loading-or-error>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default pull-right" (click)="dismiss()">Schließen</button>
</div>
