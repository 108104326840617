<div class="modal-header">
  <div class="modal-title">
    <h3>Produzent auswählen</h3>
  </div>
</div>

<div class="modal-body">

  <div class="whitelist">
    <div class="whitelist-item pointer" (click)="select(null)" [ngClass]="{'selected': selected === null}">
      <h4>Kein Produzent</h4>
    </div>
    <div *ngFor="let producer of producers"
         class="whitelist-item pointer"
         [ngClass]="{'selected': selected === producer.name}"
         (click)="select(producer)">
      <h4>{{ producer.name }}</h4>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-default pull-left" (click)="close()">
    Abbrechen
  </button>

  <button class="btn btn-success pull-right" (click)="save()">
    Übernehmen
  </button>
</div>
