import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {FetchChangeService} from '../services/fetch-change.service';

@Component({
  selector: 'fetch-status-change-modal',
  templateUrl: './fetch-status-change-modal.component.html',
})
export class FetchStatusChangeModalComponent implements OnInit {
  @Input() id: number;
  @Input() wid: number;
  @Input() ordering: number;
  name: string;
  success: string;
  lowercaseName: string;

  constructor(private modalRef: NgbActiveModal,
              private fetchService: FetchChangeService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.success = 'Status geändert';
    this.name = 'Statusänderung';
  }

  closeModal(): void {
    this.modalRef.dismiss(null);
  }

  save(status: string): void {
    let id: number;
    if (status === 'approved') {
      id = 1;
    } else if (status === 'production') {
      id = 2;
    } else if (status === 'produced') {
      id = 3;
    }
    if (status && id >= 1 && id <= 3) {
      this.fetchService.updateStatus(this.id, id, this.wid, this.ordering).subscribe(() => {
        this.alertService.add('success',  this.success + '!');
        this.modalRef.close(id);
      }, () => {
        this.alertService.add('danger', 'Beim Statusändern ist ein Fehler aufgetreten!');
      });
    }
  }

}
