/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {HolidayManageComponent} from './holiday-manage.component';
import {HolidayCreateComponent} from './holiday-create.component';
import {HOLIDAY_STATES} from './holiday.states';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: HOLIDAY_STATES }),
        NgbTooltipModule,
        SharedModule,
        InfiniteScrollModule,
    ],
    declarations: [HolidayManageComponent, HolidayCreateComponent]
})
export class HolidayModule {
}
