import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';
import {from} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowPriceIncreaseFlexUpdateModalComponent} from './workflow-price-increase-flex-update-modal.component';
import {PriceIncreaseFlexService} from '../../../price-increase-flex/price-increase-flex.service';

@Component({
  selector: 'workflow-price-increase-flex-view',
  templateUrl: './workflow-price-increase-flex-view.component.html',
})
export class WorkflowPriceIncreaseFlexViewComponent {
  @Input() flexPriceIncrease: PriceIncreaseFlex;
  @Output() update: EventEmitter<PriceIncreaseFlex | null> = new EventEmitter<PriceIncreaseFlex | null>();

  constructor(private service: PriceIncreaseFlexService,
              private modalService: NgbModal) {
  }

  openUpdateModal(): void {
    const modalRef = this.modalService.open(WorkflowPriceIncreaseFlexUpdateModalComponent, {windowClass: 'modal2-sm'});
    modalRef.componentInstance.flexPriceIncrease = this.flexPriceIncrease;

    from(modalRef.result).subscribe(flexPriceIncrease => {
      this.update.next(flexPriceIncrease);
    });
  }

  delete(): void {
    if (confirm('Flexiblen Zuschlag wirklich entfernen?')) {
      this.service.delete(this.flexPriceIncrease.workflow_id).subscribe(() => {
        this.update.next(null);
      });
    }
  }
}
