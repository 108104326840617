import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowPositionView} from '../models/position.models';
import {CreateTaskNoHistoryModalComponent} from './create-task-no-history-modal.component';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {PriceHistory} from '../../price/price.models';
import {Workflow} from '../models/workflow.models';
import {CreateTaskHistoryModalComponent} from './create-task-history-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CreateTaskModalService {

  constructor(private modalService: NgbModal) {
  }

  openNoHistory(position: WorkflowPositionView, typ: 'de' | 'cn'): void {
    const modalRef = this.modalService.open(CreateTaskNoHistoryModalComponent, {windowClass: 'modal2-mg'});
    modalRef.componentInstance.position = position;
    modalRef.componentInstance.typ = typ;
    from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
  }

  openWithHistory(workflow: Workflow, position: WorkflowPositionView, typ: 'de' | 'cn', history: PriceHistory): void {
    const modalRef = this.modalService.open(CreateTaskHistoryModalComponent, {windowClass: 'modal2-mg'});
    modalRef.componentInstance.workflow = workflow;
    modalRef.componentInstance.history = history;
    modalRef.componentInstance.position = position;
    modalRef.componentInstance.typ = typ;
    from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
  }

}
