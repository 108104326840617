<table class="table whitetable business-order-view-table business-order-create"
       *ngIf="loadingWrapper.data$|async as materialList; else loadingOrError.template">
  <thead>
  <tr>
    <th colspan="4">Auf BA gebuchtes Material</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><strong>Fertigungsschritt</strong></td>
    <td><strong>Artikel</strong></td>
    <td><strong>Menge</strong></td>
    <td><strong>Spezifikation</strong></td>
  </tr>
  <tr *ngIf="materialList.length === 0">
    <td colspan="4">Es ist noch kein Material auf diesen BA gebucht</td>
  </tr>
  <tr *ngFor="let material of materialList">
    <td><strong>{{material.bo_step_nr}}</strong>&nbsp;{{material.bo_step_name}}</td>
    <td><strong>{{material.variation_id}}</strong>&nbsp;{{material.article_name}}</td>
    <td>
      <a uiSref="a.material.inventory.update" [uiParams]="{variation: material.id}">
        <strong>{{material.amount|money}}</strong>&nbsp;{{ material.charge ? '(' + material.charge + ')' : '' }}
      </a>
    </td>
    <td>
      <ng-container *ngFor="let fieldValue of material.article_fields">
        {{fieldValue.name}}: <strong>{{formatValue(fieldValue)}}</strong><br />
      </ng-container>
      <ng-container *ngFor="let fieldValue of material.variation_fields">
        {{fieldValue.name}}: <strong>{{formatValue(fieldValue)}}</strong><br />
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>

<loading-or-error
  #loadingOrError
  [loadingWrapper]="loadingWrapper"
  [errorMessage]="'Konnte auf BA gebuchtes Material nicht laden'">
</loading-or-error>
