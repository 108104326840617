import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../core/shared.module';
import {CommentModule} from '../../../comment/comment.module';
import {OrderReceivedModalComponent} from './order-received-modal.component';
import {OrderBoxModule} from '../box/order-box.module';

@NgModule({
    imports: [
        SharedModule,
        CommentModule,
        OrderBoxModule,
    ],
    declarations: [
        // Modals
        OrderReceivedModalComponent
    ]
})
export class OrderReceivedModalModule {
}
