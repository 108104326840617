import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MaterialProductGroupForm} from '../material-product-groups.models';

@Component({
  selector: 'material-product-groups-form',
  templateUrl: './material-product-groups-form.component.html',
})
export class MaterialProductGroupsFormComponent {
  @Input() form: FormGroup<MaterialProductGroupForm>;
  @Input() errors: {[key: string]: any} = {};
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
}
