import { NgModule } from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {FinderUploadComponent} from './finder-upload.component';
import { FinderUploadProgressComponent } from './progress/finder-upload-progress.component';
import { FinderUploadCategoryComponent } from './category/finder-upload-category.component';
import { FinderUploadAdditionalComponent } from './additional/finder-upload-additional.component';
import { FinderUploadFileComponent } from './file/finder-upload-file.component';
import { FileDropComponent } from './file-drop/file-drop.component';
import { FinderUploadSuccessComponent } from './success/finder-upload-success.component';



@NgModule({
  declarations: [
    FinderUploadComponent,
    FinderUploadProgressComponent,
    FinderUploadCategoryComponent,
    FinderUploadAdditionalComponent,
    FinderUploadFileComponent,
    FileDropComponent,
    FinderUploadSuccessComponent,
  ],
  exports: [
    FinderUploadComponent,
  ],
  imports: [
    SharedModule,
  ]
})
export class FinderUploadModule {
}
