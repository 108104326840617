import {Component, ErrorHandler, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {OposService} from '../services/opos.service';
import {OposGlobalListData} from '../opos.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Lister} from '../../../common/wrapper.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {GlobalService} from '../../../core/global.service';
import {OposHistoryHelperService} from '../history/opos-history-helper.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {noop} from '../../../helper/noop';
import {DunningLockModalService} from '../../dunning/lock-modal/dunning-lock-modal.service';

@Component({
  selector: 'opos-list',
  templateUrl: './opos-list.component.html',
  styles: ['.datepicker-width-100 { width: 100px; }']
})
export class OposListComponent extends SearchBaseComponent<OposService, OposGlobalListData> implements OnInit, OnDestroy {
  @Input() listData: Lister<OposGlobalListData>;
  protected type = 'opos';
  protected initalizeFields: string[] = ['invoice_dunning_level'];
  oposForm: UntypedFormGroup;
  oposFormSubscription?: Subscription;

  constructor(private fb: UntypedFormBuilder,
              protected errorHandler: ErrorHandler,
              private globalService: GlobalService,
              private alertService: AlertService,
              private oposHistoryHelperService: OposHistoryHelperService,
              private dunningLockModalService: DunningLockModalService,
              protected service: OposService,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.oposForm = this.fb.group({
      global_opos_date: [this.globalService.getOposDateRaw()]
    });
    this.oposFormSubscription = this.oposForm.valueChanges.subscribe(values => {
      this.globalService.setOposDate(values.global_opos_date);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.oposFormSubscription) {
      this.oposFormSubscription.unsubscribe();
    }
  }

  trackByFn(index: number, item: OposGlobalListData): number {
    noop(this);
    return item.id;
  }

  history(obj: OposGlobalListData): void {
    this.oposHistoryHelperService.open(obj.id);
  }

  invoicesFilter(object: OposGlobalListData): {id: number, invoices?: string} {
    if (this.data.invoice_id) {
      return {id: object.invoice_customer_id, invoices: this.data.invoice_id};
    } else {
      return {id: object.invoice_customer_id};
    }
  }

  invoiceDunningLock(object: OposGlobalListData, index: number): void {
    this.dunningLockModalService.open(object.id, object.invoice_dunning_lock).then(response => {
      if (response === true || response === false) {
        this.list.objects[index].invoice_dunning_lock = response;
      }
    }).catch(() => {});
  }

}
