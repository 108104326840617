/*
 * Copyright (C) 2019 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {InvoiceSendService} from '../services/invoice-send.service';
import {HttpErrorResponse} from '@angular/common/http';
import {InvoiceState} from '../invoice.models';
import {CommonType, InitData} from '../../../../common/init-data/init-data';
import {LocalStorage} from '../../../../common/storage/local-storage';

@Component({
  selector: 'invoice-epost-send-modal',
  templateUrl: './invoice-epost-send-modal.component.html'
})
export class InvoiceEpostSendModalComponent {
  @Input() id: number;
  @Input() active: boolean;
  @Input() flipper: (state: InvoiceState) => void;
  lock = false;
  errorText: string | null = null;
  successVal: string | null = null;
  done = false;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private storageService: LocalStorage,
              private invoiceSendService: InvoiceSendService) {
  }

  send(): void {
    this.errorText = null;
    this.lock = true;
    this.invoiceSendService.send(this.id).subscribe(data => {
      this.successVal = data.reference;
      this.done = true;
      if (data.reference === 'post') {
        this.flipper(this.state(1));
      } else {
        this.flipper(this.state(2));
      }
    }, (response: HttpErrorResponse) => {
      this.doError(response.error);
    });
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  private doError(error: any | null) {
    this.lock = false;
    if (error !== null) {
      this.errorText = error.description;
    } else {
      this.errorText = 'Es ist ein Fehler aufgetreten - Bitte wenden Sie sich an Ihren Administrator!';
    }

    this.alertService.add('danger', 'Fehler, PDF konnte nicht versendet werden!');
  }


  private state(n: number): CommonType {
    // 1 means in_delivery
    // 2 means delivered
    return this.storageService.getObject<InitData>('data').invoice_status[n];
  }

}
