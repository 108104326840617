import {Component, Input, OnInit} from '@angular/core';
import {OposHistoryCorrection} from '../opos.models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OposHistoryService} from './opos-history.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'opos-history-correction-modal',
  templateUrl: './opos-history-correction-modal.component.html',
  styleUrls: ['./opos-history-modal.component.scss'],
})
export class OposHistoryCorrectionModalComponent implements OnInit {
  @Input() id: number;
  @Input() history: OposHistoryCorrection[];

  form: UntypedFormGroup;
  errors: {[key: string]: any} = {};
  constructor(private service: OposHistoryService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      note: [null, [Validators.required]],
    });
  }

  submit(): void {
    this.errors = {};
    this.service.createNoteCorrection(this.id, this.form.value).subscribe(oh => {
      this.history.unshift(oh);
      this.form.reset({note: null});
    }, (error: HttpErrorResponse) => {
      if (error.status === 400) {
        this.errors = error.error.errors;
      } else {
        this.alertService.add('danger', 'Konnte Kommentar nicht speichern');
      }
    });
  }
}
