import {Component, Input, OnInit} from '@angular/core';
import {BigFrame, DeliveredFrame, ExtendedFrame, FetchWithPositions, MergedFrame} from '../../models/frame.models';
import {Offer} from '../../models/offer.models';
import {ArticleCore} from '../../../article/models/article.models';
import {ShippingInstruction} from '../../../article/models/article-extra.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderFetchModalComponent} from './business-order-fetch-modal.component';
import {FetchService} from '../../workflow-services/fetch.service';
import {ProductionFetchMin, ProductionFetchView} from '../../models/fetch.models';
import {BusinessOrder, BusinessOrderWithReproduction} from '../models/business-order.models';
import {WorkflowAdditionalSimple} from '../../models/workflow.models';
import {forkJoin, from} from 'rxjs';
import {BusinessOrderService} from '../services/business-order.service';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {CommentService} from '../../../comment/comment.service';
import {Comment} from '../../../comment/comment.models';
import {ProductionArticleService} from '../../../article/production/production-article.service';
import {dateCheck} from './business-order-fetches.functions';
import {BusinessOrderProposalService} from '../services/business-order-proposal.service';
import {Money} from '../../../../common/money/Money';
import {InventoryService} from '../../../article/inventory/inventory.service';
import {
  ArticleWorkdayCalculationService
} from '../../../article/workday-calculation/article-workday-calculation.service';
import {ArticleWorkdayCalculation} from '../../../article/workday-calculation/article-workday-calculation.model';
import {ArticleInventorySum} from '../../models/inventory.models';
import {ArticleMultilayerPlan} from '../../../article/main/multilayer-plan/article-multilayer-plan.models';
import {CustomerCreditWorthiness} from '../../../customer/credit-worthiness/customer-credit-worthiness.models';

@Component({
  selector: 'business-order-create',
  templateUrl: './business-order-create.component.html'
})
export class BusinessOrderCreateComponent implements OnInit {
  @Input() workflow_id;

  @Input() fullData: {
    frame?: BigFrame,
    article: ArticleCore,
    inventory: ArticleInventorySum,
    extra: ShippingInstruction,
    business_order: BusinessOrderWithReproduction[],
    business_order_count: number,
    additional: WorkflowAdditionalSimple,
    sum: number,
    startNumber: number,
    validArticle: boolean;
    multilayerPlan?: ArticleMultilayerPlan;
    customerCredit?: CustomerCreditWorthiness;
  };

  frame?: ExtendedFrame;
  fetchesSmall: FetchWithPositions[];
  delivered?: DeliveredFrame;
  article: ArticleCore;
  inventory: ArticleInventorySum;
  businessOrderChanges = 0;
  startNumber: number;
  kanban: boolean;
  empb: boolean;
  fetches: ProductionFetchView[] = [];
  fetchesMin: ProductionFetchMin;
  quantity?: number = null;
  isCn: boolean;
  sum: number;
  plistEmpty: boolean;

  lastOffer?: Offer = null; // FIXME: try to get the last offer, if any

  comments: Comment[];

  reproductionSelected: { business_order: BusinessOrder, date?: ArticleWorkdayCalculation } | null = null;

  constructor(private ngbModal: NgbModal,
              private businessOrderService: BusinessOrderService,
              private fetchService: FetchService,
              private commentService: CommentService,
              private inventoryService: InventoryService,
              private articleProductionService: ProductionArticleService,
              private businessOrderProposalService: BusinessOrderProposalService,
              private articleWorkdayService: ArticleWorkdayCalculationService) {
  }

  ngOnInit(): void {
    this.frame = this.frame ? this.fullData.frame.frame : null;
    this.fetchesSmall = ((this.fullData.frame?.frame as MergedFrame)?.fetch ?? []);
    this.delivered = this.frame ? this.fullData.frame.delivered : null;
    this.article = this.fullData.article;
    this.inventory = this.fullData.inventory;
    this.startNumber = this.fullData.startNumber;
    this.kanban = this.frame && this.frame.lose > 1 && this.fullData.additional.minimum_stock > 0;
    this.empb = this.fullData.extra.initial_sample_testing.empb !== '-';
    this.commentService.list('workflow', this.workflow_id).subscribe(value => {
      this.comments = value;
    }, () => {
    });
    this.isCn = this.frame ? this.frame.typ === 'cn' : false;
    this.sum = this.fullData.sum;
    this.articleProductionService.list(this.fullData.article.oa_nr).subscribe(res => {
      this.plistEmpty = res.objects.length < 1;
    }, () => {
    });
  }

  extendedFrame(): ExtendedFrame {
    return this.fullData.frame ? this.fullData.frame.frame : null;
  }

  reloadInventory() {
    this.inventoryService.inventorySum(this.article.oa_nr, this.workflow_id).subscribe(value => {
      this.inventory = value;
    });
  }

  updateQuantity(quantity?: number): void {
    this.quantity = quantity;
  }

  updateSum(s: number): void {
    this.sum = s;
  }

  openProductionFetches(): void {
    this.fetchService.productionFetches(
      this.workflow_id,
      this.fullData.frame.frame.id,
      this.hasGold()
    ).subscribe(value => {
      const modalRef = this.ngbModal.open(BusinessOrderFetchModalComponent);
      modalRef.componentInstance.fetches = value;
      modalRef.componentInstance.gold = this.hasGold();
      modalRef.componentInstance.workflowId = this.workflow_id;
      modalRef.componentInstance.frameId = this.fullData.frame.frame.id;
      from(modalRef.result).subscribe(fetches => {
        this.updateFetches(fetches);
        this.commentService.list('workflow', this.workflow_id).subscribe(res => {
          this.comments = res;
        }, () => {
        });
      }, () => {
        this.commentService.list('workflow', this.workflow_id).subscribe(res => {
          this.comments = res;
        }, () => {
        });
      });
    });
  }

  updateBusinessOrders(): void {
    this.businessOrderChanges++;
    this.fetches = [];
    this.businessOrderService.sumByArticle(this.article.oa_nr).subscribe(response => {
      this.fullData.sum = response;
    }, ignoreRejection);
  }

  updateFetches(fetches: ProductionFetchView[]): void {
    this.fetches = fetches;
    let deliveryDate = null;
    let quantity = 0;
    this.fetches.forEach(fetch => {
      // Pick earliest Delivery date
      if (dateCheck(deliveryDate, fetch.fetch.delivery)) {
        deliveryDate = fetch.fetch.delivery;
      }

      if (typeof fetch.position.quanity === 'number') {
        quantity += fetch.position.quanity;
      } else {
        quantity += Money.parse(fetch.position.quanity);
      }
    });

    if (deliveryDate) {
      this.businessOrderProposalService.dateWithAmount(this.workflow_id, quantity, this.hasGold(), deliveryDate)
        .subscribe(dates => {
          this.fetchesMin = {
            quantity: quantity,
            delivery_date: deliveryDate,
            at: dates.at,
            end_date: dates.end_date,
            start_date: dates.start_date,
            gold_date: dates.gold_date,
            warning: dates.warning
          };
        });
    }
  }

  hasExpress(): boolean {
    return this.fetches.filter(b => !!b.express).length > 0;
  }

  hasGold(): boolean {
    return this.article.data['surface_area'] === 'Chem NI / AU';
  }

  updateComments(): void {
    this.commentService
      .list('workflow', this.fullData.frame.frame.workflow_id.toString())
      .subscribe(comments => {
        this.comments = comments;
      });
  }

  reproductionSelect(reproductionSelected?: BusinessOrderWithReproduction): void {
    if (!reproductionSelected) {
      this.reproductionSelected = null;
      this.updateFetches([]);
    } else {
      forkJoin({
        fetches:
          this.fetchService.boFetches(
            reproductionSelected.id,
            reproductionSelected.index,
            reproductionSelected.workflow_id,
            this.hasGold(),
          ),
        date:
          this.articleWorkdayService.endDate(this.article.oa_nr),
      }).subscribe(ret => {
        this.updateFetches(ret.fetches);
        this.reproductionSelected = {
          business_order: reproductionSelected,
          date: ret.date,
        };
      });
    }
  }
}
