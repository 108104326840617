<div class="buttonbar-view">
  <material-inventory-navigation [article]="currentArticle"
                                 [variation]="currentVariation">
  </material-inventory-navigation>
</div>

<div *ngIf="searchParams">
  <h2><a uiSref="a.material.inventory.list" [uiParams]="searchParams">Zurück zum Suchergebnis</a></h2>
</div>

<div *ngIf="!(currentGroup && currentArticle && currentVariation)">
  <div>
    <h3>Artikelauswahl:</h3>
  </div>
  <material-select-article
    [variation-id]="(currentInventory && currentInventory.length > 0) ? currentInventory[0].variation_id : null"
    (group)="setCurrentGroup($event)"
    (article)="setCurrentArticle($event)"
    (variation)="setCurrentVariation($event)">
  </material-select-article>
</div>
