<div [formGroup]="form">
  <div class="row">
    <div class="col-md-12">&nbsp;</div>
  </div>
  <div class="row">
    <div class="col-md-5">
      <h4>Bestell Positionen für Gruppe: <strong>{{mig.group.group_id}} {{mig.group.name}}</strong></h4>
    </div>
    <div class="col-md-7">
      <button type="button" class="btn btn-link" (click)="openAddArticleModal()">
        Artikel hinzufügen
      </button>
    </div>
  </div>

  <table class="table"
         *ngIf="materialFieldData.data$ | async as fields; else loadingOrError.template">
    <thead>
    <tr>
      <th></th>
      <th>Interne Nr.</th>
      <th>Art. Nr. Lief.</th>
      <th>Bezeichnung</th>
      <th>Bestell Bezeichnung</th>
      <th>Einheit</th>
      <th *ngFor="let field of fields">{{field.name}}</th>
      <th class="align-right">Bestand</th>
      <th class="align-right">Bestellt</th>
      <th class="align-right">Min. Bestand</th>
      <th class="align-right">Min. Bestellung</th>
      <th class="align-right">Menge Bestellung</th>
      <th>Lieferdatum</th>
      <th class="align-right">Preis</th>
      <th>Int. Notiz</th>
      <th class="icon"></th>
      <th class="icon"></th>
    </tr>
    </thead>
    <tbody formArrayName="orders">
    <ng-container *ngFor="let article of mig.article_list; trackBy:trackByArticleFn; let articleIndex = index">
      <tr [material-order-create-row]="article"
          [material-order-create-row-form]="form"
          [material-order-create-row-index]="deliveryIndex + articleIndex * 100 + mig.group.id * 1000000"
          [material-order-create-row-delivery-index]="deliveryIndex"
          [material-order-create-row-fields]="fields"
          [material-order-create-row-errors]="errors"
          [ngClass]="{'info': markedVariationId === article.id}"
          (material-order-create-row-remove)="removeOrder($event, mig)"
          (material-order-create-row-copy)="copyOrder($event, mig)"
          *ngFor="let subForm of (form.get('orders').value|ArticleFilters:article.id)
           trackBy:trackByIndexFn; let deliveryIndex = index">
      </tr>
    </ng-container>
    </tbody>
  </table>
  <loading-or-error
    #loadingOrError
    [loadingWrapper]="materialFieldData"
    [errorMessage]="'Konnte Material Felder nicht laden'"
    [spinnerSize]="2"
    spinnerName="circle-o-notch">
  </loading-or-error>
</div>
