<div>

  <a uiSref="a.crm.create" title="Neuer Kunde" class="btn btn-default"
     *ngIf="!state.includes('a.crm.customer.create') && ('crm.customer.create'|HasPerm)">
    <span class="fa fa-plus"></span>
  </a>

  <a uiSref="a.crm.list" title="Zurück" class="btn btn-default" *ngIf="state.includes('a.crm.customer.create')">
    <span class="fa fa-reply"></span>
  </a>


  <div class="btn-group" role="group" aria-label="customer"
       *ngIf="customer && (('crm.customer.edit'|HasPerm) || ('sales.restricted'|HasPerm))">
    <a title="Neuer Ansprechpartner" (click)="newContact()" class="btn btn-default"
       *ngIf="state.includes('a.crm.customer')">
      Neuer Ansprechpartner
    </a>

    <button class="btn btn-default" (click)="activity()" *ngIf="state.includes('a.crm.customer')">
      Neue Aktivität
    </button>

    <button class="btn btn-default" (click)="deleteCustomer()"
            *ngIf="state.includes('a.crm.customer') && ('crm.customer_delete'|HasPerm)">
      Kunde löschen
    </button>

    <button class="btn btn-default" (click)="lockCustomer()"
            *ngIf="(state.includes('a.crm.customer') && lockButton() !== '')">
      {{ lockButton() }}
    </button>

    <a uiSref="a.crm.customer.document_create" [uiParams]="{id: customer.id}" class="btn btn-default"
       *ngIf="state.includes('a.crm.customer')">
      Neues Dokument
    </a>
  </div>

</div>
