<div class="modal-header">
  <h3 class="modal-title">Vertriebsnotiz</h3>
</div>


<div class="modal-body">
  <pre *ngIf="salesNote">
    {{ salesNote }}
  </pre>
</div>

<div class="modal-footer">

</div>
