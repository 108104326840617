/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';

export const ARTICLE_EXTRA_CHECKBOX_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleExtraCheckboxComponent),
  multi: true,
};

export const ARTICLE_EXTRA_CHECKBOX_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ArticleExtraCheckboxComponent),
  multi: true
};

@Component({
  selector: 'article-extra-checkbox',
  templateUrl: './article-extra-checkbox.component.html',
  providers: [
    ARTICLE_EXTRA_CHECKBOX_ACCESSOR,
    ARTICLE_EXTRA_CHECKBOX_VALIDATOR,
  ],
  styles: ['.div-inline {display: inline;}']
})
export class ArticleExtraCheckboxComponent implements ControlValueAccessor, Validator {
  @Input() inline = false;
  @Input() disabled = false;
  current = false;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  writeValue(obj: any): void {
    this.current = obj === true;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(c: AbstractControl): ValidationErrors | any {
    return null;
  }

  onChange(value: any): void {
    if (this.disabled) {
      return;
    }

    this.current = value;
    this._onChange(value);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    return this.disabled;
  }

}
