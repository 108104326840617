<div class="row">
  <div class="col-md-12 btn-group positionnavigation">
    <button class="btn btn-default btn-sm" (click)="addArticlePosition()" *ngIf="('task.offer.change'|HasPerm)">
      Artikel hinzufügen
    </button>

    <button class="btn btn-default btn-sm" (click)="addExtraCostPosition()" *ngIf="('task.offer.change'|HasPerm)">
      Zusatzkosten hinzufügen
    </button>

    <button class="btn btn-default btn-sm" (click)="changeAllCalculations()" *ngIf="('task.offer.change'|HasPerm)">
      Kalkulation wechseln
    </button>

    <button class="btn btn-default btn-sm" (click)="changeAllProducers()" *ngIf="('task.offer.change'|HasPerm)">
      Produzent festlegen
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <position-box-list [data]="data"
                       [listChange]="listChange"
                       (updatePosition)="doPositionUpdate($event)"
                       *ngIf="('task.offer.change'|HasPerm)">
    </position-box-list>
    <position-readonly-box [positions]="data.workflow.positions" *ngIf="!('task.offer.change'|HasPerm)">
    </position-readonly-box>
  </div>
</div>

