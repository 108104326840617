import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  ArticleMultilayerLayerLabel,
  ArticleMultilayerParts,
  ArticleMultilayerPlanModel,
} from '../article-multilayer-plan.models';
import {ArticleMultilayerPlanHelper} from '../article-multilayer-plan-helper';

interface Props {
  model: ArticleMultilayerPlanModel;
  parts: ArticleMultilayerParts;
  partChange: (part: ArticleMultilayerParts) => void;
}

export function ArticleMultilayerLabelSelect(props: Props) {
  const [list, listSet] = useState<ArticleMultilayerLayerLabel[]>(
    ArticleMultilayerPlanHelper.getSelectableLabelsClean(props.model)
  );

  const setSelected: (short?: string) => void = (short) => {
    const copyPlan = {...props.parts};
    copyPlan.label = list.find(s => s.short === short);
    props.partChange(copyPlan);
  };

  useEffect(() => {
    listSet(ArticleMultilayerPlanHelper.getSelectableLabelsClean(props.model));
  }, [props.model]);

  return <>
    <select className={'form-control'}
            value={props.parts.label?.short ?? ''}
            onChange={c => setSelected(c.target.value)}>
      <option key={''} value={''}>-</option>
      {list.map(k => <option key={k.short} value={k.short}>{k.short} - {k.long}</option>)}
    </select>
  </>;
}
