/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {ArticleExtraInitialSampleTestingComponent} from '../../envisia/article-extra/values/article-extra-initial-sample-testing.component';
import {ArticleExtraGroupedComponent} from './main/article-extra-grouped.component';
import {ArticleExtraDropdownComponent} from './input/article-extra-dropdown.component';
import {ArticleExtraTextComponent} from './input/article-extra-text.component';
import {ArticleExtraCheckboxComponent} from './input/article-extra-checkbox.component';
import {ArticleExtraDimensionInspectionComponent} from './values/article-extra-dimension-inspection.component';
import {ArticleExtraMicrosectionComponent} from './values/article-extra-microsection.component';
import {ArticleExtraProductSpecificInspectionPlanComponent} from './values/article-extra-product-specific-inspection-plan.component';
import {ArticleExtraCOCTestingProtocolComponent} from './values/article-extra-c-o-c-testing-protocol.component';
import {ArticleExtraETestLabelingComponent} from './values/article-extra-e-test-labeling.component';
import {ArticleExtraUtilizeComponent} from './utilize/article-extra-utilize.component';
import {ArticleExtraTextareaComponent} from './input/article-extra-text-area.component';
import {ArticleExtraPackagingComponent} from './values/article-extra-packaging.component';
import {ArticleExtraBadPartComponent} from './values/article-extra-bad-part.component';
import {ArticleExtraTableComponent} from './input/article-extra-table.component';
import {ArticleExtraOverUnderDeliveryComponent} from './values/article-extra-over-under-delivery.component';
import {ArticleExtraSolderPatternComponent} from './values/article-extra-solder-pattern.component';
import {ArticleExtraImpedanceComponent} from './values/article-extra-impedance.component';
import {ArticleExtraDeliveryDateComponentComponent} from './values/article-extra-delivery-date.component';
import {ArticleExtraHeadlineComponent} from './article-extra-headline/article-extra-headline.component';
import {ArticleExtraTerminationDateComponent} from './values/article-extra-termination-date.component';
import {ArticleExtraTableBaTerminationComponent} from './input/article-extra-table-ba-termination.component';
import {ShippingInstructionHistoryListComponent} from './shipping-instruction-history/shipping-instruction-history-list.component';
import {ShippingInstructionHistoryDetailComponent} from './shipping-instruction-history/shipping-instruction-history-detail.component';
import {ShippingInstructionHistoryModalComponent} from './shipping-instruction-history/shipping-instruction-history-modal.component';
import {ArticleExtraStorageComponent} from './values/article-extra-storage.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    exports: [
        ArticleExtraGroupedComponent,
        ShippingInstructionHistoryModalComponent,
    ],
    declarations: [
        // Inputs
        ArticleExtraDropdownComponent,
        ArticleExtraTextComponent,
        ArticleExtraCheckboxComponent,
        ArticleExtraTextareaComponent,
        ArticleExtraTableComponent,
        ArticleExtraTableBaTerminationComponent,
        // Utilize
        ArticleExtraUtilizeComponent,
        // Values
        ArticleExtraInitialSampleTestingComponent,
        ArticleExtraDimensionInspectionComponent,
        ArticleExtraMicrosectionComponent,
        ArticleExtraCOCTestingProtocolComponent,
        ArticleExtraProductSpecificInspectionPlanComponent,
        ArticleExtraETestLabelingComponent,
        ArticleExtraPackagingComponent,
        ArticleExtraBadPartComponent,
        ArticleExtraOverUnderDeliveryComponent,
        ArticleExtraSolderPatternComponent,
        ArticleExtraImpedanceComponent,
        ArticleExtraDeliveryDateComponentComponent,
        ArticleExtraTerminationDateComponent,
        ArticleExtraStorageComponent,
        // EntryComponent
        ArticleExtraGroupedComponent,
        ArticleExtraHeadlineComponent,
        ShippingInstructionHistoryListComponent,
        ShippingInstructionHistoryDetailComponent,
        ShippingInstructionHistoryModalComponent,
    ]
})
export class ArticleExtraModule {
}
