import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {UIRouterModule, UrlParts, TargetStateDef} from '@uirouter/angular';
import {ArticleModule} from './envisia/article/article.module';
import {EnvisiaHttpModule} from './common/http';
import {CustomerModule} from './envisia/customer/customer.module';
import {NgbDropdownModule, NgbTooltipModule, NgbNavModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {DunningModule} from './envisia/dunning/dunning.module';
import {AkquiseModule} from './envisia/akquise/akquise.module';
import {CalculationModule} from './envisia/calculation/calculation.module';
import {HolidayModule} from './envisia/holiday/holiday.module';
import {PriceModule} from './envisia/price/price.module';
import {ArticleExtraModule} from './envisia/article-extra/article-extra.module';
import {MainModule} from './envisia/main/main.module';
import {EvAlertModule} from './common/ev-alert/alert.module';
import {Error404Component} from './envisia/error/error404.component';
import {Error500Component} from './envisia/error/error500.component';
import {Error401Component} from './envisia/error/error401.component';
import {Error403Component} from './envisia/error/error403.component';
import {MAIN_STATES} from './app.states';
import {OposModule} from './envisia/opos/opos.module';
import {WorkflowModule} from './envisia/workflow/workflow.module';
import {HistoryModule} from './envisia/history/history.module';
import {LabelModule} from './envisia/label/label.module';
import {routerConfigFn} from './app.router.config';
import {debug} from './helper/debug.func';
import {environment} from '../environments/environment';
import {APP_BASE_HREF} from '@angular/common';
import {DatepickerHolidayService} from '@envisia/ev-datepicker';
import {ErpDatePickerHolidayService} from './common/datepicker-holiday/erp-date-picker-holiday.service';
import {WorkflowStatusModalModule} from './envisia/workflow/status-modal/workflow-status-modal.module';
import {Location} from '@angular/common';
import {AppBootstrapComponent} from './bootstrap/app.bootstrap.component';
import {MaterialManagementModule} from './envisia/material/material-management.module';
import * as Sentry from '@sentry/angular';
import {MultilayerPlanModule} from './lazy/multilayer-plan/multilayer-plan.module';

export function otherwiseHandler(matchValue?: any, url?: UrlParts): string | TargetStateDef {
  debug('Otherwise: ', matchValue, url);
  if (url && (url.path === '!' || url.path === '' || url.path.endsWith('login'))) {
    return '/';
  }
  return {state: 'o.error404'};
}

if (environment.production) {
  Sentry.init({
    dsn: 'https://4a698c16691c47a08f02aee2d53030d2@sentry.envisia.io/3',
    integrations: [],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

@NgModule({
  imports: [
    // Core Modules
    BrowserModule,
    EnvisiaHttpModule,
    UIRouterModule.forRoot({
      useHash: true,
      states: MAIN_STATES,
      config: routerConfigFn,
      otherwise: otherwiseHandler,
      initial: {state: 'home'}
    }),
    // ng-bootstrap:
    NgbTooltipModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbNavModule,

    // forked for custom styles:
    EvAlertModule.forRoot(),
    // ERP Core Modules:
    MainModule,
    // ERP Modules:
    PriceModule,
    ArticleModule,
    WorkflowModule,
    OposModule,
    ArticleExtraModule, // Liefervorschriften
    CustomerModule,
    AkquiseModule,
    DunningModule,
    CalculationModule,
    HolidayModule,
    HistoryModule,
    LabelModule,
    MaterialManagementModule,
    // useful for downgrade
    WorkflowStatusModalModule,
    MultilayerPlanModule,
  ],
  declarations: [
    Error401Component,
    Error403Component,
    Error404Component,
    Error500Component,
    AppBootstrapComponent,
  ],
  bootstrap: [
    AppBootstrapComponent
  ],
  providers: [
    Location,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {provide: APP_BASE_HREF, useValue: '!'},
    {provide: DatepickerHolidayService, useClass: ErpDatePickerHolidayService},
  ],
})
export class AppModule {
}
