<div class="modal-header">
  <h3 class="modal-title">Rechnungsstorno für Rechnung {{ invoice.id }}</h3>
</div>

<form class="form-horizontal" [formGroup]="form">
  <div class="modal-body">

    <div class="form-group">
      <label for="inputDate" class="col-sm-2 control-label">Beleg-Datum</label>
      <div class="col-sm-10">
        <ev-datepicker class="form-control" id="inputDate"
                    [allDates]="true"
                    formControlName="date"
                    [evClass]="{red_alert: errors['obj.date']}">
        </ev-datepicker>
      </div>
    </div>

    <div class="form-group">
      <label for="inputHint" class="col-sm-2 control-label">Hinweis</label>
      <div class="col-sm-10">
        <textarea class="form-control" id="inputHint"
                  formControlName="hint"
                  [ngClass]="{red_alert: errors['obj.hint']}"></textarea>
      </div>
    </div>

    <div class="form-group">
      <label for="note" class="col-sm-2 control-label">interne Notiz</label>
      <div class="col-sm-10">
        <textarea id="note"
                  class="form-control"
                  formControlName="note"
                  [ngClass]="{red_alert: errors['obj.note']}"></textarea>
      </div>
    </div>


  </div>

  <div class="modal-footer">

    <button class="btn btn-default pull-left" type="button" (click)="dismiss()">
      Abbrechen
    </button>

    <loading-button clazz="btn btn-success pull-right" (loadingSave)="create($event)" [disabled]="!form.valid">
      Rechnung stornieren
    </loading-button>

  </div>
</form>
