import * as React from 'react';
import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';
import {OfferAnalysisQueryParams} from './offer-analysis.model';
import {ErpAngularZonedRouterService} from '../../../../react/components/react-router/erp-angular-zoned-router.service';
import {ErpReactRouterWrapper} from '../../../../react/components/react-router/erp-react-router-context-wrapper';
import {OfferAnalysisRoot} from './offer-analysis-root';

const containerElementName = 'reactOfferAnalysis';

@Component({
  selector: 'offer-analysis',
  standalone: true,
  template: `<div #${containerElementName}></div>`,
})
export class OfferAnalysisComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() query: OfferAnalysisQueryParams;
  @Input() channelId: string;

  constructor(private angularRouterService: ErpAngularZonedRouterService) {
    super();
  }

  protected override renderComponent() {
    const _ = React.version;
    return <ErpReactRouterWrapper routeAngular={(to, params, options) =>
        this.angularRouterService.routeAngular(to, params, options)}>
        <OfferAnalysisRoot query={this.query}
                           channelId={this.channelId}/>
      </ErpReactRouterWrapper>;
  }
}
