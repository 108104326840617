<div [formGroup]="form">
  <ev-datepicker id="date"
                 class="form-control"
                 placeholder="__ . __ . _____"
                 formControlName="date"
                 style="width: 80px;display: inline-block;"
                 [writeBack]="false"
                 [future]="true">
  </ev-datepicker>

  <ng-container *ngIf="state.data$|async as stateHolder; else loadingOrError.template">
    <div class="material-planning-signal click"
         style="display: inline-block;"
         [ngSwitch]="stateHolder.obj.status"
         *ngIf="!!stateHolder.obj"
         (click)="open()">
      <i class="fa fa-info-circle impossible" style="font-size: 1.5em" *ngSwitchCase="0"></i>
      <i class="fa fa-info-circle conflicts" style="font-size: 1.5em" *ngSwitchCase="1"></i>
      <i class="fa fa-info-circle free" style="font-size: 1.5em" *ngSwitchCase="2"></i>
    </div>
    <i class="fa fa-question-circle click"
       style="font-size: 1.5em"
       (click)="checkDateAndOpen()"
       *ngIf="!stateHolder.obj && this.date && this.amount">
    </i>
  </ng-container>

  <loading-or-error #loadingOrError [loadingWrapper]="state"
                    [errorMessage]="'Konnte Materialverfügbarkeit nicht berechnen.'"
                    spinnerSize="1">
  </loading-or-error>
</div>
