<div class="page-header">
  <h3>Material Artikel Feld {{fieldAttributes ? fieldAttributes.field.name+' bearbeiten': 'hinzufügen'}}</h3>
</div>
<form [formGroup]="fieldCreateForm" (ngSubmit)="onSubmit()" class="form-horizontal" style="max-width:800px;">
  <div class="red_alert" *ngIf="fieldAttributes?.field?.date_deleted">
    <h3>Achtung!</h3>
    Das Feld wurde bereits gelöscht. Durch das abspeichern wird es wiederhergestellt.
  </div>
  <div>&nbsp;</div>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="name">Bezeichnung</label>
    <div class="col-sm-10">
      <input class="form-control" id="name" type="text" formControlName="name">
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="is_variation">Feldtyp</label>
    <div class="col-sm-10">
      <select class="form-control" id="is_variation" formControlName="is_variation">
        <option [ngValue]="true">Variation</option>
        <option [ngValue]="false">Allgemein</option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="data_type">Datentyp</label>
    <div class="col-sm-10">
      <select class="form-control" id="data_type" formControlName="data_type">
        <option style="display: none"></option>
        <option [value]="valueType" *ngFor="let valueType of valueTypes">{{valueType}}</option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="read_only">Nicht veränderbar</label>
    <div class="col-sm-10">
      <input type="checkbox" class="form-control" id="read_only" formControlName="read_only">
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="on_label">Auf Etikett verfügbar</label>
    <div class="col-sm-10">
      <input type="checkbox" class="form-control" id="on_label" formControlName="on_label">
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="on_ba_list">In BA Ansicht verfügbar</label>
    <div class="col-sm-10">
      <input type="checkbox" class="form-control" id="on_ba_list" formControlName="on_ba_list">
    </div>
  </div>

  <!-- choice selection List -->
  <div class="row" *ngIf="isChoicesSelected()">
    <div class="col-sm-12">
      <label for="choices_name"><strong>Verfügbare Auswahlm&ouml;glichkeiten</strong></label>
      <ng-container [formGroup]="addChoiceForm" (ngSubmit)="addChoice()">
        <table class="table whitetable">
          <tr *ngFor="let choice of (fieldAttributes ? fieldAttributes.field.choices : [])">
            <td>{{choice}}</td>
            <td class="icon"></td>
          </tr>
          <tr *ngFor="let choice of listChoices">
            <td>{{choice}}</td>
            <td class="icon"><a (click)="removeChoice(choice)"><i class="fa fa-trash-o"></i></a></td>
          </tr>
          <tr>
            <td>
              <input id="choices_name" class="form-control" type="text" formControlName="name">
            </td>
            <td class="icon">
              <a (click)="addChoice()">
                <i class="fa fa-save" [ngClass]="isChoiceValid()?'green':''"></i>
              </a>
            </td>
          </tr>
        </table>
      </ng-container>
    </div>
  </div>

  <!-- group selection list -->
  <div class="row">
    <div class="col-sm-6">
      <button
        class="btn btn-default col-sm-12"
        type="button"
        [value]="true"
        [ngClass]="allGroups?'active':''"
        (click)="toggleAllSelected(true)"
        [disabled]="fieldAttributes && fieldAttributes.all_groups"
      >
        <i class="fa fa-check" [ngClass]="allGroups?'':'hide-icon'"></i>&nbsp;In allen Gruppen verf&uuml;gbar
      </button>
    </div>
    <div class="col-sm-6">
      <button
        class="btn btn-default col-sm-12"
        type="button"
        [value]="true"
        [ngClass]="!allGroups?'active':''"
        (click)="toggleAllSelected(false)"
        [disabled]="fieldAttributes && fieldAttributes.all_groups"
      >
        <i class="fa fa-check" [ngClass]="!allGroups?'':'hide-icon'"></i>&nbsp;In Ausgew&auml;hlten Gruppen verfügbar
      </button>
    </div>
  </div>


  <div class="row" *ngIf="!allGroups">
    <div class="col-sm-12">
      &nbsp;
    </div>
    <div class="col-sm-12">
      <strong>Verf&uuml;gbar in Gruppen:</strong>
    </div>
    <material-field-select-group
      class="col-sm-12"
      [groups]="fieldAttributes ? fieldAttributes.groups : groupList"
      (event)="setSelectedGroups($event)">
    </material-field-select-group>
  </div>

  <div class="row"><div class="col-sm-12">&nbsp;</div></div>
  <div class="row" *ngIf="errorCode">
    <div class="col-sm-12 red_alert" style="clear: both" [ngSwitch]="errorCode">

      <ng-container *ngSwitchCase="409">
        <h3>Es existiert bereits ein Feld mit diesen Eigenschaften</h3>
        <a uiSref="a.material.field.update"
           [uiParams]="{ 'field': duplicateField.id}"
           *ngIf="duplicateField">
          {{duplicateField.id}} {{duplicateField.name}}
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="404">
        <h3>Das Feld konnte nicht gefunden werden</h3>
      </ng-container>

      <ng-container *ngSwitchCase="415">
        <h3>Es muss mindestens eine Gruppe ausgew&auml;hlt werden</h3>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <h3>Unbekannter Fehler beim &Uuml;bertragen des Formulars</h3>
      </ng-container>

    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      &nbsp;
    </div>
    <div class="col-sm-12">
        <button type="Submit" class="btn btn-success" [disabled]="!fieldCreateForm.valid">
          Speichern
        </button>
    </div>
  </div>
</form>
