<a uiSref="a.article.create" class="btn btn-default" *ngIf="('article.change'|HasPerm)">
  <i class="fa fa-plus"></i>
</a>

<div class="btn-group" role="group"
     *ngIf="state.includes('a.article.workflow') && (('article.change'|HasPerm))">
  <button class="btn"
          type="button"
          [ngClass]="{'red_font': hasValidationErrors}"
          [ngbTooltip]="saveButtonErrorMessage"
          [disabled]="isLocked"
          (click)="save()">
    Speichern
  </button>
  <button class="btn btn-default" (click)="copy()" type="button" title="Artikel duplizieren" *ngIf="article.oa_nr">
    Duplizieren
  </button>
</div>

<button type="button" class="btn btn-default" title="SDS Nr. generieren"
        *ngIf="state.includes('a.article.workflow') && article.sds_nr === null && ('article.sds_generate'|HasPerm)"
        (click)="sdsGenerate()">
  SDS. Nr generieren
</button>

<button type="button" class="btn btn-default" title="Schwesterartikel generieren"
        *ngIf="state.includes('a.article.workflow') && showSisterButton() && (('article.change'|HasPerm))"
        (click)="sisterCreate()">
  Schwesterartikel generieren
</button>

<div class="btn-group" role="group" *ngIf="options.length > 0 && withLock">
  <button *ngIf="(('article.delete'|HasPerm) && !article.date_deleted) && !!article.oa_nr"
          type="button"
          class="btn btn-default"
          (click)="deleteArticle()">
    Löschen
  </button>

  <button *ngIf="(('article.delete'|HasPerm) && !!article.date_deleted) && !!article.oa_nr"
          type="button"
          class="btn btn-default"
          (click)="recoverArticle()">
    Wiederherstellen
  </button>

  <article-lock *ngIf="((('article.lock'|HasPerm) || ('article.unlock'|HasPerm)) && state.includes('a.article.workflow')) && article !== null"
                [oa]="article.oa_nr"
                [notices]="options"
                [locked]="!!article.locked"
                (locker)="lockChange($event)">
  </article-lock>
</div>

<a uiSref="a.article.document.new" [uiParams]="{id:article.oa_nr}" class="btn btn-default"
   *ngIf="(state.includes('a.article.workflow') || state.includes('a.article.document') || state.includes('a.article.production')) && (('article.change'|HasPerm))">
  Neues Dokument
</a>

<div class="btn-group" role="group" *ngIf="('article.delete'|HasPerm) && (state.includes('a.article.workflow.extra'))">
  <button type="button" class="btn btn-default" (click)="openHistory()">
    Verlauf
  </button>
</div>
