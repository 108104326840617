import {Directive} from '@angular/core';
import {DateHelper} from '../../../../helper/date-helper';
import {MaterialOrderTransaction} from '../material-order.model';
import {NumberHelper} from '../../../../helper/number-helper';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';

@Directive()
export abstract class MaterialOrderIntakePdfRow {
  protected transaction: MaterialOrderTransaction;

  get pdfUri(): string {
    const tr = this.transaction.transaction;
    const date = DateHelper.format(DateHelper.parseISO8601(tr.date_received));
    return (
      '/api/material/pdf?' +
      EnvisiaLocation.httpParams({
        variation: tr.variation_id,
        location: tr.location,
        charge: !tr.charge ? null : tr.charge,
        date_received: date,
        set_quantity: tr.amount,
        price: !this.transaction.price ? null : NumberHelper.saveParseFloat(this.transaction.price),
        order_transaction_id: !tr.order_transaction_id ? null : tr.order_transaction_id,
      }).toString()
    );
  }
}
