<div class="modal-header">
  <h3 class="modal-title">{{ header }}</h3>
</div>


<div class="modal-body">
  <h3>{{ text }}</h3>
</div>


<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="dismiss()">Abbrechen</button>
  <button type="button" class="btn btn-danger pull-right" (click)="remove()" [disabled]="blocked">Löschen</button>
</div>
