import {RawParams, StateOrName, StateService} from '@uirouter/angular';
import {Injectable, NgZone} from '@angular/core';
import {TransitionOptions} from '@uirouter/core';

@Injectable({providedIn: 'root'})
export class ErpAngularZonedRouterService {
  constructor(
    private ngZone: NgZone,
    private stateService: StateService,
  ) {
  }

  async routeAngular(
    to: StateOrName,
    params?: RawParams,
    options?: TransitionOptions,
  ): Promise<boolean> {
    return await this.ngZone.run(() => {
      return this.stateService.go(to, params, options).then(() => true, () => false);
    });
  }
}
