import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ArticleWeightService} from '../../../envisia/article/services/article-weight.service';
import {ArticleWeightConfig} from '../../../envisia/article/models/article-weight.model';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'article-config-weight-calculation',
  templateUrl: './article-config-weight-calculation.component.html'
})
export class ArticleConfigWeightCalculationComponent implements OnInit {
  @Input() config: ArticleWeightConfig;
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = null;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private weightService: ArticleWeightService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      cu_lba: [this.config.cu_lba, Validators.required],
      cu_bsa: [this.config.cu_bsa, Validators.required],
      cu_bsi: [this.config.cu_bsi, Validators.required],
      rge: [this.config.rge, Validators.required],
      rc: [this.config.rc, Validators.required],
      fl: [this.config.fl, Validators.required]
    });
  }

  submit(): void {
    this.errors = null;
    this.weightService.setConfig(this.form.value).subscribe(value => {
      this.alertService.add('success', 'Einstellungen wurden gespeichert');
      this.config = value;
    }, error => {
      if (error.status === 400) {
        this.alertService.add('danger', 'Bitte prüfen Sie Ihre Eingabe. Nur positive Kommazahlen sind gültig.');
        this.errors = error.error;
      }
      console.log(error);
    });
  }
}
