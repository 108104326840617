<div class="filter-row">
  <div class="filter-left">

    <button class="btn btn-danger btn-sm" type="button"
            (click)="badge('true', 'cfa', true)"
            [ngClass]="{active: q('true', 'cfa')}">
      Handlungsbedarf <span class="badge"></span>
    </button>

    <button class="btn btn-warning btn-sm" type="button"
            (click)="badge('true', 'production_approval_not_reached', true)"
            [ngClass]="{active: q('true', 'production_approval_not_reached')}">
      Rahmen nicht ausgenutzt <span class="badge"></span>
    </button>

    <ev-file-button [url]="downloadUrl('/api/v1/workflow/kanban/de/export/')" [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>

  </div>
  <div class="filter-right">
    <date-range [start]="null" [end]="null" (update)="updateDateRange($event)"></date-range>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'workflow_id', 'ASC')" [href]="sortUrl('workflow_id', 'ASC')">
          Vorgangs-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'ASC')" [href]="sortUrl('customer_name', 'ASC')">
          Kunde
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">
          SDS-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_nr', 'ASC')"
           [href]="sortUrl('kd_art_nr', 'ASC')">
          Kunden-Art-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_extend', 'ASC')" [href]="sortUrl('kd_art_extend', 'ASC')">
          Kunden-Art-Erweiterung
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'fetch_position_delivery', 'DESC')"
           [href]="sortUrl('fetch_position_delivery', 'DESC')">
          Liefertermin
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'fetch_position_quantity', 'ASC')" [href]="sortUrl('fetch_position_quantity', 'ASC')">
          Stückzahl
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'frame_quantity', 'ASC')" [href]="sortUrl('frame_quantity', 'ASC')">
          Rahmenmenge
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'open_quantity', 'ASC')" [href]="sortUrl('open_quantity', 'ASC')">
          Offene Menge
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'stock', 'ASC')" [href]="sortUrl('stock', 'ASC')">
          Bestand
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'minimum_stock', 'ASC')" [href]="sortUrl('minimum_stock', 'ASC')">
          Mindest-Bestand
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'production_approval', 'ASC')" [href]="sortUrl('production_approval', 'ASC')">
          Freigabe
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'currently_in_production', 'DESC')"
           [href]="sortUrl('currently_in_production', 'DESC')">
          Aktuell in Produktion
        </a>
      </th>
    </tr>
    <tr class="filterrow">
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.workflow_id" name="workflow_id" [autofocus] autocomplete="off search-workflow-id">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_name" name="customer_name">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.sds_nr" name="sds_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.kd_art_nr" name="kd_art_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.kd_art_extend" name="kd_art_extend">
      </th>
      <th>&nbsp;</th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.fetch_position_quantity"
               name="fetch_position_quantity">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.frame_quantity" name="frame_quantity">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.open_quantity" name="open_quantity">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.stock" name="stock">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.minimum_stock" name="minimum_stock">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.production_approval"
               name="production_approval">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.currently_in_production"
               name="currently_in_production">
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let object of list.objects" [ngClass]="object.clazz">
      <td>
        <a uiSref="a.workflow.detail" [uiParams]="{id: object.workflow_id}">
          <img src="/ui/assets/images/flags/de.png">
          {{ object.workflow_id }}
        </a>
      </td>
      <td>{{ object.customer_name }}</td>
      <td>{{ object.sds_nr }}</td>
      <td>{{ object.kd_art_nr }}</td>
      <td>{{ object.kd_art_extend }}</td>
      <td>{{ object.fetch_position_delivery }}</td>
      <td>{{ object.fetch_position_quantity }}</td>
      <td>{{ object.frame_quantity }}</td>
      <td>{{ object.open_quantity }}</td>
      <td>
        <i class="red_alert fa fa-exclamation-triangle" *ngIf="object.alert"></i>
        {{ object.stock }}
      </td>
      <td>{{ object.minimum_stock }}</td>
      <td>{{ object.production_approval }}</td>
      <td>{{ object.currently_in_production }}</td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
