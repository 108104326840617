import * as React from 'react';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import {Lister} from '../../../../common/wrapper.models';
import {OfferRejectionReason} from '../../../../lazy/config/offer-rejection/config-offer-rejection-reason.model';
import {OfferRejectionModal} from './offer-rejection-modal';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';

const containerElementName = 'reactOfferRejectionModal';

@Component({
  selector: 'offer-rejection-modal',
  template: `<div #${containerElementName}></div>`,
})
export class OfferRejectionModalComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() offerId: number;
  @Input() rejectionReasons: Lister<OfferRejectionReason>;
  @Output() reloadList: EventEmitter<void> = new EventEmitter<void>();

  constructor(private ngZone: NgZone) {
    super();
  }

  protected override renderComponent() {
    const _ = React.version;
    return <>
      <OfferRejectionModal offerId={this.offerId}
                           reasons={this.rejectionReasons}
                           reload={() => this.reload()}/>
    </>;
  }

  reload(): void {
    this.ngZone.run(() => {
      this.reloadList.emit();
    });
  }
}
