import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowService} from '../workflow-services/workflow.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'workflow-status-change-modal',
  templateUrl: './workflow-status-change-modal.component.html',
})
export class WorkflowStatusChangeModalComponent implements OnInit {
  @Input() id: number;
  @Input() typ: string;
  name: string;
  success: string;
  lowercaseName: string;

  constructor(private modalRef: NgbActiveModal,
              private workflowService: WorkflowService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.success = 'Status geändert';
    this.name = 'Statusänderung';
  }

  closeModal(): void {
    this.modalRef.dismiss(null);
  }

  save(status: string): void {
    let id: number;
    if (status === 'order') {
      id = 2;
    } else if (status === 'approved') {
      id = 3;
    } else if (status === 'delivered') {
      id = 4;
    } else if (status === 'cleared') {
      id = 5;
    }
    if (status && id >= 2 && id <= 5) {
      this.workflowService.updateStatus(this.id, status, null).subscribe(() => {
        this.alertService.add('success',  this.success + '!');
        this.modalRef.close(id);
      }, () => {
        this.alertService.add('danger', 'Beim Statusändern ist ein Fehler aufgetreten!');
      });
    }
  }

}
