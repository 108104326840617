import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {CustomerNavigationComponent} from './customer-navigation.component';
import {UIRouterModule} from '@uirouter/angular';

@NgModule({
  imports: [
    UIRouterModule.forChild(),
    SharedModule,
  ],
  declarations: [
    CustomerNavigationComponent,
  ],
  exports: [
    CustomerNavigationComponent,
  ]
})
export class CustomerNavigationModule {
}
