import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../models/article-specification.model';

@Component({
  selector: 'article-label',
  templateUrl: './article-label.component.html'
})
export class ArticleLabelComponent implements OnInit, OnChanges {
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() form: UntypedFormGroup;
  @Input() label: string;
  @Input() model: string;
  @Input() refCount?: number;
  @Input() bool = false;
  @Input() unit?: string;
  @Input() nullable = false;
  @Input() plain = false;
  @Input() materialSelect = 'manufacturer';
  data?: any = '';
  options: { [key: string]: { [key: string]: any } };

  ngOnInit(): void {
    this.options = this.specification[this.materialSelect].values2;
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refCount && !changes.refCount.isFirstChange()) {
      this.init();
    }
  }

  private init(): void {
    if (this.plain) {
      this.data = this.form.value[this.model];
    } else {
      const manufacturer = this.form.value['manufacturer'];
      if (!!manufacturer && !!this.options) {
        this.data = this.options[manufacturer][this.model];
      } else {
        this.data = false;
      }
    }
  }

}
