<div class="modal-header">
  <h3 class="modal-title">
    Manuelles Hinzufügen einer Material Artikel Position
  </h3>
</div>

<div class="modal-body">
  <form name="form" #changeForm="ngForm">
    <div class="row">
      <div class="col-sm-1"><label for="group_id">Gruppe:</label></div>
      <div class="col-sm-3">
        <select class="form-control input-sm" id="group_id" name="group_id" [(ngModel)]="data['group_id']">
          <option [ngValue]="group.id" *ngFor="let group of groups">
            {{group.group_id}} {{group.name}}
          </option>
        </select>
      </div>
      <div class="col-sm-8"></div>
    </div>
    <table class="table whitetable"
           *ngIf="materialFieldData.data$ | async as fields; else loadingOrError.template">
      <thead>
      <tr>
        <th><a (click)="sort($event, 'variation_id', 'ASC')">Interne Nr.</a></th>
        <th><a (click)="sort($event, 'vendor_article_id', 'ASC')">Art. Nr. Lief.</a></th>
        <th><a (click)="sort($event, 'article_name', 'ASC')">Bezeichnung</a></th>
        <th><a (click)="sort($event, 'article_name_order', 'ASC')">Bestell Bezeichnung</a></th>
        <th>Einheit</th>
        <th *ngFor="let field of fields">{{field.name}}</th>
        <th class="align-right">Bestand</th>
        <th class="align-right">Bestellt</th>
        <th class="align-right">Min. Bestand</th>
        <th class="align-right">Min. Bestellung</th>
        <th></th>
      </tr>
      <tr class="filterrow">
        <th>
          <input class="form-control input-sm" name="variation_id" [(ngModel)]="data['variation_id']">
        </th>
        <th>
          <input class="form-control input-sm" name="vendor_article_id" [(ngModel)]="data['vendor_article_id']">
        </th>
        <th>
          <input class="form-control input-sm" name="article_name" [(ngModel)]="data['article_name']">
        </th>
        <th>
          <input class="form-control input-sm" name="article_name_order" [(ngModel)]="data['article_name_order']">
        </th>
        <th></th>
        <th *ngFor="let field of fields">
          <ng-container [ngSwitch]="field.data_type">
            <ng-container *ngSwitchCase="'Auswahl'">
              <select class="form-control input-sm" [name]="field.html_name" [(ngModel)]="data[field.html_name]">
                <option [ngValue]="null" selected>-</option>
                <option [ngValue]="choice" *ngFor="let choice of field.choices">{{choice}}</option>
              </select>
            </ng-container>
            <ng-container *ngSwitchCase="'Option'">
              <i class="fa click" [ngClass]="{
                  'gray_font': (this.data[field.html_name] === undefined || this.data[field.html_name] === null),
                  'fa-check': (!this.data[field.html_name] || this.data[field.html_name] === 'true'),
                  'fa-times': (this.data[field.html_name] === 'false')
                 }"
                 (click)="triStateChange(field)"></i>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input class="form-control input-sm" [name]="field.html_name" [(ngModel)]="data[field.html_name]">
            </ng-container>
          </ng-container>
        </th>
        <th colspan="5"></th>
      </tr>
      </thead>
      <tbody *ngFor="let mig of list?.objects">
      <tr [material-order-create-list-row]="article"
          [material-order-create-list-row-group]="mig.group"
          [material-order-create-list-row-ids]="currentArticleIds"
          [material-order-create-list-row-fields]="fields"
          (material-order-create-list-row-add)="addArticle($event)"
        *ngFor="let article of mig.article_list; trackBy:trackByFn; let i = index">
      </tr>
      </tbody>
    </table>
    <loading-or-error
      #loadingOrError
      [loadingWrapper]="materialFieldData"
      [errorMessage]="'Konnte Material Felder nicht laden'"
      [spinnerSize]="2"
      spinnerName="circle-o-notch">
    </loading-or-error>
  </form>
  <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)" *ngIf="list">
  </ev-navigation>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">Schließen</button>
</div>
