<div class="row-field">
  <div class="row">
    <div class="col-md-12">
      <h3 *ngIf="!disabledHeader">Kunde</h3>
      <ng-content *ngIf="disabledHeader"></ng-content>
    </div>
  </div>

  <ng-container *ngIf="customerObj as innerCustomer">
    <div class="row">
      <div class="col-md-12">
        # {{innerCustomer.id}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>
          <a uiSref="a.crm.customer.detail"
             target="_blank"
             ngbTooltip="Kundenseite in neuem Fenster öffnen"
             [uiParams]="{id: innerCustomer.id}">
            {{ innerCustomer.name }}
          </a>
        </h4>
      </div>
    </div>
    <address *ngIf="withAddress">
      {{ innerCustomer.invoice.street }}<br>
      {{ innerCustomer.invoice.zip}} {{ innerCustomer.invoice.city }}<br>
    </address>
  </ng-container>

  <ng-container *ngIf="customer && !customerObj">
    <div class="row">
      <div class="col-md-12 min">
        <h4>
          <a uiSref="a.crm.redirect"
             target="_blank"
             ngbTooltip="Kundenseite in neuem Fenster öffnen"
             [uiParams]="{name: escape(customer.name)}">
            {{ customer.name }}
          </a>
        </h4>

        <address *ngIf="withAddress">
          &nbsp;<br>
          &nbsp;<br>
        </address>
      </div>
    </div>
  </ng-container>

  <!-- Null Value -->
  <div class="row" *ngIf="!customer">
    <div class="col-md-12 min">
      <h4>kein Kunde ausgewählt</h4>
      <address *ngIf="withAddress">
        &nbsp;<br>
        &nbsp;<br>
      </address>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <label class="label label-danger" *ngIf="isSpecialCustomer" style="display:block;padding:7px; font-size:14px;">Spezial</label>
    </div>
    <div class="col-md-12 text-left">
      <div class="btn-group">
        <button (click)="switchCustomer()" *ngIf="rights && !disabled" [disabled]="formDisabled"
                type="button"
                class="btn btn-default btn-sm">
          {{ !buttonLabel ? 'Bearbeiten' : buttonLabel }}
        </button>

        <button (click)="clearCustomer()" *ngIf="canBeCleared()" class="btn btn-default btn-sm">
          <i class="fa fa-trash-o"></i>
        </button>

        <ng-container *ngIf="withAddress">
          <button (click)="showNote()" *ngIf="!!customerObj" class="btn btn-danger btn-sm alert-danger">
            Notizen
          </button>
        </ng-container>
      </div>

    </div>
  </div>
</div>
