import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticleCustomerLockerService {

  constructor(private http: HttpClient) {
  }

  lock(form: {customer_id: number, reason: string, reference: string}): Observable<void> {
    return this.http.post<void>(environment.apiv4uri + 'article-config/customer-lock', form);
  }
}
