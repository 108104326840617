<div class="envisiasite">
  <div class="errorsite">
    <div class="error-img">
      <i class="fa fa-ban text-danger"></i>
    </div>
    <div class="error-status">
      <h2>Unauthorized</h2>
      <h1>Zugriff verweigert</h1>
    </div>
    <div class="error-message">
      <ng-container *ngIf="additional === 'empty.fields'">
        Ihnen wurde kein Vertriebsgebiet zugeordnet.<br>
        Bitte wenden Sie sich an die Geschäftsleitung oder an den Administrator.
      </ng-container>
      <ng-container *ngIf="!additional">
        Ihnen sind keine Benutzerberechtigungen für diese Anwendung zugeteilt. <br>
        Bitte wenden Sie sich an die Geschäftsleitung oder an den Administrator, falls Sie diese benötigen.
      </ng-container>
    </div>
    <div class="error-link">
      <button class="btn btn-default btn-lg" (click)="back()">
        <i class="fa fa-arrow-left"></i>Zurück
      </button>
      <button class="btn btn-success btn-lg" (click)="logout()">
        Abmelden
      </button>
      <button class="btn btn-success btn-lg" (click)="home()">
        Startseite
      </button>
    </div>
  </div>
  <div class="footer">
    <a href="http://www.envisia.de" target="_blank">www.envisia.de</a>
  </div>
</div>
