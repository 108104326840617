/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, ErrorHandler, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StateService} from '@uirouter/core';
import {LabelService} from './label.service';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {validateInt} from '../../common/form/int.validator';
import {Customer} from '../customer/model/customer';
import {ArticleListData} from '../article/models/article.models';
import Helper from '../../helper/helper';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../common/alert-service/alert.service';
import {LabelPrinterData} from './label.models';

const arrayMinLength = (n: number): (c: AbstractControl) => ValidationErrors | null => {
  return (c: AbstractControl): ValidationErrors | null => {
    if ((c as UntypedFormArray).length >= n) {
      return null;
    }

    return {arrayMinLength: {valid: false}};
  };
};

@Component({
  selector: 'label-create',
  templateUrl: './label-create.component.html',
  styles: [
    'input.ng-invalid:not(form) { background-color: #f2dede !important; border-color: #ebccd1 !important; color: #a94442 !important; }',
    'select.ng-invalid:not(form) { background-color: #f2dede !important; border-color: #ebccd1 !important; color: #a94442 !important; }',
  ]
})
export class LabelCreateComponent implements OnInit {
  @Input() printerData: LabelPrinterData | null | undefined;
  @Output() labelPreview = new EventEmitter<string>();
  settingsMode = false;
  // UI stuff
  errors: { [key: string]: any; } = {};
  form: UntypedFormGroup;
  // settings mode stuff
  headerLabel = 'Etikettendruck';
  buttonLabel = 'Drucken';
  errorName = 'obj';
  submitting = false;
  templateError = false;

  constructor(private stateService: StateService,
              private labelService: LabelService,
              private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private errorHandler: ErrorHandler) {
  }

  ngOnInit(): void {
    if (!Helper.undefined(this.printerData)) {
      this.settingsMode = true;
    }

    if (this.settingsMode) {
      this.headerLabel = 'Vorschau';
      this.buttonLabel = this.headerLabel;
      this.errorName = 'obj.form';
    }

    this.form = this.fb.group({
      'customer': [null, Validators.required],
      'article': [null, Validators.required],
      'ba_nr': [null],
      'inventory': [null],
      'quantity_total': [null, validateInt],
      'best_nr': [null],
      'e_test': [null, Validators.required],
      'quantity_ve': [null, validateInt],
      'prod_date': [null, Validators.compose([Validators.required, Validators.pattern(/\d{2}\/\d{2}/)])],
      'quantity_utilize': [null, validateInt],
      'objects': this.fb.array([], arrayMinLength(1)),
    });
  }

  updateArticle(data: { article: ArticleListData, customer?: Customer } | null): void {
    if (data !== null && data.customer !== null && data.customer !== undefined) {
      this.form.patchValue({'customer': data.customer});
    }
  }

  canCreateTable(): boolean {
    return !this.submitting &&
      (Helper.isInt(this.form.value.quantity_total) && Helper.isInt(this.form.value.quantity_ve));
  }

  createTable(): void {
    if (!this.canCreateTable()) {
      return;
    }

    const quantityTotal = Helper.toInt(this.form.value.quantity_total);
    const quantityVE = Helper.toInt(this.form.value.quantity_ve);

    const fullRows = quantityTotal > quantityVE ? Math.floor(quantityTotal / quantityVE) : 1;
    const rest = quantityTotal > quantityVE ? quantityTotal % quantityVE : 0;

    for (let i = 0; i < fullRows; i++) {
      this.appendRow(quantityVE);
    }

    if (rest > 0) {
      this.appendRow(rest);
    }
  }

  addColumn(): void {
    this.appendRow(0);
  }

  deleteAllRows(): void {
    this.objects.clear();
    Helper.keys(this.errors, (value, key) => {
      if (key.startsWith(this.errorName + '.objects')) {
        delete this.errors[key];
      }
    });
  }

  deleteColumn(index: number): void {
    this.objects.removeAt(index);
    Helper.keys(this.errors, (value, key) => {
      if (key.startsWith(this.errorName + '.objects[' + index + ']')) {
        delete this.errors[key];
      }
    });
  }

  submit(): void {
    this.templateError = false;
    this.submitting = true;
    this.form.disable();
    const errorFunction = (response: HttpErrorResponse) => {
      this.errorHandler.handleError({error: response, message: 'invalid http response'});
      if (response.status > 400) {
        if (this.settingsMode) {
          this.alertService.add('danger', 'Beim erstellen der Vorschau ist ein Fehler aufgetreten!');
        } else {
          this.alertService.add('danger', 'Beim Drucken der Etiketten ist ein Fehler aufgetreten!');
        }
      } else {
        this.alertService.add('danger', 'Bitte überprüfen Sie Ihre Eingabe!');
      }
      // this.form.setErrors()
      this.form.enable();
      this.submitting = false;

      if (response.error) {
        this.templateError = !Helper.undefined(Helper.key(response.error, 'obj.data.template'));

        Helper.keys(response.error, (value, key) => {
          const regEx = new RegExp(this.errorName + '.(\\w*)(\\[)?(\\d+)?(\\])?(\\.)?(.*)?', 'gm');
          const regexValue = regEx.exec(key);
          let ctrl = null;
          const regexLength = regexValue === null ? 0 : regexValue.filter(v => v !== null && v !== undefined).length;
          if (regexLength === 2) {
            ctrl = this.form.get(regexValue[1]) as UntypedFormControl;
          } else if (regexLength === 5) {
            const arrayCtrl = this.form.get(regexValue[1]) as UntypedFormArray;
            if (!!arrayCtrl) {
              ctrl = arrayCtrl.at(Helper.toInt(regexValue[3])) as UntypedFormControl;
            }
          } else if (regexLength === 7) {
            const arrayCtrl = this.form.get(regexValue[1]) as UntypedFormArray;
            if (!!arrayCtrl) {
              const groupCtrl = arrayCtrl.at(Helper.toInt(regexValue[3])) as UntypedFormGroup;
              if (!!groupCtrl) {
                ctrl = groupCtrl.get(regexValue[6]) as UntypedFormControl;
              }
            }
          }
          if (ctrl !== null) {
            ctrl.setErrors({invalidRemoteValue: true});
          }
        });

      }

      if (this.settingsMode) {
        this.labelPreview.emit(null);
      }
    };

    const successFn = () => {
      this.form.enable();
      this.submitting = false;
    };

    if (this.settingsMode) {
      this.labelService.preview({data: this.printerData, form: this.form.value}).subscribe(value => {
        this.alertService.add('success', 'Vorschau wurden erfolgreich erstellt!');
        successFn();
        this.labelPreview.emit(value.object);
      }, errorFunction);
    } else {
      this.labelService.print(this.form.value).subscribe(() => {
        this.alertService.add('success', 'Etiketten wurden erfolgreich gedruckt!');
        successFn();
      }, errorFunction);
    }
  }

  clearForm(): void {
    this.form.reset();
    this.form.enable();
    this.submitting = false;
  }

  get objects(): UntypedFormArray {
    return this.form.get('objects') as UntypedFormArray;
  }

  private appendRow(quantity: number): void {
    this.objects.push(this.fb.group({
      'quantity': [quantity, Validators.compose([Validators.required, validateInt])],
      'utilize': [0, Validators.compose([Validators.required, validateInt])],
      'schlechte': [0, Validators.compose([Validators.required, validateInt])],
      'inventory': [this.form.value.inventory]
    }));
  }

}
