<h3>Vertriebsgebiet</h3>
<div class="col-sm-6" [formGroup]="form">

  <select class="form-control" id="id_fieldservice"
          formControlName="fieldService"
          [ngClass]="{red_alert: error}">
    <option [ngValue]="null">-- Vertriebsgebiet auswählen --</option>
    <option *ngFor="let field of fieldServices" [value]="field.name">{{ field.name }}</option>
  </select>

</div>

<div class="col-sm-6 chrissies">
  <h4 class="padding-top-5" *ngIf="fieldServiceUser">
    - {{ fieldServiceUser.firstName }} {{ fieldServiceUser.lastName }}
  </h4>
</div>
