import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject} from '../../../common/wrapper.models';
import {Article} from '../models/article.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ArticleV3Service {

  constructor(private http: HttpClient) {
  }

  findSister(sdsNr: string): Observable<Article> {
    return this.http.get<EnvisiaObject<Article>>('/article/v2/article/sds/' + sdsNr + '/').pipe(
      map(obj => obj.object)
    );
  }

  duplicates(data: { oa_nr?: string, sds_nr?: string, kd_art_nr?: string, kd_art_index?: string }): Observable<void> {
    return this.http.put<void>('/article/v2/article/sds/duplicates/', data);
  }

}
