<div class="modal-header">
  <h3 class="modal-title">Preis aus Preisliste</h3>
</div>


<form name="article" class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="row">
      <div class="col-xs-1">
        Für die Stückzahl
      </div>
      <div class="col-xs-1">
        <select type="number" class="form-control input-sm" id="quantity" name="quantity"
                formControlName="quantity">
          <option *ngFor="let q of showQuantities()" [ngValue]="q" [selected]="q === selectedQuantity">
            {{q}}
          </option>
        </select>

      </div>
      <div class="col-xs-1">
        mit der Losemenge
      </div>
      <div class="col-xs-1">
        <select type="number" class="form-control input-sm" id="lose" name="lose" formControlName="lose" (change)="toggleQuantity()">
          <option *ngFor="let q of showLoses()" [ngValue]="q" [selected]="q === selectedLose">
            {{q}}
          </option>
        </select>
      </div>
      <div class="col-xs-3">
        existiert bereits ein Preis in der Preisliste:
      </div>
    </div>

    <div>&nbsp;</div>

    <div class="pricecheck-modal-row">

      <div class="pricecheck-modal-empty">
        <h3>Preisliste</h3>
      </div>

      <div class="pricecheck-modal-empty">
        <h3>Neu kalkulierter Preis</h3>
      </div>

    </div>

    <div class="pricecheck-modal-row" *ngIf="positionData.de.releases && showDe()">
      <div class="pricecheck-modal-empty">
        <span class="bold col-sm-12 headline"><img src="/ui/assets/images/flags/de.png" title="DE"> Deutschland</span>
        <table class="table whitetable">
          <thead>
            <tr>
              <th>K-ME</th>
              <th>Lose</th>
              <th>Freigabe</th>
              <th>Einzel €</th>
              <th>Kalk</th>
              <th>Datum</th>
              <th>Notiz</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let release of positionData.de.releases"
                [ngClass]="{'selected': isSelected(false, 'de', release?.release)}"
                class="selectable-row"
                (click)="select(false, 'de', release.release, release.notes)">
              <td *ngIf="release.calc_quanity"> {{ release.calc_quanity }} </td>
              <td *ngIf="!release.calc_quanity">{{release.quanity}}</td>
              <td> {{ release.lose }} </td>
              <td> {{ release.release }} </td>
              <td> <strong>{{ release.single }}</strong> </td>
              <td> <strong>{{ release.calc }}</strong> </td>
              <td> {{ release.date|date: "dd.MM.yyyy" }} </td>
              <td class="notes-truncated-modal" title="{{release.notes}}">
                <span class="notes-inner" *ngIf="release.notes">
                {{ release.notes }}
                </span> &nbsp;
              </td>
              <td>
                <div class="release-warning">
                  <span *ngIf="articleChange(release.article_hash)"> Artikeländerung. </span>
                  <span *ngIf="calculationChange(release.calc, 'de')"> Kalkulation wurde geändert! </span>
                  <span *ngIf="priceChange('de', release)"> Es wurde eine Preiserhöhung angewandt. </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table class="table whitetable">
          <thead>
          <tr>
            <th>K-ME</th>
            <th>Lose</th>
            <th>Freigabe</th>
            <th>Einzel €</th>
            <th>Kalk</th>
          </tr>
          </thead>
          <tbody>
            <tr class="selectable-row" [ngClass]="{'selected': isSelected(true, 'de', null)}" (click)="select(true, 'de', null)">
              <td>{{positionData.de.calc_quanity}}</td>
              <td> {{ positionData.de.releases[0]?.lose }}</td>
              <td> &nbsp; </td>
              <td><strong>{{positionData.de.calculated?.price}}</strong></td>
              <td><strong>{{positionData.de.calc}}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="pricecheck-modal-row" *ngIf="positionData.cn.releases && showCn()">
      <div class="pricecheck-modal-empty">
        <span class="bold col-sm-12 headline"><img src="/ui/assets/images/flags/cn.png" title="DE"> China</span>
        <table class="table whitetable">
          <thead>
          <tr>
            <th>K-ME</th>
            <th>Lose</th>
            <th>Freigabe</th>
            <th>Einzel €</th>
            <th>Kalk</th>
            <th>Datum</th>
            <th>Notiz</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let release of positionData.cn.releases"
              [ngClass]="{'selected': isSelected(false, 'cn', release?.release)}" class="selectable-row"
              (click)="select(false, 'cn', release.release, release.notes)">
            <td *ngIf="release.calc_quanity"> {{ release.calc_quanity }} </td>
            <td *ngIf="!release.calc_quanity">{{release.quanity}}</td>
            <td> {{ release.lose }} </td>
            <td> {{ release.release }}</td>
            <td> <strong>{{ release.single }}</strong> </td>
            <td> <strong>{{ release.calc }}</strong> </td>
            <td> {{ release.date|date: "dd.MM.yyyy" }} </td>
            <td class="notes-truncated-modal" title="{{release.notes}}">
              <span class="notes-inner" *ngIf="release.notes">
                {{ release.notes }}
                </span> &nbsp;
            </td>
            <td>
              <div class="release-warning">
                <span *ngIf="articleChange(release.article_hash)"> Artikeländerung. </span>
                <span *ngIf="calculationChange(release.calc, 'cn')"> Kalkulation wurde geändert! </span>
                <span *ngIf="priceChange(release?.last_price_id, 'cn')"> Es wurde eine Preiserhöhung angewandt. </span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table class="table whitetable">
        <thead>
        <tr>
          <th>K-ME</th>
          <th>Lose</th>
          <th>Freigabe</th>
          <th>Einzel €</th>
          <th>Kalk</th>
        </tr>
        </thead>
        <tbody>
          <tr [ngClass]="{'selected': isSelected(true, 'cn', null)}"
              (click)="select(true, 'cn', null)"
              class="selectable-row">
            <td>{{positionData.cn.calc_quanity}}</td>
            <td> {{ positionData.cn.releases[0]?.lose }}</td>
            <td> &nbsp; </td>
            <td><strong>{{positionData.cn.calculated?.price }}</strong></td>
            <td><strong>{{positionData.cn.calc}}</strong></td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>


  </div>

  <div class="modal-footer">

    <button class="btn btn-default pull-left" type="button"
            (click)="dismiss()">
      Abbrechen
    </button>

    <button class="btn btn-success pull-right" type="submit">
      Übernehmen
    </button>

  </div>

</form>
