import {Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MaterialGroupListElement, MaterialValueType} from '../../../material/material-management.model';
import {
  MaterialPlanningListAbstractComponent,
} from '../../../material/planning/material-planning-list.abstract-component';
import {
  MaterialPlanningDate,
  MaterialPlanningListElement,
  MaterialPlanningListQuery,
  MaterialSelect,
  MaterialSelectLister,
} from '../../../material/planning/material-planning.model';
import {BusinessOrderMaterialPlanningListService} from './business-order-material-planning-list.service';
import {MaterialManagementService} from '../../../material/material-management.service';
import {UntypedFormBuilder} from '@angular/forms';
import {MaterialPlanningWeekListPaginator} from '../../../material/planning/material-planning-week-list-paginator';
import {MaterialArticleNamesListService} from '../../../material/articles/material-article-names-list.service';
import {debounceTime, map} from 'rxjs/operators';
import {Lister} from '../../../../common/wrapper.models';
import {Observable} from 'rxjs';

@Component({
  selector: 'business-order-material-unlimited-list',
  templateUrl: './business-order-material-unlimited-list.component.html',
  styleUrls: ['../../../material/planning/material-planning.styles.scss']
})
export class BusinessOrderMaterialUnlimitedListComponent
  extends MaterialPlanningListAbstractComponent
  implements OnInit, OnChanges {
  @Input() groups: Lister<MaterialGroupListElement>;
  @Input() params: MaterialPlanningListQuery;
  @Input() selectionData: MaterialSelect;
  @Input() fieldValues: { id: number, value: string }[];
  @Input() fieldList: MaterialValueType[];
  @Input() currentSupplierId?: number;
  @Input() lockSelection: boolean;
  @Output() emitSelect = new EventEmitter<MaterialPlanningListElement>();
  @Output() emitSelectWeek = new EventEmitter<{ week: MaterialPlanningDate, variationId: number } | null>();

  currentGroupId: number;
  missingChoices: { [key: string]: string } = {};

  public filterFormErrors: { [key: string]: any } = {};

  constructor(protected service: BusinessOrderMaterialPlanningListService,
              protected errorHandler: ErrorHandler,
              protected mms: MaterialManagementService,
              protected manls: MaterialArticleNamesListService,
              protected fb: UntypedFormBuilder) {
    super(service, errorHandler, mms, manls, fb);
  }

  ngOnInit() {
    this.params.group_id = this.params.group_id ?? this.currentGroupId;
    this.params.supplier_id = this.params.supplier_id ?? this.currentSupplierId;
    this.params.page = 1;
    super.ngOnInit();
    this.patchDynamicFields(this.params, true, true).subscribe(patched => {
      this.form.patchValue(patched, {emitEvent: false});
      this.serviceCall();
    });
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(200)).subscribe(() => this.search());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.params && !changes.params.isFirstChange()) {
      this.reloadNewProps();
    }
  }

  reloadNewProps(): void {
    this.selectedWeek = null;
    this.selectedVariationId = null;
    this.patchDynamicFields(this.params, true, true).subscribe(patched => {
      this.form.patchValue({...patched}, {emitEvent: false});
      this.serviceCall();
    });
  }

  patchDynamicFields(
    value: MaterialPlanningListQuery,
    forceReload?: boolean,
    keepArticleName?: boolean,
  ): Observable<MaterialPlanningListQuery> {
    this.dropLastRequest();
    const supplierChanged = !!forceReload || (value.supplier_id !== this.currentSupplierId);
    if (supplierChanged) {
      this.currentSupplierId = value.supplier_id;
    }

    const groupChanged = !!forceReload || (value.group_id !== this.currentGroupId);
    if (groupChanged) {
      this.currentGroupId = value.group_id;
    }

    const articleNames = (supplierChanged || groupChanged) ? this.loadArticleNames() : this.articleNames.data$;
    this.setFieldControls(this.fieldList);
    return articleNames.pipe(map(articleNames => {
      const removeArticleName = !keepArticleName && (
        (supplierChanged || groupChanged || !articleNames.find(n => n === value.article_name))
      );
      return {
        ...value,
        article_name: removeArticleName ? null : value.article_name,
      };
    }));
  }

  protected postCall(data: MaterialSelectLister): void {
    if (!this.weekPaginator) {
      this.weekPaginator = new MaterialPlanningWeekListPaginator(data.weeks);
    } else {
      this.weekPaginator.reset(data.weeks);
    }
    this.weekPaginator.weekScrollPage(this.selectionData.weeks_to_bo_start);
  }

  setSelectedWeek(week: MaterialPlanningDate | null, variationId: number) {
    this.selectedWeek = week;
    this.selectedVariationId = variationId;
    this.emitSelectWeek.emit({week: week, variationId: variationId});
  }
}
