/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {AdapterListComponent} from './list/adapter-list.component';
import {AdapterService} from './services/adapter.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {ErpTransition} from '../../../core/erp-transition.service';

export const ADAPTER_STATE: Ng2StateDeclaration = {
  name: 'a.adapter',
  abstract: true,
  data: {requiresAuth: true}
};

export function adapterListResolveFn(transition: ErpTransition, service: AdapterService) {
  return service.list(transition.params()).toPromise();
}

export const ADAPTER_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.adapter.list',
  url: '/workflow/adapter/?page&order&sort&date_end&inventory&customer&good_piece&workflow_id&sds_nr&sid&delivery_date&next_step&work_days&current_step&e_test',
  views: {'main@a': {component: AdapterListComponent}},
  resolve: [
    {token: 'listData', resolveFn: adapterListResolveFn, deps: [ErpTransition, AdapterService]}
  ],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    sid: {dynamic: true},
    sds_nr: {dynamic: true},
    customer: {dynamic: true},
    workflow_id: {dynamic: true},
    good_piece: {dynamic: true},
    end_date: {dynamic: true},
    delivery_date: {dynamic: true},
    article_measuring_device: {dynamic: true},
    last_step_end_date: {dynamic: true},
    next_step: {dynamic: true},
    inventory: {dynamic: true},
    current_step: {dynamic: true},
    e_test: {dynamic: true}
  },
  data: {requiresAuth: true}
};


export const ADAPTER_STATES: Ng2StateDeclaration[] = [
  ADAPTER_STATE,
  ADAPTER_LIST_STATE
];

