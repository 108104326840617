<h3>Branche</h3>
<form [formGroup]="myForm">
  <div class="col-sm-12 chrissies">
    <div class="row">
      <div class="col-sm-12">
        <ngx-bootstrap-multiselect [options]="options" [texts]="texts" [settings]="settings" formControlName="optionsModel">
        </ngx-bootstrap-multiselect>
      </div>
    </div>
  </div>
</form>



