import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {DateHelper} from '../../../helper/date-helper';
import {map} from 'rxjs/operators';
import {ArticleWorkdayCalculation, ArticleWorkdayCalculationRaw} from './article-workday-calculation.model';

@Injectable({
  providedIn: 'root'
})
export class ArticleWorkdayCalculationService {

  constructor(private http: HttpClient) {
  }

  public endDate(oaNr: string): Observable<ArticleWorkdayCalculation> {
    return this.http.get<ArticleWorkdayCalculationRaw>(
      `${environment.apiv4uri}article/${oaNr}/workdays/calculate/enddate`
    )
      .pipe(map(raw => {
        return {
          workdays: raw.workdays,
          today: DateHelper.safeParse(raw.today),
          end_date: DateHelper.safeParse(raw.end_date),
        };
      }));
  }
}
