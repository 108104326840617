import * as React from 'react';
import {MaterialGroup} from '../../groups/material-group.model';

interface Props {
  materialGroup: MaterialGroup;
  selectedGroupIds: number[];
  click: () => void;
}

export function MaterialProductGroupsTabListButton(props: Props) {
  const className = !!props.selectedGroupIds.find(s => s === props.materialGroup.id) ?
    'btn btn-sm btn-info' :
    'btn btn-sm btn-default';

  return <button type={'button'}
                 className={className}
                 onClick={() => props.click()}>
    {props.materialGroup.group_id} {props.materialGroup.name}
  </button>;
}
