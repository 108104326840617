import {MaterialArticleFormBuilder} from './material-article-form-builder';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {
  MaterialArticleError,
  MaterialArticleForm,
  MaterialArticleVariationError, MaterialGroupListElement,
  MaterialValueType
} from '../material-management.model';
import {MaterialManagementService} from '../material-management.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {StateService} from '@uirouter/core';

@Component({
  selector: 'material-article-create-form-holder',
  templateUrl: './material-article-create-form-holder.component.html'
})
export class MaterialArticleCreateFormHolderComponent extends MaterialArticleFormBuilder implements OnInit {
  @Input() articleFieldDefs: MaterialValueType[];
  @Input() currentGroup: MaterialGroupListElement;
  form: UntypedFormGroup;
  error: MaterialArticleVariationError;

  constructor(
    protected fb: UntypedFormBuilder,
    private mmService: MaterialManagementService,
    private alertService: AlertService,
    private stateService: StateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.buildArticleGroup(this.currentGroup.id, this.articleFieldDefs);
  }

  onSubmit() {
    const form: MaterialArticleForm = this.form.getRawValue();

    this.mmService.createArticle({article_form: form}).subscribe((response) => {
      this.error = null;
      this.alertService.add(
        'success',
        response.article.article_id + ' ' + response.article.name + ' wurde erfolgreich hinzugefügt'
      );
      this.stateService.go('a.material.article.update', {article: response.article.id});
    }, (response) => {
      const error = response.error as MaterialArticleError;
      if (error && error.error_type) {
        this.error = error;
      }
    });
  }

}
