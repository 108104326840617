<div class="filter-row">
  <div class="filter-left">
    <button class="btn btn-default btn-sm" type="button" (click)="badge('all')" [ngClass]="{active: q('all') === true}">
      Alle
    </button>
    <button class="btn btn-danger btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1') === true}">
      Offene
    </button>
    <button class="btn btn-success btn-sm" type="button" (click)="badge('2')" [ngClass]="{active: q('2') === true}">
      Bezahlt
    </button>
    <button class="btn btn-primary btn-sm" type="button" (click)="badge('3')" [ngClass]="{active: q('3') === true}">
      Storno Gutschrift
    </button>

    <ev-file-button [url]="downloadUrl('/api/v1/invoice/correction/export/')" [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>
  </div>

  <div class="filter-right">
    <date-range [start]="null" [end]="null" (update)="updateDateRange($event)"></date-range>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'id', 'DESC')" [href]="sortUrl('id', 'DESC')">
          Gutschrift-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'date', 'DESC')" [href]="sortUrl('date', 'DESC')">
          Gutschrift-Datum
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'ASC')" [href]="sortUrl('customer_name', 'ASC')">
          Kunden-Name
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_id', 'ASC')" [href]="sortUrl('invoice_id', 'ASC')">
          Rechnungs-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'label', 'ASC')" [href]="sortUrl('label', 'ASC')">
          Bezeichnung
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'revenue', 'ASC')" [href]="sortUrl('oa_nr', 'ASC')">
          Gesamt-Betrag
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'expensed', 'ASC')" [href]="sortUrl('expensed', 'ASC')">
          Buchungssumme
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'expensed_date', 'ASC')" [href]="sortUrl('expensed_date', 'ASC')">
          Buchungsdatum
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'note', 'ASC')" [href]="sortUrl('note', 'ASC')">
          Notiz
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'username', 'ASC')" [href]="sortUrl('username', 'ASC')">
          Ersteller
        </a>
      </th>
      <th class="icon"></th>
    </tr>
    <tr class="filterrow">
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.correction_id"
               name="correction_id" [autofocus]>
      </th>
      <th>
        <ev-datepicker [(ngModel)]="data.date" name="date" class="form-control input-sm" [allDates]="true">
        </ev-datepicker>
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_name" name="customer_name">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.invoice_id" name="invoice_id">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.label" name="label">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.revenue" name="revenue">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.expensed" name="expensed">
      </th>
      <th>
        <ev-datepicker [(ngModel)]="data.expensed_date" name="expensed_date" class="form-control input-sm"
                    [allDates]="true">
        </ev-datepicker>
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.note" name="note">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.username" name="username">
      </th>
      <th class="icon"></th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let object of list.objects; let index = index">
      <td>
        <ev-file-button [objId]="object.id" type="invoice_correction">
          {{ object.id }}
          <span class="label label-success" *ngIf="object.expensed_date">Bezahlt</span>
        </ev-file-button>
      </td>
      <td>{{ object.date }}</td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}">
          {{ object.customer_name }}
        </a>
      </td>
      <td>
        <ev-file-button [objId]="object.invoice_id" type="invoice">
          {{ object.invoice_id }}
        </ev-file-button>
      </td>
      <td>{{ object.label }}</td>
      <td><strong>{{ object.revenue }}</strong></td>
      <td><strong>{{ object.expensed }}</strong></td>
      <td>{{ object.expensed_date }}</td>
      <td>{{ object.note }}</td>
      <td>{{ object.username }}</td>
      <td>
        <button type="button" class="btn btn-sm btn-link" (click)="history(object)">
          <i class="fa fa-history"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
