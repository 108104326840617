<div class="row">

  <div class="col-lg-12">
    <div class="row">
      <div class="col-lg-2">
        <h1>
          BA: {{ ba.id }}-{{ ba.index }} / {{ ba.amount }}
          <ev-file-button *ngIf="ba.status !== 0"
                          [url]="'/api/v1/workflow/business/order/pdf/' + ba.id + '/' + ba.index+ '/'"
                          [link]="true">
            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
          </ev-file-button>
        </h1>
      </div>
      <div class="col-lg-10 ba-label-row">
        <span class="label-spacer">&nbsp;</span>
        <workflow-state [key]="ba.status" type="business_order_status"></workflow-state>
        <!-- 'data.extra['initial-sample-testing'].empb' uses the old layout, since it's proxied -->
        <business-order-label size="big"
                              [surface]="data.article.data.surface_area"
                              [empb]="!!data.extra['initial-sample-testing'].empb && !!ba.first_contract"
                              [kanban]="!!data.kanban"
                              [hasPlugging]="has_plugging"
                              [hasCopperFilling]="has_copper_filling"
                              [hasReproduction]="!!data.reproduction"
                              [hasBlindHole]="!!data.article.data.blind_hole_tech_ls || !!data.article.data.blind_hole_tech_ds">
        </business-order-label>
      </div>
    </div>
  </div>

  <div *ngIf="workflowWarning" class="col-lg-12">
    <h3>
      <span class="label label-danger" *ngIf="data.workflow_status === 100">Achtung: Vorgang wurde storniert!</span>
      <span class="label label-danger" *ngIf="data.workflow_status === 101">Achtung: Vorgang wurde gelöscht!</span>
    </h3>
  </div>

  <div class="col-lg-12" *ngIf="ba.status > 0">
    <h2>Produktionsverlauf</h2>
  </div>

</div>

<div class="row">

  <!-- Production Steps -->
  <div class="col-lg-6">
    <business-order-view-step-list [ba]="ba"
                                   [steps]="steps"
                                   [reproduction]="reproduction"
                                   [filmInventory]="data.film_inventory"
                                   (updateGoodPiece)="updateGoodPiece()"
                                   (updateData)="updateData()"
                                   (updateReproduction)="updateReproduction($event)"
                                   *ngIf="ba.status > 0">
    </business-order-view-step-list>
  </div>

  <!-- Info Box-->
  <div class="col-lg-4">
    <div class="row">
      <div class="col-lg-6">
        <business-order-view-box [ba]="ba"
                                 [additional]="data.additional"
                                 [fetches]="fetches"
                                 [reproduction]="reproduction"
                                 [goodpieces]="goodpieces"
                                 (goodpiecesChange)="setGoodPiece($event)">
        </business-order-view-box>
      </div>
      <div class="col-lg-6">
        <business-order-view-article-box [data]="data"></business-order-view-article-box>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <business-order-view-material-box [ba]="ba">
        </business-order-view-material-box>
      </div>
    </div>
  </div>
  <div class="col-lg-2">
    <comment [comments]="comments" [item]="item" typ="business_order"></comment>
  </div>

</div>
