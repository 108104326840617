/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';
import Helper from '../../../helper/helper';

export const ARTICLE_EXTRA_TEXT_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleExtraTextComponent),
  multi: true,
};

export const ARTICLE_EXTRA_TEXT_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ArticleExtraTextComponent),
  multi: true
};

@Component({
  selector: 'article-extra-text',
  templateUrl: './article-extra-text.component.html',
  providers: [
    ARTICLE_EXTRA_TEXT_ACCESSOR,
    ARTICLE_EXTRA_TEXT_VALIDATOR,
  ]
})
export class ArticleExtraTextComponent implements ControlValueAccessor, Validator {
  @Input() type = 'string';
  @Input() unit?: string;
  @Input() disabled = false;
  parseError = false;
  parseValue: any;
  current?: any;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  writeValue(obj: any): void {
    this.parseError = false;
    if (obj === null || obj === undefined) {
      this.current = null;
      return;
    }
    const normalizedValue = Helper.isInt(obj) ? Helper.toInt(obj).toString() : obj;
    this.current = normalizedValue;
    this.parseValue = normalizedValue;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(c: AbstractControl): ValidationErrors | any {
    if (this.parseError) {
      return {'integer': {invalid: this.parseValue}};
    } else {
      return null;
    }
  }

  onChange(value: any): void {
    this.parseError = false;
    let cleaned;
    if (value === '') {
      cleaned = null;
    } else if (this.type === 'int') {
      if (Helper.isInt(value)) {
        cleaned = parseInt(value, 10);
      } else {
        this.parseError = true;
        cleaned = value;
      }
    } else {
      cleaned = value;
    }

    this.parseValue = cleaned;
    this._onChange(cleaned);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    return this.disabled;
  }

}
