import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrder, BusinessOrderReproduction} from '../../models/business-order.models';
import {from, Observable} from 'rxjs';
import {BusinessOrderViewMergeModalComponent} from './business-order-view-merge-modal.component';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderViewMergeModalService {

  constructor(private modalService: NgbModal) { }

  open(businessOrder: BusinessOrder, reproduction: BusinessOrderReproduction): Observable<void> {
    const modalRef = this.modalService.open(BusinessOrderViewMergeModalComponent, {windowClass: 'modal2-mm'});
    modalRef.componentInstance.businessOrder = businessOrder;
    modalRef.componentInstance.reproduction = reproduction;
    return from(modalRef.result);
  }
}
