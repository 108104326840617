/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {Component} from '@angular/core';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-over-under-delivery',
  templateUrl: './article-extra-over-under-delivery.component.html'
})
export class ArticleExtraOverUnderDeliveryComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'over_under_delivery';
  protected schemaName = 'over-under-delivery';
  protected fields = [
    'over_delivery',
    'under_delivery',
  ];
}
