<div class="modal-header">
  <h3 class="modal-title">
    Preis ist nicht freigegeben
  </h3>
</div>

<div class="modal-body">
  <p>
    <i class="fa fa-warning"></i> Der Preis von {{ price }}
    für die Stückzahl {{ position.quanity }} ist nicht freigegeben
  </p>

  <p class="bold">
    Es existiert kein aktueller Preis für diese Menge in der Preisliste. Bitte wenden Sie sich an die Preisprüfung.
  </p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="dismiss()">
    Abbrechen
  </button>
</div>
