/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
export function htmlCssOrJs(url: string): boolean {
  const idx = url.lastIndexOf('.');
  const type = url.substr(idx + 1);
  return type === 'js' || type === 'html' || type === 'css';
}

/**
 * This can decode Base4 String with Unicode characters
 *
 * @param str the base64 string
 * @returns {string} the string with unicode characters
 */
export function b64DecodeUnicode(str: string): string {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export function userFromToken(token: string): any {
  const index = token.indexOf('.');
  const lastIndex = token.lastIndexOf('.');
  return JSON.parse(b64DecodeUnicode(token.substring(index + 1, lastIndex)));
}
