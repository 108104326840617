<div class="modal-header">
  <div class="modal-title"> Gutstück Menge ändern </div>
</div>

<form [formGroup]="form" (ngSubmit)="submit()" class="form form-horizontal">
  <div class="modal-body">
    <div class="form-group">
      <label for="id_good_piece" class="col-sm-3 control-label">Gutstück Menge</label>
      <div class="col-sm-9">
        <input id="id_good_piece" min="0" type="number" class="form-control" formControlName="good_piece">
      </div>
      <div>&nbsp;</div>
      <label for="id_comment" class="col-sm-3 control-label">Kommentar</label>
      <div class="col-sm-9">
        <textarea id="id_comment" class="form-control" formControlName="comment"></textarea>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success btn-sm pull-right">
      Übernehmen
    </button>
  </div>
</form>

