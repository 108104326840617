import {UntypedFormControl, ValidationErrors} from '@angular/forms';
import Helper from '../../helper/helper';

export function validateInt(c: UntypedFormControl): ValidationErrors | null {
  const invalid = !Helper.isInt(c.value) && !Helper.undefined(c.value);

  if (invalid) {
    return {
      validateInt: {
        valid: false
      }
    };
  }

  return null;
}
