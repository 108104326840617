import {Injectable} from '@angular/core';
import {LocalStorage} from '../common/storage/local-storage';
import {DateHelper} from '../helper/date-helper';

const message = 'Es sind noch ungespeicherte Änderungen vorhanden, möchten Sie diese verwerfen?';

const changeListener = function (event) {
  event.returnValue = message;
  return message;
};

@Injectable({providedIn: 'root'})
export class GlobalService {
  public show = false;
  private _save = false; // remove that field if we remove angularjs
  private activated = false;

  constructor(private localStorage: LocalStorage) {
  }

  get save(): boolean {
    return this._save;
  }

  set save(value: boolean) {
    if (value === true) {
      setTimeout(() => {
        this._save = false;
      }, 2500);
    }
    this._save = value;
  }

  addEvents(): void {
    this.activated = true;
    window.addEventListener('beforeunload', changeListener);
  }

  removeEvents(): void {
    this.activated = false;
    window.removeEventListener('beforeunload', changeListener);
  }

  isActivated(): boolean {
    return this.activated;
  }

  onExitCaller(): boolean {
    let ret = true;

    if (this.isActivated()) {
      ret = window.confirm(message);
    }
    if (ret) {
      this.activated = false;
      this.removeEvents();
    }
    return ret;
  }

  /** opos global data */
  getOposDateRaw(): string | undefined | null {
    return this.localStorage.get('global_opos_date');
  }

  getOposDate(): Date | null {
    const date = this.localStorage.get('global_opos_date');
    if (date !== undefined && date !== null) {
      return DateHelper.safeParse(date);
    } else {
      return null;
    }
  }

  setOposDate(date: Date): void {
    this.localStorage.set('global_opos_date', DateHelper.format(date));
  }

}
