<div class="modal-header">
  <h3 class="modal-title">Produktspez. Liefervorschriften bearbeiten</h3>
</div>

<form [formGroup]="commentForm">
  <div class="modal-body">

    <div class="row">
      <div class="col-md-12">
        <label for="comment_text">Kommentar-Text</label>
        <textarea value="" [autofocus]="true" name="comment_text" id="comment_text" class="form-control" formControlName="commentText"
                  [ngClass]="{red_alert: errors['obj.infoText']}"></textarea>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-left" (click)="dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-success pull-right" [disabled]="validate()" (click)="save()">
      Speichern
    </button>
  </div>

</form>

