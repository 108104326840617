<div class="task" *ngIf="data.workflow">
  <h2>
    <a uiSref="a.workflow.detail" [uiParams]="{id: data.workflow.id}" ngbTooltip="Zurück zum Vorgang">
      <i class="fa fa-chevron-left"></i>
      Vorgang: {{ data.workflow.id }}
      <!-- We need to explicitly write "last" offer since: https://git.envisia.de/erp/loki/issues/448 -->
      letztes Angebot: {{ data.lastOffer?.id }} vom {{ data.lastOffer?.date }}
    </a>
  </h2>

  <h1>Auftrag aus Angebot</h1>
  Zu bestätigende Position aus Angebot wählen<br><br>

  <div>
    <table class="table whitetable">
      <thead>
      <tr>
        <th style="text-align: left; width: 200px;">Bezeichnung</th>
        <th>Menge</th>

        <th class="storzgreen" *ngIf="valid('de')">Lose</th>
        <th class="storzgreen" *ngIf="valid('de')">Freigabe</th>
        <th class="storzgreen bold" *ngIf="valid('de')">Einzel DE</th>
        <th class="storzblue" *ngIf="valid('cn')">Lose CHN</th>
        <th class="storzblue" *ngIf="valid('cn')">Freigabe</th>
        <th class="storzblue bold" *ngIf="valid('cn')">Einzel CHN</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let position of filteredPositions">
        <td class="alri">
          <label>
            {{ position.description }}
          </label>

          <div *ngIf="position.desextension">
            <label>
              {{ position.desextension }}
            </label>
          </div>
        </td>
        <td class="alri">
          <label>
            {{ position.quanity }}
          </label>
        </td>
        <td class="alri" [ngClass]="{selected: check(position, 'de')}"
            (click)="select(position, 'de')" *ngIf="valid('de')">
          <label>
            {{ position.lose_de }}
          </label>
        </td>
        <td class="alri" [ngClass]="{selected: check(position, 'de')}"
            (click)="select(position, 'de')" *ngIf="valid('de')">
          <label>
            {{ position.release_de }}
          </label>
        </td>
        <td class="alri bold" [ngClass]="{selected: check(position, 'de')}"
            (click)="select(position, 'de')" *ngIf="valid('de')">
          <label [ngClass]="{'red-font': !position.valid_de}">
            <i class="fa fa-warning" *ngIf="!position.valid_de"></i>

            {{ position.de.price }}
            <span *ngIf="position.de">€</span>
          </label>
        </td>
        <td class="alri" [ngClass]="{selected: check(position, 'cn')}"
            (click)="select(position, 'cn')" *ngIf="valid('cn')">
          <label [ngClass]="{'red-font': !position.valid_cn}">
            {{ position.lose_cn }}
          </label>
        </td>
        <td class="alri" [ngClass]="{selected: check(position, 'cn')}"
            (click)="select(position, 'cn')" *ngIf="valid('cn')">
          <label [ngClass]="{'red-font': !position.valid_cn}">
            {{ position.release_cn }}
          </label>
        </td>
        <td class="alri bold" [ngClass]="{selected: check(position, 'cn')}"
            (click)="select(position, 'cn')" *ngIf="valid('cn')">
          <label [ngClass]="{'red-font': !position.valid_cn}">
            <i class="fa fa-warning" *ngIf="!position.valid_cn"></i>

            {{ position.cn.price }}
            <span *ngIf="position.cn">€</span>
          </label>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-success"
              *ngIf="('task.change'|HasPerm)"
              [disabled]="clicked === null"
              (click)="create()"
              ngbTooltip="Der Auftrag wird für die ausgewählte Position erstellt.
                  Der Vorgang kann nicht rückgängig gemacht werden!">
        Auftrag erstellen
      </button>
    </div>
  </div>

  <div class="row" style="margin: 20px 0;">
    <div class="col-md-12">
      <h3>Kunde</h3>
    </div>

    <div class="col-md-6 customer-inside">
      <h4>{{ data.customer.name }}</h4>

      <p>{{ data.customer.invoice.street }}</p>

      <p>{{ data.customer.invoice.zip }} {{ data.customer.invoice.city }}</p>

      <p>{{ data.customer.invoice.country }}</p>
    </div>

    <div *ngIf="data.contact" class="col-md-6 customer-inside">
      {{ data.contact.title }}
      <h4>{{ data.contact.first_name }} {{ data.contact.last_name }}</h4>

      <p *ngIf="data.contact.position">{{ data.contact.position }}</p>

      <p *ngIf="data.contact.phone"><i class="fa fa-phone"></i>{{ data.contact.phone }}</p>

      <p *ngIf="data.contact.mobile"><i class="fa fa-mobile"></i>{{ data.contact.mobile }}</p>

      <p *ngIf="data.contact.mail"><i class="fa fa-envelope"></i>{{ data.contact.mail }}</p>
    </div>

    <div *ngIf="!data.contact" class="col-md-6">
      Kein Ansprechpartner ausgewählt
    </div>

  </div>


  <h3>Vertriebsnotiz</h3>

  <div *ngIf="data.customer">
    <pre id="sales_note">
    {{ data.customer.sales_note }}
    </pre>
  </div>


  <div class="row" *ngIf="data.article.sds_nr?.indexOf('-c') > 0 || data.article.sds_nr?.indexOf('-C') > 0">
    <div class="col-sm-12">
      <div class="alert alert-danger">
        <i class="fa fa-warning"></i> <strong>Achtung!</strong>
        China Artikel: {{ data.article.sds_nr }}
      </div>
    </div>
  </div>

</div>
