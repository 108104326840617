import {Component, Input, OnInit} from '@angular/core';
import {ArticleHistoryUserData} from './article-history.models';
import {ArticleHistoryService} from '../../services/article-history.service';
import {Article} from '../../models/article.models';
import {ArticleUpdateSharedComponent} from '../helper/article-update-shared.component';
import {UntypedFormBuilder} from '@angular/forms';
import {ArticleUpdateService} from '../../component-service/article-update.service';
import {GlobalService} from '../../../../core/global.service';
import {ArticleCalculatorService} from '../services/article-calculator.service';
import {StateService} from '@uirouter/angular';
import {ArticleMultilayerPlan} from '../multilayer-plan/article-multilayer-plan.models';
import {Lister} from '../../../../common/wrapper.models';

@Component({
  selector: 'article-history',
  templateUrl: './article-history.component.html',
  styles: ['.form-group { display: flex; }',
           'label { margin-right: 20px; }',
            '.comment-row { margin-top: 10px; }']
})
export class ArticleHistoryComponent extends ArticleUpdateSharedComponent implements OnInit {
  @Input() history: ArticleHistoryUserData[];
  @Input() mlPlanHistory: Lister<ArticleMultilayerPlan>;
  workflow_id: string | undefined | null;
  tab = 'simple';
  isMl: boolean;

  constructor(protected fb: UntypedFormBuilder,
              protected articleCalculatorService: ArticleCalculatorService,
              protected globalService: GlobalService,
              protected articleUpdateService: ArticleUpdateService,
              protected stateService: StateService,
              private articleHistoryService: ArticleHistoryService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.workflow_id = this.stateService.params.workflow_id;
    const execution: string = this.article.data.execution;
    this.isMl = execution && execution.startsWith('ML');
  }

  setView(view: string): void {
    this.tab = view;
  }

  protected newArticleValue(article: Article): void {
    this.articleHistoryService.history(article.oa_nr).subscribe(value => {
      this.history = value;
    });
  }
}
