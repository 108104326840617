import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DocumentHistory} from './history.models';
import {EnvisiaObjects} from '../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HistoryService {

  constructor(private http: HttpClient) {
  }

  list(name: string, id: number): Observable<DocumentHistory[]> {
    return this.http.get<EnvisiaObjects<DocumentHistory>>('/api/v1/document/history/' + name + '/' + id + '/').pipe(
      map(val => val.objects)
    );
  }

}
