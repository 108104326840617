/*
 * Copyright (C) 2019 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {InvoiceJobErrorListService} from './invoice-job-error-list.service';
import {timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {RightService} from '../../../../common/right-service/right.service';
import {createLeaderElection, BroadcastChannel} from 'broadcast-channel';

@Injectable({providedIn: 'root'})
export class InvoiceJobErrorStore {
  public current = {value: 0};
  public locked = false;

  constructor(service: InvoiceJobErrorListService, private rightService: RightService) {
    if (this.rightService.has('task.change')) {
      const channel = new BroadcastChannel<any>('invoice-poll');
      const channelLeaderElection = new BroadcastChannel<any>('invoice-poll-leader-election');
      const elector = createLeaderElection(channelLeaderElection, {
        fallbackInterval: 2000, // optional configuration for how often will renegotiation for leader occur
        responseTime: 1000, // optional configuration for how long will instances have to respond
      });
      elector.awaitLeadership().then(() => {
        console.log('i am the tab leader for invoice-poll');
        // 300000: 5 Minutes
        //  60000: 1 Minute
        timer(30000, 300000).pipe(switchMap(_ => service.failedPoll())).subscribe(data => {
          if (!this.locked) {
            channel.postMessage(data);
            this.current.value = data;
          }
        });
      });
      channel.onmessage = msg => {
        if (!this.locked) {
          this.current.value = msg;
        }
      };
    }
  }

  public lock(): void {
    this.locked = true;
  }

  public unlock(): void {
    this.locked = false;
  }

  public clean(): void {
    this.current.value = 0;
  }
}
