<form [formGroup]="form">

  <table class="whitetable table">
    <thead>
    <tr>
      <th>Datecode</th>
      <th>Max. Lagerzeit</th>
      <th>Vorhandene Menge</th>
      <th>Lager</th>
      <th>Lagerplatz</th>
      <th>Vorgang</th>
      <th>Verwendete Menge</th>
    </tr>
    </thead>
    <tbody formArrayName="inventory">
    <tr [ngClass]="{'warning': storageData[i].max_date_reached, 'success': storageData[i].workflow_id === workflow?.id}"
        *ngFor="let inventory of inventoryArray.controls; let i = index">
      <td>{{ storageData[i].date_code }}</td>
      <td>{{ storageData[i].date_code_max }}</td>
      <td>{{ storageData[i].quantity }}</td>
      <td>{{ storageData[i].storage_bin_name }}</td>
      <td>{{ storageData[i].storage_bin_number }}</td>
      <td>{{ storageData[i].workflow_id }}</td>
      <td>
        <integer-input class="input-bg"
                       [formControlName]="i + ''"
                       [evClass]="{red_alert: errors[i + '']}" [autofocus]="i === 0"></integer-input>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-md-12">
      Verbleidende Menge: <strong>{{ frozenQuantity - quantity }}</strong>
    </div>
  </div>

  <div class="row margin-up-down-10">
    <div class="col-md-12">
      <label for="note"><strong>Lagerbuchungs Notiz</strong></label>
      <textarea class="form-control" rows="5" id="note" formControlName="note">
      </textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-default pull-left" type="button" (click)="dismissButton()">
        Abbrechen
      </button>

      <loading-button clazz="btn btn-success pull-right"
                      name="Lieferschein erstellen"
                      (saveEmitter)="submit($event)"
                      [disabled]="frozenQuantity - quantity !== 0 || !form.valid">
      </loading-button>
    </div>
  </div>
</form>
