import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Article, ArticleCore, ArticleDuplicate, ArticleTopForm} from '../../models/article.models';
import Helper from '../../../../helper/helper';
import {ArticleV3Service} from '../../services/article3.service';
import {ArticleDuplicateModalComponent} from '../duplicate/article-duplicate-modal';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RightService} from '../../../../common/right-service/right.service';
import {debug} from '../../../../helper/debug.func';
import {ArticleTopChangeStateModalComponent} from './article-top-change-state-modal.component';
import {from, Subscription} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';

@Component({
  selector: 'article-top',
  templateUrl: './article-top.component.html',
  styles: [
    '.width-165 { width: 165px; }',
    '.width-180 { width: 180px; }',
    '.margin-left-10 { margin-left: 10px; }'
  ]
})
export class ArticleTopComponent implements OnInit, OnDestroy, DoCheck {
  @Input() article?: Article | ArticleCore;
  @Input() create = false;
  @Input() edit = false;
  @Input() topForm: UntypedFormGroup;
  @Output() update: EventEmitter<ArticleTopForm> = new EventEmitter<ArticleTopForm>();
  @Output() updateState = new EventEmitter<string>();

  loading: { sds_nr: boolean, kd_art_nr: boolean, kd_art_index: boolean } = {
    sds_nr: false,
    kd_art_nr: false,
    kd_art_index: false
  };

  disabledSds = false;
  errors = false;
  locked = false;
  duplicates: ArticleDuplicate[];

  private formSubscription?: Subscription;

  private oldArticleCD: { sdsNr?: string; sister?: string, dateDeleted?: string, locked?: number };
  private externalSdsNrChange = false;

  constructor(private modalService: NgbModal,
              private rightService: RightService,
              private fb: UntypedFormBuilder,
              private articleV3Service: ArticleV3Service) {
  }

  ngOnInit(): void {
    this.oldArticleCD = {
      sdsNr: this.article ? this.article.sds_nr : undefined,
      sister: this.article ? this.article.sister : undefined,
      dateDeleted: this.article ? this.article.date_deleted : undefined,
      locked: this.article ? this.article.locked : undefined
    };

    if (!this.topForm) {
      this.buildForm();
    } else {
      if (this.article) {
        // if an sds_nr is provided, we will actually
        // set values onto it
        if (this.topForm.controls['sds_nr']) {
          this.disabledSds = !this.withSdsNr();
          debug('sds_nr disabled:', this.disabledSds);
          this.topForm.controls['sds_nr'].reset(
            {value: this.article.sds_nr, disabled: this.disabledSds},
            {emitEvent: false}
          );
        }
      }
    }
  }

  ngDoCheck(): void {
    if (this.article) {
      // we do a dirty check since the current changes do not get through the normal change detection
      // since the form get's updated when there is a new sds_nr,
      // we actually check if the sds_nr even changed, before we reset the form
      if (this.article.sds_nr !== this.oldArticleCD.sdsNr) {
        debug('change detection sds_nr', this.article.sds_nr, this.oldArticleCD.sdsNr);
        this.externalSdsNrChange = true;
        this.oldArticleCD.sdsNr = this.article.sds_nr;
        this.disabledSds = !this.withSdsNr();
        debug('sds_nr disabled:', this.disabledSds);
        this.topForm.controls['sds_nr'].reset(
          {value: this.article.sds_nr, disabled: this.disabledSds},
          {emitEvent: false}
        );
      }

      // for completeness:
      if (this.article.sister !== this.oldArticleCD.sister) {
        debug('change detection sister');
        this.oldArticleCD.sister = this.article.sister;
      }
      if (this.article.date_deleted !== this.oldArticleCD.dateDeleted) {
        debug('change detection date_deleted');
        this.oldArticleCD.dateDeleted = this.article.date_deleted;
      }
      if (this.article.locked !== this.oldArticleCD.locked) {
        debug('change detection locked');
        this.oldArticleCD.locked = this.article.locked;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  sdsCheck(search) {
    if (search && this.locked === false) {
      this.locked = true;
      this.loading[search] = true;

      this.errors = false;
      const obj: { oa_nr?: string, kd_art_nr?: string, kd_art_index?: string, sds_nr?: string } = {
        oa_nr: this.article ? this.article.oa_nr : undefined,
        kd_art_nr: this.topForm.value.kd_art_nr,
        kd_art_index: this.topForm.value.kd_art_index
      };

      if (this.article) {
        if (!this.article.sds_nr && Helper.correctlyDefined(this.topForm.value.sds_nr)) {
          obj.sds_nr = this.topForm.value.sds_nr;
        }
      }

      this.articleV3Service.duplicates(obj).subscribe(() => {
        this.reset();
      }, (errors) => {
        this.errors = true;

        const modalRef = this.modalService.open(ArticleDuplicateModalComponent, {windowClass: 'modal2-smm'});
        if (errors.error.errors) {
          modalRef.componentInstance.duplicates = errors.error.errors;
        }
        this.reset();
      });
    }
  }

  reset() {
    setTimeout(() => {
      this.locked = false;
      this.loading.sds_nr = false;
      this.loading.kd_art_nr = false;
      this.loading.kd_art_index = false;
    }, 50);
  }

  redirectSdsNr(sds) {
    if (confirm('Möchten Sie den Schwesterartikel aufrufen?')) {
      this.articleV3Service.findSister(sds).subscribe((article) => {
        window.open('#!/article/overview/' + article.oa_nr);
      }, () => {
        console.log('Es ist ein Fehler aufgetreten');
      });
    }
  }


  // -- Article State

  stateChangeValid(article: Article): boolean {
    return this.rightService.has('article.change_state') && !article.locked && !article.date_deleted;
  }

  openStateChangeModal(article: Article): void {
    if (this.stateChangeValid(article)) {
      const modalRef = this.modalService.open(ArticleTopChangeStateModalComponent);
      modalRef.componentInstance.article = article;
      from(modalRef.result).subscribe(value => {
        if (!this.edit) {
          article.change_state = value;
        }
        this.updateState.next(value);
      }, ignoreRejection);
    }
  }

  // -- private form helper

  private createFormSubscription() {
    this.formSubscription = this.topForm.valueChanges.subscribe(values => {
      if (!this.externalSdsNrChange) {
        this.update.emit(values);
      } else {
        this.externalSdsNrChange = false;
      }
    });
  }

  private withSdsNr(): boolean {
    return !this.article.sds_nr && this.edit && this.rightService.has('article.sds_generate');
  }

  private buildForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    const sdsDisabled = !this.withSdsNr();
    this.topForm = this.fb.group({
      sds_nr: [{value: this.article.sds_nr, disabled: sdsDisabled}],
      site_nr: [{value: this.article.data.site_nr, disabled: !this.edit}],
      kd_art_nr: [{value: this.article.data.kd_art_nr, disabled: !this.edit}],
      kd_art_index: [{value: this.article.data.kd_art_index, disabled: !this.edit}],
      kd_art_extend1: [{value: this.article.data.kd_art_extend1, disabled: !this.edit}],
      kd_art_extend2: [{value: this.article.data.kd_art_extend2, disabled: !this.edit}],
      article_note: [{value: this.article.data.article_note, disabled: !this.edit}],
    });
    this.createFormSubscription();
  }

}
