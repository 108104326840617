<div class="col-md-7">

  <form id="articleForm" name="articleForm" class="form-horizontal" [formGroup]="form" (ngSubmit)="articleFind()">
    <h3>Artikelsuche</h3>

    <div class="row">
      <div class="col-md-6">

        <div class="form-group">
          <label for="id_sds_nr" class="col-md-4 control-label">SDS-Nr</label>

          <div class="col-md-8">
            <input class="form-control" id="id_sds_nr" name="sds_nr" type="text"
                   formControlName="sds_nr"
                   [ngClass]="{red_alert: errors}">
          </div>
        </div>

        <div class="form-group">
          <label for="id_kunden_art_nr" class="col-md-4 control-label">Kunden-Art-Nr</label>

          <div class="col-md-8">
            <input class="form-control" id="id_kunden_art_nr" name="kunden_art_nr" type="text"
                   formControlName="kd_art_nr"
                   [ngClass]="{red_alert: errors}">
          </div>
        </div>

        <div class="form-group">
          <label for="id_kunden_art_index" class="col-md-4 control-label">Kunden-Art-Index</label>

          <div class="col-md-8">
            <input class="form-control" id="id_kunden_art_index" name="kunden_art_index" type="text"
                   formControlName="kd_art_index"
                   [ngClass]="{red_alert: errors}">
          </div>
        </div>

      </div>
      <div class="col-md-6">

        <div class="form-group">

          <label for="id_oa_nr" class="col-md-4 control-label">0A-Nr</label>

          <div class="col-md-8">
            <input class="form-control" id="id_oa_nr" name="oa_nr" type="text" formControlName="oa_nr">
          </div>
        </div>

        <div class="form-group">
          <label for="id_offer_nr" class="col-md-4 control-label">Angebots-Nr</label>

          <div class="col-md-8">
            <input class="form-control" id="id_offer_nr" name="offer_nr" formControlName="offer_id"
                   type="text">
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-md-12">

        <div class="form-group">

          <div class="col-md-offset-2 col-md-10">

            <button id="article-new" class="col-md-4 btn btn-default" type="button"
                    (click)="next()" [disabled]="errors || nextLoading">
              Neuer Artikel
            </button>

            <div class="col-md-4"></div>

            <button id="search" class="col-md-4 btn btn-default" type="button" (click)="articleFind()">
              Suchen
            </button>

          </div>

        </div>
      </div>
    </div>

  </form>


  <div class="row">
    <div class="task col-md-12">
      <table class="whitetable wizard-article-list table">
        <thead>
        <tr>
          <th class="pad35">Status</th>
          <th class="pad35">SDS</th>
          <th class="pad35">0A</th>
          <th class="pad35">Kunden-Art</th>
          <th class="pad35">Index</th>
          <th class="pad35" *ngIf="!withoutOffers">Angebot</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody id="article-table-body">

        <tr *ngFor="let article of articleList; let index = index">
          <td [ngClass]="'article-state-' + article.change_state">
            <ng-container [ngSwitch]="article.change_state">
              <ng-container *ngSwitchCase="'red'">Rot</ng-container>
              <ng-container *ngSwitchCase="'orange'">Orange</ng-container>
              <ng-container *ngSwitchCase="'green'">Grün</ng-container>
            </ng-container>
          </td>
          <td>{{ article.sds_nr }} <span *ngIf="article.lock" class="label label-warning">Gesperrt</span></td>
          <td>{{ article.oa_nr }}</td>
          <td>{{ article.kd_art_nr }}</td>
          <td>{{ article.kd_art_index }}</td>
          <td  *ngIf="!withoutOffers">
            <ng-container *ngFor="let offer of article.offer_list; let offerIndex = index">
              <ng-container *ngIf="offerIndex !== 0">&nbsp;</ng-container>
              <button class="btn btn-default btn-sm" (click)="showWorkflow(offer)">
                {{ offer }}
              </button>
            </ng-container>
          </td>
          <td>
            <button class="btn btn-success btn-sm forward-button width-100" type="button" (click)="next(article)">
              Weiter
            </button>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>

</div>
