<div class="modal-header">
  <h3 class="modal-title">
    <span *ngIf="create">Artikel erstellen - </span>
    SDS Nr. generieren
  </h3>
</div>


<div class="modal-body">

  <div class="row" *ngIf="create">
    <div class="col-md-12 click" (click)="route('none')">
      <div class="whitebox sdsgenerationbox">
        <strong>nur 0A-Artikel</strong><br />
      </div>
    </div>
  </div>

  <div class="row" *ngIf="create">
    <div class="col-md-12">&nbsp;</div>
  </div>

  <div class="row">
    <div class="col-md-6 click" (click)="route('de')">
      <div class="whitebox sdsgenerationbox">
        <img src="/ui/assets/images/flags/de.png"><br>
        <strong>Fertigung Deutschland</strong>
      </div>

    </div>
    <div class="col-md-6 click" (click)="route('cn')">
      <div class="whitebox sdsgenerationbox">
        <img src="/ui/assets/images/flags/cn.png"><br>
        <strong>Fertigung China </strong>
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">
</div>
