import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {ArticleOverview} from './article-overview.model';

@Injectable({providedIn: 'root'})
export class ArticleOverviewService {

  constructor(private http: HttpClient) {
  }

  public get(oaNr: string): Observable<ArticleOverview> {
    return this.http.get<ArticleOverview>(environment.apiv4uri + `article/overview/${oaNr}`);
  }
}
