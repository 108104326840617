<ng-container>
  <td>
            <span *ngIf="position.typ === 'article'" class="fetchcount">
              {{ fetch.ordering }}
            </span>
    <span *ngIf="(fetch.status > 1 && position.typ === 'article') && fetch.status < 5"
          class="label label-primary">
              BESTÄTIGT
            </span>
    <span *ngIf="fetch.status === 5" class="label label-success">
              ABGERECHNET
            </span>
  </td>

  <td>
    <div class="col-sm-12" *ngIf="((nr && fetch.status > 0) || fetch.status === 5) || fetch.status === 5">
      <div class="row">
      <span>
        {{ position.label }}
      </span>
      </div>

      <div class="row">
      <span *ngIf="position.typ === 'article'">
        <br/>
        {{ position.desextension }}
      </span>
      </div>
    </div>

    <div class="col-sm-12" *ngIf="(!nr || fetch.status === 0) && fetch.status !== 5">
      <div class="row">
        <input class="width-99-percent"
               type="text"
               [name]="'position_label_' + position.id"
               [ngModel]="position.label"
               (ngModelChange)="position.label = $event; updateElement(fetchIndex, positionIndex, fetch, position)">
      </div>
      <div class="row">
      <textarea class="position width-99-percent desextension-text"
                id="desextension"
                maxlength="158"
                [name]="'position_desextension_' + position.id"
                [ngModel]="position.desextension"
                (ngModelChange)="position.desextension = $event; updateElement(fetchIndex, positionIndex, fetch, position)"
                *ngIf="position.typ === 'article'">
      </textarea>
      </div>
    </div>
  </td>

  <td class="align-right">
    <span *ngIf="(nr && fetch.status > 0) || fetch.status === 5">{{ position.quanity }}</span>
    <input type="text"
           class="align-right"
           style="width: 100%;"
           [name]="'position_quantity_' + position.id"
           [ngModel]="position.quanity"
           (ngModelChange)="position.quanity = $event; updateElement(fetchIndex, positionIndex, fetch, position)"
           *ngIf="(!nr || fetch.status === 0) && fetch.status !== 5">
  </td>

  <ng-container [ngSwitch]="position.typ">
    <td class="align-right" *ngSwitchCase="'extracost_percentual'">
      <div class="align-right" *ngIf="!editable">
        {{ position.percentual }}&nbsp;%
      </div>
      <div class="align-right" *ngIf="editable">
        <input (ngModelChange)="position.percentual = $event; updateElement(fetchIndex, positionIndex, fetch, position)"
               [name]="'position_percentual_' + position.id"
               [ngClass]="{'red_alert': position['percentual_error']}"
               [ngModel]="position.percentual"
               class="align-right"
               style="width: 60px; display: inline-block;"
               type="text">&nbsp;%

        <a class="click" (click)="reloadExtraCostPercent(position)" *ngIf="editable">
          <i class="fa fa-refresh" style="font-size: 15px;"></i>
        </a>
      </div>
    </td>

    <td *ngSwitchCase="'article'">
      <div class="row" *ngIf="!editable">
        <div class="col-sm-10 align-right" [ngClass]="{gray_font: !!priceIncreaseFlexString}" style="padding: 0;">
          {{ position.price }}
        </div>
        <div class="col-sm-2" [ngClass]="{gray_font: !!priceIncreaseFlexString}" style="padding: 0;">&nbsp;€</div>
      </div>

      <div class="row" *ngIf="editable">
        <div class="col-sm-10 align-right" style="padding: 0;">
          <input type="text"
                 class="align-right"
                 style="width: 100%; margin-right: -4px"
                 [name]="'position_price_' + position.id"
                 [ngClass]="{'red_alert': position['price_error']}"
                 [ngModel]="position.price"
                 (ngModelChange)="priceUpdate($event, fetchIndex, positionIndex, fetch, position)">
        </div>
        <div class="col-sm-2" style="padding: 5px 0 0;">&nbsp;€</div>
      </div>

      <div class="row" *ngIf="!!priceIncreaseFlexString">
        <div class="col-sm-10 align-right" style="padding: 0;" [ngbTooltip]="priceIncreaseFlexString">
          {{ priceWithIncrease|money:3}}
        </div>
        <div class="col-sm-2" style="padding: 0;">&nbsp;€</div>
      </div>
    </td>

    <td *ngSwitchDefault>
      <div class="row" *ngIf="!editable">
        <div class="col-sm-10 align-right" style="padding: 0;">
          {{ position.price }}
        </div>
        <div class="col-sm-2" style="padding: 0;">&nbsp;€</div>
      </div>

      <div class="row" *ngIf="editable">
        <div class="col-sm-10 align-right" style="padding: 0;">
          <input type="text"
                 class="align-right"
                 style="width: 100%; margin-right: -4px"
                 [name]="'position_price_' + position.id"
                 [ngClass]="{'red_alert': position['price_error']}"
                 [ngModel]="position.price"
                 (ngModelChange)="priceUpdate($event, fetchIndex, positionIndex, fetch, position)">
        </div>
        <div class="col-sm-2" style="padding: 5px 0 0;">&nbsp;€</div>
      </div>
    </td>
  </ng-container>

  <td class="align-right">
    {{ priceTotal|money:2 }}&nbsp;€
  </td>

  <td>
            <span *ngIf="((nr && fetch.status > 0) || fetch.status === 5) && (position.typ === 'article')">
              {{fetch.order_nr}}
            </span>
    <input (ngModelChange)="fetch.order_nr = $event; updateElement(fetchIndex, positionIndex, fetch, position)"
           *ngIf="((!nr || fetch.status === 0) && fetch.status !== 5) && (position.typ === 'article')"
           [name]="'fetch_order_nr_' + position.id"
           [ngModel]="fetch.order_nr"
           type="text">
  </td>
  <td>
    <div *ngIf="(nr && fetch.status > 0) || fetch.status === 5 && position.typ === 'article'"
         class="form-inline-block">
      {{ fetch.delivery }}
    </div>
    <ev-datepicker placeholder="__ . __ . _____"
                   [future]="true"
                   [name]="'fetch_delivery_' + position.id"
                   [ngModel]="fetch.delivery"
                   [writeBack]="false"
                   (ngModelChange)="fetch.delivery = $event; updateElement(fetchIndex, positionIndex, fetch, position)"
                   *ngIf="((!nr || fetch.status === 0) && fetch.status !== 5) && position.typ === 'article' && frame.typ === 'cn'">
    </ev-datepicker>

    <material-planning-date-check [article]="article"
                                  [amount]="position.quanity"
                                  [date]="fetch.delivery"
                                  [preload]="displayDeliveryDateCheck"
                                  (dateChanged)="fetch.delivery = $event; updateElement(fetchIndex, positionIndex, fetch, position)"
                                  *ngIf="use && position.typ === 'article' && frame.typ === 'de'">
    </material-planning-date-check>

    <div *ngIf="!selected && !use && fetch.earliest_delivery && position.typ === 'article'"
         class="form-inline-block">
      <i class="fa fa-check fa-2x green"></i>&nbsp;Frühestmöglicher Liefertermin
    </div>

    <div *ngIf="use && position.typ === 'article'"
         class="form-control"
         style="border: 0; transparent: none; box-shadow: none;">
      <input
        (ngModelChange)="fetch.earliest_delivery = $event === true; updateElement(fetchIndex, positionIndex, fetch, position)"
        [id]="'fetch_earliest_delivery_' + position.id" [name]="'fetch_earliest_delivery_' + position.id"
        [ngModel]="fetch.earliest_delivery"
        style="float: left;"
        type="checkbox">
      <label [for]="'fetch_earliest_delivery_' + position.id"
             style="font-size: 10px;">Frühestmöglicher Liefertermin</label>
    </div>
  </td>

  <td>
    <div *ngIf="!use && fetch.is_complaint && position.typ === 'article'"
         class="form-inline-block">
      <span class="green"><i class="fa fa-check fa-2x"></i>&nbsp;Ja</span>
    </div>
    <input
      (ngModelChange)="fetch.is_complaint = $event === true; updateElement(fetchIndex, positionIndex, fetch, position)"
      *ngIf="use && position.typ === 'article'"
      [name]="'fetch_is_complaint_' + position.id"
      [ngModel]="fetch.is_complaint"
      type="checkbox">
  </td>
  <td>
    <button (click)="serviceDropFetch(fetchIndex, positionIndex, fetch, position)" *ngIf="deletable"
            class="btn btn-link"
            type="button">
      <i class="fa fa-trash-o"></i>
    </button>

    <button (click)="addFetch()"
            *ngIf="!use && ((!nr && fetch.status < 5) || fetch.status === 0) && position.typ === 'article'"
            class="btn btn-sm btn-success btn-xs width-75"
            type="button">
      Auswählen
    </button>

    <button (click)="dropFetch()"
            *ngIf="use && ((!nr && fetch.status < 5) || fetch.status === 0) && position.typ === 'article'"
            class="btn btn-sm btn-danger btn-xs"
            style="width: 75px;"
            type="button">
      Entfernen
    </button>
  </td>
</ng-container>
