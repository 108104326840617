import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IOposFinalData, OposFinalData} from '../../opos/opos.models';
import {CustomerOposTargetDateEditService} from './customer-opos-target-date-edit.service';
import {DateHelper} from '../../../helper/date-helper';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'customer-opos-target-date-edit',
  templateUrl: './customer-opos-target-date-edit.component.html',
  styleUrls: ['./customer-opos-target-date-edit.scss'],
})
export class CustomerOposTargetDateEditComponent implements OnInit, OnChanges {
  @Input() data: OposFinalData;
  @Output() targetDateChanged = new EventEmitter<OposFinalData>();
  form: UntypedFormGroup;
  edit = false;
  constructor(private service: CustomerOposTargetDateEditService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      target_date: [this.data.target_date ?? this.data.due_date],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.data && !changes.data.isFirstChange()) {
      this.resetForm();
    }
  }

  toggleEdit(): void {
    if (this.edit) {
      this.resetForm();
    }

    this.edit = !this.edit;
  }

  resetForm(): void {
    this.form.reset({target_date: this.data.target_date ?? this.data.due_date}, {emitEvent: false});
  }

  submit(): void {
    this.service.setDate(this.data.invoice_id, this.form.value).subscribe(sd => {
      const date = !sd.target_date ? null : DateHelper.parseISO8601(sd.target_date);
      const returnData = {
        ...this.data,
        state_original: {...this.data.state_original},
        target_date: DateHelper.format(date)
      } as IOposFinalData;
      this.targetDateChanged.emit(OposFinalData.fromInterface(returnData));
      this.edit = false;
      this.alertService.add('success', 'Neues Zahlungsziel wurde gesetzt');
    }, () => {
      this.alertService.add('danger', 'Konnte neues Zahlungsziel nicht setzen');
    });
  }
}
