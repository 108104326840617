import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as React from 'react';
import {InventoryGroup, StorageBin} from '../inventory.models';
import {ArticleInventoryForm} from './article-inventory-form';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';

const containerElementName = 'reactArticleInventoryForm';

@Component({
  selector: 'article-inventory-form',
  template: `<div #${containerElementName}></div>`,
})
export class ArticleInventoryFormComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() oaNr: string;
  @Input() storage: StorageBin[];
  @Input() workflowId?: number;
  @Input() workflowIds: number[];
  @Input() selectedInventory?: InventoryGroup = null;
  @Output() selectedUnset: EventEmitter<void> = new EventEmitter<void>();
  @Output() update: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    super();
  }

  protected override renderComponent() {
    const _ = React.version;
    return <ArticleInventoryForm oaNr={this.oaNr}
                                 storage={this.storage}
                                 workflowId={this.workflowId}
                                 workflowIds={this.workflowIds}
                                 selectedInventory={this.selectedInventory}
                                 selectedUnset={() => this.selectedUnset.emit()}
                                 update={() => this.update.emit()}
    />;
  }
}
