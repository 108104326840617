/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
export const changeset = (obj1?: Object, obj2?: Object): string[] => {
  const arr: string[] = [];

  for (const key in {...obj1 ?? {}, ...obj2 ?? {}}) {
    if (obj1?.hasOwnProperty(key) !== obj2?.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
      arr.push(key);
    }
  }

  return arr;
};
