import {Injectable} from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {
  Delivery,
  DeliveryFetchForm,
  DeliveryForm,
  DeliveryListData,
  DeliveryMessage
} from '../models/delivery.models';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {map} from 'rxjs/operators';
import {BigFetchWithPosition} from '../models/fetch.models';
import {environment} from '../../../../environments/environment';
import {InventoryStorage} from '../../article/inventory/inventory.models';

@Injectable({providedIn: 'root'})
export class DeliveryService implements ListService<DeliveryListData> {

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<DeliveryListData>> {
    return this.http.get<Lister<DeliveryListData>>(
      '/workflow/v1/delivery/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  lock(deliveryId: number, reason: string): Observable<Delivery> {
    return this.http.put<EnvisiaObject<Delivery>>(
      '/workflow/v1/delivery/lock/' + deliveryId + '/',
      {reason: reason}
    ).pipe(map(val => val.object));
  }

  unlock(deliveryId: number, reason: string): Observable<Delivery> {
    return this.http.put<EnvisiaObject<Delivery>>(
      '/workflow/v1/delivery/unlock/' + deliveryId + '/',
      {reason: reason}
    ).pipe(map(val => val.object));
  }

  storno(deliveryId: number, workflowId: number, reason: string): Observable<Delivery> {
    return this.http.put<EnvisiaObject<Delivery>>(
      '/workflow/v1/delivery/storno/' + deliveryId + '/',
      {workflowId: workflowId, reason: reason}
    ).pipe(map(val => val.object));
  }

  fetches(workflowId: number, form: DeliveryFetchForm, nr?: boolean): Observable<BigFetchWithPosition[]> {
    return this.http.post<EnvisiaObjects<BigFetchWithPosition>>(
      '/workflow/v1/delivery/fetches/' + workflowId + '/',
      form,
      {params: EnvisiaLocation.httpParams({nr: nr})}
    ).pipe(map(val => val.objects));
  }

  listStorage(workflowId: number, newNr: boolean): Observable<InventoryStorage[]> {
    return this.http.get<{ objects: InventoryStorage[], sum: number }>(
      `${environment.apiv4uri}inventory/storage/delivery/${workflowId}?new_nr=${newNr}&all_workflow=true`
    ).pipe(map(val => val.objects));
  }

  create(workflowId: number, form: DeliveryForm): Observable<DeliveryMessage> {
    return this.http.post<DeliveryMessage>(
      '/workflow/v1/workflow/' + workflowId + '/delivery/',
      form
    );
  }
}
