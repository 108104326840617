import {Component, Input} from '@angular/core';

@Component({
  selector: 'material-inventory-transaction-error',
  templateUrl: './material-inventory-transaction-error.component.html'
})
export class MaterialInventoryTransactionErrorComponent {

  @Input() errors: { [key: string]: any; } = {};

  constructor() { }

}
