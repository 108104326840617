import {Article} from '../../models/article.models';
import {ArticleMultilayerParts, ArticleMultilayerPlanModel} from './article-multilayer-plan.models';
import {ArticleDataParserHelper} from './article-data-parser-helper';
import {ArticleMultilayerPartTypeHelper} from './article-multilayer-part-type-helper';
import {NumberHelper} from '../../../../helper/number-helper';

export interface ArticleMultilayerDifference {
  differs: boolean;
  current: ArticleMultilayerDifferenceCurrent;
  core_thickness: ArticleMultilayerDifferenceElement<number>[];
  cu_inside: ArticleMultilayerDifferenceElement<number[]>[];
  cu_outside: ArticleMultilayerDifferenceElement<number>[];
  cu_core: ArticleMultilayerDifferenceElement<number>[];
  sbu_layers: ArticleMultilayerDifferenceElement<number>;
}

export interface ArticleMultilayerDifferenceCurrent {
  cores: ArticleMultilayerParts[][];
  foils: ArticleMultilayerParts[];
  sbu_layers: ArticleMultilayerDifferenceSbuLayers[];
}

export interface ArticleMultilayerDifferenceSbuLayers {
  top: ArticleMultilayerParts[];
  bottom: ArticleMultilayerParts[];
  is_core: boolean;
}

export interface ArticleMultilayerDifferenceElement<T> {
  differs: boolean;
  article?: T;
  multilayer?: T;
}

export class ArticleMultilayerPlanHolderCompareHelper {
  static getCores(multilayer: ArticleMultilayerPlanModel): ArticleMultilayerParts[][] {
    const parts = [];
    for (let i = 0; i < multilayer.parts.length; i++) {
      const part = multilayer.parts[i];
      if (ArticleMultilayerPartTypeHelper.get(part.type)?.name === 'core') {
        const kashA = multilayer.parts[i - 1];
        const kashB = multilayer.parts[i + 1];
        if (ArticleMultilayerPartTypeHelper.get(kashA.type)?.name === 'cu_kasch' &&
            ArticleMultilayerPartTypeHelper.get(kashB.type)?.name === 'cu_kasch' &&
        kashA.cu_kasch?.index_core === i &&
        kashB.cu_kasch?.index_core === i) {
          parts.push([kashA, part, kashB]);
        }
      }
    }
    return parts;
  }

  static getByPartType(multilayer: ArticleMultilayerPlanModel, partType: string): ArticleMultilayerParts[] {
    return multilayer.parts.filter(p => ArticleMultilayerPartTypeHelper.get(p.type)?.name === partType);
  }

  static getSbuLayers(
    multilayer: ArticleMultilayerPlanModel,
    coreIndexMin: number,
    coreIndexMax: number
  ): ArticleMultilayerDifferenceSbuLayers[] {
    const plating = ArticleMultilayerPlanHolderCompareHelper.getByPartType(multilayer, 'cu_plating');
    if (plating.length <= 0) {
      return null;
    }

    const platingCollection: ArticleMultilayerParts[][] = [[plating[0]]];

    // Group plating
    for (let i = 1; i < plating.length; i++) {
      if (platingCollection[0][0].index + 1 !== plating[i].index) {
        platingCollection.unshift([plating[i]]);
      } else {
        platingCollection[0].unshift(plating[i]);
      }
    }

    const topHalf = platingCollection.filter(p => p[0].index < coreIndexMin);
    const bottomHalf = platingCollection.filter(p => p[0].index > coreIndexMax).reverse();

    // Verify that top and bottom half are at least the same size
    if (topHalf.length !== bottomHalf.length) {
      return null;
    }

    const merged: ArticleMultilayerDifferenceSbuLayers[] = [];
    for (let i = 0; i < topHalf.length || i < bottomHalf.length; i++) {
      const topIsCore = (topHalf[i].length > 0) && ((topHalf[i][topHalf[i].length - 1].index) === (coreIndexMin - 1));
      const bottomIsCore = (topHalf[i].length > 0) && ((bottomHalf[i][0].index) === (coreIndexMax + 1));
      merged.push({top: topHalf[i], bottom: bottomHalf[i], is_core: topIsCore && bottomIsCore});
    }
    return merged;
  }

  static diff(article: Article, multilayer: ArticleMultilayerPlanModel): ArticleMultilayerDifference {
    return ArticleMultilayerPlanHolderCompareHelper.diffData(article.data, multilayer);
  }

  static diffData(data: any, multilayer: ArticleMultilayerPlanModel): ArticleMultilayerDifference {
    const articleData = ArticleDataParserHelper.parse(data);
    const cores = ArticleMultilayerPlanHolderCompareHelper.getCores(multilayer);
    const foils = ArticleMultilayerPlanHolderCompareHelper.getByPartType(multilayer, 'cu_foil');
    const minCoreIndex = NumberHelper.min(cores.map(p => p[0].index));
    const maxCoreIndex = NumberHelper.max(cores.map(p => p[2].index));
    const sbuLayers = ArticleMultilayerPlanHolderCompareHelper.getSbuLayers(multilayer, minCoreIndex, maxCoreIndex);
    const differs: ArticleMultilayerDifference = {
      differs: false,
      current: {cores: cores, foils: foils, sbu_layers: sbuLayers},
      core_thickness: articleData.articleData.inner_values.map((iv, i) => {
        const core = (i < cores.length && !!cores[i]) ? cores[i][1].core : null;
        return {
          differs: (core?.thickness_selected ?? null) !== (iv.core_thickness ?? null),
          article: iv.core_thickness ?? null,
          multilayer: core?.thickness_selected ?? null,
        } as ArticleMultilayerDifferenceElement<number>;
      }),
      cu_inside: articleData.articleData.inner_values.map((iv, i) => {
        const kaschA = (i < cores.length && !!cores[i]) ? cores[i][0].cu_kasch : null;
        const kaschB = (i < cores.length && !!cores[i]) ? cores[i][2].cu_kasch : null;
        return {
          differs: (kaschA?.thickness_selected ?? null) !== (iv.cu_inside ?? null) ||
                   (kaschB?.thickness_selected ?? null) !== (iv.cu_inside ?? null),
          article: [iv.cu_inside ?? null, iv.cu_inside ?? null],
          multilayer: kaschA === null && kaschB === null ?
            null :
            [kaschA?.thickness_selected, kaschB?.thickness_selected],
        } as ArticleMultilayerDifferenceElement<number[]>;
      }),
      cu_outside: foils
        .filter((p, i) => i === 0 || i === foils.length - 1)
        .map(p => {
        return {
          differs: (p.cu_foil.thickness_selected ?? null) !== (articleData.articleData.cu_outside ?? null),
          article: articleData.articleData.cu_outside ?? null,
          multilayer: p.cu_foil.thickness_selected ?? null,
        };
      }),
      cu_core: foils
        .filter((p, i) => i > 0 && i < foils.length - 1)
        .map(p => {
          return {
            differs: (p.cu_foil.thickness_selected ?? null) !== (articleData.articleData.cu_core ?? null),
            article: articleData.articleData.cu_core ?? null,
            multilayer: p.cu_foil.thickness_selected ?? null,
          };
        }),
      sbu_layers: {
        differs: (articleData.articleData.sbu_layers ?? null) !== (!sbuLayers?.length ? null : sbuLayers.length - 1),
        article: articleData.articleData.sbu_layers ?? null,
        multilayer: (!sbuLayers?.length ? null : sbuLayers.length - 1),
      },
    };

    if (cores.length > differs.core_thickness.length) {
      for (let i = differs.core_thickness.length; i < cores.length; i++) {
        const core = (!!cores[i]) ? cores[i][1].core : null;
        differs.core_thickness.push({
          differs: true,
          article: null,
          multilayer: core?.thickness_selected ?? null,
        } as ArticleMultilayerDifferenceElement<number>);
      }
    }

    if (cores.length > differs.cu_inside.length) {
      for (let i = differs.cu_inside.length; i < cores.length; i++) {
        const kaschA = (!!cores[i]) ? cores[i][0].cu_kasch : null;
        const kaschB = (!!cores[i]) ? cores[i][2].cu_kasch : null;
        differs.cu_inside.push({
          differs: true,
          article: null,
          multilayer: kaschA === null && kaschB === null ?
            null :
            [kaschA?.thickness_selected ?? null, kaschB?.thickness_selected ?? null],
        } as ArticleMultilayerDifferenceElement<number[]>);
      }
    }

    differs.differs = (
      !!differs.core_thickness.find(d => d.differs) ||
      !!differs.cu_inside.find(d => d.differs) ||
      !!differs.cu_outside.find(d => d.differs) ||
      !!differs.cu_core.find(d => d.differs)
    );

    return differs;
  }
}
