import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MaterialSupplier} from './material-supplier.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {EnvisiaLocation} from '../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class MaterialSupplierService {

  constructor(private http: HttpClient) {
  }

  detail(id: number): Observable<MaterialSupplier> {
    return this.http.get<MaterialSupplier>(environment.apiv4uri + 'material/supplier/' + id);
  }

  list(query: { [param: string]: string | ReadonlyArray<string> } = {}): Observable<MaterialSupplier[]> {
    return this.http.get<{ objects: MaterialSupplier[] }>(
      environment.apiv4uri + 'material/supplier',
      {params: new HttpParams({fromObject: query})}
    ).pipe(
      map(val => val.objects),
    );
  }

  create(data: { name: string }): Observable<MaterialSupplier> {
    return this.http.post<MaterialSupplier>(environment.apiv4uri + 'material/supplier', data);
  }

  edit(id: number, data: { name: string }): Observable<MaterialSupplier> {
    return this.http.put<MaterialSupplier>(environment.apiv4uri + 'material/supplier/' + id, data);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(environment.apiv4uri + 'material/supplier/' + id);
  }

}
