import { Pipe, PipeTransform } from '@angular/core';
import {DateHelper} from '../../helper/date-helper';
import {StringHelper} from '../string-helper';

@Pipe({
  name: 'ZonedDateTime'
})
export class ZonedDateTimePipe implements PipeTransform {
  transform(value: string, args?: {showDate: boolean, showTime: boolean}): string {
    if (!value) {
      return '';
    }

    const date = DateHelper.parseISO8601(value);
    if (!date) {
      return '';
    }

    const showDate = args?.showDate !== false;
    const showTime = args?.showTime !== false;
    const ret: string[] = [];
    if (showDate) {
      ret.push(DateHelper.format(date));
    }

    if (showTime) {
      ret.push(DateHelper.formatTime(date));
    }

    return StringHelper.concat(ret, ' ');
  }
}
