import {Pipe, PipeTransform} from '@angular/core';
import {Money} from './Money';
import Helper from '../../helper/helper';

@Pipe({name: 'money'})
export class MoneyPipe implements PipeTransform {

  transform(value: number | string, args?: number | number[]): string {
    if (Helper.undefined(value)) {
      return '';
    }

    let n: number;
    if (typeof value === 'string') {
      n = Money.parse(value);
    } else {
      n = value;
    }

    // we either round to zero or the provided value
    let round;
    if (typeof args === 'number') {
      round = args;
    } else {
      round = args ? (args[0] ? args[0] : 0) : 0;
    }

    return Money.stringify(n, round);
  }

}
