import {Component, Input} from '@angular/core';
import {MaterialOrder} from '../material-order.model';
@Component({
  selector: 'material-order-status',
  templateUrl: './material-order-status.component.html'
})
export class MaterialOrderStatusComponent {
  @Input() order: MaterialOrder;

  constructor() {
  }

  get showOrderedLabel(): boolean {
    return (
      (this.order.amount_confirmed === 0) &&
      (this.order.amount_booked === 0) &&
      (this.order.amount_price_validated === 0)
    );
  }
}
