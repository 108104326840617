import * as React from 'react';
import {ArticleMultilayerPartTypeHelper} from '../article-multilayer-part-type-helper';
import {ArticleMultilayerParts} from '../article-multilayer-plan.models';
import {ArticleSpecification} from '../../../models/article-specification.model';
import {NumberHelper} from '../../../../../helper/number-helper';
import {ArticleMultilayerSelect} from '../form-elements/article-multilayer-select.component';
import {ArticleMultilayerUnit} from '../form-elements/article-multilayer-unit';
import {FormatNumber} from '../formatters/format-number';
import {ReactNodeArray} from 'react';

interface Props {
  model: ArticleMultilayerParts;
  specification: { [key: string]: ArticleSpecification };
  partChange: (part: ArticleMultilayerParts) => void;
  children: ReactNodeArray;
}

export function ArticleMultilayerPlanPartCore(props: Props) {
  const type = ArticleMultilayerPartTypeHelper.types.core;

  const thicknessChange: (value: string | null) => void = (value) => {
    const copyPlan = {...props.model};
    const parsed = NumberHelper.saveParseFloat(value);
    copyPlan.core = {thickness_selected: parsed === null ? null : parsed * 1000}; // mm -> µm
    props.partChange(copyPlan);
  };

  const thicknessGet: () => string = () => {
    if (!props.model.core.thickness_selected) {
      return null;
    } else {
      const cal = props.model.core.thickness_selected / 1000; // µm -> mm
      return cal.toString(10).replace('.', ',');
    }
  };

  return <>
    <tr style={{'height': '100%'}}>
      {props.children[0]}
      <td className={'height-fix'}>
        <div className={'rounded-full-left justify-end color-class ' + type.color_class}>&nbsp;</div>
      </td>
      <td className={'height-fix'}>
        <div className={'rounded-full-center justify-end color-class ' + type.color_class}>&nbsp;</div>
      </td>
      {props.children[3]}
      <td className={'height-fix'}>
        <div className={'rounded-full-center color-class ' + type.color_class}>{type.label}</div>
      </td>
      <td className={'height-fix'}>
        <div className={'rounded-full-right color-class ' + type.color_class}>
          <ArticleMultilayerSelect model={'core_thickness'}
                                   specification={props.specification}
                                   value={thicknessGet()}
                                   valueType={'values'}
                                   onChange={value => thicknessChange(value)}>
            <ArticleMultilayerUnit specification={props.specification} model={'core_thickness'}/>
          </ArticleMultilayerSelect>
        </div>
      </td>
      <td/>
      <td>
        <div className={'flex align-center justify-end fit-all'}>
          <FormatNumber value={props.model.thickness / 1000} decimals={3}/>&nbsp;mm
        </div>
      </td>
      {props.children[2]}
      <td className={'border-none-top border-none-bottom'}/>
      <td className={'border-none-top border-none-bottom'}/>
      <td/>
      <td>{props.children[1]}</td>
    </tr>
  </>;
}
