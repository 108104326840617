/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {WorkflowListRequestComponent} from './list/workflow-list-request.component';
import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {WorkflowRequestService} from '../workflow-services/workflow-request.service';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {ProducerService} from '../../producer/producer.service';
import {WorkflowOrderRequestService} from '../workflow-services/workflow-order-request.service';
import {ErpTransition} from '../../../core/erp-transition.service';
import {WorkflowOrderRequestMainComponent} from '../order-request/main/workflow-order-request-main.component';
import {CustomerCreditWorthinessService} from '../../customer/credit-worthiness/customer-credit-worthiness.service';

export function requestListResolveFn(service: WorkflowRequestService, transition: ErpTransition) {
  return service.list(transition.params()).toPromise();
}

export const WORKFLOW_REQUEST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.request',
  abstract: true,
  data: {requiresAuth: true}
};

export function producerListResolveFn(service: ProducerService) {
  return service.list().toPromise();
}

export function workflowRequestListDataResolveFn(service: WorkflowRequestService, transition: Transition) {
  return service.detail(transition.params().id).toPromise();
}

export function workflowPositionsResolveFn(service: WorkflowOrderRequestService, transition: Transition) {
  return service.positions(transition.params().id).toPromise();
}

export function customerCredit(service: CustomerCreditWorthinessService, transition: Transition) {
  return service.getWithWorkflowId(transition.params().id).toPromise();
}

export const WORKFLOW_REQUEST_DETAIL_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.request.detail',
  url: '/workflow/request/{id:[0-9]+}',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: WorkflowOrderRequestMainComponent}
  },
  resolve: [
    {token: 'data', resolveFn: workflowRequestListDataResolveFn, deps: [WorkflowRequestService, Transition]},
    {token: 'producerList', resolveFn: producerListResolveFn, deps: [ProducerService]},
    {token: 'workflowPositions', resolveFn: workflowPositionsResolveFn, deps: [WorkflowOrderRequestService, Transition]},
    {token: 'customerCredit', resolveFn: customerCredit, deps: [CustomerCreditWorthinessService, Transition]},
  ],
  data: {requiresAuth: true}
};

export const WORKFLOW_REQUEST_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.request.list',
  url: '/workflow/order/request/list?page&sort&order&status&oa_nr&sid&workflow_id&sds_nr&kd_art_nr&producer&username',
  views: {'main@a': {component: WorkflowListRequestComponent}},
  params: {
    page: {dynamic: true},
    sort: {dynamic: true},
    order: {dynamic: true},
    status: {dynamic: true},
    oa_nr: {type: 'string', dynamic: true},
    sid: {dynamic: true},
    workflow_id: {dynamic: true},
    sds_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    producer: {dynamic: true},
    username: {dynamic: true}
  },
  resolve: [{token: 'listData', resolveFn: requestListResolveFn, deps: [WorkflowRequestService, ErpTransition]}]
};

export const WORKFLOW_REQUEST_STATES: Ng2StateDeclaration[] = [
  WORKFLOW_REQUEST_STATE,
  WORKFLOW_REQUEST_DETAIL_STATE,
  WORKFLOW_REQUEST_LIST_STATE
];
