import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AxiosHttpClient} from '../../../envisia/auth/axios-http-client';
import {ReasonModel, ReasonFormModel} from '../justification-reasons/reason.model';
import {AxiosResponse} from 'axios';
import Axios from 'axios';
import {BusinessOrderWastrelLister, BusinessOrderWastrelStatus} from './business-order-wastrel-reason.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderWastrelReasonAxiosService {
  constructor() {
  }

  static create(form: ReasonFormModel): Promise<ReasonModel | null> {
    return AxiosHttpClient.post<ReasonModel | null>(
      `${environment.apiv4uri}config/business/order/wastrel/reason`,
      form,
    );
  }

  static update(id: number, form: ReasonFormModel): Promise<ReasonModel | null> {
    return AxiosHttpClient.put<ReasonModel | null>(
      `${environment.apiv4uri}config/business/order/wastrel/reason/${id}`,
      form,
    );
  }

  static delete(id: number): Promise<any> {
    return AxiosHttpClient.delete(
      `${environment.apiv4uri}config/business/order/wastrel/reason/${id}`
    );
  }

  static status(): Promise<BusinessOrderWastrelStatus> {
    return AxiosHttpClient.get<BusinessOrderWastrelStatus>(
      `${environment.apiv4uri}config/business/order/wastrel/reason/status`
    );
  }

  static list(
    query?: {[key: string]: any },
    abortController?: AbortController,
  ): Promise<AxiosResponse<BusinessOrderWastrelLister>> {
    return Axios.create().get<BusinessOrderWastrelLister>(
      `${environment.apiv4uri}config/business/order/wastrel/reasons`,
      {params: query, signal: abortController?.signal},
    );
  }
}
