<ul class="nav nav-tabs">

  <li role="presentation" [ngClass]="{active: state.is('article_config.extra')}" *ngIf="('article.change'|HasPerm)">
    <a uiSref="article_config.extra">Liefervorschriften</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.main')}" *ngIf="('article.configuration'|HasPerm)">
    <a uiSref="article_config.main">Konfiguration</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.release')}" *ngIf="('article.mask'|HasPerm)">
    <a uiSref="article_config.release">Freigabemasken</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.production_step')}"
      *ngIf="('article.production_step_setting'|HasPerm)">
    <a uiSref="article_config.production_step">Fertigungsschritte</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.production_template')}"
      *ngIf="('production.template_setting'|HasPerm)">
    <a uiSref="article_config.production_template">Fertigungsvorlage</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.delete')}" *ngIf="('article.delete'|HasPerm)">
    <a uiSref="article_config.delete">Artikel löschen</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.lock')}" *ngIf="('article.lock'|HasPerm)">
    <a uiSref="article_config.lock">Artikel sperren</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.unlock')}" *ngIf="('article.lock'|HasPerm)">
    <a uiSref="article_config.unlock">Artikel entsperren</a>
  </li>

  <li role="presentation"
      [ngClass]="{active: state.is('article_config.customer_locker')}"
      *ngIf="('article.customer_locker'|HasPerm)">
    <a uiSref="article_config.customer_locker">Kunden Artikel sperren</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.weight_calculation')}" *ngIf="('article.configuration'|HasPerm)">
    <a uiSref="article_config.weight_calculation">LP Gewichtsberechnung</a>
  </li>

  <li role="presentation" [ngClass]="{active: state.is('article_config.field_names')}" *ngIf="('article.mask'|HasPerm)">
    <a uiSref="article_config.field_names">Artikel Felder Bezeichnung</a>
  </li>
</ul>
