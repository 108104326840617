<div class="filter-row">
  <div class="filter-left">
    <search-type typ="de" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>
    <search-type typ="cn" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>

    <button class="btn btn-warning btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
      Nicht versendet
    </button>

    <button class="btn btn-purple btn-sm" type="button" (click)="badge('2')" [ngClass]="{active: q('2')}">
      Versendet
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('all')" [ngClass]="{active: q('all')}">
      Alle
    </button>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th><a (click)="sort($event, 'id', 'ASC')" [href]="sortUrl('id', 'ASC')">AB-Nummer</a></th>
      <th>
        <a (click)="sort($event, 'contract_date', 'ASC')" [href]="sortUrl('contract_date', 'ASC')">
          AB-Datum
        </a>
      </th>
      <th></th>
      <th>
        Lieferdatum
      </th>
      <th>
        Abruf Bestell-Nr.
      </th>
      <th>
        <div class="click"
             [ngbTooltip]="singleRow ? 'Alle Abrufe aufklappen' : 'Alle Abrufe zuklappen'"
             (click)="toggleSingleRow(!singleRow)">
          <i [ngClass]="currentIconClass"></i>
        </div>
      </th>
      <th>
        <a (click)="sort($event, 'offer_nr', 'DESC')" [href]="sortUrl('offer_nr', 'DESC')">
          Kd.Best.Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'workflow_id', 'DESC')" [href]="sortUrl('workflow_id', 'DESC')">
          Vorgangs-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">
          SDS-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_nr', 'ASC')" [href]="sortUrl('kd_art_nr', 'ASC')">
          Kd-Art-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'ASC')" [href]="sortUrl('customer_name', 'ASC')">
          Kunde
        </a>
      </th>
    </tr>
    <tr class="filterrow">
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.contract_id" name="contract_id" [autofocus] autocomplete="off search-contract-id">
      </th>
      <th>
        <ev-datepicker [(ngModel)]="data.contract_date" name="contract_date" class="form-control input-sm"
                    [allDates]="true">
        </ev-datepicker>
      </th>
      <th></th>
      <th style="width: 85px;">
        <ev-datepicker name="contract_fetch_delivery_date"
                       class="form-control input-sm"
                       [allDates]="true"
                       [(ngModel)]="data.contract_fetch_delivery_date">
        </ev-datepicker>
      </th>
      <th>
        <input type="text"
               name="contract_fetch_order_nr"
               class="form-control input-sm"
               [(ngModel)]="data.contract_fetch_order_nr">
      </th>
      <th></th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.offer_nr" name="offer_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.workflow_id" name="workflow_id">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.sds_nr" name="sds_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.kd_art_nr" name="kd_art_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_name" name="customer_name">
      </th>
    </tr>
    </thead>
    <tbody [contract-list-element]="object"
           [contract-list-element-single-row]="singleRow"
           *ngFor="let object of list.objects;trackBy: trackByFn; let index = index">
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
