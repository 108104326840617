import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../../common/search/search.component';
import {InvoiceService} from '../services/invoice.service';
import {InvoiceListData} from '../invoice.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {Lister} from '../../../../common/wrapper.models';
import {InvoiceSendModalService} from '../send/invoice-send-modal.service';
import {environment} from '../../../../../environments/environment';
import {OposHistoryHelperService} from '../../../opos/history/opos-history-helper.service';

@Component({
  selector: 'invoice-list',
  templateUrl: './invoice-list.component.html'
})
export class InvoiceListComponent extends SearchBaseComponent<InvoiceService, InvoiceListData> {
  @Input() listData: Lister<InvoiceListData>;
  protected type = 'invoice';

  public filterFormErrors: {[key: string]: any} = {};
  protected handles400Errors = true;

  constructor(protected service: InvoiceService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private oposHistoryHelperService: OposHistoryHelperService,
              private invoiceSendModalService: InvoiceSendModalService) {
    super();
  }

  invoiceModal(obj: InvoiceListData): void {
    const flipper = (state) => {
      obj.invoice_state = state;
    };
    this.invoiceSendModalService.open(obj.id, flipper).subscribe(() => {}, () => {});
  }

  get excelPositionDownload(): string {
    return this.downloadUrl(`${environment.apiv4uri}invoice/position/list/excel`);
  }

  history(obj: InvoiceListData): void {
    this.oposHistoryHelperService.open(obj.id);
  }
}
