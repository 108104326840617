import * as React from 'react';
interface Props {
}

export function EvLoadingSpinnerElement(props: Props) {
  return <div className="flex h-full w-80 items-center justify-center">
    <div className="inline-grid grid-cols-1">
      <div className="flex items-center justify-center">
        <i className="fa fa-spin fa-circle-o-notch"/>
      </div>
    </div>
  </div>;
}
