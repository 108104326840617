import * as React from 'react';
import {
  OfferAnalysisMap,
  OfferAnalysisParamHelper,
  OfferAnalysisQueryParams,
  OfferAnalysisStateTriplet,
} from './offer-analysis.model';
import {OfferAnalysisTop} from './offer-analysis-top';
import {OfferAnalysisGroup} from './offer-analysis-group';

interface Props {
  groups: OfferAnalysisMap;
  query: OfferAnalysisQueryParams;
  reloadList: (query: OfferAnalysisQueryParams) => void;
}

export function OfferAnalysisView(props: Props): JSX.Element {

  const toggleStatus:
    (e: OfferAnalysisStateTriplet, q: OfferAnalysisQueryParams) => OfferAnalysisParamHelper = (e, q) => {
    return ({param: 'status', value: q.status === e.id ? null : e.id});
  };

  const toggleExecution:
    (e: OfferAnalysisStateTriplet, q: OfferAnalysisQueryParams) => OfferAnalysisParamHelper = (e, q) => {
    return ({param: 'execution', value: q.execution === e.label ? null : e.label});
  };

  const toggleFieldService:
    (e: OfferAnalysisStateTriplet, q: OfferAnalysisQueryParams) => OfferAnalysisParamHelper = (e, q) => {
    return ({param: 'field_service', value: q.field_service === e.name ? null : e.name});
  };

  const toggleCustomer:
    (e: OfferAnalysisStateTriplet, q: OfferAnalysisQueryParams) => OfferAnalysisParamHelper = (e, q) => {
    return ({param: 'customer_name', value: q.customer_name === e.label ? null : e.label});
  };

  const toggleRejectionReason:
    (e: OfferAnalysisStateTriplet, q: OfferAnalysisQueryParams) => OfferAnalysisParamHelper = (e, q) => {
    return ({param: 'rejection_reason_id', value: q.rejection_reason_id === e.id ? null : e.id});
  };

  const toggleArticleData:
    (e: OfferAnalysisStateTriplet, q: OfferAnalysisQueryParams) => OfferAnalysisParamHelper = (e, q) => {
    return ({param: e.name, value: q[e.name] ? null : true});
  };

  return <>
    <div className="row">
      <div className="col-lg-12">
        <h3>Auswertung Angebote</h3>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <OfferAnalysisTop groups={props.groups}/>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <OfferAnalysisGroup groupName="offer_status"
                            groupLabel="Status"
                            groups={props.groups}
                            query={props.query}
                            queryActive={(e, q) => q.status === e.id}
                            queryModification={toggleStatus}
                            reloadList={props.reloadList}/>
      </div>
      <div className="col-lg-6">
        <OfferAnalysisGroup groupName="field_services"
                            groupLabel="Vertriebs-MA"
                            groups={props.groups}
                            query={props.query}
                            queryActive={(e, q) => q.field_service === e.name}
                            queryModification={toggleFieldService}
                            reloadList={props.reloadList}/>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <OfferAnalysisGroup groupName="execution"
                            groupLabel="LP-Typ"
                            groups={props.groups}
                            query={props.query}
                            queryActive={(e, q) => q.execution === e.label}
                            queryModification={toggleExecution}
                            reloadList={props.reloadList}/>
      </div>
      <div className="col-lg-6">
        <OfferAnalysisGroup groupName="customers"
                            groupLabel="Top 8 Kunden"
                            groups={props.groups}
                            query={props.query}
                            queryActive={(e, q) => q.customer_name === e.label}
                            queryModification={toggleCustomer}
                            reloadList={props.reloadList}/>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <OfferAnalysisGroup groupName="article_data"
                            groupLabel="Artikel Merkmale"
                            groups={props.groups}
                            query={props.query}
                            queryActive={(e, q) => q[e.name]}
                            queryModification={toggleArticleData}
                            reloadList={props.reloadList}/>
      </div>
      <div className="col-lg-6">
        <OfferAnalysisGroup groupName="rejection_reasons"
                            groupLabel="Ablehnungsgrund"
                            groups={props.groups}
                            query={props.query}
                            queryActive={(e, q) => q.rejection_reason_id === e.id}
                            queryModification={toggleRejectionReason}
                            reloadList={props.reloadList}/>
      </div>
    </div>
  </>;
}
