import {Component, Input} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MaterialUnit} from '../material-management.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialUnitsCreateComponent} from './material-units-create.component';

@Component({
  selector: 'material-units-list',
  templateUrl: './material-units-list.component.html'
})
export class MaterialUnitsListComponent {
  @Input() units: MaterialUnit[];

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
  ) {

  }

  openModal(unit?: MaterialUnit) {
    const modalRef = this.modalService.open(MaterialUnitsCreateComponent, {windowClass: 'modal2-sm'});
    modalRef.componentInstance.form = unit ? this.updateForm(unit) : this.insertForm();
    modalRef.result.then((list) => this.units = list).catch((_) => {});
  }

  updateForm(unit: MaterialUnit) {
    return this.fb.group({id: [unit.id, {emitEvent: false}], unit: [unit.unit, [Validators.required]]});
  }

  insertForm() {
    return this.fb.group({unit: [null, [Validators.required]]});
  }
}
