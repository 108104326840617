<div class="buttonbar-view">
  <material-product-groups-navigation (openCreate)="openCreate()"></material-product-groups-navigation>
</div>

<form #changeForm="ngForm" name="form">
  <div class="row">
    <div class="col-md-12">
      <table class="table whitetable">
        <thead>
        <tr>
          <th class="align-right" style="width:120px;">
            <a (click)="sort($event, 'id', 'DESC')" [href]="sortUrl('id', 'ASC')">
              Nr.
            </a>
          </th>
          <th style="width: 400px;">
            <a (click)="sort($event, 'name', 'DESC')" [href]="sortUrl('name', 'ASC')">
              Name
            </a>
          </th>
          <th colspan="2">Material Gruppen</th>
          <th colspan="2"></th>
        </tr>
        <tr class="filterrow">
          <th><input type="text" class="form-control input-sm" [(ngModel)]="data.id" name="id"></th>
          <th><input type="text" class="form-control input-sm" [(ngModel)]="data.name" name="name"></th>
          <th colspan="4"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let productGroup of list.objects; trackBy: trackByFn; let index = index">
          <td class="align-right">{{ productGroup.product_group.id }}</td>
          <td>{{ productGroup.product_group.name }}</td>
          <td class="icon">
            <i class="fa fa-pencil-square-o click" (click)="openSelection(productGroup)"></i>
          </td>
          <td>
            <div class="overflow-ellipsis no-wrap" style="width: 400px;">
              <div class="inline-block" style="padding-right: 8px;" *ngFor="let group of productGroup.groups">
                {{group.group_id}}&nbsp;{{group.name}}
              </div>
            </div>
          </td>
          <td>
            <button (click)="openUpdate(productGroup)" class="btn btn-link btn-sm" type="button">
              <i class="fa fa-pencil" style="font-size: 1.5em"></i>
            </button>
          </td>
          <td>
            <button (click)="doDelete(productGroup)" class="btn btn-link btn-sm" type="button">
              <i class="fa fa-trash-o" style="font-size: 1.5em"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
    </div>
  </div>
</form>
