/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {LocalStorage} from '../../../common/storage/local-storage';
import {StreamDataService} from './stream-data.service';
import {noop} from '../../../helper/noop';
import {NativeEventSource, EventSourcePolyfill} from 'event-source-polyfill';
import {RightService} from '../../../common/right-service/right.service';
import ReconnectingEventSource from 'reconnecting-eventsource';
import {createLeaderElection, BroadcastChannel} from 'broadcast-channel';

window['EventSource'] = NativeEventSource || EventSourcePolyfill;

@Injectable({providedIn: 'root'})
export class StreamService {
  private eventSource: any | null = null;
  private channel: BroadcastChannel<any> | null = null;
  private errorCount = 0;
  private allErrors = 0;
  private leader = false;
  private priceState: any | null = null;
  private printFailed: any | null = null;

  constructor(private localStorage: LocalStorage,
              private service: StreamDataService,
              private rightService: RightService) {
  }

  newEventSource(logoutFn: (inactiviy?: boolean) => void): void {
    if (!this.channel) {
      this.channel = new BroadcastChannel<any>('event-source');

      this.channel.addEventListener('message', message => {
        if (message === 'logoutAll' || message === 'logoutAllInactivity') {
          logoutFn(message === 'logoutAllInactivity');
        } else if (message === 'replay_state') {
          if (this.leader) {
            if (this.priceState) {
              this.channel.postMessage(this.priceState);
            }
            if (this.printFailed) {
              this.channel.postMessage(this.printFailed);
            }
          }
        } else {
          this.internalRoute(message);
        }
      });

      const channelLeaderElection = new BroadcastChannel<any>('event-source-leader-election');
      const elector = createLeaderElection(channelLeaderElection, {
        fallbackInterval: 2000, // optional configuration for how often will renegotiation for leader occur
        responseTime: 1000, // optional configuration for how long will instances have to respond
      });
      elector.awaitLeadership().then(() => {
        this.leader = true;
        console.log('i am the event-source leader');
        // EventSource should only be opened, once per application
        if (!this.eventSource) {
          this.openEventSource();
        }
      });

      setTimeout(() => {
        this.channel.postMessage('replay_state');
      }, 1500);
    }
  }

  logout(inactivity = false): void {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }

    if (this.channel) {
      this.channel.postMessage('logoutAll' + (inactivity ? 'Inactivity' : ''));
    }
  }

  private eventSourceError(e: any) {
    this.errorCount += 1;
    if (this.errorCount === 5) {
      this.allErrors += 1;
      this.eventSource.close();
      this.eventSource = null;
      this.errorCount = 0;
      console.log('eventSource failed', e);
      // we do not reconnect if the errors are too much per open browser session
      if (this.allErrors <= 1000) {
        setTimeout(() => {
          this.openEventSource();
        }, 10000);
      }
    }
  }

  private openEventSource() {
    const token = this.localStorage.get('token');
    // https://github.com/Microsoft/TypeScript/issues/13666#issuecomment-330320248
    this.eventSource = new ReconnectingEventSource(this.createEventSourceUri(), {});

    this.eventSource.addEventListener('error', (e) => {
      this.eventSourceError(e);
    }, false);

    this.eventSource.addEventListener('message', (e) => {
      const msg = JSON.parse(e.data);
      if (this.leader) {
        this.channel.postMessage(msg);
      }

      this.internalRoute(msg);
    }, false);
  }

  private createEventSourceUri(): string {
    noop(this);
    const location = window.location;
    return location.protocol + '//' + location.host + '/api/stream';
  }

  private internalRoute(data) {
    if (data.type === 'price') {
      this.priceState = data;
      this.service.nextPrice(data.count > 0);
    } else if (data.type === 'dashboard_event') {
      this.service.next('websocket.dashboard_event.sent', data);
    } else if (data.type === 'debug_event_batch') {
      if (this.rightService.has('debugging.calc')) {
        data.objects.forEach(element => {
          console.log('%c ' + element.msg + ' ', element.format);
        });
      }

    } else if (data.type === 'debug_event') {
      if (this.rightService.has('debugging.calc')) {
        if (!!data.clear) {
          console.clear(); // DebugMessage Clear has been sent
        }
        console.log('%c ' + data.msg + ' ', data.format);
      }
    } else if (data.type === 'new_version') {
      this.service.next('websocket.new_version', data);
    } else if (data.type === 'print_failed') {
      this.printFailed = data;
      this.service.nextPrintState(data.count > 0);
    } else if (data.type === 'invoice') {
      this.service.next('websocket.invoice.sent', data);
    } else if (data.type === 'websocket.article.duplicate') {
      this.service.next('websocket.article.duplicate', data);
    } else if (data.type === 'websocket.article.reset') {
      this.service.next('websocket.article.reset', true);
    } else {
      this.service.next(data.type, data);
    }
  }

}
