<div class="modal-body inventory" [formGroup]="form">
  <div class="row mb-10">
    <div class="col-md-3">Datecode:</div>
    <div class="col-md-9"><span>{{inventory.dateCode}}</span></div>
  </div>

  <div class="row mb-10">
    <div class="col-md-3">Lager:</div>
    <div class="col-md-9"><span>{{inventory.storageBinName}}</span></div>
  </div>

  <div class="row mb-10">
    <div class="col-md-3">Lagerplatz:</div>
    <div class="col-md-9"><span>{{inventory.storageBinNumber}}</span></div>
  </div>

  <div class="row mb-10" *ngIf="!!inventory.workflowId">
    <div class="col-md-3">Vorgangs Nr.:</div>
    <div class="col-md-9"><span>{{inventory.workflowId}}</span></div>
  </div>

  <div class="row mb-10">
    <div class="col-sm-3">
      <label for="storage_note">Hinweis</label>
    </div>
    <div class="col-sm-9">
        <textarea formControlName="storage_note" class="form-control" id="storage_note" rows="3"
                  [ngClass]="{red_alert: errors['storage_note']}"></textarea>
    </div>
  </div>
</div>
