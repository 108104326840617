import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OrderPdfEditModalComponent} from './order-pdf-edit-modal.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../../core/shared.module';



@NgModule({
    declarations: [
        OrderPdfEditModalComponent,
    ],
    imports: [
        CommonModule,
        NgbModalModule,
        SharedModule,
    ],
    exports: [
        OrderPdfEditModalComponent
    ]
})
export class OrderPdfEditModalModule { }
