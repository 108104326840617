import {Component, Input, OnInit} from '@angular/core';
import {Lister} from '../../common/wrapper.models';
import {ArticleMultilayerPlan} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.models';
import {noop} from '../../helper/noop';
import {MultilayerPlanAxiosService} from './multilayer-plan-axios.service';
import {PaginationBaseComponent} from '../../common/pagination/pagination.component';
import {StateService} from '@uirouter/angular';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  ArticleMultilayerPlanAxiosService
} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan-axios.service';

@Component({
  selector: 'multilayer-plan-list',
  templateUrl: './multilayer-plan-list.component.html',
})
export class MultilayerPlanListComponent
  extends PaginationBaseComponent<MultilayerPlanAxiosService, ArticleMultilayerPlan> implements OnInit {
  @Input() list: Lister<ArticleMultilayerPlan>;
  form: UntypedFormGroup;

  constructor(protected service: MultilayerPlanAxiosService,
              private fb: UntypedFormBuilder,
              private stateService: StateService) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      shared_id: [null, Validators.required],
      name: [null, Validators.required],
    });
  }

  trackBy(id: number, plan: ArticleMultilayerPlan): number {
    noop(this);
    return plan.id;
  }

  delete(plan: ArticleMultilayerPlan): void {
    if (confirm(`Soll der Aufbauplan "${plan.name}" wirklich dauerhaft gelöscht werden?`)) {
      MultilayerPlanAxiosService.delete(plan.shared_id).subscribe(() => {
        const stateId = this.stateService.params.id;
        if (stateId === plan.shared_id) {
          this.stateService.go('a.multilayerplan.create');
        } else {
          this.service.list().subscribe(list => {
            this.list = list;
          });
        }
      });
    }
  }

  setUpdateNameTarget(plan: ArticleMultilayerPlan): void {
    if (this.form.value.shared_id !== plan.shared_id) {
      this.form.setValue({'name': plan.name, 'shared_id': plan.shared_id});
    } else {
      this.form.setValue({'name': null, 'shared_id': null});
    }
  }

  updateName(): void {
    if (!this.form.value.name || !this.form.value.shared_id) {
      return;
    }

    MultilayerPlanAxiosService.updateName(this.form.value).subscribe(() => {
      this.reload();
      this.form.setValue({'name': null, 'shared_id': null});
    });
  }

  openPdf(amp: ArticleMultilayerPlan): void {
    ArticleMultilayerPlanAxiosService.bewitPdf(amp.id).then(s => {
      window.open(s.object, '_blank');
    });
  }
}
