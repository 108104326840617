<div>
  &nbsp;
  <a uiSref="a.material.inventory.create"
     title="Artikel Einpflegen"
     class="btn btn-default"
     *ngIf="inInventory">
    <span class="fa fa-plus"></span>
  </a>

  <a class="btn btn-default" (click)="openModal()" *ngIf="!!variation && !!article">
    Wareneingang einbuchen
  </a>

  <a class="btn btn-default"
     uiSref="a.material.order.create"
     target="_blank"
     [uiParams]="{supplier_id: article?.supplier_id, marked_variation_id: variation?.id}"
     *ngIf="('material.order.create'|HasPerm)">
      <i class="fa fa-truck"></i>&nbsp;Material bestellen
    </a>
</div>
