/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ArticleExtraService {

  private uri = '/article/v2/extra/';

  constructor(private http: HttpClient) {
  }

  startNumber(): Observable<number> {
    return this.http.get<EnvisiaObject<number>>(this.uri + 'startnumber/').pipe(map(value => value.object));
  }

}
