import {Component, Input} from '@angular/core';
import {MaterialOrderTransaction} from '../../material-order.model';
import {MaterialOrderIntakePdfRow} from '../material-order-intake-pdf-row';

@Component({
  selector: 'tr[material-order-intake-print-row]',
  templateUrl: './material-order-intake-print-row.component.html',
})
export class MaterialOrderIntakePrintRowComponent extends MaterialOrderIntakePdfRow {
  @Input('material-order-intake-print-row') transaction: MaterialOrderTransaction;

  constructor() {
    super();
  }
}
