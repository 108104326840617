import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../core/shared.module';
import { PriceSelectorComponent } from './price-selector.component';



@NgModule({
  declarations: [PriceSelectorComponent],
  exports: [PriceSelectorComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class PriceSelectorModule { }
