import {NumberHelper} from '../../../../helper/number-helper';
import {
  ArticleMultilayerCycleRow,
  ArticleMultilayerCycleThickness,
  ArticleMultilayerLayerLabel,
  ArticleMultilayerLayerLabelWithType,
  ArticleMultilayerPartCopper,
  ArticleMultilayerPartCuKasch,
  ArticleMultilayerPartLacquer,
  ArticleMultilayerPartPrepreg,
  ArticleMultilayerParts,
  ArticleMultilayerPartWithCopperGroup,
  ArticleMultilayerPartWithThickness,
  ArticleMultilayerPlanModel,
} from './article-multilayer-plan.models';
import {ArticleMultilayerPartTypeHelper} from './article-multilayer-part-type-helper';
import {BuildLayer, ParsedArticleData} from './article-data-parser-helper';

interface PartReturn {
  parts: ArticleMultilayerParts[];
  cycle: number;
}

export class ArticleMultilayerPlanHelper {
  static copperThickness: number[] = [0, 12, 18, 35, 70, 105, 140, 210, 235];
  static prepregTissues: string[] = ['106', '1080', '2116', '7628'];

  static layerLabelClean(layerLabel: ArticleMultilayerLayerLabelWithType): ArticleMultilayerLayerLabel {
    return {short: layerLabel.short, long: layerLabel.long};
  }

  public static getSelectableLabelsClean(model: ArticleMultilayerPlanModel): ArticleMultilayerLayerLabel[] {
    return ArticleMultilayerPlanHelper.getSelectableLabels(model).map(ArticleMultilayerPlanHelper.layerLabelClean);
  }

  public static getSelectableLabels(model: ArticleMultilayerPlanModel): ArticleMultilayerLayerLabelWithType[] {
    const cuLayers = model.parts.filter(p => p.type === 2 || p.type === 3); // cu_foil or cu_kasch
    const cuFoilNum = Math.max(2, Math.ceil(model.parts.filter(p => p.type === 2).length / 2) - 1); // cu_foil
    return [
      {'short': 'mt', 'top': true, 'type': 1, 'long': 'Maske Top'},
      {'short': 'lt', 'top': true, 'type': 2, 'long': 'Layer Top / B-Seite / L1'},
      ...Array.from(Array(Math.ceil(cuFoilNum))).map((l, i) => {
        return {'short': `t${cuFoilNum - i}`, 'top': true, 'type': 3, 'long': `${cuFoilNum - i}. Aufbaulage nur HDI`};
      }),
      ...Array.from(Array(cuLayers.length > 3 ? cuLayers.length - 2 : 2)).map((l, i) => {
        return {'short': `i${i + 1}`, 'top': null, 'type': 4, 'long': `Inner ${i + 1}/L${i + 2}`};
      }),
      ...Array.from(Array(Math.ceil(cuFoilNum))).map((l, i) => {
        return {'short': `b${i + 1}`, 'top': false, 'type': 3, 'long': `${i + 1}. Aufbaulage nur HDI`};
      }),
      {'short': 'lb', 'top': false, 'type': 2, 'long': 'Layer Bottom / A-Seite / LN'},
      {'short': 'mb', 'top': false, 'type': 1, 'long': 'Maske Bottom'},
    ];
  }

  // Builds the layers from the HDI hdi_type string
  static hdiLayerParse(hdiLayers: string): BuildLayer[] {
    if (!hdiLayers) {
      return [];
    }

    const layers: number[] = hdiLayers.split('-').map(ls => NumberHelper.saveParseInteger(ls));
    const layersLength = layers.length;
    if (layersLength % 2 !== 1) {
      console.error('execution layers in hdi are of incorrect sequence length (should be an odd number)');
    }

    const layersToParse = Math.floor(layersLength / 2);
    const buildLayers: BuildLayer[] = [
      {type: 'cu_plating'},
      {type: 'cu_foil', layers_a: 1, layers_b: 1, is_cu_outside: true},
    ];

    const centerExecution = layers[layersToParse];
    if (centerExecution % 2 !== 0) {
      console.error(`execution was not an even number: ${centerExecution}`);
    }

    for (let i = 0; i < (centerExecution === 2 ? layersToParse - 1 : layersToParse); i++) {
      // Verify layer template (currently all but the center one must be 1 layer high)
      if (layers[i] !== layers[layersLength - 1 - i] || layers[i] !== 1) {
        console.error('could not verify correct layer template');
        return [];
      }

      buildLayers.push({type: 'cu_plating'});
      buildLayers.push({type: 'cu_foil', layers_a: layers[i], layers_b: layers[layersLength - 1 - i]});
    }

    if (centerExecution === 2) {
      // Special case of ...-2-... which does not use any cu_foil inside but plates the cores
      buildLayers.push({type: 'cu_plating'});
      buildLayers.push({type: 'core', cores: 1});
      return buildLayers;
    }

    buildLayers.push({type: 'core', cores: (centerExecution / 2) - 1});

    return buildLayers;
  }

  // parses the execution string and builds the default setup for it
  static executionParse(execution: string): BuildLayer[] {
    if (!execution || !execution.startsWith('ML') || execution === 'ML2') {
      return [];
    }

    const executionLayers = NumberHelper.saveParseInteger(execution.split('ML')[1]);
    if (executionLayers % 2 !== 0) {
      console.error('execution was not an even number');
      return [];
    }

    return [
      {type: 'cu_plating'},
      {type: 'cu_foil', layers_a: 1, layers_b: 1, is_cu_outside: true},
      {type: 'core', cores: (executionLayers / 2 - 1)},
    ];
  }

  // Directs the recursion to the correct part and checks if a layer can not be reached
  // Errors should not be triggered as the parsed data passed to here is static (for now)
  static createRecursiveCheckNext(
    buildLayers: BuildLayer[],
    articleData: ParsedArticleData
  ): PartReturn {
    switch (buildLayers[0]?.type) {
      case 'core':
        if (buildLayers.length !== 1) {
          throw Error('tried building core before or after end of last layer');
        }
        return ArticleMultilayerPlanHelper.createRecursiveCoreParts(buildLayers[0], articleData);
      case 'cu_foil':
        if (buildLayers.length <= 1) {
          throw Error('can not reach core layer with current number of build layers');
        }
        return ArticleMultilayerPlanHelper.createRecursiveCuFoilParts(buildLayers, articleData);
      case 'cu_plating':
        if (buildLayers.length <= 1) {
          throw Error('can not reach core layer with current number of build layers');
        }
        return ArticleMultilayerPlanHelper.createRecursivePlatingParts(buildLayers, articleData);
      default:
        throw Error('could not reach core build layer');
    }
  }

  // Creates the core parts and terminates the recursive part of the building process
  static createRecursiveCoreParts(
    buildLayer: BuildLayer,
    articleData: ParsedArticleData,
  ): PartReturn {
    const parts: ArticleMultilayerParts[] = [];
    for (let i = 0; i < buildLayer.cores; i++) {
      if (i > 0) {
        parts.push(ArticleMultilayerPlanHelper.getEmpty('prepreg', 1));
      }
      const innerValue = articleData.inner_values[i];
      parts.push(ArticleMultilayerPlanHelper.getEmpty('cu_kasch', 1, {
        thickness_selected: innerValue.cu_inside ?? null,
        area_used: 50,
      }));
      parts.push(ArticleMultilayerPlanHelper.getEmpty('core', 1, {
        thickness_selected: innerValue.core_thickness ?? null,
      }));
      parts.push(ArticleMultilayerPlanHelper.getEmpty('cu_kasch', 1, {
        thickness_selected: innerValue.cu_inside ?? null,
        area_used: 50,
      }));
    }

    return {parts: parts, cycle: 1};
  }

  // Creates a layer of cu foil atop of a prepreg layer
  static createRecursiveCuFoilParts(
    buildLayers: BuildLayer[],
    articleData: ParsedArticleData,
  ): PartReturn {
    const inner = ArticleMultilayerPlanHelper.createRecursiveCheckNext(buildLayers.slice(1), articleData);
    const parts = inner.parts;
    const cuOutside = buildLayers[0]?.is_cu_outside === true;
    const cuThickness = cuOutside ? articleData.cu_outside : articleData.cu_core;
    parts.unshift(ArticleMultilayerPlanHelper.getEmpty('prepreg', inner.cycle));
    parts.unshift(ArticleMultilayerPlanHelper.getEmpty('cu_foil', inner.cycle, {
      thickness_selected: cuThickness ?? null,
      area_used: 50,
    }));

    parts.push(ArticleMultilayerPlanHelper.getEmpty('prepreg', inner.cycle));
    parts.push(ArticleMultilayerPlanHelper.getEmpty('cu_foil', inner.cycle, {
      thickness_selected: cuThickness ?? null,
      area_used: 50,
    }));
    return {parts: parts, cycle: inner.cycle};
  }

  // Creates a single layer of cu plating
  static createRecursivePlatingParts(
    buildLayers: BuildLayer[],
    articleData: ParsedArticleData,
  ): PartReturn {
    const inner = ArticleMultilayerPlanHelper.createRecursiveCheckNext(buildLayers.slice(1), articleData);
    const parts = inner.parts;
    parts.unshift(ArticleMultilayerPlanHelper.getEmpty('cu_plating', inner.cycle + 1, {
      thickness_selected: 25,
    }));

    parts.push(ArticleMultilayerPlanHelper.getEmpty('cu_plating', inner.cycle + 1, {
      thickness_selected: 25,
    }));
    return {parts: parts, cycle: inner.cycle + 1};
  }

  // Adds the lacquer layers for either side once and starts the recursive part
  static createRecursiveOuterParts(
    buildLayers: BuildLayer[],
    articleData: ParsedArticleData,
  ): ArticleMultilayerParts[] {
    const inner = ArticleMultilayerPlanHelper.createRecursiveCheckNext(buildLayers, articleData);
    const parts = inner.parts;
    if (articleData.has_solder_resist_a) {
      parts.unshift(ArticleMultilayerPlanHelper.getEmpty('lacquer', 2, {
        thickness_selected: ArticleMultilayerPlanHelper.mapLacquerThickness(articleData.cu_outside),
        thickness_proposed: null,
        custom: false,
      }));
    }

    if (articleData.has_solder_resist_b) {
      parts.push(ArticleMultilayerPlanHelper.getEmpty('lacquer', 2, {
        thickness_selected: ArticleMultilayerPlanHelper.mapLacquerThickness(articleData.cu_outside),
        thickness_proposed: null,
        custom: false,
      }));
    }
    return parts;
  }

  // Parses the article and starts the recursive call of creation for layers
  static createRecursiveOuter(article: { [key: string]: any }): ArticleMultilayerPlanModel {
    const model: ArticleMultilayerPlanModel = ArticleMultilayerPlanHelper.emptyModel();

    const execution = article['execution'] as string | undefined | null;
    const hdiType = article['hdi_type'] as string | undefined | null;
    const buildLayers = !!hdiType ?
      ArticleMultilayerPlanHelper.hdiLayerParse(hdiType) :
      ArticleMultilayerPlanHelper.executionParse(execution);

    const cuLayersTotal = NumberHelper.sum(buildLayers.map(l => (l.cores ?? 0)));
    const mixedStructure = NumberHelper.saveParseInteger(article['mixed_structure']);
    const cuThickness = ArticleMultilayerPlanHelper.mapCuOuterFromCuThickness(article['cu_thickness']);
    const cuOutside = NumberHelper.saveParseInteger(article['cu_outside']) ?? cuThickness;
    const cuCore = NumberHelper.saveParseInteger(article['cu_core']);
    const articleData: ParsedArticleData = {
      mixed_structure: mixedStructure,
      mixed_structure_used: !!mixedStructure && (mixedStructure < 10) && (mixedStructure === cuLayersTotal),
      inner_values: [],
      has_solder_resist_a: !!article['solder_resist_a'],
      has_solder_resist_b: !!article['solder_resist_b'],
      cu_core: cuCore ?? null,
      cu_outside: cuOutside ?? null,
      cu_thickness: cuThickness,
      material: article['material_internal'] ?? article['manufacturer'] ?? 'Standard FR4',
    };

    for (let i = 0; i < (articleData.mixed_structure_used ? mixedStructure : cuLayersTotal); i++) {
      const index = articleData.mixed_structure_used ? i + 1 : 1;
      const coreThickness = NumberHelper.saveParseFloat(article['core_thickness_' + index]);
      const cuInsideParsed = NumberHelper.saveParseInteger(article['cu_inside_' + index]);
      articleData.inner_values.push({
        core_thickness: coreThickness ? coreThickness * 1000 : null, // mm -> µm
        cu_inside: cuInsideParsed ? cuInsideParsed : null,
      });
    }

    model.selected_material = articleData.material;
    model.parts = ArticleMultilayerPlanHelper.createRecursiveOuterParts(buildLayers, articleData);

    return ArticleMultilayerPlanHelper.refreshLayerLabel(ArticleMultilayerPlanHelper.recalculate(model));
  }

  static refreshLayerLabel(model: ArticleMultilayerPlanModel): ArticleMultilayerPlanModel {
    const layerLabels = ArticleMultilayerPlanHelper.getSelectableLabels(model);
    const partsLength = model.parts.length;
    const partCenter = Math.floor(model.parts.length / 2);
    // First comb towards the center to set A/B layers and masks and to get core and foil indices
    let gotCuFoilLayerTop = false;
    let gotCuFoilLayerBottom = false;
    const coreIndices: number[] = [];
    const foilIndicesTop: number[] = [];
    const foilIndicesBottom: number[] = [];
    for (let i = 0; i < partCenter; i++) {
      [true, false].forEach(isTop => {
        const currentIndex = isTop ? i : partsLength - 1 - i;
        const current = model.parts[currentIndex];
        const partType = ArticleMultilayerPartTypeHelper.get(current.type);
        switch (partType.name) {
          case 'cu_foil':
            if (isTop) {
              if (!gotCuFoilLayerTop) {
                gotCuFoilLayerTop = true;
                current.label = ArticleMultilayerPlanHelper.layerLabelClean(layerLabels[1]);
              } else {
                foilIndicesTop.push(currentIndex);
              }
            } else {
              if (!gotCuFoilLayerBottom) {
                gotCuFoilLayerBottom = true;
                current.label = ArticleMultilayerPlanHelper.layerLabelClean(layerLabels[layerLabels.length - 2]);
              } else {
                foilIndicesBottom.push(currentIndex);
              }
            }
            break;
          case 'lacquer':
            current.label = ArticleMultilayerPlanHelper.layerLabelClean(
              isTop ? layerLabels[0] : layerLabels[layerLabels.length - 1]
            );
            break;
          case 'cu_kasch':
            coreIndices.push(currentIndex);
            break;
          default:
            break;
        }
      });
    }

    if (gotCuFoilLayerTop !== gotCuFoilLayerBottom || foilIndicesTop.length !== foilIndicesBottom.length) {
      console.log('could not find correct layout', partsLength, partCenter, gotCuFoilLayerTop, gotCuFoilLayerBottom);
      return model;
    }

    const coreLabels = layerLabels.filter(l => l.type === 4);
    coreIndices.sort((a, b) => a - b).forEach((coreIndex, i) => {
      model.parts[coreIndex].label = ArticleMultilayerPlanHelper.layerLabelClean(coreLabels[i]);
    });

    const foilLabelsTop = layerLabels.filter(l => l.type === 3 && l.top).reverse();
    foilIndicesTop.sort((a, b) => b - a).forEach((foilIndex, i) => {
      model.parts[foilIndex].label = ArticleMultilayerPlanHelper.layerLabelClean(foilLabelsTop[i]);
    });

    const foilLabelsBottom = layerLabels.filter(l => l.type === 3 && !l.top);
    foilIndicesBottom.sort((a, b) => a - b).forEach((foilIndex, i) => {
      model.parts[foilIndex].label = ArticleMultilayerPlanHelper.layerLabelClean(foilLabelsBottom[i]);
    });
    return model;
  }

  static mapLacquerThickness(thickness: number): number {
    switch (thickness) {
      case 12: return 30;
      case 18: return 30;
      case 35: return 30;
      case 50: return 40;
      case 70: return 40;
      case 105: return 55;
      case 140: return 55;
      case 175: return 60;
      case 210: return 70;
      case 235: return 70;
      default: return 0;
    }
  }

  // Tries to find the next smaller thickness
  static mapCuOuterFromCuThickness(cuThickness: number | null): number | null {
    if (cuThickness === null) {
      return null;
    }
    const cuThicknessIndex = ArticleMultilayerPlanHelper.copperThickness.findIndex(ct => ct === cuThickness);
    if (cuThicknessIndex > 0) {
      return ArticleMultilayerPlanHelper.copperThickness[cuThicknessIndex - 1];
    }
    return 0;
  }

  static mapPrepregDataToThickness(value: string): number {
    switch (value) {
      case '106':
        return 57;
      case '1080':
        return 76;
      case '2116':
        return 121;
      case '7628':
        return 197;
      default:
        return null;
    }
  }

  public static copyPart(part: ArticleMultilayerParts): ArticleMultilayerParts {
    const name = ArticleMultilayerPartTypeHelper.getName(part.type);
    const base = ArticleMultilayerPlanHelper.getEmpty(name, part.cycle);
    base[name] = {...part[name]};
    base.label = !part.label ? null : {...part.label};
    base.drill_data = [...part.drill_data ?? []];
    return base;
  }

  // Copies the model but does not recalculate anything - use recalculate before using the created model
  public static copy(model: ArticleMultilayerPlanModel): ArticleMultilayerPlanModel {
    return {
      parts: (model.parts ?? []).map(p => ArticleMultilayerPlanHelper.copyPart(p)),
      copper_group: [...(model.copper_group ?? [])],
      cycles_total: model.cycles_total,
      cycles_has_finishing: model.cycles_has_finishing,
      drill_data: (model.drill_data ?? []).map(dd => {
        return {...dd};
      }),
      selected_material: model.selected_material,
      thickness_prepreg_group: [...model.thickness_prepreg_group],
      thickness_cycle: [...model.thickness_cycle],
      thickness_total: model.thickness_total,
    };
  }

  public static emptyModel(): ArticleMultilayerPlanModel {
    return {
      parts: [],
      copper_group: [],
      cycles_total: 0,
      cycles_has_finishing: false,
      drill_data: [],
      selected_material: null,
      thickness_prepreg_group: [],
      thickness_cycle: [],
      thickness_total: 0,
    };
  }

  public static getEmpty(
    name: string,
    cycle: number,
    defaultValue?:
      ArticleMultilayerPartWithThickness |
      ArticleMultilayerPartCopper |
      ArticleMultilayerPartCuKasch |
      ArticleMultilayerPartPrepreg |
      ArticleMultilayerPartLacquer
  ): ArticleMultilayerParts | null {
    const partType = ArticleMultilayerPartTypeHelper.types[name];
    if (!partType) {
      return null;
    }

    const base: ArticleMultilayerParts = {
      type: partType.type,
      index: 0,
      cycle: cycle,
      thickness: 0,
      thickness_from_top: 0,
      thickness_from_bottom: 0,
      core: null,
      cu_foil: null,
      cu_kasch: null,
      cu_plating: null,
      lacquer: null,
      prepreg: null,
      label: null,
      drill_data: [],
    };

    switch (partType.name) {
      case 'core':
        const defCore = (defaultValue as ArticleMultilayerPartWithThickness | null | undefined);
        base.core = {
          thickness_selected: defCore?.thickness_selected ?? null,
        };
        break;
      case 'cu_foil':
        const defCuFoil = (defaultValue as ArticleMultilayerPartCopper | null | undefined);
        base.cu_foil = {
          thickness_selected: defCuFoil?.thickness_selected ?? null,
          index_copper: defCuFoil?.index_copper ?? 0,
          area_used: defCuFoil?.area_used ?? 50,
        };
        break;
      case 'cu_kasch':
        const defCuKasch = (defaultValue as ArticleMultilayerPartCuKasch | null | undefined);
        base.cu_kasch = {
          thickness_selected: defCuKasch?.thickness_selected ?? null,
          index_copper: defCuKasch?.index_copper ?? 0,
          area_used: defCuKasch?.area_used ?? 50,
          index_core: defCuKasch?.index_core ?? 0,
        };
        break;
      case 'cu_plating':
        const defPlating = (defaultValue as ArticleMultilayerPartWithCopperGroup | null | undefined);
        base.cu_plating = {
          thickness_selected: defPlating?.thickness_selected ?? null,
          group: defPlating?.group ?? null,
        };
        break;
      case 'lacquer':
        const defLacquer = (defaultValue as ArticleMultilayerPartLacquer | null | undefined);
        if (defLacquer?.custom === true && defLacquer?.thickness_selected !== defLacquer?.thickness_proposed) {
          base.lacquer = {
            thickness_selected: defLacquer?.thickness_selected ?? null,
            thickness_proposed: defLacquer?.thickness_proposed ?? null,
            custom: true,
          };
        } else {
          base.lacquer = {
            thickness_selected: defLacquer?.thickness_selected ?? null,
            thickness_proposed: null,
            custom: false,
          };
        }
        break;
      case 'prepreg':
        const defPrepreg = (defaultValue as ArticleMultilayerPartPrepreg | null | undefined);
        base.prepreg = {
          tissue: defPrepreg?.tissue ?? null,
          group: defPrepreg?.group ?? 0,
          thickness_selected: defPrepreg?.thickness_selected ?? null,
        };
        break;
      default:
        break;
    }

    return base;
  }

  public static partsToAdd(
    partName: string,
    model: ArticleMultilayerPlanModel,
    index: number
  ): ArticleMultilayerParts[] {
    const prev = index > 0 ? model.parts[index - 1] : null;
    const next = index < model.parts.length ? model.parts[index] : null;
    const drillData: boolean[] = model.drill_data?.map(() => false);
    if (!!prev && !!next) {
      for (let i = 0; i < (prev.drill_data?.length ?? 0) && i < (next.drill_data?.length ?? 0); i++) {
        drillData[i] = prev.drill_data[i] && next.drill_data[i];
      }
    }

    const parts = (partName === 'core') ? [
      ArticleMultilayerPlanHelper.getEmpty('cu_kasch', prev?.cycle ?? 1),
      ArticleMultilayerPlanHelper.getEmpty('core', prev?.cycle ?? 1),
      ArticleMultilayerPlanHelper.getEmpty('cu_kasch', prev?.cycle ?? 1),
    ] : [
      ArticleMultilayerPlanHelper.getEmpty(partName, prev?.cycle ?? 1),
    ];

    parts.forEach(part => {
      part.drill_data = [...drillData];
    });

    return parts;
  }

  public static mirrorCopy(model: ArticleMultilayerPlanModel, flipIndex: number): ArticleMultilayerPlanModel {
    const copy = ArticleMultilayerPlanHelper.copy(model);
    const typeName = ArticleMultilayerPartTypeHelper.getName(model.parts[flipIndex].type);
    const saveFlipIndex =
      typeName === 'core'     ? flipIndex + 1 :
      typeName === 'cu_kasch' ? model.parts[flipIndex].cu_kasch.index_core + 1 :
                                flipIndex;

    for (let i = 0; i <= saveFlipIndex; i++) {
      copy.parts.push(null);
    }

    for (let i = 0; i <= saveFlipIndex; i++) {
      copy.parts[copy.parts.length - 1 - i] = ArticleMultilayerPlanHelper.copyPart(model.parts[i]);
      copy.parts[copy.parts.length - 1 - i].drill_data = model.drill_data?.map(() => false);
    }

    return ArticleMultilayerPlanHelper.recalculate(copy);
  }

  private static calculatePrepregTotalThickness(model: ArticleMultilayerPlanModel): void {
    const mapper: Map<number, ArticleMultilayerParts[]> = new Map<number, ArticleMultilayerParts[]>();
    // Group the prepregs within their group
    (model.parts ?? [])
      .filter(p => ArticleMultilayerPartTypeHelper.getName(p.type) === 'prepreg')
      .forEach(p => {
        const a = mapper.get(p.prepreg.group) ?? [];
        a.push(p);
        mapper.set(p.prepreg.group, a);
      });

    // Ensure correct order of grouped prepreg parts
    model.thickness_prepreg_group = [...mapper.entries()]
      .sort((kv1, kv2) => kv1[0] - kv2[0])
      .map(kv => kv[1].sort((p1, p2) => p1.index - p2.index))
      .map(amp => {
        // Get first and last element from the list
        const firstAmp = amp.length > 0 ? amp[0] : null;
        const lastAmp = amp.length > 0 ? amp[amp.length - 1] : null;
        const prev = (!!firstAmp && firstAmp.index - 1 > 0) ? model.parts[firstAmp.index - 1] : null;
        const next = (!!lastAmp && lastAmp.index + 1 < model.parts.length) ? model.parts[lastAmp.index + 1] : null;
        let prepregSum = amp.reduce((sum, p) => sum + p.thickness, 0);

        // For the bottom layers
        const prevType = !prev ? null : ArticleMultilayerPartTypeHelper.getName(prev.type);
        if (prevType === 'cu_plating') {
          const prevCopperGroup = prev.cu_plating?.group === null ? null : model.copper_group[prev.cu_plating.group];
          if (prevCopperGroup?.direction === 1) {
            model.copper_group[prev.cu_plating.group].is_finishing = false;
            prepregSum -= Math.max((prevCopperGroup.thickness * (1 - prevCopperGroup.area_used / 100)), 0);
            return prepregSum;
          }
        } else if (prevType === 'cu_kasch') {
          prepregSum -= Math.max(prev.thickness * (1 - prev.cu_kasch.area_used / 100), 0);
        }

        // For the top layers
        const nextType = !next ? null : ArticleMultilayerPartTypeHelper.getName(next.type);
        if (nextType === 'cu_plating') {
          const nextCopperGroup = next.cu_plating?.group === null ? null : model.copper_group[next.cu_plating.group];
          if (nextCopperGroup?.direction === -1) {
            model.copper_group[next.cu_plating.group].is_finishing = false;
            prepregSum -= Math.max((nextCopperGroup.thickness * (1 - nextCopperGroup.area_used / 100)), 0);
            return prepregSum;
          }
        } else if (nextType === 'cu_kasch') {
          prepregSum -= Math.max(next.thickness * (1 - next.cu_kasch.area_used / 100), 0);
        }

        return prepregSum;
      }).map(p => Math.max(p, 0));
  }

  // Maps the thickness of all rows to a column/cycle
  private static calculateCycleTotalThickness(model: ArticleMultilayerPlanModel): ArticleMultilayerCycleThickness[] {
    const checkedPrepregGroups: number[] = [];
    const cycleStartIndices: (number | null)[] = [];
    const cycleLength: number[] = [];
    let cycleThickness = 0;
    const cycles = [...(new Set(model.parts.map(p => p.cycle)))]
      .sort((a, b) => a - b)
      .map(c => {
        cycleStartIndices.push(null);
        const rows: (ArticleMultilayerCycleRow | null)[] = [];
        model.parts.map((p, pi) => {
          if (p.cycle === c) {
            if (cycleStartIndices[cycleStartIndices.length - 1] === null) {
              cycleStartIndices[cycleStartIndices.length - 1] = pi;
              cycleLength.push((cycleLength.length > 0 ? cycleLength[cycleLength.length - 1] : 0) + 1);
            } else {
              cycleLength[cycleLength.length - 1]++;
            }
            if (ArticleMultilayerPartTypeHelper.getName(p.type) === 'prepreg') {
              if (checkedPrepregGroups.find(g => g === p.prepreg.group) === undefined) {
                checkedPrepregGroups.push(p.prepreg.group);
                const thickness = model.thickness_prepreg_group[p.prepreg.group];
                cycleThickness += thickness;
                rows.push({
                  thickness: thickness,
                  rows: model.parts.filter(fp => fp.prepreg?.group === p.prepreg.group).length,
                });
              } else {
                rows.push({thickness: 0, rows: 0});
              }
            } else {
              cycleThickness += p.thickness;
              rows.push({thickness: p.thickness, rows: 1});
            }
          } else {
            rows.push(null);
          }
        });
        return {cycle: c, thickness: cycleThickness, rows: rows};
      });

    for (let ic = cycles.length - 1; ic > 0; ic--) {
      cycles[ic].rows[cycleStartIndices[ic - 1]] = {thickness: cycles[ic - 1].thickness, rows: cycleLength[ic - 1]};
      for (let ir = cycleStartIndices[ic - 1] + 1; ir < cycleStartIndices[ic - 1] + cycleLength[ic - 1]; ir++) {
        cycles[ic].rows[ir] = {thickness: 0, rows: 0};
      }
    }
    return cycles;
  }

  private static recalculateDistances(model: ArticleMultilayerPlanModel): void {
    let thicknessTotal = 0;
    let thicknessFromTop = 0;
    let thicknessFromBottom = 0;
    let lastGroupFromTop: number | null = null;
    let lastGroupFromBottom: number | null = null;

    for (let i = 0; i < model.parts.length; i++) {
      if (ArticleMultilayerPartTypeHelper.getName(model.parts[i].type) === 'prepreg') {
        if (lastGroupFromTop !== model.parts[i].prepreg.group) {
          lastGroupFromTop = model.parts[i].prepreg.group;
          thicknessFromTop += model.thickness_prepreg_group[lastGroupFromTop];
          model.parts[i].thickness_from_top = 0;
          thicknessTotal += model.thickness_prepreg_group[model.parts[i].prepreg.group];
        }
      } else {
        thicknessFromTop += model.parts[i].thickness;
        model.parts[i].thickness_from_top = thicknessFromTop;
        thicknessTotal += model.parts[i].thickness;
      }

      if (ArticleMultilayerPartTypeHelper.getName(model.parts[model.parts.length - 1 - i].type) === 'prepreg') {
        if (lastGroupFromBottom !== model.parts[model.parts.length - 1 - i].prepreg.group) {
          lastGroupFromBottom = model.parts[model.parts.length - 1 - i].prepreg.group;
          thicknessFromBottom += model.thickness_prepreg_group[lastGroupFromBottom];
          model.parts[model.parts.length - 1 - i].thickness_from_bottom = 0;
        }
      } else {
        thicknessFromBottom += model.parts[model.parts.length - 1 - i].thickness;
        model.parts[model.parts.length - 1 - i].thickness_from_bottom = thicknessFromBottom;
      }
    }
    model.thickness_total = thicknessTotal;
  }

  public static calculateCycle(model: ArticleMultilayerPlanModel): ArticleMultilayerPlanModel {
    let upperCycle = 1;
    let upperHistory: string[] = [];
    let lowerCycle = 1;
    let lowerHistory: string[] = [];
    for (let i = 0; i < Math.ceil(model.parts.length / 2); i++) {
      const upperName = ArticleMultilayerPartTypeHelper.getName(model.parts[i].type);
      const lowerName = ArticleMultilayerPartTypeHelper.getName(model.parts[model.parts.length - i - 1].type);

      if (i === 0) {
        // Need to add first layer due to lacquer or plating on one side only
        model.cycles_has_finishing = upperName !== 'cu_foil' || lowerName !== 'cu_foil';
        upperCycle += (model.cycles_has_finishing && (upperName === 'cu_foil')) ? 1 : 0;
        lowerCycle += (model.cycles_has_finishing && (lowerName === 'cu_foil')) ? 1 : 0;
      }

      if (upperName === 'cu_foil') {
        if (
          (upperHistory.length > 0) &&
          ((upperHistory[0] === 'prepreg') || (upperCycle === 1)) &&
          ((upperHistory[upperHistory.length - 1] === 'cu_plating') || (upperCycle === 1))
        ) {
          upperCycle++;
        }
        upperHistory = [];
      } else {
        upperHistory.push(upperName);
      }

      if (lowerName === 'cu_foil') {
        if (
          (lowerHistory.length > 0) &&
          ((lowerHistory[0] === 'prepreg') || (lowerCycle === 1)) &&
          ((lowerHistory[lowerHistory.length - 1] === 'cu_plating') || (lowerCycle === 1))
        ) {
          lowerCycle++;
        }
        lowerHistory = [];
      } else {
        lowerHistory.push(lowerName);
      }

      model.parts[i].cycle = upperCycle;
      model.parts[model.parts.length - i - 1].cycle = lowerCycle;
    }

    const cyclesPlausible = lowerCycle === upperCycle;
    if (cyclesPlausible) {
      model.cycles_total = Math.max(lowerCycle, upperCycle);
    } else {
      model.cycles_total = 1;
      model.cycles_has_finishing = true;
    }

    for (let i = 0; i < model.parts.length; i++) {
      model.parts[i].cycle = cyclesPlausible ? model.cycles_total - model.parts[i].cycle + 1 : 1;
    }

    return model;
  }

  public static calculateLacquerThickness(model: ArticleMultilayerPlanModel): ArticleMultilayerPlanModel {
    // First filter out all plating, so that all relevant cu foil is adjacent to any lacquer it should change
    const noPlating = model.parts.filter(p => ArticleMultilayerPartTypeHelper.getName(p.type) !== 'cu_plating');
    const cuFoils = noPlating.filter(p => ArticleMultilayerPartTypeHelper.getName(p.type) === 'cu_foil');
    for (let i = 0; i < cuFoils.length; i++) {
      const lacquerThickness = ArticleMultilayerPlanHelper.mapLacquerThickness(cuFoils[i].thickness);
      const cuFoilIndex = noPlating.findIndex(p => p.index === cuFoils[i].index);
      const adjacent = noPlating.filter((_, ii) => ii === cuFoilIndex - 1 || ii === cuFoilIndex + 1);
      for (let j = 0; j < adjacent.length; j++) {
        if (ArticleMultilayerPartTypeHelper.getName(adjacent[j].type) !== 'lacquer') {
          continue;
        }

        model.parts[adjacent[j].index] = {...adjacent[j]} as ArticleMultilayerParts;
        if (adjacent[j].lacquer.custom && adjacent[j].lacquer.thickness_selected !== lacquerThickness) {
          model.parts[adjacent[j].index].lacquer = {
            thickness_selected: adjacent[j].lacquer.thickness_selected,
            thickness_proposed: lacquerThickness,
            custom: true,
          };
        } else {
          model.parts[adjacent[j].index].lacquer = {
            thickness_selected: lacquerThickness,
            thickness_proposed: null,
            custom: false,
          };
        }

        model.parts[adjacent[j].index].thickness = model.parts[adjacent[j].index].lacquer.thickness_selected;
      }
    }

    return model;
  }

  public static recalculate(model: ArticleMultilayerPlanModel): ArticleMultilayerPlanModel {
    let indexCopper = 1;
    let indexPrepreg = 0;
    model.copper_group = [];
    const drillDataFill = Array<boolean>(model.drill_data?.length ?? 0).fill(false);

    // Ensure all parts have an index and their non null parsed thickness
    for (let i = 0; i < model.parts.length; i++) {
      const partType = ArticleMultilayerPartTypeHelper.getName(model.parts[i].type);
      model.parts[i].thickness = model.parts[i][partType]?.thickness_selected ?? 0;
      model.parts[i].index = i;
    }

    for (let i = 0; i < model.parts.length; i++) {
      const part = model.parts[i];
      const partType = ArticleMultilayerPartTypeHelper.getName(part.type);

      // should only happen with newly inserted layers
      if (part.drill_data?.length !== drillDataFill?.length) {
        const dd = [...(part.drill_data ?? [])];
        part.drill_data = [...drillDataFill];
        for (let ddi = 0; ddi < dd.length && ddi < drillDataFill.length; ddi++) {
          part.drill_data[ddi] = dd[ddi];
        }
      }

      if (partType === 'cu_foil' || partType === 'cu_kasch') {
        part[partType].index_copper = indexCopper;
        indexCopper++;
      }

      const hasPrev = (i > 0);
      const hasNext = (i + 1 < model.parts.length);
      const prev = hasPrev ? model.parts[i - 1] : null;
      const next = hasNext ? model.parts[i + 1] : null;
      const prevType = hasPrev ? ArticleMultilayerPartTypeHelper.getName(prev.type) : null;
      const nextType = hasNext ? ArticleMultilayerPartTypeHelper.getName(next.type) : null;

      switch (partType) {
        case 'core':
          // Assign core index to cu kasch
          if (prevType === 'cu_kasch') {
            prev.cu_kasch.index_core = i;
          }

          if (nextType === 'cu_kasch') {
            next.cu_kasch.index_core = i;
          }

          if (((prev?.cu_kasch?.index_core ?? null) === null) || ((next?.cu_kasch?.index_core ?? null) === null)) {
            console.error(`could not find all cu_kasch for core ${i}`);
          }
          break;

        case 'prepreg':
          part.prepreg.group = indexPrepreg;
          if (nextType !== 'prepreg') {
            indexPrepreg++;
          }

          break;

        case 'cu_plating':
          const cg = model.copper_group ?? [];
          let cgLastIndex = cg.length - 1;
          if (!prevType || prevType !== 'cu_plating') {
            const prevIsCuFoil = prevType === 'cu_foil';
            if (prevIsCuFoil && cg.length > 0 && cg[cgLastIndex].index_copper === prev.index) {
              cg[cgLastIndex].rows--;
            }
            cg.push({
              index: cg.length,
              thickness: prevIsCuFoil ? prev.thickness : 0,
              rows: prevIsCuFoil ? 1 : 0,
              area_used: prevIsCuFoil ? prev.cu_foil.area_used : null,
              index_copper: prevIsCuFoil ? prev.index : null,
              direction: prevIsCuFoil ? 1 : 0,
              is_set: prevIsCuFoil,
              is_finishing: true,
            });
            cgLastIndex++;
          }

          // add thickness and increase row count
          cg[cgLastIndex].rows++;
          cg[cgLastIndex].thickness += part.thickness;
          // Add group index to part
          part.cu_plating.group = cgLastIndex;

          if (nextType === 'cu_foil' && !cg[cgLastIndex].is_set) {
            cg[cgLastIndex].thickness += next.thickness;
            cg[cgLastIndex].rows++;
            cg[cgLastIndex].area_used = next.cu_foil.area_used;
            cg[cgLastIndex].index_copper = next.index;
            cg[cgLastIndex].direction = -1;
            cg[cgLastIndex].is_set = true;
          }
          model.copper_group = cg;
          break;

        default:
          break;
      }
      model.parts[i] = part;
      if (hasPrev) {
        model.parts[i - 1] = prev;
      }
      if (hasNext) {
        model.parts[i + 1] = next;
      }
    }

    model.copper_layers = indexCopper - 1;

    ArticleMultilayerPlanHelper.calculateLacquerThickness(model);
    ArticleMultilayerPlanHelper.calculatePrepregTotalThickness(model);
    ArticleMultilayerPlanHelper.calculateCycle(model);
    model.thickness_cycle = ArticleMultilayerPlanHelper.calculateCycleTotalThickness(model);
    ArticleMultilayerPlanHelper.recalculateDistances(model);
    return model;
  }
}

