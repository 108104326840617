import {Component, Input} from '@angular/core';
import {ArticleDuplicate} from '../../models/article.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'article-duplicate-modal',
  templateUrl: './article-duplicate-modal.html',
})
export class ArticleDuplicateModalComponent {

  @Input() duplicates: ArticleDuplicate[];

  constructor(private modalRef: NgbActiveModal) {
  }

  close() {
    this.modalRef.dismiss();
  }

}
