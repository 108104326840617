<div class="row">
  <div class="col-sm-12">
    <button class="btn btn-warning btn-sm" [ngClass]="{active: status === 'active'}" (click)="setState('active')">
      Aktiv
      <ng-container *ngIf="businessOrderOpenCount.data$|async as count">
        <span class="badge">{{count|json}}</span>
      </ng-container>
    </button>
    <button class="btn btn-success btn-sm" [ngClass]="{active: status === 'finished'}" (click)="setState('finished')">
      Abgeschlossen
    </button>
    <button class="btn btn-default btn-sm" [ngClass]="{active: status === 'merged'}" (click)="setState('merged')">
      Zusammengefügt
    </button>
    <button class="btn btn-default btn-sm" [ngClass]="{active: status === 'wasted'}" (click)="setState('wasted')">
      Kompletter Ausschuss
    </button>
    <button class="btn btn-danger btn-sm" [ngClass]="{active: status === 'storno'}" (click)="setState('storno')">
      Storno
    </button>
  </div>
</div>
<div class="row">
  <div class="col-lg-12 whitelist">
    <div class="whitelist-item whitelist-item-margin whitelist-no-cursor ba-list"
         *ngFor="let listElement of list; let index = index">
      <business-order-article-list-element [article]="article"
                                           [businessOrder]="listElement.business_order"
                                           [businessOrderStatus]="businessOrderStatus"
                                           [fetches]="listElement.fetches"
                                           [empb]="empb"
                                           [kanban]="kanban"
                                           [workflowId]="workflowId"
                                           (reloadList)="reloadList()"
                                           (reproductionSelect)="reproductionSelect.emit($event)">
      </business-order-article-list-element>
    </div>
  </div>
</div>
