import {from, Observable} from 'rxjs';
import {ContractCreateOrderNrErrorModalComponent} from './contract-create-order-nr-error-modal.component';
import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Injectable({providedIn: 'root'})
export class ContractCreateOrderNrErrorModalService {
  constructor(private modalService: NgbModal) {
  }

  openIfInvalid(datesValid: boolean): Observable<boolean> {
    return (new Observable<boolean>(o => {
      if (datesValid) {
        o.next(true);
        o.complete();
      } else {
        const orderErrorModalRef = this.modalService.open(ContractCreateOrderNrErrorModalComponent);
        from(orderErrorModalRef.result).subscribe({
          next: () => o.next(true),
          error: () => o.error(false),
          complete: () => o.complete(),
        });
      }
    }));
  }
}
