import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MaterialInventoryEditModalService} from '../material-inventory-edit-modal.service';
import {MaterialStorage, MaterialVariation} from '../../material-management.model';
import {NumberHelper} from '../../../../helper/number-helper';

@Component({
  selector: 'tr[material-inventory-storage-row]',
  templateUrl: './material-inventory-storage-row.component.html'
})
export class MaterialInventoryStorageRowComponent implements OnInit, OnChanges {
  @Input('material-inventory-storage-row') storage: MaterialStorage;
  @Input('material-inventory-storage-row-has-expiration') hasExpiration: boolean;
  @Input('material-inventory-storage-row-variation') variation: MaterialVariation;
  @Output('material-inventory-storage-row-update') updateEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  konsiNow: boolean;
  isVendorPrice: boolean;
  priceSingle: number | null;
  priceTotal: number | null;

  constructor(private modalService: MaterialInventoryEditModalService) {
  }

  ngOnInit(): void {
    this.konsiNow = this.storage.konsi && this.storage.days_stored < 91;
    this.setPrice();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.storage && !changes.storage.isFirstChange()) {
      this.setPrice();
    }
    if (changes.variation && !changes.variation.isFirstChange()) {
      this.setPrice();
    }
  }

  changeAmountModal(storage: MaterialStorage, isKonsi?: boolean): void {
    this.modalService.changeAmountModalOpen(storage, isKonsi).then(reload => {
      this.updateEmitter.emit(reload);
    });
  }

  setPrice(): void {
    const price = NumberHelper.saveParseFloat(this.storage.price);
    if (price !== null) {
      this.isVendorPrice = false;
      this.priceSingle = price;
      this.priceTotal = price * this.storage.amount;
    } else if (!!this.variation.vendor_price || this.variation.vendor_price === 0) {
      this.isVendorPrice = true;
      this.priceSingle = this.variation.vendor_price;
      this.priceTotal = this.variation.vendor_price * this.storage.amount;
    } else {
      this.isVendorPrice = false;
      this.priceSingle = null;
      this.priceTotal = null;
    }
  }
}
