<form class="form-horizontal" [formGroup]="form" (ngSubmit)="save.emit()">
  <div class="modal-body">
    <div class="form-group">
      <label for="inputName" class="col-sm-2 control-label">
        Name
      </label>
      <div class="col-sm-10">
        <input type="text"
               class="form-control"
               id="inputName"
               placeholder="Name"
               formControlName="name"
               [ngClass]="{red_alert: errors['obj.text']}">
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="cancel.emit()">
      Abbrechen
    </button>
    <button type="submit" class="btn btn-success pull-right">
      Speichern
    </button>
  </div>
</form>
