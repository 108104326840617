import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder} from '@angular/forms';

@Component({
  selector: 'article-extra-storage',
  templateUrl: './article-extra-storage.component.html',
})
export class ArticleExtraStorageComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'storage';
  protected schemaName = 'storage';
  protected fields = [
    'storage_time_max',
    'storage_time_other',
    'storage_time_other_en',
  ];
}
