/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Ng2StateDeclaration} from '@uirouter/angular';
import {LabelService} from './label.service';
import {LabelCreateRouteComponent} from './label-create-route/label-create-route.component';
import {LabelSettingsComponent} from './label-settings/label-settings.component';

export const LABEL_STATE: Ng2StateDeclaration = {
  name: 'a.label',
  url: '/label',
  abstract: true,
  data: {requiresAuth: true}
};

export const LABEL_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.label.create',
  url: '/create',
  views: {'main@a': {component: LabelCreateRouteComponent}},
  data: {requiresAuth: true}
};

export function labelSettingsResolveFn(service: LabelService) {
  return service.listSettings().toPromise();
}

export const LABEL_SETTINGS_STATE: Ng2StateDeclaration = {
  name: 'a.label.settings',
  url: '/settings',
  views: {'main@a': {component: LabelSettingsComponent}},
  resolve: [
    {token: 'initialData', resolveFn: labelSettingsResolveFn, deps: [LabelService]}
  ],
  data: {requiresAuth: true}
};

export const LABEL_STATES: Ng2StateDeclaration[] = [
  LABEL_STATE,
  LABEL_CREATE_STATE,
  LABEL_SETTINGS_STATE
];
