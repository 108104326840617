import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusinessOrderStepView} from '../../models/business-order-step.models';
import {BusinessOrder, BusinessOrderReproduction} from '../../models/business-order.models';
import {Comment} from '../../../../comment/comment.models';
import {noop} from '../../../../../helper/noop';

@Component({
  selector: 'business-order-view-step-list',
  templateUrl: './business-order-view-step-list.component.html',
})
export class BusinessOrderViewStepListComponent implements OnInit {
  @Input() ba: BusinessOrder;
  @Input() steps: BusinessOrderStepView[];
  @Input() reproduction?: BusinessOrderReproduction;
  @Input() filmInventory?: number;
  @Output() updateGoodPiece = new EventEmitter<void>();
  @Output() updateData = new EventEmitter<void>();
  @Output() updateReproduction = new EventEmitter<BusinessOrderReproduction>();

  currentStepOrdering: number;

  constructor() {
  }

  ngOnInit() {
    this.currentStepOrdering = this.steps.find(s => s.state < 3)?.ordering ?? 0;
  }

  trackByFn(index: number, item: BusinessOrderStepView): number {
    noop(this, item);
    return item.ordering;
  }
}
