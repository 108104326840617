<div class="modal-header">
  <h3 class="modal-title">Schwesterartikel</h3>
</div>

<div class="modal-body">

  <a uiSref="a.article.workflow.overview" [uiParams]="{id:article.oa_nr}" target="_blank">
    <div class="row">

      <div class="col-md-2">0A-Nr.</div>
      <div class="col-md-2">{{ article.oa_nr }}</div>

    </div>

    <div class="row">

      <div class="col-md-2">SDS-Nr.</div>
      <div class="col-md-2">{{ article.sds_nr }}</div>

    </div>
  </a>

</div>
<div class="modal-footer">
</div>
