/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
export class Money {

  private static groupingSeperator = '.';
  private static decimalSeperator = ',';

  static toInt(value: number): number {
    return Math.round(value);
  }

  static parse(data: string | undefined, locale: string = 'de'): number | undefined {
    let n: number | undefined;
    if (data !== undefined) {
      if (locale === 'de' || locale === 'de_DE' || locale === 'de_de') {
        data = data.split(Money.groupingSeperator).join('').replace(Money.decimalSeperator, '.');
      } else {
        data = data.split(Money.decimalSeperator).join('');
      }
      const parsed = parseFloat(data);
      if (!Number.isNaN(parsed)) {
        n = parsed;
      }
    }
    return n;
  }

  private static rounder(x: number, round: number, grouping: string, decimal: string,
                         useThousandSeperator: boolean): string {

    let xs = String(x).toString();
    let afterPoint = '';
    if (xs.indexOf('.') > 0) {
      afterPoint = xs.substring(xs.indexOf('.'), xs.length);
    }
    x = Math.floor(x);
    xs = x.toString();
    let lastThree = xs.substring(xs.length - 3);
    const otherNumbers = xs.substring(0, xs.length - 3);
    if (otherNumbers !== '') {
      lastThree = ',' + lastThree;
    }
    const finalValue = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
    return finalValue.replace(',', '#').replace('.', decimal).replace('#', useThousandSeperator ? grouping : '');
  }

  static stringify(value: number, round?: number, locale: string = 'de', thousandSeperator: boolean = true): string {
    let ret: string;
    const rounding =  (round !== null && round !== undefined) ? round : 2;
    if (Intl !== undefined) {
      const options = {
        useGrouping: true,
        minimumFractionDigits: rounding,
        maximumFractionDigits: rounding
      };
      ret = Intl.NumberFormat(locale, options).format(value);
    } else if (locale === 'de' || locale === 'de_DE' || locale === 'de_de') {
      ret = Money.rounder(value, rounding, Money.groupingSeperator, Money.decimalSeperator, thousandSeperator);
    } else {
      ret = Money.rounder(value, rounding, Money.decimalSeperator, Money.groupingSeperator, thousandSeperator);
    }
    return ret;
  }

}
