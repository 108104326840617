export class StringHelper {

  public static isNull(s: string | null | undefined): boolean {
    return s === '' || s === null || s === undefined;
  }

  public static concat(s?: any[], delimiter?: string, filler?: string): string {
    if (!s || s.length <= 0) {
      return '';
    }
    if (filler) {
      s = s.map(c => c === null ? '-' : c);
    }
    delimiter = delimiter === null || delimiter === undefined ? ', ' : delimiter;
    return s.map(c => c.toString()).reduce(function (a, b) {
      return a + delimiter + b;
    });
  }
}
