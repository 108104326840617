/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Subscription} from 'rxjs';
import {StatefulModel, StreamModel} from './stream.model';
import Helper from '../../../helper/helper';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class StreamDataService {
  private streamSource = new Subject<StreamModel>();
  private streamStatefulSource = new BehaviorSubject<StatefulModel>({price: false, print_failed: false});
  private current?: StatefulModel = null;

  constructor() {
    console.log('UUID:', Helper.guid());
  }

  next(name: string, value: any): void {
    this.streamSource.next({name: name, data: value});
  }

  subscribe(filterString: string, next?: (value: any) => void): Subscription {
    return this.streamSource.pipe(
      filter((value: StreamModel) => value.name === filterString),
      map((value: StreamModel) => value.data)
    ).subscribe(next, errorResponse => {
      console.log('Stream Error:', errorResponse);
    });
  }

  nextPrice(priceValue: boolean): void {
    const newCurrent = this.methodCalculateCurrent();
    newCurrent.price = priceValue;
    this.current = newCurrent;
    this.streamStatefulSource.next(this.current);
  }

  nextPrintState(printStateValue: boolean): void {
    const newCurrent = this.methodCalculateCurrent();
    newCurrent.print_failed = printStateValue;
    this.current = newCurrent;
    this.streamStatefulSource.next(this.current);
  }

  subscribeStateful(next?: (value: StatefulModel) => void): Subscription {
    return this.streamStatefulSource.subscribe(next, errorResponse => {
      console.log('Stream Error:', errorResponse);
    });
  }

  private methodCalculateCurrent(): StatefulModel {
    if (this.current === null) {
      return {price: false, print_failed: false};
    } else {
      return this.current;
    }
  }

}
