<form class="form form-inline" [formGroup]="businessOrderForm" (ngSubmit)="submit()" *ngIf="!!businessOrderForm">
  <div class="row">

    <div class="col-lg-4">
      <div class="form-group">
        <label for="startDate">Start-Termin:</label>
        <ev-datepicker id="startDate" formControlName="start_date" class="form-control" [future]="false"></ev-datepicker>
      </div>

    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="endDate">End-Termin:</label>
        <ev-datepicker id="endDate" formControlName="end_date" class="form-control" [future]="true"></ev-datepicker>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="goldDate">Vergoldung bis:</label>
        <ev-datepicker id="goldDate" formControlName="gold_date" class="form-control" [future]="true"></ev-datepicker>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-lg-12"></div> <!-- Whitespace Row -->
  </div>

  <div class="row">

    <div class="col-lg-4">
      <div class="form-group">
        <label for="utilizeQuantity">Anzahl Nutzen:</label>
        <integer-input class="form-control" id="utilizeQuantity" formControlName="utilize_quantity"></integer-input>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="baAmount">Anzahl BAs:</label>
        <integer-input class="form-control" id="baAmount" formControlName="amount"></integer-input>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <label for="orderedQuantity">benötigte Menge:</label>
        <integer-input class="form-control" id="orderedQuantity" formControlName="ordered_quantity"></integer-input>
      </div>
    </div>
  </div>

  <div class="row">
    <label class="col-sm-4" for="reason">Nachproduktionsgrund:</label>
    <label class="col-sm-8" for="note">Bemerkung:</label>
  </div>

  <div class="row">
    <div class="col-sm-4">
        <select id="reason"
                class="form-control"
                formControlName="reason"
                [ngClass]="{red_alert: errors['reason']}">
          <option [ngValue]="null">-</option>
          <ng-container *ngIf="reproductionReasonList.data$|async as list; else loadingOrError.template">
          <option [ngValue]="t" *ngFor="let t of list">{{t.reason}}</option>
          </ng-container>
        </select>
      <loading-or-error #loadingOrError
                        [loadingWrapper]="reproductionReasonList"
                        [errorMessage]="'Konnte Artikelnamen nicht laden'">
      </loading-or-error>
    </div>

    <div class="col-sm-8">
      <textarea class="form-control" id="note" style="width: 100%;" formControlName="note" rows="3"
                [ngClass]="{red_alert: errors['note']}"></textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <div class="form-group" [ngClass]="{red_alert: errors['first_contract']}">
        <label for="firstContract">Erstauftrag:</label>
        <input type="checkbox" class="form-control" id="firstContract" formControlName="first_contract">
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group" [ngClass]="{red_alert: errors['express']}">
        <label for="express">Interner Eildienst:</label>
        <input type="checkbox" class="form-control" id="express" formControlName="express">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">&nbsp;</div> <!-- Whitespace Row -->
  </div>

  <div class="row">
    <div class="col-lg-12">

      <div class="label label-danger red_alert mid-label" *ngIf="!validHash">
        <strong>Achtung!</strong> Artikel wurde geändert. Fertigungsliste muss freigegeben werden.
      </div>

      <div class="label label-danger red_alert mid-label" *ngIf="plistEmpty">
        <strong>Achtung!</strong> Artikel wurde geändert. Fertigungsliste ist leer.
      </div>

      <business-order-create-multilayer-warning [errorMl]="errorMl" [invalidML]="invalidML">
      </business-order-create-multilayer-warning>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">&nbsp;</div> <!-- Whitespace Row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <article-warning [article]="article"></article-warning>
    </div>
  </div>

  <div class="row" style="margin-bottom: 20px;">
    <div class="col-sm-3">
      <strong>
        Gesamtmenge Platinen: {{ (businessOrderForm.value.utilize_quantity * amountPerUtilize)|money }}
      </strong>
    </div>
    <div class="col-md-3">
      <div class="label label-danger">
        Nachfertigung wird erstellt für BA: <strong>{{selected.id}}-{{selected.index}}</strong>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <button type="submit" class="btn btn-success"
              [disabled]="!businessOrderForm.valid || !validHash || !!article.date_deleted || !!article.locked || locked">
        Betriebsauftrag Nachfertiung erstellen
        <ng-container *ngIf="locked">
          &nbsp;<i class="fa fa-circle-o-notch fa-spin"></i>
        </ng-container>
      </button>
    </div>

    <div class="col-lg-6 align-right">
      <button type="button" class="btn btn-danger" (click)="closeForm.emit()">
        Abbrechen
      </button>
    </div>
  </div>

</form>
