<div class="modal-header">
  <div class="modal-title">
    <h3>Artikel entsperren</h3>
  </div>
</div>

<form class="form-horizontal" [formGroup]="unlockForm" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="form-group">
      <label class="col-md-2 control-label" for="reference">Referenz</label>

      <div class="col-md-10">
      <textarea id="reference" class="form-control" formControlName="reference" rows="10"
                [ngClass]="{red_alert: errors.reference}">
      </textarea>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-success pull-right" type="submit">
      Artikel entsperren
    </button>
  </div>
</form>
