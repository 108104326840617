import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Article, ArticleStream, ArticleStreamError, ArticleStreamValue} from '../../models/article.models';
import {HttpErrorResponse} from '@angular/common/http';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SessionStorage} from '../../../../common/storage/session-storage';
import {SaveModalForm} from './article-main-save-modal.models';
import {ArticleSimpleUpdateService} from '../../component-service/article-simple-update.service';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'article-main-save-modal',
  templateUrl: './article-main-save-modal.component.html'
})
export class ArticleMainSaveModalComponent implements OnInit {
  @Input() data: any;
  @Input() sdsNr: string;
  @Input() article: Article;
  @Input() notices: string[];
  @Input() subject: Subject<ArticleStream>;
  @Input() has_active_bas: boolean;
  errors: { [key: string]: any; } = {};
  buttonBlock = false;
  valid = false;

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private sessionStorage: SessionStorage,
              private activeModal: NgbActiveModal,
              private updateService: ArticleSimpleUpdateService) {
  }

  ngOnInit(): void {
    const defaultValue = this.sessionStorage.getObject<SaveModalForm>(
      'article-save-modal-' + this.article.oa_nr,
      {reason: 'Int. Änderung', reference: '', sister: !this.article.sister}
    );
    this.form = this.fb.group({
      'reason': [defaultValue.reason],
      'reference': [defaultValue.reference],
      'sister': [defaultValue.sister]
    });
    // locking is allowed if valid is marked
    this.valid = !this.has_active_bas;
  }

  makeValid(value: boolean): void {
    this.valid = value;
  }

  submit(): void {
    this.buttonBlock = true;
    this.errors = {};
    const formValue = this.form.value;
    const dataValue = {sds_nr: this.sdsNr, data: this.data, form: formValue};
    this.updateService.simpleCall(this.article, dataValue).subscribe(article => {
      this.sessionStorage.removeItem('article-save-modal-' + this.article.oa_nr);
      this.subject.next(new ArticleStreamValue(article));
      this.activeModal.close();
      this.buttonBlock = false;
    }, (response: HttpErrorResponse) => {
      if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
        if (response.error['obj.form.reference']) {
          this.alertService.add(
            'danger',
            'Artikel kann nicht gespeichert werden, da keine Referenz angegeben wurde!'
          );
        }
        this.errors = response.error;
      }
      this.sessionStorage.setObject('article-save-modal-' + this.article.oa_nr, formValue);
      this.subject.next(new ArticleStreamError(response));
      this.buttonBlock = false;
    });
  }

  close(): void {
    this.activeModal.dismiss();
  }

}
