import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
  MaterialOrderArticleListElement,
  MaterialOrderArticleListGroup,
  MaterialOrderArticleListIndexedElement,
  MaterialOrderArticleListIndexedGroupElement,
  MaterialOrderCreateOrderListElemForm,
} from '../material-order.model';
import {MaterialSupplier} from '../../../../lazy/material-supplier/material-supplier.model';
import {noop} from '../../../../helper/noop';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {MaterialValueType} from '../../material-management.model';
import {MaterialManagementService} from '../../material-management.service';

@Component({
  selector: 'material-order-create-group',
  templateUrl: './material-order-create-group.component.html'
})
export class MaterialOrderCreateGroupComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() currentSupplier: MaterialSupplier;
  @Input() markedVariationId: number;
  @Input() mig: MaterialOrderArticleListGroup;
  @Input() groupIndex: number;
  @Input() errors: { [key: string]: any } = {};
  @Output() openModal = new EventEmitter<number>();
  @Output() removeOrderEmitter = new EventEmitter<MaterialOrderArticleListIndexedGroupElement>();
  @Output() copyOrderEmitter = new EventEmitter<MaterialOrderArticleListIndexedGroupElement>();

  materialFieldData: LoadingWrapper<MaterialValueType[]>;

  constructor(private mms: MaterialManagementService) {
  }

  ngOnInit(): void {
    this.materialFieldData = new LoadingWrapper<MaterialValueType[]>(this.mms.listFormFieldClasses(this.mig.group.id));
  }

  removeOrder(article: MaterialOrderArticleListIndexedElement, mig: MaterialOrderArticleListGroup): void {
    this.removeOrderEmitter.emit({group: mig.group, delivery_index: article.delivery_index, article: article.article});
  }

  copyOrder(article: MaterialOrderArticleListElement, mig: MaterialOrderArticleListGroup): void {
    this.copyOrderEmitter.emit({
      group: mig.group,
      delivery_index: article.id * 10000 + mig.article_list.filter(a => a.id === article.id).length,
      article: article,
    });
  }

  openAddArticleModal(): void {
    this.openModal.emit(this.mig.group.id);
  }

  trackByArticleFn(index: number, item: MaterialOrderArticleListElement): number {
    noop(this);
    return item.id;
  }

  trackByIndexFn(index: number, item: MaterialOrderCreateOrderListElemForm): number {
    noop(this);
    return item.index * 100000 + item.variation_id * 100 + item.delivery_index;
  }
}
