import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ShortCustomer} from '../model/customer';
import {EnvisiaObject} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CustomerShortService {

  constructor(private http: HttpClient) {
  }

  short(uuid: string): Observable<ShortCustomer> {
    return this.http.get<EnvisiaObject<ShortCustomer>>('/crm/v1/short/' + uuid + '/').pipe(
      map(value => value.object)
    );
  }

}
