import {NgModule} from '@angular/core';
import {ArticleProductionModule} from './production/article-production.module';
import {ArticleListModule} from './list/article-list.module';
import {ArticleMainModule} from './main/article-main.module';
import {SharedModule} from '../../core/shared.module';
import {ArticleDeleteModalComponent} from './main/delete/article-delete.modal.component';
import {ArticleShippingInstructionModule} from './shipping-instruction/article-shipping-instruction.module';
import {ArticleDocumentModule} from './document/article-document.module';
import {ARTICLE_STATES} from './article.states';
import {UIRouterModule} from '@uirouter/angular';
import {ArticleRecoverModalComponent} from './main/recover/article-recover.modal.component';
import {ArticleShippingConfirmComponent} from './shipping-instruction/article-shipping-confirm.component';
import {ArticleWeightComponent} from './weight/article-weight.component';
import {InventoryTransferStockComponent} from './inventory/transfer-stock/inventory-transfer-stock.component';
import {InventoryModalWizardComponent} from './inventory/modal/inventory-modal-wizard.component';
import {InventoryModalModule} from './inventory/inventory-modal.module';
import {InventoryStorageNoteCreateComponent} from './inventory/storage-note/inventory-storage-note-create.component';
import {InventoryStorageNoteUpdateComponent} from './inventory/storage-note/inventory-storage-note-update.component';
import {InventoryStorageNoteFormComponent} from './inventory/storage-note/inventory-storage-note-form.component';
import {
  ArticleInventoryQuantityBoxComponent
} from './article-inventory-quantity-box/article-inventory-quantity-box.component';
import {LoadingOrErrorModule} from '../../common/loading/loading-or-error.module';

@NgModule({
  imports: [
    SharedModule,
    UIRouterModule.forChild({states: ARTICLE_STATES}),
    ArticleMainModule,
    ArticleListModule,
    ArticleProductionModule,
    ArticleDocumentModule,
    ArticleShippingInstructionModule,
    InventoryModalModule,
    LoadingOrErrorModule,
  ],
  declarations: [
    ArticleDeleteModalComponent,
    ArticleRecoverModalComponent,
    ArticleShippingConfirmComponent,
    ArticleInventoryQuantityBoxComponent,
    ArticleWeightComponent,
    InventoryTransferStockComponent,
    InventoryModalWizardComponent,
    InventoryStorageNoteCreateComponent,
    InventoryStorageNoteUpdateComponent,
    InventoryStorageNoteFormComponent,
  ],
  exports: [
    ArticleWeightComponent,
    ArticleInventoryQuantityBoxComponent
  ]
})
export class ArticleModule {
}
