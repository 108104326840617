import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MaterialVariation} from '../../material-management.model';
import {MaterialInventoryListService} from '../material-inventory-list.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'material-variation-edit-modal',
  templateUrl: './material-variation-edit-modal.component.html',
})
export class MaterialVariationEditModalComponent implements OnInit {
  @Input() variation: MaterialVariation;

  form: UntypedFormGroup;
  errors: {[key: string]: any} = {};

  constructor(private modalRef: NgbActiveModal,
              private inventoryListService: MaterialInventoryListService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      vendor_price: [Number(this.variation.vendor_price).toLocaleString(), Validators.required],
      min_stock: [this.variation.min_stock, Validators.required],
      lead_time: [this.variation.lead_time, Validators.required],
      min_order: [this.variation.min_order, Validators.required],
      favorite: [this.variation.favorite, Validators.required],
    });
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

  submit(): void {
    this.errors = {};
    this.inventoryListService.updateVariation(this.variation.id, this.form.value).subscribe(() => {
      this.alertService.add('success', 'Änderungen erfolgreich gespeichert');
      this.modalRef.close();
    }, (httpError: HttpErrorResponse) => {
      if (httpError.status === 400) {
        this.errors = httpError.error.errors;
      }
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Eingabe!');
    });
  }
}
