import * as React from 'react';

interface Props {
  errorType: { [key: string]: string[] };
}

export function ArticleInventoryTransferErrorLabel(props: Props) {
  const workflowNotFound = !props.errorType['workflow_id_target']?.find(e => e === 'not.found.workflow.id.target');
  const workflowSame = !props.errorType['workflow_id_target']?.find(e => e === 'same.workflow.id');
  return <>
    {
      !props.errorType['quantity'] ? null :
        <div className='row mb-10'>
          <div className='col-sm-12'>
            <span className='label label-danger red_alert' style={{fontSize: '12px'}}>
              Die Menge im aktuellen Lager ist kleiner als die angegebene Menge.
            </span>
          </div>
        </div>
    }
    {
      !props.errorType['note'] ? null :
        <div className='row mb-10'>
          <div className='col-sm-12'>
            <span className='label label-danger red_alert' style={{fontSize: '12px'}}>
              Bitte geben Sie einen aussagekräftigen Grund für die Umbuchung an.
            </span>
          </div>
        </div>
    }
    {
      workflowNotFound ? null :
        <div className='row mb-10'>
          <div className='col-sm-12'>
            <span className='label label-danger red_alert' style={{fontSize: '12px'}}>
              Der Vorgang mit der angegebenen Nummer konnte nicht gefunden werden.
            </span>
          </div>
        </div>
    }
    {
       workflowSame ? null :
        <div className='row mb-10'>
          <div className='col-sm-12'>
            <span className='label label-danger red_alert' style={{fontSize: '12px'}}>
              Aktueller Vorgang und Ziel Vorgang sind identisch.
            </span>
          </div>
        </div>
    }
  </>;
}
