import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {ArticleWeightModel} from '../models/article-weight.model';
import {ArticleWeightService} from '../services/article-weight.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'article-weight',
  templateUrl: './article-weight.component.html',
  styleUrls: ['./article-weight.component.scss']
})
export class ArticleWeightComponent implements OnInit, OnChanges, OnDestroy {
  @Input() oaNr: string;
  @Input() amount?: number;

  weightLoader: LoadingWrapper<ArticleWeightModel>;
  triggerSubject = new Subject<void>();
  subscription: Subscription;

  constructor(private weightService: ArticleWeightService) { }

  ngOnInit(): void {
    this.load();

    this.subscription = this.triggerSubject.pipe(debounceTime(750)).subscribe(() => {
      this.load();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.oaNr?.isFirstChange() && !changes.amount?.isFirstChange()) {
      this.triggerSubject.next();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  load(): void {
    if (this.oaNr) {
      this.amount = this.amount ?? 1;
      this.weightLoader = new LoadingWrapper<ArticleWeightModel>(this.weightService.getWeight(this.oaNr, this.amount));
    }
  }

}
