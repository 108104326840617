import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleSdsModalComponent} from '../main/sds-modal/article-sds-modal.component';
import {Observable, from} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ArticleSdsService {

  constructor(private ngbModal: NgbModal) {
  }

  generateSds(article: string): Observable<any> {
    const modalRef = this.ngbModal.open(ArticleSdsModalComponent);
    modalRef.componentInstance.create = false;
    modalRef.componentInstance.oaNr = article;
    return from<any>(modalRef.result);
  }

}
