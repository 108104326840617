import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DunningLockService} from './dunning-lock.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'dunning-lock-modal',
  templateUrl: './dunning-lock-modal.component.html',
})
export class DunningLockModalComponent implements OnInit {
  @Input() invoiceId: number;
  @Input() isLocked: boolean;
  form: UntypedFormGroup;
  formLocked = false;
  errors: {[key: string]: any} = {};

  constructor(private service: DunningLockService,
              private alertService: AlertService,
              private modalService: NgbActiveModal,
              private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      locked: [!this.isLocked, {emitEvent: false}],
      note: [null],
    });
  }

  submit(): void {
    this.formLocked = true;
    this.errors = {};
    this.service.toggleLock(this.invoiceId, this.form.value).subscribe(locked => {
      this.modalService.close(this.isLocked === locked ? null : locked);
      this.alertService.add('success', this.form.value.locked ? 'Rechnung gesperrt' : 'Rechnung entsperrt');
    }, (error: HttpErrorResponse) => {
      this.formLocked = false;
      if (error.status >= 400 && error.status < 500) {
        this.errors = error.error.errors;
      }
      this.alertService.add('danger', 'Konnte die Rechnung nicht sperren');
    });
  }
}
