/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-bad-part',
  templateUrl: './article-extra-bad-part.component.html'
})
export class ArticleExtraBadPartComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'bad_parts';
  protected schemaName = 'bad-parts';
  protected fields = [
    'null_error_utilize',
    'null_error_utilize_other',
    'utilize',
    'batch',
    'utilize_batch',
    'bad_mark',
    'bad_mark_other',
    'stift',
    'stift_other',
    'other',
  ];
}
