import {Component, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {MaterialPlanningService} from './material-planning.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';

@Component({
  selector: 'material-planning-force-check-button',
  templateUrl: './material-planning-force-check-button.component.html',
})
export class MaterialPlanningForceCheckButtonComponent implements OnInit, OnDestroy {
  @Output() reloaded: EventEmitter<void> = new EventEmitter<void>();

  private tic = interval(30000);
  subscription: Subscription;
  locked = false;

  constructor(private service: MaterialPlanningService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.poll();
    this.subscription = this.tic.subscribe(() => {
      this.poll();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  start(): void {
    this.service.generateJob().subscribe(openArticleTasks => {
      if (openArticleTasks.articles === 0 && openArticleTasks.material === 0) {
        this.alertService.add('danger', 'Keine geänderten Artikel vorhanden');
      } else if (openArticleTasks.running) {
        this.locked = true;
      } else if (openArticleTasks.failure) {
        this.alertService.add('danger', 'Konnte Job nicht starten');
      }
    }, ignoreRejection);
  }

  poll(): void {
    this.service.pollJob().subscribe(openArticleTasks => {
      if (this.locked && openArticleTasks.failure) {
        this.alertService.add('danger', 'Artikel konnten nicht zugeordnet werden');
      } else if (this.locked && openArticleTasks.finished) {
        this.alertService.add('success', 'Material Artikel wurden den Artikeln zugeordnet');
        this.reloaded.emit();
      }

      this.locked = openArticleTasks.running;
    }, ignoreRejection);
  }
}
