import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {ContractListComponent} from './contract-list.component';
import {WorkflowNavigationModule} from '../navigation/workflow-navigation.module';
import {UIRouterModule} from '@uirouter/angular';
import {CONTRACT_STATES} from './contract.states';
import {ContractCreateComponent} from './create/contract-create.component';
import {NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ContractCreateListComponent} from './create/contract-create-list.component';
import {HistoryModule} from '../../history/history.module';
import {ContractCreateAdditionalComponent} from './create/contract-create-additional.component';
import {AddressBoxModule} from '../../customer/address-box/address-box.module';
import {ArticleWarningModule} from '../../article/warning/article-warning.module';
import {ContractCreateFormComponent} from './create/contract-create-form.component';
import {ContractCreateFetchAddModalComponent} from './modals/contract-create-fetch-add-modal.component';
import {ContractCreateFetchExtraAddModalComponent} from './modals/contract-create-fetch-extra-add-modal.component';
import {ContractCreateErrorModalComponent} from './modals/contract-create-error-modal.component';
import {ContractCreateFrameAddModalComponent} from './modals/contract-create-frame-add-modal.component';
import {ContractCreateDeliveryDateErrorModalComponent} from './modals/contract-create-delivery-date-error-modal.component';
import {ContractOrderBoxModule} from './order-box/contract-order-box.module';
import {ContractHistoryModalComponent} from './modals/contract-history-modal.component';
import {ContractDetailComponent} from './detail/contract-detail.component';
import {CommentModule} from '../../comment/comment.module';
import {FetchBoxModule} from '../fetch/box/fetch-box.module';
import {WorkflowBoxesModule} from '../boxes/workflow-boxes.module';
import {ArticleBoxModule} from '../../article/box/article-box.module';
import {CustomerBoxModule} from '../../customer/box/customer-box.module';
import {ContactBoxModule} from '../../customer/contact-box/contact-box.module';
import {ContractCreateExistingModalComponent} from './create/contract-create-existing-modal/contract-create-existing-modal.component';
import {WorkflowOrderRequestMainModule} from '../order-request/main/workflow-order-request-main.module';
import {ContractCreateOrderNrErrorModalComponent} from './modals/contract-create-order-nr-error-modal.component';
import {ContractCreateFormItemComponent} from './create/contract-create-form-item/contract-create-form-item.component';
import {ContractCreateFormItemPositionComponent} from './create/contract-create-form-item-position/contract-create-form-item-position.component';
import {ContractCreateNavigationComponent} from './create/contract-create-navigation/contract-create-navigation.component';
import {WorkflowStatusLabelModule} from '../status-label/workflow-status-label.module';
import {MaterialManagementModule} from '../../material/material-management.module';
import {CustomerModule} from '../../customer/customer.module';
import {ContractListElementComponent} from './contract-list/contract-list-element.component';
import {ContractListElementSingleRowComponent} from './contract-list/contract-list-element-single-row.component';
import {ContractListElementMainComponent} from './contract-list/contract-list-element-main.component';
import {ContractListElementFetchComponent} from './contract-list/contract-list-element-fetch.component';

@NgModule({
    imports: [
        UIRouterModule.forChild({states: CONTRACT_STATES}),
        SharedModule,
        NgbModalModule,
        NgbTooltipModule,
        WorkflowNavigationModule,
        HistoryModule,
        AddressBoxModule,
        ArticleWarningModule,
        ContractOrderBoxModule,
        CommentModule,
        FetchBoxModule,
        WorkflowBoxesModule,
        ArticleBoxModule,
        CustomerBoxModule,
        ContactBoxModule,
        WorkflowOrderRequestMainModule,
        WorkflowStatusLabelModule,
        MaterialManagementModule,
        CustomerModule,
    ],
    declarations: [
        ContractListComponent,
        ContractCreateComponent,
        ContractCreateListComponent,
        ContractCreateAdditionalComponent,
        ContractCreateFormComponent,
        ContractDetailComponent,
        // Modals
        ContractCreateFetchAddModalComponent,
        ContractCreateFetchExtraAddModalComponent,
        ContractCreateErrorModalComponent,
        ContractCreateFrameAddModalComponent,
        ContractCreateDeliveryDateErrorModalComponent,
        ContractHistoryModalComponent,
        ContractCreateExistingModalComponent,
        ContractCreateOrderNrErrorModalComponent,
        ContractCreateFormItemComponent,
        ContractCreateFormItemPositionComponent,
        ContractCreateNavigationComponent,
        ContractListElementComponent,
        ContractListElementSingleRowComponent,
        ContractListElementMainComponent,
        ContractListElementFetchComponent,
    ]
})
export class ContractModule {
}
