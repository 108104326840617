import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {InventoryListComponent} from './inventory-list.component';
import {UIRouterModule} from '@uirouter/angular';
import {INVENTORY_STATES} from './inventory.states';
import {InventoryModalModule} from './inventory-modal.module';

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({ states: INVENTORY_STATES }),
        InventoryModalModule,
    ],
    declarations: [
        InventoryListComponent
    ]
})
export class InventoryModule {
}
