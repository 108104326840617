import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'position-extra-error-modal',
  templateUrl: './position-extra-error-modal.component.html'
})
export class PositionExtraErrorModalComponent {

  constructor(private activeModal: NgbActiveModal) {
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

}
