import {Component, Input} from '@angular/core';
import {FetchPositionClean} from './fetch-box.model';

@Component({
  selector: 'tr[fetch-box-position]',
  templateUrl: './fetch-box-position.component.html',
})
export class FetchBoxPositionComponent {
  @Input('fetch-box-position') pos: FetchPositionClean;
  @Input('fetch-box-position-has-price-increase') hasPriceIncrease: boolean;

  constructor() {
  }
}
