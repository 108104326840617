import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ArticleProduction, ArticleProductionStepView} from './production.models';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {Observable} from 'rxjs';
import {Article} from '../models/article.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProductionArticleService {

  private uri = '/api/v1/article/production/'; // /api/v1/article/production/:article/:param1/:id/

  constructor(private http: HttpClient) {
  }

  list(oaNr: string): Observable<{article: Article, objects: ArticleProductionStepView[]}> {
    return this.http.get<{article: Article, objects: ArticleProductionStepView[]}>(this.uri + oaNr + '/');
  }

  create(oaNr: string, id: number): Observable<ArticleProductionStepView> {
    return this.http.post<EnvisiaObject<ArticleProductionStepView>>(this.uri + oaNr + '/' + id + '/', {}).pipe(
      map(value => value.object)
    );
  }

  remove(oaNr: string, ordering: number): Observable<void> {
    return this.http.delete<void>(this.uri + oaNr + '/' + ordering + '/');
  }

  up(oaNr: string, ordering: number): Observable<void> {
    return this.http.put<void>(this.uri + oaNr + '/up/' + ordering + '/', {});
  }

  down(oaNr: string, ordering: number): Observable<void> {
    return this.http.put<void>(this.uri + oaNr + '/down/' + ordering + '/', {});
  }

  edit(oaNr: string, ordering: number, data: {infoText: string, lockText: string}): Observable<ArticleProductionStepView> {
    return this.http.put<EnvisiaObject<ArticleProductionStepView>>(this.uri + oaNr + '/' + ordering + '/', data).pipe(
      map(value => value.object)
    );
  }

  addAll(oaNr: string, returnForm: any): Observable<ArticleProductionStepView[]> {
    return this.http.put<EnvisiaObjects<ArticleProductionStepView>>(this.uri + oaNr + '/all/', returnForm).pipe(
      map(value => value.objects)
    );
  }

  getHash(oaNr: string): Observable<ArticleProduction> {
    return this.http.get<EnvisiaObject<ArticleProduction>>(this.uri + oaNr + '/hash/').pipe(
      map(value => value.object)
    );
  }

  checkHash(oaNr: string): Observable<boolean> {
    return this.http.get<EnvisiaObject<boolean>>(this.uri + oaNr + '/checkhash/').pipe(
      map(value => value.object)
    );
  }

  setHash(oaNr: string) {
    return this.http.put<EnvisiaObject<ArticleProduction>>(this.uri + oaNr + '/hash/', {}).pipe(
      map(value => value.object)
    );
  }

  releaseProductionList(oaNr: string) {
    return this.http.post<EnvisiaObject<Article>>(this.uri + oaNr + '/production/release/', {}).pipe(

    );
  }

}
