<div class="modal-header">
  <h3 class="modal-title">Achtung!</h3>
</div>

<div class="modal-body">
  <p>Möchten Sie wirklich den Status ändern?</p>
  <div class="status-change">
    <table>
      <tbody>
      <tr>
        <td><button type="button" class="btn btn-info" (click)="save('order')">Auftrag</button></td>
        <td><button type="button" class="btn btn-primary" (click)="save('approved')">Bestätigt</button></td>
        <td><button type="button" class="btn btn-warning" (click)="save('delivered')">Geliefert</button></td>
        <td><button type="button" class="btn btn-success" (click)="save('cleared')">Abgerechnet</button></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="pull-left btn btn-default" (click)="closeModal()">
    Abbrechen
  </button>
</div>
