<div class="modal-header">
  <h3 class="modal-title">Artikel hinzufügen</h3>
</div>

<form (ngSubmit)="submit()" [formGroup]="form" class="form-horizontal">
  <div class="modal-body">

    <div class="row task">
      <div class="col-md-4">
        <div class="form-group">
          <label class="col-sm-5 control-label" for="id_quanity">Menge</label>

          <div class="col-sm-7">
            <input [autofocus]="true" class="form-control inputtextright autofocus" formControlName="quanity"
                   id="id_quanity"
                   type="number">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-5 control-label" for="id_lose">Lose</label>

          <div class="col-sm-7">
            <input class="form-control inputtextright" formControlName="lose" id="id_lose" type="number">
          </div>
        </div>
      </div>


      <div class="col-md-8">
        <ng-container *ngIf="processesData.data$ | async as processes; else loadingOrError.template">
          <div class="form-group">
            <label class="col-sm-4 control-label" for="id_germany">Kalkulation DE:</label>

            <div class="col-sm-8">
              <price-selector name="calc_de" [form]="form" [processes]="processes.de" id="id_germany">
              </price-selector>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-4 control-label" for="id_china">Kalkulation CHN:</label>

            <div class="col-sm-8">
              <price-selector name="calc_cn" [form]="form" [processes]="processes.cn" id="id_china">
              </price-selector>
            </div>
          </div>
        </ng-container>

        <loading-or-error
          #loadingOrError
          [errorMessage]="'Konnte Artikel Prozesse nicht laden'"
          [loadingWrapper]="processesData">
        </loading-or-error>
      </div>

    </div>

  </div>
  <div class="modal-footer">

    <button [disabled]="send" class="btn btn-success pull-left" type="submit">
      Artikel hinzufügen
    </button>
  </div>
</form>
