import {AxiosHttpClient} from '../../auth/axios-http-client';
import {environment} from '../../../../environments/environment';
import {MaterialLabelPrintReply} from './material-label-print.models';

export class MaterialLabelPrintAxiosService {
  static print(variationId: number, quantity: number): Promise<MaterialLabelPrintReply> {
    return AxiosHttpClient.post<MaterialLabelPrintReply>(
      `${environment.apiv4uri}material/label/print/${variationId}/`,
      {'quantity': quantity},
    );
  }
}
