import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Customer, CustomerList} from '../model/customer';
import {Observable} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Address} from '../model/address';
import {AlertService} from '../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CustomerService} from '../services/customer.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'customer-select-modal',
  templateUrl: './customer-select-modal.component.html'
})
export class CustomerSelectModalComponent implements OnInit, OnDestroy {
  @Input() customers: CustomerList[];
  @Input() onSave: (customer: Customer) => Observable<any>;
  @Input() currentName?: string;
  selected?: string;
  customer?: Customer;
  form: UntypedFormGroup;
  private subscription?: Subscription;
  private httpSubscription?: Subscription;

  constructor(private fb: UntypedFormBuilder,
              private modalRef: NgbActiveModal,
              private customerService: CustomerService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.selected = this.currentName;
    this.form = this.fb.group({
      name: [this.currentName ? this.currentName : null]
    });
    this.subscription = this.form.valueChanges.pipe(debounceTime(350)).subscribe(data => {
      if (this.httpSubscription) {
        this.httpSubscription.unsubscribe();
      }
      this.httpSubscription = this.customerService.list({name: data.name}).subscribe((newData) => {
        this.httpSubscription = undefined;
        this.customers = newData.objects;
      }, () => {
      });
    }, () => {
    });
  }

  ngOnDestroy(): void {
    if (this.httpSubscription) {
      this.httpSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  select(customer?: CustomerList): void {
    if (customer) {
      this.customer = this.listToCustomer(customer);
      this.selected = customer.name;
    } else {
      this.customer = undefined;
      this.selected = undefined;
    }
  }

  ok(): void {
    if (this.selected === this.currentName) {
      this.cancel();
    } else {
      this.onSave(this.customer).subscribe(() => {
        this.modalRef.close(this.customer);
      }, () => {
        this.alertService.add('danger', 'Kunde konnte nicht gespeichert werden!');
      });
    }
  }

  cancel(): void {
    this.modalRef.dismiss();
  }

  private listToCustomer(obj: CustomerList): Customer {
    const address: Address = {
      id: obj.address_id.toString(),
      name: '',
      street: obj.street,
      zip: obj.zip,
      city: obj.city,
      country: obj.country
    };

    return {
      id: obj.id,
      invoice: address,
      delivery_id: '',
      name: obj.name,
      username: '',
      rating: obj.rating,
      assured: false,
      note: obj.note,
      sales_note: obj.sales_note,
      dunning_lock: false,
      special: false,
      preference: false,
      producers: [],
      cn_ul: false,
      show_producer: false,
      show_producer_offer: false,
      surplus_disposal: false,
      surplus_disposal_limit: null,
      show_contract_overview: false,
      allow_special_production: false,
      industrial_sectors: obj.industrial_sectors,
      show_ul_types: false,
      delivery_terms: null,
    };
  }

}
