<div class="article-form-item">
  <label class="article-form-item-label">{{ label }}:</label>
  <div class="article-form-item-data">
    <div class="article-form-data-content" *ngIf="!bool">{{ data }}</div>


    <ng-container *ngIf="!nullable && bool">
      <div class="article-form-data-content" *ngIf="data"><i class="fa fa-check"></i></div>
      <div class="article-form-data-content" *ngIf="!data"><i class="fa fa-times"></i></div>
    </ng-container>
    <!-- de conform can be nullable which is true -->
    <ng-container *ngIf="nullable && bool">
      <div class="article-form-data-content" *ngIf="data === true || data === null || data === undefined">
        <i class="fa fa-check"></i>
      </div>
      <div class="article-form-data-content" *ngIf="data === false"><i class="fa fa-times"></i></div>
    </ng-container>

    <div class="article-form-data-unit" *ngIf="unit">
      {{ unit }}
    </div>
  </div>
</div>
