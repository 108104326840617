import {OfferAnalysisGroup, OfferAnalysisMap, OfferAnalysisStateTriplet} from './offer-analysis.model';

export class OfferAnalysisMapHelper {
  static generateMap(list: OfferAnalysisGroup[]): OfferAnalysisMap {
    const totalOffers = list?.find(o => o.group_name === 'total')?.rows?.find(o => o.name === 'total')?.total ?? 0;
    const total = Math.max(0, totalOffers) / 100;
    return list.reduce((accum, e) => {
      accum[e.group_name] =
        e.rows.map(r => ({
          name: r.name,
          label: r.label,
          id: r.id,
          ordering: r.ordering,
          total: {numeric: r.total, percentual: (total === 0 ? 0 : (r.total / total))},
          existing: {numeric: r.existing, percentual: (total === 0 ? 0 : (r.existing / total))},
          novel: {numeric: r.total - r.existing, percentual: (total === 0 ? 0 : ((r.total - r.existing) / total))},
        }) as OfferAnalysisStateTriplet);
      return accum;
    }, {} as OfferAnalysisMap);
  }
}
