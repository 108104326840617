import {Injectable} from '@angular/core';
import {ContractService} from '../../workflow-services/contract.service';
import {ContractForm} from '../../models/contract.models';
import {HttpErrorResponse} from '@angular/common/http';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {from} from 'rxjs';
import {ContractCreateErrorModalComponent} from '../modals/contract-create-error-modal.component';
import {EnvisiaFileService} from '../../../../common/envisia-file-button/envisia-file.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {StateService} from '@uirouter/angular';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class ContractCreateService {

  constructor(private envisiaFileService: EnvisiaFileService,
              private modalService: NgbModal,
              private stateService: StateService,
              private alertService: AlertService,
              private contractService: ContractService) {
  }

  create(id: number,
         customerId: number,
         force: boolean,
         form: ContractForm,
         deliveryErrorHook: (deliveryDateErrors: { fetch: number, at: number, express: boolean }[]) => void,
         successHook?: () => void,
         errorHook?: () => void): void {
    this.contractService.create(id, force, form).subscribe(response => {
      this.envisiaFileService.open(response.id, 'contract');
      this.alertService.add('success', 'Auftr.-Best. erfolgreich erstellt!');
      if (successHook) {
        successHook();
      }
      this.stateService.go('a.workflow.detail', {id: id});
    }, (response: HttpErrorResponse) => {
      const type = response.error ? response.error.type : '';
      let text;
      let deliveryDateErrors;
      if (type === 'ust') {
        text = `
          Dokument konnte nicht erstellt werden. Bitte prüfen Sie ob beim Kunden eine USt.-Id hinterlegt ist.
          <a ng-href="#!/crm/${customerId}" target="_blank">Zur Kundenansicht</a>
        `;
      } else if (type === 'fetch') {
        deliveryDateErrors = response.error['errors'];
      } else {
        text = 'Unbekannter Fehler beim Auftr. Best. erstellen!';
      }
      if (deliveryDateErrors) {
        if (deliveryErrorHook) {
          deliveryErrorHook(deliveryDateErrors);
        }
      } else if (text) {
        const modalRef = this.modalService.open(ContractCreateErrorModalComponent, {windowClass: 'modal2-smm'});
        modalRef.componentInstance.text = text;
        from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
      } else {
        this.alertService.add('danger', 'Auftr.-Best. konnte nicht erstellt werden!');
      }

      if (errorHook) {
        errorHook();
      }
    });
  }

}
