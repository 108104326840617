import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {OrderHistory} from '../order-request.models';
import {StorageBin} from '../../../article/inventory/inventory.models';
import {deepCopy} from '../../../../helper/deep-copy';
import {noop} from '../../../../helper/noop';

@Component({
  selector: 'new-order-box',
  templateUrl: './order-box.component.html'
})
export class OrderBoxComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() orderId: number;
  @Input() orderIndex: number;
  @Input() index: number;
  @Input() history: { [key: string]: OrderHistory[] };
  @Input() storage: StorageBin[];
  @Input() errors: { [key: string]: any; } = {};
  @Input() articleLocked: boolean;
  @Input() resetStatusAvailable: boolean;
  @Output() resetStatus = new EventEmitter<number>();
  multibox: any[];
  form: any;
  disableInspection: boolean;

  ngOnInit(): void {
    this.data.index = this.data.index ?? this.index;
    this.checkInspection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.form = deepCopy(changes.data.currentValue);
      if (this.data.received && this.data.received.data) {
        this.multibox = new Array(this.data.received.data.length);
      } else {
        this.multibox = new Array(1);
      }
      this.checkInspection();
    }
  }

  checkInspection(): void {
    this.disableInspection = !this.form.conveyed?.date || !this.form.conveyed?.quantity;
  }

  dateCodeInfo(index: number): string {
    if (this.data.received && this.data.received.data && this.data.received.data[index]) {
      return this.data.received.data[index].date_code;
    }
    return '';
  }

  // -- multibox

  raise() {
    if (this.data.received && this.data.received.data) {
      this.data.received.data.push({storage_bin_name: null});
    } else if (this.data.received) {
      // form/data needs to have a single element, else the push will do nothing
      this.data.received.data = [{}];
      this.data.received.data.push({storage_bin_name: null});
    }
    if (this.data.expensed && this.data.expensed.quantities) {
      this.data.expensed.quantities.push(null);
    } else if (this.data.received) {
      // form/data needs to have a single element, else the push will do nothing
      this.data.expensed.quantities = [null];
      this.data.expensed.quantities.push(null);
    }
    this.multibox.push(undefined);
  }

  remove(index: number): void {
    if (index !== 0 && confirm(`Lieferung ${index + 1} wirklich entfernen?`)) {
      this.multibox.splice(index, 1);
      if (this.data.received && this.data.received.data) {
        this.data.received.data.splice(index, 1);
      }
      if (this.data.expensed && this.data.expensed.quantities) {
        this.data.expensed.quantities.splice(index, 1);
      }
    }
  }

  resetState(): void {
    if (this.data.id) {
      this.resetStatus.emit(this.data.id);
    }
  }

  trackById(index: number, obj: any): number {
    noop(this, obj);
    return index;
  }

}
