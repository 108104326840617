import * as React from 'react';
import {OfferAnalysisStateTriplet} from './offer-analysis.model';
import {EvFormattedNumber} from '../../../../react/components/ev-formatted-number';
import {clsx} from 'clsx';

interface Props {
  parameterName: string;
  element: OfferAnalysisStateTriplet;
  queryActive: boolean;
  onSelect: () => void;
}

export function OfferAnalysisElement(props: Props): JSX.Element {
  return <tr className={clsx({'selected': props.queryActive})}>
    <td className="w-1/3">
      <span className="click" onClick={() => props.onSelect()}>{props.element.label}</span>
    </td>
    <td className="align-right w-1/5 pr-2">
      <EvFormattedNumber value={props.element.total.numeric}/>
    </td>
    <td className="align-right w-0 pl-0 pr-0">
      /
    </td>
    <td className="align-right w-2 pl-2">
      <EvFormattedNumber value={props.element.total.percentual} decimals={2} unit="%"/>
    </td>
    <td className="align-right w-1/5 pr-2">
      <EvFormattedNumber value={props.element.existing.numeric}/>
    </td>
    <td className="align-right w-0 pl-0 pr-0">
      /
    </td>
    <td className="align-right w-2 pl-2">
      <EvFormattedNumber value={props.element.existing.percentual} decimals={2} unit="%"/>
    </td>
    <td className="align-right w-1/5 pr-2">
      <EvFormattedNumber value={props.element.novel.numeric}/>
    </td>
    <td className="align-right w-0 pl-0 pr-0">
      /
    </td>
    <td className="align-right w-2 pl-2">
      <EvFormattedNumber value={props.element.novel.percentual} decimals={2} unit="%"/>
    </td>
  </tr>;
}
