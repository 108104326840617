<div class="row whitelist-item" [ngClass]="headerClass(step)">
  <div class="col-lg-6 pull-left">
    <strong>{{ step.nr }} - {{ step.text }}</strong>
  </div>
  <div class="col-lg-6 pull-right">
    <div *ngIf="!step.lock_text && step.state === 1">Nächster Schritt</div>
    <div *ngIf="!step.lock_text && step.state === 2">AKTIV</div>
    <div *ngIf="step.lock_text">GESPERRT</div>
  </div>
</div>

<div class="row whitelist-item" [ngClass]="{'whitelist-item-default': step.state === 0||step.state === 3}">
  <div class="col-lg-4">
    <div *ngIf="step.start_date">Angemeldet: {{ step.start_date }} - {{ step.start_user }}</div>
    <div *ngIf="step.end_date">Abgemeldet: {{ step.end_date }} - {{ step.end_user }}</div>
    <div *ngIf="step.time">Dauer: {{ step.time }}</div>
  </div>

  <div class="col-lg-4">
    <div *ngIf="step.production_step_id === 37">
      <business-order-film-inventory [filmInventory]="filmInventory">
      </business-order-film-inventory>
    </div>
    <div *ngFor="let line of step.article_field">
      <div [innerHTML]="line"></div>
    </div>
    <div *ngIf="step.article_field.length === 0">&nbsp;</div>
  </div>

  <div class="col-lg-4" *ngIf="step.end_date">
    <div>Gutstück: {{ step.good_piece }}</div>
    <div>Ausschuss: {{ step.wastrel }}</div>
  </div>
</div>

<div class="row whitelist-item">
  <div class="col-lg-4">
    <div *ngIf="step.info_text"><strong>Information:</strong></div>
    <div *ngIf="step.info_text">{{ step.info_text }}</div>
    <div *ngIf="!step.info_text">&nbsp;</div>
  </div>
  <div class="col-lg-4">
    <div *ngIf="step.lock_text"><strong>Sperrtext:</strong></div>
    <div *ngIf="step.lock_text">{{ step.lock_text }}</div>
    <div *ngIf="!step.lock_text">&nbsp;</div>
  </div>
  <div class="col-lg-4">&nbsp;</div>
</div>

<div class="row whitelist-item" *ngIf="mergeButtonsVisible">
  <div class="col-lg-8" *ngIf="reproduction?.step_ordering !== step.ordering">&nbsp;</div>
  <div class="col-lg-4" *ngIf="reproduction?.step_ordering === step.ordering">&nbsp;</div>
  <div class="col-lg-4 align-center" *ngIf="reproduction?.step_ordering === step.ordering">
    <button type="button"
            ngbTooltip="Führt die Nachfertigung mit dem Haupt BA zusammen"
            class="btn btn-danger btn-sm"
            (click)="openMergeModal()"
            *ngIf="(!stepPrevious || (stepPrevious.state > 2))">
      Nachfertigung zusammenfügen
    </button>
  </div>
  <div class="col-lg-4" *ngIf="!('ba.reproduction.merge'|HasPerm)">
    <div class="red-font">Zusammenführungspunkt mit Haupt-BA</div>
  </div>
  <div class="col-lg-4" *ngIf="('ba.reproduction.merge'|HasPerm)">
    <button type="button"
            class="btn btn-default btn-sm pull-right"
            [ngbTooltip]="toolTipText"
            (click)="selectMergeStep()"
            *ngIf="reproduction?.step_ordering !== step.ordering">
      als Zusammenfügungspunkt wählen
    </button>
    <button type="button"
            ngbTooltip="Zusammenfügung in diesem Schritt abbrechen"
            class="btn btn-danger btn-sm pull-right"
            (click)="removeMergeStep()"
            *ngIf="reproduction?.step_ordering === step.ordering">
      Zusammenfügungspunkt entfernen
    </button>
  </div>
</div>

<div class="row whitelist-item">
  <div class="col-lg-12">
    <div *ngIf="startStopButtonsVisible">
      <button type="button" class="btn btn-success btn-sm pull-right" *ngIf="step.state === 2" (click)="stopStep()">
        Abmelden
      </button>
      <button type="button" class="btn btn-info btn-sm pull-left" *ngIf="step.state === 1" (click)="startStep()">
        Anmelden
      </button>
    </div>
  </div>
</div>

<div>&nbsp;</div>
