/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {MainAlertComponent} from './main-alert.component';
import {SharedModule} from '../../core/shared.module';
import {EvAlertModule} from '../../common/ev-alert/alert.module';
import {MainDropdownComponent} from './main-dropdown.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {MainVersionComponent} from './main-version.component';
import {BaseComponent} from './base.component';
import {BaseDashboardComponent} from './base-dashboard.component';
import {ArticleConfigModule} from '../../lazy/article-config/article-config.module';

@NgModule({
    imports: [
        SharedModule,
        EvAlertModule,
        UIRouterModule.forChild(),
        NgbDropdownModule,
        ArticleConfigModule,
    ],
    declarations: [
        MainAlertComponent,
        MainDropdownComponent,
        MainVersionComponent,
        BaseComponent,
        BaseDashboardComponent
    ],
    exports: [
        MainDropdownComponent,
        MainVersionComponent,
        MainAlertComponent
    ]
})
export class MainModule {
}
