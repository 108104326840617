import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HistoryService} from './history.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {AlertService} from '../../common/alert-service/alert.service';
import {Observable, from, EMPTY} from 'rxjs';
import {PdfHistoryModalComponent} from './pdf-history-modal.component';

@Injectable({providedIn: 'root'})
export class PdfHistoryModalService {

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private historyService: HistoryService) {
  }

  open(name: string, id: number, title: number | string): Observable<void> {
    return this.historyService.list(name, id).pipe(
      catchError(() => {
        this.alertService.add('danger', 'Historie konnte nicht geöffnet werden!');
        return EMPTY;
      }),
      mergeMap(val => {
        const modalRef = this.modalService.open(PdfHistoryModalComponent);
        modalRef.componentInstance.objects = val;
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.name = name;
        return from(modalRef.result);
      })
    );
  }

}
