import {Pipe, PipeTransform} from '@angular/core';
import {StringHelper} from '../string-helper';

@Pipe({name: 'Concat'})
export class Concat implements PipeTransform {

  transform(value: any[], delimiter?: string, filler?: string): string {
    return StringHelper.concat(value, delimiter, filler);
  }

}
