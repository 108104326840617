<div class="article-form-item" [formGroup]="form">
  <label [htmlFor]="'article-model-' + modelCheckbox"
         class="article-form-item-label"
         [ngClass]="{bold: bold}">
    {{ labelCheckbox }}:
  </label>

  <div class="article-form-item-padding">
  </div>

  <div class="article-form-item-data no-fixed-width">
    <div class="article-form-data-content multi-field">
      <input type="checkbox"
             [id]="'article-model-' + modelCheckbox"
             [ngClass]="{red_alert: errorCheckbox}" [formControlName]="modelCheckbox"
             (change)="change()">
    </div>
  </div>

  <label [htmlFor]="'article-model-' + model"
         class="article-form-item-label no-fixed-width"
         [ngClass]="{bold: bold}"
         *ngIf="view">
    {{ label }}:
  </label>

  <div class="article-form-item-data no-fixed-width"
       [ngClass]="{'data-group': !!unit}"
       *ngIf="view">
    <div class="article-form-data-content">
      <select [id]="'article-model-' + model"
              class="form-control"
              [formControlName]="model"
              (change)="change()"
              [ngClass]="{red_alert: error}">
        <option [ngValue]="null">-</option>
        <option *ngFor="let obj of data;trackBy: trackByFn" [ngValue]="obj.value">{{ obj.key }}</option>
      </select>
    </div>

    <div class="article-form-data-content" *ngIf="unit">
      <div class="article-form-data-unit-block">{{ unit }}</div>
    </div>

  </div>
</div>
