import {Component, Input} from '@angular/core';
import {Address} from '../model/address';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'address-box-modal-change',
  templateUrl: './address-box-modal-change.component.html'
})
export class AddressBoxModalChangeComponent {
  @Input() addresses: Address[];
  @Input() selected?: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  select(address: Address): void {
    this.activeModal.close(address);
  }

}
