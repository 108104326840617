import {Component} from '@angular/core';

@Component({
  selector: 'material-order-navigation',
  templateUrl: './material-order-navigation.component.html'
})
export class MaterialOrderNavigationComponent {
  constructor() {
  }
}
