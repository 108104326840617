/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, OnInit} from '@angular/core';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';
import {InvoiceListData} from '../invoice.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DateHelper} from '../../../../helper/date-helper';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {InvoiceCorrectionService} from '../services/invoice-correction.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'invoice-create-correction-modal',
  templateUrl: './invoice-create-correction-modal.component.html'
})
export class InvoiceCreateCorrectionModalComponent implements OnInit {
  @Input() invoice: InvoiceListData;
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private invoiceCorrectionService: InvoiceCorrectionService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      date: [DateHelper.format(new Date())],
      hint: [null, [Validators.required]],
      note: [null],
    });
  }

  create(clb: LoadingButtonEvent): void {
    this.errors = {};
    this.invoiceCorrectionService.fromInvoice(this.invoice.id, this.form.value).subscribe(response => {
      clb.callback(false);
      this.alertService.add('success', 'Stornierung für Rechnung ' + this.invoice.id + ' erfolgreich erstellt!');
      this.activeModal.close(response);
    }, (response: HttpErrorResponse) => {
      if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Stornierung für Rechnung ' + this.invoice.id + ' fehlgeschlagen!');
      clb.callback(false);
    });
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

}
