/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ArticleCore} from '../../article/models/article.models';
import {ArticleExtraSaveForm, ShippingInstructionDelete} from '../article-extra.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {
  ShippingInstruction, ShippingInstructionBase,
  ShippingInstructionSchema,
  ShippingInstructionSchemaDictionary, ShippingInstructionType
} from '../../article/models/article-extra.models';

@Component({
  selector: 'article-extra-grouped',
  templateUrl: './article-extra-grouped.component.html'
})
export class ArticleExtraGroupedComponent implements OnInit, OnDestroy {
  @Input() schema: ShippingInstructionSchema[];
  @Input() data: ShippingInstruction;
  @Input() article?: ArticleCore;
  @Input() mainType: ShippingInstructionType;
  @Input() readOnly = false;
  @Output() deleteSectionEmitter = new EventEmitter<ShippingInstructionDelete>();
  @Output() saveEmitter = new EventEmitter<ArticleExtraSaveForm>();
  form: UntypedFormGroup;
  schemaDictionary: ShippingInstructionSchemaDictionary = {};
  private formSubscription: Subscription;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.schema.forEach(value => {

      let group = this.schemaDictionary[value.group];
      if (!group) {
        group = {};
      }

      group[value.name] = value;

      this.schemaDictionary[value.group] = group;
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  update(value: { data: any, name: string }): void {
    this.data[value.name] = value.data;
    this.saveEmitter.emit({data: this.data, valid: true});
  }

}
