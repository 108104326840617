import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {MaterialOrderNavigationComponent} from './navigation/material-order-navigation.component';
import {MaterialOrderListComponent} from './list/material-order-list.component';
import {MaterialOrderCreateComponent} from './create/material-order-create.component';
import {MaterialSupplierService} from '../../../lazy/material-supplier/material-supplier.service';
import {MaterialOrderListService} from './list/material-order-list.service';
import {MaterialOrderIntakeComponent} from './intake/material-order-intake.component';
import {MaterialOrderIntakeService} from './intake/material-order-intake.service';
import {MaterialOrderService} from './material-order.service';
import {MaterialOrderPositionListComponent} from './position-list/material-order-position-list.component';
import {MaterialOrderPositionListService} from './position-list/material-order-position-list.service';
import {MaterialSupplierGroupService} from './supplier-group/material-supplier-group.service';
import {MaterialGroupListPermittedService} from '../groups/material-group-list-permitted.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {NumberHelper} from '../../../helper/number-helper';
import {MaterialProductGroupListService} from '../product-groups/material-product-groups-list.service';

export function materialSupplierListResolveFn(mss: MaterialSupplierService) {
  return mss.list().toPromise();
}

export function materialSupplierGroupListResolveFn(mss: MaterialSupplierGroupService) {
  return mss.list().toPromise();
}

export function materialOrderListResolveFn(ts: Transition, mos: MaterialOrderListService) {
  const params = ts.params();
  return mos.list(params).toPromise();
}

export function materialGroupListResolveFn(mgs: MaterialGroupListPermittedService) {
  return mgs.list().toPromise();
}

export function materialProductGroupListResolveFn(mpgs: MaterialProductGroupListService) {
  return mpgs.list().toPromise();
}

export function materialProductGroupIdResolveFn(ts: Transition) {
  return NumberHelper.saveParseInteger(ts.params()?.product_group);
}

export function materialGroupIdsResolveFn(ts: Transition) {
  const groupParams = EnvisiaLocation.decodeHttpParams(ts.params())?.groups as string;
  return groupParams?.split(',')?.map(s => NumberHelper.saveParseInteger(s)) ?? [];
}

export function materialOrderIntakeListResolveFn(ts: Transition, mois: MaterialOrderIntakeService) {
  const params = ts.params();
  return mois.list({
    order_id: params.order_id,
    show_booked: params.show_booked,
    marked_variation_id: params.marked_variation_id,
    split_booked: false,
  }).toPromise();
}

export function materialOrderIntakeListBookedResolveFn(ts: Transition, mois: MaterialOrderIntakeService) {
  const params = ts.params();
  return mois.list({
    order_id: params.order_id,
    show_booked: params.show_booked,
    marked_variation_id: params.marked_variation_id,
    split_booked: true,
  }).toPromise();
}

export function materialOrderResolveFn(ts: Transition, mos: MaterialOrderService) {
  const params = ts.params()?.order_id;
  return mos.get(params).toPromise();
}

export function materialSupplierIdResolveFn(ts: Transition) {
  const idString = ts.params()?.supplier_id;
  const id = idString ? Number.parseInt(idString, 10) : null;
  return (id && Number.isInteger(id)) ? id : null;
}

export function materialOrderMarkedVariationResolveFn(ts: Transition) {
  const idString = ts.params()?.marked_variation_id;
  const id = idString ? Number.parseInt(idString, 10) : null;
  return (id && Number.isInteger(id)) ? id : null;
}

export function materialOrderMarkedPositionResolveFn(ts: Transition) {
  const idString = ts.params()?.marked_position_id;
  const id = idString ? Number.parseInt(idString, 10) : null;
  return (id && Number.isInteger(id)) ? id : null;
}

export function materialOrderMarkedTransactionResolveFn(ts: Transition) {
  const idString = ts.params()?.marked_transaction_id;
  const id = idString ? Number.parseInt(idString, 10) : null;
  return (id && Number.isInteger(id)) ? id : null;
}

export function materialOrderPositionListResolveFn(ts: Transition, mops: MaterialOrderPositionListService) {
  const params = ts.params();
  return mops.list(params).toPromise();
}

export const MATERIAL_ORDER_STATE: Ng2StateDeclaration = {
  name: 'a.material.order',
  url: '/material/order',
  redirectTo: 'a.material.order.list',
  abstract: true,
  data: {requiresAuth: true},
  views: {
    'navigation@a': {component: MaterialOrderNavigationComponent}
  }
};

export const MATERIAL_ORDER_POSITION_STATE: Ng2StateDeclaration = {
  name: 'a.material.order.position',
  url: '/position',
  redirectTo: 'a.material.order.position.list',
  abstract: true,
  data: {requiresAuth: true},
};

export const MATERIAL_ORDER_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.material.order.list',
  url: '/list?page&sort&order&id&supplier_id&delivery_id&status&date_start&date_end&groups&product_group',
  params: {
    page: {dynamic: true},
    sort: {dynamic: true},
    order: {dynamic: true},
    id: {dynamic: true},
    supplier_id: {dynamic: true},
    delivery_id: {dynamic: true},
    status: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    groups: {dynamic: true},
    product_group: {dynamic: true},
  },
  data: {
    requiresAuth: true
  },
  views: {
    'main@a': {component: MaterialOrderListComponent}
  },
  resolve: [
    {
      token: 'suppliers',
      resolveFn: materialSupplierListResolveFn,
      deps: [MaterialSupplierService]
    },
    {
      token: 'listData',
      resolveFn: materialOrderListResolveFn,
      deps: [Transition, MaterialOrderListService]
    },
    {
      token: 'productGroups',
      resolveFn: materialProductGroupListResolveFn,
      deps: [MaterialProductGroupListService],
    },
    {
      token: 'groups',
      resolveFn: materialGroupListResolveFn,
      deps: [MaterialGroupListPermittedService],
    },
    {
      token: 'activeProductGroupId',
      resolveFn: materialProductGroupIdResolveFn,
      deps: [Transition],
    },
    {
      token: 'activeGroupIds',
      resolveFn: materialGroupIdsResolveFn,
      deps: [Transition],
    },
  ]
};

export const MATERIAL_ORDER_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.material.order.create',
  url: '/create?supplier_id&marked_variation_id',
  params: {
    supplier_id: {dynamic: true},
    marked_variation_id: {dynamic: true},
  },
  data: {
    requiresAuth: true
  },
  views: {
    'main@a': {component: MaterialOrderCreateComponent}
  },
  resolve: [
    {
      token: 'supplierGroups',
      resolveFn: materialSupplierGroupListResolveFn,
      deps: [MaterialSupplierGroupService]
    },
    {
      token: 'groups',
      resolveFn: materialGroupListResolveFn,
      deps: [MaterialGroupListPermittedService],
    },
    {
      token: 'startSupplierId',
      resolveFn: materialSupplierIdResolveFn,
      deps: [Transition]
    },
    {
      token: 'markedVariationId',
      resolveFn: materialOrderMarkedVariationResolveFn,
      deps: [Transition]
    },
  ]
};

export const MATERIAL_ORDER_INTAKE_STATE: Ng2StateDeclaration = {
  name: 'a.material.order.intake',
  url: '/intake/{order_id}?show_booked&marked_variation_id&marked_position_id&marked_transaction_id',
  params: {
    order_id: {dynamic: true},
    show_booked: {dynamic: true},
    marked_variation_id: {dynamic: true},
    marked_position_id: {dynamic: true},
    marked_transaction_id: {dynamic: true},
  },
  data: {
    requiresAuth: true
  },
  views: {
    'main@a': {component: MaterialOrderIntakeComponent}
  },
  resolve: [
    {
      token: 'order',
      resolveFn: materialOrderResolveFn,
      deps: [Transition, MaterialOrderService]
    },
    {
      token: 'listData',
      resolveFn: materialOrderIntakeListResolveFn,
      deps: [Transition, MaterialOrderIntakeService]
    },
    {
      token: 'listBookedData',
      resolveFn: materialOrderIntakeListBookedResolveFn,
      deps: [Transition, MaterialOrderIntakeService]
    },
    {
      token: 'markedVariationId',
      resolveFn: materialOrderMarkedVariationResolveFn,
      deps: [Transition]
    },
    {
      token: 'markedPositionId',
      resolveFn: materialOrderMarkedPositionResolveFn,
      deps: [Transition]
    },
    {
      token: 'markedTransactionId',
      resolveFn: materialOrderMarkedTransactionResolveFn,
      deps: [Transition]
    },
    {
      token: 'groups',
      resolveFn: materialGroupListResolveFn,
      deps: [MaterialGroupListPermittedService],
    },
  ]
};

export const MATERIAL_ORDER_POSITION_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.material.order.position.list',
  url: '/list?sort&order&page&status&order_id&index&delivery_index&supplier_id&supplier_art_nr&article_name&delivery_id&delivery_date&overdue&groups&product_group',
  params: {
    sort: {dynamic: true},
    order: {dynamic: true},
    page: {dynamic: true},
    status: {dynamic: true},
    order_id: {dynamic: true},
    index: {dynamic: true},
    delivery_index: {dynamic: true},
    supplier_id: {dynamic: true},
    supplier_art_nr: {dynamic: true},
    article_name: {dynamic: true},
    delivery_id: {dynamic: true},
    delivery_date: {dynamic: true},
    overdue: {dynamic: true},
    groups: {dynamic: true},
    product_group: {dynamic: true},
  },
  data: {
    requiresAuth: true
  },
  views: {
    'main@a': {component: MaterialOrderPositionListComponent}
  },
  resolve: [
    {
      token: 'suppliers',
      resolveFn: materialSupplierListResolveFn,
      deps: [MaterialSupplierService]
    },
    {
      token: 'listData',
      resolveFn: materialOrderPositionListResolveFn,
      deps: [Transition, MaterialOrderPositionListService]
    },
    {
      token: 'productGroups',
      resolveFn: materialProductGroupListResolveFn,
      deps: [MaterialProductGroupListService],
    },
    {
      token: 'groups',
      resolveFn: materialGroupListResolveFn,
      deps: [MaterialGroupListPermittedService],
    },
    {
      token: 'activeProductGroupId',
      resolveFn: materialProductGroupIdResolveFn,
      deps: [Transition],
    },
    {
      token: 'activeGroupIds',
      resolveFn: materialGroupIdsResolveFn,
      deps: [Transition],
    },
  ]
};
