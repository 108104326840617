<div class="page-header">
  <h3>Artikel {{ originalArticle.name }} bearbeiten</h3>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-horizontal">
  <div class="red_alert" *ngIf="originalArticle.date_deleted">
    <h3>Achtung!</h3>
    Der Artikel wurde bereits gelöscht. Durch das abspeichern wird er wiederhergestellt.
  </div>
  <div *ngIf="originalArticle.date_deleted">&nbsp;</div>

  <material-article-form-create [form]="articleForm" [articleFieldDefs]="articleFieldDefs">
  </material-article-form-create>

  <div class="row" *ngIf="originalArticle">
    <div class="col-sm-12">
      <material-variation-table
        [currentArticle]="originalArticle"
        [variations]="variations"
        [variationFieldDefs]="variationFieldDefs"
        [units]="units"
        [errors]="variationErrors"
        [variationRows]="variationForms"
        (addVariation)="addVariation()"
        (removeVariation)="removeVariation($event)">
      </material-variation-table>
    </div>
  </div>

  <material-article-error-message [error]="error" [form]="form"></material-article-error-message>

  <div class="col-sm-12">
    &nbsp;
  </div>
  <div class="col-sm-12">
    <button type="submit" class="btn btn-success" [disabled]="!form.valid">
      Artikel speichern
    </button>
  </div>
</form>
