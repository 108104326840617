<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    COC/Prüfprotokoll
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="coc_testing_protocol"
                            [defaultValues]="defaultValues['coc-testing-protocol']"
                            class="article-form-item-values">
    </article-extra-dropdown>

    <article-extra-text formControlName="coc_testing_protocol_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="completion"
                            [defaultValues]="defaultValues['completion']"
                            class="article-form-item-values">
      Ausführung
    </article-extra-dropdown>

    <article-extra-text formControlName="completion_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-text formControlName="other" class="article-form-item-extra-long">
      Sonstiges
    </article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="gold_protocol" class="article-form-item-values">
      Gold Protokoll
    </article-extra-checkbox>

    <article-extra-text formControlName="gold_protocol_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <h6>Besonderheiten China</h6>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="china_outgoing_report" class="article-form-item-values">
      China Outgoing Report
    </article-extra-checkbox>

    <article-extra-text formControlName="china_outgoing_report_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <h6>Versand Protokoll</h6>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="email" class="article-form-item-values">
      E-Mail
    </article-extra-checkbox>

    <article-extra-text formControlName="email_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="paper" class="article-form-item-values">
      Papier
    </article-extra-checkbox>

    <article-extra-text formControlName="paper_other" class="article-form-item-extra"></article-extra-text>
  </div>
</div>
