<ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
  <li [ngbNavItem]="1" class="nav-item">
    <a ngbNavLink>Lagerbestand anpassen</a>
    <ng-template ngbNavContent>
      <material-inventory-change-modal [storage]="storage" [konsi]="isKonsi" (closeEmitter)="closeWindow($event)">
      </material-inventory-change-modal>
    </ng-template>
  </li>

  <li [ngbNavItem]="2" [disabled]="lockTransferTabs" class="nav-item">
    <a ngbNavLink [ngClass]="{disabled: lockTransferTabs}">Auf anderen Lagerplatz umbuchen</a>
    <ng-template ngbNavContent>
      <material-inventory-transfer-stock-modal [storage]="storage" [isKonsi]="isKonsi" (closeEmitter)="closeWindow($event)">
      </material-inventory-transfer-stock-modal>
    </ng-template>
  </li>

  <li [ngbNavItem]="3" [disabled]="lockTransferTabs" class="nav-item" *ngIf="storage.konsi && 'material.konsi.change'|HasPerm">
    <a ngbNavLink [ngClass]="{disabled: lockTransferTabs}">
      Bestandstyp (Konsi / Eigen) ändern
    </a>
    <ng-template ngbNavContent>
      <material-inventory-transfer-konsi-modal [storage]="storage" [isKonsi]="isKonsi" (closeEmitter)="closeWindow($event)">
      </material-inventory-transfer-konsi-modal>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>