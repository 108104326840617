import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'file-drop',
  templateUrl: './file-drop.component.html',
})
export class FileDropComponent implements OnInit, OnDestroy {
  @Output() changeEmitter = new EventEmitter<File>();

  @ViewChild('fileSelector', {static: true})
  public fileSelector: ElementRef;

  @ViewChild('outerRef', {static: true})
  public ref: ElementRef;

  name = 'Dokumente hierher ziehen';
  selected = false;
  dragoverListener: () => void;
  dragstartListener: () => void;
  dragendListener: () => void;
  dragdropListener: () => void;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit() {
    const callback = (event: Event) => {
      this.dragProgress(event);
    };
    this.dragoverListener = this.renderer.listen(this.ref.nativeElement, 'dragover', callback);
    this.dragstartListener = this.renderer.listen(this.ref.nativeElement, 'dragenter', callback);
    this.dragendListener = this.renderer.listen(this.ref.nativeElement, 'dragleave', callback);
    this.dragdropListener = this.renderer.listen(this.ref.nativeElement, 'drop', (event: Event) => {
      this.dragProgress(event);

      const file = (event as any).dataTransfer.files[0];

      this.fireChangedFile(file);
    });
  }

  ngOnDestroy(): void {
    this.dragoverListener();
    this.dragstartListener();
    this.dragendListener();
    this.dragdropListener();
  }

  selectFile(): void {
    (this.fileSelector.nativeElement as HTMLInputElement).click();
  }

  fileChanged(event: Event): void {
    const element = event.target;
    const file = (element as any).files[0];
    this.fireChangedFile(file);
  }

  private fireChangedFile(file: File): void {
    this.name = file.name;
    this.selected = true;
    this.changeEmitter.emit(file);
  }

  private dragProgress(event: Event): void {
    this.renderer.removeClass(this.ref.nativeElement, 'event-dragleave');
    this.renderer.removeClass(this.ref.nativeElement, 'event-dragenter');
    this.renderer.removeClass(this.ref.nativeElement, 'event-dragover');
    this.renderer.removeClass(this.ref.nativeElement, 'event-drop');

    this.renderer.addClass(this.ref.nativeElement, 'event-' + event.type);

    event.preventDefault();
    event.stopPropagation();
  }

}
