import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {OrderRequestLists, OrderRequestView} from '../../../request/workflow-request.model';
import {ErpProducer} from '../../../../producer/producer.models';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {createNonRequiredNumberValidator} from '../order-request-main-request-form/request-form.builds';
import {orderArrayFilter, orderInnerForm} from './order-form.builders';
import {WorkflowOrderRequestService} from '../../../workflow-services/workflow-order-request.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {OrderItemForm} from '../../order-request.models';
import {HttpErrorResponse} from '@angular/common/http';
import {CustomerCreditWorthiness} from '../../../../customer/credit-worthiness/customer-credit-worthiness.models';

@Component({
  selector: 'order-request-main-order-form',
  templateUrl: './order-request-main-order-form.component.html',
})
export class OrderRequestMainOrderFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: OrderRequestView;
  @Input() producerList: ErpProducer[];
  @Input() workflowPositions: { quantity: number, lose: number }[];
  @Input() orderToRequestData: { requestNr: number, producer: string } | undefined | null;
  @Input() customerCredit: CustomerCreditWorthiness;
  @Output() updateNoteEmitter = new EventEmitter<string>();
  @Output() createOrderEmitter = new EventEmitter<OrderRequestLists>();
  @Output() requestToOrderEmitter = new EventEmitter<{ requestNr: number, producer: string } | null>();
  errors: { [key: string]: any; } = {};
  form: UntypedFormGroup;
  noteSubscription?: Subscription;
  creating = false;

  constructor(private fb: UntypedFormBuilder,
              private workflowOrderRequestService: WorkflowOrderRequestService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      'producer': [null, Validators.required],
      'request_nr': [null, createNonRequiredNumberValidator(false, true)],
      'extra_cost': [null],
      'data': this.createOrderPositions(),
      'testing': [null, Validators.required],
      'testing_note': [null],
      'note': [this.data.workflow.order_hint],
    });

    this.noteSubscription = this.form.get('note').valueChanges.pipe(debounceTime(550)).subscribe(values => {
      this.updateNoteEmitter.emit(values);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderToRequestData &&
      !changes.orderToRequestData.isFirstChange() &&
      changes.orderToRequestData.currentValue) {
      this.form.patchValue({
        'producer': changes.orderToRequestData.currentValue.producer,
        'request_nr': changes.orderToRequestData.currentValue.requestNr,
      });
      this.requestToOrderEmitter.emit(null);
    }
  }

  ngOnDestroy(): void {
    if (this.noteSubscription) {
      this.noteSubscription.unsubscribe();
    }
  }

  createOrder(): void {
    this.creating = false;
    const formData = this.form.value;
    formData.data = (formData.data as Array<OrderItemForm>).filter(orderArrayFilter);
    this.workflowOrderRequestService.create(this.data.workflow.id, formData).subscribe(response => {
      this.creating = false;
      this.createOrderEmitter.emit(response);
      this.alertService.add('success', 'Bestellung erfolgreich erstellt!');
      this.form.reset({
        'producer': null,
        'request_nr': null,
        'extra_cost': null,
        'testing': null,
        'testing_note': null,
      }, {emitEvent: false});
      const formArray = (this.form.get('data') as UntypedFormArray);
      formArray.clear();
      formArray.push(orderInnerForm(this.fb));
    }, (response: HttpErrorResponse) => {
      this.creating = false;
      if (response.error) {
        this.errors = response.error;
      } else {
        this.errors = {'request_nr': ['wrong number']};
      }
      this.alertService.add('danger', 'Konnte Bestellung nicht erstellen!');
    });
  }

  private createOrderPositions(): UntypedFormArray {
    const singleValueValidator = (control: AbstractControl) => {
      const arrayValues = ((control as UntypedFormArray).value as Array<OrderItemForm>).filter(orderArrayFilter);
      return arrayValues.length > 0 ? null : {'array': 'required'};
    };
    return this.fb.array([orderInnerForm(this.fb)], {validators: singleValueValidator});
  }
}
