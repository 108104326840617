import {Customer} from '../../model/customer';
import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../model/contact';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerContactEditComponent} from '../edit/customer-contact-edit.component';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {CustomerContactDepartmentListService} from '../../../../lazy/config/departments/customer-contact-department-list.service';
import {ContactService} from '../../services/contact.service';
import {ignoreRejection} from '../../../../helper/ignore_rejection';

@Component({
  selector: 'customer-contact-list',
  templateUrl: './customer-contact-list.component.html',
})
export class CustomerContactListComponent implements OnInit {
  @Input() data: { customer: Customer, contacts: Contact[] };
  customer: Customer;
  contacts: Contact[];

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private contactService: ContactService,
              private departmentListService: CustomerContactDepartmentListService) {
  }

  ngOnInit(): void {
    this.customer = this.data.customer;
    this.contacts = this.data.contacts;
  }

  open(index: number, contact: Contact): void {
    this.departmentListService.list({sort: 'name'}).subscribe(l => {
      const modalRef = this.modalService.open(CustomerContactEditComponent, {windowClass: 'modal2-mm'});
      modalRef.componentInstance.contact = contact;
      modalRef.componentInstance.departments = l.objects;
      modalRef.result.then(() => {
        this.contactService.list(this.customer.id).subscribe(cl => {
          this.contacts = cl;
        }, ignoreRejection);
      }, ignoreRejection);
    }, () => {
      this.alertService.add('danger', 'Beim Aufrufen der Abteilungen ist ein Fehler aufgetreten!');
    });
  }

}
