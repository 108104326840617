import {Component, Input, OnInit} from '@angular/core';
import {MaterialValueType} from '../material-management.model';
import {MaterialManagementService} from '../material-management.service';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {MaterialGroup} from '../groups/material-group.model';

@Component({
  selector: 'material-article-create',
  templateUrl: './material-article-create.component.html'
})
export class MaterialArticleCreateComponent implements OnInit {
  @Input() currentGroup?: MaterialGroup;
  fieldsObservable: LoadingWrapper<MaterialValueType[]>;

  constructor(private materialManagementService: MaterialManagementService) {
  }

  ngOnInit() {
    if (this.currentGroup) {
      this.fieldsObservable = this.createLoadingWrapper();
    }
  }

  createLoadingWrapper(): LoadingWrapper<MaterialValueType[]> {
    return new LoadingWrapper(this.materialManagementService.listCreateArticleFieldForms(this.currentGroup.id));
  }

  setGroup(group: MaterialGroup): void {
    this.currentGroup = group;
    this.fieldsObservable = this.createLoadingWrapper();
  }
}
