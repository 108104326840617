<div class="row">
  <div class="col-lg-12">
    <table class="table whitetable">
      <thead>
      <tr>
        <th class="bold fixed-width-150">Abruf</th>
        <th>&nbsp;</th>
        <th class="bold">Menge</th>
        <th class="bold">Spätester End-Termin</th>
        <th class="bold">Kunden Liefertermin</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let fetch of fetches">
        <td>{{ fetch.fetch.ordering }}</td>
        <td>
          <workflow-state type="fetch_status" [key]="fetch.fetch.status.id"></workflow-state>
          <div class="label label-danger" *ngIf="fetch.express">EILDIENST</div>
        </td>
        <td>{{ fetch.position.quanity|money }}</td>
        <td>{{ fetch.last_work_day }}</td>
        <td>{{ fetch.fetch.delivery }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="col-lg-6">
    <table class="table whitetable marginless-bottom">
      <tr class="frame-table-box">
        <td class="bold fixed-width-400">Benötigte Gesamtmenge:</td>
        <td>{{ fetchesMin?.quantity|money }}</td>
      </tr>
    </table>
  </div>
  <div class="col-lg-6">
    <table class="table whitetable marginless-bottom">
      <tr class="frame-table-box">
        <td class="bold fixed-width-400">Spätester End-Termin Produktion:</td>
        <td> {{ fetchesMin?.end_date }}</td>
      </tr>
    </table>
  </div>
</div>
