<ng-container *ngIf="isoCountryLoader.data$|async as country; else loadingOrError.template">
  <div class="contract-history-box whitelist-item whitelist-item-margin"
       *ngFor="let fetch of fetches; trackBy: trackById; let index = index">
    <fetch-box-inner [workflow]="workflow"
                     [customer]="customer"
                     [addresses]="addresses"
                     [fetch]="fetch"
                     [frameTyp]="frameTyp"
                     [isoCountry]="country"
                     [priceIncreaseFlex]="priceIncreaseFlex"
                     (statusChange)="statusChange.emit($event)">
    </fetch-box-inner>
  </div>
</ng-container>

<loading-or-error
  #loadingOrError
  [loadingWrapper]="isoCountryLoader"
  [errorMessage]="'Konnte Länder nicht laden'">
</loading-or-error>
