import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {MaterialProductGroupsListComponent} from './material-product-groups-list.component';
import {MaterialProductGroupsService} from './material-product-groups.service';

function productGroupListResolveFn(transition: Transition, service: MaterialProductGroupsService) {
  return service.list(transition.params()).toPromise();
}

export const MATERIAL_PRODUCT_GROUP_STATE: Ng2StateDeclaration = {
  name: 'a.material.product.groups.list',
  url: '/material/product/groups/list?page&sort&order&id&name',
  views: {'main@a': {component: MaterialProductGroupsListComponent}},
  params: {
    page: {dynamic: true},
    sort: {dynamic: true},
    order: {dynamic: true},
    id: {dynamic: true},
    name: {dynamic: true},
  },
  resolve: [
    {
      token: 'listData',
      resolveFn: productGroupListResolveFn,
      deps: [Transition, MaterialProductGroupsService],
    },
  ],
  data: {requiresAuth: true}
};
