import * as React from 'react';
import {ArticleMultilayerPartType} from '../article-multilayer-plan.models';
import {ArticleMultilayerPartTypeHelper} from '../article-multilayer-part-type-helper';

interface Props {
  partAdd: (partName: string | null) => void;
  partMenuOpen: boolean;
  partMenuOpened: (open: boolean) => void;
  mirrorCopy?: () => void;
}

export function ArticleMultilayerPlanSelectPart(props: Props) {
  const select: (part: ArticleMultilayerPartType) => void = (part) => {
    props.partAdd(part.name);
    props.partMenuOpened(false);
  };

  const open: () => void = () => {
    props.partMenuOpened(true);
  };

  const mirrorCopy: () => void = () => {
    props.mirrorCopy();
    props.partMenuOpened(false);
  };

  return <>
    <div className={'dropdown-button'}>
      <div className={'dropdown-button-inner click'}>
        <i className={'fa fa-plus-circle green'} onClick={() => open()}/>
      </div>
      {
        props.partMenuOpen ?
          <div className={'ddc'}>
            {
              Object.values(ArticleMultilayerPartTypeHelper.types).filter(o => o.type !== 0 && o.type !== 3).map(part =>
                <div key={part.type}
                     className={'click color-class ' + part.color_class}
                     onClick={() => select(part)}>
                  {part.label}
                </div>
              )
            }
            {props.mirrorCopy ?
              <div className={'click color-class ' + 'silver'}
                   onClick={() => mirrorCopy()}>
                Von oben Spiegeln
              </div> :
              null
            }
          </div> :
          null
      }
    </div>
  </>;
}
