/*
 * Copyright (C) 2019 envisia GmbH
 * All Rights Reserved.src/app/app.raven.ts
 */
import {from, Observable, of, Subject} from 'rxjs';
import {catchError, flatMap, shareReplay} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {StateService} from '@uirouter/core';

export class LoadingWrapper<T> {
  private readonly _errorLoading$ = new Subject<boolean>();

  readonly errorLoading$: Observable<boolean> = this._errorLoading$.pipe(
    shareReplay(1)
  );
  readonly data$: Observable<T>;

  constructor(data: Observable<T>, redirect404: StateService | null = null) {
    this.data$ = data.pipe(
      shareReplay(1),
      catchError(error => {
        if (error instanceof HttpErrorResponse && redirect404 !== null && error.status === 404) {
          return from(redirect404.go('o.error404')).pipe(
            flatMap(() => of<T>()),
            catchError(() => of<T>())
          );
        }
        this._errorLoading$.next(true);
        return of<T>();
      })
    );
  }
}
