/*
 * Copyright (C) 2020 envisia GmbH
 * All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BusinessOrderUtilizeCalc} from '../models/business-order.models';
import {Observable} from 'rxjs';
import {LocalDate} from '../../models/local-date';
import {FetchDates} from '../../models/fetch.models';

@Injectable({providedIn: 'root'})
export class BusinessOrderProposalService {

  private url = '/api/v1/workflow/business/order/';

  constructor(private http: HttpClient) {}

  calculate(workflowId: number, amount: number, isExpress: boolean): Observable<BusinessOrderUtilizeCalc> {
    return this.http.get<BusinessOrderUtilizeCalc>(
      this.url + `proposal/${workflowId}?amount=${amount}&isExpress=${isExpress ? 'true' : 'false'}`
    );
  }

  dateWithAmount(
    workflowId: number,
    amount: number,
    gold: boolean,
    deliveryDate: LocalDate
  ): Observable<FetchDates> {
    return this.http.get<FetchDates>(
      this.url + `date/amount/${workflowId}?amount=${amount}&delivery=${deliveryDate}&gold=${gold ? 'true' : 'false'}`
    );
  }

}
