import {Directive} from '@angular/core';
import Helper from '../../../helper/helper';
import {AlertService} from '../../../common/alert-service/alert.service';

@Directive()
export abstract class InventoryError {
  protected constructor(protected alertService: AlertService) {
  }

  alertError(response) {
    let message = 'Beim Lagerstand Anpassen ist ein Fehler aufgetreten!';
    const error = response['obj.storage_bin_number'];
    const noteError = response['obj.note'];
    if (Helper.nonEmpty(error)) {
      const inner = error[0];
      if (inner.msg[0] === 'error.storage') {
        const other = inner.args[0];
        if (Helper.defined(other)) {
          message = 'Beim Lagerstand Anpassen ist ein Fehler aufgetreten, ' +
            'Lagerplatz muss innerhalb von ' + other.min + ' und ' + other.max + ' sein!';
        } else {
          message = 'Beim Lagerstand Anpassen ist ein Fehler aufgetreten, Lagerplatz muss leer sein!';
        }
      }
    } else if (
      Helper.nonEmpty(noteError) &&
      Helper.defined(noteError[0]) &&
      Helper.defined(noteError[0].msg[0]) &&
      noteError[0].msg[0] === 'error.negative.not.commented'
    ) {
      message = 'Beim Lagerstand Anpassen ist ein Fehler aufgetreten. ' +
        'Bei negativen Mengen muss eine Notiz angegeben werden!';
    }

    this.alertService.add('danger', message);
  }
}
