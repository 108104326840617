import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef} from '@angular/core';

export const EV_FILE_UPLOAD_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EvFileUploadComponent),
  multi: true,
};

@Component({
  selector: 'ev-file-upload',
  templateUrl: './ev-file-upload.component.html',
  providers: [
    EV_FILE_UPLOAD_VALUE_ACCESSOR
  ],
})
export class EvFileUploadComponent implements ControlValueAccessor {
  private file?: File = null;
  disabled = false;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  constructor() {
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  writeValue(obj: any): void {
    if (obj === undefined || obj === null) {
      this.file = null;
      return;
    }

    this.file = obj;
    this._onChange(obj);
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onChangeEvent(event): void {
    const file = !event?.target?.files && (event.target.files.length > 0) ? null : event.target.files[0];
    this._onChange(file);
  }
}
