/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {HookResult, Rejection, RejectType, Transition, TransitionService, UIRouter} from '@uirouter/core';
import {StateService} from '@uirouter/angular';
import {GlobalService} from './core/global.service';
import {onExit} from './envisia/article/article.states';
import {debug} from './helper/debug.func';
import {AuthService} from './envisia/auth/auth.service';
import {map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {LocalStorage} from './common/storage/local-storage';
import {InitData} from './common/init-data/init-data';

export function loadingHook(transitionService: TransitionService) {
  const loadingTrue = (transition: Transition): HookResult => {
    transition.injector().get<GlobalService>(GlobalService).show = true;
  };
  const loadingFalse = (transition: Transition): HookResult => {
    transition.injector().get<GlobalService>(GlobalService).show = false;
  };

  const onErrorHook = (transition: Transition): HookResult => {
    const error = transition.error();
    const errorMessage = error.hasOwnProperty('message') ? error['message'] : null;
    if (errorMessage !== 'The transition has been superseded by a different transition') {
      debug('onError', transition);
    }
    loadingFalse(transition);
  };

  transitionService.onExit({exiting: 'a.article.**'}, onExit, {priority: 100});
  transitionService.onBefore({to: '**'}, loadingTrue, {priority: 100});
  transitionService.onSuccess({to: '**'}, loadingFalse, {priority: 100});
  transitionService.onError({to: '**'}, onErrorHook, {priority: 100});
}

export function requiresAuthHook(transitionService: TransitionService) {
  const requiresAuthCriteria = {
    to: (state) => {
      // we always require an authentication, except when it is set to false
      if (!state.data) {
        return true;
      }
      return state.data && state.data.requiresAuth;
    }
  };

  // Function that returns a redirect for the current transition to the login state
  // if the user is not currently authenticated (according to the AuthService)
  const redirectToLogin = (transition: Transition): HookResult => {
    const authService: AuthService = transition.injector().get<AuthService>(AuthService);
    return authService.isAuthenticated().toPromise();
  };

  // Register the "requires auth" hook with the TransitionsService
  transitionService.onBefore(requiresAuthCriteria, redirectToLogin, {priority: 10});
}

export function registerDefaultErrorHandler(stateService: StateService) {
  stateService.defaultErrorHandler((error: any) => {
    debug('Error', error);
    if (error.detail) {
      if (error.detail.status === 404) {
        stateService.go('o.error404', {errorMessage: error.detail});
        return;
      } else if (error.detail.status === 403) {
        // adds the additional parameter to the state
        // so that we can see why he was unauthorized
        let additional: string | null = null;
        if (error.detail instanceof HttpErrorResponse) {
          additional = error.detail.headers.get('status');
        }
        stateService.go('o.error403', {errorMessage: error.detail, additional: additional});
        return;
      } else if (error.detail.status === 401) {
        stateService.go('o.error401', {errorMessage: error.detail});
        return;
      }
    }

    if ((error instanceof Rejection) && error.type !== RejectType.ERROR) {
      console.log('Transition Rejection', error);
      return;
    }

    const localStorage = stateService.transition.injector().get<LocalStorage>(LocalStorage);
    const user = localStorage.getObject<InitData>('data').user;
    const additionalData = user ? {userEmail: user.email, username: user.username} : {};

    console.log('Routing Error');
    stateService.go('o.error500', {errorMessage: error});
  });
}

export function routerConfigFn(router: UIRouter) {
  const transitionService = router.transitionService;
  const stateService = router.stateService;

  router.urlService.config.hashPrefix('!');

  registerDefaultErrorHandler(stateService);
  requiresAuthHook(transitionService);
  loadingHook(transitionService);
}
