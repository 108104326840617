/*
 * Copyright (C) 2020 envisia GmbH
 * All Rights Reserved.
 */
import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import Helper from '../../../helper/helper';
import {deepCopy} from '../../../helper/deep-copy';
import {noop} from '../../../helper/noop';
import {ArticleExtraBaTerminationTableRow} from '../article-extra.models';

export const ARTICLE_EXTRA_TABLE_BA_TERMINATION_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleExtraTableBaTerminationComponent),
  multi: true,
};

export const ARTICLE_EXTRA_TABLE_BA_TERMINATION_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ArticleExtraTableBaTerminationComponent),
  multi: true
};

@Component({
  selector: 'article-extra-table-ba-termination',
  templateUrl: './article-extra-table-ba-termination.component.html',
  providers: [
    ARTICLE_EXTRA_TABLE_BA_TERMINATION_ACCESSOR,
    ARTICLE_EXTRA_TABLE_BA_TERMINATION_VALIDATOR,
  ]
})
export class ArticleExtraTableBaTerminationComponent implements ControlValueAccessor, Validator {
  @Input() disabled = false;
  current: ArticleExtraBaTerminationTableRow[] = [];
  private errors: { [key: string]: any; } = {};

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  writeValue(obj: any): void {
    if (Helper.undefined(obj)) {
      this.current = [];

      this.add(-1);
    } else {
      this.current = deepCopy(obj);
      if (Helper.isArray(obj)) {
        if (obj.length === 0) {
          this.add(-1);
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(c: AbstractControl): ValidationErrors | any {
    this.errors = {};
    for (let row = 0; row < this.current.length; row++) {
      if (
        (!this.current[row].utilized &&  this.current[row].days_left) ||
        ( this.current[row].utilized && !this.current[row].days_left)
      ) {
        this.errors[row.toString()] = {};
        if (!this.current[row].utilized) {
          this.errors[row.toString()]['utilized'] = true;
        }
        if (!this.current[row].days_left) {
          this.errors[row.toString()]['days_left'] = true;
        }
      }
    }
    let parseError = false;
    Helper.keys(this.errors, (inner) => {
      Helper.keys(inner, (value) => {
        if (value) {
          parseError = true;
        }
      });
    });
    if (parseError) {
      return {'table': 'invalid'};
    } else {
      return null;
    }
  }

  onChange(row: number, name: string, value: any): void {
    let cleaned;
    if (Helper.isInt(value) && Number(value) > 0) {
      cleaned = Helper.toInt(value);
    } else {
      cleaned = null;
    }

    this.current[row][name] = cleaned;

    this._onChange(this.current.filter(r => !(r.days_left === null && r.utilized === null)));
  }

  /** row helper */
  add(row: number): void {
    if (Helper.isLastRow(this.current, row)) {
      const obj = {
        'utilized': null,
        'days_left': null
      };
      this.current.push(obj);
    }
  }

  remove(row: number): void {
    if (Helper.isLastRow(this.current, row) && row !== 0) {
      this.current.splice(row, 1);
    } else if (row === 0) {
      this.current = [];
      this.add(-1);
    }
    this._onChange(this.current.filter(r => !(r.days_left === null && r.utilized === null)));
  }

  isLastRow(row) {
    return Helper.isLastRow(this.current, row);
  }

  firstRowIsClean(): boolean {
    return !this.current[0].days_left && !this.current[0].utilized;
  }

  trackByFn(index: number, item: ArticleExtraBaTerminationTableRow) {
    noop(this, item);
    return index;
  }

  checkError(index: number, name: string): boolean {
    let error = false;
    if (this.errors[index.toString()]) {
      error = !!this.errors[index.toString()][name];
    }
    return error;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    return this.disabled;
  }

}
