<div class="row inventory">
  <div class="col-sm-12">
    <h3>Bestandsverlauf</h3>
    <table class="table whitetable">
      <thead>
      <tr>
        <th>Typ</th>
        <th>Datum</th>
        <th>Benutzer</th>
        <th>Lagerplatz</th>
        <th>Wareneingang</th>
        <th *ngIf="currentArticle.konsi">Konsi</th>
        <th>Charge</th>
        <th class="align-right" *ngIf="('material.order.price.check'|HasPerm)">Einzelpreis</th>
        <th class="align-right">Menge</th>
        <th>Einheit</th>
        <th>Notiz</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let transaction of list.objects">
        <td>{{transactionTypes[transaction.transaction_type]}}</td>
        <td>{{transaction.date_transferred|date:'dd.MM.yyyy HH:mm'}}</td>
        <td>{{transaction.username}}</td>
        <td>{{transaction.location}}</td>
        <td>{{transaction.date_received|date:'dd.MM.yyyy'}}</td>

        <td *ngIf="currentArticle.konsi">
          {{transaction.konsi === true ? 'Ja' : transaction.konsi === false ? 'Nein' : ''}}
        </td>

        <td>{{transaction.charge}}</td>

        <td class="align-right" *ngIf="('material.order.price.check'|HasPerm) && (!!transaction.price)">
          {{transaction.price|money:2}}&nbsp;&euro;
        </td>
        <td class="align-right" *ngIf="('material.order.price.check'|HasPerm) && (!transaction.price)">
        </td>

        <td class="align-right" [ngClass]="quantityColor(transaction.amount)">
          {{quantityOperator(transaction.amount)}}&nbsp;{{quantityString(transaction.amount)}}
        </td>
        <td>{{currentVariation.unit}}</td>
        <td>
          {{transaction.note}}
          <ng-container *ngIf="transaction.bo_id && transaction.bo_index">
            BA-Nr:&nbsp;{{transaction.bo_id}}-{{transaction.bo_index}}
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
</div>
