import * as React from 'react';
import {MaterialGroup} from '../../groups/material-group.model';
import {MaterialProductGroupListElement} from '../material-product-groups.models';
import {useEffect, useState} from 'react';
import {MaterialProductGroupsTabListButton} from './material-product-groups-tab-list-button';
import {ArrayHelper} from '../../../../common/array-helper';

interface Props {
  productGroups: MaterialProductGroupListElement[];
  groups: MaterialGroup[];
  activeProductGroupId: number | null;
  activeGroupIds: number[] | null;
  singleMode?: boolean;
  setProductGroupId?: (productGroupId: number | null) => void;
  setGroupIds?: (groupIds: number[]) => void;
}

export function MaterialProductGroupsTabList(props: Props) {
  const filterGroups: (id?: number) => MaterialGroup[] = (id) => {
    if (id === null) {
      return [...props.groups];
    }

    const productGroup = props.productGroups.find(pg => pg.product_group.id === id);
    if (!productGroup) {
      return [];
    }

    return [...props.groups.filter(mg => !!productGroup.groups?.find(g => g.id === mg.id))];
  };

  // Groups that are displayed in the tab
  const [activeGroups, activeGroupsSet] = useState<MaterialGroup[]>(filterGroups(props.activeProductGroupId));

  // Selected items
  const [selectedProductGroupId, selectedProductGroupIdSet] = useState<number | null>(props.activeProductGroupId);
  const [selectedGroupIds, selectedGroupIdsSet] = useState<number[]>(props.activeGroupIds);

  const setSelectedGroupIds: (groupIds: number[]) => void = (groupIds) => {
    selectedGroupIdsSet(groupIds);

    if (!!props.setGroupIds) {
      props.setGroupIds(groupIds);
    }
  };

  const toggleProductGroupActive: (productGroup?: MaterialProductGroupListElement) => void = (productGroup) => {
    const id = productGroup?.product_group?.id ?? null;
    const filteredGroups = filterGroups(id);
    activeGroupsSet(filteredGroups);
    selectedProductGroupIdSet(id);

    if (!props.singleMode) {
      // Toggles all groups on / off when all elements in a group were off / on prior
      const allSelected = ((selectedProductGroupId === id) && filteredGroups.reduce(
        (all: boolean, mg: MaterialGroup) => all && !!selectedGroupIds.find(mgId => mg.id === mgId), true));
      setSelectedGroupIds((allSelected || id === null) ? [] : filteredGroups.map(mg => mg.id));
    }

    if (!!props.setProductGroupId) {
      props.setProductGroupId(id);
    }
  };

  const toggleGroupActive: (id: number) => void = (id) => {
    if (props.singleMode) {
      setSelectedGroupIds([id]);
    } else if (!selectedGroupIds.find(mgi => mgi === id)) {
      setSelectedGroupIds([...selectedGroupIds, id]);
    } else {
      setSelectedGroupIds(selectedGroupIds.filter(mgi => mgi !== id));
    }
  };

  const btnClass: (productGroup?: MaterialProductGroupListElement) => string = (pg) => {
    return pg?.product_group?.id === selectedProductGroupId ? 'btn btn-sm btn-info' : 'btn btn-sm btn-default';
  };

  useEffect(() => {
    if (props.activeProductGroupId === selectedProductGroupId) {
      return;
    }

    toggleProductGroupActive(props.productGroups.find(pg => pg.product_group.id === props.activeProductGroupId));
  }, [props.activeProductGroupId]);

  useEffect(() => {
    if (ArrayHelper.equals(props.activeGroupIds, selectedGroupIds)) {
      return;
    }

    setSelectedGroupIds(props.activeGroupIds);
  }, [props.activeGroupIds]);

  return <>
    <div>
      <button type={'button'}
              className={btnClass(null)}
              onClick={() => toggleProductGroupActive(null)}>
        Alle
      </button>
      {
        props.productGroups.map(pg =>
          <button key={pg.product_group.id}
                  type={'button'}
                  className={btnClass(pg)}
                  onClick={() => toggleProductGroupActive(pg)}>
            {pg.product_group.name}
          </button>
        )
      }
    </div>

    {
      activeGroups.map(mg =>
        <MaterialProductGroupsTabListButton key={mg.id}
                                            materialGroup={mg}
                                            selectedGroupIds={selectedGroupIds}
                                            click={() => toggleGroupActive(mg.id)}/>
      )
    }
  </>;
}
