import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Customer} from '../model/customer';
import {CustomerService} from '../services/customer.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'customer-header-template',
  templateUrl: './customer-header-template.component.html',
  styleUrls: ['./customer-header.scss'],
})
export class CustomerHeaderTemplateComponent {
  @Input() customer: Customer;
  @Input() showRating = false;
  @Output() ratingChanged = new EventEmitter<number>();

  constructor(private customerService: CustomerService,
              private alertService: AlertService) {
  }

  setRating(rating: number): void {
    this.customerService.setRating(this.customer.id, rating).subscribe(() => {
      this.ratingChanged.emit(rating);
      this.alertService.add('success', 'Rating erfolgreich geändert!');
    }, () => {
      this.alertService.add('danger', 'Rating ändern fehlgeschlagen!');
    });
  }
}
