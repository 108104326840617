import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../common/search/search.component';
import {DunningService} from './dunning.service';
import {Dunning} from './dunning.models';
import {EnvisiaLocation} from '../../common/location/envisia-location';
import {LocalStorage} from '../../common/storage/local-storage';
import {Lister} from '../../common/wrapper.models';
import {AlertService} from '../../common/alert-service/alert.service';
import {MailHeaderData} from '../../envisia/mail/mail.models';
import {DateHelper} from '../../helper/date-helper';
import {MailModalService} from '../mail/mail-modal.service';

@Component({
  selector: 'dunning-list',
  templateUrl: './dunning-list.component.html'
})
export class DunningListComponent extends SearchBaseComponent<DunningService, Dunning> {
  @Input() protected listData: Lister<Dunning>;
  protected type = 'dunning';

  constructor(protected service: DunningService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private alertService: AlertService,
              protected mailModalService: MailModalService) {
    super();
  }


  mail(obj: Dunning, index: number): void {
    if (obj.mail && obj.mail !== '') {
      const header = new MailHeaderData(obj.mail, null, 'buchhaltung-mahnungen@storz-pcb.de');
      this.mailModalService.open(obj.id, 'dunning', header).subscribe(() => {
        this.list.objects[index].date_sent = DateHelper.format(new Date());
      }, (response) => {
        console.log('Error', response);
      });
    } else {
      alert('Kunde hat keine Mahn-Mail hinterlegt, bitte zuerst eine Mahn-Mail hinterlegen!');
    }
  }


  transmit(obj: Dunning, index: number): void {
    if (confirm('Möchten Sie die Mahnung als übermittelt markieren?')) {
      this.service.update(obj.id).subscribe(dunning => {
        this.list.objects[index] = dunning;
        this.alertService.add('success', 'Mahnung erfolgreich als übermittelt übermittelt?');
      }, () => {
        this.alertService.add(
          'danger',
          'Es wurde ein Fehler festgestellt, bitte wenden Sie sich an Ihren Administrator'
        );
      });
    }
  }

}
