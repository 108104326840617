<div class="page-header">
  <h3>Material Artikel Felder</h3>
</div>
<div>
  <button class="btn btn-success" uiSref="a.material.field.create">
    Neues&nbsp;Feld
  </button>
</div>
<div class="row">
  <div class="col-md-8">
    <table class="table whitetable chrissies">
      <thead>
      <tr>
        <th><a (click)="sort('id')">Nr.</a></th>
        <th><a (click)="sort('name')">Bezeichnung</a></th>
        <th><a (click)="sort('variation_type')">Variations Typ</a></th>
        <th><a (click)="sort('data_type')">Daten Typ</a></th>
        <th class="icon">&nbsp;</th>
        <th class="icon">&nbsp;</th>
      </tr>
      </thead>

      <tbody infiniteScroll [infiniteScrollDisabled]="locked">
      <tr *ngFor="let field of fields">
        <td>{{ field.id }}</td>
        <td>{{ field.name }}</td>
        <td>{{ field.is_variation ? "Variation" : "Allgemein" }}</td>
        <td>{{ field.data_type }}</td>
        <td class="icon">
          <a uiSref="a.material.field.update" [uiParams]="{field: field.id}"><i class="fa fa-pencil"></i></a>
        </td>
        <td class="icon">
          <i class="fa fa-trash-o red_alert click" (click)="delete(field)"></i>
        </td>
      </tr>
      </tbody>
    </table>

    <div>
      <div *ngIf="busy"><i class="fa fa-spin fa-spinner"></i> Liste wird geladen</div>
    </div>

  </div>

</div>
