import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {OposListComponent} from './opos-list.component';
import {OposService} from '../services/opos.service';
import {ErpTransition} from '../../../core/erp-transition.service';

export function oposListResolveFn(transition: ErpTransition, service: OposService) {
  return service.list(transition.params()).toPromise();
}

export const OPOS_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.opos_list',
  url: '/opos/list?page&sort&order&date_start&date_end&status&typ&invoice_id&invoice_dunning_level&workflow_id&date&customer_id&customer_name&customer_field_service&invoice_due_date&invoice_revenue&invoice_open&invoice_expensed&invoice_expensed_date',
  views: {'main@a': {component: OposListComponent}},
  params: {
    page: {dynamic: true},
    sort: {dynamic: true},
    order: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    status: {dynamic: true},
    typ: {dynamic: true},
    invoice_id: {dynamic: true},
    invoice_dunning_level: {dynamic: true},
    workflow_id: {dynamic: true},
    date: {dynamic: true},
    customer_id: {dynamic: true},
    customer_name: {dynamic: true},
    customer_field_service: {dynamic: true},
    invoice_due_date: {dynamic: true},
    invoice_revenue: {dynamic: true},
    invoice_open: {dynamic: true},
    invoice_expensed: {dynamic: true},
    invoice_expensed_date: {dynamic: true}
  },
  resolve: [{token: 'listData', resolveFn: oposListResolveFn, deps: [ErpTransition, OposService]}],
  data: {requiresAuth: true}
};
