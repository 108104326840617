import {Component, Input, OnInit} from '@angular/core';
import {
  ShippingInstructionHistory,
  ShippingInstructionSchema,
  ShippingInstructionSchemaDictionary
} from '../../article/models/article-extra.models';
import {ArticleCore} from '../../article/models/article.models';

@Component({
  selector: 'shipping-instruction-history-detail',
  templateUrl: './shipping-instruction-history-detail.component.html'
})
export class ShippingInstructionHistoryDetailComponent implements OnInit {
  @Input() schema: ShippingInstructionSchema[];
  @Input() history: ShippingInstructionHistory;
  @Input() article?: ArticleCore;
  schemaDictionary: ShippingInstructionSchemaDictionary = {};

  constructor() { }

  ngOnInit(): void {
    this.schema.forEach(value => {

      let group = this.schemaDictionary[value.group];
      if (!group) {
        group = {};
      }

      group[value.name] = value;

      this.schemaDictionary[value.group] = group;
    });
  }

}
