import {ArticleMultilayerPartType} from './article-multilayer-plan.models';

export class ArticleMultilayerPartTypeHelper {
  public static types: { [key: string]: ArticleMultilayerPartType } = {
    empty: {type: 0, name: 'empty', label: 'Leer', color_class: 'gray'},
    core: {type: 1, name: 'core', label: 'Kern', color_class: 'yellow'},
    cu_foil: {type: 2, name: 'cu_foil', label: 'Cu-Folie', color_class: 'cu-foil-red'},
    cu_kasch: {type: 3, name: 'cu_kasch', label: 'Cu-Kasch', color_class: 'cu-kasch-red'},
    cu_plating: {type: 4, name: 'cu_plating', label: 'Cu-Plating', color_class: 'cu-plating-red'},
    lacquer: {type: 5, name: 'lacquer', label: 'Lötstoppmaske', color_class: 'lacquer-green'},
    prepreg: {type: 6, name: 'prepreg', label: 'Prepreg', color_class: 'prepreg-orange'},
  };

  public static getName(type: number): string {
    return Object.values(ArticleMultilayerPartTypeHelper.types).find(p => p.type === type)?.name ?? 'empty';
  }

  public static get(type: number): ArticleMultilayerPartType {
    return Object.values(ArticleMultilayerPartTypeHelper.types).find(p => p.type === type);
  }
}
