import {Directive, EventEmitter, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../models/article-specification.model';
import {deepCopy} from '../../../../helper/deep-copy';
import {fillData} from '../helper/specification-helper';
import {ArticleInputMarkerDirective} from './article-input-marker.directive';

@Directive()
export abstract class ArticleSelectAbstractDirective extends ArticleInputMarkerDirective implements OnChanges {
  specification: { [key: string]: ArticleSpecification };
  label: string;
  model: string;
  form: UntypedFormGroup;
  error?: string;
  unit?: string;
  refCount?: number;
  zero = false;
  bold = false;
  changeEmitter = new EventEmitter<string>();
  data: { key: string; value: string; }[] = [];
  showFn?: (key: string, data: any) => boolean;
  viewFn?: (key: string, data: any) => boolean;
  processFn?: (key: string, data: any) => any;
  view = true;
  refCounted = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refCount && !changes.refCount.isFirstChange() && this.refCounted) {
      // this runs debounced after every keystroke, maybe we can find a more optimized version
      // currently I guess this is database dependent so a change might need a change in the fn database version
      const spec = this.zero ? this.specification[this.removeIndex(this.model)] : this.specification[this.model];
      this.initData(spec, false);
    }
  }

  change(): void {
    // FIXME: call process_fn
    if (!!this.processFn) {
      const copy = deepCopy(this.form.value);
      this.processFn(this.model, copy);
      this.form.patchValue(copy, {emitEvent: false});
    }
    this.changeEmitter.next(this.model);
  }

  trackByFn(index: number, item: { key: string; value: string; }) {
    return item.key;
  }

  protected show(key: string): boolean {
    if (!!this.showFn) {
      return this.showFn(key, this.form.value);
    } else {
      return true;
    }
  }

  protected removeIndex(str: string): string {
    const n = str.lastIndexOf('_');
    return str.substring(0, n);
  }

  protected initAll(): void {
    const spec = this.zero ? this.specification[this.removeIndex(this.model)] : this.specification[this.model];

    // initalize fn's:
    if (!!spec.fn) {
      this.showFn = (key: string, value: any) => {
        if (value['execution'] !== null) {
          return new Function('data', 'article', spec.fn)(key, value);
        } else {
          const obj = value;
          obj['execution'] = undefined;
          return new Function('data', 'article', spec.fn)(key, obj);
        }
      };
      this.refCounted = true;
    }

    if (!!spec.process_fn) {
      this.processFn = (key, data) => {
        return new Function('data', 'article', spec.process_fn)(key, data);
      };
    }

    if (!!spec.view_fn) {
      this.viewFn = (key, data) => {
        return new Function('data', 'article', spec.view_fn)(key, data);
      };
      this.refCounted = true;
    }

    this.initData(spec, true);
  }

  protected initData(spec: ArticleSpecification, init: boolean): void {
    if (!!this.viewFn) {
      this.view = this.viewFn('', this.form.value);
    }

    // data will be reinitalized, either when we run it with init
    // or if a showFn function does exist
    if (!!this.showFn || init) {
      this.data = [];
      if (!!spec.values2) {
        this.data = fillData(spec, this.form.value[this.model], (key: string) => this.show(key));
      } else if (!!spec.values) {
        spec.values.forEach(value => {
          if (this.show(value)) {
            this.data.push({key: value, value: value});
          }
        });
      }

      // Append any non-falsy value in the current selection if it is not in the specs
      if (
        !!this.form.value[this.model] &&
        !this.data.find(d => d.key === this.form.value[this.model])
      ) {
        this.data.push({key: '(alter Wert) ' + this.form.value[this.model], value: (this.form.value[this.model])});
      }
    }
  }

}
