import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ListService} from '../../../common/search/search.service';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Contract, ContractForm, ContractListData} from '../models/contract.models';
import {map} from 'rxjs/operators';
import {ContractCounts} from '../contract/models/contract.models';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ContractService implements ListService<ContractListData> {

  constructor(private http: HttpClient) {
  }

  create(workflowId: number, force: boolean, form: ContractForm): Observable<Contract> {
    return this.http.post<EnvisiaObject<Contract>>(
      '/workflow/v1/frame/' + workflowId + '/contract/',
      form,
      {params: EnvisiaLocation.httpParamsWrapper({force: force.toString()})}
    ).pipe(map(val => val.object));
  }

  list(query?: any): Observable<Lister<ContractListData>> {
    return this.http.get<Lister<ContractListData>>(
      `${environment.apiv4uri}contract/list`,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  createList(id: number): Observable<EnvisiaObjects<Contract>> {
    return this.http.get<EnvisiaObjects<Contract>>('/workflow/v1/workflow/' + id + '/contract/');
  }

  counts(id: number): Observable<ContractCounts> {
    return this.http.get<EnvisiaObject<ContractCounts>>(
      '/api/v1/workflow/contract/counts/' + id).pipe(map(value => value.object)
    );
  }

}
