import {ArticleInventoryFormModel} from './article-inventory-form.model';
import {InventoryGroup, StorageBin} from '../inventory.models';
import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import * as React from 'react';
import {ArticleInventoryAxiosService} from './article-inventory-axios.service';
import {NumberHelper} from '../../../../helper/number-helper';
import {ArticleInventoryWorkflowAutosuggest} from './article-inventory-workflow-autosuggest';
import Axios, {AxiosError} from 'axios';
import {ArticleInventoryFormError} from '../form-error/article-inventory-form-error';

interface Props {
  oaNr: string;
  storage: StorageBin[];
  workflowId: number;
  workflowIds: number[];
  selectedInventory?: InventoryGroup;
  selectedUnset: () => void;
  update: () => void;
}

const defaultForm = {
  date_code: null,
  quantity: null,
  note: null,
  storage_bin_name: null,
  storage_bin_number: null,
  workflow_id: null,
};

export function ArticleInventoryForm(props: Props) {
  const [saveBtnLocked, saveBtnLockedSet] = useState<boolean>(false);
  const [errors, errorsSet] = useState<{ [key: string]: string }>({});
  const [form, formSet] = useState<ArticleInventoryFormModel>({
    ...defaultForm,
    storage_bin_name: props.storage[0].name,
    workflow_id: props.workflowId,
  });

  const saveData: (event: FormEvent) => void = async (event) => {
    event.preventDefault();

    if (saveBtnLocked) {
      return;
    }

    saveBtnLockedSet(true);
    try {
      errorsSet({});
      await ArticleInventoryAxiosService.add(props.oaNr, form)
        .then(() => {
          formSet({
            ...defaultForm,
            storage_bin_name: props.storage[0].name,
            workflow_id: props.workflowId,
          });
          props.update();
        }, (error: Error | AxiosError) => {
          if (Axios.isAxiosError(error)) {
            errorsSet(error.response?.data?.errors ?? {});
          } else {
            console.error(error);
          }
        });
    } finally {
      saveBtnLockedSet(false);
    }
  };

  const changeDateCode: (e: ChangeEvent<any>) => void = (e) => {
    const model = {...form, date_code: e.target.value?.toString()};
    formSet(model);
  };

  const changeQuantity: (e: ChangeEvent<any>) => void = (e) => {
    const model = {...form, quantity: NumberHelper.saveParseInteger(e.target.value)};
    formSet(model);
  };

  const changeNote: (e: ChangeEvent<any>) => void = (e) => {
    const model = {...form, note: e.target.value?.toString()};
    formSet(model);
  };

  const changeStorageBinName: (e: ChangeEvent<any>) => void = (e) => {
    const model = {...form, storage_bin_name: e.target.value?.toString()};
    formSet(model);
  };

  const changeStorageBinNumber: (e: ChangeEvent<any>) => void = (e) => {
    const model = {...form, storage_bin_number: NumberHelper.saveParseInteger(e.target.value)};
    formSet(model);
  };

  const changeWorkflowId: (workflowId: number | null) => void = (workflowId) => {
    formSet({...form, workflow_id: workflowId});
  };

  const getInputClassName: (element: string) => string = (element) => {
    return 'form-control' + (!!errors[element] ? ' red_alert' : '');
  };

  useEffect(() => {
    if (!props.selectedInventory) {
      formSet({
        ...defaultForm,
        storage_bin_name: props.storage[0].name,
        workflow_id: props.workflowId,
      });
    } else {
      formSet({
        date_code: props.selectedInventory.dateCode,
        quantity: null,
        note: null,
        storage_bin_name: props.selectedInventory.storageBinName,
        storage_bin_number: props.selectedInventory.storageBinNumber,
        workflow_id: props.selectedInventory.workflowId,
      });
    }
  }, [props.selectedInventory]);

  return <>
    <form className='form' onSubmit={saveData} onReset={() => props.selectedUnset()}>
      <div className='row'>
        <div className='col-sm-4'>
          <label htmlFor='date-code'>Datecode</label>
          <input id='date-code'
                 type='text'
                 className={getInputClassName('date_code')}
                 placeholder={'KW/JJ'}
                 value={form.date_code ?? ''}
                 onChange={changeDateCode}/>
        </div>

        <div className='col-sm-4'>
          <label htmlFor='quantity'>Menge</label>
          <input id='quantity'
                 type='number'
                 className={getInputClassName('quantity')}
                 value={form.quantity?.toString(10) ?? ''}
                 onChange={changeQuantity}/>
        </div>

        <div className='col-sm-4'>
          <label htmlFor='note'>Notiz</label>
          <input id='note'
                 type='text'
                 className={getInputClassName('note')}
                 value={form.note ?? ''}
                 onChange={changeNote}/>
        </div>

      </div>

      <div className='row'>
        <div className='col-sm-4'>
          <label htmlFor='storage_bin_name'>Lager</label>
          <select id='storage_bin_name'
                  className={getInputClassName('storage_bin_name')}
                  value={form.storage_bin_name ?? ''}
                  onChange={changeStorageBinName}>
            {props.storage.map(s => <option key={s.name} value={s.name}>{s.name}</option>)}
          </select>
        </div>

        <div className='col-sm-4'>
          <label htmlFor='storage_bin_number'>Lagerplatz</label>
          <input id='storage_bin_number'
                 type='number'
                 min='0'
                 className={getInputClassName('storage_bin_number')}
                 value={form.storage_bin_number?.toString(10) ?? ''}
                 onChange={changeStorageBinNumber}/>
        </div>

        <div className='col-sm-4'>
          <label htmlFor='workflow_id'>Vorgangs Nr.</label>
          <ArticleInventoryWorkflowAutosuggest id='workflow_id'
                                               className={getInputClassName('workflow_id')}
                                               workflowId={form.workflow_id}
                                               workflowIds={props.workflowIds}
                                               onChange={changeWorkflowId}/>
        </div>

        <div className='row'>
          <div className='col-sm-12'>&nbsp;</div>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-8'>
          <ArticleInventoryFormError errors={errors} />
        </div>
        <div className='col-sm-4 align-right'>
          <button type='reset' className='btn btn-danger' style={{'marginRight': '10px'}}>
            Zurücksetzen
          </button>
          <button type='submit' className='btn btn-success'>
            Hinzufügen
          </button>
        </div>
      </div>
    </form>
  </>;
}
