import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BusinessOrderStepFormObject, BusinessOrderStepView} from '../models/business-order-step.models';
import {EnvisiaObject} from '../../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class BusinessOrderStepService {

  private url = '/api/v1/workflow/business/order/';

  constructor(private http: HttpClient) {
  }

  startStep(step: BusinessOrderStepView): Observable<BusinessOrderStepView> {
    return this.http.put<EnvisiaObject<BusinessOrderStepView>>(
      this.url + step.id + '/' + step.index + '/step/start/' + step.ordering + '/',
      {}
    ).pipe(map(value => value.object));
  }

  stepStop(
    boId: number,
    boIndex: number,
    ordering: number,
    form: BusinessOrderStepFormObject,
  ): Observable<void> {
    return this.http.post<void>(`${environment.apiv4uri}business/order/${boId}/${boIndex}/step/stop/${ordering}`, form);
  }

}
