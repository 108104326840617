import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductionTemplate} from '../../../envisia/article/production/production.models';
import {ProductionTemplateService} from '../../../envisia/article/production-services/production-template.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'production-template-edit',
  templateUrl: './production-template-edit.component.html',
})
export class ProductionTemplateEditComponent implements OnInit {

  errors?: { [key: string]: any; } = {};
  editForm: UntypedFormGroup;

  @Input() data: ProductionTemplate;

  constructor(private productionTemplateService: ProductionTemplateService,
              private fb: UntypedFormBuilder,
              private modalRef: NgbActiveModal,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  edit() {
    this.errors = {};
    this.productionTemplateService.edit(this.data.name, this.editForm.value).subscribe(value => {
      this.alertService.add('success', 'Vorlage erfolgreich editiert!');
      this.modalRef.close(value);
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      this.alertService.add(
        'danger',
        'Es ist ein Fehler aufgerterten, bitte überprüfen Sie die eingegeben Werte und versuchen Sie es erneut!'
      );
    });
  }

  private buildForm() {
    this.editForm = this.fb.group({
      description: [this.data.description],
      expression: [this.data.expression, Validators.required],
      ordering: [this.data.ordering, Validators.required],
    });
  }

}
