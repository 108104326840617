import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../models/article-specification.model';
import {ArticleSelectAbstractDirective} from './article-select-abstract.directive';

@Component({
  selector: 'article-select',
  templateUrl: './article-select.component.html'
})
export class ArticleSelectComponent extends ArticleSelectAbstractDirective implements OnInit, OnChanges {
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() label: string;
  @Input() model: string;
  @Input() form: UntypedFormGroup;
  @Input() error?: string;
  @Input() unit?: string;
  @Input() refCount?: number;
  @Input() zero = false;
  @Input() bold = false;
  @Output() changeEmitter = new EventEmitter<string>();

  ngOnInit(): void {
    this.initAll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
