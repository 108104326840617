import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {
  MaterialOrderIntakeBookedFormValue,
  MaterialOrderIntakeBookedReply
} from './material-order-intake-booked-form.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialOrderIntakeBookedPriceService {

  constructor(private http: HttpClient) {
  }

  setPrice(orderId: number, form: MaterialOrderIntakeBookedFormValue): Observable<MaterialOrderIntakeBookedReply> {
    return this.http.post<MaterialOrderIntakeBookedReply>(
      `${environment.apiv4uri}material/order/${orderId}/price`,
      form
    );
  }
}
