<h3>
  {{ header }}
  <span *ngIf="lastIncrease">| Letzte Preiserhöhung: {{ lastIncrease.percent }} % - {{ lastIncrease.date_created }}</span>
</h3>

<button type="button" class="btn btn-link btn-after-headline" (click)="recalculate()">
  Preise aktualisieren
</button>


<button type="button" class="btn btn-link btn-after-headline" style="margin-left: 20px;"
        (click)="setProducer(null)" *ngIf="typ === 'cn'">
  Produzent festlegen
</button>

<form [ngClass]="{'right-pad20': typ === 'de'}">
  <table class="table whitetable pricechecktable tablemiddle">
    <thead>
      <tr>
        <th class="right">&nbsp;</th>
        <th class="right">Menge</th>
        <th class="right" *ngIf="typ === 'de'">K-Me</th> <!-- DE only-->
        <th class="right">Lose</th>
        <th class="right">Freigabe</th>

        <th class="right" *ngIf="typ === 'cn'">EK $</th> <!-- CN only-->
        <th class="right" *ngIf="typ === 'cn'">&nbsp;</th> <!-- CN only-->

        <th class="right">
          <span *ngIf="typ === 'de'">Einzel €</span>
          <span *ngIf="typ === 'cn'">VK €</span>
        </th>

        <th class="right" *ngIf="typ === 'cn'">Preis %</th> <!-- CN only-->
        <th class="right" *ngIf="typ === 'cn'">GEK €</th> <!-- CN only-->
        <th class="right">
          <span *ngIf="typ === 'de'">Gesamt €</span>
          <span *ngIf="typ === 'cn'">GVK €</span>
        </th>

        <th class="right">Kalk</th>
        <th class="right" *ngIf="typ === 'cn'">Kurs</th> <!-- CN only-->
        <th class="right">Datum</th>
        <th class="right">Notiz</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody *ngIf="formListData">
    <tr *ngFor="let obj of formListData;trackBy:trackByFn; let index = index" [formGroup]="forms[obj.id.toString()]">
      <td [ngClass]="{'greyed-out': !obj.approval}">
        <i class="fa fa-exclamation-triangle" *ngIf="!obj.valid"></i>
      </td>
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}">{{ obj.quanity }}</td>
      <!-- DE only: start -->
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}" *ngIf="typ === 'de'">
        {{ obj.calc_quanity }}
        <span *ngIf="!obj.calc_quanity">{{ obj.quanity }}</span>
      </td>
      <!-- CN only: end -->
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}">{{ obj.lose }}</td>
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}">
        {{obj.release}}
      </td>
      <!-- CN only: start -->
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}" *ngIf="typ === 'cn'">
        <input type="text" class="form-control"
               [ngClass]="{red_alert: hasErrors(obj, 'cost')}"
               formControlName="cost"
               [tabindex]="(index * 3) + 1"
               *ngIf="'price.change'|HasPerm"
               inputSelect>
        <span *ngIf="!('price.change'|HasPerm)">{{ obj.cost }}</span>
      </td>
      <td [ngClass]="{'greyed-out': !obj.approval}" *ngIf="typ === 'cn'">
        <i class="fa fa-truck iconbutton pointer"
           [ngbTooltip]="obj.producer"
           (click)="setProducer(obj)"
           [ngClass]="{'btn-link': obj.producer}">
        </i>
      </td>
      <!-- CN only: end -->
      <td class="right bold" [ngClass]="{'greyed-out': !obj.approval}">{{ obj.single }}</td>

      <!-- CN only: start -->
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}" *ngIf="typ === 'cn'">
        <input type="text"
               class="form-control"
               formControlName="price_reduction"
               [tabindex]="(index * 3) + 2"
               [ngClass]="{'red_alert': hasErrors(obj, 'price_reduction')}"
               inputSelect>
      </td>
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}" *ngIf="typ === 'cn'">
        {{obj.multi_cost}}
      </td>
      <!-- CN only: end -->
      <td class="right" [ngClass]="{'greyed-out': !obj.approval, 'red_alert': obj.is_red && typ === 'cn'}">
        {{ obj.multi }}
      </td>
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}">{{ obj.calc }}</td>
      <!-- CN only: start -->
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}" *ngIf="typ === 'cn'">
        <input type="text"
               class="form-control"
               formControlName="exchange"
               [tabindex]="(index * 3) + 3"
               [ngClass]="{'red_alert': hasErrors(obj, 'exchange')}"
               inputSelect>
      </td>
      <!-- CN only: end -->
      <td class="right" [ngClass]="{'greyed-out': !obj.approval}">{{ obj.date|date: "dd.MM.yyyy" }}</td>

      <td class="right" [ngClass]="{'greyed-out': !obj.approval}">
        <span *ngIf="obj.notes" title="{{obj.notes}}" class="notes-truncated" (click)="editNotes(obj)" style="cursor:pointer;">
          {{obj.notes}}
        </span>
        <span *ngIf="!obj.notes && ('price.change'|HasPerm)">
          <i class="fa fa-1x fa-pencil click" style="color: #999;" (click)="editNotes(obj)"></i>
        </span>
      </td>

      <td class="right middle pricerelease-check" [ngClass]="{'greyed-out': !obj.approval, 'icon': typ === 'de', 'icon-lg': typ === 'cn'}">
        <i class="click fa fa-1x fa-check" *ngIf="!obj.approval && 'price.change'|HasPerm" (click)="doApprove(obj)"></i>
      </td>

      <td [ngClass]="{'greyed-out': !obj.approval}">
        <button type="button" class="btn btn-link" (click)="recalculateSingle(obj, index)"
                *ngIf="canRecalculate(obj)">
          <i class="fa fa-refresh"></i>
        </button>
      </td>
      <td [ngClass]="{'greyed-out': !obj.approval}">
        <button type="button" class="btn btn-link" (click)="setManual(obj, index)" *ngIf="!obj.valid">
          <i class="fa fa-floppy-o"></i>
        </button>
      </td>
      <td [ngClass]="{'greyed-out': !obj.approval}">
        <button type="button" (click)="openHistory(obj)" class="btn btn-link">
          <i class="fa fa-clock-o"></i>
        </button>
      </td>
      <td [ngClass]="{'greyed-out': !obj.approval}">
        <button type="button" (click)="deletePrice(obj)" class="btn btn-link">
          <i class="fa fa-trash-o"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</form>
