import Helper from '../../../helper/helper';
import {FinderSearchService} from '../finder-search.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {FinderSearchItemList} from '../finder.models';
import { Input, Directive } from '@angular/core';

@Directive()
export abstract class FinderSearchBothAbstractComponent {
  @Input() id: string | number;
  @Input() searchType: 'customer' | 'article' | 'workflow';
  q: string | undefined;
  page = 0;
  data: FinderSearchItemList = {hits: [], status: 'error', total_hits: 0};

  protected abstract finderSearchService: FinderSearchService;
  protected abstract alertService: AlertService;

  search(next: number): void {
    const obj = {
      page: next,
      q: !Helper.undefined(this.q) ? this.q : '',
    };
    this.finderSearchService.search(this.searchType, this.id, obj).subscribe(response => {
      if (response.status !== 'error') {
        this.data = response;
        this.page = next;
      } else {
        this.alertService.add('danger', 'Beim Suchen ist ein Fehler aufgetreten!');
      }
    });
  }

  current(): number {
    return Math.floor(this.page / 10) + 1;
  }

  last(): boolean {
    const total = this.data.total_hits || 0;
    const pages = Math.floor(total / 10) + 1;
    const current = this.current();
    return current === pages;
  }

  previous() {
    this.search(this.page - 10);
  }

  next(): void {
    this.search(this.page + 10);
  }
}
