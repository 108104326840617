import {Component, Input} from '@angular/core';
import {DunningInvoiceData} from './dunning.models';
import {DunningService} from '../../envisia/dunning/dunning.service';
import {AlertService} from '../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'dunning-automation',
  templateUrl: './dunning-automation.component.html',
})
export class DunningAutomationComponent {
  @Input() invoices: DunningInvoiceData[];

  constructor(private dunningService: DunningService,
              private alertService: AlertService) {
  }

  reload() {
    this.dunningService.automationList().subscribe(newObjects => {
      this.invoices = newObjects;
      this.invoices.forEach(obj => obj.selected = true);
    }, () => {});
  }

  create() {
    const send = this.invoices.filter(obj => obj.selected).map(obj => obj.id);
    this.dunningService.createJobs(send).subscribe(() => {
      this.reload();
      this.alertService.add(
        'success',
        'Mahnungen werden im Hintergrund erstellt.',
      );
    }, (e: HttpErrorResponse) => {
      if (e.status === 400 && ((e.error as number[]) ?? []).length > 0) {
        this.alertService.add(
          'danger',
          'Die ausgewählten Rechnungen gleichen noch nicht das Guthaben der Kunden aus.',
        );
      } else if (e.status === 404 && ((e.error as number[]) ?? []).length > 0) {
        this.alertService.add(
          'danger',
          'Mindestens eine der Rechnungen konnte nicht gefunden werden. Bitte Liste neu laden.',
        );
      } else {
        this.alertService.add(
          'danger',
          'Es ist ein Fehler aufgetreten. Bitte benachrichtigen Sie Ihren Administrator.',
        );
      }
    });
  }

}
