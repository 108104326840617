import {map} from 'rxjs/operators';
import {MailHeaderData} from '../../../mail/mail.models';
import {ProducerService} from '../../../producer/producer.service';
import {MailModalService} from '../../../mail/mail-modal.service';

export abstract class WorkflowOrderMailerAbstractComponent {

  protected abstract producerService: ProducerService;
  protected abstract mailModalService: MailModalService;

  mail(id: string | number, workflowId: number, producer: string, type: 'order' | 'order_request'): void {
    //
    const mailObs = this.producerService.detail(producer).pipe(
      map(val => new MailHeaderData(type === 'order' ? val.order_mail : val.mail, 'Dear Sir or Madam,'))
    );

    this.mailModalService.open(
      id,
      type,
      mailObs,
      null,
      'en',
      workflowId,
      false,
      type === 'order' ? 'cn' : null
    ).subscribe();
  }

}
