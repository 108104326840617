/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, OnInit} from '@angular/core';
import {BigWorkflowOffer} from '../../models/workflow-create.models';
import {CreateTaskModel} from '../../create-task/create-task.models';

@Component({
  selector: 'offer-contract-create',
  template: '<create-task [data]="workflow"></create-task>'
})
export class OfferContractCreateComponent implements OnInit {
  @Input() data: BigWorkflowOffer;
  workflow: CreateTaskModel;

  ngOnInit(): void {
    this.workflow = {
      workflow: this.data.workflow.object,
      customer: this.data.customer,
      contact: this.data.contact,
      article: this.data.article,
      comments: this.data.comments,
      positions: this.data.workflow.positions,
      lastOffer: this.data.last_offer
    };
  }
}
