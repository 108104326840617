import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({ providedIn: 'root' })
export class MaterialArticleNamesListService {

  constructor(private http: HttpClient) {
  }

  public list(query: any): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiv4uri}material/article/names`,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}
