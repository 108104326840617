import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {DateHelper} from '../../../../helper/date-helper';
import {MaterialArticleListElement} from '../../material-management.model';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {
  MaterialOrder,
  MaterialOrderIntakeGroupElement,
  MaterialOrderPositionTransactionIndices,
  MaterialOrderTransactionForm,
} from '../material-order.model';
import {noop} from '../../../../helper/noop';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialOrderIntakeService} from './material-order-intake.service';
import {MaterialOrderIntakeFormCreateService} from './material-order-intake-form-create.service';
import {MaterialOrderService} from '../material-order.service';
import {MaterialOrderIntakePrintModalService} from './intake-print-modal/material-order-intake-print-modal.service';
import {environment} from '../../../../../environments/environment';
import {MaterialGroup} from '../../groups/material-group.model';

@Component({
  selector: 'material-order-intake',
  templateUrl: './material-order-intake.component.html'
})
export class MaterialOrderIntakeComponent implements OnInit {

  @Input() order: MaterialOrder;
  @Input() listData: Lister<MaterialOrderIntakeGroupElement>;
  @Input() listBookedData: Lister<MaterialOrderIntakeGroupElement>;
  @Input() groups: Lister<MaterialGroup>;
  @Input() markedVariationId?: number;
  @Input() markedPositionId?: number;
  @Input() markedTransactionId?: number;

  currentArticle: MaterialArticleListElement;

  finderSupplierUri: string;

  form: UntypedFormGroup;

  errors?: { [key: string]: any; } = {};
  lockSubmission = false;

  constructor(private service: MaterialOrderIntakeService,
              private mos: MaterialOrderService,
              private formService: MaterialOrderIntakeFormCreateService,
              private alertService: AlertService,
              private moipms: MaterialOrderIntakePrintModalService) {
  }

  ngOnInit(): void {
    this.form = this.formService.initForm(this.order.id, this.listData.objects);
    const name = this.order?.supplier?.name?.split(' ')[0];
    this.finderSupplierUri = `${environment.finderUri}Search?q=${(this.order?.id ?? '')} ${(name ?? '')}`;
  }

  addTransaction(position: MaterialOrderPositionTransactionIndices): void {
    const transactions = this.getFormArray(position);
    position.t_index = transactions.value[(transactions.length - 1)].t_index + 1;
    const orderForm = this.formService.transactionForm(position);
    transactions.push(orderForm);
  }

  removeTransaction(position: MaterialOrderPositionTransactionIndices): void {
    const transactions = this.getFormArray(position);
    const index = transactions.value.findIndex(v => v.t_index === position.t_index);
    if (index >= 0) {
      transactions.removeAt(index);
    }
  }

  private getFormArray(position: MaterialOrderPositionTransactionIndices): UntypedFormArray {
    return this.form.get(['groups', position.g_index, 'positions', position.p_index, 'transactions']) as UntypedFormArray;
  }

  submit(): void {
    const transactions: MaterialOrderTransactionForm[] = [];
    this.form.value.groups.forEach(group => {
      group.positions.forEach(position => {
        position.transactions.forEach((transaction: MaterialOrderTransactionForm) => {
          if (transaction.amount !== null || (transaction.location !== null && transaction.location !== '')) {
            transactions.push(transaction);
          }
        });
      });
    });

    const form = {
      order_id: this.form.value.order_id,
      date_received: this.form.value.date_received,
      transactions: transactions,
      note: this.form.value.note
    };

    this.lockSubmission = true;
    this.errors = {};
    this.service.orderTransaction(form).subscribe(m => {
      if (m.length > 0) {
        this.moipms.open(this.order.id, m[0].index);
      }
      this.updateView();
      this.alertService.add(
        'success',
        'Wareneinbuchung wurde vorgenommen'
      );
      this.lockSubmission = false;
    }, response => {
      switch (response.status) {
        case 400:
          this.errors = response.error?.errors ?? {};
          this.alertService.add('danger', 'Bitte überprüfen Sie die Markierten Felder');
          break;
        case 404:
          this.errors['obj.variation_id'] = [{msg: ['error.variation.not.found'], args: []}];
          break;
        default:
          this.alertService.add('danger', 'Unbekannter Fehler beim übertragen.');
      }

      this.lockSubmission = false;
    });
  }

  trackByFn(index: number, item: UntypedFormGroup): number {
    noop(this);
    return index;
  }

  dateParser(date: string | Date | null | undefined): Date | null {
    noop(this);
    if (date instanceof Date) {
      return date;
    }
    if (!!date) {
      return DateHelper.parseISO8601(date);
    }
    return null;
  }

  updateView(): void {
    this.listData = {objects: [], page: 0, count: 0, pages: 0, size: 0};
    this.mos.get(this.order.id).subscribe(order => {
      this.order = order;
      this.service.list({order_id: this.order.id, split_booked: false}).subscribe(list => {
        this.formService.resetForm(this.form, list.objects);
        this.listData = list;
      });
      this.service.list({order_id: this.order.id, split_booked: true}).subscribe(list => {
        this.listBookedData = list;
      });
    });
  }
}
