import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {MailModalComponent} from './mail-modal.component';

@NgModule({
    imports: [
        SharedModule,
        NgbModalModule,
    ],
    declarations: [MailModalComponent]
})
export class MailModule {
}
