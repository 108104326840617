import {Component, Input, OnInit} from '@angular/core';
import {
  MultilayerPlanHolderAbstractComponentDirective
} from './multilayer-plan-holder-abstract-component.directive';
import {ArticleSpecification} from '../../envisia/article/models/article-specification.model';
import {
  ArticleMultilayerPlan,
} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.models';
import {Lister} from '../../common/wrapper.models';

@Component({
  selector: 'multilayer-plan-update-holder',
  templateUrl: './multilayer-plan-update-holder.component.html',
})
export class MultilayerPlanUpdateHolderComponent extends MultilayerPlanHolderAbstractComponentDirective implements OnInit {
  @Input() multilayerPlan: ArticleMultilayerPlan;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() list: Lister<ArticleMultilayerPlan>;

  constructor() {
    super();
  }

  ngOnInit() {
    this.model = this.multilayerPlan.data;
  }

  modelSaved(multilayerPlan: ArticleMultilayerPlan) {
    this.multilayerPlan = multilayerPlan;
  }
}
