import {Component, ErrorHandler, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaterialSupplier} from '../../../../../lazy/material-supplier/material-supplier.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialOrderArticleListElement, MaterialOrderArticleListGroup} from '../../material-order.model';
import {noop} from '../../../../../helper/noop';
import {Lister} from '../../../../../common/wrapper.models';
import {MaterialValueType} from '../../../material-management.model';
import {SearchBaseComponent} from '../../../../../common/search/search.component';
import {MaterialOrderCreateService} from '../material-order-create.service';
import {EnvisiaLocation} from '../../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../../common/storage/local-storage';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';
import {MaterialManagementService} from '../../../material-management.service';
import {deepCopy} from '../../../../../helper/deep-copy';
import Helper from '../../../../../helper/helper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MaterialGroup} from '../../../groups/material-group.model';

@Component({
  selector: 'material-order-create-add-modal',
  templateUrl: './material-order-create-list.component.html'
})
export class MaterialOrderCreateListComponent
  extends SearchBaseComponent<MaterialOrderCreateService, MaterialOrderArticleListGroup>
  implements OnInit {

  @Input() currentSupplier: MaterialSupplier;
  @Input() currentArticleIds: number[];
  @Input() currentGroupId: number;
  @Input() groups: MaterialGroup[];
  @Output() articleEmitter = new EventEmitter<MaterialOrderArticleListGroup>();

  listData: Lister<MaterialOrderArticleListGroup>;
  type = 'material_order_create_list';
  data: { [key: string]: any } = {
    sort: 'variation_id',
    order: 'ASC',
  };

  materialFieldData: LoadingWrapper<MaterialValueType[]>;

  constructor(protected service: MaterialOrderCreateService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private mms: MaterialManagementService,
              private modalRef: NgbActiveModal) {
    super();
  }

  ngOnInit(): void {
    this.data.group_id = this.currentGroupId;
    this.data.supplier_id = this.currentSupplier.id;
    this.loadFields();
    this.search();
  }

  search(keep?: boolean): void {
    if (this.currentGroupId !== this.data.group_id) {
      this.loadFields().subscribe(data => {
        this.data = data;
        this.preServiceCall(data, keep);
      });
    } else {
      this.preServiceCall(deepCopy(this.data), keep);
    }
  }

  preServiceCall(data: { [key: string]: any }, keep?: boolean) {
    // Merge with old query params
    const query = Helper.assign(this.query(), data);

    // Clean empty query params
    Helper.keys(query, (obj, key) => {
      if (!Helper.nonEmpty(query[key])) {
        query[key] = undefined;
        delete query[key];
      }
    });

    if (!keep) {
      query.page = 1;
    }

    this.serviceCall(query);
  }

  triStateChange(field: MaterialValueType): void {
    if (this.data[field.html_name] === undefined || this.data[field.html_name] === null) {
      this.data[field.html_name] = 'true';
    } else if (this.data[field.html_name] === 'true') {
      this.data[field.html_name] = 'false';
    } else if (this.data[field.html_name] === 'false') {
      this.data[field.html_name] = null;
    }
    this.search();
  }

  private loadFields(): Observable<{ [key: string]: any }> {
    const data = deepCopy(this.data);
    this.currentGroupId = data.group_id;
    this.materialFieldData = new LoadingWrapper<MaterialValueType[]>(
      this.mms.listFormFieldClasses(data.group_id)
    );

    // Remove all dynamic search fields from data form
    Helper.keys(data, (obj, key) => {
      if (key.toString().startsWith('field_')) {
        data[key] = null;
      }
    });
    return this.materialFieldData.data$.pipe(map(() => data, () => data));
  }

  addArticle(mig: MaterialOrderArticleListGroup): void {
    this.articleEmitter.emit(mig);
    this.currentArticleIds.push(mig.article_list[0].id);
  }

  close(): void {
    this.modalRef.dismiss();
  }

  trackByFn(index: number, item: MaterialOrderArticleListElement): number {
    noop(this);
    return index;
  }
}
