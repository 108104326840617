import {Component, Input, Output, EventEmitter} from '@angular/core';
import {
  MaterialArticle,
  MaterialUnit,
  MaterialValueType,
  MaterialVariation,
  MaterialVariationError
} from '../material-management.model';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {noop} from '../../../helper/noop';

@Component({
  selector: 'material-variation-table',
  templateUrl: './material-variation-table.component.html'
})
export class MaterialVariationTableComponent {
  @Input() variationFieldDefs: MaterialValueType[];
  @Input() variations: MaterialVariation[];
  @Input() currentArticle: MaterialArticle | null;
  @Input() originalVariation: MaterialVariation | null;
  @Input() errors: MaterialVariationError[];
  @Input() units: MaterialUnit[];
  @Input() variationRows: UntypedFormArray;
  @Output() addVariation = new EventEmitter<void>();
  @Output() removeVariation = new EventEmitter<number>();

  constructor() {
  }

  addVariationRow(): void {
    this.addVariation.emit();
  }

  deleteRow(index: number): void {
    this.removeVariation.emit(index);
  }

  trackByFn(
    index: number,
    item: { form: UntypedFormGroup, variation?: MaterialVariation, error: MaterialVariationError, isRowErrorType: boolean }
  ): number {
    noop(this);
    return ((item.variation?.id ?? -1) * 100000) + index;
  }

  get variationArray(): { form: UntypedFormGroup, variation?: MaterialVariation, error: MaterialVariationError, isRowErrorType: boolean }[] {
    return this.variationRows.controls.map(f => {
      const error = this.errors?.find((e) => e.index === f.value.index);
      return {
        form: f as UntypedFormGroup,
        variation: this.variations.find(v => v.id === f.value.id),
        error: error,
        isRowErrorType: this.isRowErrorType(error),
      };
    });
  }

  isRowErrorType(error: MaterialVariationError): boolean {
    if (error) {
      switch (error.error_type) {
        case 'VariationNotFoundError':
          return true;
        case 'VariationDuplicatesError':
          return true;
        case 'VariationExistsError':
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
}
