import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'business-order-gold-date-error',
  templateUrl: './business-order-gold-date-error.component.html'
})
export class BusinessOrderGoldDateErrorComponent {

  constructor(private activeModal: NgbActiveModal) {
  }

  close(): void {
    this.activeModal.close(null);
  }

}
