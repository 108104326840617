import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'JoinStringPipe'
})
export class JoinStringPipe implements PipeTransform {

  transform(value: any[], delimiter: string = ', '): string {
    return value.map(v => v.toString()).join(delimiter);
  }

}
