import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  BigFetchWithPosition,
  FetchExtraCreateForm,
  FetchListData,
  FetchPosition,
  FetchPositionUpdateForm,
  ProductionFetchView
} from '../models/fetch.models';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {ListService} from '../../../common/search/search.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class FetchService implements ListService<FetchListData> {

  private uri = '/workflow/v1/frame/';

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<FetchListData>> {
    return this.http.get<Lister<FetchListData>>(
      '/workflow/v1/fetch/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  productionFetches(workflowId: number, frameId: number, gold: boolean): Observable<ProductionFetchView[]> {
    return this.http.get<EnvisiaObjects<ProductionFetchView>>(
      this.uri + frameId + '/fetch/production/',
      {params: EnvisiaLocation.httpParamsWrapper({workflow_id: workflowId, gold: gold.toString()})}
    ).pipe(map(value => value.objects));
  }

  boFetches(id: number, index: number, workflowId: number, gold: boolean): Observable<ProductionFetchView[]> {
    return this.http.get<EnvisiaObjects<ProductionFetchView>>(
      `/workflow/v1/business/order/${id}/${index}/fetch/`,
      {params: EnvisiaLocation.httpParamsWrapper({workflow_id: workflowId, gold: gold.toString()})}
    ).pipe(map(value => value.objects));
  }

  create(frameId: number, form: { delivery?: string; quanity?: number }): Observable<BigFetchWithPosition> {
    return this.http.post<EnvisiaObject<BigFetchWithPosition>>(
      '/workflow/v1/frame/' + frameId + '/fetch/',
      form
    ).pipe(map(val => val.object));
  }

  append(frameId: number, form: FetchExtraCreateForm): Observable<BigFetchWithPosition> {
    return this.http.post<EnvisiaObject<BigFetchWithPosition>>(
      '/workflow/v1/fetch/append/' + frameId + '/',
      form
    ).pipe(map(val => val.object));
  }

  update(fetchPositionId: number,
         form: FetchPositionUpdateForm): Observable<EnvisiaObject<FetchPosition> | EnvisiaObjects<FetchPosition>> {
    return this.http.put<EnvisiaObject<FetchPosition> | EnvisiaObjects<FetchPosition>>(
      '/workflow/v1/fetch/position/' + fetchPositionId + '/',
      form
    );
  }

  updatePercent(fetchPositionId: number): Observable<EnvisiaObject<FetchPosition>> {
    return this.http.put<EnvisiaObject<FetchPosition>>(
      '/workflow/v1/fetch/position/percent/' + fetchPositionId + '/',
      null
    );
  }

  remove(fetchPositionId: number): Observable<void> {
    return this.http.delete<void>('/workflow/v1/fetch/' + fetchPositionId + '/');
  }

}
