<div class="modal-header">
  <h3 class="modal-title">Datum für Auftragsende auswählen</h3>
</div>

<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="weekScrollDate()">
    <table class="table">
      <tr>
        <td>
          Artikel SDS Nr.: <strong>{{article.sds_nr}} ({{article.oa_nr}})</strong>
        </td>
        <td style="width: 200px;">
          Zeit für Standardproduktion:
        </td>
        <td style="width: 50px;" class="align-right">
          <strong>{{dateHolder.work_days_standard|money}}&nbsp;AT</strong>
        </td>
        <td style="width: 80px;">
          &nbsp;für Fertigung,
        </td>
        <td style="width: 50px;" class="align-right">
          <strong>{{dateHolder.prep_days_standard|money}}&nbsp;AT</strong>
        </td>
        <td style="width: 80px;">
          &nbsp;für Versand
        </td>
        <td rowspan="2">
          <button class="btn btn-default float-right" (click)="weekScrollDate()">Zu Datum springen</button>
          <div style="width: 110px;" class="float-right">
            <ev-datepicker class="form-control" formControlName="date" [future]="true">
            </ev-datepicker>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          Zu produzierende Anzahl Nutzen: <strong>{{dateHolder.utilize_calc.utilize_standard|money}}</strong>
        </td>
        <td style="width: 200px;">
          Zeit durch Auswahl Liefertermin:
        </td>
        <td style="width: 50px;" class="align-right">
          <strong [ngClass]="{'red_alert': dateHolder.work_days_standard > totalWorkDaysFromStart}">
            {{totalWorkDaysFromStart|money}}&nbsp;AT
          </strong>
        </td>
        <td style="width: 80px;">
          &nbsp;für Fertigung,
        </td>
        <td style="width: 50px;" class="align-right">
          <strong [ngClass]="{'red_alert': dateHolder.prep_days_standard > totalWorkDaysFromLastWorkday}">
            {{totalWorkDaysFromLastWorkday|money}}&nbsp;AT
          </strong>
        </td>
        <td style="width: 80px;">
          &nbsp;für Versand
        </td>
      </tr>

      <tr *ngIf="dateHolder.work_days_standard > totalWorkDaysFromStart">
        <td></td>
        <td colspan="5">
          <div class="alert alert-danger">
            Achtung: <strong>Zu wenig Zeit.</strong> Liefertermin kann eventuell nicht eingehalten werden
          </div>
        </td>
        <td></td>
      </tr>
    </table>
  </form>

  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <i class="fa fa-arrow-circle-left fa-2x ev-blue-color click" (click)="weekScrollPage(-1)"></i>
      </th>
      <th class="align-right" [colSpan]="pageElements * 7">
        <i class="fa fa-arrow-circle-right fa-2x ev-blue-color click" (click)="weekScrollPage(1)"></i>
      </th>
    </tr>
    <tr>
      <th>Bezeichnung</th>
      <!-- Monats/Jahres label -->
      <ng-container *ngFor="let day of visibleDays; trackBy: trackByDay; let i=index">
        <th [colSpan]="day.days_left"
            [ngClass]="{'week-border-left': i%7 === 0, 'month-border-left': day.date.getDate() === 1}"
            *ngIf="(i === 0) || (day.date.getDate() === 1)">
          {{day.monthName}} {{day.date.getFullYear()}}
        </th>
      </ng-container>
    </tr>
    <tr>
      <th></th>
      <!-- KW Label -->
      <th class="week-border-left" *ngFor="let week of visibleWeeks; trackBy: trackByWeek; let i = index" colspan="7">
        <strong [title]="'' + (firstVisibleIndex + i) + ' Wochen ab dieser Woche'">
          KW&nbsp;{{week.date_code}}
        </strong>
      </th>
    </tr>
    <tr>
      <td></td>
      <!-- Tages Label -->
      <td class="align-right"
          [ngClass]="day.classes"
          (click)="markSelectedDays(day)"
          *ngFor="let day of visibleDays; trackBy: trackByDay; let i=index">
        <span [title]="day.date|date:'dd.MM.yyyy'">{{day.date.getDate()}}</span>
      </td>
    </tr>
    </thead>
    <tbody>
    <tr [material-planning-date-check-modal-row]="materialHolder"
        [material-planning-date-check-modal-row-weeks]="visibleWeeks"
        [material-planning-date-check-modal-row-date-start]="dateStart"
        [material-planning-date-check-modal-row-holidays]="holidays"
        [material-planning-date-check-modal-row-utilize-calc]="dateHolder.utilize_calc"
        *ngFor="let materialHolder of dateHolder.planning; trackBy: trackByMaterial; let i = index">
    </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col-lg-3">
      <table class="table whitetable">
        <thead>
        <tr>
          <th colspan="2">Legende:</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <div title="Es wird mehr Material benötigt, als zur Verfügung steht">
              Material nicht ausreichend
            </div>
          </td>
          <td class="dark-red icon">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <div title="Es steht genügend Material dieser Sorte zur Verfügung, um alle Betriebsaufträge zu erfüllen">
              Material steht zur Verfügung
            </div>
          </td>
          <td class="dark-green icon">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <div title="Zeit, die benötigt wird, um das Material zu beschaffen.">
              Material Beschaffungszeit
            </div>
          </td>
          <td class="dark-gray icon">&nbsp;</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="dismiss()">Abbrechen</button>
  <button type="button" class="btn btn-success pull-right" (click)="save()">Speichern</button>
</div>
