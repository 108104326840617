import {Component, Input, OnInit} from '@angular/core';
import {FinderSearchItemData} from '../finder.models';
import {DateHelper} from '../../../helper/date-helper';
import Helper from '../../../helper/helper';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

function isValidDate(d) {
  return d instanceof Date && !isNaN(d as any);
}

@Component({
  selector: 'finder-item-content',
  templateUrl: './finder-item-content.component.html',
  styleUrls: ['./finder-item-content.component.scss']
})
export class FinderItemContentComponent implements OnInit {
  @Input() item: FinderSearchItemData;
  date: string | undefined;
  content: SafeHtml | undefined;
  additionals: { key: string; value: string; }[] = [];

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.item.content) {
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.item.content);
    }
    if (this.item.archive_date) {
      const easyDate = new Date(this.item.archive_date);
      // compat for v1 (from v0)
      const dateInstance = isValidDate(easyDate) ? easyDate : DateHelper.parseISO8601(this.item.archive_date);
      this.date = DateHelper.format(dateInstance);
    }
    Helper.keys(this.item.additionals, (value, key) => {
      this.additionals.push({key: key, value: value});
    });
    Helper.keys(this.item.multiple_values_additionals, (values, key) => {
      values.forEach(subValue => {
        if (!this.additionals.find(o => o.key === key && o.value === subValue)) {
          this.additionals.push({key: key, value: subValue});
        }
      });
    });
  }

}
