import {Component, Input, OnInit} from '@angular/core';
import {ArticleSpecification} from '../../../envisia/article/models/article-specification.model';
import {ArticleSpecificationService} from '../../../envisia/article/services/article-specification.service';
import {Comment} from '../../../envisia/comment/comment.models';
import {CommentService} from '../../../envisia/comment/comment.service';
import {RightService} from '../../../common/right-service/right.service';

@Component({
  selector: 'article-release',
  templateUrl: './article-release.component.html',
})
export class ArticleReleaseComponent implements OnInit {

  @Input() specs: ArticleSpecification[];
  comments: Comment[];

  constructor(private service: ArticleSpecificationService,
              private commentService: CommentService,
              private rightService: RightService) {
  }

  ngOnInit(): void {
    this.reloadComments();
    // console.log(this.specs);
  }

  private reloadComments(): void {
      this.commentService.listAllByType('article_spec').subscribe( res => {
      this.comments = res; } , () => {});
  }

  toggleProd(name: string) {
    if (this.rightService.has('article.mask')) {
      const index = this.specs.findIndex(x => x.name === name);
      const active = !this.specs[index].production_release;
      this.service.setProdRelease(name, active).subscribe( value => {
        this.specs[index] = value;
        this.reloadComments();
      }, e => {
        console.log(e);
      });
    } else {

    }
  }

  togglePrice(name: string) {
    if (this.rightService.has('article.mask')) {
      const index = this.specs.findIndex(x => x.name === name);
      const active = !this.specs[index].price_release;
      this.service.setPriceRelease(name, active).subscribe( value => {
        this.specs[index] = value;
        this.reloadComments();
      }, e => {
        console.log(e);
      });
    } else {}
  }

}
