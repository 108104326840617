import {Transition} from '@uirouter/angular';
import {FrameService} from '../../workflow-services/frame.service';
import {InventoryService} from '../../../article/inventory/inventory.service';
import {ArticleExtraService} from '../../../article/services/article-extra.service';
import {ProductionArticleService} from '../../../article/production/production-article.service';
import {BusinessOrderService} from '../services/business-order.service';
import {catchError, concatMap, map} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {WorkflowService} from '../../workflow-services/workflow.service';
import {ShippingInstructionService} from '../../../article/services/shipping-instruction.service';
import {ArticleCore} from '../../../article/models/article.models';
import {ArticleMultilayerPlanService} from '../../../article/main/multilayer-plan/article-multilayer-plan.service';
import {CustomerCreditWorthinessService} from '../../../customer/credit-worthiness/customer-credit-worthiness.service';

export function workflowIdResolveFn(transition: Transition) {
  return transition.params().id;
}

export function baFullDataResolveFn(trans: Transition,
                                    service: FrameService,
                                    inventoryService: InventoryService,
                                    articleExtraService: ArticleExtraService,
                                    businessOrderService: BusinessOrderService,
                                    productionArticleService: ProductionArticleService,
                                    workflowService: WorkflowService,
                                    shippingInstructionService: ShippingInstructionService,
                                    multilayerPlanService: ArticleMultilayerPlanService,
                                    customerCreditWorthinessService: CustomerCreditWorthinessService) {
  return workflowService.detail(trans.params().id).pipe(
    concatMap(workflow => {
      // call all observables that needed the frame as a base
      return forkJoin({
        shippingInstruction: shippingInstructionService.get<ArticleCore>('article', workflow.article.oa_nr),
        inventorySum: inventoryService.inventorySum(workflow.article.oa_nr, workflow.object.id),
        businessOrderList: businessOrderService.listByArticle(workflow.article.oa_nr, 'active'),
        businessOrderCount: businessOrderService.countAllByArticle(workflow.article.oa_nr),
        additionalWorkflowData: service.additional(workflow.object.id),
        businessOrderSum: businessOrderService.sumByArticle(workflow.article.oa_nr),
        articleExtraStartNumber: articleExtraService.startNumber(),
        frame: service.get(workflow.object.id).pipe(catchError(_ => of(null))),
        validArticleHash: productionArticleService.checkHash(workflow.article.oa_nr),
        multilayerPlan: multilayerPlanService.get(workflow.article.oa_nr),
        customerCredit: customerCreditWorthinessService.get(workflow.object.customer_id),
      }).pipe(map(sources => {
        return {
          frame: sources.frame,
          article: sources.shippingInstruction.object,
          inventory: sources.inventorySum,
          extra: sources.shippingInstruction.data,
          business_order: sources.businessOrderList,
          business_order_count: sources.businessOrderCount,
          additional: sources.additionalWorkflowData,
          sum: sources.businessOrderSum,
          startNumber: sources.articleExtraStartNumber,
          validArticle: sources.validArticleHash,
          multilayerPlan: sources.multilayerPlan,
          customerCredit: sources.customerCredit,
        };
      }));
    })
  ).toPromise();
}

export const businessOrderCreateResolve = [
  {token: 'workflow_id', resolveFn: workflowIdResolveFn, deps: [Transition]},
  {
    token: 'fullData',
    resolveFn: baFullDataResolveFn,
    deps: [
      Transition,
      FrameService,
      InventoryService,
      ArticleExtraService,
      BusinessOrderService,
      ProductionArticleService,
      WorkflowService,
      ShippingInstructionService,
      ArticleMultilayerPlanService,
      CustomerCreditWorthinessService,
    ],
  },
];
