import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ArticleService} from '../../services/article.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'article-recover-modal',
  templateUrl: './article-recover.modal.component.html'
})
export class ArticleRecoverModalComponent implements OnInit {
  @Input() oa: string;
  @Input() notices: string[];
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};

  constructor(private modalRef: NgbActiveModal,
              private articleService: ArticleService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'reason': ['Ext. Änderung'],
      'reference': [null, Validators.required]
    });
  }

  submit(): void {
    this.articleService.recover(this.oa, this.form.value).subscribe(article => {
      this.alertService.add('success', 'Artikel wiederherstellen erfolgreich!');
      this.modalRef.close(article);
    }, (response: HttpErrorResponse) => {
      if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Artikel Wiederherstellung ist fehlgeschlagen!');
    });

  }
}
