import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ArticleCalculatorService {

  public static executionExtractor(model: string, modelValue: any): number | undefined | null {
    let value = 1;
    if (!!model && model === 'execution') {
      const data = {ML4: 1, ML6: 2, ML8: 3, ML10: 4, ML12: 5, ML14: 6, ML16: 7};
      const number = data[modelValue];
      value = number ? number : 0;
    } else if (!model || (!!model && model === 'mixed_structure') || (!!model && model === 'microvias_layers')) {
      const n = parseInt(modelValue, 10);
      const max = model === 'mixed_structure' ? 9 : 4;
      value = n > 1 && n <= max ? n : 1;
    }
    return value;
  }

  public utilize(bs1, bs2, us1, us2, u1, u2, manual) {
      const board_size1 = this.sanitizeFloat(bs1);
      const board_size2 = this.sanitizeFloat(bs2);
      const utilize_size1 = this.sanitizeFloat(us1);
      const utilize_size2 = this.sanitizeFloat(us2);
      const temp_utilize2 = this.sanitizeInt(u2);
      // Outer Utilize Distance
      const outer_distance = 8;
      // Inner Utilize Distance
      const inner_distance = 8;
      // Outer Board Distance
      const oboard_distance = 4;
      // Inner Board Distance
      const iboard_distance = 1;

      // Production Size
      const p_size1 = 582.5;
      const p_size2 = 502.8;

      const utilize1 = u1;
      let utilize2 = 0;

      if (utilize_size1 > 0 && utilize_size2 > 0) {
        const q1 = this.calc(p_size1, p_size2, utilize_size1, utilize_size2, outer_distance, inner_distance);
        const q2 = this.calc(p_size1, p_size2, utilize_size2, utilize_size1, outer_distance, inner_distance);

        utilize2 = q1 > q2 ? q1 : q2;
      } else {
        const q1 = this.calc(p_size1, p_size2, board_size1, board_size2, oboard_distance, iboard_distance);
        const q2 = this.calc(p_size1, p_size2, board_size2, board_size1, oboard_distance, iboard_distance);

        utilize2 = q1 > q2 ? q1 : q2;
      }

      let utilize_sum;
      if (utilize1 > 0 && !manual) {
        utilize_sum = utilize1 * utilize2;
      } else if (utilize1 > 0 && manual) {
        utilize_sum = utilize1 * temp_utilize2;
      } else {
        utilize_sum = utilize2;
      }

      return {
        'utilize2': (utilize2 > 0 && !manual) ? utilize2 : temp_utilize2,
        'utilize_sum': utilize_sum
      };
    }

  public surface(us1: string | number, us2: string | number, bs1: string | number, bs2: string | number, u1: string | number): string {
      const board_size1 = this.sanitizeFloat(bs1);
      const board_size2 = this.sanitizeFloat(bs2);
      const utilize_size1 = this.sanitizeFloat(us1);
      const utilize_size2 = this.sanitizeFloat(us2);
      const newUtilize = this.sanitizeInt(u1);

      let newSurface = board_size1 * board_size2;

      if (utilize_size1 > 0 && utilize_size2 > 0 && newUtilize > 0) {
        newSurface = (utilize_size1 * utilize_size2) / newUtilize;
      }
      return (Math.round(newSurface * 100) / 1000000)
        .toFixed(2)
        .replace('.', ',');
    }

  public aspect(w1: string | number, w2: string | number): string {
    const endintensity = this.sanitizeFloat(w1);
    const drilling = this.sanitizeFloat(w2);

    const data = endintensity / drilling;

    return isFinite(data) ? ((Math.round(data * 10) / 10)
      .toFixed(1)
      .replace('.', ',')) : '';
  }

  public sanitizeFloat(str: string | number): number {
    if (typeof str === 'string') {
      return parseFloat(str.replace(',', '.'));
    } else {
      return str;
    }
  }

  public sanitizeInt(str: string | number): number {
    if (typeof str === 'number') {
      return str;
    }
    return parseInt(str, 10);
  }

  private calc(big1, big2, small1, small2, outer_distance, inner_distance) {
    let quanity_high = parseFloat(Math.floor(big1 / (small1 + inner_distance)).toFixed(0));
    let quanity_low = parseFloat(Math.floor(big2 / (small2 + inner_distance)).toFixed(0));

    let high_rest = big1 - ((small1 * quanity_high) + ((inner_distance * (quanity_high - 1)) + (2 * outer_distance)));
    let low_rest = big2 - ((small2 * quanity_low) + ((inner_distance * (quanity_low - 1)) + (2 * outer_distance)));

    if (high_rest < 0) {
      quanity_high = quanity_high - 1;
    }

    if (low_rest < 0) {
      quanity_low = quanity_low - 1;
    }

    const quanity = quanity_high * quanity_low;

    high_rest = big1 - ((small1 * quanity_high) + ((inner_distance * (quanity_high - 1)) + (2 * outer_distance)));
    low_rest = big2 - ((small2 * quanity_low) + ((inner_distance * (quanity_low - 1)) + (2 * outer_distance)));

    if (high_rest > small2 || low_rest > small1) {
      const f1 = parseFloat(Math.floor(high_rest / small2).toFixed(0));
      const f2 = parseFloat(Math.floor(big2 / small1).toFixed(0));
      const rest1 = f1 * f2;
      const f3 = parseFloat(Math.floor(big1 / small2).toFixed(0));
      const f4 = parseFloat(Math.floor(low_rest / small1).toFixed(0));
      const rest2 = f3 * f4;
      return rest1 > rest2 ? quanity + rest1 : quanity + rest2;
    } else {
      return quanity;
    }
  }

}
