import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AlertService} from '../../../common/alert-service/alert.service';
import {InventoryService} from './inventory.service';
import {Article} from '../models/article.models';
import {Inventory, InventoryGroup, InventoryReserved, StorageBin} from './inventory.models';
import {Lister} from '../../../common/wrapper.models';
import {DateHelper} from '../../../helper/date-helper';
import {DateRange} from '../../../core/date-range/date-range.models';
import {InventoryError} from './inventory-error.component';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'inventory-modal',
  templateUrl: './inventory.modal.component.html',
})
export class InventoryModalComponent extends InventoryError implements OnInit, OnChanges {

  @Input() article: Article;
  @Input() inventoryGroupData: { objects: InventoryGroup[], sum: number };
  @Input() reservedObjects: Lister<InventoryReserved>;
  @Input() inventoryData: Lister<Inventory>;
  @Input() storage: StorageBin[];
  @Input() workflowId?: number;
  @Input() workflowIds: number[];
  @Input() selectedInventory?: InventoryGroup = null;
  @Output() setInventoryQuantity: EventEmitter<number> = new EventEmitter<number>();
  @Output() setInventoryState: EventEmitter<{storage: InventoryGroup, state: number}> =
    new EventEmitter<{storage: InventoryGroup, state: number}>();

  objects?: Inventory[];
  sum: number;
  groupObjects: InventoryGroup[];
  queryData: any = {start: null, end: null};

  constructor(private inventoryService: InventoryService,
              protected alertService: AlertService) {
    super(alertService);
  }

  ngOnInit(): void {
    this.objects = this.inventoryData.objects;
    this.groupObjects = this.inventoryGroupData.objects;
    this.setQuantity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventoryData && !changes.inventoryData.isFirstChange()) {
      this.objects = this.inventoryData.objects;
    }

    if (changes.inventoryGroupData && !changes.inventoryGroupData.isFirstChange()) {
      this.groupObjects = this.inventoryGroupData.objects;
      this.setQuantity();
    }
  }

  success() {
    return this.inventoryGroupData.sum < 0;
  }

  save(): void {
    this.changeModalState(null, null);
    this.alertService.add('success', 'Lagerbestand erfolgreich angepasst!');
  }

  quantityFormat(n: number): string {
    return Math.abs(n).toLocaleString();
  }

  updateDateRange(date: DateRange): void {
    this.queryData.date_start = DateHelper.format(date.start);
    this.queryData.date_end = DateHelper.format(date.end);
    this.queryData.page = 1;
    this.call();
  }

  goto(page: number): void {
    this.queryData.page = page;
    this.call();
  }

  query(any?: any): any {
    return this.queryData;
  }

  private call(): void {
    this.inventoryService.inventoryList(this.article.oa_nr, this.query()).subscribe(value => {
      this.inventoryData = value;
      this.objects = value.objects;
    }, response => {
      console.log('Response Error', response);
    });
  }

  private setQuantity() {
    this.sum = this.inventoryGroupData.sum;
    this.setInventoryQuantity.next(this.sum);
  }

  changeModalState(inventory?: InventoryGroup, state?: number): void {
    this.setInventoryState.emit({storage: inventory, state: state});
  }

  setFormData(inventory?: InventoryGroup): void {
    this.selectedInventory = inventory ?? null;
  }

  businessOrderInventoryRelationRelease(inventoryWithBa: InventoryReserved): void {
    if (confirm(
      `Soll der auf den BA ${inventoryWithBa.ba_id}-1 reservierte Lagerbestand wirklich wieder freigegeben werden?`
    )) {
      this.inventoryService.businessOrderInventoryRelationRelease(inventoryWithBa)
        .subscribe(() => {
          forkJoin({
            inventory: this.inventoryService.inventoryList(this.article.oa_nr, this.query()),
            groups: this.inventoryService.articleGroupWithNotes(this.article.oa_nr),
            reserved: this.inventoryService.listReservedForBa(this.article.oa_nr),
          }).subscribe(lists => {
            this.reservedObjects = lists.reserved;
            this.inventoryData = lists.inventory;
            this.objects = this.inventoryData.objects;
            this.inventoryGroupData = lists.groups;
            this.groupObjects = this.inventoryGroupData.objects;
            this.setQuantity();
          }, () => {});
        }, () => {});
    }
  }
}

