<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row">
    <div class="col-md-2">
      <input type="checkbox"
             id="external_separated"
             name="external_separated"
             class="form-control"
             formControlName="external_separated"/>
    </div>
    <label for="external_separated" class="col-md-10">
      Externe Schritte ohne Kunden-Preiserhöhung rechnen
    </label>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button type="submit" class="btn btn-success" [disabled]="saveLocked">
        Speichern
      </button>
    </div>
  </div>
</form>
