import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'material-product-groups-navigation',
  templateUrl: './material-product-groups-navigation.component.html',
})
export class MaterialProductGroupsNavigationComponent {
  @Output() openCreate: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }
}
