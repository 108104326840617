<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Kennzeichnung E-Test
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="el_stamp"
                            [defaultValues]="defaultValues['el-stamp']"
                            class="article-form-item-values">
      EL Stempel Seite
    </article-extra-dropdown>

    <article-extra-text formControlName="el_stamp_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="line_label" class="article-form-item-values">
      Strichmarke Stirnseite
    </article-extra-checkbox>

    <article-extra-text formControlName="line_label_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-text formControlName="other" class="article-form-item-extra-long">Sonstiges
    </article-extra-text>

  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="testing_protocol_e_test" class="article-form-item-values">
      Prüfprotokoll E-Test
    </article-extra-checkbox>

    <article-extra-text formControlName="testing_protocol_e_test_other" class="article-form-item-extra">
    </article-extra-text>

  </div>

  <h6>Besonderheiten China</h6>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="first_pass_yield" class="article-form-item-values">
      First pass yield
    </article-extra-checkbox>

    <article-extra-text formControlName="first_pass_yield_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <h6>Versand Prüfprotokoll</h6>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="email" class="article-form-item-values">
      E-Mail
    </article-extra-checkbox>

    <article-extra-text formControlName="email_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="paper" class="article-form-item-values">
      Papier
    </article-extra-checkbox>

    <article-extra-text formControlName="paper_other" class="article-form-item-extra"></article-extra-text>
  </div>

</div>
