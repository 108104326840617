import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';
import {Component, OnInit} from '@angular/core';
import {AddressService} from '../../../customer/services/address.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {EnvisiaFileService} from '../../../../common/envisia-file-button/envisia-file.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceCorrectionService} from '../services/invoice-correction.service';
import {Customer} from '../../../customer/model/customer';
import {HttpErrorResponse} from '@angular/common/http';
import {InvoiceCorrectionForm} from '../invoice.models';

@Component({
  selector: 'invoice-correction-create',
  templateUrl: './invoice-correction-create.component.html'
})
export class InvoiceCorrectionCreateComponent implements OnInit {
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};
  customerId: number | null = null;
  invoiceId: string | null = null;

  constructor(private fb: UntypedFormBuilder,
              private activeModal: NgbActiveModal,
              private addressService: AddressService,
              private invoiceCorrectionService: InvoiceCorrectionService,
              private alertService: AlertService,
              private envisiaFileService: EnvisiaFileService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customer: [null, Validators.required],
      address: [null, Validators.required],
      date: [null, Validators.required],
      label: [null, Validators.required],
      description: [null],
      sum: [null, Validators.required],
      hint: [null],
      correction: [null],
      without_ust: [null],
      note: [null],
    });
  }

  submit(clb?: LoadingButtonEvent): void {
    this.errors = {};
    const data: InvoiceCorrectionForm = {
      customer_id: this.form.value.customer ? this.form.value.customer.id : null,
      address_id: this.form.value.address,
      hint: this.form.value.hint,
      label: this.form.value.label,
      description: this.form.value.description,
      date: this.form.value.date,
      sum: this.form.value.sum,
      correction: !this.form.value.correction,
      without_ust: !!this.form.value.without_ust,
      note: this.form.value.note,
    };

    this.invoiceCorrectionService.create(data).subscribe(result => {
      this.alertService.add('success', 'Rechnungskorrektur erfolgreich erstellt!');
      this.envisiaFileService.open(result.id.toString(), 'invoice_correction');
      this.activeModal.close(result);
    }, (response: HttpErrorResponse) => {
      let vatError = false;

      if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
        this.errors = response.error;
        if (response.error['obj.address'] === 'error.vat') {
          vatError = true;
        }
      }

      if (vatError) {
        this.alertService.add('danger',
          'Rechnungskorrektur fehlgeschlagen, keine USt.-ID. bei der Kundenadresse hinterlegt!');
      } else {
        this.alertService.add('danger', 'Rechnungskorrektur fehlgeschlagen!');
      }

      if (!!clb) {
        clb.callback(false);
      }
    });
  }

  changeCustomer(customer: Customer | null | undefined): void {
    if (!!customer) {
      this.customerId = customer.id;
      this.invoiceId = customer.invoice ? customer.invoice.id : null;
    }
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

}
