import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Article} from '../../models/article.models';
import {Inventory, InventoryGroup, InventoryReserved, StorageBin} from '../inventory.models';
import {Lister} from '../../../../common/wrapper.models';
import {InventoryService} from '../inventory.service';

@Component({
  selector: 'inventory-modal-wizard',
  templateUrl: './inventory-modal-wizard.component.html'
})
export class InventoryModalWizardComponent {
  @Input() article: Article;
  @Input() inventoryGroupData: { objects: InventoryGroup[], sum: number };
  @Input() reservedObjects: Lister<InventoryReserved>;
  @Input() inventoryData: Lister<Inventory>;
  @Input() storage: StorageBin[];
  @Input() activeInventory?: InventoryGroup = null;
  @Input() workflowId?: number;
  @Input() workflowIds: number[];
  @Output() setInventoryQuantity: EventEmitter<number> = new EventEmitter<number>();

  state = 0;

  constructor(private inventoryService: InventoryService) {
  }

  setActiveModal(storage: InventoryGroup, state?: number) {
    this.activeInventory = storage;
    this.state = state ?? 0;

    if (this.activeInventory === null) {
      this.inventoryService.inventoryList(this.article.oa_nr).subscribe(inventoryData => {
        this.inventoryData = inventoryData;
      });
      this.inventoryService.articleGroupWithNotes(this.article.oa_nr).subscribe(inventoryGroupData => {
        this.inventoryGroupData = inventoryGroupData;
        this.setInventoryQuantity.emit(this.inventoryGroupData.sum);
      });
    }
  }
}
