import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Lister} from '../../../common/wrapper.models';
import {AxiosHttpClient} from '../../../envisia/auth/axios-http-client';
import {OfferRejectionReason} from './config-offer-rejection-reason.model';
import Axios, {AxiosResponse} from 'axios';
import {ReasonFormModel} from '../justification-reasons/reason.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigOfferRejectionReasonAxiosService {
  constructor() {
  }

  static create(form: ReasonFormModel): Promise<OfferRejectionReason | null> {
    return AxiosHttpClient.post<OfferRejectionReason | null>(
      `${environment.apiv4uri}config/offer/rejection/reason`,
      form,
    );
  }

  static update(id: number, form: ReasonFormModel): Promise<OfferRejectionReason | null> {
    return AxiosHttpClient.put<OfferRejectionReason | null>(
      `${environment.apiv4uri}config/offer/rejection/reason/${id}`,
      form,
    );
  }

  static delete(id: number): Promise<any> {
    return AxiosHttpClient.delete(
      `${environment.apiv4uri}config/offer/rejection/reason/${id}`
    );
  }

  static list(
    query?: { [key: string]: any },
    abortController?: AbortController,
  ): Promise<AxiosResponse<Lister<OfferRejectionReason>>> {
    return Axios.create().get<Lister<OfferRejectionReason>>(
      `${environment.apiv4uri}config/offer/rejection/reasons`,
      {params: query, signal: abortController?.signal},
    );
  }
}
