<div class="modal-header">
  <h3 class="modal-title">Artikel auswählen</h3>
</div>

<div class="modal-body">
  <div class="task-customersearch">

    <form name="article-search" class="task-customersearch-form" [formGroup]="form">
      <label>
        <input type="text" class="form-control" id="id_customer"
               name="name"
               formControlName="name"
               autofocus="autofocus"
               [inputSelect]>
      </label>
    </form>
    <div class="whitelist customerlist">
      <ng-container *ngIf="data.data$ | async as articleList; else loadingOrError.template">
        <div class="whitelist-item"
             *ngFor="let article of articleList"
             (click)="select(article)"
             [ngClass]="{selected: selected === article.oa_nr}">
          <h4>
            SDS-Nr.: {{ article.sds_nr }}
            <span class="label label-warning" *ngIf="article.lock">Gesperrt</span>
            <span class="label label-danger" *ngIf="article.date_deleted">Gelöscht</span>
          </h4>
          <div>0A-Nr.: {{ article.oa_nr }}</div>
        </div>
      </ng-container>

      <loading-or-error
          #loadingOrError
          [loadingWrapper]="data"
          [errorMessage]="'Konnte \'Artikel\' nicht laden'"
          [spinnerSize]="2"
          spinnerName="circle-o-notch">
      </loading-or-error>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-default pull-left" (click)="cancel()" [disabled]="disabledButton">
    Abbrechen
  </button>
  <button class="btn btn-success pull-right" (click)="ok()" [disabled]="disabledButton || !selected">
    Artikel wechseln
    <ng-container *ngIf="disabledButton">

      <i class="fa fa-circle-o-notch fa-spin"></i>
    </ng-container>
  </button>
</div>
