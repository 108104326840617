import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-delivery-date',
  templateUrl: './article-extra-delivery-date.component.html',
})
export class ArticleExtraDeliveryDateComponentComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'delivery_date';
  protected schemaName = 'delivery-date';
  protected fields = [
    'after',
    'before',
    'other',
  ];
}
