<td *ngIf="!isCurrent">
  <strong>
    <a uiSref="a.business_order.view" target="_blank" [uiParams]="{id: bo.id, index: bo.index}">
      {{bo.id}}-{{bo.index}}
    </a>
  </strong>&nbsp;
  <div class="label label-danger"
       ngbTooltip="Nachfertigung"
       *ngIf="!!reproduction">
    NF&nbsp;
    <a uiSref="a.business_order.view"
       target="_blank"
       style="color: #fff !important;"
       [uiParams]="{id: reproduction.parent_id, index: reproduction.parent_index}"
       *ngIf="!isParentCurrent">
      ({{reproduction.parent_id}}-{{reproduction.parent_index}})
    </a>
    <ng-container *ngIf="isParentCurrent">
      ({{reproduction.parent_id}}-{{reproduction.parent_index}})
    </ng-container>
  </div>
</td>
<td *ngIf="!isCurrent"><strong>+&nbsp;{{bo.good_piece|money}}</strong></td>

<td *ngIf="isCurrent">
  <strong ngbTooltip="dieser BA">
    {{bo.id}}-{{bo.index}}
  </strong>&nbsp;
  <div class="label label-danger"
       ngbTooltip="Nachfertigung"
       *ngIf="!!reproduction">
    NF&nbsp;
    <a uiSref="a.business_order.view"
       target="_blank"
       style="color: #fff !important;"
       [uiParams]="{id: reproduction.parent_id, index: reproduction.parent_index}">
      ({{reproduction.parent_id}}-{{reproduction.parent_index}})
    </a>
  </div>
</td>
<td *ngIf="isCurrent"></td>
