<div class="modal-header">
  <div class="modal-title">
    <h3>Artikel löschen</h3>
  </div>
</div>

<form class="form-horizontal" [formGroup]="lockForm" (ngSubmit)="submit()">
  <div class="modal-body">

    Möchten Sie diesen Artikel wirklich löschen?

    <div class="form-group">
      <label class="col-md-2 control-label" for="reason">Grund:</label>

      <div class="col-md-10">
        <select id="reason" class="form-control" formControlName="reason"
                [ngClass]="{red_alert: errors['obj.reason']}">
          <option *ngFor="let notice of notices" [value]="notice">{{ notice }}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-2 control-label" for="reference">Referenz</label>

      <div class="col-md-10">
      <textarea id="reference" class="form-control" formControlName="reference" rows="10"
                [ngClass]="{red_alert: errors['obj.reference']}">
      </textarea>
      </div>
    </div>

    <div class="form-group red_alert" *ngIf="has_active_bas">
      <strong>Artikel in Produktion</strong>
      <div>
        Für dieses Artikel gibt es einen oder mehrere BAs, welche in der Warteschlange oder bereites in der Produktion
        sind. Bitte prüfen Sie diese.
      </div>
      <div class="pull-right">
        <img src="/ui/assets/images/envisia/chkbx-checked.png" *ngIf="valid" (click)="makeValid(false)"
             class="fade-chkbx" style="margin-right: 15px;">
        <img src="/ui/assets/images/envisia/chkbx-unchecked.png" *ngIf="!valid" (click)="makeValid(true)"
             class="fade-chkbx" style="margin-right: 15px;">
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-success pull-right" type="submit"
            [disabled]="!valid || lockForm.invalid || lockForm.pristine">
      Artikel löschen
    </button>
  </div>
</form>
