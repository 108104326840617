import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects, Lister} from '../../common/wrapper.models';
import {Akquise, AkquiseForm} from './akquise.models';
import {EnvisiaLocation} from '../../common/location/envisia-location';
import {ListService} from '../../common/search/search.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AkquiseService implements ListService<Akquise> {

  private uri = '/akquise/v1/akquise/';

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<Akquise>> {
    return this.http.get<Lister<Akquise>>(
      this.uri,
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  userList(): Observable<string[]> {
    return this.http.get<EnvisiaObjects<string>>(this.uri + 'user/list/').pipe(map(obj => obj.objects));
  }

  edit(id: number, form: AkquiseForm): Observable<Akquise> {
    return this.http.put<EnvisiaObject<Akquise>>(this.uri + id + '/', form).pipe(map(obj => obj.object));
  }

  create(customerId: number, form: AkquiseForm): Observable<Akquise> {
    return this.http.post<EnvisiaObject<Akquise>>(
      this.uri + '?customer_id=' + customerId,
      form
    ).pipe(map(obj => obj.object));
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + 'delete/' + id + '/').pipe(map(() => {}));
  }

}
