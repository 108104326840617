/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContractCounts} from '../models/contract.models';
import {ContractService} from '../../workflow-services/contract.service';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';

@Component({
  selector: 'contract-order-count',
  templateUrl: './contract-order-count.component.html'
})
export class ContractOrderCountComponent implements OnInit {
  @Input() workflowId: number;
  countWrapper: LoadingWrapper<ContractCounts>;

  constructor(private contractService: ContractService) {
  }

  ngOnInit(): void {
    this.countWrapper = new LoadingWrapper<ContractCounts>(
      this.contractService.counts(this.workflowId)
    );
  }

}
