import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import Helper from '../../helper/helper';

@Injectable({providedIn: 'root'})
export class AlertService {
  private subject = new Subject<Alert>();

  constructor() {
  }

  add(type: string, msg: string, timeout: number = 5000): void {
    this.subject.next({id: Helper.guid(), type: type, msg: msg, timeout: timeout});
  }

  getSubject(): Subject<Alert> {
    return this.subject;
  }

}

export interface Alert {
  id: string;
  type: string;
  msg: string;
  timeout: number;
}
