import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MaterialPlanningDateCheckService} from './material-planning-date-check.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {Observable, Subscription} from 'rxjs';
import {DateHelper} from '../../../helper/date-helper';
import {debounceTime, map} from 'rxjs/operators';
import {ContractDateCheckHolder} from './material-planning-date-check.model';
import {MaterialPlanningDateCheckModalService} from './material-planning-date-check-modal.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {Article} from '../../article/models/article.models';

@Component({
  selector: 'material-planning-date-check',
  templateUrl: './material-planning-date-check.component.html',
  styleUrls: ['./material-planning-date-check.component.scss'],
})
export class MaterialPlanningDateCheckComponent implements OnInit, OnDestroy, OnChanges {
  @Input() article: Article;
  @Input() amount?: number | null = null;
  @Input() date?: string;
  @Input() preload: boolean;
  @Output() dateChanged: EventEmitter<string> = new EventEmitter<string>();

  state: LoadingWrapper<{ obj: ContractDateCheckHolder }>;
  form: UntypedFormGroup;
  subscription: Subscription;

  constructor(private amps: MaterialPlanningDateCheckService,
              private fb: UntypedFormBuilder,
              private mpdcModalService: MaterialPlanningDateCheckModalService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'date': [this.date ?? null],
      'amount': [this.amount ?? null],
    });

    this.state = this.nullLoader();

    if (this.preload) {
      this.checkDate();
    }

    this.subscription = this.form.get('date').valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.checkDate();
    });
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c.amount && !c.amount.isFirstChange()) {
      this.form.patchValue({'amount': this.amount});
      this.checkDate();
    }
    if (c.date && !c.date.isFirstChange()) {
      this.form.patchValue({'date': this.date}, {emitEvent: false});
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkDate(): void {
    const value = this.form.value;

    if (value.date !== this.date) {
      this.dateChanged.emit(value.date);
    }

    const date = DateHelper.safeParse(value.date);
    if (!date || !value.amount) {
      this.state = this.nullLoader();
    } else {
      this.state = new LoadingWrapper<{ obj: ContractDateCheckHolder | null }>(
        this.amps.check(this.article.oa_nr, value.amount, value.date).pipe(map(o => {
          return {obj: o};
        }))
      );
    }
  }

  open(): void {
    this.state.data$.subscribe(o => {
      this.mpdcModalService.open(this.article, o.obj, this.form.value.date).subscribe(d => {
        this.form.patchValue({'date': d});
      }, ignoreRejection);
    });
  }

  checkDateAndOpen(): void {
    this.checkDate();
    this.open();
  }

  nullLoader(): LoadingWrapper<{ obj: ContractDateCheckHolder | null }> {
    return new LoadingWrapper<{ obj: ContractDateCheckHolder | null }>(
      new Observable<{ obj: ContractDateCheckHolder | null }>(o => {
        o.next({obj: null});
        o.complete();
      }));
  }

}
