import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {ArticleDocumentComponent} from './article-document.component';
import {ARTICLE_DOCUMENT_STATES} from './article-document.states';
import {UIRouterModule} from '@uirouter/angular';
import {ArticleNavigationModule} from '../navigiation/article-navigation.module';
import {ArticleDocumentNewComponent} from './article-document-new.component';
import {FinderModule} from '../../finder/finder.module';

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({ states: ARTICLE_DOCUMENT_STATES }),
        ArticleNavigationModule,
        FinderModule,
    ],
    declarations: [
        ArticleDocumentComponent,
        ArticleDocumentNewComponent,
    ]
})
export class ArticleDocumentModule {
}
