<div class="modal-header">
  <h3 class="modal-title">Auftragsrahmen erstellen</h3>
</div>

<form [formGroup]="form">
  <div class="modal-body">

    <div class="row">
      <div class="col-md-12">
        <label for="quanity">Menge:</label>
        <input type="text"
               id="quanity"
               class="form-control"
               required
               tabindex="1"
               formControlName="quanity"
               autocomplete="off"
               [autofocus]>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label for="lose">Lose:</label>
        <input type="number" id="lose" class="form-control" formControlName="lose" required tabindex="2" min="1"
               [ngClass]="{'red_alert': errors['lose']}">
      </div>
    </div>

    <div class="col-md-12" *ngIf="cq_needed || !!errors['lose']">&nbsp;</div>
    <div class="row" *ngIf="!!errors['lose']">
      <div class="col-md-12 red_alert">Es muss mindestens ein Los eingegeben werden.</div>
    </div>

    <div class="row" *ngIf="cq_needed">
      <div class="col-md-12 red_alert">
        Achtung Keine Kalk-Menge für Menge / Lose hinterlegt
      </div>
    </div>
    <div class="col-md-12" *ngIf="cq_needed || !!errors['lose']">&nbsp;</div>

    <div class="row" *ngIf="cq_needed">
      <div class="col-md-12">
        <label for="calc_quanity">Kalk-Menge:</label>
        <input type="text" id="calc_quanity" class="form-control" formControlName="calc_quanity" tabindex="3">
      </div>
    </div>

    <div class="red_alert" *ngIf="articleUndef">
      Preise können erst berechnet werden, wenn Artikel vollständig ist!
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" (click)="dismiss()" class="btn btn-default pull-left">Abbrechen</button>


    <loading-button typ="submit" clazz="btn btn-success"
                    (loadingSave)="add($event)"
                    [disabled]="check()"
                    tabindex="4">
      Auftragsrahmen erstellen
    </loading-button>
  </div>
</form>
