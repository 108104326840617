<div class="modal-header red_alert">
  <h3 class="modal-title">Dokument konnte nicht erstellt werden</h3>
</div>

<div class="modal-body red_alert">

  <div [innerHtml]="text">
  </div>

</div>

<div class="modal-footer red_alert">
  &nbsp;
</div>
