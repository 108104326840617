import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Akquise} from './akquise.models';
import {DateHelper} from '../../helper/date-helper';
import {AkquiseService} from './akquise.service';
import {Observable, EMPTY} from 'rxjs';
import {AlertService} from '../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingButtonEvent} from '../../common/loading-button/loading-button.component';
import {CustomerId} from '../customer/model/customer';
import {noop} from '../../helper/noop';

@Component({
  selector: 'akquise-save',
  templateUrl: './akquise-save.component.html',
})
export class AkquiseSaveComponent implements OnInit {
  @Input() akquise?: Akquise;
  @Input() customer?: CustomerId;

  header = '';
  akquiseForm: UntypedFormGroup;
  errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private akquiseService: AkquiseService,
              private modalRef: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (this.akquise) {
      this.header = 'bearbeiten';
    } else {
      this.header = 'erstellen';
    }

    this.akquiseForm = this.fb.group({
      date: [this.akquise ? this.akquise.date : null],
      start: [this.akquise ? this.akquise.start : null],
      end: [this.akquise ? this.akquise.end : null],
      text: [this.akquise ? this.akquise.raw : null],
      username: [this.akquise ? this.akquise.username : null]
    });
  }

  submit(clb?: LoadingButtonEvent): void {
    this.errors = {};

    const form = this.akquiseForm.value;
    let observable: Observable<Akquise> = null;
    if (this.akquise) {
      observable = this.akquiseService.edit(this.akquise.id, form);
    } else if (this.customer) {
      observable = this.akquiseService.create(this.customer.id, form);
    } else {
      observable = EMPTY;
    }
    observable.subscribe(response => {
      if (!this.akquise) {
        this.alertService.add('success', 'Akquise erfolgreich erstellt!');
      } else {
        this.alertService.add('success', 'Akquise erfolgreich editiert!');
      }
      this.modalRef.close(response);
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Fehler beim Absenden der Akquise!');
      if (clb) {
        clb.callback(false);
      }
    });
  }


  dateParser(date: string | Date | null | undefined): Date | null {
    noop(this);
    if (date instanceof Date) {
      return date;
    }
    if (date !== null && date !== undefined) {
      return DateHelper.parseISO8601(date);
    }
    return null;
  }

}
