<ng-container *ngIf="form" [formGroup]="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>RG Korrektur</th>
      <th>Beleg-Datum</th>
      <th>Rechnungs-Nr.</th>
      <th>Positions-Bezeichnung</th>
      <th>Betrag</th>
      <th>Offen €</th>
      <th>Gebucht €</th>
      <th>Gebucht Skonto %</th>
      <th>Gebucht Skonto €</th>
      <th>Bezahlt</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    </thead>
    <tbody formArrayName="corrections">
    <tr *ngFor="let form of table.controls; trackBy: trackByFn; let index = index" [formGroupName]="index.toString()">
      <ng-container *ngIf="objects[index] as obj"> <!-- easier name -->
        <td>
          <ev-file-button [objId]="obj.id" type="invoice_correction">{{ obj.id }}</ev-file-button>
        </td>
        <td>{{ obj.date }}</td>
        <td>
          <ev-file-button [objId]="obj.invoice_id" type="invoice">{{ obj.invoice_id }}</ev-file-button>
        </td>
        <td>{{ obj.label }}</td>
        <td>{{ obj.revenue }}</td>
        <td>{{ obj.open }}</td>
        <td>
          <customer-opos-input formControlName="expensed"
                               [error]="errors['obj.objects[' + index + '].expensed']"
                               [focus]="obj.focus_expensed"
                               (keyUpEmitter)="focus($event, index, 'expensed', obj)"
                               (lazyEmitter)="expensed(index, $event, obj)">
          </customer-opos-input>
        </td>
        <td>
          <customer-opos-input formControlName="skonto"
                               [error]="errors['obj.objects[' + index + '].skonto']"
                               [focus]="obj.focus_skonto"
                               (keyUpEmitter)="focus($event, index, 'skonto', obj)"
                               (lazyEmitter)="skonto(index, $event, obj)">
          </customer-opos-input>
        </td>
        <td>{{ form.controls.skonto_total.value }} €</td>
        <td>
          <input type="checkbox" formControlName="save"
                 (click)="save(index, $event.target.checked, obj)"
                 [oposFocus]="obj.focus_save"
                 (keyup)="focus($event, index, 'save', obj)">
        </td>
        <td>
          <button type="button" (click)="skontoCustomer(index, obj)" class="btn btn-default btn-sm">
            Skonto
          </button>
        </td>
        <td>
          <button type="button" (click)="putAll(index, obj)" class="btn btn-default btn-sm">
            Gesamt
          </button>
        </td>

        <td>
          <button type="button" class="btn btn-sm btn-link" (click)="history(obj)">
            <i class="fa fa-history"></i>
          </button>
        </td>
      </ng-container>
    </tr>

    <tr class="no-border">
      <td> &nbsp;</td>
      <td> &nbsp;</td>
      <td> &nbsp;</td>
      <td> &nbsp;</td>
      <td class="bold">{{ totalRevenue }} €</td>
      <td class="bold">{{ totalOpen }} €</td>
      <td class="bold">{{ totalExpensed }} €</td>
      <td> &nbsp;</td>
      <td> &nbsp;</td>
      <td> &nbsp;</td>
      <td> &nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
    </tbody>
  </table>
</ng-container>
