import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject} from '../../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {WorkflowPositionView} from '../../models/position.models';

@Injectable({providedIn: 'root'})
export class OfferPositionNotesService {

  private url = '/api/v2/position/';

  constructor(private http: HttpClient) {
  }

  updateNotes(posId: number, typ: string, notes: string): Observable<WorkflowPositionView> {
    return this.http.put<EnvisiaObject<WorkflowPositionView>>(this.url + 'update/notes/' + posId + '/' + typ + '/',
      {value: notes}).pipe(
      map(value => value.object)
    );
  }

}
