import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'contract-create-existing-modal',
  templateUrl: './contract-create-existing-modal.component.html'
})
export class ContractCreateExistingModalComponent {
  @Input() workflowIds: number[];

  constructor(private modalService: NgbActiveModal) {
  }

  dismiss(): void {
    this.modalService.dismiss();
  }
}
