import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {EnvisiaEvent} from './event.types';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class EnvisiaEventService {
  private emitter = new Subject<EnvisiaEvent>();

  next(value: EnvisiaEvent): void {
    this.emitter.next(value);
  }

  subscribe(next?: (value: EnvisiaEvent) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this.emitter.subscribe(next, error, complete);
  }

}
