import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateTaskNoHistoryModalComponent} from './create-task-no-history-modal.component';
import {SharedModule} from '../../../core/shared.module';
import {NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { CreateTaskHistoryModalComponent } from './create-task-history-modal.component';
import { CreateTaskComponent } from './create-task.component';
import {UIRouterModule} from '@uirouter/angular';


@NgModule({
    declarations: [
        CreateTaskNoHistoryModalComponent,
        CreateTaskHistoryModalComponent,
        CreateTaskComponent
    ],
    exports: [
        CreateTaskComponent,
    ],
    imports: [
        UIRouterModule.forChild(),
        CommonModule,
        SharedModule,
        NgbModalModule,
        NgbTooltipModule,
    ]
})
export class CreateTaskModule {
}
