<div class="filter-row">
  <div class="filter-left">

    <button class="btn btn-default btn-sm" type="button" (click)="badge('0')" [ngClass]="{active: q('0')}">
      Offen
    </button>

    <button class="btn btn-success btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
      Bestellt
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('all')" [ngClass]="{active: q('all')}">
      Alle
    </button>

  </div>
</div>

<form name="form" #changeForm="ngForm">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'order_request_id', 'ASC')" [href]="sortUrl('order_request_id', 'ASC')">
          Anfrage Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'order_request_date', 'DESC')" [href]="sortUrl('order_request_date', 'DESC')">
          Datum
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'task_workflow_id', 'ASC')" [href]="sortUrl('task_workflow_id', 'ASC')">
          Vorgang
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'article_article_data->>\'sds_nr\'', 'ASC')"
           [href]="sortUrl('article_article_data->>\'sds_nr\'', 'ASC')">
          SDS-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'article_article_oa_nr', 'ASC')" [href]="sortUrl('article_article_oa_nr', 'ASC')">
          0A-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'article_article_data->>\'kd_art_nr\'', 'ASC')"
           [href]="sortUrl('article_article_data->>\'kd_art_nr\'', 'ASC')">
          Kunden-Art-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'order_request_producer', 'ASC')" [href]="sortUrl('order_request_producer', 'ASC')">
          Produzent
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'order_request_delivery_date', 'ASC')"
           [href]="sortUrl('order_request_delivery_date', 'ASC')">
          Lief.Ter.(ETD)
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'order_request_username', 'ASC')" [href]="sortUrl('order_request_username', 'ASC')">
          Ersteller
        </a>
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>

    </tr>
    <tr class="filterrow">
      <th>
        <input [autofocus]="true" type="text" class="form-control input-sm" name="sid" [(ngModel)]="data.sid" autocomplete="off search-workflow-id">
      </th>
      <th>&nbsp;</th>
      <th>
        <input type="text" class="form-control input-sm" name="workflow_id" [(ngModel)]="data.workflow_id">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="sds_nr" [(ngModel)]="data.sds_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="oa_nr" [(ngModel)]="data.oa_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="kd_art_nr" [(ngModel)]="data.kd_art_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="producer" [(ngModel)]="data.producer">
      </th>
      <th>&nbsp;</th>
      <th>
        <input type="text" class="form-control input-sm" name="username" [(ngModel)]="data.username">
      </th>

      <th>&nbsp;</th>
      <th>&nbsp;</th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let obj of list.objects">
      <td>
        <a [href]="'#!/workflow/request/' + obj.workflow.id">
          {{ obj.order_request.id }}
          <workflow-state [key]="obj.order_request.state" type="request_status"></workflow-state>
          &nbsp;
          <i *ngIf="obj.order_request.mail_sent" class="fa fa-paper-plane ev-blue-color"
             title="Anfrage wurde per Mail versendet"></i>
        </a>
      </td>
      <td>
        <a [href]="'#!/workflow/request/' + obj.workflow.id">
          {{ obj.order_request.request_date }}
        </a>
      </td>
      <td>
        <a [href]="'#!/workflow/' + obj.workflow.id">
          {{ obj.workflow.id }}
          <workflow-state type="workflow_status" [key]="obj.workflow.status"></workflow-state>
        </a>
      </td>
      <td>{{ obj.article.data.sds_nr }}</td>
      <td>
        <div class="label"
             [ngClass]="{'label-danger': obj.order_request.article_hash !== obj.article.current_hash,
                     'label-success': obj.order_request.article_hash === obj.article.current_hash }">
          {{ obj.article.oa_nr }}
        </div>
      </td>
      <td>{{ obj.article.data.kd_art_nr }} {{ obj.article.data.kd_art_index}}</td>
      <td>
        <a [href]="'#!/workflow/request/' + obj.workflow.id">
          {{ obj.order_request.producer }}
        </a>
      </td>
      <td>
        <a [href]="'#!/workflow/request/' + obj.workflow.id">
          {{ obj.order_request.delivery_date }}
        </a>
      </td>
      <td>
        <a [href]="'#!/workflow/request/' + obj.workflow.id">
          {{ obj.order_request.username }}
        </a>
      </td>
      <td class="icon">
        <ev-file-button [objId]="obj.order_request.id" [type]="'order_request'">
          <i class="fa fa-file-pdf-o"></i>
        </ev-file-button>
      </td>

      <td class="icon">
        <button class="btn btn-link btn-sm" type="button" (click)="mail(obj)">
          <i class="fa fa-envelope"></i>
        </button>
      </td>

    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
