import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ArticleInputMarkerDirective} from '../../form/article-input-marker.directive';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../../models/article-specification.model';
import {Subscription} from 'rxjs';
import {ArticleViaHandlingFormOptionsModel} from './article-via-handling-form-options.model';

@Component({
  selector: 'article-via-handling-form',
  templateUrl: './article-via-handling-form.component.html',
})
export class ArticleViaHandlingFormComponent
  extends ArticleInputMarkerDirective
  implements OnInit, OnDestroy, OnChanges {
  @Input() errors: { [key: string]: any; } = {};
  @Input() form: UntypedFormGroup;
  @Input() refCount = 0;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() stacked = false;
  @Output() setAutomatedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  viaHandlingType: number;
  subscriptionViaHandling?: Subscription = null;
  subscriptionViaFillingResin?: Subscription = null;
  subscriptionViaFillingLacquer?: Subscription = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setViaHandling(true);
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.unSubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && !changes.form.isFirstChange()) {
      this.unSubscribe();
      this.subscribe();
    }
  }

  subscribe(): void {
    this.subscriptionViaHandling = this.form.get('via_handling').valueChanges.subscribe(viaHandling => {
      this.viaHandlingType = this.viaHandlingTypeMapping(viaHandling);
      this.setViaHandling();
    });

    this.subscriptionViaFillingResin = this.form.get('via_filling_resin').valueChanges.subscribe(viaFillingResin => {
      this.setViaFillingOptions(true, viaFillingResin);
    });

    this.subscriptionViaFillingLacquer = this.form.get('via_filling_lacquer').valueChanges.subscribe(viaFillingLacquer => {
      this.setViaFillingOptions(false, viaFillingLacquer);
    });
  }

  unSubscribe(): void {
    this.subscriptionViaFillingLacquer?.unsubscribe();
    this.subscriptionViaFillingResin?.unsubscribe();
    this.subscriptionViaHandling?.unsubscribe();
  }

  viaHandlingTypeMapping(valueViaHandling: string): number | null {
    switch (valueViaHandling) {
      case 'Plugged Via (IPC-4761 III-a)':
        return 3;
      case 'Filled and Covered Via (IPC-4761 VI)':
        return 6;
      case 'Filled and Capped Via (IPC-4761 VII)':
        return 7;
      default:
        return null;
    }
  }

  viaHandlingReverseTypeMapping(valueViaHandling: number): string | null {
    switch (valueViaHandling) {
      case 3:
        return 'Plugged Via (IPC-4761 III-a)';
      case 6:
        return 'Filled and Covered Via (IPC-4761 VI)';
      case 7:
        return 'Filled and Capped Via (IPC-4761 VII)';
      default:
        return null;
    }
  }

  viaHandlingTypeDetect(): number {
    // Via Filling checkbox is selected, must be either
    // "Filled and Covered Via (IPC-4761 VI)" or
    // "Filled and Capped Via (IPC-4761 VII)" depending on capping
    if (!!this.form.value.via_plugging) {
      return !this.form.value.capping ? 6 : 7;
    }

    // "Lochfüller" checkboxes are selected, must be type "Plugged Via (IPC-4761 III-a)"
    if (!!this.form.value.via_ls || !!this.form.value.via_ds) {
      return 3;
    }

    return 0;
  }

  setViaHandling(init: boolean = false): void {
    // Detect the existing configuration when this is loaded for the first time
    const hasViaHandlingSet = !!this.form.value.via_handling;
    if (init) {
      const existingViaHandlingType = this.viaHandlingTypeMapping(this.form.value.via_handling);
      if (existingViaHandlingType === null) {
        this.viaHandlingType = this.viaHandlingTypeDetect();
        if (this.viaHandlingType !== 0) {
          this.setAutomatedChange.emit(true);
          this.form.patchValue({
            'via_handling': this.viaHandlingReverseTypeMapping(this.viaHandlingType),
          });
        }
      } else {
        this.viaHandlingType = existingViaHandlingType;
      }
    }

    // Set the proper value for the detected handling when it is different
    // (should happen only for the first time and only for articles that have not been saved after this update)
    const initMismatch = this.viaHandlingType !== 0 && !hasViaHandlingSet && init;
    const isType6 = this.viaHandlingType === 6;
    const isType7 = this.viaHandlingType === 7;
    const hasViaFillingResin = this.form.value.via_filling_resin ?? false;
    const hasViaPlugging = (
      // always for type 7
      isType7 ? true :
        // only when via filling resin is active for type 6
        (isType6 && hasViaFillingResin) ? (this.form.value.via_plugging ?? null) :
          // only activate it automatically while initializing for type 6 when there is a mismatch
          (isType6 && initMismatch) ? true : null
    );

    this.setDiffedOptions({
      // Only show "Lochfüller" checkboxes when via handling is set to
      // "Plugged Via (IPC-4761 III-a)", unset otherwise
      via_ls: this.viaHandlingType === 3 ? !!this.form.value.via_ls : this.form.value.via_ls,
      via_ds: this.viaHandlingType === 3 ? !!this.form.value.via_ds : this.form.value.via_ds,
      // Only set via filling lacquer / resin when via handling is set to
      // "Filled and Covered Via (IPC-4761 VI)", unset otherwise
      via_filling_lacquer: isType6 ? this.form.value.via_filling_lacquer ?? false : null,
      via_filling_resin: isType6 ? (initMismatch ? true : hasViaFillingResin) : null,
      // Only set via plugging when via handling is set to
      // "Filled and Covered Via (IPC-4761 VI)" and has via_filling_resin active or when it is set to
      // "Filled and Capped Via (IPC-4761 VII)"
      via_plugging: hasViaPlugging,
      // Only set via capping when via handling is set to
      // "Filled and Capped Via (IPC-4761 VII)", unset otherwise
      capping: isType7 ? true : null,
    }, init);
  }

  setViaFillingOptions(isResin: boolean, toggle: boolean): void {
    if (toggle === false) {
      this.form.patchValue({
        'via_plugging': null,
        'via_filling_resin': null,
        'via_filling_lacquer': null,
      }, {emitEvent: false});
    } else {
      this.form.patchValue({
        'via_plugging': isResin,
        'via_filling_resin': isResin,
        'via_filling_lacquer': !isResin,
      }, {emitEvent: false});
    }
  }

  setDiffedOptions(options: ArticleViaHandlingFormOptionsModel, init?: boolean): void {
    const obj = {} as ArticleViaHandlingFormOptionsModel;
    Object.keys(options).forEach(key => {
      if (options[key] !== (this.form.value[key] ?? null)) {
        obj[key] = options[key];
      }
    });

    if (Object.keys(obj)?.length > 0) {
      if (init === true) {
        this.setAutomatedChange.emit(true);
      }

      this.form.patchValue(obj);
    }
  }
}
