import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {WorkflowCreateCustomerData} from './workflow-create.component';
import {CustomerList} from '../../customer/model/customer';
import {Contact} from '../../customer/model/contact';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Lister} from '../../../common/wrapper.models';
import {ContactCreateModalService} from '../../customer/contact/create/customer-contact-create.service';
import {CustomerService} from '../../customer/services/customer.service';
import {ContactService} from '../../customer/services/contact.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';

@Component({
  selector: 'workflow-create-customer',
  templateUrl: './workflow-create-customer.component.html'
})
export class WorkflowCreateCustomerComponent implements OnInit, OnDestroy {
  @Input() data: WorkflowCreateCustomerData;
  @Output() finishEmitter = new EventEmitter<{ typ: 0 | 1, customerId: number, contactId?: number }>();
  customerList: Lister<CustomerList> = {objects: [], count: 0, page: 1, size: 20, pages: 1};
  contactList: Contact[] = [];
  currentContact: Contact | null;
  currentCustomer: CustomerList | null;
  search: string | null | undefined;
  form: UntypedFormGroup;
  subscription?: Subscription;

  constructor(private fb: UntypedFormBuilder,
              private customerService: CustomerService,
              private contactService: ContactService,
              private contactCreateModalService: ContactCreateModalService) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.currentCustomer = this.data.customer;
      this.customerList = this.data.customerList;
      this.contactList = this.data.contactList;
      this.search = this.data.search;
    }

    this.form = this.fb.group({
      'search': [this.data ? this.data.search : null],
    });

    this.subscription = this.form.valueChanges.pipe(debounceTime(350)).subscribe(values => {
      this.search = values.search;
      const query = {
        name: values.search,
        rating: '3,4,5'
      };
      this.customerService.list(query).subscribe(response => {
        this.customerList = response;
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  newContact(): void {
    this.contactCreateModalService.open(this.currentCustomer).subscribe(obj => {
      this.contactList.push(obj);
      this.currentContact = obj;
    }, ignoreRejection);
  }

  selectContact(contact: Contact | null): void {
    this.currentContact = contact;
  }

  selectCustomer(customer: CustomerList | null): void {
    this.contactList = [];
    this.contactService.list(customer.id).subscribe(response => {
      this.contactList = response;
      this.currentContact = null;
      this.currentCustomer = customer;
    }, ignoreRejection);
  }

  setPage(n: number): void {
    const query = {
      name: this.search,
      rating: '3,4,5',
      page: n
    };
    this.customerService.list(query).subscribe(response => {
      this.customerList = response;
    });
  }

}
