import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {CUSTOMER_DOCUMENT_STATES} from './customer-document.states';
import {CustomerNavigationModule} from '../navigation/customer-navigation.module';
import {CustomerDocumentComponent} from './customer-document.component';
import {CustomerDocumentNewComponent} from './customer-document-new.component';
import {FinderModule} from '../../finder/finder.module';
import {CustomerHeaderModule} from '../header/customer-header.module';

@NgModule({
  imports: [
    SharedModule,
    FinderModule,
    CustomerNavigationModule,
    UIRouterModule.forChild({states: CUSTOMER_DOCUMENT_STATES}),
    CustomerHeaderModule
  ],
  declarations: [
    CustomerDocumentComponent,
    CustomerDocumentNewComponent
  ]
})
export class CustomerDocumentModule {
}
