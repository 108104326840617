import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MaterialValueType} from '../material-management.model';

@Component({
  selector: 'material-article-form-create',
  templateUrl: './material-article-create-form.component.html'
})
export class MaterialArticleCreateFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() articleFieldDefs: MaterialValueType[];

  constructor() {
  }

  get articleFields(): UntypedFormGroup {
    return this.form.get('article_fields') as UntypedFormGroup;
  }

  isKonsi(): boolean {
    return this.form.value.konsi;
  }
}
