<div class="modal-header">
  <h3 class="modal-title">Zusatzkosten hinzufügen</h3>
</div>

<div class="modal-body">
  <ng-container *ngIf="extraListData.data$ | async as extraList; else loadingOrError.template">

    <div style="float:left; width: 200px;">
      <div style="float:left; padding-bottom: 20px;">
        <h5>Nebenkosten</h5>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_toolingcost"
                (click)="add('additional')">
          Nebenkosten hinzufügen
        </button>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_viafilling_type6_sieve"
                (click)="add('viafilling_type6_sieve')">
          Sieberstellung Viafilling Typ VI
        </button>
      </div>
      <div style="float:left;clear:left;padding-bottom: 20px;">
        <h5>Adapterkosten</h5>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_adaptercost"
                (click)="add('conventionell')">
          Adapterkosten hinzufügen
        </button>
        <br>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_fingertest"
                (click)="add('fingertester')">
          Fingertester hinzufügen
        </button>
      </div>
      <div style="float:left;clear:left;">
        <h5>Individual</h5>
        <button class="btn btn-default btn-sm extracosts fetch-extra-cost-button"
                id="button_add_individual"
                (click)="add('individual')">
          Individual-Feld hinzufügen
        </button>
      </div>
    </div>

    <div style="float:left; width: 250px;">
      <h5>Eildienst DS</h5>
      <ng-container *ngFor="let extra of extraList.express_ds">
        <button class="btn btn-sm extracosts" (click)="add('express_ds', extra.id)"
                [ngClass]="{'btn-default': !extra.highlight, 'btn-primary': extra.highlight, 'bold': extra.bold}"
                style="margin: 5px 5px 0 0; width: 70px;">
          <ng-container *ngIf="extra.text">{{ extra.text }}</ng-container>
          <ng-container *ngIf="!extra.text">{{ extra.description_de }}</ng-container>
        </button>
      </ng-container>
    </div>

    <div style="float:left; width: 250px;">
      <h5>Eildienst ML</h5>
      <ng-container *ngFor="let extra of extraList.express_ml">
        <button class="btn btn-sm extracosts" (click)="add('express_ml', extra.id)"
                [ngClass]="{'btn-default': !extra.highlight, 'btn-primary': extra.highlight, 'bold': extra.bold}"
                style="margin: 5px 5px 0 0; width: 70px;">
          <ng-container *ngIf="extra.text">{{ extra.text }}</ng-container>
          <ng-container *ngIf="!extra.text">{{ extra.description_de }}</ng-container>
        </button>
      </ng-container>
    </div>

    <div style="float:left; width: 290px;">
      <h5>Extra Kosten - Fix Wert</h5>

      <ng-container *ngFor="let extra of extraList.extracost">
        <button class="btn btn-sm extracosts" (click)="add('extracost', extra.id)"
                [ngClass]="{'btn-default': !extra.highlight, 'btn-primary': extra.highlight, 'bold': extra.bold}"
                style="margin: 5px 5px 0 0; width: 130px; float: left">
          {{ extra.text }}
        </button>
      </ng-container>
    </div>

    <div style="float:left; width: 170px;">
      <h5>Extra Kosten - Prozentual</h5>

      <ng-container *ngFor="let extra of extraList.extracost_percentual">
        <button class="btn btn-sm extracosts" (click)="add('extracost_percentual', extra.id)"
                [ngClass]="{'btn-default': !extra.highlight, 'btn-primary': extra.highlight, 'bold': extra.bold}"
                style="margin: 5px 5px 0 0; width: 150px; float: left">
          {{ extra.text }}
        </button>
      </ng-container>
    </div>
  </ng-container>

  <loading-or-error
      #loadingOrError
      [loadingWrapper]="extraListData"
      [errorMessage]="'Konnte Position Extra Werte nicht laden'">
  </loading-or-error>
</div>

<div class="modal-footer">
</div>
