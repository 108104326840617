import {LOCALE_ID, NgModule} from '@angular/core';
import {MultilayerPlanCreateComponent} from './multilayer-plan-create.component';
import {MultilayerPlanUpdateComponent} from './multilayer-plan-update.component';
import {SharedModule} from '../../core/shared.module';
import {MultilayerPlanNavigationComponent} from './multilayer-plan-navigation.component';
import {UIRouterModule} from '@uirouter/angular';
import {MULTILAYER_PLAN_STATES} from './multilayer-plan.states';
import {MultilayerPlanCreateHolderComponent} from './multilayer-plan-create-holder.component';
import {MultilayerPlanUpdateHolderComponent} from './multilayer-plan-update-holder.component';
import { MultilayerPlanListComponent } from './multilayer-plan-list.component';

@NgModule({
  declarations: [
    MultilayerPlanNavigationComponent,
    MultilayerPlanCreateComponent,
    MultilayerPlanUpdateComponent,
    MultilayerPlanCreateHolderComponent,
    MultilayerPlanUpdateHolderComponent,
    MultilayerPlanListComponent,
  ],
  imports: [
    SharedModule,
    UIRouterModule.forChild({states: MULTILAYER_PLAN_STATES}),
  ]
})
export class MultilayerPlanModule {}
