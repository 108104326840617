import * as React from 'react';
import {ArticleMultilayerDrillData, ArticleMultilayerParts} from '../article-multilayer-plan.models';
import {ArticleMultilayerPartTypeHelper} from '../article-multilayer-part-type-helper';
import {StringHelper} from '../../../../../common/string-helper';

interface Props {
  model: ArticleMultilayerParts;
  drillData: ArticleMultilayerDrillData;
  column: number;
  drillEditLocked: boolean;
  drillStart: { partIndex: number, column: number } | null;
  drillOverlay: boolean[] | null;
  drillColumnMouseOver: () => void;
  onSelect: () => void;
}
function getDrillClass(locked: boolean, drillData: ArticleMultilayerDrillData): string {
  const classes = ['height-fix', 'drill-cell', 'drilled'];
  switch (drillData.type) {
    case 'Via gefüllt':
      classes.push('viafilled');
      break;
    case 'Microvia':
      classes.push('microvia');
      break;
    case 'DK':
      classes.push('dk');
      break;
  }

  if (!locked) {
    classes.push('click');
  }

  return StringHelper.concat(classes, ' ');
}

export function ArticleMultilayerPlanDrillCell(props: Props) {
  const type = ArticleMultilayerPartTypeHelper.get(props.model.type);
  const isSelectedColumn = props.drillStart?.column === props.column;
  const isSelectedPart = props.drillStart?.partIndex === props.model.index;
  const isPartDrilled = props.model.drill_data[props.column] === true;
  const overlay = isSelectedColumn && props.drillOverlay !== null ? props.drillOverlay[props.model.index] : null;
  const drillColumnMouseOver: () => void = () => {
    if (isSelectedColumn) {
      props.drillColumnMouseOver();
    }
  };

  if (isSelectedColumn && isSelectedPart) {
    return <>
      <td className={'height-fix drill-cell selected' + (props.drillEditLocked ? '' : ' click')}
          onMouseOver={() => drillColumnMouseOver()}
          onClick={() => props.onSelect()}/>
      <td className={'height-fix drill-cell'}>
        <div className={'rounded-full-center color-class ' + type.color_class}/>
      </td>
    </>;
  } else if (overlay === true || (!isSelectedColumn && isPartDrilled)) {
    return <>
      <td className={getDrillClass(props.drillEditLocked, props.drillData)}
          onMouseOver={() => drillColumnMouseOver()}
          onClick={() => props.onSelect()}/>
      <td className={'height-fix drill-cell'}>
        <div className={'rounded-full-center color-class ' + type.color_class}/>
      </td>
    </>;
  } else {
    return <>
      <td className={'height-fix drill-cell' + (props.drillEditLocked ? '' : ' click')}
          colSpan={2}
          onMouseOver={() => drillColumnMouseOver()}
          onClick={() => props.onSelect()}>
        <div className={'rounded-full-center color-class ' + type.color_class}/>
      </td>
    </>;
  }
}
