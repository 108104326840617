import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import * as React from 'react';
import {MultilayerPlanCreate} from './multilayer-plan-create';
import {
  ArticleMultilayerPlan,
  ArticleMultilayerPlanModel,
} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.models';
import {ArticleSpecification} from '../../envisia/article/models/article-specification.model';
import {StateService} from '@uirouter/angular';
import {ReactBaseComponent} from '../../react/base/react-base.component';

const containerElementName = 'reactMultilayerPlanContainer';

@Component({
  selector: 'multilayer-plan-create',
  template: `<div #${containerElementName}></div>`,
})
export class MultilayerPlanCreateComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() changes: number;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() saveBtnPressed = 0;
  @Output() saveBtnLocked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modelChanged: EventEmitter<ArticleMultilayerPlanModel> = new EventEmitter<ArticleMultilayerPlanModel>();

  constructor(private stateService: StateService,
              private ngZone: NgZone) {
    super();
  }

  redirect(model: ArticleMultilayerPlan): void {
    this.ngZone.run(() => {
      this.stateService.go('a.multilayerplan.update', {id: model.shared_id}, {});
    });
  }

  protected override renderComponent() {
    const _ = React.version;
    return <MultilayerPlanCreate modelSaved={m => this.redirect(m)}
                                 modelChanged={m => this.modelChanged.emit(m)}
                                 specification={this.specification}
                                 saveBtnPressed={this.saveBtnPressed}
                                 saveBtnLocked={sbc => this.saveBtnLocked.emit(sbc)}
    />;
  }

}
