import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeliveredFrame} from '../models/frame.models';
import {WorkflowAdditionalSimple} from '../../../envisia/workflow/models/workflow.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import Helper from '../../../helper/helper';
import {from, Subscription} from 'rxjs';
import {AlertService} from '../../../common/alert-service/alert.service';
import {FrameService} from '../workflow-services/frame.service';
import {debounceTime} from 'rxjs/operators';
import {ProducerService} from '../../producer/producer.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {KanbanApprovalModalComponent} from '../kanban/kanban-approval-modal.component';
import {Money} from '../../../common/money/Money';

@Component({
  selector: 'canban-box',
  templateUrl: './canban-box.component.html',
  styles: [
    '.approval_wrapper { text-align: right; cursor: pointer; }',
    '.approval-addon { margin-left: 10px; margin-right: 10px; }',
  ]
})
export class CanbanBoxComponent implements OnInit, OnDestroy {
  @Input() workflowId: number;
  @Input() delivered: DeliveredFrame;
  @Input() data: WorkflowAdditionalSimple;
  @Input() inventory: number;
  @Input() article: string;
  @Input() producer?: string;
  @Input() isCn: boolean;
  @Input() lose: number;
  currentInProduction: string;
  reproductionTime?: number;
  approval_class: string;
  canbanForm: UntypedFormGroup;
  formSubscription?: Subscription;
  errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private frameService: FrameService,
              private producerService: ProducerService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {

    this.canbanForm = this.fb.group({
      'production_approval': [this.data.production_approval],
      'minimum_stock': [this.data.minimum_stock],
      'new_production_within': [this.data.new_production_within],
      'fetch_quantity_delivered': [this.data.fetch_quantity_delivered],
      'customer_fetch_cycle': [this.data.customer_fetch_cycle],
    });

    this.canbanForm.valueChanges.pipe(debounceTime(500)).subscribe(values => {
      this.errors = {};
      this.frameService.kanbanSave(this.workflowId, values).subscribe(() => {
        this.alertService.add('success', 'Kanban Werte erfolgreich aktualisiert!');
      }, (response) => {
        this.errors = response.error ? response.error : {};
        this.alertService.add('danger', 'Beim Aktualisieren der Kanban Werte ist ein Fehler aufgetreten!');
      });
    });
    this.frameService.productionValues(this.article).subscribe(value => {
      this.currentInProduction = value.prod + ' (Warte: ' + value.waiting + ')';
    });
    if (this.producer) {
      this.producerService.detail(this.producer).subscribe(p => {
        this.reproductionTime = p.reproduction_time;
        if (this.prefillNewInProd()) {
          this.canbanForm.patchValue(
            {'new_production_within': this.reproductionTime},
            {emitEvent: false, onlySelf: true});
        }
      });
    }

    // remove thousand separator
    const actual = parseInt((<string>this.delivered.actual).split('.').join(''), 10);

    if (this.data.production_approval < actual) {
      this.approval_class = 'label label-danger';
    } else if (this.data.production_approval === actual) {
      this.approval_class = 'label label-warning';
    } else {
      this.approval_class = 'label label-success';
    }
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  private prefillNewInProd(): boolean {
    return this.isCn
      && this.lose > 1
      && this.producer
      && (this.data.new_production_within === null || this.data.new_production_within === undefined);
  }

  showApprovalModal() {
    const ref = this.modalService.open(KanbanApprovalModalComponent, {windowClass: 'modal2-slg'});
    ref.componentInstance.workflowId = this.workflowId;

    ref.componentInstance.clickevent.subscribe(($e) => {
      this.data.production_approval = $e;
      this.data.approval_money = Money.stringify($e, 0);
    });

    from(ref.result).subscribe(res => {
      this.data.production_approval = res;
      this.data.approval_money = Money.stringify(res, 0);
    }, () => {
    });
  }

  /** Helper Methods */
  productionApproval() {
    return (
      Helper.isInt(this.canbanForm.value.production_approval) ?
        Helper.toInt(this.canbanForm.value.production_approval) : 0
    );
  }

  inventoryQuantity() {
    return Helper.defined(this.inventory) ? Helper.toInt(this.inventory) : 0;
  }

  minimumStock() {
    return (
      Helper.isInt(this.canbanForm.value.minimum_stock) ?
        Helper.toInt(this.canbanForm.value.minimum_stock) : 0
    );
  }

  deliveredQuantity() {
    return Helper.defined(this.delivered.actual) ? Helper.toInt(this.delivered.actual) : 0;
  }

  currentlyInProduction() {
    return (
      Helper.defined(this.canbanForm.value.currently_in_production) ?
        Helper.toInt(this.canbanForm.value.currently_in_production) : 0
    );
  }

  minimumDelivery() {
    const quantityFromInventoryOrProduction = this.inventoryQuantity() + this.currentlyInProduction();
    const minimum = this.minimumStock();
    const production = this.productionApproval();
    const productionDefined = Helper.isInt(this.canbanForm.value.production_approval);
    const delivered = this.deliveredQuantity();

    if (quantityFromInventoryOrProduction < minimum) {
      if (productionDefined && production > delivered) {
        return 'ACHTUNG: Mindest Lagerbestand unterschritten. Bitte Produktionsauftrag oder Bestellung erstellen.';
      } else if (productionDefined && production <= delivered) {
        return 'ACHTUNG: Mindest Lagerbestand unterschritten und Produktionsfreigabe erreicht. Bitte Kundenfreigabe einholen.';
      } else {
        return 'ACHTUNG: Mindest Lagerbestand unterschritten.';
      }
    } else if (productionDefined && production <= delivered) {
      return 'ACHTUNG: Produktionsfreigabe erreicht. Bitte Kundenfreigabe einholen.';
    } else {
      return null;
    }
  }

}
