import { Component } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'contract-create-order-nr-error-modal',
  templateUrl: './contract-create-order-nr-error-modal.component.html'
})
export class ContractCreateOrderNrErrorModalComponent {

  constructor(private activeModal: NgbActiveModal) {
  }

  close(): void {
    this.activeModal.dismiss();
  }

  force(): void {
    this.activeModal.close();
  }

}
