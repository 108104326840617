import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaterialOrderPosition} from '../material-order.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from} from 'rxjs';
import {MaterialOrderPositionStatusSelectComponent} from './material-order-position-status-select.component';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {RightService} from '../../../../common/right-service/right.service';

@Component({
  selector: 'material-order-position-status',
  templateUrl: './material-order-position-status.component.html'
})
export class MaterialOrderPositionStatusComponent implements OnInit {
  @Input() id: number;
  @Input() status: number;
  @Input() deleted = false;
  @Input() edit = true;
  @Output() updatePosition = new EventEmitter<MaterialOrderPosition>();
  canBeEdited = false;

  constructor(private modalService: NgbModal, private rightService: RightService) {
  }

  ngOnInit(): void {
    this.canBeEdited = this.rightService.has('material.order.status') && this.edit;
  }

  openModal(): void {
    if (this.canBeEdited) {
      const modalRef = this.modalService.open(MaterialOrderPositionStatusSelectComponent, {windowClass: 'modal2-smm'});
      modalRef.componentInstance.id = this.id;
      modalRef.componentInstance.status = this.status;

      from(modalRef.result).subscribe(position => {
        this.status = position.status;
        this.updatePosition.emit(position);
      }, ignoreRejection);
    }
  }
}
