import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ArticleBaseMaterialFormWithKey,
  ArticleManufacturerForm,
  ArticleSpecification,
  ArticleSpecificationNameForm
} from '../models/article-specification.model';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {CacheableHttpService} from '../../../common/cacheable-http/cacheable-http.service';
import {map} from 'rxjs/operators';
import {sortedManufacturerSpec} from '../main/helper/specification-helper';

const ARTICLE_SPECIFICATION = 'article_specification';

@Injectable({providedIn: 'root'})
export class ArticleSpecificationService {

  private baseUri1 = '/api/v1/article/specification/';
  private baseUri2 = '/article/v2/';

  constructor(private http: HttpClient,
              private cacheableHttpService: CacheableHttpService) {
  }

  public description(): Observable<ArticleSpecification[]> {
    return this.descriptionUnfiltered().pipe(map(values => values.filter(value => value.production_step)));
  }

  public descriptionUnfiltered(): Observable<ArticleSpecification[]> {
    return this.cacheableHttpService.get<EnvisiaObjects<ArticleSpecification>>(
      ARTICLE_SPECIFICATION,
      this.baseUri2 + 'specification/description/'
    ).pipe(map(value => value.objects));
  }

  public descriptionUncached(): Observable<ArticleSpecification[]> {
    return this.http.get<EnvisiaObjects<ArticleSpecification>>(
      this.baseUri2 + 'specification/description/'
    ).pipe(map(value => value.objects));
  }

  public descriptionUncachedHideDeleted(): Observable<ArticleSpecification[]> {
    return this.http.get<EnvisiaObjects<ArticleSpecification>>(
      this.baseUri2 + 'specification/description/hide/deleted'
    ).pipe(map(value => value.objects));
  }

  public specification(cached: boolean = true): Observable<{ [key: string]: ArticleSpecification }> {
    const obs = cached ? this.descriptionUnfiltered() : this.descriptionUncached();
    return obs.pipe(
      map(objects => {
        const data = {};
        objects.forEach(value => {
          data[value.name] = value;
        });
        return data;
      })
    );
  }

  public get(name: string): Observable<ArticleSpecification> {
    return this.http.get<EnvisiaObject<ArticleSpecification>>(this.baseUri1 + name + '/').pipe(
      map(value => value.object)
    );
  }

  public setProdRelease(name: string, active: boolean): Observable<ArticleSpecification> {
    const encoded = encodeURIComponent(name);
    return this.http.post<EnvisiaObject<ArticleSpecification>>(this.baseUri1 + encoded + '/production/release/',
      {production: active}).pipe(
      map(value => value.object)
    );
  }

  public setPriceRelease(name: string, active: boolean): Observable<ArticleSpecification> {
    const encoded = encodeURIComponent(name);
    return this.http.post<EnvisiaObject<ArticleSpecification>>(this.baseUri1 + encoded + '/price/release/',
      {price: active}).pipe(
      map(value => value.object)
    );
  }

  public setNames(name: string, form: ArticleSpecificationNameForm): Observable<ArticleSpecification> {
    const encoded = encodeURIComponent(name);
    return this.http.post<ArticleSpecification>(this.baseUri1 + encoded + '/names/', form);
  }

  public manufacturerList(): Observable<string[]> {
    return this.get('manufacturer')
      .pipe(map(spec => sortedManufacturerSpec(spec).filter(a => !(a.data.de_compliant === false)).map(v => v.key)));
  }

  public list(): Observable<ArticleBaseMaterialFormWithKey[]> {
    return this.get('manufacturer').pipe(map(spec => sortedManufacturerSpec(spec)));
  }

  public insertBaseMaterial(data: ArticleManufacturerForm): Observable<ArticleSpecification> {
    return this.http.post<EnvisiaObject<ArticleSpecification>>(this.baseUri1 + 'manufacturer/base-material/', data)
      .pipe(map(value => value.object));
  }

  public saveBaseMaterial(data: ArticleManufacturerForm): Observable<ArticleSpecification> {
    return this.http.put<EnvisiaObject<ArticleSpecification>>(this.baseUri1 + 'manufacturer/base-material/', data)
      .pipe(map(value => value.object));
  }
}
