import {ListService} from './search.service';
import {LocalStorage} from '../storage/local-storage';
import {Lister} from '../wrapper.models';
import {EnvisiaLocation} from '../location/envisia-location';
import {Subscription, SubscriptionLike} from 'rxjs';
import Helper from '../../helper/helper';
import {AfterViewInit, OnDestroy, OnInit, ViewChild, Directive, ErrorHandler} from '@angular/core';
import {NgForm} from '@angular/forms';
import {DateHelper} from '../../helper/date-helper';
import {DateRange} from '../../core/date-range/date-range.models';
import {MailModalService} from '../../envisia/mail/mail-modal.service';
import {debounceTime} from 'rxjs/operators';
import {deepCopy} from '../../helper/deep-copy';
import {noop} from '../../helper/noop';
import {ArticleSpecification} from '../../envisia/article/models/article-specification.model';
import {fillData} from '../../envisia/article/main/helper/specification-helper';
import {HttpErrorResponse} from '@angular/common/http';

@Directive()
export abstract class SearchBaseComponent<Y extends ListService<T>, T> implements OnInit, OnDestroy, AfterViewInit {
  flags: { de: string, cn: string } = {
    de: '/ui/assets/images/flags/de.png',
    cn: '/ui/assets/images/flags/cn.png',
  };

  // Forms
  @ViewChild('changeForm') changeForm: NgForm;
  public filterFormErrors: {[key: string]: any} = {};
  protected handles400Errors = false;
  public list: Lister<T>;
  // Services
  protected abstract service: Y;
  protected abstract locationService: EnvisiaLocation;
  protected abstract storageService: LocalStorage;
  protected abstract errorHandler: ErrorHandler;
  protected mailModalService: MailModalService;
  // Data
  protected abstract listData: Lister<T>;
  protected abstract type: string;
  protected specification: { [key: string]: ArticleSpecification } | undefined = undefined;
  protected statusList: Set<string> = new Set(['status']);
  protected lastRequest?: Subscription;
  protected initalizeFields: string[] = [];
  public specificationData: { key: string; value: string; }[] = [];
  public data?: { [key: string]: string; };
  // Observer
  protected debounceTime = 500; // debounce the form so that we do not make unnecessary queries
  // caches the query
  protected cachedQuery: any;
  private subscription: Subscription;
  private locationSubscription: SubscriptionLike;
  private initialized = false;

  static contains(a, obj) {
    for (let i = 0; i < a.length; i++) {
      if (a[i] === obj) {
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    this.list = this.listData;
    this.sanitizeLocation();
    if (this.specification && this.specification['surface_area']) {
      this.initData(this.specification['surface_area']);
    }
    this.locationSubscription = this.locationService.subscribe();
  }

  ngAfterViewInit(): void {
    this.subscription = this.changeForm.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(() => {
      if (this.initialized) {
        this.search();
      }
      this.initialized = true;
    });
  }

  ngOnDestroy(): void {
    // important, since else we will call this.EnvisiaLocation
    // even after the view get's destroyed and that will cause
    // strange routing bugs when called via uiSref/[href]
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }

  protected initData(spec: ArticleSpecification): void {
    this.specificationData = [];
    if (!!spec.values2) {
      this.specificationData = fillData(spec);
    }
  }

  query(any?): any {
    if (any) {
      this.cachedQuery = any;
      this.locationService.search(any);
      return this.cachedQuery;
    } else {
      if (!this.cachedQuery) {
        this.cachedQuery = this.locationService.search();
      }
      return this.cachedQuery;
    }
  }

  sanitizeLocation(query?: any): void {
    query = query ?? this.query();
    this.initalizeFields.forEach(value => {
      const currentValue = query[value];
      if (currentValue === undefined || currentValue === null) {
        query[value] = '';
      }
    });
    this.data = deepCopy(query);
  }

  apply(query: string, data): void {
    this.query(query);
    this.list = data;
  }

  serviceCall(query: string): void {
    // cancel a ongoing request
    if (this.lastRequest) {
      this.lastRequest.unsubscribe();
    }

    this.filterFormErrors = {};
    this.lastRequest = this.service.list(query).subscribe((data) => {
      this.apply(query, data);
    }, (error: HttpErrorResponse) => {
      this.filterError(error);
    });
  }

  filterError(error: HttpErrorResponse): void {
    if (this.handles400Errors && error.status === 400 && error.error) {
      this.filterFormErrors = error.error ?? {};
    } else {
      this.errorHandler.handleError({error: error, list_type: this.type, message: 'list could not handle errors'});
    }
  }

  newOrder(old_sort, old_order, sort, ordering, asc, desc): string {
    noop(this);
    if (old_order === 'ASC' || old_order === 'ASC_NULLS_FIRST' || old_order === 'ASC_NULLS_LAST' && old_sort === sort) {
      return desc || 'DESC';
    } else if (old_order === 'DESC' || old_order === 'DESC_NULLS_FIRST' || old_order === 'DESC_NULLS_LAST' && old_sort === sort) {
      return asc || 'ASC';
    } else {
      return ordering;
    }
  }

  sortUrl(sort: string, ordering: string, asc?: string, desc?: string): string {
    // Fixme: Cache LocationSearch, we only need to call it once
    const query = this.query();
    const empty: any = deepCopy(query);
    empty.order = this.newOrder(query.sort, query.order, sort, ordering, asc, desc);
    empty.sort = sort;
    const data = EnvisiaLocation.httpParams(empty).toString();
    const path = this.locationService.path();
    return `#${path}?${data}`;
  }

  sort(e: Event, sort: string, ordering: string, asc?: string, desc?: string): void {
    e.preventDefault();
    const query = this.query();
    query.order = this.newOrder(query.sort, query.order, sort, ordering, asc, desc);
    query.sort = sort;
    this.serviceCall(query);
  }

  goto(page: number): void {
    const query = this.query();
    query.page = page;

    this.serviceCall(query);
  }

  search(keep?: boolean): void {
    const data = deepCopy(this.data);
    const query = Helper.assign(this.query(), data);

    if (!keep) {
      query.page = 1;
    }

    this.serviceCall(query);
  }

  reload(): void {
    const q = this.query();
    this.serviceCall(q);
  }

  downloadUrl(url: string, and?: boolean) {
    if (this.storageService) {
      const query = this.query();
      const empty = deepCopy(query);
      delete empty.page;
      const data = EnvisiaLocation.httpParams(empty).toString();
      let outUrl;
      if (Object.keys(empty).length === 0) {
        outUrl = url;
      } else if (and === true) {
        outUrl = `${url}&${data}`;
      } else {
        outUrl = `${url}?${data}`;
      }

      return outUrl;
    } else {
      return '';
    }
  }

  trash(newParam: string): void {
    const empty = deepCopy(this.query());
    delete empty[newParam];
    delete this.data[newParam];
    this.serviceCall(empty);
  }

  q(data: string, outer_name?: string): boolean {
    const name = outer_name ? outer_name : 'status';
    this.statusList.add(name);
    const query = this.query();
    if (query.status === undefined && !query.pricecheck && data === 'all') {
      return true;
    } else if (data === 'kanban') {
      return query.kanban === 'true' || query.kanban === true;
    } else if (data === 'urgency') {
        return query.urgency === 'true' || query.urgency === true;
    } else if (data === 'noinvoice') {
      return query.state === '3' || query.state === 3;
    } else if (data === 'noprice') {
      return query.approval === 'true' || query.approval === true;
    } else if (data === 'watchlist') {
      return query.watchlist === 'true' || query.watchlist === true;
    } else {
      if (data === 'all') {
        return null;
      } else {
        return query[name] === data;
      }
    }
  }

  get q2(): {[key: string]: any} {
    return this.query() as {[key: string]: any};
  }

  badgeToggle(badgeName: string, value: any): void {
    const q = this.query();
    if (
      q[badgeName] === null ||
      q[badgeName] === undefined ||
      q[badgeName].toString() !== value?.toString()
    ) {
      q[badgeName] = value;
    } else {
      q[badgeName] = null;
      delete q[badgeName];
    }

    this.serviceCall(q);
  }

  badge(badge_id: string, outer_name?: string, deselect?: boolean): void {
    const name = outer_name ? outer_name : 'status';
    this.statusList.add(name);
    const query = this.query();
    delete query.page;
    if (badge_id === 'all') {
      this.statusList.forEach((data) => {
        query[data] = null;
        delete this.data[data];
      });
    } else if (badge_id === 'kanban') {
      if (query.kanban) {
        query.kanban = null;
      } else {
        query.kanban = 'true';
      }
    } else if (badge_id === 'urgency') {
      if (query.urgency) {
        query.urgency = null;
      } else {
        query.urgency = 'true';
      }
    } else if (badge_id === 'noprice') {
      if (query.approval) {
        query.approval = null;
      } else {
        query.approval = 'true';
      }
    } else if (badge_id === 'noinvoice') {
      if (query.state) {
        query.state = null;
      } else {
        query.state = 3;
      }
    } else {
      if (deselect && query[name] === badge_id) {
        this.data[name] = null;
        query[name] = null;
      } else {
        this.data[name] = badge_id;
        query[name] = badge_id;
      }
    }

    this.serviceCall(query);
  }

  toggleWatchlist(): void {
    const query = this.query();
    query.watchlist = !query.watchlist ? true : null;
    this.serviceCall(query);
  }

  defaultMailCallback(id: number | string): void {
    noop(this, id);
  }

  defaultMail(id: number | string, workflowId: number): void {
    this.mailModalService.open(
      id,
      this.type,
      null,
      false,
      'de',
      workflowId,
      true
    ).subscribe(() => {
      this.defaultMailCallback(id);
    });
  }

  currentSearchType(): string | null {
    const query = this.query();
    return query.typ;
  }

  updateSearchType(data: string | null): void {
    const query = this.locationService.search();
    if (data === null) {
      delete query.typ;
    } else {
      query.typ = data;
    }
    this.serviceCall(query);
  }

  updateDateRange(date: DateRange): void {
    const query = this.locationService.search();
    query.date_start = DateHelper.format(date.start);
    query.date_end = DateHelper.format(date.end);
    query.page = 1;
    this.serviceCall(query);
  }

  updateDateRangeForStart(date: DateRange): void {
    const query = this.locationService.search();
    query.range_start_start = DateHelper.format(date.start);
    query.range_start_end = DateHelper.format(date.end);
    query.page = 1;
    this.serviceCall(query);
  }

  updateDateRangeForEnd(date: DateRange): void {
    const query = this.locationService.search();
    query.range_end_start = DateHelper.format(date.start);
    query.range_end_end = DateHelper.format(date.end);
    query.page = 1;
    this.serviceCall(query);
  }

  updateStringValue(key: string, value: string | null | undefined): void {
    this.data[key] = value;
    const data = deepCopy(this.data);
    const query = Helper.assign(this.query(), data);
    query.page = 1;
    this.serviceCall(query);
  }
}
