<div class="filter-row">
  <div class="filter-left">
    <search-type typ="de" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>
    <search-type typ="cn" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>


    <button class="btn btn-success btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1') || q('all')}">
      Aktive
    </button>

    <button class="btn btn-warning btn-sm" type="button" (click)="badge('2')" [ngClass]="{active: q('2')}">
      Gesperrt
    </button>

    <button class="btn btn-danger btn-sm" type="button" (click)="badge('3')" [ngClass]="{active: q('3')}">
      Gelöscht
    </button>


    <button class="btn btn-default btn-sm" type="button" (click)="badge('4')" [ngClass]="{active: q('4')}">
      Alle
    </button>

    &nbsp;
    <a class="btn btn-default btn-sm" [title]="'export'" [href]="downloadShort" target="_blank">
      <i class="fa fa-download"></i>
    </a>

    <a class="btn btn-default btn-sm" [title]="'long export'" [href]="downloadLong" target="_blank">
      <i class="fa fa-archive"></i>
    </a>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th></th>
      <th>
        <a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">
          SDS-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'oa_nr', 'ASC')" [href]="sortUrl('oa_nr', 'ASC')">
          0A-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_nr', 'ASC')" [href]="sortUrl('kd_art_nr', 'ASC')">
          Kd-Art-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_extend1', 'ASC')" [href]="sortUrl('kd_art_extend1', 'ASC')">
          Kd-Art-Bez. 1
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_extend2', 'ASC')" [href]="sortUrl('kd_art_extend2', 'ASC')">
          Kd-Art-Bez. 2
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer', 'ASC')" [href]="sortUrl('customer', 'ASC')">
          Kunde
        </a>
      </th>
      <th class="align-right">
        <a (click)="sort($event, 'surface', 'ASC')" [href]="sortUrl('surface', 'ASC')">
          Fläche
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'execution', 'ASC')" [href]="sortUrl('execution', 'ASC')">
          Ausführung
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'surface_area', 'ASC')" [href]="sortUrl('surface_area', 'ASC')">
          Oberfläche
        </a>
      </th>
      <th class="align-right">
        <a (click)="sort($event, 'cu_thickness', 'ASC')" [href]="sortUrl('cu_thickness', 'ASC')">
          Cu Endst.
        </a>
      </th>
      <th class="align-right">
        <a (click)="sort($event, 'endintensity', 'ASC')" [href]="sortUrl('endintensity', 'ASC')">
          LP Endst.
        </a>
      </th>
      <th class="align-right">
        <a (click)="sort($event, 'drilling', 'ASC')" [href]="sortUrl('drilling', 'ASC')">
          Bohr.
        </a>
      </th>
      <th class="align-right">Gewicht</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    <tr class="filterrow">
      <th></th>
      <th>
        <input type="text" class="form-control input-sm" name="sds_nr" [(ngModel)]="data.sds_nr" [autofocus]>
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="oa_nr" [(ngModel)]="data.oa_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="kd_art_nr" [(ngModel)]="data.kd_art_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="kd_art_extend1" [(ngModel)]="data.kd_art_extend1">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="kd_art_extend2" [(ngModel)]="data.kd_art_extend2">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="customer" [(ngModel)]="data.customer">
      </th>
      <th colspan="9"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let object of list.objects">
      <td [ngSwitch]="object.status">
        <span class="label label-success" *ngSwitchCase="0">Aktiv</span>
        <span class="label label-warning" *ngSwitchCase="1">Gesperrt</span>
        <span class="label label-danger" *ngSwitchCase="2">Gelöscht</span>
      </td>
      <td>
        <a uiSref="a.article.workflow.detail"
           class="clickable"
           [uiParams]="{id:object.oa_nr}"
           [ngClass]="{deleted: object.date_deleted}">
          {{ object.sds_nr }}
        </a>
      </td>
      <td>
        <a uiSref="a.article.workflow.detail" [uiParams]="{id:object.oa_nr}" class="clickable">
          {{ object.oa_nr }}
        </a>
      </td>
      <td>
        <a uiSref="a.article.workflow.detail" [uiParams]="{id:object.oa_nr}" class="clickable">
          {{ object.kd_art_nr }}
          <span *ngIf="object.kd_art_index" class="label label-default">{{ object.kd_art_index }}</span>
        </a>
      </td>
      <td>
        <a uiSref="a.article.workflow.detail" [uiParams]="{id:object.oa_nr}" class="clickable">
          {{ object.kd_art_extend1 }}
        </a>
      </td>
      <td>
        <a uiSref="a.article.workflow.detail" [uiParams]="{id:object.oa_nr}" class="clickable">
          {{ object.kd_art_extend2 }}
        </a>
      </td>

      <td>{{ object.customer|limitTo:40 }}</td>
      <td class="align-right">{{ object.surface|money:2 }}</td>
      <td>{{ object.execution }}</td>
      <td>{{ object.surface_area }}</td>
      <td class="align-right">{{ object.cu_thickness|money:0 }}</td>
      <td class="align-right">{{ object.end_intensity|money:2 }}</td>
      <td class="align-right">{{ object.drilling|money:2 }}</td>
      <td class="align-right" *ngIf="object.weight === null"></td>
      <td class="align-right" *ngIf="object.weight !== null && object.weight < 1000">
        {{ object.weight|money:2 }}&nbsp;g
      </td>
      <td class="align-right" *ngIf="object.weight !== null && object.weight >= 1000">
        {{ (object.weight/1000)|money:2 }}&nbsp;kg
      </td>

      <td>
        <button type="button" class="ev-button" (click)="openInventory(object)">
          <i class="fa fa-cubes"></i>
        </button>
      </td>
      <td>
        <a uiSref="a.price.check" [uiParams]="{id:object.oa_nr}" *ngIf="('price.detail'|HasPerm)">
          <button class="btn btn-default btn-sm" type="button">
            Preisprüfung
          </button>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
