import {Component, Input, OnInit} from '@angular/core';
import {ShippingInstructionHistory, ShippingInstructionSchema} from '../../article/models/article-extra.models';
import {ArticleCore} from '../../article/models/article.models';

@Component({
  selector: 'shipping-instruction-history-list',
  templateUrl: './shipping-instruction-history-list.component.html'
})
export class ShippingInstructionHistoryListComponent implements OnInit {
  @Input() schema: ShippingInstructionSchema[];
  @Input() list: ShippingInstructionHistory[];
  @Input() article?: ArticleCore;

  current?: ShippingInstructionHistory;

  constructor() {
  }

  ngOnInit(): void {
    this.current = this.list?.length > 0 ? this.list[0] : null;
  }

  selectHistory(history: ShippingInstructionHistory): void {
    this.current = history;
  }
}
