import {Component, Input, OnInit} from '@angular/core';
import {InvoiceJob} from '../invoice.models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {InvoiceAutomationService} from '../services/invoice-automation.service';
import {DateRange} from '../../../../core/date-range/date-range.models';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'invoice-automation',
  templateUrl: './invoice-automation.component.html'
})
export class InvoiceAutomationComponent implements OnInit {
  @Input() count: number;
  @Input() errors: InvoiceJob[];
  @Input() working: InvoiceJob[];
  form: UntypedFormGroup;
  disabled = false;
  formErrors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private service: InvoiceAutomationService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      date: [null, Validators.required],
      start: [null],
      end: [null],
      sending_instant: [true, Validators.required],
    });
  }

  updateDateRange($event: DateRange): void {
    this.form.patchValue({
      start: $event.start,
      end: $event.end
    });
    this.service.count(this.form.value).subscribe(response => this.count = response);
  }

  create(): void {
    this.formErrors = {};
    this.disabled = true;
    this.service.create(this.form.value).subscribe(() => {
      this.alertService.add('success', 'Rechnungslauf erfolgreich gestartet!');
      this.form.reset({sending_instant: this.form.value.sending_instant});
      this.refresh('working');
      this.disabled = false;
    }, (response: HttpErrorResponse) => {
      if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
        this.formErrors = response.error;
      }
      this.alertService.add('danger', 'Fehler beim Starten eines Rechnungslaufs!');
      this.disabled = false;
    });
  }

  refresh(name: 'error' | 'working'): void {
    if (name === 'error') {
      this.service.errors().subscribe(response => {
        this.errors = response;
      });
    } else if (name === 'working') {
      this.service.working().subscribe(response => {
        this.working = response;
      });
    }
  }

  hide(index: number, obj: InvoiceJob): void {
    this.service.hide(obj.id).subscribe(() => {
      this.errors.splice(index, 1);
    }, () => {
      this.alertService.add('danger', 'Beim Ausblenden des Auftrags ist ein Fehler aufgetreten!');
    });
  }

  restart(index: number, obj: InvoiceJob): void {
    this.service.restart(obj.id).subscribe(() => {
      this.errors.splice(index, 1);
      this.refresh('working');
    }, () => {
      this.alertService.add('danger', 'Beim Neustarten des Auftrags ist ein Fehler aufgetreten!');
    });
  }

}
