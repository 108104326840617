import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../model/customer';

@Component({
  selector: 'customer-note-modal',
  templateUrl: './customer-note-modal.component.html'
})
export class CustomerNoteModalComponent implements OnInit {
  @Input() customer: Customer;
  note?: string;
  salesNote?: string;

  ngOnInit(): void {
    this.note = this.customer.note;
    this.salesNote = this.customer.sales_note;
  }

}
