import * as React from 'react';
import {EvConfirm} from '../../../../../react/modal/EvConfirm';

interface Props {
  ignoreAllowed?: boolean;
  response: (confirmed: boolean | null) => void;
}

export function ArticleMultilayerDifferenceWarningModal(props: Props) {
  return <>
    <div className='modal-body'>
      <div className={'row'}>
        <div className={'col-sm-1'}>
          <i className={'fa fa-warning fa-2x'} style={{color: 'red'}}></i>
        </div>
        <div className={'col-sm-11'}>
          <p>
            Bitte passen Sie den Aufbauplan vor dem Speichern an
          </p>
          <p>
            oder korrigieren Sie die entsprechenden Werte im Artikel.
          </p>
          <p>
            <strong>
              Soll trotzdem gespeichert werden?
            </strong>
          </p>
        </div>
      </div>
    </div>
    <div className='modal-footer'>
      <button type='button' className='btn btn-danger' onClick={() => props.response(false)}>
        Abbrechen
      </button>
      { !props.ignoreAllowed ? null :
        <button type='button' className='btn btn-danger' onClick={() => props.response(null)}>
          Nicht Speichern
        </button>
      }
      <button type='button' className='btn btn-success' onClick={() => props.response(true)}>
        Speichern
      </button>
    </div>
  </>;
}
