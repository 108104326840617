<div class="modal-header">
  <h3 class="modal-title">Basismaterial hinzufügen</h3>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="create()">

  <div class="modal-body">

    <div class="form-group">
      <label for="input-tg" class="col-sm-2 control-label">Name</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="input-tg" name="name" formControlName="name"
               [ngClass]="{red_alert: errors['name']}" [autofocus]>
      </div>
    </div>

    <article-config-main-form [form]="dataForm" [errors]="errors" prefix="data">
    </article-config-main-form>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success pull-right" [disabled]="blocked || !form.valid">
      Basismaterial Hinzufügen
    </button>

  </div>

</form>

