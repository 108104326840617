import {Pipe, PipeTransform} from '@angular/core';
import {Money} from './Money';

@Pipe({name: 'euro'})
export class EuroPipe implements PipeTransform {

  transform(value?: number | string, args?: number[] | number): string {
    if (value === null || value === undefined) {
      return '';
    }

    let n: number;
    if (typeof value === 'string') {
      n = Money.parse(value);
    } else {
      n = value;
    }

    return Money.stringify(n, 2) + ' €';
  }

}
