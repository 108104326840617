<form [formGroup]="form" (ngSubmit)="submit()">
  <h3>Material ins Lager einbuchen</h3>

  <div class="row" style="margin-bottom:10px;">
    <div class="col-sm-2">
      Bestellung: <strong>{{order.id}}</strong> <br>
      Lieferant: <strong>{{order.supplier.name}}</strong>
    </div>
    <div class="col-sm-2">
      Status:
      <material-order-status [order]="order"></material-order-status>
    </div>
    <div class="col-sm-8">
      <label for="date_received">
        Wareneingangs-Datum:
        <ev-datepicker class="form-control"
                       id="date_received"
                       formControlName="date_received"
                       [parser]="dateParser"
                       [coreFormat]="true"
                       [writeBack]="false"
                       [allDates]="true"
                       [evClass]="{red_alert: errors['date_received']}">
        </ev-datepicker>
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <h3>Offene Positionen</h3>
      <material-order-intake-form [list]="listData.objects"
                                  [order]="order"
                                  [groups]="groups"
                                  [form]="form"
                                  [errors]="errors"
                                  [markedVariationId]="markedVariationId"
                                  [markedPositionId]="markedPositionId"
                                  [markedTransactionId]="markedTransactionId"
                                  (addTransaction)="addTransaction($event)"
                                  (removeTransaction)="removeTransaction($event)"
                                  (updateOrder)="updateView()"
                                  *ngIf="listData.objects.length > 0">
      </material-order-intake-form>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <material-inventory-transaction-error [errors]="errors"></material-inventory-transaction-error>
      <button type="submit"
              class="btn btn-success pull-right"
              style="margin: 20px 0;"
              [disabled]="lockSubmission && (listData.objects.length > 0)">
        Bestand einbuchen
      </button>
    </div>
  </div>
</form>

<material-order-intake-booked-form [order]="order"
                                   [listBookedData]="listBookedData"
                                   [groups]="groups"
                                   [markedVariationId]="markedVariationId"
                                   [markedTransactionId]="markedTransactionId"
                                   [finderSupplierUri]="finderSupplierUri"
                                   (updateOrder)="updateView()">
</material-order-intake-booked-form>
