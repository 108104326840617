import {Injectable} from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {KanbanListData} from './kanban.model';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {HttpClient} from '@angular/common/http';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class KanbanDeService implements ListService<KanbanListData> {

  constructor(private http: HttpClient) {
  }

  list(query?: { [paramName: string]: any; }): Observable<Lister<KanbanListData>> {
    return this.http.get<Lister<KanbanListData>>(
      '/api/v1/workflow/kanban/de/',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

}
