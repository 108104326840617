<h3>Standard Artikel Kalkulation</h3>
<div [formGroup]="form" class="row">
  <div class="col-sm-6 chrissies">
    <div class="row">
      <div class="col-sm-2">
        <label class="col-sm-3 control-label" title="">
          <img src="/ui/assets/images/flags/de.png">
        </label>
      </div>
      <div *ngIf="('article.calculation'|HasPerm)" class="col-sm-10">
        <price-selector [error]="!!errors['obj.calc_de']"
                        [form]="form"
                        [nullable]="true"
                        [processes]="processes.de"
                        name="calc_de">
        </price-selector>
      </div>
      <div *ngIf="!('article.calculation'|HasPerm)" class="col-sm-10">
        {{customer.calculation_de}}
      </div>
    </div>
  </div>

  <div class="col-sm-6 chrissies">
    <div class="row">
      <div class="col-sm-2">
        <label class="col-sm-3 control-label" title="">
          <img src="/ui/assets/images/flags/cn.png">
        </label>
      </div>
      <div *ngIf="('article.calculation'|HasPerm)" class="col-sm-10">
        <price-selector [error]="!!errors['obj.calc_cn']"
                        [form]="form"
                        [nullable]="true"
                        [processes]="processes.cn"
                        name="calc_cn">
        </price-selector>
      </div>
      <div *ngIf="!('article.calculation'|HasPerm)" class="col-sm-10">
        {{customer.calculation_cn}}
      </div>
    </div>
  </div>
</div>
