<div class="modal-header">
  <h3 class="modal-title">Achtung!</h3>
</div>

<div class="modal-body">
  <p>Möchten Sie wirklich den Status der Position ändern?</p>
  <div class="status-change">
    <table>
      <tbody>
      <tr>
        <td>
          <button type="button" class="btn btn-default" [autofocus]="status === 0" (click)="save(0)">
            Bestellt
          </button>
        </td>
        <td>
          <button type="button" class="btn btn-info" [autofocus]="status === 1" (click)="save(1)">
            Bestätigt
          </button>
        </td>
        <td>
          <button type="button" class="btn btn-warning" [autofocus]="status === 2" (click)="save(2)">
            Gebucht
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="pull-left btn btn-default" (click)="dismiss()">
    Abbrechen
  </button>
</div>
