<div *ngIf="!order.date_deleted" style="display:inline;">
  <span class="label label-default" *ngIf="showOrderedLabel">
    Bestellt ({{order.amount_ordered|money}})
  </span>
  <span class="label label-info" *ngIf="order.amount_confirmed > 0">
    Bestätigt ({{order.amount_confirmed|money}} von {{order.amount_ordered|money}})
  </span>
  <span class="label label-warning" *ngIf="order.amount_booked > 0">
    Gebucht ({{order.amount_booked|money}} von {{order.amount_ordered|money}})
  </span>
  <span class="label label-success" *ngIf="order.amount_price_validated > 0">
    Geprüft ({{order.amount_price_validated|money}} von {{order.amount_price_validated|money}})
  </span>
</div>
<span class="label label-danger" *ngIf="!!order.date_deleted">Gelöscht</span>
