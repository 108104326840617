import {Component, Input, OnInit} from '@angular/core';
import {CustomerId} from '../../model/customer';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../services/contact.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {ContactDepartment} from '../../contact-department/customer-contact-department.models';

@Component({
  selector: 'customer-contact-create',
  templateUrl: './customer-contact-create.component.html',
})
export class CustomerContactCreateComponent implements OnInit {
  @Input() customer: CustomerId;
  @Input() departments: ContactDepartment[];
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};

  constructor(private contactService: ContactService,
              private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private modalRef: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: ['Herr', Validators.required],
      first_name: [null],
      last_name: [null, Validators.required],
      position: [null],
      phone: [null],
      fax: [null],
      mobile: [null],
      mail: [null],
      departments: [[]],
      note: [null],
    });
  }

  save() {
    this.errors = {};
    this.contactService.create(this.customer.id, this.form.value).subscribe(response => {
      this.alertService.add('success', 'Kontakt erfolgreich erstellt!');
      this.modalRef.close(response);
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Kontakt konnte nicht erstellt werden!');
    });
  }

  dismiss() {
    this.modalRef.dismiss();
  }

}
