import {Component, Input} from '@angular/core';

@Component({
  selector: 'comment-business-order-link',
  templateUrl: './comment-business-order-link.component.html',
})
export class CommentBusinessOrderLinkComponent {
  @Input() boId: number;
  @Input() boIndex: number;
  @Input() label: string;

  constructor() {
  }

}
