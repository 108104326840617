<ng-container *ngIf="data.data$|async as obj; else loadingOrError.template">
  <table class="whitetable table marginless-bottom" *ngIf="obj.count > 0">
    <tbody>
    <tr>
      <td class="borderless-top fixed-width-150">CN Bestellungen:</td>
      <td class="borderless-top">
        <a class="btn btn-link btn-sm ml-0 pl-0"
           uiSref="a.workflow.order.list"
           [uiParams]="{order: 'ASC', sort: 'workflow_order_delivery_date', oa_nr: article}">
          <span class="label label-warning">VORHANDEN ({{ obj.count }})</span>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<loading-or-error
  #loadingOrError
  [loadingWrapper]="data"
  [errorMessage]="'Konnte \'CN Bestellungen\' nicht laden'"
  [spinnerSize]="2"
  spinnerName="circle-o-notch">
</loading-or-error>

