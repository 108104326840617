/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import Helper from '../../../helper/helper';
import {Article, SmallArticle} from '../../article/models/article.models';
import {Money} from '../../../common/money/Money';

@Component({
  selector: 'article-extra-utilize',
  templateUrl: './article-extra-utilize.component.html'
})
export class ArticleExtraUtilizeComponent implements OnChanges {
  @Input() quantity?: number;
  @Input() article?: Article | SmallArticle;
  calculated_value: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quantity) {
      this.calculate();
    }
  }

  calculate(): void {
    if (Helper.isNumber(this.quantity) && Helper.isNumber(this.article.data.utilize1)) {

      this.calculated_value = Money.stringify(parseFloat(this.quantity.toString()) * parseFloat(this.article.data.utilize1), 2);
    } else {

      this.calculated_value = '';
    }
  }


}
