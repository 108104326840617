import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MaterialProductGroupRelation} from './material-product-groups.models';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EnvisiaObjects} from '../../../common/wrapper.models';

@Injectable({
  providedIn: 'root'
})
export class MaterialProductGroupsRelationService {
  constructor(private http: HttpClient) {
  }

  list(productGroupId: number): Observable<EnvisiaObjects<MaterialProductGroupRelation>> {
    return this.http.get<EnvisiaObjects<MaterialProductGroupRelation>>(
      `${environment.apiv4uri}material/product/group/${productGroupId}/relations`,
    );
  }

  create(productGroupId: number, groupId: number): Observable<MaterialProductGroupRelation> {
    return this.http.post<MaterialProductGroupRelation>(
      `${environment.apiv4uri}material/product/group/${productGroupId}/relation/${groupId}`,
      {}
    );
  }

  delete(productGroupId: number, groupId: number): Observable<MaterialProductGroupRelation> {
    return this.http.delete<MaterialProductGroupRelation>(
      `${environment.apiv4uri}material/product/group/${productGroupId}/relation/${groupId}`,
    );
  }
}
