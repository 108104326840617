<div class="article-fixed-head">
  <article-top-navigation [article]="article"
                          [multilayerPlan]="multilayerPlan"
                          [events]="eventsSubject.asObservable()">
  </article-top-navigation>

  <article-top [edit]="false" [article]="article"></article-top>
</div>

<div class="row top-buffer" *ngIf="article.date_deleted || article.locked">
  <div class="col-lg-12">
    <div class="alert alert-danger" role="alert">
      <div *ngIf="article.date_deleted"><span class="bold">Artikel wurde gelöscht!</span>
        Bearbeitung nicht möglich!
      </div>
      <div *ngIf="article.locked"><span class="bold">Artikel wurde gesperrt!</span>
        Bearbeitung nicht möglich!
      </div>
    </div>
  </div>
</div>


<div class="row top-buffer">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-3"> Ausführung: <strong>{{ article.data.execution }}</strong></div>
      <div class="col-md-5">
        <button type="button" class="btn btn-sm btn-default" (click)="addAll()" *ngIf="validExecution && ('article.extra'|HasPerm)">
          Fertigungsschritte gem. Ausführung hinzufügen
        </button>
      </div>
      <div class="col-md-2">
        <ev-file-button [url]="'/api/v1/article/production/' + article.oa_nr + '/pdf/'" [link]="false"
                        title="Vorschau Fertigungsliste">
          Vorschau Fertigungsliste
        </ev-file-button>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="label label-danger red_alert mid-label" *ngIf="!validHash && articleProductionList.length > 0">
      <strong>Achtung!</strong> Artikel wurde geändert. Fertigungsliste muss freigegeben werden.
      <button type="button" class="ev-button link-blue" (click)="setHash()" *ngIf="('article.extra'|HasPerm)">
        Fertigungsliste freigeben
      </button>
    </div>
  </div>
</div>

<div class="row top-buffer">

  <div class="col-md-8">
    <h4>Ausgewählte Fertigungsschritte</h4>
    <table class="table whitetable">
      <thead>
      <tr>
        <th style="width: 50px">Nr.</th>
        <th>Bezeichnung</th>
        <th style="width: 65px;">Dauer</th>
        <th style="width: 105px;">Max-Dauer</th>
        <th style="width: 30px;">&nbsp;</th>
        <th style="width: 125px;">&nbsp;</th>
        <th style="width: 80px;">&nbsp;</th>
        <th style="width: 35px;">&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let obj of articleProductionList; let index = index">
        <td>{{ obj.production.nr }}</td>
        <td>
          {{ obj.production.text }}
          <span class="label label-info" *ngIf="obj.article.infoText">Info</span>
          <span class="label label-danger" *ngIf="obj.article.lockText">Sperre</span>
        </td>
        <td>{{ obj.production.duration }}</td>
        <td>{{ obj.production.max_duration }}</td>

        <td>
          <button type="button" class="ev-button" *ngIf="index !== 0 && ('article.extra'|HasPerm)"
                  (click)="up(index, obj.article.ordering)">
            <i class="fa fa-chevron-up"></i>
          </button>
        </td>
        <td>
          <button type="button" class="ev-button" (click)="down(index, obj.article.ordering)"
                  *ngIf="index !== articleProductionList.length - 1 && ('article.extra'|HasPerm)">
            <i class="fa fa-chevron-down"></i>
          </button>
        </td>
        <td>
          <button type="button" class="ev-button" (click)="edit(index, obj)" *ngIf="('article.extra'|HasPerm)">
            <i class="fa fa-pencil"></i>
          </button>
        </td>
        <td>
          <button type="button" class="ev-button"
                  (click)="deleteStep(index, obj.article.ordering)"
                  *ngIf="!obj.article.lockText && ('article.extra'|HasPerm)">
            <i class="fa fa-trash-o"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-4">
    <h4>Alle Fertigungsschritte</h4>
    <table class="table whitetable">
      <thead>
      <tr>
        <th style="width: 40px"></th>
        <th style="width: 50px">Nr.</th>
        <th>Bezeichnung</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let obj of productionStepList; let index = index">
        <td>
          <button type="button" class="ev-button" (click)="create(obj.id)" *ngIf="('article.extra'|HasPerm)">
            <i class="fa fa-chevron-left"></i>
          </button>
        </td>
        <td>{{ obj.nr }}</td>
        <td>{{ obj.text }}</td>
      </tr>
      </tbody>
    </table>
  </div>

</div>

