<div class="modal-header">
  <h3 class="modal-title">Angebotsdetails</h3>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      Angebot:
      <ev-file-button [objId]="obj.id" type="offer" class="darkredlink" *ngIf="obj.id">
        {{ obj.id }} vom {{ obj.date }}
        <img *ngIf="obj.de" src="/ui/assets/images/flags/de.png" title="Es wurden deutsche Preise angeboten">
        <img *ngIf="obj.cn" src="/ui/assets/images/flags/cn.png" title="Es wurden China-Preise angeboten">
      </ev-file-button>
    </div>

    <div class="col-md-12">
      Vorgang:
      <a uiSref="a.workflow.detail" [uiParams]="{id: obj.workflow_id}" target="_blank">
        {{ obj.workflow_id }}
      </a>
    </div>

    <div class="col-md-12 placeholder-top">
      <h3><i class="fa fa-qrcode"></i> Artikel</h3>
    </div>

    <div class="col-md-12">

      <div class="offer-article-info">
        <div>
          <label>SDS-Nr.:</label>
          <a uiSref="a.article.workflow.overview" [uiParams]="{id: obj.article?.oa_nr}" target="_blank" class="bold">
            {{ obj.article.data.sds_nr }}
          </a>
        </div>

        <div>
          <label>Kunden-Art-Nr.:</label>{{ obj.article?.data?.kd_art_nr }}
        </div>
        <div>
          <label>Zuschlag DE:</label><b>{{ obj.article?.data?.surcharge_de }} €</b>
        </div>

        <div>
          <label>0A-Nr.:</label>
          <a uiSref="a.article.workflow.overview" [uiParams]="{id: obj.article?.oa_nr}" target="_blank">
            {{ obj.article.oa_nr }}
          </a>
        </div>

        <div>
          <label>Kunden-Index:</label>{{ obj.article?.data?.kd_art_index }}
        </div>
        <div>
          <label>Schlechte DE:</label><b>{{ obj.article?.data?.schlechte_de }} %</b>
        </div>
        <div>
          <label>Lagerplatz:</label>{{ obj.article?.data?.site_nr }}
        </div>
        <div>
          <label>Kundenbezeich. 1:</label> {{ obj.article?.data?.kd_art_extend1 }}
        </div>
        <div>
          <label>Schlechte CHN:</label> <b>{{ obj.article?.data?.schlechte_cn }} %</b>
        </div>
      </div>

      <div style="float: left;">
        <div class="offer-article-spec">
          <div>
            <label>Platinen-Gr.:</label>
            {{ obj.article?.data?.board_size1 }} x {{ obj.article?.data?.board_size2 }} mm
          </div>
          <div>
            <label>Nutzen-Gr.:</label>
            {{ obj.article?.data?.utilize_size1 }} x {{ obj.article?.data?.utilize_size2 }} mm
          </div>
          <div>
            <label>Nutzenstück:</label>{{ obj.article?.data?.utilize1 }}
          </div>
          <div>
            <label>Fläche:</label>{{ obj.article?.data?.surface }} dm²
          </div>
        </div>
        <div class="offer-article-spec">
          <div>
            <label>Ausführung:</label>{{ obj.article?.data?.execution }}
          </div>
          <div>
            <label>Oberfläche:</label>{{ obj.article?.data?.surface_area }}
          </div>
          <div>
            <label>Basismaterial:</label>{{ obj.article?.data?.manufacturer }}
          </div>
          <div>
            <label>Lötstopplack A:</label>{{ obj.article?.data?.solder_resist_a }}
          </div>
          <div>
            <label>Lötstopplack B:</label>{{ obj.article?.data?.solder_resist_b }}
          </div>
        </div>
        <div class="offer-article-spec">
          <div>
            <label>Cu Endstärke:</label>{{ obj.article?.data?.cu_thickness }}
          </div>
          <div>
            <label>LP Endstärke:</label>{{ obj.article?.data?.endintensity }} mm
          </div>
          <div>
            <label>min. Bohrer:</label>{{ obj.article?.data?.drilling }}
          </div>
        </div>
      </div>

      <div style="float: left;">
        <div class="offer-article-spec">
          <div>
            <label>Senkungen:</label>{{ obj.article?.data?.lowerings }}
          </div>
          <div>
            <label>Captonband:</label>{{ obj.article?.data?.capton_band }}
          </div>
          <div>
            <label>Bohrungen:</label>
            {{ obj.article?.data?.drilldensity }}
          </div>
          <div>
            <label>Fixbohrungen:</label>{{ obj.article?.data?.fix_drill }}
          </div>
        </div>

        <div class="offer-article-spec">
          <div>
            <label>2 x Galvanisiert:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.galvanized_two"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.galvanized_two"></i>
          </div>
          <div>
            <label>Kerne verstä.:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.strong_cores"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.strong_cores"></i>
          </div>
          <div>
            <label>Buried Vias:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.buried_vias"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.buried_vias"></i>
          </div>
          <div>
            <label>Keine fehl. LPs:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.no_lp_missed"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.no_lp_missed"></i>
          </div>
          <div>
            <label>Schlechtnutzen:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.bad_utilize"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.bad_utilize"></i>
          </div>
        </div>


        <div class="offer-article-spec">
          <div>
            <label>E-Test konv.:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.testing_electric"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.testing_electric"></i>
          </div>
          <div>
            <label>E-Test 1 SMD:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.testing_1smd"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.testing_1smd"></i>
          </div>
          <div>
            <label>E-Test 2 SMD:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.testing_2smd"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.testing_2smd"></i>
          </div>
        </div>
      </div>

      <div style="float: left;">
        <div class="offer-article-spec">
          <div>
            <label>Lötabdecklack:</label>
            <span>A</span>
            <i class="fa fa-check" *ngIf="obj.article?.data?.protective_lacquer_ls"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.protective_lacquer_ls"></i>
            <span>B</span>
            <i class="fa fa-check" *ngIf="obj.article?.data?.protective_lacquer_ds"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.protective_lacquer_ds"></i>
          </div>
          <div>
            <label>Durchsteiger:</label>
            <span>A</span>
            <i class="fa fa-check" *ngIf="obj.article?.data?.via_ls"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.via_ls"></i>
            <span>B</span>
            <i class="fa fa-check" *ngIf="obj.article?.data?.via_ds"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.via_ds"></i>
          </div>
          <div>
            <label>Sacklochtech:</label>
            <span>A</span>
            <i class="fa fa-check" *ngIf="obj.article?.data?.blind_hole_tech_ls"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.blind_hole_tech_ls"></i>
            <span>B</span>
            <i class="fa fa-check" *ngIf="obj.article?.data?.blind_hole_tech_ds"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.blind_hole_tech_ds"></i>
          </div>
          <div>
            <label>Best-Druck A:</label>
            {{ obj.article?.data?.best_pressure_a}}
          </div>
          <div>
            <label>Best-Druck B:</label>
            {{ obj.article?.data?.best_pressure_b}}
          </div>
        </div>
        <div class="offer-article-spec">
          <div>
            <label>Goldstecker:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.gold_plug"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.gold_plug"></i>
          </div>
          <div>
            <label>Konturfräsen:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.shape_mill"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.shape_mill"></i>
          </div>
          <div>
            <label>Fräsen:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.mill"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.mill"></i>
          </div>
          <div>
            <label>Ritzen:</label>
            <i class="fa fa-check" *ngIf="obj.article?.data?.scratch"></i>
            <i class="fa fa-times" *ngIf="!obj.article?.data?.scratch"></i>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>


<div class="modal-footer">
</div>
