import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WorkflowOrderMailerAbstractComponent} from '../workflow-order-mailer.abstract-component';
import {MailModalService} from '../../../../mail/mail-modal.service';
import {ProducerService} from '../../../../producer/producer.service';
import {Workflow} from '../../../models/workflow.models';
import {Article} from '../../../../article/models/article.models';
import {OrderRequest} from '../../../request/workflow-request.model';

@Component({
  selector: 'order-request-main-request-list',
  templateUrl: './order-request-main-request-list.component.html',
})
export class OrderRequestMainRequestListComponent extends WorkflowOrderMailerAbstractComponent {
  @Input() requests: OrderRequest[];
  @Input() article: Article;
  @Input() workflow: Workflow;
  @Output() requestToOrderEmitter = new EventEmitter<{ requestNr: number, producer: string } | null>();

  constructor(protected mailModalService: MailModalService,
              protected producerService: ProducerService) {
    super();
  }
}
