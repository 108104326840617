import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../core/shared.module';
import {AddressBoxComponent} from './address-box.component';
import {AddressBoxModalChangeComponent} from './address-box-modal-change.component';

@NgModule({
    imports: [
        NgbModalModule,
        NgbTooltipModule,
        SharedModule,
    ],
    declarations: [
        AddressBoxComponent,
        AddressBoxModalChangeComponent
    ],
    exports: [
        AddressBoxComponent
    ]
})
export class AddressBoxModule {
}
