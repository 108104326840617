import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Address} from '../model/address';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AddressService {

  private uri = '/crm/v1/address/';

  constructor(private http: HttpClient) {
  }

  list(obj: { customer_id?: number }): Observable<Address[]> {
    return this.http.get<EnvisiaObjects<Address>>(this.uri, {params: EnvisiaLocation.httpParams(obj)}).pipe(
      map(value => value.objects)
    );
  }

  delete(addressId: string): Observable<void> {
    return this.http.delete<void>(this.uri + addressId + '/');
  }

  create(customerId: number, form: any): Observable<Address> {
    return this.http.post<EnvisiaObject<Address>>(
      this.uri,
      form,
      {params: EnvisiaLocation.httpParamsWrapper({customer_id: customerId.toString()})}
    ).pipe(map(value => value.object));
  }

  update(addressId: string, form: any): Observable<Address> {
    return this.http.put<EnvisiaObject<Address>>(
      this.uri + addressId + '/',
      form
    ).pipe(map(value => value.object));
  }

}
