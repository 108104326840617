import {MaterialArticleFormBuilder} from './material-article-form-builder';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {
  MaterialArticle,
  MaterialArticleError,
  MaterialArticleVariationError,
  MaterialUnit,
  MaterialValueType,
  MaterialVariation,
  MaterialVariationError
} from '../material-management.model';
import {MaterialManagementService} from '../material-management.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'material-article-update-form-holder',
  templateUrl: './material-article-update-form-holder.component.html'
})
export class MaterialArticleUpdateFormHolderComponent extends MaterialArticleFormBuilder implements OnInit {
  @Input() articleFieldDefs: MaterialValueType[];
  @Input() originalArticle: MaterialArticle;
  @Input() variationFieldDefs: MaterialValueType[];
  @Input() variations: MaterialVariation[];
  @Input() units: MaterialUnit[];

  form: UntypedFormGroup;
  error: MaterialArticleVariationError;

  constructor(
    protected fb: UntypedFormBuilder,
    private mmService: MaterialManagementService,
    private alertService: AlertService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'article_form': this.buildArticleGroupWithOriginalArticle(this.originalArticle, this.articleFieldDefs),
      'variation_forms' : this.fb.array(this.variations.map(v => this.createVariationForm(v))),
    });
  }

  onSubmit() {
    const form = this.form.getRawValue();

    this.mmService.updateArticle(this.originalArticle.id, form).subscribe(response => {
      this.error = null;
      const article = response.article;
      this.alertService.add(
        'success',
        (article.article_id + ' ' + article.name + ' wurde erfolgreich bearbeitet')
      );
      this.originalArticle = response.article;
      this.variations = response.variations_list;
      this.updateCreationForm();
    }, (response) => {
      const error = response.error as MaterialArticleError;
      if (error && error.error_type) {
        this.error = error;
      }
    });
  }

  createVariationForm(variation?: MaterialVariation): UntypedFormGroup {
    const buildForm = {
      'id': [(variation?.id ?? null), {options: {emitEvent: false}}],
      'index': [null, {options: {emitEvent: false}}],
      'vendor_article_id': [(variation?.vendor_article_id ?? ''), [Validators.required]],
      'vendor_price': [(variation ? Number(variation.vendor_price).toLocaleString('de-DE') : ''), [Validators.required]],
      'unit': [(variation?.unit ?? null), [Validators.required]],
      'favorite': [(variation?.favorite ?? false)],
      'min_stock': [(variation?.min_stock ?? 0), [Validators.required]],
      'min_order': [(variation?.min_order ?? 0), [Validators.required]],
      'lead_time': [(variation?.lead_time ?? 0), [Validators.required]],
      'ean': [(variation?.ean ?? '')],
      'variation_fields': this.buildFields(this.variationFieldDefs, variation?.variation_fields),
    };
    return this.fb.group(buildForm);
  }

  updateCreationForm(): void {
    const arr = this.form.get('variation_forms') as UntypedFormArray;
    while (arr.length > 0) {
      arr.removeAt(0);
    }
    this.variations.forEach(v => arr.push(this.createVariationForm(v)));
  }

  addVariation(): void {
    const arr = this.form.get('variation_forms') as UntypedFormArray;
    arr.push(this.createVariationForm());
  }

  removeVariation(index: number): void {
    const formIndex = this.form.value.variation_forms.findIndex(v => v.index === index);
    if (formIndex >= 0) {
      this.error = null;
      const arr = this.form.get('variation_forms') as UntypedFormArray;
      arr.removeAt(formIndex);
    }
  }

  get variationErrors(): MaterialVariationError[] | null {
    if (this.error && this.error.error_type === 'VariationsError' && this.error.data) {
      return this.error.data as MaterialVariationError[];
    } else {
      return null;
    }
  }

  get articleForm(): UntypedFormGroup {
    return this.form.get('article_form') as UntypedFormGroup;
  }

  get variationForms(): UntypedFormArray {
    return this.form.get('variation_forms') as UntypedFormArray;
  }

}
