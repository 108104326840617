import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleSpecification} from '../../models/article-specification.model';
import {deepCopy} from '../../../../helper/deep-copy';

@Component({
  selector: 'article-xy-input',
  templateUrl: './article-xy-input.component.html'
})
export class ArticleXyInputComponent implements OnInit, OnChanges {
  @Input() specification?: { [key: string]: ArticleSpecification };
  @Input() label: string;
  @Input() x?: string;
  @Input() y?: string;
  @Input() unit1?: string;
  @Input() unit2?: string;
  @Input() model1: string;
  @Input() model2: string;
  @Input() unit?: string;
  @Input() form: UntypedFormGroup;
  @Input() error1?: string;
  @Input() error2?: string;
  @Input() refCount?: number;
  @Output() changeEmitter = new EventEmitter<string>();
  processFn1?: (key: string, data: any) => any;
  processFn2?: (key: string, data: any) => any;
  view = true;
  refCounted = false;
  private viewFn: (key: string, data: any) => boolean | undefined;

  ngOnInit(): void {
    if (!!this.specification) {
      const spec1 = this.specification[this.model1];
      const spec2 = this.specification[this.model2];

      if (!!spec1 && !!spec2 && !!spec1.view_fn && !!spec2.view_fn && spec1.view_fn === spec2.view_fn) {
        this.viewFn = (key, data) => {
          return new Function('data', 'article', spec1.view_fn)(key, data);
        };
        this.refCounted = true;
      }

      if (spec1.process_fn) {
        this.processFn1 = (key, data) => {
          return new Function('data', 'article', spec1.process_fn)(key, data);
        };
      }

      if (spec2.process_fn) {
        this.processFn2 = (key, data) => {
          return new Function('data', 'article', spec2.process_fn)(key, data);
        };
      }

      if (!!this.viewFn) {
        this.view = this.viewFn('', this.form.value);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refCount && !changes.refCount.isFirstChange() && this.refCounted) {
      // this runs debounced after every keystroke, maybe we can find a more optimized version
      // currently I guess this is database dependent so a change might need a change in the fn database version
      if (!!this.viewFn) {
        this.view = this.viewFn('', this.form.value);
      }
    }
  }

  change(name: string): void {
    // FIXME: call process_fn
    if (!!this.processFn1) {
      const copy = deepCopy(this.form.value);
      this.processFn1(this.model1, copy);
      this.form.patchValue(copy, {emitEvent: false});
    }
    if (!!this.processFn2) {
      const copy = deepCopy(this.form.value);
      this.processFn2(this.model2, copy);
      this.form.patchValue(copy, {emitEvent: false});
    }
    this.changeEmitter.next(name);
  }

}
