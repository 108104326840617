import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {SharedModule} from '../../../core/shared.module';
import {ArticleBoxComponent} from './article-box.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ArticleButtonBoxComponent} from './article-button-box.component';
import {ArticleModule} from '../article.module';

@NgModule({
    imports: [
        SharedModule,
        NgbTooltipModule,
        UIRouterModule.forChild(),
        ArticleModule
    ],
    declarations: [
        ArticleBoxComponent,
        ArticleButtonBoxComponent
    ],
    exports: [
        ArticleBoxComponent,
        ArticleButtonBoxComponent
    ]
})
export class ArticleBoxModule {
}
