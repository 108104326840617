<div class="modal-header">
  <h3 class="modal-title">BA Stornieren {{ ba.object.id }}-{{ ba.object.index }}</h3>
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <p>Möchten Sie die BA {{ ba.object.id }}-{{ ba.object.index }} wirklich stornieren?</p>

    <div class="form-group">
      <label for="reason">Grund</label>
      <textarea class="form-control" id="reason" rows="4" formControlName="reason"
                [ngClass]="{red_alert: errors['obj.reason']}"></textarea>
    </div>

    <div class="alert alert-danger" *ngIf="ba.sums.reserved_inventory > 0">
      Es ist ein Lagerbestand von {{ba.sums.reserved_inventory|money:0}} Stück auf dem BA reserviert.
      Dieser wird automatisch zurückgebucht.
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-default pull-left" type="button" (click)="close()">Abbrechen</button>

    <button class="btn btn-danger pull-right" type="submit" [disabled]="form.pristine || form.invalid">
      Stornieren
    </button>
  </div>
</form>
