import {WorkflowService} from '../workflow-services/workflow.service';
import {Address} from '../../customer/model/address';
import {AlertService} from '../../../common/alert-service/alert.service';

export abstract class WorkflowAddressUpdate {
  protected abstract workflowService: WorkflowService;
  protected abstract alertService: AlertService;

  updateDeliveryAddress(data: Address | null | undefined): void {
    if (!!data && data.id !== this.currentDeliveryAddressId()) {
      this.workflowService.update(this.workflowId(), {delivery_id: data.id}).subscribe(() => {
        this.updateDeliveryId(data);
        this.alertService.add('success', 'Lieferaddresse erfolgreich geändert!');
      }, () => {
        this.resetDeliveryAddress();
        this.alertService.add('danger', 'Lieferaddresse konnte nicht geändert werden!');
      });
    }
  }

  protected abstract resetDeliveryAddress(): void;

  protected abstract updateDeliveryId(data: Address): void;

  protected abstract currentDeliveryAddressId(): string;

  protected abstract workflowId(): number;

}
