import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {INVOICE_AUTOMATION} from './automation/invoice-automation.state';
import {InvoiceService} from './services/invoice.service';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {InvoiceListComponent} from './list/invoice-list.component';
import {WORKFLOW_CREATE_RESOLVER} from '../resolver/workflow-data.resolver';
import {InvoiceCreateComponent} from './create/invoice-create.component';
import {ErpTransition} from '../../../core/erp-transition.service';
import {InvoiceJobErrorListComponent} from './job-error/invoice-job-error-list.component';

export const INVOICE_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.i',
  abstract: true,
  data: {requiresAuth: true}
};

export function invoiceListResolveFn(transition: ErpTransition, service: InvoiceService) {
  return service.list(transition.params()).toPromise();
}

export const INVOICE_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.i.list',
  url: '/workflow/invoice/list?page&order&sort&typ&date_start&date_end&storno&not_send&sid&workflow_id&invoice_dunning_level&sds_nr&oa_nr&kd_art_nr&customer_id&customer_name&customer_zip&customer_city&customer_country&customer_field_service&username',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: InvoiceListComponent}
  },
  resolve: [{token: 'listData', resolveFn: invoiceListResolveFn, deps: [ErpTransition, InvoiceService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    typ: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    storno: {dynamic: true},
    not_send: {dynamic: true},
    sid: {dynamic: true},
    workflow_id: {dynamic: true},
    invoice_dunning_level: {dynamic: true},
    sds_nr: {dynamic: true},
    oa_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    customer_id: {dynamic: true},
    customer_name: {dynamic: true},
    customer_zip: {dynamic: true},
    customer_city: {dynamic: true},
    customer_country: {dynamic: true},
    customer_field_service: {dynamic: true},
    username: {dynamic: true}
  },
  data: {requiresAuth: true}
};

export const INVOICE_JOB_ERROR_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.jobErrorList',
  url: '/workflow/invoice/job-error-list',
  views: {
    'main@a': {component: InvoiceJobErrorListComponent}
  },
  data: {requiresAuth: true}
};

export const INVOICE_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.create.create_invoice_create',
  params: {data: {dynamic: true}, name: {}, id: {}},
  resolve: [WORKFLOW_CREATE_RESOLVER],
  views: {'main@a': {component: InvoiceCreateComponent}}
};

export const INVOICE_STATES: Ng2StateDeclaration[] = [
  INVOICE_AUTOMATION,
  INVOICE_MAIN_STATE,
  INVOICE_LIST_STATE,
  INVOICE_CREATE_STATE,
  INVOICE_JOB_ERROR_LIST_STATE,
];
