<div class="buttonbar-view">

  <article-button-navigation [article]="article"
                             [options]="options"
                             [locked]="saveLock"
                             (saveEmitter)="save()"
                             (deleteEmitter)="deleted()"
                             (recoverEmitter)="recovered($event)"
                             (generateSdsEmitter)="generatedSds($event)"
                             (generateSisterEmitter)="generatedSister($event)"
                             (lockChangeEmitter)="lockChange($event)">
  </article-button-navigation>

</div>

<article-top-navigation [article]="article"
                        [workflowId]="workflow_id"
                        [multilayerPlan]="multilayerPlan">
</article-top-navigation>

<article-top [edit]="true" [article]="article" [topForm]="form" (updateState)="updateState($event)"></article-top>

<article-locked-info [article]="article"></article-locked-info>

<div class="row top-inner-buffer">

  <div class="col-lg-4">
    <label class="form-control-label">Artikel Änderung</label>
    <div class="btn-group" role="group" aria-label="Ansichten">
      <button type="button" class="btn btn-default" (click)="setView('simple')" [ngClass]="{active: tab === 'simple'}">
        Einfache Ansicht
      </button>
      <button type="button" class="btn btn-default" (click)="setView('extended')" [ngClass]="{active: tab === 'extended'}">
        Erweiterte Ansicht
      </button>
    </div>
  </div>

  <div class="col-lg-2">
    <label class="form-control-label">Aktionsverlauf</label>
    <div class="btn-group" role="group" aria-label="Aktionsverlauf">
      <button type="button" class="btn btn-default" (click)="setView('log')" [ngClass]="{active: tab === 'log'}">
        Einfache Ansicht
      </button>
    </div>
  </div>

  <div class="col-lg-2" *ngIf="isMl">
    <label class="form-control-label">Aufbauplan</label>
    <div class="btn-group" role="group" aria-label="Aktionsverlauf">
      <button type="button" class="btn btn-default"
              [ngClass]="{active: tab === 'ml-simple'}"
              (click)="setView('ml-simple')">
        Einfache Ansicht
      </button>
    </div>
  </div>

  <article-history-simple [history]="history" *ngIf="tab === 'simple'"></article-history-simple>
  <article-history-detail [history]="history" *ngIf="tab === 'extended'"></article-history-detail>
  <article-multilayer-plan-history [list]="mlPlanHistory"
                                   [oaNr]="article.oa_nr"
                                   *ngIf="tab === 'ml-simple'">
  </article-multilayer-plan-history>

  <div *ngIf="tab === 'log'" class="col-lg-12">
    <div class="row comment-row">
      <comment [comments]="article.comments" [canEdit]="false" [item]="article.oa_nr" typ="'article_log'"></comment>
    </div>
  </div>
</div>
