import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialSupplier} from '../../../lazy/material-supplier/material-supplier.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MaterialSupplierService} from '../../../lazy/material-supplier/material-supplier.service';

@Component({
  selector: 'material-supplier-select',
  templateUrl: './material-supplier-select.component.html'
})
export class MaterialSupplierSelectComponent implements OnInit {
  @Input() supplierList: MaterialSupplier[];
  @Input() selected?: MaterialSupplier;
  form: UntypedFormGroup;

  constructor(
    private mss: MaterialSupplierService,
    private fb: UntypedFormBuilder,
    private modalService: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({'query': [null]});
    this.updateList();
  }

  updateList(): void {
    this.mss.list(this.buildQueryModel()).subscribe(
      list => { this.supplierList = list; },
      error => { }
    );
  }

  toggleSelect(supplier: MaterialSupplier): void {
    this.selected = supplier;
    this.updateList();
  }

  dismiss(): void {
    this.modalService.dismiss();
  }

  onSubmit(): void {
    this.modalService.close(this.selected);
  }

  private buildQueryModel(): any {
    const queryModel =  {'limit': '50'};
    if (this.form.value.query) {
      queryModel['name'] = this.form.value.query;
    }
    if (this.selected) {
      queryModel['ignored_id'] = this.selected.id.toString();
    }
    return queryModel;
  }
}
