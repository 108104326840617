import {Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {ArticleSpecification} from '../../models/article-specification.model';
import {UntypedFormGroup} from '@angular/forms';
import {ArticleCalculatorService} from '../services/article-calculator.service';

@Component({
  selector: 'article-multi',
  templateUrl: './article-multi.component.html'
})
export class ArticleMultiComponent implements OnInit, OnChanges {
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() model?: string;
  @Input() refCount: number;
  @Input() form: UntypedFormGroup;
  @Input() errors: { [key: string]: any; } = {};
  @ContentChild(TemplateRef, {static: true}) tmpl: TemplateRef<any>;
  values: {
    specification: { [key: string]: ArticleSpecification },
    form: UntypedFormGroup,
    index: number,
    errors: { [key: string]: any; }
  }[] = [];
  previousValue = 0; // is 0 since we initalize with 1 (else it needs to be -1)

  ngOnInit(): void {
    this.calculateValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refCount && !changes.refCount.isFirstChange()) {
      this.calculateValues();
    }
    if (changes.errors && !changes.errors.isFirstChange()) {
      this.pushValues(this.previousValue);
    }
  }

  private calculateValues(): void {
    const modelName = this.model ? this.model : 'mixed_structure';
    const value = ArticleCalculatorService.executionExtractor(modelName, this.form.value[modelName]);

    if (this.previousValue !== value) {
      // only trigger change detection if the previous value
      // is unequal to our current value
      this.previousValue = value;
      this.pushValues(value);
    }
  }

  private pushValues(value: number): void {
    this.values = [];
    for (let i = 0; i < value; i++) {
      const index = i + 1;
      const data = {
        specification: this.specification,
        form: this.form,
        errors: this.errors,
        index: index
      };
      this.values.push(data);
    }
  }

}
