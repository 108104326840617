import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GermanOrChinaPrice} from '../../models/position.models';

@Component({
  selector: 'contract-history-modal',
  templateUrl: './contract-history-modal.component.html',
  styles: ['.notes-truncated-modal { background: #fff;}',
    '.selectable-row {cursor: pointer;}',
    '.release-warning { color: red; font-size: 80%; }',
    '.notes-inner {text-align: left; white-space: nowrap; display: block; overflow: hidden; text-overflow: ellipsis; max-width: 6ch; background: #dc3545; padding: 1px 3px; color: #fff; border-radius: 3px;}']
})
export class ContractHistoryModalComponent implements OnInit {
  @Input() data: GermanOrChinaPrice;
  errors: any = {};
  selected?: number;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.initialSelect();
  }

  // TODO this also needs to be done in the position modal since there could be cases where all releases have a release number ...
  initialSelect(): void {
    if (this.data.price.releases[0].release) {
      this.selected = this.data.price.releases[0].release;
    }
  }

  select(release?: number) {
    this.selected = release;
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.activeModal.close({release: this.selected});
  }

  isSelected(release?: number): boolean {
    return this.selected === release;
  }

}
