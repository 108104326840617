import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import Helper from '../../helper/helper';
import {debug} from '../../helper/debug.func';

export const EV_INTEGER_INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IntegerInputComponent),
  multi: true,
};

export const EV_INTEGER_INPUT_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IntegerInputComponent),
  multi: true
};

@Component({
  selector: 'integer-input',
  encapsulation: ViewEncapsulation.None,
  styles: [
    'integer-input.ng-invalid:not(form) input { background-color: #f2dede !important; border-color: #ebccd1 !important; color: #a94442 !important; }',
  ],
  template: `
      <input type="text"
             [value]="numberString"
             (change)="onChangeEvent($event)"
             (keyup)="onChange($event)"
             [placeholder]="placeholderString"
             [disabled]="formDisabled"
             [ngClass]="inputClasses"
             [inputSelect] [inputSelectEnabled]="inputSelect">
  `,
  providers: [
    EV_INTEGER_INPUT_VALUE_ACCESSOR,
    EV_INTEGER_INPUT_VALIDATOR,
  ]
})
export class IntegerInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() placeholder?: string;
  @Input() initError = false;
  @Input() errorClass: string | null | undefined = null;
  @Input() inputSelect = false;
  @Input() changeEvent = true;
  data?: number;
  numberString?: string;
  parseError: boolean;
  parseValue?: number;
  placeholderString: string;
  formValidationError = false;
  formDisabled = false;
  inputClasses: any = {};
  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  ngOnInit(): void {
    this.placeholderString = this.placeholder ? this.placeholder : '';
    if (!Helper.undefined(this.errorClass)) {
      this.inputClasses[this.errorClass] = this.formValidationError;
    }
  }

  writeValue(value: any): void {
    const normalizedValue = Helper.isInt(value) ? Helper.toInt(value) : null;
    if (normalizedValue || normalizedValue === 0) {
      this.data = normalizedValue;
      this.numberString = normalizedValue.toString();
    } else {
      this.data = undefined;
      this.numberString = '';
      this.parseValue = undefined;
      this.parseError = false;
      if (this.initError) {
        this.formValidationError = true;
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  // not used, used for touch input
  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.formDisabled = isDisabled;
  }

  validate(c: UntypedFormControl): ValidationErrors | null {
    if (c.errors) {
      this.formValidationError = true;
    } else if (this.formValidationError) {
      this.formValidationError = false;
    }

    if (this.parseError) {
      return {'integer': {invalid: this.parseValue}};
    } else {
      return null;
    }
  }

  public onChangeEvent(event): void {
    if (this.changeEvent) {
      this.onChange(event);
    }
  }

  public onChange(event) {
    const newValue = event.target.value;
    debug('integer-input change event', event, this.numberString === newValue);

    if (newValue === '' || newValue === undefined || newValue === null) {
      this.data = null;
      this.numberString = newValue;
      this.reset();
    } else if (Helper.isInt(newValue)) {
      const cache = Helper.toInt(newValue);
      this.data = cache;
      this.numberString = cache.toString();
      this.reset();
    } else {
      this.data = newValue; // push the invalid value
      this.parseValue = newValue;
      this.parseError = true;
      this.numberString = newValue;
    }

    // update the form
    this._onChange(this.data);
  }

  private reset(): void {
    this.parseError = false;
    this.parseValue = null;
  }

}
