import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {CommentComponent} from './comment.component';
import {UIRouterModule} from '@uirouter/angular';
import {CommentBusinessOrderComponent} from './comment-business-order/comment-business-order.component';
import {CommentBusinessOrderLinkComponent} from './comment-business-order/comment-business-order-link.component';

@NgModule({
    imports: [SharedModule, UIRouterModule],
    exports: [CommentComponent],
    declarations: [CommentComponent, CommentBusinessOrderComponent, CommentBusinessOrderLinkComponent]
})
export class CommentModule {
}
