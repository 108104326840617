import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExtendedFrame} from '../models/frame.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FrameBoxUpdateComponent} from './update/frame-box-update.component';
import {from} from 'rxjs';
import {ProducerService} from '../../producer/producer.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'frame-box',
  templateUrl: './frame-box.component.html',
})
export class FrameBoxComponent implements OnInit {

  @Input() frame?: ExtendedFrame;
  @Input() lastOffer?: any;
  @Input() cn?: boolean;
  @Output() frameUpdatedEvent: EventEmitter<number> = new EventEmitter<number>();
  same_exchange = true;

  constructor(private modalService: NgbModal,
              private producerService: ProducerService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    if (!this.cn) {
      this.cn = false;
    }
    if (this.frame && this.frame.exchange) {
      this.same_exchange = this.frame.exchange === this.frame.current_exchange;
    }
  }

  openUpdateModal(): void {
    this.producerService.list().subscribe(producers => {
    const modalRef = this.modalService.open(FrameBoxUpdateComponent, {windowClass: 'modal2-sm'});
    modalRef.componentInstance.frame = this.frame;
    modalRef.componentInstance.producers = producers;

    from(modalRef.result).subscribe(frame => {
      this.frameUpdatedEvent.emit(this.frame.workflow_id);
      this.frame = frame;
    });
    }, () => {
      this.alertService.add('danger', 'Konnte herstellerliste nicht laden');
    });
  }

}
