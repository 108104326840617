import {Component, Input, OnInit} from '@angular/core';
import {PositionService} from '../../../workflow-services/position.service';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {OfferExtraService} from '../../../workflow-services/offer-extra.service';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';
import {ExtraCostList} from '../../../models/offer-extra.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PositionExtraErrorModalComponent} from '../position-extra-error-modal/position-extra-error-modal.component';
import {from, Subject} from 'rxjs';
import {ignoreRejection} from '../../../../../helper/ignore_rejection';
import {WorkflowPositionView} from '../../../models/position.models';
import {AlertService} from '../../../../../common/alert-service/alert.service';

@Component({
  selector: 'position-extra-modal',
  templateUrl: './position-extra-modal.component.html',
  styleUrls: ['position-extra-modal.component.scss'],
  styles: ['.inline-div { display: inline; }']
})
export class PositionExtraModalComponent implements OnInit {
  @Input() data: BigWorkflowOffer;
  @Input() subject: Subject<WorkflowPositionView>;
  extraListData: LoadingWrapper<ExtraCostList>;

  constructor(private positionService: PositionService,
              private offerExtraService: OfferExtraService,
              private alertService: AlertService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.extraListData = new LoadingWrapper(this.offerExtraService.list());
  }

  add(value: string, id?: number): void {
    if (
      (value === 'viafilling_type6_sieve') &&
      (this.data.article.data?.via_handling !== 'Filled and Covered Via (IPC-4761 VI)')
    ) {
      this.alertService.add('danger', 'Kein Typ IPC-4761 VI im Artikel festgelegt');
      return;
    }

    this.positionService.createOther(this.data.workflow.object.id, value, id).subscribe(response => {
      this.subject.next(response);
    }, () => {
      this.openErrorModal();
    });
  }

  private openErrorModal(): void {
    const modalRef = this.modalService.open(PositionExtraErrorModalComponent, {windowClass: 'modal2-sm'});
    from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
  }

}
