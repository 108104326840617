import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {FrameBoxComponent} from './frame-box.component';
import {CanbanBoxComponent} from './canban-box.component';
import {KanbanApprovalModalComponent} from '../kanban/kanban-approval-modal.component';
import {
  WorkflowPriceIncreaseFlexBoxComponent,
} from './price-increase-flex-box/workflow-price-increase-flex-box.component';
import {LoadingOrErrorModule} from '../../../common/loading/loading-or-error.module';
import {
  WorkflowPriceIncreaseFlexEmptyComponent
} from './price-increase-flex-box/workflow-price-increase-flex-empty.component';
import {
  WorkflowPriceIncreaseFlexViewComponent
} from './price-increase-flex-box/workflow-price-increase-flex-view.component';
import {
  WorkflowPriceIncreaseFlexUpdateModalComponent
} from './price-increase-flex-box/workflow-price-increase-flex-update-modal.component';
import {
  WorkflowPriceIncreaseFlexCreateModalComponent
} from './price-increase-flex-box/workflow-price-increase-flex-create-modal.component';

@NgModule({
  imports: [
    SharedModule,
    LoadingOrErrorModule,
  ],
  declarations: [
    FrameBoxComponent,
    CanbanBoxComponent,
    KanbanApprovalModalComponent,
    WorkflowPriceIncreaseFlexBoxComponent,
    WorkflowPriceIncreaseFlexEmptyComponent,
    WorkflowPriceIncreaseFlexViewComponent,
    WorkflowPriceIncreaseFlexUpdateModalComponent,
    WorkflowPriceIncreaseFlexCreateModalComponent,
  ],
  exports: [FrameBoxComponent, CanbanBoxComponent, KanbanApprovalModalComponent, WorkflowPriceIncreaseFlexBoxComponent]
})
export class WorkflowBoxesModule {
}
