import {Component, Input} from '@angular/core';
import {ProductionFetchMin, ProductionFetchView} from '../../models/fetch.models';
@Component({
  selector: 'business-order-frame-table',
  templateUrl: './business-order-frame-table.component.html'
})
export class BusinessOrderFrameTableComponent {
  @Input() fetches: ProductionFetchView[];
  @Input() fetchesMin: ProductionFetchMin;

  constructor() {}
}
