import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductionTemplateService} from '../../../envisia/article/production-services/production-template.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'production-template-create',
  templateUrl: './production-template-create.component.html',
})
export class ProductionTemplateCreateComponent {

  errors?: { [key: string]: any; } = {};
  createForm: UntypedFormGroup;

  constructor(private productionTemplateService: ProductionTemplateService,
              private modalRef: NgbActiveModal,
              private fb: UntypedFormBuilder,
              private alertService: AlertService) {
    this.buildForm();
  }

  create() {
    this.errors = {};
    this.productionTemplateService.create(this.createForm.value).subscribe(value => {
      this.alertService.add('success', 'Vorlage erfolgreich gespeichert!');
      this.modalRef.close(value);
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      this.alertService.add(
        'danger',
        'Es ist ein Fehler aufgerterten, bitte überprüfen Sie die eingegeben Werte und versuchen Sie es erneut!'
      );
    });
  }

  private buildForm() {
    this.createForm = this.fb.group({
      name: [null, Validators.required],
      description: [null],
      expression: [null, Validators.required],
      ordering: [-1, Validators.required],
    });
  }

}
