<table class="table whitetable tablerighthead">
  <thead>
  <tr>
    <th style="text-align: left !important;">Pos.</th>
    <th style="text-align: left !important;">Bezeichnung</th>
    <th>Menge</th>
    <th>Lose</th>
    <th>Kalk-Menge</th>
    <th>Freigabe</th>
    <th class="bold">Einzel DE</th>
    <th class="align-right">Lieferzeit</th>
    <th>Gesamt DE</th>
    <th>Notiz</th>
    <!-- <th>Lose CHN</th> issue #1218 -->
    <th>Kalk Lose</th>
    <th>Freigabe</th>
    <th>Einkauf CHN</th>
    <th class="bold">Einzel CHN</th>
    <th class="align-right">Lieferzeit</th>
    <th>Preis %</th>
    <th>Gesamt CHN</th>
    <th>Notiz</th>
    <th style="width: 90px;">&nbsp;</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let position of data.workflow.positions; trackBy: trackByFn; let index = index"
      [position-box-list-item]="position"
      [position-box-list-item-list-change]="listChange"
      [position-box-list-item-data]="data"
      (position-box-list-item-update-position)="doUpdatePosition($event, index)">
  </tr>
  </tbody>
</table>
