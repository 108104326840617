<i class="fa fa-star click" (click)="changeRating(1)" *ngIf="ratingObj[1] && edit"></i>
<i class="fa fa-star-o click" (click)="changeRating(1)" *ngIf="!ratingObj[1] && edit"></i>

<i class="fa fa-star click" (click)="changeRating(2)" *ngIf="ratingObj[2] && edit"></i>
<i class="fa fa-star-o click" (click)="changeRating(2)" *ngIf="!ratingObj[2] && edit"></i>

<i class="fa fa-star click" (click)="changeRating(3)" *ngIf="ratingObj[3] && edit"></i>
<i class="fa fa-star-o click" (click)="changeRating(3)" *ngIf="!ratingObj[3] && edit"></i>

<i class="fa fa-star click" (click)="changeRating(4)" *ngIf="ratingObj[4] && edit"></i>
<i class="fa fa-star-o click" (click)="changeRating(4)" *ngIf="!ratingObj[4] && edit"></i>

<i class="fa fa-star click" (click)="changeRating(5)" *ngIf="ratingObj[5] && edit"></i>
<i class="fa fa-star-o click" (click)="changeRating(5)" *ngIf="!ratingObj[5] && edit"></i>

<!-- Read Only -->
<i class="fa fa-star" *ngIf="ratingObj[1] && !edit"></i>
<i class="fa fa-star-o" *ngIf="!ratingObj[1] && !edit"></i>

<i class="fa fa-star" *ngIf="ratingObj[2] && !edit"></i>
<i class="fa fa-star-o" *ngIf="!ratingObj[2] && !edit"></i>

<i class="fa fa-star" *ngIf="ratingObj[3] && !edit"></i>
<i class="fa fa-star-o" *ngIf="!ratingObj[3] && !edit"></i>

<i class="fa fa-star" *ngIf="ratingObj[4] && !edit"></i>
<i class="fa fa-star-o" *ngIf="!ratingObj[4] && !edit"></i>

<i class="fa fa-star" *ngIf="ratingObj[5] && !edit"></i>
<i class="fa fa-star-o" *ngIf="!ratingObj[5] && !edit"></i>
