import {Component, ErrorHandler, Input} from '@angular/core';
import {MaterialSupplier} from '../../../../lazy/material-supplier/material-supplier.model';
import {MailHeaderData} from '../../../mail/mail.models';
import {MailModalService} from '../../../mail/mail-modal.service';
import {Lister} from '../../../../common/wrapper.models';
import {SearchBaseComponent} from '../../../../common/search/search.component';
import {MaterialOrder, MaterialOrderWithNotes} from '../material-order.model';
import {MaterialOrderListService} from './material-order-list.service';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialOrderUpdateComponent} from '../update/material-order-update.component';
import {from} from 'rxjs';
import {MaterialOrderService} from '../material-order.service';
import {MaterialOrderUpdateService} from '../update/material-order-update.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {environment} from '../../../../../environments/environment';
import {MaterialGroup} from '../../groups/material-group.model';
import {StringHelper} from '../../../../common/string-helper';
import {MaterialProductGroupListElement} from '../../product-groups/material-product-groups.models';

@Component({
  selector: 'material-order-list',
  templateUrl: './material-order-list.component.html'
})
export class MaterialOrderListComponent extends SearchBaseComponent<MaterialOrderListService, MaterialOrderWithNotes> {
  @Input() suppliers: MaterialSupplier[];
  @Input() listData: Lister<MaterialOrderWithNotes>;
  @Input() productGroups: Lister<MaterialProductGroupListElement>;
  @Input() groups: Lister<MaterialGroup>;
  @Input() activeProductGroupId?: number;
  @Input() activeGroupIds: number[] = [];

  data: { [key: string]: string } = {};
  type = 'material_order';
  finderUri = environment.finderUri;
  finderCategory = environment.finder_category_material_order_pdf;

  activeGroups: number[] = [];

  constructor(protected service: MaterialOrderListService,
              protected errorHandler: ErrorHandler,
              protected updateService: MaterialOrderUpdateService,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              protected mailModalService: MailModalService,
              protected alertService: AlertService,
              private mos: MaterialOrderService,
              private modalService: NgbModal) {
    super();
  }

  filterStatus(status?: number): void {
    const query = this.query();
    query.status = status?.toString();
    this.serviceCall(query);
  }

  delete(order: MaterialOrder): void {
    if (confirm('Möchten Sie die Bestellung wirklich löschen?')) {
      this.mos.delete(order.id).subscribe(() => {
        this.search();
      }, (errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 409) {
          this.alertService.add('danger', 'Es existieren bereits Einbuchungen für diese Bestellung.');
        }
      });
    }
  }

  mail(mo: MaterialOrder, index: number): void {
    if (mo.supplier_id && mo.supplier.email) {
      const header = new MailHeaderData(mo.supplier.email, null, 'material@storz-pcb.de');
      this.mailModalService.open(mo.id, this.type, header).subscribe(() => {
        this.list.objects[index].mail_sent = true;
      }, (response) => {
        console.log('Error', response);
      });
    } else {
      alert('Lieferant hat keine Mail hinterlegt, bitte zuerst eine Mail hinterlegen!');
    }
  }

  confirm(materialOrder: MaterialOrder): void {
    this.updateService.list({'order_id': materialOrder.id}).subscribe(listData => {
      const modalRef = this.modalService.open(MaterialOrderUpdateComponent, {windowClass: 'modal2-full'});
      modalRef.componentInstance.materialOrder = materialOrder;
      modalRef.componentInstance.groups = this.groups;
      modalRef.componentInstance.list = listData;
      from(modalRef.result).subscribe(() => {
        this.reload();
      }, () => {
        this.reload();
      });
    });
  }

  openPdf(id: number): void {
    window.open(`${environment.apiv4uri}material/order/pdf/${id}`, '_blank');
  }

  setProductGroupId(productGroupId: number | null): void {
    const q = this.query();
    this.activeProductGroupId = productGroupId;
    q['product_group'] = productGroupId;
    this.serviceCall(q);
  }

  setGroupIds(groupIds: number[]): void {
    const q = this.query();
    this.activeGroupIds = groupIds;
    q['groups'] = StringHelper.concat(groupIds, ',');
    this.serviceCall(q);
  }
}
