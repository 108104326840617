import {Ng2StateDeclaration} from '@uirouter/angular';
import {InvoiceAutomationComponent} from './invoice-automation.component';
import {InvoiceAutomationService} from '../services/invoice-automation.service';

export function invoiceAutomationCountResolveFn(service: InvoiceAutomationService) {
  return service.count({}).toPromise();
}

export function invoiceAutomationErrorsResolveFn(service: InvoiceAutomationService) {
  return service.errors().toPromise();
}

export function invoiceAutomationWorkingResolveFn(service: InvoiceAutomationService) {
  return service.working().toPromise();
}

export const INVOICE_AUTOMATION: Ng2StateDeclaration = {
  name: 'a.workflow.i.invoice',
  url: '/invoice/automation',
  views: {'main@a': {component: InvoiceAutomationComponent}},
  resolve: [
    {token: 'count', resolveFn: invoiceAutomationCountResolveFn, deps: [InvoiceAutomationService]},
    {token: 'errors', resolveFn: invoiceAutomationErrorsResolveFn, deps: [InvoiceAutomationService]},
    {token: 'working', resolveFn: invoiceAutomationWorkingResolveFn, deps: [InvoiceAutomationService]}
  ],
  data: {requiresAuth: true}
};
