<div>
  <h2>Auftragsübersicht</h2>
  &nbsp;
</div>

<div>
  <h2>Vorhandene Lieferscheine</h2>

  <table class="table whitetable" style="max-width: 500px;">
    <thead>
    <tr>
      <th>Lieferschein</th>
      <th>Menge</th>
      <th>Datum</th>
      <th>Ersteller</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let delivery of data.delivery.objects">
      <td>
        <ev-file-button [objId]="delivery.id" type="delivery">
          {{ delivery.id }}
        </ev-file-button>
      </td>
      <td>
        <ev-file-button [objId]="delivery.id" type="delivery">
          {{ delivery.quanity }}
        </ev-file-button>
      </td>
      <td>
        <ev-file-button [objId]="delivery.id" type="delivery">
          {{ delivery.date }}
        </ev-file-button>
      </td>
      <td>
        <ev-file-button [objId]="delivery.id" type="delivery">
          {{ delivery.username }}
        </ev-file-button>
      </td>
      <td>
        <button type="button" class="btn btn-link btn-sm" (click)="historyDelivery(delivery)">
          <i class="fa fa-clock-o"></i>
        </button>
      </td>
      <td>
        <div *ngIf="delivery.delivery_invoice_lock" class="label label-danger">
          Gesperrt
        </div>
        <button class="btn btn-link btn-sm" *ngIf="delivery.delivery_invoice_lock" (click)="unlock(delivery)">
          <i class="fa fa-unlock"></i> Entsperren
        </button>

        <button class="btn btn-link btn-sm" *ngIf="!delivery.delivery_invoice_lock" (click)="lock(delivery)">
          <i class="fa lock"></i> Sperren
        </button>
      </td>
      <td>
        <button class="btn btn-link btn-sm"
                *ngIf="('delivery.storno'|HasPerm) &&
                                      !delivery.delivery_invoice_lock &&
                                      !delivery.storno && !hasInvoice(delivery.id) &&
                                      !containsInvoicedFetch(delivery)"
                (click)="storno(delivery)">
          <i class="fa lock"></i> Stornieren
        </button>
        <div *ngIf="delivery.storno" class="label label-danger">
          Storniert
        </div>
        <div *ngIf="data.workflow.object.status === 5 && !delivery.storno" class="label label-success">
          Abgerechnet
        </div>
      </td>
    </tr>

    </tbody>
  </table>
</div>

