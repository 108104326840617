import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ContractCheckWeek,
  ContractDateCheckElement,
  ContractDateCheckHolder,
  ContractDateDayModel
} from './material-planning-date-check.model';
import {DateHelper, monthNames} from '../../../helper/date-helper';
import {noop} from '../../../helper/noop';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Article} from '../../article/models/article.models';
import {DateCode} from '../../../helper/date-code';

@Component({
  selector: 'material-planning-date-check-modal',
  templateUrl: './material-planning-date-check-modal.component.html',
  styleUrls: ['./material-planning-date-check-modal.component.scss'],
})
export class MaterialPlanningDateCheckModalComponent implements OnInit {
  @Input() article: Article;
  @Input() dateHolder: ContractDateCheckHolder;
  @Input() dateSelected?: string;
  @Input() holidays: { [year: string]: { [month: string]: number[] } };

  readonly pageElements = 7;
  firstVisibleIndex = 0;
  firstWeek: DateCode;

  dateStart?: Date;
  dateLastWorkDay?: Date;
  totalWorkDaysFromStart: number;
  totalWorkDaysFromLastWorkday: number;

  form: UntypedFormGroup;

  visibleWeeks: ContractCheckWeek[];
  visibleDays: ContractDateDayModel[];

  constructor(private activeModal: NgbActiveModal,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.fb.group({'date': [this.dateSelected]});
    this.firstWeek = DateHelper.dateToWeekDate(DateHelper.today());
    this.weekScrollDate(true);
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  save(): void {
    this.activeModal.close(this.dateSelected);
  }

  weekScrollDate(showSelection?: boolean): void {
    const dateTarget = DateHelper.safeParse(this.form.value.date);

    if (dateTarget) {
      const weekDiff = Math.ceil((DateHelper.diffDays(this.firstWeek.toDate(), dateTarget) + 1) / 7);
      if (showSelection) {
        this.firstVisibleIndex = weekDiff - this.pageElements + 1;
      } else {
        this.firstVisibleIndex = weekDiff - Math.ceil(this.pageElements / 2);
      }
    }

    if (this.firstVisibleIndex < 0) {
      this.firstVisibleIndex = 0;
    }

    this.setVisibleWeekIndices();
    this.setVisibleDays();
  }

  weekScrollPage(pages: number): void {
    if (this.firstVisibleIndex + pages < 0) {
      this.firstVisibleIndex = 0;
    } else {
      this.firstVisibleIndex += pages;
    }
    this.setVisibleWeekIndices();
    this.setVisibleDays();

    const centerDay = this.visibleDays[Math.floor(this.visibleDays.length / 2)];
    const centerWeek = DateHelper.firstDayOfWeek(centerDay.date);
    this.form.patchValue({'date': DateHelper.format(centerWeek)}, {emitEvent: false});
  }

  markSelectedDays(date: ContractDateDayModel) {
    if (date.classes.click === true) {
      this.setSelectedDate(date.date);

      this.visibleWeeks.forEach(w => {
        const d = DateHelper.copy(w.first_day_of_week);
        w.marked_days = w.marked_days.map(() => {
          d.setDate(d.getDate() + 1);
          return this.dateStart <= d && d <= date.date;
        });
      });

      this.setVisibleDays();
    }
  }

  trackByDay(index: number, o: { index: number }): number {
    return o.index;
  }

  trackByWeek(ccw: ContractCheckWeek, i: number): number {
    noop(this, ccw.date_code);
    return i;
  }

  trackByMaterial(index: number, c: ContractDateCheckElement): number {
    noop(this, index);
    return c.variation.id;
  }

  getMarkedDays(dc: DateCode): boolean[] {
    const markedDays = [];
    const selectedDate = DateHelper.safeParse(this.dateSelected);

    for (let i = 0; i < 7; i++) {
      const d = DateHelper.copy(dc.toDate());
      d.setDate(d.getDate() + 1);
      markedDays.push(this.dateStart <= d && d <= selectedDate);
    }
    return markedDays;
  }

  setVisibleWeekIndices(): void {
    this.visibleWeeks = [];

    for (let i = this.firstVisibleIndex; i < this.firstVisibleIndex + this.pageElements; i++) {
      const dc = this.firstWeek.plus(i);

      this.visibleWeeks.push({
        index: i,
        date_code: dc.toString(),
        first_day_of_week: dc.toDate(),
        week: dc.week,
        year: dc.year,
        marked_days: this.getMarkedDays(dc),
      });
    }
  }

  setSelectedDate(date: Date): void {
    const tomorrow = DateHelper.workDaysPlus(new Date(), 1, this.holidays);

    this.dateSelected = DateHelper.format(date);
    const dateLastWorkDay = DateHelper.workDaysMinus(date, this.dateHolder.prep_days_standard, this.holidays);
    const dateStart = DateHelper.workDaysMinus(dateLastWorkDay, this.dateHolder.work_days_standard, this.holidays);

    this.dateLastWorkDay = (tomorrow.getTime() > dateLastWorkDay.getTime()) ? tomorrow : dateLastWorkDay;
    this.dateStart = (tomorrow.getTime() > dateStart.getTime()) ? tomorrow : dateStart;

    this.totalWorkDaysFromStart = DateHelper.diffWorkDays(this.dateStart, this.dateLastWorkDay, this.holidays);
    this.totalWorkDaysFromLastWorkday = DateHelper.diffWorkDays(this.dateLastWorkDay, date, this.holidays);
  }

  setVisibleDays(): void {
    this.visibleDays = [];
    const currentDay = DateHelper.copy(this.visibleWeeks[0].first_day_of_week);
    const today = DateHelper.today();

    // get first and last day that should be selected
    const selectedDate = DateHelper.safeParse(this.dateSelected);
    this.setSelectedDate(selectedDate);

    for (let i = 0; i < this.pageElements * 7; i++) {
      const lastDayInMonth = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 0);
      const dayObject = {
        date: DateHelper.copy(currentDay),
        monthName: monthNames[currentDay.getMonth()],
        days_left: lastDayInMonth.getDate() - currentDay.getDate() + 1,
        index: this.firstVisibleIndex + i,
        classes: {'week-border-left': i % 7 === 0, 'month-border-left': currentDay.getDate() === 1},
      };

      const isHoliday = DateHelper.isHoliday(currentDay, this.holidays);
      const isWorkday = DateHelper.isWorkday(currentDay);

      if (today.getTime() === currentDay.getTime()) {
        dayObject.classes['red-frame'] = true;
      }

      if (isWorkday && !isHoliday && today.getTime() < currentDay.getTime()) {
        dayObject.classes['light-gray'] = true;
        dayObject.classes['click'] = true;
      }

      if ((this.dateStart.getTime() <= currentDay.getTime()) && (currentDay.getTime() <= selectedDate.getTime())) {
        if (!isWorkday || isHoliday) {
          dayObject.classes['light-blue'] = true;
        } else {
          dayObject.classes['dark-blue'] = true;
          dayObject.classes['light-gray'] = false;
        }

        if (this.dateStart.getTime() === currentDay.getTime() || selectedDate.getTime() === currentDay.getTime()) {
          dayObject.classes['blue-frame'] = true;
        }
      }

      this.visibleDays.push(dayObject);
      currentDay.setDate(currentDay.getDate() + 1);
    }
  }

}
