import {Ng2StateDeclaration, Transition, StateDeclaration, HookResult} from '@uirouter/angular';
import {ArticleListComponent} from '../../envisia/article/list/article-list.component';
import {ArticleHistoryService} from './services/article-history.service';
import {ArticleSpecificationService} from './services/article-specification.service';
import {ArticleHistoryComponent} from './main/history/article-history.component';
import {ArticleNavigationComponent} from './navigiation/article-navigation.component';
import {
  ArticleShippingInstructionComponent,
  articleShippingInstructionResolve
} from './shipping-instruction/article-shipping-instruction.component';
import {ArticleCreateComponent} from './main/create/article-create.component';
import {GlobalService} from '../../core/global.service';
import {ArticleDetailComponent} from './main/detail/article-detail.component';
import {ArticleService} from './services/article.service';
import {ArticleOverviewComponent} from './main/detail/article-overview.component';
import {ErpTransition} from '../../core/erp-transition.service';
import {ArticleMultilayerPlanHolderComponent} from './main/multilayer-plan/article-multilayer-plan-holder.component';
import {ArticleMultilayerPlanService} from './main/multilayer-plan/article-multilayer-plan.service';
import {ArticleMultilayerPlanHistoryService} from './main/history/article-multilayer-plan-history.service';

export function onExit(transition: Transition, state: StateDeclaration): HookResult {
  return transition.injector().get<GlobalService>(GlobalService).onExitCaller();
}

export const ARTICLE_STATE: Ng2StateDeclaration = {
  name: 'a.article',
  url: '/article',
  abstract: true,
  data: {requiresAuth: true}
};

export const ARTICLE_WORKFLOW_STATE: Ng2StateDeclaration = {
  name: 'a.article.workflow',
  abstract: true,
  data: {requiresAuth: true}
};

export function getArticleSpecificationResolveFn(specificationService: ArticleSpecificationService) {
  return specificationService.specification(false).toPromise();
}

export const ARTICLE_CREATE_STATE: Ng2StateDeclaration = {
  // FIXME: if we migrated all article states to angular we can use ARTICLE_STATE and use component: instead of views:
  name: 'a.article.create',
  url: '/create',
  views: {'main@a': {component: ArticleCreateComponent}},
  resolve: [{token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]}]
};

export function getArticleResolveFn(transition: Transition, articleService: ArticleService) {
  return articleService.detail(transition.params().id).toPromise();
}

export function getArticleMultilayerPlanResolveFn(transition: ErpTransition, service: ArticleMultilayerPlanService) {
  return service.get(transition.params().id).toPromise();
}

function resolveCopyMlPlan(transition: Transition) {
  return transition.params().copy_ml === 'true' || transition.params().copy_ml === true;
}

export const ARTICLE_DETAIL_STATE: Ng2StateDeclaration = {
  name: 'a.article.workflow.detail',
  url: '/detail/{id}?workflow_id',
  views: {'main@a': {component: ArticleDetailComponent}},
  params: {
    id: {dynamic: true},
    workflow_id: {dynamic: true},
    copy_ml: {dynamic: true},
  },
  resolve: [
    {token: 'article', resolveFn: getArticleResolveFn, deps: [Transition, ArticleService]},
    {token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]},
    {token: 'copyMl', resolveFn: resolveCopyMlPlan, deps: [Transition]},
    {
      token: 'multilayerPlan',
      resolveFn: getArticleMultilayerPlanResolveFn,
      deps: [Transition, ArticleMultilayerPlanService],
    },
  ]
};

export const ARTICLE_OVERVIEW_STATE: Ng2StateDeclaration = {
  name: 'a.article.workflow.overview',
  url: '/overview/{id}?workflow_id',
  views: {'main@a': {component: ArticleOverviewComponent}},
  resolve: [
    {token: 'article', resolveFn: getArticleResolveFn, deps: [Transition, ArticleService]},
    {token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]},
    {
      token: 'multilayerPlan',
      resolveFn: getArticleMultilayerPlanResolveFn,
      deps: [Transition, ArticleMultilayerPlanService],
    },
  ]
};

export const ARTICLE_SHIPPING_INSTRUCTION_STATE: Ng2StateDeclaration = {
  name: 'a.article.workflow.extra',
  url: '/extra/{id}?workflow_id',
  views: {'main@a': {component: ArticleShippingInstructionComponent}},
  resolve: articleShippingInstructionResolve
};

export function articleHistoryListFn(service: ArticleHistoryService, trans: Transition) {
  return service.history(trans.params().id).toPromise();
}

export function articleMultilayerPlanHistoryResolveFn(service: ArticleMultilayerPlanHistoryService, transition: Transition) {
  return service.list({oaNr: transition.params().id}).toPromise();
}

export const ARTICLE_HISTORY_STATE: Ng2StateDeclaration = {
  name: 'a.article.workflow.history',
  url: '/history/{id}?workflow_id',
  views: {'main@a': {component: ArticleHistoryComponent}},
  resolve: [
    {token: 'article', resolveFn: getArticleResolveFn, deps: [Transition, ArticleService]},
    {token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]},
    {token: 'history', resolveFn: articleHistoryListFn, deps: [ArticleHistoryService, Transition]},
    {
      token: 'mlPlanHistory',
      resolveFn: articleMultilayerPlanHistoryResolveFn,
      deps: [ArticleMultilayerPlanHistoryService, Transition]
    },
    {
      token: 'multilayerPlan',
      resolveFn: getArticleMultilayerPlanResolveFn,
      deps: [Transition, ArticleMultilayerPlanService],
    },
  ]
};

export function articleListResolveFn(transition: ErpTransition, service: ArticleService) {
  return service.list(transition.params()).toPromise();
}

export const ARTICLE_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.article.list',
  url: '/list?page&order&sort&status&sds_nr&oa_nr&kd_art_nr&kd_art_extend&kd_art_extend2&customer&typ',
  views: {
    'navigation@a': {component: ArticleNavigationComponent},
    'main@a': {component: ArticleListComponent},
  },
  params: {
    page: {dynamic: true},
    order: {
      dynamic: true,
      value: 'DESC',
      replace: [
        {from: 'DESC_NULLS_LAST', to: 'DESC'},
        {from: 'ASC_NULLS_LAST', to: 'ASC'},
        {from: 'DESC_NULLS_FIRST', to: 'DESC'},
        {from: 'ASC_NULLS_FIRST', to: 'ASC'},
      ],
    },
    sort: {dynamic: true, value: 'sds_nr'},
    status: {dynamic: true, value: '5'},
    sds_nr: {dynamic: true},
    oa_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    kd_art_extend: {dynamic: true},
    kd_art_extend2: {dynamic: true},
    customer: {dynamic: true},
    typ: {dynamic: true}
  },
  resolve: [{token: 'listData', resolveFn: articleListResolveFn, deps: [ErpTransition, ArticleService]}],
};

export const ARTICLE_MULTILAYER_STATE: Ng2StateDeclaration = {
  name: 'a.article.workflow.multilayer',
  url: '/multilayer/{id}?workflow_id',
  views: {'main@a': {component: ArticleMultilayerPlanHolderComponent}},
  resolve: [
    {token: 'article', resolveFn: getArticleResolveFn, deps: [Transition, ArticleService]},
    {token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]},
    {
      token: 'multilayerPlan',
      resolveFn: getArticleMultilayerPlanResolveFn,
      deps: [Transition, ArticleMultilayerPlanService],
    },
  ]
};

// FIXME: angularjs 1.x use of exit hook for ng1
// const onExitHook: TransitionHookFn = (trans: Transition): HookResult => {
//   return trans.injector().get<ArticleComponentService>('dataService').onExitCaller();
// };

export const ARTICLE_STATES: Ng2StateDeclaration[] = [
  ARTICLE_STATE,
  ARTICLE_WORKFLOW_STATE,
  ARTICLE_CREATE_STATE,
  ARTICLE_DETAIL_STATE,
  ARTICLE_OVERVIEW_STATE,
  ARTICLE_SHIPPING_INSTRUCTION_STATE,
  ARTICLE_HISTORY_STATE,
  ARTICLE_LIST_STATE,
  ARTICLE_MULTILAYER_STATE,
];
