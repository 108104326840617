<div class="row top-buffer" *ngIf="article && (article?.date_deleted || article?.locked)">
  <div class="col-lg-12">
    <div class="alert alert-danger" role="alert">
      <div *ngIf="article.date_deleted"><span class="bold">Artikel wurde gelöscht!</span>
        Bearbeitung ist
        nicht mehr
        möglich!
      </div>
      <div *ngIf="article.locked !== null"><span class="bold">Artikel wurde gesperrt!</span>
        Bearbeitung ist
        nicht mehr
        möglich!
      </div>
    </div>
  </div>
</div>
