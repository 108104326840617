<ng-container *ngIf="fieldsLoaded">
  <business-order-material-unlimited-list [selectionData]="selectionData"
                                          [params]="params"
                                          [groups]="groups"
                                          [fieldValues]="fieldValues"
                                          [fieldList]="fields"
                                          [lockSelection]="lockSelection"
                                          (emitSelect)="emitSelect.emit($event)"
                                          (emitSelectWeek)="emitSelectWeek.emit($event)">
  </business-order-material-unlimited-list>
</ng-container>
<ng-container *ngIf="!fieldsLoaded">
  <div class="flex h-svh w-full items-center justify-center">
    <div class="inline-grid grid-cols-1 gap-6">
      <div class="flex items-center justify-center">
        <i class="fa fa-spin fa-circle-o-notch"></i>
      </div>
      <div>
        Wird geladen...
      </div>
    </div>
  </div>
</ng-container>
