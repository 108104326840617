import {Article, SmallArticle} from '../models/article.models';
import {Component, Input, OnInit} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {Category} from '../../finder/finder.models';
import {debug} from '../../../helper/debug.func';
import {ArticleMultilayerPlan} from '../main/multilayer-plan/article-multilayer-plan.models';

@Component({
  selector: 'article-document-new',
  templateUrl: './article-document-new.component.html'
})
export class ArticleDocumentNewComponent implements OnInit {
  @Input() category: Category[];
  @Input() article: Article | SmallArticle;
  @Input() multilayerPlan?: ArticleMultilayerPlan;
  initial: { article_sds_nr: string };
  workflow_id: string | null | undefined;

  constructor(private stateService: StateService) {
  }

  ngOnInit(): void {
    debug('Article:', this.article);
    this.initial = {article_sds_nr: this.article.sds_nr || this.article.oa_nr};
    this.workflow_id = this.stateService.params.workflow_id;
  }

}
