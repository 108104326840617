<div class="whitelist-item-head" [ngClass]="labelStatus">
  <div class="ba-list-head">
    <div class="row">
      <div class="col-md-4">
        <span>
          BA
          <a uiSref="a.business_order.view" [uiParams]="{id:businessOrder.id, index:businessOrder.index}">
            {{ businessOrder.id }}-{{ businessOrder.index }}
          </a> - {{ labelText }}
        </span>
        <ev-file-button *ngIf="businessOrder.status !== 0" [url]="businessOrderUri" [link]="false">
          <i class="fa fa-file-pdf-o" aria-hidden="true" title="BA PDF öffnen"></i>
        </ev-file-button>
        <span class="ba-label-spacer">&nbsp;</span>
        <business-order-label [surface]="article.data.surface_area"
                              [empb]="!!empb && !!businessOrder.first_contract"
                              [kanban]="!!kanban"
                              [hasPlugging]="businessOrder.has_plugging"
                              [hasCopperFilling]="businessOrder.has_copper_filling"
                              [hasReproduction]="!!businessOrder.reproduction"
                              [hasBlindHole]="!!article.data.blind_hole_tech_ls || !!article.data.blind_hole_tech_ds">
        </business-order-label>
      </div>

      <div class="col-md-4">
        <div class="ml-10" *ngIf="businessOrder.status === 4 && hashesAreValid">
          Fortschritt: {{ businessOrder.percent }} %
        </div>
        <div class="ml-10" *ngIf="businessOrder.status === 4 && !hashesAreValid">
          Abweichung zum aktuellen Artikel möglich
        </div>
      </div>

      <div class="col-md-4">
        <div *ngIf="businessOrder.status === 0" class="pull-right start-storno-controls">
          <button type="button" class="btn btn-sm btn-link" (click)="start()" *ngIf="('ba.start'|HasPerm)">
            Starten
          </button>
        </div>
        <div *ngIf="businessOrder.status >= 0 && businessOrder.status <= 4" class="pull-right start-storno-controls">
          <button type="button" class="btn btn-sm btn-link"
                  (click)="storno()"
                  *ngIf="businessOrder.status <= 1 && ('business_order.storno'|HasPerm)">
            Stornieren
          </button>
        </div>
        <div *ngIf="canSelectReproduction" class="pull-right start-storno-controls">
          <button type="button"
                  class="btn btn-sm btn-link"
                  (click)="selectReproduction()"
                  *ngIf="('ba.start'|HasPerm)">
            Nachfertigung
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">

    <table class="table inBoxtable">
      <tr>
        <th>Vorgang</th>
        <th>AT</th>
        <th>Start-Termin</th>
        <th>End-Termin</th>
        <th>Nutzen</th>
        <th>Best. Menge</th>
        <th>
          <span *ngIf="businessOrder.status === 1 && !businessOrder.applied_material">Vorgabe Material</span>
          <span *ngIf="businessOrder.status !== 0 && businessOrder.applied_material">Material</span>
        </th>
        <th *ngIf="businessOrder.status === 1">Produktions-Schritt</th>
        <th *ngIf="businessOrder.status === 1|| businessOrder.status === 2">Gutstück</th>
        <th *ngIf="businessOrder.status === 4">Fertigstellung</th>
      </tr>
      <tr>
        <td>
          <a uiSref="a.workflow.detail" [uiParams]="{id: businessOrder.workflow_id}">
            <span class="label label-info" *ngIf="isSameWorkflow">{{ businessOrder.workflow_id }}</span>
            <span *ngIf="!isSameWorkflow">{{ businessOrder.workflow_id }}</span>
          </a>
        </td>
        <td>
          <span [ngClass]="{label: businessOrder.express, 'label-purple': businessOrder.express}">
            {{ businessOrder.work_days }}
          </span>
        </td>
        <td>{{ businessOrder.start_date }} / {{ businessOrder.start_week }}</td>
        <td>{{ businessOrder.end_date }} / {{ businessOrder.end_week }}</td>
        <td>{{ businessOrder.utilize_quantity|money }}</td>
        <td>{{ businessOrder.quantity|money }}</td>
        <td>
          <span *ngIf="businessOrder.status === 1 && businessOrder.target_material && !businessOrder.applied_material">
            {{ businessOrder.target_material }}
          </span>
          <span *ngIf="businessOrder.status === 1 && !businessOrder.target_material && !businessOrder.applied_material">
            -
          </span>
          <span *ngIf="businessOrder.status !== 0 && businessOrder.applied_material">
            {{ businessOrder.applied_material }}
          </span>
        </td>
        <td *ngIf="businessOrder.status === 1">
          <span *ngIf="businessOrder.current_step">{{ businessOrder.current_step }}</span>
          <span *ngIf="!businessOrder.current_step">-</span>
        </td>
        <td *ngIf="businessOrder.status >= 1 && businessOrder.status <= 4">
          <span *ngIf="businessOrder.good_piece">{{ businessOrder.good_piece }}</span>
          <span *ngIf="!businessOrder.good_piece">-</span>
        </td>
        <td *ngIf="businessOrder.status >= 2 && businessOrder.status <= 4">{{ businessOrder.date_finished }}</td>
      </tr>
      <tr *ngIf="businessOrder.custom_delivery_quantities && !!businessOrder.custom_delivery_note">
        <td colspan="10"><b>Spezifische Unter-/Überlieferung:</b>&nbsp;{{businessOrder.custom_delivery_note}}</td>
      </tr>
    </table>

  </div>
</div>

<div class="row" *ngIf="!!businessOrder.reproduction">
  <div class="col-sm-6">
    <strong>{{businessOrder.reproduction.reason}}</strong> - {{businessOrder.reproduction.note}}
  </div>
  <div class="col-sm-6" *ngIf="!!businessOrder.reproduction.step_name">
    Zusammenfügung in Schritt: <strong>{{businessOrder.reproduction.step_name}}</strong>
  </div>
</div>
