import {Component, Input} from '@angular/core';

@Component({
  selector: 'article-changed-marker',
  templateUrl: './article-changed-marker.component.html',
})
export class ArticleChangedMarkerComponent {
  @Input() marked?: { original: any, ml: any };
  @Input() unit?: string;

  constructor() {
  }

  get unitPostfix(): string {
    return (this.unit ? ' ' + this.unit : '');
  }

  get newValue(): string {
    if (!!this.marked.ml) {
      return '\'' + this.marked.ml + this.unitPostfix + '\'';
    } else {
      return 'leer';
    }
  }

  get oldValue(): string {
    if (!!this.marked.original) {
      return '\'' + this.marked.original + this.unitPostfix + '\'';
    } else {
      return 'leer';
    }
  }

  get title(): string {
    if (this.marked) {
      return 'Änderung aus Multilayeraufbauplan: ' + this.newValue + ' (vorher: ' + this.oldValue + ')';
    } else {
      return '';
    }
  }
}
