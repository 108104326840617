import {Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {orderInnerForm} from './order-form.builders';

@Component({
  selector: 'order-request-main-order-position-form',
  templateUrl: './order-request-main-order-position-form.component.html',
})
export class OrderRequestMainOrderPositionFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() errors: { [key: string]: any; } = {};

  constructor(private fb: UntypedFormBuilder) {
  }

  get datas() {
    return this.form.get('data') as UntypedFormArray;
  }

  addPosition(): void {
    this.datas.push(orderInnerForm(this.fb));
  }
}
