<a uiSref="a.article.create" class="btn btn-default" *ngIf="('article.change'|HasPerm)">
  <i class="fa fa-plus"></i>
</a>

<a [href]="back()" class="btn btn-default" *ngIf="backButton">
  Zurück
</a>

<ng-container *ngIf="data?.article as article">
  <a uiSref="a.article.document.new" [uiParams]="{id:article.oa_nr}" class="btn btn-default"
     *ngIf="(state.includes('a.article.workflow') || state.includes('a.article.document') || state.includes('a.article.production')) && (('article.change'|HasPerm))">
    Neues Dokument
  </a>
</ng-container>
