import {Component, Input, OnInit} from '@angular/core';
import {Article} from '../models/article.models';
import {ArticleProduction, ArticleProductionStepView, ProductionStepEntity} from './production.models';
import {AlertService} from '../../../common/alert-service/alert.service';
import {ProductionArticleService} from './production-article.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleProductionEditComponent} from './article-production-edit.component';
import {deepCopy} from '../../../helper/deep-copy';
import {Subject} from 'rxjs';
import {ArticleProductionConfirmationModalComponent} from './confirmation-modal/article-production-confirmation-modal.component';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {BusinessOrderService} from '../../workflow/business-order/services/business-order.service';
import {ArticleMultilayerPlan} from '../main/multilayer-plan/article-multilayer-plan.models';

@Component({
  selector: 'article-production-list',
  templateUrl: './article-production-list.component.html',
})
export class ArticleProductionListComponent implements OnInit {
  @Input() data: { article: Article, objects: ArticleProductionStepView[] };
  @Input() productionStepList: ProductionStepEntity[];
  @Input() hash: ArticleProduction;
  @Input() multilayerPlan?: ArticleMultilayerPlan;
  eventsSubject: Subject<void> = new Subject<void>();
  articleProductionList: ArticleProductionStepView[];
  article: Article;

  validExecution: boolean;
  validHash = true; // TODO rename for the post-hash era

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private articleProductionService: ProductionArticleService,
              private businessOrderService: BusinessOrderService) {
  }

  ngOnInit(): void {
    this.article = this.data.article;
    this.articleProductionList = this.data.objects;
    this.validExecution = this.article.data.execution && this.article.data.execution !== 'SBU';
    // this.validHash = this.article.full_hash_v2 === this.hash.hash_v2 || this.article.full_hash_v1 === this.hash.hash;
    this.checkHash();
  }

  // TODO rename when the hash is gone from the codebase, for now the hash is still generated and written into the db!
  setHash() {
    if (confirm('Möchten Sie die Fertigungsliste wirklich freigeben?')) {
      this.articleProductionService.setHash(this.article.oa_nr).subscribe(value => {
        // this.validHash = this.article.full_hash_v2 === value.hash_v2 || this.article.full_hash_v1 === value.hash;
        this.checkHash();
        this.eventsSubject.next();
        this.alertService.add('success', 'Fertigungsliste erfolgreich freigegeben!');
      }, () => this.alert());
    }
  }

  addAll() {
    this.businessOrderService.countByArticle(this.article.oa_nr).subscribe(count => {
      const modalRef = this.modalService.open(ArticleProductionConfirmationModalComponent, {windowClass: 'modal2-mg'});
      modalRef.componentInstance.article = this.article;
      modalRef.componentInstance.hasBa = count > 0;
      modalRef.result.then(value => {
        this.articleProductionList = value;
        this.eventsSubject.next();
      }, ignoreRejection);
    });
  }

  create(productionId: number) {
    this.articleProductionService.create(this.article.oa_nr, productionId).subscribe(value => {
      this.articleProductionList.push(value);
      this.eventsSubject.next();
      this.alertService.add('success', 'Fertigungsschritt erfolgreich hinzugefügt!');
    }, () => this.alert());
  }

  deleteStep(index: number, id: number) {
    if (confirm('Möchten Sie den ' + id + '. Artikelschritt wirklich löschen?')) {
      this.articleProductionService.remove(this.article.oa_nr, id).subscribe(() => {
        this.articleProductionList.splice(index, 1);
        this.articleProductionList.forEach((obj) => {
          if (obj.article.ordering > id) {
            obj.article.ordering = obj.article.ordering - 1;
          }
        });
        this.alertService.add('success', 'Fertigungsschritt erfolgreich gelöscht!');
      }, () => this.alert());
    }
  }

  up(index: number, id: number) {
    this.articleProductionService.up(this.article.oa_nr, id).subscribe(() => {
      const tmp = this.articleProductionList[index];
      this.articleProductionList[index] = this.articleProductionList[index - 1];
      this.articleProductionList[index - 1] = tmp;
      const tmpNumber = this.articleProductionList[index].article.ordering;
      this.articleProductionList[index].article.ordering = this.articleProductionList[index - 1].article.ordering;
      this.articleProductionList[index - 1].article.ordering = tmpNumber;
    }, () => this.alert());
  }

  down(index: number, id: number) {
    this.articleProductionService.down(this.article.oa_nr, id).subscribe(() => {
      const tmp = this.articleProductionList[index];
      this.articleProductionList[index] = this.articleProductionList[index + 1];
      this.articleProductionList[index + 1] = tmp;
      const tmpNumber = this.articleProductionList[index].article.ordering;
      this.articleProductionList[index].article.ordering = this.articleProductionList[index + 1].article.ordering;
      this.articleProductionList[index + 1].article.ordering = tmpNumber;
    }, () => this.alert());
  }

  edit(index: number, obj: ArticleProductionStepView) {
    const modalRef = this.modalService.open(ArticleProductionEditComponent);
    modalRef.componentInstance.article = this.article;
    modalRef.componentInstance.data = deepCopy(obj);
    modalRef.result.then(value => {
      obj.article.infoText = value.article.infoText;
      obj.article.lockText = value.article.lockText;
    }, () => { // ignore modal backdrop
    });
  }

  private alert() {
    this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut oder ' +
      'kontaktieren Sie Ihren Administrator!');
  }

  private checkHash() {
    this.articleProductionService.checkHash(this.data.article.oa_nr).subscribe(value => {
      this.validHash = value;
    }, () => {
    });
  }

}
