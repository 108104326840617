import {Component, Input} from '@angular/core';
import {OrderDataConfirmed} from '../../order-request.models';

@Component({
  selector: 'order-box-history-confirmed',
  templateUrl: './order-box-history-confirmed.component.html',
})
export class OrderBoxHistoryConfirmedComponent {

  @Input() type: string;
  @Input() history: OrderDataConfirmed;

  constructor() {
  }
}
