<div class="buttonbar-view">
  <multilayer-plan-navigation [locked]="saveBtnLocked" (saveEmitter)="saveBtnPressedSet()">
  </multilayer-plan-navigation>
</div>
<div class="row">
  <div class="col-lg-9">
    <multilayer-plan-update [multilayerPlan]="multilayerPlan"
                            [specification]="specification"
                            [changes]="changes"
                            [saveBtnPressed]="saveBtnPressed"
                            (saveBtnLocked)="saveBtnLockedFn($event)"
                            (modelChanged)="modelChanged($event)"
                            (modelSaved)="modelSaved($event)">
    </multilayer-plan-update>
  </div>
  <div class="col-lg-3">
    <multilayer-plan-list [list]="list"></multilayer-plan-list>
  </div>
</div>
