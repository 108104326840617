import {Component, Input, OnInit} from '@angular/core';
import {
  MaterialArticle,
  MaterialGroupListElement,
  MaterialStorage,
  MaterialInventory,
  MaterialTransaction,
  MaterialVariation,
  MaterialAggregation,
} from '../material-management.model';
import {StateService} from '@uirouter/core';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {Lister} from '../../../common/wrapper.models';

@Component({
  selector: 'material-inventory-select',
  templateUrl: './material-inventory-select.component.html'
})
export class MaterialInventorySelectComponent implements OnInit {
  @Input() materialInventoryAggregations: MaterialInventory;
  @Input() currentGroup: MaterialGroupListElement;
  @Input() currentArticle: MaterialArticle;
  @Input() currentVariation: MaterialVariation;
  @Input() currentInventory: MaterialStorage[];
  @Input() transactionLister: LoadingWrapper<Lister<MaterialTransaction>>;
  @Input() currentAggregation: MaterialAggregation = null;
  @Input() searchParams: { [key: string]: string };

  constructor(private stateService: StateService) {
  }

  ngOnInit() {
    if (this.currentAggregation) {
      this.currentGroup = this.currentAggregation.group;
      this.currentArticle = this.currentAggregation.article;
      this.currentVariation = this.currentAggregation.variation;
    }
  }

  setCurrentGroup(group: MaterialGroupListElement): void {
    if (group) {
      this.currentGroup = group;
    } else {
      this.clearCurrentGroup();
    }
  }

  setCurrentArticle(article: MaterialArticle): void {
    if (article) {
      this.currentArticle = article;
    } else {
      this.clearCurrentArticle();
    }
  }

  setCurrentVariation(variation: MaterialVariation): void {
    if (variation) {
      this.stateService.go('a.material.inventory.update', {variation: variation.id});
    } else {
      this.clearCurrentVariation();
    }
  }

  clearCurrentGroup(): void {
    this.currentGroup = null;
    this.clearCurrentArticle();
  }

  clearCurrentArticle(): void {
    this.currentArticle = null;
    this.clearCurrentVariation();
  }

  clearCurrentVariation(): void {
    this.currentVariation = null;
    this.currentAggregation = null;
    this.currentInventory = [];
  }
}
