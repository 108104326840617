import {Component, Input, OnInit} from '@angular/core';
import {ArticleV3Service} from '../../article/services/article3.service';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {Article} from '../../article/models/article.models';

@Component({
  selector: 'price-detail-sister-article',
  templateUrl: './price-detail-sister-article.component.html'
})
export class PriceDetailSisterArticleComponent implements OnInit {
  @Input() sister: string | null | undefined;
  data: LoadingWrapper<Article> | null = null;

  constructor(private articleV3Service: ArticleV3Service) {
  }

  ngOnInit(): void {
    if (this.sister) {
      this.data = new LoadingWrapper(this.articleV3Service.findSister(this.sister));
    }
  }
}
