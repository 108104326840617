import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  MaterialOrderIntakeGroupElement,
  MaterialOrderTransactionCreateForm,
  MaterialOrderTransactionListElement
} from '../material-order.model';
import {environment} from '../../../../../environments/environment';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {Lister} from '../../../../common/wrapper.models';
import {ListService} from '../../../../common/search/search.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialOrderUpdateService implements ListService<MaterialOrderIntakeGroupElement> {
  constructor(protected http: HttpClient) {
  }

  list(form: any): Observable<Lister<MaterialOrderIntakeGroupElement>> {
    return this.http.get<Lister<MaterialOrderIntakeGroupElement>>(
      environment.apiv4uri + 'material/order/intake/list/paginated', {params: EnvisiaLocation.httpParams(form)}
    );
  }
}
