import {OposCorrection, OposFinalData} from '../../opos/opos.models';
import {Money} from '../../../common/money/Money';
import { EventEmitter, Input, Output, Directive } from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import Helper from '../../../helper/helper';
import {noop} from '../../../helper/noop';

@Directive()
export abstract class CustomerOposSharedTableComponent {
  @Input() form: UntypedFormGroup;
  @Input() errors: { [key: string]: any; } = {};
  @Input() totalRevenue: string;
  @Input() totalOpen: string;
  @Input() totalExpensed: string;
  @Output() focuser = new EventEmitter<{ keyCode: string, index: number, type: string, tableName: string }>();
  @Output() updateTotals = new EventEmitter<{ name: string, open: string, expensed: string }>();
  abstract objects: OposFinalData[] | OposCorrection[];

  tableName: string;

  constructor(name: string) {
    this.tableName = name;
  }

  get table(): UntypedFormArray {
    return this.form.get(this.tableName) as UntypedFormArray;
  }

  skonto(index: number, data: string | null | undefined, obj: OposFinalData | OposCorrection): void {
    this.parse(index, 'skonto', data, (skontoValue: number) => {
      const fg = (this.table.controls[index] as UntypedFormGroup);
      const skonto = Money.toInt(skontoValue);
      const expensed = obj.numberRevenue - obj.numberRevenue * skonto / 100;
      this.patcher(
        fg,
        obj,
        expensed,
        Money.stringify(obj.numberRevenue - expensed, 2),
        skonto
      );
    });
  }

  expensed(index: number, data: string | null | undefined, obj: OposFinalData | OposCorrection): void {
    this.parse(index, 'expensed', data, (expensed: number) => {
      const fg = (this.table.controls[index] as UntypedFormGroup);
      this.patcher(fg, obj, expensed, Money.stringify(obj.numberRevenue - expensed, 2));
    });
  }

  save(index: number, value: boolean, obj: OposFinalData | OposCorrection) {
    const fg = (this.table.controls[index] as UntypedFormGroup);
    if (value) {
      obj.open = Money.stringify(0.00);
      this.calculateTotal();
    } else {
      this.expensed(index, fg.controls.expensed.value, obj);
    }
  }

  skontoCustomer(index: number, data: OposFinalData | OposCorrection): void {
    const fg = (this.table.controls[index] as UntypedFormGroup);
    this.setSaved(fg, data);
    this.skonto(index, data.customer_skonto.toString(), data);
  }

  putAll(index: number, data: OposFinalData | OposCorrection): void {
    const fg = (this.table.controls[index] as UntypedFormGroup);
    this.setSaved(fg, data);
    this.expensed(index, data.revenue, data);
  }

  focus(event: KeyboardEvent, index: number, type: string, obj: OposFinalData | OposCorrection): void {
    const keyCode = event.key;
    if (keyCode === 'ArrowLeft') { // left
      this._moveLeft(type, obj);
    } else if (keyCode === 'ArrowRight') { // right
      this._moveRight(type, obj);
    } else if (keyCode === 'ArrowUp' && index !== 0) { // up
      this.objects[index - 1]['focus_' + type] = this.objects[index - 1]['focus_' + type] + 1;
    } else if (keyCode === 'ArrowDown' && index + 1 < this.objects.length) { // down
      this.objects[index + 1]['focus_' + type] = this.objects[index + 1]['focus_' + type] + 1;
    } else {
      this.focuser.next({keyCode: keyCode, index: index, type: type, tableName: this.tableName});
    }
  }

  private setSaved(fg: UntypedFormGroup, obj: OposFinalData | OposCorrection): void {
    noop(this);
    fg.patchValue({save: true});
    obj.open = Money.stringify(0.00);
    this.calculateTotal();
  }

  private patcher(fg: UntypedFormGroup,
                  obj: OposFinalData | OposCorrection,
                  expensed: number,
                  skontoTotal: string,
                  skonto?: number): void {

    const patchValue = {
      changed: true,
      expensed: Money.stringify(expensed),
      skonto_total: skontoTotal
    };
    if (skonto !== undefined) {
      patchValue['skonto'] = skonto;
    }
    // patch all values
    fg.patchValue(patchValue);
    fg.markAsDirty();

    const save = fg.controls.save.value;
    if (!save) {
      obj.open = this.calculateOpen(obj, expensed, save, obj.numberRevenue);
    }
    this.calculateTotal();
  }

  private calculateOpen(obj: OposFinalData | OposCorrection, expensed: number, save: boolean, rg?: number): string {
    if (rg === undefined || rg === null) {
      rg = obj.numberRevenue;
    }
    if (!save) {
      return Money.stringify(rg - expensed);
    } else {
      return Money.stringify(0.00);
    }
  }

  private _moveLeft(type, obj) {
    if (type === 'save') {
      obj.focus_skonto = obj.focus_skonto + 1;
    } else if (type === 'skonto') {
      obj.focus_expensed = obj.focus_expensed + 1;
    }
  }

  private _moveRight(type, obj) {
    if (type === 'expensed') {
      obj.focus_skonto = obj.focus_skonto + 1;
    } else if (type === 'skonto') {
      obj.focus_save = obj.focus_save + 1;
    }
  }

  private parse(index: number, name: string, value: string | undefined, func: (number) => void): void {
    const money = Money.parse(value);
    if (money !== undefined && money !== null) {
      delete this.errors['obj.' + this.tableName + '[' + index + '].' + name];
      func(money);
    } else {
      this.errors['obj.' + this.tableName + '[' + index + '].' + name] = true;
    }
  }

  private calculateTotal() {
    this.totalExpensed = Money.stringify(
      Helper.foldLeft(this.table.controls, (obj: UntypedFormGroup) => Money.parse(obj.controls.expensed.value), 0)
    );
    this.totalOpen = Money.stringify(
      Helper.foldLeft(this.objects, (obj: OposFinalData | OposCorrection) => Money.parse(obj.open), 0)
    );
    this.updateTotals.next({name: this.tableName, open: this.totalOpen, expensed: this.totalExpensed});
  }

}
