<form [formGroup]="createForm" name="form" (ngSubmit)="create()">
  <div style="display: inline-block;">
    <div style="float: left; clear: left; margin-bottom: 10px; width: 100%;">
      <input class="form-control" id="id_name" maxlength="100" placeholder="Kundenname hinzufügen" type="text"
             formControlName="name"
             [ngClass]="{red_alert: errors.name}">
    </div>
    <div style="float: left; clear: left; margin: 0 0 10px 0; width:450px;">
      <div class="contentbox customerbox">
        <h4>Rechnungsadresse</h4>
        <div class="form-group">
          <label class="col-sm-2 control-label">
            Straße:
          </label>

          <div class="col-sm-10">
            <input class="form-control" id="id_invoice-street" maxlength="100" placeholder="Straße hinzufügen"
                   type="text"
                   formControlName="street"
                   [ngClass]="{red_alert: errors.street}">
          </div>
          <label class="col-sm-2 control-label">
            Ort:
          </label>

          <div class="col-sm-10">
            <input placeholder="Ort hinzufügen" class="form-control" id="id_invoice-city" maxlength="100" type="text"
                   formControlName="city"
                   [ngClass]="{red_alert: errors.city}">
          </div>
          <label class="col-sm-2 control-label">
            PLZ:
          </label>

          <div class="col-sm-10">
            <input class="form-control" id="id_invoice-zip" maxlength="10" placeholder="Postleitzahl hinzufügen"
                   type="text"
                   formControlName="zip"
                   [ngClass]="{red_alert: errors.city}">
          </div>
          <label class="col-sm-2 control-label">
            Land:
          </label>

          <div class="col-sm-10">
            <select class="form-control" style="width: 308px;" formControlName="country" [ngClass]="{red_alert: errors.country}">
              <option *ngFor="let obj of countries" [value]="obj.iso3">{{ obj.name_de }}</option>
            </select>
          </div>

          <label class="col-sm-2 control-label">
            Vertriebsgebiet:
          </label>

          <div class="col-sm-10">
            <select class="form-control" style="width: 308px;"
                    formControlName="field_service"
                    [ngClass]="{red_alert: errors.field_service}">
              <option *ngFor="let obj of fieldServices" [value]="obj.name">{{ obj.name }}</option>
            </select>
          </div>

        </div>
      </div>
    </div>

    <div style="float: left; margin: 0 0 10px 10px;">
      <div class="contentbox customerbox">
        <h4>Kontakt</h4>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            E-Mail:
          </label>

          <div class="col-sm-10">
            <input class="form-control" id="id_mail" maxlength="75" placeholder="E-Mail-Adresse hinzufügen" type="text"
                   formControlName="mail"
                   [ngClass]="{red_alert: errors.mail}">
          </div>
          <label class="col-sm-2 control-label">
            Telefon:
          </label>

          <div class="col-sm-10">
            <input class="form-control" id="id_phone" maxlength="50" placeholder="Telefon-Nummer hinzufügen" type="text"
                   formControlName="phone"
                   [ngClass]="{red_alert: errors.phone}">
          </div>
          <label class="col-sm-2 control-label">
            Fax:
          </label>

          <div class="col-sm-10">
            <input class="form-control" id="id_fax" maxlength="50" placeholder="Fax-Nummer hinzufügen" type="text"
                   formControlName="fax"
                   [ngClass]="{red_alert: errors.fax}">
          </div>
          <label class="col-sm-2 control-label">
            Webseite:
          </label>

          <div class="col-sm-10">
            <input class="form-control" id="id_website" maxlength="200" placeholder="Webseite hinzufügen" type="text"
                   formControlName="website"
                   [ngClass]="{red_alert: errors.website}">
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div id="address-container" style="margin-top: 20px; margin-bottom: 20px; width: 600px">
    </div>

    <div style="clear: both" *ngIf="duplicated.length > 0" class="red_alert">
      <h3>Kunde bereits vorhanden:</h3>
      <ul *ngFor="let customer of duplicated">
        <li>
          <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}" target="_blank">
            {{ customer.name }}
          </a>
        </li>
      </ul>
    </div>

    <div style="float: left; clear: left; margin-top: 10px;" class="pull-right">
      <button type="submit" class="btn btn-primary " style="width: 150px;"
              [disabled]="!createForm.valid || loading">
        Hinzufügen
      </button>
    </div>
  </div>
</form>
