<div class="row">
  <div class="col-sm-12 btn-group simple-margin">
    <button (click)="serviceAddFetch()" class="btn btn-default btn-sm">
      Artikel hinzufügen
    </button>
    <button (click)="serviceAddFetchExtra()" class="btn btn-default btn-sm">
      Zusatzkosten hinzufügen
    </button>
  </div>
</div>


<div class="row contract-position-list">
  <div class="col-md-12">

    <form>
      <table class="table whitetable tablemiddle">
        <thead>
        <tr>
          <th>Abruf</th>
          <th>Bezeichnung</th>
          <th class="align-right" style="width: 60px;">Menge</th>
          <th class="align-right" style="width: 120px;">Einzel</th>
          <th class="align-right">Gesamt</th>
          <th>Abruf Bestell-Nr.</th>
          <th>Liefertermin</th>
          <th>Reklamation</th>
          <th></th>
        </tr>
        </thead>
        <tbody (contract-create-form-item-delete)="serviceDropFetch($event)"
               (contract-create-form-item-trigger)="triggerPrice.emit(true)"
               *ngFor="let fetch of workflow.frame.fetch; trackBy: trackByFetch; let fetchIndex = index"
               [contract-create-form-item-last-fetch-id]="createdLastFetchId"
               [contract-create-form-item-index]="fetchIndex"
               [contract-create-form-item-nr]="form.nr"
               [contract-create-form-item-frame]="workflow.frame"
               [contract-create-form-item-article]="article"
               [contract-create-form-item-price-increase-flex]="priceIncreaseFlex"
               [contract-create-form-item]="fetch">
        </tbody>
      </table>
    </form>

  </div>

</div>
