import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {OFFER_STATES} from './offer.states';
import {WorkflowNavigationModule} from '../navigation/workflow-navigation.module';
import {OfferListComponent} from './list/offer-list.component';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {OfferInfoModalComponent} from './history/offer-info-modal.component';
import {OfferContractCreateComponent} from './create/offer-contract-create.component';
import {OfferPositionModalComponent} from './position/offer-position-modal.component';
import {OfferHistoryModalComponent} from './history/offer-history-modal.component';
import {CommentModule} from '../../comment/comment.module';
import {ArticleBoxModule} from '../../article/box/article-box.module';
import {CustomerBoxModule} from '../../customer/box/customer-box.module';
import {ContactBoxModule} from '../../customer/contact-box/contact-box.module';
import {OfferDetailModule} from './detail/offer-detail.module';
import {OfferCreateModule} from './create/offer-create.module';
import {CreateTaskModule} from '../create-task/create-task.module';
import {OfferRejectionModalComponent} from './list/offer-rejection-modal.component';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: OFFER_STATES }),
        NgbModalModule,
        NgbTooltipModule,
        SharedModule,
        // Sub Modules
        OfferDetailModule,
        OfferCreateModule,
        // Imported Modules
        WorkflowNavigationModule,
        WorkflowPipesModule,
        CommentModule,
        ArticleBoxModule,
        CustomerBoxModule,
        ContactBoxModule,
        CreateTaskModule,
    ],
    declarations: [
        OfferListComponent,
        OfferRejectionModalComponent,
        OfferContractCreateComponent,
        // Modals
        OfferInfoModalComponent,
        OfferHistoryModalComponent,
        OfferPositionModalComponent,
    ]
})
export class OfferModule {
}
