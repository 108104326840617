<div class="modal-header">
  <h3 class="modal-title">
    Material Artikel bearbeiten
  </h3>
</div>
<form class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="form-group">
      <label class="col-md-3 control-label" for="vendor_price">Preis</label>
      <div class="col-md-9">
        <input id="vendor_price"
               class="form-control"
               formControlName="vendor_price"
               [ngClass]="{red_alert: errors.vendor_price}">
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-3 control-label" for="min_stock">Mindestbestand</label>
      <div class="col-md-9">
        <input id="min_stock"
               type="number"
               min="0"
               step="1"
               class="form-control"
               formControlName="min_stock"
               [ngClass]="{red_alert: errors.min_stock}">
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-3 control-label" for="min_order">Mindestbestellung</label>
      <div class="col-md-9">
        <input id="min_order"
               type="number"
               min="0"
               step="1"
               class="form-control"
               formControlName="min_order"
               [ngClass]="{red_alert: errors.min_order}">
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-3 control-label" for="lead_time">Beschaffungszeit</label>
      <div class="col-md-9">
        <input id="lead_time"
               type="number"
               min="0"
               step="1"
               class="form-control"
               formControlName="lead_time"
               [ngClass]="{red_alert: errors.lead_time}">
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-3 control-label" for="lead_time">Bevorzugt</label>
      <div class="col-md-9">
        <input id="favorite"
               type="checkbox"
               class="form-control"
               formControlName="favorite"
               [ngClass]="{red_alert: errors.favorite}">
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-left" (click)="dismiss()">Abbrechen</button>
    <button type="submit" class="btn btn-success pull-right">Speichern</button>
  </div>
</form>
