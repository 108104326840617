import { Injectable } from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {environment} from '../../../../environments/environment';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {MaterialPlanningRelationWithWeek} from './material-planning.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialPlanningSmallListService implements ListService<MaterialPlanningRelationWithWeek> {

  constructor(protected http: HttpClient) {
  }

  list(query?: any): Observable<Lister<MaterialPlanningRelationWithWeek>> {
    return this.http.get<Lister<MaterialPlanningRelationWithWeek>>(
      environment.apiv4uri + 'material/planning/list/small',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}
