import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BusinessOrderReproductionReason, BusinessOrderReproductionReasonForm} from './config-reproduction.model';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({
  providedIn: 'root'
})
export class ConfigReproductionService {
  private uri = `${environment.apiv4uri}config/business/order/reproduction`;

  constructor(private http: HttpClient) {
  }

  list(withDeleted: boolean = false): Observable<BusinessOrderReproductionReason[]> {
    return this.http.get<BusinessOrderReproductionReason[]>(
      this.uri,
      {params: EnvisiaLocation.httpParams({'with_deleted': withDeleted})}
    );
  }

  create(form: BusinessOrderReproductionReasonForm): Observable<BusinessOrderReproductionReason> {
    return this.http.post<BusinessOrderReproductionReason>(this.uri, form);
  }

  update(id: number, form: BusinessOrderReproductionReasonForm): Observable<BusinessOrderReproductionReason> {
    return this.http.put<BusinessOrderReproductionReason>(`${this.uri}/${id}`, form);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<any>(`${this.uri}/${id}`);
  }
}
