/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {PriceBoxComponent} from './box/price-box.component';
import {PriceListComponent} from './list/price-list.component';
import {UIRouterModule} from '@uirouter/angular';
import {NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {WorkflowStatusModalModule} from '../workflow/status-modal/workflow-status-modal.module';
import {CommentModule} from '../comment/comment.module';
import {PriceDetailComponent} from './detail/price-detail.component';
import {PriceDetailListComponent} from './detail/price-detail-list.component';
import {PriceDetailArticleCalcComponent} from './detail/price-detail-article-calc.component';
import {PriceDetailHistoryComponent} from './detail/price-detail-history.component';
import {ProducerModalModule} from '../producer/producer-modal.module';
import {ArticleBoxModule} from '../article/box/article-box.module';
import {PriceSalesNoteModalComponent} from './detail/price-sales-note-modal.component';
import {PRICE_STATES} from './price.states';
import {PriceDetailNotesModalComponent} from './detail/price-detail-notes-modal.component';
import {PriceDetailOrderComponent} from './detail/price-detail-order.component';
import {LoadingOrErrorModule} from '../../common/loading/loading-or-error.module';
import {PriceDetailSisterArticleComponent} from './detail/price-detail-sister-article.component';
import {PriceSelectorModule} from '../../common/price-selector/price-selector.module';
import {DeliveryConditionBoxModule} from '../workflow/delivery-condition-box/delivery-condition-box.module';
import {ArticleModule} from '../article/article.module';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: PRICE_STATES }),
        SharedModule,
        NgbModalModule,
        NgbTooltipModule,
        // Detail:
        WorkflowStatusModalModule,
        CommentModule,
        ArticleBoxModule,
        ProducerModalModule,
        LoadingOrErrorModule,
        PriceSelectorModule,
        DeliveryConditionBoxModule,
        ArticleModule,
    ],
    declarations: [
        PriceListComponent,
        PriceDetailComponent,
        // Subcomponents
        PriceSalesNoteModalComponent,
        PriceDetailNotesModalComponent,
        PriceDetailHistoryComponent,
        PriceDetailListComponent,
        PriceBoxComponent,
        PriceDetailArticleCalcComponent,
        PriceDetailOrderComponent,
        PriceDetailSisterArticleComponent
    ]
})
export class PriceModule {
}
