import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerId} from '../../model/customer';
import {Observable, from, throwError} from 'rxjs';
import {Contact} from '../../model/contact';
import {CustomerContactCreateComponent} from './customer-contact-create.component';
import {CustomerContactDepartmentListService} from '../../../../lazy/config/departments/customer-contact-department-list.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Injectable({providedIn: 'root'})
export class ContactCreateModalService {

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private departmentListService: CustomerContactDepartmentListService) {
  }

  open(customer: CustomerId): Observable<Contact> {
    return this.departmentListService.list({sort: 'name'}).pipe(
      catchError(() => {
        this.alertService.add('danger', 'Beim Aufrufen der Abteilungen ist ein Fehler aufgetreten!');
        return throwError('some requests failed');
      }),
      mergeMap(l => {
        const modalRef = this.modalService.open(CustomerContactCreateComponent, {windowClass: 'modal2-mm'});
        modalRef.componentInstance.customer = customer;
        modalRef.componentInstance.departments = l.objects;
        return from(modalRef.result);
      })
    );
  }

}
