/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */

import {NgModule} from '@angular/core';
import {AdapterListComponent} from './list/adapter-list.component';
import {UIRouterModule} from '@uirouter/angular';
import {ADAPTER_STATES} from './adapter.states';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../core/shared.module';
import {MainModule} from '../../main/main.module';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: ADAPTER_STATES }),
        NgbTooltipModule,
        SharedModule,
        MainModule,
        WorkflowPipesModule,
    ],
    declarations: [AdapterListComponent]
})

export class AdapterModule {}
