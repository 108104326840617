import {Component, Input} from '@angular/core';
import {BigCreateWorkflow, BigWorkflowOffer} from '../models/workflow-create.models';
import {StateService} from '@uirouter/core';
import {WorkflowStatusModalService} from '../status-modal/workflow-status-modal.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {WorkflowDuplicateModalComponent} from './workflow-duplicate-modal/workflow-duplicate-modal.component';
import {from} from 'rxjs';
import {WorkflowDetailService} from '../workflow-services/workflow-detail.service';
import {flatMap} from 'rxjs/operators';

@Component({
  selector: 'workflow-navigation',
  templateUrl: './workflow-navigation.component.html',
  styleUrls: ['./workflow-navigation.component.scss']
})
export class WorkflowNavigationComponent {
  @Input() data?: BigCreateWorkflow | BigWorkflowOffer;

  constructor(private stateService: StateService,
              private workflowDetailService: WorkflowDetailService,
              private statusModalService: WorkflowStatusModalService,
              private modalService: NgbModal) {
  }

  duplicate(): void {
    const modalRef = this.modalService.open(WorkflowDuplicateModalComponent, {windowClass: 'modal2-slg'});
    modalRef.componentInstance.data = this.data;
    from(modalRef.result).subscribe((responseId) => {
      if (this.stateService.is('a.workflow.detail')) {
        // Changes the route manually
        this.stateService.go(this.stateService.current, {id: responseId}, {reload: true, location: true});
      } else {
        this.stateService.go('a.workflow.detail', {id: responseId});
      }
    }, ignoreRejection);
  }

  open(typ: 'cancel' | 'delete' | 'status'): void {
    this.statusModalService.open(this.data.workflow.object.id, typ).pipe(
      flatMap(() => this.workflowDetailService.call(this.data.workflow.object.id))
    ).subscribe(workflow => {
      // multiple things come here together, which makes a reload a little bit more painful
      // first of all, all offers should be redirected to our contract view
      // second the contract view should be reloaded
      // however subview within a routable component is only possible, if the data does not get passed
      // by a resolve transition, thus we first resolve the data and only reload the contract view
      this.stateService.go('a.workflow.detail.detail_contract', {
        data: workflow,
        id: this.data.workflow.object.id
      }, {reload: 'a.workflow.detail.detail_contract', location: true});

    }, ignoreRejection);
  }

}
