<div class="article-form-item" [formGroup]="form">
  <label [htmlFor]="'article-model-' + model" class="article-form-item-label" [ngClass]="{bold: bold}">{{ label }}:</label>
  <div class="article-form-item-data">

    <div class="article-form-data-content" [ngClass]="{'multi-field': !!model2}">
      <input type="checkbox"
             [id]="'article-model-' + model"
             [ngClass]="{red_alert: error}" [formControlName]="model"
             (change)="change()">
    </div>

    <div class="article-form-data-content" *ngIf="model2">
      <input type="text" class="form-control fixed-width" id="model2"
             [ngClass]="{red_alert: error2}" [formControlName]="model2" (change)="change2()">
    </div>

  </div>
</div>
