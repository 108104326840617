import {Injectable} from '@angular/core';
import {AlertService} from '../../../common/alert-service/alert.service';
import {BusinessOrderService} from '../../workflow/business-order/services/business-order.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleDeleteModalComponent} from '../main/delete/article-delete.modal.component';
import {mergeMap} from 'rxjs/operators';
import {catchError} from 'rxjs/operators';
import {ArticleSpecificationService} from '../services/article-specification.service';
import {forkJoin, throwError, from, Observable} from 'rxjs';
import {ArticleSpecification} from '../models/article-specification.model';

@Injectable({providedIn: 'root'})
export class ArticleDeleteService {

  constructor(private articleSpecificationService: ArticleSpecificationService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private businessOrderService: BusinessOrderService) {
  }

  deleteArticle(article: string): Observable<void> {
    return forkJoin([
      this.businessOrderService.hasActiveByArticle(article),
      this.articleSpecificationService.specification()
    ]).pipe(
      catchError(error => {
        console.log('Modal Error', error);
        this.alertService.add('danger', 'Ein unbekannter Fehler ist aufgetreten!');
        return throwError('modal error');
      }),
      mergeMap((data: [boolean, { [p: string]: ArticleSpecification }]) => {
        const modalRef = this.modalService.open(ArticleDeleteModalComponent, {windowClass: 'modal2-mm'});
        modalRef.componentInstance.oa = article;
        modalRef.componentInstance.has_active_bas = data[0];
        modalRef.componentInstance.notices = data[1]['blocking_notice'].values;

        return from(modalRef.result);
      })
    );
  }

}
