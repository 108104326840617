import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {MaterialOrderPositionService} from '../material-order-position.service';

@Component({
  selector: 'material-order-position-status-select',
  templateUrl: './material-order-position-status-select.component.html',
})
export class MaterialOrderPositionStatusSelectComponent {
  @Input() id: number;
  @Input() status: number;

  constructor(private modalRef: NgbActiveModal,
              private mops: MaterialOrderPositionService,
              private alertService: AlertService) {
  }

  dismiss(): void {
    this.modalRef.dismiss(null);
  }

  save(status: number): void {
    this.mops.updateState(this.id, status).subscribe(position => {
      this.alertService.add('success', 'Status wurde erfolgreich geändert!');
      this.modalRef.close(position);
    }, () => {
      this.alertService.add('danger', 'Beim ändern des Status ist ein Fehler aufgetreten!');
    });
  }
}
