import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {StreamDataService} from '../../../envisia/main/stream/stream-data.service';
import {Subscription} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'article-config-delete',
  templateUrl: './article-config-delete.component.html'
})
export class ArticleConfigDeleteComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: true }) fileInputVariable: any;
  subscription?: Subscription;
  inProgress = false;
  isFinished = false;

  constructor(private streamService: StreamDataService,
              private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    this.subscription = this.streamService.subscribe('websocket.article.reset', () => {
      this.inProgress = false;
      this.isFinished = true;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  upload() {
    this.isFinished = false;
    const body = new FormData();
    body.append('file', this.fileInputVariable.nativeElement.files[0]);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/octet-stream');
    this.httpClient.post('/setting/v1/article/reset/', body, {headers: headers}).subscribe((response) => {
      this.inProgress = true;
      console.log('Response Success', response);
    }, (failure) => {
      console.log(failure);
    });
  }

}
