/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';

import {EvAlertConfig} from './alert-config';

/**
 * Alerts can be used to provide feedback messages.
 */
@Component({
  selector: 'ev-alert',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div [class]="'alert headalert alert-special alert-' + type + (dismissible ? ' alert-dismissible' : '')"
         role="alert">
      <button *ngIf="dismissible" type="button" class="close" aria-label="Close" (click)="closeHandler()">
        <span aria-hidden="true">&times;</span>
      </button>
      <ng-content></ng-content>
    </div>
  `
})
export class EvAlertComponent {
  /**
   * A flag indicating if a given alert can be dismissed (closed) by a user. If this flag is set, a close button (in a
   * form of an ×) will be displayed.
   */
  @Input() dismissible: boolean;
  /**
   * Alert type (CSS class). Bootstrap 4 recognizes the following types: "success", "info", "warning", "danger",
   * "primary", "secondary", "light", "dark".
   */
  @Input() type: string;
  /**
   * An event emitted when the close button is clicked. This event has no payload. Only relevant for dismissible alerts.
   */
  @Output() closeEmitter = new EventEmitter();

  constructor(config: EvAlertConfig) {
    this.dismissible = config.dismissible;
    this.type = config.type;
  }

  closeHandler() {
    this.closeEmitter.emit(null);
  }
}
