import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {SharedModule} from '../../../core/shared.module';
import {WorkflowNavigationModule} from '../navigation/workflow-navigation.module';
import {KANBAN_STATES} from './kanban.states';
import {KanbanListDeComponent} from './kanban-list-de.component';
import {KanbanListCnComponent} from './kanban-list-cn.component';

@NgModule({
  imports: [
    UIRouterModule.forChild({states: KANBAN_STATES}),
    SharedModule,
    WorkflowNavigationModule,
  ],
  declarations: [
    KanbanListDeComponent,
    KanbanListCnComponent
  ],
})
export class KanbanModule {
}
