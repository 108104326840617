import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {LabelCreateComponent} from './label-create.component';
import {SharedModule} from '../../core/shared.module';
import {LABEL_STATES} from './label.states';
import {CustomerBoxModule} from '../customer/box/customer-box.module';
import {LabelArticleFormInputComponent} from './form/label-article-form-input.component';
import {NgbNavModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {LabelArticleSelectModalComponent} from './form/label-article-select-modal.component';
import {LoadingOrErrorModule} from '../../common/loading/loading-or-error.module';
import { LabelCreateRouteComponent } from './label-create-route/label-create-route.component';
import {LabelSettingsComponent} from './label-settings/label-settings.component';
import { LabelSettingsCustomerComponent } from './label-settings-customer/label-settings-customer.component';

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({ states: LABEL_STATES }),
        CustomerBoxModule,
        NgbTooltipModule,
        LoadingOrErrorModule,
        NgbNavModule
    ],
    declarations: [
        LabelCreateComponent,
        LabelSettingsComponent,
        // Article Select Box
        LabelArticleSelectModalComponent,
        LabelArticleFormInputComponent,
        LabelCreateRouteComponent,
        LabelSettingsCustomerComponent,
    ]
})
export class LabelModule {
}
