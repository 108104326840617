import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../../customer/model/customer';

@Component({
  selector: 'price-sales-note-modal',
  templateUrl: './price-sales-note-modal.component.html'
})
export class PriceSalesNoteModalComponent implements OnInit {
  @Input() customer: Customer;
  salesNote?: string;

  ngOnInit(): void {
    this.salesNote = this.customer.sales_note;
  }

}
