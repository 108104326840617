import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PositionArticleForm, PositionUpdateForm, WorkflowPrice} from '../../models/position.models';
import {PositionService} from '../../workflow-services/position.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {PriceService} from '../../../price/services/price.service';
import {PriceHistory, PriceMain} from '../../../price/price.models';

@Component({
  selector: 'offer-history-modal',
  templateUrl: './offer-history-modal.component.html',
  styles: ['.notes-truncated-modal { background: #fff;}',
          '.selectable-row {cursor: pointer;}',
          '.release-warning { color: red; font-size: 80%; }',
          '.notes-inner {text-align: left; white-space: nowrap; display: block; overflow: hidden; text-overflow: ellipsis; max-width: 6ch; background: #dc3545; padding: 1px 3px; color: #fff; border-radius: 3px;}']
})

export class OfferHistoryModalComponent implements OnInit {

  @Input() workflow_id: number;
  @Input() article: string;
  @Input() positionData: WorkflowPrice;
  @Input() send: PositionArticleForm | PositionUpdateForm;
  @Input() refresh?: string;
  @Input() header?: any;

  form: UntypedFormGroup;
  selectedDe: {calculated: boolean, release?: number, notes?: string};
  selectedCn: {calculated: boolean, release?: number, notes?: string};
  formSubscription?: Subscription;
  priceData: PriceMain;
  priceHistories: PriceHistory[] = [];
  selectedQuantity?: number;
  selectedLose?: number;

  isRefresh: boolean;
  isUpdate: boolean;
  isCreate: boolean;

  constructor(private modalService: NgbActiveModal,
              private positionService: PositionService,
              private priceService: PriceService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {

    this.selectedDe = {calculated: true, release: null, notes: null};
    this.selectedCn = {calculated: true, release: null, notes: null};
    this.selectedQuantity = this.positionData.quanity;
    this.selectedLose = this.positionData.de.lose;

    this.isRefresh = this.header && !!this.refresh;
    this.isUpdate = this.header && !this.refresh;
    this.isCreate = !this.isRefresh && !this.isUpdate;

    this.form = this.fb.group({
      quantity: [{value: this.positionData.quanity, disabled: !this.isUpdate}, Validators.required],
      lose: [{value: this.positionData.de.lose, disabled: !this.isUpdate}, Validators.required]
    });

    this.formSubscription = this.form.valueChanges.pipe(debounceTime(550)).subscribe(value => {
      if (this.isUpdate) {
        const posUpdateForm = this.send as PositionUpdateForm;
        posUpdateForm.quanity = value.quantity;
        posUpdateForm.lose_cn = value.lose;
        posUpdateForm.lose_de = value.lose; // TODO single out
        posUpdateForm.in_modal_update = true;
        // posUpdateForm.is_refresh = this.isRefresh;
        if (this.checkCombination(value.quantity, value.lose)) {
          this.positionService.update(posUpdateForm.id, posUpdateForm, {}).subscribe(res => {
            this.positionData = res.object as WorkflowPrice;
          }, error => {
            console.error(error);
          });
        } else {
          console.log('successfully prevented an update');
        }
      }
    });

    this.priceService.detail(this.article).subscribe(priceData => {
      this.priceData = priceData;
      this.priceHistories = [...priceData.de, ...priceData.cn];
    }, error => {
      console.error(error);
    });

  }

  checkCombination(quantity: number, lose: number): boolean {
    return this.priceHistories.filter(ph => ph.quanity === quantity && ph.lose === lose).length > 0;
  }

  toggleQuantity(): void {
    const formValue = this.form.getRawValue().lose;
    const possibleList = this.priceHistories.filter(h => h.lose === formValue);
    if (this.showQuantities().length <= 1) {
      this.form.patchValue({
        'quantity': possibleList[0].quanity
      });
    }
  }

  showQuantities(): number[] {
    const f = this.priceHistories.filter(h => h.lose === Number(this.form.getRawValue().lose)).map(v => v.quanity);
    return Array.from(new Set(f)); // only distinct values
  }

  /* show all unfiltered to avoid selection deadlocks */
  showLoses(): number[] {
    return Array.from(new Set(this.priceHistories.map(v => v.lose)));
  }


  dismiss(): void {
    this.modalService.dismiss();
  }

  select(calculated: boolean, typ: string, release?: number, notes?: string): void {
    if (typ === 'de') {
      this.selectedDe.calculated = calculated;
      this.selectedDe.release = release;
      this.selectedDe.notes = notes;
    } else {
      this.selectedCn.calculated = calculated;
      this.selectedCn.release = release;
      this.selectedCn.notes = notes;
    }
  }

  isSelected(calculated: boolean, typ: string, release?: number): boolean {
    if (typ === 'de') {
      return this.selectedDe.calculated === calculated && this.selectedDe.release === release;
    } else {
      return this.selectedCn.calculated === calculated && this.selectedCn.release === release;
    }
  }

  submit(): void {
    if (this.header) {
      const id = this.header.id;
      const header: any = this.refresh ? { refresh_typ: this.refresh } : {};
      header.history_de = !this.selectedDe.calculated;
      header.history_cn = !this.selectedCn.calculated;
      const form = this.send as PositionUpdateForm;
      form.release_de = this.selectedDe.release;
      form.release_cn = this.selectedCn.release;
      form.notes_de = this.selectedDe.notes;
      form.notes_cn = this.selectedCn.notes;
      form.is_refresh = this.isRefresh;
      const clb = (response) => {
        if (response.updated) {
          this.modalService.close(response);
        }
      };
      form.in_modal_update = false;
      this.positionService.update(id, form, header).subscribe(clb, (error) => console.error(error));
    } else {
      const form = this.send as PositionArticleForm;
      form.release_de = this.selectedDe.release;
      form.release_cn = this.selectedCn.release;
      form.history_de = !this.selectedDe.calculated;
      form.history_cn = !this.selectedCn.calculated;
      this.positionService.createArticle(this.workflow_id, form).subscribe(response => {
        this.modalService.close(response);
      }, error => {
        console.error(error);
      });
    }
  }

  showDe(): boolean {
    return !this.refresh || this.refresh === 'de';
  }

  showCn(): boolean {
    return !this.refresh || this.refresh === 'cn';
  }

  articleChange(releaseHash: string): boolean {
    return releaseHash !== this.positionData.hash;
  }

  calculationChange(calc: string, typ: string): boolean {
    if (typ === 'de') {
      return calc !== this.positionData.de.calc;
    } else {
      return calc !== this.positionData.cn.calc;
    }
  }

  priceChange(typ: string, release: any): boolean {
    if (release !== null  && release.last_price_id !== null) {
      if (typ === 'de' && this.positionData.de.calculated && this.positionData.de.calculated.last_price_id) {
        return release.last_price_id !== this.positionData.de.calculated.last_price_id;
      } else if (typ === 'cn' && this.positionData.cn.calculated && this.positionData.cn.calculated.last_price_id) {
        return release.last_price_id !== this.positionData.cn.calculated.last_price_id;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

}
