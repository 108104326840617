import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {MaterialManagementService} from '../../material-management.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DateHelper} from '../../../../helper/date-helper';

@Component({
  selector: 'material-inventory-intake-modal',
  templateUrl: './material-inventory-intake-modal.component.html'
})
export class MaterialInventoryIntakeModalComponent implements OnInit {
  @Input() konsi: boolean;
  @Input() variationId: number;

  form: UntypedFormGroup;
  today: Date;
  errors?: { [key: string]: any; } = {};
  lockSubmission = false;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private mmService: MaterialManagementService,
              private modalRef: NgbActiveModal) {
    this.today = DateHelper.today();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      transaction_type: [3],
      variation_id: [this.variationId],
      location: [null, Validators.required],
      charge: [null],
      price: [null],
      order_transaction_id: [null],
      date_received: [this.today, Validators.required],
      amount: [null, Validators.required],
      konsi: [this.konsi],
      note: [null]
    });
  }

  submit(): void {
    if (this.form.valid) {
      this.lockSubmission = true;
      this.errors = {};
      this.mmService.insertTransaction(this.form.value).subscribe(() => {
        this.alertService.add(
          'success',
          'Wareneinbuchung wurde vorgenommen'
        );
        this.lockSubmission = false;
        this.modalRef.close(true);
      }, response => {
        switch (response.status) {
          case 400:
            if (response.error) {
              this.errors = response.error;
            }
            break;
          case 404:
            this.errors['obj.variation_id'] = [{msg: ['error.variation.not.found'], args: []}];
            break;
          default:
            this.alertService.add('danger', 'Unbekannter Fehler beim übertragen.');
        }

        this.lockSubmission = false;
      });
    }
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

}
