import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Term} from '../model/term.model';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TermService {

  private uri = '/crm/v1/terms/';

  constructor(private http: HttpClient) {
  }

  list(): Observable<Term[]> {
    return this.http.get<EnvisiaObjects<Term>>(this.uri).pipe(map(value => value.objects));
  }

  create(data: {description: string}): Observable<Term> {
    return this.http.post<EnvisiaObject<Term>>(this.uri, data).pipe(map(value => value.object));
  }

  update(id: number, data: {description: string}): Observable<void> {
    return this.http.put<void>(this.uri + id + '/', data);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + id + '/');
  }

}
