<div class="page-header">

  <h3>Artikelkonfiguration</h3>

</div>

<div class="row">
  <div class="col-md-12">

    <button type="button" class="btn btn-default" (click)="openCreate()">Hinzufügen</button>

  </div>
</div>

<div class="row">

  <div class="col-md-12">

    <table class="table table-striped">
      <thead>
      <tr>
        <th>Name</th>
        <th>TG</th>
        <th>CTI</th>
        <th>PLC</th>
        <th>IPC 4101</th>
        <th>halogenfrei</th>
        <th>DE konform</th>
        <th>China konform</th>
        <th>China Text</th>
        <th>&nbsp;</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let value of listData; let index = index">
        <td>{{ value.key }}</td>
        <td>{{ value.data.tg }}</td>
        <td>{{ value.data.cti }}</td>
        <td>{{ value.data.plc }}</td>
        <td>{{ value.data.ipc_4101 }}</td>
        <td>
          <i *ngIf="!value.data.halogen_free" class="fa fa-times"></i>
          <i *ngIf="value.data.halogen_free" class="fa fa-check"></i>
        </td>
        <td>
          <i *ngIf="value.data.de_compliant === false" class="fa fa-times"></i>
          <i *ngIf="!(value.data.de_compliant === false)" class="fa fa-check"></i>
        </td>
        <td>
          <i *ngIf="!value.data.china_compliant" class="fa fa-times"></i>
          <i *ngIf="value.data.china_compliant" class="fa fa-check"></i>
        </td>
        <td>{{ value.data.china_text }}</td>
        <td>
          <button type="button" class="btn btn-link" (click)="delete(index, value)">
            <i class="fa fa-trash-o"></i>
          </button>
          <button type="button" class="btn btn-link" (click)="openUpdate(index, value)">
            <i class="fa fa-edit"></i>
          </button>
        </td>
      </tr>

      </tbody>
    </table>

  </div>
</div>
