/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DatepickerHolidayService} from '@envisia/ev-datepicker';
import {catchError} from 'rxjs/operators';
import {AlertService} from '../alert-service/alert.service';

@Injectable()
export class ErpDatePickerHolidayService extends DatepickerHolidayService {

  constructor(private http: HttpClient,
              private alertService: AlertService) {
    super();
  }

  list(): Observable<{ [klass: string]: { [klass: string]: number[] } }> {
    return this.http.get<{ [klass: string]: { [klass: string]: number[] } }>('/api/v1/holiday/').pipe(
      catchError(() => {
        this.alertService.add('danger', 'Konnte Urlaubstage nicht laden!');
        return of({});
      })
    );
  }

}
