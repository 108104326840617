<div class="comment-box">
  <h3 *ngIf="canEdit">Kommentare
    <span class="clickable text-primary" (click)="openBox()">
    <i class="fa fa-plus click"></i>
    </span>
  </h3>

  <form *ngIf="box && canEdit" class="new-task-comment" [formGroup]="createForm" (ngSubmit)="create()">
    <label>
      <textarea rows="3" class="form-control input-sm" formControlName="text"
                placeholder="Kommentar hinzufügen" autofocus="autofocus">
      </textarea>
      <button class="btn btn-sm btn-success" type="submit" style="margin-top: 5px;">
        Hinzufügen
      </button>
    </label>
  </form>

  <div class="whitelist commentlist">
    <div class="whitelist-item whitelist-item-no-cursor" *ngFor="let comment of list.objects; let index = index" [ngClass]="commentClasses(comment)">
      <div class="whitelist-detail">
        <div class="whitelist-item-detail-first" *ngIf="comment.additional">
          <strong>{{ comment.additional.headline }}</strong>
        </div>
        <div class="whitelist-item-detail-first">
          <div class="pull-left">
            <strong>{{ comment.date | date:'dd.MM.yyyy HH:mm' }} - {{ comment.username }}</strong>
          </div>

          <div class="pull-right ml-2" *ngIf="canBeMarked(comment)">
            <button type="button" class="ev-button" (click)="mark(index, comment)">
              <i class="fa fa-flag" aria-hidden="true"></i>
            </button>
          </div>
          <div class="pull-right" *ngIf="isArticleOrWorkflow">
            <button type="button" class="ev-button" (click)="remove(index, comment)">
              <i class="fa fa-trash-o"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="clear-both"></div>
      <!-- DO NOT WRAP: comment.text -->
      <div class="whitelist-item-detail-second"
           *ngIf="comment.typ !== 'label_print' && !comment.additional?.json && !comment.additional?.html">
        {{ comment.text }}
      </div>
      <div class="whitelist-item-detail"
           [innerHTML]="comment.text"
           *ngIf="comment.typ !== 'label_print' && !!comment.additional?.html">
      </div>
      <comment-material-order [comment]="comment"
                              [add]="comment.additional"
                              *ngIf="comment.typ === 'material_order'">
      </comment-material-order>
    </div>
    <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
  </div>
</div>
