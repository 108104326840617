import {NumberHelper} from '../../../../helper/number-helper';

interface ParsedArticleDataInner {
  core_thickness: number; // µm
  cu_inside: number; // µm
}

export interface ParsedArticleData {
  mixed_structure?: number;
  mixed_structure_used: boolean;
  inner_values: ParsedArticleDataInner[];
  cu_core?: number; // µm
  cu_outside?: number; // µm
  cu_thickness?: number; // µm
  solder_resist_a?: string;
  solder_resist_b?: string;
  has_solder_resist_a: boolean;
  has_solder_resist_b: boolean;
  sbu_layers?: number;
  material?: string;
}

export interface BuildLayer {
  type: 'core' | 'cu_plating' | 'cu_foil';
  cores?: number;
  layers_a?: number;
  layers_b?: number;
  is_cu_outside?: boolean;
}

export interface ArticleDataParsed {
  buildLayers: BuildLayer[];
  articleData: ParsedArticleData;
}

export class ArticleDataParserHelper {
  static copperThickness: number[] = [0, 12, 18, 35, 70, 105, 140, 210, 235];
  static prepregTissues: string[] = ['106', '1080', '2116', '7628'];

  static mapLacquerThickness(thickness: number): number {
    switch (thickness) {
      case 12: return 30;
      case 18: return 30;
      case 35: return 30;
      case 50: return 40;
      case 70: return 40;
      case 105: return 55;
      case 140: return 55;
      case 175: return 60;
      case 210: return 70;
      case 235: return 70;
      default: return 0;
    }
  }

  // Builds the layers from the HDI hdi_type string
  static hdiLayerParse(hdiLayers: string): BuildLayer[] {
    if (!hdiLayers) {
      return [];
    }

    const layers: number[] = hdiLayers.split('-').map(ls => NumberHelper.saveParseInteger(ls));
    const layersLength = layers.length;
    if (layersLength % 2 !== 1) {
      console.error('execution layers in hdi are of incorrect sequence length (should be an odd number)');
    }

    const layersToParse = Math.floor(layersLength / 2);
    const buildLayers: BuildLayer[] = [
      {type: 'cu_plating'},
      {type: 'cu_foil', layers_a: 1, layers_b: 1, is_cu_outside: true},
    ];

    const centerExecution = layers[layersToParse];
    if (centerExecution % 2 !== 0) {
      console.error(`execution was not an even number: ${centerExecution}`);
    }

    for (let i = 0; i < (centerExecution === 2 ? layersToParse - 1 : layersToParse); i++) {
      // Verify layer template (currently all but the center one must be 1 layer high)
      if (layers[i] !== layers[layersLength - 1 - i] || layers[i] !== 1) {
        console.error('could not verify correct layer template');
        return [];
      }

      buildLayers.push({type: 'cu_plating'});
      buildLayers.push({type: 'cu_foil', layers_a: layers[i], layers_b: layers[layersLength - 1 - i]});
    }

    if (centerExecution === 2) {
      // Special case of ...-2-... which does not use any cu_foil inside but plates the cores
      buildLayers.push({type: 'cu_plating'});
      buildLayers.push({type: 'core', cores: 1});
      return buildLayers;
    }

    buildLayers.push({type: 'core', cores: (centerExecution / 2) - 1});

    return buildLayers;
  }

  // parses the execution string and builds the default setup for it
  static executionParse(execution: string): BuildLayer[] {
    if (!execution || !execution.startsWith('ML') || execution === 'ML2') {
      return [];
    }

    const executionLayers = NumberHelper.saveParseInteger(execution.split('ML')[1]);
    if (executionLayers % 2 !== 0) {
      console.error('execution was not an even number');
      return [];
    }

    return [
      {type: 'cu_plating'},
      {type: 'cu_foil', layers_a: 1, layers_b: 1, is_cu_outside: true},
      {type: 'core', cores: (executionLayers / 2 - 1)},
    ];
  }

  // Tries to find the next smaller thickness
  static mapCuOuterFromCuThickness(cuThickness: number | null): number | null {
    if (cuThickness === null) {
      return null;
    }
    const cuThicknessIndex = ArticleDataParserHelper.copperThickness.findIndex(ct => ct === cuThickness);
    if (cuThicknessIndex > 0) {
      return ArticleDataParserHelper.copperThickness[cuThicknessIndex - 1];
    }
    return 0;
  }

  // Tries to find the next larger thickness
  static mapCuThicknessFromCuOuter(cuOuter: number | null): number | null {
    if (cuOuter === null) {
      return null;
    }
    const cuOuterIndex = ArticleDataParserHelper.copperThickness.findIndex(ct => ct === cuOuter);
    if (cuOuterIndex + 1 < ArticleDataParserHelper.copperThickness.length) {
      return ArticleDataParserHelper.copperThickness[cuOuterIndex + 1];
    }
    return 0;
  }

  static mapPrepregDataToThickness(value: string): number {
    switch (value) {
      case '106':
        return 57;
      case '1080':
        return 76;
      case '2116':
        return 121;
      case '7628':
        return 197;
      default:
        return null;
    }
  }

  static parse(article: {[key: string]: any}): ArticleDataParsed {
    const execution = (article['execution'] ?? null) as string | null;
    const hdiType = (article['hdi_type'] ?? null) as string | null;
    const buildLayers = !!hdiType ?
      ArticleDataParserHelper.hdiLayerParse(hdiType) :
      ArticleDataParserHelper.executionParse(execution);

    const cuLayersTotal = NumberHelper.sum(buildLayers.map(l => (l.cores ?? 0)));
    const mixedStructure = NumberHelper.saveParseInteger(article['mixed_structure']);
    const cuThickness = ArticleDataParserHelper.mapCuOuterFromCuThickness(article['cu_thickness']);
    const cuOutside = NumberHelper.saveParseInteger(article['cu_outside']) ?? cuThickness;
    const cuCore = NumberHelper.saveParseInteger(article['cu_core']);
    const sbuLayers = NumberHelper.saveParseInteger(article['sbu_layers']);
    const articleData = {
      mixed_structure: mixedStructure,
      mixed_structure_used: !!mixedStructure && (mixedStructure < 10) && (mixedStructure === cuLayersTotal),
      inner_values: [],
      has_solder_resist_a: !!article['solder_resist_a'],
      has_solder_resist_b: !!article['solder_resist_b'],
      cu_core: cuCore ?? null,
      cu_outside: cuOutside ?? null,
      cu_thickness: cuThickness,
      sbu_layers: sbuLayers,
      material: article['material_internal'] ?? article['manufacturer'] ?? 'Standard FR4',
    } as ParsedArticleData;

    for (let i = 0; i < (articleData.mixed_structure_used ? mixedStructure : cuLayersTotal); i++) {
      const index = articleData.mixed_structure_used ? i + 1 : 1;
      const coreThickness = NumberHelper.saveParseFloat(article['core_thickness_' + index]);
      const cuInsideParsed = NumberHelper.saveParseInteger(article['cu_inside_' + index]);
      articleData.inner_values.push({
        core_thickness: coreThickness ? coreThickness * 1000 : null, // mm -> µm
        cu_inside: cuInsideParsed ? cuInsideParsed : null,
      });
    }

    return {
      buildLayers: buildLayers,
      articleData: articleData,
    } as ArticleDataParsed;
  }
}
