import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';
import {from} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PriceIncreaseFlexService} from '../../../price-increase-flex/price-increase-flex.service';
import {WorkflowPriceIncreaseFlexCreateModalComponent} from './workflow-price-increase-flex-create-modal.component';

@Component({
  selector: 'workflow-price-increase-flex-empty',
  templateUrl: './workflow-price-increase-flex-empty.component.html',
})
export class WorkflowPriceIncreaseFlexEmptyComponent {
  @Input() workflowId: number;
  @Output() update: EventEmitter<PriceIncreaseFlex | null> = new EventEmitter<PriceIncreaseFlex | null>();

  constructor(private service: PriceIncreaseFlexService,
              private modalService: NgbModal) {
  }

  openCreateModal(): void {
    const modalRef = this.modalService.open(WorkflowPriceIncreaseFlexCreateModalComponent, {windowClass: 'modal2-sm'});
    modalRef.componentInstance.workflowId = this.workflowId;

    from(modalRef.result).subscribe(flexPriceIncrease => {
      this.update.next(flexPriceIncrease);
    });
  }
}
