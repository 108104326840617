/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'article-extra-initial-sample-testing',
  templateUrl: './article-extra-initial-sample-testing.component.html',
})
export class ArticleExtraInitialSampleTestingComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: FormBuilder) {
    super();
  }

  public formName = 'initial_sample_testing';
  protected schemaName = 'initial-sample-testing';
  protected fields = [
    'empb',
    'empb_other',
    'empb_china',
    'empb_china_other',
    'sds_cover_sheet',
    'sds_cover_sheet_other',
    'sds_cut_image_analysis',
    'sds_cut_image_analysis_other',
    'sds_dimensional_inspection',
    'sds_dimensional_inspection_other',
    'email',
    'email_other',
    'paper',
    'paper_other',
    'portal',
    'portal_other',
    'with_pattern_measurement',
    'with_pattern_measurement_other',
    'with_grounded_chunk',
    'with_grounded_chunk_other',
  ];
}
