import {Component, Input, OnInit} from '@angular/core';
import {Comment} from '../comment.models';

@Component({
  selector: 'comment-business-order',
  templateUrl: './comment-business-order.component.html',
})
export class CommentBusinessOrderComponent implements OnInit {
  @Input() comment: Comment;
  add: {[key: string]: any};

  constructor() { }

  ngOnInit(): void {
    this.add = this.comment.additional;
  }
}
