<div style="float: left; width: 250px; margin-left: 50px;" id="invoice-address">
  <h3>Rechnungsadresse
    <i class="fa fa-truck" aria-hidden="true" *ngIf="customer.invoice.id === customer.delivery_id"></i>
  </h3>
  &nbsp;
  <span class="fa fa-pencil click" style="color:black; font-size: 22px;" (click)="addressModal(-1, customer.invoice)">
      </span>
  &nbsp;
  <button class="btn btn-link btn-sm"
          *ngIf="customer.invoice.id !== customer.delivery_id"
          (click)="setDeliveryAddress(customer.invoice.id)">
    als Lieferadresse festlegen
  </button>

  <p style="clear: both;">
    {{ customer.invoice.street }}<br>
    {{ customer.invoice.zip }} {{ customer.invoice.city }} <br>
    {{ countries[customer.invoice.country].name_de }}<br>
    {{ customer.invoice.ust }}<br>
  </p>

  <a (click)="addressModal(-1, null)" class="btn btn-default ajax-modal" style="clear: left; margin-top: 10px;">
    Weitere Adresse hinzufügen
  </a>

  <div id="address-container">
    <ng-container *ngIf="addresses">

      <div *ngFor="let address of addresses;trackBy: trackByFn;let index = index">
        <div *ngIf="address.id !== customer.invoice.id">
          <h3>
            {{ address.name }}
            <i class="fa fa-truck" aria-hidden="true" *ngIf="address.id === customer.delivery_id"></i>
          </h3>
          &nbsp;

          <span class="fa fa-pencil click" style="color:black; font-size: 22px;" (click)="addressModal(index, address)">
          </span>
          <span class="fa fa-trash-o click" style="color: black; font-size: 22px;"
                (click)="addressDelete(address, index);">
          </span>
          &nbsp;
          <button class="btn btn-link btn-sm"
                  *ngIf="address.id !== customer.delivery_id"
                  (click)="setDeliveryAddress(address.id)">
            als Lieferadresse festlegen
          </button>

          <p style="clear: both;">
            {{ address.company_name }}<br>
            {{ address.company_department }}<br>
            {{ address.street }}<br>
            {{ address.zip }} {{ address.city }} <br>
            {{ countries[address.country].name_de }}<br>
            {{ address.ust }}<br>
          </p>
        </div>
      </div>

    </ng-container>
  </div>

</div>
