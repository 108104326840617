import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PaginationBaseComponent} from '../../../../../common/pagination/pagination.component';
import {Workflow} from '../../../models/workflow.models';
import {Article} from '../../../../article/models/article.models';
import {WorkflowOrderRequestService} from '../../../workflow-services/workflow-order-request.service';
import {OrderListData} from '../../order-request.models';
import {Lister} from '../../../../../common/wrapper.models';
import {PaginationQuery} from '../../../../../common/pagination/pagination.model';

@Component({
  selector: 'order-request-main-order-wrapper',
  templateUrl: './order-request-main-order-wrapper.component.html',
})
export class OrderRequestMainOrderWrapperComponent
  extends PaginationBaseComponent<WorkflowOrderRequestService, OrderListData>
  implements OnInit, OnChanges {
  @Input() workflow: Workflow;
  @Input() article: Article;
  @Input() outerQuery: PaginationQuery;
  @Input() outerList: Lister<OrderListData>;
  @Output() updateParent: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected service: WorkflowOrderRequestService) {
    super();
  }

  ngOnInit(): void {
    this.list = this.outerList;
    this.lastQuery = this.outerQuery;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.outerQuery && !changes.outerQuery.isFirstChange() && this.outerQuery) {
      this.lastQuery = this.outerQuery;
    }

    if (changes.outerList && !changes.outerList.isFirstChange() && this.outerList) {
      this.list = this.outerList;
      this.lastQuery = this.outerQuery;
    }
  }

  get transformedList() {
    return this.list?.objects?.map(l => l.order) ?? [];
  }

  update(): void {
    this.updateParent.emit();
  }
}
