import {Component, EventEmitter, Input, Output} from '@angular/core';
import Helper from '../../../../helper/helper';
import {HttpClient} from '@angular/common/http';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {FinderFinalData} from '../finder-upload.models';

@Component({
  selector: 'finder-upload-file',
  templateUrl: './finder-upload-file.component.html',
})
export class FinderUploadFileComponent {
  @Input() data: FinderFinalData;
  @Output() forwardEmitter = new EventEmitter<{guid: string}>();
  @Output() reverseEmitter = new EventEmitter<{}>();
  file: File | null;
  pending = false;

  constructor(private http: HttpClient,
              private alertService: AlertService) {
  }

  doUpdateFile(file: File): void {
    this.file = file;
  }

  start(): void {
    this.pending = true;
    const form = new FormData();
    form.append('category', Array.from(this.data.categories.selected).map((o: any) => o.id).join(', '));
    form.append('additional_words', this.data.additionals.extra);
    form.append('additionals', JSON.stringify(Helper.toArray(this.data.additionals.additionals)));
    form.append('seperatebyblank', 'false');
    form.append('removeblank', 'true');
    form.append('filenumber', JSON.stringify(Array.from(this.data.additionals.fileNumbers)));
    form.append('file', this.file);

    this.http.post<{object: string}>('/api/v1/finder/upload/', form).subscribe(response => {
      this.file = null;

      this.forwardEmitter.emit({guid: response.object});
    }, () => {
      this.pending = false;
      this.alertService.add('danger',  'Upload fehlgeschlagen, bitte probieren Sie es später erneut!');
    });
  }
}
