import {Directive, ErrorHandler, OnInit, OnDestroy} from '@angular/core';
import {MaterialGroupListElement, MaterialValueType} from '../material-management.model';
import {
  MaterialPlanningDate,
  MaterialPlanningDynamicFieldsChange,
  MaterialPlanningListQuery,
  MaterialSelectLister,
} from './material-planning.model';
import {MaterialPlanningListAbstractService} from './material-planning-list.abstract-service';
import {MaterialPlanningWeekListPaginator} from './material-planning-week-list-paginator';
import {MaterialManagementService} from '../material-management.service';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import Helper from '../../../helper/helper';
import {Observable, of, Subscription} from 'rxjs';
import {DateHelper} from '../../../helper/date-helper';
import {Customer} from '../../customer/model/customer';
import {formatDate} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {MaterialArticleNamesListService} from '../articles/material-article-names-list.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Lister} from '../../../common/wrapper.models';

@Directive()
export abstract class MaterialPlanningListAbstractComponent implements OnInit, OnDestroy {
  groups: Lister<MaterialGroupListElement>;
  params: MaterialPlanningListQuery;

  customer: Customer | null = null;

  public form: UntypedFormGroup;
  protected formSubscription: Subscription;

  public list: MaterialSelectLister;
  currentGroupId?: number;

  selectedWeek: MaterialPlanningDate | null = null;
  selectedVariationId: number | null = null;

  public filterFormErrors: { [key: string]: any } = {};

  weekPaginator: MaterialPlanningWeekListPaginator;
  weekPaginationLock = false;

  currentSupplierId?: number;
  articleNames = new LoadingWrapper<string[]>(of([]));

  protected lastRequest: Subscription = null;

  protected constructor(protected service: MaterialPlanningListAbstractService,
                        protected errorHandler: ErrorHandler,
                        protected mms: MaterialManagementService,
                        protected manls: MaterialArticleNamesListService,
                        protected fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      group_id: [this.params.group_id, Validators.required],
      variation_id: [this.params.variation_id],
      article_name: [this.params.article_name],
      supplier_id: [this.params.supplier_id],
      customer_id: [this.params.customer_id],
      vendor_article_id: [this.params.vendor_article_id],
      date_limit_from: [this.params.date_limit_from],
      date_limit_to: [this.params.date_limit_to],
      preload_weeks: [null],
      starting_date: [null],
      cfa: [this.params.cfa],
      cfa_by_date: [this.params.cfa_by_date],
      order: [this.params.order],
      sort: [this.params.sort],
      page: [this.params.page ?? 1],
    });
  }

  ngOnDestroy(): void {
    this.dropLastRequest();
    this.formSubscription.unsubscribe();
  }

  serviceCall(): void {
    this.filterFormErrors = {};

    this.dropLastRequest();

    const query = this.form.value;
    query['starting_date'] = null;

    // remove the 'fields' query string for the server query
    this.lastRequest = this.service.list(this.form.value.group_id, query).subscribe((data) => {
      this.apply(query, data);
      this.postCall(data);
    }, (error: HttpErrorResponse) => {
      if (error.status === 400 && error.error) {
        this.filterFormErrors = error.error.errors ?? {};
      }
    }, () => {
      this.lastRequest = null;
    });
  }

  apply(query: MaterialPlanningListQuery, data: MaterialSelectLister): void {
    this.list = data;
    this.params = query;
    this.lastRequest = null;
  }

  protected postCall(data: MaterialSelectLister): void {
    this.weekPaginator.reset(data.weeks);
  }

  protected setFieldControls(fields: MaterialValueType[]): MaterialValueType[] {
    // Remove all dynamic search fields from data form
    Helper.keys(this.form.value, (obj, key) => {
      if (key.toString().startsWith('field_')) {
        this.form.removeControl(key, {emitEvent: false});
      }
    });

    fields.forEach(field => {
      this.form.addControl(field.html_name, this.fb.control(null), {emitEvent: false});
    });

    return fields;
  }

  protected loadArticleNames(): Observable<string[]> {
    if (!this.currentSupplierId || !this.currentGroupId) {
      this.articleNames = new LoadingWrapper<string[]>(of([]));
    } else {
      const query = {'supplier_id': this.form.value.supplier_id, 'group_id': this.form.value.group_id};
      this.articleNames = new LoadingWrapper<string[]>(this.manls.list(query));
    }

    return this.articleNames.data$;
  }

  abstract patchDynamicFields(form: any, forceReload?: boolean, keepArticleName?: boolean):
    Observable<MaterialPlanningListQuery>;

  search(keepPage?: boolean): void {
    this.patchDynamicFields(this.form.value).subscribe(patched => {
      this.form.patchValue({...patched, 'page': !keepPage ? 1 : patched.page}, {emitEvent: false});
      this.serviceCall();
    });
  }

  dropLastRequest(): void {
    if (this.lastRequest) {
      this.lastRequest.unsubscribe();
      this.lastRequest = null;
    }
  }

  selectGroup(groupId: number): void {
    this.selectedWeek = null;
    this.selectedVariationId = null;
    this.form.reset({'group_id': groupId});
  }

  toggleOn(name: string, value: any): void {
    const p = {};
    if (this.form.value[name] === value) {
      p[name] = null;
    } else {
      p[name] = value;
    }
    this.form.patchValue(p);
  }

  triStateChange(field: MaterialValueType): void {
    const changes = {};
    const oldValue = this.form.value[field.html_name];
    if (oldValue === undefined || oldValue === null) {
      changes[field.html_name] = true;
    } else if (oldValue === true) {
      changes[field.html_name] = false;
    } else if (oldValue === false) {
      changes[field.html_name] = null;
    }

    this.form.patchValue(changes);
  }

  sort(field: string): void {
    const changes = {sort: field, order: null};
    const isOldField = this.form.value.sort === field;
    changes.order = isOldField && this.form.value.order === 'DESC' ? 'ASC' : 'DESC';
    this.form.patchValue(changes, {emitEvent: false});
    this.search(true);
  }

  goto(page: number): void {
    this.form.patchValue({'page': page}, {emitEvent: false});
    this.search(true);
  }

  weekScrollPage(pages: number): void {
    this.weekPaginator.weekScrollPage(pages);
    if (this.weekPaginator.reloadPages && !this.weekPaginationLock) {
      this.weekPaginationLock = true;
      const query = this.form.value;
      const date = DateHelper.parseISO8601(this.weekPaginator.lastDate);
      date.setDate(date.getDate() + 7);
      query['starting_date'] = date.toISOString().split('T')[0];
      query['preload_weeks'] = null;

      this.service.list(this.currentGroupId, query).subscribe(data => {
        this.weekPaginator.addWeeks(data.weeks);
        data.objects.forEach(pl => {
          // Index of the material
          const rowIndex = this.list.objects.findIndex(pls => pls.inventory.variation_id === pl.inventory.variation_id);
          if (rowIndex >= 0) {
            pl.workload_list.forEach(wl => {
              const lastElementIndex = this.list.objects[rowIndex].workload_list.length - 1;
              const lastElement = lastElementIndex > 0 ?
                this.list.objects[rowIndex].workload_list[lastElementIndex] :
                null;

              wl.required_to_date = (lastElement?.required_to_date ?? 0) + wl.required;
              wl.ordered_to_date = (lastElement?.ordered_to_date ?? 0) + wl.ordered;
              wl.confirmed_to_date = (lastElement?.confirmed_to_date ?? 0) + wl.confirmed;
              wl.amount_to_date = (lastElement?.amount_to_date ?? 0) + wl.confirmed;
              wl.secure_confirmed_to_date = lastElement?.confirmed_to_date ?? 0;
              this.list.objects[rowIndex].workload_list.push(wl);
            });
          }
        });
        this.weekPaginationLock = false;
      }, () => {
        this.weekPaginationLock = false;
      });
    }
  }

  get startDate(): string | null {
    if (!this.form.value.date_limit) {
      return null;
    }

    const startDateParsedIso = DateHelper.parseISO8601(this.form.value.date_limit);
    const startDateParsedGerman = DateHelper.safeParse(this.form.value.date_limit);
    if (!!startDateParsedIso || !!startDateParsedGerman) {
      return formatDate(startDateParsedIso ?? startDateParsedGerman, 'dd.MM.yyyy', 'en-US');
    } else {
      return null;
    }
  }

}
