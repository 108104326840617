<!--
  ~ Copyright (C) 2017 envisia GmbH
  ~ All Rights Reserved.
  -->
<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Lötmuster
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="solder_pattern" class="article-form-item-values">
    </article-extra-checkbox>

    <article-extra-text formControlName="solder_pattern_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

</div>
