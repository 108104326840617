<div class="modal-header">
  <h3 class="modal-title">Preiserhöhung {{ uppercaseTyp }} hinzufügen</h3>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="save()">
  <div class="modal-body">

    <div class="form-group">
      <label for="inputPercent" class="col-sm-2 control-label">Erhöhung</label>
      <div class="col-sm-10">
        <input type="text" id="inputPercent" class="form-control" autofocus
               formControlName="percent"
               [ngClass]="{red_alert: errors['obj.percent']}">
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Abbrechen
    </button>

    <button type="submit" class="btn btn-success pull-right">
      Hinzufügen
    </button>
  </div>

</form>
