/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {BigWorkflowInvoice} from '../../models/workflow-create.models';
import {Workflow} from '../../models/workflow.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DateHelper} from '../../../../helper/date-helper';
import {debounceTime} from 'rxjs/operators';
import {GlobalService} from '../../../../core/global.service';
import {forkJoin, Subscription} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {FrameService} from '../../workflow-services/frame.service';
import {Invoice, InvoiceDelivery} from '../invoice.models';
import {InvoiceSendModalService} from '../send/invoice-send-modal.service';
import {CommonType, InitData} from '../../../../common/init-data/init-data';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';
import {InvoiceService} from '../services/invoice.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';
import {EnvisiaFileService} from '../../../../common/envisia-file-button/envisia-file.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceCreateCorrectionModalComponent} from './invoice-create-correction-modal.component';
import {from} from 'rxjs';

@Component({
  selector: 'invoice-create',
  templateUrl: './invoice-create.component.html'
})
export class InvoiceCreateComponent implements OnInit, OnDestroy {
  @Input() data: BigWorkflowInvoice;
  workflow: Workflow;
  form: UntypedFormGroup;
  subscription?: Subscription;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private stateService: StateService,
              private globalService: GlobalService,
              private localStorage: LocalStorage,
              private alertService: AlertService,
              private frameService: FrameService,
              private envisiaFileService: EnvisiaFileService,
              private invoiceService: InvoiceService,
              private invoiceSendModalService: InvoiceSendModalService) {
  }

  ngOnInit(): void {
    this.workflow = this.data.workflow.object;

    this.initForm();
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  create(clb: LoadingButtonEvent, delivery: InvoiceDelivery): void {
    const form = {
      invoice_date: this.form.value.date,
      hint: this.form.value.hint
    };
    this.invoiceService.create(delivery.id, form).subscribe(response => {
      clb.callback(false);
      this.envisiaFileService.open(response.id.toString(), 'invoice');
      this.stateService.go('a.workflow.detail', {id: this.workflow.id});
    }, (response: HttpErrorResponse) => {
      let message = 'Rechnung konnte nicht erstellt werden!';
      if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
        if (response.error.error === 'position') {
          message = 'Rechnung konnte aufgrund einer ungültigen Position nicht erstellt werden!';
        } else if (response.error.error === 'vat') {
          message = 'Rechnung konnte aufgrund einer fehlenden USt.-ID nicht erstellt werden!';
        }
      }
      this.alertService.add('danger', message);
      clb.callback(false);
    });
  }

  invoiceModal(id: number, index: number): void {
    const flipper = (state) => {
      this.data.overview_list[index].state = state;
    };
    this.invoiceSendModalService.open(id, flipper).subscribe(() => {}, ignoreRejection);
  }

  openStorno(invoice: Invoice, index: number): void {
    const modalRef = this.modalService.open(InvoiceCreateCorrectionModalComponent);
    modalRef.componentInstance.invoice = invoice;
    from(modalRef.result).subscribe(storno => {
      this.envisiaFileService.open(storno.id, 'invoice_correction');
      forkJoin(
        this.invoiceService.deliveryList(this.workflow.id),
        this.invoiceService.overviewList(this.workflow.id),
      ).subscribe(response => {
        this.data.delivery_list = response[0];
        this.data.overview_list = response[1];
      });
    }, ignoreRejection);
  }

  private initForm(): void {
    this.form = this.fb.group({
      date: [DateHelper.format(new Date())],
      hint: [this.data.additional.invoice_hint]
    });

    this.subscription = this.form.get('hint').valueChanges.pipe(debounceTime(500)).subscribe(formValues => {
      this.globalService.save = false;
      const send = {invoice_hint: formValues};
      this.frameService.additionalSave(this.workflow.id, send).subscribe(() => {
        this.globalService.save = true;
      }, ignoreRejection);
    });
  }

  private delivered(): CommonType {
    // 1 means paid
    return this.localStorage.getObject<InitData>('data').invoice_status[1];
  }

}
