<form name="form" [formGroup]="form">

  <div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="contentbox customerbox">
        <div class="form-horizontal">
          <div class="form-group">
            <label class="col-sm-3 control-label">E-Mail</label>

            <div class="col-sm-8">
              <input type="email" class="form-control" id="id_mail" maxlength="75"
                     placeholder="E-Mail-Adresse hinzufügen"
                     formControlName="mail"
                     [ngClass]="{red_alert: errors['obj.mail']}">
            </div>
            <div class="col-sm-1">
              <a [href]="'mailto:' + customer.mail"
                 target="_blank"
                 title="E-Mail an Kunden senden"
                 *ngIf="customer.mail">
                <span class="fa fa-envelope"></span>
              </a>
              <span class="fa fa-envelope" *ngIf="!customer.mail"></span>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">Telefon</label>

            <div class="col-sm-9">
              <input type="text" class="form-control" id="id_phone" maxlength="50"
                     placeholder="Telefon-Nummer hinzufügen"
                     formControlName="phone"
                     [ngClass]="{red_alert: errors['obj.phone']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">Fax</label>

            <div class="col-sm-9">
              <input type="text" class="form-control" id="id_fax" maxlength="50"
                     placeholder="Fax-Nummer hinzufügen"
                     formControlName="fax"
                     [ngClass]="{red_alert: errors['obj.fax']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">Webseite</label>

            <div class="col-sm-8">
              <input type="text" class="form-control" id="id_website" maxlength="200" name="website"
                     placeholder="Webseite hinzufügen"
                     formControlName="website"
                     [ngClass]="{red_alert: errors['obj.website']}">
            </div>

            <div class="col-sm-1">
              <a [href]="customer.website" target="_blank" title="Kunden-Website öffnen" *ngIf="customer.website">
                <span class="fa fa-globe"></span>
              </a>
              <span class="fa fa-globe" *ngIf="!customer.website"></span>
            </div>

          </div>

          <ng-container formGroupName="additional">
            <div class="form-group">
              <label class="col-sm-3 control-label">Angebots E-Mail</label>

              <div class="col-sm-9">
                <input type="text" class="form-control" id="id_offer_mail" maxlength="200"
                       placeholder="Angebots Email hinzufügen"
                       formControlName="offer_mail"
                       [ngClass]="{red_alert: errors['obj.additional.offer_mail']}">
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Auft.-Best. E-Mail</label>

              <div class="col-sm-9">
                <input type="email" class="form-control" id="id_contract_mail" maxlength="200"
                       placeholder="Auftragsbestätigungs Email hinzufügen"
                       formControlName="contract_mail"
                       [ngClass]="{red_alert: errors['obj.additional.contract_mail']}">
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Lieferschein E-Mail</label>

              <div class="col-sm-9">
                <input type="email" class="form-control" id="id_delivery_mail" maxlength="200"
                       placeholder="Lieferschein Email hinzufügen"
                       formControlName="delivery_mail"
                       [ngClass]="{red_alert: errors['obj.additional.delivery_mail']}">
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-3 control-label">Rechnungs E-Mail</label>

              <div class="col-sm-9">
                <input type="email" class="form-control" id="id_invoice_mail" maxlength="200"
                       placeholder="Rechnungs Email hinzufügen"
                       formControlName="invoice_mail"
                       [ngClass]="{red_alert: errors['obj.additional.invoice_mail']}">
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Mahn E-Mail</label>

              <div class="col-sm-9">
                <input type="email" class="form-control" id="id_remainder_mail" maxlength="200"
                       placeholder="Mahn Email hinzufügen"
                       formControlName="remainder_mail"
                       [ngClass]="{red_alert: errors['obj.additional.remainder_mail']}">
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Versicherungssumme</label>
              <div class="col-sm-3">
                <integer-input class="form-control" id="id_insurance_sum" maxlength="200"
                               formControlName="insurance_sum" placeholder="Summe einfügen"
                               [evClass]="{red_alert: errors['obj.additional.insurance_sum']}">
                </integer-input>
              </div>
              <div class="col-sm-6">
                <span style="padding-top:9px;display:block;">Tausend Euro</span>
              </div>
            </div>
          </ng-container>

          <div class="form-group">
            <label class="col-sm-5 control-label">Versichert</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="id_assured"
                     formControlName="assured"
                     [ngClass]="{red_alert: errors['obj.assured']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label">Mahnungen sperren</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="id_dunning_lock"
                     formControlName="dunning_lock"
                     [ngClass]="{red_alert: errors['obj.dunning_lock']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label">Vorzugskunde</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="id_preference"
                     formControlName="preference"
                     [ngClass]="{red_alert: errors['obj.preference']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label">Spezial Kunde</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="allow_special_production"
                     formControlName="allow_special_production"
                     [ngClass]="{red_alert: errors['obj.allow_special_production']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label">UL-Typen (UL USA, UL CSA) auf AB &amp; Lieferschein</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="show_ul_types"
                     formControlName="show_ul_types"
                     [ngClass]="{red_alert: errors['obj.show_ul_types']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label">CN UL-Nr. auf AB & Lieferschein</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="id_cn_ul"
                     formControlName="cn_ul"
                     [ngClass]="{red_alert: errors['obj.cn_ul']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label">CN Produzent auf Lieferschein</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="id_show_producer"
                     formControlName="show_producer"
                     [ngClass]="{red_alert: errors['obj.show_producer']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label">CN Produzent auf Angebot</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="id_show_producer_offer"
                     formControlName="show_producer_offer"
                     [ngClass]="{red_alert: errors['obj.show_producer_offer']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label" for="id_surplus_disposal">Produktionsüberschuss entsorgen</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="id_surplus_disposal"
                     formControlName="surplus_disposal"
                     [ngClass]="{red_alert: errors['obj.surplus_disposal']}">
            </div>
            <label class="col-sm-1 control-label no-wrap" for="id_surplus_disposal_limit">Bis zu</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="id_surplus_disposal_limit"
                     formControlName="surplus_disposal_limit"
                     placeholder="Summe einfügen"
                     [ngClass]="{red_alert: errors['obj.surplus_disposal_limit']}">
            </div>
            <label class="col-sm-2 control-label no-wrap" for="id_surplus_disposal_limit">&euro; Einzelpreis</label>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label">Auftragsübersicht auf AB anzeigen</label>
            <div class="col-sm-1">
              <input type="checkbox" class="form-control" id="id_show_contract_overview"
                     formControlName="show_contract_overview"
                     [ngClass]="{red_alert: errors['obj.show_contract_overview']}">
            </div>
          </div>
        </div>
      </div>

      <div class="contentbox customerbox">
        <h3 class="col-sm-12">Allgemeine Notiz</h3>
        <textarea class="form-control" cols="40" id="id_note" maxlength="600" rows="10"
                  style="width:100%; height:200px;"
                  formControlName="note"
                  [ngClass]="{red_alert: errors['obj.note']}"></textarea>
      </div>

      <div class="contentbox customerbox">
        <ng-container formGroupName="additional">
          <h3 class="col-sm-12">Vertriebs Notiz</h3>
          <div class="form-group">
            <label class="col-sm-5 control-label" for="id_circuit_board_needs">Leiterplattenbedarf</label>

            <div class="col-sm-7">
              <input type="text" class="form-control" id="id_circuit_board_needs" maxlength="200"
                     formControlName="circuit_board_needs"
                     [ngClass]="{red_alert: errors['obj.additional.circuit_board_needs']}">
            </div>
          </div>

          <div>&nbsp;</div>

          <div class="form-group">
            <label class="col-sm-5 control-label" for="id_possible_revenue">Davon möglicher Storz Umsatz</label>

            <div class="col-sm-7">
              <input type="text" class="form-control" id="id_possible_revenue" maxlength="200"
                     formControlName="possible_revenue"
                     [ngClass]="{red_alert: errors['obj.additional.possible_revenue']}">
            </div>
          </div>

          <div>&nbsp;</div>

          <div class="form-group">
            <label class="col-sm-5 control-label" for="id_main_contact">Hauptansprechpartner / Entscheider</label>

            <div class="col-sm-7">
              <input type="text" class="form-control" id="id_main_contact" maxlength="200"
                     formControlName="main_contact"
                     [ngClass]="{red_alert: errors['obj.additional.main_contact']}">
            </div>
          </div>

          <div>&nbsp;</div>

          <div class="form-group">
            <label class="col-sm-5 control-label" for="id_competitor">Wettbewerber</label>

            <div class="col-sm-7">
              <input type="text" class="form-control" id="id_competitor" maxlength="200"
                     formControlName="competitor"
                     [ngClass]="{red_alert: errors['obj.additional.competitor']}">
            </div>
          </div>
        </ng-container>

        <div>&nbsp;</div>

        <div class="clearfix"></div>

        Angebotsrelevanten Informationen:
        <textarea class="form-control" cols="40" id="id_salesnote" maxlength="1800" rows="10"
                  style="width:100%; height:200px;"
                  formControlName="sales_note"
                  [ngClass]="{red_alert: errors['obj.sales_note']}"></textarea>
      </div>
    </div>

    <div class="col-md-6 col-sm-12">
      <div class="contentbox customerbox">
        <h3>Zahlungsbedingungen</h3>
        <div class="row form-group">
          <div class="col-sm-3">
            <img src="/ui/assets/images/flags/de.png"> Deutsche Ware
          </div>
          <div class="col-sm-9">
            <select class="form-control" id="id_term_de"
                    formControlName="term_de"
                    [ngClass]="{red_alert: errors['obj.term_de']}">
              <option [ngValue]="null">-- Zahlungsbedingungen auswählen --</option>
              <option *ngFor="let term of terms" [value]="term.description">{{ term.description }}</option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-3">
            <img src="/ui/assets/images/flags/cn.png"> China Ware
          </div>
          <div class="col-sm-9">
            <select class="form-control" id="id_term_cn"
                    formControlName="term_cn"
                    [ngClass]="{red_alert: errors['obj.term_cn']}">
              <option [ngValue]="null">-- Zahlungsbedingungen auswählen --</option>
              <option *ngFor="let term of terms" [value]="term.description">{{ term.description }}</option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-3 align-middle">
            <label class="control-label no-wrap" for="id_credit_limit">
              Kreditlimit
            </label>
          </div>
          <div class="col-sm-7">
            <input type="text"
                   id="id_credit_limit"
                   class="form-control align-right"
                   placeholder="Summe einfügen"
                   formControlName="credit_limit"
                   [ngClass]="{red_alert: errors['obj.credit_limit']}">
          </div>
          <div class="col-sm-2 align-middle" style="height: 100%;">
            <label class="control-label no-wrap" for="id_credit_limit">
              &euro;&nbsp;netto
            </label>
          </div>
        </div>
      </div>

      <div class="contentbox customerbox">
        <h3>Lieferbedingungen auf Belegen</h3>
        <div class="row">
          <label class="col-sm-3">Lieferbedingung</label>
          <div class="col-sm-9">
            <select class="form-control" id="id_delivery_terms"
                    formControlName="delivery_terms"
                    [ngClass]="{red_alert: errors['obj.delivery_terms']}">
              <option [ngValue]="null">-- Lieferbedingung auswählen --</option>
              <option *ngFor="let term of deliveryTerms" [ngValue]="term.description">{{ term.description }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="contentbox customerbox" formGroupName="additional">
        <h3>Rechnungszusatz</h3>
        <textarea class="form-control" cols="40" id="id_invoice_additional" maxlength="600" rows="10"
                  style="width:100%; height:200px;"
                  formControlName="invoice_free_text"
                  [ngClass]="{red_alert: errors['obj.additional.invoice_free_text']}"></textarea>
      </div>

      <div class="contentbox customerbox" formGroupName="additional">
        <h3>Rahmenvereinbarung</h3>
        <div class="form-horizontal">
          <div class="form-group">
            <label class="col-sm-3 control-label">Laufzeit Lose</label>

            <div class="col-sm-9">
              <input type="text" class="form-control" id="id_term_lose" maxlength="200"
                     placeholder="z.B. 6 Monate oder 1 Jahr"
                     formControlName="term_lose"
                     [ngClass]="{red_alert: errors['obj.additional.term_lose']}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">Laufzeit Kanban</label>

            <div class="col-sm-9">
              <input type="text" class="form-control" id="id_term_kanban" maxlength="200"
                     placeholder="z.B. 6 Monate oder 1 Jahr"
                     formControlName="term_kanban"
                     [ngClass]="{red_alert: errors['obj.additional.term_kanban']}">
            </div>
          </div>
        </div>
      </div>

      <div class="contentbox customerbox">
        <customer-detail-field-service [customer]="customer"
                                       [fieldServices]="fieldServices"
                                       [fieldServiceUser]="fieldServiceUser">
        </customer-detail-field-service>
      </div>


      <div class="contentbox customerbox">
        <customer-detail-industrial-sector [customer]="customer" [industrialSectors]="industrialSectors">
        </customer-detail-industrial-sector>
      </div>


      <div class="contentbox customerbox">
        <customer-detail-producer [customer]="customer" [producers]="producers">
        </customer-detail-producer>
      </div>
      <div class="contentbox customerbox">
        <customer-calculation [customer]="customer" [processes]="processes">
        </customer-calculation>
      </div>
      <div class="contentbox customerbox" formGroupName="additional">
        <h3 class="col-sm-12">Kalkulationszusatz</h3>
        <div class="form-horizontal">

          <div class="form-group">
            <label class="col-sm-3 control-label" title="">
              <img src="/ui/assets/images/flags/de.png">&nbsp;Schlechte + %
            </label>

            <div class="col-sm-3">
              <integer-input class="form-control" id="id_percentaddde" placeholder="Erhöhung um Wert"
                             formControlName="percent_add_de"
                             [evClass]="{red_alert: errors['obj.additional.percent_add_de']}">
              </integer-input>
            </div>

            <label class="col-sm-3 control-label" title="">
              <img src="/ui/assets/images/flags/cn.png">&nbsp;Schlechte + %
            </label>

            <div class="col-sm-3">
              <integer-input class="form-control" id="id_percentaddchn" placeholder="Erhöhung um Wert"
                             formControlName="percent_add_chn"
                             [evClass]="{red_alert: errors['obj.additional.percent_add_chn']}">
              </integer-input>
            </div>

          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">
              <img src="/ui/assets/images/flags/de.png">&nbsp;Angebote
            </label>

            <div class="col-sm-3">
              <input type="checkbox" checked="checked" id="id_defaultofferde"
                     formControlName="offer_de"
                     [ngClass]="{red_alert: errors['obj.additional.offer_de']}">
            </div>
            <label class="col-sm-3 control-label">
              <img src="/ui/assets/images/flags/cn.png">&nbsp;Angebote
            </label>

            <div class="col-sm-3">
              <input type="checkbox" checked="checked" id="id_defaultofferchn"
                     formControlName="offer_cn"
                     [ngClass]="{red_alert: errors['obj.additional.offer_cn']}">
            </div>

          </div>
        </div>
      </div>

      <div class="contentbox customerbox">
        <h3 class="col-sm-12">Steuernummer</h3>
        <div class="form-horizontal">
          <div class="form-group">
            <label class="col-sm-3 control-label" for="id_tax_identification_number">
              Steuer-Nr.:
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="id_tax_identification_number"
                     placeholder="Steuer-Nr"
                     formControlName="tax_identification_number"
                     [ngClass]="{red_alert: errors['obj.tax_identification_number']}">
            </div>

          </div>
        </div>
      </div>

      <div class="customerbox" *ngIf="'price.increase'|HasPerm">
        <customer-increase typ="de" [increases]="increase.de" [customerId]="customer.id">
        </customer-increase>
      </div>

      <div class="customerbox" *ngIf="'price.increase'|HasPerm">
        <customer-increase typ="cn" [increases]="increase.cn" [customerId]="customer.id">
        </customer-increase>
      </div>
    </div>

  </div>

</form>
