<div class="article-form-item-label-calc" [formGroup]="form">
      <label [htmlFor]="'article-model-' + model1">{{ label2 }}</label>
      <input type="text" class="form-control fixed-width-xs" [id]="'article-model-' + model1"
             [formControlName]="model1" [ngClass]="{red_alert: error1}" (change)="change(model1)">
      <label [htmlFor]="'article-model-' + model2">{{ label3 }}</label>
      <input type="text" class="form-control fixed-width-xs" [id]="'article-model-' + model2"
             [formControlName]="model2" [ngClass]="{red_alert: error2}" (change)="change(model2)">
      <label [htmlFor]="'article-model-' + model3">{{ label1 }}</label>
      <input type="text" class="form-control fixed-width-sm input-highlighted" [id]="'article-model-' + model3"
             [formControlName]="model3" [ngClass]="{red_alert: error3}" (change)="change(model3)" [attr.disabled]="true">
</div>
