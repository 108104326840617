import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {KanbanDeService} from './kanban.de.service';
import {KanbanListData} from './kanban.model';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Lister} from '../../../common/wrapper.models';

@Component({
  selector: 'kanban-list-de',
  templateUrl: './kanban-list-de.component.html'
})
export class KanbanListDeComponent extends SearchBaseComponent<KanbanDeService, KanbanListData> {
  @Input() listData: Lister<KanbanListData>;
  protected type = 'kanban';

  constructor(protected service: KanbanDeService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

}
