import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContractDateCheckHolder} from './material-planning-date-check.model';
import {from, Observable, throwError} from 'rxjs';
import {MaterialPlanningDateCheckModalComponent} from './material-planning-date-check-modal.component';
import {catchError, mergeMap} from 'rxjs/operators';
import {HolidayService} from '../../holiday/holiday.service';
import {Article} from '../../article/models/article.models';

@Injectable({providedIn: 'root'})
export class MaterialPlanningDateCheckModalService {
  constructor(private modalService: NgbModal,
              private holidayService: HolidayService) {
  }

  open(article: Article, dateHolder: ContractDateCheckHolder, dateSelected: string): Observable<string> {
    return this.holidayService
      .listAsObject()
      .pipe(
        catchError(error => {
          console.log('Modal error while loading holidays for material planning', error);
          return throwError('modal error');
        }),
        mergeMap(holidays => {
          const modalRef = this.modalService.open(MaterialPlanningDateCheckModalComponent, {windowClass: 'modal2-full'});
          modalRef.componentInstance.article = article;
          modalRef.componentInstance.dateHolder = dateHolder;
          modalRef.componentInstance.dateSelected = dateSelected;
          modalRef.componentInstance.holidays = holidays;
          return from(modalRef.result);
        }));
  }
}
