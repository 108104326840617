import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {Address} from '../model/address';
import {AddressService} from '../services/address.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {mergeMap, publishReplay, refCount} from 'rxjs/operators';
import {AddressBoxModalChangeComponent} from './address-box-modal-change.component';
import {from} from 'rxjs';
import {of} from 'rxjs';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AlertService} from '../../../common/alert-service/alert.service';
import {debug} from '../../../helper/debug.func';

export const EV_ADDRESS_BOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AddressBoxComponent),
  multi: true,
};

@Component({
  selector: 'address-box',
  templateUrl: './address-box.component.html',
  styles: ['.button-box-text { text-align: left; font-size: 12px; }'],
  providers: [
    EV_ADDRESS_BOX_VALUE_ACCESSOR
  ]
})
export class AddressBoxComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  @Input() name = 'Adresse';
  @Input() address: Address | null;           // used as a initial value, without a form
  @Input() addresses: Address[] | null;       // only needed if customerId is not set
  @Input() customerId?: number;               // only needed if addresses is not set
  @Input() invoiceId: string | null = null;   // used with customerId
  @Input() deliveryId: string | null = null;   // used with customerId
  @Input() rights = true;
  @Input() realValue = false;
  @Input() writeBack = true;                  // if initial form values should be written back to the form
                                              // (should not be used with valueChanges)
  @Input() customerChangeNoWriteBack = true;
  @Input() uniqueAddress = false;             // only pick an address if there is only one (See issue #1835)
  @Input() button = false;                    // if the address should be rendered as a button
  @Output() updateEmitter = new EventEmitter<Address | null | undefined>();
  private data$: Observable<Address[]> | null = null;
  private addressesObject: { [key: string]: Address } = {};
  private formInit = false;
  private formAsync = false;
  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  constructor(private addressService: AddressService,
              private alertService: AlertService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (this.addresses?.length > 0) {
      this.initStaticAddresses();
    } else {
      this.data$?.subscribe(addresses => {
        this.addresses = addresses;
        this.initStaticAddresses();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerId && !!changes.customerId.currentValue) {
      debug('CustomerId:', changes);
      this.address = null;
      this.addresses = null;
      this.addressesObject = {};
      this.doChange(null);
      this.data$ = this.addressService.list({customer_id: changes.customerId.currentValue}).pipe(
        publishReplay(1),
        refCount()
      );
      debug('CustomerId Data Changed');
      // if an invoiceId is directly set, while the customerId changes
      // we actually also directly change the current invoice address
      if (this.invoiceId !== null) {
        this.handleAsyncWriteValue(this.invoiceId);
      }

      // if an deliveryId is directly set, while the customerId changes
      // we actually also directly change the current invoice address
      if (this.deliveryId !== null) {
        this.handleAsyncWriteValue(this.deliveryId);
      }
    }
    if (changes.addresses && !changes.addresses.isFirstChange()) {
      this.initStaticAddresses();
    }
    if (changes.invoiceId && !changes.invoiceId.isFirstChange() && !this.addresses) {
      this.initStaticAddress();
    }
  }

  ngOnDestroy(): void {
    this.data$ = null;
  }

  // -- VALUE ACCESSOR

  writeValue(obj: any): void {
    let currentAddress: Address | null;
    if (obj === null || obj === undefined) {
      currentAddress = null;
    } else if (obj === 'undefined') {
      currentAddress = null;
    } else if (obj.hasOwnProperty('id')) {
      currentAddress = obj;
    } else if (typeof obj === 'string') {
      if (this.addresses !== null) {
        currentAddress = this.addressesObject[obj];
      } else if (this.data$ !== null) {
        this.handleAsyncWriteValue(obj);
        return; // return early because we do not need to set anything
      }
    }

    // backup
    this.address = currentAddress;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
    if (!this.formInit && !this.formAsync) {
      this.formInit = true;
      // write back form values
      // on form register
      if (this.writeBack) {
        if (!!this.address) {
          if (this.realValue) {
            this._onChange(this.address);
          } else {
            this._onChange(this.address.id);
          }
        } else {
          this._onChange(null);
        }
      }
    }
  }

  registerOnTouched(fn: any): void {
  }

  // -- helpers

  isEmpty(): boolean {
    return !this.addresses && !this.data$;
  }

  open(): void {
    if (!this.isEmpty()) {
      this.modalObs().pipe(
        catchError(error => {
          debug('Modal Error', error);
          this.alertService.add('danger', 'Ein unbekannter Fehler ist aufgetreten!');
          return throwError('modal error');
        }),
        mergeMap(addresses => {
          const modalRef = this.modalService.open(AddressBoxModalChangeComponent);
          modalRef.componentInstance.addresses = addresses;
          debug('Address:', this.address);
          if (!!this.address) {
            modalRef.componentInstance.selected = this.address.id;
          }
          return from(modalRef.result);
        })
      ).subscribe(value => {
        this.address = value;
        this.doChange(value);
      }, () => {
      }, () => {
        debug('completed open modal');
      });
    }
  }

  private modalObs(): Observable<Address[]> {
    let obs: Observable<Address[]>;
    if (!!this.data$) {
      obs = this.data$;
    } else if (!!this.addresses) {
      obs = of(this.addresses);
    } else {
      obs = throwError('not a valid observable');
    }
    return obs;
  }

  private doChange(address: Address | null | undefined): void {
    if (this.realValue) {
      this._onChange(address);
    } else {
      if (!!address) {
        this._onChange(address.id);
      } else {
        this._onChange(null);
      }
    }
    this.updateEmitter.next(address);
  }

  private handleAsyncWriteValue(obj: string): void {
    this.formAsync = true;
    this.data$?.subscribe(value => {
      if (!this.uniqueAddress || this.addresses?.length > 1) {
        this.address = value?.find(v => v.id === obj);
        debug('New Address:', this.address, obj);
      }
      if (this.customerChangeNoWriteBack) {
        this.doChange(this.address);
      }
    }, () => {
    }, () => {
      debug('completed writeValue');
    });
  }

  private initStaticAddresses(): void {
    if (this.addresses?.length > 0) {
      this.addresses.forEach(data => this.addressesObject[data.id] = data);
      this.initStaticAddress();
    }
  }

  private initStaticAddress(): void {
    if (!!this.invoiceId) {
      this.address = this.addressesObject[this.invoiceId];
    }
  }

}
