<div [formGroup]="form">
  <div class="row border-bottom">
    <div class="col-sm-9">
      <div class="form-group row">
        <label for="title" class="col-sm-2 control-label">Anrede:</label>
        <div class="col-sm-10">
          <select class="form-control" id="title" style="width: 100px;" formControlName="title" required>
            <option value="Herr">Herr</option>
            <option value="Frau">Frau</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="contact_name" class="col-sm-2 control-label">Vorname:</label>
        <div class="col-sm-10">
          <input type="text"
                 class="form-control"
                 id="contact_name"
                 formControlName="first_name"
                 [ngClass]="{red_alert: errors['obj.first_name']}">
        </div>
      </div>

      <div class="form-group row">
        <label for="last_name" class="col-sm-2 control-label">Nachname:</label>
        <div class="col-sm-10">
          <input type="text"
                 class="form-control"
                 id="last_name"
                 formControlName="last_name"
                 [ngClass]="{red_alert: errors['obj.last_name']}">
        </div>
      </div>

      <div class="form-group row">
        <label for="department" class="col-sm-2 control-label">Abteilung:</label>
        <div class="col-sm-10">
          <ngx-bootstrap-multiselect id="department"
                                   formControlName="departments"
                                   [options]="options"
                                   [texts]="texts"
                                   [settings]="settings">
          </ngx-bootstrap-multiselect>
        </div>
      </div>

      <div class="form-group row">
        <label for="position" class="col-sm-2 control-label">Position:</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="position" formControlName="position">
        </div>
      </div>

    </div>
    <div class="col-sm-3">
      <img style="height: 100px;" src="/ui/assets/images/contact_opancy.png">
    </div>
  </div>

  <div class="row border-bottom">
    <div class="col-sm-9">
      <h4>Kontakt</h4>

      <div class="form-group row">
        <label for="phone" class="col-sm-2 control-label contact-control">Telefon:</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="phone" formControlName="phone">
        </div>
      </div>

      <div class="form-group row">
        <label for="fax" class="col-sm-2 control-label contact-control">Fax:</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="fax" formControlName="fax">
        </div>
      </div>

      <div class="form-group row">
        <label for="mobile" class="col-sm-2 control-label contact-control">Mobil:</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="mobile" formControlName="mobile">
        </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-sm-2 control-label contact-control">E-Mail:</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="email" formControlName="mail">
        </div>
      </div>

    </div>
    <div class="col-sm-3">
    </div>
  </div>

  <div class="row">
    <div class="col-sm-9">
      <label for="note"><h4>Hinweis</h4></label>

      <div class="form-group row">
        <div class="col-sm-12">
          <textarea class="form-control" id="note" rows="4" formControlName="note"></textarea>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
    </div>
  </div>
</div>
