import {Component, Input, OnInit} from '@angular/core';
import {ContactService} from '../../services/contact.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Contact, ContactForm} from '../../model/contact';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactDepartment} from '../../contact-department/customer-contact-department.models';

@Component({
  selector: 'customer-contact-edit',
  templateUrl: './customer-contact-edit.component.html',
  styleUrls: ['../customer-contacts.style.scss'],
})
export class CustomerContactEditComponent implements OnInit {
  @Input() departments: ContactDepartment[];
  @Input() contact: Contact;
  form: UntypedFormGroup;
  change = false;
  errors: { [key: string]: any; } = {};

  constructor(private activeModal: NgbActiveModal,
              private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  toggle() {
    if (this.change) {
      this.initForm();
    }
    this.change = !this.change;
  }

  save() {
    const formValue: ContactForm = this.form.value;
    this.contactService.edit(this.contact.id, formValue).subscribe(() => {
      this.alertService.add('success', 'Kontakt erfolgreich editiert!');
      this.activeModal.close();
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Beim Editieren eines Kontaktes ist ein Fehler aufgetreten!');
    });
  }

  deleteContact() {
    if (confirm('Möchten Sie den Ansprechpartner "' + this.contact.first_name + ' ' + this.contact.last_name +
        '" wirklich löschen?')) {

      this.contactService.delete(this.contact.id).subscribe(() => {
        this.activeModal.close();
        this.alertService.add('success', 'Kontakt erfolgreich gelöscht!');
      }, () => {
        this.alertService.add('danger', 'Beim Löschen des Kontaktes ist ein Fehler aufgetreten!');
      });
    }
  }

  private initForm() {
    const departments = [];
    Object.assign(departments, this.contact.departments);
    this.form = this.fb.group({
      title: [this.contact.title, Validators.required],
      first_name: [this.contact.first_name],
      last_name: [this.contact.last_name, Validators.required],
      position: [this.contact.position],
      phone: [this.contact.phone],
      fax: [this.contact.fax],
      mobile: [this.contact.mobile],
      mail: [this.contact.mail],
      departments: [departments],
      note: [this.contact.note],
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
