import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ListService} from '../../../common/search/search.service';
import {
  MaterialProductGroup,
  MaterialProductGroupFormValue,
  MaterialProductGroupListElement
} from './material-product-groups.models';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialProductGroupsService implements ListService<MaterialProductGroupListElement> {
  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<MaterialProductGroupListElement>> {
    return this.http.get<Lister<MaterialProductGroupListElement>>(
      `${environment.apiv4uri}material/product/groups`,
      {params: EnvisiaLocation.httpParams(query)},
    );
  }

  create(form: MaterialProductGroupFormValue): Observable<MaterialProductGroup> {
    return this.http.post<MaterialProductGroup>(`${environment.apiv4uri}material/product/group/`, form);
  }

  update(id: number, form: MaterialProductGroupFormValue): Observable<MaterialProductGroup> {
    return this.http.put<MaterialProductGroup>(`${environment.apiv4uri}material/product/group/${id}`, form);
  }

  delete(id: number): Observable<MaterialProductGroup> {
    return this.http.delete<MaterialProductGroup>(`${environment.apiv4uri}material/product/group/${id}`);
  }
}
