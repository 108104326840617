<!-- regular + in workflow -->
<span class="label label-success align-right"
      *ngIf="(inventory.amount_regular > 0) && (!!workflowId && (inventory.amount_regular_in_workflow > 0))">
  <span [title]="'Auf Vorgang ' + workflowId">
    {{ inventory.amount_regular_in_workflow|money:0 }}
  </span>
  /
  <span title="Gesamt">
    {{ inventory.amount_regular|money:0 }}
  </span>
</span>

<!-- regular only -->
<span class="label label-success align-right"
      *ngIf="(inventory.amount_regular > 0) && (!workflowId || (inventory.amount_regular_in_workflow <= 0))">
  {{ inventory.amount_regular|money:0 }}
</span>

<!-- locked storage + in workflow -->
<span class="label label-danger align-right"
      *ngIf="(inventory.amount_locked_stock > 0) && (!!workflowId && (inventory.amount_locked_stock_in_workflow > 0))">
  <span [title]="'In Sperrlager auf Vorgang ' + workflowId">
    {{ inventory.amount_locked_stock_in_workflow|money:0 }}
  </span>
  /
  <span title="In Sperrlager gesamt">
    {{ inventory.amount_locked_stock|money:0 }}
  </span>
</span>

<!-- locked storage only -->
<span class="label label-danger align-right"
      title="In Sperrlager gesamt"
      *ngIf="(inventory.amount_locked_stock > 0) && (!workflowId || (inventory.amount_locked_stock_in_workflow <= 0))">
  {{ inventory.amount_locked_stock|money:0 }}
</span>

<button type="button"
        class="btn btn-link btn-sm"
        title="Lagerbestand anzeigen"
        (click)="openInventory()"
        *ngIf="showInventoryButton">
  <i class="fa fa-cubes"></i>
</button>
