import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FetchChangeService {

  constructor(private http: HttpClient) {
  }

  updateStatus(fetchId: number, state: number, wid: number, ordering: number): Observable<void> {
    return this.http.put<void>('/workflow/v1/fetch/' + state + '/' + fetchId + '/' + wid  + '/' + ordering + '/', {});
  }

}
