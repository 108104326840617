<div class="buttonbar-view">
  <article-button-navigation [article]="article"
                             [locked]="saveBtnLocked"
                             [withLock]="false"
                             (saveEmitter)="saveBtnPressedSet()"
                             (generateSdsEmitter)="generatedSds($event)"
                             (generateSisterEmitter)="generatedSister($event)">
  </article-button-navigation>
</div>

<div class="article-fixed-head">
  <article-top-navigation [article]="article"
                          [multilayerPlan]="multilayerPlan">
  </article-top-navigation>

  <article-top [edit]="false" [article]="article"></article-top>

  <article-locked-info [article]="article"></article-locked-info>
</div>

<div class="clearfix article-content">&nbsp;</div>

<article-multilayer-plan-create [article]="article"
                                [externalModelChange]="model"
                                [changes]="changes"
                                [specification]="specification"
                                [saveBtnPressed]="saveBtnPressed"
                                (saveBtnLocked)="saveBtnLockedFn($event)"
                                (copyModelBtnPressed)="openCopyModal()"
                                (modelChanged)="modelChanged($event)"
                                (modelSaved)="modelSaved($event)"
                                *ngIf="!multilayerPlan">
</article-multilayer-plan-create>

<article-multilayer-plan-update [multilayerPlan]="multilayerPlan"
                                [externalModelChange]="model"
                                [article]="article"
                                [changes]="changes"
                                [specification]="specification"
                                [saveBtnPressed]="saveBtnPressed"
                                (saveBtnLocked)="saveBtnLockedFn($event)"
                                (copyModelBtnPressed)="openCopyModal()"
                                (modelChanged)="modelChanged($event)"
                                (modelSaved)="modelSaved($event)"
                                *ngIf="!!multilayerPlan">
</article-multilayer-plan-update>
