/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Ng2StateDeclaration} from '@uirouter/angular';
import {CUSTOMER_DETAIL_STATE} from './detail/customer-detail.state';
import {CUSTOMER_LIST_STATE} from './list/customer-list.state';
import {CUSTOMER_CREATE_STATE} from './create/customer-create.state';
import {CUSTOMER_NAME_REDIRECT_STATE} from './customer-redirect.state';
import {CUSTOMER_COMMENT_STATE} from './comment/customer-comment.state';
import {CUSTOMER_CONTACTS_STATE} from './contact/customer-contacts.state';
import {CUSTOMER_ARTICLE_EXTRA_STATE} from './article-extra/customer-article-extra.state';
import {CUSTOMER_OPOS_STATE} from './opos/customer-opos.state';

export const CUSTOMER_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.crm',
  abstract: true,
  data: {requiresAuth: true}
};

export const CUSTOMER_SUB_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer',
  abstract: true,
  data: {requiresAuth: true}
};

export const CUSTOMER_STATES: Ng2StateDeclaration[] = [
  // generates a abstract customer route, view nested/nested views
  CUSTOMER_NAME_REDIRECT_STATE,
  CUSTOMER_MAIN_STATE,
  CUSTOMER_SUB_MAIN_STATE,
  CUSTOMER_DETAIL_STATE,
  CUSTOMER_LIST_STATE,
  CUSTOMER_CREATE_STATE,
  // Comments
  CUSTOMER_COMMENT_STATE,
  // Contacts Route
  CUSTOMER_CONTACTS_STATE,
  // Liefervorschriften Route
  CUSTOMER_ARTICLE_EXTRA_STATE,
  CUSTOMER_OPOS_STATE,
];
