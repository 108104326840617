import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {ProducerModalComponent} from './producer-modal.component';


@NgModule({
    imports: [
        SharedModule,
        NgbModalModule
    ],
    declarations: [ProducerModalComponent]
})
export class ProducerModalModule {
}
