import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShippingInstructionHistoryService} from '../../article/services/shipping-instruction-history.service';
import {ShippingInstructionType} from '../../article/models/article-extra.models';
import {ShippingInstructionHistoryModalComponent} from './shipping-instruction-history-modal.component';
import {ShippingInstructionService} from '../../article/services/shipping-instruction.service';
import {ArticleCore} from '../../article/models/article.models';

@Injectable({
  providedIn: 'root'
})
export class ShippingInstructionModalService {

  constructor(private modalService: NgbModal,
              private siService: ShippingInstructionService,
              private sihService: ShippingInstructionHistoryService) {
  }

  open(type: ShippingInstructionType, item: string, visibleName: string, article?: ArticleCore): void {
    this.siService.schema().subscribe(schema => {
      this.sihService.list(type, item).subscribe(list => {
        const modalRef = this.modalService.open(ShippingInstructionHistoryModalComponent, {windowClass: 'modal2-full'});
        modalRef.componentInstance.list = list;
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.visibleName = visibleName;
        modalRef.componentInstance.schema = schema;
        modalRef.componentInstance.article = article;
      });
    });
  }

}
