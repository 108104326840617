import {Component, Input, OnInit} from '@angular/core';
import {ContactDepartment} from '../../contact-department/customer-contact-department.models';
import {UntypedFormGroup} from '@angular/forms';
import {MultiSelectConfig} from '../../../../common/multi-select-config';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from '../../../../common/multiselect/types';

@Component({
  selector: 'customer-contact-form',
  templateUrl: './customer-contact-form.component.html',
  styleUrls: ['../customer-contacts.style.scss'],
})
export class CustomerContactFormComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() departments: ContactDepartment[];
  errors: { [key: string]: any; } = {};
  options: IMultiSelectOption[] = [];
  settings: IMultiSelectSettings = MultiSelectConfig.settings;
  texts: IMultiSelectTexts = MultiSelectConfig.texts;

  constructor() {
  }

  ngOnInit() {
    this.options = this.departments.map(cd => {
      return {id: cd.name, name: cd.name};
    });
  }
}
