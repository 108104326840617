import {Component, Input} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {Customer, CustomerId, SmallCustomer} from '../model/customer';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {AkquiseCustomer} from '../../akquise/akquise.models';

@Component({
  selector: 'customer-navbar',
  templateUrl: './customer-navbar.component.html',
  styleUrls: ['./customer-header.scss'],
})
export class CustomerNavbarComponent {

  @Input() customer: Customer | AkquiseCustomer | SmallCustomer | CustomerId;
  public state: StateService;

  constructor(private stateService: StateService,
              private envisiaLocation: EnvisiaLocation) {
    this.state = stateService;
  }

  reload(event: Event, stateName: string): void {
    if (this.stateService.current.name === stateName) {
      event.preventDefault();
      this.envisiaLocation.search({});
      this.stateService.go(this.stateService.current, {}, {reload: true});
    }
  }

}
