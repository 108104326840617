import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MaterialManagementService} from '../material-management.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {
  MaterialFieldAttributes,
  MaterialFieldListElement,
  MaterialGroupListElement,
  MaterialGroupListElementSelectable,
  valueTypes
} from '../material-management.model';

@Component({
  selector: 'material-field-create',
  templateUrl: './material-field-create.component.html'
})
export class MaterialFieldCreateComponent implements OnInit {
  @Input() fieldAttributes?: MaterialFieldAttributes;
  @Input() groupList: MaterialGroupListElementSelectable[];
  selectedGroups: MaterialGroupListElement[];
  allGroups = false;

  fieldCreateForm: UntypedFormGroup;
  errorCode?: number = null;
  duplicateField?: MaterialFieldListElement = null;
  listChoices: string[] = [];
  valueTypes: string[] = [valueTypes[0], valueTypes[1], valueTypes[2], valueTypes[4], valueTypes[6]];

  addChoiceForm: UntypedFormGroup;

  constructor(
    private mmService: MaterialManagementService,
    private alertService: AlertService,
    private stateService: StateService,
    private globals: UIRouterGlobals,
    private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    if (this.fieldAttributes) {
      this.allGroups = this.fieldAttributes.all_groups;
      this.selectedGroups =
        this.fieldAttributes.groups
          .filter((group) => group.is_selected);
    } else {
      this.selectedGroups = [];
    }
    this.buildCreateFieldForm();
    this.buildAddChoiceForm();
  }

  private buildCreateFieldForm() {
    if (this.fieldAttributes) {
      this.fieldCreateForm = this.fb.group({
        id: [ this.fieldAttributes.field.id, {emitEvent: false}],
        name: [this.fieldAttributes.field.name, [Validators.required]],
        data_type: [ { value: this.fieldAttributes.field.data_type, disabled: true }, [Validators.required]],
        is_variation: [ { value: this.fieldAttributes.field.is_variation, disabled: true }, [Validators.required]],
        read_only: [ this.fieldAttributes.field.read_only ],
        on_label: [ this.fieldAttributes.field.on_label ],
        on_ba_list: [ this.fieldAttributes.field.on_ba_list ],
      });
    } else {
      this.fieldCreateForm = this.fb.group({
        name: [ null, [Validators.required]],
        data_type: [ null, [Validators.required]],
        is_variation: [ null, [Validators.required]],
        read_only: [ false ],
        on_label: [ false ],
        on_ba_list: [ false ],
      });
    }
  }

  buildAddChoiceForm() {
    this.addChoiceForm = this.fb.group({
      name: new UntypedFormControl(null, [Validators.required])
    });
  }

  isChoicesSelected() {
    return (
      (this.fieldCreateForm && this.fieldCreateForm.value.data_type === 'Auswahl') ||
      (this.fieldAttributes && this.fieldAttributes.field.data_type === 'Auswahl')
    );
  }

  concatChoices(): string[] | null {
    if (this.isChoicesSelected()) {
      if (this.fieldAttributes && this.fieldAttributes.field && this.fieldAttributes.field.choices) {
        return this.fieldAttributes.field.choices.concat(this.listChoices);
      } else {
        return this.listChoices;
      }
    } else {
      return null;
    }
  }

  isChoiceValid(): boolean {
    return (
      this.addChoiceForm.valid &&
      !this.listChoices.includes(this.addChoiceForm.value.name) &&
      !(
        this.fieldAttributes &&
        this.fieldAttributes.field.choices &&
        this.fieldAttributes.field.choices.includes(this.addChoiceForm.value.name)
      )
    );
  }

  addChoice() {
    if (this.isChoiceValid()) {
      this.listChoices.push(this.addChoiceForm.value.name);
      this.addChoiceForm.reset();
    }
  }

  removeChoice(choice: string) {
    const index = this.listChoices.indexOf(choice);
    this.listChoices.splice(index, 1);
  }

  toggleAllSelected(select?: boolean) {
    if (select === undefined) {
      this.allGroups = !this.allGroups;
    } else {
      this.allGroups = select;
    }
  }

  setSelectedGroups(selectedGroups: MaterialGroupListElement[]): void {
    this.selectedGroups = selectedGroups;
  }

  onSubmit() {
    const form = this.fieldCreateForm.getRawValue();
    form['choices'] = this.concatChoices();
    form['relations'] = {
      group_ids: !this.allGroups ? this.selectedGroups.map((group) => group.id) : [],
      all_groups: this.allGroups
    };
    (form['id'] ? this.mmService.updateField(form['id'], form) : this.mmService.insertField(form))
    .subscribe((attr) => {
      this.stateService.go('a.material.field.list');
    }, (response) => {
      this.errorCode = response.status;
      if (this.errorCode === 409) {
        this.duplicateField = response.error as MaterialFieldListElement;
      } else {
        this.duplicateField = null;
      }
    });
  }
}
