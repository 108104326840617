import {Component, Input} from '@angular/core';
import {MaterialFieldWithValue} from '../../material-management.model';

@Component({
  selector: 'material-inventory-field-switch',
  templateUrl: './material-inventory-field-switch.component.html',
})
export class MaterialInventoryFieldSwitchComponent {

  @Input() fieldValues: MaterialFieldWithValue[];

  constructor() { }
}
