import {
  Component,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import Helper from '../../../helper/helper';
import {ArticleV3Service} from '../../article/services/article3.service';
import {GlobalService} from '../../../core/global.service';
import {ArticleService} from '../../article/services/article.service';
import {ArticleListData} from '../../article/models/article.models';
import {Subscription} from 'rxjs';
import {ArticleEither} from './workflow-main.models';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'workflow-create-article-search',
  templateUrl: './workflow-create-article-search.component.html',
  styles: ['.pad35 { padding-right: 35px !important; }']
})
export class WorkflowCreateArticleSearchComponent implements OnInit, OnDestroy {
  @Input() limit = 50;
  @Input() withoutOffers = false;
  @Output() offerEmitter = new EventEmitter<number>();
  @Output() nextEmitter = new EventEmitter<ArticleEither>();
  form: UntypedFormGroup;
  articleList: ArticleListData[];
  nextLoading = false;
  errors = false;
  locked = false;
  loading = {sds_nr: false, kd_art_nr: false, kd_art_index: false};
  private subscription?: Subscription;
  private differ: KeyValueDiffer<string, string>;

  constructor(private fb: UntypedFormBuilder,
              private keyValueDiffers: KeyValueDiffers,
              private articleService: ArticleService,
              private articleV3Service: ArticleV3Service,
              private globalService: GlobalService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      sds_nr: [null],
      kd_art_nr: [null],
      kd_art_index: [null],
      oa_nr: [null],
      offer_id: [null]
    });
    this.differ = this.keyValueDiffers.find(this.form.value).create();

    this.subscription = this.form.valueChanges.pipe(debounceTime(500)).subscribe(formValues => {
      this.articleFind(formValues);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  next(article?: ArticleListData): void {
    if (article) {
      // we will use the article provided by setting
      // the article either to left
      const obj = {article: {left: {oa_nr: article.oa_nr}}, customer: article.customer};
      this.nextEmitter.next(obj);
    } else {
      // we will create a new article by setting
      // the article either to right
      this.nextLoading = true;
      const obj = {
        sds_nr: this.form.value.sds_nr,
        kd_art_nr: this.form.value.kd_art_nr,
        kd_art_index: this.form.value.kd_art_index
      };
      this.articleV3Service.duplicates(obj).subscribe(() => {
        this.nextLoading = false;
        this.nextEmitter.next({article: {right: obj}});
      }, () => {
        this.nextLoading = false;
        this.errors = true;
      });
    }
  }

  articleFind(formValues?: any): void {
    const data = formValues ? formValues : this.form.value;
    if (data.offer_id === '') {
      delete data.offer_id;
    }
    data.limit = this.limit;

    /** trigger sds check */
    let search;
    const changes = this.differ.diff(data);
    if (changes !== null) {
      changes.forEachChangedItem(value => {
        if (value.key === 'sds_nr' || value.key === 'kd_art_nr' || value.key === 'kd_art_index') {
          search = value.key;
        }
      });
    }

    if (search) {
      this.sdsCheck(search);
    }

    this.globalService.show = true;
    this.articleService.search(data).subscribe(values => {
      this.articleList = values;
      this.globalService.show = false;
    }, () => {
      this.globalService.show = false;
    });
  }

  showWorkflow(offer: number): void {
    this.offerEmitter.next(offer);
  }

  private sdsCheck(search: string) {
    const query = this.form.value;
    if (Helper.undefined(query.sds_nr) && Helper.undefined(query.kd_art_nr) && Helper.undefined(query.kd_art_index)) {
      this.errors = false;
      this.locked = false;
    } else if (this.locked === false && !!search && Helper.checkUndef(query, search)) {
      this.locked = true;
      this.loading[search] = true;

      this.errors = false;
      const obj = {
        sds_nr: query.sds_nr,
        kd_art_nr: query.kd_art_nr,
        kd_art_index: query.kd_art_index
      };
      this.articleV3Service.duplicates(obj).subscribe(() => {
        this.reset();
      }, () => {
        this.errors = true;
        this.reset();
      });
    }
  }

  private reset(): void {
    this.locked = false;
    this.loading.sds_nr = false;
    this.loading.kd_art_nr = false;
    this.loading.kd_art_index = false;
  }

}
