import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ContractListData} from '../../models/contract.models';
import {DateHelper} from '../../../../helper/date-helper';

@Component({
  selector: 'tr[contract-list-element-single-row]',
  templateUrl: './contract-list-element-single-row.component.html',
})
export class ContractListElementSingleRowComponent implements OnInit, OnChanges {
  @Input('contract-list-element-single-row') contract: ContractListData;
  @Input('contract-list-element-single-row-toggle-show') toggleShow: boolean;
  @Output('contract-list-element-single-row-toggle-single-row') toggleSingleRow = new EventEmitter<any>();
  orderNrDisplayed: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.contract && !changes.contract.isFirstChange()) {
      this.setDisplayedOrderNr();
    }
  }

  ngOnInit(): void {
    this.setDisplayedOrderNr();
  }

  setDisplayedOrderNr(): void {
    this.orderNrDisplayed = '';
    this.contract.fetches.forEach((f, i) => {
      const dateDelivery = DateHelper.safeParseDate(f.date_delivery, true);
      const dateDeliveryString = dateDelivery === null ? '' : ' ' + DateHelper.format(dateDelivery);
      const orderNr = !f.order_nr ? '' : ' ' + f.order_nr;
      this.orderNrDisplayed += `${i === 0 ? '' : ', '}#${f.ordering}${dateDeliveryString}${orderNr}`;
    });
  }

  toggle(): void {
    this.toggleSingleRow.emit();
  }
}
