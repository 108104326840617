import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpXsrfTokenExtractor
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { htmlCssOrJs} from './interceptor-helper';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../../envisia/auth/auth.service';

@Injectable()
export class EnvisiaInterceptor implements HttpInterceptor {
  private readonly sessionDate = new Date();

  constructor(private tokenService: HttpXsrfTokenExtractor, private authService: AuthService) {
  }

  private handleAuthError(res: HttpErrorResponse): Observable<any> {
    if (res.status === 401 && (res.url.endsWith('/Account/Check') || res.url.endsWith('/Account/Logout'))) {
      console.warn('automatic logout', this.sessionDate, new Date());

      this.authService.removeAuth(true, res.url.endsWith('/Account/Check'));

      return of(res.message);
    }

    return throwError(res);
  }

  private addAuthentication(request: HttpRequest<any>): HttpRequest<any> {
    const resource = htmlCssOrJs(request.url);
    if (!resource) {
      const headers = {'X-Requested-With': 'XMLHttpRequest'};
      const token = this.tokenService.getToken();
      if (token != null) {
        headers['X-XSRF-TOKEN'] = this.tokenService.getToken();
      }

      return request.clone({ setHeaders: headers, withCredentials: true });
    }

    return request;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = this.addAuthentication(req);

    return next.handle(authReq).pipe(catchError(e => this.handleAuthError(e)));
  }

}
