<div class="modal-header">
  <h3 class="modal-title">
    Artikel duplizieren
  </h3>
</div>

<form class="form-horizontal" [formGroup]="copyForm" (ngSubmit)="save()">
  <div class="modal-body">

    <div class="row placeholder-left">

      <div class="form-group">
        <label for="sds_nr" class="col-sm-3 control-label">SDS-Nr.</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="sds_nr" formControlName="sds_nr"
                 [ngClass]="{'red_alert': errors['obj.sds_nr']}">
        </div>

        <div class="col-sm-3">&nbsp;</div>
      </div>

      <div class="form-group">
        <label for="kd_art_nr" class="col-sm-3 control-label">Kunden-Art-Nr.</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="kd_art_nr" formControlName="kd_art_nr"
                 [ngClass]="{'red_alert': errors['obj.kd_art_nr']}">
        </div>
      </div>

      <div class="form-group">
        <label for="kd_art_index" class="col-sm-3 control-label">Kunden-Art-Index</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="kd_art_index" formControlName="kd_art_index"
                 [ngClass]="{'red_alert': errors['obj.kd_art_index']}">
        </div>
        <div class="col-sm-3">&nbsp;</div>
      </div>

      <div class="form-group">
        <label for="site_nr" class="col-sm-3 control-label">Lagerplatz</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="site_nr" formControlName="site_nr">
        </div>
        <div class="col-sm-3">&nbsp;</div>
      </div>

    </div>

    <div class="row">
      <div class="col-sm-1">&nbsp;</div>
      <div class="col-sm-10">
        <article-duplicate *ngIf="duplicates" [duplicates]="duplicates"></article-duplicate>
      </div>
      <div class="col-sm-1">&nbsp;</div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-default pull-left" type="button" (click)="cancel()">Abbrechen</button>
    <button class="btn btn-success pull-right" type="submit">Duplizieren</button>
  </div>
</form>
