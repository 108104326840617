import * as React from 'react';
import {ArticleSpecification} from '../../envisia/article/models/article-specification.model';
import {
  ArticleMultilayerPlan,
  ArticleMultilayerPlanModel,
} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan.models';
import {useState} from 'react';
import {useFetchEffect} from '../../react/helper/react-async-fetch-effect';
import {ArticleMultilayerPlanForm} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan-form';
import {MultilayerPlanAxiosService} from './multilayer-plan-axios.service';
import {
  ArticleMultilayerSelect,
} from '../../envisia/article/main/multilayer-plan/form-elements/article-multilayer-select.component';
import {ArticleMultilayerPlanHelper} from '../../envisia/article/main/multilayer-plan/article-multilayer-plan-helper';

interface Props {
  specification: { [key: string]: ArticleSpecification };
  modelChanged: (model: ArticleMultilayerPlanModel) => void;
  modelSaved: (model: ArticleMultilayerPlan) => void;
  saveBtnPressed: number;
  saveBtnLocked: (saveBtnPressed: boolean) => void;
}

export function MultilayerPlanCreate(props: Props) {
  const [modelState, modelStateSet] = useState<ArticleMultilayerPlanModel>(ArticleMultilayerPlanHelper.emptyModel());
  const [saveBtnPressed, saveBtnPressedSet] = useState<number>(props.saveBtnPressed);
  const [saveBtnLocked, saveBtnLockedSet] = useState<boolean>(false);

  const changeModel: (model: ArticleMultilayerPlanModel) => void = (model) => {
    props.modelChanged(model);
    modelStateSet(model);
  };

  const materialChange: (m: string | null | undefined) => void = (m) => {
    const model = ArticleMultilayerPlanHelper.copy(modelState);
    model.selected_material = !m ? 'Standard FR4' : m;
    props.modelChanged(model);
    modelStateSet(ArticleMultilayerPlanHelper.recalculate(model));
  };

  /* Hooks */
  useFetchEffect(async (didCancel) => {
    if (props.saveBtnPressed <= saveBtnPressed) {
      return;
    }
    if (saveBtnLocked) {
      return;
    }
    saveBtnLockedSet(true);
    props.saveBtnLocked(true);
    try {
      const plan = await MultilayerPlanAxiosService.create(modelState);
      props.modelSaved(plan);
      modelStateSet(plan.data);
    } finally {
      saveBtnLockedSet(false);
      props.saveBtnLocked(false);
    }
    saveBtnPressedSet(props.saveBtnPressed);
  }, [props.saveBtnPressed]);

  return <ArticleMultilayerPlanForm model={modelState}
                                    specification={props.specification}
                                    changeModel={m => changeModel(m)}>
    <ArticleMultilayerSelect specification={props.specification}
                             model={'manufacturer'}
                             valueType={'keys'}
                             value={modelState.selected_material}
                             disabled={modelState.parts === null || modelState.parts === undefined}
                             onChange={m => materialChange(m)}/>
  </ArticleMultilayerPlanForm>;
}
