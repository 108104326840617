import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LabelPrinterData} from '../label.models';
import {NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {LabelService} from '../label.service';
import {AlertService} from '../../../common/alert-service/alert.service';

@Component({
  selector: 'app-label-settings',
  templateUrl: './label-settings.component.html',
})
export class LabelSettingsComponent implements OnInit, OnDestroy {
  @Input() initialData: LabelPrinterData;
  previewImage: string | null = null;
  printerData: LabelPrinterData = {ip: '', template: ''};

  form: UntypedFormGroup;
  private baseTemplate: string | null;
  private activeTab: 'default' | 'customer' = 'default';
  private formSubscription?: Subscription;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private labelService: LabelService) {
  }

  ngOnInit() {
    this.baseTemplate = this.initialData.template;
    this.printerData.template = this.baseTemplate;
    this.printerData.ip = this.initialData.ip;
    this.form = this.fb.group({
      'ip': [this.initialData.ip, Validators.required],
      'template': [this.initialData.template, Validators.required]
    });
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(550)).subscribe(data => {
      this.printerData.ip = data.ip;
      if (this.activeTab === 'customer') {
        this.printerData.template = '';
      }
      if (this.activeTab === 'default') {
        this.printerData.template = data.template;
      }
    });

  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  submitData(): void {
    this.labelService.updateSettings(this.form.value).subscribe(() => {
      this.alertService.add('success', 'Einstellungen erfolgreich gespeichert!');
    }, () => {
      this.alertService.add('danger', 'Einstellungen speichern fehlgeschlagen!');
    });
  }

  preview(template: string): void {
    this.previewImage = template;
  }

  tabChange(event: NgbNavChangeEvent): void {
    if (event.nextId === 'customer') {
      this.activeTab = event.nextId;
      this.printerData.template = '';
    }

    if (event.nextId === 'default') {
      this.activeTab = event.nextId;
      this.printerData.template = this.baseTemplate;
    }
  }

  updateCustomerTemplate(customerTemplate): void {
    console.log('updateCustomerTemplate', customerTemplate);
    this.printerData.template = customerTemplate;
  }

}
