import {Ng2StateDeclaration, StateService, Transition} from '@uirouter/angular';
import {MaterialPlanningListComponent} from './material-planning-list.component';
import {MaterialPlanningListService} from './material-planning-list.service';
import {MaterialSupplierService} from '../../../lazy/material-supplier/material-supplier.service';
import {MaterialPlanningListQuery} from './material-planning.model';
import {CustomerService} from '../../customer/services/customer.service';
import {EMPTY} from 'rxjs';
import {MaterialGroupListPermittedService} from '../groups/material-group-list-permitted.service';
import {TargetState} from '@uirouter/core';
import {MaterialGroup} from '../groups/material-group.model';
import {MaterialProductGroupListService} from '../product-groups/material-product-groups-list.service';

function materialProductGroupListResolveFn(mpgs: MaterialProductGroupListService) {
  return mpgs.list().toPromise();
}

export function materialGroupListResolveFn(mgs: MaterialGroupListPermittedService) {
  return mgs.list({'endpoint': 'planning'}).toPromise();
}

export function materialSearchParamResolveFn(ts: Transition): MaterialPlanningListQuery {
  const groupId = Number.parseInt(ts.params().group_id, 10);
  const supplierId = Number.parseInt(ts.params().supplier_id, 10);
  const customerId = Number.parseInt(ts.params().customer_id, 10);
  const cfa = ts.params().cfa;
  const cfaByDate = ts.params().cfa_by_date;
  return {
    group_id: groupId,
    supplier_id: Number.isNaN(supplierId) ? null : supplierId,
    variation_id: ts.params().variation_id,
    vendor_article_id: ts.params().vendor_article_id,
    article_name: ts.params().article_name,
    page: ts.params().page,
    customer_id: Number.isNaN(customerId) ? null : customerId,
    cfa: cfa === true || cfa?.toLowerCase() === 'true' ? true : null,
    cfa_by_date: cfaByDate === true || cfaByDate?.toLowerCase() === 'true' ? true : null,
    date_limit_from: ts.params().date_limit_from,
    date_limit_to: ts.params().date_limit_to,
    order: ts.params().order,
    sort: ts.params().sort,
  };
}

export function materialPlanningListResolveFn(ts: Transition, mps: MaterialPlanningListService) {
  const params = ts.params();
  const groupId = params.group_id ?? 1;
  return mps.list(groupId, params).toPromise();
}

export function customerResolveFn(ts: Transition, cs: CustomerService) {
  const params = ts.params();
  const customerIdString = (!!params.customer_id ? params.customer_id : null);
  const customerId = Number.parseInt(customerIdString, 10);
  if (!!customerIdString && Number.isInteger(customerId)) {
    return cs.detail(customerId).toPromise();
  } else {
    return EMPTY;
  }
}

export function materialSupplierListResolveFn(mss: MaterialSupplierService) {
  return mss.list({sort: 'priority', order: 'ASC'}).toPromise();
}

export const MATERIAL_PLANNING_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.material.planning.list.list',
  url: '/material/planning/{group_id}?page&sort&order&supplier_id&article_name&fields&customer_id&cfa&cfa_by_date&date_limit_from&date_limit_to',
  params: {
    group_id: {dynamic: true},
    page: {dynamic: true},
    sort: {dynamic: true},
    order: {dynamic: true},
    supplier_id: {dynamic: true},
    article_name: {dynamic: true},
    fields: {dynamic: true},
    customer_id: {dynamic: true},
    cfa: {dynamic: true},
    cfa_by_date: {dynamic: true},
    date_limit_from: {dynamic: true},
    date_limit_to: {dynamic: true},
  },
  data: {
    requiresAuth: true
  },
  views: {
    'main@a': {component: MaterialPlanningListComponent}
  },
  resolve: [
    {
      token: 'productGroups',
      resolveFn: materialProductGroupListResolveFn,
      deps: [MaterialProductGroupListService],
    },
    {
      token: 'groups',
      resolveFn: materialGroupListResolveFn,
      deps: [MaterialGroupListPermittedService],
    },
    {
      token: 'params',
      resolveFn: materialSearchParamResolveFn,
      deps: [Transition],
    },
    {
      token: 'list',
      resolveFn: materialPlanningListResolveFn,
      deps: [Transition, MaterialPlanningListService],
    },
    {
      token: 'suppliers',
      resolveFn: materialSupplierListResolveFn,
      deps: [MaterialSupplierService],
    },
    {
      token: 'customer',
      resolveFn: customerResolveFn,
      deps: [Transition, CustomerService],
    },
  ]
};

function materialGroupFirstResolveFn(s: MaterialGroupListPermittedService): Promise<MaterialGroup> {
  return s.first('material_planning').toPromise();
}

export function workflowDocumentCreateRedirectToFn(transition: Transition): Promise<TargetState> {
  const stateService = transition.injector().get<StateService>(StateService);
  return transition.injector().getAsync('first_group').then(r => {
    return stateService.target('a.material.planning.list.list', {'group_id': r.id});
  }, () => {
    return stateService.target('a.material.planning.list.list', {'group_id': 1});
  });
}

export const MATERIAL_PLANNING_LIST_LANDINGPAGE_STATE: Ng2StateDeclaration = {
  name: 'a.material.planning.list.landingpage',
  url: '/material/planning/landingpage',
  data: {
    requiresAuth: true
  },
  redirectTo: workflowDocumentCreateRedirectToFn,
  resolve: [
    {
      token: 'first_group',
      resolveFn: materialGroupFirstResolveFn,
      deps: [MaterialGroupListPermittedService],
    },
  ],
};
