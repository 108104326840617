<div>
  <h4 class="pull-left">
    <ng-content></ng-content>
  </h4>

  <div class="pull-right">
    <button type="btn btn-sm btn-link"
            *ngIf="mainType === type && mainType !== 'global' && !readOnly"
            (click)="deleteCalled.emit(true)">
      <i class="fa fa-trash-o"></i>
    </button>

    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'article'">
        <label class="label label-success">Artikel</label>
      </ng-container>

      <ng-container *ngSwitchCase="'customer'">
        <label class="label label-warning">Kunde</label>
      </ng-container>

      <ng-container *ngSwitchCase="'global'">
        <label class="label label-default">Standard</label>
      </ng-container>
    </ng-container>
  </div>
</div>
