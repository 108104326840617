<div style="min-height: 300px;" #outerRef>
  <div class="max-width">

    <div class="file-container">
      <div style="opacity: 0.5;">{{ name }}</div>
      <div style="font-size: 14px; opacity: 0.5;" *ngIf="!selected">Oder ...</div>
      <div style="font-size: 14px; opacity: 0.5;" *ngIf="selected">&nbsp;</div>
      <div>
        <button class="btn btn-sm btn-primary" (click)="selectFile()">
          Dokument zum Hochladen auswählen
        </button>
      </div>

    </div>

    <div id="dropzone" style="visibility: hidden; display: none;">
      <input type="file" id="file" #fileSelector multiple="false" (change)="fileChanged($event)">
    </div>

  </div>
</div>
