import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../model/customer';
import {Category} from '../../finder/finder.models';

@Component({
  selector: 'customer-document-new',
  templateUrl: './customer-document-new.component.html'
})
export class CustomerDocumentNewComponent implements OnInit {
  @Input() data: {customer: Customer};
  @Input() category: Category[];
  initial: {customer_id: number};

  ngOnInit(): void {
    this.initial = {customer_id: this.data.customer.id};
  }

}
