/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {WorkflowOrderRequestService} from '../../workflow-services/workflow-order-request.service';
import {InventoryService} from '../../../article/inventory/inventory.service';
import {OrderReceivedModalService} from '../../order-request/modal/order-received-modal.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WorkflowOrderList, WorkflowOrderMapItem, WorkflowOrderView} from '../../order-request/order-request.models';
import Helper from '../../../../helper/helper';
import {deepCopy} from '../../../../helper/deep-copy';
import {debug} from '../../../../helper/debug.func';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {concat, interval, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

@Component({
  selector: 'contract-order-box',
  templateUrl: './contract-order-box.component.html'
})
export class ContractOrderBoxComponent implements OnInit {
  @Input() workflowId: number;
  @Input() article: string;
  @Input() orders: WorkflowOrderView[] = [];
  @Output() reloadInventory = new EventEmitter<any>();
  orderTypeList: { name: string, type: string, clazz: string }[] = [
    {name: 'Bestellt', type: 'ordered', clazz: 'btn-default'},
    {name: 'Bestätigt', type: 'confirmed', clazz: 'btn-info'},
    {name: 'Versendet', type: 'conveyed', clazz: 'btn-warning'},
    {name: 'Empfangen', type: 'received', clazz: 'btn-primary'},
    {name: 'Geprüft', type: 'reviewed', clazz: 'btn-purple'},
    {name: 'Gebucht', type: 'expensed', clazz: 'btn-success'},
  ];

  orderMapData: LoadingWrapper<WorkflowOrderList>;

  constructor(private workflowOrderRequestService: WorkflowOrderRequestService,
              private inventoryService: InventoryService,
              private orderReceivedModalService: OrderReceivedModalService) {
  }

  ngOnInit(): void {
    this.orderMapData = new LoadingWrapper<WorkflowOrderList>(of(this.buildOrderMap(this.orders)));
  }

  open(id: number, index: number) {
    this.orderReceivedModalService.fromIdAndIndex(id, index).subscribe(() => {
      this.reinit();
    }, () => {
      this.reinit();
    });
  }

  private buildOrderMap(orders: WorkflowOrderView[]): WorkflowOrderList {
    const orderMap: WorkflowOrderList = {
      ordered: [],
      confirmed: [],
      conveyed: [],
      received: [],
      reviewed: [],
      expensed: []
    };

    orders.forEach(value => {
      if (value.positions.status === 0 && value.position_data.length === 0) {
        const order = value as WorkflowOrderMapItem;
        order.quantity = Helper.toInt(order.positions.quanity);
        orderMap.ordered.push(order);
      } else {
        value.position_data.forEach(data => {
          debug('Inner Data:', data);
          const order = deepCopy(value as WorkflowOrderMapItem);
          order.delivery_method = data.confirmed?.delivery_method;

          if (data.hasOwnProperty('expensed') && !!data.expensed) {
            order.quantity = (data.expensed.quantities as number[]).reduce((a, b) => a + b);
            orderMap.expensed.push(order);
          } else if (data.hasOwnProperty('reviewed') && !!data.reviewed) {
            order.quantity = data.reviewed.quantity;
            orderMap.reviewed.push(order);
          } else if (data.hasOwnProperty('received') && !!data.received) {
            order.quantity = ((data.received.data?.map(a => a.quantity) ?? []) as number[]).reduce((a, b) => a + b);
            orderMap.received.push(order);
          } else if (data.hasOwnProperty('conveyed') && !!data.conveyed) {
            order.quantity = data.conveyed.quantity;
            orderMap.conveyed.push(order);
          } else if (data.hasOwnProperty('confirmed') && !!data.confirmed) {
            order.quantity = data.confirmed.quantity;
            orderMap.confirmed.push(order);
          } else {
            order.quantity = Helper.toInt(order.positions.quanity);
            orderMap.ordered.push(order);
          }
        });
      }
    });

    return orderMap;
  }

  private reinit(): void {
    this.reloadInventory.emit();
    this.orderMapData = new LoadingWrapper<WorkflowOrderList>(
      interval(650).pipe(
        take(1),
        switchMap(() => this.workflowOrderRequestService.workflowList(this.workflowId)),
        map(val => this.buildOrderMap(val)),
      )
    );
  }

}
