<div>

  <a title="Neuer Vorgang" class="btn btn-default"
     uiSref="a.workflow.new"
     *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm)">
    <span class="fa fa-plus"></span>
  </a>

  <button type="button" class="btn btn-default"
          *ngIf="data?.workflow?.object?.id && (('task.change'|HasPerm) || ('task.offer.change'|HasPerm))"
          (click)="duplicate()">
    Vorgang duplizieren
  </button>

  <button type="button" class="btn btn-default"
          *ngIf="data?.workflow?.object?.id && (('task.change'|HasPerm) || ('task.offer.change'|HasPerm))"
          (click)="open('cancel')">
    Stornieren
  </button>

  <button type="button" class="btn btn-default"
          *ngIf="data?.workflow?.object?.id && (('task.change'|HasPerm) || ('task.offer.change'|HasPerm))"
          (click)="open('delete')">
    Löschen
  </button>

  <a uiSref="a.workflow.document.new"
     [uiParams]="{id: data.workflow.object.id}"
     class="btn btn-default"
     *ngIf="data?.workflow?.object?.id && (('task.change'|HasPerm) || ('task.offer.change'|HasPerm))">
    Neues Dokument
  </a>

  <button type="button" class="btn btn-default"
          *ngIf="data?.workflow?.object?.id && ('workflow.status.change'|HasPerm)"
          (click)="open('status')">
    Statusänderung
  </button>

</div>
