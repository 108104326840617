<div>
  <div class="row">
    <div class="col-md-12">&nbsp;</div>
  </div>
  <div class="row">
    <div class="col-md-5">
      <h4>Bestell Positionen für Gruppe: <strong>{{group.group.group_id}} {{group.group.name}}</strong></h4>
    </div>
  </div>

  <table class="table"
         *ngIf="materialFieldData.data$ | async as fields; else loadingOrError.template">
    <thead>
    <tr>
      <th>Status</th>
      <th class="align-right">Pos.</th>
      <th class="align-right">Lief.</th>
      <th>Interne Nr.</th>
      <th>Art. Nr. Lief.</th>
      <th>Bezeichnung</th>
      <th>Bestell Bezeichnung</th>
      <th *ngFor="let field of fields">{{ field.name }}</th>
      <th class="align-right">Bestellt</th>
      <th>Einheit</th>
      <th>Lieferdatum</th>
      <th class="align-right">Preis</th>
      <th>Int. Notiz</th>
      <th colspan="5" *ngIf="('material.order.create'|HasPerm)"></th>
    </tr>
    </thead>
    <tbody>
      <tr role="form"
          [ngClass]="{'info': markedPositionId === position.position_id}"
          [material-order-update-position]="position"
          [material-order-update-position-order]="order"
          [material-order-update-position-fields]="fields"
          [material-order-update-position-group-permit]="groupPermitted"
          (material-order-update-position-update)="updateEvent.emit()"
          (material-order-update-position-copy)="copyEvent.emit()"
          *ngFor="let position of group.article_list; trackBy:trackByFnControl; let iP = index">
      </tr>
    </tbody>
  </table>
  <loading-or-error
    #loadingOrError
    [loadingWrapper]="materialFieldData"
    [errorMessage]="'Konnte Material Felder nicht laden'"
    [spinnerSize]="2"
    spinnerName="circle-o-notch">
  </loading-or-error>
</div>
