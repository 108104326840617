import {Component, Input} from '@angular/core';
import {Additional, Category} from '../finder.models';
import {FinderAdditionalData, FinderCategoriesData} from './finder-upload.models';
import {AlertService} from '../../../common/alert-service/alert.service';
import {FinderSearchService} from '../finder-search.service';

@Component({
  selector: 'finder-upload',
  templateUrl: './finder-upload.component.html',
})
export class FinderUploadComponent {
  @Input() category: Category[];
  @Input() initial: { customer_id: number } | { workflow_id: number } | { article_sds_nr: string };
  @Input() searchType: 'customer' | 'article' | 'workflow';
  @Input() id: string | number | null | undefined;
  status: 1 | 2 | 3 | 4 | 5 = 1;
  success = false;
  categoriesData: FinderCategoriesData = {selected: new Set<Category>(), typ: 'archive'};
  additionalsData: FinderAdditionalData | null;
  additionals: Additional[] = [];
  guidData: string | null;

  constructor(private alertService: AlertService,
              private finderSearchService: FinderSearchService) {
  }

  onReverse(next: 'start' | 'category' | 'additional'): void {
    if (next === 'start') {
      this.categoriesData = {selected: new Set<Category>(), typ: 'archive'};
      this.additionalsData = null;
      this.additionals = [];
      this.guidData = null;
      this.success = false;
      this.status = 1;
    } else if (next === 'category') {
      this.status = 1;
    } else if (next === 'additional') {
      this.status = 2;
    }
  }

  onNextCategories(data: FinderCategoriesData): void {
    this.finderSearchService.additional({id: Array.from(data.selected).map((obj: any) => obj.id)}).subscribe(val => {
      this.categoriesData = data;
      this.additionals = val;
      this.status = 2;
    }, () => {
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten!');
    });
  }

  onNextAdditional(data: FinderAdditionalData): void {
    this.additionalsData = data;
    this.status = 3;
  }

  onNextFinish(data: string): void {
    this.guidData = data;
    this.status = 4;
    this.success = true;
  }

  onUpdateStatus(): void {
    this.status = 5;
  }
}
