import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FieldService} from '../model/field-service';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FieldServiceService {

  private uri = '/crm/v1/fieldservice/';

  constructor(private http: HttpClient) {
  }

  list(): Observable<FieldService[]> {
    return this.http.get<EnvisiaObjects<FieldService>>(this.uri).pipe(map(obj => obj.objects));
  }

  set(customerId: number, name: string): Observable<void> {
    return this.http.put<void>('/crm/v1/' + customerId + '/fieldservice/' + name + '/', {});
  }

  drop(customerId: number): Observable<void> {
    return this.http.delete<void>('/crm/v1/' + customerId + '/fieldservice/');
  }

  emails(fieldService: string): Observable<string[]> {
    return this.http.get<EnvisiaObjects<string>>('/crm/v1/fieldservice/emails/' + fieldService + '/').pipe(
      map(val => val.objects)
    );
  }

  // --- administrative functions

  create(data: { name: string, mails: string[] }): Observable<FieldService> {
    return this.http.post<EnvisiaObject<FieldService>>(this.uri, data).pipe(map(val => val.object));
  }

  save(name: string, data: { username: string, mails: string[] }): Observable<FieldService> {
    return this.http.put<EnvisiaObject<FieldService>>(this.uri + 'save/' + name + '/', data).pipe(
      map(val => val.object)
    );
  }

  remove(name: string): Observable<void> {
    return this.http.delete<void>(this.uri + name + '/');
  }

}
