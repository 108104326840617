import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BusinessOrderGoodpieces} from './business-order-view-goodpiece-box.models';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderViewGoodpieceService {

  constructor(private http: HttpClient) {
  }

  get(id: number, index: number): Observable<BusinessOrderGoodpieces> {
    return this.http.get<BusinessOrderGoodpieces>(`${environment.apiv4uri}business/order/goodpieces/${id}/${index}`);
  }
}
