import {BigCreateWorkflow} from '../models/workflow-create.models';
import { Input, Directive } from '@angular/core';
import {Subscription} from 'rxjs';
import {debug} from '../../../helper/debug.func';
import {WorkflowService} from '../workflow-services/workflow.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {UntypedFormGroup} from '@angular/forms';

@Directive()
export abstract class AbstractDetailFormComponent {
  @Input() data: BigCreateWorkflow;
  protected formContactSubscription?: Subscription;
  protected abstract workflowService: WorkflowService;
  protected abstract alertService: AlertService;

  envisiaOnInitContactForm(form: UntypedFormGroup): void {
    this.formContactSubscription = form.get('contact').valueChanges.subscribe(values => {
      debug('contact ValueChanges:', values);
      this.workflowService.update(this.data.workflow.object.id, {'contact_id': values ? values.id : null}).subscribe(() => {
        this.data.contact = values;
        this.alertService.add('success', 'Kontakt erfolgreich geändert!');
      }, () => {
        form.patchValue({'customer': this.data.contact}, {emitEvent: false});
        this.alertService.add('danger', 'Kontakt konnte nicht geändert werden!');
      });
    });
  }

  envisiaOnDesotryContactForm() {
    if (this.formContactSubscription) {
      this.formContactSubscription.unsubscribe();
    }
  }
}
