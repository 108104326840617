/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Article} from '../../article/models/article.models';
import {PriceBoxForm, PriceCheckData, ProcessGroup} from './price-box.models';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Subscription} from 'rxjs';
import {InitData} from '../../../common/init-data/init-data';
import {AlertService} from '../../../common/alert-service/alert.service';
import {PriceService} from '../services/price.service';
import {PriceHistoryForm, PriceMain} from '../price.models';
import {debounceTime} from 'rxjs/operators';
import {deepCopy} from '../../../helper/deep-copy';
import {changeset} from '../../../helper/changeset';
import {HttpErrorResponse} from '@angular/common/http';

const EMPTY_CHECK_DATA: PriceCheckData = {
  quanity: 0,
  german: null,
  china: null,
  calc_quantity_found: true,
  release: null
};

@Component({
  selector: 'price-box',
  templateUrl: './price-box.component.html',
})
export class PriceBoxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() calculation: { de: string, cn: string };
  @Input() processes: ProcessGroup;
  @Input() article: Article;
  @Output() updatePrices = new EventEmitter<PriceMain>();
  exchangeRate: string;
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};
  formSubscription?: Subscription = null;
  responseData = EMPTY_CHECK_DATA;
  lock = false;

  // locks calculation request until the last one is done
  blockSave = false;
  hasRelease = false;
  hasManualPriceDe = false;
  hasManualPriceCn = false;
  hasCustomCalcQuantity = false;

  /** cached object to calculate a changeset */
  private oldForm: PriceBoxForm;

  constructor(private localStorage: LocalStorage,
              private alertService: AlertService,
              private priceService: PriceService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.exchangeRate = this.localStorage.getObject<InitData>('data').exchange_rate;
    this.form = this.fb.group({
      quantity: [null],
      release: [null],
      /** DE Price */
      lose_de: [1],
      calc_de: [this.calculation.de ?? 'manuell'],
      calc_quantity_de: [null],
      price_de: [null],
      /** CN Price */
      exchange_rate: [this.exchangeRate],
      lose_cn: [1],
      calc_cn: [this.calculation.cn ?? 'manuell'],
      price_cn: [null],
    }, {updateOn: 'change'});
    this.oldForm = this.form.value as PriceBoxForm;

    // currently we need to check the form for valueChanges
    // because if a focus event happens the form gets revalidated and thus a valueChanges event is fired
    this.formSubscription = this.form.valueChanges
      .pipe(debounceTime(1800))
      .subscribe(() => {
        if (!this.blockSave) {
          this.calculate();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.calculation && !changes.calculation.isFirstChange()) {
      if (this.form) {
        this.form.patchValue({
          calc_de: this.calculation.de,
          calc_cn: this.calculation.cn,
        }, {emitEvent: !!this.oldForm});
      }
    }
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  save(typ: 'de' | 'cn'): void {
    let form: PriceHistoryForm = null;
    if (typ === 'de' && this.responseData.german) {
      form = {
        quanity: this.responseData.quanity,
        german: {
          release: this.responseData.release,
          lose: this.responseData.german.lose,
          calc: this.responseData.german.calc,
          calc_quanity: this.responseData.german.calc_quanity,
          price: this.responseData.german.price,
          total_price: this.responseData.german.total_price,
          last_price_id: this.responseData.german.last_price_id,
        }
      };
    } else if (typ === 'cn' && this.responseData.china) {
      form = {
        quanity: this.responseData.quanity,
        exchange_rate: this.responseData.china.exchange,
        china: {
          release: this.responseData.release,
          lose: this.responseData.china.lose,
          calc: this.responseData.china.calc,
          price: this.responseData.china.price,
          total_price: this.responseData.china.total_price,
          last_price_id: this.responseData.china.last_price_id,
        }
      };
    }
    if (form !== null) {
      this.priceService.save(this.article.oa_nr, form).subscribe(response => {
        this.updatePrices.next(response);
        this.alertService.add('success', 'Preis speichern, erfolgreich abgeschlossen!');
      }, () => {
        this.alertService.add('danger', 'Preis speichern, ist fehlgeschlagen!');
      });
    } else {
      this.alertService.add('danger', 'Preis konnte nicht gespeichert werden, da Kalkulation fehlgeschlagen ist!');
    }
  }

  calculate(force = false): void {
    const values = deepCopy(this.form.value) as PriceBoxForm;
    if (this.lock) {
      return;
    }

    const changes = changeset(values, this.oldForm);

    // When forced we remove the manual prices
    if (force) {
      values.price_de = null;
      values.price_cn = null;
    }

    if (!force && changes.length <= 0) {
      return;
    }

    this.lock = true;

    /**
     * Price calculation logic
     * quantity  (Menge)                      has to be entered first to allow any other changes to the form
     * release   ((freigabe) Menge)           is optional
     * The rest of the form will be filled in with a default value:
     * lose_de   (Lose (german))              1
     * calc_de:  (Kalk (german))              whatever is set as default de calculation
     * price_de: ((manueller) Einzel(preis))  manual price defaults to price of calculation
     * calc_quantity_de (Kalk-Me):
     * - if release is not empty it will use that as an amount
     * - if there are no surcharges (zuschläge) for that amount
     *   but a predefined kalk quantity exists, it will be chosen
     * - defaults to regular quantity otherwise
     *
     * exchange: (Kurs)                       default current exchange rate
     * lose_cn:  (Lose (china))               1,
     * calc_cn:  (Kalk (china))               whatever is set as default cn calculation
     * price_cn: ((manueller) Einzel(preis))  manual price defaults to price of calculation
     */

    let manualChangeDe = false;
    let manualChangeCn = false;
    if (changes.findIndex(c => c === 'calc_quantity_de') !== -1) {
      this.hasCustomCalcQuantity = true;
    }

    if (changes.findIndex(c => c === 'release') !== -1) {
      this.hasRelease = !!this.form.value.release;
      this.hasCustomCalcQuantity = false;
    }

    if (changes.findIndex(c => c === 'lose_de') !== -1) {
      this.hasCustomCalcQuantity = false;
    }

    if (changes.findIndex(c => c === 'price_de') !== -1) {
      this.hasManualPriceDe = true;
      manualChangeDe = true;
      values.calc_de = 'manuell';
    }

    if (changes.findIndex(c => c === 'price_cn') !== -1) {
      this.hasManualPriceCn = true;
      manualChangeCn = true;
      values.calc_cn = 'manuell';
    }

    if (force || changes.findIndex(c => c === 'quantity') !== -1) {
      this.hasCustomCalcQuantity = false;
      this.hasManualPriceDe = false;
      this.hasManualPriceCn = false;

      values.lose_de = 1;
      values.lose_cn = 1;
      if (this.oldForm?.calc_de === 'manuell') {
        values.calc_de = this.calculation?.de ?? 'manuell';
      }
      if (this.oldForm?.calc_cn === 'manuell') {
        values.calc_cn = this.calculation?.cn ?? 'manuell';
      }
    }

    values.calc_quantity_de = this.hasCustomCalcQuantity ? this.form.value.calc_quantity_de : null;

    this.priceService.calculate(this.article.oa_nr, values).subscribe(response => {
      const responseForm = {...values};
      this.responseData = {...response};

      // Changes are on german data
      if (this.responseData.german && !manualChangeCn) {
        responseForm.calc_de = values.calc_de;

        // remove price if it is not valid or if this is a manual price
        if (this.responseData.calc_quantity_found || manualChangeDe) {
          responseForm.price_de = this.responseData.german.price;
        } else {
          responseForm.price_de = null;
        }

        // remove calc quantity if a correct match has not been found
        if (this.responseData.calc_quantity_found && this.responseData.german.lose >= 1) {
          responseForm.calc_quantity_de = this.responseData.german.calc_quanity;
        } else {
          responseForm.calc_quantity_de = null;
        }

        // set correct calc quantity to response data as well
        this.responseData.german.calc_quanity = responseForm.calc_quantity_de;

        // remove german response data if calc quantity is missing, disabling save for german price
        if (!this.responseData.calc_quantity_found && !manualChangeDe) {
          this.responseData.german = null;
        }

        this.responseData.release = this.responseData.german?.release;
      }

      // changes are on china data
      if (this.responseData.china && !manualChangeDe) {
        responseForm.calc_cn = values.calc_cn;
        responseForm.price_cn = this.responseData.china.price;
        this.responseData.release = this.responseData.china.release;
      }

      if (manualChangeDe) {
        responseForm.calc_de = 'manuell';
        responseForm.calc_cn = values.calc_cn;
        // Changes are on german data only, reset china data
        this.responseData.china = !this.responseData.china ? null : {...this.responseData.china};
      }

      if (manualChangeCn) {
        responseForm.calc_cn = 'manuell';
        responseForm.calc_de = values.calc_de;
        // Changes are on china data only, reset german data
        this.responseData.german = !this.responseData.german ? null : {...this.responseData.german};
      }

      this.oldForm = responseForm;
      this.form.patchValue(this.oldForm, {emitEvent: false});
      if (response?.german?.has_valid_calc_quantity === true) {
        if (this.responseData.china) {
          this.responseData.china.calc_quanity = responseForm.calc_quantity_de;
        }
        this.form.get('calc_quantity_de').disable({emitEvent: false});
      } else {
        this.form.get('calc_quantity_de').enable({emitEvent: false});
      }

      this.alertService.add('success', 'Erfolgreich kalkuliert!');
      this.blockSave = false;
      this.lock = false;
    }, (error: HttpErrorResponse) => {
      console.error('Calculation Error', error);
      this.lock = false;
      this.alertService.add('danger', 'Fehler bei der Kalkulation');
      this.blockSave = false;
    });
  }

}
