import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContractForm} from '../../models/contract.models';
import {DeliveryDateError} from '../contract.models';
import {ContractCreateService} from '../services/contract-create.service';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';

@Component({
  selector: 'contract-create-delivery-date-error-modal',
  templateUrl: './contract-create-delivery-date-error-modal.component.html'
})
export class ContractCreateDeliveryDateErrorModalComponent {
  @Input() errors: DeliveryDateError[];
  @Input() form: ContractForm;
  @Input() workflowId: number;
  @Input() customerId: number;

  constructor(private activeModal: NgbActiveModal,
              private contractCreateService: ContractCreateService) {
  }

  close(): void {
    this.activeModal.dismiss();
  }

  force(clb?: LoadingButtonEvent): void {
    const callbackHook = () => {
      if (clb) {
        clb.callback(false);
      }
    };

    const successHook = () => {
      if (clb) {
        clb.callback(false);
      }
      this.activeModal.close();
    };

    const deliveryErrorsHook = (errors: DeliveryDateError[]) => {
      this.errors = errors;
    };

    this.contractCreateService.create(
      this.workflowId,
      this.customerId,
      true,
      this.form,
      deliveryErrorsHook,
      successHook,
      callbackHook
    );
  }

}
