<div>

  <h1>Preiserhöhung {{ uppercaseTyp }}</h1>

  <button type="button" class="btn btn-success btn-sm" (click)="openCreateModal()">
    Neue Preiserhöhung
  </button>

  <table class="whitetable">
    <thead>
    <tr>
      <th>Erhöhung</th>
      <th>Hinzugefügt</th>
      <th>Benutzer</th>
      <th>&nbsp;</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let obj of increases; let index = index">
      <td>{{ obj.percent }} %</td>
      <td>{{ obj.date_created }}</td>
      <td>{{ obj.username }}</td>
      <td>
        <button type="button" class="btn btn-link btn-sm" (click)="deletePriceIncrease(index, obj)">
          <i class="fa fa-trash-o"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

</div>
