import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {MaterialGroup} from './material-group.model';
import {Lister} from '../../../common/wrapper.models';

@Injectable({
  providedIn: 'root'
})
export class MaterialGroupListBoMaterialService {
  constructor(private http: HttpClient) {
  }

  list(): Observable<Lister<MaterialGroup>> {
    return this.http.get<Lister<MaterialGroup>>(`${environment.apiv4uri}material/groups/list/business/order`);
  }
}
