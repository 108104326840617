import {Component, Input, OnInit} from '@angular/core';
import {MailModalService} from '../../../mail/mail-modal.service';
import {EnvisiaFileService} from '../../../../common/envisia-file-button/envisia-file.service';
import {OfferService} from '../../workflow-services/offer.service';
import {BigWorkflowOffer} from '../../models/workflow-create.models';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {noop} from '../../../../helper/noop';
import {Offer, WorkflowDateText} from '../../models/offer.models';
import {debug} from '../../../../helper/debug.func';
import {OfferCreateForm} from './offer-create.models';

function prependArray<T>(array: T[], value: T): T[] {
  const newArray = array.slice(0);
  newArray.unshift(value);
  return newArray;
}

function buildDefaultObject(workflow: BigWorkflowOffer, dateText: WorkflowDateText): OfferCreateForm {
  const execution = workflow.article.data?.execution;
  const isMl = execution && execution.startsWith('ML');
  const position = workflow.workflow.positions[0];

  const deliveryTimeCn =
    (isMl ? position.delivery_time_ml_cn : position.delivery_time_cn) ?? dateText.delivery_time_global_cn;

  if (workflow.offer_list.length > 0) {
    const firstOffer = workflow.offer_list[0];
    return {
      de: firstOffer.de,
      cn: firstOffer.cn,
      number: false,
      date: new Date(),
      kanban: firstOffer.kanban,
      text: firstOffer.text,
      deliveryTimeDe: dateText.delivery_time_de ?? dateText.delivery_time_global_de,
      deliveryTimeCn: deliveryTimeCn,
    };
  }

  return {
    de: false,
    cn: false,
    number: true,
    date: new Date(),
    kanban: false,
    text: null,
    deliveryTimeDe: dateText.delivery_time_de ?? dateText.delivery_time_global_de,
    deliveryTimeCn: deliveryTimeCn,
  };
}

@Component({
  selector: 'offer-create',
  templateUrl: './offer-create.component.html',
  styles: [
    '.inline-div { display: inline; }',
  ]
})
export class OfferCreateComponent implements OnInit {
  @Input() data: BigWorkflowOffer;
  @Input() dateText: WorkflowDateText;

  errors: { [key: string]: any; } = {};
  form: UntypedFormGroup;
  creating = false;

  constructor(private offerService: OfferService,
              private mailModalService: MailModalService,
              private envisiaFileService: EnvisiaFileService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    debug('Offer Create Data:', this.data);

    const obj = buildDefaultObject(this.data, this.dateText);

    this.form = this.fb.group({
      'de': [obj.de],
      'cn': [obj.cn],
      'number': [obj.number],
      'date': [obj.date],
      'kanban': [obj.kanban],
      'text': [obj.text],
      'de_delivery_time': [obj.deliveryTimeDe],
      'cn_delivery_time': [obj.deliveryTimeCn],
    });
  }

  trackByFn(index: number, obj: Offer): number {
    noop(this, index);
    return obj.id;
  }

  mail(id: number) {
    this.mailModalService.open(
      id,
      'offer',
      null,
      null,
      'de',
      this.data.workflow.object.id,
      true
    ).subscribe(() => {
      this.data.offer_list.forEach(offer => {
        if (offer.id === id) {
          offer.status = 1;
        }
      });
    });
  }

  create(): void {
    this.creating = true;
    const formValues = this.form.value;
    this.offerService.create(this.data.workflow.object.id, formValues).subscribe(newResponse => {
      this.creating = false;
      if (!formValues.number && this.data.offer_list.length !== 0) {
        this.data.offer_list[0] = newResponse;
      } else {
        this.data.offer_list = prependArray(this.data.offer_list, newResponse);
      }
      this.envisiaFileService.open(newResponse.id, 'offer');
    }, (error) => {
      this.creating = false;
      console.log('Offer Create Error:', error);
      this.alertService.add('danger', 'Angebot konnte nicht erstellt werden, bitte überprüfen Sie Ihre Eingabe.');
    });
  }

}
