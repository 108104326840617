import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '@uirouter/angular';
import {ProductionArticleService} from '../production/production-article.service';
import {Observable} from 'rxjs';
import {Article, SmallArticle} from '../models/article.models';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {ConfigService} from '../../../lazy/config/config.service';
import {map} from 'rxjs/operators';
import {ArticleMultilayerPlan} from '../main/multilayer-plan/article-multilayer-plan.models';

@Component({
  selector: 'article-top-navigation',
  templateUrl: './article-top-navigation.component.html',
})
export class ArticleTopNavigationComponent implements OnInit, OnDestroy {
  @Input() workflowId?: number | string;
  @Input() article: Article | SmallArticle;
  @Input() multilayerPlan?: ArticleMultilayerPlan;
  private eventsSubscription: any;
  @Input() events?: Observable<void>;
  invalidHash: boolean;
  intranetLink: LoadingWrapper<string>;

  public state: StateService;

  constructor(private stateService: StateService,
              private articleProductionService: ProductionArticleService,
              private configService: ConfigService) {
    this.state = stateService;
  }

  ngOnInit(): void {
    this.checkHash();
    this.intranetLink = new LoadingWrapper<string>(
      this.configService.list().pipe(map(s => {
        const val = s['network.intranet.article']?.value;
        return (!val || val === '') ? null : val;
      }))
    );
    if (this.events) {
      this.eventsSubscription = this.events.subscribe(() => this.checkHash());
    }
  }

  ngOnDestroy() {
    if (this.events) {
      this.eventsSubscription.unsubscribe();
    }
  }

  private checkHash() {
    this.articleProductionService.checkHash(this.article.oa_nr).subscribe(value => {
      this.invalidHash = !value;
    }, () => {});
  }

  get isMl(): boolean {
    // Check article for multilayer
    const execution = this.article.data.execution;
    return execution && execution.startsWith('ML') && execution !== 'ML2';
  }

}
