import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceCorrectionCreateComponent} from './invoice-correction-create.component';
import {from} from 'rxjs';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class InvoiceCorrectionModalService {

  constructor(private modalService: NgbModal) {
  }

  open(): Observable<void> {
    const modalRef = this.modalService.open(InvoiceCorrectionCreateComponent, {windowClass: 'modal2-mm'});
    return from(modalRef.result);
  }

}
