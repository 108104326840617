import {Component, ErrorHandler, Input} from '@angular/core';
import {MaterialProductGroupsService} from './material-product-groups.service';
import {MaterialProductGroupListElement} from './material-product-groups.models';
import {Lister} from '../../../common/wrapper.models';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {noop} from '../../../helper/noop';
import {MaterialProductGroupsModalService} from './modals/material-product-groups-modal.service';
import {ignoreRejection} from '../../../helper/ignore_rejection';

@Component({
  selector: 'material-product-groups-list',
  templateUrl: './material-product-groups-list.component.html',
})
export class MaterialProductGroupsListComponent
  extends SearchBaseComponent<MaterialProductGroupsService, MaterialProductGroupListElement> {
  @Input() protected listData: Lister<MaterialProductGroupListElement>;
  protected type = 'material-product-groups-list';

  constructor(protected service: MaterialProductGroupsService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              private modalService: MaterialProductGroupsModalService) {
    super();
  }

  openCreate(): void {
    this.modalService.openCreateModal().subscribe(() => {
      this.reload();
    });
  }

  openUpdate(item: MaterialProductGroupListElement): void {
    this.modalService.openEditModal(item.product_group).subscribe(() => {
      this.reload();
    });
  }

  openSelection(item: MaterialProductGroupListElement): void {
    this.modalService.openSelectionModal(item.product_group).subscribe(() => {
      this.reload();
    }, () => {
      this.reload();
    });
  }

  doDelete(item: MaterialProductGroupListElement): void {
    if (!confirm(
      `Soll die Produkt Gruppe ${item.product_group.id} "${item.product_group.name}" wirklich gelöscht werden?`
    )) {
      return;
    }

    this.service.delete(item.product_group.id).subscribe(() => {
      this.reload();
    });
  }

  trackByFn(index: number, item: MaterialProductGroupListElement): number {
    noop(this);
    return item.product_group.id;
  }
}
