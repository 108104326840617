import {Component, Input, OnInit} from '@angular/core';
import {ArticleOverviewService} from './article-overview.service';
import {ArticleOverview} from './article-overview.model';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';

@Component({
  selector: 'order-request-article-overview',
  templateUrl: './order-request-article-overview.component.html',
})
export class OrderRequestArticleOverviewComponent implements OnInit {
  @Input() oaNr?: string;
  @Input() whitebox = true;
  articleOverview: LoadingWrapper<ArticleOverview>;

  constructor(private aos: ArticleOverviewService) { }

  ngOnInit(): void {
    this.articleOverview = new LoadingWrapper<ArticleOverview>(this.aos.get(this.oaNr));
  }

}
