import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {ArticleDocumentComponent} from './article-document.component';
import {ArticleNavigationComponent} from '../navigiation/article-navigation.component';
import {ArticleService} from '../services/article.service';
import {FinderSearchService} from '../../finder/finder-search.service';
import {ArticleDocumentNewComponent} from './article-document-new.component';
import {getArticleMultilayerPlanResolveFn} from '../article.states';
import {ArticleMultilayerPlanService} from '../main/multilayer-plan/article-multilayer-plan.service';

export const ARTICLE_DOCUMENT_STATE: Ng2StateDeclaration = {
  name: 'a.article.document',
  url: '/{id}/document',
  abstract: true,
  data: {requiresAuth: true}
};

export function articleDetailResolveFn(transition: Transition, service: ArticleService) {
  return service.detail(transition.params().id).toPromise();
}

export function finderSearchResolveFn(transition: Transition, service: FinderSearchService) {
  return service.search('article', transition.params().id).toPromise();
}

export const ARTICLE_DOCUMENT_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.article.document.list',
  url: '/list?workflow_id',
  views: {
    'navigation@a': {component: ArticleNavigationComponent},
    'main@a': {component: ArticleDocumentComponent}
  },
  resolve: [
    {token: 'search', resolveFn: finderSearchResolveFn, deps: [Transition, FinderSearchService]},
    {token: 'article', resolveFn: articleDetailResolveFn, deps: [Transition, ArticleService]},
    {
      token: 'multilayerPlan',
      resolveFn: getArticleMultilayerPlanResolveFn,
      deps: [Transition, ArticleMultilayerPlanService],
    },
  ]
};

export function finderResolveFn(service: FinderSearchService) {
  return service.category().toPromise();
}

export const ARTICLE_DOCUMENT_NEW_STATE: Ng2StateDeclaration = {
  name: 'a.article.document.new',
  url: '/new',
  views: {
    'navigation@a': {component: ArticleNavigationComponent},
    'main@a': {component: ArticleDocumentNewComponent}
  },
  resolve: [
    {token: 'category', resolveFn: finderResolveFn, deps: [FinderSearchService]},
    {token: 'article', resolveFn: articleDetailResolveFn, deps: [Transition, ArticleService]}
  ]
};

export const ARTICLE_DOCUMENT_STATES: Ng2StateDeclaration[] = [
  ARTICLE_DOCUMENT_STATE,
  ARTICLE_DOCUMENT_LIST_STATE,
  ARTICLE_DOCUMENT_NEW_STATE
];
