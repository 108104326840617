<div class="row">
  <div class="col-sm-5"><h2>Planungen</h2></div>
  <div class="col-sm-7">
    <a class="click" uiSref="a.business_order.list" target="_blank" [uiParams]="linkParams">BA Liste öffnen</a>
  </div>
</div>
<table class="table whitetable whitetable-borderless">
  <tr>
    <th>Start</th>
    <th class="align-right">Bedarf</th>
    <th>SDS Nr.</th>
  </tr>
  <ng-container *ngFor="let relation of list.objects">
    <tr>
      <td>{{relation.business_order.start_date|date:'dd.MM.yyyy'}}</td>
      <td class="align-right">{{relation.amount|money}}</td>
      <td>{{relation.article.sds_nr}}</td>
    </tr>
  </ng-container>
</table>
<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
