import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  ShippingInstructionForm,
  ShippingInstructionHolder,
  ShippingInstructionSchema,
  ShippingInstructionType
} from '../models/article-extra.models';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ShippingInstructionService {

  constructor(private http: HttpClient) {
  }

  schema(old: boolean = false): Observable<ShippingInstructionSchema[]> {
    const options = old ? {params: new HttpParams({fromObject: {'old': 'true'}})} : {};
    return this.http.get<{ objects: ShippingInstructionSchema[] }>(
      environment.apiv4uri + 'shipping-instruction/schema',
      options
    ).pipe(
      map(val => val.objects)
    );
  }

  get<T>(type: ShippingInstructionType, item: string): Observable<ShippingInstructionHolder<T>> {
    return this.http.get<ShippingInstructionHolder<T>>(
      environment.apiv4uri + 'shipping-instruction/' + type + '/' + item
    );
  }

  save<T>(type: ShippingInstructionType,
          item: string,
          data: ShippingInstructionForm): Observable<ShippingInstructionHolder<T>> {
    return this.http.put<ShippingInstructionHolder<T>>(
      environment.apiv4uri + 'shipping-instruction/' + type + '/' + item,
      data
    );
  }

  delete<T>(type: ShippingInstructionType, item: string, section: string): Observable<ShippingInstructionHolder<T>> {
    return this.http.delete<ShippingInstructionHolder<T>>(
      environment.apiv4uri + 'shipping-instruction/' + type + '/' + item + '/' + section
    );
  }

}
