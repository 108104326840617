import {Component} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ErrorSharedComponent} from './error-shared.component';

@Component({
  selector: 'error500',
  templateUrl: './error500.component.html'
})
export class Error500Component extends ErrorSharedComponent {

  constructor(protected authService: AuthService) {
    super();
  }
}
