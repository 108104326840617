<div class="modal-header">
  <h3 class="modal-title">Fertigungsliste neu erstellen</h3>
</div>

<form class="form-horizontal" [formGroup]="form">
  <div class="modal-body">
    <div>
      Alle Fertigungsschritte aus Liste entfernen und neue Fertigungsschritte gemäß Ausführung:
      <strong>{{article.data.execution}}</strong> erstellen?
    </div>
    <div class="form-group" *ngIf="hasBa">
      <div class="col-sm-12">
        <div class="red_alert">
          <div class="row">

            <div class="col-sm-2">
              <input id="ignore_existing_ba"
                     type="checkbox"
                     class="form-control"
                     formControlName="ignore_existing_ba">
            </div>

            <div class="col-sm-10">
              <label for="ignore_existing_ba">
                <p>
                  <strong>Achtung:</strong>
                  Es sind Betriebsaufträge mit dieser Fertigungsliste in Produktion.
                  Bei der Neuerstellung der Liste geht die Verbindung zu den aktiven Betriebsaufträgen verloren.
                  Somit können keine Info- oder Sperrtexte für aktive Betriebsaufträge gesetzt werden.
                </p>
                <p>
                  <strong>Fertigungsliste wirklich neu erstellen?</strong>
                </p>
              </label>
            </div>
          </div>

          <a uiSref="a.business_order.list" target="_blank" [uiParams]="{sds_nr: article.sds_nr, status: 1}">
            Liste mit Betriebsaufträgen in Produktion mit diesem Artikel öffnen
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">

    <button class="btn btn-default pull-left" type="button" (click)="dismiss()">
      Abbrechen
    </button>

    <loading-button clazz="btn btn-success pull-right"
                    (loadingSave)="submit($event)"
                    [disabled]="(form.value.ignore_existing_ba === false)">
      Fertigungsliste neu erstellen
    </loading-button>

  </div>
</form>
