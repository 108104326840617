<ng-container *ngIf="type === 'dropdown'">
  <a [uiSref]="landingPage" *ngIf="!!landingPage">
    <button type="button" class="dropdown-item">
      <div class="icon">
        <i class="fa fa-qrcode"></i>
      </div>
      <div class="icon-label">
        Artikelverwaltung
      </div>
    </button>
  </a>
</ng-container>

<ng-container *ngIf="type === 'base'">
  <li *ngIf="!!landingPage">
    <a [uiSref]="landingPage">
      <div class="icon">
        <i class="fa fa-qrcode"></i>
      </div>
      <div class="icon-label">
        Artikelverwaltung
      </div>
    </a>
  </li>
</ng-container>
