<div class="modal-header">
  <h3 class="modal-title">Kunde auswählen</h3>
</div>

<div class="modal-body">
  <div class="task-customersearch">

    <form name="customer-search" class="task-customersearch-form" [formGroup]="form">
      <label>
        <input type="text" class="form-control ng-pristine ng-valid ng-touched" id="id_customer" name="name"
               formControlName="name" autofocus="autofocus" [inputSelect]>
      </label>
    </form>
    <div class="whitelist customerlist">
      <div class="whitelist-item" (click)="select(customer)"
           [ngClass]="{selected: selected === customer.name}"
           *ngFor="let customer of customers">
        <h4>
          {{ customer.name }}
          <span class="label label-warning" *ngIf="customer.locked">Gesperrt</span>
        </h4>
        <p>{{ customer.street }}</p>
        <p>{{ customer.zip }} {{ customer.city }}</p>
        <p>{{ customer.country }}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-default pull-left" (click)="cancel()">
    Abbrechen
  </button>
  <button class="btn btn-success pull-right" *ngIf="selected" (click)="ok()">
    Kunde wechseln
  </button>
</div>
