<div class="modal-header">
  <h3 id="modal-title">Wareneingang erfassen</h3>
</div>

<form name="form" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="form-group row">
      <label class="col-md-4" for="date_received">Wareneingang:</label>
      <div class="col-sm-8">
        <ev-datepicker id="date_received" class="form-control" allDates="true" formControlName="date_received"
                       [evClass]="{red_alert: errors['obj.date_received']}"></ev-datepicker>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-4" for="charge">Charge:</label>
      <div class="col-sm-8">
        <input id="charge" type="text" class="form-control" formControlName="charge"
               [ngClass]="{red_alert: errors['obj.charge']}">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-4" for="price">Preis:</label>
      <div class="col-sm-8">
        <input id="price" type="text" class="form-control" formControlName="price"
               [ngClass]="{red_alert: errors['obj.price']}">
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">
        <label for="amount" class="control-label">Menge:</label>
      </div>
      <div class="col-md-8">
        <input id="amount" class="form-control" type="number" formControlName="amount" min="1"
               [ngClass]="{red_alert: errors['obj.amount']}">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-4" for="location">Lagerplatz:</label>

      <div class="col-md-8">
        <input id="location" class="form-control" type="text" formControlName="location"
               [ngClass]="{red_alert: errors['obj.location']}">
      </div>
    </div>

    <div class="form-group row" *ngIf="konsi && 'material.konsi.change'|HasPerm">
      <label class="col-md-4" for="is_konsi">Bestands-Art:</label>

      <div class="col-md-8">
        <material-inventory-konsi-switch id="is_konsi" [isKonsiArticle]="konsi" [transactionForm]="form">
        </material-inventory-konsi-switch>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">
        <label for="note" class="control-label">Notiz</label>
      </div>
      <div class="col-md-8">
        <input id="note" type="text" class="form-control" formControlName="note"
               [ngClass]="{red_alert: errors['obj.note']}">
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">&nbsp;</div>
    </div>

    <material-inventory-transaction-error [errors]="errors"></material-inventory-transaction-error>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Abbrechen
    </button>

    <button type="submit" class="btn btn-success pull-right" [disabled]="lockSubmission || !form.valid">
      Bestand einbuchen
    </button>
  </div>
</form>
