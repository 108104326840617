import {Component, Input, OnInit} from '@angular/core';
import {FinderSearchBothAbstractComponent} from './finder-search-both.abstract-component';
import {FinderSearchService} from '../finder-search.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {FinderSearchItemList} from '../finder.models';

@Component({
  selector: 'finder-search-left',
  templateUrl: './finder-search-left.component.html',
})
export class FinderSearchLeftComponent extends FinderSearchBothAbstractComponent implements OnInit {
  @Input() initial: FinderSearchItemList;

  constructor(protected finderSearchService: FinderSearchService,
              protected alertService: AlertService) {
    super();
  }

  ngOnInit(): void {
    this.data = this.initial;
  }

}
