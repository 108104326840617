import {Component, Input} from '@angular/core';

@Component({
  selector: 'material-order-position-status-label',
  templateUrl: './material-order-position-status-label.component.html'
})
export class MaterialOrderPositionStatusLabelComponent {
  @Input() status: number;
  @Input() deleted?: boolean | null | undefined = false;

  constructor() {
  }
}
