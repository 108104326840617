import {AxiosHttpClient} from '../../../auth/axios-http-client';
import {environment} from '../../../../../environments/environment';
import {EnvisiaObjects} from '../../../../common/wrapper.models';
import {OfferAnalysisGroup, OfferAnalysisQueryParams} from './offer-analysis.model';

export class OfferAnalysisAxiosService {
  static get(params: OfferAnalysisQueryParams): Promise<EnvisiaObjects<OfferAnalysisGroup>> {
    return AxiosHttpClient.get<EnvisiaObjects<OfferAnalysisGroup>>(
      `${environment.apiv4uri}offers/analysis`,
      params,
    );
  }
}
