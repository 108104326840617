<div class="whitelist whitelist-borderless" *ngFor="let step of steps; trackBy: trackByFn; let index = index">
  <business-order-view-step-list-element [ba]="ba"
                                         [step]="step"
                                         [stepPrevious]="(index > 0) ? steps[index - 1] : null"
                                         [stepNext]="((index + 1) < steps.length) ? steps[index + 1] : null"
                                         [reproduction]="reproduction"
                                         [filmInventory]="filmInventory"
                                         (updateGoodPiece)="updateGoodPiece.emit()"
                                         (updateData)="updateData.emit()"
                                         (updateReproduction)="updateReproduction.emit($event)">
  </business-order-view-step-list-element>
</div>



