import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {FETCH_STATES} from './fetch.states';
import {FetchListComponent} from './fetch-list.component';
import {WorkflowNavigationModule} from '../navigation/workflow-navigation.module';
import {FetchCamListComponent} from './fetch-cam-list.component';
import {FetchCamArticleStateComponent} from './fetch-cam-article-state.component';
import {FetchStatusChangeModalComponent} from './modal/fetch-status-change-modal.component';

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({ states: FETCH_STATES }),
        WorkflowPipesModule,
        WorkflowNavigationModule,
    ],
    declarations: [
        FetchListComponent,
        FetchCamListComponent,
        FetchCamArticleStateComponent,
        FetchStatusChangeModalComponent
    ]
})
export class FetchModule {
}
