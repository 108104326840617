import {NgModule} from '@angular/core';
import {OposHistoryModalModule} from './history/opos-history-modal.module';
import {SharedModule} from '../../core/shared.module';
import {OPOS_LIST_STATE} from './list/opos-list.state';
import {UIRouterModule} from '@uirouter/angular';
import {OposListComponent} from './list/opos-list.component';

@NgModule({
  imports: [
    SharedModule,
    OposHistoryModalModule,
    UIRouterModule.forChild({states: [OPOS_LIST_STATE]}),
  ],
  declarations: [
    OposListComponent
  ]
})
export class OposModule {
}
