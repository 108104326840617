<div class="modal-header">
  <h3 id="modal-title">Artikel Vorschau - 0A-Nr.: {{ data.oa }}</h3>
</div>

<div class="modal-body yellowbox-no-border">

  <ul *ngFor="let data of data.change_list; trackBy: trackByFn" style="list-style-type: none; margin: 0; padding: 0;">
    <li>- {{ data }}</li>
  </ul>

</div>

<div class="modal-footer">
</div>
