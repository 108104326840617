<table class="table whitetable tablerighthead">
  <thead>
  <tr>
    <th style="text-align: left;">Pos.</th>
    <th style="text-align: left;">Bezeichnung</th>
    <th>Menge</th>
    <th>Lose</th>
    <th>Kalk-Menge</th>
    <th>Freigabe</th>
    <th class="bold">Einzel DE</th>
    <th>Gesamt DE</th>
    <th>Notiz</th>
    <th>Kalk Lose</th>
    <th>Freigabe</th>
    <th>Einkauf CHN</th>
    <th class="bold">Einzel CHN</th>
    <th>Gesamt CHN</th>
    <th>Notiz</th>
    <th style="width: 90px;">&nbsp;</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let position of positions">

    <td>
      <label style="display: inline-block">
        {{ position.nr }}.
      </label>
    </td>
    <td>
      <label style="width: 100%;">
        {{ position.description }}
      </label>
      <br>
      <label style="width: 100%;">
        {{ position.desextension }}
      </label>
    </td>
    <td>
      <label>
        {{ position.quanity }}
      </label>
    </td>
    <td class="storzgreen">
      <label>
        {{ position.lose_de }}
      </label>
    </td>
    <td class="storzgreen">
      <label>
        {{ position.calc_quanity }}
      </label>
    </td>
    <td class="storzgreen">{{ position.release_de }}</td>
    <td class="storzgreen">
      <label>
        {{ position.de.price|euro }}
      </label>
    </td>
    <td class="storzgreen_dark right">
      <label>
        {{ position.de.price_total|euro }}
      </label><br>
      <label style="font-size: 10px;">
        {{ position.calc_de }}
      </label>
    </td>
    <td>{{ position.notes_de }}</td>
    <td class="storzblue">
      <label>
        {{ position.lose_cn_visible }}
      </label>
    </td>

    <td class="storzblue">{{ position.release_cn }}</td>
    <td class="storzblue">
      <label>
        {{ position.cn.price_buy|euro }}
      </label>
    </td>
    <td class="storzblue">
      <label>
        {{ position.cn.price|euro }}
      </label>
      <label style="font-size: 10px;display: block;text-align: right; padding-right: 20px;"
             *ngIf="position.typ === 'article'">
        {{position.production_cn}}
      </label>
    </td>
    <td class="storzblue_dark right">
      <div *ngIf="position.typ === 'article'">
        <div class="pull-left">EK:</div>
        <div class="pull-right">
          {{ position.cn.price_buy_total|euro }}
        </div>
      </div>
      <div class="clearfix" *ngIf="position.typ === 'article'"></div>
      <div>
        <div class="pull-left" *ngIf="position.typ === 'article'">VK:</div>
        <div class="pull-right">
          <div *ngIf="position.cn.price_total" style="font-weight: bold; display: inline;"
               [ngClass]="{'red-font': position.cn.red}">
            {{ position.cn.price_total|euro }}
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div>
        <div class="pull-left">&nbsp;</div>
        <div class="pull-right" style="display: block; text-align: right; font-size: 10px;">
          {{ position.calc_cn }}
        </div>
      </div>
    </td>
    <td>{{ position.notes_cn }}</td>
    <td style="padding: 0; text-align: center; vertical-align: middle;font-size: 19px;">
    </td>

  </tr>
  </tbody>
</table>
