import * as React from 'react';
import {
  OfferAnalysisMap, OfferAnalysisParamHelper,
  OfferAnalysisQueryParams,
  OfferAnalysisStateTriplet,
} from './offer-analysis.model';
import {OfferAnalysisElement} from './offer-analysis-element';

interface Props {
  groupName: string;
  groupLabel: string;
  groups: OfferAnalysisMap;
  query: OfferAnalysisQueryParams;
  queryModification: (e: OfferAnalysisStateTriplet, query: OfferAnalysisQueryParams) => OfferAnalysisParamHelper;
  queryActive: (e: OfferAnalysisStateTriplet, query: OfferAnalysisQueryParams) => boolean;
  reloadList: (query: OfferAnalysisQueryParams) => void;
}

export function OfferAnalysisGroup(props: Props): JSX.Element {
  const onSelect: (e: OfferAnalysisStateTriplet) => void = (e) => {
    const query: OfferAnalysisQueryParams = {...props.query};
    const {param, value} = props.queryModification(e, props.query);
    query[param] = value;
    props.reloadList(query);
  };

  return <>
    <table className="table whitetable">
      <thead>
      <tr>
        <th><b>{props.groupLabel}</b></th>
        <th className="align-right" colSpan={3}>Gesamt</th>
        <th className="align-right" colSpan={3}>Bestand</th>
        <th className="align-right" colSpan={3}>Neue Artikel</th>
      </tr>
      </thead>
      <tbody>
      {
        props.groups[props.groupName].map(e =>
          <OfferAnalysisElement key={e.name}
                                parameterName={e.name}
                                element={e}
                                queryActive={props.queryActive(e, props.query)}
                                onSelect={() => onSelect(e)}/>
        )
      }
      </tbody>
    </table>
  </>;
}
