import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useErpLocation} from '../../../../react/hooks/use-erp-location';
import {OfferAnalysisParamHelper} from './offer-analysis-param-helper';
import {OfferAnalysisQueryParams, OfferAnalysisState} from './offer-analysis.model';
import {OfferAnalysisView} from './offer-analysis-view';
import {BroadcastChannel} from 'broadcast-channel';
import {OfferAnalysisAxiosService} from './offer-analysis-axios.service';
import {OfferAnalysisMapHelper} from './offer-analysis-map-helper';
import {EvPageLoadingSpinner} from '../../../../react/components/ev-page-loading-spinner';

interface Props {
  query: OfferAnalysisQueryParams;
  channelId: string;
}

export function OfferAnalysisRoot(props: Props): JSX.Element {
  const [setLocation] = useErpLocation();
  const [state, setState] = useState<OfferAnalysisState>({query: props.query, groups: null});
  const query = useRef<OfferAnalysisQueryParams>(props.query);
  const channelRef = useRef<BroadcastChannel<OfferAnalysisQueryParams>>();

  const reloadList: (q: OfferAnalysisQueryParams, external?: boolean, force?: boolean) => void = async (q, e, f) => {
    const cleanQuery: OfferAnalysisQueryParams = OfferAnalysisParamHelper.clean(q);
    // If the query hasn't changed we don't need to re-query
    if (OfferAnalysisParamHelper.match(query.current, cleanQuery) && !f) {
      return;
    }

    try {
      const data = await OfferAnalysisAxiosService.get(cleanQuery);
      query.current = cleanQuery;
      setState({query: cleanQuery, groups: OfferAnalysisMapHelper.generateMap(data.objects)});

      // As the state service does not recognize the difference between nullable types,
      // we backfill with null for unused values
      setLocation('a.workflow.o.analysis', OfferAnalysisParamHelper.backfill(cleanQuery), {reload: false});
    } catch (e) {
      console.error('could not fetch workflow analysis', e);
    }

    if (!e) {
      try {
        await channelRef.current?.postMessage(cleanQuery);
      } catch (e) {
        console.error('could not write message to broadcast', e);
      }
    }
  };

  useEffect(() => {
    reloadList(props.query, false, true);
    channelRef.current = new BroadcastChannel<OfferAnalysisQueryParams>(
      `offer-analysis-inter-window-interactivity-${props.channelId}`
    );

    channelRef.current.addEventListener('message', params => {
      reloadList(params, true);
    });

    return (() => {
      channelRef.current?.close()?.then(() => {
      });
    });
  }, []);

  if (!state.groups) {
    return <EvPageLoadingSpinner />;
  } else {
    return <OfferAnalysisView groups={state.groups}
                              query={state.query}
                              reloadList={reloadList}/>;
  }
}
