<div class="filter-row">
  <div class="filter-left">
    <button class="btn btn-warning btn-sm"
            style="margin-right: 10px;"
            type="button" (click)="toggleOverdue()">
      Überfällig <i class="fa"
                    [ngClass]="{'fa-toggle-on': toggleOverdueState, 'fa-toggle-off': !toggleOverdueState}"></i>
    </button>

    <button class="btn btn-rose btn-sm"
            type="button"
            style="margin-right: 10px;"
            (click)="badge('50')"
            [ngClass]="{active: q('50')}">
      Offen
    </button>

    <button class="btn btn-default btn-sm"
            type="button"
            (click)="badge('0')"
            [ngClass]="{active: q('0')}">
      Bestellt
    </button>
    <button class="btn btn-info btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
      Bestätigt
    </button>
    <button class="btn btn-warning btn-sm" type="button"
            [ngClass]="{active: q('2')}"
            [disabled]="toggleOverdueState"
            (click)="badge('2')">
      Gebucht
    </button>
    <button class="btn btn-success btn-sm" type="button"
            [ngClass]="{active: q('3')}"
            [disabled]="toggleOverdueState"
            (click)="badge('3')">
      Geprüft
    </button>
    <button class="btn btn-default btn-sm" type="button" (click)="badge('all')">
      Alle
    </button>
    <button class="btn btn-danger btn-sm" type="button"
            [ngClass]="{active: q('100')}"
            [disabled]="toggleOverdueState"
            (click)="badge('100')">
      Gelöscht
    </button>
    &nbsp;
    <button type="button" class="btn btn-sm btn-default" (click)="excelDownload()"
            title="Liste als Excel Datei exportieren">
      <i class="fa fa-download"></i>
    </button>
  </div>
</div>
<div class="filter-row">
  <div class="filter-left">
    <material-product-groups-tab-list [productGroups]="productGroups.objects"
                                      [groups]="groups.objects"
                                      [activeProductGroupId]="activeProductGroupId"
                                      [activeGroupIds]="activeGroupIds"
                                      (setProductGroupId)="setProductGroupId($event)"
                                      (setGroupIds)="setGroupIds($event)">
    </material-product-groups-tab-list>
  </div>
</div>
<div>
  <form #changeForm="ngForm" name="form">
    <table class="table whitetable">
      <thead>
      <tr>
        <th class="align-right" style="width: 90px;">
          <a (click)="sort($event, 'order_id', 'DESC')" [href]="sortUrl('order_id', 'DESC')">Bestell Nr</a>
        </th>
        <th style="width: 120px;">
          <a (click)="sort($event, 'status', 'DESC')" [href]="sortUrl('status', 'DESC')">Status</a>
        </th>
        <th class="align-right">
          <a (click)="sort($event, 'index', 'ASC')" [href]="sortUrl('index', 'ASC')">Pos.</a>
        </th>
        <th class="align-right">
          <a (click)="sort($event, 'delivery_index', 'ASC')" [href]="sortUrl('delivery_index', 'ASC')">Lief.</a>
        </th>
        <th><a (click)="sort($event, 'supplier_name', 'ASC')" [href]="sortUrl('supplier_name', 'ASC')">Lieferant</a>
        </th>
        <th>
          <a (click)="sort($event, 'vendor_article_id', 'ASC')" [href]="sortUrl('vendor_article_id', 'ASC')">
            Art. Nr. Lieferant
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'article_name', 'ASC')" [href]="sortUrl('article_name', 'ASC')">Bezeichnung</a>
        </th>
        <th>
          <a (click)="sort($event, 'delivery_id', 'ASC')" [href]="sortUrl('delivery_id', 'ASC')">Lief. AB Nr.</a>
        </th>
        <th>
          <a (click)="sort($event, 'date_created', 'ASC')" [href]="sortUrl('date_created', 'ASC')">Bestelldatum</a>
        </th>
        <th class="align-right">
          <a (click)="sort($event, 'amount', 'ASC')" [href]="sortUrl('amount', 'ASC')">Bestellte Menge</a>
        </th>
        <th>
          <a (click)="sort($event, 'date_delivery', 'ASC')" [href]="sortUrl('date_delivery', 'ASC')">Lieferdatum</a>
        </th>
        <th>
          Int. Notiz
        </th>
        <th colspan="4"></th>
      </tr>

      <tr class="filterrow">
        <th>
          <input class="form-control input-sm"
                 name="order_id"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.order_id }"
                 [(ngModel)]="data['order_id']">
        </th>
        <th colspan="3"></th>
        <th>
          <select class="form-control input-sm"
                  name="supplier_id"
                  [ngClass]="{ 'red_alert': !!filterFormErrors.supplier_id }"
                  [(ngModel)]="data['supplier_id']">
            <option [ngValue]="null" selected>&nbsp;</option>
            <option [ngValue]="supplier.id.toString()" *ngFor="let supplier of suppliers">
              {{supplier.name}}
            </option>
          </select>
        </th>
        <th>
          <input class="form-control input-sm"
                 name="vendor_article_id"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.vendor_article_id }"
                 [(ngModel)]="data['vendor_article_id']">
        </th>
        <th>
          <input class="form-control input-sm"
                 name="article_name"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.article_name }"
                 [(ngModel)]="data['article_name']">
        </th>
        <th>
          <input class="form-control input-sm"
                 name="delivery_id"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.delivery_id }"
                 [(ngModel)]="data['delivery_id']">
        </th>
        <th>
          <ev-datepicker name="date_created"
                         class="form-control input-sm"
                         [allDates]="true"
                         [coreFormat]="true"
                         [ngClass]="{ 'red_alert': !!filterFormErrors.date_created }"
                         [(ngModel)]="data.date_created">
          </ev-datepicker>
        </th>
        <th></th>
        <th>
          <ev-datepicker name="date_delivery"
                         class="form-control input-sm"
                         [allDates]="true"
                         [coreFormat]="true"
                         [ngClass]="{ 'red_alert': !!filterFormErrors.date_delivery }"
                         [(ngModel)]="data.date_delivery">
          </ev-datepicker>
        </th>
        <th colspan="5">

        </th>
      </tr>
      </thead>

      <tbody>
      <tr [material-order-position-list-row]="position"
          (material-order-position-list-row-update)="reload()"
          *ngFor="let position of list.objects; let index = index"></tr>
      </tbody>
    </table>
  </form>
  <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
</div>
