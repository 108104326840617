import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialOrder, MaterialOrderIntakeGroupElement} from '../material-order.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MaterialOrderService} from '../material-order.service';
import {Comment} from '../../../comment/comment.models';
import {Commentv2Service} from '../../../commentv2/commentv2.service';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {Lister} from '../../../../common/wrapper.models';
import {PaginationBaseComponent} from '../../../../common/pagination/pagination.component';
import {MaterialOrderUpdateService} from './material-order-update.service';
import {noop} from '../../../../helper/noop';
import {MaterialGroup} from '../../groups/material-group.model';

@Component({
  selector: 'material-order-update',
  templateUrl: './material-order-update.component.html'
})
export class MaterialOrderUpdateComponent
  extends PaginationBaseComponent<MaterialOrderUpdateService, MaterialOrderIntakeGroupElement>
  implements OnInit {
  @Input() materialOrder: MaterialOrder;
  @Input() list: Lister<MaterialOrderIntakeGroupElement>;
  @Input() markedPositionId?: number;
  @Input() groups: Lister<MaterialGroup>;

  form: UntypedFormGroup;
  commentLoader: LoadingWrapper<Lister<Comment>>;

  constructor(protected service: MaterialOrderUpdateService,
              private mos: MaterialOrderService,
              private commentService: Commentv2Service,
              private alertService: AlertService,
              private modalRef: NgbActiveModal,
              private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.updateComments();
    this.form = this.fb.group({
      delivery_id: [this.materialOrder.delivery_id],
    });
    this.query({'order_id': this.materialOrder.id.toString()});
  }

  submit(): void {
    this.mos.update(this.materialOrder.id, this.form.value).subscribe(order => {
      if (order) {
        this.form.patchValue({delivery_id: order.delivery_id});
      }
      this.alertService.add('success', 'Bestellung wurde gespeichert');
      this.updateComments();
    });
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

  updateComments(): void {
    this.commentLoader = new LoadingWrapper<Lister<Comment>>(
      this.commentService.list({typ: 'material_order', item: this.materialOrder.id.toString()})
    );
  }

  copyEvent(): void {
    this.updateComments();
    this.reload();
  }

  trackBy(id: number, item: MaterialOrderIntakeGroupElement): number {
    noop(this);
    return item.group.id;
  }
}
