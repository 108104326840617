import {DateHelper} from './date-helper';

export class DateCode {
  readonly year: number;
  readonly week: number;

  constructor(year: number, week: number) {
    this.year = year;
    this.week = week;
  }

  /**
   * See https://en.wikipedia.org/wiki/ISO_week_date#Calculating_an_ordinal_or_month_date_from_a_week_date
   * and MR !2730 for a better algorithm description.
   * 1.    Multiply the week number @week by 7.
   * 2.    Then add the weekday number @day.
   * 3.    From this sum subtract the correction for the year:
   * Get the weekday of the 4th of January and add 3.
   * 4.    The result is the ordinal date, which can be converted into a calendar date.
   * If the ordinal date thus obtained is zero or negative, the date belongs to the previous calendar year;
   * if it is greater than the number of days in the year, it belongs to the following year.
   * @param day   day of the week
   */
  toDate(day?: number): Date {
    const fourthDayOfYear = new Date(this.year, 0, 4);
    // we add 2 to the weekday of the fourth day, as js is zero based and starts with sunday
    const days = (day ?? 0) + this.week * 7 - (fourthDayOfYear.getDay() + 2);
    return new Date(this.year, 0, days);
  }

  toString(): string {
    return `0${this.week}/`.slice(-3) + `${this.year}`.slice(-2);
  }

  isBeforeOrAt(year: number, week: number): boolean {
    return this.year < year || (this.year === year && this.week <= week);
  }

  plus(weeks: number): DateCode {
    const date = this.toDate(0);
    date.setDate(date.getDate() + (weeks * 7));
    // To ensure that the changes between summer & winter time does not effect the date, we set the time to 3AM
    date.setHours(date.getHours() + 3);
    return DateHelper.dateToWeekDate(date);
  }
}
