import {Component} from '@angular/core';
import {StateService} from '@uirouter/angular';

@Component({
  selector: 'material-navigation',
  templateUrl: './material-navigation.component.html',
})
export class MaterialNavigationComponent {
  constructor(private stateService: StateService) {
  }

  get editState(): boolean {
    return (
      this.stateService.includes('a.material.article') ||
      this.stateService.includes('a.material.group') ||
      this.stateService.includes('a.material.field') ||
      this.stateService.includes('a.material.units') ||
      this.stateService.includes('a.material.users')
    );
  }
}
