import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Address} from '../model/address';
import {Customer} from '../model/customer';
import {Country} from '../model/country';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerService} from '../services/customer.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {CustomerDetailAddressDeleteModalComponent} from './customer-detail-address-delete-modal.component';
import {CustomerDetailAddressUpsertModalComponent} from './customer-detail-address-upsert-modal.component';
import {ignoreRejection} from '../../../helper/ignore_rejection';
import {noop} from '../../../helper/noop';

@Component({
  selector: 'customer-detail-address',
  templateUrl: './customer-detail-address.component.html'
})
export class CustomerDetailAddressComponent implements OnInit {
  @Input() customer: Customer;
  @Input() countries: { [key: string]: Country; };
  @Output() updateInvoice = new EventEmitter<Address>();
  addresses: Address[];

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.addresses = this.customer.addresses ? this.customer.addresses : [];
  }

  addressModal(index: number, address?: Address): void {
    const modalRef = this.modalService.open(CustomerDetailAddressUpsertModalComponent);
    modalRef.componentInstance.customer = this.customer;
    modalRef.componentInstance.countries = this.countries;
    modalRef.componentInstance.main = index === -1 && !!address;
    modalRef.componentInstance.address = address;
    modalRef.result.then(value => {
      // depending on the index and the inputed address we either use the main address
      // or we edit an existing address
      if (!!address && index >= 0) {
        this.addresses[index] = value;
      } else if (!!address && index === -1) {
        this.updateInvoice.next(value);
        this.customer.invoice = value;
      } else {
        this.addresses.push(value);
      }
    }, ignoreRejection);
  }

  addressDelete(address: Address, index: number): void {
    const text = 'Möchten Sie die Adresse mit der ID: ' + address.id + ' löschen?';
    const header = '';
    const modalRef = this.modalService.open(CustomerDetailAddressDeleteModalComponent);
    modalRef.componentInstance.text = text;
    modalRef.componentInstance.header = header;
    modalRef.componentInstance.addressId = address.id;
    modalRef.result.then(() => {
      this.addresses.splice(index, 1);
      if (this.customer.addresses) {
        this.customer.addresses.splice(index, 1);
      }
    }, ignoreRejection);
  }

  setDeliveryAddress(addressId: string): void {
    this.customerService.deliveryAddress(this.customer.id, addressId).subscribe(customer => {
      this.customer.delivery_id = customer.delivery_id;
      this.alertService.add('success', 'Lieferadresse erfolgreich gesetzt!');
    }, () => {
      this.alertService.add('danger', 'Lieferadresse konnte nicht gesetzt werden!');
    });
  }

  trackByFn(index: number, item: Address): string {
    noop(this);
    return item.id;
  }

}
