import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {SharedModule} from '../../../core/shared.module';
import {BusinessOrderCreateComponent} from './create/business-order-create.component';
import {WorkflowBoxesModule} from '../boxes/workflow-boxes.module';
import {BusinessOrderFrameTableComponent} from './create/business-order-frame-table.component';
import {BusinessOrderArticleBoxComponent} from './create/business-order-article-box.component';
import {BusinessOrderFormComponent} from './create/business-order-form.component';
import {BusinessOrderFetchModalComponent} from './create/business-order-fetch-modal.component';
import {BusinessOrderCreateModalComponent} from './create/business-order-create-modal.component';
import {BusinessOrderArticleListComponent} from './create/article-list/business-order-article-list.component';
import {BusinessOrderListComponent} from './list/business-order-list.component';
import {BusinessOrderListElementComponent} from './list/business-order-list-element.component';
import {BusinessOrderViewComponent} from './view/business-order-view.component';
import {BusinessOrderViewStepListComponent} from './view/steps-element/business-order-view-step-list.component';
import {BusinessOrderViewStepListElementComponent} from './view/steps-element/business-order-view-step-list-element.component';
import {BusinessOrderViewBoxComponent} from './view/business-order-view-box.component';
import {BusinessOrderViewArticleBoxComponent} from './view/business-order-view-article-box.component';
import {BusinessOrderStepStopComponent} from './step/business-order-step-stop.component';
import {CommentModule} from '../../comment/comment.module';
import {BUSINESS_ORDER_STATES} from './business-order.states';
import {BusinessOrderStornoModule} from './storno/business-order-storno.module';
import {BusinessOrderStartModule} from './start/business-order-start.module';
import {BusinessOrderGoldDateErrorComponent} from './create/business-order-gold-date-error.component';
import {ArticleWarningModule} from '../../article/warning/article-warning.module';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {DeliveryConditionBoxModule} from '../delivery-condition-box/delivery-condition-box.module';
import {BusinessOrderGoodPieceComponent} from './good-piece/business-order-good-piece.component';
import {BusinessOrderDateChangeComponent} from './date-change/business-order-date-change.component';
import {BusinessOrderStepMinDurationModalComponent} from './step/business-order-step-min-duration-modal.component';
import {BusinessOrderProposalTableComponent} from './create/business-order-proposal-table.component';
import {LoadingOrErrorModule} from '../../../common/loading/loading-or-error.module';
import {BusinessOrderViewMaterialBoxComponent} from './view/business-order-view-material-box.component';
import {MaterialManagementModule} from '../../material/material-management.module';
import {BusinessOrderMaterialSelectModalComponent} from './material-select-modal/business-order-material-select-modal.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderMaterialUnlimitedListComponent} from './material-select-modal/business-order-material-unlimited-list.component';
import {BusinessOrderMaterialUnlimitedListRowComponent} from './material-select-modal/business-order-material-unlimited-list-row.component';
import {BusinessOrderCreateReproductionComponent} from './create/reproduction/business-order-create-reproduction.component';
import {BusinessOrderArticleListElementComponent} from './create/article-list/business-order-article-list-element.component';
import {BusinessOrderViewGoodpieceBoxComponent} from './view/goodpiece-box/business-order-view-goodpiece-box.component';
import {BusinessOrderViewMergeModalComponent} from './view/merge/business-order-view-merge-modal.component';
import {BusinessOrderViewGoodpieceBoxElementComponent} from './view/goodpiece-box/business-order-view-goodpiece-box-element.component';
import {BusinessOrderLabelComponent} from './label/business-order-label.component';
import {ArticleModule} from '../../article/article.module';
import {
  BusinessOrderCreateMultilayerWarningComponent
} from './create/multilayer-warning/business-order-create-multilayer-warning.component';
import {ArticleListModule} from '../../article/list/article-list.module';
import {CustomerModule} from '../../customer/customer.module';
import {BusinessOrderFilmInventoryComponent} from './film-inventory/business-order-film-inventory.component';
import {
  BusinessOrderMaterialSelectGroupComponent
} from './material-select-modal/business-order-material-select-group.component';

@NgModule({
    imports: [
        UIRouterModule.forChild({states: BUSINESS_ORDER_STATES}),
        SharedModule,
        WorkflowBoxesModule,
        WorkflowPipesModule,
        WorkflowPipesModule,
        CommentModule,
        BusinessOrderStartModule,
        BusinessOrderStornoModule,
        ArticleWarningModule,
        DeliveryConditionBoxModule,
        LoadingOrErrorModule,
        MaterialManagementModule,
        NgbTooltipModule,
        ArticleModule,
        ArticleListModule,
        CustomerModule,
    ],
  exports: [
    BusinessOrderLabelComponent
  ],
  declarations: [
    BusinessOrderCreateComponent,
    BusinessOrderListComponent,
    BusinessOrderListElementComponent,
    BusinessOrderViewComponent,
    // SubComponents
    BusinessOrderFrameTableComponent,
    BusinessOrderArticleBoxComponent,
    BusinessOrderFormComponent,
    BusinessOrderArticleListComponent,
    BusinessOrderViewStepListComponent,
    BusinessOrderViewStepListElementComponent,
    BusinessOrderViewBoxComponent,
    BusinessOrderViewArticleBoxComponent,
    // Modals:
    BusinessOrderFetchModalComponent,
    BusinessOrderCreateModalComponent,
    BusinessOrderStepStopComponent,
    BusinessOrderGoldDateErrorComponent,
    BusinessOrderGoodPieceComponent,
    BusinessOrderDateChangeComponent,
    BusinessOrderProposalTableComponent,
    BusinessOrderStepMinDurationModalComponent,
    BusinessOrderViewMaterialBoxComponent,
    BusinessOrderMaterialSelectModalComponent,
    BusinessOrderMaterialUnlimitedListComponent,
    BusinessOrderMaterialUnlimitedListRowComponent,
    BusinessOrderMaterialSelectGroupComponent,
    BusinessOrderCreateReproductionComponent,
    BusinessOrderArticleListElementComponent,
    BusinessOrderViewGoodpieceBoxComponent,
    BusinessOrderViewMergeModalComponent,
    BusinessOrderViewGoodpieceBoxElementComponent,
    BusinessOrderLabelComponent,
    BusinessOrderCreateMultilayerWarningComponent,
    BusinessOrderFilmInventoryComponent,
  ]
})
export class BusinessOrderModule {
}
