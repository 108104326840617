import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  MaterialInventoryWithPlanning,
  MaterialPlanningDateWithColorCodes,
  MaterialPlanningRelation
} from './material-planning.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {MaterialOrderPositionSmallListService} from '../order/position-small-list/material-order-position-small-list.service';
import {Lister} from '../../../common/wrapper.models';
import {MaterialOrderPositionWithWeek} from '../order/material-order.model';
import {MaterialPlanningSmallListService} from './material-planning-small-list.service';

@Component({
  selector: 'material-planning-sidebar',
  templateUrl: './material-planning-sidebar.component.html',
})
export class MaterialPlanningSidebarComponent implements OnInit, OnChanges {
  @Input() week: MaterialPlanningDateWithColorCodes;
  @Input() inventory: MaterialInventoryWithPlanning;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

  orderData: LoadingWrapper<any>;
  relationData: LoadingWrapper<any>;

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private mops: MaterialOrderPositionSmallListService,
              private mprs: MaterialPlanningSmallListService) {
  }

  ngOnInit(): void {
    this.updateData();
    this.form = this.fb.group({
      temp_amount: [0],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.week && !changes.week.isFirstChange()) {
      this.updateData();
    }
    if (changes.inventory && !changes.inventory.isFirstChange()) {
      this.updateData();
    }
  }

  updateData(): void {
    const data = {
      'variation_id': this.inventory.inventory.id,
      'first_day_of_week': this.week.first_day_of_week,
    };
    this.orderData = new LoadingWrapper<Lister<MaterialOrderPositionWithWeek>>(this.mops.list(data));
    this.relationData = new LoadingWrapper<Lister<MaterialPlanningRelation>>(this.mprs.list(data));
  }

  close(): void {
    this.closeEvent.emit();
  }

  get tempAmount(): number {
    return this.form.value.temp_amount >= 0 ? -this.form.value.temp_amount : 0;
  }

  get endAmountSecure(): number {
    return this.inventory.inventory.amount +
      this.week.secure_confirmed_to_date -
      this.week.required_to_date +
      this.tempAmount;
  }

  get endAmountPossible(): number {
    return this.inventory.inventory.amount +
      this.week.confirmed_to_date -
      this.week.required_to_date +
      this.week.ordered_to_date +
      this.tempAmount;
  }

}
