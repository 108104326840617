import * as React from 'react';
import {
  ArticleMultilayerDrillData,
  ArticleMultilayerParts,
  ArticleMultilayerPlanModel,
} from './article-multilayer-plan.models';
import {ArticleSpecification} from '../../models/article-specification.model';
import {ArticleMultilayerPlanHelper} from './article-multilayer-plan-helper';
import {ArticleMultilayerPartTypeHelper} from './article-multilayer-part-type-helper';
import {ArticleMultilayerPlanTable} from './components/article-multilayer-plan-table';
import {ArticleMultilayerPlanPartCore} from './row-elements/article-multilayer-plan-part-core';
import {ArticleMultilayerPlanPartCuKasch} from './row-elements/article-multilayer-plan-part-cu-kasch';
import {ArticleMultilayerPlanPartLacquer} from './row-elements/article-multilayer-plan-part-lacquer';
import {ArticleMultilayerPlanPartCuPlating} from './row-elements/article-multilayer-plan-part-cu-plating';
import {ArticleMultilayerPlanPartCuFoil} from './row-elements/article-multilayer-plan-part-cu-foil';
import {ArticleMultilayerPlanPartPrepreg} from './row-elements/article-multilayer-plan-part-prepreg';
import {ArticleMultilayerPlanSelectPart} from './components/article-multilayer-plan-select-part';
import {ReactElement, ReactNode, useEffect, useState} from 'react';
import {ArticleMultilayerPlanCycleColumn} from './row-elements/article-multilayer-plan-cycle-column';
import {ArticleMultilayerNumberInput} from './form-elements/article-multilayer-number-input';
import {ArticleMultilayerUnit} from './form-elements/article-multilayer-unit';
import {FormatNumber} from './formatters/format-number';
import {ArticleMultilayerLabelSelect} from './form-elements/article-multilayer-label-select';
import {ArticleMultilayerPlanDrillCell} from './row-elements/article-multilayer-plan-drill-cell';
import {ArticleMultilayerPlanDrillStartCell} from './row-elements/article-multilayer-plan-drill-start-cell';

interface Props {
  model: ArticleMultilayerPlanModel;
  specification: { [key: string]: ArticleSpecification };
  changeModel: (model: ArticleMultilayerPlanModel) => void;
  children: ReactNode;
}

export function ArticleMultilayerPlanForm(props: Props) {
  // Index of the button where the menu was opened
  const [partMenuOpenIndex, partMenuOpenIndexSet] = useState<number | null>(null);
  const [drillStart, drillStartSet] = useState<{partIndex: number, column: number} | null>(null);
  const [drillOverlay, drillOverlaySet] = useState<boolean[] | null>(null);
  const [drillEditLocked, drillEditLockedSet] = useState<boolean>(true);

  /* Parts */
  const partMenuOpened: (opened: boolean, index: number) => void = (opened, index) => {
    partMenuOpenIndexSet((opened && partMenuOpenIndex !== index) ? index : null);
  };

  const partAdd: (index: number, partName: string) => void = (index, partName) => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    model.parts = [
      ...model.parts.slice(0, index),
      ...ArticleMultilayerPlanHelper.partsToAdd(partName, model, index),
      ...model.parts.slice(index),
    ];

    props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
  };

  const mirrorCopy: (index: number) => void = (index) => {
    props.changeModel(ArticleMultilayerPlanHelper.mirrorCopy(props.model, index));
  };

  const partDelete: (index: number) => void = (index) => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    const isCore = ArticleMultilayerPartTypeHelper.getName(props.model.parts[index].type) === 'core';
    model.parts.splice(index - (isCore ? 1 : 0), (isCore ? 3 : 1));
    props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
  };

  const partChange: (part: ArticleMultilayerParts) => void = (part) => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    model.parts[part.index] = part;
    props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
  };

  const refreshLayerLabels: () => void = () => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    props.changeModel(ArticleMultilayerPlanHelper.refreshLayerLabel(ArticleMultilayerPlanHelper.recalculate(model)));
  };

  /* copper parts */
  const partCopperPercentChange: (index: number, percent: number | null) => void = (index, percent) => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    switch (model.parts[index].type) {
      case 2:
        model.parts[index].cu_foil = {...model.parts[index].cu_foil, area_used: percent ?? 0};
        break;
      case 3:
        model.parts[index].cu_kasch = {...model.parts[index].cu_kasch, area_used: percent ?? 0};
        break;
      default:
        break;
    }
    props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
  };

  /* Cycles */
  // Mapping of indices to cycles
  const cycleIslandsMapping: Map<number, number[]> = new Map<number, number[]>();
  let cycleIslandsTotal = 0;
  let maxCycle: number | null = null;
  let cycleLast: number | null = null;
  for (let i = 0; i < props.model.parts.length; i++) {
    const cycleCurr = props.model.parts[i].cycle;

    if (cycleLast !== cycleCurr) {
      cycleIslandsTotal++;
      cycleLast = cycleCurr;
      if (maxCycle === null || maxCycle < cycleCurr) {
        maxCycle = cycleCurr;
      }
    }

    cycleIslandsMapping.set(
      cycleIslandsTotal,
      [...(cycleIslandsMapping.get(cycleIslandsTotal) || []), props.model.parts[i].index]
    );
  }

  // We ignore showing the highest layer if it is a finishing
  if (props.model.cycles_has_finishing) {
    maxCycle--;
  }

  // Create left part of the table with react elements
  const cycleMap: Map<number, ReactElement[]> = new Map<number, ReactElement[]>();
  for (let partIndex = 0; partIndex < props.model.parts.length; partIndex++) {
    const cycles = [];
    for (let cycle = 0; cycle < maxCycle + 1; cycle++) {
      cycles.push(<ArticleMultilayerPlanCycleColumn key={(partIndex + 1) * 1000 + cycle}/>);
    }
    cycleMap.set(partIndex, cycles);
  }

  // Create drill columns with all parts
  const drillColumnAdd: (index: number, data: ArticleMultilayerDrillData) => void = (index, data) => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    model.drill_data.splice(index, 0, data);
    model.parts.forEach(p => {
      p.drill_data.splice(index, 0, false);
    });
    props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
  };

  // Create drill columns with all parts
  const drillColumnEdit: (index: number, data: ArticleMultilayerDrillData) => void = (index, data) => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    model.drill_data.splice(index, 1, data);
    props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
  };

  const drillEditLockedToggle: () => void = () => {
    drillEditLockedSet(!drillEditLocked);
    drillStartSet(null);
    drillOverlaySet(null);
  };

  const drillColumnSelect: (partIndex: number, column: number) => void = (partIndex, column) => {
    if (!drillEditLocked) {
      if (drillStart === null || drillStart.column !== column) {
        drillStartSet({partIndex: partIndex, column: column});
        drillOverlaySet(props.model.parts.map(() => false));
      } else if (drillStart.column === column) {
        const indexStart = Math.min(drillStart.partIndex, partIndex);
        const indexEnd = Math.max(drillStart.partIndex, partIndex);
        const model = ArticleMultilayerPlanHelper.copy(props.model);
        model.parts.forEach((p, i) => {
          p.drill_data[column] = indexStart <= i && i <= indexEnd;
        });
        drillStartSet(null);
        drillOverlaySet(null);
        props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
      }
    }
  };

  const drillColumnMouseOver: (partIndex: number, column: number) => void = (partIndex, column) => {
    if (drillStart !== null && drillStart?.column === column && drillOverlay !== null) {
      const indexStart = Math.min(drillStart.partIndex, partIndex);
      const indexEnd = Math.max(drillStart.partIndex, partIndex);
      drillOverlaySet(props.model.parts.map((p, i) => indexStart <= i && i <= indexEnd));
    }
  };

  const drillColumnEscape: (event: KeyboardEvent) => void = (event) => {
    if (event.key === 'Escape') {
      drillStartSet(null);
      drillOverlaySet(null);
    }
  };

  // Will allow pressing the escape key to cancel the selection mode
  useEffect(() => {
    // run once on first component render
    document.addEventListener('keydown', drillColumnEscape, false);
    return (() => {
      // run once on component destruction
      document.removeEventListener('keydown', drillColumnEscape, false);
    });
  }, []);

  const drillColumnRemove: (index: number) => void = (index) => {
    const model = ArticleMultilayerPlanHelper.copy(props.model);
    model.drill_data.splice(index, 1);
    model.parts.forEach(p => {
      p.drill_data.splice(index, 1);
    });
    props.changeModel(ArticleMultilayerPlanHelper.recalculate(model));
  };

  useEffect(() => {
    drillStartSet(null);
    drillOverlaySet(null);
  }, [props.model]);

  // Create cycle thickness part of the table with react elements
  const cycleThicknessMap: Map<number, ReactElement[]> = new Map<number, ReactElement[]>();
  for (let partIndex = 0; partIndex < props.model.parts.length; partIndex++) {
    const cycles = props.model.thickness_cycle.slice(0, props.model.thickness_cycle.length - 1).map(ct => {
      if (ct.rows[partIndex] === null) {
        // Cells in columns before the right cycle
        return <td key={(partIndex + 1) * 1000 + ct.cycle}/>;
      } else if (!!ct.rows[partIndex] && ct.rows[partIndex].rows > 0) {
        // Cells in either the start of multiple rows or single rows
        return <td rowSpan={ct.rows[partIndex].rows}
                   key={(partIndex + 1) * 1000 + ct.cycle}>
          <div className={'flex align-center justify-end fit-all'}>
            <FormatNumber value={(ct.rows[partIndex].thickness > 0 ? ct.rows[partIndex].thickness : 0) / 1000}
                          decimals={3}/>&nbsp;mm
          </div>
        </td>;
      } else {
        // Cells where a multi row cell should be
        return null;
      }
    });
    cycleThicknessMap.set(partIndex, cycles);
  }

  // Create isolation distance elements as cells with rowspan > 0
  const isolationDistance: Map<number, ReactElement> = new Map<number, ReactElement>();
  {
    let lastPrepregGroup: number | null = null;
    let lastPrepregIndex: number | null = null;

    // Collect data on isolating parts and prepare overview of rows
    const rows: ({ thickness: number | null, rows: number } | null)[] = [];
    props.model.parts.forEach((p, i) => {
      if (ArticleMultilayerPartTypeHelper.getName(p.type) === 'prepreg') {
        if (lastPrepregGroup !== p.prepreg.group) {
          lastPrepregGroup = p.prepreg.group;
          lastPrepregIndex = i;
          rows.push({thickness: props.model.thickness_prepreg_group[p.prepreg.group], rows: 1});
        } else {
          rows[lastPrepregIndex].rows++;
          rows.push(null);
        }
      } else {
        rows.push(null);
      }
    });

    // Create DOM elements from gathered data
    rows.forEach((s, i) => {
      if (s !== null) {
        isolationDistance.set(
          i,
          <td key={i} rowSpan={s.rows}>
            <div className={'flex align-center justify-end fit-all'}>
              <FormatNumber value={s.thickness > 0 ? s.thickness / 1000 : 0} decimals={3}/>&nbsp;mm
            </div>
          </td>
        );
      }
    });
  }

  // Remove empty table cells and replace them with center column / arrow cells
  for (const partIndices of cycleIslandsMapping.values()) {
    const partIndexFirst = partIndices[0];
    const cycle = props.model.parts[partIndexFirst].cycle;

    if (cycle > maxCycle && maxCycle > 0) {
      continue;
    }

    const isFirstCycle = cycle === 1;
    const column = isFirstCycle ? 0 : cycle - 2;

    // Drop all table cells where a blue column will be
    for (let partIndexCurrent = 1; partIndexCurrent < partIndices.length; partIndexCurrent++) {
      const columns = [...cycleMap.get(partIndices[partIndexCurrent])];
      columns.splice(column, 1);
      cycleMap.set(partIndices[partIndexCurrent], columns);
    }

    // First row
    const rowFirst = [...cycleMap.get(partIndexFirst)];

    // Add blue column with label
    rowFirst[column + (isFirstCycle ? 0 : 1)] = (
      <ArticleMultilayerPlanCycleColumn key={(partIndexFirst + 1) * 1000 + column + (isFirstCycle ? 0 : 1)}
                                        rows={partIndices.length}>
        {
          (props.model.cycles_has_finishing && cycle === maxCycle + 1) ?
          <div className={'rounded-full color-class gray'}/> :
          <div className={'rounded-full color-class blue-gradient-mix'}
               style={{'animationDelay': (`-${(cycle / maxCycle).toString(10)}s`)}}>
            {cycle}
          </div>
        }
      </ArticleMultilayerPlanCycleColumn>
    );

    cycleMap.set(partIndexFirst, rowFirst);

    // Last row
    const partIndexLast = partIndices[partIndices.length - 1];
    const rowLast = [...cycleMap.get(partIndexLast)];

    cycleMap.set(partIndexLast, rowLast);
  }

  // Create cu area elements as cells with rowspan > 0
  const copperPlatingAreaInput: Map<number, ReactElement> = new Map<number, ReactElement | null>();
  {
    // Prepare an empty column for all cu_foil and cu_plating rows
    for (let i = 0; i < props.model.parts.length; i++) {
      if (props.model.parts[i].type === 2 || props.model.parts[i].type === 4) {
        copperPlatingAreaInput.set(i, <td/>);
      }
    }

    for (let i = 0; i < props.model.copper_group.length; i++) {
      const cuGroup = props.model.copper_group[i];
      const cuFoilIndex = cuGroup.index_copper;
      const list = props.model.parts.filter(p => p.cu_plating?.group === i).map(p => p.index);
      list.push(cuFoilIndex);
      const firstIndex = cuFoilIndex !== null && cuFoilIndex < list[0] ? cuFoilIndex : list[0];

      // Carve out the rows from the columns again
      for (let j = 0; j < list.length; j++) {
        copperPlatingAreaInput.set(list[j], null);
      }

      // Set the first row
      if (cuFoilIndex !== null && !cuGroup.is_finishing) {
        const cuFoil = props.model.parts[cuFoilIndex];
        copperPlatingAreaInput.set(
          firstIndex,
          <td rowSpan={cuGroup.rows}>
            <div className={'flex align-center justify-end fit-all'}>
              <ArticleMultilayerNumberInput value={cuFoil?.cu_foil?.area_used}
                                            model={'cu_foil_area_used_' + cuFoil.index}
                                            min={0}
                                            max={100}
                                            width={65}
                                            onValueChange={percent => partCopperPercentChange(cuFoil.index, percent)}>
                <ArticleMultilayerUnit unit={'%'}/>
              </ArticleMultilayerNumberInput>
            </div>
          </td>
        );
      } else {
        copperPlatingAreaInput.set(firstIndex, <td rowSpan={cuGroup.rows}/>);
      }
    }
  }

  /* Rendering */
  const renderRows: () => any = () => {
    return props.model.parts.map(p => {
      const cycle = cycleMap.get(p.index) ?? [];
      const drill = <>
        <ArticleMultilayerPlanDrillStartCell key={-1} model={p}/>
        {props.model.drill_data?.map((dd, i) => {
          return <ArticleMultilayerPlanDrillCell key={(p.index + 1) * 1000 + i}
                                                 model={p}
                                                 drillData={dd}
                                                 column={i}
                                                 drillEditLocked={drillEditLocked}
                                                 drillStart={drillStart}
                                                 drillOverlay={drillOverlay}
                                                 drillColumnMouseOver={() => drillColumnMouseOver(p.index, i)}
                                                 onSelect={() => drillColumnSelect(p.index, i)}/>;
        })}
      </>;
      const addButton = <>
        <ArticleMultilayerPlanSelectPart partAdd={part => partAdd(p.index + 1, part)}
                                         partMenuOpen={partMenuOpenIndex === p.index + 1}
                                         mirrorCopy={() => mirrorCopy(p.index)}
                                         partMenuOpened={open => partMenuOpened(open, p.index + 1)}/>
      </>;

      const deleteButton = <>
        <i className={'fa fa-trash-o'} onClick={() => partDelete(p.index)}/>
      </>;

      const labelSelect = <ArticleMultilayerLabelSelect key={p.index}
                                                        model={props.model}
                                                        parts={p}
                                                        partChange={planLacquer => partChange(planLacquer)}/>;

      switch (p.type) {
        case 1:
          return <ArticleMultilayerPlanPartCore key={p.index}
                                                model={p}
                                                specification={props.specification}
                                                partChange={planCore => partChange(planCore)}>
            {cycle}
            {deleteButton}
            {cycleThicknessMap.get(p.index)}
            {drill}
          </ArticleMultilayerPlanPartCore>;
        case 2:
          return <ArticleMultilayerPlanPartCuFoil key={p.index}
                                                  model={p}
                                                  specification={props.specification}
                                                  partChange={planCuFoil => partChange(planCuFoil)}>
            {cycle}
            {labelSelect}
            {addButton}
            {deleteButton}
            {cycleThicknessMap.get(p.index)}
            {copperPlatingAreaInput.get(p.index)}
            {drill}
          </ArticleMultilayerPlanPartCuFoil>;
        case 3:
          return <ArticleMultilayerPlanPartCuKasch key={p.index}
                                                   model={p}
                                                   specification={props.specification}
                                                   partChange={planCuKasch => partChange(planCuKasch)}>
            {cycle}
            {labelSelect}
            {addButton}
            {cycleThicknessMap.get(p.index)}
            <ArticleMultilayerNumberInput value={p.cu_kasch.area_used}
                                          model={'cu_kasch_area_used_' + p.index}
                                          min={0}
                                          max={100}
                                          width={65}
                                          onValueChange={percent => partCopperPercentChange(p.index, percent)}>
              <ArticleMultilayerUnit unit={'%'}/>
            </ArticleMultilayerNumberInput>
            {drill}
          </ArticleMultilayerPlanPartCuKasch>;
        case 4:
          return <ArticleMultilayerPlanPartCuPlating key={p.index}
                                                     model={p}
                                                     specification={props.specification}
                                                     partChange={planCuPlating => partChange(planCuPlating)}>
            {cycle}
            {addButton}
            {deleteButton}
            {cycleThicknessMap.get(p.index)}
            {copperPlatingAreaInput.get(p.index)}
            {drill}
          </ArticleMultilayerPlanPartCuPlating>;
        case 5:
          return <ArticleMultilayerPlanPartLacquer key={p.index}
                                                   model={p}
                                                   specification={props.specification}
                                                   partChange={planLacquer => partChange(planLacquer)}>
            {cycle}
            {labelSelect}
            {addButton}
            {deleteButton}
            {cycleThicknessMap.get(p.index)}
            {drill}
          </ArticleMultilayerPlanPartLacquer>;
        case 6:
          return <ArticleMultilayerPlanPartPrepreg key={p.index}
                                                   model={p}
                                                   specification={props.specification}
                                                   partChange={planPrepreg => partChange(planPrepreg)}>
            {cycle}
            {addButton}
            {deleteButton}
            {isolationDistance.get(p.index)}
            {cycleThicknessMap.get(p.index)}
            {drill}
          </ArticleMultilayerPlanPartPrepreg>;
        default:
          return <tr>
            <td colSpan={6}>Fehlerhafte Zeile</td>
          </tr>;
      }
    });
  };

  return <>
    {props.children}

    <ArticleMultilayerPlanTable cycles={maxCycle}
                                model={props.model}
                                drillColumnEditLocked={drillEditLocked}
                                drillColumnEditLockedToggled={() => drillEditLockedToggle()}
                                drillColumnAdd={(index, data) => drillColumnAdd(index, data)}
                                drillColumnEdit={(index, data) => drillColumnEdit(index, data)}
                                drillColumnRemove={index => drillColumnRemove(index)}
                                refreshLayerLabels={() => refreshLayerLabels()}>
      <ArticleMultilayerPlanSelectPart partAdd={part => partAdd(0, part)}
                                       partMenuOpen={partMenuOpenIndex === 0}
                                       partMenuOpened={open => partMenuOpened(open, 0)}/>
      {renderRows()}
    </ArticleMultilayerPlanTable>
  </>;
}
