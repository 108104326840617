import {Component, Input} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {buildInnerGroup} from './request-form.builds';

@Component({
  selector: 'order-request-main-request-position-form',
  templateUrl: './order-request-main-request-position-form.component.html',
})
export class OrderRequestMainRequestPositionFormComponent {
  @Input() positionForm: UntypedFormGroup;
  @Input() errors: {[key: string]: any};

  constructor(private fb: UntypedFormBuilder) {
  }

  get positions() {
    return this.positionForm.get('positions') as UntypedFormArray;
  }

  add(): void {
    this.positions.push(buildInnerGroup(this.fb));
  }

  remove(index: number): void {
    this.positions.removeAt(index);
  }

  asFormGroup(position: AbstractControl): UntypedFormGroup {
    return position as UntypedFormGroup;
  }
}
