import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {DeliveryListComponent} from './list/delivery-list.component';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {DELIVERY_STATES} from './delivery.states';
import {WorkflowNavigationModule} from '../navigation/workflow-navigation.module';
import {DeliveryModalModule} from './modals/delivery-modal.module';
import {DeliveryCreateModalModule} from './create-modal/delivery-create-modal.module';
import {DeliveryCreateModule} from './create/delivery-create.module';
import {DeliveryCocMaterialModalComponent} from './coc-material-modal/delivery-coc-material-modal.component';

@NgModule({
  imports: [
    UIRouterModule.forChild({states: DELIVERY_STATES}),
    NgbTooltipModule,
    SharedModule,
    WorkflowPipesModule,
    WorkflowNavigationModule,
    DeliveryModalModule,
    DeliveryCreateModalModule,
    DeliveryCreateModule,
  ],
  declarations: [
    DeliveryListComponent,
    DeliveryCocMaterialModalComponent,
  ],
})
export class DeliveryModule {
}
