import {Component, Input} from '@angular/core';

@Component({
  selector: 'cn-order-delivery-method',
  templateUrl: './cn-order-delivery-method.component.html',
  styleUrl: './cn-order-delivery-method.component.scss'
})
export class CnOrderDeliveryMethodComponent {
  @Input() deliveryMethod: string;
  @Input() spaced: boolean;
  @Input() size17: boolean;

  get clazz(): {} {
    return {
      'ev-cn-order-delivery-size-17': this.size17,
      'ev-cn-order-delivery-spaced': this.spaced,
    };
  }
}
