import {Component, Input} from '@angular/core';

@Component({
  selector: 'business-order-create-multilayer-warning',
  templateUrl: './business-order-create-multilayer-warning.component.html'
})
export class BusinessOrderCreateMultilayerWarningComponent {
  @Input() invalidML = false;
  @Input() errorMl?: string = null;
}
