import {Component, Input, OnInit} from '@angular/core';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {Offer} from '../../../models/offer.models';
import {WorkflowPositionView} from '../../../models/position.models';
import {InventoryService} from '../../../../article/inventory/inventory.service';
import {ArticleInventorySum} from '../../../models/inventory.models';
import {WorkflowService} from '../../../workflow-services/workflow.service';

@Component({
  selector: 'offer-detail',
  templateUrl: './offer-detail-base.component.html',
})
export class OfferDetailBaseComponent implements OnInit {
  @Input() data: BigWorkflowOffer;
  inventory: ArticleInventorySum;
  lastOffer?: Offer;
  positionsValid: boolean;

  constructor(private inventoryService: InventoryService,
              private workflowService: WorkflowService) {
  }

  ngOnInit() {
    this.inventory = this.data.inventory;
    this.lastOffer = this.data.offer_list.length > 0 ? this.data.offer_list[this.data.offer_list.length - 1] : null;
    this.positionsValid = this.generatePositionsValid();
  }

  generatePositionsValid(): boolean {
    let valid = true;
    this.data.workflow.positions.forEach((obj) => {
      if (obj.typ === 'article') {
        if (!obj.valid_de || !obj.valid_cn) {
          valid = false;
        }
      }
    });
    return valid;
  }

  positionChanged(data: {position: WorkflowPositionView, index: number} | null) {
    this.workflowService.detail(this.data.workflow.object.id).subscribe(w => {
      this.data.workflow.positions = w.positions;
      this.positionsValid = this.generatePositionsValid();
    });
  }

  reloadInventory(): void {
    this.inventoryService
      .inventorySum(this.data?.article?.oa_nr, this.data?.workflow?.object?.id)
      .subscribe(inventory => {
      this.inventory = inventory;
    });
  }

  get lastOfferRejectionNoteLines(): string[] {
    return this.lastOffer?.rejection_note?.split('\n') ?? [];
  }
}
