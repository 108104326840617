import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MaterialArticleBookedInventory} from '../../../material/material-management.model';
import {HttpClient} from '@angular/common/http';
import {BusinessOrder} from '../models/business-order.models';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderMaterialService {

  constructor(private http: HttpClient) { }

  listMaterial(ba: BusinessOrder): Observable<MaterialArticleBookedInventory[]> {
    return this.http.get<MaterialArticleBookedInventory[]>(
      `/api/material/ba/${ba.id}/${ba.index}`
    );
  }
}
