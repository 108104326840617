import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Article, ArticleCore} from '../../../article/models/article.models';
import {ShippingInstruction, ShippingInstructionTableRow} from '../../../article/models/article-extra.models';
import {Money} from '../../../../common/money/Money';
import {ArticleInventorySum} from '../../models/inventory.models';

@Component({
  selector: 'business-order-article-box',
  templateUrl: './business-order-article-box.component.html',
  styles: [
    '.fa-check {color: #5CB85C;}',
    '.fa-times {color: #D9534F;}',
  ]
})
export class BusinessOrderArticleBoxComponent {
  @Input() workflowId: number;
  @Input() article: Article | ArticleCore;
  @Input() inventory: ArticleInventorySum;
  @Input() extra: ShippingInstruction;
  @Input() sum: number;
  @Input() quantity?: number;
  @Input() hasExpress: boolean;
  @Output() reloadInventory = new EventEmitter<number>();

  success: boolean;

  overDelivery: ShippingInstructionTableRow[] = [];

  constructor() {
  }

  get amountPerUtilize(): number {
    return this.article.data.utilize_sum ? Money.parse(this.article.data.utilize_sum.toString()) : 0;
  }
}
