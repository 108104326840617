import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'loading-button',
  template: `
    <button [type]="typ" [ngClass]="clazz" [disabled]="disabled || loading" (click)="save()">
      {{ name }}<ng-content></ng-content> <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i>
    </button>
  `
})
export class LoadingButtonComponent implements OnInit {
  @Input() typ = 'button';
  @Input() clazz: string;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() additional?: any;
  loading: boolean;
  data: { (loading: boolean): void, additional?: any } = null;
  @Output() saveEmitter: EventEmitter<LoadingButtonEvent> = new EventEmitter<LoadingButtonEvent>();
  @Output() loadingSave: EventEmitter<LoadingButtonEvent> = new EventEmitter<LoadingButtonEvent>();

  ngOnInit(): void {
    this.loading = false;
  }

  save() {
    this.loading = true;
    const self = this;
    const callback = (loading?: boolean): void => {
      self.loading = loading || false;
    };
    this.saveEmitter.next({callback: callback, additional: this.additional});
    this.loadingSave.next({callback: callback, additional: this.additional});
  }
}

export interface LoadingButtonEvent {
  callback: (loading: boolean) => void;
  additional: any;
}
