import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {DeliveryService} from '../../workflow-services/delivery.service';

@Component({
  selector: 'delivery-storno-modal',
  templateUrl: './delivery-locking-modal.component.html'
})
export class DeliveryLockingModalComponent implements OnInit {
  @Input() id: number;
  @Input() lock: boolean;
  form: UntypedFormGroup;

  constructor(private modalRef: NgbActiveModal,
              private alertService: AlertService,
              private fb: UntypedFormBuilder,
              private deliveryService: DeliveryService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'reason': [null, [Validators.required]]
    });
  }

  closeModal(): void {
    this.modalRef.dismiss(null);
  }

  save(): void {
    (
      this.lock ?
        this.deliveryService.lock(this.id, this.form.getRawValue().reason) :
        this.deliveryService.unlock(this.id, this.form.getRawValue().reason)
    ).subscribe(response => {
      this.alertService.add(
        'success',
        'Lieferschein erfolgreich ' + (this.lock ? 'gesperrt!' : 'entsperrt!')
      );
      this.modalRef.close(response);
    }, () => {
      this.alertService.add(
        'danger',
        'Beim ' + (this.lock ? 'sperren' : 'entsperren') + ' des Lieferscheins ist ein Fehler aufgetreten!'
      );
    });

  }
}
