import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WorkflowOrder} from '../../order-request.models';
import {Article} from '../../../../article/models/article.models';
import {MailModalService} from '../../../../mail/mail-modal.service';
import {ProducerService} from '../../../../producer/producer.service';
import {WorkflowOrderMailerAbstractComponent} from '../workflow-order-mailer.abstract-component';
import {ignoreRejection} from '../../../../../helper/ignore_rejection';
import {WorkflowOrderRequestService} from '../../../workflow-services/workflow-order-request.service';
import {WorkflowRequestService} from '../../../workflow-services/workflow-request.service';
import {Workflow} from '../../../models/workflow.models';
import {OrderPdfEditModalService} from '../../order-pdf-edit-modal/order-pdf-edit-modal.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {PdfHistoryModalService} from '../../../../history/pdf-history-modal.service';

@Component({
  selector: 'order-request-main-order-list',
  templateUrl: './order-request-main-order-list.component.html',
})
export class OrderRequestMainOrderListComponent extends WorkflowOrderMailerAbstractComponent {
  @Input() workflow: Workflow;
  @Input() orders: WorkflowOrder[];
  @Input() article: Article;
  @Output() updateParent: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected mailModalService: MailModalService,
              protected producerService: ProducerService,
              private workflowOrderRequestService: WorkflowOrderRequestService,
              private workflowRequestService: WorkflowRequestService,
              private orderPdfEditModalService: OrderPdfEditModalService,
              private pdfHistoryModalService: PdfHistoryModalService,
              private alertService: AlertService) {
    super();
  }

  deleteOrder(obj: WorkflowOrder): void {
    if (confirm('Möchten Sie diese Bestellungs-Position wirklich löschen?')) {
      this.workflowOrderRequestService.removePosition(obj.id, obj.positions.index).toPromise().then(() => {
        this.updateParent.emit();
      });
    }
  }

  editOrder(obj: WorkflowOrder): void {
    this.orderPdfEditModalService.open(obj, (newObj) => {
      this.alertService.add('success', 'Bestellung erfolgreich editiert!');
      this.updateParent.emit();
    });
  }

  historyOrder(obj: WorkflowOrder): void {
    this.pdfHistoryModalService.open('order', obj.id, 'Bestellungshistorie').subscribe(
      ignoreRejection,
      ignoreRejection
    );
  }

}
