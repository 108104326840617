import {Directive, Input} from '@angular/core';

@Directive()
export abstract class ArticleInputMarkerDirective {
  @Input() public model: string;
  @Input() public markedML?: { [key: string]: { original: any, ml: any } };

  public get markedElement(): { original: any, ml: any } | null {
    if (!this.markedML) {
      return null;
    }
    return this.markedML[this.model];
  }
}
