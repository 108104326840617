import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MaterialOrderPositionWithOrder} from '../material-order.model';
import {Lister} from '../../../../common/wrapper.models';
import {ListService} from '../../../../common/search/search.service';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialArticleOrderListService implements ListService<MaterialOrderPositionWithOrder> {
  constructor(protected http: HttpClient) {
  }

  list(variationId: number): Observable<Lister<MaterialOrderPositionWithOrder>> {
    return this.http.get<Lister<MaterialOrderPositionWithOrder>>(
      environment.apiv4uri + `material/order/variation/${variationId}`
    );
  }
}
