import {MaterialOrderPosition} from './material-order.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaterialOrderPositionService {
  private uri = environment.apiv4uri + 'material/order/position/';

  constructor(protected http: HttpClient) {
  }

  get(id: number): Observable<MaterialOrderPosition> {
    return this.http.get<MaterialOrderPosition>(this.uri + id.toString());
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + id.toString());
  }

  deleteUndo(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + `${id}/undo`);
  }

  update(id: number, form: {amount_ordered: number}): Observable<MaterialOrderPosition> {
    return this.http.put<MaterialOrderPosition>(this.uri + id.toString(), form);
  }

  copy(id: number): Observable<MaterialOrderPosition> {
    return this.http.post<MaterialOrderPosition>(this.uri + `copy/${id}`, {});
  }

  updateState(id: number, status: number): Observable<MaterialOrderPosition> {
    return this.http.put<MaterialOrderPosition>(this.uri + `${id}/status`, status);
  }
}
