<div class="row">
  <div class="col-lg-12">
    <article-select label="Oberfläche" model="surface_area"
                    [form]="form"
                    [specification]="specification"
                    [error]="errors['surface_area']">
    </article-select>
  </div>
</div>

<article-optional-group [form]="form"
                        [condition]="form.value.surface_area === 'Chem NI / AU'">
  <ng-template>
    <div class="row">
      <div class="col-lg-12">
        <article-xy-input label="Gold LP Fläche"
                          model1="galv_niau_surface_pcb_t"
                          model2="galv_niau_surface_pcb_b"
                          unit="dm²"
                          x="t"
                          y="b"
                          [form]="form"
                          [error1]="errors['galv_niau_surface_pcb_t']"
                          [error2]="errors['galv_niau_surface_pcb_b']">
        </article-xy-input>
      </div>
    </div>
  </ng-template>
</article-optional-group>
