import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BusinessOrder} from '../../models/business-order.models';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderCreateReproductionService {

  constructor(private http: HttpClient) {
  }

  create(form: any): Observable<BusinessOrder> {
    return this.http.post<BusinessOrder>(`${environment.apiv4uri}business/order/reproduction`, form);
  }
}
