<div class="modal-header">
  <h3 class="modal-title">Fehler</h3>
</div>

<div class="modal-body">
  <i class="fa fa-exclamation-triangle fa-2x red-font" aria-hidden="true"></i>
  Der Lagerbestand ist zu gering um einen Lieferschein zu erstellen!
</div>

<div class="modal-footer">
  <button class="btn btn-danger" (click)="close()">Ok</button>
</div>
