import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {forkJoin, from, Observable, throwError} from 'rxjs';
import {MaterialProductGroup} from '../material-product-groups.models';
import {MaterialProductGroupsCreateModalComponent} from './material-product-groups-create-modal.component';
import {MaterialProductGroupsEditModalComponent} from './material-product-groups-edit-modal.component';
import {MaterialProductGroupsSelectionModalComponent} from './material-product-groups-selection-modal.component';
import {MaterialGroupListAllService} from '../../groups/material-group-list-all.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {MaterialProductGroupsRelationService} from '../material-product-groups-relation.service';
import {MaterialProductGroupsService} from '../material-product-groups.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialProductGroupsModalService {
  constructor(private productRelationService: MaterialProductGroupsRelationService,
              private productGroupsService: MaterialProductGroupsService,
              private groupService: MaterialGroupListAllService,
              private alertService: AlertService,
              private modalService: NgbModal) {
  }

  openCreateModal(): Observable<void> {
    const modalRef = this.modalService.open(MaterialProductGroupsCreateModalComponent, {windowClass: 'modal2-mm'});
    return from(modalRef.result);
  }

  openEditModal(productGroup: MaterialProductGroup): Observable<void> {
    const modalRef = this.modalService.open(MaterialProductGroupsEditModalComponent, {windowClass: 'modal2-mm'});
    modalRef.componentInstance.productGroup = productGroup;
    return from(modalRef.result);
  }

  openSelectionModal(productGroup: MaterialProductGroup): Observable<void> {
    return forkJoin([
      this.productRelationService.list(productGroup.id),
      this.groupService.list(),
      this.productGroupsService.list({take: 200}),
    ]).pipe(
      catchError(error => {
        console.log('Modal Error', error);
        this.alertService.add('danger', 'Materialliste konnte nicht geladen werden');
        return throwError('modal error');
      }), mergeMap(list => {
        const ids = [];
        list[2].objects.filter(mpg => mpg.product_group.id !== productGroup.id).forEach(mpg => {
          mpg.groups.forEach(mg => {
            ids.push(mg.id);
          });
        });
        const filteredGroups = list[1].objects.filter(mg => !ids.find(id => id === mg.id));
        const modalRef = this.modalService.open(MaterialProductGroupsSelectionModalComponent, {windowClass: 'modal2-mm'});
        modalRef.componentInstance.productGroup = productGroup;
        modalRef.componentInstance.relations = list[0];
        modalRef.componentInstance.groups = {objects: filteredGroups};
        return from(modalRef.result);
      }));
  }
}
