<div class="article-form-item" [formGroup]="form" *ngIf="view">
  <label [htmlFor]="'article-model1-' + model1" class="article-form-item-label">{{ label }}:</label>
  <label [htmlFor]="'article-model2-' + model2"></label>

  <div class="article-form-item-data">

    <div class="data-group" style="margin-right: 5px;">
      <div class="article-form-data-content">
        <span class="article-form-data-unit-block" *ngIf="!x">X</span>
        <span class="article-form-data-unit-block" *ngIf="x">{{ x }}</span>
      </div>

      <div class="article-form-data-content">
        <input type="text"
               class="form-control fixed-width-sm"
               [id]="'article-model1-' + model1"
               [formControlName]="model1"
               (change)="change(model1)"
               [ngClass]="{red_alert: error1}">
      </div>
      <div class="article-form-data-content" *ngIf="!!unit || !!unit2">
        <div class="article-form-data-unit-block">{{ unit }}{{ unit1 }}</div>
      </div>
    </div>
    <div class="data-group">
      <div class="article-form-data-content">
        <span class="article-form-data-unit-block" *ngIf="!y">Y</span>
        <span class="article-form-data-unit-block" *ngIf="y">{{ y }}</span>
      </div>
      <div class="article-form-data-content">
        <input type="text"
               class="form-control fixed-width-sm"
               [id]="'article-model2-' + model2"
               [formControlName]="model2"
               (change)="change(model2)"
               [ngClass]="{red_alert: error2}">
      </div>
      <div class="article-form-data-content" *ngIf="!!unit || !!unit2">
        <div class="article-form-data-unit-block">{{ unit }}{{ unit2 }}</div>
      </div>
    </div>
  </div>


</div>
