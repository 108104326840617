import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OposHistory, OposHistoryCorrection} from '../opos.models';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OposHistoryService {
  constructor(private http: HttpClient) {
  }

  history(invoiceId: number): Observable<OposHistory[]> {
    return this.http.get<OposHistory[]>(
      `${environment.apiv4uri}opos/history/${invoiceId}/list`,
    );
  }

  historyCorrection(correctionId: number): Observable<OposHistoryCorrection[]> {
    return this.http.get<OposHistoryCorrection[]>(
        `${environment.apiv4uri}opos/history/correction/${correctionId}/list`,
    );
  }

  createNote(invoiceId: number, form: any): Observable<OposHistory> {
    return this.http.post<OposHistory>(
      `${environment.apiv4uri}opos/history/${invoiceId}`,
      form,
    );
  }

  createNoteCorrection(correctionId: number, form: any): Observable<OposHistoryCorrection> {
    return this.http.post<OposHistoryCorrection>(
      `${environment.apiv4uri}opos/history/correction/${correctionId}`,
      form,
    );
  }
}
