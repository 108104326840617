import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {PriceIncreaseFlex} from '../../models/price-increase-flex.model';
import {PriceIncreaseFlexService} from '../../../price-increase-flex/price-increase-flex.service';
import {EnvisiaObject} from '../../../../common/wrapper.models';
import {from, tap} from 'rxjs';

@Component({
  selector: 'workflow-price-increase-flex-box',
  templateUrl: './workflow-price-increase-flex-box.component.html',
})
export class WorkflowPriceIncreaseFlexBoxComponent implements OnInit {
  @Input() workflowId: number;
  @Input() priceIncreaseFlex?: PriceIncreaseFlex;
  @Output() update: EventEmitter<PriceIncreaseFlex | null> = new EventEmitter<PriceIncreaseFlex | null>();

  priceIncreaseLoader: LoadingWrapper<EnvisiaObject<PriceIncreaseFlex | null>>;

  constructor(private service: PriceIncreaseFlexService) {
  }

  ngOnInit(): void {
    if (!!this.priceIncreaseFlex) {
      this.priceIncreaseLoader = new LoadingWrapper<EnvisiaObject<PriceIncreaseFlex | null>>(
        from([{object: this.priceIncreaseFlex}])
      );
    } else {
      this.reload();
    }
  }

  reload(): void {
    this.priceIncreaseLoader = new LoadingWrapper<EnvisiaObject<PriceIncreaseFlex | null>>(
      this.service.get(this.workflowId).pipe(tap(pif => {
        this.priceIncreaseFlex = pif.object;
        this.update.next(pif.object);
      }))
    );
  }

}
