import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticleLockService {

  constructor(private http: HttpClient) {
  }

  lockWithFile(form: FormData): Observable<void> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/octet-stream');
    return this.http.post<void>(environment.apiv4uri + 'article-config/file-lock', form, {headers: headers});
  }

  unlockWithFile(form: FormData): Observable<void> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/octet-stream');
    return this.http.post<void>(environment.apiv4uri + 'article-config/file-unlock', form, {headers: headers});
  }
}
