import {NgModule} from '@angular/core';
import {SharedModule} from '../../core/shared.module';
import {PdfHistoryModalComponent} from './pdf-history-modal.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        PdfHistoryModalComponent
    ]
})
export class HistoryModule {
}
