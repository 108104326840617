<div class="buttonbar-view">

  <article-button-navigation [article]="article"
                             [locked]="locked"
                             [withLock]="false"
                             (saveEmitter)="confirmModal()"
                             (generateSdsEmitter)="generatedSds($event)"
                             (generateSisterEmitter)="generatedSister($event)">
  </article-button-navigation>

</div>

<div class="article-fixed-head">
  <article-top-navigation [article]="article"
                          [workflowId]="workflowId"
                          [multilayerPlan]="multilayerPlan">
  </article-top-navigation>

  <article-top [edit]="false" [article]="article"></article-top>
</div>

<div class="clearfix article-content">&nbsp;</div>

<article-extra-grouped [schema]="schema"
                       [data]="values"
                       [article]="article"
                       [mainType]="'article'"
                       (deleteSectionEmitter)="onDeleteSection($event)"
                       (saveEmitter)="updateForm($event)">
</article-extra-grouped>
