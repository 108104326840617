import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DunningLockModalComponent} from './dunning-lock-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DunningLockModalService {

  constructor(private modalService: NgbModal) {
  }

  open(invoiceId: number, isLocked: boolean): Promise<boolean | null | undefined> {
    const modalRef = this.modalService.open(DunningLockModalComponent, {windowClass: 'modal2-xs'});
    modalRef.componentInstance.invoiceId = invoiceId;
    modalRef.componentInstance.isLocked = isLocked;
    return modalRef.result;
  }
}
