import {Component, Input, OnInit} from '@angular/core';
import {BusinessOrder} from '../models/business-order.models';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {MaterialArticleBookedInventory, MaterialFieldWithValue} from '../../../material/material-management.model';
import {BusinessOrderMaterialService} from '../services/business-order-material.service';
import {Money} from '../../../../common/money/Money';

@Component({
  selector: 'business-order-view-material-box',
  templateUrl: './business-order-view-material-box.component.html',
})
export class BusinessOrderViewMaterialBoxComponent implements OnInit {
  @Input() ba: BusinessOrder;
  loadingWrapper: LoadingWrapper<MaterialArticleBookedInventory[]>;

  constructor(private service: BusinessOrderMaterialService) {
  }

  ngOnInit(): void {
    this.listMaterial();
  }

  listMaterial(): void {
    this.loadingWrapper = new LoadingWrapper<MaterialArticleBookedInventory[]>(
      this.service.listMaterial(this.ba)
    );
  }

  formatValue(fieldValue: MaterialFieldWithValue): string {
    if (fieldValue.data_type === 'Option') {
      return (fieldValue.value as boolean) ? 'Ja' : 'Nein';
    } else if (fieldValue.data_type === 'Ganzzahl' || fieldValue.data_type === 'Kommazahl') {
      return Money.stringify(fieldValue.value as number);
    } else {
      return fieldValue.value.toString();
    }
  }

}
