<div class="row">

  <a uiSref="a.calculation.list" [uiParams]="{id: process.id}" ngbTooltip="Zurück">
    <h2><i class="fa fa-chevron-left"></i>&nbsp;{{ process.name }} - {{ layout.name }}</h2>
  </a>

  <button class="btn btn-default" (click)="create()"><i class="fa fa-plus fa-2x"></i></button>

</div>


<div class="row">
  &nbsp;
</div>

<table class="max-w-full">
  <thead>
  <tr>
    <th>&nbsp;</th>
    <th class="font-normal p-1"
        [ngClass]="{'align-right': header?.validator !== 'STRING'}"
        *ngFor="let header of headers">
      {{ header.name }}
    </th>
    <th>&nbsp;</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of table; let parentIndex = index">

    <td class="align-right pl-1 pr-1" [ngClass]="{active: parentIndex === active}" (click)="setActive(parentIndex)">
      {{ parentIndex + 1 }}
    </td>

    <td *ngFor="let column of row; let index = index" [ngClass]="{'red_alert': parentIndex === active}">
      <input type="text"
             name="row-{{ column.id }}"
             class="w-full pt-1 pb-1 pl-2 pr-2 border bg-transparent"
             [ngClass]="{red_alert: column.error, 'align-right': column?.field?.validator !== 'STRING'}"
             [(ngModel)]="column.value"
             (change)="edit(column)">
    </td>

    <td>
      <i *ngIf="parentIndex === active" class="fa fa-trash fa-2x click" (click)="deleteRow(parentIndex)"></i>
    </td>

  </tr>
  </tbody>
</table>
