import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MaterialSelectLister} from './material-planning.model';

@Injectable({
  providedIn: 'root'
})
export abstract class MaterialPlanningListAbstractService {
  abstract list(groupId: number, query?: any): Observable<MaterialSelectLister>;
}
