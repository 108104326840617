import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {MaterialManagementService} from '../../material-management.service';
import {MaterialStorage} from '../../material-management.model';
import {NumberHelper} from '../../../../helper/number-helper';

@Component({
  selector: 'material-inventory-change-modal',
  templateUrl: './material-inventory-change-modal.component.html'
})
export class MaterialInventoryChangeModalComponent implements OnInit {
  @Input() storage: MaterialStorage;
  @Input() konsi?: boolean;
  @Output() closeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  errors?: { [key: string]: any; } = {};
  lockSubmission = false;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private mmService: MaterialManagementService) { }

  ngOnInit(): void {
    const price = NumberHelper.saveParseFloat(this.storage.price);
    this.form = this.fb.group({
      transaction_type: [0],
      variation_id: [this.storage.variation_id],
      location: [this.storage.location],
      charge: [this.storage.charge],
      date_received: [this.storage.date_received],
      amount: [null, Validators.required],
      konsi: [this.konsi],
      note: [null],
      price: [price?.toLocaleString('de-DE').replace('.', ''), {emitEvent: false}],
      order_transaction_id: [this.storage.order_transaction_id, {emitEvent: false}],
    });
  }

  submit(): void {

    if (this.form.valid) {
      this.lockSubmission = true;
      this.errors = {};
      this.mmService.insertTransaction(this.form.value).subscribe(() => {
        this.alertService.add(
          'success',
          'Manuelle Anpassung wurde vorgenommen'
        );
        this.lockSubmission = false;
        this.closeEmitter.emit(true);
      }, response => {
        switch (response.status) {
          case 400:
            if (response.error) {
              this.errors = response.error;
            }
            break;
          case 404:
            this.errors['obj.variation_id'] = [{msg: ['error.variation.not.found'], args: []}];
            break;
          default:
            this.alertService.add('danger', 'Unbekannter Fehler beim übertragen.');
        }

        this.lockSubmission = false;
      });
    }
  }

  dismiss(): void {
    this.closeEmitter.emit(false);
  }
}
