import {Component, Input, OnInit} from '@angular/core';
import {MergedFrame} from '../../models/frame.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FetchService} from '../../workflow-services/fetch.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'contract-create-fetch-add-modal',
  templateUrl: './contract-create-fetch-add-modal.component.html'
})
export class ContractCreateFetchAddModalComponent implements OnInit {
  @Input() frame: MergedFrame;
  country = 'Deutschland';
  form: UntypedFormGroup;

  constructor(private fetchService: FetchService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (this.frame.typ === 'cn') {
      this.country = 'China';
    }
    this.form = this.fb.group({
      quanity: [null],
      delivery: [null],
    });
  }

  save(): void {
    this.fetchService.create(this.frame.id, this.form.value).subscribe(value => {
      this.activeModal.close(value);
    }, (response: HttpErrorResponse) => {
      if (
        (response.status === 400) &&
        ((response.error?.quanity?.length ?? 0) > 0) &&
        (response.error.quanity[0] === 'amount.negative')
      ) {
        this.alertService.add('danger', 'Menge darf nicht negativ sein!');
      } else {
        this.alertService.add('danger', 'Konnte Artikel Abruf nicht hinzufügen');
      }
    });
    console.log('FormValue:', this.form.value);
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

}
