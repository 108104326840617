import {Component, Input} from '@angular/core';
import {OrderDataReceived} from '../../order-request.models';

@Component({
  selector: 'order-box-history-received',
  templateUrl: './order-box-history-received.component.html',
})
export class OrderBoxHistoryReceivedComponent {

  @Input() type: string;
  @Input() history: OrderDataReceived;

  constructor() {
  }
}
