<form #changeForm="ngForm" name="form">
  <div class="filter-row w-full">
    <div class="filter-left">
      <button class="btn btn-default btn-sm" type="button" (click)="setShowLockedOnly(false)" [ngClass]="{active: !showLockedOnly}">
        Aktiv
      </button>

      <button class="btn btn-warning btn-sm" type="button" (click)="setShowLockedOnly(true)" [ngClass]="{active: showLockedOnly}">
        Gesperrt
      </button>

      <ev-file-button [url]="downloadUrl('/crm/v1/customer/export/')" [link]="false"
                      *ngIf="('crm.customer.export'|HasPerm)">
        <i class="fa fa-download"></i>
      </ev-file-button>
    </div>
  </div>

  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'id', 'DESC')" [href]="sortUrl('id', 'DESC')">
          Kunden-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'name', 'DESC')" [href]="sortUrl('name', 'DESC')">
          Kunde
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'street', 'ASC')" [href]="sortUrl('street', 'ASC')">
          Straße
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'zip', 'ASC')" [href]="sortUrl('zip', 'ASC')">
          Plz
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'city', 'ASC')" [href]="sortUrl('city', 'ASC')">
          Ort
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'country', 'ASC')" [href]="sortUrl('country', 'ASC')">
          Land
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'phone', 'ASC')" [href]="sortUrl('phone', 'ASC')">
          Telefon
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'mail', 'ASC')" [href]="sortUrl('mail', 'ASC')">
          E-Mail
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'field_service', 'ASC')" [href]="sortUrl('field_service', 'ASC')">
          Vertriebsgebiet
        </a>
      </th>
      <th>Branche</th>
      <th>Kategorie</th>
    </tr>

    <tr class="filterrow">
      <th>
        <input type="text" class="form-control input-sm" name="sid" [(ngModel)]="data.sid" [autofocus] autocomplete="off search-customer-id">
      </th>

      <th>
        <input type="text" class="form-control input-sm" name="name" [(ngModel)]="data.name">
      </th>

      <th>
        <input type="text" class="form-control input-sm" name="street" [(ngModel)]="data.street">
      </th>

      <th>
        <input type="text" class="form-control input-sm" name="zip" [(ngModel)]="data.zip">
      </th>
      <th>
        <input type="text" class="form-control input-sm" name="city" [(ngModel)]="data.city">
      </th>

      <th>
        <input type="text" class="form-control input-sm" name="country" [(ngModel)]="data.country">
      </th>

      <th>
        <input type="text" class="form-control input-sm" name="phone" [(ngModel)]="data.phone">
      </th>

      <th>
        <input type="text" class="form-control input-sm" name="mail" [(ngModel)]="data.mail">
      </th>

      <th>
        <input type="text" class="form-control input-sm" name="field_service" [(ngModel)]="data.field_service">
      </th>

      <th>
        <customer-list-industrial-sector-select [selected]="data.industrial_sectors"
                                                (changeSector)="updateStringValue('industrial_sectors', $event)">
        </customer-list-industrial-sector-select>
      </th>

      <th class="cat-stars">
        <customer-rating [rating]="data.rating"
                         [edit]="true"
                         (ratingChange)="updateStringValue('rating', $event)"
                         [list]="true">
        </customer-rating>
      </th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let customer of list.objects">
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}">
          {{ customer.id }}
          <span class="label label-warning" *ngIf="customer.locked">Gesperrt</span>
        </a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}">
          {{ customer.name }}
        </a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}">
          {{ customer.street }}
        </a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}">
          {{ customer.zip }}
        </a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}">
          {{ customer.city }}
        </a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}">
          {{ customer.country }}
        </a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}">
          {{ customer.phone }}
        </a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:customer.id}">
          {{ customer.mail }}
        </a>
      </td>

      <td>
        {{ customer.field_service }}
      </td>

      <td>
        {{ customer.industrial_sectors|JoinStringPipe }}
      </td>

      <td>
        <customer-rating [rating]="customer.rating" [edit]="false"></customer-rating>
      </td>

    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
