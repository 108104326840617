import {Component, DoCheck, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debug} from '../../../../helper/debug.func';

export const ARTICLE_TOP_SDS_INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleTopSdsInputComponent),
  multi: true,
};

@Component({
  selector: 'article-top-sds-input',
  template: `<input type="text" class="form-control simple-width fixed-width-md" id="article-sds-nr"
                    [value]="data"
                    [ngClass]="{red_alert: error}"
                    (keyup)="modelChange($event)"
                    (change)="changed()"
                    [readonly]="disabled">`,
  providers: [
    ARTICLE_TOP_SDS_INPUT_VALUE_ACCESSOR
  ],
  styles: ['.simple-width { width: 65px; }']
})
export class ArticleTopSdsInputComponent implements ControlValueAccessor, DoCheck {
  @Input() error: boolean;
  @Input() sdsDisabled: boolean;
  @Output() changeEmitter = new EventEmitter<void>();
  data: string | undefined | null;
  disabled = false;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  ngDoCheck(): void {
    if (this.sdsDisabled) {
      // we need to dirty check the sds disabled flag
      // since changes this deep do not get correctly
      // propagated trough the angular form
      this.disabled = true;
    }
  }

  writeValue(obj: any): void {
    if (obj === undefined || obj === null) {
      this.data = '';
    } else {
      this.data = obj;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  modelChange($event: KeyboardEvent): void {
    this._onChange(($event.target as HTMLInputElement).value);
  }

  changed(): void {
    this.changeEmitter.next(null);
  }

  setDisabledState(isDisabled: boolean): void {
    debug('setDisabledState', isDisabled);
    this.disabled = isDisabled;
  }

}
