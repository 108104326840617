import * as React from 'react';
import {OfferAnalysisMap} from './offer-analysis.model';
import {EvFormattedNumber} from '../../../../react/components/ev-formatted-number';

interface Props {
  groups: OfferAnalysisMap;
}

const resizedFontAndPadding = {fontSize: '250%', lineHeight: 1, paddingBottom: '2px'};
const noTopBorderAndPadding = {borderTop: 0, paddingTop: 0};

export function OfferAnalysisTop(props: Props): JSX.Element {
  const group = props.groups['total'][0];
  return <>
    <table className="table whitetable">
      <thead>
      <tr>
        <th className="align-right">Gesamt</th>
        <th className="align-right">Bestand</th>
        <th className="align-right">Neue Artikel</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td className="align-right bold" style={resizedFontAndPadding}>
          <EvFormattedNumber value={group.total.numeric}/>
        </td>
        <td className="align-right bold" style={resizedFontAndPadding}>
          <EvFormattedNumber value={group.existing.numeric}/>
        </td>
        <td className="align-right bold" style={resizedFontAndPadding}>
          <EvFormattedNumber value={group.novel.numeric}/>
        </td>
      </tr>
      <tr>
        <td style={noTopBorderAndPadding}/>
        <td className="align-right" style={noTopBorderAndPadding}>
          <EvFormattedNumber value={group.existing.percentual} decimals={2} unit="%"/>
        </td>
        <td className="align-right" style={noTopBorderAndPadding}>
          <EvFormattedNumber value={group.novel.percentual} decimals={2} unit="%"/>
        </td>
      </tr>
      </tbody>
    </table>
  </>;
}
