import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {LabelService} from '../label.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Customer} from '../../customer/model/customer';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'label-settings-customer',
  templateUrl: './label-settings-customer.component.html',
})
export class LabelSettingsCustomerComponent implements OnInit, OnDestroy {
  @Output() updateTemplate = new EventEmitter<string>();
  public currentCustomer: Customer | null;
  public form: UntypedFormGroup;
  private formSubscription?: Subscription;

  constructor(private labelService: LabelService,
              private alertService: AlertService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.updateTemplate.emit('');
    this.form = this.fb.group({
      'template': [null]
    });

    this.form.valueChanges.pipe(debounceTime(550)).subscribe(data => {
      this.updateTemplate.emit(data.template);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  updateCustomer(customer: Customer | null | undefined): void {
    if (customer === null || customer === undefined) {
      this.currentCustomer = null;
      this.form.patchValue({template: ''});
    } else {
      this.form.disable();
      this.labelService.getCustomer(encodeURIComponent(customer.name)).subscribe(response => {
        this.currentCustomer = customer;
        this.form.enable();
        this.form.patchValue({template: response});
      }, () => {
        this.form.enable();
        this.form.patchValue({template: ''});
        this.alertService.add('danger', 'Es ist ein Fehler aufgetreten');
      });
    }
  }

  removeTemplate(): void {
    if (confirm('Möchten Sie das Template für den Kunden ' + this.currentCustomer.name + ' wirklich löschen?')) {
      this.labelService.saveCustomer({name: this.currentCustomer.name, template: null}).subscribe(() => {
        this.form.patchValue({template: ''});
        this.alertService.add('success', 'Template erfolgreich gelöscht!');
      }, () => {
        this.alertService.add('danger', 'Template konnte nicht gelöscht werden!');
      });
    }
  }

  submit(): void {
    const data = {
      name: this.currentCustomer.name,
      template: this.form.value.template
    };
    this.labelService.saveCustomer(data).subscribe(() => {
      this.alertService.add('success', 'Template erfolgreich gespeichert!');
    }, () => {
      this.alertService.add('danger', 'Template konnte nicht gespeichert werden!');
    });
  }

}
