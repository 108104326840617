<td>{{article.variation_id}}</td>
<td>{{article.vendor_article_id}}</td>
<td>{{article.article_name}}</td>
<td>{{article.article_name_order}}</td>
<td>{{article.unit}}</td>
<td [ngClass]="{'align-right': field.isValueType('Number')}" *ngFor="let field of fields">
  <ng-container [ngSwitch]="field.data_type" *ngIf="!(article.fields[field.html_name]|isNull)">
    <ng-container *ngSwitchCase="'Option'">
      <i class="fa" [ngClass]="{
          'fa-check':(article.fields[field.html_name] === true),
          'fa-times': (article.fields[field.html_name] === false)}"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'Ganzzahl'">
      {{ article.fields[field.html_name] | money:0 }}
    </ng-container>
    <ng-container *ngSwitchCase="'Kommazahl'">
      {{ article.fields[field.html_name] | money:3 }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ article.fields[field.html_name] }}
    </ng-container>
  </ng-container>
</td>
<td class="align-right">{{article.amount|money}}</td>
<td class="align-right">{{article.amount_ordered|money}}</td>
<td class="align-right">{{article.min_stock|money}}</td>
<td class="align-right">{{article.min_order|money}}</td>
<td>
  <button type="button" class="btn btn-success btn-sm" (click)="addArticle()" [disabled]="inList">
    Hinzufügen
  </button>
</td>
