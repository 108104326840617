import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BusinessOrderService, dateBefore} from '../services/business-order.service';
import {Article} from '../../../article/models/article.models';
import {ExtendedFrame} from '../../models/frame.models';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderGoldDateErrorService} from './business-order-gold-date-error.service';
import {debug} from '../../../../helper/debug.func';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'business-order-create-modal',
  templateUrl: './business-order-create-modal.component.html',
})
export class BusinessOrderCreateModalComponent implements OnInit {
  @Input() article: Article;
  @Input() frame?: ExtendedFrame;
  @Input() workflowId: number;
  @Input() data: {
    start_date: string,
    end_date: string,
    gold_date?: string,
    utilize_quantity: number;
    amount: number,
    first_contract: boolean,
    express: boolean,
    ordered_quantity: number,
    custom_delivery_quantities: boolean,
    custom_delivery_note: string | null,
    over_delivery: number | null,
    under_delivery: number | null,
  };
  @Input() fetches: number[];
  finalBaForm: UntypedFormGroup;
  locked = false;

  constructor(private fb: UntypedFormBuilder,
              private ngbActiveModal: NgbActiveModal,
              private service: BusinessOrderService,
              private alertService: AlertService,
              private goldDateService: BusinessOrderGoldDateErrorService) {
  }

  ngOnInit(): void {
    const utilize = Math.floor(this.data.utilize_quantity / this.data.amount);
    const utilize_rest = this.data.utilize_quantity % this.data.amount;

    // fill the form with values
    const orders = [];
    for (let i = 0; i < this.data.amount; i++) {
      // the first ba should have the same amount of utilizes as others + the rest non splittable utilize
      const inner_utilize = i === 0 ? utilize + utilize_rest : utilize;
      const innerForm = {
        utilize: [inner_utilize, Validators.compose([Validators.required, Validators.min(0)])],
        start_date: [this.data.start_date, Validators.required],
        end_date: [this.data.end_date, Validators.required],
        gold_date: [this.data.gold_date],
        first_contract: [this.data.first_contract, Validators.required],
        express: [this.data.express, Validators.required],
      };
      orders.push(this.fb.group(innerForm, {validator: dateBefore}));
    }
    this.finalBaForm = this.fb.group({
      orders: this.fb.array(orders)
    });
  }

  submit() {
    if (this.locked) {
      return;
    }

    this.locked = true;

    const data = {
      amount: this.data.amount,
      utilize_quantity: this.data.utilize_quantity,
      orders: this.finalBaForm.value.orders,
      ordered_quantity: this.data.ordered_quantity,
      fetches: this.fetches,
      article: this.article.oa_nr,
      workflow_id: Number(this.workflowId),
      without_frame: !this.frame,
      custom_delivery_quantities: this.data.custom_delivery_quantities,
      custom_delivery_note: this.data.custom_delivery_note,
      over_delivery: this.data.over_delivery,
      under_delivery: this.data.under_delivery,
    };
    this.service.create(data).subscribe(success => {
      this.locked = false;
      this.alertService.add('success', 'Betriebsaufträge erfolgreich erstellt');
      this.ngbActiveModal.close(success.bas);
    }, (response: HttpErrorResponse) => {
      this.locked = false;
      debug('Response:', response.error);
      if ('invalid.gold_date' in response.error) {
        this.goldDateService.open();
      }
      this.alertService.add('danger', 'Beim Erstellen der Betriebsaufträge ist ein Fehler aufgetreten!');
    });
  }

  /** Form Getter */
  get orders(): UntypedFormArray {
    return this.finalBaForm.get('orders') as UntypedFormArray;
  }

}
