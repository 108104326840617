import {Component, ErrorHandler, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ErpProducer} from '../../../../producer/producer.models';
import {OrderRequest, OrderRequestForm, OrderRequestView} from '../../../request/workflow-request.model';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import Helper from '../../../../../helper/helper';
import {buildInnerGroup, createNumberValidator} from './request-form.builds';
import {WorkflowRequestService} from '../../../workflow-services/workflow-request.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'order-request-main-request-form',
  templateUrl: './order-request-main-request-form.component.html',
})
export class OrderRequestMainRequestFormComponent implements OnInit, OnDestroy {
  @Input() data: OrderRequestView;
  @Input() producerList: ErpProducer[];
  @Input() workflowPositions: { quantity: number, lose: number }[];
  @Output() updateNoteEmitter = new EventEmitter<string>();
  @Output() createRequestEmitter = new EventEmitter<OrderRequest[]>();
  noteSubscription?: Subscription;
  errors: { [key: string]: any; } = {};
  creating: boolean;

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private workflowRequestService: WorkflowRequestService,
              private alertService: AlertService,
              private errorHandler: ErrorHandler) {
  }

  get positionForm() {
    return this.form.get('positionForm') as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'producers': this.createProducersForm(),
      'delivery_date': [null],
      'note': [this.data.workflow.request_hint],
      'positionForm': this.createPositionsForm(),
    });

    this.noteSubscription = this.form.get('note').valueChanges.pipe(debounceTime(550)).subscribe(values => {
      this.updateNoteEmitter.emit(values);
    });
  }

  ngOnDestroy(): void {
    if (this.noteSubscription) {
      this.noteSubscription.unsubscribe();
    }
  }

  createRequest(): void {
    this.errors = {};
    this.creating = true;
    const finalForm = this.buildForm();
    this.workflowRequestService.create(this.data.workflow.id, finalForm).subscribe(response => {
      this.creating = false;
      this.createRequestEmitter.emit(response.objects);
      this.alertService.add('success', 'Anfrage(n) erfolgreich erstellt!');
    }, (response: HttpErrorResponse) => {
      this.creating = false;
      this.errors = response.error;
      this.alertService.add('danger', 'Anfrage(n) konnte(n) nicht erstellt werden!');
      this.errorHandler.handleError('createRequest failed: ' + JSON.stringify(response));
    });
  }

  private createPositionsForm(): UntypedFormGroup {
    const positionsFormData = [];
    this.workflowPositions.forEach(data => {
      const fg = this.fb.group({
        'quantity': [data.quantity, createNumberValidator()],
        'lose': [data.lose, createNumberValidator()],
      });
      positionsFormData.push(fg);
    });

    if (this.workflowPositions.length === 0) {
      positionsFormData.push(buildInnerGroup(this.fb));
    }

    const singleValueValidator: ValidatorFn = (control: AbstractControl) => {
      const array = (control as UntypedFormArray);

      return array.length > 0 ? null : {'positions': 'error'};
    };

    return this.fb.group({
      'positions': this.fb.array(positionsFormData, {validators: singleValueValidator})
    });
  }

  private createProducersForm(): UntypedFormGroup {
    const fg = {};
    this.producerList.forEach(producer => {
      fg[producer.name] = [false];
    });

    const singleValueValidator: ValidatorFn = (control: AbstractControl) => {
      let i = 0;
      Helper.keys(control.value, (value, key) => {
        if (value) {
          i++;
        }
      });

      return i > 0 ? null : {'producers': 'empty'};
    };

    return this.fb.group(fg, {validators: singleValueValidator});
  }

  private buildForm(): OrderRequestForm {
    const producers = [];
    Helper.keys(this.form.value.producers, (value, key) => {
      if (value) {
        producers.push(key);
      }
    });

    return {
      'producers': producers,
      'delivery_date': this.form.value.delivery_date,
      'note': this.form.value.note,
      'positions': this.form.value.positionForm.positions,
    };
  }

}
