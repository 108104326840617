import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MaterialSupplier} from '../../lazy/material-supplier/material-supplier.model';

export const valueTypes: string[] = [
  'Text',
  'Auswahl',
  'Option',
  'Datum',
  'Ganzzahl',
  'Positive Ganzzahl',
  'Kommazahl',
  'Positive Kommazahl'
];
export const transactionTypes: string[] = [
  'Manuelle Anpassung',
  'BA Materiallager Buchung',
  'Umbuchung',
  'Wareneingang',
  'Bestands-Typ Anpassung',
  'Lagerplatz Umbuchung',
  'Preisänderung',
  'ERP Terminal Buchung',
];

export class MaterialValueType {
  id: number;
  name: string;
  data_type: string;
  read_only: boolean;
  value?: any;
  choices?: string[];
  html_name: string;
  constructor(id: number, name: string, data_type: string, read_only: boolean, value?: any, choices?: string[]) {
    this.id = id;
    this.name = name;
    this.data_type = data_type;
    this.value = value;
    this.choices = choices;
    this.read_only = read_only;
    this.html_name = 'field_' + id;
  }

  getValueType(): string {
    switch (this.data_type) {
      case(valueTypes[0]):  return 'text';
      case(valueTypes[1]):  return 'choices';
      case(valueTypes[2]):  return 'checkbox';
      case(valueTypes[3]):  return 'date';
      case(valueTypes[4]):  return 'integer';
      case(valueTypes[5]):  return 'integer';
      case(valueTypes[6]):  return 'float';
      case(valueTypes[7]):  return 'float';
      default:              return 'text';
    }
  }

  isValueType(type: string): boolean {
    switch (type) {
      case 'String':
        return (
          this.data_type === valueTypes[0] ||
          this.data_type === valueTypes[1]
        );
      case 'Number':
        return (
          this.data_type === valueTypes[4] ||
          this.data_type === valueTypes[5] ||
          this.data_type === valueTypes[6] ||
          this.data_type === valueTypes[7]
        );
      case this.data_type:
        return true;
      default:
        return false;
    }
  }

  getDefaultValue(originalFieldValues?: MaterialArticleFormElement[]) {
    if (originalFieldValues) {
      const fieldValue = originalFieldValues.find((field) => field && field.id === this.id);
      if (fieldValue) {
        return fieldValue.value;
      } else if (this.isValueType('Option')) {
        return false;
      } else {
        return null;
      }
    } else {
      if (this.value) {
        return this.value;
      } else if (this.isValueType('Option')) {
        return false;
      } else {
        return null;
      }
    }
  }

  buildFormGroup(fb: UntypedFormBuilder, originalFields?: MaterialArticleFormElement[], readOnly?: boolean): UntypedFormGroup {
    return fb.group({
      'id': [this.id, {options: {emitEvent: false}}],
      'value': [{value: this.getDefaultValue(originalFields), disabled: this.read_only && readOnly}]
    });
  }
}

export interface MaterialGroupListElement {
  id: number;
  group_id: string;
  name: string;
  username: string;
  date_created: string;
  date_deleted: string;
  has_expiration: boolean;
  show_in_planning: boolean;
  show_in_app: boolean;
}

export interface MaterialGroupListElementSelectable extends MaterialGroupListElement {
  is_selected: boolean;
  is_disabled?: boolean;
}

export interface MaterialGroupForm {
  name: string;
}

export interface MaterialFieldForm {
  id?: number;
  name: string;
  data_type: string;
  is_variation: boolean;
  choices?: string[];
  read_only: boolean;
  on_label: boolean;
  on_ba_list: boolean;
  relations: {
    group_ids: number[];
    all_groups: boolean;
  };
}

export interface MaterialFieldListElement {
  id: number;
  name: string;
  data_type: string;
  is_variation: boolean;
  choices?: string[];
  read_only: boolean;
  on_label: boolean;
  on_ba_list: boolean;
  username: string;
  date_created: string;
  date_deleted?: string;
}

export interface MaterialArticle {
  id: number;
  article_id: string;
  name: string;
  name_order: string;
  group?: string;
  group_id: number;
  supplier_id: number;
  konsi: boolean;
  article_fields: MaterialArticleFormElement[];
  username: string;
  date_created: string;
  date_deleted?: string;
  supplier?: MaterialSupplier;
}

export interface MaterialArticleListElement extends MaterialArticle {
  supplier_name: string;
}

export interface MaterialVariation {
  id?: number;
  variation_id?: string;
  article_id?: number;
  vendor_article_id: string;
  vendor_price: number;
  unit: string;
  min_stock: number;
  min_order?: number;
  lead_time: number;
  variation_fields: MaterialArticleFormElement[];
  username?: string;
  date_created?: string;
  date_deleted?: string;
  favorite: boolean;
  ean?: string;
  inventory?: MaterialInventory;
  article?: MaterialArticle;
}

export interface MaterialAggregation {
  variation: MaterialVariation;
  article: MaterialArticle;
  group: MaterialGroupListElement;
  article_fields: MaterialFieldWithValue[];
  variation_fields: MaterialFieldWithValue[];
}

export interface MaterialVariationAggregation {
  variation_id?: number;
  article_id?: number;
  group_id: number;
  string_id: string;
  vendor_article_id?: string;
  article_name?: string;
  group_name: string;
  matching_fields?: string[];
}

export interface MaterialArticleForm {
  name: string;
  group_id: number;
  vendor: string;
  article_fields: any;
}

export interface MaterialArticleVariationsForm {
  article_form: MaterialArticleForm;
  variation_forms?: MaterialVariation[];
}

export interface MaterialArticleFormElement {
  id: number;
  value: any;
}

export interface MaterialArticleFieldForm {
  id: number;
  name: string;
  data_type: string;
  choices?: string[];
  read_only: boolean;
  value: any;
}

export interface MaterialFieldAttributes {
  field: MaterialFieldListElement;
  groups: MaterialGroupListElementSelectable[];
  all_groups: boolean;
}

export interface MaterialArticleVariationUpsertResponse {
  article: MaterialArticle;
  variations_list: MaterialVariation[];
}

export interface MaterialInventory {
  id: number;
  variation_id: string;
  vendor_article_id: string;
  article_name: string;
  group_name: string;
  charges: string[];
  locations: string[];
  amount: number;
  unit: string;
  min_stock: number;
  min_order: number;
  fields: { [key: string]: any };
  status: number;
  supplier_id: number;
  supplier_name: string;
  amount_ordered: number;
  amount_confirmed: number;
  article_name_order?: string;
  favorite: boolean;
  ean?: string;
}

interface MaterialStorageLocationId {
  variation_id: number;
  location: string;
  charge?: string;
  date_received: string;
  price?: string | number;
  order_transaction_id?: number;
}

export interface MaterialTransaction extends MaterialStorageLocationId {
  id?: number;
  variation_id: number;
  transaction_type: number;
  location: string;
  charge?: string;
  amount: number;
  konsi?: boolean;
  bo_id?: number;
  bo_index?: number;
  bo_step?: number;
  bo_ordering?: number;
  note?: string;
  username?: string;
  date_transferred: string;
  date_received: string;
  price?: string | number;
  order_transaction_id?: number;
}

export interface MaterialStorage extends MaterialStorageLocationId {
  id: number;
  variation_id: number;
  location: string;
  charge: string;
  amount: number;
  amount_storage: number;
  amount_konsi: number;
  date_updated: string;
  date_expiration: string;
  date_received: string;
  konsi: boolean;
  amount_intake: number;
  days_stored: number;
  price?: string | number;
  order_transaction_id?: number;
  order_position_id?: number;
  order_id?: number;
}

export interface MaterialArticleVariationError {
  error_type: string;
  data?: any;
}

export interface MaterialArticleError {
  error_type: string;
  data?: any;
}

export interface MaterialVariationError {
  error_type: string;
  index: number;
  data?: any;
}

export interface MaterialFieldError {
  error_type: string;
  data?: { field: MaterialFieldListElement } | any;
}

export interface MaterialUnit {
  id?: number;
  unit: string;
  username?: string;
  date_created?: string;
  date_deleted?: string;
}

export interface MaterialFieldWithValue {
  data_type: string;
  name: string;
  value: string | number | boolean;
}

export interface MaterialArticleBookedInventory {
  id: number;
  variation_id: string;
  article_name: string;
  unit: string;
  article_fields: MaterialFieldWithValue[];
  variation_fields: MaterialFieldWithValue[];
  charge?: string;
  amount: number;
  bo_id: number;
  bo_index: number;
  bo_ordering: number;
  bo_step: number;
  bo_step_nr: number;
  bo_step_name: string;
}

export interface MaterialTransferStockForm {
  transaction_type: number;
  location: string;
  amount: number;
  konsi?: boolean;
}
