<div class="row">
  <div class="whitelist col-md-6">
    <h2>
      <i class="fa fa-table"></i>&nbsp;Kalkulationstabellen
    </h2>

    <a uiSref="a.calculation.list"
       [uiParams]="{id: process.id}"
       [uiOptions]="{reload: false}"
       (click)="setCurrentElement(process.id)"
       *ngFor="let process of processes">
      <div class="whitelist-item" [ngClass]="{'selected': process.id === current?.id}">
        <div class="whitelist-item-detail">
          <span *ngIf="process.hidden" class="label label-danger">ausgeblendet</span> {{ process.name }}
        </div>
      </div>
    </a>
  </div>

  <div class="col-md-3" *ngIf="current">
    <h2>
      <span *ngIf="current.hidden" class="label label-danger">ausgeblendet</span>
      {{ current.name }}
    </h2>

    <div class="whitelist calculationlist">
      <ng-container *ngFor="let layout of current.layouts">
        <a uiSref="a.calculation.table.view" [uiParams]="{id: current.id, table: layout.id}">
          <div class="whitelist-item">
            <div class="whitelist-item-detail">
              {{ layout.name }}
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </div>

  <div class="col-md-3" *ngIf="!!current">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="btn btn-danger btn-md pull-left" (click)="openModal()">
          Kalkulation kopieren
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12"></div>

      <div class="col-md-12">
        <button type="button" class="btn btn-warning btn-md pull-left" (click)="toggleHide()">
          Kalkulation
          <span *ngIf="!current.hidden">ausblenden</span>
          <span *ngIf="current.hidden">anzeigen</span>
        </button>
      </div>
    </div>

    <calculation-list-form [current]="current" (reload)="reload()" *ngIf="current.typ === 'Deutschland'">
    </calculation-list-form>
  </div>

</div>
