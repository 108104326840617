import {OfferRejectionForm} from './offer-rejection-models';
import {Offer} from '../../models/offer.models';
import {AxiosHttpClient} from '../../../auth/axios-http-client';
import {environment} from '../../../../../environments/environment';

export class OfferRejectionAxiosService {
  constructor() {
  }

  static reject(offerId: number, form: OfferRejectionForm): Promise<Offer> {
    return AxiosHttpClient.put<Offer | null>(
      `${environment.apiv4uri}offer/${offerId}/reject`,
      form,
    );
  }
}
