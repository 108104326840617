<td>
  <p>{{ pos.label }}</p>
  <p *ngIf="!!pos.desextension">{{ pos.desextension }}</p>
</td>
<td class="right">{{ pos.quantity|money }}</td>
<td class="right">{{ pos.price|money:3 }}&nbsp;€</td>
<td class="right" *ngIf="(pos.typ==='article') && hasPriceIncrease">
  {{ pos.price_with_increase|money:3 }}&nbsp;€
</td>
<td class="right" *ngIf="(pos.typ!=='article') && hasPriceIncrease"></td>
<td class="right">{{ pos.price_total|money:2 }}&nbsp;€</td>
