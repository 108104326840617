import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusinessOrderGoodPieceComponent} from '../../good-piece/business-order-good-piece.component';
import {BusinessOrder, BusinessOrderAdditional} from '../../models/business-order.models';
import {BusinessOrderGoodpieces} from './business-order-view-goodpiece-box.models';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';
import {BusinessOrderViewGoodpieceService} from './business-order-view-goodpiece.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {of} from 'rxjs';

@Component({
  selector: 'business-order-view-goodpiece-box',
  templateUrl: './business-order-view-goodpiece-box.component.html',
})
export class BusinessOrderViewGoodpieceBoxComponent implements OnInit {
  @Input() ba: BusinessOrder;
  @Input() additional: BusinessOrderAdditional;
  @Input() goodpieces?: LoadingWrapper<BusinessOrderGoodpieces>;
  @Output() goodpiecesChange: EventEmitter<BusinessOrderGoodpieces> = new EventEmitter<BusinessOrderGoodpieces>();

  constructor(private service: BusinessOrderViewGoodpieceService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.goodpieces = (
      this.goodpieces ??
      new LoadingWrapper<BusinessOrderGoodpieces>(this.service.get(this.ba.id, this.ba.index))
    );
  }

  reloadGoodpieces(): void {
    this.service.get(this.ba.id, this.ba.index).subscribe(g => {
      this.goodpieces = new LoadingWrapper<BusinessOrderGoodpieces>(of(g));
    });
  }

  openGoodPieceModal(): void {
    const modalRef = this.modalService.open(BusinessOrderGoodPieceComponent);
    modalRef.componentInstance.ba = this.ba;
    modalRef.result.then(() => {
      this.reloadGoodpieces();
    }, () => {
      this.reloadGoodpieces();
    });
  }

}
