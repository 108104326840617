import {Injectable} from '@angular/core';
import {Observable, forkJoin, throwError, of} from 'rxjs';
import {MailHeaderData} from './mail.models';
import {WorkflowService} from '../workflow/workflow-services/workflow.service';
import {CustomerService} from '../customer/services/customer.service';
import {ContactService} from '../customer/services/contact.service';
import {FieldServiceService} from '../customer/services/field-service.service';
import {Workflow} from '../workflow/models/workflow.models';
import {Contact} from '../customer/model/contact';
import {Customer} from '../customer/model/customer';
import {map, mergeMap} from 'rxjs/operators';
import {noop} from '../../helper/noop';

@Injectable({providedIn: 'root'})
export class MailHeaderService {

  constructor(private workflowService: WorkflowService,
              private customerService: CustomerService,
              private contactService: ContactService,
              private fieldServiceService: FieldServiceService) {
  }

  customer(type: string, workflowId: number | null, language: string): Observable<MailHeaderData> {
    if (workflowId !== null) {
      return this.workflowService.detail(workflowId).pipe(
        mergeMap(val => {
          return forkJoin([
            this.create(type, val.object.customer_id),
            this.contact(val.object)
          ]);
        }),
        map(val => {
          return this.build(type, language, {customer: val[0].customer, emails: val[0].emails, contact: val[1]});
        })
      );
    } else {
      return throwError({type: -1, message: 'workflowId is null'});
    }
  }

  private build(type: string,
                language: string,
                val: { customer: Customer, emails: string[], contact?: Contact }): MailHeaderData {
    // sets a rcpt
    let rcpt: string = val.customer.mail;
    const mail = this.mailType(type, val.customer);
    if (mail !== null && mail !== undefined) {
      rcpt = mail;
    }
    if (val.contact && !mail) {
      rcpt = val.contact.mail ? val.contact.mail : val.customer.mail;
    }

    // sets a header
    let header = 'Sehr geehrte Damen und Herren,';
    if (language !== 'de') {
      header = 'Dear Sir or Madam,';
    }
    if (val.contact) {
      header = this.salution(val.contact.title, language) + ' ' + val.contact.last_name + ',';
    }

    return new MailHeaderData(rcpt, header, val.emails, false);
  }

  private create(type: string, customerId: number): Observable<{ customer: Customer, emails: string[] }> {
    return this.customerService.detail(customerId).pipe(
      mergeMap(val => this.emails(type, val))
    );
  }

  private emails(type: string, customer: Customer): Observable<{ customer: Customer, emails: string[] }> {
    if (type === 'offer' && customer.field_service !== undefined && customer.field_service !== null) {
      // if a field_service exists we try to get all emails
      return this.fieldServiceService.emails(customer.field_service).pipe(
        map(val => {
          return {customer: customer, emails: val};
        })
      );
    } else {
      return of({customer: customer, emails: []});
    }
  }

  private contact(workflow: Workflow): Observable<Contact | null> {
    if (workflow.contact_id) {
      return this.contactService.detail(workflow.contact_id);
    } else {
      return of(null);
    }
  }

  private salution(str: string, language: string): string {
    noop(this);
    if (language === 'de') {
      let first;
      if (str === 'Herr') {
        first = 'Sehr geehrter';
      } else if (str === 'Frau') {
        first = 'Sehr geehrte';
      } else {
        first = '';
      }
      return first + ' ' + str;
    } else {
      return 'Dear';
    }
  }

  private mailType(type: string, customer: Customer): string | null {
    noop(this);
    let mail: string | null = null;
    if (type === 'offer') {
      mail = customer.additional.offer_mail;
    } else if (type === 'contract') {
      mail = customer.additional.contract_mail;
    } else if (type === 'delivery') {
      mail = customer.additional.delivery_mail;
    } else if (type === 'invoice') {
      mail = customer.additional.invoice_mail;
    }

    return mail;
  }

}
