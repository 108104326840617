<div class="row">
  <div class="col-lg-12">
    <h3>Lieferbedinungen</h3>
  </div>
</div>

<div class="row">
  <div class="col-lg-6">
    <delivery-condition-ou-table tableName="Überlieferung" [deliveryConditionList]="overDelivery" *ngIf="!loading">
    </delivery-condition-ou-table>
  </div>

  <div class="col-lg-6">
    <delivery-condition-ou-table tableName="Unterlieferung" [deliveryConditionList]="underDelivery" *ngIf="!loading">
    </delivery-condition-ou-table>
  </div>
</div>

<div class="row">
  <div class="col-lg-12" *ngIf="!loading">
    <table class="table whitetable">
      <thead>
      <tr>
        <th colspan="2">BA Endtermin Steuerung</th>
      </tr>
      <tr>
        <th>Nutzen bis</th>
        <th>Tage vor Lieferung</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let obj of baTerminationDates">
        <td>{{ obj.utilized|money }}</td>
        <td>{{ obj.days_left|money }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="col-lg-12" *ngIf="loading">
  <i class="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
</div>
