import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BigFetchWithPosition} from '../../models/fetch.models';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';
import {DismissButtonComponent} from '../../../../core/dismiss-button/dismiss-button.component';
import {DeliveryForm, DeliveryMessage, SimpleInventory} from '../../models/delivery.models';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import Helper from '../../../../helper/helper';
import {HttpErrorResponse} from '@angular/common/http';
import {DeliveryService} from '../../workflow-services/delivery.service';
import {Workflow} from '../../models/workflow.models';
import {DeliveryModalService} from '../modals/delivery-modal.service';
import {DeliveryCreateModalData} from './delivery-create-modal.models';
import {debug} from '../../../../helper/debug.func';
import {InventoryStorage} from '../../../article/inventory/inventory.models';

@Component({
  selector: 'delivery-create-modal-form-inventory',
  templateUrl: './delivery-create-modal-form-inventory.component.html',
})
export class DeliveryCreateModalFormInventoryComponent extends DismissButtonComponent implements OnInit, OnDestroy {
  @Input() labelCount: number;
  @Input() data: DeliveryCreateModalData;
  @Input() fetches: BigFetchWithPosition[];
  @Input() storages: InventoryStorage[];
  @Input() workflow: Workflow;
  @Input() frozenQuantity: number;
  @Input() weightValues?: string[];
  @Output() closeEmitter = new EventEmitter<DeliveryMessage>();
  quantity = 0;
  form: UntypedFormGroup;
  formSubscription?: Subscription;
  errors: { [key: string]: any; } = {};
  storageData: { [key: number]: InventoryStorage } = {};

  constructor(private fb: UntypedFormBuilder,
              private deliveryModalService: DeliveryModalService,
              private deliveryService: DeliveryService) {
    super();
  }

  get inventoryArray(): UntypedFormArray {
    return this.form.get('inventory') as UntypedFormArray;
  }

  ngOnInit() {
    const formArray = this.initArrayAndData();
    this.form = this.fb.group({
      'inventory': this.fb.array(formArray),
      'note': [null, []]
    });

    this.formSubscription = this.form.valueChanges.pipe(debounceTime(700)).subscribe(value => {
      let quantity = 0;
      value.inventory.forEach(data => {
        if (typeof data === 'number') {
          quantity += data;
        }
      });
      this.quantity = quantity;
    });
  }

  ngOnDestroy(): void {
    debug('ngOnDestroy: Delivery Create Modal Form Inventory');
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  submit(clb: LoadingButtonEvent): void {
    const formValues = this.form.value;
    const inventoryArray: SimpleInventory[] = [];
    (formValues.inventory as number[]).forEach((value, index) => {
      // we need to have an if, and can't use a filter
      // because it would break the array index
      if (!Helper.undefined(value) && value > 0) {
        const storageValue = this.storageData[index];
        inventoryArray.push({
          date_code: storageValue.date_code,
          storage_bin_name: storageValue.storage_bin_name,
          storage_bin_number: storageValue.storage_bin_number,
          workflow_id: storageValue.workflow_id,
          quantity: value
        });
      }
    });

    const form: DeliveryForm = {
      nr: this.data.nr,
      delivery_date: this.data.delivery_date,
      hint: this.data.hint,
      fetch: this.data.fetch,
      inventory: inventoryArray,
      label_count: this.labelCount,
      weights: this.weightValues,
      note: this.form.value.note,
    };

    this.deliveryService.create(this.workflow.id, form).subscribe(response => {
      clb.callback(false);
      this.closeEmitter.emit(response);
    }, (response: HttpErrorResponse) => {
      clb.callback(false);
      this.deliveryModalService.openErrorModal(response.error.error);
    });
  }

  private initArrayAndData(): UntypedFormControl[] {
    const formControlArray: UntypedFormControl[] = [];
    this.storages.forEach((value, index) => {
      this.storageData[index] = value;
      const validators = Validators.compose([this.validateFormControl(value)]);
      formControlArray.push(this.fb.control('', validators));
    });
    return formControlArray;
  }

  private validateFormControl(value: InventoryStorage): ValidatorFn {
    return (control: AbstractControl) => {
      if (Helper.undefined(control.value) || value.quantity - control.value >= 0) {
        return null;
      }
      return {'invalid.count': 'quantity should not go below zero'};
    };
  }

}
