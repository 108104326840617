import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkflowIdListByArticleService {

  constructor(private http: HttpClient) {
  }

  public list(oaNr: string): Observable<number[]> {
    return this.http.get<number[]>(`${environment.apiv4uri}workflow/list/article/${oaNr}`);
  }
}
