export class ArrayHelper {
  static equals<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  static distinct<T>(arr: T[]): T[] {
    return arr.filter((v, i, acc) => acc.indexOf(v) === i);
  }
}
