import {Component, Input, OnInit} from '@angular/core';
import {ExtraCostList} from '../../models/offer-extra.models';
import {Subject} from 'rxjs';
import {BigFetchWithPosition} from '../../models/fetch.models';
import {MergedFrame} from '../../models/frame.models';
import {FetchService} from '../../workflow-services/fetch.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {Article} from '../../../article/models/article.models';

@Component({
  selector: 'contract-create-fetch-extra-add-modal',
  templateUrl: './contract-create-fetch-extra-add-modal.component.html',
  styleUrls: ['./contract-create-fetch-extra-add-modal.component.scss'],
})
export class ContractCreateFetchExtraAddModalComponent implements OnInit {
  @Input() frame: MergedFrame;
  @Input() article: Article;
  @Input() subject: Subject<{fetch: BigFetchWithPosition, index: number}>;
  @Input() extraCosts: ExtraCostList;
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private fetchService: FetchService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      fetch: [this.frame.fetch.length]
    });
  }

  add(name: string, extra?: number): void {
    const fetchIndex = this.form.value.fetch - 1;
    const fetch = this.frame.fetch.length < fetchIndex ? null : this.frame.fetch[fetchIndex];

    if (!fetch) {
      this.alertService.add('danger', 'Kein Abruf mit dieser Nummer vorhanden.');
      return;
    } else if (fetch.status === 5) {
      this.alertService.add('danger', 'Abruf ist bereits abgerechnet. Keine Zusatzkosten zubuchbar.');
      return;
    }

    if (
      (name === 'viafilling_type6_sieve') &&
      (this.article.data?.via_handling !== 'Filled and Covered Via (IPC-4761 VI)')
    ) {
      this.alertService.add('danger', 'Kein Typ IPC-4761 VI im Artikel festgelegt');
      return;
    }

    const data = {
      fetch: this.form.value.fetch,
      typ: name,
      extra: extra
    };
    this.fetchService.append(this.frame.id, data).subscribe(value => {
      this.subject.next({fetch: value, index: fetchIndex});
      this.alertService.add('success', 'Abrufposition erfolgreich hinzugefügt!', 2000);
    }, () => {
      this.alertService.add('danger', 'Abrufposition konnte nicht hinzugefügt werden!');
    });
  }

}
