import {ArticleNavigationComponent} from '../article/navigiation/article-navigation.component';
import {PriceListComponent} from './list/price-list.component';
import {PriceService} from './services/price.service';
import {Ng2StateDeclaration} from '@uirouter/angular';
import {PriceDetailComponent} from './detail/price-detail.component';
import {detailResolve} from './detail/price-detail.resolve';
import {ErpTransition} from '../../core/erp-transition.service';

export function priceListResolveFn(trans: ErpTransition,  service: PriceService) {
  return service.list(trans.params()).toPromise();
}

// resolvePolicy needs to be lazy, since else our ArticleComponentService would have incorrect values
export const PRICE_STATES: Ng2StateDeclaration[] = [
  {name: 'a.price', url: '/price', abstract: true, data: {requiresAuth: true}},

  {
    name: 'a.price.list',
    url: '/list?page&sort&order&sds_nr&workflow_id&oa_nr&kd_art_nr&customer_name&username',
    views: {
      'navigation@a': {component: ArticleNavigationComponent},
      'main@a': {component: PriceListComponent}
    },
    resolve: [{token: 'listData', resolveFn: priceListResolveFn, deps: [ErpTransition, PriceService]}],
    resolvePolicy: {when: 'LAZY'},
    params: {
      page: {dynamic: true},
      sort: {dynamic: true},
      order: {dynamic: true},
      sds_nr: {dynamic: true},
      workflow_id: {dynamic: true},
      oa_nr: {dynamic: true},
      kd_art_nr: {dynamic: true},
      customer_name: {dynamic: true},
      username: {dynamic: true},
    },
    data: {requiresAuth: true}
  },

  {
    name: 'a.price.check',
    url: '/{id}?create&workflow_id',
    views: {
      'navigation@a': {component: ArticleNavigationComponent},
      'main@a': {component: PriceDetailComponent}
    },
    resolve: detailResolve,
    resolvePolicy: {when: 'LAZY'},
    data: {requiresAuth: true}
  },

];
