import {Component} from '@angular/core';

@Component({
  selector: 'label-create-route',
  templateUrl: './label-create-route.component.html',
})
export class LabelCreateRouteComponent {

  constructor() {
  }
}
