<ng-container [ngSwitch]="state">
  <ng-container *ngSwitchCase="1">
    <inventory-transfer-stock [article]="article"
                              [storage]="storage"
                              [inventory]="activeInventory"
                              (setActiveInventory)="setActiveModal($event)">
    </inventory-transfer-stock>
  </ng-container>

  <ng-container *ngSwitchCase="2">
    <inventory-storage-note-create [article]="article"
                                   [inventory]="activeInventory"
                                   (setActiveInventory)="setActiveModal($event)">
    </inventory-storage-note-create>
  </ng-container>

  <ng-container *ngSwitchCase="3">
    <inventory-storage-note-update [article]="article"
                                   [inventory]="activeInventory"
                                   (setActiveInventory)="setActiveModal($event)">
    </inventory-storage-note-update>
  </ng-container>

  <ng-container *ngSwitchCase="4">
    <article-inventory-transfer-workflow-form [oaNr]="article?.oa_nr"
                                              [storage]="storage"
                                              [inventory]="activeInventory"
                                              [workflowIds]="workflowIds"
                                              (setActiveInventory)="setActiveModal($event)">
    </article-inventory-transfer-workflow-form>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <inventory-modal [article]="article"
                     [inventoryGroupData]="inventoryGroupData"
                     [reservedObjects]="reservedObjects"
                     [inventoryData]="inventoryData"
                     [storage]="storage"
                     [workflowId]="workflowId"
                     [workflowIds]="workflowIds"
                     (setInventoryQuantity)="setInventoryQuantity.emit($event)"
                     (setInventoryState)="setActiveModal($event.storage, $event.state)">
    </inventory-modal>
  </ng-container>
</ng-container>
