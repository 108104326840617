/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EvAlertComponent} from './alert.component';

@NgModule({ declarations: [EvAlertComponent], exports: [EvAlertComponent], imports: [CommonModule] })
export class EvAlertModule { // forked from NgbAlertModule
  static forRoot(): ModuleWithProviders<EvAlertModule> { return {ngModule: EvAlertModule}; }
}
