import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {ArticleHistoryUserData} from '../main/history/article-history.models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ArticleHistoryService {

  constructor(private http: HttpClient) {
  }

  history(article: string): Observable<ArticleHistoryUserData[]> {
    return this.http.get<EnvisiaObjects<ArticleHistoryUserData>>('/article/v2/history/' + article + '/').pipe(
      map(obj => obj.objects)
    );
  }

  preview(article: number): Observable<ArticleHistoryUserData> {
    return this.http.get<EnvisiaObject<ArticleHistoryUserData>>(`/article/v2/history/preview/${article}/`).pipe(
      map(obj => obj.object)
    );
  }

}
