import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialStorage} from '../../material-management.model';

@Component({
  selector: 'app-material-inventory-edit-modal',
  templateUrl: './material-inventory-edit-modal.component.html'
})
export class MaterialInventoryEditModalComponent implements OnInit {
  @Input() storage: MaterialStorage;
  @Input() isKonsi?: boolean;
  lockTransferTabs: boolean;

  constructor(private modalRef: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.isKonsi === true) {
      this.lockTransferTabs = this.storage.amount_konsi <= 0;
    } else if (this.isKonsi === false) {
      this.lockTransferTabs = this.storage.amount_storage <= 0;
    } else {
      this.lockTransferTabs = this.storage.amount <= 0;
    }
    console.log('this.lockTransferTabs', this.lockTransferTabs);
  }

  closeWindow(close: boolean): void {
    if (close) {
      this.modalRef.close(true);
    } else {
      this.modalRef.dismiss(false);
    }
  }

}
