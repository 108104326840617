<div class="modal-header">
  <div class="modal-title">
    <h3>Artikel wiederherstellen</h3>
  </div>
</div>

<form class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-body">

    Möchten Sie diesen Artikel wirklich wiederherstellen?

    <div class="form-group">
      <label class="col-md-2 control-label" for="reason">Grund:</label>

      <div class="col-md-10">
        <select id="reason" class="form-control" formControlName="reason"
                [ngClass]="{red_alert: errors['obj.reason']}">
          <option *ngFor="let notice of notices" [value]="notice">{{ notice }}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-2 control-label" for="reference">Referenz</label>

      <div class="col-md-10">
      <textarea id="reference" class="form-control" formControlName="reference" rows="10"
                [ngClass]="{red_alert: errors['obj.reference']}">
      </textarea>
      </div>
    </div>

    <div *ngIf="errors['obj.duplicated']" class="red_alert">
      <strong>Achtung!</strong>
      Artikel konnte nicht wiederhergestellt werden, da bereits ein Artikel mit gleicher
      SDS-Nr. oder Kd-Art.Nr. + Kd Index Kombination vorhanden ist.
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-success pull-right" type="submit"
            [disabled]="form.invalid || form.pristine">
      Artikel wiederherstellen
    </button>
  </div>
</form>
