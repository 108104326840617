import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Article} from '../../models/article.models';
import Helper from '../../../../helper/helper';

@Component({
  selector: 'article-input-optional',
  templateUrl: './article-input-optional.component.html'
})
export class ArticleInputOptionalComponent implements OnChanges {
  @Input() label: string;
  @Input() modelCheckbox: string;
  @Input() modelInput: string;
  @Input() form: UntypedFormGroup;
  @Input() errors: { [key: string]: any; } = {};
  @Input() article: Article;
  backupInputValue?: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.article) {
      this.backupInputValue = this.article.data[this.modelInput];
    }
  }

  change(): void {
    let value;
    if (!!this.form.value[this.modelCheckbox]) {
      value = this.backupInputValue;
    } else {
      this.backupInputValue = this.form.value[this.modelInput];
      value = null;
    }

    this.form.patchValue(Helper.dynamicObject(this.modelInput, value), {emitEvent: false});
  }

}
