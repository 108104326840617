import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderMaterialSelectModalComponent} from './business-order-material-select-modal.component';
import {forkJoin, from, Observable, throwError} from 'rxjs';
import {MaterialPlanningService} from '../../../material/planning/material-planning.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {BusinessOrderMaterialResponse, MaterialSelect} from '../../../material/planning/material-planning.model';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {ArticleSpecificationService} from '../../../article/services/article-specification.service';
import {ArticleBaseMaterialFormWithKey} from '../../../article/models/article-specification.model';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialGroupListBoMaterialService} from '../../../material/groups/material-group-list-bo-material.service';
import {MaterialGroup} from '../../../material/groups/material-group.model';
import {ArticleMultilayerPlanService} from '../../../article/main/multilayer-plan/article-multilayer-plan.service';
import {ArticleMultilayerPlan} from '../../../article/main/multilayer-plan/article-multilayer-plan.models';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderMaterialSelectModalService {

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private mgs: MaterialGroupListBoMaterialService,
              private ass: ArticleSpecificationService,
              private mps: MaterialPlanningService,
              private amps: ArticleMultilayerPlanService) {
  }

  open(id: number, index: number, oaNr: string): Observable<BusinessOrderMaterialResponse> {
    return forkJoin({
      selectionData: this.mps.getSelectableMaterial(id, index, {}),
      groups: this.mgs.list(),
      baseMaterial: this.ass.list(),
      multilayerPlan: this.amps.get(oaNr),
    })
      .pipe(
        catchError(error => {
          console.log('Modal Error', error);
          this.alertService.add('danger', 'Materialliste konnte nicht geladen werden');
          return throwError('modal error');
        }), mergeMap<{
          selectionData: MaterialSelect,
          groups: Lister<MaterialGroup>,
          baseMaterial: ArticleBaseMaterialFormWithKey[],
          multilayerPlan: ArticleMultilayerPlan | null,
        }, Observable<BusinessOrderMaterialResponse>>(obj => {
          const modalRef = this.modalService.open(
            BusinessOrderMaterialSelectModalComponent,
            {windowClass: 'modal2-full'}
          );
          modalRef.componentInstance.selectionData = obj.selectionData;
          modalRef.componentInstance.groups = obj.groups;
          modalRef.componentInstance.baseMaterial = obj.baseMaterial;
          modalRef.componentInstance.multilayerPlan = obj.multilayerPlan;
          return from(modalRef.result);
        }));
  }
}
