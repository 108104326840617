import {Component, Input} from '@angular/core';
import {OrderDataConveyed} from '../../order-request.models';

@Component({
  selector: 'order-box-history-conveyed',
  templateUrl: './order-box-history-conveyed.component.html',
})
export class OrderBoxHistoryConveyedComponent {

  @Input() type: string;
  @Input() history: OrderDataConveyed;

  constructor() {
  }
}
