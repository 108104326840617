/*
 * Copyright (C) 2018 envisia GmbH
 * All Rights Reserved.
 */
import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {SharedModule} from '../../../core/shared.module';
import {WorkflowDocumentListComponent} from './workflow-document-list.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {WORKFLOW_DOCUMENT_STATES} from './workflow-document.states';
import {WorkflowDocumentNewComponent} from './workflow-document-new.component';
import {FinderModule} from '../../finder/finder.module';

@NgModule({
  imports: [
    SharedModule,
    FinderModule,
    NgbTooltipModule,
    UIRouterModule.forChild({states: WORKFLOW_DOCUMENT_STATES})
  ],
  declarations: [
    WorkflowDocumentListComponent,
    WorkflowDocumentNewComponent
  ]
})
export class WorkflowDocumentModule {
}
