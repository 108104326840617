import * as React from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {Lister} from '../../../../common/wrapper.models';
import {OfferRejectionReason} from '../../../../lazy/config/offer-rejection/config-offer-rejection-reason.model';
import {clsx} from 'clsx';
import {useState} from 'react';
import {AxiosErrorHandler} from '../../../../react/errorhandling/AxiosErrorHandler';
import {OfferRejectionAxiosService} from './offer-rejection-axios-service';

interface Props {
  offerId: number;
  reasons: Lister<OfferRejectionReason>;
  success: () => void;
  cancel: () => void;
}

export function OfferRejectionForm(props: Props): JSX.Element {
  const [saveBtnLocked, saveBtnLockedSet] = useState<boolean>(false);
  const [errors, errorsSet] = useState<{ [key: string]: string }>({});
  const {register, watch, handleSubmit} = useForm();

  const rejectionReasonId: string | null = watch('rejection_reason_id', null);
  const rejectionNote: string | null = watch('rejection_note', null);

  const selectedRejectionReason: OfferRejectionReason | null = (
    props.reasons.objects.find(r => r.id.toString(10) === rejectionReasonId) ?? null
  );

  const saveBtnDisabled: boolean = (
    saveBtnLocked ||
    !selectedRejectionReason ||
    (selectedRejectionReason.note_required && rejectionNote.trim().length === 0)
  );

  const formSubmit: (formData: FieldValues) => void = async (formData) => {
    if (saveBtnLocked) {
      return;
    }

    saveBtnLockedSet(true);
    try {
      await OfferRejectionAxiosService.reject(
        props.offerId,
        {
          rejection_reason_id: formData.rejection_reason_id,
          rejection_note: formData.rejection_note,
        },
      ).then(() => {
        props.success();
      }, AxiosErrorHandler.handle(errorsSet));
    } finally {
      saveBtnLockedSet(false);
    }
  };

  return <form onSubmit={handleSubmit(formSubmit)}>
    <div className="form-group">
      <label htmlFor="rejection_reason_id">Ablehnungsgrund</label>
      <select id="rejection_reason_id"
              className={clsx({'form-control': true, 'red_alert': !!errors['reason_id']})}
              {...register('rejection_reason_id', {required: true})}>
        <option key={-1} value="-1">-</option>
        {
          props.reasons.objects.map(r =>
            <option key={r.id} value={r.id}>{r.reason}</option>
          )
        }
      </select>
    </div>

    <div className="form-group">
      <label htmlFor="rejection_note">
        Kommentar {
        (!!selectedRejectionReason?.note_required &&
          <span className={clsx({'red_font': !!errors['rejection_note']})}>(Pflicht)</span>)
      }
      </label>
      <textarea id="rejection_note"
                rows={5}
                className={clsx({'form-control': true, 'red_alert': errors['rejection_note']})}
                {...register('rejection_note')}/>
    </div>

    <hr/>

    <div>
      <button type="submit" className="btn btn-success float-right" disabled={saveBtnDisabled}>
        Speichern
      </button>
      <button type="reset" className="btn btn-danger float-right">
        Abbrechen
      </button>
    </div>
  </form>;
}
