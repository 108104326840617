<div class="article-form-item" [formGroup]="form" *ngIf="view">
  <label [htmlFor]="'article-model-' + model" class="article-form-item-label" [ngClass]="{bold: bold}">{{ label }}:</label>

  <div class="article-form-item-padding">
  </div>

  <div class="article-form-item-data" [ngClass]="{'data-group': !!unit}">

    <div class="article-form-data-content">
      <select [id]="'article-model-' + model" class="form-control"
              [formControlName]="model"
              (change)="change()"
              [ngClass]="{red_alert: error}">
        <option [ngValue]="null">-</option>
        <option *ngFor="let obj of data;trackBy: trackByFn" [ngValue]="obj.value">{{ obj.key }}</option>
      </select>
    </div>

    <div class="article-form-data-content" *ngIf="unit">
      <div class="article-form-data-unit-block">{{ unit }}</div>
    </div>
  </div>

  <div style="display: table-cell;">
    <article-changed-marker [marked]="markedElement" [unit]="unit"></article-changed-marker>
  </div>
</div>
