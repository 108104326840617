import Axios, {AxiosError} from 'axios';
import * as React from 'react';

export class AxiosErrorHandler {
  static handle(errorSet: (value: React.SetStateAction<{ [p: string]: string }>) => void) {
    return (error: Error | AxiosError) => {
      if (Axios.isAxiosError(error)) {
        errorSet(error.response?.data?.errors ?? {});
      } else {
        console.error(error);
      }
    };
  }
}
