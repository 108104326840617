<div class="row" *ngIf="errors['obj.date_received']">
  <ng-container *ngFor="let error of errors['obj.date_received']">
    <ng-container *ngFor="let errorMsg of error.msg">
      <div class="col-md-12" *ngIf="errorMsg === 'error.before.konsi.date'">
        <div class="alert red_alert">
          Das Wareneingangs Datum darf bei Konsibeständen nicht mehr als 90 Tage zurück liegen.
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="row" *ngIf="errors['obj.amount']">
  <ng-container *ngFor="let error of errors['obj.amount']">
    <ng-container *ngFor="let errorMsg of error.msg">
      <div class="col-md-12">
        <div class="alert red_alert" [ngSwitch]="errorMsg">
          <ng-container *ngSwitchCase="'error.negative.balance'">
            Es können nicht mehr Materialien entnommen werden, als vorhanden sind.
          </ng-container>

          <ng-container *ngSwitchCase="'error.zero.balance'">
            Es sollte mindestens eine Einheit transferiert werden.
          </ng-container>

          <ng-container *ngSwitchCase="'error.positive.only'">
            Es können nur positive Mengen eingebucht werden.
          </ng-container>

          <ng-container *ngSwitchDefault>
            Unbekannter Fehler beim &Uuml;bertragen des Formulars.
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="row" *ngIf="errors['obj.note']">
  <ng-container *ngFor="let error of errors['obj.note']">
    <ng-container *ngFor="let errorMsg of error.msg">
      <div class="col-md-12" *ngIf="errorMsg === 'error.negative.not.commented'">
        <div class="alert red_alert">
          Bei negativen Mengen muss eine Notiz angegeben werden!
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="row" *ngIf="!!errors['obj.variation_id']">
  <div class="col-md-12">
    <div class="alert red_alert">Variation konnte nicht gefunden werden.</div>
  </div>
</div>
