<div class="filter-row">
  <div class="filter-left">
    <button class="btn btn-default btn-sm"
            type="button"
            (click)="inventoryBadge('all')"
            [ngClass]="{active: iQ.status === null}">
      Alle
    </button>
    <button class="btn btn-info btn-sm"
            type="button"
            (click)="inventoryBadge('confirmed')"
            [ngClass]="{active: iQ.status === 0}">
      Bestätigt
    </button>
    <button class="btn btn-warning btn-sm"
            type="button"
            (click)="inventoryBadge('ordered')"
            [ngClass]="{active: iQ.status === 1}">
      Bestellt
    </button>
    <button class="btn btn-danger btn-sm"
            type="button"
            (click)="inventoryBadge('low_stock')"
            [ngClass]="{active: iQ.status === 2}">
      Handlungsbedarf
    </button>
    <button class="btn btn-purple btn-sm"
            type="button"
            (click)="inventoryBadge('passed_deadline')"
            [ngClass]="{active: iQ.passed_deadline}">
      <i class="fa" [ngClass]="{'fa-toggle-off': !iQ.passed_deadline, 'fa-toggle-on': iQ.passed_deadline}">
      </i>&nbsp;Bestell Lieferdatum erreicht
    </button>
    &nbsp;
    <button class="btn btn-default btn-sm"
            type="button"
            ngbTooltip="Excel Liste ohne Charge herunterladen"
            (click)="download(false)">
      <i class="fa fa-download"></i>
    </button>
    <button class="btn btn-info btn-sm"
            type="button"
            ngbTooltip="Excel Liste mit Charge herunterladen"
            (click)="download(true)">
      <i class="fa fa-download"></i><i class="fa fa-plus"></i>
    </button>
  </div>
</div>
<div>
  <h3>Bestand Materiallager</h3>
  <div>Gruppe: {{currentGroup.group_id}} {{currentGroup.name}}</div>
</div>
<div>
  <form name="form" #changeForm="ngForm">
    <table class="table whitetable">
      <thead>
      <tr>
        <th>
          <a (click)="sort($event, 'variation_id', 'DESC')" [href]="sortUrl('variation_id', 'DESC')">Interne&nbsp;Nr.</a>
        </th>
        <th>
          <a (click)="sort($event, 'vendor_article_id', 'DESC')" [href]="sortUrl('vendor_article_id', 'DESC')">
            Art.&nbsp;Nr.&nbsp;Lieferant
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'supplier_name', 'DESC')" [href]="sortUrl('supplier_name', 'DESC')">
            Lieferant
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'article_name', 'DESC')" [href]="sortUrl('article_name', 'DESC')">Bezeichnung</a>
        </th>
        <th>
          <a (click)="sort($event, 'ean', 'DESC')" [href]="sortUrl('ean', 'DESC')">EAN</a>
        </th>
        <th>
          <a (click)="sort($event, 'charges', 'DESC')" [href]="sortUrl('charge', 'DESC')">Charge</a>
        </th>
        <th>
          <a (click)="sort($event, 'locations', 'DESC')" [href]="sortUrl('location', 'DESC')">Lagerplatz</a>
        </th>
        <th>
          <a (click)="sort($event, 'unit', 'DESC')" [href]="sortUrl('unit', 'DESC')">Einheit</a>
        </th>
        <th class="align-right">
          <a (click)="sort($event, 'amount', 'DESC')" [href]="sortUrl('amount', 'DESC')">Menge</a>
        </th>
        <th class="align-right">
          <a (click)="sort($event, 'min_stock', 'DESC')" [href]="sortUrl('min_stock', 'DESC')">Min Bestand</a>
        </th>
        <th class="align-right">
          <a (click)="sort($event, 'min_order', 'DESC')" [href]="sortUrl('min_order', 'DESC')">Min Bestellung</a>
        </th>
        <th><a (click)="sort($event, 'amount_ordered', 'DESC')" [href]="sortUrl('amount_ordered', 'DESC')">Bestellt</a></th>
        <th><a (click)="sort($event, 'amount_confirmed', 'DESC')" [href]="sortUrl('amount_confirmed', 'DESC')">Bestätigt</a></th>
        <th *ngFor="let field of fields">{{field.name}}</th>
        <th [colSpan]="('material.settings'|HasPerm) ? 3 : 2"></th>
      </tr>

      <tr class="filterrow">
        <th>
          <input class="form-control input-sm"
                 name="variation_id"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.variation_id }"
                 [(ngModel)]="data['variation_id']">
        </th>
        <th>
          <input class="form-control input-sm"
                 name="vendor_article_id"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.vendor_article_id }"
                 [(ngModel)]="data['vendor_article_id']">
        </th>
        <th>
          <select class="form-control input-sm"
                  name="supplier"
                  [ngClass]="{ 'red_alert': !!filterFormErrors.supplier }"
                  [(ngModel)]="data['supplier']">
            <option [ngValue]="null" selected>-</option>
            <option [ngValue]="supplier.id.toString()" *ngFor="let supplier of suppliers">
              {{supplier.name}}
            </option>
          </select>
        </th>
        <th>
          <input class="form-control input-sm"
                 name="article_name"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.article_name }"
                 [(ngModel)]="data['article_name']">
        </th>
        <th>
          <input class="form-control input-sm"
                 name="ean"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.ean }"
                 [(ngModel)]="data['ean']">
        </th>
        <th>
          <input class="form-control input-sm"
                 name="charge"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.charge }"
                 [(ngModel)]="data['charge']">
        </th>
        <th>
          <input class="form-control input-sm"
                 name="location"
                 [ngClass]="{ 'red_alert': !!filterFormErrors.location }"
                 [(ngModel)]="data['location']">
        </th>
        <th>
          <select class="form-control input-sm"
                  name="unit"
                  [ngClass]="{ 'red_alert': !!filterFormErrors.unit }"
                  [(ngModel)]="data['unit']">
            <option [ngValue]="null" selected>-</option>
            <option [ngValue]="unit.unit" *ngFor="let unit of units">{{unit.unit}}</option>
          </select>
        </th>
        <th colspan="5"></th>
        <th *ngFor="let field of fields">
          <ng-container [ngSwitch]="field.data_type">
          <ng-container *ngSwitchCase="'Auswahl'">
            <select class="form-control input-sm"
                    [name]="field.html_name"
                    [ngClass]="{ 'red_alert': !!filterFormErrors[field.html_name] }"
                    [(ngModel)]="data[field.html_name]">
              <option [ngValue]="null" selected>-</option>
              <option [ngValue]="choice" *ngFor="let choice of field.choices">{{choice}}</option>
            </select>
          </ng-container>
          <ng-container *ngSwitchCase="'Option'">
            <i class="fa click" [ngClass]="triStateValue(field)" (click)="triStateChange(field)"></i>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <input class="form-control input-sm"
                   [name]="field.html_name"
                   [ngClass]="{ 'red_alert': !!filterFormErrors[field.html_name] }"
                   [(ngModel)]="data[field.html_name]">
          </ng-container>
          </ng-container>
        </th>
        <th [colSpan]="('material.settings'|HasPerm) ? 3 : 2"></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let article of list.objects"
          [ngClass]="{ 'alert-danger': article.status === 3,
                       'warning': article.status === 2,
                       'info': article.status === 1 }">
        <td>
          <a class="click" (click)="linkDetails(article.id)">
            {{ article.variation_id }}
          </a>
        </td>
        <td>
          {{ article.vendor_article_id }}
        </td>
        <td>
          {{ article.supplier_name }}
        </td>
        <td>
          {{ article.article_name }} <i class="fa fa-star star-yellow" *ngIf="article.favorite"></i>
        </td>
        <td>
          {{ article.ean }}
        </td>
        <td>
          {{ stringHelper.concat(article.charges, ', ', '-') }}
        </td>
        <td>
          {{ stringHelper.concat(article.locations) }}
        </td>
        <td>
          {{ article.unit }}
        </td>
        <td class="align-right">
          {{ article.amount | money }}
        </td>
        <td class="align-right">
          {{ article.min_stock | money }}
        </td>
        <td class="align-right">
          {{ article.min_order | money }}
        </td>
        <td class="align-right">
          {{ article.amount_ordered | money }}
        </td>
        <td class="align-right">
          {{ article.amount_confirmed | money }}
        </td>
        <td [ngClass]="{'align-right': field.isValueType('Number')}" *ngFor="let field of fields">
          <ng-container [ngSwitch]="field.data_type" *ngIf="!isFieldEmpty(article.fields[field.html_name])">
            <ng-container *ngSwitchCase="'Option'">
              <i class="fa" [ngClass]="getOptionFaClass(article.fields[field.html_name])"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'Ganzzahl'">
              {{ getFormattedNumber(article.fields[field.html_name]) }}
            </ng-container>
            <ng-container *ngSwitchCase="'Kommazahl'">
              {{ getFormattedNumber(article.fields[field.html_name]) }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ article.fields[field.html_name] }}
            </ng-container>
          </ng-container>
        </td>
        <td class="icon" *ngIf="('material.settings'|HasPerm)">
          <a class="click" (click)="openEditModal(article)">
            <i class="fa fa-pencil"></i>
          </a>
        </td>
        <td class="icon">
          <a uiSref="a.material.order.create"
             target="_blank"
             [uiParams]="{supplier_id: article.supplier_id, marked_variation_id: article.id}">
            <i class="fa fa-truck"></i>
          </a>
        </td>
        <td class="icon">
          <a class="click" (click)="linkDetails(article.id)">
            <i class="fa fa-cubes"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="list && list.count === 0">
      Keine Artikel mit diesen Kriterien Gefunden
    </div>
    <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
  </form>
</div>
