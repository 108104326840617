<div class="page-header">
  <h3>Neuen Artikel zu Gruppe {{ currentGroup.name }} hinzufügen</h3>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-horizontal">
  <material-article-form-create [form]="form" [articleFieldDefs]="articleFieldDefs">
  </material-article-form-create>

  <material-article-error-message [error]="error" [form]="form"></material-article-error-message>
  <div class="col-sm-12">
    &nbsp;
  </div>
  <div class="col-sm-12">
    <button type="submit" class="btn btn-success" [disabled]="!form.valid">
      Artikel hinzufügen
    </button>
  </div>
</form>
