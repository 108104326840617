import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  MaterialArticle,
  MaterialVariation,
  MaterialVariationAggregation,
} from '../material-management.model';
import {StringHelper} from '../../../common/string-helper';
import {MaterialManagementService} from '../material-management.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {MaterialGroup} from '../groups/material-group.model';
import {MaterialGroupListPermittedService} from '../groups/material-group-list-permitted.service';

@Component({
  selector: 'material-select-article',
  templateUrl: './material-article-select.component.html'
})
export class MaterialSelectArticleComponent implements OnInit, OnDestroy {
  @Input('variation-id') variationId: number;
  @Input() level = 3;
  @Output('group') groupEmitter = new EventEmitter<MaterialGroup | null>();
  @Output('article') articleEmitter = new EventEmitter<MaterialArticle | null>();
  @Output('variation') variationEmitter = new EventEmitter<MaterialVariation | null>();

  @Input() groups: MaterialGroup[] = [];
  articles: MaterialArticle[] = [];
  variations: MaterialVariation[] = [];
  currentGroup?: MaterialGroup = null;
  currentArticle: MaterialArticle = null;
  currentVariation: MaterialVariation = null;

  stringHelper = StringHelper;

  searchForm: UntypedFormGroup;
  searchSubscription: Subscription;
  searchResults: MaterialVariationAggregation[] = null;

  constructor(
    private mms: MaterialManagementService,
    private mgs: MaterialGroupListPermittedService,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.buildSearchForm();
    this.buildSearchSubscription();
    if (this.variationId) {
      this.loadVariationById(this.variationId);
    } else {
      if (this.groups.length === 0) {
        this.loadGroups();
      }
    }
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  // Search
  buildSearchForm(): void {
    const form = { searchQuery: [''] };
    this.searchForm = this.fb.group(form);
  }

  buildSearchSubscription(): void {
    this.searchSubscription = this.searchForm.valueChanges
      .pipe(debounceTime(550))
      .subscribe((values) => this.searchMaterial(values.searchQuery));
  }

  searchMaterial(searchQuery: string): void {
    if (searchQuery && searchQuery.length > 0) {
      this.groups = [];
      this.articles = [];
      this.variations = [];
      this.mms.searchMaterial(searchQuery, this.level).subscribe((searchResults) => {
        this.searchResults = searchResults;
      });
    } else {
      this.loadGroups();
    }
  }

  // Loaders
  loadGroups(): void {
    this.searchResults = null;
    this.mgs.list().subscribe((groups) => {
      this.clearArticle();
      this.groups = groups.objects;
    });
  }

  loadArticles(groupId: number): void {
    if (this.level > 1) {
      this.clearVariation();
      this.mms.listArticle(groupId).subscribe((articles) => {
        this.articles = articles ? articles : [];
      });
    }
  }

  loadVariations(articleId: number): void {
    if (this.level > 2) {
      this.mms.listVariations(articleId).subscribe((variations) => {
        this.variations = variations ? variations : [];
      });
    }
  }

  // Single Loaders
  loadVariationById(variationId: number): void {
    this.mms.getSingleVariation(variationId).subscribe((variation) => {
      this.setVariation(variation);
      this.loadArticleById(this.currentVariation.article_id);
    });
  }

  loadArticleById(articleId: number): void {
    this.mms.getSingleArticle(articleId).subscribe((article) => {
      this.setArticle(article);
      this.loadGroupById(this.currentArticle.group_id);
    });
  }

  loadGroupById(groupId: number): void {
    this.mms.getSingleGroup(groupId).subscribe((group) => {
      this.setGroup(group);
    });
  }

  // Selectors
  showGroups(): boolean {
    return (
      ((this.groups && this.groups.length > 0) || this.currentGroup) &&
      !(this.searchResults && this.searchResults.length > 0)
    );
  }

  showArticles(): boolean {
    return (
      this.level > 1 &&
      ((this.articles      && this.articles.length > 0) || this.currentArticle) &&
      !(this.searchResults && this.searchResults.length > 0)
    );
  }

  showVariations(): boolean {
    return (
      this.level > 2 &&
      ((this.variations    && this.variations.length > 0) || this.currentVariation) &&
      !(this.searchResults && this.searchResults.length > 0)
    );
  }

  // Setters
  setGroup(group: MaterialGroup): void {
    this.currentGroup = group;
    this.groupEmitter.emit(this.currentGroup);
    if (!this.currentArticle) {
      this.loadArticles(this.currentGroup.id);
    }
  }

  setArticle(article: MaterialArticle): void {
    this.currentArticle = article;
    this.articleEmitter.emit(this.currentArticle);
    if (!this.currentVariation) {
      this.loadVariations(this.currentArticle.id);
    }
  }

  setVariation(variation: MaterialVariation): void {
    this.currentVariation = variation;
    this.variationEmitter.emit(this.currentVariation);
  }

  setSearchValue(value: string): void {
    this.searchForm.patchValue({ searchQuery: value}, {emitEvent: false});
  }

  setSearchValueByCurrentSelection(): void {
    this.setSearchValue(
      this.currentVariation ? this.currentVariation.variation_id :
      this.currentArticle   ? this.currentArticle.article_id :
      this.currentGroup     ? this.currentGroup.group_id :
      ''
    );
  }

  // Togglers
  toggleSelectGroup(group: MaterialGroup): void {
    if (group !== this.currentGroup) {
      this.setGroup(group);
      this.setSearchValue(group.group_id);
    } else {
      this.clearGroup(true);
      this.loadGroups();
    }
  }

  toggleSelectArticle(article: MaterialArticle): void {
    if (article !== this.currentArticle) {
      this.setArticle(article);
      this.setSearchValue(article.article_id);
    } else {
      this.clearArticle(true);
      this.loadArticles(this.currentGroup.id);
    }
  }

  toggleSelectVariation(variation: MaterialVariation): void {
    if (variation !== this.currentVariation) {
      this.setVariation(variation);
      this.setSearchValue(variation.variation_id);
    } else {
      this.clearVariation(true);
      this.loadVariations(this.currentArticle.id);
    }
  }

  toggleSelectResult(result: MaterialVariationAggregation): void {
    if (result.variation_id) {
      if (!this.currentVariation || result.variation_id !== this.currentVariation.id) {
        this.loadVariationById(result.variation_id);
      }
    } else if (result.article_id) {
      if (!this.currentArticle || result.article_id !== this.currentArticle.id) {
        this.loadArticleById(result.article_id);
      }
    } else if (result.group_id) {
      if (!this.currentGroup || result.group_id !== this.currentGroup.id) {
        this.loadGroupById(result.group_id);
      }
    } else {
      this.clearGroup(true);
      this.loadGroups();
    }
    this.clearSearch();
  }

  // Clears
  clearGroup(includeSearchForm?: boolean): void {
    this.currentGroup = null;
    this.groupEmitter.emit(null);
    this.groups = [];
    this.clearArticle(includeSearchForm);
  }

  clearArticle(includeSearchForm?: boolean): void {
    this.currentArticle = null;
    this.articleEmitter.emit(null);
    this.articles = [];
    this.clearVariation(includeSearchForm);
  }

  clearVariation(includeSearchForm?: boolean): void {
    this.currentVariation = null;
    this.variationEmitter.emit(null);
    this.variations = [];
    this.searchResults = null;
    if (includeSearchForm) {
      this.setSearchValueByCurrentSelection();
    }
  }

  clearSearch(includeSearchForm?: boolean): void {
    this.searchResults = null;
    if (includeSearchForm) {
      this.setSearchValue('');
    }
  }

  // IsSelected
  isGroupSelected(group: MaterialGroup): boolean {
    return this.currentGroup && group.id === this.currentGroup.id;
  }

  isArticleSelected(article: MaterialArticle): boolean {
    return this.currentArticle && article.id === this.currentArticle.id;
  }

  isVariationSelected(variation: MaterialVariation): boolean {
    return this.currentVariation && variation.id === this.currentVariation.id;
  }
}
