import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderGoldDateErrorComponent} from './business-order-gold-date-error.component';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';

@Injectable({providedIn: 'root'})
export class BusinessOrderGoldDateErrorService {

  constructor(private modalService: NgbModal) {
  }

  open(): void {
    const modalRef = this.modalService.open(BusinessOrderGoldDateErrorComponent);
    from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
  }

}
