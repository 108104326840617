<div class="filter-row">
  <div class="filter-left">
    <button class="btn btn-rose btn-sm"
            type="button"
            style="margin-right: 10px;"
            (click)="filterStatus(50)">
      Offen
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="filterStatus(0)">
      Bestellt
    </button>
    <button class="btn btn-info btn-sm" type="button" (click)="filterStatus(1)">
      Bestätigt
    </button>
    <button class="btn btn-warning btn-sm" type="button" (click)="filterStatus(2)">
      Gebucht
    </button>
    <button class="btn btn-success btn-sm" type="button" (click)="filterStatus(3)">
      Geprüft
    </button>
    <button class="btn btn-default btn-sm" type="button" (click)="filterStatus(null)">
      Alle
    </button>
    <button class="btn btn-danger btn-sm" type="button" (click)="filterStatus(100)">
      Gelöscht
    </button>
  </div>
</div>
<div class="filter-row">
  <div class="filter-left">
    <material-product-groups-tab-list [productGroups]="productGroups.objects"
                                      [groups]="groups.objects"
                                      [activeProductGroupId]="activeProductGroupId"
                                      [activeGroupIds]="activeGroupIds"
                                      (setProductGroupId)="setProductGroupId($event)"
                                      (setGroupIds)="setGroupIds($event)">
    </material-product-groups-tab-list>
  </div>
</div>
<div>
  <form #changeForm="ngForm" name="form">
    <table class="table whitetable">
      <thead>
      <tr>
        <th style="width: 90px;"><a (click)="sort($event, 'id', 'DESC')" [href]="sortUrl('id', 'DESC')">Bestellung</a></th>
        <th style="width: 120px;">Status</th>
        <th></th>
        <th><a (click)="sort($event, 'supplier_name', 'ASC')" [href]="sortUrl('supplier_name', 'ASC')">Lieferant</a>
        </th>
        <th>
          <a (click)="sort($event, 'delivery_id', 'ASC')" [href]="sortUrl('delivery_id', 'ASC')">Lief. AB Nr.</a>
        </th>
        <th>
          <a (click)="sort($event, 'date_created', 'ASC')" [href]="sortUrl('date_created', 'ASC')">Bestelldatum</a>
        </th>
        <th>
          Lieferdatum
        </th>
        <th>
          Int. Notiz
        </th>
        <th colspan="6"></th>
      </tr>

      <tr class="filterrow">
        <th><input class="form-control input-sm" name="id" [(ngModel)]="data['id']"></th>
        <th colspan="2"></th>
        <th>
          <select class="form-control input-sm" name="supplier_id" [(ngModel)]="data['supplier_id']">
            <option [ngValue]="null" selected>&nbsp;</option>
            <option [ngValue]="supplier.id.toString()" *ngFor="let supplier of suppliers">
              {{supplier.name}}
            </option>
          </select>
        </th>
        <th><input class="form-control input-sm" name="delivery_id" [(ngModel)]="data['delivery_id']"></th>
        <th colspan="9"></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let order of list.objects; let index = index">
        <td>{{order.id}}</td>
        <td>
          <material-order-status [order]="order"></material-order-status>
        </td>
        <td>
          <i class="fa fa-paper-plane ev-blue-color"
             title="Bestellung wurde per Mail versendet"
             *ngIf="order.mail_sent">
          </i>
        </td>
        <td>{{order.supplier.name}}</td>
        <td>{{order.delivery_id}}</td>
        <td>
          {{order.date_created|date:'dd.MM.yyyy'}}
        </td>
        <td *ngIf="order.date_delivery_min === order.date_delivery_max">
          {{order.date_delivery_min|date:'dd.MM.yyyy'}}
        </td>
        <td *ngIf="order.date_delivery_min !== order.date_delivery_max">
          {{order.date_delivery_min|date:'dd.MM.yyyy'}} - {{order.date_delivery_max|date:'dd.MM.yyyy'}}
        </td>
        <td>
          {{order.note_positions|JoinStringPipe}}
        </td>
        <td class="icon">
          <button type="button"
                  class="btn btn-link"
                  title="Bestellübersicht öffnen"
                  (click)="confirm(order)">
            <i class="fa fa-bars"></i>
          </button>
        </td>
        <td class="icon">
          <button type="button"
                  title="Bestellungs PDF öffnen"
                  class="btn btn-default btn-link"
                  (click)="openPdf(order.id)">
            <i class="fa fa-file-pdf-o"></i>
          </button>
        </td>
        <td class="icon">
          <button type="button"
                  class="btn btn-link"
                  title="Bestellung versenden"
                  (click)="mail(order, index)">
            <i class="fa fa-envelope"></i>
          </button>
        </td>
        <td class="icon">
          <a target="_blank"
             title="Nach Bestellung im envisia finder suchen"
             [href]="finderUri + 'Search?categories=' + finderCategory + '&q=' + order.id">
            <i class="fa fa-search"></i>
          </a>
        </td>
        <td class="icon" *ngIf="!order.date_deleted">
          <button type="button"
                  class="btn btn-link"
                  style="color: red;"
                  title="Bestellung stornieren"
                  (click)="delete(order)">
            <i class="fa fa-trash-o"></i>
          </button>
        </td>
        <td class="align-right" style="width:130px;" *ngIf="!order.date_deleted">
          <button type="button"
                  class="btn btn-default btn-sm"
                  uiSref="a.material.order.intake"
                  [uiParams]="{order_id: order.id}"
                  *ngIf="'material.order.transactions'|HasPerm">
            Positionen einbuchen
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </form>
  <ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
</div>
