import {Injectable} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProducerService} from './producer.service';
import {ProducerModalComponent} from './producer-modal.component';
import {EMPTY, from, Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {AlertService} from '../../common/alert-service/alert.service';

@Injectable({providedIn: 'root'})
export class ProducerModalService {

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private service: ProducerService) {
  }

  open(saveMethod: (ref: NgbActiveModal, selected?: string) => Observable<any>, current?: string): Observable<any> {
    return this.service.list().pipe(
      catchError(() => {
        this.alertService.add('danger', 'Es ist ein Fehler aufgetreten!');
        return EMPTY;
      }),
      mergeMap(producers => {
        const modalRef = this.modalService.open(ProducerModalComponent);
        modalRef.componentInstance.producers = producers;
        modalRef.componentInstance.current = current;
        modalRef.componentInstance.saveMethod = saveMethod;
        return from(modalRef.result);
      })
    );
  }

}
