import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../common/alert-service/alert.service';
import {ProductionArticleService} from './production-article.service';
import {Article} from '../models/article.models';
import {ArticleProductionStepView} from '../../../envisia/article/production/production.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'article-production-edit',
  templateUrl: './article-production-edit.component.html',
})
export class ArticleProductionEditComponent implements OnInit {
  @Input() article: Article;
  @Input() data: ArticleProductionStepView;

  errors?: { [key: string]: string; } = {};
  editForm: UntypedFormGroup;

  commentActive = false;
  lockTextTmp?: string;

  canNotSubmit = true;

  constructor(private modalRef: NgbActiveModal,
              private modalService: NgbModal,
              private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private productionArticleService: ProductionArticleService) {
  }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      infoText: [this.data.article.infoText],
      lockText: [this.data.article.lockText],
      commentText: ['']
    });
    this.initSubmitBtnState();
  }

  isLockAction(): boolean {
    return ((this.editForm.value.lockText && this.editForm.value.lockText !== this.data.article.lockText) ||
      (!this.editForm.value.lockText && this.data.article.lockText)) as boolean;
  }

  preSubmit(lockAction: boolean) {
    if (lockAction) {
      this.commentActive = true;
      this.lockTextTmp = this.editForm.value.lockText;
    } else {
      this.submit();
    }
  }

  initSubmitBtnState(): void {
      this.canNotSubmit =
        // first condition checks null errors and the second is the general condition that a lockText should be there
        (!this.editForm.value.lockText || this.editForm.value.lockText.length < 1) &&
        // submit btn remains disabled if either there is no locktext EXCEPT the locktext is to be removed
        !(this.data.article.lockText && this.data.article.lockText.length < 1);
  }

  validateLockText() {
      this.canNotSubmit = !(this.editForm.value.commentText && this.editForm.value.commentText.length !== 0);
  }

  submit() {
    this.errors = {};
    let finalForm;
    if (this.commentActive) {
      finalForm = {
        'infoText': this.editForm.value.infoText,
        'lockText': this.lockTextTmp,
        'commentText': this.editForm.value.commentText,
        'hasChanged': this.lockTextTmp !== this.data.article.lockText
      };
    } else {
      finalForm = this.editForm.value;
    }
    this.productionArticleService.edit(
      this.article.oa_nr,
      this.data.article.ordering,
      finalForm
    ).subscribe(value => {
      this.alertService.add('success', 'Fertigungsschritt wurde erfolgreich editiert!');
      this.modalRef.close(value);
    }, response => {
      if (response.error) {
        this.errors = response.error;
      }
      this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut ' +
        'oder benachrichtigen Sie Ihren Administrator!');
    });
  }

  dismiss() {
    this.modalRef.dismiss();
  }

}
