import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../common/alert-service/alert.service';
import {SignalRService} from '../../../core/signalr.service';
import {ArticleLockService} from '../lock/article-lock.service';

@Component({
  selector: 'article-config-unlock',
  templateUrl: './article-config-unlock.component.html'
})
export class ArticleConfigUnlockComponent implements OnInit, OnDestroy {
  @Input() notices: string[];
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};
  sending = false;
  success = false;

  constructor(private fb: UntypedFormBuilder,
              private alertService: AlertService,
              private signalRService: SignalRService,
              private service: ArticleLockService) {
  }

  ngOnInit(): void {
    this.signalRService.startConnection(() => {
      this.signalRService.on('ArticleUnlock', articleCount => {
        if (articleCount) {
          console.log('successfully unlocked articles!', articleCount);
          this.success = true;
          this.sending = false;
          setTimeout(() => {
            this.success = false;
          }, 5000);
        }
      });
    });

    this.form = this.fb.group({
      'file': [null, Validators.required],
      'reason': [this.notices?.length > 0 ? this.notices[0] : 'Ext. Änderung', Validators.required],
      'reference': ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.signalRService.off('ArticleUnlock');
    this.signalRService.stopConnection();
  }

  submit(): void {
    this.success = false;
    this.sending = true;

    const body = new FormData();
    body.append('file', this.form.value.file);
    body.append('reason', this.form.value.reason);
    body.append('reference', this.form.value.reference);

    this.service.unlockWithFile(body).subscribe(() => {
      this.form.reset({'reason': this.notices?.length > 0 ? this.notices[0] : 'Ext. Änderung'});
    }, () => {
      this.sending = false;
      this.alertService.add('danger', 'Kunden Artikel konnten nicht entsperrt werden!');
    });
  }

}
