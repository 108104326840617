<h3>Neue Material Bestellung erstellen</h3>
<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row form-group">
    <div class="col-md-1"><label for="supplier_id">Für Lieferant:</label></div>
    <div class="col-md-3">
      <select class="form-control input-sm" id="supplier_id" formControlName="supplier_id">
        <option [ngValue]="null" selected disabled hidden>&nbsp;</option>
        <option [ngValue]="supplier?.supplier.id" *ngFor="let supplier of supplierGroups">
          {{supplier?.supplier.name}}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <button
        type="button"
        class="btn btn-default"
        [disabled]="currentSupplier?.supplier?.id === form.value.supplier_id"
        (click)="selectSupplier()">
        Auswählen
      </button>
    </div>
    <div class="col-md-6">
      <material-order-create-group-select [groups]="unlistedGroups"
                                          [disabled]="!currentSupplier"
                                          (addGroup)="addGroupManually($event)">
      </material-order-create-group-select>
    </div>
  </div>

  <div class="row"><div class="col-sm-12">&nbsp;</div></div>

  <div *ngIf="currentSupplier && form">
    <material-order-create-group [mig]="mig"
                                 [form]="form"
                                 [currentSupplier]="currentSupplier?.supplier"
                                 [markedVariationId]="markedVariationId"
                                 [errors]="errors"
                                 (openModal)="openAddArticleModal($event)"
                                 (removeOrderEmitter)="removeOrder($event)"
                                 (copyOrderEmitter)="copyOrder($event)"
                                 *ngFor="let mig of orderArticleList; trackBy: trackByFn; let i = index">
    </material-order-create-group>
  </div>

  <div class="row"><div class="col-lg-12">&nbsp;</div></div>

  <div class="row">
    <div class="col-lg-1">
      <label for="note">Notizen:</label>
    </div>

    <div class="col-lg-5">
      <textarea class="form-control" rows="5" id="note" formControlName="note"></textarea>
    </div>

    <div class="col-lg-6">
      <div class="whitebox red_alert" *ngIf="hasMinAmountErrors || form.value.force_amount">
        <div *ngIf="!form.value.force_amount">
          <div>
            <b>Achtung:</b> Min. Bestellwert bei mindestens einer Position unterschritten. Bestellung trotzdem
            erstellen?
          </div>
          <div>
            <button type="button" class="btn btn-danger" (click)="setForceAmount()">
              Bestellung trotzdem erstellen
            </button>
          </div>
        </div>
        <div *ngIf="form.value.force_amount">
          <div>
            <b>Achtung:</b> Min. Bestellwerte sind für diese Bestellung deaktiviert und können unterschritten werden.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="display:block;">
    <div class="float-right row" style="padding:20px 0;">
      <div class="col-sm-5">
        <button type="button" class="btn btn-warning" (click)="clearPrices()">Alle Preise leeren</button>
      </div>
      <div class="col-sm-2">&nbsp;</div>
      <div class="col-sm-5">
        <button type="submit" class="btn btn-success" [disabled]="!form.valid">Bestellung erstellen</button>
      </div>
    </div>
  </div>
</form>
