import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  ContractCheckWeek,
  ContractCheckWeekElementWithColor,
  ContractDateCheckElement,
  ContractDateCheckUtilizeCalc
} from './material-planning-date-check.model';
import {DateHelper} from '../../../helper/date-helper';

@Component({
  selector: 'tr[material-planning-date-check-modal-row]',
  templateUrl: './material-planning-date-check-modal-row.component.html',
  styleUrls: ['./material-planning-date-check-modal.component.scss'],
})
export class MaterialPlanningDateCheckModalRowComponent implements OnInit, OnChanges {
  @Input('material-planning-date-check-modal-row') materialHolder: ContractDateCheckElement;
  @Input('material-planning-date-check-modal-row-weeks') visibleWeeks: ContractCheckWeek[];
  @Input('material-planning-date-check-modal-row-date-start') dateStart?: Date;
  @Input('material-planning-date-check-modal-row-holidays') holidays: { [year: string]: { [month: string]: number[] } };
  @Input('material-planning-date-check-modal-row-utilize-calc') utilizeCalc: ContractDateCheckUtilizeCalc;

  list: ContractCheckWeekElementWithColor[];
  conflict = false;

  constructor() {
  }

  ngOnInit(): void {
    this.setList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visibleWeekIndices && !changes.visibleWeekIndices.isFirstChange()) {
      this.setList();
    }

    if (changes.dateStart && !changes.dateStart.isFirstChange()) {
      this.setList();
    }
  }

  setList(): void {
    const weekStart = DateHelper.dateToWeekDate(this.dateStart);

    const leadTime = (this.materialHolder.variation.lead_time ?? 0) + 1;
    const leadDate = DateHelper.workDaysPlus(new Date(), leadTime, this.holidays);

    const dateStart = this.dateStart.getTime();
    const lastWeek = this.materialHolder.workload_list[this.materialHolder.workload_list.length - 1];
    this.conflict = false;
    this.materialHolder.workload_list.forEach(week => {
      if (weekStart.isBeforeOrAt(week.year, week.week) && week.amount_to_date < week.contract_to_date) {
        this.conflict = true;
      }
    });

    if (!weekStart.isBeforeOrAt(lastWeek.year, lastWeek.week) && lastWeek.amount_to_date < lastWeek.contract_to_date) {
      this.conflict = true;
    }

    this.list = this.visibleWeeks.map(week => {
      const isOverDateIndex = week.index >= this.materialHolder.workload_list.length;
      const materialInWeek = isOverDateIndex ? lastWeek : this.materialHolder.workload_list[week.index];

      const ccw: ContractCheckWeekElementWithColor = {
        date_code: week.date_code,
        first_day_of_week: week.first_day_of_week,
        week: week.week,
        year: week.year,
        marked_days: week.marked_days.map(m => m),
        confirmed: materialInWeek.confirmed,
        required: materialInWeek.required,
        amount_to_date: materialInWeek.amount_to_date,
        contract_to_date: materialInWeek.contract_to_date,
        required_to_date: materialInWeek.required_to_date,
        work_load: materialInWeek.work_load.map(wl => isOverDateIndex ? 0 : wl),
        classes: [{}, {}, {}, {}, {}, {}, {}],
        title: ['', '', '', '', '', '', ''],
        week_in_lead_time: week.first_day_of_week < leadDate,
      };

      ccw.classes.forEach((c, i) => {
        const currentDate = DateHelper.copy(ccw.first_day_of_week);
        currentDate.setDate(currentDate.getDate() + i);
        if (currentDate.getTime() === dateStart) {
          ccw.work_load[i] += this.materialHolder.amount;
        }
        const isGreen = currentDate.getTime() >= dateStart ?
          ccw.amount_to_date >= ccw.contract_to_date :
          ccw.amount_to_date >= ccw.required_to_date;
        const lightClass = DateHelper.isBusinessDay(currentDate, this.holidays) ? 'dark' : 'light';
        c[lightClass + (isGreen ? '-green' : '-red')] = true;
        if (this.conflict) {
          c[lightClass + '-gray'] = ccw.week_in_lead_time;
        }
        if (isGreen) {
          ccw.title[i] = 'Material Verfügbar';
        } else {
          if (ccw.week_in_lead_time) {
            ccw.title[i] = 'Material auch bei heutiger bestellung noch nicht verfügbar';
          } else {
            ccw.title[i] = 'Material nicht verfügbar, kann bestellt werden';
          }
        }
      });

      return ccw;
    });
  }
}
