<div class="modal-header">
  <h3 class="modal-title">Preis aus Preisliste</h3>
</div>

<form (ngSubmit)="submit()">
  <div class="modal-body">

    <div class="pricecheck-modal-row">
      <div class="pricecheck-modal-empty">
        <h3>Preisliste</h3>
      </div>
    </div>


    <div class="pricecheck-modal-row" *ngIf="data.price.releases && data.price_type ==='de'">
      <div class="pricecheck-modal-empty">
        <span class="bold col-sm-12 headline"><img src="/ui/assets/images/flags/de.png" title="DE"> Deutschland</span>
        <table class="table whitetable">
          <thead>
            <tr>
              <th>K-ME</th>
              <th>Lose</th>
              <th>Freigabe</th>
              <th>Einzel €</th>
              <th>Kalk</th>
              <th>Datum</th>
              <th>Notiz</th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let release of data.price.releases"
              [ngClass]="{'selected': isSelected(release?.release)}"
              (click)="select(release.release)"
              class="selectable-row">
            <td *ngIf="release.calc_quanity"> {{ release.calc_quanity }} </td>
            <td *ngIf="!release.calc_quanity">{{release.quanity}}</td>
            <td> {{ release.lose }} </td>
            <td> {{ release.release }} </td>
            <td> <strong>{{ release.single }}</strong> </td>
            <td> <strong>{{ release.calc }}</strong> </td>
            <td> {{ release.date|date: "dd.MM.yyyy" }} </td>
            <td class="notes-truncated-modal" title="{{release.notes}}">
                <span class="notes-inner" *ngIf="release.notes">
                {{ release.notes }}
                </span> &nbsp;
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="pricecheck-modal-row" *ngIf="data.price.releases && data.price_type ==='cn'">
      <div class="pricecheck-modal-empty">
        <span class="bold col-sm-12 headline"><img src="/ui/assets/images/flags/cn.png" title="DE"> China</span>
        <table class="table whitetable">
          <thead>
          <tr>
            <th>K-ME</th>
            <th>Lose</th>
            <th>Freigabe</th>
            <th>Produzent</th>
            <th>Einzel €</th>
            <th>Kalk</th>
            <th>Datum</th>
            <th>Notiz</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let release of data.price.releases"
              [ngClass]="{'selected': isSelected(release?.release)}"
              (click)="select(release.release)"
              class="selectable-row">
            <td *ngIf="release.calc_quanity"> {{ release.calc_quanity }} </td>
            <td *ngIf="!release.calc_quanity">{{release.quanity}}</td>
            <td> {{ release.lose }} </td>
            <td> {{ release.release }} </td>
            <td *ngIf="release.producer"> {{release.producer }}</td>
            <td *ngIf="!release.producer"></td>
            <td> <strong>{{ release.single }}</strong> </td>
            <td> <strong>{{ release.calc }}</strong> </td>
            <td> {{ release.date|date: "dd.MM.yyyy" }} </td>
            <td class="notes-truncated-modal" title="{{release.notes}}">
                <span class="notes-inner" *ngIf="release.notes">
                {{ release.notes }}
                </span> &nbsp;
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>

  <div class="modal-footer">
    <button type="button" (click)="dismiss()" class="btn btn-default pull-left">Abbrechen</button>

    <button class="btn btn-success pull-right" type="submit">
      Übernehmen
    </button>
  </div>
</form>
