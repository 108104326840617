<div class="modal-header">
  <div class="modal-header">
    <h3 class="modal-title">
      Produkt Gruppe erstellen
    </h3>
  </div>
</div>

<material-product-groups-form [form]="form"
                              [errors]="errors"
                              (cancel)="close()"
                              (save)="save()">
</material-product-groups-form>
