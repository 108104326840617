import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FetchWithPositions, MergedFrame} from '../../../models/frame.models';
import {FetchPosition, FetchPositionUpdateForm} from '../../../models/fetch.models';
import {RightService} from '../../../../../common/right-service/right.service';
import {debug} from '../../../../../helper/debug.func';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {FetchService} from '../../../workflow-services/fetch.service';
import {Article} from '../../../../article/models/article.models';
import {PriceIncreaseFlex} from '../../../models/price-increase-flex.model';
import {NumberHelper} from '../../../../../helper/number-helper';
import {Money} from '../../../../../common/money/Money';

@Component({
  selector: 'tr[contract-create-form-item-position]',
  templateUrl: './contract-create-form-item-position.component.html',
})
export class ContractCreateFormItemPositionComponent implements OnInit, OnChanges {
  @HostBinding('class.selectedgray') grayField = false;
  @Input('contract-create-form-item-position') position: FetchPosition;
  @Input('contract-create-form-item-position-fetch') fetch: FetchWithPositions;
  @Input('contract-create-form-item-position-index') positionIndex: number;
  @Input('contract-create-form-item-position-fetchIndex') fetchIndex: number;
  @Input('contract-create-form-item-position-nr') nr: boolean;
  @Input('contract-create-form-item-position-frame') frame: MergedFrame;
  @Input('contract-create-form-item-position-article') article: Article;
  @Input('contract-create-form-item-position-last-fetch-id') createdLastFetchId: number | null;
  @Input('contract-create-form-item-position-price-increase-flex') priceIncreaseFlex: PriceIncreaseFlex;
  @Output('contract-create-form-item-position-trigger') trigger = new EventEmitter<boolean>();
  @Output('contract-create-form-item-position-update-multi') updateEvent = new EventEmitter<FetchPosition[]>();
  @Output('contract-create-form-item-position-update-single') updateSingleEvent = new EventEmitter<FetchPosition[]>();
  @Output('contract-create-form-item-position-delete') deleteEvent = new EventEmitter<{ fetchIndex: number, positionIndex: number, fetch: FetchWithPositions, position: FetchPosition; }>();

  use: boolean;
  selected: boolean;
  hasPerm: boolean;
  editable: boolean;
  deletable: boolean;
  interval: any | null = null;
  displayDeliveryDateCheck: boolean;
  priceWithIncrease: number;
  priceTotal: number;
  priceIncreaseFlexString: string | null = null;

  constructor(private rightService: RightService,
              private alertService: AlertService,
              private fetchService: FetchService) {
  }

  ngOnInit(): void {
    this.use = !!this.fetch.use;
    this.hasPerm = this.rightService.has('price.contract.change');
    this.triggerUsage(false);
    this.displayDeliveryDateCheck =
      this.use && !!this.position.quanity && !!this.fetch.delivery && this.createdLastFetchId === this.fetch.id;
    this.priceRecalculate();
  }

  reloadExtraCostPercent(position: FetchPosition): void {
    this.fetchService.updatePercent(position.id).subscribe(f => {
      this.position = f.object;
    });
  }

  updateElement(fetchIndex: number, positionIndex: number, fetch: FetchWithPositions, position: FetchPosition): void {
    this.debounceUpdateElement(() => {
      debug('debounced update element');
      position.price_error = false;
      const data: FetchPositionUpdateForm = {
        label: position.label || '',
        desextension: position.desextension,
        quanity: position.quanity,
        price: position.price || '1',
        order_nr: fetch.order_nr,
        is_complaint: fetch.is_complaint,
        earliest_delivery: fetch.earliest_delivery,
        date: fetch.delivery,
        percentual: position.percentual,
      };
      this.fetchService.update(position.id, data).subscribe(response => {
        if (response['object']) {
          this.updateSingleEvent.emit(response['object']);
        } else if (response['objects']) {
          this.updateEvent.emit(response['objects']);
        }
        this.alertService.add('success', 'Abruf erfolgreich gespeichert!', 1500);
      }, (response: HttpErrorResponse) => {
        if (
          (response.status === 400) &&
          ((response.error?.quanity?.length ?? 0) > 0) &&
          (response.error.quanity[0] === 'amount.negative')
        ) {
          this.alertService.add('danger', 'Menge darf nicht negativ sein!');
        } else {
          this.alertService.add('danger', 'Abruf konnte nicht gespeichert werden!');
        }
        if ('price' in response.error) {
          position.price_error = true;
        }
      });
    }, 600);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // only allows editing if the status is zero or new number is selected AND if the group is
    // either management or admin or it's not an article position
    this.editable = this.isEditable();
    this.deletable = this.isDeletable();
    if (changes.position && !changes.position.isFirstChange()) {
      this.priceRecalculate();
    }
    if (changes.priceIncreaseFlex && !changes.priceIncreaseFlex.isFirstChange()) {
      this.priceRecalculate();
    }
  }

  serviceDropFetch(fetchIndex: number,
                   positionIndex: number,
                   fetch: FetchWithPositions,
                   position: FetchPosition): void {
    if (fetch.status < 5 || confirm('Soll diese Position wirklich gelöscht werden? Dies kann nicht rückgängig gemacht werden.')) {
      this.deleteEvent.emit({fetchIndex: fetchIndex, positionIndex: positionIndex, fetch: fetch, position: position});
    }
  }

  addFetch(): void {
    this.use = true;
    this.triggerUsage(true);
  }

  dropFetch(): void {
    this.use = false;
    this.triggerUsage(true);
  }

  private triggerUsage(emit: boolean): void {
    this.grayField = !this.use;
    if (emit) {
      this.fetch.use = this.use;
      this.trigger.emit(true);
    }
  }

  private isEditable() {
    return ((!this.nr || this.fetch.status === 0) && this.fetch.status !== 5) && (this.hasPerm || this.position.typ !== 'article');
  }

  private isDeletable(): boolean {
    let deletable = false;
    if (this.fetch.status < 1) {
      deletable = true;
    } else if (this.fetch.status < 5 && this.position.typ !== 'article') {
      deletable = true;
    } else if (this.fetch.status < 4) {
      deletable = !this.nr;
    } else if (this.rightService.isSuperuser() && this.position.typ !== 'article') {
      deletable = true;
    }
    return deletable;
  }

  private debounceUpdateElement(callback: () => void, time: number): void {
    const handle = this.interval;
    clearTimeout(handle as any);
    const newHandle = setTimeout(() => {
      this.interval = null;
      callback();
    }, time);
    this.interval = newHandle as any;
  }

  priceUpdate(price: string, fetchIndex: number, positionIndex: number, fetch: FetchWithPositions, position: FetchPosition): void {
    this.position.price = price;
    this.updateElement(fetchIndex, positionIndex, fetch, position);
  }

  priceRecalculate(): void {
    const priceIncrease = (
      this.fetch.status < 5 ?
        this.priceIncreaseFlex?.increase ?? null :
        this.position.price_increase ?? null
    );
    const priceParsed = NumberHelper.saveParseFloat(this.position.price as string);
    if (priceParsed !== null && priceIncrease !== null && this.position.typ === 'article') {
      const decimals = (this.frame.typ === 'de') ? 2 : 3;
      this.priceWithIncrease = NumberHelper.roundDecimal(priceParsed * (1 + (priceIncrease / 100)), decimals);
      this.priceIncreaseFlexString = `Flexibler Zuschlag von ${Money.stringify(priceIncrease, 2)} %`;
    } else if (priceParsed !== null) {
      this.priceWithIncrease = priceParsed;
      this.priceIncreaseFlexString = null;
    } else {
      this.priceWithIncrease = 0;
    }

    const quantityParsed = NumberHelper.saveParseInteger(this.position.quanity) ?? 0;
    this.priceTotal = (quantityParsed > 0) ? quantityParsed * this.priceWithIncrease : 0;
  }

}
