import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../common/alert-service/alert.service';
import {AddressService} from '../services/address.service';

@Component({
  selector: 'customer-detail-address-delete-modal',
  templateUrl: './customer-detail-address-delete-modal.component.html'
})
export class CustomerDetailAddressDeleteModalComponent {
  @Input() text: string;
  @Input() header: string;
  @Input() addressId: string;
  blocked = false;

  constructor(private modalService: NgbActiveModal,
              private alertService: AlertService,
              private addressService: AddressService) {
  }

  dismiss(): void {
    this.modalService.dismiss();
  }

  remove(): void {
    this.blocked = true;
    this.addressService.delete(this.addressId).subscribe(() => {
      this.alertService.add('success', 'Adresse erfolgreich gelöscht!');
      this.modalService.close();
    }, () => {
      this.blocked = false;
      this.alertService.add('danger', 'Es ist ein unbekannter Fehler aufgetreten!');
    });

  }

}
