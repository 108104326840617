import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Contact, ContactForm} from '../model/contact';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects} from '../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ContactService {

  private url = '/crm/v1/contact/';
  private urlV2 = environment.apiv4uri + 'customer/contact/';

  constructor(private http: HttpClient) {
  }

  detail(contactId: number): Observable<Contact> {
    return this.http.get<EnvisiaObject<Contact>>(this.url + contactId + '/').pipe(
      map(value => value.object)
    );
  }

  list(customerId: number): Observable<Contact[]> {
    return this.http.get<EnvisiaObjects<Contact>>(
      this.url + '?customer_id=' + customerId
    ).pipe(map(value => value.objects));
  }

  create(customerId: number, data: ContactForm): Observable<Contact> {
    return this.http.post<Contact>(
      this.urlV2 + '?customer_id=' + customerId,
      data
    );
  }

  edit(contactId: number, form: ContactForm): Observable<void> {
    return this.http.put<void>(this.urlV2 + contactId, form);
  }

  delete(contactId: number): Observable<void> {
    return this.http.delete<void>(this.url + contactId + '/');
  }

}
