<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-header">
    <h3 class="modal-title">Rechnung {{ isLocked ? 'entsperren' : 'sperren'}} - Rechnungs-Nr.: {{ invoiceId }}</h3>
  </div>

  <div class="modal-body">
    <div class="row form-group">
      <div class="col-sm-2">
        <label for="note">Grund:</label>
      </div>
      <div class="col-sm-10">
        <input id="note"
               class="form-control"
               name="note"
               formControlName="note"
               [ngClass]="{red_alert: !!errors['note']}">
      </div>
    </div>
  </div>

  <div class="modal-footer">
      <button type="submit"
              class="btn btn-success float-right"
              [disabled]="formLocked"
              *ngIf="isLocked">
        Rechnung entsperren
      </button>
      <button type="submit"
              class="btn btn-danger float-right"
              [disabled]="formLocked"
              *ngIf="!isLocked">
        Rechnung sperren
      </button>
  </div>
</form>
