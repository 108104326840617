import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ArticleSpecification} from '../models/article-specification.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export const ARTICLE_LIST_SPEC_SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleListSpecFilterComponent),
  multi: true,
};

@Component({
  selector: 'article-list-spec-select',
  templateUrl: './article-list-spec-filter.component.html',
  providers: [
    ARTICLE_LIST_SPEC_SELECT_VALUE_ACCESSOR,
  ],
})
export class ArticleListSpecFilterComponent implements ControlValueAccessor, OnInit {
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() specName: string;

  @Input() ngModel: string | null | undefined;
  @Output() ngModelChange = new EventEmitter<string | null | undefined>();
  keys: string[];

  disabled: boolean;

  selectedValue: string | null;
  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  constructor() {
  }

  ngOnInit(): void {
    this.keys = this.specification[this.specName]?.values ?? [];
    this.selectedValue = !this.ngModel ? null : this.ngModel;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    this._onChange(!obj ? null : obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectedValueChanges(value: string | null): void {
    this._onChange((value ?? null) as string | null);
  }

}
