import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BigWorkflowOffer} from '../../../models/workflow-create.models';
import {from, Observable, Subject} from 'rxjs';
import {WorkflowPositionView} from '../../../models/position.models';
import {PositionExtraModalComponent} from './position-extra-modal.component';
import {ignoreRejection} from '../../../../../helper/ignore_rejection';

@Injectable({
  providedIn: 'root'
})
export class OfferDetailPositionExtraModalService {

  constructor(private modalService: NgbModal) { }

  open(data: BigWorkflowOffer, subject: Subject<WorkflowPositionView>): Observable<any> {
    const modalRef = this.modalService.open(PositionExtraModalComponent, {windowClass: 'modal2-1200'});
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.subject = subject;
    return from(modalRef.result);
  }
}
