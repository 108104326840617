import * as React from 'react';
import {useEffect, useState} from 'react';

interface Props {
  errors: { [key: string]: string };
}

export function ArticleInventoryFormError(props: Props) {
  const [errorMessages, errorMessagesSet] = useState<string[]>([]);

  const setErrorMessages: () => void = () => {
    errorMessagesSet(
      Object.keys(props.errors)
        .map(k => (props.errors[k] ?? []) as string[])
        .reduce((a, em) => a.concat(em), []) // flatten
        .filter((t, i, arr) => arr.findIndex(t2 => t === t2) === i) // distinct
        .map(errorCode => {
          switch (errorCode) {
            case 'zero':
              return 'Menge darf nicht 0 sein';
            case 'negative.empty.note':
              return 'Bei Ausbuchungen muss eine Notiz angegeben werden';
            case 'positive.empty.workflow.id':
              return 'Vorgangs Nr. muss bei Einbuchungen angegeben werden';
            case 'not.found.workflow.id':
              return 'Konnte Vorgang nicht finden oder Vorgang stimmt nicht mit Artikel überein';
            case 'not.found.article':
              return 'Konnte Artikel nicht finden';
            case 'not.found.storage':
              return 'Konnte Lager / Lagerplatz nicht finden';
            case 'invalid.storage':
              return 'Konnte Lager / Lagerplatz nicht finden';
            case 'invalid.value.date.code':
              return 'Datecode muss das Format von KW/JJ haben';
            case 'invalid.below.zero':
              return 'Menge im Lager ist nicht ausreichend. Bitte auf Vorgangsnummer achten!';
            default:
              return null;
          }
        })
        .filter(errorMessage => errorMessage !== null)
    );
  };

  useEffect(() => {
    setErrorMessages();
  }, [props.errors]);

  if (!!errorMessages && errorMessages.length > 0) {
    return <div className={'alert red_alert'}>
      {
        errorMessages.map(errorMessage =>
          <div key={errorMessage}>
            {errorMessage}
          </div>
        )
      }
    </div>;
  } else {
    return null;
  }
}
