/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {BusinessOrderSimple} from '../models/business-order.models';
import {Observable, from} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderStartModalComponent} from './business-order-start-modal.component';

@Injectable({providedIn: 'root'})
export class BusinessOrderStartService {

  constructor(private modalService: NgbModal) {
  }

  start(order: BusinessOrderSimple): Observable<any> {
      const modalRef = this.modalService.open(BusinessOrderStartModalComponent);
      modalRef.componentInstance.order = order;
      return from<any>(modalRef.result);
  }

}
