import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InvoiceAutomationForm, InvoiceJob} from '../invoice.models';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects} from '../../../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class InvoiceAutomationService {
  private uri = '/api/v1/invoice/automation/';

  constructor(private http: HttpClient) {
  }

  create(form: InvoiceAutomationForm): Observable<{}> {
    // URI: / METHOD: POST
    return this.http.post<{}>(this.uri, form);

  }

  restart(invoiceId: number): Observable<{}> {
    return this.http.put<{}>(this.uri + 'restart/' + invoiceId + '/', {});
  }


  hide(invoiceId: number): Observable<{}> {
    return this.http.put<{}>(this.uri + 'hide/' + invoiceId + '/', {});
  }

  errors(): Observable<InvoiceJob[]> {
    return this.http.get<EnvisiaObjects<InvoiceJob>>(this.uri + 'errors/').pipe(map(value => value.objects));
  }


  working(): Observable<InvoiceJob[]> {
    return this.http.get<EnvisiaObjects<InvoiceJob>>(this.uri + 'working/').pipe(map(value => value.objects));
  }

  count(form: InvoiceAutomationForm): Observable<number> {
    return this.http.get<EnvisiaObject<number>>(
      this.uri + 'count/',
      {params: EnvisiaLocation.httpParams(form)}
    ).pipe(map(value => value.object));
  }

}
