import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MaterialOrderPosition} from '../material-order.model';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {Lister} from '../../../../common/wrapper.models';
import {ListService} from '../../../../common/search/search.service';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class MaterialOrderPositionListService implements ListService<MaterialOrderPosition> {
  constructor(protected http: HttpClient) {
  }

  list(query?: any): Observable<Lister<MaterialOrderPosition>> {
    return this.http.get<Lister<MaterialOrderPosition>>(
      environment.apiv4uri + 'material/order/position/list',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }
}
