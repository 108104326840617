<article-select-title [label]="'HDI'"
                      [model]="'hdi_type'"
                      [error]="errors['hdi_type']"
                      [form]="form"
                      [markedML]="markedML"
                      [specification]="specification">
</article-select-title>

<article-input label="SBU Lagen"
               model="sbu_layers"
               [form]="form" unit="Anzahl"
               [markedML]="markedML"
               [error]="errors['sbu_layers']"></article-input>

<article-input label="Microvia Lagen" model="microvias_layers" [form]="form" unit="Anzahl"
               [error]="errors['microvias_layers']"></article-input>

<article-multi [form]="form" [specification]="specification" [refCount]="refCount" [errors]="errors"
               *ngIf="form.value['microvias_layers'] > 0" model="microvias_layers">
  <ng-template let-item="item">
    <div class="row" *ngIf="!!form.get('microvias_top_'  + item.index) &&
                            !!form.get('microvias_bottom_'  + item.index)">
      <article-xy-input class="col-sm-8"
                        [label]="'Lage ' + item.index"
                        [form]="form"
                        [x]="'t'"
                        [y]="'b'"
                        [model1]="'microvias_top_'  + item.index"
                        [model2]="'microvias_bottom_'  + item.index">
      </article-xy-input>
      <div class="col-sm-4">&nbsp;</div>
    </div>
  </ng-template>
</article-multi>

<article-checkbox-with-select labelCheckbox="Cu Filling Außen"
                              label="Cu Filling Dicke"
                              modelCheckbox="cu_filling"
                              model="cu_filling_thickness"
                              unit="µm"
                              [specification]="specification"
                              [form]="form"
                              [errorCheckbox]="errors['cu_filling']"
                              [error]="errors['cu_filling_thickness']"
                              [refCount]="refCount">
</article-checkbox-with-select>

<article-checkbox label="Cu Plating extern" model="cu_galvanize_extern" [form]="form"
                  [error]="errors['cu_galvanize_extern']"></article-checkbox>

<article-checkbox label="THT" model="tht" [form]="form"
                  [error]="errors['tht']"></article-checkbox>

<section *ngIf="hdiVisible">
  <h5>HDI Innen</h5>

  <article-checkbox label="Burried Vias Kern" model="burried_vias_core" [form]="form"
                    [error]="errors['burried_vias_core']"></article-checkbox>

  <article-checkbox label="Viafill Kern" model="plugging_core" [form]="form"
                    [error]="errors['plugging_core']"></article-checkbox>

  <article-checkbox label="Capping Kern" model="capping_core" [form]="form"
                    [error]="errors['capping_core']"></article-checkbox>

  <article-checkbox label="Stacked Vias" model="stacked_vias" [form]="form"
                    [error]="errors['stacked_vias']"></article-checkbox>

  <article-checkbox-with-select labelCheckbox="Cu Filling Innen"
                                label="Cu Filling Dicke"
                                modelCheckbox="cu_filling_core"
                                model="cu_filling_thickness_core"
                                unit="µm"
                                [specification]="specification"
                                [form]="form"
                                [errorCheckbox]="errors['cu_filling_core']"
                                [error]="errors['cu_filling_thickness_core']"
                                [refCount]="refCount">
  </article-checkbox-with-select>

</section>
