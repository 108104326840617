import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CustomerCreditWorthiness} from './customer-credit-worthiness.models';

@Injectable({providedIn: 'root'})
export class CustomerCreditWorthinessService {

  constructor(private http: HttpClient) {
  }

  get(customerId: number): Observable<CustomerCreditWorthiness> {
    return this.http.get<CustomerCreditWorthiness>(
      `${environment.apiv4uri}customer/${customerId}/credit/overview`
    );
  }

  getWithWorkflowId(workflowId: number): Observable<CustomerCreditWorthiness> {
    return this.http.get<CustomerCreditWorthiness>(
      `${environment.apiv4uri}workflow/${workflowId}/credit/overview`
    );
  }
}
