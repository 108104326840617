/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import Helper from '../../../helper/helper';
import {deepCopy} from '../../../helper/deep-copy';
import {noop} from '../../../helper/noop';
import {ArticleExtraTableRow} from '../article-extra.models';

export const ARTICLE_EXTRA_TABLE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleExtraTableComponent),
  multi: true,
};

export const ARTICLE_EXTRA_TABLE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ArticleExtraTableComponent),
  multi: true
};

@Component({
  selector: 'article-extra-table',
  templateUrl: './article-extra-table.component.html',
  providers: [
    ARTICLE_EXTRA_TABLE_ACCESSOR,
    ARTICLE_EXTRA_TABLE_VALIDATOR,
  ]
})
export class ArticleExtraTableComponent implements ControlValueAccessor, Validator {
  @Input() disabled = false;
  current: ArticleExtraTableRow[] = [];
  private errors: { [key: string]: any; } = {};

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  writeValue(obj: any): void {
    if (Helper.undefined(obj)) {
      this.current = [];

      this.add(-1);
    } else {
      this.current = deepCopy(obj);
      if (Helper.isArray(obj)) {
        if (obj.length === 0) {
          this.add(-1);
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(c: AbstractControl): ValidationErrors | any {
    let parseError = false;
    Helper.keys(this.errors, (inner) => {
      Helper.keys(inner, (value) => {
        if (value) {
          parseError = true;
        }
      });
    });
    if (parseError) {
      return {'table': 'invalid'};
    } else {
      return null;
    }
  }

  onChange(row: number, name: string, value: any): void {
    if (!this.errors[row.toString()]) {
      this.errors[row.toString()] = {};
    }
    this.errors[row.toString()][name] = false;

    let cleaned;
    if (Helper.isInt(value)) {
      cleaned = Helper.toInt(value);
    } else if (value === '') {
      cleaned = null;
    } else {
      cleaned = value;
      this.errors[row.toString()][name] = true;
    }

    this.current[row][name] = cleaned;

    this._onChange(this.current);
  }

  /** row helper */
  add(row: number): void {
    if (Helper.isLastRow(this.current, row)) {
      const obj = {
        'quantity_till': null,
        'quantity': null,
        '%': null,
      };
      this.current.push(obj);
    }
  }

  remove(row: number): void {
    if (Helper.isLastRow(this.current, row) && row !== 0) {
      this.current.splice(row, 1);
    }
    this._onChange(this.current);
  }

  isLastRow(row) {
    return Helper.isLastRow(this.current, row);
  }

  trackByFn(index: number, item: ArticleExtraTableRow) {
    noop(this, item);
    return index;
  }

  checkError(index: number, name: string): boolean {
    let error = false;
    if (this.errors[index.toString()]) {
      error = !!this.errors[index.toString()][name];
    }
    return error;
  }

  cleanValue(value: any): any {
    noop(this);
    if (value === undefined) {
      return null;
    }
    return value;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    return this.disabled;
  }
}
