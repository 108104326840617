import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductionStepEntity} from '../../../envisia/article/production/production.models';
import {ProductionStepService} from '../../../envisia/article/production-services/production-step.service';

@Component({
  selector: 'production-step-edit',
  templateUrl: './production-step-edit.component.html',
})
export class ProductionStepEditComponent implements OnInit {

  errors?: { [key: string]: string; } = {};
  editForm: UntypedFormGroup;

  @Input() data: ProductionStepEntity;

  constructor(private productionStepService: ProductionStepService,
              private modalRef: NgbActiveModal,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      nr: [this.data.nr, Validators.required],
      text: [this.data.text, Validators.required],
      duration: [this.data.duration, Validators.required],
      max_duration: [this.data.max_duration, Validators.required],
      significance: [this.data.significance, null],
      active: [this.data.active, Validators.required],
      min_duration: [this.data.min_duration, null],
      booking_type: [this.data.booking_type, Validators.required],
      drill_op_code_type: [this.data.drill_op_code_type],
      drill_op_code: [this.data.drill_op_code],
      use_good_pieces: [this.data.use_good_pieces ?? false, Validators.required],
    });
  }

  edit() {
    this.errors = {};
    this.productionStepService.edit(this.data.id, this.editForm.value).subscribe((value) => {
      this.modalRef.close(value);
    }, (response) => {
      this.errors = response.error;
    });
  }

}
