import * as React from 'react';
import {ArticleMultilayerParts} from '../article-multilayer-plan.models';
import {ArticleMultilayerPartTypeHelper} from '../article-multilayer-part-type-helper';

interface Props {
  model: ArticleMultilayerParts;
}

export function ArticleMultilayerPlanDrillStartCell(props: Props) {
  const type = ArticleMultilayerPartTypeHelper.get(props.model.type);
  return <td className={'height-fix drill-cell'}>
    <div className={'rounded-full-center color-class ' + type.color_class} />
  </td>;
}
