import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as React from 'react';
import {
  ArticleMultilayerPlan,
  ArticleMultilayerPlanModel,
} from './article-multilayer-plan.models';
import {ArticleSpecification} from '../../models/article-specification.model';
import {Article} from '../../models/article.models';
import {ArticleMultilayerPlanUpdate} from './article-multilayer-plan-update';
import {ArticleMultilayerPlanHelper} from './article-multilayer-plan-helper';
import {StateService} from '@uirouter/angular';
import {RightService} from '../../../../common/right-service/right.service';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';

const containerElementName = 'reactArticleMultilayerPlanContainer';

@Component({
  selector: 'article-multilayer-plan-update',
  template: `<div #${containerElementName}></div>`,
})
export class ArticleMultilayerPlanUpdateComponent extends ReactBaseComponent implements OnInit {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() multilayerPlan: ArticleMultilayerPlan;
  @Input() externalModelChange: ArticleMultilayerPlanModel;
  @Input() article: Article;
  @Input() changes: number;
  @Input() specification: { [key: string]: ArticleSpecification };
  @Input() saveBtnPressed: number;
  @Output() saveBtnLocked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modelChanged: EventEmitter<ArticleMultilayerPlanModel> = new EventEmitter<ArticleMultilayerPlanModel>();
  @Output() modelSaved: EventEmitter<ArticleMultilayerPlan> = new EventEmitter<ArticleMultilayerPlan>();
  @Output() copyModelBtnPressed: EventEmitter<void> = new EventEmitter<void>();

  isSuperuser: boolean;

  constructor(private stateService: StateService,
              private rightService: RightService,
              private ngZone: NgZone) {
    super();
  }

  ngOnInit(): void {
    this.isSuperuser = this.rightService.isSuperuser();
    this.multilayerPlan.data = ArticleMultilayerPlanHelper.recalculate(this.multilayerPlan.data);
  }

  goToArticleAndInsert(): void {
    this.ngZone.run(() => {
      this.stateService.go(
        'a.article.workflow.detail',
        {
          id: this.article.oa_nr,
          copy_ml: true,
        });
    });
  }

  protected override renderComponent() {
    const _ = React.version;
    return <ArticleMultilayerPlanUpdate model={this.multilayerPlan}
                                        externalModelChange={this.externalModelChange}
                                        article={this.article}
                                        changeModel={m => this.modelChanged.emit(m)}
                                        isAdmin={this.isSuperuser}
                                        specification={this.specification}
                                        saveBtnPressed={this.saveBtnPressed}
                                        saveBtnLocked={sbc => this.saveBtnLocked.emit(sbc)}
                                        copyModelBtnPressed={() => this.copyModelBtnPressed.emit()}
                                        modelSaved={m => this.modelSaved.emit(m)}
                                        goToArticleAndInsert={() => this.goToArticleAndInsert()}
    />;
  }
}
