import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  MaterialOrder,
  MaterialOrderArticleListElement,
  MaterialOrderIntakeGroupElement
} from '../material-order.model';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {MaterialValueType} from '../../material-management.model';
import {MaterialManagementService} from '../../material-management.service';
import {noop} from '../../../../helper/noop';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialGroup} from '../../groups/material-group.model';

@Component({
  selector: 'material-order-update-group',
  templateUrl: './material-order-update-group.component.html'
})
export class MaterialOrderUpdateGroupComponent implements OnInit {
  @Input() group: MaterialOrderIntakeGroupElement;
  @Input() groups: Lister<MaterialGroup>;
  @Input() order: MaterialOrder;
  @Input() markedPositionId?: number;
  @Output() updateEvent = new EventEmitter<void>();
  @Output() copyEvent = new EventEmitter<void>();

  materialFieldData: LoadingWrapper<MaterialValueType[]>;
  groupPermitted: boolean;

  constructor(private mms: MaterialManagementService) {
  }

  ngOnInit(): void {
    this.materialFieldData = new LoadingWrapper<MaterialValueType[]>(this.mms.listFormFieldClasses(this.group.group.id));
    this.groupPermitted = !!this.groups.objects.find(mg => mg.id === this.group.group.id);
  }

  trackByFnControl(index: number, item: MaterialOrderArticleListElement): number {
    noop(this);
    return item.id;
  }
}
