import {MaterialSupplier} from '../../../lazy/material-supplier/material-supplier.model';
import {Lister} from '../../../common/wrapper.models';
import {environment} from '../../../../environments/environment';
import Axios, {AxiosResponse} from 'axios';
import {AxiosHttpClient} from '../../../envisia/auth/axios-http-client';

export class MaterialSupplierSelectAxiosService {
  static getDefault(supplierId: number, groupId?: number): Promise<MaterialSupplier> {
    return AxiosHttpClient.get<MaterialSupplier>(
      `${environment.apiv4uri}material/supplier/default`,
      {supplier_id: supplierId, group_id: groupId},
    );
  }

  static search(
    groupId: number,
    query: string,
    abortController?: AbortController,
  ): Promise<AxiosResponse<Lister<MaterialSupplier>>> {
    return Axios.create().get<Lister<MaterialSupplier>>(
      `${environment.apiv4uri}material/supplier/search`,
      {params: {group_id: groupId, query: query}, signal: abortController?.signal},
    );
  }
}
