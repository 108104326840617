import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InventoryGroup} from '../inventory.models';
import {Article} from '../../models/article.models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {InventoryService} from '../inventory.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'inventory-storage-note-create',
  templateUrl: './inventory-storage-note-create.component.html',
  styles: ['.mb-10 { margin-bottom:10px; }']
})
export class InventoryStorageNoteCreateComponent implements OnInit {
  @Input() article: Article;
  @Input() inventory: InventoryGroup;
  @Output() setActiveInventory: EventEmitter<InventoryGroup> = new EventEmitter<InventoryGroup>();

  form: UntypedFormGroup;
  errors: { [key: string]: any } = {};

  constructor(private inventoryService: InventoryService,
              private modalService: NgbActiveModal,
              private fb: UntypedFormBuilder,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'date_code': [this.inventory.dateCode, Validators.required],
      'storage_bin_name': [this.inventory.storageBinName, Validators.required],
      'storage_bin_number': [this.inventory.storageBinNumber],
      'storage_note': [null, Validators.required],
      'workflow_id': [this.inventory.workflowId],
    });
  }

  dismiss(): void {
    this.modalService.dismiss();
  }

  back(): void {
    this.setActiveInventory.emit(null);
  }

  submit(): void {
    this.errors = {};
    this.inventoryService.insertNote(this.article.oa_nr, this.form.value).subscribe(() => {
      this.setActiveInventory.emit(null);
    }, (errorResponse: HttpErrorResponse) => {
      if (errorResponse.status === 409) {
        this.alertService.add('danger', 'Es existiert bereits eine Notiz für dieses Lager.');
      } else if (errorResponse.error) {
        this.alertService.add('danger', 'Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Eingabe.');
        this.errors = errorResponse.error.errors;
      }
    });
  }
}
