import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ExtendedFrame} from '../../models/frame.models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FrameService} from '../../workflow-services/frame.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ErpProducer} from '../../../producer/producer.models';

@Component({
  selector: 'frame-box-update',
  templateUrl: './frame-box-update.component.html',
})
export class FrameBoxUpdateComponent implements OnInit {
  @Input() frame?: ExtendedFrame;
  @Input() producers: ErpProducer[];

  form: UntypedFormGroup;
  errors: {[key: string]: any} = {};

  constructor(private fb: UntypedFormBuilder,
              private service: FrameService,
              private alertService: AlertService,
              private modalRef: NgbActiveModal) {
  }

  ngOnInit(): void {
    const formObject = {
      quanity: [this.frame.quanity, Validators.required],
      lose: [this.frame.lose, Validators.required],
      single: [Number.parseFloat(this.frame.single.replace('.', '').replace(',', '.')), Validators.required],
      producer: [(this.producers ?? []).find(p => p.name === this.frame.producer)?.name],
    };
    if (this.frame.typ === 'cn') {
      formObject['cost'] = [
        !this.frame.cost ? null : Number.parseFloat(this.frame.cost.replace('.', '').replace(',', '.'))
      ];
    }
    this.form = this.fb.group(formObject);
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

  submit(): void {
    this.service.update(this.frame.workflow_id, this.form.value).subscribe(frame => {
      this.alertService.add('success', 'Auftragsrahmen wurde erfolgreich gespeichert');
      this.modalRef.close(frame);
    }, (errorResponse: HttpErrorResponse) => {
      if (errorResponse.error) {
        this.errors = errorResponse.error;
      }
    });
  }

}
