<div class="modal-header">
  <h3 class="modal-title">Rechnungskorrektur</h3>
</div>

<form class="form-horizontal" [formGroup]="form">

  <div class="modal-body">

    <div class="row">
      <div class="col-md-6">

        <customer-box formControlName="customer"
                      [realValue]="true"
                      (updateEmitter)="changeCustomer($event)"
                      [ngClass]="{red_alert: errors['obj.customer_id']}"
                      [clear]="false">
        </customer-box>
      </div>

      <div class="col-md-6">
        <address-box [customerId]="customerId"
                     [invoiceId]="invoiceId"
                     formControlName="address"
                     [ngClass]="{red_alert: errors['obj.address_id']}">
        </address-box>
      </div>
    </div>

    &nbsp;

    <div class="row">
      <div class="col-md-12">

        <div class="form-group">
          <label class="col-sm-2 control-label"><strong>Beleg-Datum*</strong></label>
          <div class="col-sm-10">
            <ev-datepicker class="form-control" formControlName="date" [evClass]="{red_alert: errors['obj.date']}">
            </ev-datepicker>
          </div>
        </div>

        <div class="form-group">
          <label for="label" class="col-sm-2 control-label"><strong>Positions-Bezeichnung*</strong></label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="label" formControlName="label"
                   [ngClass]="{red_alert: errors['obj.label']}">
          </div>
        </div>

        <div class="form-group">
          <label for="description" class="col-sm-2 control-label">Positions-Beschreibung</label>
          <div class="col-sm-10">
            <textarea class="form-control" id="description" formControlName="description"
                      [ngClass]="{red_alert: errors['obj.description']}">
            </textarea>
          </div>
        </div>


        <div class="form-group">
          <label for="sum" class="col-sm-2 control-label"><strong>Betrag*</strong></label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="sum" formControlName="sum"
                   [ngClass]="{red_alert: errors['obj.sum']}">
          </div>
        </div>

        <div class="form-group">
          <label for="hint" class="col-sm-2 control-label">Hinweis-Text</label>
          <div class="col-sm-10">
                        <textarea class="form-control" id="hint" formControlName="hint"
                                  [ngClass]="{red_alert: errors['obj.hint']}">
                        </textarea>
          </div>
        </div>

        <div class="form-group">
          <label for="note" class="col-sm-2 control-label">interne Notiz</label>
          <div class="col-sm-10">
                        <textarea class="form-control" id="note" formControlName="note"
                                  [ngClass]="{red_alert: errors['obj.note']}">
                        </textarea>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-12">
            <strong>* Pflichtfelder</strong>
          </div>
        </div>
        <div class="col-sm-offset-2 col-sm-10" *ngIf="('correction_all'|HasPerm)">
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="correction" [ngClass]="{red_alert: errors['obj.correction']}">
              Gutschrift / Provisionsabrechnung
            </label>
          </div>
        </div>
        <div class="col-sm-offset-2 col-sm-10" *ngIf="true">
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="without_ust" [ngClass]="{red_alert: errors['obj.without_ust']}">
              Ohne Umsatzsteuer
            </label>
          </div>
        </div>
      </div>


    </div>

  </div>


  <div class="modal-footer">

    <button class="btn btn-default pull-left" type="button" (click)="dismiss()">
      Abbrechen
    </button>

    <loading-button [clazz]="'btn btn-default'" name="Rechnungskorrektur erstellen" (loadingSave)="submit($event)">
    </loading-button>

  </div>

</form>
