import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CustomerComment} from '../customer/model/customer-comment';
import {EnvisiaObject, EnvisiaObjects} from '../../common/wrapper.models';
import {Comment} from './comment.models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CommentService {

  private uri = '/api/comment/';

  constructor(private http: HttpClient) {
  }

  customerList(id: number): Observable<CustomerComment[]> {
    return this.http.get<EnvisiaObjects<CustomerComment>>(this.uri + 'customer/list/' + id + '/').pipe(
      map(obj => obj.objects)
    );
  }

  list(typ: string, item: string): Observable<Comment[]> {
    return this.http.get<EnvisiaObjects<Comment>>(this.uri + typ + '/' + item + '/').pipe(
      map(obj => obj.objects)
    );
  }

  listAllByType(typ: string): Observable<Comment[]> {
    return this.http.get<EnvisiaObjects<Comment>>(this.uri + typ + '/').pipe(map(obj => obj.objects));
  }

  listBottom(typ: string, item: string): Observable<Comment[]> {
    return this.http.get<EnvisiaObjects<Comment>>(this.uri + typ + '/' + item + '/?bottom_placement=true').pipe(
      map(obj => obj.objects)
    );
  }

  create(typ: string, item: string, text: string): Observable<Comment> {
    const body = {text: text};
    return this.http.post<EnvisiaObject<Comment>>(this.uri + typ + '/' + item + '/', body).pipe(
      map(obj => obj.object)
    );
  }

  remove(typ: string, id: number): Observable<void> {
    return this.http.delete<void>(this.uri + typ + '/' + id + '/');
  }

  mark(typ: string, id: number): Observable<void> {
    return this.http.put<void>(this.uri + 'mark/' + typ + '/' + id + '/', {});
  }

}
