import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {DeliveryCocMaterialModalComponent} from './delivery-coc-material-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleSpecificationService} from '../../../article/services/article-specification.service';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryCocMaterialModalService {

  constructor(private articleSpecificationService: ArticleSpecificationService,
              private modalService: NgbModal) {
  }

  documentDownload(id: number, type: string, showModal: boolean, selected: string): Observable<string> {
    return this.modalOrNull(showModal, selected).pipe(map(material => this.cocDocumentUri(id, type, material)));
  }

  private cocDocumentUri(id: number, type: string, material: string | null): string {
    return `${environment.apiv4uri}delivery/pdf/coc/document/${id}/${type}` + (!!material ? `?material=${material}` : '');
  }

  private modalOrNull(showModal: boolean, selected: string): Observable<string | null> {
    if (showModal) {
      return this.showModal(selected);
    } else {
      return new Observable(o => {
        o.next(null);
        o.complete();
      });
    }
  }

  private showModal(selected: string): Observable<string> {
    return this.articleSpecificationService.manufacturerList()
      .pipe(mergeMap<string[], Observable<string>>(manufacturerList => {
        const ref = this.modalService.open(DeliveryCocMaterialModalComponent, {windowClass: 'modal2-sm'});
        ref.componentInstance.materials = manufacturerList.filter(material => material !== 'Standard FR4');
        ref.componentInstance.selected = selected;
        return from(ref.result);
      }));
  }
}
