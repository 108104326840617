import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects} from '../../common/wrapper.models';
import {
  CalculationCopyForm,
  CalculationQuantity,
  CalculationQuantityForm,
  CalculationTable,
  CalculationProcess,
  CalculationProcessListElement,
  CalculationProcessUpdateForm,
  CalculationTableRow,
  CalculationRowInsertForm,
} from './calculation.model';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class CalculationService {

  private uri = '/calculation/v1/table/';

  private copyUri = '/calculation/v1/copy/';

  constructor(private http: HttpClient) {
  }

  copy(form: CalculationCopyForm): Observable<EnvisiaObject<any>> {
    return this.http.post<EnvisiaObject<any>>(this.copyUri, form).pipe(map(obj => obj.object));
  }

  list(): Observable<CalculationProcessListElement[]> {
    return this.http.get<CalculationProcessListElement[]>(environment.apiv4uri + 'calculation/table/list');
  }

  toggleHide(id: number): Observable<void> {
    return this.http.put<void>(this.uri + `toggle-hide/${id}/`, {});
  }

  table(processId: number, layoutId: number): Observable<CalculationTable> {
    return this.http.get<CalculationTable>(`${environment.apiv4uri}calculation/table/${processId}/layout/${layoutId}`);
  }

  createRow(processId: number, layoutId: number, rowNumber: number): Observable<CalculationTableRow[]> {
    return this.http.post<CalculationTableRow[]>(
      `${environment.apiv4uri}calculation/table/${processId}/layout/${layoutId}`,
      rowNumber
    );
  }

  updateProcess(processId: number, form: CalculationProcessUpdateForm): Observable<CalculationProcess> {
    return this.http.put<CalculationProcess>(
      `${environment.apiv4uri}calculation/table/${processId}`,
      form
    );
  }

  createCell(processId: number, layoutId: number, row: number, data: CalculationRowInsertForm): Observable<CalculationTableRow> {
    return this.http.post<CalculationTableRow>(
      `${environment.apiv4uri}calculation/table/${processId}/layout/${layoutId}/row/${row}`,
      data
    );
  }

  updateCell(id: number, data: CalculationRowInsertForm): Observable<CalculationTableRow> {
    return this.http.put<CalculationTableRow>(`${environment.apiv4uri}calculation/table/cell/${id}`, data);
  }

  deleteRow(processId: number, layoutId: number, row: number): Observable<void> {
    return this.http.delete<void>(this.uri + `${processId}/layout/${layoutId}/row/${row}/`);
  }

  loseList(): Observable<CalculationQuantity[]> {
    return this.http.get<EnvisiaObjects<CalculationQuantity>>('/calculation/v1/lose/').pipe(
      map(val => val.objects)
    );
  }

  loseCreate(body: CalculationQuantityForm): Observable<CalculationQuantity[]> {
    return this.http.post<EnvisiaObjects<CalculationQuantity>>('/calculation/v1/lose/', body).pipe(
      map(val => val.objects)
    );
  }

  loseDelete(data: number[]): Observable<void> {
    const form = {items: data};
    return this.http.put<void>('/calculation/v1/lose/', form);
  }

}
