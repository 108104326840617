import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {CustomerService} from '../services/customer.service';
import {CustomerIncreaseService} from '../services/customer-increase.service';
import {CountryService} from '../services/country.service';
import {FieldServiceService} from '../../../envisia/customer/services/field-service.service';
import {RightService} from '../../../common/right-service/right.service';
import Helper from '../../../helper/helper';
import {UserService} from '../../user/user.service';
import {CustomerDetailComponent} from './customer-detail.component';
import {TermService} from '../services/term.service';
import {of, forkJoin} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {ProcessService} from '../../price/services/process.service';
import {ProducerService} from '../../producer/producer.service';
import {IndustrialSectorListService} from '../../../lazy/config/industrial-sector/industrial-sector-list.service';

function customerIncrease(id: number, has: boolean, service: CustomerIncreaseService) {
  if (has) {
    return service.list(id);
  } else {
    return of({de: null, cn: null});
  }
}

export function getBigCustomer(transition: Transition,
                               service: CustomerService,
                               countryService: CountryService,
                               increaseService: CustomerIncreaseService,
                               fieldServiceService: FieldServiceService,
                               producerService: ProducerService,
                               rightService: RightService,
                               userService: UserService,
                               industrialSectorListService: IndustrialSectorListService) {
  return forkJoin({
    customer: service.detail(transition.params().id),
    fieldService: fieldServiceService.list(),
    country: countryService.map(),
    producer: producerService.list(),
    industrialSector: industrialSectorListService.list({take: 200, sort: 'name'}),
  }).pipe(
    mergeMap(responses => {
      const customer = responses.customer;
      const fieldServices = responses.fieldService;
      const countries = responses.country;
      const producers = responses.producer;
      const industrialSectors = responses.industrialSector;
      const fieldService = Helper.getByKey(fieldServices, customer.field_service, 'name');
      let fieldServiceUserObs;
      if (fieldService && fieldService.username) {
        fieldServiceUserObs = userService.info(fieldService.username);
      } else {
        fieldServiceUserObs = of(null);
      }
      const customerIncreaseObs = customerIncrease(
        customer.id,
        rightService.has('price.increase'),
        increaseService
      );
      return forkJoin({
        customerIncrease: customerIncreaseObs,
        fieldService: fieldServiceUserObs
      }).pipe(
        map((innerResponses) => {
          return {
            customer: customer,
            country: countries,
            producers: producers,
            fieldServices: fieldServices,
            increase: innerResponses.customerIncrease,
            fieldServiceUser: innerResponses.fieldService,
            industrialSectors: industrialSectors.objects,
          };
        })
      );
    })
  ).toPromise();
}

export function getTermsResolveFn(termService: TermService) {
  return termService.list().toPromise();
}

export function customerCalculationResolveFn(processService: ProcessService, rightService: RightService) {
  if (rightService.has('settings.calculation') || rightService.has('article.calculation')) {
    return processService.list().toPromise();
  } else {
    // if the user has no right to see all calculations
    // he should get an empty array of all calculations,
    // this is importanted for the sales.restricted group
    return of({de: [], cn: []}).toPromise();
  }
}

export const CUSTOMER_DETAIL_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer.detail',
  url: '/crm/{id:[0-9]+}',
  views: {'main@a': {component: CustomerDetailComponent}},
  resolve: [
    {
      token: 'data',
      resolveFn: getBigCustomer,
      deps: [
        Transition,
        CustomerService,
        CountryService,
        CustomerIncreaseService,
        FieldServiceService,
        ProducerService,
        RightService,
        UserService,
        IndustrialSectorListService,
      ]
    },
    {
      token: 'terms',
      resolveFn: getTermsResolveFn,
      deps: [TermService]
    },
    {token: 'processes', resolveFn: customerCalculationResolveFn, deps: [ProcessService, RightService]}
  ],
  data: {requiresAuth: true}
};
