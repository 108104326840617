<label for="input-textarea">
  <ng-content></ng-content>
</label>

<textarea id="input-textarea" class="form-control"style="width: 100%;"
          (change)="onChange($event.target.value)"
          (keyup)="onChange($event.target.value)"
          [ngClass]="{red_alert: parseError}"
          [disabled]="disabled"
          [cols]="cols"
          [value]="current">
</textarea>
