import {ProductionStepService} from './production-step.service';

export function getProductionStepResolve(service: ProductionStepService) {
  return service.list().toPromise();
}

export const productionStepResolve = {
  token: 'objects',
  resolveFn: getProductionStepResolve,
  deps: [ProductionStepService],
};
