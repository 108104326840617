import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ArticleMultilayerPlanCopyListService} from './article-multilayer-plan-copy-list.service';
import {ArticleMultilayerPlan} from '../article-multilayer-plan.models';
import {Lister} from '../../../../../common/wrapper.models';

@Component({
  selector: 'article-multilayer-plan-copy-select-modal',
  templateUrl: './article-multilayer-plan-copy-select-modal.component.html',
})
export class ArticleMultilayerPlanCopySelectModalComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private searchSubscription: Subscription | null;
  form: UntypedFormGroup;
  list: Lister<ArticleMultilayerPlan> = {objects: [], count: 0, page: 1, size: 0, pages: 1};
  withArticle = true;
  selection: ArticleMultilayerPlan | null = null;

  constructor(private fb: UntypedFormBuilder,
              private modalRef: NgbActiveModal,
              private service: ArticleMultilayerPlanCopyListService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      oa_nr: [null],
      name: [null],
      page: [1],
    });
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => this.submitRequest());
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  submitRequest(): void {
    this.searchSubscription?.unsubscribe();
    this.searchSubscription = this.service.list(this.form.value).subscribe(list => {
      this.list = list;
    });
  }

  get query(): any {
    return this.form.value;
  }

  goto(page: number): void {
    this.form.patchValue({page: page});
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

  apply(): void {
    this.modalRef.close(this.selection);
  }

  setWithArticle(withArticle: boolean): void {
    this.withArticle = withArticle;
    this.form.reset({'oa_nr': null, 'name': null}, {emitEvent: false});
    this.list = {objects: [], count: 0, page: 1, size: 0, pages: 1};
    this.selection = null;
  }

  select(plan: ArticleMultilayerPlan): void {
    if (this.selection?.id === plan.id) {
      this.selection = null;
    } else {
      this.selection = plan;
    }
  }
}
