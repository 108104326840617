import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {
  BusinessOrderMaterialResponse,
  BusinessOrderSelectableMaterialQuery,
  BusinessOrderSelectVariationMaterialForm,
  BusinessOrderSelectPrepregMaterialForm,
  MaterialPlanningPollResponse,
  MaterialSelect,
} from './material-planning.model';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({
  providedIn: 'root'
})
export class MaterialPlanningService {
  uri = `${environment.apiv4uri}business/order/material/`;

  constructor(private http: HttpClient) {
  }

  getSelectableMaterial(id: number, index: number, form: BusinessOrderSelectableMaterialQuery): Observable<MaterialSelect> {
    const query = EnvisiaLocation.httpParams(form);
    return this.http.get<MaterialSelect>(this.uri + `${id}/${index}`, {params: query});
  }

  setMaterial(
    id: number,
    index: number,
    component: string,
    form: BusinessOrderSelectVariationMaterialForm | BusinessOrderSelectPrepregMaterialForm,
  ): Observable<BusinessOrderMaterialResponse> {
    return this.http.put<BusinessOrderMaterialResponse>(this.uri + `${id}/${index}/${component}`, form);
  }

  generateJob(): Observable<MaterialPlanningPollResponse> {
    return this.http.post<MaterialPlanningPollResponse>(`${environment.apiv4uri}material/planning/task`, {});
  }

  pollJob(): Observable<MaterialPlanningPollResponse> {
    return this.http.get<MaterialPlanningPollResponse>(`${environment.apiv4uri}material/planning/task`);
  }
}
