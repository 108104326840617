<h3>Gespeicherte Aufbaupläne</h3>

<form [formGroup]="form" (submit)="updateName()">
  <table class="table whitetable">
    <thead>
    <tr>
      <th class="align-right">Nr</th>
      <th>Bezeichnung</th>
      <th colspan="2"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let plan of list.objects; trackBy: trackBy">
      <td class="align-right">{{plan.id}}</td>
      <td *ngIf="form.value.shared_id !== plan.shared_id">
        {{plan.name}}&nbsp;<a class="click" (click)="setUpdateNameTarget(plan)"><i class="fa fa-pencil"></i></a>
      </td>
      <td *ngIf="form.value.shared_id === plan.shared_id">
        <input type="text" class="form-control input-sm" formControlName="name" />
        <button type="submit" class="btn btn-link"><i class="fa fa-save"></i></button>
        <button type="button" class="btn btn-link" (click)="setUpdateNameTarget(plan)"><i class="fa fa-times"></i></button>
      </td>
      <td>
        <a class="click" uiSref="a.multilayerplan.update"
           [uiParams]="{id: plan.shared_id}"
           [uiOptions]="{reload: true}">
          <i class="fa fa-file-o"></i>
        </a>
      </td>
      <td>
        <a class="click" (click)="openPdf(plan)"><i class="fa fa-file-pdf-o"></i></a>
      </td>
      <td>
        <a class="click" (click)="delete(plan)"><i class="fa fa-trash-o"></i></a>
      </td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
