<div class="modal-header">
  <h3 class="modal-title">Achtung!</h3>
</div>

<div class="modal-body">
  <p>Keine Abruf Bestell-Nr. bei ausgewählten Abrufen mit Lieferterminen eingetragen.</p>

  <p>Auftragsbestätigung ohne Abruf Bestell-Nr. erstellen?</p>
</div>

<div class="modal-footer">
  <button class="btn btn-default pull-left" type="button" (click)="close()">
    Abbrechen
  </button>

  <button class="btn btn-success pull-right" type="button" (click)="force()">
    Auft.-Best. erstellen
  </button>
</div>
