/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component} from '@angular/core';
import {ArticleExtraBaseComponent} from './article-extra-base.component';
import {UntypedFormBuilder} from '@angular/forms';

@Component({
  selector: 'article-extra-termination-date',
  templateUrl: './article-extra-termination-date.component.html',
})
export class ArticleExtraTerminationDateComponent extends ArticleExtraBaseComponent {

  constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  public formName = 'ba_termination';
  protected schemaName = 'ba-termination';
  protected fields = ['ba_termination'];
}
