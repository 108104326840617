import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of, from, forkJoin, throwError, EMPTY} from 'rxjs';
import {MailHeaderData} from './mail.models';
import {AlertService} from '../../common/alert-service/alert.service';
import {LocalStorage} from '../../common/storage/local-storage';
import {MailModalComponent} from './mail-modal.component';
import {MailService} from './mail.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {WorkflowService} from '../workflow/workflow-services/workflow.service';
import {WorkflowUser} from '../workflow/models/workflow.models';
import {MailHeaderService} from './mail-header.service';
import {InitData} from '../../common/init-data/init-data';

@Injectable({providedIn: 'root'})
export class MailModalService {

  constructor(private modalService: NgbModal,
              private workflowService: WorkflowService,
              private mailHeaderService: MailHeaderService,
              private mailService: MailService,
              private alertService: AlertService,
              private localStorage: LocalStorage) {
  }

  open(id: number | string,
       type: string,
       header: Observable<MailHeaderData> | MailHeaderData | null,
       locked: boolean = false,
       language: string = 'de',
       workflowId: number | null = null,
       withWorkflowUser: boolean = false,
       frameTyp?: string): Observable<void> {

    let headerObs: Observable<MailHeaderData>;
    if (header === null) {
      headerObs = this.mailHeaderService.customer(type, workflowId, language);
    } else if (header instanceof Observable) {
      headerObs = header;
    } else {
      headerObs = of(header);
    }

    const user = this.localStorage.getObject<InitData>('data').user;

    return forkJoin([
      headerObs,
      this.getMailUser(withWorkflowUser, workflowId)
    ]).pipe(
      mergeMap(responses => {
        const newHeader: MailHeaderData = responses[0];
        const workflowUser: WorkflowUser = responses[1];

        const templateData = {
          user: workflowUser || user,
          header: newHeader.header
        };

        return this.mailService.template(type, templateData).pipe(
          mergeMap(template => {
            const modalRef = this.modalService.open(MailModalComponent, {windowClass: 'modal2-slg'});
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.type = type;
            modalRef.componentInstance.header = newHeader;
            modalRef.componentInstance.locked = locked;
            modalRef.componentInstance.language = language;
            modalRef.componentInstance.user = user;
            modalRef.componentInstance.workflowUser = workflowUser;
            modalRef.componentInstance.template = template;
            modalRef.componentInstance.frameTyp = frameTyp;

            return from(modalRef.result).pipe(catchError(error => throwError({type: 3, error: error})));
          }),
          catchError(error => {
            if (error.type === 3) {
              return throwError(error);
            } else {
              return throwError({type: 2, error: error});
            }
          })
        );
      }),
      catchError(error => {
        if (error.type !== 3) {
          this.alertService.add('danger', 'Das Mail Modal konnte nicht geöffnet werden!');
          return throwError({type: 1});
        } else {
          return EMPTY;
        }
      })
    );
  }

  private getMailUser(withWorkflowUser: boolean, workflowId: number | null): Observable<WorkflowUser | null> {
    if (withWorkflowUser && workflowId !== null) {
      return this.workflowService.userInfo(workflowId);
    } else {
      return of(null);
    }
  }

}
