import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'multilayer-plan-navigation',
  templateUrl: './multilayer-plan-navigation.component.html',
})
export class MultilayerPlanNavigationComponent {
  @Input() locked = false;
  @Output() saveEmitter = new EventEmitter<void>();

  constructor() { }
}
