import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  MaterialOrderIntakeListElement,
  MaterialOrderPosition,
  MaterialOrderTransactionListElement,
} from '../../material-order.model';
import {MaterialValueType} from '../../../material-management.model';
import {FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {MaterialOrderIntakeService} from '../material-order-intake.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {MaterialOrderPositionService} from '../../material-order-position.service';
import {MaterialOrderIntakePrintModalService} from '../intake-print-modal/material-order-intake-print-modal.service';
import {MaterialOrderIntakeBookedRowAbstractComponent} from './material-order-intake-booked-row-abstract.component';
import {
  MaterialOrderIntakeBookedPositionForm,
  MaterialOrderIntakeBookedTransactionForm
} from './material-order-intake-booked-form.model';

@Component({
  selector: 'tr[material-order-intake-booked-row]',
  templateUrl: './material-order-intake-booked-row.component.html',
  styleUrls: ['../../material-order.component.scss'],
})
export class MaterialOrderIntakeBookedRowComponent extends MaterialOrderIntakeBookedRowAbstractComponent {
  @Input('material-order-intake-booked-row') position?: MaterialOrderIntakeListElement;
  @Input('material-order-intake-booked-row-index-g') iG: number;
  @Input('material-order-intake-booked-row-index-p') iP: number;
  @Input('material-order-intake-booked-row-index-t') iT: number;
  @Input('material-order-intake-booked-row-finder-supplier-uri') finderSupplierUri?: string;
  @Input('material-order-intake-booked-row-transaction') transaction: MaterialOrderTransactionListElement;
  @Input('material-order-intake-booked-row-form-position') formPosition: FormGroup<MaterialOrderIntakeBookedPositionForm>;
  @Input('material-order-intake-booked-row-form-transaction') formTransaction: FormGroup<MaterialOrderIntakeBookedTransactionForm>;
  @Input('material-order-intake-booked-row-errors') errors: { [key: string]: any; };
  @Input('material-order-intake-booked-row-fields') fields: MaterialValueType[];
  @Input('material-order-intake-booked-row-group-permit') groupPermission: boolean;
  @Input('material-order-intake-booked-row-is-first') isFirst: boolean;
  @Output('material-order-intake-booked-row-remove-transaction') removeTransaction =
    new EventEmitter<void>();
  @Output('material-order-intake-booked-row-update-position') updatePositionEmitter =
    new EventEmitter<MaterialOrderPosition>();

  constructor(private service: MaterialOrderIntakeService,
              protected positionService: MaterialOrderPositionService,
              private alertService: AlertService,
              private moipms: MaterialOrderIntakePrintModalService) {
    super(positionService);
  }

  remove(): void {
    if (confirm('Möchten Sie diese Einbuchung wirklich löschen?')) {
      this.service.deleteTransaction(this.transaction.id).subscribe(() => {
        this.alertService.add('success', 'Einbuchung erfolgreich gelöscht');
        this.removeTransaction.emit();
      }, (response: HttpErrorResponse) => {
        switch (response.status) {
          case 404:
            this.alertService.add('danger', 'Konnte Transaktion nicht finden');
            break;
          case 409:
            this.alertService.add('danger', 'Es gibt bereits Buchungen auf diesem Material');
        }
      });
    }
  }

  openPrint(transactionId: number): void {
    this.moipms.open(this.transaction.order_id, this.transaction.index, transactionId);
  }

  get errorPrice(): boolean {
    return !!this.errors[`groups[${this.iG}].positions[${this.iP}].transactions[${this.iT}].price`];
  }
}
