<td>
  <ev-file-button [objId]="contract.id" type="contract" [link]="true">
    {{ contract.id }}
    <span class="label label-purple" *ngIf="contract.date_sent">Versendet</span>
  </ev-file-button>
  <img *ngIf="contract.typ === 'cn'" src="/ui/assets/images/flags/cn.png">
  <img *ngIf="contract.typ === 'de'" src="/ui/assets/images/flags/de.png">
</td>
<td>{{ contract.contract_date | date:'dd.MM.yyyy'}}</td>
<td *ngIf="contract.fetches.length > 1">Abruf&nbsp;{{ contract.fetches[0].ordering }}</td>
<td *ngIf="contract.fetches.length <= 1"></td>
<td *ngIf="contract.fetches.length > 0">{{ contract.fetches[0].date_delivery|date:'dd.MM.yyyy' }}</td>
<td *ngIf="contract.fetches.length > 0">
  <div class="overflow-ellipsis no-wrap"
       style="width: 220px;"
       [ngbTooltip]="contract.fetches[0].order_nr">
    {{ contract.fetches[0].order_nr ?? '' }}
  </div>
</td>
<td colspan="2" *ngIf="contract.fetches.length === 0"></td>
<td>
  <div class="click" ngbTooltip="Abrufe zusammenklappen" (click)="toggle()" *ngIf="toggleShow">
    <i class="fa fa-minus red-font"></i>
  </div>
</td>
<td>{{ contract.offer_nr }}</td>
<td>
  <a uiSref="a.workflow.detail" [uiParams]="{id: contract.workflow_id}">
    {{ contract.workflow_id }}
  </a>
</td>
<td>
  <a uiSref="a.article.workflow.overview" [uiParams]="{id: contract.oa_nr}">
    {{ contract.sds_nr }}
  </a>
</td>
<td>
  <a uiSref="a.article.workflow.overview" [uiParams]="{id: contract.oa_nr}">
    {{ contract.kd_art_nr }}
  </a>
</td>
<td>
  <a uiSref="a.crm.customer.detail" [uiParams]="{id:contract.customer_id}">
    {{ contract.customer_name }}
  </a>
</td>
