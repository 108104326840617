import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {OposHistoryModalComponent} from './opos-history-modal.component';
import {OposHistoryCorrectionModalComponent} from './opos-history-correction-modal.component';

@NgModule({
    imports: [
        SharedModule,
        NgbModalModule,
    ],
    declarations: [
        OposHistoryModalComponent,
        OposHistoryCorrectionModalComponent,
    ],
    exports: [
        NgbModalModule,
        OposHistoryModalComponent,
        OposHistoryCorrectionModalComponent,
    ],
})
export class OposHistoryModalModule {
}
