<div class="modal-header">
  <h3>Produktionsvorlage anlegen</h3>
</div>

<form class="form-horizontal" [formGroup]="createForm" (ngSubmit)="create()">
  <div class="modal-body">

    <div class="form-group">
      <label for="template-name" class="col-sm-2 control-label">Name *</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="template-name" formControlName="name"
               [ngClass]="{red_alert: errors['obj.name']}" [autofocus]>
      </div>
    </div>

    <div class="form-group">
      <label for="template-description" class="col-sm-2 control-label">Beschreibung</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="template-description" formControlName="description"
               [ngClass]="{red_alert: errors['obj.description']}">
      </div>
    </div>

    <div class="form-group">
      <label for="template-expression" class="col-sm-2 control-label">Bedingung *</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="template-expression" formControlName="expression"
               [ngClass]="{red_alert: errors['obj.expression']}">

        <div>&nbsp;</div>

        <span *ngIf="errors['obj.expression']" class="red_alert">{{ errors['obj.expression'][0].msg }}</span>
      </div>

    </div>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success pull-right"
            [disabled]="createForm.pristine || !createForm.valid">
      Hinzufügen
    </button>
  </div>
</form>
