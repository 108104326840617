<div>
  <h3>Auftragsübersicht</h3>

  <ng-container *ngIf="countWrapper.data$|async as counts; else loadingOrError.template">
  <div class="contract-history-box">
    <table class="table whitetable">
      <tbody>
      <tr>
        <td>Warten auf Start</td>
        <td class="right">{{counts?.new}}</td>
      </tr>
      <tr>
        <td>In Produktion</td>
        <td class="right">{{counts?.production}}</td>
      </tr>
      <tr>
        <td>Zur Lagerbuchung</td>
        <td class="right">{{counts?.booking}}</td>
      </tr>
      <tr>
        <td>Etikettendruck</td>
        <td class="right">{{counts?.label_print}}</td>
      </tr>
      <tr>
        <td>Abgeschlossen</td>
        <td class="right">{{counts?.done}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  </ng-container>
  <loading-or-error
    #loadingOrError
    [loadingWrapper]="countWrapper"
    [errorMessage]="'Konnte Auftragsübersicht nicht laden'">
  </loading-or-error>
</div>
