<div class="modal-header">
  <h3 class="modal-title">Kalkulation ändern</h3>
</div>

<form (ngSubmit)="submit()" [formGroup]="form">
  <div class="modal-body">

    <ng-container *ngIf="processesData.data$ | async as processes; else loadingOrError.template">
      <div style="float:left; width: 325px;">
        <div class="input-group input-group-sm btn-group-justified" style="margin-bottom: 5px;">
        <span class="input-group-addon" style="width: 70px;">
           DEU:
        </span>
          <price-selector name="de" [form]="form" [processes]="processes.de" id="id_germany">
          </price-selector>
        </div>
        <div class="input-group input-group-sm btn-group-justified" style="margin-bottom: 5px;">
        <span class="input-group-addon" style="width: 70px;">
           CHN:
        </span>
          <price-selector name="cn" [form]="form" [processes]="processes.cn" id="id_china">
          </price-selector>
        </div>
      </div>
    </ng-container>

    <loading-or-error
      #loadingOrError
      [errorMessage]="'Konnte Artikel Prozesse nicht laden'"
      [loadingWrapper]="processesData">
    </loading-or-error>

  </div>

  <div class="modal-footer">

    <input [disabled]="send" class="btn btn-default btn-sm" id="button_calchange"
           style="margin: 10px 0 0; width: 100%;" type="submit" value="Kalkulationen übernehmen und Preise aktualisieren">

  </div>
</form>
