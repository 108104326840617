<div class="modal-header">
  <h3 class="modal-title">Ansprechpartner erstellen</h3>
</div>

<form name="contact" class="form-horizontal" [formGroup]="form" (ngSubmit)="save()">
  <div class="modal-body">
    <customer-contact-form [form]="form" [departments]="departments"></customer-contact-form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default float-left" (click)="dismiss()">Abbrechen</button>
    <button type="submit" class="btn btn-primary float-right" [disabled]="!form.valid">Speichern</button>
  </div>
</form>
