<ng-container>
  <select class="form-control input-sm"
          [id]="specName"
          [name]="specName"
          [disabled]="disabled"
          [ngModel]="selectedValue"
          (ngModelChange)="selectedValueChanges($event)">
    <option [ngValue]="null">-</option>
    <option [ngValue]="key" *ngFor="let key of keys">{{key}}</option>
  </select>
</ng-container>
