import * as React from 'react';
interface Props {
}

export function EvPageLoadingSpinner(props: Props) {
  return <div className="flex h-svh w-full items-center justify-center">
    <div className="inline-grid grid-cols-1 gap-6">
      <div className="flex items-center justify-center">
        <i className="fa fa-spin fa-circle-o-notch"/>
      </div>
      <div>
        Wird geladen...
      </div>
    </div>
  </div>;
}
