import {Component, Input} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ErrorSharedComponent} from './error-shared.component';

@Component({
  selector: 'error403',
  templateUrl: './error403.component.html'
})
export class Error403Component extends ErrorSharedComponent {
  @Input() additional?: string;

  constructor(protected authService: AuthService) {
    super();
  }
}
