/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {Observable, from, throwError} from 'rxjs';
import {BusinessOrder, BusinessOrderSimple} from '../models/business-order.models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderStornoModalComponent} from './business-order-storno-modal.component';
import {BusinessOrderService} from '../services/business-order.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {catchError, mergeMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BusinessOrderStornoService {

  constructor(private businessOrderService: BusinessOrderService,
              private modalService: NgbModal,
              private alertService: AlertService) {
  }

  call(ba: BusinessOrderSimple): Observable<{ confirm: boolean, business_order?: BusinessOrder }> {
    return this.businessOrderService.detail(ba.id, ba.index).pipe(
        catchError(error => {
          console.log('Modal Error', error);
          this.alertService.add('danger', 'Konnte BA nicht laden');
          return throwError('modal error');
        }),
        mergeMap(baDetail => {
          const modalRef = this.modalService.open(BusinessOrderStornoModalComponent, {windowClass: 'modal2-mm'});
          modalRef.componentInstance.ba = baDetail;
          return from(modalRef.result);
        }),
    );
  }

}
