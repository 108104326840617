<ng-container [formGroup]="form">

  <article-matrix formControlName="description_matrix" [defaults]="matrix" [error]="errors['description_matrix']">
    <article-input cssClass="col-sm-12" class="date_code_input" model="format_date_code" [form]="form"
                   [error]="errors['format_date_code']"></article-input>
    <article-input cssClass="col-sm-12" class="sonstiges_input" model="sonstiges" [form]="form"
                   [error]="errors['sonstiges']"></article-input>
  </article-matrix>

</ng-container>
