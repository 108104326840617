import {Ng2StateDeclaration} from '@uirouter/angular';
import {AkquiseService} from './akquise.service';
import {CustomerService} from '../customer/services/customer.service';
import {Transition} from '@uirouter/angular';
import {AkquiseListComponent} from './akquise-list.component';
import {CustomerNavigationComponent} from '../customer/navigation/customer-navigation.component';
import {of} from 'rxjs';
import {deepCopy} from '../../helper/deep-copy';
import {ErpTransition} from '../../core/erp-transition.service';


export function customerIdResolver(akquiseParams: any): number | undefined {
  let customerId: number | undefined = akquiseParams.customer_id;
  if (!customerId) {
    customerId = akquiseParams.id;
  }
  return customerId;
}

export function getCustomerAkquiseResolverFn(customerService: CustomerService, trans: Transition) {
  const customerId: number | undefined = customerIdResolver(trans.params());
  return (customerId ? customerService.detail(customerId) : of(null)).toPromise();
}

export function getListAkquiseResolverFn(service: AkquiseService, trans: ErpTransition) {
  const customerId: number | undefined = customerIdResolver(trans.params());
  const akquiseParams = deepCopy(trans.params());
  akquiseParams.customer_id = customerId;
  return service.list(akquiseParams).toPromise();
}

export function getAkquiseUserListResolverFn(service: AkquiseService) {
  return service.userList().toPromise();
}

export const akquiseResolver = [
  {
    token: 'listData',
    resolveFn: getListAkquiseResolverFn,
    deps: [AkquiseService, ErpTransition],
  },
  {
    token: 'customer',
    resolveFn: getCustomerAkquiseResolverFn,
    deps: [CustomerService, Transition],
  },
  {
    token: 'users',
    resolveFn: getAkquiseUserListResolverFn,
    deps: [AkquiseService]
  },
];

export const AKQUISE_STATE: Ng2StateDeclaration = {
  parent: 'a',
  name: 'akquise',
  url: '/akquise',
  redirectTo: 'akquise.list',
  data: {requiresAuth: true}
};

const params = {
  customer_id: {dynamic: true},
  typ: {dynamic: true},
  start: {dynamic: true},
  end: {dynamic: true},
  username: {dynamic: true},
  page: {dynamic: true}
};

export const AKQUISE_LIST_STATE: Ng2StateDeclaration = {
  name: 'akquise.list',
  url: '/list?customer_id&typ&start&end&username&page',
  views: {'main@a': {component: AkquiseListComponent}},
  params: params,
  resolve: akquiseResolver,
  data: {requiresAuth: true}
};

export const AKQUISE_CUSTOMER_STATE: Ng2StateDeclaration = {
  name: 'a.crm.customer.akquise_list',
  url: '/crm/{id}/akquise?customer_id&typ&start&end&username&page',
  views: {
    'navigation@a': {component: CustomerNavigationComponent},
    'main@a': {component: AkquiseListComponent}
  },
  params: params,
  resolve: akquiseResolver,
  data: {requiresAuth: true}
};

export const AKQUISE_STATES: Ng2StateDeclaration[] = [
  AKQUISE_STATE,
  AKQUISE_LIST_STATE,
  AKQUISE_CUSTOMER_STATE
];
