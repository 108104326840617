<div class="row">

  <div class="col-sm-6">

    <customer-box (updateEmitter)="updateCustomer($event)" [clear]="false" [realValue]="true">
    </customer-box>

  </div>

</div>

<div class="row" *ngIf="currentCustomer">
  <div class="col-sm-12">

    <h1>{{ currentCustomer.name }}</h1>

    <form class="form form-horizontal" [formGroup]="form" (ngSubmit)="submit()">

      <div class="form-group">
        <label for="inputTemplate" class="col-sm-2 control-label">Template:</label>
        <div class="col-sm-10">
          <textarea class="form-control" id="inputTemplate" rows="20" formControlName="template"></textarea>
        </div>
      </div>

      <button type="button" class="btn btn-danger" (click)="removeTemplate()">
        Template entfernen
      </button>

      <button type="submit" class="btn btn-success">
        Speichern
      </button>

    </form>

  </div>
</div>
