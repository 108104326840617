/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';

export const ARTICLE_EXTRA_TEXTAREA_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArticleExtraTextareaComponent),
  multi: true,
};

export const ARTICLE_EXTRA_TEXTAREA_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ArticleExtraTextareaComponent),
  multi: true
};

@Component({
  selector: 'article-extra-text-area',
  templateUrl: './article-extra-text-area.component.html',
  providers: [
    ARTICLE_EXTRA_TEXTAREA_ACCESSOR,
    ARTICLE_EXTRA_TEXTAREA_VALIDATOR,
  ]
})
export class ArticleExtraTextareaComponent implements ControlValueAccessor, Validator {
  @Input() type = 'string';
  @Input() cols: number;
  @Input() disabled = false;
  parseError = false;
  current: any;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  writeValue(obj: any): void {
    this.current = ((obj === null || obj === undefined) ? null : obj);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(c: AbstractControl): ValidationErrors | any {
    return null;
  }

  onChange(value: any): void {
    let cleaned;
    if (value === '') {
      cleaned = null;
    } else {
      cleaned = value;
    }

    this._onChange(cleaned);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    return this.disabled;
  }
}
