/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Ng2StateDeclaration, Transition} from '@uirouter/angular';
import {MaterialManagementService} from './material-management.service';
import {MaterialGroupListComponent} from './groups/material-group-list.component';
import {MaterialArticleListComponent} from './articles/material-article-list.component';
import {MaterialFieldListComponent} from './fields/material-field-list.component';
import {MaterialNavigationComponent} from './material-navigation.component';
import {MaterialFieldCreateComponent} from './fields/material-field-create.component';
import {MaterialGroupListElementSelectable, MaterialValueType} from './material-management.model';
import {MaterialInventoryListComponent} from './inventory/material-inventory-list.component';
import {MaterialInventorySelectComponent} from './inventory/material-inventory-select.component';
import {MaterialArticleCreateComponent} from './articles/material-article-create.component';
import {MaterialArticleUpdateFormHolderComponent} from './articles/material-article-update-form-holder.component';
import {MaterialUnitsListComponent} from './units/material-units-list.component';
import {MaterialInventoryListGroupSelectComponent} from './inventory/material-inventory-list-group-select.component';
import {MaterialInventoryListService} from './inventory/material-inventory-list.service';
import {deepCopy} from '../../helper/deep-copy';
import {MaterialSupplierService} from '../../lazy/material-supplier/material-supplier.service';
import {
  MATERIAL_ORDER_CREATE_STATE,
  MATERIAL_ORDER_INTAKE_STATE,
  MATERIAL_ORDER_LIST_STATE,
  MATERIAL_ORDER_POSITION_LIST_STATE,
  MATERIAL_ORDER_POSITION_STATE,
  MATERIAL_ORDER_STATE
} from './order/material-order.states';
import {MaterialInventoryDetailComponent} from './inventory/material-inventory-detail.component';
import {MATERIAL_PLANNING_LIST_LANDINGPAGE_STATE, MATERIAL_PLANNING_LIST_STATE} from './planning/material-planning.states';
import {MATERIAL_USER_LIST_STATE} from './user-list/material-user-list.states';
import {MaterialGroupListAllService} from './groups/material-group-list-all.service';
import {MaterialGroupListPermittedService} from './groups/material-group-list-permitted.service';
import {MATERIAL_PRODUCT_GROUP_STATE} from './product-groups/material-product-groups.states';

export function materialListResolveFn(ts: Transition, mms: MaterialManagementService) {
  const groupId = Number(ts.params().group);
  if (Number.isInteger(groupId)) {
    return mms.listArticle(groupId).toPromise();
  } else {
    return mms.listArticle().toPromise();
  }
}

export function materialGroupListResolveFn(ts: Transition, mgs: MaterialGroupListAllService) {
  return mgs.list(ts.params()).toPromise();
}

export function materialGroupListByPermissionResolveFn(ts: Transition, mgs: MaterialGroupListPermittedService) {
  return mgs.list(ts.params()).toPromise();
}

export function materialGroupSelectableListResolveFn(ts: Transition, mgs: MaterialGroupListAllService) {
  return materialGroupListResolveFn(ts, mgs).then(groups => {
    return groups.objects.map(group =>
      ({...group, is_selected: false} as MaterialGroupListElementSelectable));
  });
}

export function materialFieldListResolveFn(ts: Transition, mms: MaterialManagementService) {
  const sort = ts.params().sort;
  const order = ts.params().order === 'DESC';
  return mms.listField(sort ? sort : null, order).toPromise();
}

export function materialFieldUpdateResolveFn(ts: Transition, mms: MaterialManagementService) {
  const fieldId = Number(ts.params().field);
  return mms.getField(fieldId).toPromise();
}

export function materialGetGroupResolveFn(ts: Transition, mms: MaterialManagementService) {
  const groupId = Number(ts.params().group);
  if (ts.params().group && !Number.isNaN(groupId) && Number.isInteger(groupId)) {
    return mms.getSingleGroup(groupId).toPromise();
  } else {
    return null;
  }
}

export function materialArticleFieldDefWithArticleIdResolveFn(ts: Transition, mms: MaterialManagementService) {
  const articleId = Number(ts.params().article);
  return mms.listCreateArticleFormElementsWithArticleId(articleId).toPromise().then((value) => {
    return value.map(mf =>
      new MaterialValueType(mf.id, mf.name, mf.data_type, mf.read_only, mf.value, mf.choices)
    );
  });
}

export function materialArticleFieldDefByGroupIdResolveFn(ts: Transition, mms: MaterialManagementService) {
  const groupId = Number(ts.params().group);
  if (ts.params().group && !Number.isNaN(groupId) && Number.isInteger(groupId)) {
    return mms.listFormFieldClasses(groupId).toPromise();
  } else {
    return null;
  }
}

export function materialVariationResolveFn(ts: Transition, mms: MaterialManagementService) {
  const articleId = Number(ts.params().article);
  return mms.listCreateVariationFormElements(articleId);
}

export function materialArticleGetResolveFn(ts: Transition, mms: MaterialManagementService) {
  const articleId = Number(ts.params().article);
  return mms.getSingleArticle(articleId).toPromise();
}

export function materialInventoryListResolveFn(ts: Transition, mils: MaterialInventoryListService) {
  const params = deepCopy(ts.params());
  const groupId = Number(params.group);
  params.fields?.toString().split('_field_').forEach(kv => {
    const s = kv.split('=');
    params[`field_${s[0]}`] = decodeURIComponent(s[1]);
  });
  delete params['fields'];
  delete params['group'];

  if (ts.params().group && !Number.isNaN(groupId) && Number.isInteger(groupId)) {
    return mils.list(groupId, params).toPromise();
  } else {
    return null;
  }
}

export function materialInventoryResolveFn(ts: Transition, mms: MaterialManagementService) {
  const variationId = ts.params().variation;
  return mms.listInventoryForVariation(variationId).toPromise();
}

export function materialVariationArticleGroupResolveFn(ts: Transition, mms: MaterialManagementService) {
  const variation = ts.params().variation;
  return mms.getAggregation(variation).toPromise();
}

export function materialUnitsList(mms: MaterialManagementService) {
  return mms.listUnits().toPromise();
}

export function materialSearchParamResolveFn(ts: Transition) {
  return ts.params().searchParams;
}

export function materialSupplierListResolveFn(mss: MaterialSupplierService) {
  return mss.list().toPromise();
}

export function materialVariationsGetResolveFn(ts: Transition, mms: MaterialManagementService) {
  const articleId = Number(ts.params().article);
  return mms.listVariations(articleId).toPromise();
}

export const ARTICLE_MANAGEMENT_STATES: Ng2StateDeclaration[] = [
  {
    name: 'a.material',
    abstract: true,
    data: {
      requiresAuth: true
    },
    views: {
      'navigation@a': {component: MaterialNavigationComponent}
    }
  },
  {name: 'a.material.group', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.users', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.field', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.article', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.variation', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.inventory', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.units', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.planning', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.planning.list', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.product', abstract: true, data: {requiresAuth: true}},
  {name: 'a.material.product.groups', abstract: true, data: {requiresAuth: true}},
  {
    name: 'a.material.article.list',
    url:  '/material/article?group',
    views: {'main@a': {component: MaterialArticleListComponent}},
    params: {
      group: {dynamic: true},
    },
    resolve: [
      {
        token: 'currentGroup',
        resolveFn: materialGetGroupResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'articles',
        resolveFn: materialListResolveFn,
        deps: [Transition, MaterialManagementService],
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.article.create',
    url: '/material/article/create?group',
    views: {'main@a': {component: MaterialArticleCreateComponent}},
    params: {
      group: {dynamic: true}
    },
    resolve: [
      {
        token: 'currentGroup',
        resolveFn: materialGetGroupResolveFn,
        deps: [Transition, MaterialManagementService]
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.article.update',
    url: '/material/article/update?article',
    views: {'main@a': {component: MaterialArticleUpdateFormHolderComponent}},
    params: {
      article: {dynamic: true}
    },
    resolve: [
      {
        token: 'originalArticle',
        resolveFn: materialArticleGetResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'variations',
        resolveFn: materialVariationsGetResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'articleFieldDefs',
        resolveFn: materialArticleFieldDefWithArticleIdResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'variationFieldDefs',
        resolveFn: materialVariationResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'units',
        resolveFn: materialUnitsList,
        deps: [MaterialManagementService],
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.group.list',
    url:  '/material/group?sort&order',
    views: {'main@a': {component: MaterialGroupListComponent}},
    params: {
      sort: {dynamic: true},
      order: {dynamic: true}
    },
    resolve: [
      {
        token: 'listData',
        resolveFn: materialGroupListResolveFn,
        deps: [Transition, MaterialGroupListAllService]
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.field.list',
    url:  '/material/field?sort&order',
    views: {'main@a': {component: MaterialFieldListComponent}},
    params: {
      sort: {dynamic: true},
      order: {dynamic: true}
    },
    resolve: [
      {
        token: 'fields',
        resolveFn: materialFieldListResolveFn,
        deps: [Transition, MaterialManagementService]
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.field.create',
    url: '/material/field/create',
    views: {'main@a': {component: MaterialFieldCreateComponent}},
    resolve: [
      {
        token: 'groupList',
        resolveFn: materialGroupSelectableListResolveFn,
        deps: [Transition, MaterialGroupListAllService]
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.field.update',
    url: '/material/field/update?field',
    views: {'main@a': {component: MaterialFieldCreateComponent}},
    params: { field: {dynamic: true}},
    resolve: [
      {
        token: 'fieldAttributes',
        resolveFn: materialFieldUpdateResolveFn,
        deps: [Transition, MaterialManagementService]
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.inventory.list-select',
    url:  '/material/inventory',
    views: {'main@a': {component: MaterialInventoryListGroupSelectComponent}},
    data: {requiresAuth: true},
    resolve: [
      {
        token: 'groups',
        resolveFn: materialGroupListByPermissionResolveFn,
        deps: [Transition, MaterialGroupListPermittedService],
      },
    ],
  },

  {
    name: 'a.material.inventory.list',
    url:  '/material/inventory/{group}?page&sort&order&variation_id&vendor_article_id&supplier&article_name&charge&location&unit&min_stock&fields&status&passed_deadline',
    views: {'main@a': {component: MaterialInventoryListComponent}},
    params: {
      group: {dynamic: true},
      page: {dynamic: true},
      sort: {dynamic: true},
      order: {dynamic: true},
      variation_id: {dynamic: true},
      vendor_article_id: {dynamic: true},
      supplier: {dynamic: true},
      article_name: {dynamic: true},
      charge: {dynamic: true},
      location: {dynamic: true},
      unit: {dynamic: true},
      min_stock: {dynamic: true},
      fields: {dynamic: true},
      status: {dynamic: true},
      passed_deadline: {dynamic: true},
    },
    resolve: [
      {
        token: 'currentGroup',
        resolveFn: materialGetGroupResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'fields',
        resolveFn: materialArticleFieldDefByGroupIdResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'listData',
        resolveFn: materialInventoryListResolveFn,
        deps: [Transition, MaterialInventoryListService]
      },
      {
        token: 'units',
        resolveFn: materialUnitsList,
        deps: [MaterialManagementService]
      },
      {
        token: 'suppliers',
        resolveFn: materialSupplierListResolveFn,
        deps: [MaterialSupplierService]
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.inventory.update',
    url:  '/material/inventory/update?variation',
    views: {'main@a': {component: MaterialInventoryDetailComponent}},
    params: {
      article: {dynamic: true},
      searchParams: {dynamic: true},
    },
    resolve: [
      {
        token: 'currentInventory',
        resolveFn: materialInventoryResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'currentAggregation',
        resolveFn: materialVariationArticleGroupResolveFn,
        deps: [Transition, MaterialManagementService]
      },
      {
        token: 'searchParams',
        resolveFn: materialSearchParamResolveFn,
        deps: [Transition]
      }
    ],
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.inventory.create',
    url:  '/material/inventory/create',
    views: {'main@a': {component: MaterialInventorySelectComponent}},
    data: {requiresAuth: true}
  },

  {
    name: 'a.material.units.list',
    url:  '/material/units',
    views: {'main@a': {component: MaterialUnitsListComponent}},
    resolve: [
      {
        token: 'units',
        resolveFn: materialUnitsList,
        deps: [MaterialManagementService],
      }
    ],
    data: {requiresAuth: true}
  },
  MATERIAL_ORDER_STATE,
  MATERIAL_ORDER_LIST_STATE,
  MATERIAL_ORDER_CREATE_STATE,
  MATERIAL_ORDER_INTAKE_STATE,
  MATERIAL_ORDER_POSITION_STATE,
  MATERIAL_ORDER_POSITION_LIST_STATE,
  MATERIAL_PLANNING_LIST_LANDINGPAGE_STATE,
  MATERIAL_PLANNING_LIST_STATE,
  MATERIAL_USER_LIST_STATE,
  MATERIAL_PRODUCT_GROUP_STATE,
];
