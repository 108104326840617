import {Ng2StateDeclaration} from '@uirouter/angular';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {WorkflowCnOrderListComponent} from './list/workflow-cn-order-list.component';
import {ErpTransition} from '../../../core/erp-transition.service';
import {WorkflowCnOrderListService} from './list/workflow-cn-order-list.service';

export const ORDER_REQUEST_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.order',
  abstract: true,
  data: {requiresAuth: true}
};

export function orderListResolveFn(transition: ErpTransition, service: WorkflowCnOrderListService) {
  return service.list(transition.params()).toPromise();
}

export const ORDER_REQUEST_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.order.list',
  url: '/workflow/order/order/list?page&order&sort&status&sid&workflow_id&sds_nr&oa_nr&kd_art_nr&producer&request_nr&quanity&price&customer&username&booking_id&supplier_rg_nr&cfa&delivery_method&workflow_order_testing&delivery_date_start&delivery_date_end&delivery_date_estimated_start&delivery_date_estimated_end',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: WorkflowCnOrderListComponent}
  },
  resolve: [{token: 'listData', resolveFn: orderListResolveFn, deps: [ErpTransition, WorkflowCnOrderListService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    status: {dynamic: true},
    sid: {dynamic: true},
    workflow_id: {dynamic: true},
    sds_nr: {dynamic: true},
    oa_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    producer: {dynamic: true},
    request_nr: {dynamic: true},
    quanity: {dynamic: true},
    price: {dynamic: true},
    customer: {dynamic: true},
    username: {dynamic: true},
    booking_id: {dynamic: true},
    supplier_rg_nr: {dynamic: true},
    cfa: {dynamic: true},
    delivery_method: {dynamic: true},
    workflow_order_testing: {dynamic: true},
    delivery_date_start: {dynamic: true},
    delivery_date_end: {dynamic: true},
    delivery_date_estimated_start: {dynamic: true},
    delivery_date_estimated_end: {dynamic: true},
  },
  data: {requiresAuth: true}
};

export const ORDER_REQUEST_STATES: Ng2StateDeclaration[] = [
  ORDER_REQUEST_MAIN_STATE,
  ORDER_REQUEST_LIST_STATE
];
