<div id="konsi" class="form-control" style="padding: 0;">
  <button class="btn col-sm-6" type="button"
          [ngClass]="{active: transactionForm.value.konsi === false}"
          (click)="setKonsiState(false)">
    <i class="fa" [ngClass]="{'fa-check': transactionForm.value.konsi === false}"></i> Eigenbestand
  </button>
  <button class="btn col-sm-6" type="button"
          [ngClass]="{active: transactionForm.value.konsi === true}"
          (click)="setKonsiState(true)">
    <i class="fa" [ngClass]="{'fa-check': transactionForm.value.konsi === true}"></i> Konsibestand
  </button>
</div>
