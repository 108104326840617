<div class="modal-header">
  <h3 class="modal-title">
    Bestellung editieren Nr.: {{ data.id }}-{{ data.positions.index + 1 }}
  </h3>
</div>

<form class="form-horizontal" [formGroup]="form">
  <div class="modal-body">

    <div class="form-group">
      <label for="inputQuantity" class="col-sm-2 control-label">Menge</label>
      <div class="col-sm-10">
        <input type="number" id="inputQuantity" class="form-control" formControlName="quanity">
      </div>
    </div>

    <div class="form-group">
      <label for="inputPrice" class="col-sm-2 control-label">Preis</label>
      <div class="col-sm-10">
        <input type="text" id="inputPrice" class="form-control" formControlName="price">
      </div>
    </div>

    <div class="form-group">
      <label for="inputDeliveryDate" class="col-sm-2 control-label">Lief.Ter. (ETD)</label>
      <div class="col-sm-10">
        <ev-datepicker id="inputDeliveryDate" formControlName="delivery_date" class="form-control"
                       [allDates]="true"
                       [writeBack]="false">
        </ev-datepicker>
      </div>
    </div>


    <div class="form-group">
      <label for="inputCallOf" class="col-sm-2 control-label">Auf Abruf</label>
      <div class="col-sm-10">
        <input type="checkbox" id="inputCallOf" class="form-control" formControlName="call_of">
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" (click)="dismiss()">
      Abbrechen
    </button>
    <button type="button" class="btn btn-success pull-right" (click)="save()">
      Speichern
    </button>
  </div>

</form>
