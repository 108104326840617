import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {IndustrialSector} from '../../../envisia/industrial-sector/producer.models';
import {environment} from '../../../../environments/environment';
import {ListService} from '../../../common/search/search.service';
import {EnvisiaLocation} from '../../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class IndustrialSectorListService implements ListService<IndustrialSector> {

  constructor(private http: HttpClient) {
  }

  list(query?: any): Observable<Lister<IndustrialSector>> {
    return this.http.get<Lister<IndustrialSector>>(
      environment.apiv4uri + 'industrial-sector',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

  create(form: any): Observable<IndustrialSector> {
    return this.http.post<IndustrialSector>(
      environment.apiv4uri + 'industrial-sector',
      form,
    );
  }
}
