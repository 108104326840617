/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  MaterialGroupForm,
  MaterialGroupListElement,
  MaterialFieldForm,
  MaterialFieldListElement,
  MaterialArticle,
  MaterialFieldAttributes,
  MaterialArticleFieldForm,
  MaterialVariation,
  MaterialValueType,
  MaterialTransaction,
  MaterialStorage,
  MaterialVariationAggregation,
  MaterialArticleVariationsForm,
  MaterialArticleVariationUpsertResponse,
  MaterialUnit,
  MaterialArticleListElement,
  MaterialAggregation,
  MaterialTransferStockForm,
} from './material-management.model';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LocalStorage} from '../../common/storage/local-storage';
import {EnvisiaLocation} from '../../common/location/envisia-location';

@Injectable({providedIn: 'root'})
export class MaterialManagementService {
  private uri = '/api/material';

  constructor(
    private http: HttpClient
  ) {
  }

  listArticle(groupId?: Number): Observable<MaterialArticleListElement[]> {
    return this.http.get<MaterialArticleListElement[]>(
      this.uri + '/article',
      groupId ? {params: {group: groupId.toString()}} : {}
    );
  }

  createArticle(form: MaterialArticleVariationsForm): Observable<MaterialArticleVariationUpsertResponse> {
    return this.http.post<MaterialArticleVariationUpsertResponse>(this.uri + '/article', form);
  }

  updateArticle(id: number, form: MaterialArticleVariationsForm): Observable<MaterialArticleVariationUpsertResponse> {
    return this.http.put<MaterialArticleVariationUpsertResponse>(this.uri + '/article/' + id.toString(), form);
  }

  getSingleArticle(articleId: Number): Observable<MaterialArticle> {
    return this.http.get<MaterialArticle>(
      this.uri + '/article',
      {params: {article: articleId.toString()}}
    );
  }

  listGroup(sortBy?: string, desc?: boolean): Observable<MaterialGroupListElement[]> {
    return this.http.get<MaterialGroupListElement[]>(
      this.uri + '/group',
      sortBy ? {params: {sort: sortBy, order: desc ? 'DESC' : 'ASC'}} : {}
    );
  }

  createGroup(form: MaterialGroupForm): Observable<number> {
    return this.http.post<number>(this.uri + '/group', form);
  }

  updateGroup(form: MaterialGroupForm, groupId: number): Observable<MaterialGroupListElement> {
    return this.http.put<MaterialGroupListElement>(
      this.uri + '/group',
      form,
      {params: {group: groupId.toString()}}
    );
  }

  getSingleGroup(groupId: number): Observable<MaterialGroupListElement> {
    return this.http.get<MaterialGroupListElement>(
      this.uri + '/group',
      {params: {group: groupId.toString()}}
    );
  }

  insertField(form: MaterialFieldForm): Observable<MaterialFieldAttributes> {
    return this.http.post<MaterialFieldAttributes>(this.uri + '/field', form);
  }

  updateField(id: number, form: MaterialFieldForm): Observable<MaterialFieldAttributes> {
    return this.http.put<MaterialFieldAttributes>(this.uri + `/field/${id}`, form);
  }

  getField(fieldId: number): Observable<MaterialFieldAttributes> {
    return this.http.get<MaterialFieldAttributes>(
      this.uri + '/field',
      {params: {field: fieldId.toString()}}
    );
  }

  getSingleVariation(variationId: number): Observable<MaterialVariation> {
    return this.http.get<MaterialVariation>(
      this.uri + '/variation',
      {params: {variation: variationId.toString()}}
    );
  }

  searchMaterial(searchQuery: string, level: number): Observable<MaterialVariationAggregation[]> {
    return this.http.get<MaterialVariationAggregation[]>(
      `${environment.apiv4uri}material/search`,
      {params: EnvisiaLocation.httpParams({q: searchQuery, level: level})},
    );
  }

  listField(sortBy?: string, desc?: boolean): Observable<MaterialFieldListElement[]> {
    return this.http.get<MaterialFieldListElement[]>(
      this.uri + '/field',
      sortBy ? {params: {sort: sortBy, order: desc ? 'DESC' : 'ASC'}} : {}
    );
  }

  listVariations(articleId): Observable<MaterialVariation[]> {
    return this.http.get<MaterialVariation[]>(
      this.uri + '/variation',
      articleId ? {params: {article: articleId}} : {}
    );
  }

  listCreateArticleFormElements(groupId: number): Observable<MaterialArticleFieldForm[]> {
    return this.http.get<MaterialArticleFieldForm[]>(
      this.uri + '/article/fields',
      {params: {group: groupId.toString()}}
    );
  }

  listFieldsByGroupId(groupId: number): Observable<MaterialArticleFieldForm[]> {
    return this.http.get<MaterialArticleFieldForm[]>(
      this.uri + '/fields',
      {params: {group: groupId.toString()}}
    );
  }

  mapFieldFormsToClass(fields: Observable<MaterialArticleFieldForm[]>): Observable<MaterialValueType[]> {
    return fields.pipe(map(value => {
      return value.map(mf =>
        new MaterialValueType(mf.id, mf.name, mf.data_type, mf.read_only, mf.value, mf.choices)
      );
    }));
  }

  listCreateArticleFieldForms(groupId: number): Observable<MaterialValueType[]> {
    return this.mapFieldFormsToClass(this.listCreateArticleFormElements(groupId));
  }

  listFormFieldClasses(groupId: number): Observable<MaterialValueType[]> {
    return this.mapFieldFormsToClass(this.listFieldsByGroupId(groupId));
  }

  listCreateArticleFormElementsWithArticleId(articleId: number): Observable<MaterialArticleFieldForm[]> {
    return this.http.get<MaterialArticleFieldForm[]>(
      this.uri + '/article/fields',
      {params: {article: articleId.toString()}}
    );
  }

  listCreateVariationFormElements(articleId: number): Promise<MaterialValueType[]> {
    return this.http.get<MaterialArticleFieldForm[]>(
      this.uri + '/variation/fields',
      {params: {article: articleId.toString()}}
    ).toPromise().then((value) => {
      return value.map(mf =>
        new MaterialValueType(mf.id, mf.name, mf.data_type, mf.read_only, mf.value, mf.choices)
      );
    });
  }

  listInventoryForVariation(variationId: number, all: boolean = false): Observable<MaterialStorage[]> {
    return this.http.get<MaterialStorage[]>(
      this.uri + '/inventory',
      {params: {variation: variationId.toString(), all: all.toString()}}
    );
  }

  getAggregation(variationId: number): Observable<MaterialAggregation> {
    return this.http.get<MaterialAggregation>(
      this.uri + '/variation/aggregated',
      {params: {variation: variationId.toString()}}
    );
  }

  insertTransaction(form: MaterialTransaction): Observable<MaterialTransaction> {
    return this.http.post<MaterialTransaction>(
      this.uri + '/transaction',
      form
    );
  }

  transferStock(storageId: number, form: MaterialTransferStockForm): Observable<MaterialTransaction[]> {
    return this.http.put<MaterialTransaction[]>(this.uri +
      `/inventory/transfer?storage=${storageId}`,
      form
    );
  }

  listUnits(): Observable<MaterialUnit[]> {
    return this.http.get<MaterialUnit[]>(
      this.uri + '/units'
    );
  }

  updateUnits(form: MaterialUnit): Observable<MaterialUnit[]> {
    return this.http.post<MaterialUnit[]>(
      this.uri + '/units',
      form
    );
  }

  deleteVariation(id: number): Observable<MaterialVariation> {
    return this.http.delete<MaterialVariation>(this.uri + '/variation/' + id.toString());
  }

  deleteField(id: number): Observable<MaterialFieldListElement> {
    return this.http.delete<MaterialFieldListElement>(this.uri + '/field/' + id.toString());
  }

  deleteArticle(articleId: number): Observable<MaterialArticle> {
    return this.http.delete<MaterialArticle>(
      this.uri + '/article/' + articleId.toString()
    );
  }

}
