import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ArticleSpecificationService} from '../../../../envisia/article/services/article-specification.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'article-config-main-create',
  templateUrl: './article-config-main-create.component.html',
})
export class ArticleConfigMainCreateComponent implements OnInit {
  form: UntypedFormGroup;
  errors?: { [key: string]: any; } = {};
  blocked = false;

  constructor(private fb: UntypedFormBuilder,
              private articleSpecificationService: ArticleSpecificationService,
              private alertService: AlertService,
              private modalRef: NgbActiveModal) {
  }

  get dataForm(): UntypedFormGroup {
    return this.form.get('data') as UntypedFormGroup;
  }

  ngOnInit() {
    this.form = this.fb.group({
      'name': [null, Validators.required],
      'data': this.fb.group({
        'tg': [null, Validators.required],
        'cti': [null, Validators.required],
        'plc': [null, Validators.required],
        'ipc_4101': [null, Validators.required],
        'halogen_free': [false, Validators.required],
        'de_compliant': [false],
        'china_compliant': [false, Validators.required],
        'china_text': [null],
        'priority': [null],
      })
    });
  }

  create(): void {
    this.blocked = true;
    this.errors = {};
    this.articleSpecificationService.insertBaseMaterial(this.form.value).subscribe(data => {
      this.alertService.add('success', 'Basismaterial erfolgreich hinzugefügt!');
      this.modalRef.close(data);
    }, (response: HttpErrorResponse) => {
      this.blocked = false;
      if (response.status === 404) {
        this.errors = {'name': ['duplicated']};
      } else {
        this.errors = response.error;
      }
    });
  }

}
