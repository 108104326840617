<form class="form-horizontal" [formGroup]="form">
  <div class="col-md-12">

    <h3>Bestellung erstellen</h3>

    <customer-credit-worthiness-alert [customerCredit]="customerCredit">
      <ng-template>
        <div class="form-group">
          <label for="input-producer" class="col-sm-2 control-label">Produzent</label>
          <div class="col-sm-4">
            <select class="form-control" id="input-producer" formControlName="producer">
              <option *ngFor="let producer of producerList" [ngValue]="producer.name">{{ producer.name }}</option>
            </select>
          </div>
          <div class="col-sm-6">&nbsp;</div>
        </div>

        <div class="form-group">
          <label for="input-request_nr" class="col-sm-2 control-label">Anfrage-Nr.</label>
          <div class="col-sm-2">
            <input type="text" class="form-control" id="input-request_nr"
                   formControlName="request_nr"
                   [ngClass]="{red_alert: errors.request_nr}">
          </div>
          <div class="col-sm-8">&nbsp;</div>
        </div>

        <div class="form-group">
          <label for="input-extra_cost" class="col-sm-2 control-label">Nebenkosten</label>
          <div class="col-sm-2">
            <div class="input-group ">
              <input type="text" class="form-control" id="input-extra_cost"
                     formControlName="extra_cost"
                     [ngClass]="{red_alert: errors.extra_cost}">
              <div class="input-group-addon">$</div>
            </div>

          </div>
          <div class="col-sm-8">&nbsp;</div>
        </div>

        <div class="form-group">
          <label for="input-testing" class="col-sm-2 control-label">Prüfung</label>
          <div class="col-sm-2">
            <select id="input-testing" class="form-control"
                    formControlName="testing"
                    [ngClass]="{red_alert: errors.testing}">
              <option>E-Muster</option>
              <option>Serie</option>
              <option>Serie mit EMPB</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label for="input-order_testing_note" class="col-sm-2 control-label">Prüfhinweis</label>
          <div class="col-sm-3">
        <textarea id="input-order_testing_note" class="form-control" rows="5"
                  formControlName="testing_note"
                  [ngClass]="{red_alert: errors.testing_note}"></textarea>
          </div>
        </div>

        <div>
          <h4>Positionen</h4>
        </div>

        <div class="small-form">
          <div class="order-position-header row">
            <div class="col-sm-1">&nbsp;</div>
            <div class="col-sm-2">
              <h5>Menge</h5>
            </div>

            <div class="col-sm-2">
              <h5>Preis</h5>
            </div>
            <div class="col-sm-2">
              <h5>Lief.Ter.(ETD)</h5>
            </div>
            <div class="col-sm-2"><h5>Auf Abruf</h5></div>
          </div>

          <order-request-main-order-position-form [form]="form" [errors]="errors">
          </order-request-main-order-position-form>
        </div>

        <div class="form-group">
          <div class="col-sm-6">
            <label for="input-offer_hint" class="control-label">Hinweis-Text</label>

            <textarea class="form-control" id="input-offer_hint" rows="5"
                      formControlName="note"
                      [ngClass]="{red_alert: errors.note}"></textarea>
          </div>

          <div class="col-sm-6"></div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <article-warning [article]="data.article"></article-warning>
          </div>
        </div>

        <div class="col-sm-6">
          <!-- TODO: remove button if article is deleted -->
          <button type="button" class="btn btn-success pull-right"
                  (click)="createOrder()"
                  [disabled]="!form.valid || creating || !!data.article.locked">
            Bestellung erstellen
          </button>
        </div>
      </ng-template>
    </customer-credit-worthiness-alert>
  </div>
</form>
