import { NgModule } from '@angular/core';
import {ArticleWarningModule} from '../../../article/warning/article-warning.module';
import {NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {OfferCreateComponent} from './offer-create.component';
import {SharedModule} from '../../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {WorkflowPipesModule} from '../../pipes/workflow-pipes.module';

@NgModule({
  declarations: [
    OfferCreateComponent,
  ],
  imports: [
    SharedModule,
    UIRouterModule.forChild(),
    ArticleWarningModule,
    NgbModalModule,
    NgbTooltipModule,
    WorkflowPipesModule,
  ]
})
export class OfferCreateModule { }
