import * as React from 'react';
import {ArticleMultilayerPartTypeHelper} from '../article-multilayer-part-type-helper';
import {ArticleMultilayerParts} from '../article-multilayer-plan.models';
import {ArticleSpecification} from '../../../models/article-specification.model';
import {FormatNumber} from '../formatters/format-number';
import {ArticleMultilayerNumberInput} from '../form-elements/article-multilayer-number-input';
import {ReactNodeArray} from 'react';

interface Props {
  model: ArticleMultilayerParts;
  specification: { [key: string]: ArticleSpecification };
  partChange: (part: ArticleMultilayerParts) => void;
  children: ReactNodeArray;
}

export function ArticleMultilayerPlanPartCuPlating(props: Props) {
  const type = ArticleMultilayerPartTypeHelper.types.cu_plating;

  const thicknessChange: (value: number | null) => void = (value) => {
    const copyPlan = {...props.model};
    copyPlan.cu_plating = {thickness_selected: value, group: null};
    props.partChange(copyPlan);
  };

  return <>
    <tr style={{'height': '100%'}}>
      {props.children[0]}
      <td className={'height-fix'}>
        <div className={'rounded-full-left justify-end color-class ' + type.color_class}>&nbsp;</div>
      </td>
      <td className={'height-fix'}>
        <div className={'rounded-full-center justify-end color-class ' + type.color_class}/>
      </td>
      {props.children[5]}
      <td className={'height-fix'}>
        <div className={'rounded-full-center color-class ' + type.color_class}>{type.label}</div>
      </td>
      <td className={'height-fix'}>
        <div className={'rounded-full-right color-class ' + type.color_class}>
          <ArticleMultilayerNumberInput model={'cu_plating'}
                                        value={props.model.cu_plating.thickness_selected}
                                        onValueChange={value => thicknessChange(value)}>
            <div className={'article-form-data-content'}>
              <div className={'article-form-data-unit-block'}>
                &mu;m
              </div>
            </div>
          </ArticleMultilayerNumberInput>
        </div>
      </td>
      {props.children[4]}
      <td>
        <div className={'flex align-center justify-end fit-all'}>
          <FormatNumber value={props.model.thickness / 1000} decimals={3}/>&nbsp;mm
        </div>
      </td>
      {props.children[3]}
      <td className={'border-none-top border-none-bottom'}/>
      <td className={'border-none-top border-none-bottom'}/>
      <td>{props.children[1]}</td>
      <td>{props.children[2]}</td>
    </tr>
  </>;
}
