<div class="whitebox" [formGroup]="form">

  <article-extra-headline [type]="type"
                          [mainType]="mainType"
                          [readOnly]="readOnly"
                          (deleteCalled)="deleteCalled.emit({section: formName})">
    Schliffbilduntersuchung
  </article-extra-headline>

  <div class="clearfix"></div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="every_batch" class="article-form-item-values">
      Jedes Los
    </article-extra-checkbox>

    <article-extra-text formControlName="every_batch_other" class="article-form-item-extra"></article-extra-text>
  </div>

  <h6>Besonderheiten China</h6>

  <div class="article-form-item-extra-values">
    <article-extra-dropdown formControlName="sds_cut_image_analysis"
                            [defaultValues]="defaultValues['sds-cut-image-analysis']"
                            class="article-form-item-values">
      SDS Schliffbildanalyse
    </article-extra-dropdown>

    <article-extra-text formControlName="sds_cut_image_analysis_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

  <h6>Versand per</h6>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="email" class="article-form-item-values">E-Mail
    </article-extra-checkbox>

    <article-extra-text formControlName="email_other" class="article-form-item-extra"></article-extra-text>

  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="paper" class="article-form-item-values">
      Papier
    </article-extra-checkbox>

    <article-extra-text formControlName="paper_other" class="article-form-item-extra"></article-extra-text>

  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="with_pattern_measurement" class="article-form-item-values">
      Mit Messmuster
    </article-extra-checkbox>

    <article-extra-text formControlName="with_pattern_measurement_other" class="article-form-item-extra">
    </article-extra-text>

  </div>

  <div class="article-form-item-extra-values">
    <article-extra-checkbox formControlName="with_grounded_chunk" class="article-form-item-values">
      Mit Schliffklotz
    </article-extra-checkbox>

    <article-extra-text formControlName="with_grounded_chunk_other" class="article-form-item-extra">
    </article-extra-text>
  </div>

</div>
