<ng-container [ngSwitch]="workflow.status" *ngIf="!('workflow.status.change'|HasPerm)">
  <span *ngSwitchCase="0" class="label label-default">Unbearbeitet</span>
  <span *ngSwitchCase="1" class="label label-default">Offen</span>
  <span *ngSwitchCase="2" class="label label-info">Auftrag</span>
  <span *ngSwitchCase="3" class="label label-primary">Bestätigt</span>
  <span *ngSwitchCase="4" class="label label-warning">Geliefert</span>
  <span *ngSwitchCase="5" class="label label-success">Abgerechnet</span>
  <span *ngSwitchCase="100" class="label label-danger">Storniert</span>
  <span *ngSwitchCase="101" class="label label-danger">Gelöscht</span>
</ng-container>

<ng-container [ngSwitch]="workflow.status" *ngIf="('workflow.status.change'|HasPerm)">
  <span *ngSwitchCase="0" class="label label-default click" (click)="open()">Unbearbeitet</span>
  <span *ngSwitchCase="1" class="label label-default click" (click)="open()">Offen</span>
  <span *ngSwitchCase="2" class="label label-info click" (click)="open()">Auftrag</span>
  <span *ngSwitchCase="3" class="label label-primary click" (click)="open()">Bestätigt</span>
  <span *ngSwitchCase="4" class="label label-warning click" (click)="open()">Geliefert</span>
  <span *ngSwitchCase="5" class="label label-success click" (click)="open()">Abgerechnet</span>
  <span *ngSwitchCase="100" class="label label-danger click" (click)="open()">Storniert</span>
  <span *ngSwitchCase="101" class="label label-danger click" (click)="open()">Gelöscht</span>
</ng-container>
